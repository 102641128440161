// https://www.warrants.com.sg/NewsTableServlet?sp=todayhighlight&start=0

const dailyhigh = {
    "newsList": [
        {
            "id": "6745",
            "en_short": "<p class=\"normaltextnobulletpoint\"><span style=\"font-size:12pt\"><span style=\"line-height:115%\"><span style=\"font-family:Arial,sans-serif\"><span style=\"color:#333333\"><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">Last week, the decline in global bonds prices sent yields climbing</span></span>, as the y<span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">ield on the 10-year Treasury note reached a one-year high of 1.614%. This was the largest move since 2016, as</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\"> yield-seeking investors cashed in on equity gains and flocked to the bonds market where prices are lower and seemed a safer choice than equities (Reuters).<br /><br />Market volatility picked up on the moves, as the Cboe Volatility Index for stocks, also known as the VIX or fear index, traded close to 30 on Friday alone.<br /><br />Read on to find out what does the steepening of the yield curve means, and what triggered the selloff in stocks globally this week</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">...</span></span></span></span></span></span></p>",
            "en_title": "Global bond yields surge; equities to suffer?",
            "date": "01 Mar 21",
            "thai_short": "<p class=\"normaltextnobulletpoint\"><span style=\"font-size:12pt\"><span style=\"line-height:115%\"><span style=\"font-family:Arial,sans-serif\"><span style=\"color:#333333\"><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">Last week, the decline in global bonds prices sent yields climbing</span></span>, as the y<span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">ield on the 10-year Treasury note reached a one-year high of 1.614%. This was the largest move since 2016, as</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\"> yield-seeking investors cashed in on equity gains and flocked to the bonds market where prices are lower and seemed a safer choice than equities (Reuters).<br /><br />Market volatility picked up on the moves, as the Cboe Volatility Index for stocks, also known as the VIX or fear index, traded close to 30 on Friday alone.<br /><br />Read on to find out what does the steepening of the yield curve means, and what triggered the selloff in stocks globally this week</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">...</span></span></span></span></span></span></p>",
            "thai_title": "Global bond yields surge; equities to suffer?"
        },
        {
            "id": "6741",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">UOB, the last of the 3 local banks, reported its earnings for the fourth quarter of 2020 (4Q20) yesterday. The reported showed improvements in its earnings, consistent to the rival banks. This highlighted the steady recovery in the banking sector, which boosted bank stocks in the past trading sessions.<br /><br />Read on for a breakdown of UOB&rsquo;s results in the excerpts from a Macquarie Research (MQ) note published on 25 February 2021</span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">&hellip;</span></span></span></span></p>",
            "en_title": "UOB – sequential improvements continue in 4Q20",
            "date": "26 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">UOB, the last of the 3 local banks, reported its earnings for the fourth quarter of 2020 (4Q20) yesterday. The reported showed improvements in its earnings, consistent to the rival banks. This highlighted the steady recovery in the banking sector, which boosted bank stocks in the past trading sessions.<br /><br />Read on for a breakdown of UOB&rsquo;s results in the excerpts from a Macquarie Research (MQ) note published on 25 February 2021</span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">&hellip;</span></span></span></span></p>",
            "thai_title": "UOB – sequential improvements continue in 4Q20"
        },
        {
            "id": "6737",
            "en_short": "<p><span style=\"font-size:11pt\"><span style=\"line-height:115%\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Tomorrow, City Developments (CityDev) is scheduled to release its 2020 earnings before market hours. This comes on the back of CityDev&rsquo;s agreement to acquire a majority stake in Shenzhen Technology Park from Sincere Property Group and 2 entities of China Ping An.<br /><br />Read on for excerpts from a Macquarie Research (MQ) note published on 23 February 2021, as MQ discusses the firm&rsquo;s outlook for 2021, and will it cope in the low interest rate climate...</span></span></span></span></p>",
            "en_title": "City Developments – preview for Friday’s earnings announcement",
            "date": "25 Feb 21",
            "thai_short": "<p><span style=\"font-size:11pt\"><span style=\"line-height:115%\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Tomorrow, City Developments (CityDev) is scheduled to release its 2020 earnings before market hours. This comes on the back of CityDev&rsquo;s agreement to acquire a majority stake in Shenzhen Technology Park from Sincere Property Group and 2 entities of China Ping An.<br /><br />Read on for excerpts from a Macquarie Research (MQ) note published on 23 February 2021, as MQ discusses the firm&rsquo;s outlook for 2021, and will it cope in the low interest rate climate...</span></span></span></span></p>",
            "thai_title": "City Developments – preview for Friday’s earnings announcement"
        },
        {
            "id": "6733",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">The recent months have been filled with large market moves from the US election and US-China tensions. During these volatile periods, the huge price fluctuations in the underlying can cause the dynamics of a warrant to alter.<br /><br />Understand how such situations influences a warrant&rsquo;s delta and why <a href=\"https://www.warrants.com.sg/tools/livematrix/XLDW\" style=\"color:blue; text-decoration:underline\">XLDW</a> &ndash; a popular HSI call warrant previously, is now on widened spreads</span></span></span></span><span style=\"font-size:12pt\"><span style=\"background:white\"><span style=\"font-family:&quot;Times New Roman&quot;,serif\"><span style=\"font-size:11.0pt\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">&hellip;</span></span></span></span></span></span></p>",
            "en_title": "Why is “delta” important in volatile market ",
            "date": "23 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">The recent months have been filled with large market moves from the US election and US-China tensions. During these volatile periods, the huge price fluctuations in the underlying can cause the dynamics of a warrant to alter.<br /><br />Understand how such situations influences a warrant&rsquo;s delta and why <a href=\"https://www.warrants.com.sg/tools/livematrix/XLDW\" style=\"color:blue; text-decoration:underline\">XLDW</a> &ndash; a popular HSI call warrant previously, is now on widened spreads</span></span></span></span><span style=\"font-size:12pt\"><span style=\"background:white\"><span style=\"font-family:&quot;Times New Roman&quot;,serif\"><span style=\"font-size:11.0pt\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">&hellip;</span></span></span></span></span></span></p>",
            "thai_title": "Why is “delta” important in volatile market "
        },
        {
            "id": "6729",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Macquarie is pleased to introduce warrants in Singapore over the highly anticipated Hang Seng TECH Index (HSTECH) listed on the Hong Kong Exchange (HKEx)! Singapore investors can now gain exposure to the moves in the HSTECH, without leaving the SGX!<br /><br />Macquarie&rsquo;s HSTECH warrants &ndash; to be listed on the SGX and traded in Singapore dollars &ndash; will begin trading on <strong>Monday, 22 February 2021</strong> and will track the moves in the relevant HSTECH futures. The market-making hours for the warrants will follow the futures&rsquo; trading hours on the HKEx from 9.15am to 4.30pm, with a break from 12-1PM.<br /><br />To learn more about the market-beating index of the past year, </span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/education/hstech\" style=\"font-family:Arial,sans-serif; color:blue; text-decoration:underline\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">click here</span></a></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">!</span></span></span></p>",
            "en_title": "Warrant launch: Hang Seng TECH Index in Singapore!",
            "date": "22 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Macquarie is pleased to introduce warrants in Singapore over the highly anticipated Hang Seng TECH Index (HSTECH) listed on the Hong Kong Exchange (HKEx)! Singapore investors can now gain exposure to the moves in the HSTECH, without leaving the SGX!<br /><br />Macquarie&rsquo;s HSTECH warrants &ndash; to be listed on the SGX and traded in Singapore dollars &ndash; will begin trading on <strong>Monday, 22 February 2021</strong> and will track the moves in the relevant HSTECH futures. The market-making hours for the warrants will follow the futures&rsquo; trading hours on the HKEx from 9.15am to 4.30pm, with a break from 12-1PM.<br /><br />To learn more about the market-beating index of the past year, </span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/education/hstech\" style=\"font-family:Arial,sans-serif; color:blue; text-decoration:underline\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">click here</span></a></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">!</span></span></span></p>",
            "thai_title": "Warrant launch: Hang Seng TECH Index in Singapore!"
        },
        {
            "id": "6725",
            "en_short": "<p style=\"margin-bottom:11px\"><span style=\"font-size:11pt\"><span style=\"line-height:115%\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">Last week, Singtel announced weaker than expected earnings for the quarter ending 31 December 2020 (4Q20). Investor sentiments received a second blow as Singtel confirmed a cybersecurity breach on Wednesday, which saw data of 129,000 of its customers leaked.</span></span></span></span></span><br /><br /><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">After rebounding to $2.42 on Wednesday, Singtel&rsquo;s shares slipped for 2 straight days, booking in a -2.5% loss. Bullish investors expecting a rebound and wishing to capture leveraged returns may consider the Singtel call </span></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/tools/warrantterms/59XW\" style=\"color:blue; text-decoration:underline\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">59XW</span></a></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\"> with a 6.3x gearing&hellip;</span></span></span></span></p>",
            "en_title": "Singtel – weaker earnings and recent data breach",
            "date": "19 Feb 21",
            "thai_short": "<p style=\"margin-bottom:11px\"><span style=\"font-size:11pt\"><span style=\"line-height:115%\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">Last week, Singtel announced weaker than expected earnings for the quarter ending 31 December 2020 (4Q20). Investor sentiments received a second blow as Singtel confirmed a cybersecurity breach on Wednesday, which saw data of 129,000 of its customers leaked.</span></span></span></span></span><br /><br /><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\">After rebounding to $2.42 on Wednesday, Singtel&rsquo;s shares slipped for 2 straight days, booking in a -2.5% loss. Bullish investors expecting a rebound and wishing to capture leveraged returns may consider the Singtel call </span></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/tools/warrantterms/59XW\" style=\"color:blue; text-decoration:underline\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">59XW</span></a></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:#333333\"> with a 6.3x gearing&hellip;</span></span></span></span></p>",
            "thai_title": "Singtel – weaker earnings and recent data breach"
        },
        {
            "id": "6721",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">There are 23 warrants expiring soon that will stop trading this month. Holders of these warrants now have to wait until the expiry date to see if their warrants will finish with a settlement value. Amongst the February expiry warrants, 5 are HSI warrants that stops trading today. Here is what will happen on the expiry date&hellip;</span></span></span></p>",
            "en_title": "Last trading day of February expiry HSI warrants – what next?",
            "date": "18 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">There are 23 warrants expiring soon that will stop trading this month. Holders of these warrants now have to wait until the expiry date to see if their warrants will finish with a settlement value. Amongst the February expiry warrants, 5 are HSI warrants that stops trading today. Here is what will happen on the expiry date&hellip;</span></span></span></p>",
            "thai_title": "Last trading day of February expiry HSI warrants – what next?"
        },
        {
            "id": "6713",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">After markets closed on Monday, ComfortDegro (CD) announced its earnings for the fourth quarter of 2020 (4Q20), where the firm recorded its first annual operating loss of $46.2 million. Losses came largely from its taxi business, where rental waiver schemes contributed to an annual operating loss of S$64.4 million.<br /><br />The losses were mitigated however, with support measures by the government keeping operating profits afloat at $123.1 million in 2020. In yesterday&rsquo;s budget announcement, deputy prime minister Heng Swee Keat has set aside another $133 million to the Driver Relief Fund which has contributed to the sector&rsquo;s steady recovery in 2020.<br /><br />Read on for excerpts from a Macquarie Research (MQ) note published on 16 February 2021, as MQ discusses the outlook for public transport services and what lies ahead for CD&hellip;</span></span></span></span></p>",
            "en_title": "Comfort Delgro – is the worst behind for the transport sector?",
            "date": "17 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">After markets closed on Monday, ComfortDegro (CD) announced its earnings for the fourth quarter of 2020 (4Q20), where the firm recorded its first annual operating loss of $46.2 million. Losses came largely from its taxi business, where rental waiver schemes contributed to an annual operating loss of S$64.4 million.<br /><br />The losses were mitigated however, with support measures by the government keeping operating profits afloat at $123.1 million in 2020. In yesterday&rsquo;s budget announcement, deputy prime minister Heng Swee Keat has set aside another $133 million to the Driver Relief Fund which has contributed to the sector&rsquo;s steady recovery in 2020.<br /><br />Read on for excerpts from a Macquarie Research (MQ) note published on 16 February 2021, as MQ discusses the outlook for public transport services and what lies ahead for CD&hellip;</span></span></span></span></p>",
            "thai_title": "Comfort Delgro – is the worst behind for the transport sector?"
        },
        {
            "id": "6709",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Yesterday, </span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"letter-spacing:-.1pt\">Singapore released its Gross Domestic Product (GDP) figure for 2020 which saw the economy contracting by -5.4%. This marks the first annual decline since 2001 and the worst recession since independence.<br /><br />Despite the sombre GDP figures, markets rallied as the results came in slightly better than advance estimate of a -5.8% contraction and the government&rsquo;s forecasted range of -6.5% to -6%. (CNA)<br /><br />Today, Finance Minister Heng Swee Keat is scheduled to announce Singapore&rsquo;s 2021 budget to stem the recession caused by the coronavirus pandemic. Read on for more details on the government&rsquo;s 2021 outlook and what to expect from the budget&hellip;</span></span></span></span></p>",
            "en_title": "Singapore – better than expected GDP; Budget 2021 announced today",
            "date": "16 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Yesterday, </span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"letter-spacing:-.1pt\">Singapore released its Gross Domestic Product (GDP) figure for 2020 which saw the economy contracting by -5.4%. This marks the first annual decline since 2001 and the worst recession since independence.<br /><br />Despite the sombre GDP figures, markets rallied as the results came in slightly better than advance estimate of a -5.8% contraction and the government&rsquo;s forecasted range of -6.5% to -6%. (CNA)<br /><br />Today, Finance Minister Heng Swee Keat is scheduled to announce Singapore&rsquo;s 2021 budget to stem the recession caused by the coronavirus pandemic. Read on for more details on the government&rsquo;s 2021 outlook and what to expect from the budget&hellip;</span></span></span></span></p>",
            "thai_title": "Singapore – better than expected GDP; Budget 2021 announced today"
        },
        {
            "id": "6705",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\">The bid-offer spread is the difference between the bid and offer price. One of the key reasons investors trade warrants is due to tighter spreads (1-2 ticks) set by market makers. However, there are cases where the warrant spreads go on wide during the lifespan of the warrant.<br /><br />As a warrant approaches expiry, market makers often widen spreads as the erosion of time value accelerates. Therefore, spreads are widened by raising the offer price to discourage investors from buying the expiring warrant.<br /><br />Spreads are also widened during volatile markets, as huge price swings in the underlying can cause the warrant deltas to change dramatically in a short space of time, as the warrants move from in-the-money to out-of-the-money and vice versa, very quickly. When this happens, you would notice that the offer spreads of warrants have been widened to discourage investors from buying these warrants&hellip;</span></span></span></p>",
            "en_title": "Warrants that were on tighter spreads… now wide – why?",
            "date": "15 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\">The bid-offer spread is the difference between the bid and offer price. One of the key reasons investors trade warrants is due to tighter spreads (1-2 ticks) set by market makers. However, there are cases where the warrant spreads go on wide during the lifespan of the warrant.<br /><br />As a warrant approaches expiry, market makers often widen spreads as the erosion of time value accelerates. Therefore, spreads are widened by raising the offer price to discourage investors from buying the expiring warrant.<br /><br />Spreads are also widened during volatile markets, as huge price swings in the underlying can cause the warrant deltas to change dramatically in a short space of time, as the warrants move from in-the-money to out-of-the-money and vice versa, very quickly. When this happens, you would notice that the offer spreads of warrants have been widened to discourage investors from buying these warrants&hellip;</span></span></span></p>",
            "thai_title": "Warrants that were on tighter spreads… now wide – why?"
        },
        {
            "id": "6701",
            "en_short": "<p>All structured warrants will experience time decay, meaning the warrant price will decrease over time even if the underlying price remains unchanged (all other factors held constant). This process is accelerated for warrants with shorter time to expiry. This means that the 26 warrants that stops trading in February have already begun to see greater erosion to their time value, by up to 4 ticks of decay per day.<br /><br />Investors trading shorter dated warrants may consider switching to longer dated ones. Read on for more&hellip;</p>",
            "en_title": "Why is it risky to trade warrants approaching expiry? ",
            "date": "10 Feb 21",
            "thai_short": "<p>All structured warrants will experience time decay, meaning the warrant price will decrease over time even if the underlying price remains unchanged (all other factors held constant). This process is accelerated for warrants with shorter time to expiry. This means that the 26 warrants that stops trading in February have already begun to see greater erosion to their time value, by up to 4 ticks of decay per day.<br /><br />Investors trading shorter dated warrants may consider switching to longer dated ones. Read on for more&hellip;</p>",
            "thai_title": "Why is it risky to trade warrants approaching expiry? "
        },
        {
            "id": "6697",
            "en_short": "<p class=\"normaltextnobulletpoint\"><span style=\"font-size:12pt\"><span style=\"line-height:115%\"><span style=\"font-family:Arial,sans-serif\"><span style=\"color:#333333\"><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">Macquarie Warrants is pleased to launch new warrants over the shipbuilding group Yangzijiang today! Gain exposure via the new Yangzijiang warrant and proxy exposure to the latest moves over China&rsquo;s shipbuilding industry which saw increased output in 2020.</span></span></span></span></span></span><br /><br /><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Read on for a summary of China&rsquo;s shipbuilding sector&rsquo;s performance 2020, and how Yangzijiang has performed in the pandemic-stricken industry...</span></span></span></p>",
            "en_title": "Yangzijiang – shipbuilding giant powering through turbulent waters",
            "date": "09 Feb 21",
            "thai_short": "<p class=\"normaltextnobulletpoint\"><span style=\"font-size:12pt\"><span style=\"line-height:115%\"><span style=\"font-family:Arial,sans-serif\"><span style=\"color:#333333\"><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">Macquarie Warrants is pleased to launch new warrants over the shipbuilding group Yangzijiang today! Gain exposure via the new Yangzijiang warrant and proxy exposure to the latest moves over China&rsquo;s shipbuilding industry which saw increased output in 2020.</span></span></span></span></span></span><br /><br /><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Read on for a summary of China&rsquo;s shipbuilding sector&rsquo;s performance 2020, and how Yangzijiang has performed in the pandemic-stricken industry...</span></span></span></p>",
            "thai_title": "Yangzijiang – shipbuilding giant powering through turbulent waters"
        },
        {
            "id": "6693",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"background:white\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><span style=\"color:black\">As Asian markets head into the Lunar New Year holidays this week, China&rsquo;s liquidity squeeze continues to rattle markets, as the world&rsquo;s second largest economy takes an unprecedented stance ahead for the festive season.<br /><br />People&rsquo;s Bank of China (PBoC) advisor Ma Jun warned about a growing asset bubble due to the abundance of liquidity from Chinese investors which have stoked the stock and property market. In the Hong Kong equities market, Chinese investors bought a net total of nearly USD48 billion stocks in the Hong Kong market in the first five weeks of 2021, sending the Hang Seng Index (HSI) futures +8.4% higher in the past month. The trending HSI call warrant </span></span></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/tools/livematrix/HGKW\" style=\"color:blue; text-decoration:underline\"><span style=\"background:white\"><span style=\"color:black\">HGKW</span></span></a><span style=\"background:white\"><span style=\"color:black\"> rose +28% in the same period.<br /><br />Starting tomorrow, the Hong Kong-China Stock Connect &ndash; the trading link that allows mainland investors to buy domestic stocks on the Hong Kong Stock Exchange, will be closed through February 17 for the Lunar New Year. Find out if Hong Kong&rsquo;s USD7.1 trillion market can keep its position without its biggest source of flows (Bloomberg)</span></span>&hellip;</span></span></span></p>",
            "en_title": "HSI – will markets see a Lunar New Year rally?",
            "date": "08 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"background:white\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><span style=\"color:black\">As Asian markets head into the Lunar New Year holidays this week, China&rsquo;s liquidity squeeze continues to rattle markets, as the world&rsquo;s second largest economy takes an unprecedented stance ahead for the festive season.<br /><br />People&rsquo;s Bank of China (PBoC) advisor Ma Jun warned about a growing asset bubble due to the abundance of liquidity from Chinese investors which have stoked the stock and property market. In the Hong Kong equities market, Chinese investors bought a net total of nearly USD48 billion stocks in the Hong Kong market in the first five weeks of 2021, sending the Hang Seng Index (HSI) futures +8.4% higher in the past month. The trending HSI call warrant </span></span></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/tools/livematrix/HGKW\" style=\"color:blue; text-decoration:underline\"><span style=\"background:white\"><span style=\"color:black\">HGKW</span></span></a><span style=\"background:white\"><span style=\"color:black\"> rose +28% in the same period.<br /><br />Starting tomorrow, the Hong Kong-China Stock Connect &ndash; the trading link that allows mainland investors to buy domestic stocks on the Hong Kong Stock Exchange, will be closed through February 17 for the Lunar New Year. Find out if Hong Kong&rsquo;s USD7.1 trillion market can keep its position without its biggest source of flows (Bloomberg)</span></span>&hellip;</span></span></span></p>",
            "thai_title": "HSI – will markets see a Lunar New Year rally?"
        },
        {
            "id": "6689",
            "en_short": "<p>Similar to stocks, Singapore investors are able to short-sell structured warrants. However, one may wonder how it works and if they should consider short-selling as part of their warrant trading strategy. Today&rsquo;s issue discusses the steps and why short-selling may not be optimal with warrants&hellip;</p>",
            "en_title": "What happens when I short-sell a warrant? ",
            "date": "05 Feb 21",
            "thai_short": "<p>Similar to stocks, Singapore investors are able to short-sell structured warrants. However, one may wonder how it works and if they should consider short-selling as part of their warrant trading strategy. Today&rsquo;s issue discusses the steps and why short-selling may not be optimal with warrants&hellip;</p>",
            "thai_title": "What happens when I short-sell a warrant? "
        },
        {
            "id": "6685",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">Almost three months since Ant Group&rsquo;s botched IPO, Chinese regulators have reportedly agreed on a restructuring plan with Ant to turn the fintech company into a financial holding company.<br /><br />Currently, the plan requires all of Ant&rsquo;s businesses to be grouped into the holdings company, which will be subject to capital requirements similar to banks. (Bloomberg)<br /><br />The news came minutes before markets shut yesterday, which led to Alibaba, which owns about a third of Ant, to spike in the final 5 minutes to recoup all morning losses of 5% and close 0.4% higher. </span></span></span></span><br /><br /><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Trending Alibaba call </span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/tools/livematrix/R42W\" style=\"font-family:Arial,sans-serif; color:blue; text-decoration:underline\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">R42W</span></a></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"> <span style=\"color:black\">also rebounded +40.7% during the stock&rsquo;s last minute spike. Read on, as we discuss the ramifications </span>of the overhaul plan...</span></span></span></p>",
            "en_title": "What is the latest update on Ant Group?",
            "date": "04 Feb 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">Almost three months since Ant Group&rsquo;s botched IPO, Chinese regulators have reportedly agreed on a restructuring plan with Ant to turn the fintech company into a financial holding company.<br /><br />Currently, the plan requires all of Ant&rsquo;s businesses to be grouped into the holdings company, which will be subject to capital requirements similar to banks. (Bloomberg)<br /><br />The news came minutes before markets shut yesterday, which led to Alibaba, which owns about a third of Ant, to spike in the final 5 minutes to recoup all morning losses of 5% and close 0.4% higher. </span></span></span></span><br /><br /><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Trending Alibaba call </span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\"><a href=\"https://www.warrants.com.sg/tools/livematrix/R42W\" style=\"font-family:Arial,sans-serif; color:blue; text-decoration:underline\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">R42W</span></a></span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"> <span style=\"color:black\">also rebounded +40.7% during the stock&rsquo;s last minute spike. Read on, as we discuss the ramifications </span>of the overhaul plan...</span></span></span></p>",
            "thai_title": "What is the latest update on Ant Group?"
        },
        {
            "id": "6681",
            "en_short": "<p style=\"margin-bottom:11px\"><span style=\"font-size:11pt\"><span style=\"line-height:115%\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">In the past 2 weeks, real estate investment trusts (REITS) reported their earnings which generally showed a pick-up in the sector.<br /><br />Last Wednesday, Mapletree Commercial Trust (MCT) reported its third quarter earnings for the financial year, while AREIT announced its earnings for the second half of 2020 yesterday.</span></span></span></span><br />&nbsp;</p><p style=\"margin-bottom:11px\"><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Since MCT released its report, its shares rose by +1.4% on the optimism, reversing a 6-day decline. In the same period, MCT call <a href=\"https://www.warrants.com.sg/tools/warrantterms/TRGW\" style=\"font-family:Arial,sans-serif; color:blue; text-decoration:underline\">TRGW</a> recorded +13.9% in gains. Keep a look out as AREIT shares price-in the outlook for the year ahead&hellip;</span></span></span></p>",
            "en_title": "Singapore REITs – beginning the year with a steady recovery",
            "date": "03 Feb 21",
            "thai_short": "<p style=\"margin-bottom:11px\"><span style=\"font-size:11pt\"><span style=\"line-height:115%\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">In the past 2 weeks, real estate investment trusts (REITS) reported their earnings which generally showed a pick-up in the sector.<br /><br />Last Wednesday, Mapletree Commercial Trust (MCT) reported its third quarter earnings for the financial year, while AREIT announced its earnings for the second half of 2020 yesterday.</span></span></span></span><br />&nbsp;</p><p style=\"margin-bottom:11px\"><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Since MCT released its report, its shares rose by +1.4% on the optimism, reversing a 6-day decline. In the same period, MCT call <a href=\"https://www.warrants.com.sg/tools/warrantterms/TRGW\" style=\"font-family:Arial,sans-serif; color:blue; text-decoration:underline\">TRGW</a> recorded +13.9% in gains. Keep a look out as AREIT shares price-in the outlook for the year ahead&hellip;</span></span></span></p>",
            "thai_title": "Singapore REITs – beginning the year with a steady recovery"
        },
        {
            "id": "6677",
            "en_short": "<p>Last Friday, the Monetary Authority of Singapore (MAS) released December statistics for the Singapore banking system. On the back of a steady economic recovery, bank lending rose for the second straight month, and asset quality improved on declining non-performing loans.<br /><br />Read on for excerpts from a Macquarie Research (MQ) note published on 27 January 2021, as MQ further breakdowns the numbers and shares its outlook for the banks&rsquo; upcoming earnings reports for the fourth quarter of 2020 (4Q20/FY20) in February&hellip;</p>",
            "en_title": "Singapore Banks – MAS December stats; fourth quarter earnings outlook",
            "date": "02 Feb 21",
            "thai_short": "<p>Last Friday, the Monetary Authority of Singapore (MAS) released December statistics for the Singapore banking system. On the back of a steady economic recovery, bank lending rose for the second straight month, and asset quality improved on declining non-performing loans.<br /><br />Read on for excerpts from a Macquarie Research (MQ) note published on 27 January 2021, as MQ further breakdowns the numbers and shares its outlook for the banks&rsquo; upcoming earnings reports for the fourth quarter of 2020 (4Q20/FY20) in February&hellip;</p>",
            "thai_title": "Singapore Banks – MAS December stats; fourth quarter earnings outlook"
        },
        {
            "id": "6673",
            "en_short": "<p>Last night, the US reported the Gross Domestic Product (GDP) for 2020 which sank -3.5% from the year before - the worst decline since World War 2. The glum result came largely from the awful second quarter, as the nation struggled with initial pandemic lockdowns.<br /><br />Despite subdued results for the year, markets gained confidence from the fourth quarter&rsquo;s annualized and seasonally adjusted growth at +4%. This sent stocks rebounding from Wednesday&rsquo;s fall, which was the sharpest daily selloff in weeks.<br /><br />From yesterday&rsquo;s -2.5% decline in the S&amp;P500 March futures, the S&amp;P put XYPW soared +79.6%. Prepare for another wild day, as the S&amp;P500 futures are expected to open strong amidst heightened volatility. The S&amp;P futures are up 1.5% as of 925AM...</p>",
            "en_title": "US – markets rebound despite worst GDP slump since 1946",
            "date": "29 Jan 21",
            "thai_short": "<p>Last night, the US reported the Gross Domestic Product (GDP) for 2020 which sank -3.5% from the year before - the worst decline since World War 2. The glum result came largely from the awful second quarter, as the nation struggled with initial pandemic lockdowns.<br /><br />Despite subdued results for the year, markets gained confidence from the fourth quarter&rsquo;s annualized and seasonally adjusted growth at +4%. This sent stocks rebounding from Wednesday&rsquo;s fall, which was the sharpest daily selloff in weeks.<br /><br />From yesterday&rsquo;s -2.5% decline in the S&amp;P500 March futures, the S&amp;P put XYPW soared +79.6%. Prepare for another wild day, as the S&amp;P500 futures are expected to open strong amidst heightened volatility. The S&amp;P futures are up 1.5% as of 925AM...</p>",
            "thai_title": "US – markets rebound despite worst GDP slump since 1946"
        },
        {
            "id": "6669",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">There are 7 HSI warrants expiring today, 28 January 2021, and holders of these warrants will be able to see if their warrants expire with a settlement value. Here is what will happen when markets close at 5pm today&hellip;</span></span></span></p>",
            "en_title": "Do your HSI warrants expire today?",
            "date": "28 Jan 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">There are 7 HSI warrants expiring today, 28 January 2021, and holders of these warrants will be able to see if their warrants expire with a settlement value. Here is what will happen when markets close at 5pm today&hellip;</span></span></span></p>",
            "thai_title": "Do your HSI warrants expire today?"
        },
        {
            "id": "6665",
            "en_short": "<p>Yesterday, the Chinese central bank known as the People&rsquo;s Bank of China (PBOC) made an abrupt withdrawal of USD12 billion from the banking system, as PBOC advisor Ma Jun highlighted the central bank&rsquo;s concern of an asset bubble forming. The move dealt a huge blow to Hong Kong stocks which has been on an 8% rally this month, as mainland investors bought more than HKD250 million worth of stocks this year alone. (Bloomberg)<br /><br />The Hang Seng Index (HSI) futures, which is currently on its biggest rally since 1985, skidded 2.1% yesterday on the news. The pullback also came after the index reached the 30,000 resistance level. Those who were bearish on the HSI beyond the 30,000 level and had bought into the HSI put EEKW on Monday, would have seen 1-day gains of +39.3% alongside the -2.1% drop in the HSI January futures yesterday&hellip;</p>",
            "en_title": "HSI – loses steam on China’s surprise cut-back on liquidity",
            "date": "27 Jan 21",
            "thai_short": "<p>Yesterday, the Chinese central bank known as the People&rsquo;s Bank of China (PBOC) made an abrupt withdrawal of USD12 billion from the banking system, as PBOC advisor Ma Jun highlighted the central bank&rsquo;s concern of an asset bubble forming. The move dealt a huge blow to Hong Kong stocks which has been on an 8% rally this month, as mainland investors bought more than HKD250 million worth of stocks this year alone. (Bloomberg)<br /><br />The Hang Seng Index (HSI) futures, which is currently on its biggest rally since 1985, skidded 2.1% yesterday on the news. The pullback also came after the index reached the 30,000 resistance level. Those who were bearish on the HSI beyond the 30,000 level and had bought into the HSI put EEKW on Monday, would have seen 1-day gains of +39.3% alongside the -2.1% drop in the HSI January futures yesterday&hellip;</p>",
            "thai_title": "HSI – loses steam on China’s surprise cut-back on liquidity"
        },
        {
            "id": "6661",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\">After trading to the 3,000 mark last week, the Straits Times Index saw two days of losses as investors cashed in on gains from US President Biden&rsquo;s inauguration. In the following days, trading should receive guidance from the busy schedule of earnings results.<br /><br />Notable results would include Mapletree Commercial Trust (MCT) on Wednesday and 18 other real estate investment trusts&nbsp;(REITs). (Straits Times) Gain exposure to moves ahead of the earning reports via MCT AREIT warrants today!</span></span></span></p>",
            "en_title": "STI – retreats from 3,000 level; earnings update",
            "date": "26 Jan 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\">After trading to the 3,000 mark last week, the Straits Times Index saw two days of losses as investors cashed in on gains from US President Biden&rsquo;s inauguration. In the following days, trading should receive guidance from the busy schedule of earnings results.<br /><br />Notable results would include Mapletree Commercial Trust (MCT) on Wednesday and 18 other real estate investment trusts&nbsp;(REITs). (Straits Times) Gain exposure to moves ahead of the earning reports via MCT AREIT warrants today!</span></span></span></p>",
            "thai_title": "STI – retreats from 3,000 level; earnings update"
        },
        {
            "id": "6657",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Last week, UOB&rsquo;s asset management arm completed the acquisition of VAM Vietnam Fund Management Joint Stock Company (VAM VN), which looks to ride on the booming growth in Vietnam&rsquo;s mutual funds. Between 2016 to end-September 2020, Vietnam&rsquo;s onshore mutual funds saw a compounded annual growth rate of more than 75% in assets under management. (The Asset) The acquisition adds to UOB&rsquo;s regional network as the firm expands its reach across 9 markets in Asia.<br /><br />Find out more on UOB&rsquo;s plans and outlook for 2021 in the excerpts from a Macquarie Research (MQ) note published on 22 January 2021</span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">&hellip;</span></span></span></span></p>",
            "en_title": "UOB – further confidence on the asset quality outlook",
            "date": "25 Jan 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\">Last week, UOB&rsquo;s asset management arm completed the acquisition of VAM Vietnam Fund Management Joint Stock Company (VAM VN), which looks to ride on the booming growth in Vietnam&rsquo;s mutual funds. Between 2016 to end-September 2020, Vietnam&rsquo;s onshore mutual funds saw a compounded annual growth rate of more than 75% in assets under management. (The Asset) The acquisition adds to UOB&rsquo;s regional network as the firm expands its reach across 9 markets in Asia.<br /><br />Find out more on UOB&rsquo;s plans and outlook for 2021 in the excerpts from a Macquarie Research (MQ) note published on 22 January 2021</span></span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Arial&quot;,sans-serif\"><span style=\"color:black\">&hellip;</span></span></span></span></p>",
            "thai_title": "UOB – further confidence on the asset quality outlook"
        },
        {
            "id": "6653",
            "en_short": "<p>The recent months have been filled with large market moves from the US election and optimism from the vaccine roll-out. During these volatile periods, the huge price fluctuations in the underlying can cause the dynamics of a warrant to alter.<br /><br />Understand how such situations influences a warrant&rsquo;s delta and why call warrants are back on tight spreads following the recent rally&hellip;</p>",
            "en_title": "Why is “delta” important in volatile market",
            "date": "22 Jan 21",
            "thai_short": "<p>The recent months have been filled with large market moves from the US election and optimism from the vaccine roll-out. During these volatile periods, the huge price fluctuations in the underlying can cause the dynamics of a warrant to alter.<br /><br />Understand how such situations influences a warrant&rsquo;s delta and why call warrants are back on tight spreads following the recent rally&hellip;</p>",
            "thai_title": "Why is “delta” important in volatile market"
        },
        {
            "id": "6649",
            "en_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\">Following the pandemic fallout, crude oil saw the longest run of gains in 2 years as prices reached a 11-month high. The momentum appears to stay strong, as an output cut in Saudi Arabia at the start of the year sent prices 12% higher.<br /><br />Oil-related names benefited from the rise in oil prices, as offshore and marine name Keppel Corp as well as palm oil giant Wilmar International advanced +5.9% and +14.8% year-to-date respectively. Alongside the moves, Keppel Corp call warrant <a href=\"https://www.warrants.com.sg/tools/livematrix/WFEW\" style=\"color:blue; text-decoration:underline\">WFEW</a> has gained +19.7% while Wilmar call warrant <a href=\"https://www.warrants.com.sg/tools/livematrix/NLGW\" style=\"color:blue; text-decoration:underline\">NLGW</a> gained +47.4% year to date...</span></span></span></p>",
            "en_title": "KepCorp &amp; Wilmar – crude oil’s longest rally in 2 years to boost oil-related names",
            "date": "21 Jan 21",
            "thai_short": "<p><span style=\"font-size:11.0pt\"><span style=\"line-height:107%\"><span style=\"font-family:&quot;Calibri&quot;,sans-serif\">Following the pandemic fallout, crude oil saw the longest run of gains in 2 years as prices reached a 11-month high. The momentum appears to stay strong, as an output cut in Saudi Arabia at the start of the year sent prices 12% higher.<br /><br />Oil-related names benefited from the rise in oil prices, as offshore and marine name Keppel Corp as well as palm oil giant Wilmar International advanced +5.9% and +14.8% year-to-date respectively. Alongside the moves, Keppel Corp call warrant <a href=\"https://www.warrants.com.sg/tools/livematrix/WFEW\" style=\"color:blue; text-decoration:underline\">WFEW</a> has gained +19.7% while Wilmar call warrant <a href=\"https://www.warrants.com.sg/tools/livematrix/NLGW\" style=\"color:blue; text-decoration:underline\">NLGW</a> gained +47.4% year to date...</span></span></span></p>",
            "thai_title": "KepCorp &amp; Wilmar – crude oil’s longest rally in 2 years to boost oil-related names"
        },
        {
            "id": "6645",
            "en_short": "<p>Singapore&rsquo;s giant property developer CapitaLand, has rallied 38% since November 2020, outperforming the benchmark STI&rsquo;s 23.6% gain. CapitaLand&rsquo;s exuberant display has come against a backdrop of persistently low interest rates and optimism of a macroeconomic rebound, both of which will lend support to rising home prices.</p><p>Other developer peers like City Development and UOL Group, have lagged CapitaLand&rsquo;s share price over the same period, rising in line with the benchmark index with a 24.4% and 27.7% gain since November 2020 respectively.<br /><br />Looking beyond developers, REITs such as AREIT have lagged CapitaLand even further, rising just 7% from November 2020.<br /><br />What exactly is underpinning the share price performance difference within the property sector and these names in particular? &nbsp;</p>",
            "en_title": "CapitaLand – a standout performer relative to developer peers and REITs",
            "date": "20 Jan 21",
            "thai_short": "<p>Singapore&rsquo;s giant property developer CapitaLand, has rallied 38% since November 2020, outperforming the benchmark STI&rsquo;s 23.6% gain. CapitaLand&rsquo;s exuberant display has come against a backdrop of persistently low interest rates and optimism of a macroeconomic rebound, both of which will lend support to rising home prices.</p><p>Other developer peers like City Development and UOL Group, have lagged CapitaLand&rsquo;s share price over the same period, rising in line with the benchmark index with a 24.4% and 27.7% gain since November 2020 respectively.<br /><br />Looking beyond developers, REITs such as AREIT have lagged CapitaLand even further, rising just 7% from November 2020.<br /><br />What exactly is underpinning the share price performance difference within the property sector and these names in particular? &nbsp;</p>",
            "thai_title": "CapitaLand – a standout performer relative to developer peers and REITs"
        }
    ],
    "noOfPage": 261
}

export default dailyhigh;