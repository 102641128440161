import React, { Component } from "react";
import AmCharts from "@amcharts/amcharts3-react";
import { Link } from "react-router-dom";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import LiveMatrix from "../../../components/liveMatrix";
import MoneyFlow from "../../../components/moneyFlow";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./turnoverdist.css";

export default class TurnoverDist extends Component {
  constructor() {
    super();
    this.state = {
      turnoverdistData: null,
    };
  }

  componentDidMount() {
    this.onFetchData();
    document.title = "Turnover distribution | Singapore Warrants | Macquarie"
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("TurnoverDist");
    if (staticData !== null) {
      this.setState({ turnoverdistData: staticData });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("TurnoverDist")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let turnoverdistData = null;
    const url = MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=turnoverdist";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          turnoverdistData = result;
          this.setState({ turnoverdistData });
        },
        (error) => {
          this.setState({ turnoverdistData });
        }
      );
  }

  render() {
    return (
      <div id="turnoverdist" className="pageobj">
        <BreadcrumbBar
          link="/home/warrantstats"
          group="Warrant statistics"
          title="Turnover distribution"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="topimg hidden-sm hidden-xs">
            <img src="/img/bg/top-turnoverdist.jpg" alt="" />
          </div>
          <div className="row">
            <div className="col-md-8 turnoverdist_left" style={{ paddingLeft: 0 }}>
              <DistributionSection
                turnoverdistData={this.state.turnoverdistData}
              />
            </div>
            <div className="hidden-xs hidden-sm col-md-4" style={{ padding: 0 }}>
              <LiveMatrix />
              <AdvertBox />
              {/* <MoneyFlow /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class DistributionTable extends Component {
  getHeaders() {
    const columns = [];
    const statesDateList = this.props.statesDateList;
    statesDateList.forEach((date, index) => {
      const DATEMAXLEN = 6;
      const dateXS =
        date.length > DATEMAXLEN ? date.substring(0, DATEMAXLEN) : date;
      const column = (
        <th className={"date_" + index + " hidden-xs"} key={"date_" + index}>
          {date}
        </th>
      );
      const columnXS = (
        <th
          className={"date_" + index + " visible-xs"}
          key={"date_xs_" + index}
        >
          {dateXS}
        </th>
      );
      columns.push(column);
      columns.push(columnXS);
    });
    if (columns.length > 0) {
      columns.splice(0, 0, <th key="Underlying">Underlying</th>);
    }
    return <tr>{columns}</tr>;
  }

  getRows() {
    const rows = [];
    const statesDateList = this.props.statesDateList;
    const content = this.props.content;
    if (statesDateList.length > 0 && content.length > 0) {
      content.forEach((data, index) => {
        const columns = [];
        const underlyingticker = MQSGUtil.getData(data, "underlying_ticker");
        const underlyingric = MQSGUtil.getData(data, "underlying_ric");
        const underlyingname = MQSGUtil.getData(data, "underlying_name");
        const symbol = MQSGUtil.getData(data, "symbol");
        let displayname = MQSGUtil.getUnderlyingTickerDisplayName(
          underlyingric,
          underlyingticker,
        );
        if (displayname === null) {
          displayname = underlyingname;
        }
        const columnTicker = (
          <td key={underlyingticker + "_" + index}>
            <Link
              className="underlying_ticker"
              to={"/tools/underlying/" + symbol}
            >
              {displayname}
            </Link>
          </td>
        );
        columns.push(columnTicker);
        statesDateList.forEach((date, columnIndex) => {
          const turnover = MQSGUtil.getData(data, date) + "%";
          const className = "date_val_" + columnIndex;
          const key = underlyingticker + "_" + className;
          const column = (
            <td className={className} key={key}>
              {turnover}
            </td>
          );
          columns.push(column);
        });
        const row = (
          <tr className="rowtmpl" idx={index} key={underlyingticker}>
            {columns}
          </tr>
        );
        rows.push(row);
      });
    }
    return rows;
  }

  render() {
    return (
      <>
        <div className="tblh">
          <div className="tblh-left">
            <h2 className="small">Top traded underlyings</h2>
          </div>
        </div>
        <div id="topTbl" style={{ position: "relative", overflowX: "scroll" }}>
          <table className="table table-striped table-closed turnoverTable">
            <thead>{this.getHeaders()}</thead>
            <tbody>{this.getRows()}</tbody>
          </table>
        </div>
      </>
    );
  }
}

class DistributionChart extends Component {
  parseChartData() {
    const chartdata = { dataProvider: [] };
    const MAXUNDERLYINGUSE = 5;
    const content = this.props.content;
    const statsDateList = this.props.statesDateList;
    if (content.length > 0 && statsDateList.length > 0) {
      const targetDate = statsDateList[0];
      let acc = 0;
      let underlyingCount = 0;
      content.forEach((data) => {
        const underlyingticker = MQSGUtil.getData(data, "underlying_ticker");
        const underlyingric = MQSGUtil.getData(data, "underlying_ric");
        const underlyingname = MQSGUtil.getData(data, "underlying_name");
        let displayname = MQSGUtil.getUnderlyingTickerDisplayName(
          underlyingric,
          underlyingticker,
        );
        if (displayname === null) {
          displayname = underlyingname;
        }
        const turnover = MQSGUtil.getNumber(data, targetDate, 0);
        if (turnover > 0) {
          if (underlyingCount < MAXUNDERLYINGUSE) {
            acc += turnover;
            underlyingCount += 1;
            chartdata.dataProvider.push({
              underlying: displayname,
              value: turnover,
            });
          } else if (underlyingCount === MAXUNDERLYINGUSE) {
            underlyingCount += 1;
            chartdata.dataProvider.push({
              underlying: "Others",
              value: 100.0 - acc,
            });
          }
        }
      });
    }
    return chartdata;
  }

  legend(chartData) {
    const legends = [];
    const dataProvider = MQSGUtil.getData(chartData, "dataProvider", null);
    if (dataProvider !== null) {
      dataProvider.forEach((dataPoint, index) => {
        const legend = (
          <div className="chartbox-legend-item" key={"legend_" + index}>
            <span className={"chartbox-legend-square cbg0" + index}></span>
            <span className={"chartbox-legend-txt lgnm_" + index}>
              {dataPoint.underlying}
            </span>
          </div>
        );
        legends.push(legend);
      });
    }
    const hiddenXS = <div className="chartbox-legend hidden-xs">{legends}</div>;
    const visibleXSRow1 = [];
    const visibleXSRow2 = [];
    legends.forEach((legend, index) => {
      const key = "visible_xs_column_" + index;
      if (index < 3) {
        visibleXSRow1.push(<td key={key}>{legend}</td>);
      } else {
        visibleXSRow2.push(<td key={key}>{legend}</td>);
      }
    });
    const visibleXS = (
      <div className="chartbox-legend-xs visible-xs">
        <table>
          <tbody>
            <tr>{visibleXSRow1}</tr>
            <tr>{visibleXSRow2}</tr>
          </tbody>
        </table>
      </div>
    );
    return (
      <>
        {hiddenXS}
        {visibleXS}
      </>
    );
  }

  chart(chartData) {
    if (chartData === null) {
      return (
        <div className="nochart">
          <span className="nochart-txt">No related data</span>
        </div>
      );
    }
    const options = {
      type: "pie",
      labelRadius: -35,
      marginBottom: 0,
      marginTop: 0,
      radius: "42%",
      labelText: "[[percents]]%",
      balloonText: "[[title]]:[[percents]]%",
      startDuration: 0,
      percentPrecision: 1,
      hideLabelsPercent: 5,
      pullOutRadius: "0",
      pullOutDuration: 0,
      colors: [
        "#0675C2",
        "#00A1E0",
        "#6CC263",
        "#F7941E",
        "#FECC0A",
        "#C61C2D",
      ],
      titleField: "underlying",
      valueField: "value",
      allLabels: [],
      balloon: {},
      color: "#FFFFFF",
      titles: [],
      dataProvider: chartData.dataProvider,
    };
    const style = {
      width: "100%",
      height: "370px",
    };
    return (
      <div id="turnoverdistChart" className="chartbox-pie">
        <AmCharts.React style={style} options={options} />
      </div>
    );
  }

  render() {
    const chartData = this.parseChartData();
    const legend = this.legend(chartData);
    const chart = this.chart(chartData);
    return (
      <>
        {legend}
        {chart}
      </>
    );
  }
}

class DistributionSection extends Component {
  getStatsDateList() {
    let statesDateList = [];
    const turnoverdistData = this.props.turnoverdistData;
    if (turnoverdistData !== null && "turnoverdist" in turnoverdistData) {
      statesDateList = MQSGUtil.getData(
        turnoverdistData.turnoverdist,
        "stats_date_list",
        null
      );
      if (statesDateList !== null && Array.isArray(statesDateList)) {
        return statesDateList;
      }
      statesDateList = [];
    }
    return statesDateList;
  }

  getTargetDate() {
    let targetDate = "";
    const statesDateList = this.getStatsDateList();
    if (statesDateList.length > 0) {
      return statesDateList[0];
    }
    return targetDate;
  }

  getContent() {
    let content = [];
    const turnoverdistData = this.props.turnoverdistData;
    if (turnoverdistData !== null && "turnoverdist" in turnoverdistData) {
      content = MQSGUtil.getData(
        turnoverdistData.turnoverdist,
        "content",
        null
      );
      if (content !== null && Array.isArray(content)) {
        return content;
      }
      content = [];
    }
    return content;
  }

  render() {
    const targetDate = this.getTargetDate();

    return (
      <div className="section">
        <div className="page-header">
          <h2>Turnover distribution</h2>
        </div>
        <p>
          The most popular single stock underlyings in the warrant market for
          the day.
        </p>
        <div className="chartbox">
          <h2 className="chartdate small">
            {" "}
            Today's underlying - <span>{targetDate}</span>
          </h2>
          <DistributionChart
            content={this.getContent()}
            statesDateList={this.getStatsDateList()}
          />
        </div>
        <DistributionTable
          content={this.getContent()}
          statesDateList={this.getStatsDateList()}
        />
        <hr className="hr-hmgbm" />
      </div>
    );
  }
}
