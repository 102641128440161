import React, { Component } from 'react'
import BreadcrumbBar from '../../../components/breadcrumbBar'
import Videos from './videos'

export default class WarrantVideos extends Component {
    componentDidMount() {
        document.title = "Warrant Videos | Singapore Warrants | Macquarie"
    }
    render() {
        return (
            <div id="dwvideo" className="pageobj">
                <BreadcrumbBar link="/home/edu" group="Warrant education" title="Educational videos" />
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="section">
                        <div className="page-header">
                            <h2>Educational videos</h2>
                        </div>
                        <p className="section-p">
                            Learn about structured warrants with these bite-sized animated videos! In these videos, we will provide an introduction on structured warrants and the things to know when trading or investing in warrants.
                        </p>
                        <Videos />
                    </div>
                </div>
            </div>
        )
    }
}
