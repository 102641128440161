import React, { Component } from 'react'
import BreadcrumbBar from '../../../components/breadcrumbBar'
import Videos from './videos'

export default class WarrantVideos extends Component {
    componentDidMount() {
        document.title = "Warrant Videos | Singapore Warrants | Macquarie"
    }
    render() {
        return (
            <div id="dwvideo" className="pageobj">
                <BreadcrumbBar link="/home/edu" group="Warrant education" title="Educational 1-minute videos" />
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="section">
                        <div className="page-header">
                            <h2>Macquarie Warrants 1-minute Video Playlists</h2>
                        </div>
                        <p className="section-p">
                            Do you have a question about structured warrants? Get your answers from these 1-minute videos.
                        </p>
                        <Videos />
                    </div>
                </div>
            </div>
        )
    }
}
