const data1 = {
	"AEML_tk.SI": {
		"underlying_ric": "AEM.SI",
		"b_trbc_business": null,
		"theta": "-3.75",
		"LOLIMIT": "0",
		"TRDPRC_1": "0.034",
		"percent_moneyness": "0",
		"last_trading_date": "23 Feb 21",
		"HIGH_1": "0.068",
		"type": "CALL",
		"effective_gearing": "10.4",
		"conv_ratio": "10.0",
		"wrnt_per_share": "0.100",
		"BID_PCTCHNG_2DP": "-17.86",
		"LOW_1": "0.011",
		"30D_LOW": "-",
		"ticker": "983W",
		"breakeven_price": "4.73",
		"underlying_pctchng": "-1.53",
		"delta_per_wrnt": "5.313",
		"warrant_price": 0.023,
		"TURNOVER_SCALED": "0",
		"OPEN_PRC": "0.049",
		"NO_BIDMKR2": "-",
		"dsply_nmll": "AEM MB eCW210302",
		"issuer_name": "Macquarie Bank Ltd",
		"sensitivity": "1.88",
		"delta": "53.1",
		"TRADE_DATE": "16 Feb 21",
		"NO_BIDMKR2_SCALED": "-",
		"underlying_price_adj": 4.52,
		"BID_PCTCHNG": "-17.9",
		"BIDSIZE": "940,000",
		"underlying_price_cal": 4.52,
		"exercise_price_adj": 4.5,
		"update_time": "17 Feb 21 16:43",
		"dsply_name": "AEM MB eCW210302",
		"YRHIGH": "-",
		"BEST_BSIZ1": "940000",
		"HST_CLOSE": "0.028",
		"ACVOL_1_SCALED": "0",
		"BID": "0.023",
		"r_hst_close": 1,
		"maturity": "02 Mar 21",
		"div_yield": 1.883117,
		"underlying_bid": 4.51,
		"fmt": 1,
		"30D_HIGH": "-",
		"SYMBOL": "AEML_tk.SI",
		"HST_VOL_SCALED": "-",
		"underlying_price": "4.52",
		"list_date": "24 Sep 20",
		"underlying_curr": "SGD",
		"exchange_code": "SES",
		"ASK": "0.027",
		"currency": "SGD",
		"conv_ratio_cal": 10.0,
		"UPLIMIT": "0",
		"BID_NETCHNG": "-0.005",
		"NETCHNG_1": "0.0",
		"HST_VOL": "-",
		"implied_volatility": "66.5",
		"VOL_PCTCHNG": "-",
		"TRDTIM_1": "  :",
		"moneyness": "1",
		"ASKSIZE": "790,000",
		"premium": "4.6",
		"SERVICE": "ELEKTRON_DD",
		"underlying_ticker": "AWX",
		"intrinsic_value": "0.002",
		"exercise_price_cal": 4.5,
		"issuer_code": "MBL",
		"REC_STATUS": "0",
		"underlying_name": "AEM HOLDINGS LTD",
		"TURNOVER": "0",
		"PCTCHNG": "0.0",
		"YRLOW": "-",
		"BEST_ASIZ1": "790000",
		"ACVOL_1": "0",
		"days_to_maturity": "13",
		"ric": "AEML_tk.SI",
		"exercise_price": "4.50"
	},
	"systemtime": "17 Feb 21 16:43"
}

const symbols = {
  "symbols": [
    {
      "ric": "AEML_tk.SI",
      "name": "AEM MB eCW210302",
      "security_code": "983W"
    },
    {
      "ric": "AEML_tm.SI",
      "name": "AEM MB eCW210917",
      "security_code": "VKEW"
    },
    {
      "ric": "ALML_th.SI",
      "name": "Alibaba MB eCW210705",
      "security_code": "R42W"
    },
    {
      "ric": "ALML_tg.SI",
      "name": "Alibaba MB ePW210705",
      "security_code": "QBDW"
    },
    {
      "ric": "ALML_tf.SI",
      "name": "AlibabaMBeCW210405",
      "security_code": "1M7W"
    },
    {
      "ric": "ALML_te.SI",
      "name": "AlibabaMBePW210405",
      "security_code": "X7RW"
    },
    {
      "ric": "AEML_tj.SI",
      "name": "AscendasReit MBeCW210302",
      "security_code": "WQ6W"
    },
    {
      "ric": "AEML_tl.SI",
      "name": "AscendasReit MBeCW210524",
      "security_code": "RQOW"
    },
    {
      "ric": "BYML_td.SI",
      "name": "BYD MB eCW210302",
      "security_code": "XATW"
    },
    {
      "ric": "BYML_tb.SI",
      "name": "BYD MB eCW210405",
      "security_code": "VHUW"
    },
    {
      "ric": "BYML_te.SI",
      "name": "BYD MB eCW210705",
      "security_code": "TMHW"
    },
    {
      "ric": "BYML_tg.SI",
      "name": "BYD MB eCW210705A",
      "security_code": "MGVW"
    },
    {
      "ric": "BYML_tc.SI",
      "name": "BYD MB ePW210302",
      "security_code": "NYBW"
    },
    {
      "ric": "BYML_ta.SI",
      "name": "BYD MB ePW210405",
      "security_code": "RUQW"
    },
    {
      "ric": "BYML_tf.SI",
      "name": "BYD MB ePW210705",
      "security_code": "YBMW"
    },
    {
      "ric": "BYML_th.SI",
      "name": "BYD MB ePW210705A",
      "security_code": "VRJW"
    },
    {
      "ric": "CAML_t2.SI",
      "name": "CapitalaMBeCW210222",
      "security_code": "WU1W"
    },
    {
      "ric": "CAML_t3.SI",
      "name": "CapitalaMBeCW210304",
      "security_code": "HUFW"
    },
    {
      "ric": "CAML_t4.SI",
      "name": "CapitalaMBeCW211018",
      "security_code": "MHJW"
    },
    {
      "ric": "CTML_tt.SI",
      "name": "CityDev MB eCW210507",
      "security_code": "LQXW"
    },
    {
      "ric": "CMML_t1.SI",
      "name": "ComfortDel MBeCW210301",
      "security_code": "XLJW"
    },
    {
      "ric": "CMML_t2.SI",
      "name": "ComfortDel MBeCW211018",
      "security_code": "MSHW"
    },
    {
      "ric": "DBML_t30.SI",
      "name": "DBS MB eCW210405",
      "security_code": "WRAW"
    },
    {
      "ric": "DBML_t31.SI",
      "name": "DBS MB eCW210407",
      "security_code": "EYOW"
    },
    {
      "ric": "DBML_t38.SI",
      "name": "DBS MB eCW210503",
      "security_code": "HRZW"
    },
    {
      "ric": "DBML_t33.SI",
      "name": "DBS MB eCW211001",
      "security_code": "9WJW"
    },
    {
      "ric": "DBML_t34.SI",
      "name": "DBS MB eCW211008",
      "security_code": "OFHW"
    },
    {
      "ric": "DBML_t29.SI",
      "name": "DBS MB ePW210217",
      "security_code": "XPUW"
    },
    {
      "ric": "DBML_t32.SI",
      "name": "DBS MB ePW210701",
      "security_code": "YY9W"
    },
    {
      "ric": "DBML_t37.SI",
      "name": "DBS MB ePW210705",
      "security_code": "QYLW"
    },
    {
      "ric": "DBML_t36.SI",
      "name": "DBS MB ePW211001",
      "security_code": "KEDW"
    },
    {
      "ric": "DBML_t35.SI",
      "name": "DBS MB ePW211008",
      "security_code": "RWXW"
    },
    {
      "ric": "GEML_tb.SI",
      "name": "Geely MB eCW210405",
      "security_code": "RQUW"
    },
    {
      "ric": "GEML_td.SI",
      "name": "Geely MB eCW210705",
      "security_code": "RUHW"
    },
    {
      "ric": "GEML_te.SI",
      "name": "Geely MB eCW211005",
      "security_code": "XQDW"
    },
    {
      "ric": "GEML_ta.SI",
      "name": "Geely MB ePW210405",
      "security_code": "XZ4W"
    },
    {
      "ric": "GEML_tc.SI",
      "name": "Geely MB ePW210705",
      "security_code": "OCCW"
    },
    {
      "ric": "GEML_tf.SI",
      "name": "Geely MB ePW211005",
      "security_code": "VZGW"
    },
    {
      "ric": "GTML_te.SI",
      "name": "GentingSMBeCW210701",
      "security_code": "XJLW"
    },
    {
      "ric": "HSML_t210.SI",
      "name": "HSI 24800MBePW210528",
      "security_code": "TPCW"
    },
    {
      "ric": "HSML_t189.SI",
      "name": "HSI 25000MBePW210225",
      "security_code": "VOIW"
    },
    {
      "ric": "HSML_t204.SI",
      "name": "HSI 25400MBePW210429",
      "security_code": "FMPW"
    },
    {
      "ric": "HSML_t197.SI",
      "name": "HSI 25800MBePW210330",
      "security_code": "KWBW"
    },
    {
      "ric": "HSML_t191.SI",
      "name": "HSI 26000MBePW210225",
      "security_code": "JXBW"
    },
    {
      "ric": "HSML_t205.SI",
      "name": "HSI 26200MBePW210429",
      "security_code": "TNSW"
    },
    {
      "ric": "HSML_t214.SI",
      "name": "HSI 26400MBePW210528",
      "security_code": "EGAW"
    },
    {
      "ric": "HSML_t193.SI",
      "name": "HSI 26600MBeCW210225",
      "security_code": "UCEW"
    },
    {
      "ric": "HSML_t209.SI",
      "name": "HSI 26600MBeCW210528",
      "security_code": "OKCW"
    },
    {
      "ric": "HSML_t196.SI",
      "name": "HSI 26600MBePW210330",
      "security_code": "NMKW"
    },
    {
      "ric": "HSML_t199.SI",
      "name": "HSI 27000MBePW210429",
      "security_code": "8XWW"
    },
    {
      "ric": "HSML_t195.SI",
      "name": "HSI 27200MBeCW210330",
      "security_code": "W6JW"
    },
    {
      "ric": "HSML_t192.SI",
      "name": "HSI 27400MBeCW210225",
      "security_code": "FCHW"
    },
    {
      "ric": "HSML_t208.SI",
      "name": "HSI 27400MBePW210330",
      "security_code": "EEKW"
    },
    {
      "ric": "HSML_t200.SI",
      "name": "HSI 27600MBeCW210429",
      "security_code": "GJDW"
    },
    {
      "ric": "HSML_t194.SI",
      "name": "HSI 28000MBeCW210330",
      "security_code": "OMCW"
    },
    {
      "ric": "HSML_t207.SI",
      "name": "HSI 28000MBePW210528",
      "security_code": "1HZW"
    },
    {
      "ric": "HSML_t190.SI",
      "name": "HSI 28200MBeCW210225",
      "security_code": "8Y4W"
    },
    {
      "ric": "HSML_t213.SI",
      "name": "HSI 28200MBePW210330",
      "security_code": "9PCW"
    },
    {
      "ric": "HSML_t201.SI",
      "name": "HSI 28400MBeCW210429",
      "security_code": "SGAW"
    },
    {
      "ric": "HSML_t206.SI",
      "name": "HSI 28600MBePW210429",
      "security_code": "CVCW"
    },
    {
      "ric": "HSML_t198.SI",
      "name": "HSI 28800MBeCW210330",
      "security_code": "QHEW"
    },
    {
      "ric": "HSML_t202.SI",
      "name": "HSI 29200MBeCW210429",
      "security_code": "DZBW"
    },
    {
      "ric": "HSML_t215.SI",
      "name": "HSI 29600MBeCW210330",
      "security_code": "5N8W"
    },
    {
      "ric": "HSML_t203.SI",
      "name": "HSI 30000MBeCW210429",
      "security_code": "XLDW"
    },
    {
      "ric": "HSML_t212.SI",
      "name": "HSI 30400MBeCW210330",
      "security_code": "HGKW"
    },
    {
      "ric": "HSML_t216.SI",
      "name": "HSI 30800MBeCW210429",
      "security_code": "X5OW"
    },
    {
      "ric": "HSML_t211.SI",
      "name": "HSI 31200MBeCW210528",
      "security_code": "Z7PW"
    },
    {
      "ric": "IFML_tu.SI",
      "name": "IFAST MB eCW210601",
      "security_code": "KTRW"
    },
    {
      "ric": "JDML_tb.SI",
      "name": "JD MB eCW210705",
      "security_code": "OPSW"
    },
    {
      "ric": "JDML_tc.SI",
      "name": "JD MB ePW210705",
      "security_code": "DVDW"
    },
    {
      "ric": "KPML_t8.SI",
      "name": "KepCorp MBeCW210222",
      "security_code": "SJOW"
    },
    {
      "ric": "KPML_t9.SI",
      "name": "KepCorpMBeCW210304",
      "security_code": "NMBW"
    },
    {
      "ric": "KPML_t10.SI",
      "name": "KepCorpMBeCW211018",
      "security_code": "WFEW"
    },
    {
      "ric": "MCML_ta.SI",
      "name": "MapleComTr MB eCW210302",
      "security_code": "9EFW"
    },
    {
      "ric": "MAML_t.SI",
      "name": "MapleComTr MB eCW211018",
      "security_code": "TRGW"
    },
    {
      "ric": "MEML_tb.SI",
      "name": "Meituan MB eCW210405",
      "security_code": "JAOW"
    },
    {
      "ric": "MEML_td.SI",
      "name": "Meituan MB eCW210705",
      "security_code": "YC8W"
    },
    {
      "ric": "MEML_ta.SI",
      "name": "Meituan MB ePW210405",
      "security_code": "YLAW"
    },
    {
      "ric": "MEML_tc.SI",
      "name": "Meituan MB ePW210705",
      "security_code": "MXTW"
    },
    {
      "ric": "NIML_t20.SI",
      "name": "NKY 19000MBePW210312",
      "security_code": "WG8W"
    },
    {
      "ric": "NIML_t27.SI",
      "name": "NKY 20000MBePW210611",
      "security_code": "HASW"
    },
    {
      "ric": "NIML_t19.SI",
      "name": "NKY 22000MBePW210312",
      "security_code": "UAFW"
    },
    {
      "ric": "NIML_t28.SI",
      "name": "NKY 23000MBePW210611",
      "security_code": "XDQW"
    },
    {
      "ric": "NIML_t16.SI",
      "name": "NKY 24000MBeCW210312",
      "security_code": "PJLW"
    },
    {
      "ric": "NIML_t22.SI",
      "name": "NKY 25000MBeCW210611",
      "security_code": "ZALW"
    },
    {
      "ric": "NIML_t21.SI",
      "name": "NKY 25000MBePW210312",
      "security_code": "VTWW"
    },
    {
      "ric": "NIML_t23.SI",
      "name": "NKY 26000MBePW210611",
      "security_code": "EOGW"
    },
    {
      "ric": "NIML_t17.SI",
      "name": "NKY 27000MBeCW210312",
      "security_code": "FIQW"
    },
    {
      "ric": "NIML_t24.SI",
      "name": "NKY 28000MBeCW210611",
      "security_code": "TCFW"
    },
    {
      "ric": "NIML_t25.SI",
      "name": "NKY 29000MBePW210611",
      "security_code": "9AFW"
    },
    {
      "ric": "NIML_t18.SI",
      "name": "NKY 30000MBeCW210312",
      "security_code": "YTXW"
    },
    {
      "ric": "NIML_t26.SI",
      "name": "NKY 31000MBeCW210611",
      "security_code": "LXLW"
    },
    {
      "ric": "OCML_t11.SI",
      "name": "OCBC Bk MB eCW210301",
      "security_code": "XV1W"
    },
    {
      "ric": "OCML_t18.SI",
      "name": "OCBC Bk MB eCW210303",
      "security_code": "JSYW"
    },
    {
      "ric": "OCML_t27.SI",
      "name": "OCBC Bk MB eCW210507",
      "security_code": "ZRDW"
    },
    {
      "ric": "OCML_t20.SI",
      "name": "OCBC Bk MB eCW210510",
      "security_code": "LQFW"
    },
    {
      "ric": "OCML_t22.SI",
      "name": "OCBC Bk MB eCW210514",
      "security_code": "GWWW"
    },
    {
      "ric": "OCML_t25.SI",
      "name": "OCBC Bk MB eCW211112",
      "security_code": "WZ1W"
    },
    {
      "ric": "OCML_t23.SI",
      "name": "OCBC Bk MB eCW211115",
      "security_code": "VYWW"
    },
    {
      "ric": "OCML_t17.SI",
      "name": "OCBC Bk MB ePW210217",
      "security_code": "IEIW"
    },
    {
      "ric": "OCML_t19.SI",
      "name": "OCBC Bk MB ePW210701",
      "security_code": "EUEW"
    },
    {
      "ric": "OCML_t21.SI",
      "name": "OCBC Bk MB ePW210705",
      "security_code": "9I5W"
    },
    {
      "ric": "OCML_t24.SI",
      "name": "OCBC Bk MB ePW211112",
      "security_code": "95JW"
    },
    {
      "ric": "OCML_t26.SI",
      "name": "OCBC Bk MB ePW211115",
      "security_code": "JWRW"
    },
    {
      "ric": "SXML_t20.SI",
      "name": "S&P 2700MBePW210319",
      "security_code": "IQZW"
    },
    {
      "ric": "SXML_t22.SI",
      "name": "S&P 2950MBePW210319",
      "security_code": "DOHW"
    },
    {
      "ric": "SXML_t24.SI",
      "name": "S&P 3000MBePW210618",
      "security_code": "XYPW"
    },
    {
      "ric": "SXML_t23.SI",
      "name": "S&P 3400MBePW210618",
      "security_code": "OKBW"
    },
    {
      "ric": "SXML_t26.SI",
      "name": "S&P 3800MBeCW210618",
      "security_code": "RUUW"
    },
    {
      "ric": "SXML_t21.SI",
      "name": "S&P 3950MBeCW210319",
      "security_code": "VQEW"
    },
    {
      "ric": "SXML_t25.SI",
      "name": "S&P 4200MBeCW210618",
      "security_code": "GTJW"
    },
    {
      "ric": "SNML_t2.SI",
      "name": "SGX MB eCW210315",
      "security_code": "9QJW"
    },
    {
      "ric": "SNML_t4.SI",
      "name": "SGX MB eCW211018",
      "security_code": "ESYW"
    },
    {
      "ric": "SNML_t1.SI",
      "name": "SGX MB ePW210315",
      "security_code": "RXPW"
    },
    {
      "ric": "SNML_t3.SI",
      "name": "SGX MB ePW211018",
      "security_code": "UJGW"
    },
    {
      "ric": "SLML_ta.SI",
      "name": "SIA MB eCW210701",
      "security_code": "GIJW"
    },
    {
      "ric": "SEML_t17.SI",
      "name": "SingtelMBeCW210222",
      "security_code": "YX7W"
    },
    {
      "ric": "SEML_t18.SI",
      "name": "SingtelMBeCW210304",
      "security_code": "GJAW"
    },
    {
      "ric": "SEML_t16.SI",
      "name": "SingtelMBeCW210331",
      "security_code": "YBNW"
    },
    {
      "ric": "SEML_t7.SI",
      "name": "SingtelMBeCW211018",
      "security_code": "59XW"
    },
    {
      "ric": "STML_t31.SI",
      "name": "STI 2300MBePW210331",
      "security_code": "WVEW"
    },
    {
      "ric": "STML_t35.SI",
      "name": "STI 2400MBePW210630",
      "security_code": "OSTW"
    },
    {
      "ric": "STML_t32.SI",
      "name": "STI 2500MBePW210331",
      "security_code": "DFAW"
    },
    {
      "ric": "STML_t42.SI",
      "name": "STI 2500MBePW210930",
      "security_code": "JUUW"
    },
    {
      "ric": "STML_t33.SI",
      "name": "STI 2550MBeCW210331",
      "security_code": "8C5W"
    },
    {
      "ric": "STML_t39.SI",
      "name": "STI 2600MBePW210630",
      "security_code": "VVZW"
    },
    {
      "ric": "STML_t34.SI",
      "name": "STI 2650MBeCW210630",
      "security_code": "XINW"
    },
    {
      "ric": "STML_t41.SI",
      "name": "STI 2750MBePW210930",
      "security_code": "JUPW"
    },
    {
      "ric": "STML_t29.SI",
      "name": "STI 2800MBeCW210331",
      "security_code": "URDW"
    },
    {
      "ric": "STML_t40.SI",
      "name": "STI 2800MBeCW210930",
      "security_code": "UNCW"
    },
    {
      "ric": "STML_t36.SI",
      "name": "STI 2800MBePW210630",
      "security_code": "YZHW"
    },
    {
      "ric": "STML_t37.SI",
      "name": "STI 2900MBeCW210630",
      "security_code": "PXKW"
    },
    {
      "ric": "STML_t30.SI",
      "name": "STI 3000MBeCW210331",
      "security_code": "VSBW"
    },
    {
      "ric": "STML_t45.SI",
      "name": "STI 3000MBePW210930",
      "security_code": "PCEW"
    },
    {
      "ric": "STML_t44.SI",
      "name": "STI 3050MBeCW210930",
      "security_code": "V6OW"
    },
    {
      "ric": "STML_t38.SI",
      "name": "STI 3100MBeCW210630",
      "security_code": "EZEW"
    },
    {
      "ric": "STML_t43.SI",
      "name": "STI 3300MBeCW210930",
      "security_code": "LZTW"
    },
    {
      "ric": "SUML_tb.SI",
      "name": "Sunny MB eCW210405",
      "security_code": "9T9W"
    },
    {
      "ric": "SUML_tc.SI",
      "name": "Sunny MB eCW210705",
      "security_code": "WAVW"
    },
    {
      "ric": "SUML_ta.SI",
      "name": "Sunny MB ePW210405",
      "security_code": "PDGW"
    },
    {
      "ric": "SUML_td.SI",
      "name": "Sunny MB ePW210705",
      "security_code": "8P7W"
    },
    {
      "ric": "TCML_t17.SI",
      "name": "TencentMBeCW210302",
      "security_code": "Q31W"
    },
    {
      "ric": "TCML_t20.SI",
      "name": "TencentMBeCW210705",
      "security_code": "PHDW"
    },
    {
      "ric": "TCML_t18.SI",
      "name": "TencentMBePW210302",
      "security_code": "WL7W"
    },
    {
      "ric": "TCML_t19.SI",
      "name": "TencentMBePW210705",
      "security_code": "WVDW"
    },
    {
      "ric": "TOML_tc.SI",
      "name": "Top Glove MB eCW210302",
      "security_code": "WHGW"
    },
    {
      "ric": "TOPG_ta.SI",
      "name": "Top Glove MB eCW210917",
      "security_code": "1P7W"
    },
    {
      "ric": "UOML_t24.SI",
      "name": "UOB MB eCW210405",
      "security_code": "SMOW"
    },
    {
      "ric": "UOML_t25.SI",
      "name": "UOB MB eCW210407",
      "security_code": "FSXW"
    },
    {
      "ric": "UOML_t35.SI",
      "name": "UOB MB eCW210503",
      "security_code": "V8DW"
    },
    {
      "ric": "UOML_t26.SI",
      "name": "UOB MB eCW210506",
      "security_code": "PWBW"
    },
    {
      "ric": "UOML_t34.SI",
      "name": "UOB MB eCW210514",
      "security_code": "ELVW"
    },
    {
      "ric": "UOML_t31.SI",
      "name": "UOB MB eCW211001",
      "security_code": "XIIW"
    },
    {
      "ric": "UOML_t28.SI",
      "name": "UOB MB eCW211004",
      "security_code": "KVKW"
    },
    {
      "ric": "UOML_t29.SI",
      "name": "UOB MB eCW211008",
      "security_code": "RABW"
    },
    {
      "ric": "UOML_t23.SI",
      "name": "UOB MB ePW210217",
      "security_code": "9KVW"
    },
    {
      "ric": "UOML_t27.SI",
      "name": "UOB MB ePW210701",
      "security_code": "X0RW"
    },
    {
      "ric": "UOML_t33.SI",
      "name": "UOB MB ePW210705",
      "security_code": "YWRW"
    },
    {
      "ric": "UOML_t32.SI",
      "name": "UOB MB ePW211001",
      "security_code": "VIFW"
    },
    {
      "ric": "UOML_t30.SI",
      "name": "UOB MB ePW211008",
      "security_code": "MPPW"
    },
    {
      "ric": "ULML_t.SI",
      "name": "UOL MB eCW210701",
      "security_code": "ORGW"
    },
    {
      "ric": "WLML_t8.SI",
      "name": "Wilmar MB eCW210222",
      "security_code": "NDHW"
    },
    {
      "ric": "WLML_t7.SI",
      "name": "Wilmar MB eCW210331",
      "security_code": "KSCW"
    },
    {
      "ric": "WLML_t9.SI",
      "name": "Wilmar MB eCW211018",
      "security_code": "NLGW"
    },
    {
      "ric": "XIML_t11.SI",
      "name": "XIAOMI MBeCW210405",
      "security_code": "FWDW"
    },
    {
      "ric": "XIML_t16.SI",
      "name": "XIAOMI MBeCW210705",
      "security_code": "WAIW"
    },
    {
      "ric": "XIML_t12.SI",
      "name": "XIAOMI MBePW210405",
      "security_code": "UDWW"
    },
    {
      "ric": "XIML_t15.SI",
      "name": "XIAOMI MBePW210705",
      "security_code": "UKKW"
    },
    {
      "ric": "YZML_tx.SI",
      "name": "YangzijiMBeCW211101",
      "security_code": "FOHW"
    }
  ]
}

const symbolsSimple = {
  "symbols": [
    {
      "ric": "AEML_tk.SI",
      "name": "AEM MB eCW210302",
      "security_code": "983W"
    }    
  ]
}

const calculatorData = {
  symbols: symbolsSimple,
  "AEML_tk.SI": data1
}

export default calculatorData;