
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class creditrisk extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>
                                                A holder of a structured warrant will be exposed to the credit risk of the issuer.<br /><br /> You can view Macquarie Group's most recent credit rating on our information page, <a href="https://www.macquarie.com/sg/about/investors/debt-investors/credit-ratings" style={{ color: 'red' }}>click here</a>.
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/creditrisk1.png" style={{ maxWidth: '180px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}