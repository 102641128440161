// 20210224100108
// https://www.warrants.com.sg/SymbolsJSON?type=u&qid=1614132041246

const symbols = {
  "symbols": [
    {
      "symbol": "AWX",
      "ric": "AEM.SI",
      "name": "AEM HOLDINGS LTD"
    },
    {
      "symbol": "A17U",
      "ric": "AEMN.SI",
      "name": "ASCEN REIT"
    },
    {
      "symbol": "BABA-SW",
      "ric": "9988.HK",
      "name": "BABA-SW"
    },
    {
      "symbol": "BYD COMPANY",
      "ric": "1211.HK",
      "name": "BYD COMPANY"
    },
    {
      "symbol": "C31",
      "ric": "CATL.SI",
      "name": "CAPLAND"
    },
    {
      "symbol": "C09",
      "ric": "CTDM.SI",
      "name": "CITY DEVELOPMENT"
    },
    {
      "symbol": "C52",
      "ric": "CMDG.SI",
      "name": "COMFORTDELGRO LD"
    },
    {
      "symbol": "D05",
      "ric": "DBSM.SI",
      "name": "DBS"
    },
    {
      "symbol": "GEELY AUTO",
      "ric": "0175.HK",
      "name": "GEELY AUTO"
    },
    {
      "symbol": "G13",
      "ric": "GENS.SI",
      "name": "GENTINGSIN"
    },
    {
      "symbol": "HSTECH",
      "ric": "HHTIc2",
      "name": "HANG SENG TECH INDEX"
    },
    {
      "symbol": "HSI",
      "ric": "HSIc2",
      "name": "HSI"
    },
    {
      "symbol": "AIY",
      "ric": "IFAS.SI",
      "name": "IFAST CORPORATIO"
    },
    {
      "symbol": "JD-SW",
      "ric": "9618.HK",
      "name": "JD-SW"
    },
    {
      "symbol": "BN4",
      "ric": "KPLM.SI",
      "name": "KEPCORP"
    },
    {
      "symbol": "N2IU",
      "ric": "MACT.SI",
      "name": "MAPLETREE COMMER"
    },
    {
      "symbol": "MEITUAN-W",
      "ric": "3690.HK",
      "name": "MEITUAN-W"
    },
    {
      "symbol": "N225",
      "ric": "SSIcv1",
      "name": "N225"
    },
    {
      "symbol": "O39",
      "ric": "OCBC.SI",
      "name": "OCBC"
    },
    {
      "symbol": "S&P500",
      "ric": "ESc1",
      "name": "S&P500"
    },
    {
      "symbol": "S68",
      "ric": "SGXL.SI",
      "name": "SGX"
    },
    {
      "symbol": "C6L",
      "ric": "SIAL.SI",
      "name": "SINGAPORE AIRLIN"
    },
    {
      "symbol": "Z74",
      "ric": "STEL.SI",
      "name": "SINGTEL"
    },
    {
      "symbol": "STI",
      "ric": ".STI",
      "name": "STI"
    },
    {
      "symbol": "SUNNY OPTICAL",
      "ric": "2382.HK",
      "name": "SUNNY OPTICAL"
    },
    {
      "symbol": "TENCENT",
      "ric": "0700.HK",
      "name": "TENCENT"
    },
    {
      "symbol": "BVA",
      "ric": "TOPG.SI",
      "name": "TOP GLOVE CORPOR"
    },
    {
      "symbol": "U11",
      "ric": "UOBH.SI",
      "name": "UOB"
    },
    {
      "symbol": "U14",
      "ric": "UTOS.SI",
      "name": "UOL GROUP LIMITE"
    },
    {
      "symbol": "F34",
      "ric": "WLIL.SI",
      "name": "WILMAR INTERNATI"
    },
    {
      "symbol": "XIAOMI-W",
      "ric": "1810.HK",
      "name": "XIAOMI-W"
    },
    {
      "symbol": "BS6",
      "ric": "YAZG.SI",
      "name": "YANGZIJIANG"
    }
  ]
}

const symbolsSample = {
    "symbols": [
        {
          "symbol": "AWX",
          "ric": "AEM.SI",
          "name": "AEM HOLDINGS LTD"
        },
        {
            "symbol": "BABA-SW",
            "ric": "9988.HK",
            "name": "BABA-SW"
        }        
    ]
}
// 20210224143603
// https://www.warrants.com.sg/ComparisonDataJSON?type=u&ric=AEM.SI&qid=1614148544607

const AWX = {
  "comparison": [
    {
      "BID": "4.06",
      "30D_HIGH": 4.710000000,
      "TRDPRC_1": "4.07",
      "SYMBOL": "AEM.SI",
      "ASK": "4.07",
      "30D_LOW": 3.230000000,
      "ticker": "AWX",
      "NETCHNG_1": "-0.22",
      "HST_VOL": 3366900.000000000,
      "VOL_PCTCHNG": -6.6166314423125,
      "NO_BIDMKR2": 3605460.000000000,
      "ASKSIZE": "25,100",
      "SERVICE": "ELEKTRON_DD",
      "OFFCL_NAME": "AEM HOLDINGS LTD",
      "TURNOVER": 27374636.000000000,
      "REC_STATUS": "0",
      "PCTCHNG": "-5.13",
      "BIDSIZE": "55,100",
      "YRLOW": 1.310000000,
      "VOL_RATIO": 0.933833686,
      "ACVOL_1": "6,643,800",
      "update_time": "24 Feb 21 14:31",
      "ric": "AEM.SI",
      "OFFCL_CODE": "AWX",
      "dsply_name": "AEM HOLDINGS LTD",
      "YRHIGH": 4.720000000,
      "HST_CLOSE": "4.29",
      "ACVOL_1_SCALED": "6,644"
    },
    {
      "underlying_ric": "AEM.SI",
      "b_trbc_business": null,
      "theta": "-29.03",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.011",
      "percent_moneyness": "10",
      "last_trading_date": "23 Feb 21",
      "HIGH_1": "0.068",
      "type": "CALL",
      "effective_gearing": "24.7",
      "conv_ratio": "10.0",
      "wrnt_per_share": "0.100",
      "LOW_1": "0.002",
      "BID_PCTCHNG_2DP": "-200.00",
      "breakeven_price": "4.52",
      "ticker": "983W",
      "30D_LOW": "-",
      "underlying_pctchng": "-5.13",
      "delta_per_wrnt": "1.216",
      "warrant_price": 0.002000000,
      "OPEN_PRC": "0.049",
      "TURNOVER_SCALED": "0",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "AEM MB eCW210302",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "8.23",
      "delta": "12.2",
      "TRADE_DATE": "22 Feb 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 4.070000000,
      "BID_PCTCHNG": "-200.0",
      "BIDSIZE": "0",
      "underlying_price_cal": 4.07,
      "exercise_price_adj": 4.500000000,
      "update_time": "24 Feb 21 14:35",
      "dsply_name": "AEM MB eCW210302",
      "YRHIGH": "-",
      "BEST_BSIZ1": "0",
      "HST_CLOSE": "0.002",
      "ACVOL_1_SCALED": "0",
      "BID": "0.000",
      "r_hst_close": 1,
      "maturity": "02 Mar 21",
      "div_yield": 2.009238000,
      "underlying_bid": 4.06,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "AEML_tk.SI",
      "underlying_price": "4.07",
      "HST_VOL_SCALED": "-",
      "list_date": "24 Sep 20",
      "exchange_code": "SES",
      "underlying_curr": "SGD",
      "currency": "SGD",
      "ASK": "0.000",
      "conv_ratio_cal": 10.000000000,
      "UPLIMIT": "0",
      "NETCHNG_1": "0.0",
      "BID_NETCHNG": "-0.004",
      "HST_VOL": "-",
      "implied_volatility": "66.4",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "2",
      "ASKSIZE": "0",
      "premium": "11.1",
      "SERVICE": "ELEKTRON_DD",
      "intrinsic_value": "0.000",
      "underlying_ticker": "AWX",
      "issuer_code": "MBL",
      "exercise_price_cal": 4.500000000,
      "TURNOVER": "0",
      "underlying_name": "AEM HOLDINGS LTD",
      "REC_STATUS": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "0",
      "ACVOL_1": "0",
      "days_to_maturity": "6",
      "ric": "AEML_tk.SI",
      "exercise_price": "4.50"
    },
    {
      "underlying_ric": "AEM.SI",
      "b_trbc_business": null,
      "theta": "-0.24",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.083",
      "percent_moneyness": "3",
      "last_trading_date": "10 Sep 21",
      "HIGH_1": "0.118",
      "type": "CALL",
      "effective_gearing": "2.5",
      "conv_ratio": "12.0",
      "wrnt_per_share": "0.083",
      "LOW_1": "0.059",
      "BID_PCTCHNG_2DP": "-11.83",
      "breakeven_price": "5.18",
      "ticker": "VKEW",
      "30D_LOW": "-",
      "underlying_pctchng": "-5.13",
      "delta_per_wrnt": "5.010",
      "warrant_price": 0.082000000,
      "OPEN_PRC": "0.068",
      "TURNOVER_SCALED": "19",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "AEM MB eCW210917",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "2.00",
      "delta": "60.1",
      "TRADE_DATE": "24 Feb 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 4.060000000,
      "BID_PCTCHNG": "-11.8",
      "BIDSIZE": "500,000",
      "underlying_price_cal": 4.07,
      "exercise_price_adj": 4.200000000,
      "update_time": "24 Feb 21 14:31",
      "dsply_name": "AEM MB eCW210917",
      "YRHIGH": "-",
      "BEST_BSIZ1": "500000",
      "HST_CLOSE": "0.093",
      "ACVOL_1_SCALED": "230",
      "BID": "0.082",
      "r_hst_close": 1,
      "maturity": "17 Sep 21",
      "div_yield": 2.009238000,
      "underlying_bid": 4.06,
      "fmt": 1,
      "30D_HIGH": "-",
      "link": true,
      "SYMBOL": "AEML_tm.SI",
      "underlying_price": "4.07",
      "HST_VOL_SCALED": "-",
      "list_date": "17 Dec 20",
      "exchange_code": "SES",
      "underlying_curr": "SGD",
      "currency": "SGD",
      "ASK": "0.084",
      "conv_ratio_cal": 12.000000048,
      "UPLIMIT": "0",
      "NETCHNG_1": "-0.0",
      "BID_NETCHNG": "-0.011",
      "HST_VOL": "-",
      "implied_volatility": "88.1",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "06:27",
      "moneyness": "2",
      "ASKSIZE": "500,000",
      "premium": "27.4",
      "SERVICE": "ELEKTRON_DD",
      "intrinsic_value": "0.000",
      "underlying_ticker": "AWX",
      "issuer_code": "MBL",
      "exercise_price_cal": 4.200000000,
      "TURNOVER": "19,490",
      "underlying_name": "AEM HOLDINGS LTD",
      "REC_STATUS": "0",
      "PCTCHNG": "-18.6",
      "YRLOW": "-",
      "BEST_ASIZ1": "500000",
      "ACVOL_1": "230,000",
      "days_to_maturity": "205",
      "ric": "AEML_tm.SI",
      "exercise_price": "4.20"
    }
  ]
}

const ALIBABA = {
  "comparison": [
    {
      "BID": "246.40",
      "30D_HIGH": null,
      "TRDPRC_1": "246.40",
      "SYMBOL": "9988.HK",
      "ASK": "246.60",
      "30D_LOW": null,
      "ticker": "9988",
      "NETCHNG_1": "-1.20",
      "HST_VOL": null,
      "VOL_PCTCHNG": null,
      "NO_BIDMKR2": null,
      "ASKSIZE": "36,900",
      "SERVICE": "ELEKTRON_DD",
      "OFFCL_NAME": "BABA-SW",
      "TURNOVER": 1217026226.000000000,
      "REC_STATUS": "0",
      "PCTCHNG": "-0.48",
      "BIDSIZE": "6,000",
      "YRLOW": null,
      "VOL_RATIO": null,
      "ACVOL_1": "3,639,118",
      "update_time": "24 Feb 21 10:18",
      "ric": "9988.HK",
      "OFFCL_CODE": "BABA-SW",
      "dsply_name": "BABA-SW",
      "YRHIGH": null,
      "HST_CLOSE": "247.60",
      "ACVOL_1_SCALED": "3,639"
    },
    {
      "underlying_ric": "9988.HK",
      "b_trbc_business": null,
      "theta": "-1.69",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.013",
      "percent_moneyness": "30",
      "last_trading_date": "26 Mar 21",
      "HIGH_1": "0.149",
      "type": "CALL",
      "effective_gearing": "20.1",
      "conv_ratio": "25.0",
      "wrnt_per_share": "0.040",
      "LOW_1": "0.001",
      "BID_PCTCHNG_2DP": "-100.00",
      "breakeven_price": "350.15",
      "ticker": "1M7W",
      "30D_LOW": "-",
      "underlying_pctchng": "-0.48",
      "delta_per_wrnt": "N/A",
      "warrant_price": 0.001000000,
      "OPEN_PRC": "0.124",
      "TURNOVER_SCALED": "0",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "AlibabaMBeCW210405",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "208.15",
      "delta": "1.2",
      "TRADE_DATE": "20 Jan 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 41.835160000,
      "BID_PCTCHNG": "-100.0",
      "BIDSIZE": "0",
      "underlying_price_cal": 246.4,
      "exercise_price_adj": 59.570000000,
      "update_time": "24 Feb 21 10:18",
      "dsply_name": "AlibabaMBeCW210405",
      "YRHIGH": "-",
      "BEST_BSIZ1": "0",
      "HST_CLOSE": "0.001",
      "ACVOL_1_SCALED": "0",
      "BID": "0.001",
      "r_hst_close": 0.1702000000,
      "maturity": "05 Apr 21",
      "div_yield": 0,
      "underlying_bid": 246.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "ALML_tf.SI",
      "underlying_price": "246.40",
      "HST_VOL_SCALED": "-",
      "list_date": "22 Oct 20",
      "exchange_code": "SES",
      "underlying_curr": "HKD",
      "currency": "SGD",
      "ASK": "0.005",
      "conv_ratio_cal": 25.000000000,
      "UPLIMIT": "0",
      "NETCHNG_1": "0.0",
      "BID_NETCHNG": "-0.001",
      "HST_VOL": "-",
      "implied_volatility": "45.6",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "2",
      "ASKSIZE": "100,000",
      "premium": "42.5",
      "SERVICE": "ELEKTRON_DD",
      "intrinsic_value": "0.000",
      "underlying_ticker": "BABA-SW",
      "issuer_code": "MBL",
      "exercise_price_cal": 350.000000000,
      "TURNOVER": "0",
      "underlying_name": "BABA-SW",
      "REC_STATUS": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "100000",
      "ACVOL_1": "0",
      "days_to_maturity": "40",
      "ric": "ALML_tf.SI",
      "exercise_price": "350.00"
    },
    {
      "underlying_ric": "9988.HK",
      "b_trbc_business": null,
      "theta": "-0.07",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.097",
      "percent_moneyness": "3",
      "last_trading_date": "28 Jun 21",
      "HIGH_1": "0.181",
      "type": "PUT",
      "effective_gearing": "3.8",
      "conv_ratio": "45.0",
      "wrnt_per_share": "0.022",
      "LOW_1": "0.071",
      "BID_PCTCHNG_2DP": "+3.09",
      "breakeven_price": "213.56",
      "ticker": "QBDW",
      "30D_LOW": "-",
      "underlying_pctchng": "-0.48",
      "delta_per_wrnt": "-0.893",
      "warrant_price": 0.099000000,
      "OPEN_PRC": "0.101",
      "TURNOVER_SCALED": "0",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "Alibaba MB ePW210705",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "11.20",
      "delta": "-40.2",
      "TRADE_DATE": "23 Feb 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 41.835160000,
      "BID_PCTCHNG": "+3.1",
      "BIDSIZE": "3,000,000",
      "underlying_price_cal": 246.4,
      "exercise_price_adj": 40.848000000,
      "update_time": "24 Feb 21 10:18",
      "dsply_name": "Alibaba MB ePW210705",
      "YRHIGH": "-",
      "BEST_BSIZ1": "3000000",
      "HST_CLOSE": "0.097",
      "ACVOL_1_SCALED": "0",
      "BID": "0.100",
      "r_hst_close": 0.1702000000,
      "maturity": "05 Jul 21",
      "div_yield": 0,
      "underlying_bid": 246.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "link": true,
      "SYMBOL": "ALML_tg.SI",
      "underlying_price": "246.40",
      "HST_VOL_SCALED": "-",
      "list_date": "17 Dec 20",
      "exchange_code": "SES",
      "underlying_curr": "HKD",
      "currency": "SGD",
      "ASK": "0.101",
      "conv_ratio_cal": 45.000000450,
      "UPLIMIT": "0",
      "NETCHNG_1": "0.0",
      "BID_NETCHNG": "+0.003",
      "HST_VOL": "-",
      "implied_volatility": "51.3",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "2",
      "ASKSIZE": "3,000,000",
      "premium": "13.3",
      "SERVICE": "ELEKTRON_DD",
      "intrinsic_value": "0.000",
      "underlying_ticker": "BABA-SW",
      "issuer_code": "MBL",
      "exercise_price_cal": 240.000000000,
      "TURNOVER": "0",
      "underlying_name": "BABA-SW",
      "REC_STATUS": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "3000000",
      "ACVOL_1": "0",
      "days_to_maturity": "131",
      "ric": "ALML_tg.SI",
      "exercise_price": "240.00"
    },
    {
      "underlying_ric": "9988.HK",
      "b_trbc_business": null,
      "theta": "-0.14",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.046",
      "percent_moneyness": "18",
      "last_trading_date": "28 Jun 21",
      "HIGH_1": "0.090",
      "type": "CALL",
      "effective_gearing": "6.4",
      "conv_ratio": "45.0",
      "wrnt_per_share": "0.022",
      "LOW_1": "0.021",
      "BID_PCTCHNG_2DP": "-6.52",
      "breakeven_price": "311.37",
      "ticker": "R42W",
      "30D_LOW": "-",
      "underlying_pctchng": "-0.48",
      "delta_per_wrnt": "0.657",
      "warrant_price": 0.043000000,
      "OPEN_PRC": "0.072",
      "TURNOVER_SCALED": "0",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "Alibaba MB eCW210705",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "15.22",
      "delta": "29.6",
      "TRADE_DATE": "24 Feb 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 41.835160000,
      "BID_PCTCHNG": "-6.5",
      "BIDSIZE": "3,000,000",
      "underlying_price_cal": 246.4,
      "exercise_price_adj": 51.060000000,
      "update_time": "24 Feb 21 10:18",
      "dsply_name": "Alibaba MB eCW210705",
      "YRHIGH": "-",
      "BEST_BSIZ1": "3000000",
      "HST_CLOSE": "0.046",
      "ACVOL_1_SCALED": "0",
      "BID": "0.043",
      "r_hst_close": 0.1702000000,
      "maturity": "05 Jul 21",
      "div_yield": 0,
      "underlying_bid": 246.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "link": true,
      "SYMBOL": "ALML_th.SI",
      "underlying_price": "246.40",
      "HST_VOL_SCALED": "-",
      "list_date": "17 Dec 20",
      "exchange_code": "SES",
      "underlying_curr": "HKD",
      "currency": "SGD",
      "ASK": "0.044",
      "conv_ratio_cal": 45.000000450,
      "UPLIMIT": "0",
      "NETCHNG_1": "+0.0",
      "BID_NETCHNG": "-0.003",
      "HST_VOL": "-",
      "implied_volatility": "47.6",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "01:54",
      "moneyness": "2",
      "ASKSIZE": "3,000,000",
      "premium": "26.4",
      "SERVICE": "ELEKTRON_DD",
      "intrinsic_value": "0.000",
      "underlying_ticker": "BABA-SW",
      "issuer_code": "MBL",
      "exercise_price_cal": 300.000000000,
      "TURNOVER": "18",
      "underlying_name": "BABA-SW",
      "REC_STATUS": "0",
      "PCTCHNG": "+2.2",
      "YRLOW": "-",
      "BEST_ASIZ1": "3000000",
      "ACVOL_1": "400",
      "days_to_maturity": "131",
      "ric": "ALML_th.SI",
      "exercise_price": "300.00"
    },
    {
      "underlying_ric": "9988.HK",
      "b_trbc_business": null,
      "theta": "-0.18",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.142",
      "percent_moneyness": "1",
      "last_trading_date": "26 Mar 21",
      "HIGH_1": "0.330",
      "type": "PUT",
      "effective_gearing": "7.2",
      "conv_ratio": "25.0",
      "wrnt_per_share": "0.040",
      "LOW_1": "0.066",
      "BID_PCTCHNG_2DP": "+5.26",
      "breakeven_price": "232.37",
      "ticker": "X7RW",
      "30D_LOW": "-",
      "underlying_pctchng": "-0.48",
      "delta_per_wrnt": "-2.033",
      "warrant_price": 0.118000000,
      "OPEN_PRC": "0.105",
      "TURNOVER_SCALED": "0",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "AlibabaMBePW210405",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "4.92",
      "delta": "-50.8",
      "TRADE_DATE": "22 Jan 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 41.835160000,
      "BID_PCTCHNG": "+5.3",
      "BIDSIZE": "1,000,000",
      "underlying_price_cal": 246.4,
      "exercise_price_adj": 42.550000000,
      "update_time": "24 Feb 21 10:18",
      "dsply_name": "AlibabaMBePW210405",
      "YRHIGH": "-",
      "BEST_BSIZ1": "1000000",
      "HST_CLOSE": "0.114",
      "ACVOL_1_SCALED": "0",
      "BID": "0.120",
      "r_hst_close": 0.1702000000,
      "maturity": "05 Apr 21",
      "div_yield": 0,
      "underlying_bid": 246.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "ALML_te.SI",
      "underlying_price": "246.40",
      "HST_VOL_SCALED": "-",
      "list_date": "22 Oct 20",
      "exchange_code": "SES",
      "underlying_curr": "HKD",
      "currency": "SGD",
      "ASK": "0.122",
      "conv_ratio_cal": 25.000000000,
      "UPLIMIT": "0",
      "NETCHNG_1": "0.0",
      "BID_NETCHNG": "+0.006",
      "HST_VOL": "-",
      "implied_volatility": "47.0",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "1",
      "ASKSIZE": "1,000,000",
      "premium": "5.7",
      "SERVICE": "ELEKTRON_DD",
      "intrinsic_value": "0.025",
      "underlying_ticker": "BABA-SW",
      "issuer_code": "MBL",
      "exercise_price_cal": 250.000000000,
      "TURNOVER": "0",
      "underlying_name": "BABA-SW",
      "REC_STATUS": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "1000000",
      "ACVOL_1": "0",
      "days_to_maturity": "40",
      "ric": "ALML_te.SI",
      "exercise_price": "250.00"
    }
  ]
}

const underlyingchartData = {
    "symbols": symbolsSample,
    "9988.HK": ALIBABA,
    "AEM.SI": AWX
}

export default underlyingchartData;