import React, { Component } from "react";
import classNames from "classnames";
import MQSGUtil from "../../components/mqsgUtil"
// import AdvertBox from "../../../components/advert/advert";
import { Link } from "react-router-dom";
import "./UnSubscribe.css";

export default class UnSubscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isShow: true
        };
    }
    setHttpEmail() {
        const url = MQSGUtil.getAPIBasePath() + '/UnSubscribe';
        if (this.state.email == '') { return; }
        fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'email=' + this.state.email
            }
        ).then((res) => res.json()).then(
            (result) => {
                this.setState({
                    isShow: false
                })
            },
            (error) => {
            }
        );
    }
    setEmail(e) {
        this.setState({
            email: e.target.value
        })
    }
    componentDidMount() {

    }
    render() {
        return (
            <div className="pageobj" id="unsubscribe">
                <div className="container-fluid" id="Popups-one" style={{ display: this.state.isShow ? 'block' : 'none' }}>
                    <div className="row">
                        <div className="unsubscribe_box col-md-12">
                            <img src="/img/home/ca877357a66a35e9361916b0cd48539.png" style={{ marginBottom: '30px' }} />
                            <h3>Do you want to unsubscribe?</h3>
                            <p>If you unsubscribe, you will stop</p>
                            <p>receiving our daily newsletter</p>
                            <div style={{ marginTop: '40px', paddingBottom: '100px' }}>
                                <input id="email" onChange={(e) => { this.setEmail(e) }} type="text" name="Email" placeholder="Email" key="label.email" style={{ padding: '4px', color: '#000' }} />
                                <br />
                                <button className="btn btn-warning Unsubscribe" onClick={(e) => { this.setHttpEmail() }}>Unsubscribe</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" id="Popups-two" style={{ display: this.state.isShow ? 'none' : 'block' }} >
                    <div className="row">
                        <div className="unsubscribe_box col-md-12">
                            <img src="/img/home/6906ea781c1b3b5266ae558738a6dde.png" style={{ marginBottom: '8px', marginLeft: '-13px' }} />
                            <h3>Unsubscribe successful</h3>
                            <p>You will no longer receive the daily</p>
                            <p>newsletter from us.</p>
                            <p>You may close this window now.</p>
                            <div style={{ marginTop: '40px', paddingBottom: '100px' }}>

                                <button id="USClose" className="btn btn-light Close" style={{ color: '#000' }}>
                                    <Link to="/home">
                                        Close
                                    </Link>
                                </button>


                            </div>
                            <div className="unsubscribe_box_img">

                                <span>
                                    <Link to="/home">
                                        <img src="/img/home/463eb8cead869ec5d283574d012d37f.png" style={{ height: '80px', width: '80px' }} />
                                    </Link>
                                    <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MacquarieWarrantsSG/?utm_source=MQ&utm_medium=Icon&utm_campaign=FB" style={{ height: '50px', width: '50px' }}></a>
                                    <a target="_black" className="sharebtn-tl btn" type="button" href="https://t.me/MacquarieWarrantsSG" style={{ height: '50px', width: '50px', marginLeft: '10px' }}></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid" id="Popups-three" style={{ display: 'none' }}>
                    <div className="row">
                        <div className="unsubscribe_box col-md-12" style={{ textAlign: 'left' }}>
                            <h3 style={{ fontSize: '60px' }}>Unsubscribe Successful</h3>
                            <p>You will no longer receive the daily newsletter from us</p>
                            <p style={{ marginTop: '25px' }}>If you have a moment, do let us know why you unsubscribed:</p>

                            <p><input type="radio" value="Receive too many emails"></input></p>
                            <p><input type="radio">Not interested in structured warrants</input></p>
                                <p><input type="radio">Not interested in the content of these emails</input></p>
                                <p><input type="radio">I already subscribe to your Telegram and/or Facebook channels</input></p>
                                <p><input type="radio">Other</input></p>
                            <div style={{ marginTop: '40px', paddingBottom: '100px' }}>
                                <button className="btn btn-primary Submit">Submit</button>
                            </div>
                            <div className="unsubscribe_box_img" style={{ textAlign: 'center' }}>
                                <span>
                                    <img src="" style={{ height: '80px', width: '80px' }} />
                                    <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/" style={{ height: '50px', width: '50px' }}></a>
                                    <a target="_black" className="sharebtn-tl btn" type="button" href="https://web.telegram.org/#/im?p=@Malaysiawarrants" style={{ height: '50px', width: '50px', marginLeft: '10px' }}></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="container-fluid" id="Popups-four" style={{ display: 'none' }}>
                    <div className="row">
                        <div className="unsubscribe_box col-md-12">
                            <img src="" style={{ marginBottom: '8px', height: '300px', width: '300px' }} />
                            <h3>Thank you for your feedback</h3>
                            <p>You may close this window now.</p>
                            <div style={{ marginTop: '40px', paddingBottom: '100px' }}>
                                <button className="btn btn-light" style={{ color: '#000' }}>Close</button>
                            </div>
                            <div className="unsubscribe_box_img">
                                <span>
                                    <img src="" style={{ height: ' 80px', width: '80px' }} />
                                    <a target="_black" className="sharebtn-fb btn" type="button" href="https://www.facebook.com/MalaysiaWarrants/" style={{ height: '50px', width: '50px' }}></a>
                                    <a target="_black" className="sharebtn-tl btn" type="button" href="https://web.telegram.org/#/im?p=@Malaysiawarrants" style={{ height: '50px', width: '50px', marginLeft: '10px' }}></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

