import React, { Component } from "react";
import { Link } from "react-router-dom";
import MQSGUtil from "../../../components/mqsgUtil";
import "./videos.css";
import YouTubePlayer from 'youtube-player';

export default class Videos extends Component {
  constructor() {
    super();
    this.state = {
      mewVideo: [],
      list: [],
      bannerData: []
    };
  }
  getBanner() {
    const url = MQSGUtil.getAPIBasePath() + "/ShortsVideoBannerWarrantsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState(
            {
              bannerData: result
            })
        },
        (error) => {
        }
      );
  }
  componentDidMount() {
    this.getBanner()
    const url = MQSGUtil.getAPIBasePath() + "/ShortsVideoWarrantsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          let src = ''
          let srcArray = []
          for (var i = 0; i < result.length; i++) {
            srcArray.push(result[i].videos[0])
          }
          srcArray.sort((a, b) => {
            return new Date(b.update_time) - new Date(a.update_time)
          })
          this.setState(
            {
              list: result,
              mewVideo: srcArray
            })
        },
        (error) => {
        }
      );
  }
  render() {
    return (
      <div style={{ overflow: 'hidden' }}>
        {/* <VideoGallery mewVideo={this.state.mewVideo} bannerData={this.state.bannerData} /> */}
        {this.state.list.map((i) => {
          return (
            <VideoContent
              columns={i.videos}
              title={i.title}
            />
          );
        })}
        {this.state.list.map((i) => {
          return (
            <VideoContentXs
              columns={i.videos}
              title={i.title}
            />
          );
        })}
      </div>
    );
  }
}
const videoBoxObject = {}
var videoId = null;
const stopVideo = () => {
  for (let i in videoBoxObject) {
    videoBoxObject[i].stopVideo()
  }
}
window.addEventListener('scroll', (e) => {
  var object = null;
  for (let i in videoBoxObject) {
    if (videoBoxObject[i].getPlayerState && videoBoxObject[i].getPlayerState() == 1) {
      object = videoBoxObject[i];
    }
  }
  if (videoId && object) {
    let dom = document.getElementsByTagName('iframe');
    if (dom.length > 0) {
      for (let i = 0; i < dom.length; i++) {
        if (dom[i].attributes.src.textContent.indexOf(videoId) != -1) {
          var domHeight = dom[i].parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.offsetTop;
          if (window.scrollY - domHeight > 500 || window.scrollY - domHeight < -900) {
            object.stopVideo()
          }
        }
      }
    }
  }
})
class VideoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgs: [],
      hrefs: [],
      showIndex: 0, //显示第几个图片
      timer: null, // 定时器
      show: false, // 前后按钮显示
    };
  }

  renderImg(data) {
    if (data) {
      return <img src={MQSGUtil.getShortsVideoBannerAPIImgPath() + data.id} style={{ height: "640px", maxWidth: "370px" }}></img>
    }
  }
  getIframe(e, playlistid) {
    var dom = document.getElementById(playlistid)
    var imgDom = dom.getElementsByTagName('img')
    imgDom[0].style.display = 'none';
    var iframe = document.createElement("iframe");
    iframe.height = '640px';
    iframe.width = '100%';
    iframe.src = `https://www.youtube.com/embed/${playlistid}?&index=0&rel=0&wmode=opaque&autoplay=1`
    dom.appendChild(iframe)
  }
  getVideo(e, playlistid) {
    let dom = document.getElementById(playlistid + '-box')
    let imgDom = dom.getElementsByTagName('img')
    imgDom[0].style.display = 'none';
    let player;
    player = new YT.Player(playlistid, {
      height: '640px',
      width: '100%',
      videoId: playlistid
    });
    videoBoxObject[playlistid] = player;
    videoId = playlistid;
  }
  render() {
    return (
      <div className="swiper-container" style={{
        height: 'auto', maxWidth: '370px',
        margin: ' 0 auto'
      }}>
        <div
          className="swiper-wrapper"
        // onMouseEnter={() => {
        //   this.stop();
        // }}
        // onMouseLeave={() => {
        //   this.start();
        // }}
        >
          <ul className="ul">
            {this.props.bannerData.map((value, index) => {
              return index < 3 ? (
                <li
                  className={index === this.state.showIndex ? "show" : ""}
                  key={index}
                >
                  {/* <div className="videoplayer-container" style={{ height: '100%' }} onClick={(e) => { this.getIframe(e, value.playlist) }} id={value.playlist}>
                    {this.renderImg(value)}
                  </div> */}
                  <div className="videoplayer-container" style={{ height: '100%' }} onClick={(e) => { this.getVideo(e, value.playlist) }} id={value.playlist + '-box'}>
                    <div id={value.playlist}></div>
                    {this.renderImg(value)}
                  </div>
                </li>
              ) : ''
            })}
          </ul>
          <ul className="swiper-pagination" style={{ bottom: '15px', right: '38%', top: '93%' }}>
            {this.props.bannerData.map((value, index) => {
              return index < 3 ? (
                <li
                  key={index}
                  className={index === this.state.showIndex ? "active" : ""}
                  onClick={() => {
                    this.change(index);
                  }}
                ></li>
              ) : ''
            })}
          </ul>
        </div>
      </div>
    );
  }
  componentDidMount() {
    const url = MQSGUtil.getAPIBasePath() + "/GetVideoBanner";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          var hrefs = [];
          var imgs = [];
          for (var i = 0; i < result.length; i++) {
            hrefs.push(result[i].link);
            imgs.push(MQSGUtil.getAPIBasePath() + "/photo?ad_id=" + result[i].id);
          }
          this.setState({ hrefs, imgs });
        },
        (error) => {
        }
      );
    //一开始自动播放
    // this.start();
  }
  componentWillUnmount() {
    //销毁前清除定时器
    this.stop();
  }
  stop = () => {
    //暂停
    let { timer } = this.state;
    clearInterval(timer);
  };
  start = () => {
    //开始
    let { timer } = this.state;
    timer = setInterval(() => {
      this.next();
    }, 3000);
    this.setState({
      timer,
    });
  };
  change = (index) => {
    //点击下面的按钮切换当前显示的图片
    let { showIndex } = this.state;
    showIndex = index;
    this.setState({
      showIndex,
    });
    stopVideo()
  };
  previous = (e) => {
    //上一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex <= 0) {
      showIndex = imgs.length - 1;
    } else {
      showIndex--;
    }
    this.setState({
      showIndex,
    });
  };
  next = (e) => {
    //下一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex >= imgs.length - 1) {
      showIndex = 0;
    } else {
      showIndex++;
    }
    this.setState({
      showIndex,
    });
  };
}

class VideoContent extends Component {
  constructor(props) {
    super(props);
    this.tableColumns = [];
    this.pageSize = 3;
    this.state = {
      current: 0, //当前页码
      totalPage: 0
    };
  }

  onPageIndexChange(pageIndex) {
    stopVideo()
    this.setState({ current: pageIndex });
  }
  componentWillReceiveProps(props) {
    if (props.title != '') {
      this.setState({ totalPage: this.setupRows(props.columns) })
    }
  }
  componentDidMount() {
    this.setState({ totalPage: this.setupRows(this.props.columns) })
  }
  setupRows(columns) {
    const title = this.props.title;
    if (typeof columns !== "undefined" && columns !== null) {
      if (Array.isArray(columns)) {
        this.tableColumns = columns.map((content, index) => {
          return (
            <Table
              key={title + "_" + index}
              content={content}
              subtitle={title}
              view='lg'
            />
          );
        });
        return Math.ceil(columns.length / this.pageSize);
      }
    }
    return 0;
  }

  getRow() {
    // page index 0: current -> pageSize * (current + 1) - 1
    // page index 1: pageSize * current -> pageSize * 2 - 1
    const pageIndex = this.state.current;
    const startRowIndex = this.pageSize * pageIndex;
    const endRowIndex = this.pageSize * (pageIndex + 1);
    if (this.tableColumns.length > endRowIndex) {
      return this.tableColumns.slice(startRowIndex, endRowIndex);
    }
    return this.tableColumns.slice(startRowIndex);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.columns !== this.props.columns) {
      this.setupRows(nextProps.columns);
    }
    return true;
  }

  render() {
    const row = this.getRow();
    return (
      <div className="content hidden-xs">
        <div id="edurack" className="videorack">
          <div className="videorack-title">{this.props.title}</div>
          <div className="videorack-body">
            <div className="swiper-container" style={{ height: 'auto' }}>
              <div className="swiper-wrapper">
                <table className="videorack-page" style={{ width: "100%", }}>
                  <tbody>
                    <tr>{row}</tr>
                  </tbody>
                </table>
              </div>
              <PageButton
                onPageIndexChange={(pageIndex) =>
                  this.onPageIndexChange(pageIndex)
                }
                current={this.state.current}
                totalPage={this.state.totalPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class VideoContentXs extends Component {
  constructor(props) {
    super(props);
    this.tableColumns = [];
    this.pageSize = 1;
    this.state = {
      current: 0, //当前页码
      totalPage: 0
    };
  }

  onPageIndexChange(pageIndex) {
    this.setState({ current: pageIndex });
    stopVideo()
  }
  componentWillReceiveProps(props) {
    if (props.title != '') {
      this.setState({ totalPage: this.setupRows(props.columns) })
    }
  }
  componentDidMount() {
    this.setState({ totalPage: this.setupRows(this.props.columns) })
  }
  setupRows(columns) {
    const title = this.props.title;
    if (typeof columns !== "undefined" && columns !== null) {
      if (Array.isArray(columns)) {
        this.tableColumns = columns.map((content, index) => {
          return (
            <Table
              key={title + "_" + index}
              content={content}
              subtitle={title}
              view='xs'
            />
          );
        });
        return Math.ceil(columns.length / this.pageSize);
      }
    }
    return 0;
  }

  getRow() {
    // page index 0: current -> pageSize * (current + 1) - 1
    // page index 1: pageSize * current -> pageSize * 2 - 1
    const pageIndex = this.state.current;
    const startRowIndex = this.pageSize * pageIndex;
    const endRowIndex = this.pageSize * (pageIndex + 1);
    if (this.tableColumns.length > endRowIndex) {
      return this.tableColumns.slice(startRowIndex, endRowIndex);
    }
    return this.tableColumns.slice(startRowIndex);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.columns !== this.props.columns) {
      this.setupRows(nextProps.columns);
    }
    return true;
  }

  render() {
    const row = this.getRow();
    return (
      <div className="content visible-xs">
        <div id="edurack" className="videorack">
          <div className="videorack-title">{this.props.title}</div>
          <div className="videorack-body">
            <div className="swiper-container" style={{ height: 'auto' }}>
              <div className="swiper-wrapper">
                <table className="videorack-page" style={{ width: "100%", }}>
                  <tbody>
                    <tr>{row}</tr>
                  </tbody>
                </table>
              </div>
              <PageButton
                onPageIndexChange={(pageIndex) =>
                  this.onPageIndexChange(pageIndex)
                }
                current={this.state.current}
                totalPage={this.state.totalPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Table extends Component {
  constructor(props) {
    super(props);
  }
  getVideo(e, playlistid) {
    // stopVideo()
    let dom = document.getElementById(playlistid + '_' + this.props.view)
    let imgDom = dom.getElementsByTagName('img')
    imgDom[0].style.display = 'none';
    let player;
    player = new YT.Player(playlistid + '-' + this.props.view, {
      height: '550px',
      width: '100%',
      videoId: playlistid,
      events: {
        'onStateChange': this.onPlayerStateChange
      }
    });
    videoBoxObject[playlistid + '-' + this.props.view] = player;
    videoId = playlistid;
  }
  onPlayerStateChange(e) {
    if (e.data == 3) {
      for (let i in videoBoxObject) {
        if (i != e.target.g.id) {
          videoBoxObject[i].stopVideo()
        }
      }
    }
  }

  render() {
    const content = this.props.content;
    const playlistid = MQSGUtil.getData(content, "playlist_id");
    var src = null;
    if (MQSGUtil.getData(content, "image")) {
      src = MQSGUtil.getShortsVideoAPIImgPath() + MQSGUtil.getData(content, "id");
    } else {
      src = 'https://i.ytimg.com/vi/' + MQSGUtil.getData(content, "playlist_id") + '/oar2.jpg'
    }
    return (
      <td style={{ textAlign: 'left', width: '330px' }}>
        {/* <div className="videoplayer-container" style={{ height: '100%' }} onClick={(e) => { this.getIframe(e, playlistid) }} id={playlistid + '_' + this.props.view}>
          <img style={{ width: "100%", height: '550px' }} src={src} alt="" />
        </div> */}
        <div className="videoplayer-container" style={{ height: '100%' }} id={playlistid + '_' + this.props.view}>
          <div id={playlistid + '-' + this.props.view}></div>
          <img style={{ width: "100%", height: '550px' }} src={src} alt="" onClick={(e) => { this.getVideo(e, playlistid) }} />
        </div>
      </td >
    );
  }
}

class PageButton extends Component {
  //下一页
  onForward() {
    if (this.props.current + 1 < this.props.totalPage) {
      this.props.onPageIndexChange(this.props.current + 1);
    }
  }

  //上一页
  onBackward() {
    if (this.props.current - 1 >= 0) {
      this.props.onPageIndexChange(this.props.current - 1);
    }
  }

  isDisabled(direction) {
    if (direction === "backward") {
      if (this.props.current === 0) {
        return "swiper-button-disabled";
      }
    } else if (direction === "forward") {
      if (this.props.current === this.props.totalPage - 1) {
        return "swiper-button-disabled";
      }
    }
    return "";
  }

  render() {
    return (
      <div className="swiper-botton">
        <div
          className={"gallery-nav left backward " + this.isDisabled("backward")}
          onClick={() => this.onBackward()}
        >
          <div className="videorack-nav-icon"></div>
        </div>
        <div
          className={"gallery-nav right forward " + this.isDisabled("forward")}
          onClick={() => this.onForward()}
        >
          <div className="videorack-nav-icon"></div>
        </div>
      </div>
    );
  }
}
