import React, { Component } from "react";
import "./advert.css";
import MQSGUtil from "../../components/mqsgUtil";

export default class Advert extends Component {
  constructor() {
    super();
    this.state = {
      imgs: [
        // MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 158,
        // MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 160,
        // MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 63,
      ], // 图片数组
      hrefs: [
        // 'https://www.malaysiawarrants.com.my/HKunderlyings',
        //  'https://en.wikipedia.org/wiki/Koi',
        //   "/education/seminar"
      ],
      redirect_type: [],
      showIndex: 0, //显示第几个图片
      timer: null, // 定时器
      show: false, // 前后按钮显示
    };
  }
  getBanner() {
    const url =
      MQSGUtil.getAPIBasePath() + "/GetbannerJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          let imgs = [];
          let hrefs = [];
          let redirect_type = []
          for (let i = 0; i < result.length; i++) {
            imgs.push(MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + result[i].id)
            hrefs.push(result[i].link);
            redirect_type.push(result[i].redirect_type)
          }

          this.setState({
            imgs,
            hrefs,
            redirect_type
          })
        },
        (error) => {
        }
      );
  }
  render() {
    return (
      <div className="advert">
        <div className="swiper-container">
          <div
            className="swiper-wrapper"
            onMouseEnter={() => {
              this.stop();
            }} //鼠标进入停止自动播放
            onMouseLeave={() => {
              this.start();
            }} //鼠标退出自动播放
          >
            <ul className="ul hidden-xs">
              {this.state.imgs.map((value, index) => {
                return (
                  <li
                    className={index === this.state.showIndex ? "show" : ""}
                    key={index}
                    style={{ width: "367px", background: "#fff" }}
                  >
                    <a href={this.state.hrefs[index]} target={this.state.redirect_type[index] == 1 ? '_blank' : '_self'}>
                      <img src={value + ""} style={{ height: "auto" }} />
                    </a>
                  </li>
                );
              })}
            </ul>
            <ul className="swiper-pagination">
              {this.state.imgs.map((value, index) => {
                return (
                  <li
                    key={index}
                    className={index === this.state.showIndex ? "active" : ""}
                    onClick={() => {
                      this.change(index);
                    }}
                  ></li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    //一开始自动播放
    this.start();
    this.getBanner()
    // if (window.location.href.indexOf('topgainloss') !== -1) {
    //   var img = [MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 158,
    //   MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 63
    //   ]

    //   this.setState({
    //     imgs: img,
    //     hrefs: ['https://www.malaysiawarrants.com.my/HKunderlyings', '/education/seminar']
    //   }, () => {
    //   })
    // } else if (window.location.href.indexOf('selector') !== -1) {
    //   var img = [MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 158,
    //   MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 160,
    //   MQSGUtil.getAPIBasePath() + "/photo?adv_id=" + 63,
    //   ]

    //   this.setState({
    //     imgs: img,
    //     hrefs: ['https://www.malaysiawarrants.com.my/HKunderlyings', 'https://en.wikipedia.org/wiki/Koi', "/education/seminar"]
    //   }, () => {
    //   })
    // }
  }
  componentWillUnmount() {
    //销毁前清除定时器
    this.stop();
  }
  stop = () => {
    //暂停
    let { timer } = this.state;
    clearInterval(timer);
  };
  start = () => {
    //开始
    let { timer } = this.state;
    timer = setInterval(() => {
      this.next();
    }, 5000);
    this.setState({
      timer,
    });
  };
  change = (index) => {
    //点击下面的按钮切换当前显示的图片
    let { showIndex } = this.state;
    showIndex = index;
    this.setState({
      showIndex,
    });
  };
  previous = (e) => {
    //上一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex <= 0) {
      showIndex = imgs.length - 1;
    } else {
      showIndex--;
    }
    this.setState({
      showIndex,
    });
  };
  next = (e) => {
    //下一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex >= imgs.length - 1) {
      showIndex = 0;
    } else {
      showIndex++;
    }
    this.setState({
      showIndex,
    });
  };
}
