import React, { Component } from 'react'

export default class MoneyFlow extends Component {
    render() {
        return (
            <div className="section qlink-section">
                <div className="page-header">
                    <h2 className="small">Money flow</h2>
                </div>
                <p>
                    See what other investors are buying and selling. Exclusive information each week for Macqurie warrants.
                </p>
                <div className="section-qlink-img section-qlink-moneyflow">
                    <a href="/marketdata/moneyflow" className="section-qlink-achor">
                        <img src="/img/bullet/qlink-achor.png" alt="" />
                    </a>
                </div>
            </div>
        )
    }
}
