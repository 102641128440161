import React, { Component } from "react";
import "./banner.css";
import MQSGUtil from "../mqsgUtil";

export default class banner extends Component {
  constructor() {
    super();
    this.state = {
      imgs: [
        "/img/home/Article_homepage_banner.png",
        "/img/home/SGX_Awards_homepage_banner.png",
        "/img/home/homepage_banner_MCMAY222.png",

      ], // 图片数组
      hrefs: [
        "https://warrants.com.sg/marketnews/highlight/todayhighlight/7045",
        '',
        "/education/marketcommentary/Market%20Commentary/DCxdaFjBXMM/0",
      ],
      xsImgs: [
        "/img/home/Article_mobile_banner.png",
        "/img/home/SGX_Awards_mobile_banner.png",
        "/img/home/mobile_banner_MCMAY222.png",

      ],
      mobile: [],
      desk: [],
      showIndex: 0, //显示第几个图片
      timer: null, // 定时器
      show: false, // 前后按钮显示
      disclaimerShow: false,
      brokerUrl: ''
    };
  }
  onSelectBroker(value) {
    if (!value.importantNotice) {
      window.open(value.link, "_blank")
    } else {
      this.setState({
        disclaimerShow: true,
        brokerUrl: value.link
      })
    }
  }
  onDisclaimerChange(isContinue) {
    if (isContinue) {
      if (this.state.brokerUrl != null) {
        window.open(this.state.brokerUrl, "_blank"); //to open new page
      }
    }
    this.setState({
      disclaimerShow: false,
      brokerUrl: ''
    });
  }
  getHomeBanner() {
    const url =
      MQSGUtil.getAPIBasePath() + "/getHomeBanner";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          // chartdata = result;
          var mobile = [];
          var desk = [];
          for (var i = 0; i < result.length; i++) {
            result[i].url = MQSGUtil.getAPIBasePath() + "/photo?adh_id=" + result[i].id;
            if (result[i].img == 'desk') {
              desk.push(result[i])
            } else {
              mobile.push(result[i])
            }
          }
          this.setState({ desk, mobile });
        },
        (error) => {
        }
      );
  }
  render() {
    return (
      <div className="swiper-container swiper-containerh">
        <div
          className="swiper-wrapper"
          onMouseEnter={() => {
            this.stop();
          }} //鼠标进入停止自动播放
          onMouseLeave={() => {
            this.start();
          }} //鼠标退出自动播放
        >
          <ul className="ul hidden-xs">
            {this.state.desk.map((value, index) => {
              return (
                <li
                  className={index === this.state.showIndex ? "show" : ""}
                  key={index}
                  style={{ position: 'relative' }}
                >
                  {/* <a href={value.link}> */}
                  <a onClick={() => { this.onSelectBroker(value) }}>
                    <img src={value.url} />
                  </a>
                  <div className="swiper-botton hidden-xs">
                    <span
                      className="gallery-nav left"
                      onClick={(e) => {
                        this.previous(e);
                      }}
                    ></span>
                    <span
                      className="gallery-nav right"
                      onClick={(e) => {
                        this.next(e);
                      }}
                    ></span>
                  </div>
                </li>
              );
            })}
          </ul>
          <ul className="ul visible-xs">
            {this.state.mobile.map((value, index) => {
              return (
                <li
                  className={index === this.state.showIndex ? "show" : ""}
                  key={index}
                  style={{ position: 'relative' }}
                >
                  {/* <a href={value.link}> */}
                  <a onClick={() => { this.onSelectBroker(value) }}>
                    <img src={value.url} />
                  </a>
                  <div className="swiper-botton hidden-xs">
                    <span
                      className="gallery-nav left"
                      onClick={(e) => {
                        this.previous(e);
                      }}
                    ></span>
                    <span
                      className="gallery-nav right"
                      onClick={(e) => {
                        this.next(e);
                      }}
                    ></span>
                  </div>
                </li>
              );
            })}
          </ul>
          <ul className="swiper-pagination swiper-paginationh">
            {this.state.imgs.map((value, index) => {
              return (
                <li
                  key={index}
                  className={index === this.state.showIndex ? "active" : ""}
                  onClick={() => {
                    this.change(index);
                  }}
                ></li>
              );
            })}
          </ul>
        </div>
        <Disclaimer
          show={this.state.disclaimerShow}
          onDisclaimerChange={this.onDisclaimerChange.bind(this)}
        />
      </div>
    );
  }
  componentDidMount() {
    //一开始自动播放
    this.start();
    this.getHomeBanner()
  }
  componentWillUnmount() {
    //销毁前清除定时器
    this.stop();
  }
  stop = () => {
    //暂停
    let { timer } = this.state;
    clearInterval(timer);
  };
  start = () => {
    //开始
    let { timer } = this.state;
    timer = setInterval(() => {
      this.next();
    }, 5000);
    this.setState({
      timer,
    });
  };
  change = (index) => {
    //点击下面的按钮切换当前显示的图片
    let { showIndex } = this.state;
    showIndex = index;
    this.setState({
      showIndex,
    });
  };
  previous = (e) => {
    //上一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex <= 0) {
      showIndex = imgs.length - 1;
    } else {
      showIndex--;
    }
    this.setState({
      showIndex,
    });
  };
  next = (e) => {
    //下一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex >= imgs.length - 1) {
      showIndex = 0;
    } else {
      showIndex++;
    }
    this.setState({
      showIndex,
    });
  };
}
class Disclaimer extends React.Component {
  onDisclaimerChange(isContinue) {
    this.props.onDisclaimerChange(isContinue);
  }

  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    return (
      <div
        id="disclaimer-ppboxHTML"
        className="disclaimer-overlay"
        style={isShow}
      >
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <div>
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>Important notice</h3>
                  </div>
                  <p className="small">
                    You are now entering a third party website which is neither
                    operated nor sponsored by Macquarie. Macquarie is not
                    responsible for the content, cookie collection, personal
                    data collection, trading and services provided on such
                    website. You should review the terms and conditions of third
                    party websites and contact the operators of such websites if
                    you have any queries.
                    <br />
                    <br />
                    Investment in warrants involves risk. These links to third
                    party websites do not constitute an offer, a solicitation of
                    an offer, or any advice or recommendation to participate in
                    any transaction. You should consult your own professional
                    advisors to ensure that any decision made is suitable with
                    regards to your circumstances and financial position. Please
                    note that the list of brokers is not exhaustive and the
                    inclusion of the brokers on the list does not imply that the
                    brokers or their services are endorsed by Macquarie.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <input
                      className="btn btn-primary continue"
                      value="Continue"
                      type="button"
                      onClick={this.onDisclaimerChange.bind(this, true)}
                    />
                    <input
                      className="btn btn-primary cancel"
                      value="Cancel"
                      type="button"
                      onClick={this.onDisclaimerChange.bind(this, false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}