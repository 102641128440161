import React, { Component } from 'react'

export default class liveMatrix extends Component {
    render() {
        return (
            <div className="section qlink-section">
                <div className="page-header">
                    <h2 className="small">Live matrix</h2>
                </div>
                <p>
                    Makes trading warrant easy by showing investors where our bid and offer prices will be throughout the day.
                </p>
                <div className="section-qlink-img section-qlink-lmatrix">
                    <a href="/tools/livematrix" className="section-qlink-achor">
                        <img src="/img/bullet/qlink-achor.png" alt="" />
                    </a>
                </div>
            </div>
        )
    }
}
