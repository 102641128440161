
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class interestrates extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>Interest rates will affect the price of a warrant through financial holding costs. Typically, when you buy a call warrant, issuers will hedge their position by buying the underlying share. This requires funding and, as such,
                                                when the cost of borrowing goes up as a result of rising interest rates, the price of a call warrant will also go up. The converse is true for a put warrant.</p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/Interest-Rates_enlarge.jpg" />
                                            </div>
                                            <br />
                                            <br />
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful Tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>When choosing an appropriate warrant, investors should be most concerned with the first four factors. Generally, the last two factors – dividends and interest rates – do not have a large effect on the warrant
                                                                price and are less important.</p>
                                                        </li>
                                                        <li>
                                                            <p>Use Macquarie’s warrant calculator to see how changes in the various factors affect a warrant's price <a href="/tools/warrantcalculator" style={{ color: 'red' }}>click
                                                                here</a></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}