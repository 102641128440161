
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class releasingsharecapital extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div>
                            <div id="mainnav">
                                <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                    <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                        <div id="mainscrollcontent">
                                            <div className="contentbox">
                                                <p>Using warrants to gain exposure to stocks can free up the capital that would otherwise be invested into fully paid shares. Alternatively, an investor may sell existing share holdings and gain a similar effective exposure
                                                    by purchasing call warrants for a fraction of the price. The investor has maintained exposure to share prices while releasing capital from the security holding.</p>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/Script_0105-releasing-share-capital_enlarge.jpg" alt />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}