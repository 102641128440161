
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class premium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>
                                                Now to calculate the price that the underlying share needs to reach at expiry to break even, you need to multiply the warrant price by its warrants per share and then add the exercise price. So that’s 10 cents multiplied by seven, plus $11.00. This gives
                                                you $11.70.
                                                <br /><br /> $11.70 is your effective cost of purchasing the share using the warrant. That is, the price the stock has to reach at expiry to breakeven on your initial purchase price of 10 cents. This is what we term as the
                                                breakeven price at expiry.
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/Cost-of-Buying-the-Share-via-Warrant-At-Breakeven-Price_enlarge.jpg" alt="Cost of buying the share via warrant at breakeven price enlarge" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/Cost-of-Buying-the-Share-via-Warrant-At-Breakeven-Price_enlarge.jpg')}>View table</div>
                                            </div>
                                            <p>You will notice that there is a 50 cent difference between this $11.70 figure and the direct cost of buying the share at $11.20.<br /><br /> This difference is what we term as premium. While 50 cents may seem high at first sight,
                                                it is only 4.5% of the underlying’s share price. A relatively small price you have to pay in return for the gearing you enjoy from gaining exposure to the underlying at a fraction of its cost. Generally, premiums are higher
                                                for longer-dated warrants. They are also higher for warrants with higher effective gearing levels.
                                            </p>
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips on how to use Premium</h2>
                                                    <ul>
                                                        <li>
                                                            <p>Having a higher premium level does not mean that one warrant is necessarily more ‘expensive’ than another. Premium will be higher for warrants that are longer dated and also for warrants with higher gearing
                                                                levels.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p>Premium is not the best indicator to use to compare the relative price of one warrant over another. When comparing similar warrants, to determine which is cheaper, you should use <a href="/WarrantsGuidebook/impliedvolatility" style={{ color: 'red' }}>implied
                                                                volatility</a>.</p>
                                                        </li>
                                                        <li>
                                                            <p>Premium is most useful for estimating the amount of ltime decay that a warrant will experience over its life. Say for example, a warrant has a premium amount of $0.20 and it has 2 months left to expiry. You
                                                                can then assume that (assuming all other factors are constant) the warrant will decay in approx. $0.10 per month over the next 2 months. This however will not happen in a straight line and will speed up
                                                                as it approaches expiry, <a href="/WarrantsGuidebook/timedecay" style={{ color: 'red' }}>click here</a> to read more about time decay.</p>
                                                        </li>
                                                        <li>
                                                            <p>You can view the premium for all warrants via the <a href="/tools/warrantterms" style={{ color: 'red' }}>warrant terms</a> page.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}