
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class closingtimestonote extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="" >
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>
                                                Note that Macquarie does not participate in the closing auction for Singapore listed stocks, and therefore withdraws its bid and offer quotes 2 minutes prior to the close of the market at 4:58pm each day.<br /><br /> For HSI
                                                warrants we withdraw our market making 2 minutes before the futures close at 4:28pm; for China A50 warrants, we withdraw 2 minutes prior to close of the China A50 futures market, at 4.28pm.<br /><br /> The Japan market closes
                                                during the day for lunch and our market making quotes will be withdrawn between 2:23pm and 3:15pm. We resume market-making thereafter until the end of the day, at 4:58pm.<br /><br /> Like the Nikkei warrants, market making
                                                for S&amp;P warrants will be withdrawn 2 minutes before end of the day, at 4:58pm<br /><br />
                                            </p>
                                            <br />
                                            <br />
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <p style={{ paddingLeft: '1em' }}>Summary of the timings of the previous day’s closing bid prices for warrants that investors should compare current bid prices with:</p>
                                                    <ul>
                                                        <li>Warrants over STI and Singapore stocks : 4.58pm</li>
                                                        <li>Warrants over HSI : 4.28pm</li>
                                                        <li>Warrants over China A50 : 4.28pm</li>
                                                        <li>Warrants over Nikkei : 4.58pm</li>
                                                        <li>Warrants over S&amp;P : 4.58pm</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}