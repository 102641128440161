import React, { Component } from "react";
import classNames from "classnames";
import { Link, Switch, Route } from "react-router-dom";

import Banner from "../../components/banner/banner";
import WarrantsToolsTab from "./tab/warranttools";
// import MarketNewsTab from './tab/marketnews'
import WarrantEducationTab from "./tab/warranteducation";
// import WarrantStatisticsTab from './tab/warrantstatistics'
import "./home.css";
import { Helmet } from "react-helmet";
export default class home extends Component {
  render() {
    return (
      <div id="home">
        {/* <Helmet>
          <title>Singapore Warrants | Trading Stocks and Index Warrants | Macquarie</title>
          <meta name="keywords" content="Singapore warrants, structured warrants, Macquarie" />
          <meta name="description"
            content="See today's top traded index and single stock structured warrants in Singapore Warrants. Trading tools and stock market updates, all in one place." />
        </Helmet> */}
        <div className="hometop">
          <Banner />
          <HomeTab />
          <div className="tab-container container-fluid">
            {/* 路由跳转tab */}
            <Switch>
              <Route
                path="/home"
                exact
                render={() => <WarrantsToolsTab />}
              ></Route>
              {/* <Route path='/home/news' exact render={() => <MarketNewsTab/> }></Route> */}
              <Route
                path="/home/edu"
                exact
                render={() => <WarrantEducationTab />}
              ></Route>
              {/* <Route path='/home/warrantstats' exact render={() => <WarrantStatisticsTab/> }></Route> */}
            </Switch>
            <FootBanner />
          </div>
        </div>
        <LightBox />
        <SplashPage />
      </div>
    );
  }
  componentDidMount() {
    document.title = "Singapore Warrants | Trading Stocks and Index Warrants | Macquarie"
  }
}

class HomeTab extends Component {
  constructor() {
    super();
    this.state = {
      selected: "Trading warrants",
    };
  }

  onTabClicked(tab) {
    this.setState({ selected: tab });
  }

  isActive(tab) {
    if (this.state.selected === tab) {
      return "active";
    }
    return "";
  }
  componentDidMount() {
    if (window.location.href.indexOf('edu') != -1) {
      this.setState({
        selected: "Warrant education"
      })
    }
  }
  render() {
    return (
      <nav className="tabnav container-fluid">
        <div className="row">
          <div className="col-xs-3 col-xs-offset-3">
            <div
              className={
                "tabnav-item tabnav-item-dwtool " +
                this.isActive("Trading warrants")
              }
              onClick={() => this.onTabClicked("Trading warrants")}
            >
              <Link to="/home">
                <div className="tabnav-img"></div>
                <h2 className="tabnav-htxt">Trading warrants</h2>
                {/* <div className="tabnav-txt visible-md visible-lg">Stay ahead of the market with our warrant tools.</div> */}
              </Link>
            </div>
          </div>
          {/* <div className="col-xs-3 clearfix">
                    <div className={classNames({"active":this.state.onClicked=='2'},"tabnav-item tabnav-item-dwnews")} onClick={this.tabClicked(2)}>
                    <Link to="/home/news">
                        <div className="tabnav-img"></div>
                        <h2 className="tabnav-htxt">Market news</h2>
                        <div className="tabnav-txt visible-md visible-lg">Market news as it breaks. Stay informed and ready.</div>
                    </Link>
                    </div>
                </div> */}
          <div className="col-xs-3">
            <div
              className={
                "tabnav-item tabnav-item-dwedu " +
                this.isActive("Warrant education")
              }
              onClick={() => this.onTabClicked("Warrant education")}
            >
              <Link to="/home/edu">
                <div className="tabnav-img"></div>
                <h2 className="tabnav-htxt">Warrant education</h2>
                {/* <div className="tabnav-txt visible-md visible-lg">New to warrants? We will help you get started and grow your knowledge.</div> */}
              </Link>
            </div>
          </div>
          {/* <div className="col-xs-3">
                    <div className={classNames({"active":this.state.onClicked=='4'},"tabnav-item tabnav-item-dwstat")} onClick={this.tabClicked(4)}>
                    <Link to="/home/warrantstats">
                        <div className="tabnav-img"></div>
                        <h2 className="tabnav-htxt">Warrant statistics</h2>
                        <div className="tabnav-txt visible-md visible-lg">Our customised graphs and charts provide excellent insight into the markets.</div>
                    </Link>
                    </div>
                </div> */}
        </div>
        <div className="hidden">
          <p className="nav-dtxt  show" data-value="dwtool">
            Stay ahead of the market with our warrant tools.
          </p>
          <p className="nav-dtxt " data-value="dwstat">
            Our customised graphs and charts provide excellent insight into the
            markets.
          </p>
          <p className="nav-dtxt " data-value="dwedu">
            New to warrants? We will help you get started and grow your
            knowledge.
          </p>
          <p className="nav-dtxt " data-value="dwnews">
            Market news as it breaks. Stay informed and ready.
          </p>
        </div>
      </nav>
    );
  }
}

class FootBanner extends Component {
  render() {
    return (
      <div>
        <div className="hidden-xs hf-banner">
          <div className="banner-wapper container-fluid">
            <a href="/education/whymacquarie">
              <img
                className="banner-img lazyload"
                src="/img/home/Why_Macquarie_updated.jpg"
              />
            </a>
          </div>
        </div>
        <div className="visible-xs hf-banner">
          <div className="banner-wapper container-fluid">
            <a href="/education/whymacquarie">
              <img
                className="banner-img lazyload"
                src="/img/home/Why_Macquarie_updated.jpg"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

class LightBox extends Component {
  constructor() {
    super();
    this.state = {
      time: 12,
      show: false,
      policyShow: false
    };
  }
  dontShowAgain() {
    clearInterval(this.timer);
    this.setState({ show: false });
  }
  componentDidMount() {
    if (localStorage.getItem('policy') == null) {
      this.setState({
        policyShow: true
      })
    } else {
      if (sessionStorage.getItem('banShow') == null) {
        this.setState({ show: true }, () => {
          this.timer = setInterval(() => {
            if (this.state.time !== 0) {
              var value = this.state.time;
              value = --value;
              this.setState({ time: value });
            } else {
              clearInterval(this.timer);
              this.setState({ show: false });
            }
          }, 1000);
        });
      } else {
        this.setState({ show: false });
      }
    }
  }
  noShow() {
    clearInterval(this.timer);
    this.setState({ show: false });
    sessionStorage.setItem('banShow', 'none')
  }
  setStorage() {
    localStorage.setItem('policy', 'none')
    this.setState({
      policyShow: false
    }, () => {
      if (sessionStorage.getItem('banShow') == null) {
        this.setState({ show: true }, () => {
          this.timer = setInterval(() => {
            if (this.state.time !== 0) {
              var value = this.state.time;
              value = --value;
              this.setState({ time: value });
            } else {
              clearInterval(this.timer);
              this.setState({ show: false });
            }
          }, 1000);
        });
      } else {
        this.setState({ show: false });
      }
    })
  }
  render() {
    return (
      <div>
        <div
          id="lightbox"
          style={{ display: this.state.show ? 'none' : 'none' }}
          // style={{ display: this.state.show ? 'table' : 'none' }}
          onClick={() => this.dontShowAgain()}
        >
          <div>
            <div className="inner-area">
              <a
                className="btn-close"
                style={{ color: "#FFFFFF" }}
                onClick={() => {
                  this.dontShowAgain();
                }}
              ></a>
              <div className="countdown-txt">
                Closes after <span id="ctlbl">{this.state.time}</span> seconds...
              </div>
              <a href="https://bit.ly/3arW1aX">
                <img src="/img/home/splashhstech.png" />
              </a>
              <a
                className="noshow text-bold"
                onClick={() => this.noShow()}
              >
                Don't show again
              </a>
            </div>
          </div>
        </div>

        <div className="main-disclaimer-overlay" id="disclaimer" style={{ display: this.state.policyShow ? 'table' : 'none' }}>
          <div className="disclaimer-overlay-inner">
            <div style={{ padding: '10px' }}>
              <div className="disclaimer-page-header" style={{ textAlign: 'left' }}>
                <h2 className="small">Terms of use and privacy policy</h2>
              </div>
              <p style={{ textAlign: 'left' }}>
                To continue, you must agree to our terms of use and privacy policy.
              </p>
              <div className="popupbox-content">
                <span className="bold disclaimer-title">Terms of use</span>
                <hr />
                This internet site is produced by Macquarie Capital Securities (Singapore) Pte Limited (Registration No 198702912C) (“MCSSPL”), holder of a capital markets services licence under the Securities and Futures Act, Chapter 289 of Singapore.
                <hr />
                The information on this internet site is directed and available to residents of Singapore only and is not provided to any person who is a resident of the United States or any other country. Any material provided on this internet site, including any indicative terms are provided for information purposes only and do not constitute an offer, a solicitation of an offer, or any advice or recommendation to conclude any transaction (whether on the indicative terms or otherwise).The Warrants will not be offered to any US persons.
                <hr />
                The price of securities may go down as well as up, and under certain circumstances an investor may sustain a total loss of investment. As past performance is not an indication of future performance, an investor should ensure that they understand the nature of the investment product and in particular the risk factors before investing.
                <hr />
                <span className="bold">Macquarie and the Material on this website</span>
                <hr />
                Macquarie Warrants are issued by Macquarie Bank Limited (ABN 008 583 542) ("MBL"). MCSSPL has issued the information in this site. Except where expressly stated, the information and services on this website (the "Material") are provided by MCSSPL as at the date indicated on the relevant material.
                <hr />
                <span className="bold">MBL, acting through its Singapore branch is authorised and licensed by the Monetary Authority of Singapore to carry on wholesale banking business in Singapore pursuant to the Banking Act, Chapter 19 of Singapore and therefore is subject to the supervision of the Monetary Authority of Singapore.</span>
                <hr />
                <span className="bold">MCSSPL</span>
                is not an authorised deposit-taking institution for the purposes of the Banking Act (Cth) 1959, and MCSSPL’s obligations do not represent deposits or other liabilities of Macquarie Bank Limited ABN 46 008 583 542. Macquarie Bank Limited does not guarantee or otherwise provide assurance in respect of the obligations of
                <span className="bold">MCSSPL</span>.
                <hr />
                In relation to Warrants which are listed on the Singapore Exchange Securities Trading Limited (“SGX”), a base listing document dated 12 July 2012 has been prepared. In addition a supplemental listing document will or has been prepared by MBL for the purpose of obtaining a listing for the Warrants on the SGX. Copies of the base listing document and supplemental listing document(s)-(together the “Listing Documents”) may be obtained from the offices of MCSSPL at 9 Straits View #21-07 Marina One West Tower Singapore 018937 and on this web-site.
                <hr />
                <span className="bold">Third Party Information</span>
                <hr />
                The material in this document has been prepared in good faith with all reasonable care. However, certain parts of this material is obtained or is based upon information obtained from third parties which may not have been checked or verified. Except to the extent permitted by law, and only to the extent so required, no member of the Macquarie Group makes any warranty in relation to, or accepts any responsibility or liability for any loss or damage suffered by any person arising out of or in relation to the material. This document is not for circulation or publication without the prior written consent of MCSSPL.
                <hr />
                <span className="bold">Opinions, ideas and strategies</span>
                <hr />
                The opinions, ideas and strategies in this website are based on certain assumptions and current market conditions. If those underlying assumptions or market conditions change, the opinions, ideas and strategies will change. All quotations are indicative only. A firm quotation can be provided by contacting MBL directly. The strategies outlined are not intended to be specific and/or personal advice and MBL expressly disclaims all or any liability and responsibility to any person in respect to anything (and the consequences of anything) done or omitted to be done by any person in reliance on the whole or any part of this publication.
                <hr />
                <span className="bold">No personal investment advice – seek personalised advice from a qualified adviser before investing</span>
                <hr />
                Any general advice contained on this website has been prepared by MCSSPL and does not take account of your objectives, financial situation or needs. Before acting on this general advice, you should consider the appropriateness of the advice having regard to your situation. We recommend you obtain financial, legal and taxation advice before making any financial investment decision. Macquarie or its associates, officers or employees may have interests in the financial products referred to in this information by acting in various roles including as investment banker, underwriter or dealer, holder of principal positions, broker, lender or adviser. Macquarie or its associates may receive fees, brokerage or commissions for acting in those capacities. In addition, Macquarie or its associates, officers or employees may buy or sell the financial products as principal or agent and as such may effect transactions which are not consistent with any recommendations in the information. All investors should obtain the Listing Documents for the products discussed and should consider it in full before making a decision.
                <hr />
                <span className="bold disclaimer-title">Macquarie Group Privacy Policy</span>
                <hr />
                The Macquarie Group respects the privacy of the people we deal with and we uphold high standards of privacy practice and security. This policy explains how we handle personal information and how we interact with you online.
                <hr />
                Whenever we handle personal information, we take steps to ensure that appropriate standards of privacy practice and security are applied.
                <hr />
                The Macquarie Group Privacy Policy sets out why we need to collect personal information, how we collect it, what we do with it, how it is stored and who we might share it with. The Policy also describes how you can access or correct information we hold about you, how you can ask further questions or make a complaint, and information about our websites and online activities.
                <hr />
                <span className="bold">The information we collect</span>
                <hr />
                For most products and services, it is necessary for us to collect 'personal information' such as your name, identity details, photos, contact details and transaction information. We may also need to collect other personal details (such as gender, marital status and financial information) and other information from which you can be identified.
                <hr />
                <span className="bold">Employment Applications</span>
                <hr />
                We collect personal information from you if you apply for a job with the Macquarie Group. Please read the
                <a href="https://www.macquarie.com/au/en/disclosures/careers-privacy-policy.html" target="_blank">Career Privacy Policy</a>
                for more information.
                <hr />
                <span className="bold">Credit Applications</span>
                <hr />
                Our
                <a href="https://www.macquarie.com/au/en/disclosures/credit-information-policy.html" traget="_blank">Credit Information Policy</a>
                sets out details of our handling of personal credit information obtained from credit reporting bodies. This applies mostly to our Australian credit products such as credit cards and loans.
                <hr />
                <span className="bold">Health Information</span>
                <hr />
                We collect your health information where we offer you products with an insurance component or in assessing certain claims, including hardship. We do not use or share that information for any purpose other than the underwriting or administration of your policy, claim or account, or as otherwise notified to you at the time we collect your information.
                <hr />
                <span className="bold">Sensitive Information</span>
                <hr />
                Generally, we do not collect sensitive information about you unless required or permitted by law or where you consent for us to do so. Sensitive information will only be collected if it is relevant to your product or the service or function you are engaging us to provide. If applicable, this will be communicated to you. For example, some forms of biometric data are collected, stored and used to support access and verification procedures.
                <hr />
                We will not collect sensitive information about you where this is expressly prohibited by local law.
                <hr />
                Sensitive information includes information relating to:
                <ul>
                  <li>race</li>
                  <li>political or religious beliefs</li>
                  <li>sexual orientation and sexual life</li>
                  <li>criminal convictions</li>
                  <li>membership of professional or trade associations or unions</li>
                  <li>biometric and health information</li>
                  <li>information about your affiliation with certain organisations, such as professional associations.</li>
                </ul>
                <hr />
                <span className="bold">Why we collect personal information</span>
                <hr />
                We collect personal information about you which is reasonably necessary to:
                <ul>
                  <li>provide you with quality products, services or transaction opportunities. This may include consumer facing services conducted by Macquarie Bank Limited and or its related entities (such as consumer banking, insurance or wealth advisory services) or services, advice or investment activity provided and/or undertaken by entities involved in the various corporate advisory and investment activities undertaken by Macquarie Group Limited and its related entities</li>
                  <li>consider applications and approaches you make to us</li>
                  <li>conduct marketing and social functions</li>
                  <li>maintain your contact details</li>
                  <li>fulfil our legal obligations, such as those relating to taxation and anti-money laundering and counter-terrorism financing or as otherwise authorised by you</li>
                </ul>
                Without such information, we may not be able to process your application or provide you with an appropriate level of service.  In such circumstances, we will process your application and provide you with the most appropriate level of service that we can.
                <hr />
                <span className="bold">How we collect your information</span>
                <hr />
                We collect personal information about you directly from you — this can be in person, in documents you give us, from telephone calls, emails, competitions you enter, your access to our website or from transactions you make. We take reasonable steps to be transparent about how and why we collect personal data.
                <hr />
                We may also collect your personal information from joint account holder(s) or third parties including public sources, your adviser(s), employer (or its affiliates), agents, introducers, referrers, brokers, our related companies and service providers (including credit reporting bodies, fraud and financial crime information exchanges  and other information service providers).
                <span className="bold">How we store your information</span>
                <hr />
                We keep personal information in physical and electronic records, at our premises and the premises of our service providers, which may include processing or storage in the cloud, which may mean in practice that this information is stored outside Australia. Where this occurs, we take steps to protect the security and integrity of personal information.
                <hr />
                We also keep records of our interactions with you (including by telephone, email and online) and of your transaction history.
                <hr />
                <span className="bold">How we use your information</span>
                <hr />
                We use personal information about you for the purpose for which it was provided to us, including to:
                <ul>
                  <li>process applications</li>
                  <li>administer and manage our products and services (including monitoring, auditing, and evaluating those products and services)</li>
                  <li>model and test data (in a controlled environment)</li>
                  <li>develop scores, risk assessments and related analytical tools</li>
                  <li>communicate with you and deal with or investigate any complaints or enquiries.</li>
                </ul>
                We may also:
                <ul>
                  <li>anonymise your data for our own purposes including market research and  new product development</li>
                  <li>use personal information about you for the prevention and mitigation of fraud and other financial crimes</li>
                  <li>use or permit our affiliates to use your personal information for related purposes to the extent that it is lawful to do so without your express permission. For example, we may from time to time use your personal information to inform you or your employer of investment opportunities or to provide information about products and services which we expect may be of interest to you. However, if you don't want to receive such communications you can tell us by using any of the methods listed below.</li>
                </ul>
                While we may sometimes - where it is lawful and with your permission if necessary - share personal information with companies we do business with (e.g. in product development, joint venture arrangements or distribution arrangements), we do not sell personal information for marketing purposes to other organisations or allow such companies to do this.
                <hr />
                <span className="bold">When your information is disclosed</span>
                <hr />
                Subject in all cases to local law, we may share personal information within the Macquarie Group, and may disclose personal information outside the Macquarie Group:
                <ul>
                  <li>to our service providers, who provide services in connection with our products and services (including archival, auditing, accounting, customer contact, legal, business consulting, banking, payment, delivery, data processing, data analysis, information broking, mailing, marketing, research, investigation, insurance, identity verification, brokerage, maintenance, trustee, securitisation, website and technology services)</li>
                  <li>to comply with any legal or regulatory obligation, such as those imposed by regulators, arising under anti-money laundering or anti-terrorism financing laws, or reasonably arising in connection with legal proceedings</li>
                  <li>to our affiliates</li>
                  <li>to your nominated financial adviser with your permission</li>
                  <li>for the prevention and mitigation of fraud and other financial crimes</li>
                  <li>as contained in the terms and conditions of any specific product or service.</li>
                </ul>
                We may also disclose your personal information (on a confidential basis) in connection with the undertaking of our principal investment activities including, but not limited to, acquisitions, mergers, and the management of any parts of our business or assets (including divestments), and the provision of any debt to another party.
                <hr />
                In some circumstances the parties to whom we disclose personal information may operate outside of Australia or, in the case of data collected within the United Kingdom or European Union, outside the United Kingdom and European Union — this includes locations in the countries listed in the Appendix. Where this occurs, we take steps to protect personal information against misuse or loss and to comply with local law in respect of the transfer of your data from one jurisdiction to another. Those parties, in turn, may make such information available to the governments of such other countries in accordance with local law requirements.
                <hr />
                <span className="bold">Keeping information accurate and up to date</span>
                <hr />
                We take reasonable steps to ensure that all information we hold is as accurate as possible. You are able to contact us at any time and ask for its correction if you feel the information, we have about you is inaccurate or incomplete.
                <hr />
                <span className="bold">Keeping information secure</span>
                <hr />
                We use security procedures and technology to protect the information we hold. Access to and use of personal information within Macquarie Group seeks to prevent misuse or unlawful disclosure of the information — this includes internal policies, auditing, training and monitoring of staff.
                <hr />
                If other organisations provide support services, we require them to take appropriate technical and organisational measures to secure the privacy of the information provided to them.
                <hr />
                <span className="bold">How you can access or correct your information</span>
                <hr />
                You can contact us to request access to or correction of your personal information. In normal circumstances we will give you full access or make the requested corrections to your information. However, there may be some legal or administrative reasons to deny these requests. If your request is denied, we will provide you with the reason why (if we can). Where we decide not to make a requested correction to your personal information and you disagree, you may ask us to make a note of your requested correction with the information.
                <hr />
                <span className="bold">Dealing with Macquarie Group online</span>
                <hr />
                When you visit Macquarie websites you will generally browse anonymously unless you have logged into a client portal or accessed the website from a personalised communication from Macquarie.
                <hr />
                For all visitors to our website, we use cookies and other web browser technologies to collect information such as the server your computer is logged on to, your browser type (for example, Internet Explorer, Chrome or Firefox), and your IP address. (An IP address is a number that is assigned to your computer automatically and required for using the Internet). We may also derive the location associated with an IP address.
                <hr />
                Once you have logged into a Macquarie portal, accessed our website from an e-mail or other personalised communication sent to you, or provided us with personal information by completing a form online, we may be able to identify you and we may combine that with other information in order to provide you with a better online experience. If you would prefer not to be identified, you can delete the cookies and reconfigure the cookie preferences on your internet browser (see below).
                <hr />
                <span className="bold">Cookies</span>
                <hr />
                A 'cookie' is a packet of information placed on a user's computer by a website for record keeping purposes. Cookies are generally used on Macquarie Group sites to:
                <ul>
                  <li>access online services - if you log into our secure areas on our website, we will use cookies to authorise your access and save your preferences</li>
                  <li>manage advertising - when you see one of our ads on a third-party website, cookies are sometimes used to collect anonymous information about the page you visit and the type of software you are using</li>
                  <li>monitor traffic - we use anonymous information to track how people are using the Macquarie site. This may include time of visit, pages visited and some system information about the type of computer you are using</li>
                  <li>monitor your use of our website - if we have identified you as a result of you logging into a Macquarie client portal, accessing our website from a personalised communication or providing us with personal information by completing a form online, we may use cookies to collect personal information about you.</li>
                </ul>
                You can configure your browser to accept all cookies, reject all cookies, or notify you when a cookie is sent. (Each browser is different, so check the Help menu of your browser to learn how to change your cookie preferences).
                <hr />
                If you disable the use of cookies on your web browser or remove or reject specific cookies from Macquarie Group websites or linked sites, then you may not be able to gain access to all of the content and facilities on those websites.
                <hr />
                <span className="bold">Your rights if you are in the European Union</span>
                <hr />
                As provided for in the General Data Protection Regulation, you have the right to access your data; to correct or rectify your data; to delete your data subject to applicable law; to have your data processed only in accordance with applicable law; to have copies of your data to be moved to another controller; to object to our processing your data otherwise than in accordance with the law; and to withdraw any consent to our processing your data at any time. Please email
                <a href="mailto:privacy@macquarie.com">privacy@macquarie.com</a>
                to exercise any of those rights.
                <hr />
                <span className="bold">Contact us</span>
                <hr />
                If you have any questions or complaints regarding privacy or information handling, please write to privacy@macquarie.com or contact us by phone. Please mark communications to the attention of our Privacy Officer. We will respond to let you know who will be handling your matter and when you can expect a further response.
                <hr />
                If your concerns are not resolved to your satisfaction, you may be able to escalate your complaint to an external dispute resolution scheme or to a regulatory authority (if you are in the European Union or United Kingdom, you may contact one of the data protection regulators found here:
                <a href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080" target="_blank">http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080</a>
                ). We can also provide details of the appropriate scheme/regulator for you, as these may vary by jurisdiction or product.
                <hr />
                Policy updated May 2020
                <hr />
                <span className="bold">Appendix — Disclosure to other countries</span>
                <hr />
                Personal information may be disclosed to Macquarie Group entities and third parties in jurisdictions including: Australia; Austria; Brazil; Canada; China; Denmark; France; Germany; Hong Kong; India; Indonesia; Ireland; Japan; Luxembourg; Malaysia; Mexico; Netherlands; New Zealand; Norway; Philippines; Singapore; South Africa; South Korea; Spain; Switzerland; Taiwan; Thailand; United Arab Emirates; United Kingdom; United States of America.
              </div>
              <div className="disclaimer_btn" style={{ textAlign: 'right' }}>
                <div className="btn btn-primary back-btn" style={{ marginRight: '5px' }}>Back</div>
                <div className="btn btn-primary close-btn" onClick={() => { this.setStorage() }}>Accept</div>
              </div>
            </div>
          </div>
        </div >
      </div>
    );
  }
}
// class SplashPage extends Component {
//   constructor() {
//     super();
//     this.state = {
//       show: false,
//       isShow: false
//     };
//   }
//   show(value, n) {
//     this.setState({
//       show: value
//     })

//     if (n) {
//       localStorage.setItem('SplashPage', 'none')
//     }
//   }

//   changeShow(i) {
//     this.setState({ isShow: i });
//   }
//   componentDidMount() {
//     if (localStorage.getItem('SplashPage') == null) {
//       this.setState({
//         show: true
//       })
//     }
//   }
//   render() {
//     return (
//       <div className="visible-md visible-lg">
//         <div id="ppBoxHTML1" className="overlay indexModal" style={{ display: this.state.show ? 'block' : 'none' }}>
//           <div className="overlay-inner" style={{ background: '#F5F6F7', width: '1000px', margin: '18vh auto' }}>
//             <div className='newpopup_image_box' style={{ height: 'auto', position: 'relative' }}>
//               {/* <img src="/img/education/icon-close-black.png" style={{ right: ' 12px', top: '5px' }} className='btn-closes' alt="" onClick={() => { this.show(false, true) }} /> */}
//               <p style={{ width: 'auto', height: 'auto', right: ' 12px', top: '5px', textDecoration: 'underline' }} className='btn-closes' alt="" onClick={() => { this.show(false, true) }}>Do not show again</p>
//               <img src="/img/home/MicrosoftTeams-image__24_.png" className='image' alt="" style={{ width: '100%' }} />
//               <p style={{ width: 'auto', height: 'auto', bottom: '0px', right: '10px', top: 'auto', textDecoration: 'underline' }} className='btn-closes' alt="" onClick={() => { this.show(false, false) }}>Show me again next time</p>
//               {/* <img src="/img/home/qwewqe.png" style={{ width: '40px', height: '40px', bottom: '0px', right: '10px', top: 'auto' }} className='btn-closes' alt="" onClick={() => { this.show(false, false) }} /> */}
//               <a href="/tools/selector" style={{
//                 position: 'absolute',
//                 left: '193px',
//                 top: '222px',
//                 display: 'block',
//                 width: '115px',
//                 height: '32px',
//               }}></a>

//               <a href="/tools/livematrix" style={{
//                 position: 'absolute',
//                 left: '193px',
//                 top: '341px',
//                 display: 'block',
//                 width: '115px',
//                 height: '32px',
//               }}></a>

//               <a href="/tools/warrantsearch" style={{
//                 position: 'absolute',
//                 left: '193px',
//                 top: '449px',
//                 display: 'block',
//                 width: '120px',
//                 height: '32px',
//               }}></a>

//               <a href="/marketnews/subscribe" style={{
//                 position: 'absolute',
//                 left: '365px',
//                 top: '128px',
//                 display: 'block',
//                 width: '120px',
//                 height: '32px',
//               }}></a>

//               <a href="/WarrantsGuidebook" style={{
//                 position: 'absolute',
//                 left: '686px',
//                 top: '119px',
//                 display: 'block',
//                 width: '120px',
//                 height: '42px',
//               }}></a>

//               <a href="/education/warrantvideos" style={{
//                 position: 'absolute',
//                 left: '825px',
//                 top: '119px',
//                 display: 'block',
//                 width: '120px',
//                 height: '42px',
//               }}></a>

//               <a href="/marketdata/topgainloss" style={{
//                 position: 'absolute',
//                 left: '689px',
//                 top: '350px',
//                 display: 'block',
//                 width: '120px',
//                 height: '42px',
//               }}></a>

//               <a href="/marketdata/turnoverdist" style={{
//                 position: 'absolute',
//                 left: '832px',
//                 top: '350px',
//                 display: 'block',
//                 width: '120px',
//                 height: '42px',
//               }}></a>

//               <a style={{
//                 position: 'absolute',
//                 left: '688px',
//                 top: '677px',
//                 display: 'block',
//                 width: '44px',
//                 height: '43px',
//               }} onClick={() => this.changeShow(true)}></a>

//               <a href="https://www.facebook.com/MacquarieWarrantsSG/?utm_source=MQ&utm_medium=Icon&utm_campaign=FB" style={{
//                 position: 'absolute',
//                 left: '745px',
//                 top: '677px',
//                 display: 'block',
//                 width: '44px',
//                 height: '43px',
//               }}></a>

//               <a href="https://t.me/MacquarieWarrantsSG" style={{
//                 position: 'absolute',
//                 left: '799px',
//                 top: '677px',
//                 display: 'block',
//                 width: '44px',
//                 height: '43px',
//               }}></a>

//               <a href="https://bit.ly/34Bgg1t" style={{
//                 position: 'absolute',
//                 left: '856px',
//                 top: '677px',
//                 display: 'block',
//                 width: '44px',
//                 height: '43px',
//               }}></a>
//             </div>
//           </div>
//         </div>
//         <div
//           className="popup-customized"
//           style={{ display: this.state.isShow ? "table" : "none" }}
//           onClick={() => this.changeShow(false)}
//         >
//           <div>
//             <div className="hlbox">
//               <div className="hlbox-close"></div>
//               <div className="hl-txt">Hotline</div>
//               <a href="tel:+65 6601 0289" className="btn btn-primary">
//                 <span
//                   className="hl-icon"
//                   onClick={() => this.changeShow(false)}
//                 ></span>
//                 <span className="hl-num">+65 6601 0289</span>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>

//     );
//   }
// }
class SplashPage extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      count: 10
    };
  }
  show(value) {
    this.setState({
      show: value
    })
  }
  updatePopuptime = () => {
    try {
      var c = 10;
      var ninterval = setInterval(() => {
        this.setState({ count: c });
        if (c === 0) {
          clearInterval(ninterval);
          this.show(false)
        }
        c--;
      }, 1000);
    } catch (error) { }
  };
  componentDidMount() {
    this.updatePopuptime()
  }
  render() {
    return (
      <div className="SplashPage_container">
        <div id="ppBoxHTML1" className="overlay indexModal" style={{ display: this.state.show ? 'table' : 'none' }}>
          <div className="overlay-inner" style={{ background: '#F5F6F7', margin: '25vh auto' }}>
            <div className='newpopup_image_box' style={{ height: 'auto', position: 'relative' }}>
              <p style={{ width: 'auto', height: 'auto', right: ' 3px', top: '0px', color: '#fff' }} className='btn-closes' alt="">Closes after <span>{this.state.count}</span> seconds...</p>
              <a href="/education/ShortFormVideos">
                <img src="/img/home/Splash_Page.jpg" className='image' alt="" style={{ width: '100%', maxHeight: '385px' }} />
              </a>
              <p style={{ width: 'auto', height: 'auto', right: 'auto', top: '-2px', left: '6px', color: '#fff' }} className='btn-closes  btn-left' alt="" onClick={() => { this.show(false) }}>X</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
