import React, { Component } from "react";
import AmCharts from "@amcharts/amcharts3-react";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import LiveMatrix from "../../../components/liveMatrix";
import MoneyFlow from "../../../components/moneyFlow";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./dwturnover.css";

export default class DWTurnover extends Component {
  constructor() {
    super();
    this.state = {
      dwturnoverData: null,
      lastUpdate: null,
    };
  }

  componentDidMount() {
    this.onFetchData();
    document.title = "Warrant market turnover | Singapore Warrants | Macquarie"
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("DWTurnover");
    if (staticData !== null) {
      const lastUpdate = this.getLastUpdateString();
      this.setState({ dwturnoverData: staticData, lastUpdate });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("DWTurnover")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let dwturnoverData = null;
    let lastUpdate = "";
    const url = MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=dwturnover";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          dwturnoverData = result;
          lastUpdate = this.getLastUpdateString();
          this.setState({ dwturnoverData, lastUpdate });
        },
        (error) => {
          this.setState({ dwturnoverData });
        }
      );
  }

  getLastUpdateString() {
    // 09 Mar 21 14:24
    const currentTimestamp = new Date();
    const day = currentTimestamp.getDate();
    const month = MQSGUtil.getMonthString(currentTimestamp.getMonth());
    const hr = currentTimestamp.getHours();
    const min = currentTimestamp.getMinutes();
    const year = currentTimestamp.getFullYear().toString().substring(2);
    return day + " " + month + " " + year + " " + hr + ":" + min;
  }

  render() {
    return (
      <div id="dwturnover" className="pageobj">
        <BreadcrumbBar
          link="/home/warrantstats"
          group="Warrant statistics"
          title="Warrant market turnover"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="topimg hidden-sm hidden-xs">
            <img src="/img/bg/top-marketturnover.jpg" alt="" />
          </div>
          <div className="row">
            <div className="col-md-8" style={{ paddingLeft: 0 }}>
              <Section
                dwturnoverData={this.state.dwturnoverData}
                lastUpdate={this.state.lastUpdate}
              />
            </div>
            <div className="hidden-xs hidden-sm col-md-4" style={{ padding: 0 }}>
              <LiveMatrix />
              {/* <MoneyFlow />
               */}
              <AdvertBox />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class TurnoverTotal extends Component {
  getRows() {
    const rows = [];

    const dwturnoverData = this.props.dwturnoverData;
    if (dwturnoverData !== null) {
      if (
        "dwturnover" in dwturnoverData &&
        "all" in dwturnoverData.dwturnover
      ) {
        const turnoverAll = dwturnoverData.dwturnover["all"];
        if (turnoverAll !== null && Array.isArray(turnoverAll)) {
          turnoverAll.forEach((turnover, index) => {
            const statsdateobj = MQSGUtil.getData(turnover, "stats_date_obj");
            const total = MQSGUtil.getData(turnover, "total");
            const dw = MQSGUtil.getData(turnover, "dw");
            const pct = MQSGUtil.getData(turnover, "pct") + "%";

            const column1 = <td className="stats_date_obj">{statsdateobj}</td>;
            const column2 = <td className="total">{total}</td>;
            const column3 = <td className="dw">{dw}</td>;
            const column4 = <td className="pct">{pct}</td>;

            const row = (
              <tr className="rowtmpl" idx={index} key={"total_" + index}>
                {column1}
                {column2}
                {column3}
                {column4}
              </tr>
            );
            rows.push(row);
          });
        }
      }
    }
    return rows;
  }

  render() {
    const lastUpdate = MQSGUtil.getData(this.props, "lastUpdate");

    return (
      <>
        <div className="tblh">
          <div className="tblh-left">
            <h2 className="small">Warrant market turnover - Total</h2>
          </div>
        </div>
        <div id="totalTbl" className="tbl" style={{overflowX: "scroll"}}>
          <table className="table table-striped table-closed turnoverTable">
            <thead>
              <tr style={{ fontSize: "16px" }}>
                <th>Date</th>
                <th>Total market turnover (SGD)</th>
                <th>Total warrant turnover (SGD)</th>
                <th>Total warrant turnovers as % of market turnover</th>
              </tr>
            </thead>
            <tbody>{this.getRows()}</tbody>
          </table>
          <p className="small">
            Last updated: <span className="lastupdate">{lastUpdate}</span>
          </p>
        </div>
      </>
    );
  }
}

class TurnoverIndex extends Component {
  getRows() {
    const rows = [];

    const dwturnoverData = this.props.dwturnoverData;
    if (dwturnoverData !== null) {
      if (
        "dwturnover" in dwturnoverData &&
        "index" in dwturnoverData.dwturnover
      ) {
        const turnoverIndex = dwturnoverData.dwturnover["index"];
        if (turnoverIndex !== null && Array.isArray(turnoverIndex)) {
          turnoverIndex.forEach((turnover, index) => {
            const statsdateobj = MQSGUtil.getData(turnover, "stats_date_obj");
            const total = MQSGUtil.getData(turnover, "total");
            const dw = MQSGUtil.getData(turnover, "dw");
            const pct = MQSGUtil.getData(turnover, "pct") + "%";

            const column1 = <td className="stats_date_obj">{statsdateobj}</td>;
            const column2 = <td className="total">{total}</td>;
            const column3 = <td className="dw">{dw}</td>;
            const column4 = <td className="pct">{pct}</td>;

            const row = (
              <tr className="rowtmpl" idx={index} key={"index_" + index}>
                {column1}
                {column2}
                {column3}
                {column4}
              </tr>
            );
            rows.push(row);
          });
        }
      }
    }
    return rows;
  }

  render() {
    const lastUpdate = MQSGUtil.getData(this.props, "lastUpdate");

    return (
      <>
        <div className="tblh">
          <div className="tblh-left">
            <h2 className="small">Warrant market turnover - Index</h2>
          </div>
        </div>
        <div id="indexTbl" className="tbl" style={{overflowX: "scroll"}}>
          <table className="table table-striped table-closed turnoverTable">
            <thead>
              <tr style={{ fontSize: "16px" }}>
                <th>Date</th>
                <th>Total warrant market turnover (SGD)</th>
                <th>Total index warrant turnover (SGD)</th>
                <th>
                  Index warrant turnover as % of warrant <br />
                  market turnover
                </th>
              </tr>
            </thead>
            <tbody>{this.getRows()}</tbody>
          </table>
          <p className="small">
            Last updated: <span className="lastupdate">{lastUpdate}</span>
          </p>
        </div>
      </>
    );
  }
}

class TurnoverStock extends Component {
  getRows() {
    const rows = [];

    const dwturnoverData = this.props.dwturnoverData;
    if (dwturnoverData !== null) {
      if (
        "dwturnover" in dwturnoverData &&
        "stock" in dwturnoverData.dwturnover
      ) {
        const turnoverStock = dwturnoverData.dwturnover["stock"];
        if (turnoverStock !== null && Array.isArray(turnoverStock)) {
          turnoverStock.forEach((turnover, index) => {
            const statsdateobj = MQSGUtil.getData(turnover, "stats_date_obj");
            const total = MQSGUtil.getData(turnover, "total");
            const dw = MQSGUtil.getData(turnover, "dw");
            const pct = MQSGUtil.getData(turnover, "pct") + "%";

            const column1 = <td className="stats_date_obj">{statsdateobj}</td>;
            const column2 = <td className="total">{total}</td>;
            const column3 = <td className="dw">{dw}</td>;
            const column4 = <td className="pct">{pct}</td>;

            const row = (
              <tr className="rowtmpl" idx={index} key={"stock_" + index}>
                {column1}
                {column2}
                {column3}
                {column4}
              </tr>
            );
            rows.push(row);
          });
        }
      }
    }
    return rows;
  }

  render() {
    const lastUpdate = MQSGUtil.getData(this.props, "lastUpdate");

    return (
      <>
        <div className="tblh">
          <div className="tblh-left">
            <h2 className="small">Warrant market turnover - Single stock</h2>
          </div>
        </div>
        <div id="stockTbl" className="tbl" style={{overflowX: "scroll"}}>
          <table className="table table-striped table-closed turnoverTable">
            <thead>
              <tr style={{ fontSize: "16px" }}>
                <th>Date</th>
                <th>Total warrant market turnover (SGD)</th>
                <th>Total single stock warrant turnover (SGD)</th>
                <th>
                  Single stock warrant turnover as % of warrant market turnover
                </th>
              </tr>
            </thead>
            <tbody>{this.getRows()}</tbody>
          </table>
          <p className="small">
            Last updated: <span className="lastupdate">{lastUpdate}</span>
          </p>
        </div>
      </>
    );
  }
}

class Section extends Component {
  render() {
    return (
      <div className="section">
        <div className="page-header">
          <h2>Warrant market turnover</h2>
        </div>
        <p>
          How active was the warrant market today and how did it compared to
          previous days?
        </p>
        {/* total */}
        <TurnoverTotal
          dwturnoverData={this.props.dwturnoverData}
          lastUpdate={this.props.lastUpdate}
        />
        {/* chart */}
        <div className="chartbox">
          <div className="tblh">
            <div className="tblh-left">
              <h2 className="small">Total warrants turnover (SGD)</h2>
            </div>
          </div>
          <div style={{overflowX:'auto'}}>
          <div id="dwturnoverChart" className="chartbox-bars">
            <TurnoverTotalChart dwturnoverData={this.props.dwturnoverData} />
          </div>
          </div>
        </div>
        {/* index */}
        <TurnoverIndex
          dwturnoverData={this.props.dwturnoverData}
          lastUpdate={this.props.lastUpdate}
        />
        {/* stock */}
        <TurnoverStock
          dwturnoverData={this.props.dwturnoverData}
          lastUpdate={this.props.lastUpdate}
        />
      </div>
    );
  }
}

class TurnoverTotalChart extends Component {
  parseTurnOverChartData() {
    let chartdata = null;
    const dwturnoverData = this.props.dwturnoverData;
    if (dwturnoverData !== null) {
      if (
        "dwturnover" in dwturnoverData &&
        "all" in dwturnoverData.dwturnover
      ) {
        const turnoverAll = dwturnoverData.dwturnover["all"];
        if (turnoverAll !== null && Array.isArray(turnoverAll)) {
          const turnonverAllReverse = turnoverAll.reverse();
          turnonverAllReverse.forEach((turnover) => {
            if (chartdata === null) {
              chartdata = { dataProvider: [] };
            }
            const datapoint = {
              category: "",
              "column-1": 0,
              "category-xs": "",
            };
            const dw = MQSGUtil.getNumber(turnover, "dw", null, 2, 1000000);
            const statsdateobj = MQSGUtil.getData(turnover, "stats_date_obj");
            if (statsdateobj.length > 7 && dw !== null) {
              datapoint["category"] = statsdateobj.substring(0, 7);
              datapoint["category-xs"] = statsdateobj.substring(0, 2);
              datapoint["column-1"] = dw.toString();
              chartdata.dataProvider.push(datapoint);
            }
          });
        }
      }
    }
    return chartdata;
  }

  render() {
    const chartdata = this.parseTurnOverChartData();
    if (chartdata === null) {
      return (
        <div className="nochart">
          <span className="nochart-txt">No related data</span>
        </div>
      );
    }
    const options = {
      type: "serial",
      categoryField: "category",
      fontFamily: "HelveticaNeueLight",
      panEventsEnabled: false,
      sequencedAnimation: false,
      colors: [
        "#F7941E",
        "#FCD202",
        "#B0DE09",
        "#0D8ECF",
        "#2A0CD0",
        "#CD0D74",
        "#CC0000",
        "#00CC00",
        "#0000CC",
        "#DDDDDD",
        "#999999",
        "#333333",
        "#990000",
      ],
      fontSize: 12,
      categoryAxis: {
        gridPosition: "start",
        axisColor: "#CCCCCC",
        color: "#333333",
        centerLabels: true,
        gridThickness: 0,
        tickLength: 0,
        forceShowField: true,
        minHorizontalGap: 35,
        fontSize: 11,
        // "labelFrequency": (this.screenSize === "xs")? 2 : 1
        labelFrequency: 1
      },
      trendLines: [],
      graphs: [
        {
          balloonText: "[[category]]:[[value]] M",
          fillAlphas: 1,
          id: "AmGraph-1",
          title: "graph 1",
          type: "column",
          valueField: "column-1",
        },
      ],
      guides: [],
      valueAxes: [
        {
          axisFrequency: 4,
          baseValue: 3,
          id: "ValueAxis-1",
          axisColor: "#CCCCCC",
          gridCount: 15,
          showFirstLabel: false,
          tickLength: 0,
          title: "-",
          titleColor: "#FFFFFF",
          titleFontSize: 8,
          precision: 0,
          minimum: 0,
          maximum: 20
        },
      ],
      allLabels: [
        {
          id: "Label-mil",
          text: "0 (mil)",
          x: 20,
          y: 330,
        },
      ],
      balloon: {},
      titles: [],
      dataProvider: chartdata.dataProvider,
    };
    const style = {
      width: "100%",
      height: "100%",
    };
    return <AmCharts.React style={style} options={options} />;
  }
}

// "labelFrequency": (this.screenSize === "xs")? 2 : 1
