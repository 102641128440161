import React, { Component } from 'react'

export default class subscribe extends Component {
    render() {
        return (
            <div className="section qlink-section">
                <div className="page-header">
                    <h2 className="small">Subscribe</h2>
                </div>
                <p>
                    Don't want to miss any important news? Subscribe to our newsletter today!
                </p>
                <div className="section-qlink-img section-qlink-subscribe">
                    <a href="/marketnews/subscribe" className="section-qlink-achor">
                        <img src="/img/bullet/qlink-achor.png" />
                    </a>
                </div>
            </div>
        )
    }
}
