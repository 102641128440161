import React, { Component } from "react";
import MQSGUtil from "../../../components/mqsgUtil";
import "./IndexModal.css";

export default class IndexModal extends Component {
    constructor(props) {
        super(props);
    }
    setShow() {
        this.props.setShow(false)
    }
    render() {
        return (
            <div id="ppBoxHTML" className="overlay indexModal" style={{ display: this.props.show ? 'block' : 'none' }}>
                <div className="overlay-inner" style={{ background: '#fff', position: 'relative', maxHeight: '70%', overflowY: 'auto' }}>
                    <img
                        className="btn-close"
                        style={{}}
                        src="/img/education//icon-close-black.png"
                        onClick={() => this.setShow()}
                    />
                    <div style={{ padding: '60px' }}>
                        {/* <WhyTarde /> */}
                        {/* <WarrantIssue /> */}
                        {/* <HowCalculate /> */}
                        {/* <HowTarde /> */}
                        {this.props.keyValue == "Why-trade-index-warrants" && <WhyTarde />}
                        {this.props.keyValue == "How-to-trade-index-warrants" && <HowTarde />}
                        {this.props.keyValue == "How-to-calculate" && <HowCalculate />}
                        {this.props.keyValue == "Index-warrants-issued-mq" && <WarrantIssue />}
                    </div>


                </div>
            </div>
        )
    }

}
class WhyTarde extends Component {
    render() {
        return (
            <div style={{ color: '#000' }}>
                <h2 className="ppc-h">
                    Why trade index warrants?
                </h2>
                <p>The key advantages of index warrants are:</p>
                <ol>
                    <li>
                        <h5 className="text-bold">Allow you to take a view on the general market direction</h5>
                        <p>
                            By gaining access to the index, you remove the need to select a share and instead take a view on the market as a whole.
                        </p>
                    </li>
                    <li>
                        <h5 className="text-bold">Able to profit from both rises and falls</h5>
                        <p>
                            Index warrants allow you to take either a bullish (call warrant) or a bearish (put warrant) view on the market. So you have the potential to profit from both rises and falls in the market.
                        </p>
                    </li>
                    <li>
                        <h5 className="text-bold">Get exposure to different markets</h5>
                        <p>
                            Index warrants allow you to get exposure to foreign markets like Hong Kong (e.g. the HSI Index) without having to leave the SGX.
                        </p>
                    </li>
                    <li>
                        <h5 className="text-bold">Hedge your share portfolio</h5>
                        <p>
                            Index put warrants allow you the potential to profit from downward movements in the market, making them a useful hedge for a diversified share portfolio.
                        </p>
                    </li>
                </ol>
            </div>
        )
    }
}
class HowTarde extends Component {
    render() {
        return (
            <div>
                <h2 className="ppc-h">
                    How to trade index warrants?
                </h2>
                <h5 className="text-bold">Index call warrant</h5>
                <p>If you have a bullish view on the Hang Seng Index, you can buy a HSI call warrant Example as follows:</p>
                <img className="ppc-table" src="/img/education/Index_table1.png" style={{ maxWidth: '435px', width: '100%' }} />
                <h5 className="text-bold">Index put warrant</h5>
                <p>If you have a bearish view on the S&P Index, you can buy a S&P put warrant. Example as follows:</p>
                <img className="ppc-table" src="/img/education/Index_table2.png" style={{ maxWidth: '435px', width: '100%' }} />
                <p>
                    Assuming your view was right, if you have bought the put warrant instead of shorting the underlying index, you would have made a 36.4% return, more than 5 times that of the index level move.<br /><br />
                    These are examples of gearing at work and the advantage that warrants offer i.e. a greater potential profit as a percentage of your invested capital. However, gearing works in both directions, so greater potential returns also come with greater risk.
                </p>
            </div>
        )
    }
}
class HowCalculate extends Component {
    render() {
        return (
            <div>
                <h2>How to calculate the cash settlement amount at expiry?</h2>
                <p>
                    Your index warrant will be automatically exercised if you hold it until expiry. If the cash settlement amount is positive, Macquarie will make a cheque payment to you within 7 market days after expiry.
                </p>
                {/* <img style={{ maxWidth: "574px" }} src="/img/education/graphnew5.png" /> */}
                <div className="howCalculate_con">
                    <span className="howCalculate_title">Index call warrant expiry formula:</span>
                    <div className="flex_row howCalculate_count">
                        <span className="howCalculate_left">Value at expiry =</span>
                        <div className="flex_column howCalculate_center">
                            <span className="howCalculate_underline">Futures’ settlement level – Strike</span>
                            <span>Warrants Per Share</span>
                        </div>
                        <span className="howCalculate_right">x <label />FX rate</span>
                    </div>
                    <span className="howCalculate_title howCalculate_title2">Index put warrant expiry formula:</span>
                    <div className="flex_row howCalculate_count">
                        <span className="howCalculate_left">Value at expiry =</span>
                        <div className="flex_column howCalculate_center">
                            <span className="howCalculate_underline">Strike – Futures’ settlement level</span>
                            <span>Warrants Per Share</span>
                        </div>
                        <span className="howCalculate_right">x <label />FX rate</span>
                    </div>
                </div>
                <p>The FX rate component takes into consideration the underlying indexes that are denominated in a foreign currency and not SGD.</p>
                {/* <img style={{ maxWidth: "505px" }} src="/img/education/graphnew6.png" /> */}
                <div style={{ overflowX: 'auto' }}>
                    <h5 className="text-bold" style={{ whiteSpace: 'nowrap' }}>Example of an S&amp;P 500® call warrant:</h5>
                    <div className="howCalculate_example">
                        <div className="flex_row">
                            <span>Expiry Date:</span>
                            <span>16 June</span>
                        </div>
                        <div className="flex_row">
                            <span>Warrants Per Share:</span>
                            <span>5,000</span>
                        </div>
                        <div className="flex_row">
                            <span>Strike:</span>
                            <span>3,000</span>
                        </div>
                        <div className="flex_row">
                            <span>Futures settlement level:</span>
                            <span>4,000</span>
                        </div>
                        <div className="flex_row">
                            <span>FX rate (USDSGD):</span>
                            <span>1.30</span>
                        </div>
                    </div>
                    <div className="flex_row howCalculate_count howCalculate_example_count">
                        <span className="howCalculate_left">Value of call warrant at expiry =</span>
                        <div className="flex_column howCalculate_center">
                            <span className="howCalculate_underline">4,000 – 3,000</span>
                            <span>5,000</span>
                        </div>
                        <span className="howCalculate_right">x <label />1.30</span>
                    </div>
                    <span className="howCalculate_example_total">= SGD 0.260</span>
                </div>
                {/* <img style={{ maxWidth: "522px" }} src="/img/education/graphnew7.png" /> */}
                <div style={{ overflowX: 'auto' }}>
                    <h5 className="text-bold" style={{ whiteSpace: 'nowrap' }}>Example of an HSI put warrant:</h5>
                    <div className="howCalculate_example">
                        <div className="flex_row">
                            <span>Expiry Date:</span>
                            <span>28 December</span>
                        </div>
                        <div className="flex_row">
                            <span>Warrants Per Share:</span>
                            <span>1,200</span>
                        </div>
                        <div className="flex_row">
                            <span>Strike:</span>
                            <span>22,000</span>
                        </div>
                        <div className="flex_row">
                            <span>Futures settlement level:</span>
                            <span>20,800</span>
                        </div>
                        <div className="flex_row">
                            <span>FX rate (HKDSGD):</span>
                            <span>0.170</span>
                        </div>
                    </div>
                    <div className="flex_row howCalculate_count howCalculate_example_count">
                        <span className="howCalculate_left">Value of call warrant at expiry =</span>
                        <div className="flex_column howCalculate_center">
                            <span className="howCalculate_underline">22,000 – 20,800</span>
                            <span>1,200</span>
                        </div>
                        <span className="howCalculate_right">x <label />0.170</span>
                    </div>
                    <span className="howCalculate_example_total">= SGD 0.170</span>
                </div>
            </div>
        )
    }
}
class WarrantIssue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: null
        };
    }
    componentDidMount() {
        this.getTableData()
    }
    getTableData() {
        var qid = Date.now()
        const url =
            MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=index_sortbyname&lmt=all&qid=" + qid;
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({ result: result.ranking })
                },
                (error) => {

                }
            );
    }
    render() {
        return (
            <div style={{ overflowX: 'auto' }}>
                <h2>Index warrants issued by Macquarie</h2>
                <table id="warranttbl">
                    <thead>
                        <tr>
                            <th>Warrant Name</th>
                            {/* <th>Underlying</th> */}
                            <th>Type</th>
                            <th >Last bid price</th>
                            <th className="hidden-xs">Change</th>
                            <th className="hidden-xs">Change (%)</th>
                            <th >Volume ('000)</th>
                            <th>Expiry</th>
                            <th >Last trading date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.result && this.state.result.map((d) =>
                            <tr key={d.dsply_nmll}>
                                <td><a target="_blank" href={`${"/tools/livematrix/"}${d.ticker}`}>{d.dsply_nmll}</a></td>
                                {/* <td ><a target="_blank" href={`/sg/tools/underlying/${d.underlying_ric}`}>{d.underlying_ticker}</a></td> */}
                                <td >{d.type == 'PUT' ? "P" : 'C'}</td>
                                <td >{d.BID}</td>
                                <td className={d.BID_NETCHNG < 0 ? "hidden-xs col_BID_NETCHNG down" : d.BID_NETCHNG == "-" ? "hidden-xs col_BID_NETCHNG" : "hidden-xs col_BID_NETCHNG up"}>{d.BID_NETCHNG}</td>
                                <td className={d.BID_PCTCHNG < 0 ? "hidden-xs col_BID_PCTCHNG down" : d.BID_PCTCHNG == "-" ? "hidden-xs col_BID_PCTCHNG" : "hidden-xs col_BID_PCTCHNG up"}>{d.BID_PCTCHNG}</td>
                                <td >{d.ACVOL_1_SCALED}</td>
                                <td >{d.maturity}</td>
                                <td >{d.last_trading_date}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}