import React, { Component } from "react";
import "./indexwarrants.css";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IndexModal from "./IndexModal";
import DataModal from "./DataModal";
import SwiperPagination from "../../../components/swiperpagination";
import MQSGUtil from "../../../components/mqsgUtil";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-6VGY7GKL9V');
export default class Indexwarrants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: null,
      show: false,
      keyValue: null,
      sliderShow: false,
      settings: {
        infinite: true,
        slidesToShow: 5,
        speed: 500,
        slidesToScroll: 1,
        swipeToSlide: true,
        lazyLoad: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '10%',
            }
          }, {
            breakpoint: 900,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '0%',
            }
          }, {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '15%',
            }
          }, {
            breakpoint: 550,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '15%',
            }
          }, {
            breakpoint: 400,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: true,
              centerPadding: '0%',
            }
          }],


        nextArrow: (
          <div>
            <div className="next-slick-arrow">
              {" "}
              <i className="fa fa-angle-right"></i>{" "}
            </div>
          </div>
        ),
        prevArrow: (
          <div>
            <div className="prev-slick-arrow">
              {" "}
              <i className="fa fa-angle-left"></i>{" "}
            </div>
          </div>
        ),
      },
      keyData: {
        key1: "Why-trade-index-warrants",
        key2: "How-to-trade-index-warrants",
        key3: "How-to-calculate",
        key4: "Index-warrants-issued-mq",
      },
      Image: [
        {
          value: 1,
          src: "/img/education/nasdq_tile.png",
          text: "The NASDAQ-100 Index",
        },

        {
          value: 2,
          src: "/img/education/dow_tile.png",
          text: "The Dow Jones Industrial Average Index",
        },
        {
          value: 3,
          src: "/img/education/hsti_tile.png",
          text: "Hang Seng Index",
        },
        {
          value: 4,
          src: "/img/education/hktech_tile.png",
          text: "Hang Seng TECH Index",
        },
        {
          value: 5,
          src: "/img/education/Index_warrants_SG_MCSI.png",
          text: "MSCI Singapore Free Index",
        },
        {
          value: 6,
          src: "/img/education/Index_warrants_SG_Nikkei.png",
          text: "Nikkei 225 Index",
        },
        {
          value: 7,
          src: "/img/education/us_tile.png",
          text: "The Standard & Poor's 500 Index",
        },
      ],
      gtag: [
        {
          category: 'Nasdaq-100_Index',
          action: 'Rated an App',
          label: 'Index',
          Event_category: 'Nasdaq-100_Index',
          event_label: 'Index',
        },
        {
          category: 'Dow Jones_Index',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'Dow Jones_Index',
          event_label: 'Index',
        },
        {
          category: 'Hang Seng_Index',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'Hang Seng_Index',
          event_label: 'Index',
        },
        {
          category: 'Hang Seng Tech_Index',
          action: 'Rated an App',
          label: 'Index',
          Event_category: 'Hang Seng Tech_Index',
          event_label: 'Index',
        },
        {
          category: 'Singapore Free_Index',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'Singapore Free_Index',
          event_label: 'Index',
        },
        {
          category: 'Nikkei-225_Index',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'Where_futures',
          event_label: 'Index',
        },
        {
          category: 'ButtonClick',
          label: 'Subscribe',
          action: 'Rated an App',
          Event_category: 'ButtonClick',
          event_label: 'Subscribe',
        },
        {
          category: 'Why_index_warrants',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'Why_index_warrants',
          event_label: 'Index',
        },
        {
          category: 'How_index_warrants',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'How_index_warrants',
          event_label: 'Index',
        },
        {
          category: 'How_calculate',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'How_calculate',
          event_label: 'Index',
        },
        {
          category: 'Macquarie_issued_index',
          label: 'Index',
          action: 'Rated an App',
          Event_category: 'Macquarie_issued_index',
          event_label: 'Index',
        }
      ],
      highlightdata: null,
      startDate: null,
    };
  }
  componentDidMount() {
    this.onFetchMmbData()
    const match = MQSGUtil.getData(this.props, "match", null);
    if (match !== null) {
      const params = MQSGUtil.getData(match, "params", null);
      if (params !== null) {
        const ticker = MQSGUtil.getData(params, "ticker", null);
        if (ticker !== null) {
          this.ticker = ticker.toUpperCase();
          var val = this.ticker == 'HSI'
            ? 3
            : this.ticker == 'HSTECH'
              ? 4
              : this.ticker == 'NDX'
                ? 1
                : this.ticker == 'SP500'
                  ? 7
                  : this.ticker == 'N225'
                    ? 6
                    : this.ticker == 'DJIA'
                      ? 2
                      : this.ticker == 'STI'
                        ? 5
                        : null;
          val && this.setValue(true, val)
        }
      }
    }
  }
  onFetchMmbData(startDateString = null) {
    if (MQSGUtil.isUsingStaticData("DailyHighlights")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let highlightdata = null;
    let startdate = "";
    if (startDateString !== null) {
      startdate = "&startdate=" + startDateString;
    }
    // const url = MQSGUtil.getAPIBasePath() + "/NewsTableServlet?subPage=todayhighlight&start=0" + startdate
    const url =
      MQSGUtil.getAPIBasePath() +
      "/NewsTableServlet?sp=todayhighlight&start=0" +
      startdate + '&underlying=index';
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          highlightdata = result;
          this.setState({ highlightdata });
        },
        (error) => {
          this.setState({ highlightdata });
        }
      );
  }
  onStartDateChange(startDate) {
    this.setState({ startDate });
    const startDateString = MQSGUtil.getDateString(startDate);
    this.onFetchMmbData(startDateString);
  }
  setShow(b, v, i) {
    this.setState({
      show: b
    })
    this.setState({
      key: v
    })
    ReactGA.event('Index', this.state.gtag[i - 1])
  }
  setValue(b, v) {
    this.setState({
      sliderShow: b
    })
    this.setState({
      keyValue: v
    })
    ReactGA.event('Index', this.state.gtag[v - 1])
  }
  render() {
    return (
      <div className="whyBox pageobj" id="indexdw">
        <BreadcrumbBar
          link="/home/edu"
          group="Warrant education"
          title="Index warrants"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>What is an index warrant? </h1>
            </div>
            <p>
              An index warrant is a structured warrant that is issued over an underlying index e.g. the Hang Seng Index or the SGX Nikkei225. The index call warrant tracks the performance of the underlying index i.e. it will tend to increase in value when the underlying index increases, while index put warrants move in an opposite direction to the underlying index, meaning it tends to increase in value when the underlying index decreases.
              <br />
              <br />
              Click on a tile below to learn more about each underlying index.
            </p>
            <div id="topimggrip" className="imggrip row">
              <Slider {...this.state.settings} >
                {this.state.Image.map((d) => (
                  <div
                    className="imggrip-col col-xs-4 col-sm-5th"
                    // onMouseOver={() => setValue(d.value)}
                    key={d}
                    onClick={() => {
                      this.setValue(true, d.value)
                      // if (swiping) {
                      //   return
                      // }
                      // setOpen(true)
                    }}
                  >
                    <img src={d.src} />
                    <div className="imggrip-col-txt">
                      <h3 className="imggrip-col-h">{d.text}</h3>
                      <span className="imggrip-col-a">More info</span>
                    </div>
                    {(d.value === 1 || d.value === 2) && (
                      <div className="new-icon bgcolor-06">NEW!</div>
                    )}
                  </div>
                ))}
              </Slider>
            </div>
            <NewsSection
              highlightdata={this.state.highlightdata}
              startDate={this.state.startDate}
              onStartDateChange={(startDate) =>
                this.onStartDateChange(startDate)
              }
            />
            {/* <div className="livematrix-row row-fullw row">
              <div className="livematrix-col col-xs-12 col-sm-6">
                <table className="col-txtarea">
                  <tbody>
                    <tr>
                      <td>
                        <h2 className="col-h">Live matrix</h2>
                        <p className="col-p">
                          Visit the Live Matrix page to see how you can utilize
                          this powerful tool to track the movements of your index
                          warrants.
                        </p>
                        <a
                          className="moreinfobtn"
                          target="_blank"
                          href="/tools/livematrix"
                        >
                          View Live Matrix
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="livematrix-col ipad-col col-xs-12 col-sm-6">
                <div className="imggrip-col">
                  <img src="/img/education/livematrix_iPad-only_bg.png" />
                </div>
              </div>
            </div> */}
            <div className="page-header">
              <h1>Want to know more?</h1>
            </div>
            <p>
              Click on a section below to get more in depth information on
              warrants.
            </p>
            {/* <OutsideClickHandler onOutsideClick={() => setShow(false)}> */}
            <div id="wtkm">

              <div className="row">
                <div
                  className="imggrip-col col-sm-6"
                  // onMouseOver={() => setKeyPara(keyData.key1)}
                  onClick={() => {
                    this.setShow(true, this.state.keyData.key1, 8);
                  }}
                >
                  <img
                    className="ppbtn"
                    key={this.state.keyData.key1}
                    src="/img/education/whytradeindexwarrant.png"
                  />
                </div>
                <div
                  className="imggrip-col col-sm-6"
                  // onMouseOver={() => setKeyPara(keyData.key2)}
                  onClick={() => {
                    this.setShow(true, this.state.keyData.key2, 9);
                  }}
                >
                  <img
                    className="ppbtn"
                    key={this.state.keyData.key2}
                    src="/img/education/howtotradeindexwarrants.png"
                  />
                </div>
              </div>

              <div className="row">
                <div
                  className="imggrip-col col-sm-6"
                  // onMouseOver={() => setKeyPara(keyData.key3)}
                  onClick={() => {
                    this.setShow(true, this.state.keyData.key3, 10);
                  }}
                >
                  <img
                    className="ppbtn"
                    key={this.state.keyData.key3}
                    src="/img/education/bot_left.jpg"
                  />
                </div>
                <div
                  className="imggrip-col col-sm-6"
                  // onMouseOver={() => setKeyPara(keyData.key4)}
                  onClick={() => {
                    this.setShow(true, this.state.keyData.key4, 11);
                  }}
                >
                  <img
                    className="ppbtn"
                    key={this.state.keyData.key4}
                    src="/img/education/bot_right.jpg"
                  />
                </div>
              </div>
            </div>
            {/* </OutsideClickHandler> */}

            <hr className="hr-hmgbm" />
            <span style={{ fontSize: 14 }}>Disclaimer:</span>
            <p className="small">
              All data sourced from Bloomberg and the official websites of the
              underlying index sponsor
              {/* or ETF manager (as applicable) */}
              &nbsp;as of
              December 2021.
            </p>
          </div>
        </div>
        <IndexModal keyValue={this.state.key} show={this.state.show} setShow={this.setShow.bind(this)} />
        {this.state.sliderShow && <DataModal show={this.state.sliderShow} keyValue={this.state.keyValue} setValue={this.setValue.bind(this)} />}
      </div>
    );
  }
}

class NewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      gtag: [
        {
          category: 'ButtonClick',
          label: 'Subscribe',
          action: 'Rated an App',
          Event_category: 'ButtonClick',
          event_label: 'Subscribe',
        },
      ],
      show: false,
      warningShow: false,
      email: '',
      httpEmailText: false
    };
    this.rows = [];
    this.pageSize = 5;
  }

  onDotClicked(pageIndex) {
    this.setState({ current: pageIndex });
  }

  onDateChange(date) {
    this.props.onStartDateChange(date);
  }

  setupRows(highlightdata) {
    const rows = [];
    const newslist = MQSGUtil.getData(highlightdata, "newsList");
    if (newslist !== null && Array.isArray(newslist)) {
      newslist.forEach((news, index) => {
        const date = MQSGUtil.getData(news, "date");
        const title = { __html: MQSGUtil.getData(news, "en_title") };
        const short = { __html: MQSGUtil.getData(news, "en_short") };
        const id = MQSGUtil.getData(news, "id");
        // const column1 = (
        //   <div className="newstbl-col-left">
        //     <span className="newstbl-col-date">{date}</span>
        //   </div>
        // );
        // const column2 = (
        //   <div className="newstbl-col-right">
        //     {/* <h3 className="newstbl-col-dt" dangerouslySetInnerHTML={title}></h3> */}
        //     {/* <div
        //       className="newstbl-col-dd"
        //       dangerouslySetInnerHTML={short}
        //       style={{ overflowWrap: "break-word" }}
        //     ></div> */}
        //     <div className="newstbl-col-rm">
        //       <h3 className="newstbl-col-dt">
        //         <Link
        //           to={"/marketnews/highlight/todayhighlight/" + id}
        //           // className="btn btn-primary btn-default"
        //           target="_blank"
        //           dangerouslySetInnerHTML={title}
        //         >
        //           {/* Read more */}
        //         </Link>
        //       </h3>
        //     </div>
        //   </div>
        // );
        // const row = (
        //   <div className="newstbl-row rowtmpl" key={"daily_" + id}>
        //     {column1}
        //     {column2}
        //   </div>
        // );
        const column1 = (
          <div className="newstbl-col-left" style={{ margin: '0px 0px 0px 10px', padding: '10px 50px 10px 10px', background: index % 2 == 0 ? '#F5F6F7' : '#fff' }}>
            <span className="newstbl-col-date">{date}</span>
            <div dangerouslySetInnerHTML={title} style={{ color: '#000', fontWeight: 'bolder' }}>
              {/* <Link
                      to={"/marketnews/highlight/todayhighlight/" + id}
                      target="_blank"
                      dangerouslySetInnerHTML={title}
                  >
                  </Link> */}
            </div>
          </div>
        );
        const column2 = (
          <div className="newstbl-col-right" style={{
            position: 'absolute',
            right: '5px',
            top: '18px'
          }}>
            <Link
              to={"/marketnews/highlight/todayhighlight/" + id}
            >
              <img src="/img/bullet/qlink-achor.png" alt="" />
            </Link>


          </div>
        );
        const row = (
          <div className="newstbl-row rowtmpl" key={"daily_" + id} style={{ background: '#DFDBCF', position: 'relative' }}>
            {column1}
            {column2}
          </div>
        );
        rows.push(row);
      });
    }
    this.rows = rows;
    return rows;
  }

  getRows() {
    // page index 0: current -> pageSize * (current + 1) - 1
    // page index 1: pageSize * current -> pageSize * 2 - 1
    const pageIndex = this.state.current;
    const startRowIndex = this.pageSize * pageIndex;
    const endRowIndex = this.pageSize * (pageIndex + 1);
    if (this.rows.length > endRowIndex) {
      return this.rows.slice(startRowIndex, endRowIndex);
    }
    return this.rows.slice(startRowIndex);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.highlightdata !== this.props.highlightdata) {
      this.setupRows(nextProps.highlightdata);
    }
    return true;
  }
  getbgDate(date) {
    var m = new Date().getMonth() + 1;
    var M = new Date(date).getMonth() + 1;
    var cls = m != M ? "random" : undefined;
    return cls
  }
  setEmail(e) {
    this.setState({
      email: e.target.value,
    })
  }
  gtag_report_conversion() {
    const { email } = this.state
    // let reg = /^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/;
    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!reg.test(email)) {
      this.setState({
        warningShow: true,
      });
    } else {
      const url = MQSGUtil.getAPIBasePath() + "/registration?p=register";
      fetch(
        url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: 'email=' + email
        }
      ).then((res) => res.json()).then(
        (result) => {
          this.setState({
            httpEmailText: true,
            show: false
          }, () => {
            window.setTimeout(() => {
              this.setState({
                httpEmailText: false,
                show: false
              })
            }, 3000)

          })
        },
        (error) => {
        }
      );
    }
  }
  popUp(e, val) {
    e.stopPropagation()
    ReactGA.event('Index', this.state.gtag[0])
    this.setState({
      show: val,
      email: '',
      warningShow: false,
    })
  }
  render() {
    const rows = this.getRows();
    return (
      <div
        id="newssection"
        style={{ position: "relative", padding: '0px' }}
        className="section"
      >
        {/* <div className="page-header">
                  <h1>Morning Market Buzz</h1>
              </div> */}
        <h3 className="section-p" style={{ minHeight: '40px', borderBottom: '1px solid #FECC0A' }}>
          Newsletter articles on index warrants
        </h3>
        <div id="commentarytbl" className="newstbl">
          {/* <div className="newstbl-htxt">Latest News</div> */}
          <div className="newstbl-content" style={{ color: '#0675C2', overflow: 'hidden', marginBottom: '15px' }}>{rows}</div>
          <div className="newstbl-bttm">
            {/* <div id="datepicker" className="newstbl-cal-icon input-group date">
                          <input type="hidden" className="form-control" />
                          <div className="input-group-addon">
                              <DatePicker
                                  selected={this.props.startDate}
                                  onChange={(date) => this.onDateChange(date)}
                                  maxDate={new Date()}
                                  value=""
                                  dayClassName={(date) =>
                                      this.getbgDate(date)
                                      // getDate(date) < Math.random() * 31 ? "random" : undefined
                                  }
                              />
                          </div>
                      </div> */}
            <SwiperPagination
              current={this.state.current}
              rows={this.rows}
              pageSize={this.pageSize}
              onDotClicked={(pageIndex) => this.onDotClicked(pageIndex)}
            />
            <div className="newstbl-col-rm" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ color: '#3F312D', fontSize: '22px' }}>
                Sign up for our daily newsletter for the latest updates on the market
              </div>
              {/* <Link
                to={"/marketnews/subscribe"}
                className="btn btn-primary btn-default"
                target="_blank"
                style={{
                  padding: '10px 30px 10px 30px',
                  height: '55px', fontSize: '22px', marginLeft: '5px'
                }}
                onClick={() => { ReactGA.event('Index', this.state.gtag[0]) }}
              >
                Subscribe
              </Link> */}
              <button
                className="btn btn-primary btn-default"
                target="_blank"
                style={{
                  padding: '10px 30px 10px 30px',
                  height: '55px', fontSize: '22px', marginLeft: '5px'
                }}
                // onClick={() => { ReactGA.event('Index', this.state.gtag[0]) }}
                onClick={(e) => { this.popUp(e, true) }}
              >
                Subscribe
              </button>
            </div>
          </div>
          <hr className="hr-hmgbm" />
        </div>
        {/* pop-up */}
        <div
          id="disclaimer-ppboxHTML"
          className="disclaimer-overlay"
          style={{ display: this.state.show ? 'block' : 'none' }}
        >
          <div className="overlay-inner">
            <div className="popupbox-content">
              <div className="signup-layer">
                <p style={{ float: 'right', fontSize: '20px', cursor: 'pointer' }} onClick={(e) => this.popUp(e, false)}>X</p>
                <div className="page-header">
                  <h3>Sign up now</h3>
                </div>
                <input type="text" id="email" placeholder="Email" value={this.state.email} onChange={(e) => { this.setEmail(e) }} />
                <div className="warning" style={{ visibility: this.state.warningShow ? 'visible' : 'hidden' }}>invalid email</div>
                <p className="small">
                  By clicking "Submit", you confirm that you would like to subscribe to Macquarie’s daily warrant newsletter. By subscribing, you will also automatically receive invitations or marketing material from Macquarie for warrant seminars and other warrant related initiatives. Macquarie website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities (Singapore) Pte Ltd and other companies of Macquarie Group (“Macquarie”).
                  <br /><br />
                  Please read the <a target="_blank" href="/about/privacypolicy">privacy policy</a> and <a target="_blank" href="/about/importantinformation">important information</a> via the links at the bottom of this page to understand how your personal information will be treated by Macquarie.
                </p>
                <input type="button" id="submitBtn" value="Submit" onClick={() => this.gtag_report_conversion()} />
              </div>
            </div>
          </div>
        </div>
        {/* pop-up */}
        <div id="popup-customized" style={{ display: this.state.httpEmailText ? 'table' : 'none' }}>
          <div>
            <h5>You have successfully registered for our daily newsletter!</h5>
          </div>
        </div>
      </div>
    );
  }
}
