
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class cashsettlementamountforsinglesharewarrantsatexpiry extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css"/>*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>All structured warrants in Singapore are currently cash settled; investors do not take delivery of the physical shares. Instead, holders of warrants with value at expiry will be sent a cheque of the settlement amount within
                                                10 business days from the expiry date.
                                            </p>
                                            <p>To calculate the settlement value of single stock warrants, the average closing price of the underlying share on the 5 trading days prior to the expiry date, is used.</p>
                                            <h2 className="title">FORMULA TO CALCULATE VALUE OF CALL WARRANT AT EXPIRY</h2>
                                            <div className="formulaholder">
                                                <div className="formulabox">
                                                    <div className="leqt">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 39, }}>
                                                                        Value at Expiry =
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="reqt">
                                                        <table className="fraction">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{}}>(5 Day Average Closing Price - Exercise Price)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="upper_line" style={{}}>Warrants per share</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}