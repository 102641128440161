
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class nomargincall extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div>
                            <div id="mainnav">
                                <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                    <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                        <div id="mainscrollcontent">
                                            <div className="contentbox">
                                                <p>Even if the underlying share or index moves adversely, warrant investors will not need to make margin top-ups. The maximum exposure to the investor is set up front at the start of the trade. So you can rest a little easier.</p>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/nomargincall1.png" alt />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}