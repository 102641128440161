const dwturnover = {
    "dwturnover": {
        "all": [
            {
                "total": "618,047,082",
                "pct_ref": 1.016123,
                "dw": "6,280,120",
                "update_time": "08 Mar 21 11:04",
                "pct": "1.02",
                "stats_date_obj": "08 Mar 21"
            },
            {
                "total": "1,595,697,894",
                "pct_ref": 0.779468,
                "dw": "12,437,962",
                "update_time": "05 Mar 21 17:15",
                "pct": "0.78",
                "stats_date_obj": "05 Mar 21"
            },
            {
                "total": "1,619,239,200",
                "pct_ref": 1.1326,
                "dw": "18,339,508",
                "update_time": "04 Mar 21 17:15",
                "pct": "1.13",
                "stats_date_obj": "04 Mar 21"
            },
            {
                "total": "1,453,913,273",
                "pct_ref": 0.874821,
                "dw": "12,719,142",
                "update_time": "03 Mar 21 17:15",
                "pct": "0.87",
                "stats_date_obj": "03 Mar 21"
            },
            {
                "total": "1,573,858,137",
                "pct_ref": 1.289157,
                "dw": "20,289,501",
                "update_time": "02 Mar 21 17:15",
                "pct": "1.29",
                "stats_date_obj": "02 Mar 21"
            },
            {
                "total": "1,629,525,838",
                "pct_ref": 1.017393,
                "dw": "16,578,677",
                "update_time": "01 Mar 21 17:15",
                "pct": "1.02",
                "stats_date_obj": "01 Mar 21"
            },
            {
                "total": "2,316,906,052",
                "pct_ref": 1.172913,
                "dw": "27,175,282",
                "update_time": "26 Feb 21 17:15",
                "pct": "1.17",
                "stats_date_obj": "26 Feb 21"
            },
            {
                "total": "1,935,184,172",
                "pct_ref": 0.902996,
                "dw": "17,474,643",
                "update_time": "25 Feb 21 17:15",
                "pct": "0.90",
                "stats_date_obj": "25 Feb 21"
            },
            {
                "total": "1,783,171,285",
                "pct_ref": 0.676202,
                "dw": "12,057,847",
                "update_time": "24 Feb 21 17:15",
                "pct": "0.68",
                "stats_date_obj": "24 Feb 21"
            },
            {
                "total": "1,530,743,463",
                "pct_ref": 0.859612,
                "dw": "13,158,448",
                "update_time": "23 Feb 21 17:15",
                "pct": "0.86",
                "stats_date_obj": "23 Feb 21"
            }
        ],
        "index": [
            {
                "total": "6,280,120",
                "pct_ref": 87.193978,
                "dw": "5,475,887",
                "update_time": "08 Mar 21 11:04",
                "pct": "87.19",
                "stats_date_obj": "08 Mar 21"
            },
            {
                "total": "383,553",
                "pct_ref": 0.0,
                "dw": "0",
                "update_time": "08 Mar 21 09:01",
                "pct": "0.00",
                "stats_date_obj": "05 Mar 21"
            },
            {
                "total": "343,413",
                "pct_ref": 0.0,
                "dw": "0",
                "update_time": "05 Mar 21 09:01",
                "pct": "0.00",
                "stats_date_obj": "04 Mar 21"
            },
            {
                "total": "12,719,142",
                "pct_ref": 95.359836,
                "dw": "12,128,953",
                "update_time": "03 Mar 21 17:15",
                "pct": "95.36",
                "stats_date_obj": "03 Mar 21"
            },
            {
                "total": "406,259",
                "pct_ref": 0.0,
                "dw": "0",
                "update_time": "03 Mar 21 09:03",
                "pct": "0.00",
                "stats_date_obj": "02 Mar 21"
            },
            {
                "total": "16,578,677",
                "pct_ref": 97.211236,
                "dw": "16,116,337",
                "update_time": "01 Mar 21 17:15",
                "pct": "97.21",
                "stats_date_obj": "01 Mar 21"
            },
            {
                "total": "50,657",
                "pct_ref": 0.0,
                "dw": "0",
                "update_time": "01 Mar 21 09:01",
                "pct": "0.00",
                "stats_date_obj": "26 Feb 21"
            },
            {
                "total": "17,474,643",
                "pct_ref": 95.306674,
                "dw": "16,654,501",
                "update_time": "25 Feb 21 17:15",
                "pct": "95.31",
                "stats_date_obj": "25 Feb 21"
            },
            {
                "total": "12,057,847",
                "pct_ref": 96.829486,
                "dw": "11,675,552",
                "update_time": "24 Feb 21 17:15",
                "pct": "96.83",
                "stats_date_obj": "24 Feb 21"
            },
            {
                "total": "12,666",
                "pct_ref": 0.0,
                "dw": "0",
                "update_time": "24 Feb 21 09:00",
                "pct": "0.00",
                "stats_date_obj": "23 Feb 21"
            }
        ],
        "stock": [
            {
                "total": "6,280,120",
                "pct_ref": 12.806022,
                "dw": "804,234",
                "update_time": "08 Mar 21 11:04",
                "pct": "12.81",
                "stats_date_obj": "08 Mar 21"
            },
            {
                "total": "383,553",
                "pct_ref": 100.0,
                "dw": "383,553",
                "update_time": "08 Mar 21 09:01",
                "pct": "100.00",
                "stats_date_obj": "05 Mar 21"
            },
            {
                "total": "343,413",
                "pct_ref": 100.0,
                "dw": "343,413",
                "update_time": "05 Mar 21 09:01",
                "pct": "100.00",
                "stats_date_obj": "04 Mar 21"
            },
            {
                "total": "12,719,142",
                "pct_ref": 4.640164,
                "dw": "590,189",
                "update_time": "03 Mar 21 17:15",
                "pct": "4.64",
                "stats_date_obj": "03 Mar 21"
            },
            {
                "total": "406,259",
                "pct_ref": 100.0,
                "dw": "406,259",
                "update_time": "03 Mar 21 09:03",
                "pct": "100.00",
                "stats_date_obj": "02 Mar 21"
            },
            {
                "total": "16,578,677",
                "pct_ref": 2.788764,
                "dw": "462,340",
                "update_time": "01 Mar 21 17:15",
                "pct": "2.79",
                "stats_date_obj": "01 Mar 21"
            },
            {
                "total": "50,657",
                "pct_ref": 100.0,
                "dw": "50,657",
                "update_time": "01 Mar 21 09:01",
                "pct": "100.00",
                "stats_date_obj": "26 Feb 21"
            },
            {
                "total": "17,474,643",
                "pct_ref": 4.693326,
                "dw": "820,142",
                "update_time": "25 Feb 21 17:15",
                "pct": "4.69",
                "stats_date_obj": "25 Feb 21"
            },
            {
                "total": "12,057,847",
                "pct_ref": 3.170514,
                "dw": "382,296",
                "update_time": "24 Feb 21 17:15",
                "pct": "3.17",
                "stats_date_obj": "24 Feb 21"
            },
            {
                "total": "12,666",
                "pct_ref": 100.0,
                "dw": "12,666",
                "update_time": "24 Feb 21 09:00",
                "pct": "100.00",
                "stats_date_obj": "23 Feb 21"
            }
        ]
    }
}

export default dwturnover