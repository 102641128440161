function getData(fieldName, data) {    
    const fieldNameSplit = fieldName.split("-");
    const fieldNameUse = fieldNameSplit[fieldNameSplit.length - 1];
    if (fieldNameUse in data) {
        return data[fieldNameUse];
    }
    return "noData";
}

const tableConfig = {
    fields: [{
        className: "rw-ticker",
        dataHasTip: "0",
        name: "Warrant code",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            const href = "/tools/livematrix/" + value
            return (<td key={this.dataHasTip + "-" + index}><a href={href}>{value}</a></td>);
        },
        tooltip: function() {
            return (<div><p><strong>Warrant code:</strong>&nbsp;   
                        Each warrant has an unique 4-digit identification code, click 
                        <a target="_blank" rel="noreferrer" href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantsnamed"> click here </a>for a more detailed explanation.
                    </p></div>);
        }
    },{
        className: "rw-underlying_ric",
        dataHasTip: "1",
        name: "Underlying",
        columnData: function(data, index) {            
            const value = getData("label", data);
            const valueSplit = value.split(".");
            const valueWithoutCountryCode = valueSplit[0];       
            const href = "/tools/underlying/" + valueWithoutCountryCode
            return (<td key={this.dataHasTip + "-" + index}><a href={href}>{valueWithoutCountryCode}</a></td>);
        },
        tooltip: function() {
            return (<div><p><strong>Underlying:</strong> The stock or index over which the Warrant is listed.</p></div>)
        }
    },{
        className: "rw-issuer_code",
        dataHasTip: "2",
        name: "Issuer",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index} className="text-bold">{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Issuer:</strong> The Bank or Securities company that issues the Warrant.</p></div>)
        }         
    },{
        className: "rw-view",
        dataHasTip: "3",
        name: "Live matrix",
        columnData: function(data, index) {
            const value = getData("ticker", data);
            const href = "/tools/livematrix/" + value
            return (<td key={this.dataHasTip + "-" + index}><a className="btn btn-primary" href={href}>View</a></td>);
        },
        tooltip: function() {
            return (<div><p><strong>Live matrix:</strong> Makes trading warrant easy by showing investors where our bid prices will be throughout the day</p></div>)
        }
    },{
        className: "rw-type",
        dataHasTip: "4",
        name: "Type",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Type:</strong> Calls increase in value as share price rises, Puts increase in value as share price falls.</p></div>);
        }     
    },{
        className: "rw-exercise_price",
        dataHasTip: "5",
        name: "Exercise price",
        columnData: function(data, index) {
            var value = getData(this.className, data);
            if ((data.underlying_name.indexOf('TENCENT') != -1)) {
                value = Number(value).toFixed(3)
              }
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Exercise price:</strong> The price at which you can buy (calls) or sell (puts) the underlying share or index at expiry.</p></div>)
        }                
    },{
        className: "rw-maturity",
        dataHasTip: "6",
        name: "Expiry",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Expiry:</strong> The date at which the warrant will expire.</p></div>);
        }         
    },{
        className: "rw-last_trading_date",
        dataHasTip: "7",
        name: "Last trading date",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Last trading date:</strong> The last day to buy or sell the warrant. This date is always the 5th trading day prior to the expiry date.</p></div>);
        }           
    },{
        className: "rw-conv_ratio",
        dataHasTip: "8",
        name: "Warrant per share",
        columnData: function(data, index) {
            var  value = getData(this.className, data);
            var  value2 = getData('rw-conv_ratio_cal', data);
            const name = getData('dsply_name', data);
            if (name.indexOf('Tencent') != -1) {
                value=Number(value2).toFixed(3)
              } else if (
                name.indexOf('SembInd') != -1 ||
                name.indexOf('CityDev') != -1 ||
                name.indexOf('KepCorp') != -1 ||
                name.indexOf('Singtel') != -1
              ) {
                // value=Number(value2).toFixed(5)
                value=Math.floor(value2 * 100) / 100;
              }
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Warrant per share:</strong> The number of warrants that entitles you to one unit of the underlying share or index at expiry.</p></div>)
        }        
    },{
        className: "rw-moneyness",
        dataHasTip: "9",
        name: "Moneyness",
        columnData: function(data, index) {
            const moneyness = getData(this.className, data);
            const percent = getData("percent_moneyness", data);
            let moneynessName = "ATM";
            if (moneyness === "1") {  
                moneynessName = "ITM";
            } else if (moneyness === "2") {
                moneynessName = "OTM";
            }
            let upDown = "val ";
            if (moneynessName === "OTM") {
                upDown += "down";
            } else if (moneynessName === "ITM") {
                upDown += "up";
            }            
            const value = percent + "% " + moneynessName;
            return (<td key={this.dataHasTip + "-" + index} className={upDown}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Moneyness:</strong> ITM (in-the-money), OTM (out-of-the-money), ATM (at-the-money). 
                        <a target="_blank" rel="noreferrer" href="https://www.warrants.com.sg/InvestorAcademy/moneyness"> click here </a>to learn more about moneyness.
                    </p></div>)
        }                         
    },{
        className: "rw-BID",
        dataHasTip: "10",
        name: "Bid",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Bid:</strong> The highest current buy price of the Warrant. This is the value that most accurately reflects the 'value' of the Warrant at any point in time.</p></div>)
        }         
    },{
        className: "rw-ASK",
        dataHasTip: "11",
        name: "Ask",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Ask:</strong> The lowest current sell price for the Warrant.</p></div>)
        }                
    },{
        className: "rw-TRDPRC_1",
        dataHasTip: "12",
        name: "Last",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Last:</strong> The last executed trade price for the Warrant. Be careful when refering to this as a reference price as Warrant do not trade as frequently as shares, this trade may have occurred some time ago.</p></div>);
        }                
    },{
        className: "rw-HST_CLOSE",
        dataHasTip: "13",
        name: "Prior",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Prior:</strong> Best bid price at close of previous days trading.</p></div>);
        }                
    },{
        className: "rw-BID_NETCHNG",
        dataHasTip: "14",
        name: "Change (SGD)",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            let upDown = "val ";
            if (value.indexOf("-") > -1) {
                upDown += "down";
            } else if (value.indexOf("+") > -1) {
                upDown += "up";
            }
            return (<td key={this.dataHasTip + "-" + index} className={upDown}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Change:</strong> Current bid price for the Warrant compared to the Prior.</p></div>);
        }                
    },{
        className: "rw-underlying_price",
        dataHasTip: "15",
        name: "Underlying price",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Underlying price:</strong> The last traded price for the underlying shares.</p></div>);
        }           
    },{
        className: "rw-ACVOL_1",
        dataHasTip: "16",
        name: "Volume",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Volume:</strong> Total traded volume in the Warrant for the day.</p></div>)
        }             
    },{
        className: "rw-delta",
        dataHasTip: "17",
        name: "Delta (%)",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Delta:</strong> Estimates how a warrant moves in relation to the underlying stock/index. Puts have negative deltas because their prices increase as the underlying stock/index falls. Note, if your warrant has a WPS that this will need to be taken into account.</p></div>);
        }               
    },{
        className: "rw-delta_per_wrnt",
        dataHasTip: "18",
        name: "Delta per warrant (%)",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Delta per warrant:</strong> Delta/Warrant Per Share. 
                        <a target="_blank" rel="noreferrer" href="https://www.warrants.com.sg/InvestorAcademy/delta"> click here </a>to learn more about Delta per warrant.
                    </p></div>)
        }          
    },{
        className: "rw-premium",
        dataHasTip: "19",
        name: "Premium (%)",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Premium:</strong> Also refered to as the "Time Value of the Warrant. Note a higher premium could be due to the warrant being longer dated or due to the higher gearing. 
                        <a target="_blank" rel="noreferrer" href="https://www.warrants.com.sg/InvestorAcademy/premium"> click here </a>to learn more about premium.
                    </p></div>)
        }         
    },{
        className: "rw-effective_gearing",
        dataHasTip: "20",
        name: "Effective gearing (x)",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Effective gearing:</strong> Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your Warrant price. 
                        <a target="_blank" rel="noreferrer" href="https://www.warrants.com.sg/InvestorAcademy/effectivegearing"> click here </a>to learn more about effective gearing.
                    </p></div>)
        }          
    },{
        className: "rw-implied_volatility",
        dataHasTip: "21",
        name: "Volatility (%)",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Volatility:</strong> The higher the implied volatility, the higher the price of the Warrant. Relates to the volatility and risk of the underlying share/Index. 
                        <a target="_blank" rel="noreferrer" href="https://www.warrants.com.sg/InvestorAcademy/impliedvolatility"> click here </a>to learn more about implied volatility.
                    </p></div>);
        }              
    },{
        className: "rw-theta",
        dataHasTip: "22",
        name: "Theta (%)",
        columnData: function(data, index) {
            const value = getData(this.className, data);
            return (<td key={this.dataHasTip + "-" + index}>{value}</td>);
        },
        tooltip: function() {
            return (<div><p><strong>Theta:</strong> The daily time decay of the warrant expressed in percentage terms. 
                        <a target="_blank" rel="noreferrer"  href="https://www.warrants.com.sg/InvestorAcademy/timedecay"> click here </a>to learn more about Theta.
                    </p></div>);
        }               
    }]
}
 
export default tableConfig;



