const rankingData = {
    "gainer": [
        {
            "underlying_ric": "HHTIc1",
            "b_trbc_business": null,
            "theta": "-0.52",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.020",
            "percent_moneyness": "25",
            "last_trading_date": "22 Apr 21",
            "HIGH_1": "0.077",
            "type": "CALL",
            "effective_gearing": "8.7",
            "conv_ratio": "800.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "+50.00",
            "LOW_1": "0.017",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "IQTW",
            "breakeven_price": "12,439.78",
            "underlying_pctchng": "+2.48",
            "delta_per_wrnt": "0.017",
            "warrant_price": 0.030000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.071",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSTECH 12300MBeCW210429",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "35.24",
            "delta": "13.2",
            "APPCHEXPRY": false,
            "TRADE_DATE": "-",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 1583.932500000,
            "BID_PCTCHNG": "+50.0",
            "BIDSIZE": "1,000,000",
            "underlying_price_cal": 9226.0,
            "exercise_price_adj": 2111.910000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "HSTECH 12300MBeCW210429",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1000000",
            "HST_CLOSE": "0.020",
            "ACVOL_1_SCALED": "0",
            "BID": "0.030",
            "r_hst_close": 0.1717000000,
            "maturity": "29 Apr 21",
            "div_yield": 0.243468750,
            "underlying_bid": 9222.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t219.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "9,226.00",
            "list_date": "22 Feb 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.031",
            "currency": "SGD",
            "conv_ratio_cal": 800.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.010",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "58.7",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "1,000,000",
            "premium": "34.8",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSTECH",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 12300.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HANG SENG TECH INDEX",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "1000000",
            "ACVOL_1": "0",
            "days_to_maturity": "59",
            "ric": "HSML_t219.SI",
            "HOTLIST": false,
            "exercise_price": "12,300.00"
        },
        {
            "underlying_ric": "CATL.SI",
            "b_trbc_business": null,
            "theta": "-5.39",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.235",
            "percent_moneyness": "6",
            "last_trading_date": "25 Feb 21",
            "HIGH_1": "0.260",
            "type": "CALL",
            "effective_gearing": "11.5",
            "conv_ratio": "2.0",
            "wrnt_per_share": "0.500",
            "BID_PCTCHNG_2DP": "+34.55",
            "LOW_1": "0.027",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "HUFW",
            "breakeven_price": "3.22",
            "underlying_pctchng": "0.00",
            "delta_per_wrnt": "39.632",
            "warrant_price": 0.110000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.080",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "CapitalaMBeCW210304",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "0.25",
            "delta": "79.3",
            "APPCHEXPRY": true,
            "TRADE_DATE": "18 Jan 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 3.180000000,
            "BID_PCTCHNG": "+34.5",
            "BIDSIZE": "0",
            "underlying_price_cal": 3.18,
            "exercise_price_adj": 3.000000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "CapitalaMBeCW210304",
            "YRHIGH": "-",
            "BEST_BSIZ1": "0",
            "HST_CLOSE": "0.110",
            "ACVOL_1_SCALED": "0",
            "BID": "0.000",
            "r_hst_close": 1,
            "maturity": "04 Mar 21",
            "div_yield": 3.312693000,
            "underlying_bid": 3.18,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "CAML_t3.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "3.18",
            "list_date": "08 Oct 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.000",
            "currency": "SGD",
            "conv_ratio_cal": 2.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.038",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "87.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "1",
            "ASKSIZE": "0",
            "premium": "1.3",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "C31",
            "intrinsic_value": "0.090",
            "exercise_price_cal": 3.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "CAPLAND",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "0",
            "ACVOL_1": "0",
            "days_to_maturity": "3",
            "ric": "CAML_t3.SI",
            "HOTLIST": false,
            "exercise_price": "3.00"
        },
        {
            "underlying_ric": "3690.HK",
            "b_trbc_business": null,
            "theta": "-0.04",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.350",
            "percent_moneyness": "20",
            "last_trading_date": "26 Mar 21",
            "HIGH_1": "0.780",
            "type": "CALL",
            "effective_gearing": "5.0",
            "conv_ratio": "35.0",
            "wrnt_per_share": "0.029",
            "BID_PCTCHNG_2DP": "+28.57",
            "LOW_1": "0.079",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "JAOW",
            "breakeven_price": "364.21",
            "underlying_pctchng": "+5.53",
            "delta_per_wrnt": "2.548",
            "warrant_price": 0.315000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.122",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "Meituan MB eCW210405",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "19.62",
            "delta": "89.2",
            "APPCHEXPRY": false,
            "TRADE_DATE": "27 Jan 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 62.018040000,
            "BID_PCTCHNG": "+28.6",
            "BIDSIZE": "100,000",
            "underlying_price_cal": 358.8,
            "exercise_price_adj": 51.510000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "Meituan MB eCW210405",
            "YRHIGH": "-",
            "BEST_BSIZ1": "100000",
            "HST_CLOSE": "0.245",
            "ACVOL_1_SCALED": "0",
            "BID": "0.315",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Apr 21",
            "div_yield": 0E-9,
            "underlying_bid": 358.6,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "MEML_tb.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "358.80",
            "list_date": "05 Oct 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.330",
            "currency": "SGD",
            "conv_ratio_cal": 34.999999475,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.070",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "50.9",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "1",
            "ASKSIZE": "100,000",
            "premium": "1.5",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "MEITUAN-W",
            "intrinsic_value": "0.288",
            "exercise_price_cal": 300.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "MEITUAN-W",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "100000",
            "ACVOL_1": "0",
            "days_to_maturity": "35",
            "ric": "MEML_tb.SI",
            "HOTLIST": false,
            "exercise_price": "300.00"
        },
        {
            "underlying_ric": "SIAL.SI",
            "b_trbc_business": null,
            "theta": "-0.49",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.028",
            "percent_moneyness": "4",
            "last_trading_date": "24 Jun 21",
            "HIGH_1": "0.064",
            "type": "CALL",
            "effective_gearing": "4.9",
            "conv_ratio": "8.5",
            "wrnt_per_share": "0.118",
            "BID_PCTCHNG_2DP": "+28.00",
            "LOW_1": "0.019",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "GIJW",
            "breakeven_price": "6.04",
            "underlying_pctchng": "+5.82",
            "delta_per_wrnt": "5.996",
            "warrant_price": 0.064000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.033",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "SIA MB eCW210701",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "1.67",
            "delta": "51.0",
            "APPCHEXPRY": false,
            "TRADE_DATE": "09 Feb 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5.270000000,
            "BID_PCTCHNG": "+28.0",
            "BIDSIZE": "1,700,000",
            "underlying_price_cal": 5.27,
            "exercise_price_adj": 5.500000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "SIA MB eCW210701",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1700000",
            "HST_CLOSE": "0.050",
            "ACVOL_1_SCALED": "0",
            "BID": "0.064",
            "r_hst_close": 1,
            "maturity": "01 Jul 21",
            "div_yield": 0E-9,
            "underlying_bid": 5.26,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "SLML_ta.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "5.27",
            "list_date": "28 Dec 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.066",
            "currency": "SGD",
            "conv_ratio_cal": 8.499999987,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.014",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "51.9",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "1,650,000",
            "premium": "14.7",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "C6L",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 5.500000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "SINGAPORE AIRLIN",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "1650000",
            "ACVOL_1": "0",
            "days_to_maturity": "122",
            "ric": "SLML_ta.SI",
            "HOTLIST": true,
            "exercise_price": "5.50"
        },
        {
            "underlying_ric": "1211.HK",
            "b_trbc_business": null,
            "theta": "-0.17",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.057",
            "percent_moneyness": "35",
            "last_trading_date": "28 Jun 21",
            "HIGH_1": "0.179",
            "type": "CALL",
            "effective_gearing": "4.1",
            "conv_ratio": "45.0",
            "wrnt_per_share": "0.022",
            "BID_PCTCHNG_2DP": "+26.19",
            "LOW_1": "0.040",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "MGVW",
            "breakeven_price": "333.89",
            "underlying_pctchng": "+6.19",
            "delta_per_wrnt": "0.604",
            "warrant_price": 0.053000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.129",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "BYD MB eCW210705A",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "16.55",
            "delta": "27.2",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 36.160020000,
            "BID_PCTCHNG": "+26.2",
            "BIDSIZE": "590,000",
            "underlying_price_cal": 209.4,
            "exercise_price_adj": 54.944000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "BYD MB eCW210705A",
            "YRHIGH": "-",
            "BEST_BSIZ1": "590000",
            "HST_CLOSE": "0.042",
            "ACVOL_1_SCALED": "2",
            "BID": "0.053",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Jul 21",
            "div_yield": 0.075932000,
            "underlying_bid": 209.4,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "BYML_tg.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "209.40",
            "list_date": "15 Jan 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.055",
            "currency": "SGD",
            "conv_ratio_cal": 45.000000450,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.011",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "83.5",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "02:43",
            "moneyness": "2",
            "ASKSIZE": "3,000,000",
            "premium": "59.5",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "BYD COMPANY",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 320.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "BYD COMPANY",
            "TURNOVER": "82",
            "PCTCHNG": "+32.6",
            "YRLOW": "-",
            "BEST_ASIZ1": "3000000",
            "ACVOL_1": "1,500",
            "days_to_maturity": "126",
            "ric": "BYML_tg.SI",
            "HOTLIST": true,
            "exercise_price": "320.00"
        },
        {
            "underlying_ric": "1211.HK",
            "b_trbc_business": null,
            "theta": "-0.06",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.160",
            "percent_moneyness": "5",
            "last_trading_date": "28 Jun 21",
            "HIGH_1": "0.445",
            "type": "CALL",
            "effective_gearing": "3.5",
            "conv_ratio": "35.0",
            "wrnt_per_share": "0.029",
            "BID_PCTCHNG_2DP": "+26.03",
            "LOW_1": "0.145",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "TMHW",
            "breakeven_price": "237.51",
            "underlying_pctchng": "+6.19",
            "delta_per_wrnt": "1.795",
            "warrant_price": 0.184000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.155",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "BYD MB eCW210705",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "5.57",
            "delta": "62.8",
            "APPCHEXPRY": false,
            "TRADE_DATE": "26 Feb 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 36.160020000,
            "BID_PCTCHNG": "+26.0",
            "BIDSIZE": "100,000",
            "underlying_price_cal": 209.4,
            "exercise_price_adj": 34.340000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "BYD MB eCW210705",
            "YRHIGH": "-",
            "BEST_BSIZ1": "100000",
            "HST_CLOSE": "0.146",
            "ACVOL_1_SCALED": "0",
            "BID": "0.184",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Jul 21",
            "div_yield": 0.075932000,
            "underlying_bid": 209.4,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "BYML_te.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "209.40",
            "list_date": "18 Dec 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.196",
            "currency": "SGD",
            "conv_ratio_cal": 34.999999475,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.038",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "65.8",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "1",
            "ASKSIZE": "100,000",
            "premium": "13.4",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "BYD COMPANY",
            "intrinsic_value": "0.046",
            "exercise_price_cal": 200.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "BYD COMPANY",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "100000",
            "ACVOL_1": "0",
            "days_to_maturity": "126",
            "ric": "BYML_te.SI",
            "HOTLIST": false,
            "exercise_price": "200.00"
        },
        {
            "underlying_ric": "UOBH.SI",
            "b_trbc_business": null,
            "theta": "-1.07",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.065",
            "percent_moneyness": "1",
            "last_trading_date": "30 Mar 21",
            "HIGH_1": "0.087",
            "type": "CALL",
            "effective_gearing": "12.2",
            "conv_ratio": "17.83",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "+25.00",
            "LOW_1": "0.016",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "FSXW",
            "breakeven_price": "25.93",
            "underlying_pctchng": "+1.86",
            "delta_per_wrnt": "3.149",
            "warrant_price": 0.065000000,
            "TURNOVER_SCALED": "6",
            "OPEN_PRC": "0.070",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "UOB MB eCW210407",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "3.18",
            "delta": "56.2",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 25.140000000,
            "BID_PCTCHNG": "+25.0",
            "BIDSIZE": "480,000",
            "underlying_price_cal": 25.14,
            "exercise_price_adj": 24.770000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "UOB MB eCW210407",
            "YRHIGH": "-",
            "BEST_BSIZ1": "480000",
            "HST_CLOSE": "0.052",
            "ACVOL_1_SCALED": "101",
            "BID": "0.065",
            "r_hst_close": 1,
            "maturity": "07 Apr 21",
            "div_yield": 4.320487000,
            "underlying_bid": 25.14,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "UOML_t25.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "25.14",
            "list_date": "10 Jun 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.066",
            "currency": "SGD",
            "conv_ratio_cal": 17.834213155,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.013",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "31.6",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:30",
            "moneyness": "1",
            "ASKSIZE": "1,360,000",
            "premium": "3.1",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "U11",
            "intrinsic_value": "0.021",
            "exercise_price_cal": 24.770000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "UOB",
            "TURNOVER": "6,425",
            "PCTCHNG": "+20.4",
            "YRLOW": "-",
            "BEST_ASIZ1": "1360000",
            "ACVOL_1": "101,200",
            "days_to_maturity": "37",
            "ric": "UOML_t25.SI",
            "HOTLIST": false,
            "exercise_price": "24.77"
        },
        {
            "underlying_ric": "3690.HK",
            "b_trbc_business": null,
            "theta": "-0.10",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.130",
            "percent_moneyness": "10",
            "last_trading_date": "28 Jun 21",
            "HIGH_1": "0.360",
            "type": "CALL",
            "effective_gearing": "4.8",
            "conv_ratio": "45.0",
            "wrnt_per_share": "0.022",
            "BID_PCTCHNG_2DP": "+24.76",
            "LOW_1": "0.038",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "YC8W",
            "breakeven_price": "434.33",
            "underlying_pctchng": "+5.53",
            "delta_per_wrnt": "0.993",
            "warrant_price": 0.129000000,
            "TURNOVER_SCALED": "14",
            "OPEN_PRC": "0.123",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "Meituan MB eCW210705",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "10.08",
            "delta": "44.7",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 61.812000000,
            "BID_PCTCHNG": "+24.8",
            "BIDSIZE": "500,000",
            "underlying_price_cal": 358.8,
            "exercise_price_adj": 68.680000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "Meituan MB eCW210705",
            "YRHIGH": "-",
            "BEST_BSIZ1": "500000",
            "HST_CLOSE": "0.105",
            "ACVOL_1_SCALED": "109",
            "BID": "0.131",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Jul 21",
            "div_yield": 0E-9,
            "underlying_bid": 358.6,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "MEML_td.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "358.80",
            "list_date": "16 Nov 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.133",
            "currency": "SGD",
            "conv_ratio_cal": 45.000000450,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.026",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "57.1",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:47",
            "moneyness": "2",
            "ASKSIZE": "100,000",
            "premium": "21.1",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "MEITUAN-W",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 400.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "MEITUAN-W",
            "TURNOVER": "13,692",
            "PCTCHNG": "+25.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "100000",
            "ACVOL_1": "109,400",
            "days_to_maturity": "126",
            "ric": "MEML_td.SI",
            "HOTLIST": true,
            "exercise_price": "400.00"
        },
        {
            "underlying_ric": "1211.HK",
            "b_trbc_business": null,
            "theta": "-0.00",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.555",
            "percent_moneyness": "50",
            "last_trading_date": "26 Mar 21",
            "HIGH_1": "0.940",
            "type": "CALL",
            "effective_gearing": "3.0",
            "conv_ratio": "25.0",
            "wrnt_per_share": "0.040",
            "BID_PCTCHNG_2DP": "+24.36",
            "LOW_1": "0.098",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "VHUW",
            "breakeven_price": "210.62",
            "underlying_pctchng": "+6.19",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.485000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.108",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "BYD MB eCW210405",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "12.50",
            "delta": "100.0",
            "APPCHEXPRY": false,
            "TRADE_DATE": "04 Jan 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 36.160020000,
            "BID_PCTCHNG": "+24.4",
            "BIDSIZE": "100,000",
            "underlying_price_cal": 209.4,
            "exercise_price_adj": 24.038000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "BYD MB eCW210405",
            "YRHIGH": "-",
            "BEST_BSIZ1": "100000",
            "HST_CLOSE": "0.390",
            "ACVOL_1_SCALED": "0",
            "BID": "0.485",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Apr 21",
            "div_yield": 0.075932000,
            "underlying_bid": 209.4,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "BYML_tb.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "209.40",
            "list_date": "05 Oct 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.505",
            "currency": "SGD",
            "conv_ratio_cal": 25.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.095",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "10.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "1",
            "ASKSIZE": "200,000",
            "premium": "0.6",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "BYD COMPANY",
            "intrinsic_value": "0.477",
            "exercise_price_cal": 140.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "BYD COMPANY",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "200000",
            "ACVOL_1": "0",
            "days_to_maturity": "35",
            "ric": "BYML_tb.SI",
            "HOTLIST": false,
            "exercise_price": "140.00"
        },
        {
            "underlying_ric": "DBSM.SI",
            "b_trbc_business": null,
            "theta": "-1.28",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.037",
            "percent_moneyness": "5",
            "last_trading_date": "26 Apr 21",
            "HIGH_1": "0.077",
            "type": "CALL",
            "effective_gearing": "13.7",
            "conv_ratio": "18.0",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "+23.33",
            "LOW_1": "0.018",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "HRZW",
            "breakeven_price": "29.17",
            "underlying_pctchng": "+1.61",
            "delta_per_wrnt": "1.867",
            "warrant_price": 0.037000000,
            "TURNOVER_SCALED": "2",
            "OPEN_PRC": "0.045",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "DBS MB eCW210503",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "5.36",
            "delta": "33.6",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 27.070000000,
            "BID_PCTCHNG": "+23.3",
            "BIDSIZE": "260,000",
            "underlying_price_cal": 27.07,
            "exercise_price_adj": 28.500000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "DBS MB eCW210503",
            "YRHIGH": "-",
            "BEST_BSIZ1": "260000",
            "HST_CLOSE": "0.030",
            "ACVOL_1_SCALED": "69",
            "BID": "0.037",
            "r_hst_close": 1,
            "maturity": "03 May 21",
            "div_yield": 4.037175000,
            "underlying_bid": 27.07,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "DBML_t38.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "27.07",
            "list_date": "01 Dec 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.038",
            "currency": "SGD",
            "conv_ratio_cal": 17.999999856,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.007",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "28.3",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:45",
            "moneyness": "2",
            "ASKSIZE": "7,000",
            "premium": "7.7",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "D05",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 28.500000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "DBS",
            "TURNOVER": "2,469",
            "PCTCHNG": "+19.4",
            "YRLOW": "-",
            "BEST_ASIZ1": "7000",
            "ACVOL_1": "69,000",
            "days_to_maturity": "63",
            "ric": "DBML_t38.SI",
            "HOTLIST": false,
            "exercise_price": "28.50"
        }
    ],
    "loser": [
        {
            "underlying_ric": "1211.HK",
            "b_trbc_business": null,
            "theta": "-34.04",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.215",
            "percent_moneyness": "16",
            "last_trading_date": "23 Feb 21",
            "HIGH_1": "0.285",
            "type": "CALL",
            "effective_gearing": "19.8",
            "conv_ratio": "25.0",
            "wrnt_per_share": "0.040",
            "BID_PCTCHNG_2DP": "-2,000.00",
            "LOW_1": "0.001",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "XATW",
            "breakeven_price": "250.15",
            "underlying_pctchng": "+6.19",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.001000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.086",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "BYD MB eCW210302",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "182.22",
            "delta": "1.4",
            "APPCHEXPRY": true,
            "TRADE_DATE": "03 Feb 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 36.160020000,
            "BID_PCTCHNG": "-2,000.0",
            "BIDSIZE": "0",
            "underlying_price_cal": 209.4,
            "exercise_price_adj": 42.925000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "BYD MB eCW210302",
            "YRHIGH": "-",
            "BEST_BSIZ1": "0",
            "HST_CLOSE": "0.001",
            "ACVOL_1_SCALED": "0",
            "BID": "0.000",
            "r_hst_close": 0.1717000000,
            "maturity": "02 Mar 21",
            "div_yield": 0.075932000,
            "underlying_bid": 209.4,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "BYML_td.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "209.40",
            "list_date": "12 Nov 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.000",
            "currency": "SGD",
            "conv_ratio_cal": 25.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.020",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "150.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "0",
            "premium": "18.8",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "BYD COMPANY",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 250.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "BYD COMPANY",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "0",
            "ACVOL_1": "0",
            "days_to_maturity": "1",
            "ric": "BYML_td.SI",
            "HOTLIST": false,
            "exercise_price": "250.00"
        },
        {
            "underlying_ric": "AEM.SI",
            "b_trbc_business": null,
            "theta": "-113.00",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.011",
            "percent_moneyness": "11",
            "last_trading_date": "23 Feb 21",
            "HIGH_1": "0.068",
            "type": "CALL",
            "effective_gearing": "15.3",
            "conv_ratio": "10.0",
            "wrnt_per_share": "0.100",
            "BID_PCTCHNG_2DP": "-200.00",
            "LOW_1": "0.002",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "983W",
            "breakeven_price": "4.52",
            "underlying_pctchng": "-0.50",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.002000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.049",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "AEM MB eCW210302",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "13.08",
            "delta": "7.6",
            "APPCHEXPRY": true,
            "TRADE_DATE": "22 Feb 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 4.010000000,
            "BID_PCTCHNG": "-200.0",
            "BIDSIZE": "0",
            "underlying_price_cal": 4.01,
            "exercise_price_adj": 4.500000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "AEM MB eCW210302",
            "YRHIGH": "-",
            "BEST_BSIZ1": "0",
            "HST_CLOSE": "0.002",
            "ACVOL_1_SCALED": "0",
            "BID": "0.000",
            "r_hst_close": 1,
            "maturity": "02 Mar 21",
            "div_yield": 2.352941000,
            "underlying_bid": 4.01,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "AEML_tk.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "4.01",
            "list_date": "24 Sep 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.000",
            "currency": "SGD",
            "conv_ratio_cal": 10.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.004",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "150.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "0",
            "premium": "12.7",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "AWX",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 4.500000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "AEM HOLDINGS LTD",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "0",
            "ACVOL_1": "0",
            "days_to_maturity": "1",
            "ric": "AEML_tk.SI",
            "HOTLIST": false,
            "exercise_price": "4.50"
        },
        {
            "underlying_ric": "9988.HK",
            "b_trbc_business": null,
            "theta": "-1.99",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.013",
            "percent_moneyness": "33",
            "last_trading_date": "26 Mar 21",
            "HIGH_1": "0.149",
            "type": "CALL",
            "effective_gearing": "18.1",
            "conv_ratio": "25.0",
            "wrnt_per_share": "0.040",
            "BID_PCTCHNG_2DP": "-100.00",
            "LOW_1": "0.001",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "1M7W",
            "breakeven_price": "350.15",
            "underlying_pctchng": "+0.52",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.001000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.124",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "AlibabaMBeCW210405",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "222.28",
            "delta": "1.1",
            "APPCHEXPRY": false,
            "TRADE_DATE": "20 Jan 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 40.280820000,
            "BID_PCTCHNG": "-100.0",
            "BIDSIZE": "0",
            "underlying_price_cal": 233.6,
            "exercise_price_adj": 60.095000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "AlibabaMBeCW210405",
            "YRHIGH": "-",
            "BEST_BSIZ1": "0",
            "HST_CLOSE": "0.001",
            "ACVOL_1_SCALED": "0",
            "BID": "0.001",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Apr 21",
            "div_yield": 0E-9,
            "underlying_bid": 233.4,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "ALML_tf.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "233.60",
            "list_date": "22 Oct 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.005",
            "currency": "SGD",
            "conv_ratio_cal": 25.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.001",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "55.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "100,000",
            "premium": "49.3",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "BABA-SW",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 350.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "BABA-SW",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "100000",
            "ACVOL_1": "0",
            "days_to_maturity": "35",
            "ric": "ALML_tf.SI",
            "HOTLIST": false,
            "exercise_price": "350.00"
        },
        {
            "underlying_ric": "SSIcv1",
            "b_trbc_business": null,
            "theta": "-0.45",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.077",
            "percent_moneyness": "18",
            "last_trading_date": "05 Mar 21",
            "HIGH_1": "0.545",
            "type": "PUT",
            "effective_gearing": "40.7",
            "conv_ratio": "80.0",
            "wrnt_per_share": "0.013",
            "BID_PCTCHNG_2DP": "-66.67",
            "LOW_1": "0.001",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "VTWW",
            "breakeven_price": "24,993.59",
            "underlying_pctchng": "+1.06",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.001000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.500",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "NKY 25000MBePW210312",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "727.24",
            "delta": "-0.9",
            "APPCHEXPRY": true,
            "TRADE_DATE": "05 Jan 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 369.362175000,
            "BID_PCTCHNG": "-66.7",
            "BIDSIZE": "1,000,000",
            "underlying_price_cal": 29570.0,
            "exercise_price_adj": 312.225000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "NKY 25000MBePW210312",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1000000",
            "HST_CLOSE": "0.003",
            "ACVOL_1_SCALED": "0",
            "BID": "0.001",
            "r_hst_close": 0.0124890000,
            "maturity": "12 Mar 21",
            "div_yield": 0E-9,
            "underlying_bid": 29565.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "NIML_t21.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,570.00",
            "list_date": "10 Sep 20",
            "underlying_curr": "JPY",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.007",
            "currency": "SGD",
            "conv_ratio_cal": 80.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.002",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "41.4",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "1,000,000",
            "premium": "15.5",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "N225",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 25000.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "N225",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "1000000",
            "ACVOL_1": "0",
            "days_to_maturity": "11",
            "ric": "NIML_t21.SI",
            "HOTLIST": false,
            "exercise_price": "25,000.00"
        },
        {
            "underlying_ric": "CMDG.SI",
            "b_trbc_business": null,
            "theta": "-58.29",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.058",
            "percent_moneyness": "5",
            "last_trading_date": "22 Feb 21",
            "HIGH_1": "0.114",
            "type": "CALL",
            "effective_gearing": "46.9",
            "conv_ratio": "2.0",
            "wrnt_per_share": "0.500",
            "BID_PCTCHNG_2DP": "-50.00",
            "LOW_1": "0.001",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "XLJW",
            "breakeven_price": "1.70",
            "underlying_pctchng": "+0.62",
            "delta_per_wrnt": "5.788",
            "warrant_price": 0.002000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.039",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "ComfortDel MBeCW210301",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "3.46",
            "delta": "11.6",
            "APPCHEXPRY": true,
            "TRADE_DATE": "11 Jan 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 1.620000000,
            "BID_PCTCHNG": "-50.0",
            "BIDSIZE": "0",
            "underlying_price_cal": 1.62,
            "exercise_price_adj": 1.700000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "ComfortDel MBeCW210301",
            "YRHIGH": "-",
            "BEST_BSIZ1": "0",
            "HST_CLOSE": "0.002",
            "ACVOL_1_SCALED": "0",
            "BID": "0.000",
            "r_hst_close": 1,
            "maturity": "01 Mar 21",
            "div_yield": 3.888889000,
            "underlying_bid": 1.61,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "CMML_t1.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "1.62",
            "list_date": "11 Sep 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.000",
            "currency": "SGD",
            "conv_ratio_cal": 2.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.001",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "N/A",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "0",
            "premium": "5.2",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "C52",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 1.700000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "COMFORTDELGRO LD",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "0",
            "ACVOL_1": "0",
            "days_to_maturity": "0",
            "ric": "CMML_t1.SI",
            "HOTLIST": false,
            "exercise_price": "1.70"
        },
        {
            "underlying_ric": ".STI",
            "b_trbc_business": null,
            "theta": "-10.03",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.004",
            "percent_moneyness": "19",
            "last_trading_date": "24 Mar 21",
            "HIGH_1": "0.121",
            "type": "PUT",
            "effective_gearing": "28.5",
            "conv_ratio": "2,500.0",
            "wrnt_per_share": "0.000",
            "BID_PCTCHNG_2DP": "-50.00",
            "LOW_1": "0.001",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "DFAW",
            "breakeven_price": "2,497.50",
            "underlying_pctchng": "+0.90",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.001000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.104",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "STI 2500MBePW210331",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "104.54",
            "delta": "-2.4",
            "APPCHEXPRY": true,
            "TRADE_DATE": "17 Feb 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 2975.780000000,
            "BID_PCTCHNG": "-50.0",
            "BIDSIZE": "1,000,000",
            "underlying_price_cal": 2975.57,
            "exercise_price_adj": 2500.000000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "STI 2500MBePW210331",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1000000",
            "HST_CLOSE": "0.002",
            "ACVOL_1_SCALED": "0",
            "BID": "0.001",
            "r_hst_close": 1,
            "maturity": "31 Mar 21",
            "div_yield": 0.305766667,
            "underlying_bid": null,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "STML_t32.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "2,975.57",
            "list_date": "29 Jul 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.005",
            "currency": "SGD",
            "conv_ratio_cal": 2500.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.001",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "31.6",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "1,000,000",
            "premium": "16.1",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "STI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 2500.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "STI",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "1000000",
            "ACVOL_1": "0",
            "days_to_maturity": "30",
            "ric": "STML_t32.SI",
            "HOTLIST": false,
            "exercise_price": "2,500.00"
        },
        {
            "underlying_ric": "STEL.SI",
            "b_trbc_business": null,
            "theta": "-33.90",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.100",
            "percent_moneyness": "3",
            "last_trading_date": "25 Feb 21",
            "HIGH_1": "0.190",
            "type": "CALL",
            "effective_gearing": "41.8",
            "conv_ratio": "1.5",
            "wrnt_per_share": "0.667",
            "BID_PCTCHNG_2DP": "-40.00",
            "LOW_1": "0.008",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "GJAW",
            "breakeven_price": "2.42",
            "underlying_pctchng": "-0.43",
            "delta_per_wrnt": "17.856",
            "warrant_price": 0.010000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.059",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "SingtelMBeCW210304",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "0.56",
            "delta": "26.8",
            "APPCHEXPRY": true,
            "TRADE_DATE": "10 Dec 20",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 2.340000000,
            "BID_PCTCHNG": "-40.0",
            "BIDSIZE": "0",
            "underlying_price_cal": 2.34,
            "exercise_price_adj": 2.400000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "SingtelMBeCW210304",
            "YRHIGH": "-",
            "BEST_BSIZ1": "0",
            "HST_CLOSE": "0.010",
            "ACVOL_1_SCALED": "0",
            "BID": "0.000",
            "r_hst_close": 1,
            "maturity": "04 Mar 21",
            "div_yield": 4.621849000,
            "underlying_bid": 2.34,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "SEML_t18.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "2.34",
            "list_date": "08 Oct 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.000",
            "currency": "SGD",
            "conv_ratio_cal": 1.499999999,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.004",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "44.1",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "0",
            "premium": "3.2",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "Z74",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 2.400000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "SINGTEL",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "0",
            "ACVOL_1": "0",
            "days_to_maturity": "3",
            "ric": "SEML_t18.SI",
            "HOTLIST": false,
            "exercise_price": "2.40"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-1.01",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.028",
            "percent_moneyness": "10",
            "last_trading_date": "23 Mar 21",
            "HIGH_1": "0.245",
            "type": "PUT",
            "effective_gearing": "25.7",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "-30.77",
            "LOW_1": "0.006",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "NMKW",
            "breakeven_price": "26,474.20",
            "underlying_pctchng": "+0.79",
            "delta_per_wrnt": "-0.009",
            "warrant_price": 0.018000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.220",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 26600MBePW210330",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "63.24",
            "delta": "-11.1",
            "APPCHEXPRY": true,
            "TRADE_DATE": "26 Feb 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5015.185300000,
            "BID_PCTCHNG": "-30.8",
            "BIDSIZE": "100,000",
            "underlying_price_cal": 29187.0,
            "exercise_price_adj": 4567.220000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "HSI 26600MBePW210330",
            "YRHIGH": "-",
            "BEST_BSIZ1": "100000",
            "HST_CLOSE": "0.026",
            "ACVOL_1_SCALED": "0",
            "BID": "0.018",
            "r_hst_close": 0.1717000000,
            "maturity": "30 Mar 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29187.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t196.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,187.00",
            "list_date": "15 Dec 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.030",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.008",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "27.7",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "160,000",
            "premium": "9.3",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 26600.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "160000",
            "ACVOL_1": "0",
            "days_to_maturity": "29",
            "ric": "HSML_t196.SI",
            "HOTLIST": false,
            "exercise_price": "26,600.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.98",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.036",
            "percent_moneyness": "13",
            "last_trading_date": "23 Mar 21",
            "HIGH_1": "0.190",
            "type": "PUT",
            "effective_gearing": "19.1",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "-28.57",
            "LOW_1": "0.009",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "KWBW",
            "breakeven_price": "25,625.28",
            "underlying_pctchng": "+0.79",
            "delta_per_wrnt": "-0.010",
            "warrant_price": 0.025000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.171",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 25800MBePW210330",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "61.18",
            "delta": "-11.4",
            "APPCHEXPRY": true,
            "TRADE_DATE": "26 Feb 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5015.185300000,
            "BID_PCTCHNG": "-28.6",
            "BIDSIZE": "100,000",
            "underlying_price_cal": 29187.0,
            "exercise_price_adj": 4429.860000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "HSI 25800MBePW210330",
            "YRHIGH": "-",
            "BEST_BSIZ1": "100000",
            "HST_CLOSE": "0.035",
            "ACVOL_1_SCALED": "0",
            "BID": "0.025",
            "r_hst_close": 0.1717000000,
            "maturity": "30 Mar 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29187.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t197.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,187.00",
            "list_date": "15 Dec 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.028",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.010",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "37.9",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "10,000",
            "premium": "12.2",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 25800.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "10000",
            "ACVOL_1": "0",
            "days_to_maturity": "29",
            "ric": "HSML_t197.SI",
            "HOTLIST": false,
            "exercise_price": "25,800.00"
        },
        {
            "underlying_ric": "UOBH.SI",
            "b_trbc_business": null,
            "theta": "-1.56",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.035",
            "percent_moneyness": "26",
            "last_trading_date": "24 Jun 21",
            "HIGH_1": "0.084",
            "type": "PUT",
            "effective_gearing": "11.4",
            "conv_ratio": "18.0",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "-26.67",
            "LOW_1": "0.011",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "X0RW",
            "breakeven_price": "19.80",
            "underlying_pctchng": "+1.86",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.011000000,
            "TURNOVER_SCALED": "0",
            "OPEN_PRC": "0.081",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "UOB MB ePW210701",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "20.04",
            "delta": "-9.0",
            "APPCHEXPRY": false,
            "TRADE_DATE": "12 Jan 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 25.140000000,
            "BID_PCTCHNG": "-26.7",
            "BIDSIZE": "1,000,000",
            "underlying_price_cal": 25.14,
            "exercise_price_adj": 20.000000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "UOB MB ePW210701",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1000000",
            "HST_CLOSE": "0.015",
            "ACVOL_1_SCALED": "0",
            "BID": "0.011",
            "r_hst_close": 1,
            "maturity": "01 Jul 21",
            "div_yield": 4.320487000,
            "underlying_bid": 25.14,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "UOML_t27.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "25.14",
            "list_date": "13 Nov 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.014",
            "currency": "SGD",
            "conv_ratio_cal": 17.999999856,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.004",
            "NETCHNG_1": "0.0",
            "HST_VOL": "-",
            "implied_volatility": "30.3",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "  :",
            "moneyness": "2",
            "ASKSIZE": "1,000,000",
            "premium": "21.2",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "U11",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 20.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "UOB",
            "TURNOVER": "0",
            "PCTCHNG": "0.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "1000000",
            "ACVOL_1": "0",
            "days_to_maturity": "122",
            "ric": "UOML_t27.SI",
            "HOTLIST": false,
            "exercise_price": "20.00"
        }
    ],
    "topstock": [
        {
            "underlying_ric": "9988.HK",
            "b_trbc_business": null,
            "theta": "-0.18",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.031",
            "percent_moneyness": "22",
            "last_trading_date": "28 Jun 21",
            "HIGH_1": "0.090",
            "type": "CALL",
            "effective_gearing": "6.7",
            "conv_ratio": "45.0",
            "wrnt_per_share": "0.022",
            "BID_PCTCHNG_2DP": "0.00",
            "LOW_1": "0.021",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "R42W",
            "breakeven_price": "307.86",
            "underlying_pctchng": "+0.52",
            "delta_per_wrnt": "0.518",
            "warrant_price": 0.031000000,
            "TURNOVER_SCALED": "8",
            "OPEN_PRC": "0.072",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "Alibaba MB eCW210705",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "19.29",
            "delta": "23.3",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 40.315160000,
            "BID_PCTCHNG": "0.0",
            "BIDSIZE": "1,300,000",
            "underlying_price_cal": 233.6,
            "exercise_price_adj": 51.510000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "Alibaba MB eCW210705",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1300000",
            "HST_CLOSE": "0.030",
            "ACVOL_1_SCALED": "250",
            "BID": "0.030",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Jul 21",
            "div_yield": 0E-9,
            "underlying_bid": 233.4,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "ALML_th.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "233.60",
            "list_date": "17 Dec 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.032",
            "currency": "SGD",
            "conv_ratio_cal": 45.000000450,
            "UPLIMIT": "0",
            "BID_NETCHNG": "0.000",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "48.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:40",
            "moneyness": "2",
            "ASKSIZE": "2,500,000",
            "premium": "31.8",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "BABA-SW",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 300.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "BABA-SW",
            "TURNOVER": "7,762",
            "PCTCHNG": "+3.3",
            "YRLOW": "-",
            "BEST_ASIZ1": "2500000",
            "ACVOL_1": "250,400",
            "days_to_maturity": "126",
            "ric": "ALML_th.SI",
            "HOTLIST": true,
            "exercise_price": "300.00"
        },
        {
            "underlying_ric": "DBSM.SI",
            "b_trbc_business": null,
            "theta": "-0.73",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.093",
            "percent_moneyness": "4",
            "last_trading_date": "30 Mar 21",
            "HIGH_1": "0.140",
            "type": "CALL",
            "effective_gearing": "11.1",
            "conv_ratio": "18.0",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "+22.97",
            "LOW_1": "0.017",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "EYOW",
            "breakeven_price": "27.64",
            "underlying_pctchng": "+1.61",
            "delta_per_wrnt": "3.688",
            "warrant_price": 0.090000000,
            "TURNOVER_SCALED": "11",
            "OPEN_PRC": "0.063",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "DBS MB eCW210407",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "2.71",
            "delta": "66.4",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 27.070000000,
            "BID_PCTCHNG": "+23.0",
            "BIDSIZE": "90,000",
            "underlying_price_cal": 27.07,
            "exercise_price_adj": 26.000000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "DBS MB eCW210407",
            "YRHIGH": "-",
            "BEST_BSIZ1": "90000",
            "HST_CLOSE": "0.074",
            "ACVOL_1_SCALED": "131",
            "BID": "0.091",
            "r_hst_close": 1,
            "maturity": "07 Apr 21",
            "div_yield": 4.037175000,
            "underlying_bid": 27.07,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "DBML_t31.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "27.07",
            "list_date": "10 Jun 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.092",
            "currency": "SGD",
            "conv_ratio_cal": 17.999999856,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.017",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "31.1",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:29",
            "moneyness": "1",
            "ASKSIZE": "540,000",
            "premium": "2.0",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "D05",
            "intrinsic_value": "0.059",
            "exercise_price_cal": 26.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "DBS",
            "TURNOVER": "11,109",
            "PCTCHNG": "+24.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "540000",
            "ACVOL_1": "130,500",
            "days_to_maturity": "37",
            "ric": "DBML_t31.SI",
            "HOTLIST": false,
            "exercise_price": "26.00"
        },
        {
            "underlying_ric": "3690.HK",
            "b_trbc_business": null,
            "theta": "-0.10",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.130",
            "percent_moneyness": "10",
            "last_trading_date": "28 Jun 21",
            "HIGH_1": "0.360",
            "type": "CALL",
            "effective_gearing": "4.8",
            "conv_ratio": "45.0",
            "wrnt_per_share": "0.022",
            "BID_PCTCHNG_2DP": "+24.76",
            "LOW_1": "0.038",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "YC8W",
            "breakeven_price": "434.33",
            "underlying_pctchng": "+5.53",
            "delta_per_wrnt": "0.993",
            "warrant_price": 0.129000000,
            "TURNOVER_SCALED": "14",
            "OPEN_PRC": "0.123",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "Meituan MB eCW210705",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "10.08",
            "delta": "44.7",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 61.812000000,
            "BID_PCTCHNG": "+24.8",
            "BIDSIZE": "500,000",
            "underlying_price_cal": 358.8,
            "exercise_price_adj": 68.680000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "Meituan MB eCW210705",
            "YRHIGH": "-",
            "BEST_BSIZ1": "500000",
            "HST_CLOSE": "0.105",
            "ACVOL_1_SCALED": "109",
            "BID": "0.131",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Jul 21",
            "div_yield": 0E-9,
            "underlying_bid": 358.6,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "MEML_td.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "358.80",
            "list_date": "16 Nov 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.133",
            "currency": "SGD",
            "conv_ratio_cal": 45.000000450,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.026",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "57.1",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:47",
            "moneyness": "2",
            "ASKSIZE": "100,000",
            "premium": "21.1",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "MEITUAN-W",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 400.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "MEITUAN-W",
            "TURNOVER": "13,692",
            "PCTCHNG": "+25.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "100000",
            "ACVOL_1": "109,400",
            "days_to_maturity": "126",
            "ric": "MEML_td.SI",
            "HOTLIST": true,
            "exercise_price": "400.00"
        },
        {
            "underlying_ric": "UOBH.SI",
            "b_trbc_business": null,
            "theta": "-1.07",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.065",
            "percent_moneyness": "1",
            "last_trading_date": "30 Mar 21",
            "HIGH_1": "0.087",
            "type": "CALL",
            "effective_gearing": "12.2",
            "conv_ratio": "17.83",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "+25.00",
            "LOW_1": "0.016",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "FSXW",
            "breakeven_price": "25.93",
            "underlying_pctchng": "+1.86",
            "delta_per_wrnt": "3.149",
            "warrant_price": 0.065000000,
            "TURNOVER_SCALED": "6",
            "OPEN_PRC": "0.070",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "UOB MB eCW210407",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "3.18",
            "delta": "56.2",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 25.140000000,
            "BID_PCTCHNG": "+25.0",
            "BIDSIZE": "480,000",
            "underlying_price_cal": 25.14,
            "exercise_price_adj": 24.770000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "UOB MB eCW210407",
            "YRHIGH": "-",
            "BEST_BSIZ1": "480000",
            "HST_CLOSE": "0.052",
            "ACVOL_1_SCALED": "101",
            "BID": "0.065",
            "r_hst_close": 1,
            "maturity": "07 Apr 21",
            "div_yield": 4.320487000,
            "underlying_bid": 25.14,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "UOML_t25.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "25.14",
            "list_date": "10 Jun 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.066",
            "currency": "SGD",
            "conv_ratio_cal": 17.834213155,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.013",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "31.6",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:30",
            "moneyness": "1",
            "ASKSIZE": "1,360,000",
            "premium": "3.1",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "U11",
            "intrinsic_value": "0.021",
            "exercise_price_cal": 24.770000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "UOB",
            "TURNOVER": "6,425",
            "PCTCHNG": "+20.4",
            "YRLOW": "-",
            "BEST_ASIZ1": "1360000",
            "ACVOL_1": "101,200",
            "days_to_maturity": "37",
            "ric": "UOML_t25.SI",
            "HOTLIST": false,
            "exercise_price": "24.77"
        },
        {
            "underlying_ric": "WLIL.SI",
            "b_trbc_business": null,
            "theta": "-0.14",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.133",
            "percent_moneyness": "11",
            "last_trading_date": "11 Oct 21",
            "HIGH_1": "0.158",
            "type": "CALL",
            "effective_gearing": "3.2",
            "conv_ratio": "8.5",
            "wrnt_per_share": "0.118",
            "BID_PCTCHNG_2DP": "+3.13",
            "LOW_1": "0.068",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "NLGW",
            "breakeven_price": "5.92",
            "underlying_pctchng": "+1.33",
            "delta_per_wrnt": "7.799",
            "warrant_price": 0.132000000,
            "TURNOVER_SCALED": "13",
            "OPEN_PRC": "0.070",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "Wilmar MB eCW211018",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "1.28",
            "delta": "66.3",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5.350000000,
            "BID_PCTCHNG": "+3.1",
            "BIDSIZE": "2,770,000",
            "underlying_price_cal": 5.35,
            "exercise_price_adj": 4.800000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "Wilmar MB eCW211018",
            "YRHIGH": "-",
            "BEST_BSIZ1": "2770000",
            "HST_CLOSE": "0.128",
            "ACVOL_1_SCALED": "100",
            "BID": "0.132",
            "r_hst_close": 1,
            "maturity": "18 Oct 21",
            "div_yield": 2.787150000,
            "underlying_bid": 5.35,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "WLML_t9.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "5.35",
            "list_date": "07 Dec 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.134",
            "currency": "SGD",
            "conv_ratio_cal": 8.499999987,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.004",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "53.4",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "01:01",
            "moneyness": "1",
            "ASKSIZE": "2,580,000",
            "premium": "10.7",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "F34",
            "intrinsic_value": "0.065",
            "exercise_price_cal": 4.800000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "WILMAR INTERNATI",
            "TURNOVER": "13,300",
            "PCTCHNG": "+3.9",
            "YRLOW": "-",
            "BEST_ASIZ1": "2580000",
            "ACVOL_1": "100,000",
            "days_to_maturity": "231",
            "ric": "WLML_t9.SI",
            "HOTLIST": true,
            "exercise_price": "4.80"
        },
        {
            "underlying_ric": "OCBC.SI",
            "b_trbc_business": null,
            "theta": "-0.37",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.091",
            "percent_moneyness": "6",
            "last_trading_date": "03 May 21",
            "HIGH_1": "0.091",
            "type": "CALL",
            "effective_gearing": "8.2",
            "conv_ratio": "10.0",
            "wrnt_per_share": "0.100",
            "BID_PCTCHNG_2DP": "+9.76",
            "LOW_1": "0.045",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "LQFW",
            "breakeven_price": "11.40",
            "underlying_pctchng": "+1.09",
            "delta_per_wrnt": "6.664",
            "warrant_price": 0.090000000,
            "TURNOVER_SCALED": "8",
            "OPEN_PRC": "0.045",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "OCBC Bk MB eCW210510",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "1.50",
            "delta": "66.6",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 11.110000000,
            "BID_PCTCHNG": "+9.8",
            "BIDSIZE": "710,000",
            "underlying_price_cal": 11.11,
            "exercise_price_adj": 10.500000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "OCBC Bk MB eCW210510",
            "YRHIGH": "-",
            "BEST_BSIZ1": "710000",
            "HST_CLOSE": "0.082",
            "ACVOL_1_SCALED": "89",
            "BID": "0.090",
            "r_hst_close": 1,
            "maturity": "10 May 21",
            "div_yield": 4.182475000,
            "underlying_bid": 11.11,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "OCML_t20.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "11.11",
            "list_date": "16 Nov 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.091",
            "currency": "SGD",
            "conv_ratio_cal": 10.000000000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.008",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "31.1",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:43",
            "moneyness": "1",
            "ASKSIZE": "370,800",
            "premium": "2.6",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "O39",
            "intrinsic_value": "0.061",
            "exercise_price_cal": 10.500000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "OCBC",
            "TURNOVER": "7,624",
            "PCTCHNG": "+11.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "370800",
            "ACVOL_1": "89,200",
            "days_to_maturity": "70",
            "ric": "OCML_t20.SI",
            "HOTLIST": false,
            "exercise_price": "10.50"
        },
        {
            "underlying_ric": "DBSM.SI",
            "b_trbc_business": null,
            "theta": "-1.28",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.037",
            "percent_moneyness": "5",
            "last_trading_date": "26 Apr 21",
            "HIGH_1": "0.077",
            "type": "CALL",
            "effective_gearing": "13.7",
            "conv_ratio": "18.0",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "+23.33",
            "LOW_1": "0.018",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "HRZW",
            "breakeven_price": "29.17",
            "underlying_pctchng": "+1.61",
            "delta_per_wrnt": "1.867",
            "warrant_price": 0.037000000,
            "TURNOVER_SCALED": "2",
            "OPEN_PRC": "0.045",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "DBS MB eCW210503",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "5.36",
            "delta": "33.6",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 27.070000000,
            "BID_PCTCHNG": "+23.3",
            "BIDSIZE": "260,000",
            "underlying_price_cal": 27.07,
            "exercise_price_adj": 28.500000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "DBS MB eCW210503",
            "YRHIGH": "-",
            "BEST_BSIZ1": "260000",
            "HST_CLOSE": "0.030",
            "ACVOL_1_SCALED": "69",
            "BID": "0.037",
            "r_hst_close": 1,
            "maturity": "03 May 21",
            "div_yield": 4.037175000,
            "underlying_bid": 27.07,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "DBML_t38.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "27.07",
            "list_date": "01 Dec 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.038",
            "currency": "SGD",
            "conv_ratio_cal": 17.999999856,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.007",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "28.3",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:45",
            "moneyness": "2",
            "ASKSIZE": "7,000",
            "premium": "7.7",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "D05",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 28.500000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "DBS",
            "TURNOVER": "2,469",
            "PCTCHNG": "+19.4",
            "YRLOW": "-",
            "BEST_ASIZ1": "7000",
            "ACVOL_1": "69,000",
            "days_to_maturity": "63",
            "ric": "DBML_t38.SI",
            "HOTLIST": false,
            "exercise_price": "28.50"
        },
        {
            "underlying_ric": "DBSM.SI",
            "b_trbc_business": null,
            "theta": "-0.73",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.056",
            "percent_moneyness": "10",
            "last_trading_date": "28 Jun 21",
            "HIGH_1": "0.134",
            "type": "PUT",
            "effective_gearing": "7.5",
            "conv_ratio": "18.0",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "-15.15",
            "LOW_1": "0.055",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "QYLW",
            "breakeven_price": "23.49",
            "underlying_pctchng": "+1.61",
            "delta_per_wrnt": "-1.559",
            "warrant_price": 0.056000000,
            "TURNOVER_SCALED": "2",
            "OPEN_PRC": "0.128",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "DBS MB ePW210705",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "6.42",
            "delta": "-28.1",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 27.070000000,
            "BID_PCTCHNG": "-15.2",
            "BIDSIZE": "1,490,000",
            "underlying_price_cal": 27.07,
            "exercise_price_adj": 24.500000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "DBS MB ePW210705",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1490000",
            "HST_CLOSE": "0.066",
            "ACVOL_1_SCALED": "40",
            "BID": "0.056",
            "r_hst_close": 1,
            "maturity": "05 Jul 21",
            "div_yield": 4.037175000,
            "underlying_bid": 27.07,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "DBML_t37.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "27.07",
            "list_date": "01 Dec 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.057",
            "currency": "SGD",
            "conv_ratio_cal": 17.999999856,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.010",
            "NETCHNG_1": "-0.0",
            "HST_VOL": "-",
            "implied_volatility": "32.7",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:29",
            "moneyness": "2",
            "ASKSIZE": "310,000",
            "premium": "13.2",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "D05",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 24.500000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "DBS",
            "TURNOVER": "2,302",
            "PCTCHNG": "-13.9",
            "YRLOW": "-",
            "BEST_ASIZ1": "310000",
            "ACVOL_1": "40,200",
            "days_to_maturity": "126",
            "ric": "DBML_t37.SI",
            "HOTLIST": true,
            "exercise_price": "24.50"
        },
        {
            "underlying_ric": "UOBH.SI",
            "b_trbc_business": null,
            "theta": "-0.36",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.062",
            "percent_moneyness": "10",
            "last_trading_date": "01 Oct 21",
            "HIGH_1": "0.063",
            "type": "CALL",
            "effective_gearing": "7.3",
            "conv_ratio": "18.0",
            "wrnt_per_share": "0.056",
            "BID_PCTCHNG_2DP": "+14.55",
            "LOW_1": "0.035",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "RABW",
            "breakeven_price": "29.13",
            "underlying_pctchng": "+1.86",
            "delta_per_wrnt": "1.825",
            "warrant_price": 0.063000000,
            "TURNOVER_SCALED": "1",
            "OPEN_PRC": "0.049",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "UOB MB eCW211008",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "5.48",
            "delta": "32.9",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 25.140000000,
            "BID_PCTCHNG": "+14.5",
            "BIDSIZE": "1,200,000",
            "underlying_price_cal": 25.14,
            "exercise_price_adj": 28.000000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "UOB MB eCW211008",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1200000",
            "HST_CLOSE": "0.055",
            "ACVOL_1_SCALED": "20",
            "BID": "0.063",
            "r_hst_close": 1,
            "maturity": "08 Oct 21",
            "div_yield": 4.320487000,
            "underlying_bid": 25.14,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "UOML_t29.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "25.14",
            "list_date": "27 Nov 20",
            "underlying_curr": "SGD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.065",
            "currency": "SGD",
            "conv_ratio_cal": 17.999999856,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.008",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "30.1",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:08",
            "moneyness": "2",
            "ASKSIZE": "3,000,000",
            "premium": "15.9",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "U11",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 28.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "UOB",
            "TURNOVER": "1,240",
            "PCTCHNG": "+40.9",
            "YRLOW": "-",
            "BEST_ASIZ1": "3000000",
            "ACVOL_1": "20,000",
            "days_to_maturity": "221",
            "ric": "UOML_t29.SI",
            "HOTLIST": true,
            "exercise_price": "28.00"
        },
        {
            "underlying_ric": "1810.HK",
            "b_trbc_business": null,
            "theta": "-0.01",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.305",
            "percent_moneyness": "30",
            "last_trading_date": "26 Mar 21",
            "HIGH_1": "0.770",
            "type": "CALL",
            "effective_gearing": "4.2",
            "conv_ratio": "3.5",
            "wrnt_per_share": "0.286",
            "BID_PCTCHNG_2DP": "+9.26",
            "LOW_1": "0.052",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "FWDW",
            "breakeven_price": "26.01",
            "underlying_pctchng": "+2.77",
            "delta_per_wrnt": "N/A",
            "warrant_price": 0.295000000,
            "TURNOVER_SCALED": "5",
            "OPEN_PRC": "0.110",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "XIAOMI MBeCW210405",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "1.80",
            "delta": "97.5",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 4.455615000,
            "BID_PCTCHNG": "+9.3",
            "BIDSIZE": "50,000",
            "underlying_price_cal": 26.0,
            "exercise_price_adj": 3.434000000,
            "update_time": "01 Mar 21 11:50",
            "dsply_name": "XIAOMI MBeCW210405",
            "YRHIGH": "-",
            "BEST_BSIZ1": "50000",
            "HST_CLOSE": "0.270",
            "ACVOL_1_SCALED": "16",
            "BID": "0.295",
            "r_hst_close": 0.1717000000,
            "maturity": "05 Apr 21",
            "div_yield": 0E-9,
            "underlying_bid": 26.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "XIML_t11.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "26.00",
            "list_date": "13 Jul 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.310",
            "currency": "SGD",
            "conv_ratio_cal": 3.499999997,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.025",
            "NETCHNG_1": "-0.3",
            "HST_VOL": "-",
            "implied_volatility": "45.5",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "01:42",
            "moneyness": "1",
            "ASKSIZE": "10,000",
            "premium": "0.1",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "XIAOMI-W",
            "intrinsic_value": "0.294",
            "exercise_price_cal": 20.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "XIAOMI-W",
            "TURNOVER": "4,576",
            "PCTCHNG": "-52.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "10000",
            "ACVOL_1": "15,600",
            "days_to_maturity": "35",
            "ric": "XIML_t11.SI",
            "HOTLIST": false,
            "exercise_price": "20.00"
        }
    ],
    "topindex": [
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.28",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.088",
            "percent_moneyness": "8",
            "last_trading_date": "22 Apr 21",
            "HIGH_1": "0.305",
            "type": "PUT",
            "effective_gearing": "12.2",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "-14.71",
            "LOW_1": "0.030",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "8XWW",
            "breakeven_price": "26,391.96",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "-0.021",
            "warrant_price": 0.087000000,
            "TURNOVER_SCALED": "1,827",
            "OPEN_PRC": "0.280",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 27000MBePW210429",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "27.50",
            "delta": "-25.4",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "-14.7",
            "BIDSIZE": "3,000,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 4635.900000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 27000MBePW210429",
            "YRHIGH": "-",
            "BEST_BSIZ1": "3000000",
            "HST_CLOSE": "0.102",
            "ACVOL_1_SCALED": "20,975",
            "BID": "0.087",
            "r_hst_close": 0.1717000000,
            "maturity": "29 Apr 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t199.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "16 Dec 20",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.088",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.015",
            "NETCHNG_1": "-0.0",
            "HST_VOL": "-",
            "implied_volatility": "32.5",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:32",
            "moneyness": "2",
            "ASKSIZE": "3,000,000",
            "premium": "9.6",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 27000.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "1,827,440",
            "PCTCHNG": "-17.0",
            "YRLOW": "-",
            "BEST_ASIZ1": "3000000",
            "ACVOL_1": "20,975,000",
            "days_to_maturity": "59",
            "ric": "HSML_t199.SI",
            "HOTLIST": false,
            "exercise_price": "27,000.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.37",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.111",
            "percent_moneyness": "1",
            "last_trading_date": "23 Mar 21",
            "HIGH_1": "0.275",
            "type": "CALL",
            "effective_gearing": "16.9",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "+11.11",
            "LOW_1": "0.094",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "5N8W",
            "breakeven_price": "30,368.78",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "0.037",
            "warrant_price": 0.110000000,
            "TURNOVER_SCALED": "1,451",
            "OPEN_PRC": "0.172",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 29600MBeCW210330",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "15.72",
            "delta": "44.4",
            "APPCHEXPRY": true,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "+11.1",
            "BIDSIZE": "500,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 5082.320000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 29600MBeCW210330",
            "YRHIGH": "-",
            "BEST_BSIZ1": "500000",
            "HST_CLOSE": "0.099",
            "ACVOL_1_SCALED": "13,300",
            "BID": "0.110",
            "r_hst_close": 0.1717000000,
            "maturity": "30 Mar 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t215.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "11 Feb 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.111",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.011",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "28.6",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:47",
            "moneyness": "2",
            "ASKSIZE": "500,000",
            "premium": "4.0",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 29600.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "1,450,720",
            "PCTCHNG": "+12.1",
            "YRLOW": "-",
            "BEST_ASIZ1": "500000",
            "ACVOL_1": "13,300,000",
            "days_to_maturity": "29",
            "ric": "HSML_t215.SI",
            "HOTLIST": false,
            "exercise_price": "29,600.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.45",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.090",
            "percent_moneyness": "4",
            "last_trading_date": "23 Mar 21",
            "HIGH_1": "0.116",
            "type": "PUT",
            "effective_gearing": "15.6",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "-18.75",
            "LOW_1": "0.028",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "9PCW",
            "breakeven_price": "27,564.01",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "-0.028",
            "warrant_price": 0.091000000,
            "TURNOVER_SCALED": "1,092",
            "OPEN_PRC": "0.077",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 28200MBePW210330",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "20.59",
            "delta": "-33.9",
            "APPCHEXPRY": true,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "-18.8",
            "BIDSIZE": "1,000,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 4841.940000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 28200MBePW210330",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1000000",
            "HST_CLOSE": "0.112",
            "ACVOL_1_SCALED": "12,030",
            "BID": "0.091",
            "r_hst_close": 0.1717000000,
            "maturity": "30 Mar 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t213.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "11 Feb 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.092",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.021",
            "NETCHNG_1": "-0.0",
            "HST_VOL": "-",
            "implied_volatility": "32.9",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:46",
            "moneyness": "2",
            "ASKSIZE": "1,000,000",
            "premium": "5.6",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 28200.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "1,091,985",
            "PCTCHNG": "-18.9",
            "YRLOW": "-",
            "BEST_ASIZ1": "1000000",
            "ACVOL_1": "12,030,000",
            "days_to_maturity": "29",
            "ric": "HSML_t213.SI",
            "HOTLIST": false,
            "exercise_price": "28,200.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.54",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.066",
            "percent_moneyness": "4",
            "last_trading_date": "23 Mar 21",
            "HIGH_1": "0.197",
            "type": "CALL",
            "effective_gearing": "20.2",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "+8.33",
            "LOW_1": "0.040",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "HGKW",
            "breakeven_price": "30,854.28",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "0.026",
            "warrant_price": 0.065000000,
            "TURNOVER_SCALED": "759",
            "OPEN_PRC": "0.042",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 30400MBeCW210330",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "22.23",
            "delta": "31.4",
            "APPCHEXPRY": true,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "+8.3",
            "BIDSIZE": "510,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 5219.680000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 30400MBeCW210330",
            "YRHIGH": "-",
            "BEST_BSIZ1": "510000",
            "HST_CLOSE": "0.060",
            "ACVOL_1_SCALED": "11,621",
            "BID": "0.065",
            "r_hst_close": 0.1717000000,
            "maturity": "30 Mar 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t212.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "08 Jan 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.066",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.005",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "27.9",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:48",
            "moneyness": "2",
            "ASKSIZE": "366,600",
            "premium": "5.6",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 30400.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "759,493",
            "PCTCHNG": "+8.2",
            "YRLOW": "-",
            "BEST_ASIZ1": "366600",
            "ACVOL_1": "11,621,000",
            "days_to_maturity": "29",
            "ric": "HSML_t212.SI",
            "HOTLIST": false,
            "exercise_price": "30,400.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.39",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.053",
            "percent_moneyness": "10",
            "last_trading_date": "22 Apr 21",
            "HIGH_1": "0.145",
            "type": "CALL",
            "effective_gearing": "16.2",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "+8.51",
            "LOW_1": "0.026",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "LPQW",
            "breakeven_price": "32,756.44",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "0.016",
            "warrant_price": 0.051000000,
            "TURNOVER_SCALED": "522",
            "OPEN_PRC": "0.145",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 32400MBeCW210429",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "35.45",
            "delta": "19.7",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "+8.5",
            "BIDSIZE": "3,000,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 5563.080000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 32400MBeCW210429",
            "YRHIGH": "-",
            "BEST_BSIZ1": "3000000",
            "HST_CLOSE": "0.047",
            "ACVOL_1_SCALED": "9,888",
            "BID": "0.051",
            "r_hst_close": 0.1717000000,
            "maturity": "29 Apr 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t218.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "18 Feb 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": true,
            "ASK": "0.052",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.004",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "28.7",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:46",
            "moneyness": "2",
            "ASKSIZE": "300,000",
            "premium": "12.2",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 32400.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "522,180",
            "PCTCHNG": "+10.4",
            "YRLOW": "-",
            "BEST_ASIZ1": "300000",
            "ACVOL_1": "9,887,700",
            "days_to_maturity": "59",
            "ric": "HSML_t218.SI",
            "HOTLIST": false,
            "exercise_price": "32,400.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.32",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.071",
            "percent_moneyness": "8",
            "last_trading_date": "22 Apr 21",
            "HIGH_1": "0.184",
            "type": "CALL",
            "effective_gearing": "15.1",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "+9.23",
            "LOW_1": "0.062",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "KKAW",
            "breakeven_price": "32,096.21",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "0.021",
            "warrant_price": 0.071000000,
            "TURNOVER_SCALED": "610",
            "OPEN_PRC": "0.184",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 31600MBeCW210429",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "27.16",
            "delta": "25.7",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "+9.2",
            "BIDSIZE": "1,000,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 5425.720000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 31600MBeCW210429",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1000000",
            "HST_CLOSE": "0.065",
            "ACVOL_1_SCALED": "8,535",
            "BID": "0.071",
            "r_hst_close": 0.1717000000,
            "maturity": "29 Apr 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t217.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "18 Feb 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.072",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.006",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "28.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:28",
            "moneyness": "2",
            "ASKSIZE": "1,000,000",
            "premium": "9.9",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 31600.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "610,405",
            "PCTCHNG": "+7.6",
            "YRLOW": "-",
            "BEST_ASIZ1": "1000000",
            "ACVOL_1": "8,535,000",
            "days_to_maturity": "59",
            "ric": "HSML_t217.SI",
            "HOTLIST": false,
            "exercise_price": "31,600.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.20",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.104",
            "percent_moneyness": "11",
            "last_trading_date": "20 May 21",
            "HIGH_1": "0.119",
            "type": "PUT",
            "effective_gearing": "9.8",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "-12.07",
            "LOW_1": "0.055",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "EGAW",
            "breakeven_price": "25,687.13",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "-0.020",
            "warrant_price": 0.102000000,
            "TURNOVER_SCALED": "652",
            "OPEN_PRC": "0.097",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 26400MBePW210528",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "29.14",
            "delta": "-24.0",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "-12.1",
            "BIDSIZE": "500,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 4532.880000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 26400MBePW210528",
            "YRHIGH": "-",
            "BEST_BSIZ1": "500000",
            "HST_CLOSE": "0.116",
            "ACVOL_1_SCALED": "6,405",
            "BID": "0.102",
            "r_hst_close": 0.1717000000,
            "maturity": "28 May 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t214.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "11 Feb 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.103",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.014",
            "NETCHNG_1": "-0.0",
            "HST_VOL": "-",
            "implied_volatility": "32.7",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:19",
            "moneyness": "2",
            "ASKSIZE": "500,000",
            "premium": "12.0",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 26400.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "652,005",
            "PCTCHNG": "-10.3",
            "YRLOW": "-",
            "BEST_ASIZ1": "500000",
            "ACVOL_1": "6,405,000",
            "days_to_maturity": "88",
            "ric": "HSML_t214.SI",
            "HOTLIST": false,
            "exercise_price": "26,400.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.18",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.157",
            "percent_moneyness": "2",
            "last_trading_date": "22 Apr 21",
            "HIGH_1": "0.315",
            "type": "PUT",
            "effective_gearing": "10.8",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "-12.22",
            "LOW_1": "0.060",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "CVCW",
            "breakeven_price": "27,495.75",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "-0.034",
            "warrant_price": 0.158000000,
            "TURNOVER_SCALED": "959",
            "OPEN_PRC": "0.315",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 28600MBePW210429",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "17.06",
            "delta": "-41.0",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "-12.2",
            "BIDSIZE": "1,000,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 4910.620000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 28600MBePW210429",
            "YRHIGH": "-",
            "BEST_BSIZ1": "1000000",
            "HST_CLOSE": "0.180",
            "ACVOL_1_SCALED": "6,010",
            "BID": "0.158",
            "r_hst_close": 0.1717000000,
            "maturity": "29 Apr 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t206.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "08 Jan 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.160",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.022",
            "NETCHNG_1": "-0.0",
            "HST_VOL": "-",
            "implied_volatility": "30.2",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:45",
            "moneyness": "2",
            "ASKSIZE": "390,000",
            "premium": "5.9",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 28600.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "959,135",
            "PCTCHNG": "-12.8",
            "YRLOW": "-",
            "BEST_ASIZ1": "390000",
            "ACVOL_1": "6,010,000",
            "days_to_maturity": "59",
            "ric": "HSML_t206.SI",
            "HOTLIST": false,
            "exercise_price": "28,600.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.26",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.089",
            "percent_moneyness": "5",
            "last_trading_date": "22 Apr 21",
            "HIGH_1": "0.220",
            "type": "CALL",
            "effective_gearing": "15.2",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "+10.00",
            "LOW_1": "0.076",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "X5OW",
            "breakeven_price": "31,415.03",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "0.027",
            "warrant_price": 0.088000000,
            "TURNOVER_SCALED": "489",
            "OPEN_PRC": "0.142",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 30800MBeCW210429",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "21.81",
            "delta": "32.0",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "+10.0",
            "BIDSIZE": "500,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 5288.360000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 30800MBeCW210429",
            "YRHIGH": "-",
            "BEST_BSIZ1": "500000",
            "HST_CLOSE": "0.080",
            "ACVOL_1_SCALED": "5,455",
            "BID": "0.088",
            "r_hst_close": 0.1717000000,
            "maturity": "29 Apr 21",
            "div_yield": 1.265788462,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t216.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "11 Feb 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.089",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "+0.008",
            "NETCHNG_1": "+0.0",
            "HST_VOL": "-",
            "implied_volatility": "26.0",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:47",
            "moneyness": "2",
            "ASKSIZE": "500,000",
            "premium": "7.6",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 30800.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "489,090",
            "PCTCHNG": "+12.7",
            "YRLOW": "-",
            "BEST_ASIZ1": "500000",
            "ACVOL_1": "5,455,000",
            "days_to_maturity": "59",
            "ric": "HSML_t216.SI",
            "HOTLIST": false,
            "exercise_price": "30,800.00"
        },
        {
            "underlying_ric": "HSIc1",
            "b_trbc_business": null,
            "theta": "-0.26",
            "LOLIMIT": "0",
            "TRDPRC_1": "0.070",
            "percent_moneyness": "18",
            "last_trading_date": "20 May 21",
            "HIGH_1": "0.128",
            "type": "PUT",
            "effective_gearing": "9.7",
            "conv_ratio": "1,200.0",
            "wrnt_per_share": "0.001",
            "BID_PCTCHNG_2DP": "-12.66",
            "LOW_1": "0.037",
            "HIGHRESP": false,
            "30D_LOW": "-",
            "ticker": "TPCW",
            "breakeven_price": "24,317.76",
            "underlying_pctchng": "+0.85",
            "delta_per_wrnt": "-0.013",
            "warrant_price": 0.069000000,
            "TURNOVER_SCALED": "305",
            "OPEN_PRC": "0.121",
            "NO_BIDMKR2": "-",
            "dsply_nmll": "HSI 24800MBePW210528",
            "issuer_name": "Macquarie Bank Ltd",
            "sensitivity": "43.42",
            "delta": "-16.1",
            "APPCHEXPRY": false,
            "TRADE_DATE": "01 Mar 21",
            "NO_BIDMKR2_SCALED": "-",
            "underlying_price_adj": 5013.983400000,
            "BID_PCTCHNG": "-12.7",
            "BIDSIZE": "300,000",
            "underlying_price_cal": 29205.0,
            "exercise_price_adj": 4258.160000000,
            "update_time": "01 Mar 21 11:49",
            "dsply_name": "HSI 24800MBePW210528",
            "YRHIGH": "-",
            "BEST_BSIZ1": "300000",
            "HST_CLOSE": "0.079",
            "ACVOL_1_SCALED": "4,400",
            "BID": "0.069",
            "r_hst_close": 0.1717000000,
            "maturity": "28 May 21",
            "div_yield": 1.276384615,
            "underlying_bid": 29204.0,
            "fmt": 1,
            "30D_HIGH": "-",
            "SYMBOL": "HSML_t210.SI",
            "HST_VOL_SCALED": "-",
            "underlying_price": "29,205.00",
            "list_date": "08 Jan 21",
            "underlying_curr": "HKD",
            "exchange_code": "SES",
            "HIGHLQDY": false,
            "ASK": "0.070",
            "currency": "SGD",
            "conv_ratio_cal": 1200.000480000,
            "UPLIMIT": "0",
            "BID_NETCHNG": "-0.010",
            "NETCHNG_1": "-0.0",
            "HST_VOL": "-",
            "implied_volatility": "36.9",
            "VOL_PCTCHNG": "-",
            "TRDTIM_1": "03:32",
            "moneyness": "2",
            "ASKSIZE": "300,000",
            "premium": "16.7",
            "SERVICE": "ELEKTRON_DD",
            "underlying_ticker": "HSI",
            "intrinsic_value": "0.000",
            "exercise_price_cal": 24800.000000000,
            "issuer_code": "MBL",
            "REC_STATUS": "0",
            "underlying_name": "HSI",
            "TURNOVER": "305,100",
            "PCTCHNG": "-6.7",
            "YRLOW": "-",
            "BEST_ASIZ1": "300000",
            "ACVOL_1": "4,400,000",
            "days_to_maturity": "88",
            "ric": "HSML_t210.SI",
            "HOTLIST": false,
            "exercise_price": "24,800.00"
        }
    ]
}

export default rankingData