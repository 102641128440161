// 20210305152021
// https://www.warrants.com.sg/MarketDataJSON?type=lastview&ric=BYML_th.SI,ALML_th.SI,AEML_tm.SI,ALML_tg.SI,BYML_tb.SI,AEM.SI

const lastview = {
  "dwList": [
    {
      "underlying_ric": "1211.HK",
      "b_trbc_business": null,
      "theta": "-0.20",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.039",
      "percent_moneyness": "38",
      "last_trading_date": "28 Jun 21",
      "HIGH_1": "0.179",
      "type": "CALL",
      "effective_gearing": "4.5",
      "conv_ratio": "45.0",
      "wrnt_per_share": "0.022",
      "BID_PCTCHNG_2DP": "-2.56",
      "LOW_1": "0.029",
      "30D_LOW": "-",
      "ticker": "MGVW",
      "breakeven_price": "329.92",
      "underlying_pctchng": "+0.71",
      "delta_per_wrnt": "0.507",
      "warrant_price": 0.038000000,
      "TURNOVER_SCALED": "20",
      "OPEN_PRC": "0.129",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "BYD MB eCW210705A",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "19.71",
      "delta": "22.8",
      "TRADE_DATE": "05 Mar 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 33.943100000,
      "BID_PCTCHNG": "-2.6",
      "BIDSIZE": "1,950,000",
      "underlying_price_cal": 198.4,
      "exercise_price_adj": 55.136000000,
      "update_time": "05 Mar 21 15:19",
      "dsply_name": "BYD MB eCW210705A",
      "YRHIGH": "-",
      "BEST_BSIZ1": "1950000",
      "HST_CLOSE": "0.039",
      "ACVOL_1_SCALED": "542",
      "BID": "0.038",
      "r_hst_close": 0.1723000000,
      "maturity": "05 Jul 21",
      "div_yield": 0.076638000,
      "underlying_bid": 198.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "BYML_tg.SI",
      "HST_VOL_SCALED": "-",
      "underlying_price": "198.40",
      "list_date": "15 Jan 21",
      "underlying_curr": "HKD",
      "exchange_code": "SES",
      "ASK": "0.040",
      "currency": "SGD",
      "conv_ratio_cal": 45.000000450,
      "UPLIMIT": "0",
      "BID_NETCHNG": "-0.001",
      "NETCHNG_1": "0.0",
      "HST_VOL": "-",
      "implied_volatility": "83.4",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "07:18",
      "moneyness": "2",
      "ASKSIZE": "3,000,000",
      "premium": "66.3",
      "SERVICE": "ELEKTRON_DD",
      "underlying_ticker": "BYD COMPANY",
      "intrinsic_value": "0.000",
      "exercise_price_cal": 320.000000000,
      "issuer_code": "MBL",
      "REC_STATUS": "0",
      "underlying_name": "BYD COMPANY",
      "TURNOVER": "19,673",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "3000000",
      "ACVOL_1": "542,000",
      "days_to_maturity": "122",
      "ric": "BYML_tg.SI",
      "exercise_price": "320.00"
    },
    {
      "underlying_ric": "1211.HK",
      "b_trbc_business": null,
      "theta": "-0.00",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.555",
      "percent_moneyness": "42",
      "last_trading_date": "26 Mar 21",
      "HIGH_1": "0.940",
      "type": "CALL",
      "effective_gearing": "3.4",
      "conv_ratio": "25.0",
      "wrnt_per_share": "0.040",
      "BID_PCTCHNG_2DP": "-1.27",
      "LOW_1": "0.098",
      "30D_LOW": "-",
      "ticker": "VHUW",
      "breakeven_price": "196.59",
      "underlying_pctchng": "+0.71",
      "delta_per_wrnt": "N/A",
      "warrant_price": 0.395000000,
      "TURNOVER_SCALED": "0",
      "OPEN_PRC": "0.108",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "BYD MB eCW210405",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "12.50",
      "delta": "100.0",
      "TRADE_DATE": "04 Jan 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 33.960330000,
      "BID_PCTCHNG": "-1.3",
      "BIDSIZE": "100,000",
      "underlying_price_cal": 198.4,
      "exercise_price_adj": 24.122000000,
      "update_time": "05 Mar 21 15:18",
      "dsply_name": "BYD MB eCW210405",
      "YRHIGH": "-",
      "BEST_BSIZ1": "100000",
      "HST_CLOSE": "0.395",
      "ACVOL_1_SCALED": "0",
      "BID": "0.390",
      "r_hst_close": 0.1723000000,
      "maturity": "05 Apr 21",
      "div_yield": 0.076638000,
      "underlying_bid": 198.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "BYML_tb.SI",
      "HST_VOL_SCALED": "-",
      "underlying_price": "198.40",
      "list_date": "05 Oct 20",
      "underlying_curr": "HKD",
      "exchange_code": "SES",
      "ASK": "0.415",
      "currency": "SGD",
      "conv_ratio_cal": 25.000000000,
      "UPLIMIT": "0",
      "BID_NETCHNG": "-0.005",
      "NETCHNG_1": "0.0",
      "HST_VOL": "-",
      "implied_volatility": "10.0",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "1",
      "ASKSIZE": "400,000",
      "premium": "-0.9",
      "SERVICE": "ELEKTRON_DD",
      "underlying_ticker": "BYD COMPANY",
      "intrinsic_value": "0.402",
      "exercise_price_cal": 140.000000000,
      "issuer_code": "MBL",
      "REC_STATUS": "0",
      "underlying_name": "BYD COMPANY",
      "TURNOVER": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "400000",
      "ACVOL_1": "0",
      "days_to_maturity": "31",
      "ric": "BYML_tb.SI",
      "exercise_price": "140.00"
    },
    {
      "underlying_ric": "1211.HK",
      "b_trbc_business": null,
      "theta": "-0.15",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.075",
      "percent_moneyness": "32",
      "last_trading_date": "28 Jun 21",
      "HIGH_1": "0.134",
      "type": "PUT",
      "effective_gearing": "3.4",
      "conv_ratio": "25.0",
      "wrnt_per_share": "0.040",
      "BID_PCTCHNG_2DP": "0.00",
      "LOW_1": "0.025",
      "30D_LOW": "-",
      "ticker": "YBMW",
      "breakeven_price": "139.12",
      "underlying_pctchng": "+0.71",
      "delta_per_wrnt": "-0.753",
      "warrant_price": 0.075000000,
      "TURNOVER_SCALED": "0",
      "OPEN_PRC": "0.134",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "BYD MB ePW210705",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "13.28",
      "delta": "-18.8",
      "TRADE_DATE": "26 Feb 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 33.943100000,
      "BID_PCTCHNG": "0.0",
      "BIDSIZE": "1,000,000",
      "underlying_price_cal": 198.4,
      "exercise_price_adj": 25.845000000,
      "update_time": "05 Mar 21 15:19",
      "dsply_name": "BYD MB ePW210705",
      "YRHIGH": "-",
      "BEST_BSIZ1": "1000000",
      "HST_CLOSE": "0.075",
      "ACVOL_1_SCALED": "0",
      "BID": "0.075",
      "r_hst_close": 0.1723000000,
      "maturity": "05 Jul 21",
      "div_yield": 0.076638000,
      "underlying_bid": 198.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "BYML_tf.SI",
      "HST_VOL_SCALED": "-",
      "underlying_price": "198.40",
      "list_date": "18 Dec 20",
      "underlying_curr": "HKD",
      "exchange_code": "SES",
      "ASK": "0.077",
      "currency": "SGD",
      "conv_ratio_cal": 25.000000000,
      "UPLIMIT": "0",
      "BID_NETCHNG": "0.000",
      "NETCHNG_1": "0.0",
      "HST_VOL": "-",
      "implied_volatility": "72.4",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "2",
      "ASKSIZE": "700,000",
      "premium": "29.9",
      "SERVICE": "ELEKTRON_DD",
      "underlying_ticker": "BYD COMPANY",
      "intrinsic_value": "0.000",
      "exercise_price_cal": 150.000000000,
      "issuer_code": "MBL",
      "REC_STATUS": "0",
      "underlying_name": "BYD COMPANY",
      "TURNOVER": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "700000",
      "ACVOL_1": "0",
      "days_to_maturity": "122",
      "ric": "BYML_tf.SI",
      "exercise_price": "150.00"
    }
  ],
  "lvList": [
    {
      "underlying_ric": "9988.HK",
      "b_trbc_business": null,
      "theta": "-0.20",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.025",
      "percent_moneyness": "24",
      "last_trading_date": "28 Jun 21",
      "HIGH_1": "0.090",
      "type": "CALL",
      "effective_gearing": "7.4",
      "conv_ratio": "45.0",
      "wrnt_per_share": "0.022",
      "BID_PCTCHNG_2DP": "0.00",
      "LOW_1": "0.019",
      "30D_LOW": "-",
      "ticker": "R42W",
      "breakeven_price": "306.27",
      "underlying_pctchng": "+0.70",
      "delta_per_wrnt": "0.449",
      "warrant_price": 0.024000000,
      "TURNOVER_SCALED": "2",
      "OPEN_PRC": "0.072",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "Alibaba MB eCW210705",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "22.27",
      "delta": "20.2",
      "TRADE_DATE": "05 Mar 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 39.353320000,
      "BID_PCTCHNG": "0.0",
      "BIDSIZE": "3,020,000",
      "underlying_price_cal": 228.8,
      "exercise_price_adj": 51.690000000,
      "update_time": "05 Mar 21 15:18",
      "dsply_name": "Alibaba MB eCW210705",
      "YRHIGH": "-",
      "BEST_BSIZ1": "3020000",
      "HST_CLOSE": "0.024",
      "ACVOL_1_SCALED": "70",
      "BID": "0.024",
      "r_hst_close": 0.1723000000,
      "maturity": "05 Jul 21",
      "div_yield": 0,
      "underlying_bid": 228.6,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "ALML_th.SI",
      "HST_VOL_SCALED": "-",
      "underlying_price": "228.80",
      "list_date": "17 Dec 20",
      "underlying_curr": "HKD",
      "exchange_code": "SES",
      "ASK": "0.026",
      "currency": "SGD",
      "conv_ratio_cal": 45.000000450,
      "UPLIMIT": "0",
      "BID_NETCHNG": "0.000",
      "NETCHNG_1": "+0.0",
      "HST_VOL": "-",
      "implied_volatility": "47.5",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "05:54",
      "moneyness": "2",
      "ASKSIZE": "3,000,000",
      "premium": "33.9",
      "SERVICE": "ELEKTRON_DD",
      "underlying_ticker": "BABA-SW",
      "intrinsic_value": "0.000",
      "exercise_price_cal": 300.000000000,
      "issuer_code": "MBL",
      "REC_STATUS": "0",
      "underlying_name": "BABA-SW",
      "TURNOVER": "1,570",
      "PCTCHNG": "+4.2",
      "YRLOW": "-",
      "BEST_ASIZ1": "3000000",
      "ACVOL_1": "70,000",
      "days_to_maturity": "122",
      "ric": "ALML_th.SI",
      "exercise_price": "300.00"
    },
    {
      "underlying_ric": "AEM.SI",
      "b_trbc_business": null,
      "theta": "-0.28",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.072",
      "percent_moneyness": "8",
      "last_trading_date": "10 Sep 21",
      "HIGH_1": "0.118",
      "type": "CALL",
      "effective_gearing": "2.6",
      "conv_ratio": "12.0",
      "wrnt_per_share": "0.083",
      "BID_PCTCHNG_2DP": "0.00",
      "LOW_1": "0.059",
      "30D_LOW": "-",
      "ticker": "VKEW",
      "breakeven_price": "5.04",
      "underlying_pctchng": "-0.26",
      "delta_per_wrnt": "4.713",
      "warrant_price": 0.070000000,
      "TURNOVER_SCALED": "0",
      "OPEN_PRC": "0.068",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "AEM MB eCW210917",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "2.12",
      "delta": "56.6",
      "TRADE_DATE": "04 Mar 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 3.850000000,
      "BID_PCTCHNG": "0.0",
      "BIDSIZE": "500,000",
      "underlying_price_cal": 3.85,
      "exercise_price_adj": 4.200000000,
      "update_time": "05 Mar 21 15:18",
      "dsply_name": "AEM MB eCW210917",
      "YRHIGH": "-",
      "BEST_BSIZ1": "500000",
      "HST_CLOSE": "0.070",
      "ACVOL_1_SCALED": "0",
      "BID": "0.070",
      "r_hst_close": 1,
      "maturity": "17 Sep 21",
      "div_yield": 2.436548000,
      "underlying_bid": 3.85,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "AEML_tm.SI",
      "HST_VOL_SCALED": "-",
      "underlying_price": "3.85",
      "list_date": "17 Dec 20",
      "underlying_curr": "SGD",
      "exchange_code": "SES",
      "ASK": "0.072",
      "currency": "SGD",
      "conv_ratio_cal": 12.000000048,
      "UPLIMIT": "0",
      "BID_NETCHNG": "0.000",
      "NETCHNG_1": "0.0",
      "HST_VOL": "-",
      "implied_volatility": "88.0",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "2",
      "ASKSIZE": "500,000",
      "premium": "30.9",
      "SERVICE": "ELEKTRON_DD",
      "underlying_ticker": "AWX",
      "intrinsic_value": "0.000",
      "exercise_price_cal": 4.200000000,
      "issuer_code": "MBL",
      "REC_STATUS": "0",
      "underlying_name": "AEM HOLDINGS LTD",
      "TURNOVER": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "500000",
      "ACVOL_1": "0",
      "days_to_maturity": "196",
      "ric": "AEML_tm.SI",
      "exercise_price": "4.20"
    },
    {
      "underlying_ric": "9988.HK",
      "b_trbc_business": null,
      "theta": "-0.05",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.127",
      "percent_moneyness": "5",
      "last_trading_date": "28 Jun 21",
      "HIGH_1": "0.181",
      "type": "PUT",
      "effective_gearing": "3.4",
      "conv_ratio": "45.0",
      "wrnt_per_share": "0.022",
      "BID_PCTCHNG_2DP": "-1.56",
      "LOW_1": "0.071",
      "30D_LOW": "-",
      "ticker": "QBDW",
      "breakeven_price": "207.09",
      "underlying_pctchng": "+0.70",
      "delta_per_wrnt": "-1.108",
      "warrant_price": 0.127000000,
      "TURNOVER_SCALED": "13",
      "OPEN_PRC": "0.101",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "Alibaba MB ePW210705",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "9.03",
      "delta": "-49.9",
      "TRADE_DATE": "05 Mar 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 39.353320000,
      "BID_PCTCHNG": "-1.6",
      "BIDSIZE": "3,000,000",
      "underlying_price_cal": 228.8,
      "exercise_price_adj": 41.352000000,
      "update_time": "05 Mar 21 15:18",
      "dsply_name": "Alibaba MB ePW210705",
      "YRHIGH": "-",
      "BEST_BSIZ1": "3000000",
      "HST_CLOSE": "0.128",
      "ACVOL_1_SCALED": "100",
      "BID": "0.126",
      "r_hst_close": 0.1723000000,
      "maturity": "05 Jul 21",
      "div_yield": 0,
      "underlying_bid": 228.6,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "ALML_tg.SI",
      "HST_VOL_SCALED": "-",
      "underlying_price": "228.80",
      "list_date": "17 Dec 20",
      "underlying_curr": "HKD",
      "exchange_code": "SES",
      "ASK": "0.128",
      "currency": "SGD",
      "conv_ratio_cal": 45.000000450,
      "UPLIMIT": "0",
      "BID_NETCHNG": "-0.002",
      "NETCHNG_1": "0.0",
      "HST_VOL": "-",
      "implied_volatility": "51.7",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "06:50",
      "moneyness": "1",
      "ASKSIZE": "2,900,000",
      "premium": "9.5",
      "SERVICE": "ELEKTRON_DD",
      "underlying_ticker": "BABA-SW",
      "intrinsic_value": "0.043",
      "exercise_price_cal": 240.000000000,
      "issuer_code": "MBL",
      "REC_STATUS": "0",
      "underlying_name": "BABA-SW",
      "TURNOVER": "13,180",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "2900000",
      "ACVOL_1": "100,000",
      "days_to_maturity": "122",
      "ric": "ALML_tg.SI",
      "exercise_price": "240.00"
    },
    {
      "underlying_ric": "1211.HK",
      "b_trbc_business": null,
      "theta": "-0.00",
      "LOLIMIT": "0",
      "TRDPRC_1": "0.555",
      "percent_moneyness": "42",
      "last_trading_date": "26 Mar 21",
      "HIGH_1": "0.940",
      "type": "CALL",
      "effective_gearing": "3.4",
      "conv_ratio": "25.0",
      "wrnt_per_share": "0.040",
      "BID_PCTCHNG_2DP": "-1.27",
      "LOW_1": "0.098",
      "30D_LOW": "-",
      "ticker": "VHUW",
      "breakeven_price": "196.59",
      "underlying_pctchng": "+0.71",
      "delta_per_wrnt": "N/A",
      "warrant_price": 0.395000000,
      "TURNOVER_SCALED": "0",
      "OPEN_PRC": "0.108",
      "NO_BIDMKR2": "-",
      "dsply_nmll": "BYD MB eCW210405",
      "issuer_name": "Macquarie Bank Ltd",
      "sensitivity": "12.50",
      "delta": "100.0",
      "TRADE_DATE": "04 Jan 21",
      "NO_BIDMKR2_SCALED": "-",
      "underlying_price_adj": 33.960330000,
      "BID_PCTCHNG": "-1.3",
      "BIDSIZE": "100,000",
      "underlying_price_cal": 198.4,
      "exercise_price_adj": 24.122000000,
      "update_time": "05 Mar 21 15:18",
      "dsply_name": "BYD MB eCW210405",
      "YRHIGH": "-",
      "BEST_BSIZ1": "100000",
      "HST_CLOSE": "0.395",
      "ACVOL_1_SCALED": "0",
      "BID": "0.390",
      "r_hst_close": 0.1723000000,
      "maturity": "05 Apr 21",
      "div_yield": 0.076638000,
      "underlying_bid": 198.4,
      "fmt": 1,
      "30D_HIGH": "-",
      "SYMBOL": "BYML_tb.SI",
      "HST_VOL_SCALED": "-",
      "underlying_price": "198.40",
      "list_date": "05 Oct 20",
      "underlying_curr": "HKD",
      "exchange_code": "SES",
      "ASK": "0.415",
      "currency": "SGD",
      "conv_ratio_cal": 25.000000000,
      "UPLIMIT": "0",
      "BID_NETCHNG": "-0.005",
      "NETCHNG_1": "0.0",
      "HST_VOL": "-",
      "implied_volatility": "10.0",
      "VOL_PCTCHNG": "-",
      "TRDTIM_1": "  :",
      "moneyness": "1",
      "ASKSIZE": "400,000",
      "premium": "-0.9",
      "SERVICE": "ELEKTRON_DD",
      "underlying_ticker": "BYD COMPANY",
      "intrinsic_value": "0.402",
      "exercise_price_cal": 140.000000000,
      "issuer_code": "MBL",
      "REC_STATUS": "0",
      "underlying_name": "BYD COMPANY",
      "TURNOVER": "0",
      "PCTCHNG": "0.0",
      "YRLOW": "-",
      "BEST_ASIZ1": "400000",
      "ACVOL_1": "0",
      "days_to_maturity": "31",
      "ric": "BYML_tb.SI",
      "exercise_price": "140.00"
    },
    {
      "theta": "-",
      "TRDPRC_1": "3.850",
      "LOLIMIT": "0",
      "percent_moneyness": "-",
      "last_trading_date": "-",
      "HIGH_1": "-",
      "conv_ratio": "-",
      "wrnt_per_share": "-",
      "effective_gearing": "N/A",
      "BID_PCTCHNG_2DP": "-",
      "LOW_1": "-",
      "ticker": "AWX",
      "30D_LOW": "3.230",
      "breakeven_price": "-",
      "underlying_pctchng": "-",
      "delta_per_wrnt": "N/A",
      "OPEN_PRC": "-",
      "TURNOVER_SCALED": "20,502",
      "NO_BIDMKR2": "5,988,820",
      "dsply_nmll": "AEM",
      "sensitivity": "N/A",
      "delta": "-",
      "TRADE_DATE": "05 Mar 21",
      "BID_PCTCHNG": "-",
      "NO_BIDMKR2_SCALED": "5,989",
      "BIDSIZE": "71,400",
      "underlying_price_cal": null,
      "update_time": "05 Mar 21 15:18",
      "dsply_name": "AEM HOLDINGS LTD",
      "YRHIGH": "4.720",
      "HST_CLOSE": "3.860",
      "BEST_BSIZ1": "71400",
      "ACVOL_1_SCALED": "5,366",
      "BID": "3.850",
      "maturity": "-",
      "fmt": 1,
      "30D_HIGH": "4.710",
      "SYMBOL": "AEM.SI",
      "underlying_price": "-",
      "HST_VOL_SCALED": "5,222",
      "list_date": "-",
      "ASK": "3.860",
      "conv_ratio_cal": null,
      "UPLIMIT": "0",
      "NETCHNG_1": "-0.0",
      "BID_NETCHNG": "-",
      "HST_VOL": "5,222,200",
      "implied_volatility": "N/A",
      "VOL_PCTCHNG": "-12.80",
      "TRDTIM_1": "07:20",
      "moneyness": "-",
      "ASKSIZE": "59,600",
      "premium": "-",
      "SERVICE": "ELEKTRON_DD",
      "intrinsic_value": "-",
      "exercise_price_cal": null,
      "REC_STATUS": "0",
      "TURNOVER": "20,501,740",
      "PCTCHNG": "-0.3",
      "YRLOW": "1.310",
      "BEST_ASIZ1": "59600",
      "ACVOL_1": "5,366,100",
      "VOL_RATIO": 0.871991477,
      "days_to_maturity": "-",
      "ric": "AEM.SI",
      "exercise_price": "-"
    }
  ]
}

export default lastview;