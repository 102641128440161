import React, { Component } from "react";
import PageHeader from "../../../components/pageHeader/pageHeader";
import "./disclaimerr.css";
import BreadcrumbBar from "../../../components/breadcrumbBar";

export default class Disclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onKeyDownchange(e, val) {
        if (e.keyCode === 13 || val) {
            //事件操作
            this.props.history.push({
                pathname: "/search",
                state: {
                    key: e.target.value,
                },
            });
            e.target.value = "";
        }
    }
    render() {
        return (
            <div id="importantinformation" className="pageobj">
                <BreadcrumbBar link="/home" group="Macquarie" title="Page Not Found" />
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section sect">
                                <div className="instruction11">Oops! Looks like the page you are looking can't be found.</div>
                                <div className="instruction2 inst"> The page you have requested has changed or is temporarily unavailable. Please try searching below or
                                    <a href="/home">click here</a>
                                    to return to homepage.
                                </div>
                                <div className="seach_area input-group">
                                    <input type="text" className="form-control" placeholder="Search all of warrants.com.sg" onKeyDown={(e) => this.onKeyDownchange(e)} />
                                    <div className="input-group-btn">
                                        <button className="btn btn-default" type="submit" onClick={(e) => this.onKeyDownchange(e, true)}>
                                            <i className="glyphicon glyphicon-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
