
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class dividends extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>Warrant holders do not receive dividends. They will not be disadvantaged in this respect however, as forecast dividends are priced into the warrant. As such, call warrants will not fall in price if the underlying shares fall
                                                by the dividend amount on the ex-div date.
                                                <br /><br /> Similarly, put warrants will not increase in price in such a scenario. If however, there is a change in the forecast dividend announced on the stock, where the actual dividend comes in higher than the amount forecasted
                                                in the warrant, this can lead to a drop in the price of a call warrant and an increase in the put. Corporate actions (such as special dividends, share splits and rights issues) are treated separately and adjustments are
                                                made to the warrant terms to account for these.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/Dividends_enlarge.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}