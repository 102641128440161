import React, { Component } from "react";

export default class SwiperPagination extends Component {
  onDotClicked(pageIndex) {
    this.props.onDotClicked(pageIndex);
  }

  pagination() {
    const rows = this.props.rows;
    let pageSize = this.props.pageSize;
    const paginationDots = [];
    if (rows !== null && Array.isArray(rows)) {
      if (typeof pageSize === "undefined" || pageSize === null) {
        pageSize = 5; // default
      }
      const totalPage = Math.ceil(rows.length / pageSize);
      for (let index = 0; index < totalPage; index++) {
        let isActive = "";
        if (index === this.props.current) {
          // isActive = "swiper-pagination-bullet-active";
          isActive = "active";
        }
        const dot = (
          <li
            key={"dot_" + index}
            className={"swiper-pagination-bullet " + isActive}
            onClick={() => this.onDotClicked(index)}
          ></li>
        );
        paginationDots.push(dot);
      }
    }
    return (
      <div id="pgmarkers" style={{ textAlign: "center" }}>
        <ul className="swiper-pagination" style={{ display: "inline-block" }}>
          {paginationDots}
        </ul>
      </div>
    );
  }

  render() {
    return this.pagination();
  }
}
