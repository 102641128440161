
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";

export default class cashsettlementamountforindexcallsandputsatexpiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>
                                                The main difference in the way the settlement price for index calls and puts are calculated is that, unlike single stock calls and puts, it’s not the average five day closing price that’s used to work out the settlement figure.<br /><br />
                                            </p>
                                            <p>Instead, you use the final settlement value of the index futures on expiry date.</p>
                                            <p>If the index you’re using isn’t based in Singapore, the settlement amount will need to be converted to Singapore dollars, using a foreign exchange rate taken on the day the warrant expires.</p>
                                            <p>Let’s take a look at the formula that’s used to work out the settlement price for index calls and puts, using the Hang Seng Index.<br /><br /></p>
                                            <h2>FORMULA TO CALCULATE VALUE OF INDEX CALLS AND PUTS AT EXPIRY</h2>
                                            <div className="imgholder">
                                                <img src="/img/education/formula_calculate_index_calls_and_puts.png" alt="Formula to calculate value of index Calls and Puts at expiry" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/formula_calculate_index_calls_and_puts.png')}>View formula</div>
                                            </div>
                                            <p>
                                                These are similar to the formulas used to calculate the expiry values of single stock calls and puts. The difference you would notice is that the final settlement level of HSI Futures is used instead. The formula also includes an additional component
                                                in the FX rate, which is to take into consideration the HSI Futures is denominated in a different currency and not the Singapore dollars.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}