import { Link } from 'react-router-dom'
import React from 'react';


class BreadcrumbBar extends React.Component {

    getChild() {
        let child = null;
        if (typeof this.props.childlink !== "undefined" && this.props.childlink !== null) {
            if (typeof this.props.child !== "undefined" && this.props.child !== null) {
                child = <li><Link to={this.props.childlink}>{this.props.child}</Link></li>
            }
        }
        return child;
    }

    render() {
        const child = this.getChild();
        const title = { __html: this.props.title }
        return (<div className="breadcrumbbar">
            <div className="container-fluid">
                <ol className="breadcrumb">
                    <li><Link to={this.props.link}>{this.props.group}</Link></li>
                    &nbsp;
                    {child}
                    &nbsp;
                    <li className="active" id="breadcrumb-label" dangerouslySetInnerHTML={title} ></li>
                </ol>
            </div>
        </div>);
    }
}

export default BreadcrumbBar