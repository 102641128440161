
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class howacallwarrantworks extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <h2>Hypothetical example of a call warrant</h2>
                                            <p>Here’s a look at an example of an investment in a call warrant over DBS. The dotted line here represents the DBS mother share while the yellow line is the call warrant over DBS. As we can see from the graph, the two lines move
                                                in tandem with each other – the call warrant moves up when the DBS mother share goes up, and likewise follows it downward.
                                                <br /><br /> Suppose you have a bullish view on DBS with the share price at $13. In this case, instead of paying $13 per share for an exposure over DBS, you could buy a call warrant for under 13 cents, less than one percent
                                                of the share price.</p>
                                            <div className="imgholder hidden-xs">
                                                <img src="/img/education/script_0203-DBS-call-warrants-graph_enlarge.jpg" />
                                                <div className="visible-xs"><a href="javascript:showImgpopup('edu/img/script_0203-DBS-call-warrants-graph_enlarge.jpg','600','509')">View
                                                    chart</a></div>
                                            </div>
                                            <p>
                                                Assuming your view was right and DBS did break up further above $13, this would translate to a return of 6.0% in a month if you had purchased the shares. However, if you bought the call warrant instead of the shares, you would have made a 33% return,
                                                more than 5 times that of the share price move.<br /><br /> This is an example of gearing at work, and the advantage warrants offer – a greater potential profit as a percentage of your invested
                                                capital.
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/DBS-Shares-Vs-Warrant-Price_enlarge.jpg" alt />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}