import React, { Component } from 'react'
import { Link } from "react-router-dom";
import MQSGUtil from '../../../components/mqsgUtil';
import './commentary.css'
export default class Commentary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subtitle: "",
            index: "0",
            id: "PLqu_w2HLo_GtJFww_Fxfdlvf5xpj4_v75&index=0",
            marketCommentaryData: [],
            marketViewVideosData: [],
            educationalVideosData: [],
            QuickVideosData: [],
            videoData: [],
            dataList: {},
            videoList: []
        }
        this.initRedirect(props, this.state);
    }

    componentDidMount() {
        const url = MQSGUtil.getAPIBasePath() + "/VideoWarrantsJSON";
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    var marketCommentaryData = []
                    var marketViewVideosData = []
                    var educationalVideosData = []
                    var QuickVideosData = []
                    for (var i = 0; i < result.length; i++) {
                        if (result[i]['title'] == 'Market Commentary') {
                            marketCommentaryData = result[i].videos
                        } else if (result[i]['title'] == 'Market View') {
                            marketViewVideosData = result[i].videos
                        } else if (result[i]['title'] == 'Educational Videos') {
                            educationalVideosData = result[i].videos
                        } else if (result[i]['title'] == 'Quick market talk with experts') {
                            QuickVideosData = result[i].videos
                        }
                    }

                    this.setState(
                        {
                            marketCommentaryData,
                            marketViewVideosData,
                            educationalVideosData,
                            QuickVideosData,
                            videoData: result
                        }, () => {
                            this.setMeta()
                        })
                },
                (error) => {
                }
            );
    }
    changeVideo(value) {
        if (value == 'next') {
            var index = Number(this.state.index) + 1;
        } else {
            var index = Number(this.state.index) - 1;
        }
        var dataList = this.state.videoList[index];
        this.setState({ dataList, index })
    }
    setMeta() {
        const subtitle = this.state.subtitle;
        var dataList = {};
        var videoList = []
        if (typeof subtitle !== "undefined" && subtitle !== null && this.state.videoData.length > 0) {
            for (var i = 0; i < this.state.videoData.length; i++) {
                if (this.state.videoData[i].title == subtitle) {
                    videoList = this.state.videoData[i].videos
                    dataList = this.state.videoData[i].videos[this.state.index]
                }
            }
        }
        $('head').append(`<meta property="og:type" content='website'/>`);
        $('head').append(`<meta property="og:title" content=${dataList.fbtitle}/>`);
        $('head').append(`<meta property="og:description" content=${dataList.fb_text}/>`);
        $('head').append(`<meta property="og:image" content=${dataList.image}/>`);
        this.setState({ videoList, dataList })
    }
    initRedirect(props, state) {
        const match = MQSGUtil.getData(props, "match", null);
        if (match !== null) {
            const params = MQSGUtil.getData(match, "params", null);
            if (params !== null) {
                const id = MQSGUtil.getData(params, "id", null);
                if (id !== null) {
                    state["id"] = id;
                }
                const subtitle = MQSGUtil.getData(params, "subtitle", null);
                if (subtitle !== null) {
                    state["subtitle"] = subtitle;
                }
                const index = MQSGUtil.getData(params, "index", null);
                if (index !== null) {
                    state["index"] = index;
                }
            }
        }
    }
    getVideoValue(a, b) {
        this.setState({
            id: a,
            index: b
        })
    }
    render() {
        const subtitle = this.state.dataList.list_title;
        const srcLink = "https://www.youtube.com/embed/" + this.state.dataList.playlist_id + "?autoplay=" + 0 + "&index=" + this.state.dataList.index + "&rel=0&wmode=opaque";
        var text = this.state.dataList.title;
        var p = this.state.dataList.text;
        var pHtml = { __html: p };
        var textHtml = { __html: text };
        return (
            <div id="dwvideoind" className="pageobj">
                <div className="breadcrumbbar">
                    <div className="container-fluid">
                        <ol className="breadcrumb">
                            <li><a href="/home/edu">Warrant education</a></li>
                            <li><a href="/education/warrantvideos">Warrant videos</a></li>
                            <li className="active">Education</li>
                        </ol>
                    </div>
                </div>
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="section">
                        <div className="page-header">
                            <h1>{subtitle}</h1>
                        </div>
                        <div className="videoplayer">
                            <div className="videoplayer-container" style={{ position: 'relative' }}>
                                <iframe frameBorder="0" allowFullScreen="" title={subtitle} src={srcLink}></iframe>
                                <div className='slick-prev slick-arrow'>
                                    <img src="/img/education/arrow.png" style={{ display: this.state.index == 0 ? 'none' : 'block' }} alt="" className='preImg' onClick={() => { this.changeVideo('pre') }} />
                                </div>
                                <div className='slick-next slick-arrow'>
                                    <img src="/img/education/arrow.png" style={{ display: this.state.index == this.state.videoList.length - 1 ? 'none' : 'block' }} alt="" className='nextImg' onClick={() => { this.changeVideo('next') }} />
                                </div>


                            </div>
                        </div>
                        <div className="videoinfo">
                            <div className="videoinfo-title" dangerouslySetInnerHTML={textHtml}></div>
                            <p className="videoinfo-p" dangerouslySetInnerHTML={pHtml}></p>

                            <Link
                                to={{
                                    pathname:
                                        "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fmqmy_aws%252Feducation%252Fwarrantvideosind%253Flang%253Den%2526playlistId%253DPLOkzCdnS95xTdMhV0ItxdWqpa2ji_BVB3%2526index%253D5&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB",
                                }}
                                target="_blank"
                            >
                                <div className="fbSharebtn"><i className="fa fa-facebook" aria-hidden="true"></i>Share on Facebook</div>
                            </Link>
                        </div>
                        {/* <Content subtitle={subtitle} getVideoValue={this.getVideoValue.bind(this)} /> */}
                    </div>
                </div>
            </div>
        )
    }
}

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: "",
            id: ""
        }
    }
    getColumnsMarketView() {
        let columns = [];
        if (typeof this.state.marketViewVideosData !== "undefined" && this.state.marketViewVideosData !== null) {
            columns = this.state.marketViewVideosData.map(function (item) {
                const index = MQSGUtil.getData(item, "index");
                const key = "marketview_" + index;
                const playlistid = MQSGUtil.getData(item, "playlistId");
                const image = MQSGUtil.getData(item, "image");
                const title = { __html: MQSGUtil.getData(item, "title") };
                return <td key={key} onClick={() => { this.setVideo(playlistid, index) }}>
                    <div playlistid={playlistid} index={index}
                        className="videorack-item" style={{ display: 'inline-block' }}>
                        <img style={{ width: '100%' }} src={image} alt="" />
                        <div className="videorack-item-txt">
                            <div dangerouslySetInnerHTML={title}></div>
                        </div>
                    </div>
                </td>
            }, this)
        }
        return columns;
    }
    setVideo(a, b) {
        this.props.getVideoValue(a, b)
    }
    getColumnsEducational() {
        let columns = [];
        if (typeof this.state.educationalVideosData !== "undefined" && this.state.educationalVideosData !== null) {
            columns = this.state.educationalVideosData.map(function (item) {
                const index = MQSGUtil.getData(item, "index");
                const key = "marketview_" + index;
                const playlistid = MQSGUtil.getData(item, "playlistId");
                const image = MQSGUtil.getData(item, "image");
                const title = { __html: MQSGUtil.getData(item, "title") };
                return <td key={key} onClick={() => { this.setVideo(playlistid, index) }}>
                    <div playlistid={playlistid} index={index}
                        className="videorack-item" style={{ display: 'inline-block' }}>
                        <img style={{ width: '100%' }} src={image} alt="" />
                        <div className="videorack-item-txt">
                            <div dangerouslySetInnerHTML={title}></div>
                        </div>
                    </div>
                </td>
            }, this)
        }
        return columns;
    }
    getmarketCommentaryData() {
        let columns = [];
        if (typeof this.state.marketCommentaryData !== "undefined" && this.state.marketCommentaryData !== null) {
            columns = this.state.marketCommentaryData.map(function (item) {
                const index = MQSGUtil.getData(item, "index");
                const key = "marketview_" + index;
                const playlistid = MQSGUtil.getData(item, "playlistId");
                const image = MQSGUtil.getData(item, "image");
                const title = { __html: MQSGUtil.getData(item, "title") };
                return <td key={key} onClick={() => { this.setVideo(playlistid, index) }}>
                    <div playlistid={playlistid} index={index}
                        className="videorack-item" style={{ display: 'inline-block' }}>
                        <img style={{ width: '100%' }} src={image} alt="" />
                        <div className="videorack-item-txt">
                            <div dangerouslySetInnerHTML={title}></div>
                        </div>
                    </div>
                </td>
            }, this)
        }
        return columns;
    }
    getColumnsSpeaker() {
        let columns = [];
        if (typeof this.state.speakerVideosData !== "undefined" && this.state.speakerVideosData !== null) {
            columns = this.state.speakerVideosData.map(function (item) {
                const index = MQSGUtil.getData(item, "index");
                const key = "speaker_" + index;
                const playlistid = MQSGUtil.getData(item, "playlistId");
                const image = MQSGUtil.getData(item, "image");
                const title = { __html: MQSGUtil.getData(item, "title") };
                return <td key={key} onClick={() => { this.setVideo(playlistid, index) }}>
                    <div playlistid={playlistid} index={index}
                        className="videorack-item" style={{ display: 'inline-block' }}>
                        <img style={{ width: '100%' }} src={image} alt="" />
                        <div className="videorack-item-txt">
                            <div dangerouslySetInnerHTML={title}></div>
                        </div>
                    </div>
                </td>
            }, this)
        }
        return columns;
    }

    render() {
        const columnsMarketView = this.getColumnsMarketView();
        const columnsEducational = this.getColumnsEducational();
        const columnsSpeaker = this.getColumnsSpeaker();
        const columsComData = this.getmarketCommentaryData()
        let columnsUse = null;
        const subtitle = this.props.subtitle;
        if (typeof subtitle !== "undefined" && subtitle !== null) {
            if (subtitle.indexOf("Educational") > -1) {
                columnsUse = columnsEducational;
            } else if (subtitle.indexOf("Commentary") > -1) {
                columnsUse = columsComData;
            } else {
                columnsUse = columnsMarketView;
            }
        }
        return (
            <div className="content hidden-xs">
                <div id="edurack" className="videorack">
                    <div className="videorack-title">More educational videos</div>
                    <div className="videorack-body">
                        <div className="videorack-content">
                            <table className="videorack-page hidden-xs">
                                <tbody>
                                    <tr>{columnsUse}</tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div id="gsrack" className="videorack">
                            <div className="videorack-title">More guest speaker videos</div>
                            <div className="videorack-body">
                                <div className="videorack-content">
                                    <table className="videorack-page hidden-xs">
                                        <tbody>
                                            <tr>{columnsSpeaker}</tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
