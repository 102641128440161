import React, { Component } from "react";
import "./contactUs.css";
import BreadcrumbBar from "../../../components/breadcrumbBar";

export default class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="termsofuse" className="pageobj">
        <BreadcrumbBar link="/home" group="Macquarie" title="Contact us" />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header">
                  <h1>Contact us</h1>
                </div>
                <div className="row">
                  <div className="col-sm-4 outer_area">
                    <img src="/img/about/icon-address.png" alt="" />
                    <div className="inner_title">Address</div>
                    <div className="adress_content">
                      9 Straits View #21-07
                      <br />
                      Marina One West Tower
                      <br />
                      Singapore 018937
                    </div>
                  </div>
                  <div className="col-sm-4 outer_area">
                    <img src="/img/about/icon-hotline.png" alt="" />
                    <div className="inner_title">Hotline</div>
                    <div className="visible-xs">
                      <a href="tel:+65 6601 0289">+65 6601 0289</a>
                    </div>
                    <div className="hidden-xs">+65 6601 0289</div>
                  </div>
                  <div className="col-sm-4 outer_area">
                    <img src="/img/about/icon-email.png" alt="" />
                    <div className="inner_title">Email</div>
                    <div>
                      <a href="mailto:info@warrants.com.sg">
                        info@warrants.com.sg
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
