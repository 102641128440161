import React, { Component } from "react";
import Select from "react-select";

import PageHeader from "../../../components/pageHeader/pageHeader";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";

import { Link } from "react-router-dom";
import "./sp500.css"
export default class Sp500 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: []
        };
    }
    componentDidMount() {
        document.title = "S&P 500 warrants"
        const url = MQSGUtil.getAPIBasePath() + "/MarketDataJSON?type=index_sp500&lmt=all&qid=1641564597295";
        // const url = 't1www5.trkd-hs.com/mqsg_aws/MarketDataJSON?type=index_sp500&lmt=all&qid=1641564597295';

        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({ tableData: result.ranking });
                },
                (error) => {
                }
            );
    }
    render() {
        return (
            <div id="sp500" className="pageobj">
                <BreadcrumbBar
                    link="/home"
                    group="Warrant education"
                    title="S&P 500"
                />
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="section">
                        <div className="livematrix-row row-fullw row">
                            <div className="livematrix-col">
                                <h2 className="col-h color-01">Invest in S&P 500
                                    <sup>®</sup> warrants on the SGX</h2>
                                <p className="col-p">
                                    Brought to you by Singapore's #1 Warrant Issuer
                                </p>
                            </div>
                        </div>

                        <div className="fpa-row row row-fullw">
                            <div className="fpa-col col-sm-3">
                                <img src="/img/education/globle.png" alt="" />
                                <h3 className="text-bold">World's leading index</h3>
                                <p>
                                    Get exposure to the iconic
                                    S&P 500
                                    <sup>®</sup>
                                    index, tracking the
                                    largest 500 companies listed on
                                    the New York Stock Exchange
                                    and NASDAQ.
                                </p>
                            </div>

                            <div className="fpa-col col-sm-3">
                                <img src="/img/education/Shorted.png" alt="" />
                                <h3 className="text-bold">Allows you to go 'long' or 'short'</h3>
                                <p>

                                    Get exposure to the iconic
                                    S&P 500
                                    <sup>®</sup>
                                    index, tracking the
                                    largest 500 companies listed on
                                    the New York Stock Exchange
                                    and NASDAQ.

                                </p>
                            </div>

                            <div className="fpa-col col-sm-3">
                                <img src="/img/education/High-liquidity.png" alt="" />
                                <h3 className="text-bold">Liquidity provider</h3>
                                <p>

                                    Macquarie leads the market
                                    by providing state-of-the-art
                                    market marking in our warrants.
                                    Allowing investors to enter and
                                    exit their trades with ease.

                                </p>
                            </div>

                            <div className="fpa-col col-sm-3">
                                <img src="/img/education/Clock.png" alt="" />
                                <h3 className="text-bold">Trade USA in the Singapore time zone</h3>
                                <p>

                                    Gain access to the U.S. market
                                    movements without leaving the
                                    SGX. Traded during the local
                                    Singapore trading hours of
                                    9AM-5PM
                                </p>
                            </div>


                            <div className="fpa-col col-xs-12">
                                <div className="table-header-txt">S&P 500 warrants</div>
                                <div className="table-holder">
                                    <table id="fpaTable" className="table-responsive">
                                        <thead>
                                            <tr>
                                                <th>Warrantbr <br />Name</th>
                                                <th>Underlying</th>
                                                <th>Type</th>
                                                <th>Exercise <br />price</th>
                                                <th>WPS</th>
                                                <th>Last trading <br />date</th>
                                                <th>Bid <br />Volume <br />('000)</th>
                                                <th>Bid Price <br />('000)</th>
                                                <th>Ask <br />Volume <br />('000)</th>
                                                <th>Ask <br />Price <br /> ('000)</th>
                                                <th>Price <br />Change <br />(%)</th>
                                                <th>Traded <br />Volume <br />('000)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.tableData.map((item) => {
                                                    return (
                                                        <tr className="rowtmpl" key={item.ticker}>
                                                            <td>
                                                                <a href={'/tools/warrantterms/' + item.ticker}>{item.ticker}</a>
                                                            </td>
                                                            <td>
                                                                <a href={'/tools/underlying/' + item.underlying_ticker}>{item.underlying_ticker}</a>
                                                            </td>
                                                            <td>
                                                                {item.type == 'CALL' ? 'C' : 'P'}
                                                            </td>
                                                            <td>
                                                                {item.exercise_price}
                                                            </td>
                                                            <td>
                                                                {item.conv_ratio}
                                                            </td>
                                                            <td>
                                                                {item.last_trading_date}
                                                            </td>
                                                            <td>
                                                                {item['BIDSIZE']}
                                                            </td>
                                                            <td>
                                                                {item['BID']}
                                                            </td>
                                                            <td>
                                                                {item['ASKSIZE']}
                                                            </td>
                                                            <td>
                                                                {item['ASK']}
                                                            </td>
                                                            <td>
                                                                {item['BID_PCTCHNG_2DP']}
                                                            </td>
                                                            <td>
                                                                {item['ACVOL_1_SCALED']}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="fpa-col col-xs-12 htt-row" style={{ textAlign: 'center' }}>
                                <div className="text-bold color-01">2020 elections commentaries</div>
                                <div className="iconmsg text-center" style={{ marginTop: '10px' }}>
                                    <a href="https://bit.ly/3mr3CcP" className="color-01">Takeaways from the final presidential debate</a>
                                </div>
                                <div className="iconmsg text-center" style={{ marginTop: '10px' }}>
                                    <a href="https://bit.ly/34DGbai" className="color-01">US stimulus – what can investors expect on different election outcomes?</a>
                                </div>
                                <div className="iconmsg text-center" style={{ marginTop: '10px' }}>
                                    <a href="https://bit.ly/3kT9Wti" className="color-01">All eyes on the US elections… and data releases</a>
                                </div>
                                <div className="iconmsg text-center" style={{ marginTop: '10px' }}>
                                    <a href="https://www.warrants.com.sg/marketnews/highlight?cat=todayhighlight&id=6505&utm_source=SPX&utm_medium=SPX&utm_campaign=SPX" className="color-01">Remisier and client bought into S&P Call Warrants ahead of US elections and made 20% + $1,000!</a>
                                </div>
                            </div>


                        </div>

                        <div className="htt-row row row-fullw">
                            <div className="col-xs-12">
                                <h2 className="text-center color-01">How the warrants track the S&P 500
                                    <sup>®</sup> index?
                                </h2>
                                <p className="text-center">

                                    The warrants will track the performance of the S&P 500
                                    <sup>®</sup>
                                    index, comprising of 500 of the largest companies listed
                                    on the New York Stock exchange and the NASDAQ. During the Singapore time zone, investors can refer to
                                    the relevant futures contracts overs the S&P 500
                                    <sup>®</sup>
                                    , which are traded 23 hours a day, covering
                                    the full trading hours of the Singapore market from 9AM to 5PM.

                                </p>
                            </div>
                        </div>

                        <div className="stt-row row row-fullw ipadbg">
                            <div className="stt-col">
                                <div className="stt-item">
                                    <img className="pimg" src="/img/education/dollar.png" alt="" />
                                    <div className="ph">Up to $300 billion</div>
                                    <div className="ptxt">
                                        average daily traded
                                        <br />
                                        value of S&P 500
                                        <sup>®</sup>
                                        futures
                                    </div>
                                </div>

                                <div className="stt-item">
                                    <img className="pimg" src="/img/education/trillion.png" alt="" />
                                    <div className="ph">Over $11.2 trillion</div>
                                    <div className="ptxt">
                                        in assets benchmarked
                                    </div>
                                </div>

                                <div className="stt-item">
                                    <img className="pimg" src="/img/education/chart.png" alt="" />
                                    <div className="ph">Captures ≈ 80%</div>
                                    <div className="ptxt">
                                        of U.S equity market
                                        <br />
                                        capitalization
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="sp500disclaimer" style={{ padding: '25px 25px 0px 25px' }}>
                            <p className="small" style={{ fontSize: '10px', padding: 0, margin: 0 }}>

                                The S&P 500 index is a product of S&P Dow Jones indices LLC("SPDJI"), and has been licensed for use by Macquarie Bank Limited("MBL"). Standard & Poor's
                                <sup>®</sup>
                                , S&P
                                <sup>®</sup>
                                and S&P 500
                                <sup>®</sup>
                                are registered trademarks
                                of Standard & Poor's Financial Services LLC("S&P"); Dow Jones
                                <sup>®</sup>
                                is a registered trademark of Dow Jones Trademark Holdings LLC("Dow Jones"); and these trademarks have been licensed for use by SPDJI and
                                sublicensed for certain purposes by MBL. MBL's warrant are not sponsored, endorsed, sold or promoted by SPDJI, Dow Jones, S&P, their respective affiliates, and none of such parties make any representation
                                regarding the advisability of investing in such product(s) nor do they have any liability for any errors, omissions, or interruptions of the S&P 500 index.

                            </p>
                        </div>

                        <hr className="hr-hmgbm" />
                    </div>
                </div>
            </div>
        )
    }
}