import React, { Component } from "react";
import { Link } from "react-router-dom";
import MQSGUtil from "../../../components/mqsgUtil";
import "./videos.css";

export default class Videos extends Component {
  constructor() {
    super();
    this.state = {
      // marketCommentaryData: [],
      // marketViewVideosData: [],
      // educationalVideosData: [],
      // QuickVideosData: [],
      list: []
    };
  }
  componentDidMount() {
    const url = MQSGUtil.getAPIBasePath() + "/VideoWarrantsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState(
            {
              list: result
              // marketCommentaryData: result['Market Commentary'],
              // marketViewVideosData: result['Market View'],
              // educationalVideosData: result['Educational Videos'],
              // QuickVideosData: result['Quick market talk with experts']
            })
        },
        (error) => {
        }
      );
  }
  render() {
    return (
      <div>
        <VideoGallery />
        {this.state.list.map((i) => {
          return (
            <VideoContent
              columns={i.videos}
              title={i.title}
            />
          );
        })}

        {/* <VideoContent
          columns={this.state.educationalVideosData}
          title="Educational Videos"
        />
        <VideoContent
          columns={this.state.QuickVideosData}
          title="Quick market talk with experts"
        />
        <VideoContent columns={this.state.marketViewVideosData} title="Market View" />
        <VideoContent
          columns={this.state.marketCommentaryData}
          title="Market Commentary"
        /> */}
        {/* <VideoContent
          columns={QuickVideosData}
          title="Quick market talk with experts"
        /> */}
      </div>
    );
  }
}

class VideoGallery extends Component {
  constructor() {
    super();
    this.state = {
      // imgs: [
      //   "https://i.ytimg.com/vi/rX0C-l5IRuU/maxresdefault.jpg",
      //   "https://i.ytimg.com/vi/BWGK3KPXclM/maxresdefault.jpg",
      //   "https://i.ytimg.com/vi/8Qe2OzTSNpc/maxresdefault.jpg",

      // ], // 图片数组
      // hrefs: [
      //   "/education/marketcommentary/Quick market talk with experts/rX0C-l5IRuU/0",
      //   "/education/marketcommentary/Quick market talk with experts/BWGK3KPXclM/1",
      //   "/education/marketcommentary/Educational Videos/8Qe2OzTSNpc/1",
      // ],
      imgs: [],
      hrefs: [],
      showIndex: 0, //显示第几个图片
      timer: null, // 定时器
      show: false, // 前后按钮显示
    };
  }


  render() {
    return (
      <div className="swiper-container" style={{ height: 'auto' }}>
        <div
          className="swiper-wrapper"
          onMouseEnter={() => {
            this.stop();
          }} //鼠标进入停止自动播放
          onMouseLeave={() => {
            this.start();
          }} //鼠标退出自动播放
        >
          <ul className="ul">
            {this.state.imgs.map((value, index) => {
              return (
                <li
                  className={index === this.state.showIndex ? "show" : ""}
                  key={index}
                >
                  <a href={this.state.hrefs[index]}>
                    <img src={value + ""} alt="" />
                  </a>
                </li>
              );
            })}
          </ul>
          <ul className="swiper-pagination" style={{ bottom: '15px' }}>
            {this.state.imgs.map((value, index) => {
              return (
                <li
                  key={index}
                  className={index === this.state.showIndex ? "active" : ""}
                  onClick={() => {
                    this.change(index);
                  }}
                ></li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  componentDidMount() {
    const url = MQSGUtil.getAPIBasePath() + "/GetVideoBanner";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          var hrefs = [];
          var imgs = [];
          for (var i = 0; i < result.length; i++) {
            hrefs.push(result[i].link);
            imgs.push(MQSGUtil.getAPIBasePath() + "/photo?ad_id=" + result[i].id);
          }
          this.setState({ hrefs, imgs });
        },
        (error) => {
        }
      );
    //一开始自动播放
    this.start();
  }
  componentWillUnmount() {
    //销毁前清除定时器
    this.stop();
  }
  stop = () => {
    //暂停
    let { timer } = this.state;
    clearInterval(timer);
  };
  start = () => {
    //开始
    let { timer } = this.state;
    timer = setInterval(() => {
      this.next();
    }, 3000);
    this.setState({
      timer,
    });
  };
  change = (index) => {
    //点击下面的按钮切换当前显示的图片
    let { showIndex } = this.state;
    showIndex = index;
    this.setState({
      showIndex,
    });
  };
  previous = (e) => {
    //上一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex <= 0) {
      showIndex = imgs.length - 1;
    } else {
      showIndex--;
    }
    this.setState({
      showIndex,
    });
  };
  next = (e) => {
    //下一张
    let ev = e || window.event;
    let { showIndex, imgs } = this.state;
    if (showIndex >= imgs.length - 1) {
      showIndex = 0;
    } else {
      showIndex++;
    }
    this.setState({
      showIndex,
    });
  };
}

class VideoContent extends Component {
  constructor(props) {
    super(props);
    this.tableColumns = [];
    this.pageSize = 3;
    this.state = {
      current: 0, //当前页码
      totalPage: 0
    };
  }

  onPageIndexChange(pageIndex) {
    this.setState({ current: pageIndex });
  }
  componentWillReceiveProps(props) {
    if (props.title != '') {
      this.setState({ totalPage: this.setupRows(props.columns) })
    }
  }
  componentDidMount() {
    this.setState({ totalPage: this.setupRows(this.props.columns) })
  }
  setupRows(columns) {
    const title = this.props.title;
    if (typeof columns !== "undefined" && columns !== null) {
      if (Array.isArray(columns)) {
        this.tableColumns = columns.map((content, index) => {
          return (
            <Table
              key={title + "_" + index}
              content={content}
              subtitle={title}
            />
          );
        });
        return Math.ceil(columns.length / this.pageSize);
      }
    }
    return 0;
  }

  getRow() {
    // page index 0: current -> pageSize * (current + 1) - 1
    // page index 1: pageSize * current -> pageSize * 2 - 1
    const pageIndex = this.state.current;
    const startRowIndex = this.pageSize * pageIndex;
    const endRowIndex = this.pageSize * (pageIndex + 1);
    if (this.tableColumns.length > endRowIndex) {
      return this.tableColumns.slice(startRowIndex, endRowIndex);
    }
    return this.tableColumns.slice(startRowIndex);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.columns !== this.props.columns) {
      this.setupRows(nextProps.columns);
    }
    return true;
  }

  render() {
    const row = this.getRow();
    return (
      <div className="content hidden-xs">
        <div id="edurack" className="videorack">
          <div className="videorack-title">{this.props.title}</div>
          <div className="videorack-body">
            <div className="swiper-container" style={{ height: 'auto' }}>
              <div className="swiper-wrapper">
                <table className="videorack-page" style={{ width: "100%", }}>
                  <tbody>
                    <tr>{row}</tr>
                  </tbody>
                </table>
              </div>
              <PageButton
                onPageIndexChange={(pageIndex) =>
                  this.onPageIndexChange(pageIndex)
                }
                current={this.state.current}
                totalPage={this.state.totalPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Table extends Component {
  render() {
    const content = this.props.content;
    const subtitle = this.props.subtitle;
    const playlistid = MQSGUtil.getData(content, "playlist_id");
    const index = MQSGUtil.getData(content, "index");
    var src = '';
    if (MQSGUtil.getData(content, "image")) {
      src = MQSGUtil.getAPIImgPath() + MQSGUtil.getData(content, "id");
    } else {
      src = 'https://i.ytimg.com/vi/' + MQSGUtil.getData(content, "playlist_id") + '/mqdefault.jpg'
    }
    const title = { __html: MQSGUtil.getData(content, "title") };
    const fbtitle = MQSGUtil.getData(content, "fbtitle");
    const link =
      "/education/marketcommentary/" +
      subtitle +
      "/" +
      playlistid +
      "/" +
      index;
    return (
      <td style={{ textAlign: 'left' }}>
        <div
          playlistid={playlistid}
          index={index}
          className="videorack-item"
          style={{ display: "inline-block" }}
        >
          <Link target="_blank" to={link} search={fbtitle}>
            <img style={{ width: "100%", maxWidth: '327px' }} src={src} alt="" />
            <div
              className="videorack-item-txt"
              style={{ color: '#000', maxWidth: '330px' }}
              dangerouslySetInnerHTML={title}
            ></div>
          </Link>
        </div>
      </td >
    );
  }
}

class PageButton extends Component {
  //下一页
  onForward() {
    if (this.props.current + 1 < this.props.totalPage) {
      this.props.onPageIndexChange(this.props.current + 1);
    }
  }

  //上一页
  onBackward() {
    if (this.props.current - 1 >= 0) {
      this.props.onPageIndexChange(this.props.current - 1);
    }
  }

  isDisabled(direction) {
    if (direction === "backward") {
      if (this.props.current === 0) {
        return "swiper-button-disabled";
      }
    } else if (direction === "forward") {
      if (this.props.current === this.props.totalPage - 1) {
        return "swiper-button-disabled";
      }
    }
    return "";
  }

  render() {
    return (
      <div className="swiper-botton hidden-xs">
        <div
          className={"gallery-nav left backward " + this.isDisabled("backward")}
          onClick={() => this.onBackward()}
        >
          <div className="videorack-nav-icon"></div>
        </div>
        <div
          className={"gallery-nav right forward " + this.isDisabled("forward")}
          onClick={() => this.onForward()}
        >
          <div className="videorack-nav-icon"></div>
        </div>
      </div>
    );
  }
}
