
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class gearing extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <div className="videotxt hidden-xxs hidden-xs">
                                                <p>In the last tutorial we explored the idea of premium, and how that is the small price to pay for the gearing warrants provide. Gearing is also one of the biggest advantages for investors to trade warrants. But what exactly
                                                    is gearing?</p>
                                            </div>
                                            <p>
                                                Gearing Indicates the increased number of warrants you can buy with a certain amount of capital as opposed to buying the underlying share i.e. a gearing level of 10x means that you will be able to buy 10 times as many units of underlying exposure than
                                                you could if you purchased the underlying share.<br /><br />
                                            </p>
                                            <div className="formulaholder">
                                                <div className="formulabox">
                                                    <p><span className="leqt">Gearing</span> = Underlying Asset Price / (Warrant Price x Warrants Per Share)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}