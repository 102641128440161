import React, { Component } from "react";
import "./termsOfUse.css";
import BreadcrumbBar from "../../../components/breadcrumbBar";

export default class TermsOfUse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="termsofuse" className="pageobj">
        <BreadcrumbBar link="/home" group="Macquarie" title="Terms of use" />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header">
                  <h1>Terms of use</h1>
                </div>
                <p>
                  This internet site is produced by Macquarie Capital Securities
                  (Singapore) Pte Limited (Registration No 198702912C)
                  (“MCSSPL”), holder of a capital markets services licence under
                  the Securities and Futures Act, Chapter 289 of Singapore.
                </p>
                <p>
                  The information on this internet site is directed and
                  available to residents of Singapore only and is not provided
                  to any person who is a resident of the United States or any
                  other country. Any material provided on this internet site,
                  including any indicative terms are provided for information
                  purposes only and do not constitute an offer, a solicitation
                  of an offer, or any advice or recommendation to conclude any
                  transaction (whether on the indicative terms or otherwise).The
                  Warrants will not be offered to any US persons.
                </p>
                <p>
                  The price of securities may go down as well as up, and under
                  certain circumstances an investor may sustain a total loss of
                  investment. As past performance is not an indication of future
                  performance, an investor should ensure that they understand
                  the nature of the investment product and in particular the
                  risk factors before investing.
                </p>
                <br />
                <div className="title">
                  Macquarie and the Material on this website
                </div>
                <p>
                  Macquarie Warrants are issued by Macquarie Bank Limited (ABN
                  008 583 542) ("MBL"). MCSSPL has issued the information in
                  this site. Except where expressly stated, the information and
                  services on this website (the "Material") are provided by
                  MCSSPL as at the date indicated on the relevant material.
                </p>
                <p>
                  <strong>
                    MBL, acting through its Singapore branch is authorised and
                    licensed by the Monetary Authority of Singapore to carry on
                    wholesale banking business in Singapore pursuant to the
                    Banking Act, Chapter 19 of Singapore and therefore is
                    subject to the supervision of the Monetary Authority of
                    Singapore.
                  </strong>
                </p>
                <p>
                  <b>MCSSPL</b>
                  is not an authorised deposit-taking institution for the
                  purposes of the Banking Act (Cth) 1959, and MCSSPL’s
                  obligations do not represent deposits or other liabilities of
                  Macquarie Bank Limited ABN 46 008 583 542. Macquarie Bank
                  Limited does not guarantee or otherwise provide assurance in
                  respect of the obligations of
                  <b>MCSSPL</b>.
                </p>
                <p>
                  In relation to Warrants which are listed on the Singapore
                  Exchange Securities Trading Limited (“SGX”), a base listing
                  document dated 12 July 2012 has been prepared. In addition a
                  supplemental listing document will or has been prepared by MBL
                  for the purpose of obtaining a listing for the Warrants on the
                  SGX. Copies of the base listing document and supplemental
                  listing document(s)-(together the “Listing Documents”) may be
                  obtained from the offices of MCSSPL at 9 Straits View #21-07
                  Marina One West Tower Singapore 018937 and on this web-site.
                </p>
                <br />
                <div className="title">Third Party Information</div>
                <p>
                  The material in this document has been prepared in good faith
                  with all reasonable care. However, certain parts of this
                  material is obtained or is based upon information obtained
                  from third parties which may not have been checked or
                  verified. Except to the extent permitted by law, and only to
                  the extent so required, no member of the Macquarie Group makes
                  any warranty in relation to, or accepts any responsibility or
                  liability for any loss or damage suffered by any person
                  arising out of or in relation to the material. This document
                  is not for circulation or publication without the prior
                  written consent of MCSSPL.
                </p>
                <br />
                <div className="title">Opinions, ideas and strategies</div>
                <p>
                  The opinions, ideas and strategies in this website are based
                  on certain assumptions and current market conditions. If those
                  underlying assumptions or market conditions change, the
                  opinions, ideas and strategies will change. All quotations are
                  indicative only. A firm quotation can be provided by
                  contacting MBL directly. The strategies outlined are not
                  intended to be specific and/or personal advice and MBL
                  expressly disclaims all or any liability and responsibility to
                  any person in respect to anything (and the consequences of
                  anything) done or omitted to be done by any person in reliance
                  on the whole or any part of this publication.
                </p>
                <br />
                <div className="title">
                  No personal investment advice – seek personalised advice from
                  a qualified adviser before investing
                </div>
                <p>
                  Any general advice contained on this website has been prepared
                  by MCSSPL and does not take account of your objectives,
                  financial situation or needs. Before acting on this general
                  advice, you should consider the appropriateness of the advice
                  having regard to your situation. We recommend you obtain
                  financial, legal and taxation advice before making any
                  financial investment decision. Macquarie or its associates,
                  officers or employees may have interests in the financial
                  products referred to in this information by acting in various
                  roles including as investment banker, underwriter or dealer,
                  holder of principal positions, broker, lender or adviser.
                  Macquarie or its associates may receive fees, brokerage or
                  commissions for acting in those capacities. In addition,
                  Macquarie or its associates, officers or employees may buy or
                  sell the financial products as principal or agent and as such
                  may effect transactions which are not consistent with any
                  recommendations in the information. All investors should
                  obtain the Listing Documents for the products discussed and
                  should consider it in full before making a decision.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
