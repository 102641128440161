// ScreenerJSONServlet?undefined&underlying=all
// &type=all&issuer=MBL&maturity=all&moneyness=all
// &moneynessPercent=all&effectiveGearing=all
// &expiry=all&indicator=all&sortBy=&sortOrder=asc&qid=1613542941968
const underlyingAll = {
    "data": [
        {
        "askVolume": "590000.000000000",
        "bidVolume": "690000.000000000",
        "underlying_ric": "AEM.SI",
        "underlyingSymbol": "AWX",
        "theta": "-3.49",
        "tradeVolume_f": "0",
        "exercisePrice": "4.50",
        "bidVolume_f": "690",
        "askVolume_f": "590",
        "HIGHLQDY": true,
        "priceChange": "-14.285714286",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.024000000",
        "dwps": "10.0",
        "moneyness_c": "1% ITM",
        "priceChange_f": "-14.3",
        "impliedVolalitiy": "64.7",
        "moneyness": "ITM",
        "sensitivity": "1.81",
        "askPrice_f": "0.028",
        "delta": "55.2",
        "APPCHEXPRY": true,
        "underlying_name": "AEM HOLDINGS LTD",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.024",
        "issuer": "MBL",
        "ric": "AEML_tk.SI",
        "avg_decay": "0.00094",
        "effectiveGearing": "10.4",
        "moneyness_percent": "1",
        "dwSymbol": "983W",
        "askPrice": "0.028000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "AEM.SI",
        "underlyingSymbol": "AWX",
        "theta": "-0.19",
        "tradeVolume_f": "0",
        "exercisePrice": "4.20",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-2.702702703",
        "type": "C",
        "conv_ratio": "12.0",
        "bidPrice": "0.108000000",
        "dwps": "12.0",
        "moneyness_c": "8% ITM",
        "priceChange_f": "-2.7",
        "impliedVolalitiy": "87.1",
        "moneyness": "ITM",
        "sensitivity": "1.80",
        "askPrice_f": "0.110",
        "delta": "66.5",
        "APPCHEXPRY": false,
        "underlying_name": "AEM HOLDINGS LTD",
        "ltDate": "10 Sep 21",
        "bidPrice_f": "0.108",
        "issuer": "MBL",
        "ric": "AEML_tm.SI",
        "avg_decay": "0.00020",
        "effectiveGearing": "2.3",
        "HOTLIST": true,
        "moneyness_percent": "8",
        "dwSymbol": "VKEW",
        "askPrice": "0.110000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "AEMN.SI",
        "underlyingSymbol": "A17U",
        "theta": "-1.15",
        "tradeVolume_f": "0",
        "exercisePrice": "3.60",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "6.5",
        "bidPrice": "0.013000000",
        "HIGHRESP": false,
        "dwps": "6.5",
        "moneyness_c": "15% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "41.5",
        "moneyness": "OTM",
        "sensitivity": "2.69",
        "askPrice_f": "0.014",
        "delta": "24.2",
        "APPCHEXPRY": false,
        "underlying_name": "ASCEN REIT",
        "ltDate": "17 May 21",
        "bidPrice_f": "0.013",
        "issuer": "MBL",
        "ric": "AEML_tl.SI",
        "avg_decay": "0.00015",
        "effectiveGearing": "8.8",
        "HOTLIST": true,
        "moneyness_percent": "15",
        "dwSymbol": "RQOW",
        "askPrice": "0.014000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "AEMN.SI",
        "underlyingSymbol": "A17U",
        "theta": "-18.66",
        "tradeVolume_f": "0",
        "exercisePrice": "3.50",
        "bidVolume_f": "0",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "5.5",
        "bidPrice": "0.001000000",
        "dwps": "5.5",
        "moneyness_c": "12% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "42.6",
        "moneyness": "OTM",
        "sensitivity": "10.14",
        "askPrice_f": "0.005",
        "delta": "5.4",
        "APPCHEXPRY": true,
        "underlying_name": "ASCEN REIT",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "AEML_tj.SI",
        "avg_decay": "0.00015",
        "effectiveGearing": "30.3",
        "moneyness_percent": "12",
        "dwSymbol": "WQ6W",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "9988.HK",
        "underlyingSymbol": "BABA-SW",
        "theta": "-1.04",
        "tradeVolume_f": "0",
        "exercisePrice": "350.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "100",
        "priceChange": "33.333333333",
        "type": "C",
        "conv_ratio": "25",
        "bidPrice": "0.004000000",
        "dwps": "25",
        "moneyness_c": "23% OTM",
        "priceChange_f": "+33.3",
        "impliedVolalitiy": "40.2",
        "moneyness": "OTM",
        "sensitivity": "63.44",
        "askPrice_f": "0.010",
        "delta": "3.9",
        "APPCHEXPRY": false,
        "underlying_name": "BABA-SW",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.004",
        "issuer": "MBL",
        "ric": "ALML_tf.SI",
        "avg_decay": "0.00020",
        "effectiveGearing": "18.1",
        "moneyness_percent": "23",
        "dwSymbol": "1M7W",
        "askPrice": "0.010000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "9988.HK",
        "underlyingSymbol": "BABA-SW",
        "theta": "-0.10",
        "tradeVolume_f": "2",
        "exercisePrice": "300.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "0",
        "HIGHLQDY": true,
        "priceChange": "8.450704225",
        "type": "C",
        "conv_ratio": "45",
        "bidPrice": "0.077000000",
        "HIGHRESP": false,
        "dwps": "45",
        "moneyness_c": "10% OTM",
        "priceChange_f": "+8.5",
        "impliedVolalitiy": "47.5",
        "moneyness": "OTM",
        "sensitivity": "10.88",
        "askPrice_f": "0.078",
        "delta": "41.4",
        "APPCHEXPRY": false,
        "underlying_name": "BABA-SW",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.077",
        "issuer": "MBL",
        "ric": "ALML_th.SI",
        "avg_decay": "0.00045",
        "effectiveGearing": "5.5",
        "HOTLIST": true,
        "moneyness_percent": "10",
        "dwSymbol": "R42W",
        "askPrice": "0.078000000",
        "tradeVolume": "2400.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "9988.HK",
        "underlyingSymbol": "BABA-SW",
        "theta": "-0.29",
        "tradeVolume_f": "0",
        "exercisePrice": "250.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "-15.189873418",
        "type": "P",
        "conv_ratio": "25",
        "bidPrice": "0.067000000",
        "dwps": "25",
        "moneyness_c": "8% OTM",
        "priceChange_f": "-15.2",
        "impliedVolalitiy": "47.9",
        "moneyness": "OTM",
        "sensitivity": "8.16",
        "askPrice_f": "0.069",
        "delta": "-30.6",
        "APPCHEXPRY": false,
        "underlying_name": "BABA-SW",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.067",
        "issuer": "MBL",
        "ric": "ALML_te.SI",
        "avg_decay": "0.00123",
        "effectiveGearing": "8.4",
        "moneyness_percent": "8",
        "dwSymbol": "X7RW",
        "askPrice": "0.069000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "9988.HK",
        "underlyingSymbol": "BABA-SW",
        "theta": "-0.09",
        "tradeVolume_f": "60",
        "exercisePrice": "240.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-7.692307692",
        "type": "P",
        "conv_ratio": "45",
        "bidPrice": "0.072000000",
        "HIGHRESP": false,
        "dwps": "45",
        "moneyness_c": "12% OTM",
        "priceChange_f": "-7.7",
        "impliedVolalitiy": "51.4",
        "moneyness": "OTM",
        "sensitivity": "15.05",
        "askPrice_f": "0.073",
        "delta": "-29.9",
        "APPCHEXPRY": false,
        "underlying_name": "BABA-SW",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.072",
        "issuer": "MBL",
        "ric": "ALML_tg.SI",
        "avg_decay": "0.00033",
        "effectiveGearing": "4.2",
        "HOTLIST": true,
        "moneyness_percent": "12",
        "dwSymbol": "QBDW",
        "askPrice": "0.073000000",
        "tradeVolume": "60000.000000000"
        },
        {
        "askVolume": "170000.000000000",
        "bidVolume": "50000.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-0.09",
        "tradeVolume_f": "0",
        "exercisePrice": "320.00",
        "bidVolume_f": "50",
        "askVolume_f": "170",
        "HIGHLQDY": true,
        "priceChange": "-2.758620690",
        "type": "C",
        "conv_ratio": "45",
        "bidPrice": "0.141000000",
        "dwps": "45",
        "moneyness_c": "17% OTM",
        "priceChange_f": "-2.8",
        "impliedVolalitiy": "83.9",
        "moneyness": "OTM",
        "sensitivity": "9.69",
        "askPrice_f": "0.143",
        "delta": "46.4",
        "APPCHEXPRY": false,
        "underlying_name": "BYD COMPANY",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.141",
        "issuer": "MBL",
        "ric": "BYML_tg.SI",
        "avg_decay": "0.00083",
        "effectiveGearing": "3.3",
        "HOTLIST": true,
        "moneyness_percent": "17",
        "dwSymbol": "MGVW",
        "askPrice": "0.143000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-0.34",
        "tradeVolume_f": "0",
        "exercisePrice": "250.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "-8.620689655",
        "type": "C",
        "conv_ratio": "25",
        "bidPrice": "0.159000000",
        "dwps": "25",
        "moneyness_c": "7% ITM",
        "priceChange_f": "-8.6",
        "impliedVolalitiy": "67.9",
        "moneyness": "ITM",
        "sensitivity": "3.52",
        "askPrice_f": "0.165",
        "delta": "71.0",
        "APPCHEXPRY": true,
        "underlying_name": "BYD COMPANY",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.159",
        "issuer": "MBL",
        "ric": "BYML_td.SI",
        "avg_decay": "0.00358",
        "effectiveGearing": "8.1",
        "moneyness_percent": "7",
        "dwSymbol": "XATW",
        "askPrice": "0.165000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "60000.000000000",
        "bidVolume": "180000.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-0.07",
        "tradeVolume_f": "0",
        "exercisePrice": "250.00",
        "bidVolume_f": "180",
        "askVolume_f": "60",
        "priceChange": "2.255639098",
        "type": "P",
        "conv_ratio": "45",
        "bidPrice": "0.136000000",
        "dwps": "45",
        "moneyness_c": "7% OTM",
        "priceChange_f": "+2.3",
        "impliedVolalitiy": "70.5",
        "moneyness": "OTM",
        "sensitivity": "12.69",
        "askPrice_f": "0.140",
        "delta": "-35.5",
        "APPCHEXPRY": false,
        "underlying_name": "BYD COMPANY",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.136",
        "issuer": "MBL",
        "ric": "BYML_th.SI",
        "avg_decay": "0.00048",
        "effectiveGearing": "2.6",
        "moneyness_percent": "7",
        "dwSymbol": "VRJW",
        "askPrice": "0.140000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-0.02",
        "tradeVolume_f": "0",
        "exercisePrice": "200.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "-2.500000000",
        "type": "C",
        "conv_ratio": "35",
        "bidPrice": "0.390000000",
        "dwps": "35",
        "moneyness_c": "33% ITM",
        "priceChange_f": "-2.5",
        "impliedVolalitiy": "65.6",
        "moneyness": "ITM",
        "sensitivity": "21.30",
        "askPrice_f": "0.405",
        "delta": "82.2",
        "APPCHEXPRY": false,
        "underlying_name": "BYD COMPANY",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.390",
        "issuer": "MBL",
        "ric": "BYML_te.SI",
        "avg_decay": "0.00020",
        "effectiveGearing": "2.7",
        "moneyness_percent": "33",
        "dwSymbol": "TMHW",
        "askPrice": "0.405000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "300000.000000000",
        "bidVolume": "900000.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-0.23",
        "tradeVolume_f": "0",
        "exercisePrice": "150.00",
        "bidVolume_f": "900",
        "askVolume_f": "300",
        "HIGHLQDY": true,
        "priceChange": "3.846153846",
        "type": "P",
        "conv_ratio": "25",
        "bidPrice": "0.027000000",
        "dwps": "25",
        "moneyness_c": "78% OTM",
        "priceChange_f": "+3.8",
        "impliedVolalitiy": "72.2",
        "moneyness": "OTM",
        "sensitivity": "39.23",
        "askPrice_f": "0.029",
        "delta": "-6.4",
        "APPCHEXPRY": false,
        "underlying_name": "BYD COMPANY",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.027",
        "issuer": "MBL",
        "ric": "BYML_tf.SI",
        "avg_decay": "0.00039",
        "effectiveGearing": "4.3",
        "HOTLIST": true,
        "moneyness_percent": "78",
        "dwSymbol": "YBMW",
        "askPrice": "0.029000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-5.29",
        "tradeVolume_f": "0",
        "exercisePrice": "150.00",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "25",
        "bidPrice": "0.001000000",
        "dwps": "25",
        "moneyness_c": "78% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "128.5",
        "moneyness": "OTM",
        "sensitivity": "385.93",
        "askPrice_f": "0.005",
        "delta": "-0.6",
        "APPCHEXPRY": true,
        "underlying_name": "BYD COMPANY",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "BYML_tc.SI",
        "avg_decay": "0.00018",
        "effectiveGearing": "11.8",
        "moneyness_percent": "78",
        "dwSymbol": "NYBW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "140.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "-1.704545455",
        "type": "C",
        "conv_ratio": "25",
        "bidPrice": "0.865000000",
        "dwps": "25",
        "moneyness_c": "90% ITM",
        "priceChange_f": "-1.7",
        "impliedVolalitiy": "10.0",
        "moneyness": "ITM",
        "sensitivity": "12.50",
        "askPrice_f": "0.890",
        "delta": "100.0",
        "APPCHEXPRY": false,
        "underlying_name": "BYD COMPANY",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.865",
        "issuer": "MBL",
        "ric": "BYML_tb.SI",
        "avg_decay": "-0.00042",
        "effectiveGearing": "2.1",
        "moneyness_percent": "90",
        "dwSymbol": "VHUW",
        "askPrice": "0.890000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "1211.HK",
        "underlyingSymbol": "BYD COMPANY",
        "theta": "-1.54",
        "tradeVolume_f": "0",
        "exercisePrice": "98.00",
        "bidVolume_f": "0",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "25",
        "bidPrice": "0.001000000",
        "dwps": "25",
        "moneyness_c": "172% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "113.3",
        "moneyness": "OTM",
        "sensitivity": "646.72",
        "askPrice_f": "0.005",
        "delta": "-0.4",
        "APPCHEXPRY": false,
        "underlying_name": "BYD COMPANY",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "BYML_ta.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "7.1",
        "moneyness_percent": "172",
        "dwSymbol": "RUQW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "0.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "CATL.SI",
        "underlyingSymbol": "C31",
        "theta": "-71.92",
        "tradeVolume_f": "0",
        "exercisePrice": "4.00",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "priceChange": "-200.000000000",
        "type": "C",
        "conv_ratio": "2.0",
        "bidPrice": "0.000000000",
        "dwps": "2.0",
        "moneyness_c": "22% OTM",
        "priceChange_f": "-200.0",
        "impliedVolalitiy": "95.8",
        "moneyness": "OTM",
        "sensitivity": "12.14",
        "askPrice_f": "0.000",
        "delta": "1.6",
        "APPCHEXPRY": true,
        "underlying_name": "CAPLAND",
        "ltDate": "15 Feb 21",
        "bidPrice_f": "0.000",
        "issuer": "MBL",
        "ric": "CAML_t2.SI",
        "avg_decay": "0.00000",
        "effectiveGearing": "25.9",
        "moneyness_percent": "22",
        "dwSymbol": "WU1W",
        "askPrice": "0.000000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "CATL.SI",
        "underlyingSymbol": "C31",
        "theta": "-0.40",
        "tradeVolume_f": "0",
        "exercisePrice": "3.80",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "5.5",
        "bidPrice": "0.028000000",
        "dwps": "5.5",
        "moneyness_c": "17% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "37.3",
        "moneyness": "OTM",
        "sensitivity": "1.86",
        "askPrice_f": "0.030",
        "delta": "29.6",
        "APPCHEXPRY": false,
        "underlying_name": "CAPLAND",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.028",
        "issuer": "MBL",
        "ric": "CAML_t4.SI",
        "avg_decay": "0.00001",
        "effectiveGearing": "6.0",
        "HOTLIST": true,
        "moneyness_percent": "17",
        "dwSymbol": "MHJW",
        "askPrice": "0.030000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "CATL.SI",
        "underlyingSymbol": "C31",
        "theta": "-1.16",
        "tradeVolume_f": "0",
        "exercisePrice": "3.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-1.282051282",
        "type": "C",
        "conv_ratio": "2.0",
        "bidPrice": "0.077000000",
        "dwps": "2.0",
        "moneyness_c": "5% ITM",
        "priceChange_f": "-1.3",
        "impliedVolalitiy": "29.4",
        "moneyness": "ITM",
        "sensitivity": "0.26",
        "askPrice_f": "0.083",
        "delta": "76.6",
        "APPCHEXPRY": true,
        "underlying_name": "CAPLAND",
        "ltDate": "25 Feb 21",
        "bidPrice_f": "0.077",
        "issuer": "MBL",
        "ric": "CAML_t3.SI",
        "avg_decay": "0.00011",
        "effectiveGearing": "15.6",
        "moneyness_percent": "5",
        "dwSymbol": "HUFW",
        "askPrice": "0.083000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2520000.000000000",
        "bidVolume": "1570000.000000000",
        "underlying_ric": "CTDM.SI",
        "underlyingSymbol": "C09",
        "theta": "-1.70",
        "tradeVolume_f": "0",
        "exercisePrice": "9.00",
        "bidVolume_f": "1,570",
        "askVolume_f": "2,520",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.019000000",
        "HIGHRESP": false,
        "dwps": "10.0",
        "moneyness_c": "20% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "53.2",
        "moneyness": "OTM",
        "sensitivity": "4.73",
        "askPrice_f": "0.020",
        "delta": "21.1",
        "APPCHEXPRY": false,
        "underlying_name": "CITY DEVELOPMENT",
        "ltDate": "30 Apr 21",
        "bidPrice_f": "0.019",
        "issuer": "MBL",
        "ric": "CTML_tt.SI",
        "avg_decay": "0.00028",
        "effectiveGearing": "8.0",
        "HOTLIST": true,
        "moneyness_percent": "20",
        "dwSymbol": "LQXW",
        "askPrice": "0.020000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "CMDG.SI",
        "underlyingSymbol": "C52",
        "theta": "-0.38",
        "tradeVolume_f": "0",
        "exercisePrice": "2.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "5.5",
        "bidPrice": "0.020000000",
        "dwps": "5.5",
        "moneyness_c": "21% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "48.1",
        "moneyness": "OTM",
        "sensitivity": "3.39",
        "askPrice_f": "0.022",
        "delta": "32.5",
        "APPCHEXPRY": false,
        "underlying_name": "COMFORTDELGRO LD",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.020",
        "issuer": "MBL",
        "ric": "CMML_t2.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "4.7",
        "HOTLIST": true,
        "moneyness_percent": "21",
        "dwSymbol": "MSHW",
        "askPrice": "0.022000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "CMDG.SI",
        "underlyingSymbol": "C52",
        "theta": "-12.45",
        "tradeVolume_f": "0",
        "exercisePrice": "1.70",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "2.0",
        "bidPrice": "0.004000000",
        "dwps": "2.0",
        "moneyness_c": "6% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "35.5",
        "moneyness": "OTM",
        "sensitivity": "2.60",
        "askPrice_f": "0.006",
        "delta": "15.4",
        "APPCHEXPRY": true,
        "underlying_name": "COMFORTDELGRO LD",
        "ltDate": "22 Feb 21",
        "bidPrice_f": "0.004",
        "issuer": "MBL",
        "ric": "CMML_t1.SI",
        "avg_decay": "0.00048",
        "effectiveGearing": "30.6",
        "moneyness_percent": "6",
        "dwSymbol": "XLJW",
        "askPrice": "0.006000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1510000.000000000",
        "bidVolume": "890000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.33",
        "tradeVolume_f": "0",
        "exercisePrice": "29.00",
        "bidVolume_f": "890",
        "askVolume_f": "1,510",
        "priceChange": "1.408450704",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.072000000",
        "dwps": "18.0",
        "moneyness_c": "10% OTM",
        "priceChange_f": "+1.4",
        "impliedVolalitiy": "30.7",
        "moneyness": "OTM",
        "sensitivity": "5.26",
        "askPrice_f": "0.074",
        "delta": "34.2",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "01 Oct 21",
        "bidPrice_f": "0.072",
        "issuer": "MBL",
        "ric": "DBML_t34.SI",
        "avg_decay": "0.00039",
        "effectiveGearing": "6.9",
        "moneyness_percent": "10",
        "dwSymbol": "OFHW",
        "askPrice": "0.074000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2410000.000000000",
        "bidVolume": "1420000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-1.39",
        "tradeVolume_f": "61",
        "exercisePrice": "28.50",
        "bidVolume_f": "1,420",
        "askVolume_f": "2,410",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.026000000",
        "dwps": "18.0",
        "moneyness_c": "9% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "28.9",
        "moneyness": "OTM",
        "sensitivity": "7.12",
        "askPrice_f": "0.028",
        "delta": "25.3",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "26 Apr 21",
        "bidPrice_f": "0.026",
        "issuer": "MBL",
        "ric": "DBML_t38.SI",
        "avg_decay": "0.00029",
        "effectiveGearing": "14.1",
        "moneyness_percent": "9",
        "dwSymbol": "HRZW",
        "askPrice": "0.028000000",
        "tradeVolume": "60700.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "550000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.24",
        "tradeVolume_f": "861",
        "exercisePrice": "27.00",
        "bidVolume_f": "550",
        "askVolume_f": "500",
        "priceChange": "0.952380952",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.106000000",
        "HIGHRESP": false,
        "dwps": "18.0",
        "moneyness_c": "4% OTM",
        "priceChange_f": "+1.0",
        "impliedVolalitiy": "31.2",
        "moneyness": "OTM",
        "sensitivity": "3.98",
        "askPrice_f": "0.107",
        "delta": "45.3",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "24 Sep 21",
        "bidPrice_f": "0.106",
        "issuer": "MBL",
        "ric": "DBML_t33.SI",
        "avg_decay": "0.00026",
        "effectiveGearing": "6.2",
        "HOTLIST": true,
        "moneyness_percent": "4",
        "dwSymbol": "9WJW",
        "askPrice": "0.107000000",
        "tradeVolume": "860900.000000000"
        },
        {
        "askVolume": "400000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.23",
        "tradeVolume_f": "0",
        "exercisePrice": "26.00",
        "bidVolume_f": "500",
        "askVolume_f": "400",
        "priceChange": "-1.176470588",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.168000000",
        "dwps": "18.0",
        "moneyness_c": "0% OTM",
        "priceChange_f": "-1.2",
        "impliedVolalitiy": "35.2",
        "moneyness": "OTM",
        "sensitivity": "3.97",
        "askPrice_f": "0.170",
        "delta": "-45.3",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "24 Sep 21",
        "bidPrice_f": "0.168",
        "issuer": "MBL",
        "ric": "DBML_t36.SI",
        "avg_decay": "0.00038",
        "effectiveGearing": "3.9",
        "moneyness_percent": "0",
        "dwSymbol": "KEDW",
        "askPrice": "0.170000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "740000.000000000",
        "bidVolume": "430000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.95",
        "tradeVolume_f": "269",
        "exercisePrice": "26.00",
        "bidVolume_f": "430",
        "askVolume_f": "740",
        "priceChange": "1.562500000",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.065000000",
        "HIGHRESP": false,
        "dwps": "18.0",
        "moneyness_c": "0% ITM",
        "priceChange_f": "+1.6",
        "impliedVolalitiy": "31.8",
        "moneyness": "ITM",
        "sensitivity": "3.49",
        "askPrice_f": "0.066",
        "delta": "51.6",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "30 Mar 21",
        "bidPrice_f": "0.065",
        "issuer": "MBL",
        "ric": "DBML_t31.SI",
        "avg_decay": "0.00067",
        "effectiveGearing": "11.5",
        "moneyness_percent": "0",
        "dwSymbol": "EYOW",
        "askPrice": "0.066000000",
        "tradeVolume": "269300.000000000"
        },
        {
        "askVolume": "670000.000000000",
        "bidVolume": "1140000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.54",
        "tradeVolume_f": "0",
        "exercisePrice": "24.50",
        "bidVolume_f": "1,140",
        "askVolume_f": "670",
        "priceChange": "-1.234567901",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.080000000",
        "dwps": "18.0",
        "moneyness_c": "6% OTM",
        "priceChange_f": "-1.2",
        "impliedVolalitiy": "32.7",
        "moneyness": "OTM",
        "sensitivity": "5.07",
        "askPrice_f": "0.082",
        "delta": "-35.5",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.080",
        "issuer": "MBL",
        "ric": "DBML_t37.SI",
        "avg_decay": "0.00047",
        "effectiveGearing": "6.4",
        "HOTLIST": true,
        "moneyness_percent": "6",
        "dwSymbol": "QYLW",
        "askPrice": "0.082000000",
        "tradeVolume": "100.000000000"
        },
        {
        "askVolume": "600000.000000000",
        "bidVolume": "1100000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.31",
        "tradeVolume_f": "0",
        "exercisePrice": "24.00",
        "bidVolume_f": "1,100",
        "askVolume_f": "600",
        "priceChange": "-1.785714286",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.110000000",
        "dwps": "18.0",
        "moneyness_c": "8% OTM",
        "priceChange_f": "-1.8",
        "impliedVolalitiy": "34.4",
        "moneyness": "OTM",
        "sensitivity": "5.22",
        "askPrice_f": "0.112",
        "delta": "-34.5",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "01 Oct 21",
        "bidPrice_f": "0.110",
        "issuer": "MBL",
        "ric": "DBML_t35.SI",
        "avg_decay": "0.00039",
        "effectiveGearing": "4.5",
        "moneyness_percent": "8",
        "dwSymbol": "RWXW",
        "askPrice": "0.112000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.96",
        "tradeVolume_f": "0",
        "exercisePrice": "22.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "HIGHLQDY": true,
        "priceChange": "-3.125000000",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.031000000",
        "dwps": "18.0",
        "moneyness_c": "18% OTM",
        "priceChange_f": "-3.1",
        "impliedVolalitiy": "32.7",
        "moneyness": "OTM",
        "sensitivity": "9.99",
        "askPrice_f": "0.036",
        "delta": "-18.0",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "24 Jun 21",
        "bidPrice_f": "0.031",
        "issuer": "MBL",
        "ric": "DBML_t32.SI",
        "avg_decay": "0.00022",
        "effectiveGearing": "8.4",
        "moneyness_percent": "18",
        "dwSymbol": "YY9W",
        "askPrice": "0.036000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "380000.000000000",
        "bidVolume": "220000.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.04",
        "tradeVolume_f": "0",
        "exercisePrice": "21.00",
        "bidVolume_f": "220",
        "askVolume_f": "380",
        "priceChange": "1.818181818",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.280000000",
        "dwps": "18.0",
        "moneyness_c": "24% ITM",
        "priceChange_f": "+1.8",
        "impliedVolalitiy": "37.8",
        "moneyness": "ITM",
        "sensitivity": "9.53",
        "askPrice_f": "0.290",
        "delta": "94.4",
        "APPCHEXPRY": false,
        "underlying_name": "DBS",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.280",
        "issuer": "MBL",
        "ric": "DBML_t30.SI",
        "avg_decay": "0.00052",
        "effectiveGearing": "4.9",
        "moneyness_percent": "24",
        "dwSymbol": "WRAW",
        "askPrice": "0.290000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "0.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "DBSM.SI",
        "underlyingSymbol": "D05",
        "theta": "-0.68",
        "tradeVolume_f": "0",
        "exercisePrice": "19.00",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.000000000",
        "dwps": "18.0",
        "moneyness_c": "37% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "0.0",
        "moneyness": "OTM",
        "sensitivity": "64,077.92",
        "askPrice_f": "0.000",
        "delta": "-0.0",
        "APPCHEXPRY": true,
        "underlying_name": "DBS",
        "ltDate": "09 Feb 21",
        "bidPrice_f": "0.000",
        "issuer": "MBL",
        "ric": "DBML_t29.SI",
        "effectiveGearing": "0.0",
        "moneyness_percent": "37",
        "dwSymbol": "XPUW",
        "askPrice": "0.000000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1220000.000000000",
        "bidVolume": "1630000.000000000",
        "underlying_ric": "0175.HK",
        "underlyingSymbol": "GEELY AUTO",
        "theta": "-0.08",
        "tradeVolume_f": "0",
        "exercisePrice": "45.00",
        "bidVolume_f": "1,630",
        "askVolume_f": "1,220",
        "HIGHLQDY": true,
        "priceChange": "2.898550725",
        "type": "C",
        "conv_ratio": "7.0",
        "bidPrice": "0.071000000",
        "dwps": "7.0",
        "moneyness_c": "32% OTM",
        "priceChange_f": "+2.9",
        "impliedVolalitiy": "70.3",
        "moneyness": "OTM",
        "sensitivity": "2.10",
        "askPrice_f": "0.073",
        "delta": "33.3",
        "APPCHEXPRY": false,
        "underlying_name": "GEELY AUTO",
        "ltDate": "28 Sep 21",
        "bidPrice_f": "0.071",
        "issuer": "MBL",
        "ric": "GEML_te.SI",
        "avg_decay": "0.00032",
        "effectiveGearing": "3.5",
        "HOTLIST": true,
        "moneyness_percent": "32",
        "dwSymbol": "XQDW",
        "askPrice": "0.073000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1010000.000000000",
        "bidVolume": "760000.000000000",
        "underlying_ric": "0175.HK",
        "underlyingSymbol": "GEELY AUTO",
        "theta": "-0.04",
        "tradeVolume_f": "0",
        "exercisePrice": "30.00",
        "bidVolume_f": "760",
        "askVolume_f": "1,010",
        "HIGHLQDY": true,
        "priceChange": "-1.369863014",
        "type": "P",
        "conv_ratio": "7.0",
        "bidPrice": "0.144000000",
        "dwps": "7.0",
        "moneyness_c": "2% OTM",
        "priceChange_f": "-1.4",
        "impliedVolalitiy": "66.0",
        "moneyness": "OTM",
        "sensitivity": "1.81",
        "askPrice_f": "0.146",
        "delta": "-38.7",
        "APPCHEXPRY": false,
        "underlying_name": "GEELY AUTO",
        "ltDate": "28 Sep 21",
        "bidPrice_f": "0.144",
        "issuer": "MBL",
        "ric": "GEML_tf.SI",
        "avg_decay": "0.00018",
        "effectiveGearing": "2.0",
        "HOTLIST": true,
        "moneyness_percent": "2",
        "dwSymbol": "VZGW",
        "askPrice": "0.146000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "200000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "0175.HK",
        "underlyingSymbol": "GEELY AUTO",
        "theta": "-0.05",
        "tradeVolume_f": "1",
        "exercisePrice": "28.00",
        "bidVolume_f": "300",
        "askVolume_f": "200",
        "priceChange": "3.636363636",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.285000000",
        "dwps": "3.5",
        "moneyness_c": "9% ITM",
        "priceChange_f": "+3.6",
        "impliedVolalitiy": "62.3",
        "moneyness": "ITM",
        "sensitivity": "2.68",
        "askPrice_f": "0.290",
        "delta": "65.2",
        "APPCHEXPRY": false,
        "underlying_name": "GEELY AUTO",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.285",
        "issuer": "MBL",
        "ric": "GEML_td.SI",
        "avg_decay": "0.00117",
        "effectiveGearing": "3.4",
        "moneyness_percent": "9",
        "dwSymbol": "RUHW",
        "askPrice": "0.290000000",
        "tradeVolume": "500.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "200000.000000000",
        "underlying_ric": "0175.HK",
        "underlyingSymbol": "GEELY AUTO",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "18.00",
        "bidVolume_f": "200",
        "askVolume_f": "100",
        "priceChange": "1.666666667",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.610000000",
        "dwps": "3.5",
        "moneyness_c": "69% ITM",
        "priceChange_f": "+1.7",
        "impliedVolalitiy": "10.0",
        "moneyness": "ITM",
        "sensitivity": "1.75",
        "askPrice_f": "0.630",
        "delta": "99.9",
        "APPCHEXPRY": false,
        "underlying_name": "GEELY AUTO",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.610",
        "issuer": "MBL",
        "ric": "GEML_tb.SI",
        "avg_decay": "0.00018",
        "effectiveGearing": "2.4",
        "moneyness_percent": "69",
        "dwSymbol": "RQUW",
        "askPrice": "0.630000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "0175.HK",
        "underlyingSymbol": "GEELY AUTO",
        "theta": "-0.39",
        "tradeVolume_f": "0",
        "exercisePrice": "16.00",
        "bidVolume_f": "100",
        "askVolume_f": "10",
        "priceChange": "-33.333333333",
        "type": "P",
        "conv_ratio": "3.5",
        "bidPrice": "0.002000000",
        "dwps": "3.5",
        "moneyness_c": "90% OTM",
        "priceChange_f": "-33.3",
        "impliedVolalitiy": "52.2",
        "moneyness": "OTM",
        "sensitivity": "22.06",
        "askPrice_f": "0.005",
        "delta": "-1.6",
        "APPCHEXPRY": false,
        "underlying_name": "GEELY AUTO",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.002",
        "issuer": "MBL",
        "ric": "GEML_tc.SI",
        "avg_decay": "-0.00010",
        "effectiveGearing": "11.8",
        "moneyness_percent": "90",
        "dwSymbol": "OCCW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "0175.HK",
        "underlyingSymbol": "GEELY AUTO",
        "theta": "-1.48",
        "tradeVolume_f": "0",
        "exercisePrice": "12.00",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "3.5",
        "bidPrice": "0.001000000",
        "dwps": "3.5",
        "moneyness_c": "154% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "107.8",
        "moneyness": "OTM",
        "sensitivity": "71.16",
        "askPrice_f": "0.005",
        "delta": "-0.5",
        "APPCHEXPRY": false,
        "underlying_name": "GEELY AUTO",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "GEML_ta.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "7.3",
        "moneyness_percent": "154",
        "dwSymbol": "XZ4W",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "GENS.SI",
        "underlyingSymbol": "G13",
        "theta": "-0.54",
        "tradeVolume_f": "0",
        "exercisePrice": "0.95",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-4.761904762",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.020000000",
        "dwps": "3.5",
        "moneyness_c": "11% OTM",
        "priceChange_f": "-4.8",
        "impliedVolalitiy": "53.3",
        "moneyness": "OTM",
        "sensitivity": "1.68",
        "askPrice_f": "0.022",
        "delta": "41.7",
        "APPCHEXPRY": false,
        "underlying_name": "GENTINGSIN",
        "ltDate": "24 Jun 21",
        "bidPrice_f": "0.020",
        "issuer": "MBL",
        "ric": "GTML_te.SI",
        "avg_decay": "0.00011",
        "effectiveGearing": "5.1",
        "HOTLIST": true,
        "moneyness_percent": "11",
        "dwSymbol": "XJLW",
        "askPrice": "0.022000000",
        "tradeVolume": "0.000000000"
        },
        {
        "bidVolume": "0.000000000",
        "askVolume": "0.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "0.00",
        "exercisePrice": "32,400.00",
        "tradeVolume_f": "0",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "type": "C",
        "conv_ratio": "1,200",
        "dwps": "1,200",
        "moneyness_c": "4% OTM",
        "impliedVolalitiy": "0.0",
        "moneyness": "OTM",
        "sensitivity": "0.00",
        "delta": "0.0",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "issuer": "MBL",
        "ric": "HSML_t218.SI",
        "effectiveGearing": "0.0",
        "moneyness_percent": "4",
        "dwSymbol": "LPQW",
        "tradeVolume": "0.000000000"
        },
        {
        "bidVolume": "0.000000000",
        "askVolume": "0.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "0.00",
        "exercisePrice": "31,600.00",
        "tradeVolume_f": "0",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "type": "C",
        "conv_ratio": "1,200",
        "dwps": "1,200",
        "moneyness_c": "2% OTM",
        "impliedVolalitiy": "0.0",
        "moneyness": "OTM",
        "sensitivity": "0.00",
        "delta": "0.0",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "issuer": "MBL",
        "ric": "HSML_t217.SI",
        "effectiveGearing": "0.0",
        "moneyness_percent": "2",
        "dwSymbol": "KKAW",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.09",
        "tradeVolume_f": "0",
        "exercisePrice": "31,200.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "11.111111111",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.250000000",
        "dwps": "1,200",
        "moneyness_c": "1% OTM",
        "priceChange_f": "+11.1",
        "impliedVolalitiy": "28.3",
        "moneyness": "OTM",
        "sensitivity": "68.93",
        "askPrice_f": "0.255",
        "delta": "50.8",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "20 May 21",
        "bidPrice_f": "0.250",
        "issuer": "MBL",
        "ric": "HSML_t211.SI",
        "avg_decay": "0.00160",
        "effectiveGearing": "9.0",
        "moneyness_percent": "1",
        "dwSymbol": "Z7PW",
        "askPrice": "0.255000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1060000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.11",
        "tradeVolume_f": "2,880",
        "exercisePrice": "30,800.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,060",
        "priceChange": "11.979166667",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.215000000",
        "dwps": "1,200",
        "moneyness_c": "1% ITM",
        "priceChange_f": "+12.0",
        "impliedVolalitiy": "25.6",
        "moneyness": "ITM",
        "sensitivity": "64.84",
        "askPrice_f": "0.220",
        "delta": "54.0",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.215",
        "issuer": "MBL",
        "ric": "HSML_t216.SI",
        "avg_decay": "0.00165",
        "effectiveGearing": "11.1",
        "moneyness_percent": "1",
        "dwSymbol": "X5OW",
        "askPrice": "0.220000000",
        "tradeVolume": "2880000.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.16",
        "tradeVolume_f": "21,473",
        "exercisePrice": "30,400.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "15.151515152",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.190000000",
        "HIGHRESP": true,
        "dwps": "1,200",
        "moneyness_c": "2% ITM",
        "priceChange_f": "+15.2",
        "impliedVolalitiy": "24.4",
        "moneyness": "ITM",
        "sensitivity": "11.61",
        "askPrice_f": "0.191",
        "delta": "60.4",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.190",
        "issuer": "MBL",
        "ric": "HSML_t212.SI",
        "avg_decay": "0.00169",
        "effectiveGearing": "14.0",
        "HOTLIST": true,
        "moneyness_percent": "2",
        "dwSymbol": "HGKW",
        "askPrice": "0.191000000",
        "tradeVolume": "21472500.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.08",
        "tradeVolume_f": "0",
        "exercisePrice": "30,000.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "12.244897959",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.275000000",
        "dwps": "1,200",
        "moneyness_c": "3% ITM",
        "priceChange_f": "+12.2",
        "impliedVolalitiy": "25.4",
        "moneyness": "ITM",
        "sensitivity": "55.49",
        "askPrice_f": "0.280",
        "delta": "63.2",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.275",
        "issuer": "MBL",
        "ric": "HSML_t203.SI",
        "avg_decay": "0.00143",
        "effectiveGearing": "10.2",
        "moneyness_percent": "3",
        "dwSymbol": "XLDW",
        "askPrice": "0.280000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.10",
        "tradeVolume_f": "0",
        "exercisePrice": "29,600.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "12.765957447",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.265000000",
        "dwps": "1,200",
        "moneyness_c": "5% ITM",
        "priceChange_f": "+12.8",
        "impliedVolalitiy": "24.7",
        "moneyness": "ITM",
        "sensitivity": "48.72",
        "askPrice_f": "0.275",
        "delta": "71.9",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.265",
        "issuer": "MBL",
        "ric": "HSML_t215.SI",
        "avg_decay": "0.00151",
        "effectiveGearing": "12.0",
        "moneyness_percent": "5",
        "dwSymbol": "5N8W",
        "askPrice": "0.275000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "300000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.06",
        "tradeVolume_f": "0",
        "exercisePrice": "29,200.00",
        "bidVolume_f": "300",
        "askVolume_f": "300",
        "priceChange": "8.955223881",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.365000000",
        "dwps": "1,200",
        "moneyness_c": "6% ITM",
        "priceChange_f": "+9.0",
        "impliedVolalitiy": "28.0",
        "moneyness": "ITM",
        "sensitivity": "49.85",
        "askPrice_f": "0.370",
        "delta": "70.3",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.365",
        "issuer": "MBL",
        "ric": "HSML_t202.SI",
        "avg_decay": "0.00134",
        "effectiveGearing": "8.5",
        "moneyness_percent": "6",
        "dwSymbol": "DZBW",
        "askPrice": "0.370000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "300000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.06",
        "tradeVolume_f": "0",
        "exercisePrice": "28,800.00",
        "bidVolume_f": "300",
        "askVolume_f": "300",
        "priceChange": "11.111111111",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.350000000",
        "dwps": "1,200",
        "moneyness_c": "8% ITM",
        "priceChange_f": "+11.1",
        "impliedVolalitiy": "24.7",
        "moneyness": "ITM",
        "sensitivity": "42.81",
        "askPrice_f": "0.360",
        "delta": "81.9",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.350",
        "issuer": "MBL",
        "ric": "HSML_t198.SI",
        "avg_decay": "0.00100",
        "effectiveGearing": "10.3",
        "moneyness_percent": "8",
        "dwSymbol": "QHEW",
        "askPrice": "0.360000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.24",
        "tradeVolume_f": "13,494",
        "exercisePrice": "28,600.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "-13.592233010",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.089000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "8% OTM",
        "priceChange_f": "-13.6",
        "impliedVolalitiy": "29.5",
        "moneyness": "OTM",
        "sensitivity": "28.19",
        "askPrice_f": "0.090",
        "delta": "-24.9",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.089",
        "issuer": "MBL",
        "ric": "HSML_t206.SI",
        "avg_decay": "0.00122",
        "effectiveGearing": "12.4",
        "moneyness_percent": "8",
        "dwSymbol": "CVCW",
        "askPrice": "0.090000000",
        "tradeVolume": "13493900.000000000"
        },
        {
        "askVolume": "300000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.04",
        "tradeVolume_f": "0",
        "exercisePrice": "28,400.00",
        "bidVolume_f": "300",
        "askVolume_f": "300",
        "priceChange": "8.750000000",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.435000000",
        "dwps": "1,200",
        "moneyness_c": "9% ITM",
        "priceChange_f": "+8.8",
        "impliedVolalitiy": "26.3",
        "moneyness": "ITM",
        "sensitivity": "44.50",
        "askPrice_f": "0.440",
        "delta": "78.8",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.435",
        "issuer": "MBL",
        "ric": "HSML_t201.SI",
        "avg_decay": "0.00092",
        "effectiveGearing": "8.0",
        "moneyness_percent": "9",
        "dwSymbol": "SGAW",
        "askPrice": "0.440000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "530000.000000000",
        "bidVolume": "3100000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.60",
        "tradeVolume_f": "5,095",
        "exercisePrice": "28,200.00",
        "bidVolume_f": "3,100",
        "askVolume_f": "530",
        "HIGHLQDY": true,
        "priceChange": "-21.428571429",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.033000000",
        "dwps": "1,200",
        "moneyness_c": "10% OTM",
        "priceChange_f": "-21.4",
        "impliedVolalitiy": "28.2",
        "moneyness": "OTM",
        "sensitivity": "46.64",
        "askPrice_f": "0.035",
        "delta": "-15.0",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.033",
        "issuer": "MBL",
        "ric": "HSML_t213.SI",
        "avg_decay": "0.00117",
        "effectiveGearing": "20.1",
        "moneyness_percent": "10",
        "dwSymbol": "9PCW",
        "askPrice": "0.035000000",
        "tradeVolume": "5095000.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.04",
        "tradeVolume_f": "160",
        "exercisePrice": "28,200.00",
        "bidVolume_f": "100",
        "askVolume_f": "10",
        "priceChange": "12.676056338",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.400000000",
        "dwps": "1,200",
        "moneyness_c": "10% ITM",
        "priceChange_f": "+12.7",
        "impliedVolalitiy": "33.2",
        "moneyness": "ITM",
        "sensitivity": "36.05",
        "askPrice_f": "0.410",
        "delta": "97.2",
        "APPCHEXPRY": true,
        "underlying_name": "HSI",
        "ltDate": "18 Feb 21",
        "bidPrice_f": "0.400",
        "issuer": "MBL",
        "ric": "HSML_t190.SI",
        "avg_decay": "0.00006",
        "effectiveGearing": "10.7",
        "moneyness_percent": "10",
        "dwSymbol": "8Y4W",
        "askPrice": "0.410000000",
        "tradeVolume": "160000.000000000"
        },
        {
        "askVolume": "300000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.17",
        "tradeVolume_f": "100",
        "exercisePrice": "28,000.00",
        "bidVolume_f": "300",
        "askVolume_f": "300",
        "priceChange": "-10.256410256",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.105000000",
        "dwps": "1,200",
        "moneyness_c": "11% OTM",
        "priceChange_f": "-10.3",
        "impliedVolalitiy": "30.6",
        "moneyness": "OTM",
        "sensitivity": "29.50",
        "askPrice_f": "0.107",
        "delta": "-23.8",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "20 May 21",
        "bidPrice_f": "0.105",
        "issuer": "MBL",
        "ric": "HSML_t207.SI",
        "avg_decay": "0.00102",
        "effectiveGearing": "10.0",
        "moneyness_percent": "11",
        "dwSymbol": "1HZW",
        "askPrice": "0.107000000",
        "tradeVolume": "100000.000000000"
        },
        {
        "askVolume": "300000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.03",
        "tradeVolume_f": "0",
        "exercisePrice": "28,000.00",
        "bidVolume_f": "300",
        "askVolume_f": "300",
        "priceChange": "9.756097561",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.450000000",
        "dwps": "1,200",
        "moneyness_c": "11% ITM",
        "priceChange_f": "+9.8",
        "impliedVolalitiy": "24.5",
        "moneyness": "ITM",
        "sensitivity": "39.10",
        "askPrice_f": "0.465",
        "delta": "89.6",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.450",
        "issuer": "MBL",
        "ric": "HSML_t194.SI",
        "avg_decay": "0.00148",
        "effectiveGearing": "8.8",
        "moneyness_percent": "11",
        "dwSymbol": "OMCW",
        "askPrice": "0.465000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.03",
        "tradeVolume_f": "0",
        "exercisePrice": "27,600.00",
        "bidVolume_f": "100",
        "askVolume_f": "10",
        "priceChange": "8.163265306",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.530000000",
        "dwps": "1,200",
        "moneyness_c": "12% ITM",
        "priceChange_f": "+8.2",
        "impliedVolalitiy": "27.4",
        "moneyness": "ITM",
        "sensitivity": "41.60",
        "askPrice_f": "0.545",
        "delta": "84.2",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.530",
        "issuer": "MBL",
        "ric": "HSML_t200.SI",
        "avg_decay": "0.00069",
        "effectiveGearing": "7.0",
        "moneyness_percent": "12",
        "dwSymbol": "GJDW",
        "askPrice": "0.545000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "510000.000000000",
        "bidVolume": "651000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.66",
        "tradeVolume_f": "5,832",
        "exercisePrice": "27,400.00",
        "bidVolume_f": "651",
        "askVolume_f": "510",
        "priceChange": "-23.076923077",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.030000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "13% OTM",
        "priceChange_f": "-23.1",
        "impliedVolalitiy": "32.9",
        "moneyness": "OTM",
        "sensitivity": "57.22",
        "askPrice_f": "0.031",
        "delta": "-12.3",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.030",
        "issuer": "MBL",
        "ric": "HSML_t208.SI",
        "avg_decay": "0.00114",
        "effectiveGearing": "18.1",
        "HOTLIST": true,
        "moneyness_percent": "13",
        "dwSymbol": "EEKW",
        "askPrice": "0.031000000",
        "tradeVolume": "5832000.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "10000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "27,400.00",
        "bidVolume_f": "10",
        "askVolume_f": "10",
        "priceChange": "9.574468085",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.515000000",
        "dwps": "1,200",
        "moneyness_c": "13% ITM",
        "priceChange_f": "+9.6",
        "impliedVolalitiy": "10.0",
        "moneyness": "ITM",
        "sensitivity": "35.06",
        "askPrice_f": "0.530",
        "delta": "100.0",
        "APPCHEXPRY": true,
        "underlying_name": "HSI",
        "ltDate": "18 Feb 21",
        "bidPrice_f": "0.515",
        "issuer": "MBL",
        "ric": "HSML_t192.SI",
        "avg_decay": "0.00024",
        "effectiveGearing": "8.6",
        "moneyness_percent": "13",
        "dwSymbol": "FCHW",
        "askPrice": "0.530000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "10000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.02",
        "tradeVolume_f": "0",
        "exercisePrice": "27,200.00",
        "bidVolume_f": "10",
        "askVolume_f": "10",
        "priceChange": "8.737864078",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.560000000",
        "dwps": "1,200",
        "moneyness_c": "14% ITM",
        "priceChange_f": "+8.7",
        "impliedVolalitiy": "27.7",
        "moneyness": "ITM",
        "sensitivity": "37.94",
        "askPrice_f": "0.570",
        "delta": "92.4",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.560",
        "issuer": "MBL",
        "ric": "HSML_t195.SI",
        "avg_decay": "0.00136",
        "effectiveGearing": "7.3",
        "moneyness_percent": "14",
        "dwSymbol": "W6JW",
        "askPrice": "0.570000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1001000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.33",
        "tradeVolume_f": "5,766",
        "exercisePrice": "27,000.00",
        "bidVolume_f": "1,001",
        "askVolume_f": "1,000",
        "priceChange": "-13.333333333",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.052000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "15% OTM",
        "priceChange_f": "-13.3",
        "impliedVolalitiy": "32.5",
        "moneyness": "OTM",
        "sensitivity": "46.36",
        "askPrice_f": "0.053",
        "delta": "-15.1",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.052",
        "issuer": "MBL",
        "ric": "HSML_t199.SI",
        "avg_decay": "0.00104",
        "effectiveGearing": "12.9",
        "moneyness_percent": "15",
        "dwSymbol": "8XWW",
        "askPrice": "0.053000000",
        "tradeVolume": "5766000.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "10000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "26,600.00",
        "bidVolume_f": "10",
        "askVolume_f": "10",
        "priceChange": "8.620689655",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.630000000",
        "dwps": "1,200",
        "moneyness_c": "17% ITM",
        "priceChange_f": "+8.6",
        "impliedVolalitiy": "10.0",
        "moneyness": "ITM",
        "sensitivity": "35.06",
        "askPrice_f": "0.635",
        "delta": "100.0",
        "APPCHEXPRY": true,
        "underlying_name": "HSI",
        "ltDate": "18 Feb 21",
        "bidPrice_f": "0.630",
        "issuer": "MBL",
        "ric": "HSML_t193.SI",
        "avg_decay": "0.00042",
        "effectiveGearing": "7.0",
        "moneyness_percent": "17",
        "dwSymbol": "UCEW",
        "askPrice": "0.635000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "10000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.01",
        "tradeVolume_f": "0",
        "exercisePrice": "26,600.00",
        "bidVolume_f": "10",
        "askVolume_f": "10",
        "priceChange": "6.400000000",
        "type": "C",
        "conv_ratio": "1,200",
        "bidPrice": "0.665000000",
        "dwps": "1,200",
        "moneyness_c": "17% ITM",
        "priceChange_f": "+6.4",
        "impliedVolalitiy": "25.2",
        "moneyness": "ITM",
        "sensitivity": "39.57",
        "askPrice_f": "0.670",
        "delta": "88.6",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "20 May 21",
        "bidPrice_f": "0.665",
        "issuer": "MBL",
        "ric": "HSML_t209.SI",
        "avg_decay": "0.00128",
        "effectiveGearing": "5.9",
        "moneyness_percent": "17",
        "dwSymbol": "OKCW",
        "askPrice": "0.670000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.99",
        "tradeVolume_f": "200",
        "exercisePrice": "26,600.00",
        "bidVolume_f": "500",
        "askVolume_f": "100",
        "priceChange": "-33.333333333",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.008000000",
        "dwps": "1,200",
        "moneyness_c": "17% OTM",
        "priceChange_f": "-33.3",
        "impliedVolalitiy": "28.3",
        "moneyness": "OTM",
        "sensitivity": "141.21",
        "askPrice_f": "0.013",
        "delta": "-5.0",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.008",
        "issuer": "MBL",
        "ric": "HSML_t196.SI",
        "avg_decay": "0.00035",
        "effectiveGearing": "27.4",
        "moneyness_percent": "17",
        "dwSymbol": "NMKW",
        "askPrice": "0.013000000",
        "tradeVolume": "200000.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.24",
        "tradeVolume_f": "5,600",
        "exercisePrice": "26,400.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-11.764705882",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.060000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "17% OTM",
        "priceChange_f": "-11.8",
        "impliedVolalitiy": "31.9",
        "moneyness": "OTM",
        "sensitivity": "47.26",
        "askPrice_f": "0.061",
        "delta": "-14.8",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "20 May 21",
        "bidPrice_f": "0.060",
        "issuer": "MBL",
        "ric": "HSML_t214.SI",
        "avg_decay": "0.00085",
        "effectiveGearing": "10.9",
        "moneyness_percent": "17",
        "dwSymbol": "EGAW",
        "askPrice": "0.061000000",
        "tradeVolume": "5600000.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.40",
        "tradeVolume_f": "5",
        "exercisePrice": "26,200.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-14.634146341",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.035000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "18% OTM",
        "priceChange_f": "-14.6",
        "impliedVolalitiy": "32.8",
        "moneyness": "OTM",
        "sensitivity": "63.97",
        "askPrice_f": "0.036",
        "delta": "-11.0",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.035",
        "issuer": "MBL",
        "ric": "HSML_t205.SI",
        "avg_decay": "0.00078",
        "effectiveGearing": "13.8",
        "moneyness_percent": "18",
        "dwSymbol": "TNSW",
        "askPrice": "0.036000000",
        "tradeVolume": "5000.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-8.45",
        "tradeVolume_f": "0",
        "exercisePrice": "26,000.00",
        "bidVolume_f": "0",
        "askVolume_f": "10",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.001000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "19% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "50.5",
        "moneyness": "OTM",
        "sensitivity": "791.67",
        "askPrice_f": "0.002",
        "delta": "-0.9",
        "APPCHEXPRY": true,
        "underlying_name": "HSI",
        "ltDate": "18 Feb 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "HSML_t191.SI",
        "avg_decay": "0.00020",
        "effectiveGearing": "39.2",
        "moneyness_percent": "19",
        "dwSymbol": "JXBW",
        "askPrice": "0.002000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.94",
        "tradeVolume_f": "30",
        "exercisePrice": "25,800.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-18.750000000",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.013000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "20% OTM",
        "priceChange_f": "-18.8",
        "impliedVolalitiy": "35.5",
        "moneyness": "OTM",
        "sensitivity": "126.12",
        "askPrice_f": "0.014",
        "delta": "-5.6",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "23 Mar 21",
        "bidPrice_f": "0.013",
        "issuer": "MBL",
        "ric": "HSML_t197.SI",
        "avg_decay": "0.00075",
        "effectiveGearing": "18.9",
        "moneyness_percent": "20",
        "dwSymbol": "KWBW",
        "askPrice": "0.014000000",
        "tradeVolume": "30000.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.45",
        "tradeVolume_f": "0",
        "exercisePrice": "25,400.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-16.129032258",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.026000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "22% OTM",
        "priceChange_f": "-16.1",
        "impliedVolalitiy": "34.4",
        "moneyness": "OTM",
        "sensitivity": "84.45",
        "askPrice_f": "0.027",
        "delta": "-8.3",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "22 Apr 21",
        "bidPrice_f": "0.026",
        "issuer": "MBL",
        "ric": "HSML_t204.SI",
        "avg_decay": "0.00061",
        "effectiveGearing": "14.1",
        "moneyness_percent": "22",
        "dwSymbol": "FMPW",
        "askPrice": "0.027000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-8.74",
        "tradeVolume_f": "0",
        "exercisePrice": "25,000.00",
        "bidVolume_f": "0",
        "askVolume_f": "10",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.001000000",
        "dwps": "1,200",
        "moneyness_c": "24% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "60.4",
        "moneyness": "OTM",
        "sensitivity": "935.67",
        "askPrice_f": "0.005",
        "delta": "-0.7",
        "APPCHEXPRY": true,
        "underlying_name": "HSI",
        "ltDate": "18 Feb 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "HSML_t189.SI",
        "avg_decay": "0.00020",
        "effectiveGearing": "33.1",
        "moneyness_percent": "24",
        "dwSymbol": "VOIW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "HSIc1",
        "underlyingSymbol": "HSI",
        "theta": "-0.29",
        "tradeVolume_f": "0",
        "exercisePrice": "24,800.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-10.869565217",
        "type": "P",
        "conv_ratio": "1,200",
        "bidPrice": "0.041000000",
        "HIGHRESP": false,
        "dwps": "1,200",
        "moneyness_c": "25% OTM",
        "priceChange_f": "-10.9",
        "impliedVolalitiy": "35.7",
        "moneyness": "OTM",
        "sensitivity": "70.57",
        "askPrice_f": "0.042",
        "delta": "-9.9",
        "APPCHEXPRY": false,
        "underlying_name": "HSI",
        "ltDate": "20 May 21",
        "bidPrice_f": "0.041",
        "issuer": "MBL",
        "ric": "HSML_t210.SI",
        "avg_decay": "0.00064",
        "effectiveGearing": "10.7",
        "moneyness_percent": "25",
        "dwSymbol": "TPCW",
        "askPrice": "0.042000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "4000.000000000",
        "bidVolume": "40000.000000000",
        "underlying_ric": "IFAS.SI",
        "underlyingSymbol": "AIY",
        "theta": "-0.21",
        "tradeVolume_f": "0",
        "exercisePrice": "4.20",
        "bidVolume_f": "40",
        "askVolume_f": "4",
        "priceChange": "-1.052631579",
        "type": "C",
        "conv_ratio": "5.5",
        "bidPrice": "0.470000000",
        "dwps": "5.5",
        "moneyness_c": "46% ITM",
        "priceChange_f": "-1.1",
        "impliedVolalitiy": "129.8",
        "moneyness": "ITM",
        "sensitivity": "3.38",
        "askPrice_f": "0.485",
        "delta": "81.4",
        "APPCHEXPRY": false,
        "underlying_name": "IFAST CORPORATIO",
        "ltDate": "24 May 21",
        "bidPrice_f": "0.470",
        "issuer": "MBL",
        "ric": "IFML_tu.SI",
        "avg_decay": "0.00159",
        "effectiveGearing": "1.9",
        "moneyness_percent": "46",
        "dwSymbol": "KTRW",
        "askPrice": "0.485000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2200200.000000000",
        "bidVolume": "900000.000000000",
        "underlying_ric": "9618.HK",
        "underlyingSymbol": "JD-SW",
        "theta": "-0.05",
        "tradeVolume_f": "17",
        "exercisePrice": "400.00",
        "bidVolume_f": "900",
        "askVolume_f": "2,200",
        "HIGHLQDY": true,
        "priceChange": "32.432432432",
        "type": "C",
        "conv_ratio": "45",
        "bidPrice": "0.245000000",
        "dwps": "45",
        "moneyness_c": "5% ITM",
        "priceChange_f": "+32.4",
        "impliedVolalitiy": "53.3",
        "moneyness": "ITM",
        "sensitivity": "36.26",
        "askPrice_f": "0.250",
        "delta": "62.0",
        "APPCHEXPRY": false,
        "underlying_name": "JD-SW",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.245",
        "issuer": "MBL",
        "ric": "JDML_tb.SI",
        "avg_decay": "0.00119",
        "effectiveGearing": "4.0",
        "HOTLIST": true,
        "moneyness_percent": "5",
        "dwSymbol": "OPSW",
        "askPrice": "0.250000000",
        "tradeVolume": "17100.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "9618.HK",
        "underlyingSymbol": "JD-SW",
        "theta": "-0.20",
        "tradeVolume_f": "0",
        "exercisePrice": "280.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-29.268292683",
        "type": "P",
        "conv_ratio": "45",
        "bidPrice": "0.029000000",
        "dwps": "45",
        "moneyness_c": "49% OTM",
        "priceChange_f": "-29.3",
        "impliedVolalitiy": "58.3",
        "moneyness": "OTM",
        "sensitivity": "46.87",
        "askPrice_f": "0.031",
        "delta": "-9.6",
        "APPCHEXPRY": false,
        "underlying_name": "JD-SW",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.029",
        "issuer": "MBL",
        "ric": "JDML_tc.SI",
        "avg_decay": "0.00023",
        "effectiveGearing": "5.3",
        "HOTLIST": true,
        "moneyness_percent": "49",
        "dwSymbol": "DVDW",
        "askPrice": "0.031000000",
        "tradeVolume": "100.000000000"
        },
        {
        "askVolume": "0.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "KPLM.SI",
        "underlyingSymbol": "BN4",
        "theta": "-70.71",
        "tradeVolume_f": "0",
        "exercisePrice": "6.50",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "priceChange": "-300.000000000",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.000000000",
        "dwps": "3.5",
        "moneyness_c": "22% OTM",
        "priceChange_f": "-300.0",
        "impliedVolalitiy": "95.9",
        "moneyness": "OTM",
        "sensitivity": "19.94",
        "askPrice_f": "0.000",
        "delta": "1.8",
        "APPCHEXPRY": true,
        "underlying_name": "KEPCORP",
        "ltDate": "15 Feb 21",
        "bidPrice_f": "0.000",
        "issuer": "MBL",
        "ric": "KPML_t8.SI",
        "avg_decay": "0.00000",
        "effectiveGearing": "25.6",
        "moneyness_percent": "22",
        "dwSymbol": "SJOW",
        "askPrice": "0.000000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2890000.000000000",
        "bidVolume": "2030000.000000000",
        "underlying_ric": "KPLM.SI",
        "underlyingSymbol": "BN4",
        "theta": "-0.28",
        "tradeVolume_f": "0",
        "exercisePrice": "5.70",
        "bidVolume_f": "2,030",
        "askVolume_f": "2,890",
        "HIGHLQDY": true,
        "priceChange": "-7.407407407",
        "type": "C",
        "conv_ratio": "8.5",
        "bidPrice": "0.050000000",
        "dwps": "8.5",
        "moneyness_c": "11% OTM",
        "priceChange_f": "-7.4",
        "impliedVolalitiy": "41.2",
        "moneyness": "OTM",
        "sensitivity": "2.08",
        "askPrice_f": "0.052",
        "delta": "40.9",
        "APPCHEXPRY": false,
        "underlying_name": "KEPCORP",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.050",
        "issuer": "MBL",
        "ric": "KPML_t10.SI",
        "avg_decay": "0.00014",
        "effectiveGearing": "4.9",
        "HOTLIST": true,
        "moneyness_percent": "11",
        "dwSymbol": "WFEW",
        "askPrice": "0.052000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2670000.000000000",
        "bidVolume": "1870000.000000000",
        "underlying_ric": "KPLM.SI",
        "underlyingSymbol": "BN4",
        "theta": "-7.89",
        "tradeVolume_f": "0",
        "exercisePrice": "5.40",
        "bidVolume_f": "1,870",
        "askVolume_f": "2,670",
        "priceChange": "-35.000000000",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.013000000",
        "dwps": "3.5",
        "moneyness_c": "6% OTM",
        "priceChange_f": "-35.0",
        "impliedVolalitiy": "35.7",
        "moneyness": "OTM",
        "sensitivity": "1.58",
        "askPrice_f": "0.015",
        "delta": "22.2",
        "APPCHEXPRY": true,
        "underlying_name": "KEPCORP",
        "ltDate": "25 Feb 21",
        "bidPrice_f": "0.013",
        "issuer": "MBL",
        "ric": "KPML_t9.SI",
        "avg_decay": "0.00106",
        "effectiveGearing": "24.9",
        "moneyness_percent": "6",
        "dwSymbol": "NMBW",
        "askPrice": "0.015000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "MACT.SI",
        "underlyingSymbol": "N2IU",
        "theta": "-20.08",
        "tradeVolume_f": "0",
        "exercisePrice": "2.40",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.001000000",
        "dwps": "3.5",
        "moneyness_c": "15% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "49.9",
        "moneyness": "OTM",
        "sensitivity": "7.66",
        "askPrice_f": "0.005",
        "delta": "4.6",
        "APPCHEXPRY": true,
        "underlying_name": "MAPLETREE COMMER",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "MCML_ta.SI",
        "avg_decay": "0.00016",
        "effectiveGearing": "26.6",
        "moneyness_percent": "15",
        "dwSymbol": "9EFW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "MACT.SI",
        "underlyingSymbol": "N2IU",
        "theta": "-0.25",
        "tradeVolume_f": "0",
        "exercisePrice": "2.20",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "3.030303030",
        "type": "C",
        "conv_ratio": "5.5",
        "bidPrice": "0.034000000",
        "dwps": "5.5",
        "moneyness_c": "7% OTM",
        "priceChange_f": "+3.0",
        "impliedVolalitiy": "40.5",
        "moneyness": "OTM",
        "sensitivity": "1.25",
        "askPrice_f": "0.036",
        "delta": "44.1",
        "APPCHEXPRY": false,
        "underlying_name": "MAPLETREE COMMER",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.034",
        "issuer": "MBL",
        "ric": "MAML_t.SI",
        "avg_decay": "0.00009",
        "effectiveGearing": "4.8",
        "HOTLIST": true,
        "moneyness_percent": "7",
        "dwSymbol": "TRGW",
        "askPrice": "0.036000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "3690.HK",
        "underlyingSymbol": "MEITUAN-W",
        "theta": "-0.04",
        "tradeVolume_f": "0",
        "exercisePrice": "400.00",
        "bidVolume_f": "300",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "6.451612903",
        "type": "C",
        "conv_ratio": "45",
        "bidPrice": "0.330000000",
        "dwps": "45",
        "moneyness_c": "12% ITM",
        "priceChange_f": "+6.5",
        "impliedVolalitiy": "58.0",
        "moneyness": "ITM",
        "sensitivity": "32.76",
        "askPrice_f": "0.335",
        "delta": "68.7",
        "APPCHEXPRY": false,
        "underlying_name": "MEITUAN-W",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.330",
        "issuer": "MBL",
        "ric": "MEML_td.SI",
        "avg_decay": "0.00076",
        "effectiveGearing": "3.5",
        "HOTLIST": true,
        "moneyness_percent": "12",
        "dwSymbol": "YC8W",
        "askPrice": "0.335000000",
        "tradeVolume": "200.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "3690.HK",
        "underlyingSymbol": "MEITUAN-W",
        "theta": "-0.01",
        "tradeVolume_f": "0",
        "exercisePrice": "300.00",
        "bidVolume_f": "100",
        "askVolume_f": "1,000",
        "priceChange": "5.109489051",
        "type": "C",
        "conv_ratio": "35",
        "bidPrice": "0.720000000",
        "dwps": "35",
        "moneyness_c": "49% ITM",
        "priceChange_f": "+5.1",
        "impliedVolalitiy": "54.3",
        "moneyness": "ITM",
        "sensitivity": "17.80",
        "askPrice_f": "0.730",
        "delta": "98.3",
        "APPCHEXPRY": false,
        "underlying_name": "MEITUAN-W",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.720",
        "issuer": "MBL",
        "ric": "MEML_tb.SI",
        "avg_decay": "0.00031",
        "effectiveGearing": "3.0",
        "moneyness_percent": "49",
        "dwSymbol": "JAOW",
        "askPrice": "0.730000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "3690.HK",
        "underlyingSymbol": "MEITUAN-W",
        "theta": "-0.28",
        "tradeVolume_f": "50",
        "exercisePrice": "260.00",
        "bidVolume_f": "500",
        "askVolume_f": "100",
        "HIGHLQDY": true,
        "priceChange": "-7.692307692",
        "type": "P",
        "conv_ratio": "45",
        "bidPrice": "0.012000000",
        "HIGHRESP": false,
        "dwps": "45",
        "moneyness_c": "72% OTM",
        "priceChange_f": "-7.7",
        "impliedVolalitiy": "57.1",
        "moneyness": "OTM",
        "sensitivity": "104.19",
        "askPrice_f": "0.013",
        "delta": "-4.3",
        "APPCHEXPRY": false,
        "underlying_name": "MEITUAN-W",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.012",
        "issuer": "MBL",
        "ric": "MEML_tc.SI",
        "avg_decay": "0.00025",
        "effectiveGearing": "6.1",
        "HOTLIST": true,
        "moneyness_percent": "72",
        "dwSymbol": "MXTW",
        "askPrice": "0.013000000",
        "tradeVolume": "50000.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "3690.HK",
        "underlyingSymbol": "MEITUAN-W",
        "theta": "-1.56",
        "tradeVolume_f": "0",
        "exercisePrice": "200.00",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "35",
        "bidPrice": "0.001000000",
        "dwps": "35",
        "moneyness_c": "123% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "89.9",
        "moneyness": "OTM",
        "sensitivity": "840.47",
        "askPrice_f": "0.005",
        "delta": "-0.4",
        "APPCHEXPRY": false,
        "underlying_name": "MEITUAN-W",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "MEML_ta.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "9.1",
        "moneyness_percent": "123",
        "dwSymbol": "YLAW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.01",
        "tradeVolume_f": "78",
        "exercisePrice": "31,000.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-2.083333333",
        "type": "C",
        "conv_ratio": "80",
        "bidPrice": "0.235000000",
        "dwps": "80",
        "moneyness_c": "2% OTM",
        "priceChange_f": "-2.1",
        "impliedVolalitiy": "24.9",
        "moneyness": "OTM",
        "sensitivity": "67.04",
        "askPrice_f": "0.240",
        "delta": "47.7",
        "APPCHEXPRY": false,
        "underlying_name": "N225",
        "ltDate": "04 Jun 21",
        "bidPrice_f": "0.235",
        "issuer": "MBL",
        "ric": "NIML_t26.SI",
        "avg_decay": "0.00190",
        "effectiveGearing": "9.6",
        "moneyness_percent": "2",
        "dwSymbol": "LXLW",
        "askPrice": "0.240000000",
        "tradeVolume": "78000.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.02",
        "tradeVolume_f": "0",
        "exercisePrice": "30,000.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-5.084745763",
        "type": "C",
        "conv_ratio": "80",
        "bidPrice": "0.168000000",
        "HIGHRESP": false,
        "dwps": "80",
        "moneyness_c": "1% ITM",
        "priceChange_f": "-5.1",
        "impliedVolalitiy": "27.7",
        "moneyness": "ITM",
        "sensitivity": "11.06",
        "askPrice_f": "0.169",
        "delta": "57.8",
        "APPCHEXPRY": true,
        "underlying_name": "N225",
        "ltDate": "05 Mar 21",
        "bidPrice_f": "0.168",
        "issuer": "MBL",
        "ric": "NIML_t18.SI",
        "avg_decay": "0.00321",
        "effectiveGearing": "16.4",
        "HOTLIST": true,
        "moneyness_percent": "1",
        "dwSymbol": "YTXW",
        "askPrice": "0.169000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "550000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.01",
        "tradeVolume_f": "180",
        "exercisePrice": "29,000.00",
        "bidVolume_f": "550",
        "askVolume_f": "500",
        "priceChange": "2.500000000",
        "type": "P",
        "conv_ratio": "80",
        "bidPrice": "0.205000000",
        "dwps": "80",
        "moneyness_c": "5% OTM",
        "priceChange_f": "+2.5",
        "impliedVolalitiy": "30.1",
        "moneyness": "OTM",
        "sensitivity": "90.30",
        "askPrice_f": "0.210",
        "delta": "-35.4",
        "APPCHEXPRY": false,
        "underlying_name": "N225",
        "ltDate": "04 Jun 21",
        "bidPrice_f": "0.205",
        "issuer": "MBL",
        "ric": "NIML_t25.SI",
        "avg_decay": "0.00122",
        "effectiveGearing": "8.2",
        "moneyness_percent": "5",
        "dwSymbol": "9AFW",
        "askPrice": "0.210000000",
        "tradeVolume": "180000.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.00",
        "tradeVolume_f": "10",
        "exercisePrice": "28,000.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-1.980198020",
        "type": "C",
        "conv_ratio": "80",
        "bidPrice": "0.495000000",
        "dwps": "80",
        "moneyness_c": "9% ITM",
        "priceChange_f": "-2.0",
        "impliedVolalitiy": "24.6",
        "moneyness": "ITM",
        "sensitivity": "42.51",
        "askPrice_f": "0.500",
        "delta": "75.2",
        "APPCHEXPRY": false,
        "underlying_name": "N225",
        "ltDate": "04 Jun 21",
        "bidPrice_f": "0.495",
        "issuer": "MBL",
        "ric": "NIML_t24.SI",
        "avg_decay": "0.00089",
        "effectiveGearing": "7.2",
        "moneyness_percent": "9",
        "dwSymbol": "TCFW",
        "askPrice": "0.500000000",
        "tradeVolume": "10000.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "27,000.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-2.678571429",
        "type": "C",
        "conv_ratio": "80",
        "bidPrice": "0.545000000",
        "dwps": "80",
        "moneyness_c": "13% ITM",
        "priceChange_f": "-2.7",
        "impliedVolalitiy": "29.5",
        "moneyness": "ITM",
        "sensitivity": "33.76",
        "askPrice_f": "0.565",
        "delta": "94.7",
        "APPCHEXPRY": true,
        "underlying_name": "N225",
        "ltDate": "05 Mar 21",
        "bidPrice_f": "0.545",
        "issuer": "MBL",
        "ric": "NIML_t17.SI",
        "avg_decay": "0.00120",
        "effectiveGearing": "8.3",
        "moneyness_percent": "13",
        "dwSymbol": "FIQW",
        "askPrice": "0.565000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.01",
        "tradeVolume_f": "150",
        "exercisePrice": "26,000.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "1.250000000",
        "type": "P",
        "conv_ratio": "80",
        "bidPrice": "0.081000000",
        "HIGHRESP": false,
        "dwps": "80",
        "moneyness_c": "17% OTM",
        "priceChange_f": "+1.3",
        "impliedVolalitiy": "32.5",
        "moneyness": "OTM",
        "sensitivity": "38.02",
        "askPrice_f": "0.082",
        "delta": "-16.8",
        "APPCHEXPRY": false,
        "underlying_name": "N225",
        "ltDate": "04 Jun 21",
        "bidPrice_f": "0.081",
        "issuer": "MBL",
        "ric": "NIML_t23.SI",
        "avg_decay": "0.00077",
        "effectiveGearing": "9.9",
        "HOTLIST": true,
        "moneyness_percent": "17",
        "dwSymbol": "EOGW",
        "askPrice": "0.082000000",
        "tradeVolume": "150000.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "25,000.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-1.685393258",
        "type": "C",
        "conv_ratio": "80",
        "bidPrice": "0.875000000",
        "dwps": "80",
        "moneyness_c": "22% ITM",
        "priceChange_f": "-1.7",
        "impliedVolalitiy": "23.3",
        "moneyness": "ITM",
        "sensitivity": "33.90",
        "askPrice_f": "0.895",
        "delta": "94.3",
        "APPCHEXPRY": false,
        "underlying_name": "N225",
        "ltDate": "04 Jun 21",
        "bidPrice_f": "0.875",
        "issuer": "MBL",
        "ric": "NIML_t22.SI",
        "avg_decay": "-0.00020",
        "effectiveGearing": "5.1",
        "moneyness_percent": "22",
        "dwSymbol": "ZALW",
        "askPrice": "0.895000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.16",
        "tradeVolume_f": "0",
        "exercisePrice": "25,000.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "-20.000000000",
        "type": "P",
        "conv_ratio": "80",
        "bidPrice": "0.004000000",
        "dwps": "80",
        "moneyness_c": "22% OTM",
        "priceChange_f": "-20.0",
        "impliedVolalitiy": "40.8",
        "moneyness": "OTM",
        "sensitivity": "244.61",
        "askPrice_f": "0.006",
        "delta": "-2.6",
        "APPCHEXPRY": true,
        "underlying_name": "N225",
        "ltDate": "05 Mar 21",
        "bidPrice_f": "0.004",
        "issuer": "MBL",
        "ric": "NIML_t21.SI",
        "avg_decay": "0.00038",
        "effectiveGearing": "31.1",
        "moneyness_percent": "22",
        "dwSymbol": "VTWW",
        "askPrice": "0.006000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "24,000.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-0.985221675",
        "type": "C",
        "conv_ratio": "80",
        "bidPrice": "1.005000000",
        "dwps": "80",
        "moneyness_c": "27% ITM",
        "priceChange_f": "-1.0",
        "impliedVolalitiy": "10.0",
        "moneyness": "ITM",
        "sensitivity": "31.96",
        "askPrice_f": "1.020",
        "delta": "100.0",
        "APPCHEXPRY": true,
        "underlying_name": "N225",
        "ltDate": "05 Mar 21",
        "bidPrice_f": "1.005",
        "issuer": "MBL",
        "ric": "NIML_t16.SI",
        "avg_decay": "0.00019",
        "effectiveGearing": "4.7",
        "moneyness_percent": "27",
        "dwSymbol": "PJLW",
        "askPrice": "1.020000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.02",
        "tradeVolume_f": "0",
        "exercisePrice": "23,000.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "3.030303030",
        "type": "P",
        "conv_ratio": "80",
        "bidPrice": "0.034000000",
        "dwps": "80",
        "moneyness_c": "32% OTM",
        "priceChange_f": "+3.0",
        "impliedVolalitiy": "37.2",
        "moneyness": "OTM",
        "sensitivity": "87.44",
        "askPrice_f": "0.038",
        "delta": "-7.3",
        "APPCHEXPRY": false,
        "underlying_name": "N225",
        "ltDate": "04 Jun 21",
        "bidPrice_f": "0.034",
        "issuer": "MBL",
        "ric": "NIML_t28.SI",
        "avg_decay": "0.00057",
        "effectiveGearing": "10.2",
        "moneyness_percent": "32",
        "dwSymbol": "XDQW",
        "askPrice": "0.038000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.24",
        "tradeVolume_f": "0",
        "exercisePrice": "22,000.00",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "80",
        "bidPrice": "0.001000000",
        "dwps": "80",
        "moneyness_c": "38% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "51.0",
        "moneyness": "OTM",
        "sensitivity": "1,274.47",
        "askPrice_f": "0.005",
        "delta": "-0.5",
        "APPCHEXPRY": true,
        "underlying_name": "N225",
        "ltDate": "05 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "NIML_t19.SI",
        "avg_decay": "0.00014",
        "effectiveGearing": "23.9",
        "moneyness_percent": "38",
        "dwSymbol": "UAFW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.03",
        "tradeVolume_f": "0",
        "exercisePrice": "20,000.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "-4.761904762",
        "type": "P",
        "conv_ratio": "80",
        "bidPrice": "0.020000000",
        "dwps": "80",
        "moneyness_c": "52% OTM",
        "priceChange_f": "-4.8",
        "impliedVolalitiy": "46.0",
        "moneyness": "OTM",
        "sensitivity": "164.35",
        "askPrice_f": "0.031",
        "delta": "-3.9",
        "APPCHEXPRY": false,
        "underlying_name": "N225",
        "ltDate": "04 Jun 21",
        "bidPrice_f": "0.020",
        "issuer": "MBL",
        "ric": "NIML_t27.SI",
        "avg_decay": "0.00033",
        "effectiveGearing": "9.2",
        "moneyness_percent": "52",
        "dwSymbol": "HASW",
        "askPrice": "0.031000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "SSIcv1",
        "underlyingSymbol": "N225",
        "theta": "-0.25",
        "tradeVolume_f": "0",
        "exercisePrice": "19,000.00",
        "bidVolume_f": "0",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "80",
        "bidPrice": "0.001000000",
        "dwps": "80",
        "moneyness_c": "60% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "71.8",
        "moneyness": "OTM",
        "sensitivity": "1,767.72",
        "askPrice_f": "0.005",
        "delta": "-0.4",
        "APPCHEXPRY": true,
        "underlying_name": "N225",
        "ltDate": "05 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "NIML_t20.SI",
        "avg_decay": "0.00015",
        "effectiveGearing": "17.2",
        "moneyness_percent": "60",
        "dwSymbol": "WG8W",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1010000.000000000",
        "bidVolume": "540000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-1.06",
        "tradeVolume_f": "0",
        "exercisePrice": "11.50",
        "bidVolume_f": "540",
        "askVolume_f": "1,010",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.028000000",
        "HIGHRESP": false,
        "dwps": "10.0",
        "moneyness_c": "8% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "29.9",
        "moneyness": "OTM",
        "sensitivity": "3.27",
        "askPrice_f": "0.029",
        "delta": "30.6",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "06 May 21",
        "bidPrice_f": "0.028",
        "issuer": "MBL",
        "ric": "OCML_t22.SI",
        "avg_decay": "0.00024",
        "effectiveGearing": "11.6",
        "moneyness_percent": "8",
        "dwSymbol": "GWWW",
        "askPrice": "0.029000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "330000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.18",
        "tradeVolume_f": "1",
        "exercisePrice": "10.80",
        "bidVolume_f": "330",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "12.0",
        "bidPrice": "0.079000000",
        "HIGHRESP": false,
        "dwps": "12.0",
        "moneyness_c": "2% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "30.4",
        "moneyness": "OTM",
        "sensitivity": "2.41",
        "askPrice_f": "0.080",
        "delta": "49.7",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "05 Nov 21",
        "bidPrice_f": "0.079",
        "issuer": "MBL",
        "ric": "OCML_t25.SI",
        "avg_decay": "0.00006",
        "effectiveGearing": "5.6",
        "HOTLIST": true,
        "moneyness_percent": "2",
        "dwSymbol": "WZ1W",
        "askPrice": "0.080000000",
        "tradeVolume": "1200.000000000"
        },
        {
        "askVolume": "400000.000000000",
        "bidVolume": "210000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.52",
        "tradeVolume_f": "52",
        "exercisePrice": "10.50",
        "bidVolume_f": "210",
        "askVolume_f": "400",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.064000000",
        "HIGHRESP": false,
        "dwps": "10.0",
        "moneyness_c": "1% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "29.6",
        "moneyness": "ITM",
        "sensitivity": "1.83",
        "askPrice_f": "0.065",
        "delta": "54.7",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "03 May 21",
        "bidPrice_f": "0.064",
        "issuer": "MBL",
        "ric": "OCML_t20.SI",
        "avg_decay": "0.00043",
        "effectiveGearing": "9.1",
        "moneyness_percent": "1",
        "dwSymbol": "LQFW",
        "askPrice": "0.065000000",
        "tradeVolume": "51500.000000000"
        },
        {
        "askVolume": "380000.000000000",
        "bidVolume": "710000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.24",
        "tradeVolume_f": "0",
        "exercisePrice": "10.00",
        "bidVolume_f": "710",
        "askVolume_f": "380",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "12.0",
        "bidPrice": "0.079000000",
        "dwps": "12.0",
        "moneyness_c": "6% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "33.9",
        "moneyness": "OTM",
        "sensitivity": "3.25",
        "askPrice_f": "0.081",
        "delta": "-36.9",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "08 Nov 21",
        "bidPrice_f": "0.079",
        "issuer": "MBL",
        "ric": "OCML_t26.SI",
        "avg_decay": "0.00025",
        "effectiveGearing": "4.1",
        "moneyness_percent": "6",
        "dwSymbol": "JWRW",
        "askPrice": "0.081000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "420000.000000000",
        "bidVolume": "220000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.11",
        "tradeVolume_f": "0",
        "exercisePrice": "9.80",
        "bidVolume_f": "220",
        "askVolume_f": "420",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "12.0",
        "bidPrice": "0.121000000",
        "dwps": "12.0",
        "moneyness_c": "8% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "30.8",
        "moneyness": "ITM",
        "sensitivity": "1.88",
        "askPrice_f": "0.123",
        "delta": "63.8",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "08 Nov 21",
        "bidPrice_f": "0.121",
        "issuer": "MBL",
        "ric": "OCML_t23.SI",
        "avg_decay": "0.00022",
        "effectiveGearing": "4.7",
        "moneyness_percent": "8",
        "dwSymbol": "VYWW",
        "askPrice": "0.123000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "440000.000000000",
        "bidVolume": "840000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.59",
        "tradeVolume_f": "0",
        "exercisePrice": "9.80",
        "bidVolume_f": "840",
        "askVolume_f": "440",
        "priceChange": "-2.083333333",
        "type": "P",
        "conv_ratio": "10.0",
        "bidPrice": "0.047000000",
        "dwps": "10.0",
        "moneyness_c": "8% OTM",
        "priceChange_f": "-2.1",
        "impliedVolalitiy": "31.9",
        "moneyness": "OTM",
        "sensitivity": "3.20",
        "askPrice_f": "0.049",
        "delta": "-31.3",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.047",
        "issuer": "MBL",
        "ric": "OCML_t21.SI",
        "avg_decay": "0.00034",
        "effectiveGearing": "7.1",
        "HOTLIST": true,
        "moneyness_percent": "8",
        "dwSymbol": "9I5W",
        "askPrice": "0.049000000",
        "tradeVolume": "100.000000000"
        },
        {
        "askVolume": "270000.000000000",
        "bidVolume": "140000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.21",
        "tradeVolume_f": "0",
        "exercisePrice": "9.50",
        "bidVolume_f": "140",
        "askVolume_f": "270",
        "priceChange": "-1.052631579",
        "type": "C",
        "conv_ratio": "12.0",
        "bidPrice": "0.094000000",
        "dwps": "12.0",
        "moneyness_c": "12% ITM",
        "priceChange_f": "-1.1",
        "impliedVolalitiy": "37.1",
        "moneyness": "ITM",
        "sensitivity": "1.26",
        "askPrice_f": "0.098",
        "delta": "95.3",
        "APPCHEXPRY": true,
        "underlying_name": "OCBC",
        "ltDate": "22 Feb 21",
        "bidPrice_f": "0.094",
        "issuer": "MBL",
        "ric": "OCML_t11.SI",
        "avg_decay": "-0.00002",
        "effectiveGearing": "9.0",
        "moneyness_percent": "12",
        "dwSymbol": "XV1W",
        "askPrice": "0.098000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "300000.000000000",
        "bidVolume": "160000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.20",
        "tradeVolume_f": "0",
        "exercisePrice": "9.50",
        "bidVolume_f": "160",
        "askVolume_f": "300",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "12.0",
        "bidPrice": "0.109000000",
        "dwps": "12.0",
        "moneyness_c": "12% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "32.8",
        "moneyness": "ITM",
        "sensitivity": "1.54",
        "askPrice_f": "0.115",
        "delta": "77.8",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "30 Apr 21",
        "bidPrice_f": "0.109",
        "issuer": "MBL",
        "ric": "OCML_t27.SI",
        "avg_decay": "0.00029",
        "effectiveGearing": "6.3",
        "moneyness_percent": "12",
        "dwSymbol": "ZRDW",
        "askPrice": "0.115000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.36",
        "tradeVolume_f": "0",
        "exercisePrice": "9.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "12.0",
        "bidPrice": "0.044000000",
        "dwps": "12.0",
        "moneyness_c": "18% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "34.1",
        "moneyness": "OTM",
        "sensitivity": "4.91",
        "askPrice_f": "0.046",
        "delta": "-24.5",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "05 Nov 21",
        "bidPrice_f": "0.044",
        "issuer": "MBL",
        "ric": "OCML_t24.SI",
        "avg_decay": "0.00020",
        "effectiveGearing": "4.9",
        "moneyness_percent": "18",
        "dwSymbol": "95JW",
        "askPrice": "0.046000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-1.09",
        "tradeVolume_f": "0",
        "exercisePrice": "8.80",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "12.0",
        "bidPrice": "0.013000000",
        "dwps": "12.0",
        "moneyness_c": "21% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "30.8",
        "moneyness": "OTM",
        "sensitivity": "8.51",
        "askPrice_f": "0.021",
        "delta": "-14.1",
        "APPCHEXPRY": false,
        "underlying_name": "OCBC",
        "ltDate": "24 Jun 21",
        "bidPrice_f": "0.013",
        "issuer": "MBL",
        "ric": "OCML_t19.SI",
        "avg_decay": "0.00017",
        "effectiveGearing": "9.6",
        "moneyness_percent": "21",
        "dwSymbol": "EUEW",
        "askPrice": "0.021000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "270000.000000000",
        "bidVolume": "140000.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-0.02",
        "tradeVolume_f": "0",
        "exercisePrice": "8.60",
        "bidVolume_f": "140",
        "askVolume_f": "270",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "12.0",
        "bidPrice": "0.169000000",
        "dwps": "12.0",
        "moneyness_c": "24% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "47.2",
        "moneyness": "ITM",
        "sensitivity": "1.21",
        "askPrice_f": "0.172",
        "delta": "98.9",
        "APPCHEXPRY": true,
        "underlying_name": "OCBC",
        "ltDate": "24 Feb 21",
        "bidPrice_f": "0.169",
        "issuer": "MBL",
        "ric": "OCML_t18.SI",
        "avg_decay": "0.00004",
        "effectiveGearing": "5.2",
        "moneyness_percent": "24",
        "dwSymbol": "JSYW",
        "askPrice": "0.172000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "0.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "OCBC.SI",
        "underlyingSymbol": "O39",
        "theta": "-20.80",
        "tradeVolume_f": "0",
        "exercisePrice": "8.50",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "12.0",
        "bidPrice": "0.000000000",
        "dwps": "12.0",
        "moneyness_c": "25% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "0.0",
        "moneyness": "OTM",
        "sensitivity": "640.69",
        "askPrice_f": "0.000",
        "delta": "-0.2",
        "APPCHEXPRY": true,
        "underlying_name": "OCBC",
        "ltDate": "09 Feb 21",
        "bidPrice_f": "0.000",
        "issuer": "MBL",
        "ric": "OCML_t17.SI",
        "effectiveGearing": "1.7",
        "moneyness_percent": "25",
        "dwSymbol": "IEIW",
        "askPrice": "0.000000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "ESc1",
        "underlyingSymbol": "S&P500",
        "theta": "-0.93",
        "tradeVolume_f": "0",
        "exercisePrice": "4,200.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-5.586592179",
        "type": "C",
        "conv_ratio": "1,000",
        "bidPrice": "0.169000000",
        "HIGHRESP": false,
        "dwps": "1,000",
        "moneyness_c": "6% OTM",
        "priceChange_f": "-5.6",
        "impliedVolalitiy": "25.6",
        "moneyness": "OTM",
        "sensitivity": "2.16",
        "askPrice_f": "0.170",
        "delta": "34.9",
        "APPCHEXPRY": false,
        "underlying_name": "S&P500",
        "ltDate": "11 Jun 21",
        "bidPrice_f": "0.169",
        "issuer": "MBL",
        "ric": "SXML_t25.SI",
        "avg_decay": "0.00130",
        "effectiveGearing": "10.8",
        "moneyness_percent": "6",
        "dwSymbol": "GTJW",
        "askPrice": "0.170000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "ESc1",
        "underlyingSymbol": "S&P500",
        "theta": "-2.42",
        "tradeVolume_f": "0",
        "exercisePrice": "3,950.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "-8.823529412",
        "type": "C",
        "conv_ratio": "1,000",
        "bidPrice": "0.155000000",
        "HIGHRESP": false,
        "dwps": "1,000",
        "moneyness_c": "1% OTM",
        "priceChange_f": "-8.8",
        "impliedVolalitiy": "28.1",
        "moneyness": "OTM",
        "sensitivity": "1.55",
        "askPrice_f": "0.156",
        "delta": "48.6",
        "APPCHEXPRY": true,
        "underlying_name": "S&P500",
        "ltDate": "12 Mar 21",
        "bidPrice_f": "0.155",
        "issuer": "MBL",
        "ric": "SXML_t21.SI",
        "avg_decay": "0.00293",
        "effectiveGearing": "16.4",
        "HOTLIST": true,
        "moneyness_percent": "1",
        "dwSymbol": "VQEW",
        "askPrice": "0.156000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "ESc1",
        "underlyingSymbol": "S&P500",
        "theta": "-0.43",
        "tradeVolume_f": "0",
        "exercisePrice": "3,800.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "-3.191489362",
        "type": "C",
        "conv_ratio": "1,000",
        "bidPrice": "0.455000000",
        "dwps": "1,000",
        "moneyness_c": "3% ITM",
        "priceChange_f": "-3.2",
        "impliedVolalitiy": "31.2",
        "moneyness": "ITM",
        "sensitivity": "6.23",
        "askPrice_f": "0.460",
        "delta": "60.5",
        "APPCHEXPRY": false,
        "underlying_name": "S&P500",
        "ltDate": "11 Jun 21",
        "bidPrice_f": "0.455",
        "issuer": "MBL",
        "ric": "SXML_t26.SI",
        "avg_decay": "0.00100",
        "effectiveGearing": "6.9",
        "moneyness_percent": "3",
        "dwSymbol": "RUUW",
        "askPrice": "0.460000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "ESc1",
        "underlyingSymbol": "S&P500",
        "theta": "-1.15",
        "tradeVolume_f": "15",
        "exercisePrice": "3,400.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "3.267973856",
        "type": "P",
        "conv_ratio": "1,000",
        "bidPrice": "0.158000000",
        "HIGHRESP": false,
        "dwps": "1,000",
        "moneyness_c": "16% OTM",
        "priceChange_f": "+3.3",
        "impliedVolalitiy": "37.8",
        "moneyness": "OTM",
        "sensitivity": "3.44",
        "askPrice_f": "0.159",
        "delta": "-21.9",
        "APPCHEXPRY": false,
        "underlying_name": "S&P500",
        "ltDate": "11 Jun 21",
        "bidPrice_f": "0.158",
        "issuer": "MBL",
        "ric": "SXML_t23.SI",
        "avg_decay": "0.00135",
        "effectiveGearing": "7.2",
        "moneyness_percent": "16",
        "dwSymbol": "OKBW",
        "askPrice": "0.159000000",
        "tradeVolume": "15000.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "ESc1",
        "underlyingSymbol": "S&P500",
        "theta": "-1.58",
        "tradeVolume_f": "50",
        "exercisePrice": "3,000.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "3.061224490",
        "type": "P",
        "conv_ratio": "1,000",
        "bidPrice": "0.101000000",
        "HIGHRESP": false,
        "dwps": "1,000",
        "moneyness_c": "31% OTM",
        "priceChange_f": "+3.1",
        "impliedVolalitiy": "46.9",
        "moneyness": "OTM",
        "sensitivity": "5.88",
        "askPrice_f": "0.102",
        "delta": "-12.8",
        "APPCHEXPRY": false,
        "underlying_name": "S&P500",
        "ltDate": "11 Jun 21",
        "bidPrice_f": "0.101",
        "issuer": "MBL",
        "ric": "SXML_t24.SI",
        "avg_decay": "0.00111",
        "effectiveGearing": "6.6",
        "HOTLIST": true,
        "moneyness_percent": "31",
        "dwSymbol": "XYPW",
        "askPrice": "0.102000000",
        "tradeVolume": "50000.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "ESc1",
        "underlyingSymbol": "S&P500",
        "theta": "-15.90",
        "tradeVolume_f": "0",
        "exercisePrice": "2,950.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "1,000",
        "bidPrice": "0.003000000",
        "dwps": "1,000",
        "moneyness_c": "33% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "45.6",
        "moneyness": "OTM",
        "sensitivity": "61.88",
        "askPrice_f": "0.007",
        "delta": "-1.2",
        "APPCHEXPRY": true,
        "underlying_name": "S&P500",
        "ltDate": "12 Mar 21",
        "bidPrice_f": "0.003",
        "issuer": "MBL",
        "ric": "SXML_t22.SI",
        "avg_decay": "0.00031",
        "effectiveGearing": "21.2",
        "moneyness_percent": "33",
        "dwSymbol": "DOHW",
        "askPrice": "0.007000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "ESc1",
        "underlyingSymbol": "S&P500",
        "theta": "-20.19",
        "tradeVolume_f": "0",
        "exercisePrice": "2,700.00",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "1,000",
        "bidPrice": "0.001000000",
        "dwps": "1,000",
        "moneyness_c": "46% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "50.8",
        "moneyness": "OTM",
        "sensitivity": "185.08",
        "askPrice_f": "0.005",
        "delta": "-0.4",
        "APPCHEXPRY": true,
        "underlying_name": "S&P500",
        "ltDate": "12 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "SXML_t20.SI",
        "avg_decay": "0.00012",
        "effectiveGearing": "21.2",
        "moneyness_percent": "46",
        "dwSymbol": "IQZW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "460000.000000000",
        "bidVolume": "260000.000000000",
        "underlying_ric": "SGXL.SI",
        "underlyingSymbol": "S68",
        "theta": "-0.17",
        "tradeVolume_f": "0",
        "exercisePrice": "9.80",
        "bidVolume_f": "260",
        "askVolume_f": "460",
        "HIGHLQDY": true,
        "priceChange": "-3.361344538",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.115000000",
        "dwps": "10.0",
        "moneyness_c": "2% ITM",
        "priceChange_f": "-3.4",
        "impliedVolalitiy": "35.8",
        "moneyness": "ITM",
        "sensitivity": "1.81",
        "askPrice_f": "0.117",
        "delta": "55.2",
        "APPCHEXPRY": false,
        "underlying_name": "SGX",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.115",
        "issuer": "MBL",
        "ric": "SNML_t4.SI",
        "avg_decay": "0.00031",
        "effectiveGearing": "4.8",
        "HOTLIST": true,
        "moneyness_percent": "2",
        "dwSymbol": "ESYW",
        "askPrice": "0.117000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "340000.000000000",
        "bidVolume": "190000.000000000",
        "underlying_ric": "SGXL.SI",
        "underlyingSymbol": "S68",
        "theta": "-0.85",
        "tradeVolume_f": "0",
        "exercisePrice": "9.50",
        "bidVolume_f": "190",
        "askVolume_f": "340",
        "priceChange": "-9.090909091",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.060000000",
        "dwps": "10.0",
        "moneyness_c": "5% ITM",
        "priceChange_f": "-9.1",
        "impliedVolalitiy": "31.9",
        "moneyness": "ITM",
        "sensitivity": "1.39",
        "askPrice_f": "0.062",
        "delta": "71.9",
        "APPCHEXPRY": true,
        "underlying_name": "SGX",
        "ltDate": "08 Mar 21",
        "bidPrice_f": "0.060",
        "issuer": "MBL",
        "ric": "SNML_t2.SI",
        "avg_decay": "0.00068",
        "effectiveGearing": "11.9",
        "moneyness_percent": "5",
        "dwSymbol": "9QJW",
        "askPrice": "0.062000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "640000.000000000",
        "bidVolume": "1130000.000000000",
        "underlying_ric": "SGXL.SI",
        "underlyingSymbol": "S68",
        "theta": "-0.38",
        "tradeVolume_f": "0",
        "exercisePrice": "8.50",
        "bidVolume_f": "1,130",
        "askVolume_f": "640",
        "HIGHLQDY": true,
        "priceChange": "1.923076923",
        "type": "P",
        "conv_ratio": "10.0",
        "bidPrice": "0.053000000",
        "HIGHRESP": false,
        "dwps": "10.0",
        "moneyness_c": "17% OTM",
        "priceChange_f": "+1.9",
        "impliedVolalitiy": "36.3",
        "moneyness": "OTM",
        "sensitivity": "3.93",
        "askPrice_f": "0.054",
        "delta": "-25.4",
        "APPCHEXPRY": false,
        "underlying_name": "SGX",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.053",
        "issuer": "MBL",
        "ric": "SNML_t3.SI",
        "avg_decay": "0.00015",
        "effectiveGearing": "4.8",
        "HOTLIST": true,
        "moneyness_percent": "17",
        "dwSymbol": "UJGW",
        "askPrice": "0.054000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "SGXL.SI",
        "underlyingSymbol": "S68",
        "theta": "-11.83",
        "tradeVolume_f": "0",
        "exercisePrice": "8.00",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "10.0",
        "bidPrice": "0.001000000",
        "dwps": "10.0",
        "moneyness_c": "24% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "42.1",
        "moneyness": "OTM",
        "sensitivity": "43.51",
        "askPrice_f": "0.005",
        "delta": "-2.3",
        "APPCHEXPRY": true,
        "underlying_name": "SGX",
        "ltDate": "08 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "SNML_t1.SI",
        "avg_decay": "0.00010",
        "effectiveGearing": "22.9",
        "moneyness_percent": "24",
        "dwSymbol": "RXPW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "SIAL.SI",
        "underlyingSymbol": "C6L",
        "theta": "-0.83",
        "tradeVolume_f": "0",
        "exercisePrice": "5.50",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "8.5",
        "bidPrice": "0.026000000",
        "HIGHRESP": false,
        "dwps": "8.5",
        "moneyness_c": "21% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "52.8",
        "moneyness": "OTM",
        "sensitivity": "2.89",
        "askPrice_f": "0.027",
        "delta": "29.4",
        "APPCHEXPRY": false,
        "underlying_name": "SINGAPORE AIRLIN",
        "ltDate": "24 Jun 21",
        "bidPrice_f": "0.026",
        "issuer": "MBL",
        "ric": "SLML_ta.SI",
        "avg_decay": "0.00022",
        "effectiveGearing": "5.8",
        "HOTLIST": true,
        "moneyness_percent": "21",
        "dwSymbol": "GIJW",
        "askPrice": "0.027000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "STEL.SI",
        "underlyingSymbol": "Z74",
        "theta": "-8.29",
        "tradeVolume_f": "0",
        "exercisePrice": "3.80",
        "bidVolume_f": "0",
        "askVolume_f": "10",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.001000000",
        "dwps": "3.5",
        "moneyness_c": "37% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "62.7",
        "moneyness": "OTM",
        "sensitivity": "17.56",
        "askPrice_f": "0.005",
        "delta": "2.0",
        "APPCHEXPRY": false,
        "underlying_name": "SINGTEL",
        "ltDate": "24 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "SEML_t16.SI",
        "avg_decay": "0.00007",
        "effectiveGearing": "13.7",
        "moneyness_percent": "37",
        "dwSymbol": "YBNW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "0.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "STEL.SI",
        "underlyingSymbol": "Z74",
        "theta": "-64.62",
        "tradeVolume_f": "0",
        "exercisePrice": "2.80",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "1.5",
        "bidPrice": "0.000000000",
        "dwps": "1.5",
        "moneyness_c": "14% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "63.3",
        "moneyness": "OTM",
        "sensitivity": "6.51",
        "askPrice_f": "0.000",
        "delta": "2.3",
        "APPCHEXPRY": true,
        "underlying_name": "SINGTEL",
        "ltDate": "15 Feb 21",
        "bidPrice_f": "0.000",
        "issuer": "MBL",
        "ric": "SEML_t17.SI",
        "avg_decay": "0.00000",
        "effectiveGearing": "37.0",
        "moneyness_percent": "14",
        "dwSymbol": "YX7W",
        "askPrice": "0.000000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "STEL.SI",
        "underlyingSymbol": "Z74",
        "theta": "-0.34",
        "tradeVolume_f": "0",
        "exercisePrice": "2.80",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-4.166666667",
        "type": "C",
        "conv_ratio": "5.5",
        "bidPrice": "0.023000000",
        "dwps": "5.5",
        "moneyness_c": "14% OTM",
        "priceChange_f": "-4.2",
        "impliedVolalitiy": "35.6",
        "moneyness": "OTM",
        "sensitivity": "1.72",
        "askPrice_f": "0.025",
        "delta": "31.9",
        "APPCHEXPRY": false,
        "underlying_name": "SINGTEL",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.023",
        "issuer": "MBL",
        "ric": "SEML_t7.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "6.1",
        "HOTLIST": true,
        "moneyness_percent": "14",
        "dwSymbol": "59XW",
        "askPrice": "0.025000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "STEL.SI",
        "underlyingSymbol": "Z74",
        "theta": "-2.96",
        "tradeVolume_f": "0",
        "exercisePrice": "2.40",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-19.607843137",
        "type": "C",
        "conv_ratio": "1.5",
        "bidPrice": "0.041000000",
        "dwps": "1.5",
        "moneyness_c": "0% ITM",
        "priceChange_f": "-19.6",
        "impliedVolalitiy": "29.8",
        "moneyness": "ITM",
        "sensitivity": "0.28",
        "askPrice_f": "0.043",
        "delta": "53.0",
        "APPCHEXPRY": true,
        "underlying_name": "SINGTEL",
        "ltDate": "25 Feb 21",
        "bidPrice_f": "0.041",
        "issuer": "MBL",
        "ric": "SEML_t18.SI",
        "avg_decay": "0.00134",
        "effectiveGearing": "20.8",
        "moneyness_percent": "0",
        "dwSymbol": "GJAW",
        "askPrice": "0.043000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.45",
        "tradeVolume_f": "0",
        "exercisePrice": "3,300.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "-4.545454545",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.042000000",
        "dwps": "2,500",
        "moneyness_c": "11% OTM",
        "priceChange_f": "-4.5",
        "impliedVolalitiy": "24.9",
        "moneyness": "OTM",
        "sensitivity": "7.95",
        "askPrice_f": "0.044",
        "delta": "31.4",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Sep 21",
        "bidPrice_f": "0.042",
        "issuer": "MBL",
        "ric": "STML_t43.SI",
        "avg_decay": "0.00021",
        "effectiveGearing": "8.8",
        "moneyness_percent": "11",
        "dwSymbol": "LZTW",
        "askPrice": "0.044000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.58",
        "tradeVolume_f": "0",
        "exercisePrice": "3,100.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-3.846153846",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.050000000",
        "HIGHRESP": false,
        "dwps": "2,500",
        "moneyness_c": "6% OTM",
        "priceChange_f": "-3.8",
        "impliedVolalitiy": "27.0",
        "moneyness": "OTM",
        "sensitivity": "6.22",
        "askPrice_f": "0.051",
        "delta": "40.2",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Jun 21",
        "bidPrice_f": "0.050",
        "issuer": "MBL",
        "ric": "STML_t38.SI",
        "avg_decay": "0.00032",
        "effectiveGearing": "9.4",
        "HOTLIST": true,
        "moneyness_percent": "6",
        "dwSymbol": "EZEW",
        "askPrice": "0.051000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.29",
        "tradeVolume_f": "0",
        "exercisePrice": "3,050.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "-2.531645570",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.077000000",
        "dwps": "2,500",
        "moneyness_c": "4% OTM",
        "priceChange_f": "-2.5",
        "impliedVolalitiy": "25.9",
        "moneyness": "OTM",
        "sensitivity": "5.30",
        "askPrice_f": "0.079",
        "delta": "47.2",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Sep 21",
        "bidPrice_f": "0.077",
        "issuer": "MBL",
        "ric": "STML_t44.SI",
        "avg_decay": "0.00026",
        "effectiveGearing": "7.2",
        "moneyness_percent": "4",
        "dwSymbol": "V6OW",
        "askPrice": "0.079000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-1.66",
        "tradeVolume_f": "0",
        "exercisePrice": "3,000.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-5.882352941",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.032000000",
        "HIGHRESP": true,
        "dwps": "2,500",
        "moneyness_c": "3% OTM",
        "priceChange_f": "-5.9",
        "impliedVolalitiy": "28.0",
        "moneyness": "OTM",
        "sensitivity": "5.97",
        "askPrice_f": "0.033",
        "delta": "41.8",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "24 Mar 21",
        "bidPrice_f": "0.032",
        "issuer": "MBL",
        "ric": "STML_t30.SI",
        "avg_decay": "0.00057",
        "effectiveGearing": "15.3",
        "moneyness_percent": "3",
        "dwSymbol": "VSBW",
        "askPrice": "0.033000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.19",
        "tradeVolume_f": "0",
        "exercisePrice": "3,000.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "1.428571429",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.142000000",
        "dwps": "2,500",
        "moneyness_c": "3% ITM",
        "priceChange_f": "+1.4",
        "impliedVolalitiy": "35.9",
        "moneyness": "ITM",
        "sensitivity": "5.34",
        "askPrice_f": "0.144",
        "delta": "-46.8",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Sep 21",
        "bidPrice_f": "0.142",
        "issuer": "MBL",
        "ric": "STML_t45.SI",
        "avg_decay": "0.00004",
        "effectiveGearing": "3.9",
        "moneyness_percent": "3",
        "dwSymbol": "PCEW",
        "askPrice": "0.144000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.36",
        "tradeVolume_f": "0",
        "exercisePrice": "2,900.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-2.409638554",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.081000000",
        "HIGHRESP": true,
        "dwps": "2,500",
        "moneyness_c": "1% ITM",
        "priceChange_f": "-2.4",
        "impliedVolalitiy": "25.9",
        "moneyness": "ITM",
        "sensitivity": "4.44",
        "askPrice_f": "0.082",
        "delta": "56.3",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Jun 21",
        "bidPrice_f": "0.081",
        "issuer": "MBL",
        "ric": "STML_t37.SI",
        "avg_decay": "0.00053",
        "effectiveGearing": "8.1",
        "moneyness_percent": "1",
        "dwSymbol": "PXKW",
        "askPrice": "0.082000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.62",
        "tradeVolume_f": "0",
        "exercisePrice": "2,800.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-5.333333333",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.071000000",
        "dwps": "2,500",
        "moneyness_c": "4% ITM",
        "priceChange_f": "-5.3",
        "impliedVolalitiy": "25.9",
        "moneyness": "ITM",
        "sensitivity": "3.52",
        "askPrice_f": "0.073",
        "delta": "71.1",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "24 Mar 21",
        "bidPrice_f": "0.071",
        "issuer": "MBL",
        "ric": "STML_t29.SI",
        "avg_decay": "0.00050",
        "effectiveGearing": "11.7",
        "moneyness_percent": "4",
        "dwSymbol": "URDW",
        "askPrice": "0.073000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.18",
        "tradeVolume_f": "0",
        "exercisePrice": "2,800.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "-2.325581395",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.126000000",
        "HIGHRESP": true,
        "dwps": "2,500",
        "moneyness_c": "4% ITM",
        "priceChange_f": "-2.3",
        "impliedVolalitiy": "26.1",
        "moneyness": "ITM",
        "sensitivity": "3.93",
        "askPrice_f": "0.127",
        "delta": "63.6",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Sep 21",
        "bidPrice_f": "0.126",
        "issuer": "MBL",
        "ric": "STML_t40.SI",
        "avg_decay": "0.00047",
        "effectiveGearing": "5.9",
        "moneyness_percent": "4",
        "dwSymbol": "UNCW",
        "askPrice": "0.127000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.47",
        "tradeVolume_f": "0",
        "exercisePrice": "2,800.00",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "1.428571429",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.071000000",
        "HIGHRESP": false,
        "dwps": "2,500",
        "moneyness_c": "4% OTM",
        "priceChange_f": "+1.4",
        "impliedVolalitiy": "35.4",
        "moneyness": "OTM",
        "sensitivity": "6.76",
        "askPrice_f": "0.072",
        "delta": "-37.0",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Jun 21",
        "bidPrice_f": "0.071",
        "issuer": "MBL",
        "ric": "STML_t36.SI",
        "avg_decay": "0.00011",
        "effectiveGearing": "6.1",
        "HOTLIST": true,
        "moneyness_percent": "4",
        "dwSymbol": "YZHW",
        "askPrice": "0.072000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.28",
        "tradeVolume_f": "0",
        "exercisePrice": "2,750.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "2.173913043",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.094000000",
        "dwps": "2,500",
        "moneyness_c": "6% OTM",
        "priceChange_f": "+2.2",
        "impliedVolalitiy": "36.7",
        "moneyness": "OTM",
        "sensitivity": "7.15",
        "askPrice_f": "0.096",
        "delta": "-35.0",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Sep 21",
        "bidPrice_f": "0.094",
        "issuer": "MBL",
        "ric": "STML_t41.SI",
        "avg_decay": "0.00024",
        "effectiveGearing": "4.4",
        "moneyness_percent": "6",
        "dwSymbol": "JUPW",
        "askPrice": "0.096000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.15",
        "tradeVolume_f": "0",
        "exercisePrice": "2,650.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-2.127659574",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.138000000",
        "dwps": "2,500",
        "moneyness_c": "10% ITM",
        "priceChange_f": "-2.1",
        "impliedVolalitiy": "24.0",
        "moneyness": "ITM",
        "sensitivity": "3.19",
        "askPrice_f": "0.140",
        "delta": "78.3",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Jun 21",
        "bidPrice_f": "0.138",
        "issuer": "MBL",
        "ric": "STML_t34.SI",
        "avg_decay": "0.00047",
        "effectiveGearing": "6.6",
        "moneyness_percent": "10",
        "dwSymbol": "XINW",
        "askPrice": "0.140000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.70",
        "tradeVolume_f": "0",
        "exercisePrice": "2,600.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "2.631578947",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.039000000",
        "HIGHRESP": false,
        "dwps": "2,500",
        "moneyness_c": "12% OTM",
        "priceChange_f": "+2.6",
        "impliedVolalitiy": "34.3",
        "moneyness": "OTM",
        "sensitivity": "10.25",
        "askPrice_f": "0.040",
        "delta": "-24.4",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Jun 21",
        "bidPrice_f": "0.039",
        "issuer": "MBL",
        "ric": "STML_t39.SI",
        "avg_decay": "0.00026",
        "effectiveGearing": "7.3",
        "moneyness_percent": "12",
        "dwSymbol": "VVZW",
        "askPrice": "0.040000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "500000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.02",
        "tradeVolume_f": "0",
        "exercisePrice": "2,550.00",
        "bidVolume_f": "500",
        "askVolume_f": "500",
        "priceChange": "-2.597402597",
        "type": "C",
        "conv_ratio": "2,500",
        "bidPrice": "0.150000000",
        "dwps": "2,500",
        "moneyness_c": "15% ITM",
        "priceChange_f": "-2.6",
        "impliedVolalitiy": "10.0",
        "moneyness": "ITM",
        "sensitivity": "2.50",
        "askPrice_f": "0.153",
        "delta": "100.0",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "24 Mar 21",
        "bidPrice_f": "0.150",
        "issuer": "MBL",
        "ric": "STML_t33.SI",
        "avg_decay": "-0.00016",
        "effectiveGearing": "7.8",
        "moneyness_percent": "15",
        "dwSymbol": "8C5W",
        "askPrice": "0.153000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-0.40",
        "tradeVolume_f": "0",
        "exercisePrice": "2,500.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "1.785714286",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.057000000",
        "HIGHRESP": false,
        "dwps": "2,500",
        "moneyness_c": "17% OTM",
        "priceChange_f": "+1.8",
        "impliedVolalitiy": "37.6",
        "moneyness": "OTM",
        "sensitivity": "10.42",
        "askPrice_f": "0.058",
        "delta": "-24.0",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Sep 21",
        "bidPrice_f": "0.057",
        "issuer": "MBL",
        "ric": "STML_t42.SI",
        "avg_decay": "0.00021",
        "effectiveGearing": "4.9",
        "moneyness_percent": "17",
        "dwSymbol": "JUUW",
        "askPrice": "0.058000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-4.94",
        "tradeVolume_f": "250",
        "exercisePrice": "2,500.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.004000000",
        "dwps": "2,500",
        "moneyness_c": "17% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "32.4",
        "moneyness": "OTM",
        "sensitivity": "36.97",
        "askPrice_f": "0.006",
        "delta": "-6.8",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "24 Mar 21",
        "bidPrice_f": "0.004",
        "issuer": "MBL",
        "ric": "STML_t32.SI",
        "avg_decay": "0.00019",
        "effectiveGearing": "19.8",
        "moneyness_percent": "17",
        "dwSymbol": "DFAW",
        "askPrice": "0.006000000",
        "tradeVolume": "250000.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-1.02",
        "tradeVolume_f": "0",
        "exercisePrice": "2,400.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.020000000",
        "dwps": "2,500",
        "moneyness_c": "22% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "34.9",
        "moneyness": "OTM",
        "sensitivity": "17.42",
        "askPrice_f": "0.022",
        "delta": "-14.3",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "23 Jun 21",
        "bidPrice_f": "0.020",
        "issuer": "MBL",
        "ric": "STML_t35.SI",
        "avg_decay": "0.00019",
        "effectiveGearing": "8.4",
        "moneyness_percent": "22",
        "dwSymbol": "OSTW",
        "askPrice": "0.022000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": ".STI",
        "underlyingSymbol": "STI",
        "theta": "-7.59",
        "tradeVolume_f": "0",
        "exercisePrice": "2,300.00",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "2,500",
        "bidPrice": "0.001000000",
        "dwps": "2,500",
        "moneyness_c": "27% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "35.3",
        "moneyness": "OTM",
        "sensitivity": "132.89",
        "askPrice_f": "0.005",
        "delta": "-1.9",
        "APPCHEXPRY": false,
        "underlying_name": "STI",
        "ltDate": "24 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "STML_t31.SI",
        "avg_decay": "0.00007",
        "effectiveGearing": "22.0",
        "moneyness_percent": "27",
        "dwSymbol": "WVEW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "2382.HK",
        "underlyingSymbol": "SUNNY OPTICAL",
        "theta": "-0.01",
        "tradeVolume_f": "0",
        "exercisePrice": "160.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "-5.504587156",
        "type": "C",
        "conv_ratio": "25",
        "bidPrice": "0.515000000",
        "dwps": "25",
        "moneyness_c": "45% ITM",
        "priceChange_f": "-5.5",
        "impliedVolalitiy": "48.9",
        "moneyness": "ITM",
        "sensitivity": "13.66",
        "askPrice_f": "0.530",
        "delta": "91.5",
        "APPCHEXPRY": false,
        "underlying_name": "SUNNY OPTICAL",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.515",
        "issuer": "MBL",
        "ric": "SUML_tc.SI",
        "avg_decay": "0.00052",
        "effectiveGearing": "2.8",
        "moneyness_percent": "45",
        "dwSymbol": "WAVW",
        "askPrice": "0.530000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "2382.HK",
        "underlyingSymbol": "SUNNY OPTICAL",
        "theta": "-0.00",
        "tradeVolume_f": "0",
        "exercisePrice": "140.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "-5.303030303",
        "type": "C",
        "conv_ratio": "25",
        "bidPrice": "0.625000000",
        "dwps": "25",
        "moneyness_c": "65% ITM",
        "priceChange_f": "-5.3",
        "impliedVolalitiy": "10.0",
        "moneyness": "ITM",
        "sensitivity": "12.51",
        "askPrice_f": "0.640",
        "delta": "99.9",
        "APPCHEXPRY": false,
        "underlying_name": "SUNNY OPTICAL",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.625",
        "issuer": "MBL",
        "ric": "SUML_tb.SI",
        "avg_decay": "-0.00061",
        "effectiveGearing": "2.5",
        "moneyness_percent": "65",
        "dwSymbol": "9T9W",
        "askPrice": "0.640000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "200000.000000000",
        "underlying_ric": "2382.HK",
        "underlyingSymbol": "SUNNY OPTICAL",
        "theta": "-0.35",
        "tradeVolume_f": "0",
        "exercisePrice": "120.00",
        "bidVolume_f": "200",
        "askVolume_f": "10",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "25",
        "bidPrice": "0.005000000",
        "dwps": "25",
        "moneyness_c": "93% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "57.9",
        "moneyness": "OTM",
        "sensitivity": "118.13",
        "askPrice_f": "0.009",
        "delta": "-2.1",
        "APPCHEXPRY": false,
        "underlying_name": "SUNNY OPTICAL",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.005",
        "issuer": "MBL",
        "ric": "SUML_td.SI",
        "avg_decay": "0.00012",
        "effectiveGearing": "6.7",
        "moneyness_percent": "93",
        "dwSymbol": "8P7W",
        "askPrice": "0.009000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "2382.HK",
        "underlyingSymbol": "SUNNY OPTICAL",
        "theta": "-1.48",
        "tradeVolume_f": "0",
        "exercisePrice": "100.00",
        "bidVolume_f": "0",
        "askVolume_f": "2,000",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "25",
        "bidPrice": "0.001000000",
        "dwps": "25",
        "moneyness_c": "132% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "97.7",
        "moneyness": "OTM",
        "sensitivity": "489.11",
        "askPrice_f": "0.005",
        "delta": "-0.5",
        "APPCHEXPRY": false,
        "underlying_name": "SUNNY OPTICAL",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "SUML_ta.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "8.1",
        "moneyness_percent": "132",
        "dwSymbol": "PDGW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2650000.000000000",
        "bidVolume": "400000.000000000",
        "underlying_ric": "0700.HK",
        "underlyingSymbol": "TENCENT",
        "theta": "-0.04",
        "tradeVolume_f": "30",
        "exercisePrice": "700.00",
        "bidVolume_f": "400",
        "askVolume_f": "2,650",
        "HIGHLQDY": true,
        "priceChange": "7.936507937",
        "type": "C",
        "conv_ratio": "60",
        "bidPrice": "0.340000000",
        "dwps": "60",
        "moneyness_c": "9% ITM",
        "priceChange_f": "+7.9",
        "impliedVolalitiy": "47.2",
        "moneyness": "ITM",
        "sensitivity": "45.01",
        "askPrice_f": "0.350",
        "delta": "66.6",
        "APPCHEXPRY": false,
        "underlying_name": "TENCENT",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.340",
        "issuer": "MBL",
        "ric": "TCML_t20.SI",
        "avg_decay": "0.00073",
        "effectiveGearing": "4.3",
        "HOTLIST": true,
        "moneyness_percent": "9",
        "dwSymbol": "PHDW",
        "askPrice": "0.350000000",
        "tradeVolume": "30000.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "0700.HK",
        "underlyingSymbol": "TENCENT",
        "theta": "-0.08",
        "tradeVolume_f": "0",
        "exercisePrice": "688.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "18.604651163",
        "type": "C",
        "conv_ratio": "50",
        "bidPrice": "0.255000000",
        "dwps": "50",
        "moneyness_c": "10% ITM",
        "priceChange_f": "+18.6",
        "impliedVolalitiy": "38.4",
        "moneyness": "ITM",
        "sensitivity": "27.37",
        "askPrice_f": "0.260",
        "delta": "91.3",
        "APPCHEXPRY": true,
        "underlying_name": "TENCENT",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.255",
        "issuer": "MBL",
        "ric": "TCML_t17.SI",
        "avg_decay": "0.00112",
        "effectiveGearing": "9.3",
        "moneyness_percent": "10",
        "dwSymbol": "Q31W",
        "askPrice": "0.260000000",
        "tradeVolume": "300.000000000"
        },
        {
        "askVolume": "2000000.000000000",
        "bidVolume": "2000000.000000000",
        "underlying_ric": "0700.HK",
        "underlyingSymbol": "TENCENT",
        "theta": "-0.21",
        "tradeVolume_f": "0",
        "exercisePrice": "500.00",
        "bidVolume_f": "2,000",
        "askVolume_f": "2,000",
        "HIGHLQDY": true,
        "priceChange": "-11.428571429",
        "type": "P",
        "conv_ratio": "60",
        "bidPrice": "0.031000000",
        "dwps": "60",
        "moneyness_c": "52% OTM",
        "priceChange_f": "-11.4",
        "impliedVolalitiy": "55.9",
        "moneyness": "OTM",
        "sensitivity": "73.36",
        "askPrice_f": "0.033",
        "delta": "-8.2",
        "APPCHEXPRY": false,
        "underlying_name": "TENCENT",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.031",
        "issuer": "MBL",
        "ric": "TCML_t19.SI",
        "avg_decay": "0.00033",
        "effectiveGearing": "5.7",
        "HOTLIST": true,
        "moneyness_percent": "52",
        "dwSymbol": "WVDW",
        "askPrice": "0.033000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "0700.HK",
        "underlyingSymbol": "TENCENT",
        "theta": "-5.56",
        "tradeVolume_f": "0",
        "exercisePrice": "450.00",
        "bidVolume_f": "0",
        "askVolume_f": "10",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "50",
        "bidPrice": "0.001000000",
        "dwps": "50",
        "moneyness_c": "69% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "112.8",
        "moneyness": "OTM",
        "sensitivity": "935.79",
        "askPrice_f": "0.005",
        "delta": "-0.5",
        "APPCHEXPRY": true,
        "underlying_name": "TENCENT",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "TCML_t18.SI",
        "avg_decay": "0.00018",
        "effectiveGearing": "13.9",
        "moneyness_percent": "69",
        "dwSymbol": "WL7W",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1000000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "TOPG.SI",
        "underlyingSymbol": "BVA",
        "theta": "-15.07",
        "tradeVolume_f": "0",
        "exercisePrice": "3.50",
        "bidVolume_f": "0",
        "askVolume_f": "1,000",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "12.0",
        "bidPrice": "0.001000000",
        "dwps": "12.0",
        "moneyness_c": "42% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "150.0",
        "moneyness": "OTM",
        "sensitivity": "32.03",
        "askPrice_f": "0.005",
        "delta": "3.7",
        "APPCHEXPRY": true,
        "underlying_name": "TOP GLOVE CORPOR",
        "ltDate": "23 Feb 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "TOML_tc.SI",
        "avg_decay": "0.00018",
        "effectiveGearing": "6.3",
        "moneyness_percent": "42",
        "dwSymbol": "WHGW",
        "askPrice": "0.005000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1760000.000000000",
        "bidVolume": "420000.000000000",
        "underlying_ric": "TOPG.SI",
        "underlyingSymbol": "BVA",
        "theta": "-0.40",
        "tradeVolume_f": "0",
        "exercisePrice": "3.15",
        "bidVolume_f": "420",
        "askVolume_f": "1,760",
        "HIGHLQDY": true,
        "priceChange": "2.380952381",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.043000000",
        "dwps": "10.0",
        "moneyness_c": "36% OTM",
        "priceChange_f": "+2.4",
        "impliedVolalitiy": "114.8",
        "moneyness": "OTM",
        "sensitivity": "2.09",
        "askPrice_f": "0.045",
        "delta": "47.8",
        "APPCHEXPRY": false,
        "underlying_name": "TOP GLOVE CORPOR",
        "ltDate": "10 Sep 21",
        "bidPrice_f": "0.043",
        "issuer": "MBL",
        "ric": "TOPG_ta.SI",
        "avg_decay": "0.00037",
        "effectiveGearing": "2.3",
        "HOTLIST": true,
        "moneyness_percent": "36",
        "dwSymbol": "1P7W",
        "askPrice": "0.045000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "400000.000000000",
        "bidVolume": "200000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.43",
        "tradeVolume_f": "0",
        "exercisePrice": "28.00",
        "bidVolume_f": "200",
        "askVolume_f": "400",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.048000000",
        "dwps": "18.0",
        "moneyness_c": "14% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "29.6",
        "moneyness": "OTM",
        "sensitivity": "6.53",
        "askPrice_f": "0.050",
        "delta": "27.6",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "01 Oct 21",
        "bidPrice_f": "0.048",
        "issuer": "MBL",
        "ric": "UOML_t29.SI",
        "avg_decay": "0.00027",
        "effectiveGearing": "7.7",
        "moneyness_percent": "14",
        "dwSymbol": "RABW",
        "askPrice": "0.050000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "540000.000000000",
        "bidVolume": "300000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-1.38",
        "tradeVolume_f": "0",
        "exercisePrice": "27.00",
        "bidVolume_f": "300",
        "askVolume_f": "540",
        "priceChange": "-4.166666667",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.023000000",
        "dwps": "18.0",
        "moneyness_c": "11% OTM",
        "priceChange_f": "-4.2",
        "impliedVolalitiy": "29.9",
        "moneyness": "OTM",
        "sensitivity": "8.15",
        "askPrice_f": "0.026",
        "delta": "22.1",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "06 May 21",
        "bidPrice_f": "0.023",
        "issuer": "MBL",
        "ric": "UOML_t34.SI",
        "avg_decay": "0.00037",
        "effectiveGearing": "12.8",
        "moneyness_percent": "11",
        "dwSymbol": "ELVW",
        "askPrice": "0.026000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "200000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.30",
        "tradeVolume_f": "0",
        "exercisePrice": "26.00",
        "bidVolume_f": "100",
        "askVolume_f": "200",
        "priceChange": "-1.315789474",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.075000000",
        "dwps": "18.0",
        "moneyness_c": "8% OTM",
        "priceChange_f": "-1.3",
        "impliedVolalitiy": "29.8",
        "moneyness": "OTM",
        "sensitivity": "4.65",
        "askPrice_f": "0.077",
        "delta": "38.7",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "27 Sep 21",
        "bidPrice_f": "0.075",
        "issuer": "MBL",
        "ric": "UOML_t28.SI",
        "avg_decay": "0.00012",
        "effectiveGearing": "6.9",
        "moneyness_percent": "8",
        "dwSymbol": "KVKW",
        "askPrice": "0.077000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.19",
        "tradeVolume_f": "0",
        "exercisePrice": "25.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.190000000",
        "dwps": "18.0",
        "moneyness_c": "4% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "36.3",
        "moneyness": "ITM",
        "sensitivity": "3.57",
        "askPrice_f": "0.196",
        "delta": "-50.5",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "24 Sep 21",
        "bidPrice_f": "0.190",
        "issuer": "MBL",
        "ric": "UOML_t32.SI",
        "avg_decay": "0.00044",
        "effectiveGearing": "3.5",
        "moneyness_percent": "4",
        "dwSymbol": "VIFW",
        "askPrice": "0.196000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "200000.000000000",
        "bidVolume": "110000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-1.37",
        "tradeVolume_f": "80",
        "exercisePrice": "24.77",
        "bidVolume_f": "110",
        "askVolume_f": "200",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "17.8",
        "bidPrice": "0.043000000",
        "HIGHRESP": false,
        "dwps": "17.8",
        "moneyness_c": "3% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "31.8",
        "moneyness": "OTM",
        "sensitivity": "4.38",
        "askPrice_f": "0.044",
        "delta": "40.7",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "30 Mar 21",
        "bidPrice_f": "0.043",
        "issuer": "MBL",
        "ric": "UOML_t25.SI",
        "avg_decay": "0.00070",
        "effectiveGearing": "12.7",
        "moneyness_percent": "3",
        "dwSymbol": "FSXW",
        "askPrice": "0.044000000",
        "tradeVolume": "80400.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.20",
        "tradeVolume_f": "2",
        "exercisePrice": "24.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.116000000",
        "HIGHRESP": false,
        "dwps": "18.0",
        "moneyness_c": "0% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "29.8",
        "moneyness": "OTM",
        "sensitivity": "3.47",
        "askPrice_f": "0.117",
        "delta": "51.8",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "24 Sep 21",
        "bidPrice_f": "0.116",
        "issuer": "MBL",
        "ric": "UOML_t31.SI",
        "avg_decay": "0.00035",
        "effectiveGearing": "6.0",
        "HOTLIST": true,
        "moneyness_percent": "0",
        "dwSymbol": "XIIW",
        "askPrice": "0.117000000",
        "tradeVolume": "1600.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "100000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.26",
        "tradeVolume_f": "0",
        "exercisePrice": "23.00",
        "bidVolume_f": "100",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.128000000",
        "dwps": "18.0",
        "moneyness_c": "4% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "36.1",
        "moneyness": "OTM",
        "sensitivity": "4.60",
        "askPrice_f": "0.130",
        "delta": "-39.1",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "01 Oct 21",
        "bidPrice_f": "0.128",
        "issuer": "MBL",
        "ric": "UOML_t30.SI",
        "avg_decay": "0.00025",
        "effectiveGearing": "4.1",
        "moneyness_percent": "4",
        "dwSymbol": "MPPW",
        "askPrice": "0.130000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "100000.000000000",
        "bidVolume": "50000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.43",
        "tradeVolume_f": "0",
        "exercisePrice": "23.00",
        "bidVolume_f": "50",
        "askVolume_f": "100",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.104000000",
        "dwps": "18.0",
        "moneyness_c": "4% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "32.7",
        "moneyness": "ITM",
        "sensitivity": "2.86",
        "askPrice_f": "0.106",
        "delta": "62.9",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "26 Apr 21",
        "bidPrice_f": "0.104",
        "issuer": "MBL",
        "ric": "UOML_t35.SI",
        "avg_decay": "0.00040",
        "effectiveGearing": "8.1",
        "moneyness_percent": "4",
        "dwSymbol": "V8DW",
        "askPrice": "0.106000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "140000.000000000",
        "bidVolume": "260000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.61",
        "tradeVolume_f": "0",
        "exercisePrice": "22.00",
        "bidVolume_f": "260",
        "askVolume_f": "140",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.059000000",
        "HIGHRESP": false,
        "dwps": "18.0",
        "moneyness_c": "9% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "32.9",
        "moneyness": "OTM",
        "sensitivity": "5.91",
        "askPrice_f": "0.060",
        "delta": "-30.5",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.059",
        "issuer": "MBL",
        "ric": "UOML_t33.SI",
        "avg_decay": "0.00029",
        "effectiveGearing": "6.9",
        "HOTLIST": true,
        "moneyness_percent": "9",
        "dwSymbol": "YWRW",
        "askPrice": "0.060000000",
        "tradeVolume": "400.000000000"
        },
        {
        "askVolume": "70000.000000000",
        "bidVolume": "40000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.17",
        "tradeVolume_f": "0",
        "exercisePrice": "20.81",
        "bidVolume_f": "40",
        "askVolume_f": "70",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "17.8",
        "bidPrice": "0.187000000",
        "dwps": "17.8",
        "moneyness_c": "15% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "36.5",
        "moneyness": "ITM",
        "sensitivity": "2.05",
        "askPrice_f": "0.198",
        "delta": "86.9",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.187",
        "issuer": "MBL",
        "ric": "UOML_t24.SI",
        "avg_decay": "0.00032",
        "effectiveGearing": "6.3",
        "moneyness_percent": "15",
        "dwSymbol": "SMOW",
        "askPrice": "0.198000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "70000.000000000",
        "bidVolume": "40000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-0.09",
        "tradeVolume_f": "0",
        "exercisePrice": "20.00",
        "bidVolume_f": "40",
        "askVolume_f": "70",
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "18.0",
        "bidPrice": "0.230000000",
        "dwps": "18.0",
        "moneyness_c": "20% ITM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "34.7",
        "moneyness": "ITM",
        "sensitivity": "10.25",
        "askPrice_f": "0.240",
        "delta": "87.8",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "29 Apr 21",
        "bidPrice_f": "0.230",
        "issuer": "MBL",
        "ric": "UOML_t26.SI",
        "avg_decay": "0.00040",
        "effectiveGearing": "5.1",
        "moneyness_percent": "20",
        "dwSymbol": "PWBW",
        "askPrice": "0.240000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "400000.000000000",
        "bidVolume": "800000.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-1.07",
        "tradeVolume_f": "0",
        "exercisePrice": "20.00",
        "bidVolume_f": "800",
        "askVolume_f": "400",
        "priceChange": "-4.545454545",
        "type": "P",
        "conv_ratio": "18.0",
        "bidPrice": "0.021000000",
        "dwps": "18.0",
        "moneyness_c": "20% OTM",
        "priceChange_f": "-4.5",
        "impliedVolalitiy": "30.9",
        "moneyness": "OTM",
        "sensitivity": "12.11",
        "askPrice_f": "0.024",
        "delta": "-14.9",
        "APPCHEXPRY": false,
        "underlying_name": "UOB",
        "ltDate": "24 Jun 21",
        "bidPrice_f": "0.021",
        "issuer": "MBL",
        "ric": "UOML_t27.SI",
        "avg_decay": "0.00019",
        "effectiveGearing": "9.4",
        "moneyness_percent": "20",
        "dwSymbol": "X0RW",
        "askPrice": "0.024000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "0.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "UOBH.SI",
        "underlyingSymbol": "U11",
        "theta": "-15.13",
        "tradeVolume_f": "0",
        "exercisePrice": "18.83",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "17.8",
        "bidPrice": "0.000000000",
        "dwps": "17.8",
        "moneyness_c": "27% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "0.0",
        "moneyness": "OTM",
        "sensitivity": "2,129.89",
        "askPrice_f": "0.000",
        "delta": "-0.1",
        "APPCHEXPRY": true,
        "underlying_name": "UOB",
        "ltDate": "09 Feb 21",
        "bidPrice_f": "0.000",
        "issuer": "MBL",
        "ric": "UOML_t23.SI",
        "effectiveGearing": "1.1",
        "moneyness_percent": "27",
        "dwSymbol": "9KVW",
        "askPrice": "0.000000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "1220000.000000000",
        "bidVolume": "1000000.000000000",
        "underlying_ric": "UTOS.SI",
        "underlyingSymbol": "U14",
        "theta": "-0.72",
        "tradeVolume_f": "0",
        "exercisePrice": "8.50",
        "bidVolume_f": "1,000",
        "askVolume_f": "1,220",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.033000000",
        "HIGHRESP": false,
        "dwps": "10.0",
        "moneyness_c": "14% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "41.3",
        "moneyness": "OTM",
        "sensitivity": "3.17",
        "askPrice_f": "0.034",
        "delta": "31.5",
        "APPCHEXPRY": false,
        "underlying_name": "UOL GROUP LIMITE",
        "ltDate": "24 Jun 21",
        "bidPrice_f": "0.033",
        "issuer": "MBL",
        "ric": "ULML_t.SI",
        "avg_decay": "0.00018",
        "effectiveGearing": "7.0",
        "HOTLIST": true,
        "moneyness_percent": "14",
        "dwSymbol": "ORGW",
        "askPrice": "0.034000000",
        "tradeVolume": "100.000000000"
        },
        {
        "askVolume": "0.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "WLIL.SI",
        "underlyingSymbol": "F34",
        "theta": "-48.22",
        "tradeVolume_f": "0",
        "exercisePrice": "6.00",
        "bidVolume_f": "0",
        "askVolume_f": "0",
        "priceChange": "-50.000000000",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.000000000",
        "dwps": "3.5",
        "moneyness_c": "9% OTM",
        "priceChange_f": "-50.0",
        "impliedVolalitiy": "47.0",
        "moneyness": "OTM",
        "sensitivity": "6.29",
        "askPrice_f": "0.000",
        "delta": "5.6",
        "APPCHEXPRY": true,
        "underlying_name": "WILMAR INTERNATI",
        "ltDate": "15 Feb 21",
        "bidPrice_f": "0.000",
        "issuer": "MBL",
        "ric": "WLML_t8.SI",
        "avg_decay": "0.00000",
        "effectiveGearing": "43.6",
        "moneyness_percent": "9",
        "dwSymbol": "NDHW",
        "askPrice": "0.000000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "930000.000000000",
        "bidVolume": "2130000.000000000",
        "underlying_ric": "WLIL.SI",
        "underlyingSymbol": "F34",
        "theta": "-0.12",
        "tradeVolume_f": "28",
        "exercisePrice": "4.80",
        "bidVolume_f": "2,130",
        "askVolume_f": "930",
        "HIGHLQDY": true,
        "priceChange": "-1.360544218",
        "type": "C",
        "conv_ratio": "8.5",
        "bidPrice": "0.145000000",
        "dwps": "8.5",
        "moneyness_c": "14% ITM",
        "priceChange_f": "-1.4",
        "impliedVolalitiy": "54.3",
        "moneyness": "ITM",
        "sensitivity": "1.26",
        "askPrice_f": "0.147",
        "delta": "67.6",
        "APPCHEXPRY": false,
        "underlying_name": "WILMAR INTERNATI",
        "ltDate": "11 Oct 21",
        "bidPrice_f": "0.145",
        "issuer": "MBL",
        "ric": "WLML_t9.SI",
        "avg_decay": "0.00034",
        "effectiveGearing": "3.0",
        "HOTLIST": true,
        "moneyness_percent": "14",
        "dwSymbol": "NLGW",
        "askPrice": "0.147000000",
        "tradeVolume": "28000.000000000"
        },
        {
        "askVolume": "280000.000000000",
        "bidVolume": "500000.000000000",
        "underlying_ric": "WLIL.SI",
        "underlyingSymbol": "F34",
        "theta": "-0.22",
        "tradeVolume_f": "0",
        "exercisePrice": "4.50",
        "bidVolume_f": "500",
        "askVolume_f": "280",
        "priceChange": "-1.639344262",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.300000000",
        "dwps": "3.5",
        "moneyness_c": "22% ITM",
        "priceChange_f": "-1.6",
        "impliedVolalitiy": "55.9",
        "moneyness": "ITM",
        "sensitivity": "2.02",
        "askPrice_f": "0.315",
        "delta": "86.8",
        "APPCHEXPRY": false,
        "underlying_name": "WILMAR INTERNATI",
        "ltDate": "24 Mar 21",
        "bidPrice_f": "0.300",
        "issuer": "MBL",
        "ric": "WLML_t7.SI",
        "avg_decay": "0.00117",
        "effectiveGearing": "4.5",
        "moneyness_percent": "22",
        "dwSymbol": "KSCW",
        "askPrice": "0.315000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "2000000.000000000",
        "bidVolume": "2000000.000000000",
        "underlying_ric": "1810.HK",
        "underlyingSymbol": "XIAOMI-W",
        "theta": "-0.07",
        "tradeVolume_f": "51",
        "exercisePrice": "30.00",
        "bidVolume_f": "2,000",
        "askVolume_f": "2,000",
        "HIGHLQDY": true,
        "priceChange": "-1.282051282",
        "type": "C",
        "conv_ratio": "10.0",
        "bidPrice": "0.077000000",
        "dwps": "10.0",
        "moneyness_c": "2% OTM",
        "priceChange_f": "-1.3",
        "impliedVolalitiy": "65.2",
        "moneyness": "OTM",
        "sensitivity": "1.78",
        "askPrice_f": "0.079",
        "delta": "56.2",
        "APPCHEXPRY": false,
        "underlying_name": "XIAOMI-W",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.077",
        "issuer": "MBL",
        "ric": "XIML_t16.SI",
        "avg_decay": "0.00040",
        "effectiveGearing": "3.7",
        "HOTLIST": true,
        "moneyness_percent": "2",
        "dwSymbol": "WAIW",
        "askPrice": "0.079000000",
        "tradeVolume": "50600.000000000"
        },
        {
        "askVolume": "2000000.000000000",
        "bidVolume": "2000000.000000000",
        "underlying_ric": "1810.HK",
        "underlyingSymbol": "XIAOMI-W",
        "theta": "-0.21",
        "tradeVolume_f": "1",
        "exercisePrice": "20.00",
        "bidVolume_f": "2,000",
        "askVolume_f": "2,000",
        "HIGHLQDY": true,
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "3.5",
        "bidPrice": "0.022000000",
        "HIGHRESP": false,
        "dwps": "3.5",
        "moneyness_c": "47% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "53.1",
        "moneyness": "OTM",
        "sensitivity": "3.96",
        "askPrice_f": "0.023",
        "delta": "-8.8",
        "APPCHEXPRY": false,
        "underlying_name": "XIAOMI-W",
        "ltDate": "28 Jun 21",
        "bidPrice_f": "0.022",
        "issuer": "MBL",
        "ric": "XIML_t15.SI",
        "avg_decay": "0.00031",
        "effectiveGearing": "5.8",
        "HOTLIST": true,
        "moneyness_percent": "47",
        "dwSymbol": "UKKW",
        "askPrice": "0.023000000",
        "tradeVolume": "900.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "50000.000000000",
        "underlying_ric": "1810.HK",
        "underlyingSymbol": "XIAOMI-W",
        "theta": "-0.01",
        "tradeVolume_f": "0",
        "exercisePrice": "20.00",
        "bidVolume_f": "50",
        "askVolume_f": "10",
        "priceChange": "-1.075268817",
        "type": "C",
        "conv_ratio": "3.5",
        "bidPrice": "0.460000000",
        "dwps": "3.5",
        "moneyness_c": "47% ITM",
        "priceChange_f": "-1.1",
        "impliedVolalitiy": "58.1",
        "moneyness": "ITM",
        "sensitivity": "1.80",
        "askPrice_f": "0.475",
        "delta": "97.4",
        "APPCHEXPRY": false,
        "underlying_name": "XIAOMI-W",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.460",
        "issuer": "MBL",
        "ric": "XIML_t11.SI",
        "avg_decay": "-0.00025",
        "effectiveGearing": "3.0",
        "moneyness_percent": "47",
        "dwSymbol": "FWDW",
        "askPrice": "0.475000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "10000.000000000",
        "bidVolume": "0.000000000",
        "underlying_ric": "1810.HK",
        "underlyingSymbol": "XIAOMI-W",
        "theta": "-1.45",
        "tradeVolume_f": "0",
        "exercisePrice": "13.00",
        "bidVolume_f": "0",
        "askVolume_f": "10",
        "priceChange": "0.000000000",
        "type": "P",
        "conv_ratio": "3.5",
        "bidPrice": "0.001000000",
        "dwps": "3.5",
        "moneyness_c": "126% OTM",
        "priceChange_f": "0.0",
        "impliedVolalitiy": "96.2",
        "moneyness": "OTM",
        "sensitivity": "61.69",
        "askPrice_f": "0.010",
        "delta": "-0.6",
        "APPCHEXPRY": false,
        "underlying_name": "XIAOMI-W",
        "ltDate": "26 Mar 21",
        "bidPrice_f": "0.001",
        "issuer": "MBL",
        "ric": "XIML_t12.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "8.1",
        "moneyness_percent": "126",
        "dwSymbol": "UDWW",
        "askPrice": "0.010000000",
        "tradeVolume": "0.000000000"
        },
        {
        "askVolume": "3000000.000000000",
        "bidVolume": "3000000.000000000",
        "underlying_ric": "YAZG.SI",
        "underlyingSymbol": "BS6",
        "theta": "-0.22",
        "tradeVolume_f": "0",
        "exercisePrice": "1.15",
        "bidVolume_f": "3,000",
        "askVolume_f": "3,000",
        "HIGHLQDY": true,
        "priceChange": "-5.128205128",
        "type": "C",
        "conv_ratio": "5.0",
        "bidPrice": "0.037000000",
        "dwps": "5.0",
        "moneyness_c": "10% OTM",
        "priceChange_f": "-5.1",
        "impliedVolalitiy": "70.2",
        "moneyness": "OTM",
        "sensitivity": "1.94",
        "askPrice_f": "0.039",
        "delta": "51.6",
        "APPCHEXPRY": false,
        "underlying_name": "YANGZIJIANG",
        "ltDate": "25 Oct 21",
        "bidPrice_f": "0.037",
        "issuer": "MBL",
        "ric": "YZML_tx.SI",
        "avg_decay": "0.00008",
        "effectiveGearing": "2.9",
        "HOTLIST": true,
        "moneyness_percent": "10",
        "dwSymbol": "FOHW",
        "askPrice": "0.039000000",
        "tradeVolume": "0.000000000"
        }
    ]
}

// ScreenerJSONServlet?init=1&qid=1613542941798
const init = {
	"issuers": [
		"MBL"
	],
	"expiryDates": [
		"Feb 2021",
		"Mar 2021",
		"Apr 2021",
		"May 2021",
		"Jun 2021",
		"Jul 2021",
		"Aug 2021",
		"Sep 2021",
		"Oct 2021",
		"Nov 2021",
		"Dec 2021",
		"Jan 2022",
		"Feb 2022",
		"Mar 2022",
		"Apr 2022",
		"May 2022"
	],
	"underlyings": [
		{
			"underlying_ticker": "AWX",
			"underlying_name": "AEM HOLDINGS LTD"
		},
		{
			"underlying_ticker": "A17U",
			"underlying_name": "ASCEN REIT"
		},
		{
			"underlying_ticker": "BABA-SW",
			"underlying_name": "BABA-SW"
		},
		{
			"underlying_ticker": "BYD COMPANY",
			"underlying_name": "BYD COMPANY"
		},
		{
			"underlying_ticker": "C31",
			"underlying_name": "CAPLAND"
		},
		{
			"underlying_ticker": "C09",
			"underlying_name": "CITY DEVELOPMENT"
		},
		{
			"underlying_ticker": "C52",
			"underlying_name": "COMFORTDELGRO LD"
		},
		{
			"underlying_ticker": "D05",
			"underlying_name": "DBS"
		},
		{
			"underlying_ticker": "GEELY AUTO",
			"underlying_name": "GEELY AUTO"
		},
		{
			"underlying_ticker": "G13",
			"underlying_name": "GENTINGSIN"
		},
		{
			"underlying_ticker": "HSI",
			"underlying_name": "HSI"
		},
		{
			"underlying_ticker": "AIY",
			"underlying_name": "IFAST CORPORATIO"
		},
		{
			"underlying_ticker": "JD-SW",
			"underlying_name": "JD-SW"
		},
		{
			"underlying_ticker": "BN4",
			"underlying_name": "KEPCORP"
		},
		{
			"underlying_ticker": "N2IU",
			"underlying_name": "MAPLETREE COMMER"
		},
		{
			"underlying_ticker": "MEITUAN-W",
			"underlying_name": "MEITUAN-W"
		},
		{
			"underlying_ticker": "N225",
			"underlying_name": "N225"
		},
		{
			"underlying_ticker": "O39",
			"underlying_name": "OCBC"
		},
		{
			"underlying_ticker": "S&P500",
			"underlying_name": "S&P500"
		},
		{
			"underlying_ticker": "S68",
			"underlying_name": "SGX"
		},
		{
			"underlying_ticker": "C6L",
			"underlying_name": "SINGAPORE AIRLIN"
		},
		{
			"underlying_ticker": "Z74",
			"underlying_name": "SINGTEL"
		},
		{
			"underlying_ticker": "STI",
			"underlying_name": "STI"
		},
		{
			"underlying_ticker": "SUNNY OPTICAL",
			"underlying_name": "SUNNY OPTICAL"
		},
		{
			"underlying_ticker": "TENCENT",
			"underlying_name": "TENCENT"
		},
		{
			"underlying_ticker": "BVA",
			"underlying_name": "TOP GLOVE CORPOR"
		},
		{
			"underlying_ticker": "U11",
			"underlying_name": "UOB"
		},
		{
			"underlying_ticker": "U14",
			"underlying_name": "UOL GROUP LIMITE"
		},
		{
			"underlying_ticker": "F34",
			"underlying_name": "WILMAR INTERNATI"
		},
		{
			"underlying_ticker": "XIAOMI-W",
			"underlying_name": "XIAOMI-W"
		},
		{
			"underlying_ticker": "BS6",
			"underlying_name": "YANGZIJIANG"
		}
	]
}

const searchData = {
    "init": init,
    "underlyingAll": underlyingAll
}

export default searchData;


