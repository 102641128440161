
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";

export default class gearingeffect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div className="">
                <div>
                    <div>
                        <div>
                            <div id="mainnav">
                                <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60, color: '#000' }}>
                                    <div id="mainscroll" style={{ color: '#000', height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                        <div id="mainscrollcontent">
                                            <div className="contentbox">
                                                <p>A warrant enables you to gain exposure to a security for just a fraction of its
                                                    price, meaning you can get a similar effective exposure for a much smaller capital
                                                    outlay. Therefore, warrants tend to move in greater percentages than the underlying
                                                    shares, allowing you the potential for higher percentage returns than if you had
                                                    bought the shares directly. This is known as the gearing effect and the main reason
                                                    why warrants are so popular with investors.</p>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/Script_0102-warrent-performance-graph_enlarge.jpg" alt />
                                                <div className="visible-xs" style={{ color: '#FFD843' }} onClick={() => this.openImg('/img/education/Script_0102-warrent-performance-graph_enlarge.jpg')}>View chart</div>
                                            </div>
                                            <div className="videoplayer-container" style={{
                                                height: '75vh',
                                                maxWidth: '440px',
                                                margin: ' 0 auto'
                                            }}>
                                                <iframe
                                                    title="Market View"
                                                    frameBorder="0"
                                                    allowFullScreen=""
                                                    height="100%"
                                                    width="100%"
                                                    src="https://www.youtube.com/embed/C4BYMsQMFTE?autoplay=0&index=0&rel=0&wmode=opaque"
                                                // src={link}
                                                ></iframe>
                                            </div>
                                            <p>
                                                <a href="/WarrantsGuidebook/WhyTradeWarrantsPlaylist" style={{ color: '#000', fontSize: '16px', fontWeight: 'bold' }}>Warrants Short Video Playlist</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}