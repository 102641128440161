import React, { Component } from "react";
import "./search.css";
import MQSGUtil from "../../../components/mqsgUtil";
export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      dataList: [],
      show: true,
    };
  }
  componentDidMount() {
    this.setState(
      {
        value: this.props.history.location.state.key,
      },
      () => {
        this.getData();
      }
    );
  }
  change(e) {
    this.setState({
      value: e.target.value,
    });
  }
  onKeyDownchange(e) {
    if (e.keyCode === 13) {
      this.getData();
    }
  }
  getData() {
    const url =
      MQSGUtil.getAPIBasePath() +
      "/PageSearchJSON?key=" +
      this.state.value +
      "&lang=en";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          //   if (result.length > 0) {
          this.setState({
            dataList: result,
            //   show: false,
          });
          //   } else {
          //     this.setState({
          //       show: true,
          //     });
          //   }
        },
        (error) => { }
      );
  }
  render() {
    return (
      <div id="pagesearch" className="">
        <div className="pagesearch-top">
          <div className="container-fluid">
            <div className="psearchbox input-group">
              <input
                type="text"
                name="keywords"
                className="form-control"
                value={this.state.value}
                placeholder="Search all of warrants.com.sg"
                onChange={(e) => {
                  this.change(e);
                }}
                onKeyDown={(e) => this.onKeyDownchange(e)}
              />
              <div className="input-group-btn">
                <button
                  className="btn btn-default"
                  type="submit"
                  onClick={() => this.getData()}
                >
                  <i className="glyphicon glyphicon-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pagesearch-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="search-resultbox"
                  style={{ position: "relative" }}
                >
                  <div
                    className="search-resultbox"
                    style={{
                      display:
                        this.state.dataList.length === 0 ? "block" : "none",
                    }}
                  >
                    <div className="nomatch">
                      Your search did not match any documents
                    </div>
                  </div>
                  {this.state.dataList.map((p, i) => {
                    return (
                      <div className="search-result resulttmpl" index={i}>
                        <a
                          href={"/" + p.page_url}
                          className="search-result-title"
                        >
                          {p.page_title}
                        </a>
                        <div className="seach-result-hashtag tagbar">
                          <ul
                            className="taglist list-inline"
                            style={{ display: "none" }}
                          >
                            <li className="taglist-item">
                              <div className="taglist-item-img">Tags:</div>
                            </li>
                          </ul>
                        </div>
                        <div className="search-result-dsply"></div>
                        <a
                          href={"/" + p.page_url}
                          className="search-result-achor"
                        >
                          {p.page_url}
                        </a>
                      </div>
                    );
                  })}
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
