
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class effectivegearing extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div >
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>
                                                A similar indicator to ‘delta’, it combines gearing and delta to express the warrant price movement as a percentage.
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/effective_gearing_formula.png" alt="effective gearing formula" />
                                            </div>
                                            <p>There are two main uses for effective gearing:</p>
                                            <ol className="edulist">
                                                <li style={{ color: '#000' }}>
                                                    <p className="ttl"><b>Indicate the % change in the price of a warrant relative to a 1%
                                                        change in the underlying</b></p>
                                                    <p>Take for example a warrant with an effective gearing of 10x. This means, the warrant should move approximately 10% for a 1% movement in the underlying share.
                                                    </p>
                                                    <div className="formulaholder">
                                                        <div className="formulabox">
                                                            <p><span className="leqt">1% change in price of stock x 10</span> = 10% change in price of Warrant</p>
                                                        </div>
                                                    </div>
                                                    <p>A higher effective gearing generally translates to a higher profit potential and also a higher level of risk.</p>
                                                </li>
                                                <li style={{ color: '#000' }}>
                                                    <p className="ttl"><b>Helps you decide how much to invest</b></p>
                                                    <p>
                                                        With effective gearing, you can estimate the effective stock exposure in the underlying asset that a warrant holder gains by holding the warrant.<br /><br /> Take for example a warrant with an effective gearing of 10x.
                                                        This means if an investor buys $10,000 worth of the warrant they will have an effective stock exposure of $100,000.
                                                    </p>
                                                    <div className="formulaholder">
                                                        <div className="formulabox">
                                                            <p><span className="leqt">SGD 10,000 Warrant investment x 10</span> = SGD 100,000 effective stock exposure</p>
                                                        </div>
                                                    </div>
                                                    <p>Similarly, if you are currently holding $200,000 worth of stocks and wish to release some of these share capital without losing the stock exposure, you can do so by purchasing $20,000 worth of a warrant with an effective
                                                        gearing of 10x.
                                                    </p>
                                                    {/*                    <div class="formulaholder">
                                                              <div class="formulabox">
                                                                  <p><span class="leqt">SGD 200,000 effective stock exposure / 10</span> = SGD 20,000 Warrant investment</p>
                                                              </div>
                                                          </div>*/}
                                                </li>
                                            </ol>
                                            <div className="imgholder">
                                                <img src="/img/education/Bar-Chart-of-$200,000-of-Shares-$20,000-of-Call-Warrant.jpg" />
                                            </div>
                                            <div className="tipholder">
                                                <h2>Helpful Tips on how to use Effective Gearing</h2>
                                                <ul>
                                                    <li style={{ listStyleType: 'disc', color: '#000' }}>
                                                        <p>You can view the effective gearing for all warrants via the <a href="/tools/warrantsearch" style={{ color: 'red' }}>Warrant Search Tool</a> and the <a href="/tools/warrantterms" style={{ color: 'red' }}>Warrant Terms</a> page.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}