import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SpeaksPopup from './speakspopup';
import MQSGUtil from "../../../components/mqsgUtil";
import './speaks.css'

export default class Speaks extends Component {
    componentDidMount() {
        document.title = "Success Speaks | Singapore Warrants | Macquarie"
    }
    render() {
        return (
            <div id="campaign" className="pageobj">
                <div className="top-background">
                    <div className="top-txtarea container-fluid">
                        <h2>Success Speaks</h2>
                        <h3>What does it take to be a warrant trader?</h3>

                    </div>
                </div>
                <VideoBox />
                <div className="hrbar">
                    <div className="container-fluid">
                        Learn more about structured warrants
                    </div>
                </div>
                <div className="bttm">

                    <div className="container-fluid">
                        <div className="bttm-row row">
                            <div className="col-sm-6">
                                <div className="bttm-item-left">
                                    <p>
                                        Hear what these investors have to say about their warrant trading experience. Introducing Wong Kon How, Wang Tsu Wei and Zen Low, who are sharing their warrant trading insights with you! At Macquarie Warrants, we believe that you too can be a warrant trader if you are willing to put in effort to improve your skills. We hope that these stories will inspire you to start your warrant trading journey today.
                                    </p>
                                    {/* <a href="/edu/investormy.html" target="_blank" type="button" 
                                onclick="gtag('event', 'Click', {'event_category' : 'LearnMore',});" 
                                className="btn btn-primary btn-lm">Learn more</a> */}
                  <a href="/WarrantsGuidebook" target="_blank" type="button"
                    className="btn btn-primary btn-lm">Learn more</a>

                                </div>
                            </div>
                            <div className="col-sm-6">
                                <SubmitBox />
                            </div>
                            <div className="col-xs-12 bttm-disclaimer-holder">
                                <p className="bttm-disclaimer small">
                                    By clicking “Submit”, you confirm that you would like to receive Macquarie’s daily newsletter and invitations for warrant seminars, and you acknowledge that Macquarie’s website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities (Singapore) Pte Ltd and other companies of Macquarie group (“Macquarie”). You may view Macquarie’s privacy policy with regard to collection and management of personal data via the links at the bottom of this page.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class VideoBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showID: "",
            videoShow: false
        }
    }

    isShow(id) {
        if (id === this.state.showID) {
            return true;
        }
        return false;
    }

    onClickImage(showID) {
        this.setState({ showID, videoShow: true });
    }

    onClose(id) {
        this.setState({ showID: "", videoShow: false });
    }

    render() {
        const speakersPopupLink = "/education/speakerspopup/"
        return (
            <div className="videobox container-fluid">
                <div id="popup-video-bg" style={{
                    display: this.state.videoShow ? 'block' : 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1000,
                    backgroundColor: 'rgba(255,255,255,0.5)'
                }}></div>
                <div className="row is-table-row">
                    <div className="col-sm-4">
                        <div className="videobox-item">
                            <div className="videobox-item-imgarea">
                                {/* <img onClick="gtag('event', 'VideoClick', {'event_category': 'WangTsuWei',});" 
                                    className="videolink" data-source="nuCGB9MPigY" data-title="Wang Tsu Wei" 
                                    src="/img/education/campaign_screen2.jpg?v=1" alt=""/>  */}

                                <img className="videolink" data-source="nuCGB9MPigY" data-title="Wang Tsu Wei"
                                    src="/img/education/campaign_screen2.jpg?v=1" alt=""
                                    onClick={() => this.onClickImage("nuCGB9MPigY")} />
                                <SpeaksPopup id="nuCGB9MPigY" show={this.isShow("nuCGB9MPigY")}
                                    onClose={(id) => this.onClose(id)} />
                            </div>
                            <div className="videobox-item-txtarea">
                                <h3 className="videobox-h3-clickable">Wang Tsu Wei</h3>
                                <hr className="hidden-xs" />
                                <p className="hidden-xs">Tsu Wei is an ex-warrant market maker turned remisier. He went from working at a warrant issuer as a market-maker, to trading warrants daily for his own account. Apart from his own trading, he also helps clients execute their stock and warrant trades.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="videobox-item">
                            <div className="videobox-item-imgarea">
                                {/* <img onClick="gtag('event', 'VideoClick', {'event_category' : 'WongKonHow',});" 
                                    className="videolink" data-source="BhH9FH-v1T4" data-title="Wong Kon How" 
                                    src="/img/education/campaign_screen1.jpg?v=1" alt=""/> */}
                                <img className="videolink" data-source="BhH9FH-v1T4" data-title="Wong Kon How"
                                    src="/img/education/campaign_screen1.jpg?v=1" alt=""
                                    onClick={() => this.onClickImage("BhH9FH-v1T4")} />
                                <SpeaksPopup id="BhH9FH-v1T4" show={this.isShow("BhH9FH-v1T4")}
                                    onClose={(id) => this.onClose(id)} />

                            </div>
                            <div className="videobox-item-txtarea">
                                <h3 className="videobox-h3-clickable">Wong Kon How</h3>
                                <hr className="hidden-xs" />
                                <p className="hidden-xs">Kon How is an investment strategist and founder of his company, Weipedia Private Limited. He is a frequent speaker at local and overseas seminars, sharing with investors his trading experience of more than 20 years. He is also an SGX Academy professional trainer with the Singapore Exchange.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="videobox-item">
                            <div className="videobox-item-imgarea">
                                {/* <img onClick="gtag('event', 'VideoClick', {'event_category' : 'ZenLow',});" 
                                    className="videolink" data-source="HNRK5QNs_1Y" data-title="Zen Low" 
                                    src="/img/education/campaign_screen3.jpg?v=3" alt=""/> */}
                                <img className="videolink" data-source="HNRK5QNs_1Y" data-title="Zen Low"
                                    src="/img/education/campaign_screen3.jpg?v=3" alt=""
                                    onClick={() => this.onClickImage("HNRK5QNs_1Y")} />
                                <SpeaksPopup id="HNRK5QNs_1Y" show={this.isShow("HNRK5QNs_1Y")}
                                    onClose={(id) => this.onClose(id)} />
                            </div>
                            <div className="videobox-item-txtarea">
                                <h3 className="videobox-h3-clickable">Zen Low</h3>
                                <hr className="hidden-xs" />
                                <p className="hidden-xs">Zen is one of the winners of Macquarie's Surface Pro contest, which was held in January 2018 to celebrate the launch of Macquarie's new warrant website in Singapore. Hear what Zen has to say about warrant trading and his thoughts on the new Macquarie website!</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

class SubmitBox extends Component {
    constructor() {
        super();
        this.state = {
          name: '',
          email: '',
          nameWarning: false,
      invalid: false,
      emailWarning: false,
      httpEmailText:false
        };
      }
    gtag(str) {
    }
    setEmail(e) {
        this.setState({
          email: e.target.value
        })
      }
    setName(e) {
        this.setState({
          name: e.target.value
        })
    }
    seminarreg() {
        const url = MQSGUtil.getAPIBasePath() + "/seminarreg";
        fetch(
          url,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            // body: 'email=' + this.state.email + '&firstName=' + this.state.name + '&seminar=' + this.state.id + '&lang=en',
            body: 'email=' + this.state.email + '&firstName=' + this.state.name + '&lang=en',
          }
        ).then((res) => res.json()).then(
          (result) => {
          },
          (error) => {
          }
        );
      }
      submit() {
        if (this.state.name == '') {
          this.setState({
            nameWarning: true,
          })
        } else {
          this.setState({
            nameWarning: false,
          })
        }
        if (this.state.email == '') {
          this.setState({
            emailWarning: true,
            invalid: false
          })
        } else {
          this.setState({
            emailWarning: false,
          })
        }
        var emailPat = /^(.+)@(.+)$/;
        var matchArray = this.state.email.match(emailPat);
        if (this.state.email != '' && matchArray == null) {
          this.setState({
            invalid: true,
            emailWarning: false,
          })
        } else {
          this.setState({
            invalid: false,
          })
        }
        if (matchArray == null) {
        } else {
          const url = MQSGUtil.getAPIBasePath() + "/registration?p=register";
          fetch(
            url,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              method: 'POST',
              body: 'email=' + this.state.email
            }
          ).then((res) => res.json()).then(
            (result) => {
              this.seminarreg();
              this.setState({
                httpEmailText: true,
                emailWarning: false,
                nameWarning: false,
                invalid: false
              }, () => {
                window.setTimeout(() => {
                  this.setState({
                    httpEmailText: false,
                    emailWarning: false,
                    nameWarning: false,
                    invalid: false
                  })
                }, 3000)
    
              })
            },
            (error) => {
            }
          );
        }
      }
    render() {
        return (
            <div className="bttm-item-right">
                <div className="notiform">
                    <p>Receive notifications on new videos and daily market highlights</p>
                    <input id="sname" type="text" className="form-control" placeholder="Name"  onChange={(e) => { this.setName(e) }}/>
                    {/* <div id="sname-warning" className="warning">* Fill in name</div> */}
                    <div className="warning" style={{ visibility: this.state.nameWarning ? "visible" : 'hidden',marginBottom: '22px' }}>Name as per NRIC is required. Please fill in.</div>
                    <input id="email" type="text" className="form-control" placeholder="Email"  onChange={(e) => { this.setEmail(e) }}/>
                    <div className="warning" style={{ display: this.state.invalid ? "block" : 'none' }}>* Invalid email</div>
                    <div className="warning" style={{ visibility: this.state.emailWarning ? "visible" : 'hidden' }}>Email is required. Please fill in.</div>
                    {/* <div id="email-warning" className="warning">* Fill in valid email</div> */}
                    {/* <button id="submitBtn" className="btn btn-primary" onclick={this.gtag('event', 'newsletter', {'event_category' : 'subscribe',})}>
                        Submit
                    </button> */}
                    <button id="submitBtn" className="btn btn-primary"  onClick={() => { this.submit() }}>
                        Submit
                    </button>
                </div>
                <div id="popup-customized" style={{ display: this.state.httpEmailText ? 'table' : 'none' }}>
                    <div>
                        <h5>Your seminar registration was successful. Thank you</h5>
                    </div>
                </div>
            </div>
        )

    }
}