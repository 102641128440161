
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class warrantsensitivity extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div>
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <div className="videotxt hidden-xxs hidden-xs">
                                                <p>In this episode we are going to go through another term you need to know when you’re trading warrants. So far, we have looked at delta and warrants per share both of which will be key in helping us derive a warrant’s sensitivity.</p>
                                            </div>
                                            <p>A warrant’s “sensitivity” is an estimate of how much the underlying price needs to move for a corresponding one tick (or minimum spread) movement in the price of the warrant. We use delta and WPS to calculate the sensitivity
                                                of the warrant to the underlying.</p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/ws_dbs_call_example.jpg" />
                                            </div>
                                            <p>
                                                In this example we’re using a call warrant over DBS bank shares, the warrant has a price of 12 cents.<br /><br /> The WPS is 5 and the warrant expires on 1 December. The delta is 50 per cent, which means it has an even chance
                                                of being in the money when it expires.
                                            </p>
                                            <h2>Warrant Sensitivity</h2>
                                            <p>
                                                To calculate how many ticks in the underlying is required for a one tick move in the warrant (i.e. minimum tick of $0.001 for warrants priced below $0.200), you first need to know the delta per warrant. You’d recall from the previous chapter on “Delta”
                                                that the delta formula is as below:
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/Delta-Formula_enlarge.jpg" />
                                            </div>
                                            <p>
                                                To calculate the “Delta per warrant”, you divide 50 per cent by the WPS of 5, to get a figure of 10 per cent. Now that you have the delta per warrant, you can calculate the sensitivity, ie. how much the underlying will move for a 1 tick move ($0.001)
                                                in the warrant.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/sensitivity-Formula_enlarge.jpg" />
                                            </div>
                                            <p>
                                                Simply divide the change in warrant price of $0.001 by the delta per warrant of 10%, and you would arrive at a sensitivity of $0.01. Meaning that each time the share price moves $0.01, the warrant price will move one tick, or $0.001.<br /><br />                                With a sensitivity of $0.01, which is 1 tick in DBS shares (as with all Singapore stocks above $2), we say that this warrant moves “tick for tick”, which means it’s price will move one tick for every tick in the underlying
                                                share.
                                                <br /><br /> For index warrants, the same calculation can be used. Let’s look at an example.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/ws_stl_call_example.jpg" />
                                            </div>
                                            <p>
                                                In the case of the above STI warrant, the delta per warrant can be calculated by dividing the delta of 50% by the WPS of 2,500, which gives 0.0002. Hence, for the warrant to move 1 tick ($0.001), the STI will therefore have to move, $0.001 divided by
                                                0.0002, which is 5 points. This is the warrant’s sensitivity.
                                            </p>
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful Tips on how to use Warrant Sensitivity</h2>
                                                    <ul>
                                                        <li>
                                                            <p>Always remember to take into account WPS when looking at sensitivity</p>
                                                        </li>
                                                        <li>
                                                            <p>Warrant sensitivity is primarily used by short term day traders and investors interested in the small/short term movements in a warrant. For all other investors, Delta or Effective Gearing are likely to be more
                                                                useful.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p>You can view the warrant sensitivity for each warrant via <a href="/tools/warrantterms" style={{ color: 'red' }}>the warrants terms page.</a></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}