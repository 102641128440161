import React, { Component } from "react";
import PageHeader from "../../../components/pageHeader/pageHeader";
import "./importantInformation.css";
import BreadcrumbBar from "../../../components/breadcrumbBar";

export default class ImportantInformationextends extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="importantinformation" className="pageobj">
        <BreadcrumbBar
          link="/home"
          group="Macquarie"
          title="Important information"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header">
                  <h1>Important information</h1>
                </div>
                <div className="title">This Website</div>
                <p>
                  This web site is operated by and on behalf of Macquarie Bank
                  Limited ("Macquarie") and its related bodies corporate (the
                  "Macquarie Group"). Macquarie Bank Limited is incorporated in
                  Australia (Company Registration number: ABN 46 008 583 542)
                  and is an approved deposit-taking institution in Australia
                  regulated by the Australian Prudential Regulation Authority.
                  Details of the assets and liabilities of Macquarie Bank
                  Limited may be found in the annual report of Macquarie Bank
                  Limited which may be obtained from the offices Macquarie
                  Capital Securities (Singapore) Pte Limited,9 Straits View
                  #21-07 Marina One West Tower Singapore 018937, Singapore or
                  extracted from the following website{" "}
                  <a href="http://www.macquarie.com.au" style={{ color: '#0675C2' }}>www.macquarie.com.au</a>
                </p>
                <p>
                  <strong>
                    MBL, acting through its Singapore branch is authorised and
                    licensed by the Monetary Authority of Singapore to carry on
                    wholesale banking business in Singapore pursuant to the
                    Banking Act, Chapter 19 of Singapore and therefore is
                    subject to the supervision of the Monetary Authority of
                    Singapore.
                  </strong>
                </p>
                <p>
                  The information on this site is subject to change without
                  notice and, accordingly, the Macquarie Group recommends that
                  you make direct contact with Macquarie Group staff before
                  acting upon material on this site.
                </p>
                <p>
                  The information on this Internet site is directed and
                  available to residents of Singapore only, and is not an offer
                  or solicitation for the purchase of securities, units or
                  investments, unless expressly stated otherwise. While
                  Macquarie provides the information in good faith, it accepts
                  no responsibility for the accuracy, completeness or timeliness
                  of the information.
                </p>
                <br />
                <div className="title">
                  Software applications accessible from this site
                </div>
                <p>
                  This site may contain links to other web sites operated by
                  entities which do not belong to the Macquarie Group. These
                  links have been provided solely for you to obtain further
                  information about other relevant products and entities in the
                  market. The Macquarie Group has no control over the
                  information on these sites or the products or services on
                  them, and therefore makes no representations regarding the
                  accuracy or suitability of the information, services, or
                  products described on them. You are advised to make your own
                  enquiries in relation to third parties described or linked on
                  this site. Inclusion of a link to a third party site should
                  not be construed as that party's endorsement of this site.
                </p>
                <p>
                  By linking to sites operated by third parties, the Macquarie
                  Group is not authorising the reproduction of any material on
                  such sites, as such material may be the subject of
                  intellectual property rights.
                </p>
                <br />
                <div className="title">
                  Software applications accessible from this site
                </div>
                <p>
                  Some of the software programs that may be downloaded via links
                  from this site are products belonging to third parties. You
                  use such third party software at your own risk. Such software
                  is likely to be subject to licensing terms imposed by the
                  owner of the software.
                </p>
                <p>
                  To the full extent permitted by law, the Macquarie Group
                  excludes all liability in relation to using or downloading any
                  software (third party or otherwise) that may be accessed from
                  this site. The Macquarie Group makes no representations and
                  provides no warranties in relation to such software. In
                  particular, to the full extent permitted by law, the Macquarie
                  Group excludes all liability for any damage (including,
                  without limitation, loss of data, interruption to business and
                  loss of profits) resulting from viruses or any other
                  consequence of using or downloading any software (third party
                  or otherwise) accessible via this site.
                </p>
                <br />
                <div className="title">Copyright and Trademarks</div>
                <p>
                  The Macquarie Group is either the owner or licensed user of
                  the copyright in the material on this site. You may not
                  reproduce, adapt, upload, link, frame, broadcast, distribute
                  or in any way transmit the material on this site without the
                  written consent of the Macquarie Group, other than to the
                  extent necessary to view the material or as permitted by law.
                </p>
                <p>
                  The Macquarie name and all its associated trademarks
                  (including, without limitation, the Holey Dollar device mark)
                  are trademarks of the Macquarie Group.
                </p>
                <br />
                <div className="title">Privacy</div>
                <p>
                  Macquarie Capital Securities (Singapore) Pte. Limited and
                  other companies of Macquarie Group (“Macquarie”) intend to use
                  your personal data provided via this website for the purposes
                  of marketing and advertising of financial products including
                  using your data for market research and analysis, and sending
                  newsletters and seminars/focus group invitation to you
                  (including sending product information by email or via post to
                  the address provided by you). We may engage data processers
                  (whether within or outside Singapore) such as event
                  coordinating agents or public relation companies to process
                  personal data on behalf of Macquarie. Administrator of this
                  website may also have access to your personal data in the
                  process of website management. Provision of your personal data
                  is voluntary. We may not so use your personal data unless we
                  have received your consent.
                </p>
                <p>
                  After providing your personal data, you may request to access
                  or correct your personal data or request us to cease to use
                  your personal data. Such request should be sent to Derivatives
                  Warrant Department, Macquarie Capital Securities (Singapore)
                  Pte. Limited at 9 Straits View #21-07 Marina One West Tower
                  Singapore 018937. We will process your request free of charge.
                </p>
                <p>
                  If you choose to give us personal information via the Internet
                  that we may need - for example to correspond with you, send a
                  prospectus or brochure - it is our intent to let you know how
                  we will use such information. Under certain circumstances, it
                  may be necessary to email you announcements or updates
                  containing important information about the service you
                  subscribe to.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
