import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdvertBox from "../../../components/advert/advert";
import "./started.css";

export default class started extends Component {
  componentDidMount() {
    document.title = "How to get started | Singapore Warrants | Macquarie"
  }
  render() {
    return (
      <div id="wheretotrade" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <a href="/home/edu">Warrant education</a>
              </li>
              <li className="active">How to get started</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h2>How to get started</h2>
                </div>
                <p>Tap on one of the steps below or scroll down for more.</p>
                <StartTop />
                <div id="section1" className="pointer_tiger"></div>
                <div className="section1 section_area areaMargin">
                  <div className="row" style={{ padding: "10px" }}>
                    <div className="col-sm-2 section_number hidden-xs">
                      <p style={{ marginLeft: "15px", marginTop: "-25px" }}>
                        1
                      </p>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                      <div className="section_title">
                        <span className="visible-xs section_number">1 </span>
                        <span
                          style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            color: "#333333",
                          }}
                        >
                          Knowledge
                        </span>
                      </div>
                      <p className="section_content">
                        Before you start trading, the first thing you need to do
                        is improve your knowledge in structured warrants! Never
                        buy something you don’t understand. Check out some of
                        our learning resources:
                      </p>
                      <div className="row" style={{ marginRight: "0px" }}>
                        <div className="col-xs-4">
                          <div>
                            <a
                              href="/education/marketcommentary/Educational%20Videos/2sWqTvnYqrA/0"
                              target="_blank"
                            >
                              <img
                                src="/img/started/Knowledge3.png"
                                style={{ width: "100%" }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div>
                            <a href="/education/seminar" target="_blank">
                              <img
                                src="/img/started/Knowledge2.png"
                                style={{ width: "100%" }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div>
                            <a href="/WarrantsGuidebook" target="_blank">
                              <img
                                src="/img/started/image125.png"
                                style={{ width: "100%" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="section2" className="pointer_tiger"></div>
                <SectionTwo />
                <div id="section3" className="pointer_tiger"></div>
                <SeactionThree />
                <div id="section4" className="pointer_tiger"></div>
                <div className="section4 section_area areaMargin">
                  <div className="row">
                    <div className="col-sm-10 col-xs-12 move-right-sm">
                      <div className="section_title">
                        <span className="visible-xs section_number">4 </span>
                        <span
                          style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            color: "#333333",
                          }}
                        >
                          Selecting a warrant
                        </span>
                      </div>
                      <p className="section_content">
                        Now that you’re all set up, how do you select a
                        structured warrant that is right for you? Check out
                        Episode 3 of our animated videos for a short explanation
                        on the selection process, or use our Warrant search tool
                        to find warrants that match your investment criteria.
                        <br />
                        <br />
                        If you are trading a structured warrant listed by
                        Macquarie, you should always refer to the Live matrix to
                        see where Macquarie’s bid and offer prices will be in
                        the warrant for various levels in the underlying.{" "}
                      </p>
                      <div className="row">
                        <div className="col-xs-4">
                          <div>
                            <a
                              href="/education/marketcommentary/Educational%20Videos/2sWqTvnYqrA/0"
                              target="_blank"
                            >
                              <img
                                src="/img/started/Selecting1.png"
                                style={{ width: "100%" }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div>
                            <a href="/tools/warrantsearch" target="_blank">
                              <img
                                src="/img/started/Selecting2.png"
                                style={{ width: "100%" }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-xs-4">
                          <div>
                            <a href="/tools/livematrix" target="_blank">
                              <img
                                src="/img/started/Selecting3.png"
                                style={{ width: "100%" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <p
                        className="section_content"
                        style={{ paddingTop: "10px" }}
                      >
                        You may also refer to the Warrant hot list on our
                        homepage.{" "}
                      </p>
                    </div>
                    <div className="col-sm-2 section_number hidden-xs">
                      <p style={{ marginTop: "-25px" }}>4</p>
                    </div>
                  </div>
                </div>
                <div id="section5" className="pointer_tiger"></div>
                <div className="section5 section_area areaMargin">
                  <div className="row">
                    <div className="col-sm-2 section_number hidden-xs">
                      <p style={{ marginLeft: "20px", marginTop: "-25px" }}>
                        5
                      </p>
                    </div>
                    <div className="col-sm-10 col-xs-12">
                      <div className="section_title">
                        <span className="visible-xs section_number">5 </span>
                        <span
                          style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            color: "#333333",
                          }}
                        >
                          How to buy or sell structured warrants?
                        </span>
                      </div>
                      <p className="section_content">
                        On your broker’s trading platform, you will be able to
                        view all structured warrants listed on the SGX, just
                        like shares. Once you have used the tools on Macquarie’s
                        website to select the right structured warrant for you,
                        you can simply buy or sell in the same way that you buy
                        or sell shares. If you are unsure of how to buy or sell
                        warrants, please contact your broker.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div id="section6" className="pointer_tiger"></div>
                <div className="section6 section_area areaMargin">
                  <div className="row">
                    <div className="col-sm-10 col-xs-12 move-right-sm">
                      <div className="section_title">
                        <span className="visible-xs section_number">6 </span>
                        <span
                          style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            color: "#333333",
                          }}
                        >
                          Constant support and updates
                        </span>
                      </div>
                      <p className="section_content">
                        If you need any assistance, please contact us at +65
                        6601 0289 or info@warrants.com.sg.
                        <br />
                        <br />
                        You can also subscribe to our newsletter for daily
                        highlights and important updates of the warrants market!{" "}
                      </p>
                    </div>
                    <div className="col-sm-2 section_number hidden-xs">
                      <p style={{ marginTop: "-25px" }}>6</p>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ textAlign: "center" }}>
                      <a href="/marketnews/subscribe" target="_blank">
                        <img src="/img/started/Subscribe.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <p style={{ fontStyle: "italic" }}>
                  Important notice: Investment in warrants involves risk. These
                  links to third party websites do not constitute an offer, a
                  solicitation of an offer, or any advice or recommendation to
                  participate in any transaction. You should consult your own
                  professional advisors to ensure that any decision made is
                  suitable with regards to your circumstances and financial
                  position. Please note that the list of brokers is not
                  exhaustive and the inclusion of the brokers on the list does
                  not imply that the brokers or their services are endorsed by
                  Macquarie. The brokers mentioned on this page are independent
                  and Macquarie is not responsible for any services provided by
                  them.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <AdvertBox />
              <Search />
              <Terms />
              <Calculator />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class StartTop extends Component {
  render() {
    return (
      <div className="area_top">
        <div className="row hidden-xs">
          <div className="col-sm-2 top-pointer">
            <div>
              <a href="#section1">1</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-sm-2 top-pointer">
            <div>
              <a href="#section2">2</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-sm-2 top-pointer">
            <div>
              <a href="#section3">3</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-sm-2 top-pointer">
            <div>
              <a href="#section4">4</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-sm-2 top-pointer">
            <div>
              <a href="#section5">5</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-sm-2 top-pointer">
            <div>
              <a href="#section6">6</a>
            </div>
          </div>
        </div>
        <div className="row hidden-xs">
          <div className="col-sm-2 top-img">
            <a href="#section1">
              <div>
                <img src="/img/started/1.png" />
              </div>
              <div className="top_img_text">Knowledge</div>
            </a>
          </div>
          <div className="col-sm-2 top-img">
            <a href="#section2">
              <div>
                <img src="/img/started/2.png" />
              </div>
              <div className="top_img_text">Brokerage account</div>
            </a>
          </div>
          <div className="col-sm-2 top-img">
            <a href="#section3">
              <div>
                <img src="/img/started/3.png" />
              </div>
              <div className="top_img_text">SIP requirements</div>
            </a>
          </div>
          <div className="col-sm-2 top-img">
            <a href="#section4">
              <div>
                <img src="/img/started/4.png" />
              </div>
              <div className="top_img_text">Selecting a warrant</div>
            </a>
          </div>
          <div className="col-sm-2 top-img">
            <a href="#section5">
              <div>
                <img src="/img/started/5.png" />
              </div>
              <div className="top_img_text">How to buy or sell warrants </div>
            </a>
          </div>
          <div className="col-sm-2 top-img">
            <a href="#section6">
              <div>
                <img src="/img/started/6.png" />
              </div>
              <div className="top_img_text">Constant support</div>
            </a>
          </div>
        </div>
        <div className="row visible-xs">
          <div className="col-xs-4 top-pointer">
            <div>
              <a href="#section1">1</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-xs-4 top-pointer">
            <div>
              <a href="#section2">2</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-xs-4 top-pointer">
            <div>
              <a href="#section3">3</a>
            </div>
            <div className="top_line"></div>
          </div>
        </div>
        <div className="row visible-xs">
          <div className="col-xs-4 top-img">
            <a href="#section1">
              <div>
                <img src="/img/started/1.png" />
              </div>
              <div className="top_img_text">Knowledge</div>
            </a>
          </div>
          <div className="col-xs-4 top-img">
            <a href="#section2">
              <div>
                <img src="/img/started/2.png" />
              </div>
              <div className="top_img_text">Brokerage account</div>
            </a>
          </div>
          <div className="col-xs-4 top-img">
            <a href="#section3">
              <div>
                <img src="/img/started/3.png" />
              </div>
              <div className="top_img_text">SIP requirements</div>
            </a>
          </div>
        </div>
        <div className="row visible-xs">
          <div className="col-xs-4 top-pointer">
            <div>
              <a href="#section4">4</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-xs-4 top-pointer">
            <div>
              <a href="#section5">5</a>
            </div>
            <div className="top_line"></div>
          </div>
          <div className="col-xs-4 top-pointer">
            <div>
              <a href="#section6">6</a>
            </div>
          </div>
        </div>
        <div className="row visible-xs">
          <div className="col-xs-4 top-img">
            <a href="#section4">
              <div>
                <img src="/img/started/4.png" />
              </div>
              <div className="top_img_text">Selecting a warrant</div>
            </a>
          </div>
          <div className="col-xs-4 top-img">
            <a href="#section5">
              <div>
                <img src="/img/started/5.png" />
              </div>
              <div className="top_img_text">How to buy or sell warrants </div>
            </a>
          </div>
          <div className="col-xs-4 top-img">
            <a href="#section6">
              <div>
                <img src="/img/started/6.png" />
              </div>
              <div className="top_img_text">Constant support</div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
const brokerageList = [
  {
    src: "/img/education/cimb_securities.png",
    link: "https://www.itradecimb.com.sg/app/home.z",
    clickfun: "gtag('event', 'Click', {'event_category' : 'CIMB',})",
    services: true,
    trading: true,
    contact: "1800 538 9889",
    email: "clientservices.sg@cimb.com",
  },
  {
    src: "/img/education/dbs_securities.png",
    link: "https://www.dbs.com.sg/vickers/en/trade/default.page",
    clickfun: "gtag('event', 'Click', {'event_category' : 'DBS',})",
    services: true,
    trading: false,
    contact: "+65 6327 2288	",
    email: "info-sg@dbsvonline.com",
  },
  {
    src: "/img/education/iFast_financial.png",
    link: "http://www.ifastfinancial.com.sg/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'iFast',})",
    services: true,
    trading: true,
    contact: "+65 6557 2853",
    email: "clienthelp@fundsupermart.com",
  },
  {
    src: "/img/education/kgi_securities.png",
    link: "https://www.kgieworld.sg/securities/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'KGI',})",
    services: true,
    trading: false,
    contact: "+65 6202 1188	",
    email: "sgp.cso@kgi.com",
  },
  {
    src: "/img/education/LimTan_securities.png",
    link: "http://www.limtan.com.sg/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'LimTan',})",
    services: true,
    trading: true,
    contact: "+65 6533 0595",
    email: "online@limtan.com.sg",
  },
  {
    src: "/img/education/maybank.png",
    link: "http://www.maybank-ke.com.sg/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'Maybank',});",
    services: true,
    trading: true,
    contact: "+65 6231 5888",
    email: "MSSG_Helpdesk@Maybank.com",
  },
  {
    src: "/img/education/imagemomo.png",
    link: "https://www.moomoo.com/sg/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'UOB',});",
    services: false,
    trading: true,
    contact: "+65 6321 8888",
    email: "clientservice@futusg.com",
  },
  {
    src: "/img/education/ocbc_securities.jpg",
    link: "http://www.iocbc.com/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'OCBC',});",
    services: true,
    trading: true,
    contact: "1800 338 8688	",
    email: "cs@ocbcsec.com",
  },
  {
    src: "/img/education/philip_securities.png",
    link: "https://www.phillip.com.sg/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'Phillip',});",
    services: true,
    trading: true,
    contact: "+65 6531 1555",
    email: "talktophillip@phillip.com.sg",
  },
  {
    src: "/img/education/Macquarie.png",
    link: "https://myaccount.phillipnova.com.sg/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'UOB',});",
    services: true,
    trading: true,
    contact: "+65 6538 0500",
    email: "nova@phillip.com.sg",
  },
  {
    src: "/img/education/rhb_securities.png",
    link: "http://www.rhbinvest.com.sg/",
    clickfun: "gtag('event', 'Click', {'event_category' : 'RHB',});",
    services: true,
    trading: false,
    contact: "+65 6438 8810",
    email: "rhbsec.enquiries@rhbgroup.com",
  },
  {
    src: "/img/education/uob_securities.jpg",
    link: "http://www.utrade.com.sg/index.jsp",
    clickfun: "gtag('event', 'Click', {'event_category' : 'UOB',});",
    services: true,
    trading: false,
    contact: "+65 6536 9338",
    email: "contact@utrade.com.sg",
  },


];

class SectionTwo extends Component {
  gtag(str) {
  }
  render() {
    return (
      <div className="section2 section_area areaMargin">
        <div className="row">
          <div className="col-sm-10 col-xs-12 move-right-sm">
            <div className="section_title">
              <span className="visible-xs section_number">2 </span>
              <span>Brokerage account</span>
            </div>
            <p className="section_content">
              To start trading, you’ll need to have a brokerage account with a
              stockbroker. If you already have a brokerage account, proceed to
              no. 3. Otherwise, here is a list of stockbroking companies in
              Singapore that you could contact:
            </p>
          </div>
          <div className="col-sm-2 section_number hidden-xs">
            <p style={{ marginTop: "-25px", marginLeft: "10px" }}>2</p>
          </div>
        </div>
        <div
          className="broker-table-holder"
          style={{ padding: "9px", background: "#C7EAF8" }}
        >
          <table className="broker-table table table-responsive table-striped">
            <thead style={{ background: "#fff" }}>
              <tr>
                <th width="22%">Broker</th>
                <th>
                  Execution
                  <br />
                  services
                </th>
                <th>
                  Online
                  <br />
                  trading
                </th>
                <th>Contact detail</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {brokerageList.map((item, index) => {
                return (
                  <tr>
                    <td className="broker-td-logo" key={index}>
                      <div
                        className="broker_img"
                        link={item.link}
                        onClick={this.gtag(item.clickfun)}
                      >
                        <img src={item.src} />
                      </div>
                    </td>
                    <td
                      className={item.services ? "broker-td-tick" : ""}
                    // className="broker-td-tick"
                    ></td>
                    {index == 5 ? (
                      <td className="broker-td-note">
                        <img src="/img/education/broker_tick.png" />
                        <span style={{ fontSize: "20px", fontWeight: "600" }}>
                          *
                        </span>
                      </td>
                    ) : (
                      <td className={item.trading ? "broker-td-tick" : ""}></td>
                    )}

                    <td className="broker-td-contact">
                      <a href={"tel:" + item.contact}>
                        <span className="broker-phone hidden-md hidden-lg"></span>
                        <span className="hidden-xs hidden-sm">
                          {item.contact}
                        </span>
                      </a>
                    </td>
                    <td className="broker-td-email">
                      <a href={"mailto:" + item.email}>
                        <span className="broker-email hidden-md hidden-lg"></span>
                        <span className="hidden-xs hidden-sm">
                          {item.email}
                        </span>
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p>
          <span style={{ fontSize: "20px", fontWeight: "600" }}>*</span>{" "}
          <span style={{ fontStyle: "italic", fontWeight: "100" }}>
            For pre-funded accounts only
          </span>
        </p>
      </div>
    );
  }
}

class SeactionThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eligibilityShow: false,
      moduleShow: false,
    };
  }

  onEligibilityChange() {
    this.setState({ eligibilityShow: false });
  }

  onEligibilityClick() {
    this.setState({ eligibilityShow: true });
  }

  onModuleChange(isContinue) {
    if (isContinue) {
      window.open(
        "https://onlineeducation.sgx.com/specifiedinvestmentproducts/",
        "_blank"
      );
    }
    this.setState({ moduleShow: false });
  }

  onModuleClick() {
    this.setState({ moduleShow: true });
  }

  render() {
    return (
      <div className="section3 section_area areaMargin started_con">
        <div className="row">
          <div className="col-sm-2 section_number hidden-xs">
            <p style={{ marginLeft: "20px", marginTop: "-25px" }}>3</p>
          </div>
          <div className="col-sm-10 col-xs-12">
            <div className="section_title">
              <span className="visible-xs section_number">3 </span>
              <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                Specified Investment Product (SIP) requirements
              </span>
            </div>
            <p className="section_content">
              Structured warrants are listed SIPs, which means a broker must
              assess if a retail investor has relevant knowledge and experience
              dealing in SIPs before allowing the investor to trade in SIPs
              including structured warrants.{" "}
            </p>
            <div className="row started_SIP" style={{ marginRight: "0" }}>
              <div className="col-sm-8 left">
                <ul style={{ paddingLeft: "20px", fontWeight: "400" }}>
                  <li className="section_content">
                    You are deemed eligible if you…
                  </li>
                  <input
                    className="btn btn-primary eligibility visible-xs"
                    value="Check eligibility"
                    type="button"
                    onClick={this.onEligibilityClick.bind(this)}
                  />
                  <EligibilityDialog
                    show={this.state.eligibilityShow}
                    onEligibilityChange={this.onEligibilityChange.bind(this)}
                  />
                  <li className="section_content">
                    Otherwise, you can learn more about SIPs by enrolling in the{" "}
                    <span className="content_bold">SIP learning module</span>{" "}
                    provided by the SGX, and taking the{" "}
                    <span className="content_bold">assessment</span> after. Once
                    you have passed the assessment, forward the results onto
                    your broker so that you can get started on trading warrants!
                  </li>
                  <input
                    className="btn btn-primary link-btn visible-xs"
                    link="https://onlineeducation.sgx.com/"
                    value="Learning module"
                    type="button"
                    onClick={this.onModuleClick.bind(this)}
                  />
                  <LearningModuleDialog
                    show={this.state.moduleShow}
                    onModuleChange={this.onModuleChange.bind(this)}
                  />
                  <input
                    className="btn btn-primary link-btn visible-xs"
                    link="http://www.sgx.com/sip"
                    value="Take assessment"
                    type="button"
                    onClick={this.onModuleClick.bind(this)}
                  />
                </ul>
              </div>
              <div className="col-sm-4 hidden-xs right">
                <input
                  className="btn btn-primary eligibility"
                  value="Check eligibility"
                  type="button"
                  onClick={this.onEligibilityClick.bind(this)}
                />
                <EligibilityDialog
                  show={this.state.eligibilityShow}
                  onEligibilityChange={this.onEligibilityChange.bind(this)}
                />
                <br />
                <input
                  className="btn btn-primary link-btn"
                  link="https://onlineeducation.sgx.com/"
                  value="Learning module"
                  type="button"
                  onClick={this.onModuleClick.bind(this)}
                />
                <LearningModuleDialog
                  show={this.state.moduleShow}
                  onModuleChange={this.onModuleChange.bind(this)}
                />
                <br />
                <input
                  className="btn btn-primary link-btn"
                  link="http://www.sgx.com/sip"
                  value="Take assessment"
                  type="button"
                  onClick={this.onModuleClick.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class EligibilityDialog extends Component {
  onEligibilityChange(isContinue) {
    this.props.onEligibilityChange(isContinue);
  }

  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    return (
      <div id="ppboxEligibility" className="overlay overlay_con" style={isShow}>
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <div className="ppc" key="subscribe-newsletter">
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>You are deemed eligible if you:</h3>
                  </div>
                  <p className="small"></p>
                  <ul style={{ paddingLeft: "20px" }}>
                    <li>
                      have executed 6 trades in the past 3 years in warrants or
                      any listed SIPs such as ETFs, structured notes, futures or
                      certificates; or
                    </li>
                    <li>
                      possess educational/professional finance-related
                      qualifications; or
                    </li>
                    <li>
                      possess a minimum of 3 consecutive years of{" "}
                      <span
                        className="ttip"
                        data-target="eligibility"
                        style={{ color: "#00A1E0", fontWeight: "bold" }}
                        data-hasqtip="0"
                      >
                        relevant working experience
                      </span>{" "}
                      in the past 10 years.
                    </li>
                  </ul>
                  <p></p>
                  <div style={{ textAlign: "center" }}>
                    <input
                      className="btn btn-primary cancel"
                      value="Close"
                      type="button"
                      onClick={this.onEligibilityChange.bind(this, false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class LearningModuleDialog extends Component {
  onModuleChange(isContinue) {
    this.props.onModuleChange(isContinue);
  }
  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    return (
      <div id="ppboxHTML2" className="overlay overlay_con" style={isShow}>
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <div className="ppc" key="subscribe-newsletter">
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>Important notice</h3>
                  </div>
                  <p className="small">
                    You are now entering a third party website which is neither
                    operated nor sponsored by Macquarie. Macquarie is not
                    responsible for the content, cookie collection, personal
                    data collection, trading and services provided on such
                    website. You should review the terms and conditions of third
                    party websites and contact the operators of such websites if
                    you have any queries.
                    <br />
                    <br />
                    Investment in warrants involves risk. These links to third
                    party websites do not constitute an offer, a solicitation of
                    an offer, or any advice or recommendation to participate in
                    any transaction. You should consult your own professional
                    advisors to ensure that any decision made is suitable with
                    regards to your circumstances and financial position.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <input
                      className="btn btn-primary continue"
                      value="Continue"
                      type="button"
                      onClick={this.onModuleChange.bind(this, true)}
                    />
                    <input
                      className="btn btn-primary cancel"
                      value="Cancel"
                      type="button"
                      onClick={this.onModuleChange.bind(this, false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Search extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrant search</h2>
        </div>
        <p className="section-p">
          Find warrants according to your investment criteria. For more
          information on how to select a warrant, click{" "}
          <a href="/tools/warrantsearch">here</a>
        </p>
        <div className="section-qlink-img section-qlink-dwsearch">
          <Link to="/tools/warrantsearch" className="section-qlink-achor">
            <img src="/img/bullet/qlink-achor.png" />
          </Link>
        </div>
      </div>
    );
  }
}

class Terms extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrant terms</h2>
        </div>
        <p className="section-p">
          All the key parameters and terms about your selected warrants are
          found here.
        </p>
        <div className="section-qlink-img section-qlink-dwterm">
          <Link to="/tools/warrantterms" className="section-qlink-achor">
            <img src="/img/bullet/qlink-achor.png" />
          </Link>
        </div>
      </div>
    );
  }
}

class Calculator extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrant calculator</h2>
        </div>
        <p className="section-p">
          Estimates the theoretical price of a warrant based on your input of
          the underlying price, time, or implied volatility.
        </p>
        <div className="section-qlink-img section-qlink-dwcalculator">
          <Link
            to="/tools/warrantcalculator"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" />
          </Link>
        </div>
      </div>
    );
  }
}
