
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class trackingwarrantspricechangelastprice extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>
                                                In order to track the price changes of listed securities, information sources such as the media, brokers and online providers will typically publish the “daily price change” for shares/warrants, consisting of the current traded price versus its closing
                                                price the day before. However, with warrants, this method is often totally inaccurate as warrants do not trade as frequently as shares. Therefore the price that is listed as the warrant’s “previous close” may actually relate
                                                to a trade that happened the previous morning, two days earlier, or even weeks ago.
                                                <br /><br /> The below shows the method that would be published by most publicly available information sources. In this case it is totally inaccurate as the ‘last traded price’ for the warrant which is used as the ‘closing
                                                price’ actually occurred the previous morning. Therefore it is not relevant to compare this to the stock price change which recorded its last trade at the market close.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/calculation-using-traded-prices_Table-of-Closing-Prices_enlarge.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}