const quickheadline = {
    "overnight": {
        "id": 6793,
        "thai_short_content": "<p>S&amp;P 500:&nbsp;3,811.15 (-0.5%)</p><p>Eurostoxx:&nbsp;3,636.44 (-1.3%)</p><p>US Crude Futures: USD61.50 (-3.2%)</p>",
        "publish_date": 1614556800000,
        "en_short_content": "<p>S&amp;P 500:&nbsp;3,811.15 (-0.5%)</p><p>Eurostoxx:&nbsp;3,636.44 (-1.3%)</p><p>US Crude Futures: USD61.50 (-3.2%)</p>",
        "en_title": "Tech stocks rebound as global bond rout eases",
        "thai_title": "Tech stocks rebound as global bond rout eases"
    },
    "commentary": {
        "id": 5021,
        "thai_short_content": "<p>HSI investors bought 5M call warrants as the index futures fell 1.8% on Thursday as trade tensions between the US and China linger. The market came off quickly after the opening bell, falling in tandem with the weak results from the US overnight markets.</p>",
        "publish_date": 1558569600000,
        "en_short_content": "<p>HSI investors bought 5M call warrants as the index futures fell 1.8% on Thursday as trade tensions between the US and China linger. The market came off quickly after the opening bell, falling in tandem with the weak results from the US overnight markets.</p>",
        "en_title": "Investors buy 5M HSI calls as markets fell",
        "thai_title": "Investors buy 5M HSI calls as markets fell"
    },
    "albums": [
        {
            "en_description": "Template",
            "id": 121,
            "last_update_time": "26 Oct 2020",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Document 2",
            "date": 1595289600000
        },
        {
            "en_description": "",
            "id": 125,
            "last_update_time": "01 Oct 2020",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Webinar: Market View with the experts",
            "date": 1601510400000
        },
        {
            "en_description": "",
            "id": 117,
            "last_update_time": "18 Feb 2020",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "SGX Top Traded Leveraged Products Issuer - 2019 ",
            "date": 1581984000000
        },
        {
            "en_description": "",
            "id": 113,
            "last_update_time": "22 Jan 2020",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "SGX Market Open Ceremony, 22 January 2020",
            "date": 1579651200000
        },
        {
            "en_description": "",
            "id": 101,
            "last_update_time": "21 Oct 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Strategy and Stock Picks, 19 October 2019",
            "date": 1571616000000
        },
        {
            "en_description": "",
            "id": 97,
            "last_update_time": "14 Oct 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Live Warrant Trading Demonstration Workshop, 14 October 2019",
            "date": 1571097600000
        },
        {
            "en_description": "INVEST Fair 2019, 18 August 2019",
            "id": 93,
            "last_update_time": "18 Aug 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "INVEST Fair 2019, 18 August 2019",
            "date": 1568073600000
        },
        {
            "en_description": "",
            "id": 89,
            "last_update_time": "27 Apr 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "SGX Active Traders Fair, 29 April 2019",
            "date": 1556582400000
        },
        {
            "en_description": "",
            "id": 85,
            "last_update_time": "03 Apr 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "How to trade the current market (Guest: Conrad Alvin Lim), 3 April 2019",
            "date": 1554422400000
        },
        {
            "en_description": "",
            "id": 77,
            "last_update_time": "28 Mar 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Live Warrant Trading Demonstration Workshop, 28 March 2019",
            "date": 1553817600000
        },
        {
            "en_description": "",
            "id": 81,
            "last_update_time": "27 Mar 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "How to trade the current market (Guest: Conrad Alvin Lim), 27 March 2019",
            "date": 1553817600000
        },
        {
            "en_description": "",
            "id": 73,
            "last_update_time": "19 Mar 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "How to trade the current market (Guest: Conrad Alvin Lim), 19 March 2019",
            "date": 1553040000000
        },
        {
            "en_description": "",
            "id": 69,
            "last_update_time": "06 Mar 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Live Warrant Trading Demonstration Workshop, 6 March 2019",
            "date": 1552435200000
        },
        {
            "en_description": "",
            "id": 61,
            "last_update_time": "12 Feb 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Exclusive warrant training workshop, 12 February 2019",
            "date": 1550016000000
        },
        {
            "en_description": "",
            "id": 57,
            "last_update_time": "23 Jan 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Exclusive warrant training workshop, 23 January 2019",
            "date": 1548720000000
        },
        {
            "en_description": "",
            "id": 65,
            "last_update_time": "12 Jan 2019",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Market Outlook Q1 2019 Seminar",
            "date": 1550102400000
        },
        {
            "en_description": "SGX Active Traders Fair, 14 April 2018",
            "id": 53,
            "last_update_time": "19 Apr 2018",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "SGX Active Traders Fair, 14 April 2018",
            "date": 1523836800000
        },
        {
            "en_description": "For newsletter images",
            "id": 13,
            "last_update_time": "11 Mar 2018",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "Document",
            "date": 1508976000000
        },
        {
            "en_description": "",
            "id": 33,
            "last_update_time": "09 May 2016",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "\"Unlock the Secrets of Successful Trading with Structured Warrants\" seminar, 26 March 2016 ",
            "date": 1462752000000
        },
        {
            "en_description": "",
            "id": 29,
            "last_update_time": "09 May 2016",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "\"Finding Opportunities in a Bear Market\" seminar by CK Ee, 24 February 2016",
            "date": 1462752000000
        },
        {
            "en_description": "",
            "id": 25,
            "last_update_time": "09 May 2016",
            "th_title": "",
            "status": 1,
            "th_description": "",
            "en_title": "\"Is the 2014 year-end volatility a precursor for markets in 2015? seminar by Ray Barros, 4 Feb 2015",
            "date": 1462752000000
        },
        {
            "en_description": "Exclusive seminar with Warrant Trader Robin Ho",
            "id": 9,
            "last_update_time": "09 May 2016",
            "th_title": "Exclusive seminar with Warrant Trader Robin Ho",
            "status": 1,
            "th_description": "Exclusive seminar with Warrant Trader Robin Ho",
            "en_title": "Exclusive seminar with Warrant Trader Robin Ho, 8 April 2014",
            "date": 1462752000000
        },
        {
            "en_description": "Lunar New Year Seminar with Conrad Alvin Lim & Adam Khoo - Uncover the investment secrets for 2014",
            "id": 8,
            "last_update_time": "09 May 2016",
            "th_title": "Lunar New Year Seminar with Conrad Alvin Lim & Adam Khoo - Uncover the investment secrets for 2014",
            "status": 1,
            "th_description": "Lunar New Year Seminar with Conrad Alvin Lim & Adam Khoo - Uncover the investment secrets for 2014",
            "en_title": "\"Uncover the investment secrets for 2014\" seminar with Conrad Alvin Lim & Adam Khoo, 20 Jan 2014",
            "date": 1462752000000
        },
        {
            "en_description": "Warrant Challenge 2014",
            "id": 10,
            "last_update_time": "12 Jun 2014",
            "th_title": "Fourth round of fortnightly winners",
            "status": 1,
            "th_description": "Fourth round of fortnightly winners",
            "en_title": "Warrant Challenge 2014",
            "date": 1402531200000
        }
    ],
    "highlight": {
        "id": 6745,
        "thai_short_content": "<p class=\"normaltextnobulletpoint\"><span style=\"font-size:12pt\"><span style=\"line-height:115%\"><span style=\"font-family:Arial,sans-serif\"><span style=\"color:#333333\"><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">Last week, the decline in global bonds prices sent yields climbing</span></span>, as the y<span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">ield on the 10-year Treasury note reached a one-year high of 1.614%. This was the largest move since 2016, as</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\"> yield-seeking investors cashed in on equity gains and flocked to the bonds market where prices are lower and seemed a safer choice than equities (Reuters).<br /><br />Market volatility picked up on the moves, as the Cboe Volatility Index for stocks, also known as the VIX or fear index, traded close to 30 on Friday alone.<br /><br />Read on to find out what does the steepening of the yield curve means, and what triggered the selloff in stocks globally this week</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">...</span></span></span></span></span></span></p>",
        "publish_date": 1614556800000,
        "en_short_content": "<p class=\"normaltextnobulletpoint\"><span style=\"font-size:12pt\"><span style=\"line-height:115%\"><span style=\"font-family:Arial,sans-serif\"><span style=\"color:#333333\"><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">Last week, the decline in global bonds prices sent yields climbing</span></span>, as the y<span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">ield on the 10-year Treasury note reached a one-year high of 1.614%. This was the largest move since 2016, as</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\"> yield-seeking investors cashed in on equity gains and flocked to the bonds market where prices are lower and seemed a safer choice than equities (Reuters).<br /><br />Market volatility picked up on the moves, as the Cboe Volatility Index for stocks, also known as the VIX or fear index, traded close to 30 on Friday alone.<br /><br />Read on to find out what does the steepening of the yield curve means, and what triggered the selloff in stocks globally this week</span></span><span style=\"font-size:11.0pt\"><span style=\"line-height:115%\">...</span></span></span></span></span></span></p>",
        "en_title": "Global bond yields surge; equities to suffer?",
        "thai_title": "Global bond yields surge; equities to suffer?"
    },
    "soldout": []
}

export default quickheadline;