
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class howdomarketmakershedge extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>Before addressing this, let us first cover a long-lived misconception. Many investors think that issuers aim to make profits at the expense of investors. Meaning, if an investor buys a warrant for $0.10 and sells it back to
                                                the issuer for $0.08 the issuer makes a $0.02 profit. This is simply not correct and in fact, Macquarie would prefer that investors make money trading their warrants, so that they keep coming back to buy more warrants!</p>
                                            <p>The aim of issuer is to capture profit on the risk management of warrants sold. When issuers sell warrants, they will normally buy shares or other derivatives to ‘hedge' their position and attempt to capture a ‘margin’ whether
                                                warrant prices go up or down.
                                            </p>
                                            <p>For example, if an issuer sells a call warrant they will often go into the underlying market and buy the underlying shares to ‘hedge’ themselves. Thus, if the share price increases, and investors profit on their call warrants,
                                                the issuer will also gain on their share holding. Warrants are not a ‘zero sum game‘.</p>
                                            <p>What an issuer is really attempting to do is to hedge against changes in ‘volatility’, rather than the ‘direction’ of the shares.</p>
                                            <p>The volatility of a share, simply put, is how much a share price moves up and down and is expressed in a percentage: the lower the percentage figure the less volatile the share is, the higher the more volatile. This volatility
                                                level is a key parameter in determining the price of all options and warrants.</p>
                                            <p>Issuers use this percentage to measure the value of options and warrants and when considering which options to buy as a hedge against the warrants they have sold. If they have sold a warrant that has an ‘implied volatility'
                                                level of, say, 25% and they can then buy another option of similar terms at, say 23%, they would theoretically be making a ‘margin', or profit. In essence, this is how issuers attempt to profit rather than ‘trading against
                                                investors'.
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/markethedge1.png" style={{ maxWidth: '330px' }} />
                                            </div>
                                            <p>Remember, warrants are simply another financial product where it is in the issuer's interest for the investors to have a profitable experience. Therefore, many warrant issuers invest considerable sums in education, marketing
                                                and websites to help investors understand the nature and risk of the products so that they can achieve their investment objectives.
                                            </p>
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>It is a common misconception that issuers and market makers benefit when investors lose money and therefore are trading against the investor. Issuers would much prefer that investors make money trading warrants,
                                                                so that they continue to trade warrants. This is why issuers invest so much time and money in educating investors.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}