import React, { Component } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Subscribe from "../../../components/subscribe";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import SwiperPagination from "../../../components/swiperpagination";
import AdvertBox from "../../../components/advert/advert";
import "./overnight.css";

export default class OvernightMarkets extends Component {
  constructor() {
    super();
    this.state = {
      highlightdata: null,
      startDate: null,
    };
  }

  componentDidMount() {
    document.title = "Overnight market wrap | Singapore Warrants | Macquarie"
    this.onFetchData();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("OvernightMarkets");
    if (staticData !== null) {
      this.setState({ highlightdata: staticData });
    }
  }

  onFetchData(startDateString = null) {
    if (MQSGUtil.isUsingStaticData("OvernightMarkets")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let highlightdata = null;
    let startdate = "";
    if (startDateString !== null) {
      startdate = "&startdate=" + startDateString;
    }
    // const url = MQSGUtil.getAPIBasePath() + "/NewsTableServlet?subPage=overnightmarkets&start=0" + startdate
    const url =
      MQSGUtil.getAPIBasePath() +
      "/NewsTableServlet?sp=overnightmarkets&start=0" +
      startdate;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          highlightdata = result;
          this.setState({ highlightdata });
        },
        (error) => {
          this.setState({ highlightdata });
        }
      );
  }

  onStartDateChange(startDate) {
    this.setState({ startDate });
    const startDateString = MQSGUtil.getDateString(startDate);
    this.onFetchData(startDateString);
  }

  render() {
    return (
      <div id="overnightmarkets" className="pageobj">
        <BreadcrumbBar
          link="/home/news"
          group="Market news"
          title="Overnight market wrap"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="topimg hidden-sm hidden-xs">
            <img src="/img/bg/top-marketwrap.jpg" alt="" />
          </div>
          <div className="row">
            <div
              className="col-md-8"
              style={{ marginTop: "20px", paddingleft: 0 }}
            >
              <NewsSection
                highlightdata={this.state.highlightdata}
                startDate={this.state.startDate}
                onStartDateChange={(startDate) =>
                  this.onStartDateChange(startDate)
                }
              />
            </div>
            <div
              className="hidden-xs hidden-sm col-md-4"
              style={{ marginTop: "40px", paddingRight: 0 }}
            >
              <AdvertBox />
              <Subscribe />
              <MorningMarket />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class NewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
    this.rows = [];
    this.pageSize = 5;
  }

  onDotClicked(pageIndex) {
    this.setState({ current: pageIndex });
  }

  onDateChange(date) {
    this.props.onStartDateChange(date);
  }

  setupRows(highlightdata) {
    const rows = [];
    const newslist = MQSGUtil.getData(highlightdata, "newsList");
    if (newslist !== null && Array.isArray(newslist)) {
      newslist.forEach((news, index) => {
        const date = MQSGUtil.getData(news, "date");
        const title = MQSGUtil.getData(news, "en_title");
        const short = { __html: MQSGUtil.getData(news, "en_short") };
        const id = MQSGUtil.getData(news, "id");
        const column1 = (
          <div className="newstbl-col-left">
            <span className="newstbl-col-date">{date}</span>
          </div>
        );
        const column2 = (
          <div className="newstbl-col-right">
            <h3 className="newstbl-col-dt">{title}</h3>
            <div
              className="newstbl-col-dd"
              dangerouslySetInnerHTML={short}
              style={{ overflowWrap: "break-word", overflowX: 'auto' }}
            ></div>
            <div className="newstbl-col-rm">
              <Link
                to={"/marketnews/highlight/overnightmarkets/" + id}
                className="btn btn-primary btn-default"
                target="_blank"
              >
                Read more
              </Link>
            </div>
          </div>
        );
        const row = (
          <div className="newstbl-row rowtmpl" key={"daily_" + id}>
            {column1}
            {column2}
          </div>
        );
        rows.push(row);
      });
    }
    this.rows = rows;
    return rows;
  }

  getRows() {
    // page index 0: current -> pageSize * (current + 1) - 1
    // page index 1: pageSize * current -> pageSize * 2 - 1
    const pageIndex = this.state.current;
    const startRowIndex = this.pageSize * pageIndex;
    const endRowIndex = this.pageSize * (pageIndex + 1);
    if (this.rows.length > endRowIndex) {
      return this.rows.slice(startRowIndex, endRowIndex);
    }
    return this.rows.slice(startRowIndex);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.highlightdata !== this.props.highlightdata) {
      this.setupRows(nextProps.highlightdata);
    }
    return true;
  }
  getbgDate(date) {
    var m = new Date().getMonth() + 1;
    var M = new Date(date).getMonth() + 1;
    var cls = m != M ? "random" : undefined;
    return cls
  }
  render() {
    const rows = this.getRows();
    return (
      <div
        id="newssection"
        style={{ position: "relative" }}
        className="section"
      >
        <div className="page-header">
          <h2>Overnight market wrap</h2>
        </div>
        <p className="section-p">
          Hear about the latest news in Global markets that might affect your
          trading day.
        </p>
        <div id="commentarytbl" className="newstbl">
          <div className="newstbl-htxt">Latest News</div>
          <div className="newstbl-content">{rows}</div>
          <div className="newstbl-bttm">
            <div id="datepicker" className="newstbl-cal-icon input-group date">
              <input type="hidden" maxlength="0" readOnly className="form-control" />
              <div className="input-group-addon">
                <DatePicker
                  selected={this.props.startDate}
                  onChange={(date) => this.onDateChange(date)}
                  maxDate={new Date()}
                  value=""
                  dayClassName={(date) =>
                    this.getbgDate(date)
                    // getDate(date) < Math.random() * 31 ? "random" : undefined
                  }
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            <SwiperPagination
              current={this.state.current}
              rows={this.rows}
              pageSize={this.pageSize}
              onDotClicked={(pageIndex) => this.onDotClicked(pageIndex)}
            />
          </div>
          <hr className="hr-hmgbm" />
        </div>
      </div>
    );
  }
}

class MorningMarket extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Morning Market Buzz</h2>
        </div>
        <p>Keep up to date with the latest news and more...</p>
        <div className="section-qlink-img section-qlink-todayhighlight">
          <a
            href="/marketnews/dailyhighlights"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" alt="" />
          </a>
        </div>
      </div>
    );
  }
}

// 1. datepicker
// 2. pagination
