import React from 'react'
import MQSGUtil from '../../../components/mqsgUtil';
export default class SpeaksPopup extends React.Component {

    isShow() {
        const id = this.props.id;
        const show = this.props.show;
        if (typeof id !== "undefined" && typeof show !== "undefined") {
            if (this.props.id !== "" && this.props.show) {
                return true;
            }
        }
        return false;
    }

    render() {
        if (this.isShow()) {
            const id = this.props.id;
            const srcLink = "https://www.youtube.com/embed/" + id + "?autoplay=1"
            const title = ""
            return (<div id="campaign">
                <div id="popup-video">
                    <div>
                        <div className="inner-area">
                            <img className="btn-close" src="/img/bullet/btn_close.png" alt="" onClick={() => this.props.onClose(id)}></img>
                            <div className="videoplayer">
                                <div class="videoplayer-container">
                                    <iframe src={srcLink} frameborder="0" allowfullscreen="" title={title}>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
        }
        return null;
    }

}
