import React, { Component } from 'react'
import BreadcrumbBar from '../../../components/breadcrumbBar'
import Videos from './videos'

export default class WarrantVideos extends Component {

    render() {
        return (
            <div id="dwvideo" className="pageobj">
                <BreadcrumbBar link="/home/edu" group="Warrant education" title="Market View / Commentary" />
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="section">
                        <div className="page-header">
                            <h1>Market View / Commentary</h1>
                        </div>
                        <p className="section-p">
                            In these series, hear the views of Singapore's investment strategists first-hand, and see how the market has moved in the month! Don't miss out as we also cover the upcoming financial events and what to expect in the coming weeks.                        </p>
                        <Videos />
                    </div>
                </div>
            </div>
        )
    }
}