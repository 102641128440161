import React, { Component } from "react";
import PageHeader from "../../../components/pageHeader/pageHeader";
import "./disclaimer.css";
import BreadcrumbBar from "../../../components/breadcrumbBar";

export default class Disclaimer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="importantinformation" className="pageobj">
        <BreadcrumbBar link="/home" group="Macquarie" title="Disclaimer" />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header">
                  <h1>Disclaimer</h1>
                </div>
                <p>
                  This internet site is produced by Macquarie Capital Securities
                  (Singapore) Pte. Limited (Registration No 198702912C) ("
                  <strong>MCSSPL</strong>
                  "), holder of a capital markets services licence under the
                  Securities and Futures Act, Chapter 289 of Singapore. No part
                  of this internet site may be copied, either in whole or in
                  part, or distributed to any other person. If you are not the
                  intended recipient you must not use or disclose the
                  information in any way.
                </p>
                <p>
                  The information on this internet is directed and available for
                  general circulation to residents of Singapore only and is not
                  provided to any person who is a resident of the United States
                  or any other country. Any material provided herein, including
                  any indicative terms are provided for information purposes
                  only and do not constitute an offer, a solicitation of an
                  offer, or any advice or recommendation to conclude any
                  transaction. The Warrants will not be offered to any US
                  persons. While MCSSPL provides the information in good faith
                  and derived from sources believed to be reliable, MCSSPL does
                  not represent or warrant the completeness, reliability,
                  accuracy, timeliness or fitness for any purpose of any of the
                  material and it accepts no responsibility for the accuracy,
                  completeness or timeliness of the information.
                </p>
                <p>
                  Macquarie Bank Limited (ABN 46 008 583 542) ("
                  <strong>MBL</strong>
                  ") is the Issuer of the Macquarie Warrants ("
                  <strong>Warrants</strong>
                  "). References to Macquarie include MBL and MCSSPL. MBL is
                  regulated as an authorised deposit taking institution by the
                  Australian Prudential Regulation Authority. MBL, acting
                  through its Singapore branch is authorised and licensed by the
                  Monetary Authority of Singapore to carry on wholesale banking
                  business in Singapore pursuant to the Banking Act, Chapter 19
                  of Singapore and therefore is subject to the supervision of
                  the Monetary Authority of Singapore. MCSSPL is not an
                  authorised deposit taking institution for the purposes of the
                  Banking Act 1959 (Commonwealth of Australia), and MCSSPL's
                  obligations do not represent deposits or other liabilities of
                  MBL. MBL does not guarantee or otherwise provide assurance in
                  respect of the obligations of MCSSPL.
                </p>
                <p>
                  Disclosures with respect to the listed companies, if any,
                  mentioned in this document are available at
                  <a
                    href="http://www.macquarie.com/disclosures"
                    target="_blank"
                    style={{ color: '#0675C2' }}
                  >
                    www.macquarie.com/disclosures
                  </a>
                  .
                </p>
                <p>
                  Copyright © 2018 Refinitiv. Refinitiv content is the
                  intellectual property of Refinitv. Any copying, republication
                  or redistribution of Refinitv content, including by caching,
                  framing or similar means, is expressly prohibited without the
                  prior written consent of Refinitiv. Data is provided for
                  information purposes only and is not intended for trading
                  purposes. Neither Refinitiv nor its Third Party Providers
                  shall be liable for any any loss, damage or cost, including
                  without limitation, direct or indirect, special, incidental,
                  or consequential, arising in connection with the information,
                  content or materials provided or as a result of actions taken
                  in reliance thereon. You expressly agree that your use of
                  Refinitiv's information, content or materials is at your sole
                  risk. Refinitiv and the Refinitiv Logo are trademarks and
                  registered trademarks of the Refinitiv companies around the
                  world.
                </p>
                <p>
                  The advertisements on this internet site have not been
                  reviewed by the Monetary Authority of Singapore.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
