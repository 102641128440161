import React, { Component } from 'react';
import './index.css';

class ShowImgpopup extends Component {
    render() {
        const {imgUrl}=this.props
        return (
            <div className='showImgpopup_fixed' style={{display:imgUrl?'':'none'}}>
                <div className='showImgpopup_con'>
                    <span className='showImgpopup_close'>
                        <img src='/img/bullet/icon-close-white.png'onClick={()=>{this.props.cbClose()}} />
                    </span>
                    <img src={imgUrl} />
                </div>
            </div>
        );
    }
}
export default ShowImgpopup;