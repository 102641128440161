import React, { Component } from 'react';
// import { is, fromJS } from 'immutable';
import ReactDOM from 'react-dom';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './alert.css';


class Alert extends Component {

    state = {
        show: false,
        index: 0
    };
    // 关闭弹框
    confirm = () => {
        this.setState({
            show: false
        })
        this.state.closeAlert();
    }
    open = (options) => {
        this.setState({
            show: true
        })
        document.getElementById('menubtn').style.display = 'none'
    }
    close() {
        this.setState({
            show: false
        })
        document.getElementById('menubtn').style.display = 'block'
    }
    setIndex(v) {
        if (v == this.state.index) {
            this.setState({
                index: 0
            })
        } else {
            this.setState({
                index: v
            })
        }
    }

    render() {
        return (
            <div style={{ display: this.state.show ? 'block' : 'none', background: '#000', width: '100%', height: '100vh', zIndex: '99999999999999999999', position: 'fixed', opacity: .9, fontFamily: 'thincond' }}>
                <div id="menu" style={{ position: 'relative', left: '0px', right: '0px', height: 'auto' }}>
                    <div>
                        <div id="closebtn" onClick={() => this.close()} style={{ cursor: 'pointer', position: 'absolute', right: 0, top: 0, width: 58, height: 58, background: 'url("/img/education/btn_close.png")' }}>
                        </div>
                    </div>

                    <div style={{ color: '#ffe180' }} className='alertBox'>
                        <p style={{ fontSize: '25px', color: '#FFD843' }} > Education Topics</p>
                        <p style={{ marginTop: '30px' }}><a href="/eduhtml" style={{ color: '#ffe180' }}>Home</a></p>
                        <p onClick={(e) => { this.setIndex(1) }}>Why Trade Warrants?</p>
                        <ul className='ulBox' style={{ display: this.state.index == 1 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.whytradewarrants">Introduction</a></li>
                            <li><a href="/eduhtml.gearingeffect">Gearing effect</a></li>
                            <li><a href="/eduhtml.limitedloss">Limited loss</a></li>
                            <li><a href="/eduhtml.porfitfrombullorbearmarket">Ability to go long/short</a></li>
                            <li><a href="/eduhtml.nomargincall">No margin call</a></li>
                            <li><a href="/eduhtml.lowercapitaloutlay">Lower capital outlay</a></li>
                            <li><a href="/eduhtml.releasingsharecapital">Releasing share capital</a></li>
                            <li><a href="/eduhtml.easeoftrade">Ease of trade</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(2) }}>What are warrants?</p>
                        <ul className='ulBox' style={{ display: this.state.index == 2 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.whatarewarrants">Introduction</a></li>
                            <li><a href="/eduhtml.callvsput">Call vs Put</a></li>
                            <li><a href="/eduhtml.howacallwarrantworks">How a call Warrant works?</a></li>
                            <li><a href="/eduhtml.howaputwarrantworks">How a put Warrant works?</a></li>
                            <li><a href="/eduhtml.howarewarrantsnamed">How are Warrants named?</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(3) }}>How are Warrants priced?</p>
                        <ul className='ulBox' style={{ display: this.state.index == 3 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.howarewarrantspriced">Introduction</a></li>
                            <li><a href="/eduhtml.shareprice">Stock Price</a></li>
                            <li><a href="/eduhtml.exerciseprice">Exercise Price</a></li>
                            <li><a href="/eduhtml.expirydate">Expiry Date</a></li>
                            <li><a href="/eduhtml.volatility">Volatility</a></li>
                            <li><a href="/eduhtml.dividends">Dividends</a></li>
                            <li><a href="/eduhtml.interestrates">Interest Rates</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(4) }}>Understanding the Warrant Structure</p>
                        <ul className='ulBox' style={{ display: this.state.index == 4 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.understandingthewarrantstructure">Introduction</a></li>
                            <li><a href="/eduhtml.intrinsicvalueandtimevalue">Intrinsic & Time Value</a></li>
                            <li><a href="/eduhtml.timedecay">Time Decay</a></li>
                            <li><a href="/eduhtml.moneyness">Moneyness</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(5) }}>Monitoring warrant price changes</p>
                        <ul className='ulBox' style={{ display: this.state.index == 5 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.monitoringpricechanges">Introduction</a></li>
                            <li><a href="/eduhtml.trackingwarrantspricechangelastprice">Last traded prices</a></li>
                            <li><a href="/eduhtml.trackingwarrantspricechangebidprice">Bid prices</a></li>
                            <li><a href="/eduhtml.closingtimestonote">Closing times</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(6) }}>Useful Warrant terms</p>
                        <ul className='ulBox' style={{ display: this.state.index == 6 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.usefulwarrantterms">Introduction</a></li>
                            <li><a href="/eduhtml.delta">Delta</a></li>
                            <li><a href="/eduhtml.exerciseratio">Warrants Per Share</a></li>
                            <li><a href="/eduhtml.warrantsensitivity">Warrant Sensitivity</a></li>
                            <li><a href="/eduhtml.breakevenprice">Breakeven Price</a></li>
                            <li><a href="/eduhtml.premium">Premium</a></li>
                            <li><a href="/eduhtml.gearing">Gearing</a></li>
                            <li><a href="/eduhtml.effectivegearing">Effective Gearing</a></li>
                            <li><a href="/eduhtml.impliedvolatility">Implied Volatility</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(7) }}>How to select a Warrant?</p>
                        <ul className='ulBox' style={{ display: this.state.index == 7 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.howtoselectawarrant">Introduction</a></li>
                            <li><a href="/eduhtml.fourviewonunderlyingshareorindex">Selecting a warrant</a></li>
                            <li><a href="/eduhtml.qualityofamarketmaker">Market maker</a></li>
                            <li><a href="/eduhtml.riskmatrix">Risk Matrix</a></li>
                            <li><a href="/eduhtml.tipsandtools">Tips and tools</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(8) }}>Single share calls and puts at expiry</p>
                        <ul className='ulBox' style={{ display: this.state.index == 8 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.singlesharecallsandputsatexpiry">Introduction</a></li>
                            <li><a href="/eduhtml.warrantsatexpirylasttradingday">Last trading day</a></li>
                            <li><a href="/eduhtml.cashsettlementamountforsinglesharewarrantsatexpiry">Call expiry formula</a></li>
                            <li><a href="/eduhtml.singlesharecallwarrantexample">Call example</a></li>
                            <li><a href="/eduhtml.formulatocalculatevalueofputwarrantatexpiry">Put expiry formula</a></li>
                            <li><a href="/eduhtml.singleshareputwarrantexample">Put example</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(9) }}>Index calls and puts at expiry</p>
                        <ul className='ulBox' style={{ display: this.state.index == 9 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.indexcallsandputsatexpiry">Introduction</a></li>
                            <li><a href="/eduhtml.cashsettlementamountforindexcallsandputsatexpiry">Index expiry Formula</a></li>
                            <li><a href="/eduhtml.indexcallwarrantexample">Call example</a></li>
                            <li><a href="/eduhtml.indexputwarrantexample">Put example</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(10) }}>Risks</p>
                        <ul className='ulBox' style={{ display: this.state.index == 10 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.risk">Introduction</a></li>
                            <li><a href="/eduhtml.marketrisk">Market Risk</a></li>
                            <li><a href="/eduhtml.riskgearing">Gearing</a></li>
                            <li><a href="/eduhtml.limitedlife">Limited Life</a></li>
                            <li><a href="/eduhtml.creditrisk">Credit Risk</a></li>
                            <li><a href="/eduhtml.takeovers">Takeovers</a></li>
                        </ul>
                        <p onClick={(e) => { this.setIndex(11) }}>Market Makers</p>
                        <ul className='ulBox' style={{ display: this.state.index == 11 ? 'block' : 'none' }}>
                            <li><a href="/eduhtml.marketmakers">Introduction</a></li>
                            <li><a href="/eduhtml.liquidityvstradedvolume">Liquidity vs Volume</a></li>
                            <li><a href="/eduhtml.howdomarketmakershedge">How We Hedge</a></li>
                        </ul>
                    </div>
                </div>
            </div >
        );
    }
}

let div = document.createElement('div');
let props = {

};
document.body.appendChild(div);

let Box = ReactDOM.render(React.createElement(
    Alert,
    props
), div);



export default Box;