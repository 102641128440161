import React from "react";
import "./pageHeader.css";

class Disclaimer extends React.Component {
  onDisclaimerChange(isContinue) {
    this.props.onDisclaimerChange(isContinue);
  }

  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    return (
      <div
        id="disclaimer-ppboxHTML"
        className="disclaimer-overlay"
        style={isShow}
      >
        <div className="overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <div>
                <div className="signup-layer">
                  <div className="page-header">
                    <h3>Important notice</h3>
                  </div>
                  <p className="small">
                    You are now entering a third party website which is neither
                    operated nor sponsored by Macquarie. Macquarie is not
                    responsible for the content, cookie collection, personal
                    data collection, trading and services provided on such
                    website. You should review the terms and conditions of third
                    party websites and contact the operators of such websites if
                    you have any queries.
                    <br />
                    <br />
                    Investment in warrants involves risk. These links to third
                    party websites do not constitute an offer, a solicitation of
                    an offer, or any advice or recommendation to participate in
                    any transaction. You should consult your own professional
                    advisors to ensure that any decision made is suitable with
                    regards to your circumstances and financial position. Please
                    note that the list of brokers is not exhaustive and the
                    inclusion of the brokers on the list does not imply that the
                    brokers or their services are endorsed by Macquarie.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <input
                      className="btn btn-primary continue"
                      value="Continue"
                      type="button"
                      onClick={this.onDisclaimerChange.bind(this, true)}
                    />
                    <input
                      className="btn btn-primary cancel"
                      value="Cancel"
                      type="button"
                      onClick={this.onDisclaimerChange.bind(this, false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class TradeHereDropDown extends React.Component {
  onSelectBroker(brokderUrl, n) {
    this.props.onSelectBroker(brokderUrl, n);
  }

  render() {
    const className = this.props.show ? "tradeDropDown" : "tradeDropDown hide";
    return (
      <div id="pageHeader">
        <div className={className}
          style={{
            right: '0px'
          }}
        >
          <a
            className="btn btn-primary"
            href="/education/getstarted"
            type="button"
            target="_blank"
          >
            How to get started
          </a>
          <div className="dropdown-or">
            <hr />
            <p>or</p>
            <hr />
          </div>
          <p>Select your broker:</p>
          <div className="row">
            <input
              className="btn btn-primary broker_link"
              value="CGS-CIMB Securities"
              type="button"
              onClick={() => this.onSelectBroker(
                "https://www.itradecimb.com.sg/app/home.z",
                "cimb"
              )}
            // onClick={this.onSelectBroker.bind(
            //   this,
            //   "https://www.itradecimb.com.sg/app/home.z",
            //   "cimb"
            // )}
            />

            <input
              className="btn btn-primary broker_link"
              value="FSMOne.com"
              type="button"
              onClick={() => this.onSelectBroker(
                "https://secure.fundsupermart.com/fsm/account/login",
                "fsm"
              )}
            // onClick={this.onSelectBroker.bind(
            //   this,
            //   "https://secure.fundsupermart.com/fsm/account/login",
            //   "fsm"
            // )}
            />

          </div>
          <div className="row">
            <input
              className="btn btn-primary broker_link"
              value="Moomoo"
              type="button"
              onClick={() => this.onSelectBroker(
                "https://www.moomoo.com/sg/",
              )}
            // onClick={this.onSelectBroker.bind(
            //   this,
            //   "https://www.moomoo.com/sg/",
            // )}
            />

            <input
              className="btn btn-primary broker_link"
              value="OCBC Securities"
              type="button"
              onClick={() => this.onSelectBroker(
                "https://www2.iocbc.com/V2/cl_login.jsp",
                "ocbc"
              )}
            // onClick={this.onSelectBroker.bind(
            //   this,
            //   "https://www2.iocbc.com/V2/cl_login.jsp",
            //   "ocbc"
            // )}
            />
          </div>
          <div className="row">
            <input
              className="btn btn-primary broker_link"
              value="Phillip Nova"
              type="button"
              onClick={() => this.onSelectBroker(
                "https://myaccount.phillipnova.com.sg/"
              )}
            // onClick={this.onSelectBroker.bind(
            //   this,
            //   "https://myaccount.phillipnova.com.sg/"
            // )}
            />
            <input
              className="btn btn-primary broker_link"
              value="Phillip Securities"
              type="button"
              // onClick={this.onSelectBroker.bind(
              //   this,
              //   "https://p2.poems.com.sg/"
              // )}
              onClick={() => this.onSelectBroker(
                "https://p2.poems.com.sg/"
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tradeHereDropDown: false,
      disclaimerShow: false,
      brokerUrl: null,
    };
  }

  onClickTradeWarrantsHere(e, value) {
    if (value) {
      e.stopPropagation()
      const currState = this.state.tradeHereDropDown;
      this.setState({ tradeHereDropDown: !currState });
      return;
    }
    this.setState({ tradeHereDropDown: false });
  }

  onSelectBroker(brokerUrl, n) {
    // var u = navigator.userAgent;
    // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // alert("是否是Android：" + isAndroid);
    // alert("是否是iOS：" + isiOS);
    var link = brokerUrl;
    if (n !== undefined) {
      var isiDevice = / iPod| iPad| iPhone/i.test(
        navigator.userAgent.toLowerCase()
      );
      if (isiDevice) {
        var iosLink = {
          ocbc: "https://itunes.apple.com/sg/app/iocbc-trademobile/id367829836?mt=8",
          fsm: "https://apps.apple.com/sg/app/fsm-mobile-invest-globally/id427129018",
          cimb: "https://apps.apple.com/us/app/new-i-trade-cimb/id680480648",
        };
        link = iosLink[n];
      }
      var isAndroid = / Android/i.test(navigator.userAgent.toLowerCase());
      if (isAndroid) {
        var andLink = {
          ocbc: "https://play.google.com/store/apps/details?id=com.iocbc.trademobile",
          fsm: "https://play.google.com/store/apps/details?id=com.ifast.fsm&hl=en",
          cimb: "https://play.google.com/store/apps/details?id=com.n2nconnect.iTradeCIMBSG.stock",
        };
        link = andLink[n];
      }
    }
    this.setState({ disclaimerShow: true, brokerUrl: link });
  }

  onDisclaimerChange(isContinue) {
    if (isContinue) {
      if (this.state.brokerUrl != null) {
        window.open(this.state.brokerUrl, "_blank"); //to open new page
      }
    }
    this.setState({
      disclaimerShow: false,
      tradeHereDropDown: false,
      brokerUrl: null,
    });
  }

  getButtonClass() {
    return this.state.tradeHereDropDown
      ? "btn btn-primary selectedTrade"
      : "btn btn-primary";
  }

  render() {
    return (
      <div className="page-header" style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <h2>{this.props.title}</h2>
        <div className="trade-area">
          <input
            className={this.getButtonClass()}
            value="Trade warrants here"
            type="button"
            onClick={(e) => { this.onClickTradeWarrantsHere(e, true) }}
          />
          <TradeHereDropDown
            show={this.state.tradeHereDropDown}
            onSelectBroker={this.onSelectBroker.bind(this)}
          />
          <Disclaimer
            show={this.state.disclaimerShow}
            onDisclaimerChange={this.onDisclaimerChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default PageHeader;
