import React, { Component } from "react";
import "./whymacquarie.css";
import BreadcrumbBar from "../../../components/breadcrumbBar";

export default class Whymacquarie extends Component {
  render() {
    return (
      <div className="whyBox">
        <BreadcrumbBar
          link="/home/edu"
          group="Warrant education"
          title="Why Macquarie?"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div style={{ backgroundColor: "#fff" }}>
            <div id="area_1">
              <img src="/img/education/u22204.png" alt="" />
              <div className="inner_title color-primary">Why Macquarie?</div>
              <div className="subscribe_area">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="description">
                          Keep up to date with the latest news
                        </div>
                        <a href="/marketnews/subscribe">
                          <div id="btn-subscribe" className="color-primary">
                            Subscribe
                          </div>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table className="tablearea hidden-xs">
              <tbody>
                <tr>
                  <td style={{ padding: '25px 25px' }}>
                    <div className="videoplayer">
                      <div className="videoplayer-container">
                        <iframe
                          title="Market View"
                          frameBorder="0"
                          allowFullScreen=""
                          className="dwvideo-iframe"
                          style={{
                            height: '400px',
                            width: '100%'
                          }}
                          src="https://www.youtube.com/embed/8BPbGRq29jQ?autoplay=0&index=0&rel=0&wmode=opaque&t=14s"
                        ></iframe>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: '35%', padding: '0px 25px 0px 0px' }}>
                    <div className="inner_title color-primary">
                      Asia's leading
                      <br />
                      <span style={{ whiteSpace: 'nowrap' }}> warrant issuer</span>
                    </div>
                    <div className="inner_content">Macquarie is the leading warrant issuer in Asia, and the only issuer active in 5 warrant markets in the region.  We were named the “Best warrant provider” at the Structured retail products APAC awards in 2022.
                      In Singapore, we are the only issuer who has remained in the warrant market since 2005, weathering through the ups and downs of the stock market alongside warrant investors.
                      We constantly refine, revamp and invent new ways and tools of helping investors achieve a better warrant trading experience.</div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="tablearea visible-xs">
              <tbody>
                <tr>
                  <td style={{ padding: '25px 0px 0px 0px' }}>
                    <div className="videoplayer">
                      <div className="videoplayer-container">
                        <iframe
                          title="Market View"
                          frameBorder="0"
                          allowFullScreen=""
                          className="dwvideo-iframe"
                          style={{
                            height: '400px',
                            width: '100%'
                          }}
                          src="https://www.youtube.com/embed/8BPbGRq29jQ?autoplay=0&index=0&rel=0&wmode=opaque&t=14s"
                        ></iframe>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      Asia's leading
                      <br />
                      <span style={{ whiteSpace: "nowrap" }}> warrant issuer</span>
                    </div>
                    <div className="inner_content">Macquarie is the leading warrant issuer in Asia, and the only issuer active in 5 warrant markets in the region.  We were named the “Best warrant provider” at the Structured retail products APAC awards in 2022.
                      In Singapore, we are the only issuer who has remained in the warrant market since 2005, weathering through the ups and downs of the stock market alongside warrant investors.
                      We constantly refine, revamp and invent new ways and tools of helping investors achieve a better warrant trading experience.</div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="tablearea hidden-xs">
              <tbody>
                <tr>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      State-of-the-art
                      <br />
                      <span style={{ whiteSpace: 'nowrap' }}>market making</span>
                    </div>
                    <div className="inner_content">Our consistent price has made Macquarie a market leader for warrants in Asia. endeavour to provide tight spreads and liquidity in our warrants so that investors can buy and sell Macquarie warrants with relative ease.</div>
                  </td>
                  <td>
                    <img src="/img/education/u22329.png" alt="" />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="tablearea visible-xs">
              <tbody>
                <tr>
                  <td>
                    <img src="/img/education/u22329.png" alt="" />
                  </td>
                </tr>
                <tr>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      State-of-the-art
                      <br />
                      <span style={{ whiteSpace: "nowrap" }}>
                        market making
                      </span>
                    </div>
                    <div className="inner_content">
                      Our consistent price has made Macquarie a market leader
                      for warrants in Asia. endeavour to provide tight spreads
                      and liquidity in our warrants so that investors can buy
                      and sell Macquarie warrants with relative ease.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="tablearea hidden-xs">
              <tbody>
                <tr>
                  <td>
                    <img src="/img/education/u22323.png" alt="" />
                  </td>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      Learn more with Macquarie
                    </div>
                    <div className="inner_content">
                      Our aim is to make you a successful investor. That’s why
                      we invest a lot of time and effort into educating
                      investors to improve your knowledge of warrants and
                      trading skills.
                    </div>
                    <a href="/WarrantsGuidebook" target="_blank">
                      <div id="btn-get-started" className="color-primary">
                        Get started
                      </div>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="tablearea visible-xs">
              <tbody>
                <tr>
                  <td>
                    <img src="/img/education/u22323.png" alt="" />
                  </td>
                </tr>
                <tr>
                  <td className="td-text altarea">
                    <div className="inner_title color-primary">
                      Learn more with Macquarie
                    </div>
                    <div className="inner_content">
                      Our aim is to make you a successful investor. That’s why
                      we invest a lot of time and effort into educating
                      investors to improve your knowledge of warrants and
                      trading skills.
                    </div>
                    <a target="_blank" href="/WarrantsGuidebook">
                      <div id="btn-get-started" className="color-primary">
                        Get started
                      </div>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="tablearea hidden-xs">
              <tbody>
                <tr>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      Constant support
                    </div>
                    <div className="inner_content">
                      Our local team is available throughout the day to help
                      with your needs. Call us or email your questions, we would
                      love to hear from you.
                    </div>
                  </td>
                  <td>
                    <img src="/img/education/u22327.png" alt="" />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="tablearea visible-xs">
              <tbody>
                <tr>
                  <td>
                    <img src="/img/education/u22327.png" alt="" />
                  </td>
                </tr>
                <tr>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      Constant support
                    </div>
                    <div className="inner_content">
                      Our local team is available throughout the day to help
                      with your needs. Call us or email your questions, we would
                      love to hear from you.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div style={{ backgroundColor: "#fff", padding: "20px 10px" }}>
              <hr className="hr-hmgbm" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
