import React, { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import MQSGUtil from "../../../components/mqsgUtil";
import SwiperPagination from "../../../components/swiperpagination";
import "./document.css";

export default class WarrantDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsheetdata: null,
      noticedata: null,
      tab: "termsheets",
    };
  }

  componentDidMount() {
    this.onFetchTermSheetData();
    this.onFetchNoticeData();
    document.title = "Warrant documents | Singapore Warrants | Macquarie"
  }

  onFetchStaticTermSheetData() {
    const staticData = MQSGUtil.getStaticData("WarrantDocument");
    if ("termsheetData" in staticData) {
      this.setState({ termsheetdata: staticData.termsheetData });
    }
  }

  onFetchTermSheetData() {
    if (MQSGUtil.isUsingStaticData("WarrantDocument")) {
      setTimeout(() => {
        this.onFetchStaticTermSheetData();
      }, 100); // pretend fetch
      return;
    }
    let termsheetdata = null;
    const url = MQSGUtil.getAPIBasePath() + "/DocumentJSON?m=0";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          termsheetdata = result;
          this.setState({ termsheetdata });
        },
        (error) => {
          this.setState({ termsheetdata });
        }
      );
  }

  onFetchStaticNoticeData() {
    const staticData = MQSGUtil.getStaticData("WarrantDocument");
    if ("noticeData" in staticData) {
      this.setState({ noticedata: staticData.noticeData });
    }
  }

  onFetchNoticeData() {
    if (MQSGUtil.isUsingStaticData("WarrantDocument")) {
      setTimeout(() => {
        this.onFetchStaticNoticeData();
      }, 100); // pretend fetch
      return;
    }
    let noticedata = null;
    const url = MQSGUtil.getAPIBasePath() + "/DocumentJSON?m=n";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          noticedata = result;
          this.setState({ noticedata });
        },
        (error) => {
          this.setState({ noticedata });
        }
      );
  }

  tabChange(tab) {
    this.setState({ tab });
  }

  showTab() {
    if (this.state.tab === "termsheets") {
      return <TermSheetsTab termsheetdata={this.state.termsheetdata} />;
    }
    return <ExpiryTab noticedata={this.state.noticedata} />;
  }

  isActive(tab) {
    if (this.state.tab === tab) {
      return "active";
    }
    return "";
  }

  render() {
    return (
      <div id="document" className="pageobj" preview="0">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">Warrant tools</Link>
              </li>
              <li className="active">Warrant documents</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h2>Warrant documents</h2>
            </div>
            <p className="section-txt">
              Provide access to Macquarie's listing documents, prospectus, terms
              and conditions relevant for the warrants that are issued. It is
              advised that you review these documents before investing.
            </p>
            <div className="tablist">
              <div id="topTabs" className="tablist-left">
                <table>
                  <tbody>
                    <tr>
                      <td
                        className={this.isActive("termsheets")}
                        onClick={() => this.tabChange("termsheets")}
                      >
                        <div className="tablist-item">
                          <div className="tablist-item-txt">Term sheets</div>
                        </div>
                      </td>
                      <td
                        className={this.isActive("notices")}
                        onClick={() => this.tabChange("notices")}
                      >
                        <div className="tablist-item">
                          <div className="tablist-item-txt">
                            Expiry &amp;
                            <br />
                            Adjustment notices
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* 2tab */}
            <div className="tabcontent-holder">{this.showTab()}</div>
          </div>
        </div>
      </div>
    );
  }
}

class TermSheetsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      current: 0,
      isShow: false,
      inputValue: '',
      dataList: [
      ]
    };
    this.rows = [];
    this.pageSize = 10;
  }

  onDotClicked(pageIndex) {
    this.setState({ current: pageIndex });
  }

  onFilterStringChange(event) {
    event.stopPropagation()
    this.setState({ filter: event.target.value, inputValue: event.target.value });
    // PowerSearchJSON?key=a&limit=10&type=w
    if (event.target.value == '') {
      this.setState({
        dataList: [],
      })
      this.setupRows(this.props.termsheetdata);
      return
    }
    const url =
      MQSGUtil.getAPIBasePath() +
      "/PowerSearchJSON?key=" +
      event.target.value +
      "&type=w";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          //   if (result.length > 0) {
          this.setState({
            dataList: result.powersearch,
          });
        },
        (error) => { }
      );
  }

  setupRows(termsheetdata) {
    const rows = [];
    if (termsheetdata !== null && "termsheet" in termsheetdata) {
      const termsheet = termsheetdata.termsheet;
      if (termsheet.length == 0) {
        const row = (
          <tr
            className="emptymsg"
          >
            <td colSpan="5">No related term sheets</td>
          </tr>
        );
        rows.push(row);
        this.rows = rows;
        return rows;
      }
      if (Array.isArray(termsheet)) {
        termsheet.forEach((term, index) => {
          const dsplynmll = MQSGUtil.getData(term, "dsply_nmll");
          let isAdd = true;
          // if (this.state.filter.length > 0 && dsplynmll.length > 0) {
          //   const filterLowercase = this.state.filter.toLowerCase();
          //   const dsplynmllLowercase = dsplynmll.toLowerCase();
          //   if (dsplynmllLowercase.indexOf(filterLowercase) === -1) {
          //     isAdd = false;
          //   }
          // }

          if (isAdd) {
            const ric = MQSGUtil.getData(term, "ric");
            const ticker = MQSGUtil.getData(term, "ticker");
            const maturity = MQSGUtil.getData(term, "maturity");
            const listdatef = MQSGUtil.getData(term, "list_date_f");
            const column1 = (
              <td className="ticker">
                <Link to={"/tools/livematrix/" + ticker}>{ticker}</Link>
              </td>
            );
            const column2 = (
              <td className="dsply_nmll">
                <Link to={"/tools/livematrix/" + ticker}>{dsplynmll}</Link>
              </td>
            );
            const column3 = <td className="maturity">{maturity}</td>;
            const column4 = <td className="list_date">{listdatef}</td>;
            const view = MQSGUtil.getAPIBasePath() + "/pdf?type=termsheet&id=" + ric;
            const column5 = (
              <td className="view">
                <a
                  href={view}
                  target="_blank"
                >
                  <img src="/img/bullet/pdf.png" alt="" />
                </a>
              </td>
            );
            const row = (
              <tr
                className="rowtmpl_swiper_termsheet"
                idx={index}
                key={index + "_" + ric}
                data-ric={ric}
              >
                {column1}
                {column2}
                {column3}
                {column4}
                {column5}
              </tr>
            );
            rows.push(row);
          }
        });
      }
    }
    this.rows = rows;
    return rows;
  }

  getRows() {
    // page index 0: current -> pageSize * (current + 1) - 1
    // page index 1: pageSize * current -> pageSize * 2 - 1
    if (this.state.inputValue != '') {
      var termsheetdata = {
        termsheet: []
      };
      var arr = this.props.termsheetdata.termsheet.filter((value) => {
        return value.ticker.indexOf((this.state.inputValue).toUpperCase()) != -1
      })
      if (arr.length > 0) {
        termsheetdata.termsheet = arr;
        this.setupRows(termsheetdata);
        return this.rows;
      } else {
        this.setupRows(termsheetdata);
        return this.rows;
      }
    } else {
      if (this.rows.length === 0) {
        this.setupRows(this.props.termsheetdata);
      }
      const pageIndex = this.state.current;
      const startRowIndex = this.pageSize * pageIndex;
      const endRowIndex = this.pageSize * (pageIndex + 1);
      if (this.rows.length > endRowIndex) {
        return this.rows.slice(startRowIndex, endRowIndex);
      }
      return this.rows.slice(startRowIndex);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.termsheetdata !== this.props.termsheetdata) {
      this.setupRows(nextProps.termsheetdata);
    }
    return true;
  }
  showTabList(value, e) {
    e.stopPropagation()
    this.setState({
      isShow: !this.state.isShow,
    })
  }
  clearData(e) {
    // e.stopPropagation()
    // this.setState({
    //   // isShow: !this.state.isShow,
    //   dataList: []
    // })
  }
  setSymbol(value, e) {
    e.stopPropagation()
    this.setState({ inputValue: value, isShow: false, filter: value, dataList: [] });
  }
  getRowData() {
    var rows = [];
    var reg = new RegExp()
    var str = '<span style="color:red">' + this.state.inputValue.toUpperCase() + '</span>';
    for (let i = 0; i < this.state.dataList.length; i++) {
      var html = this.state.dataList[i].name.replace(this.state.inputValue.toUpperCase(), str) + ' ' + this.state.dataList[i].ticker.replace(this.state.inputValue.toUpperCase(), str);
      var row = (
        <p
          dangerouslySetInnerHTML={{ __html: html }}
          key={this.state.dataList[i].name}
          onClick={(e) => { this.setSymbol(this.state.dataList[i].ticker, e) }} style={{ padding: '0', lineHeight: '20px', cursor: 'pointer', margin: '0', background: i % 2 == 1 ? '#ddd' : '', padding: '5px 0px 0px  5px', }}
        ></p>
      )
      rows.push(row)
    }
    return rows;
  }
  render() {
    const rows = this.getRows();
    // this.getRowData()
    var str = '<span style="color:red">' + this.state.inputValue + '</span>';
    return (
      <div id="termsheet" className="tabcontent tableSize" style={{ position: 'relative' }}>
        <div className="searchbox searchbox-toppage input-group">
          <div id="powersearch">
            <input
              type="text"
              maxLength="30"
              className="powersearchinput form-control text-center ui-autocomplete-input placeholder"
              placeholder="Search warrant"
              autoComplete="off"
              value={this.state.inputValue}
              onChange={(event) => this.onFilterStringChange(event)}
              onFocus={(e) => { this.showTabList(true, e) }}
              onBlur={(e) => { this.clearData(e) }}
            />
            <div className="loadingicon"></div>
          </div>
          <span className="input-group-btn">
            <button className="btn btn-default" type="button">
              <span
                className="glyphicon glyphicon-search search_btn"
                aria-label="search"
              ></span>
            </button>
          </span>
        </div>
        <div className="dataListClass" style={{ position: 'absolute', top: '35px', width: 'calc(100% - 38px)', border: '1px solid #aeaeae', background: '#fff', display: this.state.isShow && this.state.dataList.length > 0 ? 'block' : 'none' }}>
          {/* {
            this.state.dataList.map((data, i) => {
              return (
                <p onClick={(e) => { this.setSymbol(data.symbol, e) }} style={{ padding: '0', lineHeight: '20px', cursor: 'pointer', margin: '0', background: i % 2 == 1 ? '#ddd' : '', padding: '5px 0px 0px  5px', }} key={data.name}>{data.name + ' ' + '(' + data.ticker + ')'}</p>
              )
            })
          } */}
          {this.getRowData()}

        </div>
        <div style={{ overflowX: "auto" }}>
          <table className="table table-striped table-responsive search-table">
            <thead>
              <tr>
                <th>Warrant code</th>
                <th>Warrant name</th>
                <th>Maturity date</th>
                <th>Listing date</th>
                <th>Listing document</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
        {/* <div className="doctbl-bttm">
                    <div id="pgmarkers" className="swiper-pagination doctbl-pgmarkers swiper-pagination-clickable swiper-pagination-bullets"><span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span></div>
                </div> */}
        <div className="doctbl-bttm">
          <SwiperPagination
            current={this.state.current}
            rows={this.rows}
            pageSize={this.pageSize}
            onDotClicked={(pageIndex) => this.onDotClicked(pageIndex)}
          />
        </div>
      </div>
    );
  }
}

class ExpiryTab extends Component {
  getRows() {
    const rows = [];
    const noticedata = this.props.noticedata;
    if (noticedata !== null && "notice" in noticedata) {
      const notices = noticedata.notice;
      if (Array.isArray(notices)) {
        notices.forEach((notice, index) => {
          const date = MQSGUtil.getData(notice, "date");
          let underlyings = MQSGUtil.getData(notice, "underlyings", null);
          if (underlyings === null || !Array.isArray(underlyings)) {
            underlyings = [];
          }
          const underlyingsJSX = underlyings.map((underlying, index) => {
            let comma = "";
            if (index !== underlyings.length - 1) {
              comma = ", ";
            }
            const displayname =
              MQSGUtil.getData(underlying, "underlying_name") + comma;
            const underlyingticker = MQSGUtil.getData(
              underlying,
              "underlying_ticker",
              null
            );
            const key = index + "_" + displayname;
            if (underlyingticker !== null) {
              const url = "/tools/underlying/" + underlyingticker;
              const link = (
                <Link to={url} className="link" key={key}>
                  {displayname}
                </Link>
              );
              return link;
            }
            return <span key={key}>{displayname}</span>;
          });
          const headline = MQSGUtil.getData(notice, "headline");
          const relatedwarrant = MQSGUtil.getData(
            notice,
            "related_warrant"
          ).replace(/;/g, ", ");
          const id = MQSGUtil.getData(notice, "id");
          const view = MQSGUtil.getAPIBasePath() + "/pdf?type=notice&id=" + id;

          const column1 = (
            <td className="date" style={{ whiteSpace: "nowrap" }}>
              {date}
            </td>
          );
          const column2 = <td className="underlying">{underlyingsJSX}</td>;
          const column3 = <td className="headline">{headline}</td>;
          const column4 = <td className="related_warrant">{relatedwarrant}</td>;
          const column5 = (
            <td className="view">
              <a target="_blank" href={view}>
                <img src="/img/bullet/pdf.png" alt="" />
              </a>
            </td>
          );

          const row = (
            <tr className="rowtmpl_notice" key={index + "_" + id}>
              {column1}
              {column2}
              {column3}
              {column4}
              {column5}
            </tr>
          );
          rows.push(row);
        });
      }
    }
    return rows;
  }

  render() {
    const rows = this.getRows();
    return (
      <div id="notice" className="tabcontent" style={{overflowX:'auto'}}>
        <table className="table table-striped table-responsive">
          <thead>
            <tr>
              <th>Date</th>
              <th>Underlying</th>
              <th>Headline</th>
              <th>Related Warrant</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}
