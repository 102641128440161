
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class singlesharecallwarrantexample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>
                                                Single stock call warrants that expire with value are those where the average closing price of the underlying share is above the exercise price. These warrants thus have an intrinsic value. You will then use this settlement price, which is, the five day
                                                average closing price prior to expiry date, minus the exercise price, divided by warrants per share.
                                            </p>
                                            <p>
                                                Let’s look at an example of an expired call warrant.
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/Example-of-Expired-Stock-Call-Warrant.jpg" alt="Example of Expired Stock Call Warrant." />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/Example-of-Expired-Stock-Call-Warrant.jpg')}>View table</div>
                                            </div>
                                            <p>
                                                Let's assume the exercise price of the call warrant is $1.00 and the warrant has a warrants per share of 1. Prior to the expiry date, the five business days average closing price of the underlying share is $1.30.
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/Calculation-of-Cash-Settlement-Amount.jpg" alt />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/Calculation-of-Cash-Settlement-Amount.jpg')}>View table</div>
                                            </div>
                                            <p>
                                                Since the settlement price of the shares at $1.30 is higher than the exercise price of the call warrant, the warrant expires in-the-money. To work out the cash settlement amount, you would subtract $1.00 from $1.30 and divide this by 1, giving you a settlement
                                                value of 30 cents per warrant. If the average closing price of the underlying shares was the same as the exercise price of $1 or less, the warrant expires out-of-money and you would not receive a settlement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}