import React, { Component } from "react";
import BreadcrumbBar from "../breadcrumbBar"
import "./sitemap.css";
import WarrantSelector from "../../pages/tools/warrantselector/selector";
import MQSGUtil from "../mqsgUtil";
import PageHeader from "../pageHeader/pageHeader";
import { Link } from "react-router-dom";




export default class Sitemap extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {

        return (
            <div id="Sitemap" className="pageobj">
                <BreadcrumbBar
                    link="/Sitemap"
                    group="Home"
                    title="Sitemap"
                />
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="section">
                        <div className="row">
                            <div className="col-sm-12 sections">
                                <h1 style={{ padding: '0px', margin: '0px', borderBottom: '1px solid #FECC0A', lineHeight: '60px' }}>Sitemap</h1>
                                <h3><Link style={{ color: '#000' }} to="/home">Warrant tools</Link></h3>
                                <ul>
                                    <li><Link to="/tools/livematrix">Live matrix</Link></li>
                                    <li><Link to="/tools/selector">Warrant selector</Link></li>
                                    <li><Link to="/tools/warrantsearch">Warrant search</Link></li>
                                    <li><Link to={{ pathname: "/tools/HistoricalPerformance", query: { name: 'Performance' } }}>Historical Performance</Link></li>
                                    <li><Link to="/tools/warrantterms">Warrant terms</Link></li>
                                    <li><Link to="/tools/warrantcalculator">
                                        Warrant calculator
                                    </Link></li>
                                    <li><Link to="/tools/underlying">Underlying charts</Link></li>
                                    <li><Link to="/tools/warrantcomparison">
                                        Warrant comparison
                                    </Link></li>
                                    <li><Link to="/tools/document">Warrant documents</Link></li>
                                </ul>
                            </div>

                            <div className="col-sm-12 sections">
                                <h3 >Market news</h3>
                                <ul>
                                    {/* <li><Link to="/telegram">Join us on Telegram</Link></li> */}
                                    <li><Link to="/marketnews/dailyhighlights">
                                        Morning Market Buzz
                                    </Link></li>
                                    <li><Link to="/marketnews/overnightmarkets">
                                        Overnight market wrap
                                    </Link></li>
                                    <li><Link to="/marketnews/subscribe">Subscribe</Link></li>
                                    <li><Link to="/marketnews/photogallery">
                                        Photo gallery
                                    </Link></li>

                                </ul>
                            </div>

                            <div className="col-sm-12 sections">
                                <h3 ><Link style={{ color: '#000' }} to="/home/edu">Warrant education</Link></h3>
                                <ul>
                                    <li><Link to="/education/getstarted">
                                        How to get started
                                    </Link></li>
                                    <li><Link to="/education/brochure">Brochure</Link></li>
                                    <li><Link to="/education/warrantvideos">
                                        Educational videos
                                    </Link></li>
                                    <li><Link to="/SuccessSpeaks">Success Speaks</Link></li>
                                    <li><Link to="/InvestorAcademy">
                                        Investor academy
                                    </Link></li>
                                    <li><Link to="/education/whymacquarie">Why Macquarie</Link></li>
                                    <li><Link to="/education/faq">FAQ</Link></li>
                                    <li><Link to="/education/seminar">Seminar</Link></li>
                                    <li><Link to="/education/SiMSCI">SiMSCI</Link></li>
                                    <li><Link to="/education/Indexwarrants">Index Warrants</Link></li>
                                    {/* <li><Link to="/education/hstech">HSTECH</Link></li> */}
                                </ul>
                            </div>

                            <div className="col-sm-12 sections">
                                <h3>Warrant statistics</h3>
                                <ul>
                                    <li><Link to="/marketdata/toptradedwarrants">
                                        Top traded warrants
                                    </Link></li>
                                    <li><Link to="/marketdata/topgainloss">Top 10 gainers / losers</Link></li>
                                    <li><Link to="/marketdata/turnover">
                                        Warrant market turnover
                                    </Link></li>
                                    <li><Link to="/marketdata/turnoverdist">Turnover distribution</Link></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                </div>
            </div >
        );
    }
}









