import liveMatrixStaticData from "../../data/livematrixData";
import underlyingChartStaticData from "../../data/underlyingchartData";
import calculatorStaticData from "../../data/calculatorData";
import {
  comparisonStaticData,
  underlyingStaticData,
} from "../../data/comparisonData";
import documentStaticData from "../../data/documentData";
import searchStaticData from "../../data/searchData";
import selectorStaticData from "../../data/selectorData";
import termStaticData from "../../data/termData";
import dwtoolStaticData from "../../data/dwtoolData";
import quickheadlineStaticData from "../../data/dwnewsData";
import seminarStaticData from "../../data/dweduData";
import dwturnoverStaticData from "../../data/dwturnoverData";
import rankingStaticData from "../../data/rankingData";
import turnoverdistStaticData from "../../data/turnoverdistData";
import dailyhighStaticData from "../../data/dailyhighData";
import highlightStaticData from "../../data/highlightData";
import photogalleryStaticData, { albumID } from "../../data/photogalleryData";
import overnighthighStaticData from "../../data/overnightData";
import seminarSectionStaticData from "../../data/seminarData";
import liveindexfutureStaticData from "../../data/liveindexfutureData";

class MQSGUtil {
  static indicators = [
    { value: "HIGHRESP", label: "Responsive", classname: "icon-responsive" },
    { value: "HOTLIST", label: "Hot warrants", classname: "icon-hotdw" },
    { value: "APPCHEXPRY", label: "Near Expiry", classname: "icon-expiry" },
    {
      value: "HIGHLQDY",
      label: "Highest liquidity",
      classname: "icon-liquidity",
    },
  ];

  static underlyingtickerdisplaynameMap = {
    N225: "Nikkei225",
    A17U: "AREIT",
    AWX: "AEM",
    "BABA-SW": "ALIBABA",
    "BILIBILI-SW": "BILIBILI",
    "BIDU-SW": "BAIDU",
    BN4: "KEPCORP",
    BVA: "TOPGLOVE",
    C31: "CAPLAND",
    C09: "CITYDEV",
    C52: "COMFORT",
    C6L: "SIA",
    D05: "DBS",
    F34: "WILMAR",
    G13: "GENTING",
    S08: "SINGPOST",
    BS6: "YANGZIJIANG",
    AIY: "IFAST",
    "JD-SW": "JD.com",
    N2IU: "MAPLECOMTRUST",
    "NTES-S": "NETEASE",
    O39: "OCBC",
    S51: "SEMBMAR",
    S68: "SGX",
    TENCENT: "TENCENT",
    U11: "UOB",
    U14: "UOL",
    V03: "VENTURE",
    "XIAOMI-W": "XIAOMI",
    Z74: "SINGTEL",
    "Top Glove": "TOP GLOVE",
    "GEELY AUTO": "GEELY",
    "MEITUAN-W": "MEITUAN",
    "SUNNY OPTICAL": "SUNNY",
    "BYD COMPANY": "BYD",
    "0208300": "HSTECH",
    "HANG SENG TECH": "HSTECH",
    "HANG SENG TECH INDEX": "HSTECH",
    S58: "SATS",
    T39: "SPH",
    GANFENGLITHIUM: "GANFENG",
    "9CI": "CAPITALANDINV",
    U96: "SembCorp Industries",
    NDX: "NASDAQ-100",
    DJI: "DOW JONES",
    M44U:'MapleTreeLogistics',
    'KUAISHOU-W':'KUAISHOU',
    '5E2':'Seatrium',
    'S63':'ST Engineering'
  };

  static getUpDownClass(nc) {
    if (nc !== undefined && nc !== null) {
      const ncstring = nc.toString();
      const ncval = Number(nc);

      if (!isNaN(ncval)) {
        // Check if 0
        if (ncval === 0) {
          return "";
        }
        // If -nnn ?
        else if (ncstring.length > 1 && ncstring.startsWith("-")) {
          return "val down";
        }
        // If +nnn ?
        else if (
          (ncstring.length > 1 && ncstring.startsWith("+")) ||
          ncval > 0
        ) {
          return "val up";
        }
      }
    }

    // Any other case, return ""
    return "";
  }

  static isPositive(value, isEqualtoZero = false) {
    let valueUse = value;
    if (typeof value === "string") {
      valueUse = valueUse.replace(/,/g, "");
      valueUse = valueUse.replace(/%/g, "");
    }
    const valueNumber = Number(valueUse);
    if (!isNaN(valueNumber)) {
      if (isEqualtoZero && valueNumber >= 0) {
        return true;
      } else if (valueNumber > 0) {
        return true;
      }
    }
    return false;
  }

  static signedString(
    value,
    posSign = "+",
    negSign = "-",
    isEqualtoZero = false
  ) {
    if (MQSGUtil.isPositive(value, isEqualtoZero)) {
      return posSign + value;
    }
    return negSign + value;
  }

  static getData(source, fieldName, noData = "") {
    if (source !== undefined && source !== null && fieldName in source) {
      return source[fieldName];
    }
    return noData;
  }

  static getFormatPrice(source, fieldName, noData = "", dp = null) {
    // return is a string with comma
    const value = MQSGUtil.getNumber(source, fieldName, null, dp);
    if (value !== null) {
      return Intl.NumberFormat().format(value);
    }
    return noData;
  }

  static getNumber(
    source,
    fieldName,
    noData = "",
    dp = null,
    dividedBy = 1,
    formatAsPrice = false
  ) {
    const value = MQSGUtil.getData(source, fieldName, null);
    if (value !== null) {
      let valueUse = value;
      if (typeof value === "string") {
        valueUse = valueUse.replace(/,/g, "");
        valueUse = valueUse.replace(/%/g, "");
      }
      valueUse = Number(valueUse) / dividedBy;
      if (!isNaN(valueUse)) {
        if (dp !== null) {
          valueUse = Number(valueUse.toFixed(dp));
        }
        if (formatAsPrice) {
          return Intl.NumberFormat().format(valueUse);
        }
        return valueUse;
      }
    }
    return noData;
  }

  static getDecimalPlace(number) {
    const numberString = number.toString();
    const decimalIndex = numberString.indexOf(".");
    if (decimalIndex > -1) {
      return Number(numberString.length - (decimalIndex + 1));
    }
    return 0;
  }

  static getFormatKValue(source, fieldName, noData = "", formatAsPrice = true) {
    let retvalue = noData;
    const value = MQSGUtil.getNumber(source, fieldName, null);
    if (value !== null) {
      if (value > 1000) {
        retvalue = (value / 1000).toFixed(0);
        if (formatAsPrice) {
          retvalue = new Intl.NumberFormat().format(retvalue);
        }
        retvalue = retvalue + "K";
      }
    }
    return retvalue;
  }

  static getIndicatorClassName(source) {
    let classnames = [];
    MQSGUtil.indicators.forEach((indicator) => {
      const value = MQSGUtil.getData(source, indicator.value, null);
      if (value !== null && value) {
        classnames.push(indicator.classname);
      }
    });
    return classnames;
  }

  static getUnderlyingTickerDisplayName(
    ric,
    underlyingticker,
    isReturnNull = false
  ) {
    let UnderlyingtickerdisplaynameMap = JSON.parse(window.localStorage.getItem('UnderlyingtickerdisplaynameMap'))
    let value = UnderlyingtickerdisplaynameMap.filter((v)=>{
      return v.underlying_ric == ric
    })
    if (isReturnNull) {
        return null;
      }
    if(value.length>0){
      return value[0].underlying_ticker;
    }else{
      return underlyingticker;
    }
  }
  static getScreenerJSONServlet = async()=>{
    const url = MQSGUtil.getAPIBasePath() + "/ScreenerJSONServlet/ric";
      let ScreenerJSONServlet = await fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          return result
        }
      );
      window.localStorage.setItem('UnderlyingtickerdisplaynameMap',JSON.stringify(ScreenerJSONServlet))
  }
  static getLastSelection(page) {
    const selection = localStorage.getItem("lastview");
    if (selection !== undefined) {
      //  修改
      if (page != "lastview") {
        if (selection != null) {
          let lastViewSplit = selection.split(",");
          return lastViewSplit[0];
        }
      }
      return selection;
      // 修改
    }
    return null;
  }

  static updateLastSelection(page, selection) {
    localStorage.setItem(page, selection);
  }

  static updateLastView(newRic) {
    // string: ric1,ric2,..,ric5
    // save max 5 rics
    const MAX = 5;
    let lastView = MQSGUtil.getLastSelection("lastview");
    if (lastView !== null && lastView.length > 0) {
      let lastViewSplit = lastView.split(",");
      const index = lastViewSplit.indexOf(newRic);
      if (index === -1) {
        if (lastViewSplit.length === MAX) {
          // replace index = 0 with new ric
          lastViewSplit.splice(lastViewSplit.length - 1, 1);
          // lastViewSplit[0] = newRic;
          lastViewSplit.unshift(newRic);
        } else {
          // put the new ric in index = 0
          lastViewSplit.splice(0, 0, newRic);
        }
      } else {
        // move the ric to index = 0
        lastViewSplit.splice(index, 1); // remove duplicated
        // lastViewSplit.splice(0, 0, newRic);
        lastViewSplit.unshift(newRic);
      }
      lastView = lastViewSplit.join(",");
    } else {
      lastView = newRic;
    }
    MQSGUtil.updateLastSelection("lastview", lastView);
    return lastView;
  }

  static isValidDateString(datetimeString) {
    if (isNaN(Date.parse(datetimeString))) {
      return false;
    }
    return true;
  }

  static parseDateString(datetimeString) {
    const ms = Date.parse(datetimeString);
    if (isNaN(ms)) {
      return null;
    }
    return new Date(ms);
  }

  static getMonthString(index, isShortForm = true) {
    const months = {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    };
    if (index in months) {
      if (isShortForm) {
        return months[index].substring(0, 3);
      }
      return months[index];
    }
    return "";
  }

  static getDateString(dateObj) {
    // output YYYY-MM-DD
    if (dateObj instanceof Date) {
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const monthString = month.toString().padStart(2, "0");
      const day = dateObj.getDate();
      const dayString = day.toString().padStart(2, "0");
      const dateString = year + "-" + monthString + "-" + dayString;
      return dateString;
    }
    return "";
  }

  static getNewsDateString(dateString) {
    const ms = MQSGUtil.parseDateString(dateString);
    if (ms !== null) {
      const dateObj = new Date(ms);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth();
      const monthString = MQSGUtil.getMonthString(month, false);
      const day = dateObj.getDate();
      const dayString = day.toString().padStart(2, "0");
      const dateString = dayString + " " + monthString + " " + year;
      return dateString;
    }
    return "";
  }

  static isUsingStaticData(component = null) {
    const exception = [];
    if (component !== null && exception.indexOf(component) > -1) {
      return true;
    }
    return false;
  }

  static getStaticData(component) {
    let staticData = null;
    switch (component) {
      case "LiveMatrix":
        staticData = liveMatrixStaticData;
        break;
      case "UnderlyingChart":
        staticData = underlyingChartStaticData;
        break;
      case "WarrantCalculator":
        staticData = calculatorStaticData;
        break;
      case "WarrantComparison":
        staticData = { comparisonStaticData, underlyingStaticData };
        break;
      case "WarrantDocument":
        staticData = documentStaticData;
        break;
      case "WarrantSearch":
        staticData = searchStaticData;
        break;
      case "WarrantSelector":
        staticData = selectorStaticData;
        break;
      case "Warrantterms":
        staticData = termStaticData;
        break;
      case "WarrantTools":
        staticData = dwtoolStaticData;
        break;
      case "QuickHeadline":
        staticData = quickheadlineStaticData;
        break;
      case "Seminar":
        staticData = seminarStaticData;
        break;
      case "DWTurnover":
        staticData = dwturnoverStaticData;
        break;
      case "TopGainer":
      case "TopTraded":
        staticData = rankingStaticData;
        break;
      case "TurnoverDist":
        staticData = turnoverdistStaticData;
        break;
      case "DailyHighlights":
        staticData = dailyhighStaticData;
        break;
      case "HighLight":
        staticData = highlightStaticData;
        break;
      case "IndividualGallery":
        staticData = { photogalleryStaticData, albumID };
        break;
      case "OvernightMarkets":
        staticData = overnighthighStaticData;
        break;
      case "Gallery":
        staticData = photogalleryStaticData;
        break;
      case "SeminarSection":
        staticData = seminarSectionStaticData;
        break;
      case "LiveIndexFuture":
        staticData = liveindexfutureStaticData;
        break;
      default:
    }
    return staticData;
  }
  static getAPIImgPath() {
    // return "https://t12a.trkd-hs.com/cmsapimqsg/v1/file/image/video/";
    return "https://www7a.trkd-hs.com/cmsapimqsg/v1/file/image/video/";

  }
  static getShortsVideoAPIImgPath() {
    // return "https://t12a.trkd-hs.com/cmsapimqsg/v1/file/image/ShortsVideo/";
    return "https://www7a.trkd-hs.com/cmsapimqsg/v1/file/image/ShortsVideo/";

  }
  static getShortsVideoBannerAPIImgPath() {
    // return "https://t12a.trkd-hs.com/cmsapimqsg/v1/file/image/shortsvideobanner/";
    return "https://www7a.trkd-hs.com/cmsapimqsg/v1/file/image/shortsvideobanner/";

  }
  static getShortsPlaylistVideoAPIImgPath() {
    // return "https://t12a.trkd-hs.com/cmsapimqsg/v1/file/image/shortsplaylist/";
    return "https://www7a.trkd-hs.com/cmsapimqsg/v1/file/image/shortsplaylist/";
  }
  static getAPIBasePath() {
    return "/apimqsg";
    // return "https://www.warrants.com.sg/apimqsg";
  }
}

export default MQSGUtil;
