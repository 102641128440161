
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class howaputwarrantworks extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="" >
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <h2>Hypothetical example of a put warrant</h2>
                                            <p>If you are wanting to profit from downward movements or are fearful of losses on your existing portfolio from short-term corrections, put warrants may be the tool you are looking for.<br /><br /> In our example below using a put
                                                warrant over STI, you can see that the put warrant, denoted by the yellow line, moves in inverse directions with the STI which is denoted by the dotted line.<br /><br /> If you have a view that the Singapore market, represented
                                                by the Straits Times Index (STI), had moved too high within a short space of time and due to fall, you could purchase an STI put warrant to profit from your view.</p>
                                            <div className="imgholder hidden-xs">
                                                <img src="/img/education/STI-Put-Warrants-Graph_enlarge.jpg" />
                                                <div className="visible-xs"><a href="javascript:showImgpopup('edu/img/STI-Put-Warrants-Graph_enlarge.jpg','600','450')">View
                                                    chart</a></div>
                                            </div>
                                            <p>In our example, the STI took a 7% tumble in two weeks. If you had purchased the STI put warrant at 3.6 cents when the index was at 3,001, you could then sell the put warrant at 6.7 cents when the index traded at 2,779, earning
                                                a profit of 86%.<br /><br /> If you were holding onto a portfolio of Singapore stocks, the 86% gain from the STI put warrant could be used to offset the losses you had on your portfolio. This strategy is known as ‘hedging’.</p>
                                            <div className="imgholder">
                                                <img src="/img/education/script_0206-STI-Index-Vs-Warrant-Price_enlarge.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}