import React, { Component } from "react";
import BreadcrumbBar from "../breadcrumbBar"
import "./landingPage.css";
import WarrantSelector from "../../pages/tools/warrantselector/selector";
import MQSGUtil from "../mqsgUtil";
import PageHeader from "../pageHeader/pageHeader";
import { Link } from "react-router-dom";




export default class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        lazyload()
    }
    render() {

        return (
            <div id="landingPage" className="pageobj" style={{ marginTop: '71px', marginBottom: '0px' }}>
                <nav className="navbar navbar-inverse navbar-fixed-top navbar-larger" id="topnavbar">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <a className="navbar-brand" href="/home">
                                <img src="/img/home/logo.png" alt="Macquarie logo" />{" "}
                                <span style={{ position: "relative", top: "2px", fontFamily: "'MCQGlobal_W_Rg', 'MCQGlobal_W_Bd', 'Roboto', 'Arial', 'sans-serif'" }}>
                                    {" "}
                                    | Singapore Warrants
                                </span>
                            </a>
                        </div>
                    </div>
                </nav>
                {/* container-fluid   hidden-xs hidden-sm*/}
                <div className="banner">
                    <div className="row" style={{ padding: '0px', position: 'relative', background: '#000' }}>
                        {/* <div className="hidden-xs hidden-sm col-md-4  banner_text" style={{ background: '#000', padding: '15px', height: '362px' }}>
                                <p style={{ color: '#fff', fontSize: '42px', lineHeight: '42px' }}>Leverage your <br /> trading returns<br /> with  <span style={{ color: '#29949e' }}>warrants</span></p>
                                <p style={{ color: '#fff', fontSize: '27px' }}>Discover Asia’s leading warrants issuer since <br />2005</p>
                                <div style={{ border: '1px solid #29949e', fontFamily: 'MCQ_Global_App', padding: '5px 15px', lineHeight: '24px', color: '#fff', fontSize: '18px', borderRadius: '15px', display: 'inline-block' }}>
                                    <a href="/home/edu" target="_blank" style={{ color: '#fff' }}>
                                        <span >Click </span><a href="/home/edu" target="_blank" style={{ fontFamily: 'MCQGlobal_A_Md', color: '#29949e', textDecoration: 'underline' }}>here</a> <span> to find out</span>
                                        <br />
                                        more about Warrants!
                                    </a>
                                </div>
                            </div> */}
                        {/* <div className="banner_text  col-xs-12 col-sm-12 hidden-md hidden-lg" style={{ background: '#000', padding: '15px', height: '100%' }}>
                                <div className="row banner_p" style={{ position: 'relative', marginTop: '8%' }}>
                                    <div className="col-xs-7 col-sm-7">
                                        <p style={{ color: '#fff', fontSize: '21px' }}>Leverage your trading returns with  <span style={{ color: '#29949e', fontFamily: 'MCQ_Global_App' }}>warrants</span></p>
                                        <p style={{ color: '#fff', fontSize: '13px', fontFamily: 'MCQ_Global_App_Medium' }}>Discover Asia’s leading warrants issuer since <br />2005</p>
                                    </div>

                                    <div className="col-xs-5 col-sm-5" style={{
                                        position: 'absolute',
                                        bottom: '12px', fontFamily: 'MCQ_Global_App', maxWidth: '100%', lineHeight: '12px', border: '1px solid #29949e', padding: '5px', color: '#fff', fontSize: '9px', borderRadius: '10px', display: 'inline-block', textAlign: 'center'
                                    }}>
                                        <a href="/home/edu" target="_blank" style={{ color: '#fff' }}>
                                            <span>Click </span>
                                            <a href="/home/edu" target="_blank" style={{ fontFamily: 'MCQGlobal_A_Md', color: '#29949e', textDecoration: 'underline' }}>here</a>
                                            <span> to find out</span>
                                            <br />
                                            more about Warrants!
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                        <div className="col-md-8 col-xs-12 col-sm-12  hidden-md hidden-lg" style={{ padding: '0px' }}>
                            <a href="/home/edu" target="_blank">
                                <img src="/img/landingPage/Landing Page Hero mobile.jpg" alt="" style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }} />
                            </a>
                        </div>
                        <div className="col-md-12 hidden-xs hidden-sm" style={{ padding: '0px' }}>
                            <a href="/home/edu" target="_blank">
                                <img src="/img/landingPage/Landing Page Hero desktop.jpg" alt="" style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ marginBottom: '40px' }}>
                    <h2 className="title">Why trade warrants?</h2>
                    <div className="section hidden-md hidden-lg Whytrade" style={{ padding: '0px 0px', background: '#F0F0F0' }}>
                        <div style={{ background: '#fff', padding: '10px' }}>
                            <h3 style={{ fontWeight: 'bold' }}>Key benefits</h3>
                            <h4 style={{ fontWeight: 'bold' }}>Leverage</h4>
                            <p>Warrants move between <b style={{ fontFamily: 'MCQ_Global_App_Medium' }}>5 to 20x.</b> more than the price of the underlying asset.</p>
                            <div style={{ display: 'flex' }}>
                                <img src="/img/landingPage/Leverage.png" alt="" style={{ width: '50px', height: '45px', marginRight: '10px' }} />
                                <p>For example, a warrant with an effective gearing of 20x will move, both positively and negatively, approximately 20%. given a 1% move in the underlying.
                                </p>
                            </div>
                            <h4 style={{ fontWeight: 'bold' }}>Limited Loss</h4>
                            <p>The downside risk for warrants is capped at the initial investment as there are no margin calls.</p>
                            <div style={{ display: 'flex' }}>
                                <img src="/img/landingPage/Limited Loss icon.png" alt="" style={{ width: '50px', height: '45px', marginRight: '10px' }} />
                                <p>Hence warrants provide leverage on the upside and limited loss for investors.
                                </p>
                            </div>
                            <h4 style={{ fontWeight: 'bold' }}>Low capital outlay
                            </h4>
                            <p>While a share can be costly for some investors, a warrant typically cost <b style={{ fontFamily: 'MCQ_Global_App_Medium' }}>10-20 cents.</b>
                            </p>
                            <div style={{ display: 'flex' }}>
                                <img src="/img/landingPage/Low Cap Outlay icon.png" alt="" style={{ width: '50px', height: '45px', marginRight: '10px' }} />
                                <p>Warrants enable investors to gain exposure to blue chip shares for a fraction of the share price.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="banner  hidden-xs hidden-sm Whytrade">
                        {/* <img src="/img/landingPage/keyBenefits.png" alt="" /> */}
                        <h3 style={{ fontWeight: 'bold', padding: '20px 20px 0px 40px', background: '#fff', margin: '0px' }}>Key benefits</h3>
                        <div style={{ background: '#fff', padding: '5px 25px', display: 'flex' }}>
                            <div style={{ padding: '0px 15px' }}>
                                <h4 style={{ fontWeight: 'bold' }}>Leverage</h4>
                                <p>Warrants move between <b>5 to 20x.</b> more than the price of the underlying asset.</p>
                                <div>
                                    <img src="/img/landingPage/Leverage.png" alt="" style={{ width: '100px', height: '80px', marginRight: '10px', marginBottom: '10px' }} />
                                    <p>For example, a warrant with an effective gearing of 20x will move, both positively and negatively, approximately 20%. given a 1% move in the underlying.
                                    </p>
                                </div>
                            </div>
                            <div style={{ padding: '0px 15px' }}>
                                <h4 style={{ fontWeight: 'bold' }}>Limited Loss</h4>
                                <p>The downside risk for warrants is capped at the initial investment as there are no margin calls.</p>
                                <div >
                                    <img src="/img/landingPage/Limited Loss icon.png" alt="" style={{ width: '70px', height: '80px', marginRight: '10px', marginBottom: '10px' }} />
                                    <p>Hence warrants provide leverage on the upside and limited loss for investors.
                                    </p>
                                </div>
                            </div>
                            <div style={{ padding: '0px 15px' }}>
                                <h4 style={{ fontWeight: 'bold' }}>Low capital outlay
                                </h4>
                                <p>While a share can be costly for some investors, a warrant typically cost <b>10-20 cents.</b>
                                </p>
                                <div >
                                    <img src="/img/landingPage/Low Cap Outlay icon.png" alt="" style={{ width: '100px', height: '80px', marginRight: '10px', marginBottom: '10px' }} />
                                    <p>Warrants enable investors to gain exposure to blue chip shares for a fraction of the share price.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="main  " style={{ fontFamily: 'MCQ_Global_App' }}>
                        <div className="main_top">
                            <img src="/img/landingPage/Beware of risk icon.png" alt="" />
                            <div>
                                <h3 className="hidden-md hidden-lg">Be aware of the <br />risks involved</h3>
                                <h3 className="hidden-sm hidden-xs">Be aware of the risks involved</h3>
                                <p className="hidden-xs hidden-sm">It’s crucial to thoroughly <b>understand the risks</b> of any financial products you choose to invest in. You are strongly encouraged to read and understand the risks involved in
                                    trading warrant before making any investment decision. You can find information on <b>Risks</b> <a href="/education/brochure" target="_blank" style={{ color: '#fff', textDecoration: 'underline' }}>here.</a>
                                </p>
                            </div>
                        </div>
                        {/* <div className="main_bottom hidden-xs hidden-sm">
                            
                        </div> */}
                        <div className="main_bottom  hidden-md hidden-lg">
                            It’s crucial to thoroughly <b>understand the risk</b> of any financial products you choose to invest in. You are strongly encouraged to read and understand the risks involved in trading warrant before making any investment decision. You can find information on <b>Risks</b> <a href="/education/brochure" target="_blank" style={{ color: '#fff' }}>here.</a>
                        </div>
                    </div>
                    <h2 className="title">What are structured warrants?</h2>
                    {/* <div className="banner hidden-xs hidden-sm">
                        <img src="/img/landingPage/bru.png" alt="" />
                    </div> */}
                    <div className="section Whatare_text" style={{ padding: '0px 0px', marginTop: '0px', background: '#F0F0F0' }}>
                        <div style={{
                            background: '#fff', padding: '25px 15px 25px 15px', fontSize: '18px'
                            , color: '#004559', fontFamily: 'MCQ_Global_App'
                        }}>
                            <div style={{ display: 'flex', padding: '0px 20px' }} className="whatAre_img">
                                <div className="Whatare_text_img1">
                                    <img src="/img/landingPage/bull_and_bear_log.png" alt="" style={{ width: '130px', height: '120px', margin: '7px' }} />
                                </div>
                                <div>
                                    Structured warrants are leveraged derivative instruments issued by banks and financial institutions. Structured warrants derive their value from, and generally move in <b>greater percentages</b> than the price movements in the <b>underlying assets</b> that they track.
                                    <br />
                                    <br />
                                    <p className="hidden-xs hidden-sm" style={{ color: '#004559' }}>Listed on the SGX and classified as Specified Investment Product (SIP). Investors need to be SIP qualified to invest in structured warrants.</p>
                                </div>
                            </div>
                            <div className="hidden-md hidden-lg">Listed on the SGX and classified as Specified Investment Product (SIP). Investors need to be SIP qualified to invest in structured warrants.
                            </div>
                            <div>
                                <img src="/img/landingPage/Call_warrants_2.jpg" alt="" className="Whatare_text_img" />
                                <img src="/img/landingPage/Put_warrants_1.jpg" alt="" className="Whatare_text_img" />
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    <h2 className="title  hidden-xs hidden-sm">How to navigate our website</h2>
                    <h2 className="title  hidden-md hidden-lg">How to navigate the website</h2>
                    <div className="section" style={{ marginTop: '0px' }} >
                        <div className="row">
                            <div className="col-md-12 sections" style={{ padding: '0px' }}>
                                <WhatAreWarrants />
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="bottom_left">
                            <div>
                                <a href="/home/edu" target="_blank" style={{ fontFamily: 'MCQ_Global_App' }}><span >Click </span>
                                    <a href="/home/edu" target="_blank">here</a>
                                    <span style={{ fontFamily: 'MCQ_Global_App' }}> to find out <br />more about Warrants!</span>
                                </a>
                            </div>
                            <p className="hidden-xs hidden-sm" style={{ fontFamily: 'MCQ_Global_App' }}>Need more help? +65 6601 0289</p>
                            <p className="hidden-lg hidden-md" style={{ fontFamily: 'MCQ_Global_App' }}>Need more help?<br /> +65 6601 0289</p>
                        </div>
                        <div className="bottom_right" style={{ position: 'relative' }}>
                            <div className="bottom_center">
                                <p style={{ color: '#fff', fontSize: '16px', border: '1px solid #29949e' }}>Follow us</p>
                            </div>
                            <div>
                                <a target="_blank"
                                    href="/marketnews/subscribe" style={{ height: '60px', width: '60px' }} >
                                    <img src="/img/bullet/subscribe_icon.png" alt="" />
                                </a>
                                <p className="hidden-xs hidden-sm">Newsletter</p>
                            </div>
                            <div>
                                <a target="_blank" href="https://t.me/MacquarieWarrantsSG">
                                    <img src="/img/bullet/t_logo.png" alt="" />
                                </a>
                                <p className="hidden-xs hidden-sm">Telegram</p>
                            </div>
                            <div>
                                <a target="_blank" href="https://www.facebook.com/MacquarieWarrantsSG/?utm_source=MQ&utm_medium=Icon&utm_campaign=FB">
                                    <img src="/img/bullet/Facebook-logo-blue-circle-large-transparent-png.png" alt="" />
                                </a>

                                <p className="hidden-xs hidden-sm">Facebook</p>
                            </div>
                            <div className="youtu_img">
                                <a target="_blank" href="https://bit.ly/34Bgg1t">
                                    <img src="/img/bullet/Youtube-logo-png.png" alt="" />
                                </a>
                                <p className="hidden-xs hidden-sm">YouTube</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="sitemap-othr-xs visible-xs">
                                <div className="sitemap-othr-col">
                                    <div>
                                        <a href="/about/importantinformation">
                                            Important information
                                        </a>
                                    </div>
                                    <div>
                                        <a href="http://www.macquarie.sg/mgl/sg/about/privacy">
                                            Privacy policy
                                        </a>
                                    </div>
                                    <div>
                                        <a href="/about/termsofuse">Terms of use</a>
                                    </div>
                                </div>
                                <div className="sitemap-othr-col last">
                                    <div>
                                        <a href="/about/contactus">Contact us</a>
                                    </div>
                                    <div>
                                        <a href="/about/disclaimer">Disclaimer</a>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="col-sm-12 sitemap-othr-sm visible-sm">
                                <p>
                                    <a href="/about/importantinformation">
                                        Important information
                                    </a>{" "}
                                    |
                                    <a href="http://www.macquarie.sg/mgl/sg/about/privacy">
                                        Privacy policy
                                    </a>{" "}
                                    |<a href="/about/termsofuse">Terms of use</a> |
                                    <a href="/about/contactus">Contact us</a> |
                                    <a href="/about/disclaimer">Disclaimer</a>
                                </p>
                            </div>
                            <div className="col-md-6 sitemap-corp">
                                <p>
                                    © Macquarie Group Limited ABN 94 122 169 279. All rights
                                    reserved
                                </p>
                            </div>
                            <div className="col-md-6 sitemap-othr hidden-xs hidden-sm">
                                <p>
                                    <a href="/about/importantInformation">
                                        Important information
                                    </a>{" "}
                                    |
                                    <a href="http://www.macquarie.sg/mgl/sg/about/privacy">
                                        Privacy policy
                                    </a>{" "}
                                    |<a href="/about/termsofuse">Terms of use</a> |
                                    <a href="/about/contactus">Contact us</a> |
                                    <a href="/about/disclaimer">Disclaimer</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="rights">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 sitemap-corp">
                            <p>
                                © Macquarie Group Limited ABN 94 122 169 279. All rights
                                reserved
                            </p>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 sitemap-othr">
                            <p>
                                <a href="/about/importantInformation">
                                    Important information
                                </a>{" "}
                                |
                                <a href="http://www.macquarie.sg/mgl/sg/about/privacy">
                                    Privacy policy
                                </a>{" "}
                                |<a href="/about/termsofuse">Terms of use</a> |
                                <a href="/about/contactus">Contact us</a> |
                                <a href="/about/disclaimer">Disclaimer</a>
                            </p>
                        </div>
                    </div>
                </div> */}
            </div >
        );
    }
}
class WhatAreWarrants extends Component {

    render() {
        return (
            <div>
                <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ margin: "0px" }}>
                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="heading1">
                            <h4 className="panel-title">
                                <a role="button" className="collapsed" data-toggle="collapse" data-parent="#accordion1" href="#collapse1" aria-expanded="true" aria-controls="collapse1" >
                                    Homepage
                                    <span className="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
                            <div className="panel-body">
                                {/* 内容 */}
                                <p className="homepage_text" style={{ color: '#000' }}>The <a href="/home">homepage</a> is equipped with information and tools you need to learn about warrants. Here’s a simple guide as you land on the official website.
                                </p>
                                <img className="hidden-xs hidden-sm lazyload" data-src="/img/landingPage/homepage.png" src="" alt="" />
                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Homepage2.JPG" src="/img/landingPage/Homepage2.JPG" alt="" />
                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Live index futures prices.png" src="/img/landingPage/Live index futures prices.png" alt="" />
                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Live matrix.png" src="/img/landingPage/Live matrix.png" alt="" />
                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Top movers.png" src="/img/landingPage/Top movers.png" alt="" />
                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Warrant selector.png" src="/img/landingPage/Warrant selector.png" alt="" />
                                {/* <div className="row" style={{ position: 'relative' }}>
                                    <div className="col-md-9 visible-lg visible-md">
                                        <img src="/img/landingPage/Homepage.JPG" alt="" />
                                        <img src="/img/landingPage/Top movers box yellow.png" className="topMovers" />
                                        <img src="/img/landingPage/Top movers arrow.png" className="topMovers_arrow" />
                                        <img src="/img/landingPage/Top movers box yellow.png" className="liveIndex" />
                                        <img src="/img/landingPage/Live matrix arrow.png" className="liveIndex_arrow" />

                                        <img src="/img/landingPage/Top movers box yellow.png" className="Live" />
                                        <img src="/img/landingPage/Live index futures arrow.png" className="Live_arrow" />
                                        <img src="/img/landingPage/Top movers box yellow.png" className="WarrantSelector" />
                                        <img src="/img/landingPage/Warrant selector arrow.png" className="WarrantSelector_arrow" />

                                    </div>
                                    <div className="col-md-3  visible-lg visible-md" style={{ position: 'relative' }}>
                                        <p className="topMovers_p">Top movers: showcases the top stock and index movers of the moment. Click on the stock to see the price performance of the trending warrants tracking the top moving stock/index</p>
                                        <p className="liveIndex_p">Live matrix: type in the warrant code in the dropdown box to see how the warrant bid and price moves alongside the underlying bid and offer price                                        </p>
                                        <p className="Live_p">Live index futures prices: displays the live, streaming prices of HSI, HSETCH, Nikkei and SIMSCI futures which our index warrants track. Click on the refresh button of HSI and HSTECH futures for the most updated prices. </p>
                                        <p className="WarrantSelector_p">Warrant selector: view the current trending warrant! Also see how the warrant perform with different holding periods based on your target price of the selected underlying.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>


                    <div className="panel panel-default">
                        <div className="panel-heading" role="tab" id="heading2">
                            <h4 className="panel-title">
                                <a role="button" className="collapsed" data-toggle="collapse" data-parent="#accordion1" href="#collapse2" aria-expanded="true" aria-controls="collapse2" >
                                    Toolbars<span className="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                </a>
                            </h4>
                        </div>
                        <div id="collapse2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                            <div className="panel-body">
                                {/* 内容 */}
                                <div className="panel-group" id="accordion2" role="tablist" aria-multiselectable="true">
                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="heading2_1">
                                            <h4 className="panel-title">
                                                <a role="button" className="collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapse2_1" aria-expanded="true" aria-controls="collapse2_1" >
                                                    Warrant tools<span className="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapse2_1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2_1">
                                            <div className="panel-body" style={{ position: 'relative' }}>
                                                {/* <div className="row" style={{ position: 'relative' }}>
                                                    <div className="col-md-9 visible-lg visible-md">
                                                        <img src="/img/landingPage/Warrant tools.JPG " alt="" />
                                                        <img src="/img/landingPage/Top movers box yellow.png" className="Warrant_tools" />
                                                        <img src="/img/landingPage/Warrant tools arrow.png" className="Warrant_tools_arrow" />
                                                    </div>
                                                    <div className="col-md-3  visible-lg visible-md" style={{ position: 'relative' }}>
                                                        <p className="Warrant_tools_p">Warrants tools: all the tools that will help you search, price, calculate, and compare warrants. Also contains the warrant term sheets, expiry and adjustment notices.</p>
                                                    </div>
                                                </div> */}
                                                <a href="/education/warrantvideos" target="_blank">
                                                    <img className="hidden-xs hidden-sm lazyload" data-src="/img/landingPage/Warrant tools(12).png" src="/img/landingPage/Warrant tools(12).png" alt="" />
                                                    <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Warrant tool.png" src="/img/landingPage/Warrant tool.png" alt="" />
                                                </a>

                                                {/* <div style={{
                                                    position: 'absolute', background: '#FFDD00', color: '#000', fontSize: '10.8px', fontFamily: 'MCQ_Global_App', right: '25px'
                                                    , bottom: '45px',
                                                    textAlign: 'center',
                                                    padding: '5px 25px'
                                                }}
                                                    className="hidden-xs hidden-sm"
                                                >
                                                    Click <a href="/education/warrantvideos" style={{ color: '#000', textDecoration: 'underline' }}>here</a> to watch
                                                    <br />
                                                    "how to use Macquarie warrants tools"
                                                    <br />
                                                    tutorial videos
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="heading2_2">
                                            <h4 className="panel-title">
                                                <a role="button" className="collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapse2_2" aria-expanded="true" aria-controls="collapse2_2" >
                                                    Market news<span className="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapse2_2" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2_2">
                                            <div className="panel-body">
                                                {/* <div className="row" style={{ position: 'relative' }}>
                                                    <div className="col-md-9 visible-lg visible-md">
                                                        <img src="/img/landingPage/Market news.JPG " alt="" />
                                                        <img src="/img/landingPage/Top movers box yellow.png" className="Market_news" />
                                                        <img src="/img/landingPage/Market news arrow.png" className="Market_news_arrow" />
                                                    </div>
                                                    <div className="col-md-3  visible-lg visible-md" style={{ position: 'relative' }}>
                                                        <p className="Market_news_p">
                                                            Market news: this tab lists out the contents of our daily newsletter. You can also view the gallery for the upcoming events.
                                                            <br />
                                                            <br />
                                                            Please sign up here to subscribe our newsletter.
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <img className="hidden-xs hidden-sm lazyload" data-src="/img/landingPage/Market news(12).png" src="/img/landingPage/Market news(12).png" alt="" />
                                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Market news2.png" src="/img/landingPage/Market news2.png" alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="heading2_3">
                                            <h4 className="panel-title">
                                                <a role="button" className="collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapse2_3" aria-expanded="true" aria-controls="collapse2_3" >
                                                    Warrant education<span className="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapse2_3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2_3">
                                            <div className="panel-body">
                                                {/* <div className="row" style={{ position: 'relative' }}>
                                                    <div className="col-md-9 visible-lg visible-md">
                                                        <img src="/img/landingPage/Warrant education.JPG" alt="" />
                                                        <img src="/img/landingPage/Top movers box yellow.png" className="warrant_education" />
                                                        <img src="/img/landingPage/warrant education arrow.png" className="warrant_education_arrow" />
                                                    </div>
                                                    <div className="col-md-3  visible-lg visible-md" style={{ position: 'relative' }}>
                                                        <p className="warrant_education_p">
                                                            Warrant education: this tab includes the education materials you need to know about warrants. All designed to cater to both beginners and those seeking to deepen their knowledge in both written and video formats.
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <img className="hidden-xs hidden-sm lazyload" data-src="/img/landingPage/Warrant education(12).png" src="/img/landingPage/Warrant education(12).png" alt="" />
                                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Warrant education2.png" src="/img/landingPage/Warrant education2.png" alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="panel panel-default">
                                        <div className="panel-heading" role="tab" id="heading2_4">
                                            <h4 className="panel-title">
                                                <a role="button" className="collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapse2_4" aria-expanded="true" aria-controls="collapse2_4" >
                                                    Warrant statistics<span className="glyphicon glyphicon-triangle-bottom" style={{ float: 'right' }}></span>
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapse2_4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2_4">
                                            <div className="panel-body">
                                                {/* <div className="row" style={{ position: 'relative' }}>
                                                    <div className="col-md-9 visible-lg visible-md">
                                                        <img src="/img/landingPage/Warrant statistics.JPG" alt="" />
                                                        <img src="/img/landingPage/Top movers box yellow.png" className="warrant_statistics" />
                                                        <img src="/img/landingPage/warrant statistics arrow.png" className="warrant_statistics_arrow" />
                                                    </div>
                                                    <div className="col-md-3  visible-lg visible-md" style={{ position: 'relative' }}>
                                                        <p className="warrant_statistics_p">
                                                            Warrant statistics: the day’s top traded, turnover split, top warrant gainers and losers
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <img className="hidden-xs hidden-sm lazyload" data-src="/img/landingPage/Warrant statistics(12).png" src="/img/landingPage/Warrant statistics(12).png" alt="" />
                                                <img className="hidden-md hidden-lg lazyload" data-src="/img/landingPage/Warrant statistics2.png" src="/img/landingPage/Warrant statistics2.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}









