import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import AdvertBox from "../../../components/advert/advert";
import "./seminar.css";

export default class Seminar extends Component {
  componentDidMount() {
    document.title = "Seminar | Singapore Warrants | Macquarie"
  }
  render() {
    return (
      <div id="seminar" className="pageobj">
        <BreadcrumbBar
          link="/home/edu"
          group="Warrant education"
          title="Seminar"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="topimg">
            <img src="/img/bg/top-seminar.jpg" alt="" />
          </div>
        </div>
        <div className="container-fluid" style={{ padding: '0px' }} >
          <div className="row">
            <div className="col-md-8" style={{ paddingLeft: '0px' }}>
              <SeminarSection />
            </div>
            <div className="hidden-xs hidden-sm col-md-4" style={{ paddingRight: '0px' }}>
              <Videos />
              <InvestorAcademy />
              <MarketBuzz />
              <AdvertBox />
            </div>
          </div>
        </div>
      </div >
    );
  }
}

class SeminarSection extends Component {
  constructor() {
    super();
    this.state = {
      seminardata: {
        seminarList: [],
      },
      regModel: false,
      webinarShow: false,
      recordedShow: false,
      emailWarning: false,
      name: '',
      email: '',
      nameWarning: false,
      invalid: false,
      httpEmailText: false,
      id: ''
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("SeminarSection");
    if (staticData !== null) {
      this.setState({ seminardata: staticData });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("SeminarSection")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let seminardata = null;
    const url = MQSGUtil.getAPIBasePath() + "/SeminarJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          seminardata = result;
          seminardata.seminarList.reverse();
          this.setState({ seminardata });
        },
        (error) => {
          this.setState({ seminardata });
        }
      );
  }

  onReadMore() {
  }
  linkTo(v) {
    var linkS = [
      'http://www.facebook.com/share.php?u=https%3A//www.warrants.com.sg/education/seminar%3Fid%3D321%26lang%3Den&title=Why%20is%20it%20risky%20to%20trade%20warrants%20approaching%20expiry%3F%20&description=%3Cp%3E12%3C%2Fp%3E'
      , 'https://twitter.com/intent/tweet?url=https%3A//www.warrants.com.sg/education/seminar%3Fid%3D321%26lang%3Den&text=%3Cp%3E12%3C%2Fp%3E'
      , 'https://www.linkedin.cn/injobs/home'
    ]
    window.open(linkS[v], ' _blank')
  }
  regToLink(data) {
    if (data.link != '') {
      window.open(data.link, ' _blank')
    } else {
      var webinarShow = null;
      var recordedShow = null;
      if (data.webinar == "0") {
        webinarShow = false;
        recordedShow = false;
      } else if (data.webinar == "1" && data.recorded == "0") {
        webinarShow = true;
        recordedShow = false;
      } else if (data.webinar == "1" && data.recorded == "1") {
        webinarShow = false;
        recordedShow = true;
      } else {
        webinarShow = false;
        recordedShow = false;
      }
      this.setState({
        regModel: true,
        webinarShow,
        recordedShow,
        id: data.id
      })
    }
  }
  closeRegModel() {
    this.setState({
      regModel: false,
    })
  }
  getRows() {
    const rows = [];
    const seminardata = this.state.seminardata;
    if (seminardata != null) {
      const seminarlist = MQSGUtil.getData(seminardata, "seminarList");
      if (seminarlist !== null && Array.isArray(seminarlist)) {
        seminarlist.forEach((seminar, index) => {
          const id = MQSGUtil.getData(seminar, "id");
          const seminarid = "seminar-" + id;
          // let imgsrc = "/photo?seminar=" + id + "&lang=en";
          let imgsrc =
            MQSGUtil.getAPIBasePath() + "/photo?seminar=" + id + "&lang=en";
          // --- below code can be take out in PROD later
          // const demo = MQSGUtil.getData(seminar, "demo", null);
          // if (demo !== null && demo) {
          //   imgsrc = "/img/photo/seminar_id_" + id + ".jpeg";
          // }
          // --------------------------------------------
          const date = MQSGUtil.getData(seminar, "date");
          const venue = MQSGUtil.getData(seminar, "en_venue");
          const time = MQSGUtil.getData(seminar, "en_time");
          const title = MQSGUtil.getData(seminar, "en_title");
          const content = MQSGUtil.getData(seminar, "en_content");
          const contents = content.match(/<p>.*?p>/g);
          let contentsummary = "";
          if (contents != null && contents.length > 0) {
            contentsummary = contents[0];
            // contentsummary += "<a class='readmore'>Read more</a>";
          }
          // const contentShow = { __html: contentsummary };
          const contentShow = { __html: content };
          const div1 = (
            <div className="smr-img">
              <img src={imgsrc} alt=""></img>
            </div>
          );
          const div2 = (
            <div className="smr-body-v2">
              <div
                className="smr-txt-area"
                style={{ overflowWrap: "break-word" }}
              >
                <div className="smr-datevenue">
                  <span className="smr-date-v2">{date}</span> -&nbsp;
                  <span className="smr-venue-v2">{venue}</span>
                </div>
                <div className="smr-time visible-xs">{time}</div>
                <div className="smr-title-v2">{title}</div>
                <div
                  className="smr-content hidden-xs"
                  onClick={() => this.onReadMore()}
                  dangerouslySetInnerHTML={contentShow}
                ></div>
              </div>
              <div className="btn-area-v2">
                <div className="bookBtn-area">
                  <div className="bookBtn-v2 bgcolor-06" onClick={() => { this.regToLink(seminar) }}>Register</div>
                </div>
                <div className="shareBtn-area">
                  <div className="shareToLabel">Share to:</div>
                  <div className="shareBtn facebookBtn" onClick={() => { this.linkTo(0) }}></div>
                  <div className="shareBtn twitterBtn" onClick={() => { this.linkTo(1) }}></div>
                  <div className="shareBtn instagramBtn" onClick={() => { this.linkTo(2) }}></div>
                </div>
              </div>
            </div>
          );
          const row = (
            <div
              className="smr-row-v2"
              id={seminarid}
              key={index + "_" + seminarid}
            >
              {div1}
              {div2}
            </div>
          );
          rows.push(row);
        });
      }
    }
    return rows;
  }
  setEmail(e) {
    this.setState({
      email: e.target.value
    })
  }
  setName(e) {
    this.setState({
      name: e.target.value
    })
  }
  seminarreg() {
    const url = MQSGUtil.getAPIBasePath() + "/seminarreg";
    fetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'email=' + this.state.email + '&firstName=' + this.state.name + '&seminar=' + this.state.id + '&lang=en',
      }
    ).then((res) => res.json()).then(
      (result) => {
      },
      (error) => {
      }
    );
  }
  submit() {
    if (this.state.name == '') {
      this.setState({
        nameWarning: true,
      })
    } else {
      this.setState({
        nameWarning: false,
      })
    }
    if (this.state.email == '') {
      this.setState({
        emailWarning: true,
        invalid: false
      })
    } else {
      this.setState({
        emailWarning: false,
      })
    }
    var emailPat = /^(.+)@(.+)$/;
    var matchArray = this.state.email.match(emailPat);
    if (this.state.email != '' && matchArray == null) {
      this.setState({
        invalid: true,
        emailWarning: false,
      })
    } else {
      this.setState({
        invalid: false,
      })
    }
    if (matchArray == null) {
    } else {
      const url = MQSGUtil.getAPIBasePath() + "/registration?p=register";
      fetch(
        url,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          method: 'POST',
          body: 'email=' + this.state.email
        }
      ).then((res) => res.json()).then(
        (result) => {
          this.seminarreg();
          this.setState({
            httpEmailText: true,
            regModel: false,
            emailWarning: false,
            nameWarning: false,
            invalid: false
          }, () => {
            window.setTimeout(() => {
              this.setState({
                httpEmailText: false,
                regModel: false,
                emailWarning: false,
                nameWarning: false,
                invalid: false
              })
            }, 3000)

          })
        },
        (error) => {
        }
      );
    }
  }
  render() {
    const rows = this.getRows();
    return (
      <div className="section" id="smr-section">
        <div className="page-header">
          <h2>Seminar</h2>
        </div>
        <p>
          Macquarie believes that investor education is essential for trading
          warrants and is committed to improving the understanding of investors.
          One of the best ways to improve your understanding is to attend one of
          our free seminars. If there are no seminars currently listed below,
          please sign up as a member to receive advance notice for the next
          upcoming event.
        </p>
        <div id="smr-list">{rows}</div>
        <div
          id="nosmr"
          style={{
            display:
              this.state.seminardata.seminarList.length > 0 ? "none" : "block",
            // display: "none",
          }}
        >
          <p>
            There are no upcoming seminars for now.&nbsp;
            <a href="/marketnews/subscribe">
              <u>Subscribe</u>
            </a> to our newsletter now to receive exclusive invitations for any
            upcoming seminars!
          </p>
        </div>
        <div id="popup-customized" style={{ padding: '0px', display: this.state.regModel ? 'table' : 'none' }}>
          <div>
            <div id="book_area">
              <img src="/img/bullet/icon-close-white.png" alt="" className="btn-close" style={{ top: '40px', right: '40px' }} onClick={(e) => { this.closeRegModel(e) }} />
              <div className="page-header">
                <h1>Register now</h1>
              </div>
              <input type="text" name="firstName" placeholder="Name as per NRIC" onChange={(e) => { this.setName(e) }} />
              <div className="warning" style={{ visibility: this.state.nameWarning ? "visible" : 'hidden' }}>Name as per NRIC is required. Please fill in.</div>
              <input type="text" name="email" placeholder="Email" onChange={(e) => { this.setEmail(e) }} />
              <div className="warning" style={{ display: this.state.invalid ? "block" : 'none' }}>* Invalid email</div>
              <div className="warning" style={{ visibility: this.state.emailWarning ? "visible" : 'hidden' }}>Email is required. Please fill in.</div>
              <p className="small">
                By clicking ”Submit”, you confirm that you would like to subscribe to Macquarie’s daily warrant newsletter. By subscribing, you will also automatically receive invitations or marketing material from Macquarie for warrant seminars and other warrant related initiatives. Macquarie website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities (Singapore) Pte Ltd and other companies of Macquarie Group (“Macquarie”).
                <span className="webinar" style={{ display: this.state.webinarShow ? "inline" : 'none' }}>
                  Please note that your username, email address and/or participation during the Q&A or polling sessions, where applicable, may be recorded and/or published. You may view Macquarie’s privacy notice with regard to collection and management of personal data via the links at the bottom of this page.
                </span>
                <span className="recorded" style={{ display: this.state.recordedShow ? "inline" : 'none' }}>
                  This webinar will be recorded. By attending the webinar, you are consenting to be recorded and that your username and participation during the Q&A or polling sessions may be published. The recording will be for record-keeping, assurance, training and educational purposes. You may view Macquarie’s privacy notice with regard to collection and management of personal data via the links at the bottom of this page.
                </span>
              </p>
              <input type="button" id="submitBtn" value="Agree & Submit" onClick={() => { this.submit() }} />
            </div>
          </div>
        </div>
        <div id="popup-customized" style={{ display: this.state.httpEmailText ? 'table' : 'none' }}>
          <div>
            <h5>Your seminar registration was successful. Thank you</h5>
          </div>
        </div>
      </div>
    );
  }
}

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: null,
    };
  }
  getVideoApiLink() {
    const url = MQSGUtil.getAPIBasePath() + "/VideoWarrantsJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ link: `/education/marketcommentary/${result[0].title}/${result[0].videos[0].playlist_id}/${result[0].videos[0].index}` })
        },
      );
  }
  componentDidMount() {
    this.getVideoApiLink()
  }
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">School of Warrants</h2>
        </div>
        <p className="section-p">
          Improve your knowledge of warrant in these short 3 min videos.
        </p>
        <div className="section-qlink-img section-qlink-dwvideo">
          <a href={this.state.link} target="_blank" className="section-qlink-achor">
            <img src="/img/bullet/qlink-achor.png" alt="" />
          </a>
        </div>
      </div>
    );
  }
}

class InvestorAcademy extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Warrants guidebook</h2>
        </div>
        <p className="section-p">Learn all about warrants here.</p>
        <div className="section-qlink-img section-qlink-investoracademy">
          <a
            target="_blank"
            href="/WarrantsGuidebook"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" alt="" />
          </a>
        </div>
      </div>
    );
  }
}

class MarketBuzz extends Component {
  render() {
    return (
      <div className="section qlink-section">
        <div className="page-header">
          <h2 className="small">Morning Market Buzz</h2>
        </div>
        <p>Keep up to date with the latest news and more...</p>
        <div className="section-qlink-img section-qlink-todayhighlight">
          <a
            href="/marketnews/dailyhighlights"
            className="section-qlink-achor"
          >
            <img src="/img/bullet/qlink-achor.png" alt="" />
          </a>
        </div>
      </div>
    );
  }
}

// read more (pop up window)
