import React, { Component } from "react";
import classNames from "classnames";
import MQSGUtil from "../../components/mqsgUtil"
// import AdvertBox from "../../../components/advert/advert";

import "./sgxtradingfestival.css";

export default class sgxtradingfestival extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answerA: '',
            answerB: '',
            answerC: '',
            email: '',
            name: '',
            text: '',
            win: false,
            policyShow: false,
            emailErr: false
        };
    }
    // componentDidUpdate() {
    //     console.log(1)
    //     if (window.location.href.indexOf('answerandwin') == -1) {
    //         console.log(1)
    //         document.querySelector("#navbar").style.visibility = 'visible'
    //         document.querySelector(".sharebtn").style.display = 'block'
    //         document.querySelector(".footer .sitemaps").style.display = 'block'
    //         document.querySelector(".footer .sitemap-xs").style.visibility = 'visible'
    //     }
    // }
    componentDidMount() {
        document.querySelector("#navbar").style.visibility = 'hidden'
        document.querySelector(".sharebtn").style.display = 'none'
        document.querySelector(".footer .sitemaps").style.display = 'none'
        document.querySelector(".footer .sitemap-xs").style.visibility = 'hidden'
        // window.addEventListener(
        //     "resize",
        //     e => {
        //         var larger = "navbar navbar-inverse navbar-fixed-top navbar-larger";
        //         var small = "navbar navbar-inverse navbar-fixed-top navbar-small";
        //         var dom = document.querySelector("#topnavbar");
        //         if (dom) {
        //             if (window.scrollY > 100) {
        //                 if (dom.className) {
        //                     dom.className = small
        //                 }
        //             } else {
        //                 if (dom.className) {
        //                     dom.className = larger;
        //                 }
        //             }
        //         }
        //     },
        //     false
        // );
    }
    setEmail(e) {
        this.setState({
            email: e.target.value
        })
    }
    setName(e) {
        this.setState({
            name: e.target.value
        })
    }
    answerAClick(v) {
        this.setState({
            answerA: v
        })
    }
    answerBClick(v) {
        this.setState({
            answerB: v
        })
    }
    answerCClick(v) {
        this.setState({
            answerC: v
        })
    }
    registration() {
        const url = MQSGUtil.getAPIBasePath() + "/registration?p=register";
        fetch(
            url,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'POST',
                body: 'email=' + this.state.email
            }
        ).then((res) => res.json()).then(
            (result) => {
            },
            (error) => {
            }
        );
    }
    submit() {
        // var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        // console.log(myreg.test(this.state.email));
        // if (!myreg.test(this.state.email)) {
        //     this.setState({
        //         emailErr: true
        //     }, () => {
        //         window.setTimeout(() => {
        //             this.setState({
        //                 emailErr: false,
        //             })
        //         }, 2000)
        //     })
        //     return false;
        // }
        // if (this.state.answerA != '' && this.state.answerB != '' && this.state.email != '' && myreg.test(this.state.email)) {
        if (this.state.answerA != '' && this.state.answerB != '' && this.state.email != '') {
            const url = MQSGUtil.getAPIBasePath() + "/WinGrabVouchersServlet";
            fetch(
                url,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    method: 'POST',
                    body: 'email=' + this.state.email + '&name=' + this.state.name + '&answerA=' + this.state.answerA + '&answerB=' + this.state.answerB,
                }
            ).then((res) => res.json()).then(
                (result) => {
                    // if (result.message == 'fail to submit ') {
                    //     return;
                    // }
                    this.setState({
                        messageShow: true,
                        text: result.message,
                        win: result.win
                    }, () => {
                        this.registration();
                        // window.setTimeout(() => {
                        //     this.setState({
                        //         messageShow: false,
                        //         text: ''
                        //     })
                        // }, 3000)
                    })
                },
                (error) => {
                }
            );
        }
    }
    showdisclaimer(v, e) {
        e.stopPropagation();
        this.setState({
            policyShow: v
        })
    }
    render() {
        return (
            <div id="faq" className="pageobj">
                {/* <div className="breadcrumbbar">
                    <div className="container-fluid">
                        <ol className="breadcrumb">
                            <li>
                                <a href="/home/edu">Warrant education</a>
                            </li>
                            <li className="active">FAQ</li>
                        </ol>
                    </div>
                </div> */}
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12" style={{ padding: '0px' }}>
                            <img
                                style={{ width: "100%" }}
                                src="/img/home/Placeholder_banner.jpg"
                                alt=""
                            />
                        </div>
                        <div style={{ clear: 'both', background: '#000000', overflow: 'hidden', paddingBottom: '30px' }}>
                            <h3 style={{ color: '#FFFFFF', textAlign: 'center' }}><b>First 100 to subscribe to the newsletter and correctly answer these questions will each win a Grab voucher worth SGD25!</b></h3>
                            <div className="col-md-6 col-xs-6" style={{ color: '#FFFFFF', paddingLeft: '50px', marginTop: '30px' }}>
                                <p style={{ color: '#FFFFFF', margin: '0px 0px 10px 0px', fontSize: '21px' }}>Full name</p>
                                <input type="text" className="inputWidth" placeholder="First Name,Last Name" style={{ color: '#FFFFFF', paddingLeft: '5px', background: '#000', border: '1px solid #FFFFFF', width: '60%', height: '30px' }} onChange={(e) => { this.setName(e) }} />
                                <p style={{ color: '#FFFFFF', margin: '10px 0px 10px 0px', fontSize: '21px' }}>Email address</p>
                                <input type="email" className="inputWidth" placeholder="qwerty@domain.com" style={{ color: '#FFFFFF', paddingLeft: '5px', background: '#000', border: '1px solid #FFFFFF', width: '60%', height: '30px' }} onChange={(e) => { this.setEmail(e) }} />
                                <p style={{ color: '#ABADB0', marginTop: '80px', fontStyle: 'italic' }}>
                                    * Email address provided must be valid for receipt of prize. By participating in this contest, you confirm that you agree to the terms of conditions of this contest. View full terms and conditions of the contest <b onClick={(e) => this.showdisclaimer(true, e)} style={{ cursor: 'pointer', color: '#94edd1', textDecoration: 'underline' }}>here</b>.
                                </p>
                            </div>
                            <div className="col-md-6  col-xs-6 question" style={{
                                color: '#FFFFFF', marginTop: '30px', paddingLeft: '20px',
                                paddingRight: '20px'
                            }}>
                                {/* <p style={{ color: '#FFFFFF', margin: '0px 0px 10px 0px', fontSize: '21px' }}>Question:</p> */}
                                <h1 style={{ color: '#FFFFFF', margin: '0px 0px 20px 0px', }}>The Warrant Selector tool by Macquarie shows investors the estimated gross returns of trending warrants with different holding periods based on the investor's target price of the selected underlying.</h1>
                                <ul style={{ listStyle: 'none', padding: '0px' }}>
                                    <li style={{ cursor: 'pointer' }}
                                        onClick={() => this.answerAClick("A")}
                                    ><span style={{
                                        display: 'inline-block',
                                        padding: '8px 15px 8px 15px',
                                        background: '#009973',
                                    }}
                                    >A</span>
                                        <span style={{
                                            display: 'inline-block',
                                            padding: '8px 15px 8px 15px',
                                            background: '#FFF',
                                            color: '#000',
                                            width: '85%'
                                        }}
                                            className={classNames(
                                                { 'answerA-item': this.state.answerA == "A" }, 'border'
                                            )}
                                        >True</span>
                                    </li>
                                    <li style={{ cursor: 'pointer' }} onClick={() => this.answerAClick("B")}><span style={{
                                        display: 'inline-block',
                                        padding: '8px 15px 8px 14px',
                                        background: '#009973',
                                        marginTop: '5px'
                                    }}>B</span>
                                        <span style={{
                                            display: 'inline-block',
                                            padding: '8px 15px 8px 15px',
                                            background: '#FFF',
                                            color: '#000',
                                            width: '85%'
                                        }}
                                            className={classNames(
                                                { 'answerA-item': this.state.answerA == "B" }, 'border'
                                            )}
                                        >False</span>
                                    </li>
                                </ul>

                                <h1 style={{ color: '#FFFFFF', margin: '23px 0px 20px 0px', }}>In Macquarie Warrants's daily newsletter on 13 April (Wednesday), we mentioned an example of a 3 week trade on DBS call warrant. How many percent did DBS call warrant (9ZVW) gain over 3 weeks in March 2022, on the back of a 13% increase in DBS share price?</h1>
                                <ul style={{ listStyle: 'none', padding: '0px' }}>
                                    <li style={{ cursor: 'pointer' }} onClick={() => this.answerBClick("A")}><span style={{
                                        display: 'inline-block',
                                        padding: '8px 15px 8px 15px',
                                        background: '#009973',
                                    }}>A</span>
                                        <span style={{
                                            display: 'inline-block',
                                            padding: '8px 15px 8px 15px',
                                            background: '#FFF',
                                            color: '#000',
                                            width: '85%'
                                        }}
                                            className={classNames(
                                                { 'answerA-item': this.state.answerB == "A" }, 'border'
                                            )}
                                        >117%</span>
                                    </li>
                                    <li style={{ cursor: 'pointer' }} onClick={() => this.answerBClick("B")}><span style={{
                                        display: 'inline-block',
                                        padding: '8px 15px 8px 14px',
                                        background: '#009973',
                                        marginTop: '5px'
                                    }}>B</span>
                                        <span style={{
                                            display: 'inline-block',
                                            padding: '8px 15px 8px 15px',
                                            background: '#FFF',
                                            color: '#000',
                                            width: '85%'
                                        }}
                                            className={classNames(
                                                { 'answerA-item': this.state.answerB == "B" }, 'border'
                                            )}
                                        >217%</span>
                                    </li>
                                    <li style={{ cursor: 'pointer' }} onClick={() => this.answerBClick("C")}><span style={{
                                        display: 'inline-block',
                                        padding: '8px 15px 8px 14px',
                                        background: '#009973',
                                        marginTop: '5px'
                                    }}>C</span>
                                        <span style={{
                                            display: 'inline-block',
                                            padding: '8px 15px 8px 15px',
                                            background: '#FFF',
                                            color: '#000',
                                            width: '85%'
                                        }}
                                            className={classNames(
                                                { 'answerA-item': this.state.answerB == "C" }, 'border'
                                            )}
                                        >317%</span>
                                    </li>
                                </ul>

                                <input type="button" value="Subscribe and Submit" onClick={() => { this.submit() }} style={{
                                    color: '#fff',
                                    background: 'rgb(0, 153, 115)',
                                    padding: '5px 15px 5px 15px',
                                    border: '0px',
                                    borderRadius: '5px',
                                    fontSize: '22px',
                                    display: 'block',
                                    margin: '0 auto',
                                    marginTop: '30px'
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={(e) => this.showdisclaimer(false, e)} className="main-disclaimer-overlay main-p" id="disclaimer" style={{ display: this.state.policyShow ? 'table' : 'none', height: '100%' }}>
                    <div className="disclaimer-overlay-inner">
                        <div style={{ padding: '10px' }}>
                            <div className="disclaimer-page-header" style={{ textAlign: 'left' }}>
                                {/* <h1 className="small">Terms and conditions</h1> */}
                                <div className="bold" style={{ textAlign: 'center', fontSize: '22px', padding: '15px 0px' }}>TERMS AND CONDITIONS – SINGAPORE WARRANTS APRIL 2022 CONTEST</div>
                            </div>
                            <div className="popupbox-content">

                                {/* <ul style={{ marginTop: '15px' }}>
                                    <li>
                                    </li>
                                </ul> */}
                                <p>
                                    1. The Singapore Warrants April 2022 Contest (“Contest”) organized by Macquarie Capital Securities (Singapore) Pte Limited (“Organizer”) will be held on a webpage within its website www.warrants.com.sg (“Contest Page”) anytime from 25 April 2022 to 8 May 2022 (or as updated and advised accordingly) ("Contest Period"), with the link to the Contest Page made available via the Macquarie Warrants Singapore Facebook page (available on https://www.facebook.com/MacquarieWarrantsSG), Telegram channel (available on https://t.me/MacquarieWarrantsSG) and/or the daily newsletter (subscription via the link https://warrants.com.sg/marketnews/subscribe; the “Newsletter”) anytime within the Contest Period.
                                </p>
                                <div className="bold">ELIGIBILITY</div>
                                <p>
                                    2. The Contest is open to all citizens and permanent residents of Singapore above the age of 18, except for permanent and/or contract employees of the Organizer, as well as their parents, siblings, spouses and children. The Organizer reserves the right but does not have the obligation to verify the eligibility of the participants.
                                </p>
                                <p>
                                    3. Participants must ensure that their email addresses provided on the Contest Page are accurate to be eligible for the Contest. Please note that winners of the Contest will be awarded via the email address provided.
                                </p>
                                <div className="bold">CONTEST MECHANISM AND PRIZES</div>
                                <p>
                                    4. At anytime during the Contest Period, the link to the Contest Page shall be made available on any one (1), any two (2), or all of these platforms:
                                </p>
                                <ul>
                                    <li>
                                        Macquarie Warrants Singapore Facebook page (available on https://www.facebook.com/MacquarieWarrantsSG),
                                    </li>
                                    <li>
                                        Telegram channel (available on https://t.me/MacquarieWarrantsSG) and,
                                    </li>
                                    <li>
                                        the daily newsletter (subscription via the link https://warrants.com.sg/marketnews/subscribe; the “Newsletter”)
                                    </li>
                                </ul>
                                <p>
                                    The first one hundred (100) fastest participants to correctly answer all the questions posted on the Contest Page (based on the time of receipt of the submission on the Contest Page) will win one (1) Grab voucher each valued at twenty-five Singapore dollars only (SGD 25.00).
                                </p>
                                <p>
                                    5. With respect to prizes, if there are two (2) or more participants contesting for the one hundredth (100th) place having submitted their answers at the same time (i.e. a tie), the Organizer shall contact the said participants via email, who will be required to reply and answer a specific question within three (3) calendar days from the send date of the email/notification. The participant with the best answer, as determined by the Organizer, shall be the winner.
                                </p>
                                <p>
                                    6. Participants will have until 11:59pm Singapore time on the seventh (7th) calendar day from the date the link to the Contest Page is made available (the “Question Period”) to participate in the Contest. Submission of the answers to the questions posted on the Contest Page must be received by the Organizer during the Question Period. At the end of the Question Period, if there are less than one hundred (100) participants who have correctly answered all the question(s) on the Contest Page, the remaining prizes that have not been awarded shall be forfeited. For avoidance of doubt, each participant can only win one (1) prize, regardless of the number of email accounts they may have used to participate in the Contest. Participants found cheating will be disqualified immediately.
                                </p>
                                <p>
                                    7. The Organizer will not be held responsible for any late, lost or misdirected submission of answers, including submissions not received or delay in receipt of the submissions due to the breakdown or failure of transmission and communication facilities, technical issue or for any other reason.
                                </p>
                                <div className="bold">NOTIFICATION OF REWARDS</div>
                                <p>
                                    8. The Organizer shall send the winners the prize of SGD 25.00 Grab vouchers via the email address submitted on the Contest Page within five (5) business days after the end of the Question Period.
                                </p>
                                <div className="bold">GENERAL TERMS</div>
                                <p>
                                    9. By participating in the Contest, you have read, understood and agreed to be bound by the Terms and Conditions herein and further agree that any decision by the Organizer in relation to every aspect of the Contest, including the awarding of the rewards, shall be final, binding and conclusive. No correspondence, queries, appeals or protests (whether verbal or written) will be entertained.
                                </p>
                                <p>
                                    10. In the event there is reasonable suspicion that you have tampered with or benefited from tampering the entry process or the operation of the Contest or have acted in violation of the Terms and Conditions stated herein, the Organizer reserves the sole and absolute right and the exclusive discretion to disqualify all entries and prohibit you from further participation in this Contest.
                                </p>
                                <p>
                                    11. The prizes are non-transferable to any third party, non-negotiable, non-exchangeable and cannot be used in conjunction with any other offers or promotions.
                                </p>
                                <p>
                                    12. The Organizer has the right and discretion to add and/or remove and/or substitute the reward(s) with any other item(s) or service(s) of similar value.
                                </p>
                                <p>
                                    13. The winners shall personally bear and be responsible for ALL incidental costs relating to the collection and/or usage of the prize(s) including but not limited to any applicable registration fees, processing fees, administrative fees, fuel charges, courier and/or delivery fees and all other personal expenses.
                                </p>
                                <p>
                                    14. Picture(s) of the reward(s) shown in any advertising, promotional, publicity and other materials relating to or in connection with the Contest are solely for illustration purposes only and may not depict the actual colour, brand or specifications of the reward(s).
                                </p>
                                <p>
                                    15. The Organizer gives no representation or warranty with respect to the quality or suitability of the prizes. The winners shall deal directly with the supplier/manufacturer of the prizes for all warranty information and any dispute with respect to the quality, merchantability, fitness of purposes of the prizes.
                                </p>
                                <p>
                                    16. The prizes may carry the supplier(s)/manufacturer(s) own terms and conditions and those terms and conditions shall be applicable, in addition to these Terms and Conditions contained herein.
                                </p>
                                <p>
                                    17. The Organizer reserves the sole and absolute right and exclusive discretion to change, vary and/or amend any aspect of the Contest (including cancelling or discontinuing the Contest inclusive of rewards which shall be awarded) or any part of these Terms and Conditions without prior notice and/or reference to any party.
                                </p>
                                <div className="bold">USE OF PERSONAL INFORMATION</div>
                                <p>
                                    18. By participating in the Contest, you shall be deemed to have given consent to the Organizer to collect, store, process and use your personal data in accordance with the Organizer’s Privacy Policy (which is available at https://www.macquarie.com/sg/en/disclosures/privacy-and-cookies.html), and for the purposes of organizing, administering, advertising and promoting the Contest at its sole and absolute discretion (“Approved Purpose”). You will also automatically receive invitations or marketing material from Macquarie for warrant seminars and other warrant related initiatives. In the case where you do not want your details published by the organizer, a written request should be sent to info@warrants.com.sg.  in order for such information to be removed. Macquarie website administrators may have access to your personal data for the processing of such data to be used only for the Approved Purpose on behalf of the Organizer and other companies of Macquarie Group.
                                </p>
                                <p>
                                    19. You may gain access to any personal information that the Organizer holds about you by contacting the Organizer and accessing the privacy policy available on the Organizer’s website or requested directly from the Organizer.
                                </p>
                                <p>
                                    20. By providing the personal data of any third party (“Third Party Data Subject”) in connection with this Contest or otherwise, you shall be deemed to have obtained the consent and authorization of each such Third Party Data Subject for the Organizer to collect, store, process, use, or disclose the Third Party Data Subject’s personal data as set out in paragraphs 18 to 19 above.
                                </p>
                                <div className="bold">DISCLAIMER</div>
                                <p>
                                    21. You agree to and acknowledge the Disclaimer (http://www.warrants.com.sg/about/termsofuse).
                                </p>
                                <p>
                                    22. The Organizer shall not be held liable and/or responsible for any loss, damage or any other form of liability in whatsoever nature and howsoever arising or suffered by any participants resulting directly or indirectly from participating in the Contest or resulting from the acceptance, possession, use/misuse of the prize(s) in this Contest.
                                </p>
                                <p>
                                    23. The information provided in the Contest is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to laws or regulations or which would subject the Organizer to any registration requirement within such jurisdiction or country. Neither the information, nor any opinion distributed on social media and/or contained in the Contest constitutes a solicitation or offer by the Organizer to buy or sell any securities, futures, options or other financial instruments or provide any investment advice or service whatsoever and cannot under any circumstances be relied upon for investment dealings of whatever nature. You are advised to obtain separate independent professional advice before making any decision to trade/invest in securities or invest in any financial instruments.
                                </p>
                                <p>
                                    24. Whilst every care has been taken in preparing the information materials contained in the Contest, such information is provided on an “as is” basis without any warranty or indemnity of any kind either expressly or implied, including but not limited to any implied warranties or indemnities or implied terms of satisfactory quality, fitness for a particular purpose or non-infringement. All such implied terms, warranties and indemnities are hereby excluded.
                                </p>
                                <p>
                                    25. The Organizer will not be held responsible for any loss or damage that could result from interception by any third parties of any information made available to you during the Contest. Although the information provided to you during the Contest is obtained or compiled from sources we believe to be reliable, the Organizer cannot and does not guarantee the accuracy, validity, timeliness or completeness of any information or data made available to you for any particular purpose.
                                </p>
                                <p>
                                    26. In no event will the Organizer or any such parties be liable to you for any other damages of any direct, special, indirect, consequential, incidental damages or any other damages of any kind even if the Organizer or any other party have been advised of the possibility thereof.
                                </p>
                                <p>
                                    27. Nothing contained in the Contest constitutes an offer to sell any securities and other goods or services of whatever nature and cannot under any circumstances be relied upon for investment dealings of whatever nature.
                                </p>
                                <p>
                                    28. If any provision of these disclaimers and exclusions shall be unlawful, void or any reason unenforceable then that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.
                                </p>
                                <div className="bold">LIABILITY</div>
                                <p>
                                    29. By participating in the Contest, you are deemed to have agreed and accepted that the Organizer will not be held liable under any circumstances, including negligence, for any direct, indirect or consequential loss arising from your use of the information and material distributed during the Contest. The Organizer shall also not be liable for any material provided by third parties with their own respective copyright and shall not under any circumstances, be liable for any loss, damages or injury arising from these materials.
                                </p>
                                <p>
                                    30. You hereby undertake to fully indemnify the Organizer for any damages, losses, expenses and cost that may arise as a direct or indirect consequence of a breach on your part of any provisions of these Terms and Conditions, in addition and without prejudice, to any other right that the Organizer may have as contained herein.
                                </p>
                                <p>
                                    31. In the event that the Organizer failed and/or neglected to enforce any provision or remedy as provided in these Terms and Conditions, we shall not be construed as having waived our rights to enforce the same.
                                </p>
                                <div className="bold">EXCLUSIONS</div>
                                <p>
                                    32. This Contest shall be governed by the Laws of Singapore and subject to the exclusive jurisdiction of the Courts of Singapore.
                                </p>
                                <p>
                                    33. The exclusions and limitations described herein shall apply only to the extent permitted by law, without prejudice to our rights to seek legal redress in accordance with the applicable laws.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>







                <div id="popup-customized" style={{ display: this.state.messageShow ? 'table' : 'none' }}  >
                    <div>
                        {/* <h5>{this.state.text}</h5> */}
                        <ul style={{ listStyle: 'none' }}>
                            {/* <li style={{ display: this.state.text == 'fail to submit ' ? 'none' : 'block' }}>Invalid email detected, please try again with a valid email address as prizes will be sent to this email address.</li> */}
                            {/* <li style={{ display: this.state.win == undefined ? 'block' : 'none' }}>Invalid email detected, please try again with a valid email address as prizes will be sent to this email address.</li> */}
                            {/* <li style={{ display: this.state.win ? 'none' : 'block' }}>Thank you for your participation. We will contact you within 5 business days after the question period if you are one of the 100 winners.</li> */}
                            <li>Thank you for your participation. We will contact you within 5 business days after the question period if you are one of the 100 winners.</li>
                            {/* <li style={{ display: this.state.win == true ? 'block' : 'none' }}>Congratulations, you are one of the winners of a Grab voucher worth SGD25! Your prize will be sent to the email address provided.</li> */}
                            <li>
                                <a type="button" href="/home" style={{
                                    color: '#fff',
                                    background: 'rgb(0, 153, 115)',
                                    padding: '5px 15px 5px 15px',
                                    border: '0px',
                                    borderRadius: '5px',
                                    width: '165px',
                                    display: 'block',
                                    margin: '0 auto',
                                    marginTop: '30px'
                                }} >Back to homepage
                                </a>
                            </li>
                        </ul>
                        <div>
                        </div>
                    </div>
                </div>

                {/* <div id="popup-customized" style={{ display: this.state.emailErr ? 'table' : 'none' }}  >
                    <div>
                        <ul style={{ listStyle: 'none' }}>
                            <li style={{ display: this.state.win == true ? 'none' : 'block' }}>invalid email</li>
                        </ul>
                        <div>
                        </div>
                    </div>
                </div> */}
            </div >
        );
    }
}

