
import React, { Component } from 'react';
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class tipsandtools extends Component {
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p><a href="/home" style={{ color: 'red' }}>warrants.com.sg</a> has a selection of very useful tools that will assist in your warrant selection process and also in your subsequent decision on when to sell. Here are a brief summary of some of these: </p>
                                            <div className="txtbox" style={{ maxWidth: '60%' }}>
                                                <h2>Warrant search:</h2>
                                                <p style={{ color: '#fff' }}>allows you to search for warrants by specifying your criteria, it will display all of the important info you need to make your decisions, <a href="/tools/warrantsearch" style={{ color: 'red' }}>Click here</a></p>
                                            </div>
                                            <div className="txtbox" style={{ maxWidth: '60%' }}>
                                                <h2>Warrant terms:</h2>
                                                <p style={{ color: '#fff' }}>lists all of the current pricing information for a particular warrant. Can be accessed directly or via clicking on a warrant code in the warrant search table, <a style={{ color: 'red' }} href="/tools/warrantterms">Click here</a></p>
                                            </div>
                                            <div className="txtbox" style={{ maxWidth: '60%' }}>
                                                <h2>Compare warrants:</h2>
                                                <p style={{ color: '#fff' }}>allows you to select two warrants and compare their details side by side.</p>
                                            </div>
                                            <div className="txtbox" style={{ maxWidth: '60%' }}>
                                                <h2>Warrant calculator:</h2>
                                                <p style={{ color: '#fff' }}>allows to change the variables of a warrant and see the resultant effect on the warrant price <a style={{ color: 'red' }} href="/tools/warrantcalculator">Click here</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}