const dwtool = {
    "trending_warrants": {
        "BYD COMPANY": {
            "CALL": [
                {
                    "BID": 0.057000000,
                    "underlying_ric": "1211.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "BYD COMPANY",
                    "underlying_name": "BYD COMPANY",
                    "type": "CALL",
                    "BID_PCTCHNG": "-21.9",
                    "effective_gearing": "4.1",
                    "id": 1,
                    "exercise_price_adj": 54.464000000,
                    "ticker": "MGVW",
                    "ric": "BYML_tg.SI",
                    "BID_NETCHNG": -0.016000000,
                    "dsply_name": "BYD MB eCW210705A",
                    "exercise_price": 320.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.062000000,
                    "underlying_ric": "1211.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "BYD COMPANY",
                    "underlying_name": "BYD COMPANY",
                    "type": "PUT",
                    "BID_PCTCHNG": "24.0",
                    "effective_gearing": "3.6",
                    "id": 2,
                    "exercise_price_adj": 25.530000000,
                    "ticker": "YBMW",
                    "ric": "BYML_tf.SI",
                    "BID_NETCHNG": 0.012000000,
                    "dsply_name": "BYD MB ePW210705",
                    "exercise_price": 150.000000000
                }
            ]
        },
        "AEM HOLDINGS LTD": {
            "CALL": [
                {
                    "BID": 0.079000000,
                    "underlying_ric": "AEM.SI",
                    "maturity": "17 Sep 21",
                    "underlying_ticker": "AWX",
                    "underlying_name": "AEM HOLDINGS LTD",
                    "type": "CALL",
                    "BID_PCTCHNG": "-15.1",
                    "effective_gearing": "2.5",
                    "id": 1,
                    "exercise_price_adj": 4.200000000,
                    "ticker": "VKEW",
                    "ric": "AEML_tm.SI",
                    "BID_NETCHNG": -0.014000000,
                    "dsply_name": "AEM MB eCW210917",
                    "exercise_price": 4.200000000
                }
            ],
            "PUT": []
        },
        "S&P500": {
            "CALL": [
                {
                    "BID": 0.135000000,
                    "underlying_ric": "ESc1",
                    "maturity": "18 Jun 21",
                    "underlying_ticker": "S&P500",
                    "underlying_name": "S&P500",
                    "type": "CALL",
                    "BID_PCTCHNG": "-1.5",
                    "effective_gearing": "11.7",
                    "id": 1,
                    "exercise_price_adj": 5544.000000000,
                    "ticker": "GTJW",
                    "ric": "SXML_t25.SI",
                    "BID_NETCHNG": -0.002000000,
                    "dsply_name": "S&P 4200MBeCW210618",
                    "exercise_price": 4200.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.101000000,
                    "underlying_ric": "ESc1",
                    "maturity": "18 Jun 21",
                    "underlying_ticker": "S&P500",
                    "underlying_name": "S&P500",
                    "type": "PUT",
                    "BID_PCTCHNG": "-1.0",
                    "effective_gearing": "6.7",
                    "id": 2,
                    "exercise_price_adj": 3960.000000000,
                    "ticker": "XYPW",
                    "ric": "SXML_t24.SI",
                    "BID_NETCHNG": -0.001000000,
                    "dsply_name": "S&P 3000MBePW210618",
                    "exercise_price": 3000.000000000
                }
            ]
        },
        "N225": {
            "CALL": [
                {
                    "BID": 0.197000000,
                    "underlying_ric": "SSIcv1",
                    "maturity": "11 Jun 21",
                    "underlying_ticker": "N225",
                    "underlying_name": "N225",
                    "type": "CALL",
                    "BID_PCTCHNG": "-8.4",
                    "effective_gearing": "10.2",
                    "id": 1,
                    "exercise_price_adj": 388.802000000,
                    "ticker": "LXLW",
                    "ric": "NIML_t26.SI",
                    "BID_NETCHNG": -0.018000000,
                    "dsply_name": "NKY 31000MBeCW210611",
                    "exercise_price": 31000.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.101000000,
                    "underlying_ric": "SSIcv1",
                    "maturity": "11 Jun 21",
                    "underlying_ticker": "N225",
                    "underlying_name": "N225",
                    "type": "PUT",
                    "BID_PCTCHNG": "8.6",
                    "effective_gearing": "9.0",
                    "id": 2,
                    "exercise_price_adj": 326.092000000,
                    "ticker": "EOGW",
                    "ric": "NIML_t23.SI",
                    "BID_NETCHNG": 0.008000000,
                    "dsply_name": "NKY 26000MBePW210611",
                    "exercise_price": 26000.000000000
                }
            ]
        },
        "CITY DEVELOPMENT": {
            "CALL": [
                {
                    "BID": 0.020000000,
                    "underlying_ric": "CTDM.SI",
                    "maturity": "07 May 21",
                    "underlying_ticker": "C09",
                    "underlying_name": "CITY DEVELOPMENT",
                    "type": "CALL",
                    "BID_PCTCHNG": "-4.8",
                    "effective_gearing": "8.3",
                    "id": 1,
                    "exercise_price_adj": 9.000000000,
                    "ticker": "LQXW",
                    "ric": "CTML_tt.SI",
                    "BID_NETCHNG": -0.001000000,
                    "dsply_name": "CityDev MB eCW210507",
                    "exercise_price": 9.000000000
                }
            ],
            "PUT": []
        },
        "SINGTEL": {
            "CALL": [
                {
                    "BID": 0.020000000,
                    "underlying_ric": "STEL.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "Z74",
                    "underlying_name": "SINGTEL",
                    "type": "CALL",
                    "BID_PCTCHNG": "-4.8",
                    "effective_gearing": "6.6",
                    "id": 1,
                    "exercise_price_adj": 2.800000000,
                    "ticker": "59XW",
                    "ric": "SEML_t7.SI",
                    "BID_NETCHNG": -0.001000000,
                    "dsply_name": "SingtelMBeCW211018",
                    "exercise_price": 2.800000000
                }
            ],
            "PUT": []
        },
        "KEPCORP": {
            "CALL": [
                {
                    "BID": 0.046000000,
                    "underlying_ric": "KPLM.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "BN4",
                    "underlying_name": "KEPCORP",
                    "type": "CALL",
                    "BID_PCTCHNG": "-2.1",
                    "effective_gearing": "5.0",
                    "id": 1,
                    "exercise_price_adj": 5.700000000,
                    "ticker": "WFEW",
                    "ric": "KPML_t10.SI",
                    "BID_NETCHNG": -0.001000000,
                    "dsply_name": "KepCorpMBeCW211018",
                    "exercise_price": 5.700000000
                }
            ],
            "PUT": []
        },
        "GENTINGSIN": {
            "CALL": [
                {
                    "BID": 0.019000000,
                    "underlying_ric": "GENS.SI",
                    "maturity": "01 Jul 21",
                    "underlying_ticker": "G13",
                    "underlying_name": "GENTINGSIN",
                    "type": "CALL",
                    "BID_PCTCHNG": "5.6",
                    "effective_gearing": "5.3",
                    "id": 1,
                    "exercise_price_adj": 0.950000000,
                    "ticker": "XJLW",
                    "ric": "GTML_te.SI",
                    "BID_NETCHNG": 0.001000000,
                    "dsply_name": "GentingSMBeCW210701",
                    "exercise_price": 0.950000000
                }
            ],
            "PUT": []
        },
        "HANG SENG TECH INDEX": {
            "CALL": [
                {
                    "BID": 0.019000000,
                    "underlying_ric": "HHTIc2",
                    "maturity": "29 Apr 21",
                    "underlying_ticker": "HSTECH",
                    "underlying_name": "HANG SENG TECH INDEX",
                    "type": "CALL",
                    "BID_PCTCHNG": "-44.1",
                    "effective_gearing": "6.4",
                    "id": 1,
                    "exercise_price_adj": 2093.460000000,
                    "ticker": "IQTW",
                    "ric": "HSML_t219.SI",
                    "BID_NETCHNG": -0.015000000,
                    "dsply_name": "HSTECH 12300MBeCW210429",
                    "exercise_price": 12300.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.152000000,
                    "underlying_ric": "HHTIc2",
                    "maturity": "29 Apr 21",
                    "underlying_ticker": "HSTECH",
                    "underlying_name": "HANG SENG TECH INDEX",
                    "type": "PUT",
                    "BID_PCTCHNG": "35.7",
                    "effective_gearing": "7.9",
                    "id": 2,
                    "exercise_price_adj": 1582.860000000,
                    "ticker": "YF7W",
                    "ric": "HSML_t222.SI",
                    "BID_NETCHNG": 0.040000000,
                    "dsply_name": "HSTECH 9300MBePW210429",
                    "exercise_price": 9300.000000000
                }
            ]
        },
        "COMFORTDELGRO LD": {
            "CALL": [
                {
                    "BID": 0.021000000,
                    "underlying_ric": "CMDG.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "C52",
                    "underlying_name": "COMFORTDELGRO LD",
                    "type": "CALL",
                    "BID_PCTCHNG": "5.0",
                    "effective_gearing": "4.6",
                    "id": 1,
                    "exercise_price_adj": 2.000000000,
                    "ticker": "MSHW",
                    "ric": "CMML_t2.SI",
                    "BID_NETCHNG": 0.001000000,
                    "dsply_name": "ComfortDel MBeCW211018",
                    "exercise_price": 2.000000000
                }
            ],
            "PUT": []
        },
        "CAPLAND": {
            "CALL": [
                {
                    "BID": 0.027000000,
                    "underlying_ric": "CATL.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "C31",
                    "underlying_name": "CAPLAND",
                    "type": "CALL",
                    "BID_PCTCHNG": "0.0",
                    "effective_gearing": "6.0",
                    "id": 1,
                    "exercise_price_adj": 3.800000000,
                    "ticker": "MHJW",
                    "ric": "CAML_t4.SI",
                    "BID_NETCHNG": 0E-9,
                    "dsply_name": "CapitalaMBeCW211018",
                    "exercise_price": 3.800000000
                }
            ],
            "PUT": []
        },
        "SGX": {
            "CALL": [
                {
                    "BID": 0.110000000,
                    "underlying_ric": "SGXL.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "S68",
                    "underlying_name": "SGX",
                    "type": "CALL",
                    "BID_PCTCHNG": "-3.5",
                    "effective_gearing": "4.9",
                    "id": 1,
                    "exercise_price_adj": 9.800000000,
                    "ticker": "ESYW",
                    "ric": "SNML_t4.SI",
                    "BID_NETCHNG": -0.004000000,
                    "dsply_name": "SGX MB eCW211018",
                    "exercise_price": 9.800000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.053000000,
                    "underlying_ric": "SGXL.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "S68",
                    "underlying_name": "SGX",
                    "type": "PUT",
                    "BID_PCTCHNG": "3.9",
                    "effective_gearing": "4.8",
                    "id": 2,
                    "exercise_price_adj": 8.500000000,
                    "ticker": "UJGW",
                    "ric": "SNML_t3.SI",
                    "BID_NETCHNG": 0.002000000,
                    "dsply_name": "SGX MB ePW211018",
                    "exercise_price": 8.500000000
                }
            ]
        },
        "SINGAPORE AIRLIN": {
            "CALL": [
                {
                    "BID": 0.037000000,
                    "underlying_ric": "SIAL.SI",
                    "maturity": "01 Jul 21",
                    "underlying_ticker": "C6L",
                    "underlying_name": "SINGAPORE AIRLIN",
                    "type": "CALL",
                    "BID_PCTCHNG": "8.8",
                    "effective_gearing": "5.5",
                    "id": 1,
                    "exercise_price_adj": 5.500000000,
                    "ticker": "GIJW",
                    "ric": "SLML_ta.SI",
                    "BID_NETCHNG": 0.003000000,
                    "dsply_name": "SIA MB eCW210701",
                    "exercise_price": 5.500000000
                }
            ],
            "PUT": []
        },
        "YANGZIJIANG": {
            "CALL": [
                {
                    "BID": 0.037000000,
                    "underlying_ric": "YAZG.SI",
                    "maturity": "01 Nov 21",
                    "underlying_ticker": "BS6",
                    "underlying_name": "YANGZIJIANG",
                    "type": "CALL",
                    "BID_PCTCHNG": "0.0",
                    "effective_gearing": "2.9",
                    "id": 1,
                    "exercise_price_adj": 1.150000000,
                    "ticker": "FOHW",
                    "ric": "YZML_tx.SI",
                    "BID_NETCHNG": 0E-9,
                    "dsply_name": "YangzijiMBeCW211101",
                    "exercise_price": 1.150000000
                }
            ],
            "PUT": []
        },
        "ASCEN REIT": {
            "CALL": [
                {
                    "BID": 0.009000000,
                    "underlying_ric": "AEMN.SI",
                    "maturity": "24 May 21",
                    "underlying_ticker": "A17U",
                    "underlying_name": "ASCEN REIT",
                    "type": "CALL",
                    "BID_PCTCHNG": "-10.0",
                    "effective_gearing": "9.6",
                    "id": 1,
                    "exercise_price_adj": 3.600000000,
                    "ticker": "RQOW",
                    "ric": "AEML_tl.SI",
                    "BID_NETCHNG": -0.001000000,
                    "dsply_name": "AscendasReit MBeCW210524",
                    "exercise_price": 3.600000000
                }
            ],
            "PUT": []
        },
        "MAPLETREE COMMER": {
            "CALL": [
                {
                    "BID": 0.029000000,
                    "underlying_ric": "MACT.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "N2IU",
                    "underlying_name": "MAPLETREE COMMER",
                    "type": "CALL",
                    "BID_PCTCHNG": "0.0",
                    "effective_gearing": "5.2",
                    "id": 1,
                    "exercise_price_adj": 2.200000000,
                    "ticker": "TRGW",
                    "ric": "MAML_t.SI",
                    "BID_NETCHNG": 0E-9,
                    "dsply_name": "MapleComTr MB eCW211018",
                    "exercise_price": 2.200000000
                }
            ],
            "PUT": []
        },
        "XIAOMI-W": {
            "CALL": [
                {
                    "BID": 0.052000000,
                    "underlying_ric": "1810.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "XIAOMI-W",
                    "underlying_name": "XIAOMI-W",
                    "type": "CALL",
                    "BID_PCTCHNG": "-18.8",
                    "effective_gearing": "4.1",
                    "id": 1,
                    "exercise_price_adj": 5.106000000,
                    "ticker": "WAIW",
                    "ric": "XIML_t16.SI",
                    "BID_NETCHNG": -0.012000000,
                    "dsply_name": "XIAOMI MBeCW210705",
                    "exercise_price": 30.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.033000000,
                    "underlying_ric": "1810.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "XIAOMI-W",
                    "underlying_name": "XIAOMI-W",
                    "type": "PUT",
                    "BID_PCTCHNG": "32.0",
                    "effective_gearing": "5.4",
                    "id": 2,
                    "exercise_price_adj": 3.404000000,
                    "ticker": "UKKW",
                    "ric": "XIML_t15.SI",
                    "BID_NETCHNG": 0.008000000,
                    "dsply_name": "XIAOMI MBePW210705",
                    "exercise_price": 20.000000000
                }
            ]
        },
        "UOB": {
            "CALL": [
                {
                    "BID": 0.111000000,
                    "underlying_ric": "UOBH.SI",
                    "maturity": "01 Oct 21",
                    "underlying_ticker": "U11",
                    "underlying_name": "UOB",
                    "type": "CALL",
                    "BID_PCTCHNG": "5.7",
                    "effective_gearing": "6.1",
                    "id": 1,
                    "exercise_price_adj": 24.000000000,
                    "ticker": "XIIW",
                    "ric": "UOML_t31.SI",
                    "BID_NETCHNG": 0.006000000,
                    "dsply_name": "UOB MB eCW211001",
                    "exercise_price": 24.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.058000000,
                    "underlying_ric": "UOBH.SI",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "U11",
                    "underlying_name": "UOB",
                    "type": "PUT",
                    "BID_PCTCHNG": "-7.9",
                    "effective_gearing": "7.0",
                    "id": 2,
                    "exercise_price_adj": 22.000000000,
                    "ticker": "YWRW",
                    "ric": "UOML_t33.SI",
                    "BID_NETCHNG": -0.005000000,
                    "dsply_name": "UOB MB ePW210705",
                    "exercise_price": 22.000000000
                }
            ]
        },
        "TOP GLOVE CORPOR": {
            "CALL": [
                {
                    "BID": 0.032000000,
                    "underlying_ric": "TOPG.SI",
                    "maturity": "17 Sep 21",
                    "underlying_ticker": "BVA",
                    "underlying_name": "TOP GLOVE CORPOR",
                    "type": "CALL",
                    "BID_PCTCHNG": "-11.1",
                    "effective_gearing": "2.4",
                    "id": 1,
                    "exercise_price_adj": 3.150000000,
                    "ticker": "1P7W",
                    "ric": "TOPG_ta.SI",
                    "BID_NETCHNG": -0.004000000,
                    "dsply_name": "Top Glove MB eCW210917",
                    "exercise_price": 3.150000000
                }
            ],
            "PUT": []
        },
        "JD-SW": {
            "CALL": [
                {
                    "BID": 0.141000000,
                    "underlying_ric": "9618.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "JD-SW",
                    "underlying_name": "JD-SW",
                    "type": "CALL",
                    "BID_PCTCHNG": "-19.0",
                    "effective_gearing": "4.9",
                    "id": 1,
                    "exercise_price_adj": 68.080000000,
                    "ticker": "OPSW",
                    "ric": "JDML_tb.SI",
                    "BID_NETCHNG": -0.033000000,
                    "dsply_name": "JD MB eCW210705",
                    "exercise_price": 400.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.048000000,
                    "underlying_ric": "9618.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "JD-SW",
                    "underlying_name": "JD-SW",
                    "type": "PUT",
                    "BID_PCTCHNG": "23.1",
                    "effective_gearing": "4.6",
                    "id": 2,
                    "exercise_price_adj": 47.656000000,
                    "ticker": "DVDW",
                    "ric": "JDML_tc.SI",
                    "BID_NETCHNG": 0.009000000,
                    "dsply_name": "JD MB ePW210705",
                    "exercise_price": 280.000000000
                }
            ]
        },
        "GEELY AUTO": {
            "CALL": [
                {
                    "BID": 0.037000000,
                    "underlying_ric": "0175.HK",
                    "maturity": "05 Oct 21",
                    "underlying_ticker": "GEELY AUTO",
                    "underlying_name": "GEELY AUTO",
                    "type": "CALL",
                    "BID_PCTCHNG": "-19.6",
                    "effective_gearing": "4.0",
                    "id": 1,
                    "exercise_price_adj": 7.659000000,
                    "ticker": "XQDW",
                    "ric": "GEML_te.SI",
                    "BID_NETCHNG": -0.009000000,
                    "dsply_name": "Geely MB eCW211005",
                    "exercise_price": 45.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.191000000,
                    "underlying_ric": "0175.HK",
                    "maturity": "05 Oct 21",
                    "underlying_ticker": "GEELY AUTO",
                    "underlying_name": "GEELY AUTO",
                    "type": "PUT",
                    "BID_PCTCHNG": "9.8",
                    "effective_gearing": "1.7",
                    "id": 2,
                    "exercise_price_adj": 5.106000000,
                    "ticker": "VZGW",
                    "ric": "GEML_tf.SI",
                    "BID_NETCHNG": 0.017000000,
                    "dsply_name": "Geely MB ePW211005",
                    "exercise_price": 30.000000000
                }
            ]
        },
        "DBS": {
            "CALL": [
                {
                    "BID": 0.113000000,
                    "underlying_ric": "DBSM.SI",
                    "maturity": "01 Oct 21",
                    "underlying_ticker": "D05",
                    "underlying_name": "DBS",
                    "type": "CALL",
                    "BID_PCTCHNG": "17.7",
                    "effective_gearing": "6.1",
                    "id": 1,
                    "exercise_price_adj": 27.000000000,
                    "ticker": "9WJW",
                    "ric": "DBML_t33.SI",
                    "BID_NETCHNG": 0.017000000,
                    "dsply_name": "DBS MB eCW211001",
                    "exercise_price": 27.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.070000000,
                    "underlying_ric": "DBSM.SI",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "D05",
                    "underlying_name": "DBS",
                    "type": "PUT",
                    "BID_PCTCHNG": "-16.7",
                    "effective_gearing": "6.9",
                    "id": 2,
                    "exercise_price_adj": 24.500000000,
                    "ticker": "QYLW",
                    "ric": "DBML_t37.SI",
                    "BID_NETCHNG": -0.014000000,
                    "dsply_name": "DBS MB ePW210705",
                    "exercise_price": 24.500000000
                }
            ]
        },
        "OCBC": {
            "CALL": [
                {
                    "BID": 0.089000000,
                    "underlying_ric": "OCBC.SI",
                    "maturity": "12 Nov 21",
                    "underlying_ticker": "O39",
                    "underlying_name": "OCBC",
                    "type": "CALL",
                    "BID_PCTCHNG": "12.7",
                    "effective_gearing": "5.4",
                    "id": 1,
                    "exercise_price_adj": 10.800000000,
                    "ticker": "WZ1W",
                    "ric": "OCML_t25.SI",
                    "BID_NETCHNG": 0.010000000,
                    "dsply_name": "OCBC Bk MB eCW211112",
                    "exercise_price": 10.800000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.038000000,
                    "underlying_ric": "OCBC.SI",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "O39",
                    "underlying_name": "OCBC",
                    "type": "PUT",
                    "BID_PCTCHNG": "-15.6",
                    "effective_gearing": "7.6",
                    "id": 2,
                    "exercise_price_adj": 9.800000000,
                    "ticker": "9I5W",
                    "ric": "OCML_t21.SI",
                    "BID_NETCHNG": -0.007000000,
                    "dsply_name": "OCBC Bk MB ePW210705",
                    "exercise_price": 9.800000000
                }
            ]
        },
        "UOL GROUP LIMITE": {
            "CALL": [
                {
                    "BID": 0.037000000,
                    "underlying_ric": "UTOS.SI",
                    "maturity": "01 Jul 21",
                    "underlying_ticker": "U14",
                    "underlying_name": "UOL GROUP LIMITE",
                    "type": "CALL",
                    "BID_PCTCHNG": "2.8",
                    "effective_gearing": "6.9",
                    "id": 1,
                    "exercise_price_adj": 8.500000000,
                    "ticker": "ORGW",
                    "ric": "ULML_t.SI",
                    "BID_NETCHNG": 0.001000000,
                    "dsply_name": "UOL MB eCW210701",
                    "exercise_price": 8.500000000
                }
            ],
            "PUT": []
        },
        "HSI": {
            "CALL": [
                {
                    "BID": 0.070000000,
                    "underlying_ric": "HSIc2",
                    "maturity": "29 Apr 21",
                    "underlying_ticker": "HSI",
                    "underlying_name": "HSI",
                    "type": "CALL",
                    "BID_PCTCHNG": "-36.4",
                    "effective_gearing": "14.9",
                    "id": 1,
                    "exercise_price_adj": 5514.480000000,
                    "ticker": "LPQW",
                    "ric": "HSML_t218.SI",
                    "BID_NETCHNG": -0.040000000,
                    "dsply_name": "HSI 32400MBeCW210429",
                    "exercise_price": 32400.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.062000000,
                    "underlying_ric": "HSIc2",
                    "maturity": "30 Mar 21",
                    "underlying_ticker": "HSI",
                    "underlying_name": "HSI",
                    "type": "PUT",
                    "BID_PCTCHNG": "77.1",
                    "effective_gearing": "18.1",
                    "id": 2,
                    "exercise_price_adj": 4799.640000000,
                    "ticker": "9PCW",
                    "ric": "HSML_t213.SI",
                    "BID_NETCHNG": 0.027000000,
                    "dsply_name": "HSI 28200MBePW210330",
                    "exercise_price": 28200.000000000
                }
            ]
        },
        "MEITUAN-W": {
            "CALL": [
                {
                    "BID": 0.140000000,
                    "underlying_ric": "3690.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "MEITUAN-W",
                    "underlying_name": "MEITUAN-W",
                    "type": "CALL",
                    "BID_PCTCHNG": "-30.0",
                    "effective_gearing": "4.6",
                    "id": 1,
                    "exercise_price_adj": 68.080000000,
                    "ticker": "YC8W",
                    "ric": "MEML_td.SI",
                    "BID_NETCHNG": -0.060000000,
                    "dsply_name": "Meituan MB eCW210705",
                    "exercise_price": 400.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.033000000,
                    "underlying_ric": "3690.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "MEITUAN-W",
                    "underlying_name": "MEITUAN-W",
                    "type": "PUT",
                    "BID_PCTCHNG": "57.1",
                    "effective_gearing": "5.0",
                    "id": 2,
                    "exercise_price_adj": 44.252000000,
                    "ticker": "MXTW",
                    "ric": "MEML_tc.SI",
                    "BID_NETCHNG": 0.012000000,
                    "dsply_name": "Meituan MB ePW210705",
                    "exercise_price": 260.000000000
                }
            ]
        },
        "TENCENT": {
            "CALL": [
                {
                    "BID": 0.205000000,
                    "underlying_ric": "0700.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "TENCENT",
                    "underlying_name": "TENCENT",
                    "type": "CALL",
                    "BID_PCTCHNG": "-18.0",
                    "effective_gearing": "5.1",
                    "id": 1,
                    "exercise_price_adj": 119.140000000,
                    "ticker": "PHDW",
                    "ric": "TCML_t20.SI",
                    "BID_NETCHNG": -0.045000000,
                    "dsply_name": "TencentMBeCW210705",
                    "exercise_price": 700.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.050000000,
                    "underlying_ric": "0700.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "TENCENT",
                    "underlying_name": "TENCENT",
                    "type": "PUT",
                    "BID_PCTCHNG": "22.0",
                    "effective_gearing": "5.1",
                    "id": 2,
                    "exercise_price_adj": 85.100000000,
                    "ticker": "WVDW",
                    "ric": "TCML_t19.SI",
                    "BID_NETCHNG": 0.009000000,
                    "dsply_name": "TencentMBePW210705",
                    "exercise_price": 500.000000000
                }
            ]
        },
        "STI": {
            "CALL": [
                {
                    "BID": 0.049000000,
                    "underlying_ric": ".STI",
                    "maturity": "30 Jun 21",
                    "underlying_ticker": "STI",
                    "underlying_name": "STI",
                    "type": "CALL",
                    "BID_PCTCHNG": "11.4",
                    "effective_gearing": "9.6",
                    "id": 1,
                    "exercise_price_adj": 3100.000000000,
                    "ticker": "EZEW",
                    "ric": "STML_t38.SI",
                    "BID_NETCHNG": 0.005000000,
                    "dsply_name": "STI 3100MBeCW210630",
                    "exercise_price": 3100.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.068000000,
                    "underlying_ric": ".STI",
                    "maturity": "30 Jun 21",
                    "underlying_ticker": "STI",
                    "underlying_name": "STI",
                    "type": "PUT",
                    "BID_PCTCHNG": "-8.1",
                    "effective_gearing": "6.3",
                    "id": 2,
                    "exercise_price_adj": 2800.000000000,
                    "ticker": "YZHW",
                    "ric": "STML_t36.SI",
                    "BID_NETCHNG": -0.006000000,
                    "dsply_name": "STI 2800MBePW210630",
                    "exercise_price": 2800.000000000
                }
            ]
        },
        "WILMAR INTERNATI": {
            "CALL": [
                {
                    "BID": 0.137000000,
                    "underlying_ric": "WLIL.SI",
                    "maturity": "18 Oct 21",
                    "underlying_ticker": "F34",
                    "underlying_name": "WILMAR INTERNATI",
                    "type": "CALL",
                    "BID_PCTCHNG": "-0.7",
                    "effective_gearing": "3.1",
                    "id": 1,
                    "exercise_price_adj": 4.800000000,
                    "ticker": "NLGW",
                    "ric": "WLML_t9.SI",
                    "BID_NETCHNG": -0.001000000,
                    "dsply_name": "Wilmar MB eCW211018",
                    "exercise_price": 4.800000000
                }
            ],
            "PUT": []
        },
        "BABA-SW": {
            "CALL": [
                {
                    "BID": 0.040000000,
                    "underlying_ric": "9988.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "BABA-SW",
                    "underlying_name": "BABA-SW",
                    "type": "CALL",
                    "BID_PCTCHNG": "-13.0",
                    "effective_gearing": "6.5",
                    "id": 1,
                    "exercise_price_adj": 51.060000000,
                    "ticker": "R42W",
                    "ric": "ALML_th.SI",
                    "BID_NETCHNG": -0.006000000,
                    "dsply_name": "Alibaba MB eCW210705",
                    "exercise_price": 300.000000000
                }
            ],
            "PUT": [
                {
                    "BID": 0.104000000,
                    "underlying_ric": "9988.HK",
                    "maturity": "05 Jul 21",
                    "underlying_ticker": "BABA-SW",
                    "underlying_name": "BABA-SW",
                    "type": "PUT",
                    "BID_PCTCHNG": "7.2",
                    "effective_gearing": "3.7",
                    "id": 2,
                    "exercise_price_adj": 40.848000000,
                    "ticker": "QBDW",
                    "ric": "ALML_tg.SI",
                    "BID_NETCHNG": 0.007000000,
                    "dsply_name": "Alibaba MB ePW210705",
                    "exercise_price": 240.000000000
                }
            ]
        }
    },
    "hotlist": {
        "index": [],
        "stock": []
    },
    "symbols": [
        {
            "dsply_nmll": "AEM MB eCW210917",
            "publish_time": 1614147024000,
            "ric": "AEML_tm.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VKEW"
        },
        {
            "dsply_nmll": "Alibaba MB eCW210705",
            "publish_time": 1614154031000,
            "ric": "ALML_th.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "R42W"
        },
        {
            "dsply_nmll": "Alibaba MB ePW210705",
            "publish_time": 1614154031000,
            "ric": "ALML_tg.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "QBDW"
        },
        {
            "dsply_nmll": "AlibabaMBeCW210405",
            "publish_time": 1614154031000,
            "ric": "ALML_tf.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "1M7W"
        },
        {
            "dsply_nmll": "AlibabaMBePW210405",
            "publish_time": 1614154031000,
            "ric": "ALML_te.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "X7RW"
        },
        {
            "dsply_nmll": "AscendasReit MBeCW210524",
            "publish_time": 1614126604000,
            "ric": "AEML_tl.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "RQOW"
        },
        {
            "dsply_nmll": "BYD MB eCW210405",
            "publish_time": 1614154113000,
            "ric": "BYML_tb.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VHUW"
        },
        {
            "dsply_nmll": "BYD MB eCW210705",
            "publish_time": 1614154113000,
            "ric": "BYML_te.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "TMHW"
        },
        {
            "dsply_nmll": "BYD MB eCW210705A",
            "publish_time": 1614154113000,
            "ric": "BYML_tg.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "MGVW"
        },
        {
            "dsply_nmll": "BYD MB ePW210405",
            "publish_time": 1614154113000,
            "ric": "BYML_ta.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "RUQW"
        },
        {
            "dsply_nmll": "BYD MB ePW210705",
            "publish_time": 1614154113000,
            "ric": "BYML_tf.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "YBMW"
        },
        {
            "dsply_nmll": "BYD MB ePW210705A",
            "publish_time": 1614154113000,
            "ric": "BYML_th.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VRJW"
        },
        {
            "dsply_nmll": "CapitalaMBeCW210304",
            "publish_time": 1614126603000,
            "ric": "CAML_t3.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "HUFW"
        },
        {
            "dsply_nmll": "CapitalaMBeCW211018",
            "publish_time": 1614126605000,
            "ric": "CAML_t4.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "MHJW"
        },
        {
            "dsply_nmll": "CityDev MB eCW210507",
            "publish_time": 1614130872000,
            "ric": "CTML_tt.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "LQXW"
        },
        {
            "dsply_nmll": "ComfortDel MBeCW211018",
            "publish_time": 1614126605000,
            "ric": "CMML_t2.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "MSHW"
        },
        {
            "dsply_nmll": "DBS MB eCW210405",
            "publish_time": 1614150480000,
            "ric": "DBML_t30.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "WRAW"
        },
        {
            "dsply_nmll": "DBS MB eCW210407",
            "publish_time": 1614150480000,
            "ric": "DBML_t31.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "EYOW"
        },
        {
            "dsply_nmll": "DBS MB eCW210503",
            "publish_time": 1614152270000,
            "ric": "DBML_t38.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "HRZW"
        },
        {
            "dsply_nmll": "DBS MB eCW211001",
            "publish_time": 1614150480000,
            "ric": "DBML_t33.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "9WJW"
        },
        {
            "dsply_nmll": "DBS MB eCW211008",
            "publish_time": 1614150480000,
            "ric": "DBML_t34.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "OFHW"
        },
        {
            "dsply_nmll": "DBS MB ePW210701",
            "publish_time": 1614150480000,
            "ric": "DBML_t32.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "YY9W"
        },
        {
            "dsply_nmll": "DBS MB ePW210705",
            "publish_time": 1614152270000,
            "ric": "DBML_t37.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "QYLW"
        },
        {
            "dsply_nmll": "DBS MB ePW211001",
            "publish_time": 1614150480000,
            "ric": "DBML_t36.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "KEDW"
        },
        {
            "dsply_nmll": "DBS MB ePW211008",
            "publish_time": 1614150629000,
            "ric": "DBML_t35.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "RWXW"
        },
        {
            "dsply_nmll": "Geely MB eCW210405",
            "publish_time": 1614153777000,
            "ric": "GEML_tb.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "RQUW"
        },
        {
            "dsply_nmll": "Geely MB eCW210705",
            "publish_time": 1614153777000,
            "ric": "GEML_td.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "RUHW"
        },
        {
            "dsply_nmll": "Geely MB eCW211005",
            "publish_time": 1614153777000,
            "ric": "GEML_te.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "XQDW"
        },
        {
            "dsply_nmll": "Geely MB ePW210405",
            "publish_time": 1614153776000,
            "ric": "GEML_ta.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "XZ4W"
        },
        {
            "dsply_nmll": "Geely MB ePW210705",
            "publish_time": 1614153777000,
            "ric": "GEML_tc.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "OCCW"
        },
        {
            "dsply_nmll": "Geely MB ePW211005",
            "publish_time": 1614153777000,
            "ric": "GEML_tf.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VZGW"
        },
        {
            "dsply_nmll": "GentingSMBeCW210701",
            "publish_time": 1614126606000,
            "ric": "GTML_te.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "XJLW"
        },
        {
            "dsply_nmll": "HSI 24800MBePW210528",
            "publish_time": 1614155296000,
            "ric": "HSML_t210.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "TPCW"
        },
        {
            "dsply_nmll": "HSI 25400MBePW210429",
            "publish_time": 1614155318000,
            "ric": "HSML_t204.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "FMPW"
        },
        {
            "dsply_nmll": "HSI 25800MBePW210330",
            "publish_time": 1614155317000,
            "ric": "HSML_t197.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "KWBW"
        },
        {
            "dsply_nmll": "HSI 26200MBePW210429",
            "publish_time": 1614155295000,
            "ric": "HSML_t205.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "TNSW"
        },
        {
            "dsply_nmll": "HSI 26400MBePW210528",
            "publish_time": 1614155400000,
            "ric": "HSML_t214.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "EGAW"
        },
        {
            "dsply_nmll": "HSI 26600MBeCW210528",
            "publish_time": 1614155318000,
            "ric": "HSML_t209.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "OKCW"
        },
        {
            "dsply_nmll": "HSI 26600MBePW210330",
            "publish_time": 1614155317000,
            "ric": "HSML_t196.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "NMKW"
        },
        {
            "dsply_nmll": "HSI 27000MBePW210429",
            "publish_time": 1614155295000,
            "ric": "HSML_t199.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "8XWW"
        },
        {
            "dsply_nmll": "HSI 27200MBeCW210330",
            "publish_time": 1614155295000,
            "ric": "HSML_t195.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "W6JW"
        },
        {
            "dsply_nmll": "HSI 27400MBePW210330",
            "publish_time": 1614155296000,
            "ric": "HSML_t208.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "EEKW"
        },
        {
            "dsply_nmll": "HSI 27600MBeCW210429",
            "publish_time": 1614155318000,
            "ric": "HSML_t200.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "GJDW"
        },
        {
            "dsply_nmll": "HSI 28000MBeCW210330",
            "publish_time": 1614155318000,
            "ric": "HSML_t194.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "OMCW"
        },
        {
            "dsply_nmll": "HSI 28000MBePW210528",
            "publish_time": 1614155318000,
            "ric": "HSML_t207.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "1HZW"
        },
        {
            "dsply_nmll": "HSI 28200MBePW210330",
            "publish_time": 1614155400000,
            "ric": "HSML_t213.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "9PCW"
        },
        {
            "dsply_nmll": "HSI 28400MBeCW210429",
            "publish_time": 1614155296000,
            "ric": "HSML_t201.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "SGAW"
        },
        {
            "dsply_nmll": "HSI 28600MBePW210429",
            "publish_time": 1614155318000,
            "ric": "HSML_t206.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "CVCW"
        },
        {
            "dsply_nmll": "HSI 28800MBeCW210330",
            "publish_time": 1614155318000,
            "ric": "HSML_t198.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "QHEW"
        },
        {
            "dsply_nmll": "HSI 29200MBeCW210429",
            "publish_time": 1614155295000,
            "ric": "HSML_t202.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "DZBW"
        },
        {
            "dsply_nmll": "HSI 29600MBeCW210330",
            "publish_time": 1614155296000,
            "ric": "HSML_t215.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "5N8W"
        },
        {
            "dsply_nmll": "HSI 30000MBeCW210429",
            "publish_time": 1614155318000,
            "ric": "HSML_t203.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "XLDW"
        },
        {
            "dsply_nmll": "HSI 30400MBeCW210330",
            "publish_time": 1614155318000,
            "ric": "HSML_t212.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "HGKW"
        },
        {
            "dsply_nmll": "HSI 30800MBeCW210429",
            "publish_time": 1614155400000,
            "ric": "HSML_t216.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "X5OW"
        },
        {
            "dsply_nmll": "HSI 31200MBeCW210528",
            "publish_time": 1614155318000,
            "ric": "HSML_t211.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "Z7PW"
        },
        {
            "dsply_nmll": "HSI 31600MBeCW210429",
            "publish_time": 1614155351000,
            "ric": "HSML_t217.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "KKAW"
        },
        {
            "dsply_nmll": "HSI 32400MBeCW210429",
            "publish_time": 1614155351000,
            "ric": "HSML_t218.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "LPQW"
        },
        {
            "dsply_nmll": "HSTECH 12300MBeCW210429",
            "publish_time": 1614154875000,
            "ric": "HSML_t219.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "IQTW"
        },
        {
            "dsply_nmll": "HSTECH 14000MBeCW210629",
            "publish_time": 1614154875000,
            "ric": "HSML_t220.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "ICYW"
        },
        {
            "dsply_nmll": "HSTECH 8000MBePW210629",
            "publish_time": 1614154875000,
            "ric": "HSML_t221.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "V6EW"
        },
        {
            "dsply_nmll": "HSTECH 9300MBePW210429",
            "publish_time": 1614154875000,
            "ric": "HSML_t222.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "HANG SENG MAR1",
            "security_code": "YF7W"
        },
        {
            "dsply_nmll": "IFAST MB eCW210601",
            "publish_time": 1614150234000,
            "ric": "IFML_tu.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "KTRW"
        },
        {
            "dsply_nmll": "JD MB eCW210705",
            "publish_time": 1614154079000,
            "ric": "JDML_tb.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "OPSW"
        },
        {
            "dsply_nmll": "JD MB ePW210705",
            "publish_time": 1614154079000,
            "ric": "JDML_tc.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "DVDW"
        },
        {
            "dsply_nmll": "KepCorpMBeCW210304",
            "publish_time": 1614134154000,
            "ric": "KPML_t9.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "NMBW"
        },
        {
            "dsply_nmll": "KepCorpMBeCW211018",
            "publish_time": 1614126605000,
            "ric": "KPML_t10.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "WFEW"
        },
        {
            "dsply_nmll": "MapleComTr MB eCW211018",
            "publish_time": 1614126605000,
            "ric": "MAML_t.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "TRGW"
        },
        {
            "dsply_nmll": "Meituan MB eCW210405",
            "publish_time": 1614154113000,
            "ric": "MEML_tb.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "JAOW"
        },
        {
            "dsply_nmll": "Meituan MB eCW210705",
            "publish_time": 1614154113000,
            "ric": "MEML_td.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "YC8W"
        },
        {
            "dsply_nmll": "Meituan MB ePW210405",
            "publish_time": 1614154113000,
            "ric": "MEML_ta.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "YLAW"
        },
        {
            "dsply_nmll": "Meituan MB ePW210705",
            "publish_time": 1614154113000,
            "ric": "MEML_tc.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "MXTW"
        },
        {
            "dsply_nmll": "NKY 19000MBePW210312",
            "publish_time": 1614153679000,
            "ric": "NIML_t20.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "WG8W"
        },
        {
            "dsply_nmll": "NKY 20000MBePW210611",
            "publish_time": 1614153679000,
            "ric": "NIML_t27.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "HASW"
        },
        {
            "dsply_nmll": "NKY 22000MBePW210312",
            "publish_time": 1614153679000,
            "ric": "NIML_t19.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "UAFW"
        },
        {
            "dsply_nmll": "NKY 23000MBePW210611",
            "publish_time": 1614153679000,
            "ric": "NIML_t28.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "XDQW"
        },
        {
            "dsply_nmll": "NKY 24000MBeCW210312",
            "publish_time": 1614153679000,
            "ric": "NIML_t16.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "PJLW"
        },
        {
            "dsply_nmll": "NKY 25000MBeCW210611",
            "publish_time": 1614153679000,
            "ric": "NIML_t22.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "ZALW"
        },
        {
            "dsply_nmll": "NKY 25000MBePW210312",
            "publish_time": 1614153679000,
            "ric": "NIML_t21.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "VTWW"
        },
        {
            "dsply_nmll": "NKY 26000MBePW210611",
            "publish_time": 1614153679000,
            "ric": "NIML_t23.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "EOGW"
        },
        {
            "dsply_nmll": "NKY 27000MBeCW210312",
            "publish_time": 1614153679000,
            "ric": "NIML_t17.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "FIQW"
        },
        {
            "dsply_nmll": "NKY 28000MBeCW210611",
            "publish_time": 1614153679000,
            "ric": "NIML_t24.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "TCFW"
        },
        {
            "dsply_nmll": "NKY 29000MBePW210611",
            "publish_time": 1614153679000,
            "ric": "NIML_t25.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "9AFW"
        },
        {
            "dsply_nmll": "NKY 30000MBeCW210312",
            "publish_time": 1614153679000,
            "ric": "NIML_t18.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "YTXW"
        },
        {
            "dsply_nmll": "NKY 31000MBeCW210611",
            "publish_time": 1614153679000,
            "ric": "NIML_t26.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "LXLW"
        },
        {
            "dsply_nmll": "OCBC Bk MB eCW210303",
            "publish_time": 1614145747000,
            "ric": "OCML_t18.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "JSYW"
        },
        {
            "dsply_nmll": "OCBC Bk MB eCW210507",
            "publish_time": 1614146492000,
            "ric": "OCML_t27.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "ZRDW"
        },
        {
            "dsply_nmll": "OCBC Bk MB eCW210510",
            "publish_time": 1614145747000,
            "ric": "OCML_t20.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "LQFW"
        },
        {
            "dsply_nmll": "OCBC Bk MB eCW210514",
            "publish_time": 1614145747000,
            "ric": "OCML_t22.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "GWWW"
        },
        {
            "dsply_nmll": "OCBC Bk MB eCW211112",
            "publish_time": 1614145747000,
            "ric": "OCML_t25.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "WZ1W"
        },
        {
            "dsply_nmll": "OCBC Bk MB eCW211115",
            "publish_time": 1614145747000,
            "ric": "OCML_t23.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VYWW"
        },
        {
            "dsply_nmll": "OCBC Bk MB ePW210701",
            "publish_time": 1614145747000,
            "ric": "OCML_t19.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "EUEW"
        },
        {
            "dsply_nmll": "OCBC Bk MB ePW210705",
            "publish_time": 1614145747000,
            "ric": "OCML_t21.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "9I5W"
        },
        {
            "dsply_nmll": "OCBC Bk MB ePW211112",
            "publish_time": 1614145747000,
            "ric": "OCML_t24.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "95JW"
        },
        {
            "dsply_nmll": "OCBC Bk MB ePW211115",
            "publish_time": 1614145748000,
            "ric": "OCML_t26.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "JWRW"
        },
        {
            "dsply_nmll": "S&P 2700MBePW210319",
            "publish_time": 1614154799000,
            "ric": "SXML_t20.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "IQZW"
        },
        {
            "dsply_nmll": "S&P 2950MBePW210319",
            "publish_time": 1614154743000,
            "ric": "SXML_t22.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "DOHW"
        },
        {
            "dsply_nmll": "S&P 3000MBePW210618",
            "publish_time": 1614154743000,
            "ric": "SXML_t24.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "XYPW"
        },
        {
            "dsply_nmll": "S&P 3400MBePW210618",
            "publish_time": 1614154810000,
            "ric": "SXML_t23.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "OKBW"
        },
        {
            "dsply_nmll": "S&P 3800MBeCW210618",
            "publish_time": 1614154732000,
            "ric": "SXML_t26.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "RUUW"
        },
        {
            "dsply_nmll": "S&P 3950MBeCW210319",
            "publish_time": 1614154742000,
            "ric": "SXML_t21.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "VQEW"
        },
        {
            "dsply_nmll": "S&P 4200MBeCW210618",
            "publish_time": 1614154732000,
            "ric": "SXML_t25.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "",
            "security_code": "GTJW"
        },
        {
            "dsply_nmll": "SGX MB eCW210315",
            "publish_time": 1614126603000,
            "ric": "SNML_t2.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "9QJW"
        },
        {
            "dsply_nmll": "SGX MB eCW211018",
            "publish_time": 1614126605000,
            "ric": "SNML_t4.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "ESYW"
        },
        {
            "dsply_nmll": "SGX MB ePW210315",
            "publish_time": 1614126603000,
            "ric": "SNML_t1.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "RXPW"
        },
        {
            "dsply_nmll": "SGX MB ePW211018",
            "publish_time": 1614126605000,
            "ric": "SNML_t3.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "UJGW"
        },
        {
            "dsply_nmll": "SIA MB eCW210701",
            "publish_time": 1614129577000,
            "ric": "SLML_ta.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "GIJW"
        },
        {
            "dsply_nmll": "SingtelMBeCW210304",
            "publish_time": 1614126603000,
            "ric": "SEML_t18.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "GJAW"
        },
        {
            "dsply_nmll": "SingtelMBeCW210331",
            "publish_time": 1614126602000,
            "ric": "SEML_t16.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "YBNW"
        },
        {
            "dsply_nmll": "SingtelMBeCW211018",
            "publish_time": 1614126605000,
            "ric": "SEML_t7.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "59XW"
        },
        {
            "dsply_nmll": "STI 2300MBePW210331",
            "publish_time": 1614155234000,
            "ric": "STML_t31.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "WVEW"
        },
        {
            "dsply_nmll": "STI 2400MBePW210630",
            "publish_time": 1614142802000,
            "ric": "STML_t35.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "OSTW"
        },
        {
            "dsply_nmll": "STI 2500MBePW210331",
            "publish_time": 1614142803000,
            "ric": "STML_t32.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "DFAW"
        },
        {
            "dsply_nmll": "STI 2500MBePW210930",
            "publish_time": 1614142803000,
            "ric": "STML_t42.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "JUUW"
        },
        {
            "dsply_nmll": "STI 2550MBeCW210331",
            "publish_time": 1614155234000,
            "ric": "STML_t33.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "8C5W"
        },
        {
            "dsply_nmll": "STI 2600MBePW210630",
            "publish_time": 1614142803000,
            "ric": "STML_t39.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VVZW"
        },
        {
            "dsply_nmll": "STI 2650MBeCW210630",
            "publish_time": 1614142802000,
            "ric": "STML_t34.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "XINW"
        },
        {
            "dsply_nmll": "STI 2750MBePW210930",
            "publish_time": 1614142803000,
            "ric": "STML_t41.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "JUPW"
        },
        {
            "dsply_nmll": "STI 2800MBeCW210331",
            "publish_time": 1614155234000,
            "ric": "STML_t29.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "URDW"
        },
        {
            "dsply_nmll": "STI 2800MBeCW210930",
            "publish_time": 1614142803000,
            "ric": "STML_t40.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "UNCW"
        },
        {
            "dsply_nmll": "STI 2800MBePW210630",
            "publish_time": 1614142803000,
            "ric": "STML_t36.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "YZHW"
        },
        {
            "dsply_nmll": "STI 2900MBeCW210630",
            "publish_time": 1614142803000,
            "ric": "STML_t37.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "PXKW"
        },
        {
            "dsply_nmll": "STI 3000MBeCW210331",
            "publish_time": 1614155234000,
            "ric": "STML_t30.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VSBW"
        },
        {
            "dsply_nmll": "STI 3000MBePW210930",
            "publish_time": 1614142803000,
            "ric": "STML_t45.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "PCEW"
        },
        {
            "dsply_nmll": "STI 3050MBeCW210930",
            "publish_time": 1614142803000,
            "ric": "STML_t44.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "V6OW"
        },
        {
            "dsply_nmll": "STI 3100MBeCW210630",
            "publish_time": 1614142803000,
            "ric": "STML_t38.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "EZEW"
        },
        {
            "dsply_nmll": "STI 3300MBeCW210930",
            "publish_time": 1614142803000,
            "ric": "STML_t43.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "LZTW"
        },
        {
            "dsply_nmll": "Sunny MB eCW210405",
            "publish_time": 1614154076000,
            "ric": "SUML_tb.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "9T9W"
        },
        {
            "dsply_nmll": "Sunny MB eCW210705",
            "publish_time": 1614154076000,
            "ric": "SUML_tc.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "WAVW"
        },
        {
            "dsply_nmll": "Sunny MB ePW210405",
            "publish_time": 1614154076000,
            "ric": "SUML_ta.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "PDGW"
        },
        {
            "dsply_nmll": "Sunny MB ePW210705",
            "publish_time": 1614154076000,
            "ric": "SUML_td.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "8P7W"
        },
        {
            "dsply_nmll": "TencentMBeCW210705",
            "publish_time": 1614154113000,
            "ric": "TCML_t20.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "PHDW"
        },
        {
            "dsply_nmll": "TencentMBePW210705",
            "publish_time": 1614154113000,
            "ric": "TCML_t19.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "WVDW"
        },
        {
            "dsply_nmll": "Top Glove MB eCW210917",
            "publish_time": 1614126606000,
            "ric": "TOPG_ta.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "1P7W"
        },
        {
            "dsply_nmll": "UOB MB eCW210405",
            "publish_time": 1614153750000,
            "ric": "UOML_t24.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "SMOW"
        },
        {
            "dsply_nmll": "UOB MB eCW210407",
            "publish_time": 1614153750000,
            "ric": "UOML_t25.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "FSXW"
        },
        {
            "dsply_nmll": "UOB MB eCW210503",
            "publish_time": 1614153751000,
            "ric": "UOML_t35.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "V8DW"
        },
        {
            "dsply_nmll": "UOB MB eCW210506",
            "publish_time": 1614153750000,
            "ric": "UOML_t26.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "PWBW"
        },
        {
            "dsply_nmll": "UOB MB eCW210514",
            "publish_time": 1614153751000,
            "ric": "UOML_t34.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "ELVW"
        },
        {
            "dsply_nmll": "UOB MB eCW211001",
            "publish_time": 1614153751000,
            "ric": "UOML_t31.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "XIIW"
        },
        {
            "dsply_nmll": "UOB MB eCW211004",
            "publish_time": 1614153751000,
            "ric": "UOML_t28.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "KVKW"
        },
        {
            "dsply_nmll": "UOB MB eCW211008",
            "publish_time": 1614153751000,
            "ric": "UOML_t29.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "RABW"
        },
        {
            "dsply_nmll": "UOB MB ePW210701",
            "publish_time": 1614153750000,
            "ric": "UOML_t27.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "X0RW"
        },
        {
            "dsply_nmll": "UOB MB ePW210705",
            "publish_time": 1614153751000,
            "ric": "UOML_t33.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "YWRW"
        },
        {
            "dsply_nmll": "UOB MB ePW211001",
            "publish_time": 1614153751000,
            "ric": "UOML_t32.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "VIFW"
        },
        {
            "dsply_nmll": "UOB MB ePW211008",
            "publish_time": 1614153751000,
            "ric": "UOML_t30.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "MPPW"
        },
        {
            "dsply_nmll": "UOL MB eCW210701",
            "publish_time": 1614126604000,
            "ric": "ULML_t.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "ORGW"
        },
        {
            "dsply_nmll": "Wilmar MB eCW210331",
            "publish_time": 1614126602000,
            "ric": "WLML_t7.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "KSCW"
        },
        {
            "dsply_nmll": "Wilmar MB eCW211018",
            "publish_time": 1614126605000,
            "ric": "WLML_t9.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "NLGW"
        },
        {
            "dsply_nmll": "XIAOMI MBeCW210405",
            "publish_time": 1614153667000,
            "ric": "XIML_t11.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "FWDW"
        },
        {
            "dsply_nmll": "XIAOMI MBeCW210705",
            "publish_time": 1614153667000,
            "ric": "XIML_t16.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "WAIW"
        },
        {
            "dsply_nmll": "XIAOMI MBePW210405",
            "publish_time": 1614153667000,
            "ric": "XIML_t12.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "UDWW"
        },
        {
            "dsply_nmll": "XIAOMI MBePW210705",
            "publish_time": 1614153667000,
            "ric": "XIML_t15.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "UKKW"
        },
        {
            "dsply_nmll": "YangzijiMBeCW211101",
            "publish_time": 1614126606000,
            "ric": "YZML_tx.SI",
            "create_for_date": 1614124800000,
            "future_dsply_name": "-",
            "security_code": "FOHW"
        }
    ],
    "topmover": {
        "HK_stocks": [
            {
                "underlying_ric": "3690.HK",
                "NETCHNG_1": -27.000000000,
                "TRDPRC_1": 365.800000000,
                "underlying_ticker": "MEITUAN-W",
                "HST_CLOSE": 392.800000000,
                "underlying_name": "MEITUAN-W",
                "PCTCHNG": -6.870000000
            },
            {
                "underlying_ric": "1211.HK",
                "NETCHNG_1": -13.400000000,
                "TRDPRC_1": 212.200000000,
                "underlying_ticker": "BYD COMPANY",
                "HST_CLOSE": 225.600000000,
                "underlying_name": "BYD COMPANY",
                "PCTCHNG": -5.940000000
            },
            {
                "underlying_ric": "0175.HK",
                "NETCHNG_1": -1.600000000,
                "TRDPRC_1": 25.900000000,
                "underlying_ticker": "GEELY AUTO",
                "HST_CLOSE": 27.500000000,
                "underlying_name": "GEELY AUTO",
                "PCTCHNG": -5.820000000
            },
            {
                "underlying_ric": "2382.HK",
                "NETCHNG_1": -10.800000000,
                "TRDPRC_1": 197.400000000,
                "underlying_ticker": "SUNNY OPTICAL",
                "HST_CLOSE": 208.200000000,
                "underlying_name": "SUNNY OPTICAL",
                "PCTCHNG": -5.190000000
            }
        ],
        "SG_stocks": [
            {
                "underlying_ric": "AEM.SI",
                "NETCHNG_1": -0.270000000,
                "TRDPRC_1": 4.020000000,
                "underlying_ticker": "AWX",
                "HST_CLOSE": 4.290000000,
                "underlying_name": "AEM HOLDINGS LTD",
                "PCTCHNG": -6.290000000
            },
            {
                "underlying_ric": "TOPG.SI",
                "NETCHNG_1": -0.090000000,
                "TRDPRC_1": 1.810000000,
                "underlying_ticker": "BVA",
                "HST_CLOSE": 1.900000000,
                "underlying_name": "TOP GLOVE CORPOR",
                "PCTCHNG": -4.740000000
            },
            {
                "underlying_ric": "DBSM.SI",
                "NETCHNG_1": 0.700000000,
                "TRDPRC_1": 26.360000000,
                "underlying_ticker": "D05",
                "HST_CLOSE": 25.660000000,
                "underlying_name": "DBS",
                "PCTCHNG": 2.730000000
            },
            {
                "underlying_ric": "OCBC.SI",
                "NETCHNG_1": 0.250000000,
                "TRDPRC_1": 10.880000000,
                "underlying_ticker": "O39",
                "HST_CLOSE": 10.630000000,
                "underlying_name": "OCBC",
                "PCTCHNG": 2.350000000
            }
        ],
        "Indices": [
            {
                "underlying_ric": "HHTIc2",
                "NETCHNG_1": -446.000000000,
                "TRDPRC_1": 9339.000000000,
                "underlying_ticker": "HSTECH",
                "HST_CLOSE": 9785.000000000,
                "underlying_name": "HANG SENG TECH INDEX",
                "PCTCHNG": -4.560000000
            },
            {
                "underlying_ric": "HSIc2",
                "NETCHNG_1": -897.000000000,
                "TRDPRC_1": 29597.000000000,
                "underlying_ticker": "HSI",
                "HST_CLOSE": 30494.000000000,
                "underlying_name": "HSI",
                "PCTCHNG": -2.940000000
            },
            {
                "underlying_ric": ".STI",
                "NETCHNG_1": 37.790000000,
                "TRDPRC_1": 2928.490000000,
                "underlying_ticker": "STI",
                "HST_CLOSE": 2890.700000000,
                "underlying_name": "STI",
                "PCTCHNG": 1.310000000
            },
            {
                "underlying_ric": "SSIcv1",
                "NETCHNG_1": 110.000000000,
                "TRDPRC_1": 29775.000000000,
                "underlying_ticker": "N225",
                "HST_CLOSE": 29665.000000000,
                "underlying_name": "N225",
                "PCTCHNG": 0.370000000
            }
        ]
    }
}

export default dwtool;