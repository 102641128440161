
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class delta extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="" >
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <div className="videotxt hidden-xxs hidden-xs">
                                                <p>There are a number of different terms that are often used when investing in warrants and it’s important to understand what these mean if you’re going to trade this important market instrument successfully. The first term
                                                    we’re going to look at is called delta.</p>
                                            </div>
                                            <h2>Delta Formula</h2>
                                            <p>Delta shows the approximate change in the warrant price for a small change in the underlying share (or asset) price.</p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/delta_formula.png" />
                                            </div>
                                            <p>Therefore, a delta of 50% means that the warrant should move approximately $0.005 for each $0.01 move in the underlying asset. Deltas for puts are negative since they move inversely with the underlying price.</p>
                                            <table className="ruletable">
                                                <thead>
                                                    <tr>
                                                        <td className="title" colSpan={3}>
                                                            General rule of thumb for </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td />
                                                        <td className="col2">Call</td>
                                                        <td className="col3">Put</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col1">In-the-money(max)</td>
                                                        <td className="col2">100%</td>
                                                        <td className="col3">-100%</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col1">At-the-money</td>
                                                        <td className="col2">50%</td>
                                                        <td className="col3">-50%</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col1">Out-of-the-money(min)</td>
                                                        <td className="col2">0%</td>
                                                        <td className="col3">0%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p className="disclaimer" style={{
                                                cursor: 'default'
                                            }}>
                                                Deltas range from 0 to 100% for calls and 0 to -100% for puts
                                            </p>
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips on how to use Delta </h2>
                                                    <ul>
                                                        <li>
                                                            <p>Delta can also be viewed an indicator of the probability that a warrant will expire in the money (ie. with value). For example a warrant with a delta of 20% has a 20% probability that it will expire with value,
                                                                and 80% chance that it will expire worthless.</p>
                                                        </li>
                                                        <li>
                                                            <p>When using delta to estimate how much a warrant price will move for a particular price change in the underlying, you must take into consideration the warrants per share number to estimate the “delta per warrant”.
                                                                See an <a href="/WarrantsGuidebook/warrantsensitivity" style={{ color: 'red' }}>example</a> of how you can use delta and warrants per share to calculate a warrant’s sensitivity.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p>Use caution when choosing warrants that have deltas either above 85% (termed deep in the money) or below 15% (termed deep out of the money) as issuers will often widen the offer spread for these warrants to
                                                                discourage further buying in these warrants. Deep OTM warrants in particular, are risky for investors to buy.</p>
                                                        </li>
                                                        <li>
                                                            <p>Macquarie provides delta for all warrants listed on the SGX, you view these via the <a href="/tools/warrantsearch" style={{ color: 'red' }}>warrant search tool</a> or the <a href="/tools/warrantterms" style={{ color: 'red' }}>warrant terms page</a>.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}