
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class exerciseprice extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div>
                <div >
                    <div className="" >
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>This is the fixed price at which investors are entitled to buy the underlying share on the expiry date for call warrants (or sell, in the case of put warrants). As such, the higher this price, the less likely the call warrant
                                                will be exercised and hence, the less value ascribed to the warrant. Conversely, in the case of a put warrant, the higher the price at which you are able to sell the asset for on a future date, then the more likely the
                                                put warrant is to be exercised and hence, a higher value ascribed to that put.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/Exercise-Price_enlarge.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}