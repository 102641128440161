
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class singleshareputwarrantexample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <div className="imgholder">
                                                <img src="/img/education/example-of-expired-stock-put-warrant.jpg" alt="Example of expired stock put warrant." />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/example-of-expired-stock-put-warrant.jpg')}>View table</div>
                                            </div>
                                            <p>
                                                Now let’s look at an example. Let’s say the exercise price of the share was $1.00, the warrants per share figure was 1 and the average closing price of the underlying shares in the five days prior to expiry is 80 cents. Since the settlement value of the
                                                shares at 80 cents is below the put warrant’s exercise price, the right to sell the shares at the exercise price is automatically exercised.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/calculation-of-cash-settlement-amount_enlarge.jpg" alt="Calculation of cash settlement amount enlarge" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/calculation-of-cash-settlement-amount_enlarge.jpg')}>View table</div>
                                            </div>
                                            <p>
                                                To work out the amount you will receive on this warrant, you would subtract 80 cents from $1.00 and divide this by 1, giving you a figure of 20 cents.
                                            </p>
                                            <p>
                                                If the average closing price is at the money or out of the money, there is no cash settlement value. Hence, you would not receive a cheque.
                                            </p>
                                            <br />
                                            <br />
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>The settlement value for single stock warrants at expiry is based on an average closing price for the underlying share over the 5 days prior to expiry. This is done to reduce the ability for any large expiring
                                                                warrant positions to effect the closing price of the underlying share at expiry. </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}