import React, { Component } from "react";
import "./hstech.css";
import MQSGUtil from "../../../components/mqsgUtil";
import { Link } from "react-router-dom";

export default class Hstech extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trendingwarrant: null
    };
  }
  componentDidMount() {
    document.title = "HSTECH warrants | Singapore Warrants | Macquarie";
    this.onFetchData()
  }
  onFetchData() {
    const url = MQSGUtil.getAPIBasePath() + "/HomeJSON?m=dwtool";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ trendingwarrant: result.trending_warrants });
        },
        (error) => {
          // this.setState({ quickheadlinedata });
        }
      );
  }
  getData(data, fieldName) {
    if (data !== null) {
      if (fieldName in data) {
        return data[fieldName];
      }
    }
    return "";
  }
  getCallPutData(underlyingname, isCALL = true) {
    if (this.state.trendingwarrant !== null) {
      if (underlyingname in this.state.trendingwarrant) {
        const callputdata = this.state.trendingwarrant[underlyingname];
        if (isCALL && "CALL" in callputdata) {
          return callputdata["CALL"];
        } else if (!isCALL && "PUT" in callputdata) {
          return callputdata["PUT"];
        }
      }
    }
    return [];
  }
  getRowSubTable(rowdata) {
    const ticker = this.getData(rowdata, "ticker");
    const dsplyname = this.getData(rowdata, "dsply_name");
    const bid = this.getData(rowdata, "BID");
    const bidpctchng = this.getData(rowdata, "BID_PCTCHNG");
    let bidpctchngString = bidpctchng + "%";
    let bidpctchngupdown = "down";
    if (Number(bidpctchng) > 0) {
      bidpctchngString = "+" + bidpctchng + "%";
      bidpctchngupdown = "up";
    }
    const strike = this.getData(rowdata, "exercise_price");
    const expiry = this.getData(rowdata, "maturity");
    const eff = this.getData(rowdata, "effective_gearing");

    const row1 = (
      <tr>
        <td colSpan="2" className="tw_dsply_name">
          {dsplyname}
        </td>
      </tr>
    );
    const row2 = (
      <tr>
        <td style={{ width: "40%" }}>Price:</td>
        <td style={{ width: "60%" }}>
          {" "}
          $<span className="tw_BID">{bid}</span>&nbsp; (
          <span className={"tw_BID_PCTCHNG " + bidpctchngupdown}>
            {bidpctchngString}
          </span>
          )
        </td>
      </tr>
    );
    const row3 = (
      <tr>
        <td style={{ width: "40%" }}>Strike:</td>
        <td className="tw_strike" style={{ width: "60%" }}>
          {strike}
        </td>
      </tr>
    );
    const row4 = (
      <tr>
        <td style={{ width: "40%" }}>Expiry:</td>
        <td className="tw_expiry" style={{ width: "60%" }}>
          {expiry}
        </td>
      </tr>
    );
    const row5 = (
      <tr>
        <td style={{ width: "40%" }}>Eff gearing:</td>
        <td className="tw_eff" style={{ width: "60%" }}>
          {eff}
        </td>
      </tr>
    );
    const row6 = (
      <tr>
        <td colSpan="2">
          <div className="vmbtn-link" style={{ justifyContent: "normal" }}>
            <Link
              className="lmbtn"
              to={"/tools/livematrix/" + ticker}
              target="_blank"
            >
              Live
              <br />
              Matrix
            </Link>
            <a
              className="wtbtn"
              href={"/tools/warrantterms/" + ticker}
              style={{ marginLeft: "5px" }}
              target="_blank"
            >
              Warrant
              <br />
              Terms
            </a>
          </div>
        </td>
      </tr>
    );
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          {row1}
          {row2}
          {row3}
          {row4}
          {row5}
          {row6}
        </tbody>
      </table>
    );
  }

  getRow(rowdata, index, isCALL = true) {
    let putcall = "PUT";
    let className = "put_tmp";
    if (isCALL) {
      putcall = "CALL";
      className = "call_tmp";
    }
    const ticker = this.getData(rowdata, "ticker");

    return (
      <tr className={className, 'homeTopMversTable'} idx={index} key={putcall + "_" + index}>
        <td>
          <div className="tw_warrant">
            <div colSpan="2" className="tw_title" style={{ marginTop: '10px' }}>
              <div className="tw_type">{putcall}</div>
              <div className="tw_ticker">{ticker}</div>
            </div>
            {this.getRowSubTable(rowdata)}
          </div>
        </td>
      </tr>
    );
  }
  render() {
    const calldata = this.getCallPutData("HANG SENG TECH INDEX");
    const callrows = [];
    calldata.forEach((rowdata, index) => {
      const row = this.getRow(rowdata, index);
      callrows.push(row);
    });
    const putdata = this.getCallPutData("HANG SENG TECH INDEX", false);
    const putrows = [];
    putdata.forEach((rowdata, index) => {
      const row = this.getRow(rowdata, index, false);
      putrows.push(row);
    });
    let callTableStyle = { display: "none" };
    let putTableStyle = { display: "none" };
    let noCallPutStyle = {
      display: "none",
      marginLeft: "-10px",
      marginRight: "-10px",
      backgroundColor: "rgb(245, 246, 246)",
    };
    let titleStyle = { display: "none" };
    if (callrows.length > 0) {
      callTableStyle = {};
    }
    if (putrows.length > 0) {
      putTableStyle = {};
    }
    if (callrows.length === 0 && putrows.length === 0) {
      noCallPutStyle = {
        // margin: '0 -10px 0px -10px',
        // marginLeft: "-10px",
        // marginRight: "-10px",
        backgroundColor: "rgb(245, 246, 246)",
      };
    }
    if (
      this.props.type === "HK_stocks" ||
      this.props.underlyingname === "S&P500"
    ) {
      titleStyle = { display: "inline-block" };
    }
    return (
      <div id="hstech" className="pageobj">
        <div className="hsh-welcometop">
          <div className="hsh-video-wrapper">
            <div className="hsh-welcomeimgtop hidden-xs hidden-sm">
              <img src="/img/hstech/hero-image-v2.png" />
            </div>
            <div className="hsh-welcomeimgtop2 visible-sm">
              <img src="/img/hstech/mobile-hero-image-v2.png" />
            </div>
            <div className="hsh-welcomeimg visible-xs">
              <img src="/img/hstech/mobile-hero-image-v2.png" />
            </div>
          </div>
        </div>
        {/* xs-nav */}
        <NavBar />
        {/* 内容 */}
        <div className="container-fluid">
          <div className="row hestch-container">
            <div className="col-md-7 htc-main-left p-0">
              {/*  1  */}
              <div className="mlarea-index" style={{ marginBottom: "20px" }}>
                <div>
                  <h2 style={{ fontWeight: "600" }}>
                    Trade the future of the new economy with the Hang Seng TECH
                    Index
                  </h2>
                </div>
                <hr className="hstech-hr" />
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  Singapore investors can now access leveraged returns on moves
                  in the Hang Seng TECH (HSTECH) index by trading structured
                  warrants on the SGX! Learn more about the HSTECH index, a
                  technology focussed index that outperformed the HSI by 79% and
                  the STI by 85% in 2020, and how to capture trading
                  opportunities with warrants.{" "}
                </div>
              </div>
              {/*  2 */}
              <div
                id="section1"
                style={{ position: "relative", top: "-170px" }}
              ></div>
              <div className="mlarea-one">
                <div>
                  <img
                    src="/img/hstech/sflag_hk.png"
                    style={{
                      width: "50px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                    className="hidden-md hidden-xs"
                  />
                  <h2 style={{ fontWeight: "600" }}>What is HSTECH?</h2>
                </div>
                <hr
                  style={{
                    border: "1px solid #FECF19",
                    width: "75px",
                    marginLeft: "15%",
                    marginBottom: "0",
                    marginTop: "10px",
                  }}
                />
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  Launched in 2020, the HSTECH index comprises the top 30
                  companies listed on the Hong Kong Stock Exchange which have
                  high exposure in selected technology themes. Notable
                  constituents include well-known names such as Alibaba,
                  Tencent, Meituan and Xiaomi (commonly referred to as ‘ATMX’)
                  which have a combined weight of <strong>more than 30%</strong>{" "}
                  and contribute an average of <strong>18%</strong> to the Hang
                  Seng Index’s (HSI) daily turnover.{" "}
                </div>
                <div className="hestch-text">
                  More information on the HSTECH index constituents can be found{" "}
                  <a
                    href="https://www.hsi.com.hk/eng/indexes/all-indexes/hstech"
                    target="_blank"
                  >
                    here
                  </a>
                </div>
              </div>
              {/* 3  */}
              <div
                id="section2"
                style={{ position: "relative", top: "-160px" }}
              ></div>
              <div className="mlarea-two">
                <h2 style={{ fontWeight: "600" }}>Why trade HSTECH?</h2>
                <h3
                  style={{
                    margin: "0",
                    margin: "10px 0 20px 0",
                    letterSpacing: "0.1em",
                    fontSize: "20px",
                  }}
                >
                  Higher volatility in HSTECH,with +94% growth over the past 12
                  months!
                </h3>
                <hr className="hstech-hr" />
                <div className="hestch-text">
                  Technology companies have been top performers globally in
                  recent years, with many technology giants continuing to see
                  unprecedented growth and resilience to economic cycles. The
                  HSTECH index, which has ATMX as heavyweights, outperformed the
                  HSI since its launch in July 2020.
                </div>
                <div className="chart1-box">
                  <img
                    src="/img/hstech/chart1.png"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="hestch-tips">*As of XX December 2020</div>
                <div className="hestch-text">
                  21% The HSTECH index is also a lot more volatile than the HSI,
                  boasting an average volatility of <strong>36%</strong>{" "}
                  compared to the <strong>21%</strong> in the HSI and{" "}
                  <strong>19%</strong> in the STI. This means that the HSTECH
                  index moves in a larger range, the average daily price move
                  over the last 12 months has been 1.84% compared to 1.06% in
                  the HSI and <strong>0.94%</strong> in the STI. This means that
                  the short and medium term trends are likely to be larger and
                  potentially present more trading opportunities for investors.
                  Investors may now capture the volatility of the HSTECH index
                  with structured warrants issued by Macquarie!
                </div>
                <div className="chart2-box">
                  <img
                    src="/img/hstech/chart2.png"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                {/* <div className="hestch-tips">*As of XX December 2020</div> */}
              </div>
              {/* 4 */}
              <div
                id="section3"
                style={{ position: "relative", top: "-160px" }}
              ></div>
              <div className="mlarea-three">
                <div
                  className="mlarea-three-title"
                  style={{ marginBottom: "20px" }}
                >
                  <h2 style={{ fontWeight: "600", wordBreak: 'break-word' }}>
                    Why trade the HSTECH index with warrants?
                  </h2>
                  <hr className="hstech-hr" />
                </div>
                {hstechIndexList.map((item, index) => {
                  return (
                    <div className="mlarea-three-context" key={index}>
                      <div className="context-title">
                        <img
                          src={item.src}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginRight: "20px",
                          }}
                        />
                        <h5>{item.title}</h5>
                      </div>
                      <div className="context-text">{item.context}</div>
                    </div>
                  );
                })}
                <div className="hestch-tips" style={{ paddingLeft: "20px" }}>
                  Source: Bloomberg, Hang Seng Indexes Company Limited, as of{" "}
                  <strong> 24 December 2020</strong> .
                </div>
              </div>
              {/* 5  */}
              <div className="mlarea-index" style={{ marginTop: "20px" }}>
                <div>
                  <h2 style={{ fontWeight: "600" }}>
                    Risk factors of warrants
                  </h2>
                </div>
                <hr className="hstech-hr" />
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  Leverage, also known as gearing works in both ways, meaning
                  one can gain or lose more in trading warrants than investing
                  directly in the underlying asset.
                </div>
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  As with all investment products, warrants are subject to price
                  movements in the underlying asset and other prevailing market
                  fluctuations or circumstances. An investor may loss the full
                  amount invested in the warrant.
                </div>
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  If held overnight, warrants are subjected to holding costs
                  which increases the longer the holding period is. Manage
                  holding costs by using the warrant calculator.
                </div>
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  Warrants with foreign underlying assets are subject to foreign
                  exchange risk. The warrants trade and settle in SGD, and the
                  value of the warrants will change in response to movements in
                  the FX rate, as well as the underlying asset.
                </div>
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  Given the HSTECH index has exposure only to technology
                  companies, the performance of the index may not correlate with
                  the performance of other indices that track the market
                  generally or track other sectors or asset classes. This may
                  affect the performance of the Warrants as compared to other
                  warrants that are linked to other indices. The HSTECH warrants
                  may experience increased price volatility of technology stocks
                  and may be more susceptible to economic, market, political or
                  regulatory occurrences affecting technology stocks than other
                  warrants over indices that do not concentrate in the
                  technology sector.
                </div>
              </div>
            </div>
            <div className="col-md-5 htc-main-right p-0">
              <div
                id="section4"
                style={{ position: "relative", top: "-160px" }}
              ></div>
              <Carousel />
              {/* <!-- 1 --> */}
              <div
                id="section5"
                style={{ position: "relative", top: "-160px" }}
              ></div>
              <div className="mrarea-two">
                <h2 style={{ fontWeight: "600" }}>How to get started?</h2>
                <hr className="hstech-hr" />
                <div className="hestch-text" style={{ fontSize: "18px" }}>
                  Trade Macquarie’s HSTECH warrants on the SGX just like how you
                  trade shares! Simply login to your local brokerage account to
                  start trading. You may now view the live HSTECH futures level
                  on <a href="/home#LiveIndexFutures">our homepage</a>!
                </div>
                <div className="hestch-text">
                  The market-making hours for the HSTECH warrants will be from{" "}
                  <strong>9.15am to 12pm</strong>, then{" "}
                  <strong>1pm to 4.30pm</strong>.
                </div>
                <div className="hestch-tips" style={{ marginTop: "10px" }}>
                  *Note to check with your broker if you are eligible to trade
                  Specified Investment Products (SIP)
                </div>
                {/* <!-- 2 icon 1-3 --> */}
                <div
                  className="row rstart-icon-box"
                  style={{ marginTop: "20px" }}
                >
                  <div className="col-xs-4">
                    <div className="icon-pointer">
                      <span>1</span>
                      <div className="top-line"></div>
                    </div>
                    <div className="img-pointer" align="center">
                      <img src="/img/hstech/step1.png" />
                      <div className="icon-text">Knowledge</div>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="icon-pointer">
                      <span>2</span>
                      <div className="top-line"></div>
                    </div>
                    <div className="img-pointer" align="center">
                      <img src="/img/hstech/step2.png" />
                      <div className="icon-text">Brokerage Account</div>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="icon-pointer">
                      <span>3</span>
                      <div className="top-line"></div>
                    </div>
                    <div className="img-pointer" align="center">
                      <img src="/img/hstech/step3.png" />
                      <div className="icon-text">SIP requirements</div>
                    </div>
                  </div>
                </div>
                {/* <!-- 2 icon 4-6 --> */}
                <div
                  className="row rstart-icon-box"
                  style={{ marginTop: "20px" }}
                >
                  <div className="col-xs-4">
                    <div className="icon-pointer">
                      <span>4</span>
                      <div className="top-line"></div>
                    </div>
                    <div className="img-pointer" align="center">
                      <img src="/img/hstech/step4.png" />
                      <div className="icon-text">Selecting a warrant</div>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="icon-pointer">
                      <span>5</span>
                      <div className="top-line"></div>
                    </div>
                    <div className="img-pointer" align="center">
                      <img src="/img/hstech/step5.png" />
                      <div className="icon-text">
                        How to buy or sell warrants
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="icon-pointer">
                      <span>6</span>
                      <div className="top-line"></div>
                    </div>
                    <div className="img-pointer" align="center">
                      <img src="/img/hstech/step6.png" />
                      <div className="icon-text">Constant support</div>
                    </div>
                  </div>
                </div>
                {/* <!-- learn more --> */}
                <div
                  className="hstechbtn-area"
                  style={{ width: "120px", height: "50px" }}
                >
                  <div className="learnbtn bgcolor-06">
                    <a href="/education/getstarted">Learn More</a>
                  </div>
                </div>
              </div>
              <div className="mlarea-index" style={{ marginTop: "20px" }}>
                <div>
                  <h2 style={{ fontWeight: "600" }}>
                    Trending HSTECH warrants
                  </h2>
                </div>
                <hr className="hstech-hr" />
                <div className="row tw_table" style={{ overflowY: 'auto', marginRight: '-10px', marginLeft: '-10px' }}>
                  <div className="call_table col-xs-6" style={callTableStyle}>
                    <table className="tw_call_table" style={{ width: "100%" }}>
                      <tbody>{callrows}</tbody>
                    </table>
                    <table className="tmp_table" style={{ display: "none" }}>
                      <tbody>{/* insert point */}</tbody>
                    </table>
                  </div>
                  <div className="put_table col-xs-6" style={putTableStyle}>
                    <table className="tw_put_table" style={{ width: "100%" }}>
                      <tbody>{putrows}</tbody>
                    </table>
                    <table className="tmp_table" style={{ display: "none" }}>
                      <tbody></tbody>
                    </table>
                  </div>
                  <p className="notw" style={noCallPutStyle}>
                    No warrants available
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          style={{
            paddingLeft: "20px",
            paddingTop: "10px",
            maxWidth: "1200px",
            margin: "0 auto",
            fontSize: "12px",
          }}
        >
          * All information on this page is based on data sourced from
          Bloomberg, Hang Seng Indexes Company Limited, as of 31 January 2021,
          which includes back-tested data for the period before the HSTECH
          index's launch date of 27 July 2020.
        </p>
      </div>
    );
  }
}

class NavBar extends Component {
  componentDidMount() {
    window.addEventListener(
      "scroll",
      e => {
        var cls = "container-fluid hsh-xsnav hidden-lg"
        var dom = document.querySelector("#hshnav-bar");
        if (dom) {
          if (window.scrollY >= 254) {
            if (dom.className) {
              dom.className = cls + ' ' + 'fixed'
            }
          } else {
            if (dom.className) {
              dom.className = cls;
            }
          }
        }
      },
      false
    );
  }
  navTo(val) {
    if (val == 1) {
      window.scrollTo(0, 350)
    } else if (val == 2) {
      window.scrollTo(0, 700)
    } else if (val == 3) {
      window.scrollTo(0, 1650)
    } else if (val == 4) {
      window.scrollTo(0, 350)
    } else {
      window.scrollTo(0, 750)
    }

  }
  render() {
    return (
      <div id="hshnav-bar" className="container-fluid hsh-xsnav hidden-lg">
        <div style={{ background: "#fff" }}>
          <div className="row">
            <div
              className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-what p-0"
              align="center"
              onClick={() => { this.navTo(1) }}
            >
              <div className="hsh-navimg"></div>
              <div className="hsh-navtxt">What is the HSTECH?</div>
            </div>
            <div
              className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-grow p-0"
              onClick={() => { this.navTo(2) }}
            >
              <div className="hsh-navimg"></div>
              <div className="hsh-navtxt">HSTECH growth</div>
            </div>
            <div
              className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-why p-0"
              onClick={() => { this.navTo(3) }}
            >
              <div className="hsh-navimg"></div>
              <div className="hsh-navtxt">Why trade HSTECH Index warrants?</div>
            </div>
            <div
              className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-webinars p-0"
              onClick={() => { this.navTo(4) }}
            >
              <div className="hsh-navimg"></div>
              <div className="hsh-navtxt">HSTECH Webinars</div>
            </div>
            <div
              className="col-xs-5ths hsh-xsnav-box hsh-xsnav-box-how p-0"
              onClick={() => { this.navTo(5) }}
            >
              <div className="hsh-navimg"></div>
              <div className="hsh-navtxt">How to get started?</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Carousel extends Component {
  constructor() {
    super();
    this.state = {
      signupShow: false,
      subscribeShow: false,
    };
  }
  onSignupChange() {
    this.setState({ signupShow: false });
  }
  onSignupClick() {
    this.setState({ signupShow: true });
  }
  onSubscribeChange() {
    this.setState({ subscribeShow: false });
  }
  onSubscribeClick() {
    this.setState({ subscribeShow: true });
  }
  render() {
    return (
      <div
        id="carousel-example-generic"
        className="mrarea-one carousel slide center-block"
      >
        {/*  Wrapper for slides */}
        {/* className="carousel-inner" */}
        <div role="listbox" style={{ padding: '1px 0px' }}>
          {/* 1 */}
          {/* <div className="item active carousel-box">
            <div className="carousel-caption">
              <div
                style={{
                  marginTop: "20px",
                  color: "#FECC09",
                  fontSize: "30px",
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
              >
                Hong Kong Market Outlook + Warrants
              </div>
              <div style={{ marginTop: "10px", fontSize: "23px" }}>
                <strong>DATE:</strong> 24 February 2021
              </div>
              <div style={{ marginTop: "10px", fontSize: "23px" }}>
                <strong>TIME:</strong> 7.00-8.00pm
              </div>
              <div style={{ marginTop: "20px" }}>
                <ul>
                  <li>Hong Kong Market Outlook</li>
                  <li>Technical Analysis of Hang Seng Index</li>
                  <li>Things You Must Watch Out for in Trading</li>
                  <li>How to Select Warrants</li>
                  <li>How to Estimate Your Potential Returns</li>
                </ul>
              </div>
              <div className="hstechbtn-area" style={{ marginTop: "50px" }}>
                <div
                  className="signupbtn bgcolor-06"
                  onClick={this.onSignupClick.bind(this)}
                >
                  Sign up
                </div>
              </div>
            </div>
          </div> */}
          {/* 2 */}
          <div className="item carousel-box">
            <div className="carousel-caption">
              <div
                style={{
                  marginTop: "20px",
                  color: "#FECC09",
                  fontSize: "36px",
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
              >
                Stay tuned for more HSTECH webinars available soon!
              </div>
              <div style={{ marginTop: "10px", fontSize: "24px" }}>
                Sign up for our newsletter to receive invites!
              </div>
              <div className="hstechbtn-area" style={{ marginTop: "50px" }}>
                <div
                  className="subscribebtn bgcolor-06"
                  onClick={this.onSubscribeClick.bind(this)}
                >
                  Subscribe
                </div>
              </div>
            </div>
          </div>
          {/* 3 */}
          {/* <div className="item carousel-box">
            <div className="carousel-caption">
              <div
                style={{
                  marginTop: "20px",
                  color: "#FECC09",
                  fontSize: "30px",
                  fontWeight: "600",
                  letterSpacing: "0.1em",
                }}
              >
                Mastering the 3 key tools in warrant trading
              </div>
              <div style={{ marginTop: "10px", fontSize: "23px" }}>
                <strong>DATE:</strong> 4 March 2021
              </div>
              <div style={{ marginTop: "10px", fontSize: "23px" }}>
                <strong>TIME:</strong> 6.30-7.30pm
              </div>
              <div style={{ marginTop: "20px" }}>
                Description of Webinar A
                <ul>
                  <li>Recognise the key features of warrants</li>
                  <li>Identify opportunities to trade with warrants</li>
                  <li>Matching an investor's profile to warrant type</li>
                  <li>Access to free warrant tools</li>
                  <li>Selecting warrants to trade LIVE!</li>
                </ul>
              </div>
              <div className="hstechbtn-area" style={{ marginTop: "50px" }}>
                <div
                  className="signupbtn bgcolor-06"
                  onClick={this.onSignupClick.bind(this)}
                >
                  Sign up
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <a
          className="left carousel-control"
          href="#carousel-example-generic"
          role="button"
          data-slide="prev"
        >
          <span
            className="glyphicon glyphicon-chevron-left"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control active"
          href="#carousel-example-generic"
          role="button"
          data-slide="next"
        >
          <span
            className="glyphicon glyphicon-chevron-right"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
        <SignupArea
          show={this.state.signupShow}
          onSignupChange={this.onSignupChange.bind(this)}
        />
        <SubscribeArea
          show={this.state.subscribeShow}
          onSubscribeChange={this.onSubscribeChange.bind(this)}
        />
      </div>
    );
  }
}

class SignupArea extends Component {
  onSignupChange(isContinue) {
    this.props.onSignupChange(isContinue);
  }
  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    return (
      <div id="hshbook_area" className="hsh-overlay" style={isShow}>
        <div className="hsh-overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <img
                className="btn-close"
                style={{ top: "40px", right: "40px" }}
                src="/img/bullet/icon-close-white.png"
                onClick={this.onSignupChange.bind(this, false)}
              />
              <div className="page-header">
                <h1>Sign up now</h1>
              </div>
              <hr className="hstech-hr" style={{ marginBottom: "40px" }} />
              <input
                type="text"
                name="Name"
                placeholder="Name"
                key="label.name"
              />
              <div className="warning"></div>
              <input
                type="text"
                name="Email"
                placeholder="Email"
                key="label.email"
              />
              <div className="warning"></div>
              <p className="small">
                "By clicking "Submit", you confirm that you would like to
                subscribe to Macquarie's daily warrant newsletter. By
                subscribing, you will also automatically receive invitations or
                marketing material from Macquarie for warrant seminars and other
                warrant related initiatives. Macquarie website administrators
                may have access to your personal data for the processing of such
                data on behalf of Macquarie Capital Securities (Singapore) Pte
                Ltd and other companies of Macquarie Group ("Macquarie")."
              </p>
              <input
                type="button"
                id="submitBtn"
                value="Agree and submit"
                key="label.agreesubmit"
              />
            </div>
          </div>
        </div>
        <div id="text-content" style={{ display: "none" }}>
          <div className="text_subscribe_registration_success">
            You have successfully registered for our daily newsletter!
          </div>
        </div>
      </div>
    );
  }
}

class SubscribeArea extends Component {
  onSubscribeChange(isContinue) {
    this.props.onSubscribeChange(isContinue);
  }
  render() {
    const isShow = this.props.show ? {} : { display: "none" };
    return (
      <div id="hshSubscribe_area" className="hsh-overlay" style={isShow}>
        <div className="hsh-overlay-inner">
          <div style={{ position: "relative" }}>
            <div className="popupbox-content">
              <img
                className="btn-close"
                style={{ top: "40px", right: "40px" }}
                src="/img/bullet/icon-close-white.png"
                onClick={this.onSubscribeChange.bind(this, false)}
              />
              <div className="page-header">
                <h1>Subscribe to our newsletter</h1>
              </div>
              <hr className="hstech-hr" style={{ marginBottom: "40px" }} />
              <input
                type="text"
                name="Name"
                placeholder="Name"
                key="label.name"
              />
              <div className="warning"></div>
              <input
                type="text"
                name="Email"
                placeholder="Email"
                key="label.email"
              />
              <div className="warning"></div>
              <p className="small">
                "By clicking ”Submit”, you confirm that you would like to
                subscribe to Macquarie’s daily warrant newsletter. By
                subscribing, you will also automatically receive invitations or
                marketing material from Macquarie for warrant seminars and other
                warrant related initiatives. Macquarie website administrators
                may have access to your personal data for the processing of such
                data on behalf of Macquarie Capital Securities (Singapore) Pte
                Ltd and other companies of Macquarie Group (“Macquarie”).
              </p>
              <p className="small popup-a">
                You may view Macquarie’s{" "}
                <a target="_blank" href="/about/privacypolicy">
                  privacy policy
                </a>{" "}
                with regard to collection and management of personal data via
                the links at the bottom of this page.
              </p>
              <input
                type="button"
                id="submitBtn"
                value="Agree and submit"
                key="label.agreesubmit"
              />
            </div>
          </div>
        </div>
        <div id="text-content" style={{ display: "none" }}>
          <div className="text_subscribe_registration_success">
            You have successfully registered for our daily newsletter!
          </div>
        </div>
      </div>
    );
  }
}

const hstechIndexList = [
  {
    src: "/img/hstech/selling-point1.png",
    title: "Leverage your returns on the HSTECH index",
    context:
      "Macquarie has listed a suite of warrants that allow investors to select the amount of leverage that best suits their view of short to medium term outlook. Investors can achieve magnified potential returns on market moves in the HSTECH index that leads major benchmark indices with an average volatility of 37% and +94% growth over the past 12 months.",
  },
  {
    src: "/img/hstech/selling-point2.png",
    title: "Access to the leading tech giants from Singapore",
    context:
      "Investors can trade the HSTECH index using warrants from their existing stock trading account on the SGX in SGD.",
  },
  {
    src: "/img/hstech/selling-point3.png",
    title: "Broad coverage of technology sector",
    context:
      "Of the 30 HSTECH index constituents, the popular ATMX stocks account for over 30% of the index. Investors can gain exposure to these heavyweights as well as a diverse spectrum of themes within the tech sector including internet, fintech, cloud, e-commerce and digital while minimizing any single stock specific risk.",
  },
  {
    src: "/img/hstech/selling-point4.png",
    title: "Opportunity to profit from rises and falls",
    context:
      "Macquarie’s call and put warrants over the HSTECH allow investors to trade on a bullish or bearish view, providing increased trading opportunities especially when coupled with the HSTECH’s higher volatility",
  },
  {
    src: "/img/hstech/selling-point5.png",
    title: "Trade with confidence of the Live Price Matrix",
    context:
      "Investors can use the Live Price Matrix to see how the warrant price will move for every change in the underlying index futures price and determine their entry and exit points",
  },
];
