
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";

export default class fourviewonunderlyingshareorindex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>When choosing a warrant, we suggest that investors consider the following 4 steps:</p>
                                            <div className="imgholder hidden-xs">
                                                <img src="/img/education/fourstep1.jpg" style={{ maxWidth: 600 }} />
                                            </div>
                                            <div className="imgholder visible-xs">
                                                <img src="/img/education/fourstep1_m.jpg" style={{ maxWidth: 400 }} />
                                            </div>
                                            <div className="imgholder"><img style={{ maxWidth: 600 }} src="/img/education/1201-The-Four-Steps-To-Selecting-A-Warrant_enlarge.jpg" />
                                            </div>
                                            <h2>View on underlying stock or index</h2>
                                            <p>
                                                To choose a warrant you should first look at the nature and performance of the underlying shares and take a view. If you think the share price is going to go up, buy a call warrant. If you are bearish, buy a put warrant.<br /><br />                                In this first step, you also need to have a target price for the stock in mind. This will help you to narrow your selection of the warrants.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/What-Is-Your-View-On-The-Underlying-Asset-.jpg" alt="What is your view on the underlying asset" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/What-Is-Your-View-On-The-Underlying-Asset-.jpg')}>View chart</div>
                                            </div>
                                            <p>Most investors will choose a warrant where the exercise price is close to that of the underlying asset. So, as an example, if you think a particular stock will rise from $9 to say $10, you’ll probably buy a warrant with an
                                                exercise price of between $9 and $11.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}