
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class indexcallwarrantexample extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <h2>EXAMPLE OF A HSI CALL WARRANT EXPIRING IN MAY</h2>
                                            <div className="exampleholder">
                                                <div className="examplebox">
                                                    <div className="col">
                                                        <div className="lbl">Exercise Level:&nbsp;&nbsp;</div>
                                                        <div className="val" >20,000</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="lbl">Warrants per share :&nbsp;&nbsp;</div>
                                                        <div className="val" >1,000</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="lbl">Settlement value of HSI May futures:&nbsp;&nbsp;</div>
                                                        <div className="val" >21,000</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="lbl">FX Rate:&nbsp;&nbsp;</div>
                                                        <div className="val" >0.16 (SGD per HKD)</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="formulaholder">
                                                <div className="formulabox">
                                                    <div className="leqt">
                                                        <table cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 39 }}>
                                                                        <b>Cash Settlement</b>:
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="reqt">
                                                        <table className="fraction" cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>(21,000 - 20,000)</td>
                                                                    <td rowSpan={2}>&nbsp;=&nbsp;HKD1.00 </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="upper_line">1,000</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="reqt" style={{ display: 'block' }}>
                                                        <table cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 39 }}> HKD1.00x0.16 (FX Rate) = SGD0.16</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                Since the settlement value of the HSI May futures of 21,000 is higher than the 20,000 exercise level of the call warrant, the warrant will have value at expiry. To work out the exact amount, subtract 20,000 from 21,000 and divide this by the warrants
                                                per share of 1000. You will arrive at 1 Hong Kong dollar. To convert this to Singapore dollars, you have to multiply the figure by the Hong Kong dollar/Singapore dollar exchange rate of say 0.160. You will then arrive at
                                                a cash settlement amount of SGD$0.16, which you will receive given that the warrant had expired in-the-money.
                                            </p>
                                            <p>
                                                There would be no cash settlement if the HSI may futures final settlement level is at the warrant’s exercise level of 20,000 or lower.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}