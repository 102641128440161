const AWX = {
"comparison": [{
        "BID": "3.99",
        "30D_HIGH": 4.220000000,
        "TRDPRC_1": "3.97",
        "SYMBOL": "AEM.SI",
        "ASK": "3.99",
        "30D_LOW": 3.230000000,
        "ticker": "AWX",
        "NETCHNG_1": "-0.01",
        "HST_VOL": 5979500.000000000,
        "VOL_PCTCHNG": 25.4937268877052,
        "NO_BIDMKR2": 4764780.000000000,
        "ASKSIZE": "117,000",
        "SERVICE": "ELEKTRON_DD",
        "OFFCL_NAME": "AEM HOLDINGS LTD",
        "TURNOVER": 18933783.000000000,
        "REC_STATUS": "0",
        "PCTCHNG": "-0.25",
        "BIDSIZE": "90,400",
        "YRLOW": 1.310000000,
        "VOL_RATIO": 1.254937269,
        "ACVOL_1": "4,772,600",
        "update_time": "29 Jan 21 16:57",
        "ric": "AEM.SI",
        "OFFCL_CODE": "AWX",
        "dsply_name": "AEM HOLDINGS LTD",
        "YRHIGH": 4.350000000,
        "HST_CLOSE": "3.99",
        "ACVOL_1_SCALED": "4,773"
    },{
        "underlying_ric": "AEM.SI",
        "b_trbc_business": null,
        "theta": "-2.84",
        "TRDPRC_1": "0.019",
        "percent_moneyness": "12",
        "last_trading_date": "23 Feb 21",
        "HIGH_1": "0.068",
        "type": "CALL",
        "effective_gearing": "7.1",
        "conv_ratio": "10.0",
        "wrnt_per_share": "0.100",
        "BID_PCTCHNG_2DP": "-5.00",
        "LOW_1": "0.011",
        "breakeven_price": "4.69",
        "ticker": "983W",
        "30D_LOW": "-",
        "underlying_pctchng": "+1.75",
        "delta_per_wrnt": "3.409",
        "warrant_price": 0.019000000,
        "OPEN_PRC": "0.049",
        "TURNOVER_SCALED": "20",
        "NO_BIDMKR2": "-",
        "dsply_nmll": "AEM MB eCW210302",
        "sensitivity": "2.93",
        "issuer_name": "Macquarie Bank Ltd",
        "delta": "34.1",
        "TRADE_DATE": "29 Jan 21",
        "NO_BIDMKR2_SCALED": "-",
        "underlying_price_adj": 3.980000000,
        "BID_PCTCHNG": "-5.0",
        "BIDSIZE": "1,740,000",
        "underlying_price_cal": 3.980000000,
        "exercise_price_adj": 4.500000000,
        "update_time": "29 Jan 21 16:57",
        "dsply_name": "AEM MB eCW210302",
        "YRHIGH": "-",
        "HST_CLOSE": "0.020",
        "ACVOL_1_SCALED": "1,030",
        "BID": "0.019",
        "r_hst_close": 1,
        "maturity": "02 Mar 21",
        "div_yield": 2.091346000,
        "underlying_bid": 3.980000000,
        "fmt": 1,
        "30D_HIGH": "-",
        "link": true,
        "HST_VOL_SCALED": "-",
        "underlying_price": "3.98",
        "list_date": "24 Sep 20",
        "exchange_code": "SES",
        "underlying_curr": "SGD",
        "currency": "SGD",
        "ASK": "0.020",
        "conv_ratio_cal": 10.000000000,
        "NETCHNG_1": "-",
        "BID_NETCHNG": "-0.001",
        "HST_VOL": "-",
        "implied_volatility": "80.6",
        "VOL_PCTCHNG": "-",
        "moneyness": "2",
        "ASKSIZE": "1,350,000",
        "premium": "17.8",
        "intrinsic_value": "0.000",
        "underlying_ticker": "AWX",
        "issuer_code": "MBL",
        "exercise_price_cal": 4.500000000,
        "TURNOVER": "19,540",
        "underlying_name": "AEM HOLDINGS LTD",
        "PCTCHNG": "-",
        "YRLOW": "-",
        "ACVOL_1": "1,030,000",
        "days_to_maturity": "32",
        "ric": "AEML_tk.SI",
        "exercise_price": "4.50"
    },{
        "underlying_ric": "AEM.SI",
        "b_trbc_business": null,
        "theta": "-0.22",
        "TRDPRC_1": "0.093",
        "percent_moneyness": "5",
        "last_trading_date": "10 Sep 21",
        "HIGH_1": "0.097",
        "type": "CALL",
        "effective_gearing": "2.4",
        "conv_ratio": "12.0",
        "wrnt_per_share": "0.083",
        "BID_PCTCHNG_2DP": "-1.20",
        "LOW_1": "0.059",
        "breakeven_price": "5.18",
        "ticker": "VKEW",
        "30D_LOW": "-",
        "underlying_pctchng": "+1.75",
        "delta_per_wrnt": "4.963",
        "warrant_price": 0.082000000,
        "OPEN_PRC": "0.068",
        "TURNOVER_SCALED": "0",
        "NO_BIDMKR2": "-",
        "dsply_nmll": "AEM MB eCW210917",
        "sensitivity": "2.01",
        "issuer_name": "Macquarie Bank Ltd",
        "delta": "59.6",
        "TRADE_DATE": "21 Jan 21",
        "NO_BIDMKR2_SCALED": "-",
        "underlying_price_adj": 3.980000000,
        "BID_PCTCHNG": "-1.2",
        "BIDSIZE": "500,000",
        "underlying_price_cal": 3.980000000,
        "exercise_price_adj": 4.200000000,
        "update_time": "29 Jan 21 16:57",
        "dsply_name": "AEM MB eCW210917",
        "YRHIGH": "-",
        "HST_CLOSE": "0.083",
        "ACVOL_1_SCALED": "0",
        "BID": "0.082",
        "r_hst_close": 1,
        "maturity": "17 Sep 21",
        "div_yield": 2.091346000,
        "underlying_bid": 3.980000000,
        "fmt": 1,
        "30D_HIGH": "-",
        "link": true,
        "HST_VOL_SCALED": "-",
        "underlying_price": "3.98",
        "list_date": "17 Dec 20",
        "exchange_code": "SES",
        "underlying_curr": "SGD",
        "currency": "SGD",
        "ASK": "0.084",
        "conv_ratio_cal": 12.000000048,
        "NETCHNG_1": "-",
        "BID_NETCHNG": "-0.001",
        "HST_VOL": "-",
        "implied_volatility": "87.7",
        "VOL_PCTCHNG": "-",
        "moneyness": "2",
        "ASKSIZE": "500,000",
        "premium": "30.3",
        "intrinsic_value": "0.000",
        "underlying_ticker": "AWX",
        "issuer_code": "MBL",
        "exercise_price_cal": 4.200000000,
        "TURNOVER": "0",
        "underlying_name": "AEM HOLDINGS LTD",
        "PCTCHNG": "-",
        "YRLOW": "-",
        "ACVOL_1": "0",
        "days_to_maturity": "231",
        "ric": "AEML_tm.SI",
        "exercise_price": "4.20"
    }]
}

const BABASW = {
	"comparison": [
		{
			"BID": "260.80",
			"30D_HIGH": null,
			"TRDPRC_1": "261.00",
			"SYMBOL": "9988.HK",
			"ASK": "261.00",
			"30D_LOW": null,
			"ticker": "9988",
			"NETCHNG_1": "-1.00",
			"HST_VOL": null,
			"VOL_PCTCHNG": null,
			"NO_BIDMKR2": null,
			"ASKSIZE": "22,500",
			"SERVICE": "ELEKTRON_DD",
			"OFFCL_NAME": "BABA-SW",
			"TURNOVER": 3527164461.0,
			"REC_STATUS": "0",
			"PCTCHNG": "-0.38",
			"BIDSIZE": "74,400",
			"YRLOW": null,
			"VOL_RATIO": null,
			"ACVOL_1": "13,576,585",
			"update_time": "11 Feb 21 16:54",
			"ric": "9988.HK",
			"OFFCL_CODE": "BABA-SW",
			"dsply_name": "BABA-SW",
			"YRHIGH": null,
			"HST_CLOSE": "262.00",
			"ACVOL_1_SCALED": "13,577"
		},
		{
			"underlying_ric": "9988.HK",
			"b_trbc_business": null,
			"theta": "-0.94",
			"LOLIMIT": "0",
			"TRDPRC_1": "0.013",
			"percent_moneyness": "25",
			"last_trading_date": "26 Mar 21",
			"HIGH_1": "0.149",
			"type": "CALL",
			"effective_gearing": "16.8",
			"conv_ratio": "25.0",
			"wrnt_per_share": "0.040",
			"LOW_1": "0.001",
			"BID_PCTCHNG_2DP": "0.00",
			"breakeven_price": "350.58",
			"ticker": "1M7W",
			"30D_LOW": "-",
			"underlying_pctchng": "-0.38",
			"delta_per_wrnt": "N/A",
			"warrant_price": 0.004,
			"OPEN_PRC": "0.124",
			"TURNOVER_SCALED": "0",
			"NO_BIDMKR2": "-",
			"dsply_nmll": "AlibabaMBeCW210405",
			"issuer_name": "Macquarie Bank Ltd",
			"sensitivity": "66.31",
			"delta": "3.8",
			"TRADE_DATE": "20 Jan 21",
			"NO_BIDMKR2_SCALED": "-",
			"underlying_price_adj": 44.6571,
			"BID_PCTCHNG": "0.0",
			"BIDSIZE": "0",
			"underlying_price_cal": 261.0,
			"exercise_price_adj": 59.885,
			"update_time": "11 Feb 21 15:57",
			"dsply_name": "AlibabaMBeCW210405",
			"YRHIGH": "-",
			"BEST_BSIZ1": "0",
			"HST_CLOSE": "0.004",
			"ACVOL_1_SCALED": "0",
			"BID": "0.004",
			"r_hst_close": 0.1711,
			"maturity": "05 Apr 21",
			"div_yield": 0.0,
			"underlying_bid": 260.8,
			"fmt": 1,
			"30D_HIGH": "-",
			"link": true,
			"SYMBOL": "ALML_tf.SI",
			"underlying_price": "261.00",
			"HST_VOL_SCALED": "-",
			"list_date": "22 Oct 20",
			"exchange_code": "SES",
			"underlying_curr": "HKD",
			"currency": "SGD",
			"ASK": "0.009",
			"conv_ratio_cal": 25.0,
			"UPLIMIT": "0",
			"NETCHNG_1": "0.0",
			"BID_NETCHNG": "0.000",
			"HST_VOL": "-",
			"implied_volatility": "41.2",
			"VOL_PCTCHNG": "-",
			"TRDTIM_1": "  :",
			"moneyness": "2",
			"ASKSIZE": "0",
			"premium": "34.3",
			"SERVICE": "ELEKTRON_DD",
			"intrinsic_value": "0.000",
			"underlying_ticker": "BABA-SW",
			"issuer_code": "MBL",
			"exercise_price_cal": 350.0,
			"TURNOVER": "0",
			"underlying_name": "BABA-SW",
			"REC_STATUS": "0",
			"PCTCHNG": "0.0",
			"YRLOW": "-",
			"BEST_ASIZ1": "0",
			"ACVOL_1": "0",
			"days_to_maturity": "53",
			"ric": "ALML_tf.SI",
			"exercise_price": "350.00"
		},
		{
			"underlying_ric": "9988.HK",
			"b_trbc_business": null,
			"theta": "-0.08",
			"LOLIMIT": "0",
			"TRDPRC_1": "0.084",
			"percent_moneyness": "9",
			"last_trading_date": "28 Jun 21",
			"HIGH_1": "0.181",
			"type": "PUT",
			"effective_gearing": "4.0",
			"conv_ratio": "45.0",
			"wrnt_per_share": "0.022",
			"LOW_1": "0.073",
			"BID_PCTCHNG_2DP": "+2.44",
			"breakeven_price": "218.43",
			"ticker": "QBDW",
			"30D_LOW": "-",
			"underlying_pctchng": "-0.38",
			"delta_per_wrnt": "-0.732",
			"warrant_price": 0.082,
			"OPEN_PRC": "0.101",
			"TURNOVER_SCALED": "9",
			"NO_BIDMKR2": "-",
			"dsply_nmll": "Alibaba MB ePW210705",
			"issuer_name": "Macquarie Bank Ltd",
			"sensitivity": "13.66",
			"delta": "-32.9",
			"TRADE_DATE": "11 Feb 21",
			"NO_BIDMKR2_SCALED": "-",
			"underlying_price_adj": 44.6571,
			"BID_PCTCHNG": "+2.4",
			"BIDSIZE": "0",
			"underlying_price_cal": 261.0,
			"exercise_price_adj": 41.064,
			"update_time": "11 Feb 21 15:57",
			"dsply_name": "Alibaba MB ePW210705",
			"YRHIGH": "-",
			"BEST_BSIZ1": "0",
			"HST_CLOSE": "0.082",
			"ACVOL_1_SCALED": "100",
			"BID": "0.084",
			"r_hst_close": 0.1711,
			"maturity": "05 Jul 21",
			"div_yield": 0.0,
			"underlying_bid": 260.8,
			"fmt": 1,
			"30D_HIGH": "-",
			"link": true,
			"SYMBOL": "ALML_tg.SI",
			"underlying_price": "261.00",
			"HST_VOL_SCALED": "-",
			"list_date": "17 Dec 20",
			"exchange_code": "SES",
			"underlying_curr": "HKD",
			"currency": "SGD",
			"ASK": "0.085",
			"conv_ratio_cal": 45.00000045,
			"UPLIMIT": "0",
			"NETCHNG_1": "0.0",
			"BID_NETCHNG": "+0.002",
			"HST_VOL": "-",
			"implied_volatility": "50.6",
			"VOL_PCTCHNG": "-",
			"TRDTIM_1": "03:48",
			"moneyness": "2",
			"ASKSIZE": "0",
			"premium": "16.3",
			"SERVICE": "ELEKTRON_DD",
			"intrinsic_value": "0.000",
			"underlying_ticker": "BABA-SW",
			"issuer_code": "MBL",
			"exercise_price_cal": 240.0,
			"TURNOVER": "8,600",
			"underlying_name": "BABA-SW",
			"REC_STATUS": "0",
			"PCTCHNG": "0.0",
			"YRLOW": "-",
			"BEST_ASIZ1": "0",
			"ACVOL_1": "100,000",
			"days_to_maturity": "144",
			"ric": "ALML_tg.SI",
			"exercise_price": "240.00"
		},
		{
			"underlying_ric": "9988.HK",
			"b_trbc_business": null,
			"theta": "-0.10",
			"LOLIMIT": "0",
			"TRDPRC_1": "0.066",
			"percent_moneyness": "13",
			"last_trading_date": "28 Jun 21",
			"HIGH_1": "0.090",
			"type": "CALL",
			"effective_gearing": "5.4",
			"conv_ratio": "45.0",
			"wrnt_per_share": "0.022",
			"LOW_1": "0.021",
			"BID_PCTCHNG_2DP": "-4.23",
			"breakeven_price": "318.67",
			"ticker": "R42W",
			"30D_LOW": "-",
			"underlying_pctchng": "-0.38",
			"delta_per_wrnt": "0.866",
			"warrant_price": 0.071,
			"OPEN_PRC": "0.072",
			"TURNOVER_SCALED": "27",
			"NO_BIDMKR2": "-",
			"dsply_nmll": "Alibaba MB eCW210705",
			"issuer_name": "Macquarie Bank Ltd",
			"sensitivity": "11.55",
			"delta": "39.0",
			"TRADE_DATE": "11 Feb 21",
			"NO_BIDMKR2_SCALED": "-",
			"underlying_price_adj": 44.6571,
			"BID_PCTCHNG": "-4.2",
			"BIDSIZE": "0",
			"underlying_price_cal": 261.0,
			"exercise_price_adj": 51.33,
			"update_time": "11 Feb 21 15:57",
			"dsply_name": "Alibaba MB eCW210705",
			"YRHIGH": "-",
			"BEST_BSIZ1": "0",
			"HST_CLOSE": "0.071",
			"ACVOL_1_SCALED": "400",
			"BID": "0.068",
			"r_hst_close": 0.1711,
			"maturity": "05 Jul 21",
			"div_yield": 0.0,
			"underlying_bid": 260.8,
			"fmt": 1,
			"30D_HIGH": "-",
			"link": true,
			"SYMBOL": "ALML_th.SI",
			"underlying_price": "261.00",
			"HST_VOL_SCALED": "-",
			"list_date": "17 Dec 20",
			"exchange_code": "SES",
			"underlying_curr": "HKD",
			"currency": "SGD",
			"ASK": "0.070",
			"conv_ratio_cal": 45.00000045,
			"UPLIMIT": "0",
			"NETCHNG_1": "-0.0",
			"BID_NETCHNG": "-0.003",
			"HST_VOL": "-",
			"implied_volatility": "48.9",
			"VOL_PCTCHNG": "-",
			"TRDTIM_1": "03:12",
			"moneyness": "2",
			"ASKSIZE": "500",
			"premium": "22.1",
			"SERVICE": "ELEKTRON_DD",
			"intrinsic_value": "0.000",
			"underlying_ticker": "BABA-SW",
			"issuer_code": "MBL",
			"exercise_price_cal": 300.0,
			"TURNOVER": "26,500",
			"underlying_name": "BABA-SW",
			"REC_STATUS": "0",
			"PCTCHNG": "-7.0",
			"YRLOW": "-",
			"BEST_ASIZ1": "500",
			"ACVOL_1": "400,000",
			"days_to_maturity": "144",
			"ric": "ALML_th.SI",
			"exercise_price": "300.00"
		},
		{
			"underlying_ric": "9988.HK",
			"b_trbc_business": null,
			"theta": "-0.21",
			"LOLIMIT": "0",
			"TRDPRC_1": "0.142",
			"percent_moneyness": "4",
			"last_trading_date": "26 Mar 21",
			"HIGH_1": "0.330",
			"type": "PUT",
			"effective_gearing": "7.2",
			"conv_ratio": "25.0",
			"wrnt_per_share": "0.040",
			"LOW_1": "0.086",
			"BID_PCTCHNG_2DP": "+2.20",
			"breakeven_price": "236.70",
			"ticker": "X7RW",
			"30D_LOW": "-",
			"underlying_pctchng": "-0.38",
			"delta_per_wrnt": "-1.468",
			"warrant_price": 0.091,
			"OPEN_PRC": "0.105",
			"TURNOVER_SCALED": "0",
			"NO_BIDMKR2": "-",
			"dsply_nmll": "AlibabaMBePW210405",
			"issuer_name": "Macquarie Bank Ltd",
			"sensitivity": "6.81",
			"delta": "-36.7",
			"TRADE_DATE": "22 Jan 21",
			"NO_BIDMKR2_SCALED": "-",
			"underlying_price_adj": 44.6571,
			"BID_PCTCHNG": "+2.2",
			"BIDSIZE": "0",
			"underlying_price_cal": 261.0,
			"exercise_price_adj": 42.775,
			"update_time": "11 Feb 21 15:57",
			"dsply_name": "AlibabaMBePW210405",
			"YRHIGH": "-",
			"BEST_BSIZ1": "0",
			"HST_CLOSE": "0.091",
			"ACVOL_1_SCALED": "0",
			"BID": "0.093",
			"r_hst_close": 0.1711,
			"maturity": "05 Apr 21",
			"div_yield": 0.0,
			"underlying_bid": 260.8,
			"fmt": 1,
			"30D_HIGH": "-",
			"link": true,
			"SYMBOL": "ALML_te.SI",
			"underlying_price": "261.00",
			"HST_VOL_SCALED": "-",
			"list_date": "22 Oct 20",
			"exchange_code": "SES",
			"underlying_curr": "HKD",
			"currency": "SGD",
			"ASK": "0.095",
			"conv_ratio_cal": 25.0,
			"UPLIMIT": "0",
			"NETCHNG_1": "0.0",
			"BID_NETCHNG": "+0.002",
			"HST_VOL": "-",
			"implied_volatility": "47.8",
			"VOL_PCTCHNG": "-",
			"TRDTIM_1": "  :",
			"moneyness": "2",
			"ASKSIZE": "0",
			"premium": "9.3",
			"SERVICE": "ELEKTRON_DD",
			"intrinsic_value": "0.000",
			"underlying_ticker": "BABA-SW",
			"issuer_code": "MBL",
			"exercise_price_cal": 250.0,
			"TURNOVER": "0",
			"underlying_name": "BABA-SW",
			"REC_STATUS": "0",
			"PCTCHNG": "0.0",
			"YRLOW": "-",
			"BEST_ASIZ1": "0",
			"ACVOL_1": "0",
			"days_to_maturity": "53",
			"ric": "ALML_te.SI",
			"exercise_price": "250.00"
		}
	]
}

const underlyingStaticData = {
    "hllist": [],
    "list": [
        {
            "underlying_ric": "AEM.SI",
            "underlying_ticker": "AWX",
            "underlying_name": "AEM HOLDINGS LTD"
        },
		{
			"underlying_ric": "9988.HK",
			"underlying_ticker": "BABA-SW",
			"underlying_name": "BABA-SW"
		}     
    ]
}

const comparisonStaticData = {
    "AEM.SI": AWX,
    "9988.HK": BABASW
}

export { comparisonStaticData, underlyingStaticData }