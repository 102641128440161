import React, { Component } from "react";
// import AdvertBox from "../components/advert/advert";
import "./SiMSCI.css";
import WarrantSelector from "../../pages/tools/warrantselector/selector";
import MQSGUtil from "../mqsgUtil";
import PageHeader from "../pageHeader/pageHeader";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';
import DatePicker from "react-datepicker";
import SwiperPagination from "../../components/swiperpagination";
// import "../../pages/marketnews/dailyhighlights/dailyhigh.css"
ReactGA.initialize('G-6VGY7GKL9V');
class Disclaimer extends React.Component {
    stopPropagation(e) {
        e.stopPropagation()
    }
    render() {
        const isShow = this.props.show ? {} : { display: "none" };
        const index = this.props.index;
        const msg = this.props.msg[index];
        return (
            <div
                id="disclaimer-ppboxHTML"
                className="disclaimer-overlay"
                style={isShow}
            >
                <div className="overlay-inner">
                    <div style={{ position: "relative" }} onClick={(e) => { this.stopPropagation(e) }}>
                        <div className="popupbox-content">
                            <div>
                                <div className="signup-layer">
                                    <div className="page-header">
                                        <h3>{msg.title}</h3>
                                    </div>
                                    {
                                        msg.text.map(function (name, index) {
                                            return <p className="small" key={index} dangerouslySetInnerHTML={{ __html: name }}></p>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default class SiMSCI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trendingwarrant: null,
            disclaimerShow: false,
            index: 0,
            msg: [
                { title: 'What is the SiMSCI?', text: ['The MSCI Singapore Free Index (SIMSCI index) is made up of 23 stocks (as of 31 Oct 2023), covering approximately 85% of the free float-adjusted market capitalisation of the Singapore equity universe.'] },
                {
                    title: 'Why would investors be interested in SIMSCI/SEA warrants?',
                    text: ['With SIMSCI and SEA warrants, investors get a leveraged exposure at a low absolute cost and smaller investment capital to share price movements on the SIMSCI and SEA.',
                        'Through SIMSCI warrants, investors can take a short-term bullish or bearish view on the overall Singapore market.',
                        'With SEA warrants, they can take a short-term leveraged view on the SEA ADRs during Singapore trading hours, ahead of the ADR’s share price move during overnight US trading. The SEA single stock futures listed on the SGX makes an average 1-day percentage change of 4.7%**, since listing on 1 March 2022 to 5 August 2022.',
                        '**Source: Bloomberg'
                    ]
                },
                {
                    title: 'How is the SIMSCI different from the STI',
                    text: [
                        'The SIMSCI includes shares of Singapore companies listed overseas such as SEA ADR and Grab amongst its constituents, while STI focuses on Singapore domestic names that are listed on the SGX only. Nevertheless, the SIMSCI has a 98%* correlation to the STI Index',
                        '*Source:<a href="https://www.sgx.com/derivatives/products/sgxsimsci?cc=SGP"> https://www.sgx.com/derivatives/products/sgxsimsci?cc=SGP in Aug 2022.</a>',
                    ]
                },
                {
                    title: 'What is the SEA futures?',
                    text: [
                        'SEA Limited provides information technology services via brands such as Garena, Shopee and Sea Money. Whilst SEA shares are listed in the US and known as SEA American Depository Receipts (ADR), they are included as a SIMSCI constituent stock as of 2022. SEA is the third largest constituent on the SIMSCI, with a 13% weightage on the index (as at 29 July 2022).',
                        'With the introduction of SEA single stock futures from March 2022 , investors can now gain access to the movements of the SEA ADR shares during Singapore trading hours and ahead of the US trading hours.',
                    ]
                }
                ,
                {
                    title: 'Why do the warrants track the futures and not the cash index?',
                    text: [
                        'Before addressing this, let us first cover a long-lived misconception.',
                        'Many investors think that market makers aim to make profit at the expense of investors. Meaning, if an investor buys a warrant for $0.100 and sells it back to the market maker for $0.050, the market maker simply makes a $0.050 profit. This is simply incorrect and in fact, Macquarie would prefer investors to profit from trading warrants, so that they continue to do so.',
                        'As a market maker, when we sell or buy back any warrants, we need to hedge our positions. For example, if we sell a call warrant, we will often buy the underlying share / index to hedge our position. Whereas if we buy back the call warrants, we need to sell the underlying share / index. The opposite applies for put warrants.',
                        'For index warrants such as warrants over the HSI, HSTECH, Nikkei 225, S&P500 and SIMSCI, since we are unable to buy and sell the cash index, we hedge our positions by buying and selling their respective futures, which is why these warrants track the futures instead of the cash index.',
                        'In the case of SEA, given that only the SEA single stock futures (as opposed to its shares) trade during Singapore trading hours, the warrant prices will now have a reference price to track during the day.',
                    ]
                },
                {
                    title: 'Where can I access the futures live prices?',
                    text: [
                        'The live futures prices of both underlyings can be found under the “Live index futures prices” section on our home page. Just ensure to click on the refresh button to keep seeing the live quote, so you can be rest assured that you are looking at the most current levels'
                    ]
                }
            ],
            gtag: [
                {
                    category: 'What_SiMSCi',
                    action: 'Rated an App',
                    label: 'SiMSCi',
                    Event_category: 'What_SiMSCi',
                    event_label: 'SiMSCi',
                },
                {
                    category: 'Why_SIMSCI',
                    label: 'SiMSCi',
                    action: 'Rated an App',
                    Event_category: 'Why_SIMSCI',
                    event_label: 'SiMSCI',
                },
                {
                    category: 'How_SIMSCI',
                    label: 'SiMSCi',
                    action: 'Rated an App',
                    Event_category: 'How_SIMSCI',
                    event_label: 'SiMSCI',
                },
                {
                    category: 'What_SEA',
                    action: 'Rated an App',
                    label: 'SiMSCi',
                    Event_category: 'What_SEA',
                    event_label: 'SiMSCI',
                },
                {
                    category: 'Why_warrants',
                    label: 'SiMSCi',
                    action: 'Rated an App',
                    Event_category: 'Why_warrants',
                    event_label: 'SiMSCI',
                },
                {
                    category: 'Where_futures',
                    label: 'SiMSCi',
                    action: 'Rated an App',
                    Event_category: 'Where_futures',
                    event_label: 'SiMSCI',
                }
            ],
            highlightdata: null,
            startDate: null,
        };
    }
    getMsg(i, e) {
        e.stopPropagation()
        this.setState({ index: i, disclaimerShow: true })
        // window.gtag('event', 'Clicks', this.state.gtag[i]);
        ReactGA.event(this.state.gtag[i])
    }
    closeDisclaimer(e) {
        e.stopPropagation()
        this.setState({ disclaimerShow: false })
    }
    componentDidMount() {
        // document.title = "Warrant Calculator | Macquarie"
        this.onFetchData()
        this.onFetchMmbData()
    }
    onFetchMmbData(startDateString = null) {
        if (MQSGUtil.isUsingStaticData("DailyHighlights")) {
            setTimeout(() => {
                this.onFetchStaticData();
            }, 100); // pretend fetch
            return;
        }
        let highlightdata = null;
        let startdate = "";
        if (startDateString !== null) {
            startdate = "&startdate=" + startDateString;
        }
        // const url = MQSGUtil.getAPIBasePath() + "/NewsTableServlet?subPage=todayhighlight&start=0" + startdate
        const url =
            MQSGUtil.getAPIBasePath() +
            "/NewsTableServlet?sp=todayhighlight&start=0" +
            startdate
            + '&underlying=SIMSCI;SEA';
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    highlightdata = result;
                    this.setState({ highlightdata });
                },
                (error) => {
                    this.setState({ highlightdata });
                }
            );
    }
    onStartDateChange(startDate) {
        this.setState({ startDate });
        const startDateString = MQSGUtil.getDateString(startDate);
        this.onFetchMmbData(startDateString);
    }
    onFetchData() {
        const url = MQSGUtil.getAPIBasePath() + "/HomeJSON?m=dwtool";
        fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({ trendingwarrant: result.trending_warrants });
                },
                (error) => {
                    // this.setState({ quickheadlinedata });
                }
            );
    }
    getCallPutData(underlyingname, isCALL = true) {
        if (this.state.trendingwarrant !== null) {
            if (underlyingname in this.state.trendingwarrant) {
                const callputdata = this.state.trendingwarrant[underlyingname];
                if (isCALL && "CALL" in callputdata) {
                    return callputdata["CALL"];
                } else if (!isCALL && "PUT" in callputdata) {
                    return callputdata["PUT"];
                }
            }
        }
        return [];
    }
    getRowSubTable(rowdata) {
        const ticker = this.getData(rowdata, "ticker");
        const dsplyname = this.getData(rowdata, "dsply_name");
        const bid = this.getData(rowdata, "BID");
        const bidpctchng = this.getData(rowdata, "BID_PCTCHNG");
        let bidpctchngString = bidpctchng + "%";
        let bidpctchngupdown = "down";
        if (Number(bidpctchng) > 0) {
            bidpctchngString = "+" + bidpctchng + "%";
            bidpctchngupdown = "up";
        }
        const strike = this.getData(rowdata, "exercise_price");
        const expiry = this.getData(rowdata, "maturity");
        const eff = this.getData(rowdata, "effective_gearing");

        const row1 = (
            <tr>
                <td colSpan="2" className="tw_dsply_name">
                    {dsplyname}
                </td>
            </tr>
        );
        const row2 = (
            <tr>
                <td style={{ width: "40%" }}>Price:</td>
                <td style={{ width: "60%" }}>
                    {" "}
                    $<span className="tw_BID">{bid}</span>&nbsp; (
                    <span className={"tw_BID_PCTCHNG " + bidpctchngupdown}>
                        {bidpctchngString}
                    </span>
                    )
                </td>
            </tr>
        );
        const row3 = (
            <tr>
                <td style={{ width: "40%" }}>Strike:</td>
                <td className="tw_strike" style={{ width: "60%" }}>
                    {strike}
                </td>
            </tr>
        );
        const row4 = (
            <tr>
                <td style={{ width: "40%" }}>Expiry:</td>
                <td className="tw_expiry" style={{ width: "60%" }}>
                    {expiry}
                </td>
            </tr>
        );
        const row5 = (
            <tr>
                <td style={{ width: "40%" }}>Eff gearing:</td>
                <td className="tw_eff" style={{ width: "60%" }}>
                    {eff}
                </td>
            </tr>
        );
        const row6 = (
            <tr>
                <td colSpan="2">
                    <div className="vmbtn-link" style={{ justifyContent: "normal" }}>
                        <Link
                            className="lmbtn"
                            to={"/tools/livematrix/" + ticker}
                            target="_blank"
                        >
                            Live
                            <br />
                            Matrix
                        </Link>
                        <a
                            className="wtbtn"
                            href={"/tools/warrantterms/" + ticker}
                            style={{ marginLeft: "5px" }}
                            target="_blank"
                        >
                            Warrant
                            <br />
                            Terms
                        </a>
                    </div>
                </td>
            </tr>
        );
        return (
            <table style={{ width: "100%" }}>
                <tbody>
                    {row1}
                    {row2}
                    {row3}
                    {row4}
                    {row5}
                    {row6}
                </tbody>
            </table>
        );
    }
    getData(data, fieldName) {
        if (data !== null) {
            if (fieldName in data) {
                return data[fieldName];
            }
        }
        return "";
    }
    getRow(rowdata, index, isCALL = true) {
        let putcall = "PUT";
        let className = "put_tmp";
        if (isCALL) {
            putcall = "CALL";
            className = "call_tmp";
        }
        const ticker = this.getData(rowdata, "ticker");

        return (
            <tr className={className, 'homeTopMversTable'} idx={index} key={putcall + "_" + index}>
                <td>
                    <div className="tw_warrant">
                        <div colSpan="2" className="tw_title" style={{ marginTop: '10px' }}>
                            <div className="tw_type">{putcall}</div>
                            <div className="tw_ticker">{ticker}</div>
                        </div>
                        {this.getRowSubTable(rowdata)}
                    </div>
                </td>
            </tr>
        );
    }
    render() {
        const calldata = this.getCallPutData("SiMSCI");
        const seaCalldata = this.getCallPutData("SEA");
        const callrows = [];
        const seaCallrows = [];
        calldata.forEach((rowdata, index) => {
            const row = this.getRow(rowdata, index);
            callrows.push(row);
        });
        seaCalldata.forEach((rowdata, index) => {
            const row = this.getRow(rowdata, index);
            seaCallrows.push(row);
        });
        const putdata = this.getCallPutData("SiMSCI", false);
        const seaPutdata = this.getCallPutData("SEA", false);
        const putrows = [];
        const seaPutrows = [];
        putdata.forEach((rowdata, index) => {
            const row = this.getRow(rowdata, index, false);
            putrows.push(row);
        });
        seaPutdata.forEach((rowdata, index) => {
            const row = this.getRow(rowdata, index, false);
            seaPutrows.push(row);
        });
        let callTableStyle = { display: "none" };
        let putTableStyle = { display: "none" };
        let noCallPutStyle = {
            display: "none",
            marginLeft: "-10px",
            marginRight: "-10px",
            backgroundColor: "rgb(245, 246, 246)",
        };
        let titleStyle = { display: "none" };
        if (callrows.length > 0 || seaCallrows.length > 0) {
            callTableStyle = {};
        }
        if (putrows.length > 0 || seaPutrows.length > 0) {
            putTableStyle = {};
        }
        if (callrows.length === 0 && putrows.length === 0 && seaCallrows.length === 0 && seaPutrows.length === 0) {
            noCallPutStyle = {
                backgroundColor: "rgb(245, 246, 246)",
            };
        }
        return (
            <div id="SiMSCI" className="pageobj" onClick={(e) => { this.closeDisclaimer(e) }}>
                <Disclaimer
                    show={this.state.disclaimerShow}
                    msg={this.state.msg}
                    index={this.state.index}
                />
                <div className="breadcrumbbar">
                    <div className="container-fluid">
                        <ol className="breadcrumb">
                            <li>
                                <a href="/home/edu">Warrant education</a>
                            </li>
                            &nbsp;
                            <li className="active">SiMSCI</li>
                        </ol>
                    </div>
                </div >
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 hidden-sm hidden-xs">
                            <img
                                style={{ width: "100%" }}
                                src="/img/education/hero_test_small.gif"
                                alt=""
                            />
                        </div>
                        <div className="col-md-12 visible-sm visible-xs">
                            <img
                                style={{ width: "100%" }}
                                src="/img/education/SiMSCI_mobile_hero_MCQ.gif"
                                alt=""
                            />
                        </div>
                        <div className="col-md-7">
                            <div className="section" id="page-headers">
                                <h2 style={{ fontSize: "30px" }}>Want to know more?</h2>
                                <div>Click on the boxes to find out more</div>
                                <div className="row">
                                    <div className="col-md-6 paddingRight" style={{ padding: '0px 5px 0px 0px', marginTop: '10px' }}>
                                        <div className="accordion" id="accordionExample" onClick={(e) => this.getMsg(0, e)}>
                                            <div className="fixed-top" style={{ background: '#8BC9F2', padding: '5px 5px', cursor: 'pointer' }}>
                                                <h3 style={{ margin: "0px" }}><b>What</b> is the SiMSCI?</h3>
                                                {/* <div className="collapse" id="navbarToggleExternalContent">
                                                    <div className="bg-dark p-4">
                                                        <hr className="hstech-hr" />
                                                        <p>
                                                            Made up 19 stocks covering approximately 85% of the free float-adjusted market capitalisation of the Singapore equity universe, the MSCI Singapore Free Index (“SiMSCI Index”) is designed to measure the performance of the large and mid-cap segments of the Singapore market. The active SIMSCI futures allow investors to trade the moves of the SIMSCI index.
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <nav className="navbar navbar-dark bg-dark" style={{ textAlign: 'center', minHeight: 'auto' }}>
                                                    <div className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggleExternalContent" style={{ textAlign: 'center', minHeight: 'auto' }}>
                                                        {/* <span className="navmbl-panel-caret glyphicon glyphicon-menu-down" style={{ float: 'none', cursor: 'pointer' }}></span> */}
                                                        {/* Click on the boxes to find out more */}
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>

                                        <div className="accordion" id="accordionExample1" style={{ marginTop: '10px' }} onClick={(e) => this.getMsg(1, e)}>
                                            <div className="fixed-top" style={{ background: '#8BC9F2', padding: '5px 5px', cursor: 'pointer' }}>
                                                <h3 style={{ margin: "0px" }}><b>Why</b> would investors be interested in SIMSCI/SEA warrants?</h3>
                                                {/* <div className="collapse" id="navbarToggleExternalContent1">
                                                    <div className="bg-dark p-4">
                                                        <hr className="hstech-hr" />
                                                        <p>With SIMSCI and SEA warrants, investors get a leveraged exposure at a low absolute cost and smaller investment capital to share price movements on the SIMSCI and SEA.</p>
                                                        <p>Through SIMSCI warrants, investors can take a short-term bullish or bearish view on the overall Singapore market. </p>
                                                        <p>With SEA warrants, they can take a short-term leveraged view on the SEA ADRs during Singapore trading hours, ahead of the ADR’s share price move during overnight US trading. The SEA single stock futures listed on the SGX makes an average 1-day percentage change of 4.7%**, since listing on 1 March 2022 to 5 August 2022. </p>
                                                        <p>**Source: Bloomberg</p>
                                                    </div>
                                                </div> */}
                                                <nav className="navbar navbar-dark bg-dark" style={{ textAlign: 'center', minHeight: 'auto' }}>
                                                    <div className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggleExternalContent1" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                        {/* <span className="navmbl-panel-caret glyphicon glyphicon-menu-down" style={{ float: 'none', cursor: 'pointer' }}></span> */}
                                                        {/* Click on the boxes to find out more */}
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>

                                        <div className="accordion" id="accordionExample5" style={{ marginTop: '10px' }} onClick={(e) => this.getMsg(2, e)}>
                                            <div className="fixed-top" style={{ background: '#8BC9F2', padding: '5px 5px', cursor: 'pointer' }}>
                                                <h3 style={{ margin: "0px" }}><b>How</b> is the SIMSCI different from the STI</h3>
                                                {/* <div className="collapse" id="navbarToggleExternalContent5">
                                                    <div className="bg-dark p-4">
                                                        <hr className="hstech-hr" />
                                                        <p>The SIMSCI includes shares of Singapore companies
                                                            listed overseas such as SEA ADR and Grab amongst
                                                            its constituents, while STI focuses on Singapore
                                                            domestic names that are listed on the SGX only.
                                                            Nevertheless, the SIMSCI has a 98%* correlation to
                                                            the STI Index</p>
                                                        <p><span>*Source: </span> <a style={{ wordBreak: 'break-all' }} href="https://www.sgx.com/derivatives/products/sgxsimsci?cc=SGP">https://www.sgx.com/derivatives/products/sgxsimsci?cc=SGP in Aug 2022.</a></p>
                                                    </div>
                                                </div> */}
                                                <nav className="navbar navbar-dark bg-dark" style={{ textAlign: 'center', minHeight: 'auto', }}>
                                                    <div className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggleExternalContent5" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                        {/* <span className="navmbl-panel-caret glyphicon glyphicon-menu-down" style={{ float: 'none', cursor: 'pointer' }}></span> */}
                                                        {/* Click on the boxes to find out more */}
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="col-md-6" style={{ padding: '0px 0px 0px 0px', marginTop: '10px' }} onClick={(e) => this.getMsg(3, e)}>
                                        <div className="accordion" id="accordionExample3">
                                            <div className="fixed-top" style={{ background: '#8BC9F2', padding: '5px 5px', cursor: 'pointer' }}>
                                                <h3 style={{ margin: "0px" }}><b>What</b> is the SEA futures?</h3>
                                                {/* <div className="collapse" id="navbarToggleExternalContent3">
                                                    <div className="bg-dark p-4">
                                                        <hr className="hstech-hr" />
                                                        <p>SEA Limited provides information technology services
                                                            via brands such as Garena, Shopee and Sea Money.
                                                            Whilst SEA shares are listed in the US and known as
                                                            SEA American Depository Receipts (ADR), they are
                                                            included as a SIMSCI constituent stock as of 2022.
                                                            SEA is the third largest constituent on the SIMSCI,
                                                            with a 13% weightage on the index (as at 29 July
                                                            2022). </p>
                                                        <p>With the introduction of SEA single stock futures from
                                                            March 2022 , investors can now gain access to the
                                                            movements of the SEA ADR shares during Singapore
                                                            trading hours and ahead of the US trading hours.</p>
                                                    </div>
                                                </div> */}
                                                <nav className="navbar navbar-dark bg-dark" style={{ textAlign: 'center', minHeight: 'auto' }}>
                                                    <div className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggleExternalContent3" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                        {/* <span className="navmbl-panel-caret glyphicon glyphicon-menu-down" style={{ float: 'none', cursor: 'pointer' }}></span> */}
                                                        {/* Click on the boxes to find out more */}
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>

                                        <div className="accordion" id="accordionExample4" style={{ marginTop: '10px' }} onClick={(e) => this.getMsg(4, e)}>
                                            <div className="fixed-top" style={{ background: '#8BC9F2', padding: '5px 5px', cursor: 'pointer' }}>
                                                <h3 style={{ margin: "0px" }}><b>Why</b> do the warrants track
                                                    the futures and not the cash
                                                    index?</h3>
                                                {/* <div className="collapse" id="navbarToggleExternalContent4">
                                                    <div className="bg-dark p-4">
                                                        <hr className="hstech-hr" />
                                                        <p>Before addressing this, let us first cover a long-lived
                                                            misconception.</p>
                                                        <p>Many investors think that market makers aim to make
                                                            profit at the expense of investors. Meaning, if an
                                                            investor buys a warrant for $0.100 and sells it back
                                                            to the market maker for $0.050, the market maker
                                                            simply makes a $0.050 profit. This is simply incorrect
                                                            and in fact, Macquarie would prefer investors to profit
                                                            from trading warrants, so that they continue to do so.</p>
                                                        <p>As a market maker, when we sell or buy back any
                                                            warrants, we need to hedge our positions. For
                                                            example, if we sell a call warrant, we will often buy
                                                            the underlying share / index to hedge our position.
                                                            Whereas if we buy back the call warrants, we need to
                                                            sell the underlying share / index. The opposite applies
                                                            for put warrants.</p>
                                                        <p>For index warrants such as warrants over the HSI,
                                                            HSTECH, Nikkei 225, S&P500 and SIMSCI, since we
                                                            are unable to buy and sell the cash index, we hedge
                                                            our positions by buying and selling their respective
                                                            futures, which is why these warrants track the futures
                                                            instead of the cash index. </p>
                                                        <p>
                                                            In the case of SEA, given that only the SEA single
                                                            stock futures (as opposed to its shares) trade during
                                                            Singapore trading hours, the warrant prices will now
                                                            have a reference price to track during the day.
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <nav className="navbar navbar-dark bg-dark" style={{ textAlign: 'center', minHeight: 'auto' }}>
                                                    <div className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggleExternalContent4" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                        {/* <span className="navmbl-panel-caret glyphicon glyphicon-menu-down" style={{ float: 'none', cursor: 'pointer' }}></span> */}
                                                        {/* Click on the boxes to find out more */}
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>

                                        <div className="accordion" id="accordionExample2" style={{ marginTop: '10px' }} onClick={(e) => this.getMsg(5, e)}>
                                            <div className="fixed-top" style={{ background: '#8BC9F2', padding: '5px 5px', cursor: 'pointer' }}>
                                                <h3 style={{ margin: "0px" }}><b>Where</b> can I access the futures live prices?</h3>
                                                {/* <div className="collapse" id="navbarToggleExternalContent2">
                                                    <div className="bg-dark p-4">
                                                        <hr className="hstech-hr" />
                                                        <p>The live futures prices of both underlyings can be
                                                            found under the “Live index futures prices” section on
                                                            our home page. Just ensure to click on the refresh
                                                            button to keep seeing the live quote, so you can be
                                                            rest assured that you are looking at the most current
                                                            levels</p>
                                                    </div>
                                                </div> */}
                                                <nav className="navbar navbar-dark bg-dark" style={{ textAlign: 'center', minHeight: 'auto' }}>
                                                    <div className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggleExternalContent2" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                                        {/* <span className="navmbl-panel-caret glyphicon glyphicon-menu-down" style={{ float: 'none', cursor: 'pointer' }}></span> */}
                                                        {/* Click on the boxes to find out more */}
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <NewsSection
                                    highlightdata={this.state.highlightdata}
                                    startDate={this.state.startDate}
                                    onStartDateChange={(startDate) =>
                                        this.onStartDateChange(startDate)
                                    }
                                />
                                <MarketView />
                            </div >
                        </div>
                        <div className="col-md-5" >
                            <div className="mlarea-index" style={{ marginTop: "20px", position: 'relative' }}>
                                <div>
                                    <h2 >
                                        Trending warrants
                                    </h2>
                                </div>
                                <hr className="hstech-hr" />
                                <div className="row tw_table" style={{ overflowY: 'auto', marginRight: '-10px', marginLeft: '-10px' }}>
                                    <div className="call_table col-xs-6" style={callTableStyle}>
                                        <table className="tw_call_table" style={{ width: "100%" }}>
                                            <tbody>{callrows}</tbody>
                                        </table>
                                        <table className="tw_call_table" style={{ width: "100%" }}>
                                            <tbody>{seaCallrows}</tbody>
                                        </table>
                                        <table className="tmp_table" style={{ display: "none" }}>
                                            <tbody>{/* insert point */}</tbody>
                                        </table>
                                    </div>
                                    <div className="put_table col-xs-6" style={putTableStyle}>
                                        <table className="tw_put_table" style={{ width: "100%" }}>
                                            <tbody>{putrows}</tbody>
                                        </table>
                                        <table className="tw_put_table" style={{ width: "100%" }}>
                                            <tbody>{seaPutrows}</tbody>
                                        </table>
                                        <table className="tmp_table" style={{ display: "none" }}>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                    <p className="notw" style={noCallPutStyle}>
                                        No warrants available
                                    </p>
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <a style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        zIndex: '999'
                                    }} href="/education/getstarted" className="btn btn-primary btn-default" target="_blank">How to get started</a>
                                    <PageHeader title="" ref="child" />
                                </div>

                            </div>
                            <div className="holder-warranttools">
                                <div id="warranttools" className="section">
                                    <div>
                                        <div className="tools-row">
                                            <div id="selector-tools" className="tools-detail">
                                                <WarrantSelector page="home" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
class MarketView extends Component {
    render() {
        return (
            <div id="qlink-dwvideo" className="holder-qlink-dwvideo section" style={{ padding: '0px' }}>
                <div className="page-header">
                    <h2 className="small" style={{ fontSize: '30px' }}>Singapore Free Index</h2>
                </div>
                <p style={{ fontWeight: '600' }}>
                    Watch this video to understand what Singapore Free Index is.
                </p>
                <div className="videoplayer">
                    <div className="videoplayer-container">
                        {/* <iframe
                            // title="Market View"
                            frameBorder="0"
                            allowFullScreen=""
                            className="dwvideo-iframe"
                            src="https://app.frame.io/presentations/a5655882-dd52-4343-931a-f0f8eb087406"
                        ></iframe> */}
                        <video width="100%" height="100%" controls>
                            <source src="/videos/R32_MSCI_Explainer_Singapore - V03.mp4" type="video/mp4" />
                            {/* <source src="/images/video/demo.ogg" type="video/ogg" />
                            您的浏览器不支持video标记。 */}
                        </video>
                    </div>
                </div>
                <p style={{ fontWeight: '600' }}>Source : MSCI.com</p>
                <p style={{ fontWeight: '600', fontSize: '14px' }}>For more information about the index, please download MSCI's <a href="/pdf/MSCI-Singapore-cbr-en.pdf" target="_blank">brochure</a> or <a href="https://www.msci.com/research-and-insights/singapore-free-index" target="_blank">click here.</a></p>
            </div>
        );
    }
}

class NewsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
        this.rows = [];
        this.pageSize = 5;
    }

    onDotClicked(pageIndex) {
        this.setState({ current: pageIndex });
    }

    onDateChange(date) {
        this.props.onStartDateChange(date);
    }

    setupRows(highlightdata) {
        const rows = [];
        const newslist = MQSGUtil.getData(highlightdata, "newsList");
        if (newslist !== null && Array.isArray(newslist)) {
            newslist.forEach((news, index) => {
                const date = MQSGUtil.getData(news, "date");
                const title = { __html: MQSGUtil.getData(news, "en_title") };
                const short = { __html: MQSGUtil.getData(news, "en_short") };
                const id = MQSGUtil.getData(news, "id");
                const column1 = (
                    <div className="newstbl-col-left" style={{ margin: '0px 0px 0px 10px', padding: '10px 50px 10px 10px', background: index % 2 == 0 ? '#F5F6F7' : '#fff' }}>
                        <span className="newstbl-col-date">{date}</span>
                        <div dangerouslySetInnerHTML={title} style={{ color: '#000', fontWeight: 'bolder' }}>
                            {/* <Link
                                to={"/marketnews/highlight/todayhighlight/" + id}
                                target="_blank"
                                dangerouslySetInnerHTML={title}
                            >
                            </Link> */}
                        </div>
                    </div>
                );
                const column2 = (
                    <div className="newstbl-col-right" style={{
                        position: 'absolute',
                        right: '5px',
                        top: '18px'
                    }}>
                        <Link
                            to={"/marketnews/highlight/todayhighlight/" + id}
                        >
                            <img src="/img/bullet/qlink-achor.png" alt="" />
                        </Link>


                    </div>
                );
                const row = (
                    <div className="newstbl-row rowtmpl" key={"daily_" + id} style={{ background: '#DFDBCF', position: 'relative' }}>
                        {column1}
                        {column2}
                    </div>
                );
                rows.push(row);
            });
        }
        this.rows = rows;
        return rows;
    }

    getRows() {
        // page index 0: current -> pageSize * (current + 1) - 1
        // page index 1: pageSize * current -> pageSize * 2 - 1
        const pageIndex = this.state.current;
        const startRowIndex = this.pageSize * pageIndex;
        const endRowIndex = this.pageSize * (pageIndex + 1);
        if (this.rows.length > endRowIndex) {
            return this.rows.slice(startRowIndex, endRowIndex);
        }
        return this.rows.slice(startRowIndex);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.highlightdata !== this.props.highlightdata) {
            this.setupRows(nextProps.highlightdata);
        }
        return true;
    }
    getbgDate(date) {
        var m = new Date().getMonth() + 1;
        var M = new Date(date).getMonth() + 1;
        var cls = m != M ? "random" : undefined;
        return cls
    }
    render() {
        const rows = this.getRows();
        return (
            <div
                id="newssection"
                style={{ position: "relative", padding: '0px' }}
                className="section"
            >
                {/* <div className="page-header">
                    <h1>Morning Market Buzz</h1>
                </div> */}
                <h2 className="section-p" style={{ minHeight: '40px', borderBottom: '1px solid #FECC0A', fontSize: '26px' }}>
                    Newsletter articles on SIMSCI and/or SEA:
                </h2>
                <div id="commentarytbl" className="newstbl">
                    {/* <div className="newstbl-htxt">Latest News</div> */}
                    <div className="newstbl-content" style={{ color: '#0675C2', overflow: 'hidden', marginBottom: '15px' }}>{rows}</div>
                    <div className="newstbl-bttm">
                        {/* <div id="datepicker" className="newstbl-cal-icon input-group date">
                            <input type="hidden" className="form-control" />
                            <div className="input-group-addon">
                                <DatePicker
                                    selected={this.props.startDate}
                                    onChange={(date) => this.onDateChange(date)}
                                    maxDate={new Date()}
                                    value=""
                                    dayClassName={(date) =>
                                        this.getbgDate(date)
                                        // getDate(date) < Math.random() * 31 ? "random" : undefined
                                    }
                                />
                            </div>
                        </div> */}
                        <SwiperPagination
                            current={this.state.current}
                            rows={this.rows}
                            pageSize={this.pageSize}
                            onDotClicked={(pageIndex) => this.onDotClicked(pageIndex)}
                        />
                        <div className="newstbl-col-rm" style={{ display: 'flex' }}>
                            <div style={{ color: '#3F312D', fontSize: '22px' }}>
                                Sign up for our daily newsletter for the latest updates on the market
                            </div>
                            <Link
                                to={"/marketnews/subscribe"}
                                className="btn btn-primary btn-default"
                                target="_blank"
                                style={{
                                    padding: '0px 30px',
                                    height: '55px',
                                    fontSize: '22px',
                                    marginLeft: '5px',
                                    lineHeight: '55px'
                                }}
                            >
                                Subscribe
                            </Link>
                        </div>
                    </div>
                    <hr className="hr-hmgbm" />
                </div>
            </div>
        );
    }
}






