// LiveMatrixJSON?ric=" + ric + "&mode=0"

const livematrixDataCase1_1 = {
  "last_update": "09 Feb 21 17:30",
  "symbols": [
    {
      "dsply_nmll": "AEM MB eCW210302",
      "ric": "AEML_tk.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "983W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AEM MB eCW210917",
      "ric": "AEML_tm.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VKEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Alibaba MB eCW210705",
      "ric": "ALML_th.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "R42W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Alibaba MB ePW210705",
      "ric": "ALML_tg.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "QBDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "AlibabaMBeCW210405",
      "ric": "ALML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "1M7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AlibabaMBePW210405",
      "ric": "ALML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "X7RW",
      "hide_date": null
    },
    {
      "dsply_nmll": "AscendasReit MBeCW210302",
      "ric": "AEML_tj.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WQ6W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AscendasReit MBeCW210524",
      "ric": "AEML_tl.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RQOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210302",
      "ric": "BYML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XATW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210405",
      "ric": "BYML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VHUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210705",
      "ric": "BYML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "TMHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210705A",
      "ric": "BYML_tg.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MGVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210302",
      "ric": "BYML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NYBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210405",
      "ric": "BYML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RUQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210705",
      "ric": "BYML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YBMW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210705A",
      "ric": "BYML_th.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VRJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW210222",
      "ric": "CAML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WU1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW210304",
      "ric": "CAML_t3.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "HUFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW211018",
      "ric": "CAML_t4.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MHJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CityDev MB eCW210507",
      "ric": "CTML_tt.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LQXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "ComfortDel MBeCW210301",
      "ric": "CMML_t1.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XLJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "ComfortDel MBeCW211018",
      "ric": "CMML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MSHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210405",
      "ric": "DBML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WRAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210407",
      "ric": "DBML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EYOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210503",
      "ric": "DBML_t38.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "HRZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW211001",
      "ric": "DBML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9WJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW211008",
      "ric": "DBML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OFHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210217",
      "ric": "DBML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XPUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210701",
      "ric": "DBML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YY9W",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210705",
      "ric": "DBML_t37.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "QYLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW211001",
      "ric": "DBML_t36.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KEDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW211008",
      "ric": "DBML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RWXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW210405",
      "ric": "GEML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RQUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW210705",
      "ric": "GEML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RUHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW211005",
      "ric": "GEML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XQDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW210405",
      "ric": "GEML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XZ4W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW210705",
      "ric": "GEML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OCCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW211005",
      "ric": "GEML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VZGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "GentingSMBeCW210701",
      "ric": "GTML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XJLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 24800MBePW210528",
      "ric": "HSML_t210.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TPCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25000MBePW210225",
      "ric": "HSML_t189.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VOIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25400MBePW210429",
      "ric": "HSML_t204.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FMPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25800MBePW210330",
      "ric": "HSML_t197.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "KWBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26000MBePW210225",
      "ric": "HSML_t191.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "JXBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26200MBePW210429",
      "ric": "HSML_t205.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TNSW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBeCW210225",
      "ric": "HSML_t193.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "UCEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBeCW210528",
      "ric": "HSML_t209.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OKCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBePW210330",
      "ric": "HSML_t196.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "NMKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27000MBePW210429",
      "ric": "HSML_t199.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "8XWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27200MBeCW210330",
      "ric": "HSML_t195.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "W6JW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27400MBeCW210225",
      "ric": "HSML_t192.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FCHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27400MBePW210330",
      "ric": "HSML_t208.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "EEKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27600MBeCW210429",
      "ric": "HSML_t200.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "GJDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28000MBeCW210330",
      "ric": "HSML_t194.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OMCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28000MBePW210528",
      "ric": "HSML_t207.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "1HZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28200MBeCW210225",
      "ric": "HSML_t190.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "8Y4W",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28400MBeCW210429",
      "ric": "HSML_t201.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "SGAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28600MBePW210429",
      "ric": "HSML_t206.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "CVCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28800MBeCW210330",
      "ric": "HSML_t198.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "QHEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 29200MBeCW210429",
      "ric": "HSML_t202.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "DZBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 30000MBeCW210429",
      "ric": "HSML_t203.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XLDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 30400MBeCW210330",
      "ric": "HSML_t212.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "HGKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 31200MBeCW210528",
      "ric": "HSML_t211.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "Z7PW",
      "hide_date": null
    },
    {
      "dsply_nmll": "IFAST MB eCW210601",
      "ric": "IFML_tu.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KTRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "JD MB eCW210705",
      "ric": "JDML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OPSW",
      "hide_date": null
    },
    {
      "dsply_nmll": "JD MB ePW210705",
      "ric": "JDML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "DVDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorp MBeCW210222",
      "ric": "KPML_t8.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "SJOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorpMBeCW210304",
      "ric": "KPML_t9.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NMBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorpMBeCW211018",
      "ric": "KPML_t10.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WFEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "MapleComTr MB eCW210302",
      "ric": "MCML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9EFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "MapleComTr MB eCW211018",
      "ric": "MAML_t.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "TRGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB eCW210405",
      "ric": "MEML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JAOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB eCW210705",
      "ric": "MEML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YC8W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB ePW210405",
      "ric": "MEML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YLAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB ePW210705",
      "ric": "MEML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MXTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 19000MBePW210312",
      "ric": "NIML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "WG8W",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 20000MBePW210611",
      "ric": "NIML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "HASW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 22000MBePW210312",
      "ric": "NIML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "UAFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 23000MBePW210611",
      "ric": "NIML_t28.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XDQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 24000MBeCW210312",
      "ric": "NIML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "PJLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 25000MBeCW210611",
      "ric": "NIML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "ZALW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 25000MBePW210312",
      "ric": "NIML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VTWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 26000MBePW210611",
      "ric": "NIML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "EOGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 27000MBeCW210312",
      "ric": "NIML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FIQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 28000MBeCW210611",
      "ric": "NIML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TCFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 29000MBePW210611",
      "ric": "NIML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "9AFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 30000MBeCW210312",
      "ric": "NIML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "YTXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 31000MBeCW210611",
      "ric": "NIML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "LXLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210301",
      "ric": "OCML_t11.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XV1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210303",
      "ric": "OCML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JSYW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210507",
      "ric": "OCML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ZRDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210510",
      "ric": "OCML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LQFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210514",
      "ric": "OCML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GWWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW211112",
      "ric": "OCML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WZ1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW211115",
      "ric": "OCML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VYWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210217",
      "ric": "OCML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "IEIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210701",
      "ric": "OCML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EUEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210705",
      "ric": "OCML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9I5W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW211112",
      "ric": "OCML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "95JW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW211115",
      "ric": "OCML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JWRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 2700MBePW210319",
      "ric": "SXML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "IQZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 2950MBePW210319",
      "ric": "SXML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "DOHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3000MBePW210618",
      "ric": "SXML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XYPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3400MBePW210618",
      "ric": "SXML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OKBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3800MBeCW210618",
      "ric": "SXML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "RUUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3950MBeCW210319",
      "ric": "SXML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VQEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 4200MBeCW210618",
      "ric": "SXML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "GTJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB eCW210315",
      "ric": "SNML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9QJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB eCW211018",
      "ric": "SNML_t4.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ESYW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB ePW210315",
      "ric": "SNML_t1.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RXPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB ePW211018",
      "ric": "SNML_t3.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UJGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SIA MB eCW210701",
      "ric": "SLML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GIJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210222",
      "ric": "SEML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YX7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210304",
      "ric": "SEML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GJAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210331",
      "ric": "SEML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YBNW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW211018",
      "ric": "SEML_t7.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "59XW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2300MBePW210331",
      "ric": "STML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WVEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2400MBePW210630",
      "ric": "STML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OSTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2500MBePW210331",
      "ric": "STML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "DFAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2500MBePW210930",
      "ric": "STML_t42.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JUUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2550MBeCW210331",
      "ric": "STML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "8C5W",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2600MBePW210630",
      "ric": "STML_t39.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VVZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2650MBeCW210630",
      "ric": "STML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XINW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2750MBePW210930",
      "ric": "STML_t41.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JUPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBeCW210331",
      "ric": "STML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "URDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBeCW210930",
      "ric": "STML_t40.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UNCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBePW210630",
      "ric": "STML_t36.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YZHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2900MBeCW210630",
      "ric": "STML_t37.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PXKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3000MBeCW210331",
      "ric": "STML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VSBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3000MBePW210930",
      "ric": "STML_t45.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PCEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3050MBeCW210930",
      "ric": "STML_t44.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "V6OW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3100MBeCW210630",
      "ric": "STML_t38.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EZEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3300MBeCW210930",
      "ric": "STML_t43.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LZTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB eCW210405",
      "ric": "SUML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9T9W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB eCW210705",
      "ric": "SUML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WAVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB ePW210405",
      "ric": "SUML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PDGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB ePW210705",
      "ric": "SUML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "8P7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBeCW210302",
      "ric": "TCML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "Q31W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBeCW210705",
      "ric": "TCML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PHDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBePW210302",
      "ric": "TCML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WL7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBePW210705",
      "ric": "TCML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WVDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Top Glove MB eCW210302",
      "ric": "TOML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WHGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Top Glove MB eCW210917",
      "ric": "TOPG_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "1P7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210405",
      "ric": "UOML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "SMOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210407",
      "ric": "UOML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FSXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210503",
      "ric": "UOML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "V8DW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210506",
      "ric": "UOML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PWBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210514",
      "ric": "UOML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ELVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211001",
      "ric": "UOML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XIIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211004",
      "ric": "UOML_t28.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KVKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211008",
      "ric": "UOML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RABW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210217",
      "ric": "UOML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9KVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210701",
      "ric": "UOML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "X0RW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210705",
      "ric": "UOML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YWRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW211001",
      "ric": "UOML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VIFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW211008",
      "ric": "UOML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MPPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOL MB eCW210701",
      "ric": "ULML_t.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ORGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW210222",
      "ric": "WLML_t8.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NDHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW210331",
      "ric": "WLML_t7.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KSCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW211018",
      "ric": "WLML_t9.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NLGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBeCW210405",
      "ric": "XIML_t11.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FWDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBeCW210705",
      "ric": "XIML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WAIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBePW210405",
      "ric": "XIML_t12.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UDWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBePW210705",
      "ric": "XIML_t15.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UKKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "YangzijiMBeCW211101",
      "ric": "YZML_tx.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FOHW",
      "hide_date": null
    }
  ],
  "is_compressed": true,
  "livematrix": [
    {
      "id": "3890154793",
      "head_tail_id": "1",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29748.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.117",
      "bid": "0.116",
      "underlying_ask": "29749.000"
    },
    {
      "id": "3890154797",
      "head_tail_id": "1",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29733.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.117",
      "bid": "0.116",
      "underlying_ask": "29734.000"
    },
    {
      "id": "3890154801",
      "head_tail_id": "2",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29732.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.117",
      "bid": "0.115",
      "underlying_ask": "29733.000"
    },
    {
      "id": "3890154813",
      "head_tail_id": "2",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29716.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.116",
      "bid": "0.115",
      "underlying_ask": "29717.000"
    },
    {
      "id": "3890154817",
      "head_tail_id": "4",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29715.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.116",
      "bid": "0.114",
      "underlying_ask": "29716.000"
    },
    {
      "id": "3890154829",
      "head_tail_id": "4",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29699.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.115",
      "bid": "0.114",
      "underlying_ask": "29700.000"
    },
    {
      "id": "3890154833",
      "head_tail_id": "6",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29698.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.115",
      "bid": "0.113",
      "underlying_ask": "29699.000"
    },
    {
      "id": "3890154845",
      "head_tail_id": "6",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29682.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.114",
      "bid": "0.113",
      "underlying_ask": "29683.000"
    },
    {
      "id": "3890154853",
      "head_tail_id": "8",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29681.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.114",
      "bid": "0.112",
      "underlying_ask": "29682.000"
    },
    {
      "id": "3890154861",
      "head_tail_id": "8",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29665.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.113",
      "bid": "0.112",
      "underlying_ask": "29666.000"
    },
    {
      "id": "3890154865",
      "head_tail_id": "10",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29664.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.113",
      "bid": "0.111",
      "underlying_ask": "29665.000"
    },
    {
      "id": "3890154877",
      "head_tail_id": "10",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29648.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.112",
      "bid": "0.111",
      "underlying_ask": "29649.000"
    },
    {
      "id": "3890154881",
      "head_tail_id": "12",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29647.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.112",
      "bid": "0.110",
      "underlying_ask": "29648.000"
    },
    {
      "id": "3890154893",
      "head_tail_id": "12",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29630.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.111",
      "bid": "0.110",
      "underlying_ask": "29631.000"
    },
    {
      "id": "3890154897",
      "head_tail_id": "14",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29629.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.110",
      "bid": "0.109",
      "underlying_ask": "29630.000"
    },
    {
      "id": "3890154901",
      "head_tail_id": "14",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29613.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.110",
      "bid": "0.109",
      "underlying_ask": "29614.000"
    },
    {
      "id": "3890154905",
      "head_tail_id": "15",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29612.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.109",
      "bid": "0.108",
      "underlying_ask": "29613.000"
    },
    {
      "id": "3890154909",
      "head_tail_id": "15",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29595.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.109",
      "bid": "0.108",
      "underlying_ask": "29596.000"
    },
    {
      "id": "3890154913",
      "head_tail_id": "16",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29594.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.108",
      "bid": "0.107",
      "underlying_ask": "29595.000"
    },
    {
      "id": "3890154917",
      "head_tail_id": "16",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29578.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.108",
      "bid": "0.107",
      "underlying_ask": "29579.000"
    },
    {
      "id": "3890154921",
      "head_tail_id": "17",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29577.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.107",
      "bid": "0.106",
      "underlying_ask": "29578.000"
    },
    {
      "id": "3890154925",
      "head_tail_id": "17",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29560.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.107",
      "bid": "0.106",
      "underlying_ask": "29561.000"
    },
    {
      "id": "3890154929",
      "head_tail_id": "18",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29559.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.106",
      "bid": "0.105",
      "underlying_ask": "29560.000"
    },
    {
      "id": "3890154933",
      "head_tail_id": "18",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29542.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.106",
      "bid": "0.105",
      "underlying_ask": "29543.000"
    },
    {
      "id": "3890154937",
      "head_tail_id": "19",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29541.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.105",
      "bid": "0.104",
      "underlying_ask": "29542.000"
    },
    {
      "id": "3890154941",
      "head_tail_id": "19",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29524.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.105",
      "bid": "0.104",
      "underlying_ask": "29525.000"
    },
    {
      "id": "3890154945",
      "head_tail_id": "20",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29523.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.104",
      "bid": "0.103",
      "underlying_ask": "29524.000"
    },
    {
      "id": "3890154949",
      "head_tail_id": "20",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29506.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.104",
      "bid": "0.103",
      "underlying_ask": "29507.000"
    },
    {
      "id": "3890154953",
      "head_tail_id": "21",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29505.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.103",
      "bid": "0.102",
      "underlying_ask": "29506.000"
    },
    {
      "id": "3890154957",
      "head_tail_id": "21",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29488.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.103",
      "bid": "0.102",
      "underlying_ask": "29489.000"
    },
    {
      "id": "3890154961",
      "head_tail_id": "22",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29487.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.102",
      "bid": "0.101",
      "underlying_ask": "29488.000"
    },
    {
      "id": "3890154965",
      "head_tail_id": "22",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29470.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.102",
      "bid": "0.101",
      "underlying_ask": "29471.000"
    },
    {
      "id": "3890154969",
      "head_tail_id": "23",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29469.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.101",
      "bid": "0.100",
      "underlying_ask": "29470.000"
    },
    {
      "id": "3890154973",
      "head_tail_id": "23",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29451.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.101",
      "bid": "0.100",
      "underlying_ask": "29452.000"
    },
    {
      "id": "3890154977",
      "head_tail_id": "24",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29450.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.100",
      "bid": "0.099",
      "underlying_ask": "29451.000"
    },
    {
      "id": "3890154981",
      "head_tail_id": "24",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29433.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.100",
      "bid": "0.099",
      "underlying_ask": "29434.000"
    },
    {
      "id": "3890154985",
      "head_tail_id": "25",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29432.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.099",
      "bid": "0.098",
      "underlying_ask": "29433.000"
    },
    {
      "id": "3890154989",
      "head_tail_id": "25",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29414.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.099",
      "bid": "0.098",
      "underlying_ask": "29415.000"
    },
    {
      "id": "3890154993",
      "head_tail_id": "26",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29413.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.098",
      "bid": "0.097",
      "underlying_ask": "29414.000"
    },
    {
      "id": "3890154997",
      "head_tail_id": "26",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29395.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.098",
      "bid": "0.097",
      "underlying_ask": "29396.000"
    },
    {
      "id": "3890155001",
      "head_tail_id": "27",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29394.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.097",
      "bid": "0.096",
      "underlying_ask": "29395.000"
    },
    {
      "id": "3890155005",
      "head_tail_id": "27",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29376.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.097",
      "bid": "0.096",
      "underlying_ask": "29377.000"
    },
    {
      "id": "3890155009",
      "head_tail_id": "28",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29375.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.096",
      "bid": "0.095",
      "underlying_ask": "29376.000"
    },
    {
      "id": "3890155013",
      "head_tail_id": "28",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29357.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.096",
      "bid": "0.095",
      "underlying_ask": "29358.000"
    },
    {
      "id": "3890155017",
      "head_tail_id": "29",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29356.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.095",
      "bid": "0.094",
      "underlying_ask": "29357.000"
    },
    {
      "id": "3890155021",
      "head_tail_id": "29",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29338.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.095",
      "bid": "0.094",
      "underlying_ask": "29339.000"
    },
    {
      "id": "3890155025",
      "head_tail_id": "30",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29337.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.094",
      "bid": "0.093",
      "underlying_ask": "29338.000"
    },
    {
      "id": "3890155029",
      "head_tail_id": "30",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29319.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.094",
      "bid": "0.093",
      "underlying_ask": "29320.000"
    },
    {
      "id": "3890155033",
      "head_tail_id": "31",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29318.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.093",
      "bid": "0.092",
      "underlying_ask": "29319.000"
    },
    {
      "id": "3890155037",
      "head_tail_id": "31",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29299.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.093",
      "bid": "0.092",
      "underlying_ask": "29300.000"
    },
    {
      "id": "3890155041",
      "head_tail_id": "32",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29298.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.092",
      "bid": "0.091",
      "underlying_ask": "29299.000"
    },
    {
      "id": "3890155045",
      "head_tail_id": "32",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29280.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.092",
      "bid": "0.091",
      "underlying_ask": "29281.000"
    },
    {
      "id": "3890155049",
      "head_tail_id": "33",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29279.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.091",
      "bid": "0.090",
      "underlying_ask": "29280.000"
    },
    {
      "id": "3890155053",
      "head_tail_id": "33",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29260.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.091",
      "bid": "0.090",
      "underlying_ask": "29261.000"
    },
    {
      "id": "3890155057",
      "head_tail_id": "34",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29259.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.090",
      "bid": "0.089",
      "underlying_ask": "29260.000"
    },
    {
      "id": "3890155061",
      "head_tail_id": "34",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29240.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.090",
      "bid": "0.089",
      "underlying_ask": "29241.000"
    },
    {
      "id": "3890155065",
      "head_tail_id": "35",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29239.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.089",
      "bid": "0.088",
      "underlying_ask": "29240.000"
    },
    {
      "id": "3890155069",
      "head_tail_id": "35",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29220.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.089",
      "bid": "0.088",
      "underlying_ask": "29221.000"
    },
    {
      "id": "3890155073",
      "head_tail_id": "36",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29219.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.088",
      "bid": "0.087",
      "underlying_ask": "29220.000"
    },
    {
      "id": "3890155077",
      "head_tail_id": "36",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29200.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.088",
      "bid": "0.087",
      "underlying_ask": "29201.000"
    },
    {
      "id": "3890155081",
      "head_tail_id": "37",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29199.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.087",
      "bid": "0.086",
      "underlying_ask": "29200.000"
    },
    {
      "id": "3890155085",
      "head_tail_id": "37",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29180.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.087",
      "bid": "0.086",
      "underlying_ask": "29181.000"
    },
    {
      "id": "3890155089",
      "head_tail_id": "38",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29179.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.086",
      "bid": "0.085",
      "underlying_ask": "29180.000"
    },
    {
      "id": "3890155093",
      "head_tail_id": "38",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29159.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.086",
      "bid": "0.085",
      "underlying_ask": "29160.000"
    },
    {
      "id": "3890155097",
      "head_tail_id": "39",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29158.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.085",
      "bid": "0.084",
      "underlying_ask": "29159.000"
    },
    {
      "id": "3890155101",
      "head_tail_id": "39",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29138.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.085",
      "bid": "0.084",
      "underlying_ask": "29139.000"
    },
    {
      "id": "3890155105",
      "head_tail_id": "40",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29137.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.084",
      "bid": "0.083",
      "underlying_ask": "29138.000"
    },
    {
      "id": "3890155109",
      "head_tail_id": "40",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29117.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.084",
      "bid": "0.083",
      "underlying_ask": "29118.000"
    },
    {
      "id": "3890155113",
      "head_tail_id": "41",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29116.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.083",
      "bid": "0.082",
      "underlying_ask": "29117.000"
    },
    {
      "id": "3890155117",
      "head_tail_id": "41",
      "last_update": "2021-02-09 09:30:45.0",
      "publish_time": "2021-02-09 17:30:43.0",
      "underlying_bid": "29096.000",
      "is_compressed": "true",
      "create_for_date": "2021-02-09",
      "security_code": "HGKW",
      "ask": "0.083",
      "bid": "0.082",
      "underlying_ask": "29097.000"
    }
  ],
  "ric_data": {
    "underlying_ric": "HSIc1",
    "b_trbc_business": "null",
    "theta": "-0.27",
    "TRDPRC_1": "0.098",
    "percent_moneyness": "3",
    "last_trading_date": "23 Mar 21",
    "HIGH_1": "0.169",
    "type": "CALL",
    "effective_gearing": "16.3",
    "conv_ratio": "1,200.0",
    "wrnt_per_share": "0.001",
    "BID_PCTCHNG_2DP": "+11.36",
    "LOW_1": "0.040",
    "breakeven_price": "31,084.12",
    "ticker": "HGKW",
    "30D_LOW": "-",
    "underlying_pctchng": "+0.70",
    "delta_per_wrnt": "0.032",
    "warrant_price": "0.098000000",
    "OPEN_PRC": "0.042",
    "TURNOVER_SCALED": "4,831",
    "NO_BIDMKR2": "-",
    "dsply_nmll": "HSI 30400MBeCW210330",
    "sensitivity": "18.44",
    "issuer_name": "Macquarie Bank Ltd",
    "delta": "37.9",
    "TRADE_DATE": "09 Feb 21",
    "NO_BIDMKR2_SCALED": "-",
    "underlying_price_adj": "5052.141000000",
    "BID_PCTCHNG": "+11.4",
    "BIDSIZE": "3,000,000",
    "underlying_price_cal": "29390.000000000",
    "exercise_price_adj": "5225.760000000",
    "update_time": "09 Feb 21 16:27",
    "dsply_name": "HSI 30400MBeCW210330",
    "YRHIGH": "-",
    "HST_CLOSE": "0.088",
    "ACVOL_1_SCALED": "51,638",
    "BID": "0.098",
    "r_hst_close": "0.1719000000",
    "maturity": "30 Mar 21",
    "div_yield": "1.204538462",
    "underlying_bid": "29390.000000000",
    "fmt": "1",
    "30D_HIGH": "-",
    "HST_VOL_SCALED": "-",
    "underlying_price": "29,390.00",
    "list_date": "08 Jan 21",
    "exchange_code": "SES",
    "underlying_curr": "HKD",
    "currency": "SGD",
    "ASK": "0.099",
    "conv_ratio_cal": "1200.000480000",
    "NETCHNG_1": "-",
    "BID_NETCHNG": "+0.010",
    "HST_VOL": "-",
    "implied_volatility": "25.4",
    "VOL_PCTCHNG": "-",
    "underlying_bid_lm": "29390.000000000",
    "moneyness": "2",
    "ASKSIZE": "3,300,000",
    "premium": "5.8",
    "intrinsic_value": "0.000",
    "underlying_ticker": "HSI",
    "issuer_code": "MBL",
    "exercise_price_cal": "30400.000000000",
    "TURNOVER": "4,831,239",
    "underlying_name": "HSI",
    "PCTCHNG": "-",
    "YRLOW": "-",
    "ACVOL_1": "51,637,700",
    "days_to_maturity": "49",
    "ric": "HSML_t212.SI",
    "exercise_price": "30,400.00"
  }
}

const livematrixDataCase1_2 = {
  "last_update": "09 Feb 21 14:04",
  "symbols": [
    {
      "dsply_nmll": "AEM MB eCW210302",
      "ric": "AEML_tk.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "983W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AEM MB eCW210917",
      "ric": "AEML_tm.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VKEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Alibaba MB eCW210705",
      "ric": "ALML_th.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "R42W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Alibaba MB ePW210705",
      "ric": "ALML_tg.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "QBDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "AlibabaMBeCW210405",
      "ric": "ALML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "1M7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AlibabaMBePW210405",
      "ric": "ALML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "X7RW",
      "hide_date": null
    },
    {
      "dsply_nmll": "AscendasReit MBeCW210302",
      "ric": "AEML_tj.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WQ6W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AscendasReit MBeCW210524",
      "ric": "AEML_tl.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RQOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210302",
      "ric": "BYML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XATW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210405",
      "ric": "BYML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VHUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210705",
      "ric": "BYML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "TMHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210705A",
      "ric": "BYML_tg.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MGVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210302",
      "ric": "BYML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NYBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210405",
      "ric": "BYML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RUQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210705",
      "ric": "BYML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YBMW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210705A",
      "ric": "BYML_th.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VRJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW210222",
      "ric": "CAML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WU1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW210304",
      "ric": "CAML_t3.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "HUFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW211018",
      "ric": "CAML_t4.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MHJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CityDev MB eCW210507",
      "ric": "CTML_tt.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LQXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "ComfortDel MBeCW210301",
      "ric": "CMML_t1.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XLJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "ComfortDel MBeCW211018",
      "ric": "CMML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MSHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210405",
      "ric": "DBML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WRAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210407",
      "ric": "DBML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EYOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210503",
      "ric": "DBML_t38.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "HRZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW211001",
      "ric": "DBML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9WJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW211008",
      "ric": "DBML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OFHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210217",
      "ric": "DBML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XPUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210701",
      "ric": "DBML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YY9W",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210705",
      "ric": "DBML_t37.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "QYLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW211001",
      "ric": "DBML_t36.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KEDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW211008",
      "ric": "DBML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RWXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW210405",
      "ric": "GEML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RQUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW210705",
      "ric": "GEML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RUHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW211005",
      "ric": "GEML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XQDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW210405",
      "ric": "GEML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XZ4W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW210705",
      "ric": "GEML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OCCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW211005",
      "ric": "GEML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VZGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "GentingSMBeCW210701",
      "ric": "GTML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XJLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 24800MBePW210528",
      "ric": "HSML_t210.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TPCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25000MBePW210225",
      "ric": "HSML_t189.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VOIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25400MBePW210429",
      "ric": "HSML_t204.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FMPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25800MBePW210330",
      "ric": "HSML_t197.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "KWBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26000MBePW210225",
      "ric": "HSML_t191.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "JXBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26200MBePW210429",
      "ric": "HSML_t205.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TNSW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBeCW210225",
      "ric": "HSML_t193.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "UCEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBeCW210528",
      "ric": "HSML_t209.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OKCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBePW210330",
      "ric": "HSML_t196.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "NMKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27000MBePW210429",
      "ric": "HSML_t199.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "8XWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27200MBeCW210330",
      "ric": "HSML_t195.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "W6JW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27400MBeCW210225",
      "ric": "HSML_t192.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FCHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27400MBePW210330",
      "ric": "HSML_t208.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "EEKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27600MBeCW210429",
      "ric": "HSML_t200.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "GJDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28000MBeCW210330",
      "ric": "HSML_t194.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OMCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28000MBePW210528",
      "ric": "HSML_t207.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "1HZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28200MBeCW210225",
      "ric": "HSML_t190.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "8Y4W",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28400MBeCW210429",
      "ric": "HSML_t201.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "SGAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28600MBePW210429",
      "ric": "HSML_t206.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "CVCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28800MBeCW210330",
      "ric": "HSML_t198.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "QHEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 29200MBeCW210429",
      "ric": "HSML_t202.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "DZBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 30000MBeCW210429",
      "ric": "HSML_t203.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XLDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 30400MBeCW210330",
      "ric": "HSML_t212.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "HGKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 31200MBeCW210528",
      "ric": "HSML_t211.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "Z7PW",
      "hide_date": null
    },
    {
      "dsply_nmll": "IFAST MB eCW210601",
      "ric": "IFML_tu.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KTRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "JD MB eCW210705",
      "ric": "JDML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OPSW",
      "hide_date": null
    },
    {
      "dsply_nmll": "JD MB ePW210705",
      "ric": "JDML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "DVDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorp MBeCW210222",
      "ric": "KPML_t8.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "SJOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorpMBeCW210304",
      "ric": "KPML_t9.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NMBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorpMBeCW211018",
      "ric": "KPML_t10.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WFEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "MapleComTr MB eCW210302",
      "ric": "MCML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9EFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "MapleComTr MB eCW211018",
      "ric": "MAML_t.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "TRGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB eCW210405",
      "ric": "MEML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JAOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB eCW210705",
      "ric": "MEML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YC8W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB ePW210405",
      "ric": "MEML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YLAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB ePW210705",
      "ric": "MEML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MXTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 19000MBePW210312",
      "ric": "NIML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "WG8W",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 20000MBePW210611",
      "ric": "NIML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "HASW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 22000MBePW210312",
      "ric": "NIML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "UAFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 23000MBePW210611",
      "ric": "NIML_t28.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XDQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 24000MBeCW210312",
      "ric": "NIML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "PJLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 25000MBeCW210611",
      "ric": "NIML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "ZALW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 25000MBePW210312",
      "ric": "NIML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VTWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 26000MBePW210611",
      "ric": "NIML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "EOGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 27000MBeCW210312",
      "ric": "NIML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FIQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 28000MBeCW210611",
      "ric": "NIML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TCFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 29000MBePW210611",
      "ric": "NIML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "9AFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 30000MBeCW210312",
      "ric": "NIML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "YTXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 31000MBeCW210611",
      "ric": "NIML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "LXLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210301",
      "ric": "OCML_t11.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XV1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210303",
      "ric": "OCML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JSYW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210507",
      "ric": "OCML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ZRDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210510",
      "ric": "OCML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LQFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210514",
      "ric": "OCML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GWWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW211112",
      "ric": "OCML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WZ1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW211115",
      "ric": "OCML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VYWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210217",
      "ric": "OCML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "IEIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210701",
      "ric": "OCML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EUEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210705",
      "ric": "OCML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9I5W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW211112",
      "ric": "OCML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "95JW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW211115",
      "ric": "OCML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JWRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 2700MBePW210319",
      "ric": "SXML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "IQZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 2950MBePW210319",
      "ric": "SXML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "DOHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3000MBePW210618",
      "ric": "SXML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XYPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3400MBePW210618",
      "ric": "SXML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OKBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3800MBeCW210618",
      "ric": "SXML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "RUUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3950MBeCW210319",
      "ric": "SXML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VQEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 4200MBeCW210618",
      "ric": "SXML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "GTJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB eCW210315",
      "ric": "SNML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9QJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB eCW211018",
      "ric": "SNML_t4.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ESYW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB ePW210315",
      "ric": "SNML_t1.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RXPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB ePW211018",
      "ric": "SNML_t3.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UJGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SIA MB eCW210701",
      "ric": "SLML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GIJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210222",
      "ric": "SEML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YX7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210304",
      "ric": "SEML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GJAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210331",
      "ric": "SEML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YBNW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW211018",
      "ric": "SEML_t7.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "59XW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2300MBePW210331",
      "ric": "STML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WVEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2400MBePW210630",
      "ric": "STML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OSTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2500MBePW210331",
      "ric": "STML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "DFAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2500MBePW210930",
      "ric": "STML_t42.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JUUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2550MBeCW210331",
      "ric": "STML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "8C5W",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2600MBePW210630",
      "ric": "STML_t39.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VVZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2650MBeCW210630",
      "ric": "STML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XINW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2750MBePW210930",
      "ric": "STML_t41.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JUPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBeCW210331",
      "ric": "STML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "URDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBeCW210930",
      "ric": "STML_t40.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UNCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBePW210630",
      "ric": "STML_t36.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YZHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2900MBeCW210630",
      "ric": "STML_t37.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PXKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3000MBeCW210331",
      "ric": "STML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VSBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3000MBePW210930",
      "ric": "STML_t45.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PCEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3050MBeCW210930",
      "ric": "STML_t44.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "V6OW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3100MBeCW210630",
      "ric": "STML_t38.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EZEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3300MBeCW210930",
      "ric": "STML_t43.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LZTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB eCW210405",
      "ric": "SUML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9T9W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB eCW210705",
      "ric": "SUML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WAVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB ePW210405",
      "ric": "SUML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PDGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB ePW210705",
      "ric": "SUML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "8P7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBeCW210302",
      "ric": "TCML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "Q31W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBeCW210705",
      "ric": "TCML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PHDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBePW210302",
      "ric": "TCML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WL7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBePW210705",
      "ric": "TCML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WVDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Top Glove MB eCW210302",
      "ric": "TOML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WHGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Top Glove MB eCW210917",
      "ric": "TOPG_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "1P7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210405",
      "ric": "UOML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "SMOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210407",
      "ric": "UOML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FSXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210503",
      "ric": "UOML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "V8DW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210506",
      "ric": "UOML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PWBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210514",
      "ric": "UOML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ELVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211001",
      "ric": "UOML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XIIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211004",
      "ric": "UOML_t28.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KVKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211008",
      "ric": "UOML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RABW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210217",
      "ric": "UOML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9KVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210701",
      "ric": "UOML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "X0RW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210705",
      "ric": "UOML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YWRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW211001",
      "ric": "UOML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VIFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW211008",
      "ric": "UOML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MPPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOL MB eCW210701",
      "ric": "ULML_t.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ORGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW210222",
      "ric": "WLML_t8.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NDHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW210331",
      "ric": "WLML_t7.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KSCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW211018",
      "ric": "WLML_t9.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NLGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBeCW210405",
      "ric": "XIML_t11.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FWDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBeCW210705",
      "ric": "XIML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WAIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBePW210405",
      "ric": "XIML_t12.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UDWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBePW210705",
      "ric": "XIML_t15.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UKKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "YangzijiMBeCW211101",
      "ric": "YZML_tx.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FOHW",
      "hide_date": null
    }
  ],
  "is_compressed": false,
  "holidays": [
    
  ],
  "livematrix": [
    {
      "id": 3888772181,
      "underlying_bid": 4.490,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.110,
      "bid": 0.108,
      "underlying_ask": 4.500
    },
    {
      "id": 3888772185,
      "underlying_bid": 4.480,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.109,
      "bid": 0.107,
      "underlying_ask": 4.490
    },
    {
      "id": 3888772189,
      "underlying_bid": 4.470,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.109,
      "bid": 0.107,
      "underlying_ask": 4.480
    },
    {
      "id": 3888772193,
      "underlying_bid": 4.460,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.108,
      "bid": 0.106,
      "underlying_ask": 4.470
    },
    {
      "id": 3888772197,
      "underlying_bid": 4.450,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.108,
      "bid": 0.106,
      "underlying_ask": 4.460
    },
    {
      "id": 3888772201,
      "underlying_bid": 4.440,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.107,
      "bid": 0.105,
      "underlying_ask": 4.450
    },
    {
      "id": 3888772205,
      "underlying_bid": 4.430,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.106,
      "bid": 0.104,
      "underlying_ask": 4.440
    },
    {
      "id": 3888772209,
      "underlying_bid": 4.420,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.106,
      "bid": 0.104,
      "underlying_ask": 4.430
    },
    {
      "id": 3888772213,
      "underlying_bid": 4.410,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.105,
      "bid": 0.103,
      "underlying_ask": 4.420
    },
    {
      "id": 3888772217,
      "underlying_bid": 4.400,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.105,
      "bid": 0.103,
      "underlying_ask": 4.410
    },
    {
      "id": 3888772221,
      "underlying_bid": 4.390,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.104,
      "bid": 0.102,
      "underlying_ask": 4.400
    },
    {
      "id": 3888772225,
      "underlying_bid": 4.380,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.104,
      "bid": 0.102,
      "underlying_ask": 4.390
    },
    {
      "id": 3888772229,
      "underlying_bid": 4.370,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.103,
      "bid": 0.101,
      "underlying_ask": 4.380
    },
    {
      "id": 3888772233,
      "underlying_bid": 4.360,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.103,
      "bid": 0.101,
      "underlying_ask": 4.370
    },
    {
      "id": 3888772237,
      "underlying_bid": 4.350,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.102,
      "bid": 0.100,
      "underlying_ask": 4.360
    },
    {
      "id": 3888772241,
      "underlying_bid": 4.340,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.102,
      "bid": 0.100,
      "underlying_ask": 4.350
    },
    {
      "id": 3888772245,
      "underlying_bid": 4.330,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.101,
      "bid": 0.099,
      "underlying_ask": 4.340
    },
    {
      "id": 3888772249,
      "underlying_bid": 4.320,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.100,
      "bid": 0.098,
      "underlying_ask": 4.330
    },
    {
      "id": 3888772253,
      "underlying_bid": 4.310,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.100,
      "bid": 0.098,
      "underlying_ask": 4.320
    },
    {
      "id": 3888772257,
      "underlying_bid": 4.300,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.099,
      "bid": 0.097,
      "underlying_ask": 4.310
    },
    {
      "id": 3888772261,
      "underlying_bid": 4.290,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.099,
      "bid": 0.097,
      "underlying_ask": 4.300
    },
    {
      "id": 3888772265,
      "underlying_bid": 4.280,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.098,
      "bid": 0.096,
      "underlying_ask": 4.290
    },
    {
      "id": 3888772269,
      "underlying_bid": 4.270,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.098,
      "bid": 0.096,
      "underlying_ask": 4.280
    },
    {
      "id": 3888772273,
      "underlying_bid": 4.260,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.097,
      "bid": 0.095,
      "underlying_ask": 4.270
    },
    {
      "id": 3888772277,
      "underlying_bid": 4.250,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.097,
      "bid": 0.095,
      "underlying_ask": 4.260
    },
    {
      "id": 3888772281,
      "underlying_bid": 4.240,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.096,
      "bid": 0.094,
      "underlying_ask": 4.250
    },
    {
      "id": 3888772285,
      "underlying_bid": 4.230,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.096,
      "bid": 0.094,
      "underlying_ask": 4.240
    },
    {
      "id": 3888772289,
      "underlying_bid": 4.220,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.095,
      "bid": 0.093,
      "underlying_ask": 4.230
    },
    {
      "id": 3888772293,
      "underlying_bid": 4.210,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.095,
      "bid": 0.093,
      "underlying_ask": 4.220
    },
    {
      "id": 3888772297,
      "underlying_bid": 4.200,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.094,
      "bid": 0.092,
      "underlying_ask": 4.210
    },
    {
      "id": 3888772301,
      "underlying_bid": 4.190,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.094,
      "bid": 0.092,
      "underlying_ask": 4.200
    },
    {
      "id": 3888772305,
      "underlying_bid": 4.180,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.093,
      "bid": 0.091,
      "underlying_ask": 4.190
    },
    {
      "id": 3888772309,
      "underlying_bid": 4.170,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.092,
      "bid": 0.090,
      "underlying_ask": 4.180
    },
    {
      "id": 3888772313,
      "underlying_bid": 4.160,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.092,
      "bid": 0.090,
      "underlying_ask": 4.170
    },
    {
      "id": 3888772317,
      "underlying_bid": 4.150,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.091,
      "bid": 0.089,
      "underlying_ask": 4.160
    },
    {
      "id": 3888772321,
      "underlying_bid": 4.140,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.091,
      "bid": 0.089,
      "underlying_ask": 4.150
    },
    {
      "id": 3888772325,
      "underlying_bid": 4.130,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.090,
      "bid": 0.088,
      "underlying_ask": 4.140
    },
    {
      "id": 3888772329,
      "underlying_bid": 4.120,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.090,
      "bid": 0.088,
      "underlying_ask": 4.130
    },
    {
      "id": 3888772333,
      "underlying_bid": 4.110,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.089,
      "bid": 0.087,
      "underlying_ask": 4.120
    },
    {
      "id": 3888772337,
      "underlying_bid": 4.100,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.089,
      "bid": 0.087,
      "underlying_ask": 4.110
    },
    {
      "id": 3888772341,
      "underlying_bid": 4.090,
      "publish_time": "2021-02-09 14:04:59",
      "ask": 0.088,
      "bid": 0.086,
      "underlying_ask": 4.100
    }
  ],
  "ric_data": {
    "underlying_ric": "AEM.SI",
    "b_trbc_business": null,
    "theta": "-0.20",
    "LOLIMIT": "0",
    "TRDPRC_1": "0.098",
    "percent_moneyness": "2",
    "last_trading_date": "10 Sep 21",
    "HIGH_1": "0.114",
    "type": "CALL",
    "effective_gearing": "2.3",
    "conv_ratio": "12.0",
    "wrnt_per_share": "0.083",
    "LOW_1": "0.059",
    "BID_PCTCHNG_2DP": "0.00",
    "breakeven_price": "5.36",
    "ticker": "VKEW",
    "30D_LOW": "-",
    "underlying_pctchng": "-0.23",
    "delta_per_wrnt": "5.300",
    "warrant_price": 0.097000000,
    "OPEN_PRC": "0.068",
    "TURNOVER_SCALED": "0",
    "NO_BIDMKR2": "-",
    "dsply_nmll": "AEM MB eCW210917",
    "issuer_name": "Macquarie Bank Ltd",
    "sensitivity": "1.89",
    "delta": "63.6",
    "TRADE_DATE": "08 Feb 21",
    "NO_BIDMKR2_SCALED": "-",
    "underlying_price_adj": 4.290000000,
    "BID_PCTCHNG": "0.0",
    "BIDSIZE": "500,000",
    "underlying_price_cal": 4.29,
    "exercise_price_adj": 4.200000000,
    "update_time": "09 Feb 21 15:02",
    "dsply_name": "AEM MB eCW210917",
    "YRHIGH": "-",
    "BEST_BSIZ1": "500000",
    "HST_CLOSE": "0.097",
    "ACVOL_1_SCALED": "0",
    "BID": "0.097",
    "r_hst_close": 1,
    "maturity": "17 Sep 21",
    "div_yield": 2.027972000,
    "underlying_bid": 4.29,
    "fmt": 1,
    "30D_HIGH": "-",
    "SYMBOL": "AEML_tm.SI",
    "underlying_price": "4.29",
    "HST_VOL_SCALED": "-",
    "list_date": "17 Dec 20",
    "exchange_code": "SES",
    "underlying_curr": "SGD",
    "currency": "SGD",
    "ASK": "0.099",
    "conv_ratio_cal": 12.000000048,
    "UPLIMIT": "0",
    "NETCHNG_1": "0.0",
    "BID_NETCHNG": "0.000",
    "HST_VOL": "-",
    "implied_volatility": "88.2",
    "VOL_PCTCHNG": "-",
    "underlying_bid_lm": 4.29,
    "TRDTIM_1": "  :",
    "moneyness": "1",
    "ASKSIZE": "500,000",
    "premium": "25.0",
    "SERVICE": "ELEKTRON_DD",
    "intrinsic_value": "0.008",
    "underlying_ticker": "AWX",
    "issuer_code": "MBL",
    "exercise_price_cal": 4.200000000,
    "TURNOVER": "0",
    "underlying_name": "AEM HOLDINGS LTD",
    "REC_STATUS": "0",
    "PCTCHNG": "0.0",
    "YRLOW": "-",
    "BEST_ASIZ1": "500000",
    "ACVOL_1": "0",
    "days_to_maturity": "220",
    "ric": "AEML_tm.SI",
    "exercise_price": "4.20"
  }
}

const livematrixDataCase2_2 = {
  "last_update": "09 Feb 21 15:14",
  "symbols": [
    {
      "dsply_nmll": "AEM MB eCW210302",
      "ric": "AEML_tk.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "983W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AEM MB eCW210917",
      "ric": "AEML_tm.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VKEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Alibaba MB eCW210705",
      "ric": "ALML_th.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "R42W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Alibaba MB ePW210705",
      "ric": "ALML_tg.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "QBDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "AlibabaMBeCW210405",
      "ric": "ALML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "1M7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AlibabaMBePW210405",
      "ric": "ALML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "X7RW",
      "hide_date": null
    },
    {
      "dsply_nmll": "AscendasReit MBeCW210302",
      "ric": "AEML_tj.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WQ6W",
      "hide_date": null
    },
    {
      "dsply_nmll": "AscendasReit MBeCW210524",
      "ric": "AEML_tl.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RQOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210302",
      "ric": "BYML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XATW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210405",
      "ric": "BYML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VHUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210705",
      "ric": "BYML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "TMHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB eCW210705A",
      "ric": "BYML_tg.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MGVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210302",
      "ric": "BYML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NYBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210405",
      "ric": "BYML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RUQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210705",
      "ric": "BYML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YBMW",
      "hide_date": null
    },
    {
      "dsply_nmll": "BYD MB ePW210705A",
      "ric": "BYML_th.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VRJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW210222",
      "ric": "CAML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WU1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW210304",
      "ric": "CAML_t3.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "HUFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CapitalaMBeCW211018",
      "ric": "CAML_t4.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MHJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "CityDev MB eCW210507",
      "ric": "CTML_tt.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LQXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "ComfortDel MBeCW210301",
      "ric": "CMML_t1.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XLJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "ComfortDel MBeCW211018",
      "ric": "CMML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MSHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210405",
      "ric": "DBML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WRAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210407",
      "ric": "DBML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EYOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW210503",
      "ric": "DBML_t38.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "HRZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW211001",
      "ric": "DBML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9WJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB eCW211008",
      "ric": "DBML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OFHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210217",
      "ric": "DBML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XPUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210701",
      "ric": "DBML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YY9W",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW210705",
      "ric": "DBML_t37.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "QYLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW211001",
      "ric": "DBML_t36.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KEDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "DBS MB ePW211008",
      "ric": "DBML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RWXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW210405",
      "ric": "GEML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RQUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW210705",
      "ric": "GEML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RUHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB eCW211005",
      "ric": "GEML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XQDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW210405",
      "ric": "GEML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XZ4W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW210705",
      "ric": "GEML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OCCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Geely MB ePW211005",
      "ric": "GEML_tf.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VZGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "GentingSMBeCW210701",
      "ric": "GTML_te.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XJLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 24800MBePW210528",
      "ric": "HSML_t210.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TPCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25000MBePW210225",
      "ric": "HSML_t189.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VOIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25400MBePW210429",
      "ric": "HSML_t204.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FMPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 25800MBePW210330",
      "ric": "HSML_t197.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "KWBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26000MBePW210225",
      "ric": "HSML_t191.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "JXBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26200MBePW210429",
      "ric": "HSML_t205.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TNSW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBeCW210225",
      "ric": "HSML_t193.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "UCEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBeCW210528",
      "ric": "HSML_t209.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OKCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 26600MBePW210330",
      "ric": "HSML_t196.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "NMKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27000MBePW210429",
      "ric": "HSML_t199.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "8XWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27200MBeCW210330",
      "ric": "HSML_t195.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "W6JW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27400MBeCW210225",
      "ric": "HSML_t192.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FCHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27400MBePW210330",
      "ric": "HSML_t208.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "EEKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 27600MBeCW210429",
      "ric": "HSML_t200.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "GJDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28000MBeCW210330",
      "ric": "HSML_t194.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OMCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28000MBePW210528",
      "ric": "HSML_t207.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "1HZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28200MBeCW210225",
      "ric": "HSML_t190.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "8Y4W",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28400MBeCW210429",
      "ric": "HSML_t201.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "SGAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28600MBePW210429",
      "ric": "HSML_t206.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "CVCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 28800MBeCW210330",
      "ric": "HSML_t198.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "QHEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 29200MBeCW210429",
      "ric": "HSML_t202.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "DZBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 30000MBeCW210429",
      "ric": "HSML_t203.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XLDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 30400MBeCW210330",
      "ric": "HSML_t212.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "HGKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 31200MBeCW210528",
      "ric": "HSML_t211.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "Z7PW",
      "hide_date": null
    },
    {
      "dsply_nmll": "IFAST MB eCW210601",
      "ric": "IFML_tu.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KTRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "JD MB eCW210705",
      "ric": "JDML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OPSW",
      "hide_date": null
    },
    {
      "dsply_nmll": "JD MB ePW210705",
      "ric": "JDML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "DVDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorp MBeCW210222",
      "ric": "KPML_t8.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "SJOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorpMBeCW210304",
      "ric": "KPML_t9.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NMBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "KepCorpMBeCW211018",
      "ric": "KPML_t10.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WFEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "MapleComTr MB eCW210302",
      "ric": "MCML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9EFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "MapleComTr MB eCW211018",
      "ric": "MAML_t.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "TRGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB eCW210405",
      "ric": "MEML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JAOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB eCW210705",
      "ric": "MEML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YC8W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB ePW210405",
      "ric": "MEML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YLAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Meituan MB ePW210705",
      "ric": "MEML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MXTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 19000MBePW210312",
      "ric": "NIML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "WG8W",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 20000MBePW210611",
      "ric": "NIML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "HASW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 22000MBePW210312",
      "ric": "NIML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "UAFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 23000MBePW210611",
      "ric": "NIML_t28.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XDQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 24000MBeCW210312",
      "ric": "NIML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "PJLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 25000MBeCW210611",
      "ric": "NIML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "ZALW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 25000MBePW210312",
      "ric": "NIML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VTWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 26000MBePW210611",
      "ric": "NIML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "EOGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 27000MBeCW210312",
      "ric": "NIML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "FIQW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 28000MBeCW210611",
      "ric": "NIML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "TCFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 29000MBePW210611",
      "ric": "NIML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "9AFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 30000MBeCW210312",
      "ric": "NIML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "YTXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "NKY 31000MBeCW210611",
      "ric": "NIML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "LXLW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210301",
      "ric": "OCML_t11.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XV1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210303",
      "ric": "OCML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JSYW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210507",
      "ric": "OCML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ZRDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210510",
      "ric": "OCML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LQFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW210514",
      "ric": "OCML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GWWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW211112",
      "ric": "OCML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WZ1W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB eCW211115",
      "ric": "OCML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VYWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210217",
      "ric": "OCML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "IEIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210701",
      "ric": "OCML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EUEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW210705",
      "ric": "OCML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9I5W",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW211112",
      "ric": "OCML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "95JW",
      "hide_date": null
    },
    {
      "dsply_nmll": "OCBC Bk MB ePW211115",
      "ric": "OCML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JWRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 2700MBePW210319",
      "ric": "SXML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "IQZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 2950MBePW210319",
      "ric": "SXML_t22.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "DOHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3000MBePW210618",
      "ric": "SXML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "XYPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3400MBePW210618",
      "ric": "SXML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "OKBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3800MBeCW210618",
      "ric": "SXML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "RUUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 3950MBeCW210319",
      "ric": "SXML_t21.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "VQEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "S&P 4200MBeCW210618",
      "ric": "SXML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "GTJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB eCW210315",
      "ric": "SNML_t2.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9QJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB eCW211018",
      "ric": "SNML_t4.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ESYW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB ePW210315",
      "ric": "SNML_t1.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RXPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SGX MB ePW211018",
      "ric": "SNML_t3.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UJGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SIA MB eCW210701",
      "ric": "SLML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GIJW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210222",
      "ric": "SEML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YX7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210304",
      "ric": "SEML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "GJAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW210331",
      "ric": "SEML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YBNW",
      "hide_date": null
    },
    {
      "dsply_nmll": "SingtelMBeCW211018",
      "ric": "SEML_t7.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "59XW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2300MBePW210331",
      "ric": "STML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WVEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2400MBePW210630",
      "ric": "STML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "OSTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2500MBePW210331",
      "ric": "STML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "DFAW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2500MBePW210930",
      "ric": "STML_t42.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JUUW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2550MBeCW210331",
      "ric": "STML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "8C5W",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2600MBePW210630",
      "ric": "STML_t39.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VVZW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2650MBeCW210630",
      "ric": "STML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XINW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2750MBePW210930",
      "ric": "STML_t41.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "JUPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBeCW210331",
      "ric": "STML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "URDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBeCW210930",
      "ric": "STML_t40.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UNCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2800MBePW210630",
      "ric": "STML_t36.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YZHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 2900MBeCW210630",
      "ric": "STML_t37.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PXKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3000MBeCW210331",
      "ric": "STML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VSBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3000MBePW210930",
      "ric": "STML_t45.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PCEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3050MBeCW210930",
      "ric": "STML_t44.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "V6OW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3100MBeCW210630",
      "ric": "STML_t38.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "EZEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "STI 3300MBeCW210930",
      "ric": "STML_t43.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "LZTW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB eCW210405",
      "ric": "SUML_tb.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9T9W",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB eCW210705",
      "ric": "SUML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WAVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB ePW210405",
      "ric": "SUML_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PDGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Sunny MB ePW210705",
      "ric": "SUML_td.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "8P7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBeCW210302",
      "ric": "TCML_t17.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "Q31W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBeCW210705",
      "ric": "TCML_t20.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PHDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBePW210302",
      "ric": "TCML_t18.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WL7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "TencentMBePW210705",
      "ric": "TCML_t19.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WVDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Top Glove MB eCW210302",
      "ric": "TOML_tc.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WHGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Top Glove MB eCW210917",
      "ric": "TOPG_ta.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "1P7W",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210405",
      "ric": "UOML_t24.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "SMOW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210407",
      "ric": "UOML_t25.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FSXW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210503",
      "ric": "UOML_t35.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "V8DW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210506",
      "ric": "UOML_t26.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "PWBW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW210514",
      "ric": "UOML_t34.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ELVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211001",
      "ric": "UOML_t31.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "XIIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211004",
      "ric": "UOML_t28.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KVKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB eCW211008",
      "ric": "UOML_t29.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "RABW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210217",
      "ric": "UOML_t23.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "9KVW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210701",
      "ric": "UOML_t27.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "X0RW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW210705",
      "ric": "UOML_t33.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "YWRW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW211001",
      "ric": "UOML_t32.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VIFW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOB MB ePW211008",
      "ric": "UOML_t30.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "MPPW",
      "hide_date": null
    },
    {
      "dsply_nmll": "UOL MB eCW210701",
      "ric": "ULML_t.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "ORGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW210222",
      "ric": "WLML_t8.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NDHW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW210331",
      "ric": "WLML_t7.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "KSCW",
      "hide_date": null
    },
    {
      "dsply_nmll": "Wilmar MB eCW211018",
      "ric": "WLML_t9.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "NLGW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBeCW210405",
      "ric": "XIML_t11.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FWDW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBeCW210705",
      "ric": "XIML_t16.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "WAIW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBePW210405",
      "ric": "XIML_t12.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UDWW",
      "hide_date": null
    },
    {
      "dsply_nmll": "XIAOMI MBePW210705",
      "ric": "XIML_t15.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "UKKW",
      "hide_date": null
    },
    {
      "dsply_nmll": "YangzijiMBeCW211101",
      "ric": "YZML_tx.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FOHW",
      "hide_date": null
    }
  ],
  "is_compressed": false,
  "holidays": [
    
  ],
  "livematrix": [
    {
      "id": 3889467121,
      "underlying_bid": 28.150,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.420,
      "bid": 0.405,
      "underlying_ask": 28.200
    },
    {
      "id": 3889467125,
      "underlying_bid": 28.100,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.420,
      "bid": 0.405,
      "underlying_ask": 28.150
    },
    {
      "id": 3889467129,
      "underlying_bid": 28.050,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.420,
      "bid": 0.400,
      "underlying_ask": 28.100
    },
    {
      "id": 3889467133,
      "underlying_bid": 28.000,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.415,
      "bid": 0.400,
      "underlying_ask": 28.050
    },
    {
      "id": 3889467137,
      "underlying_bid": 27.950,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.415,
      "bid": 0.395,
      "underlying_ask": 28.000
    },
    {
      "id": 3889467141,
      "underlying_bid": 27.900,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.410,
      "bid": 0.395,
      "underlying_ask": 27.950
    },
    {
      "id": 3889467145,
      "underlying_bid": 27.850,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.410,
      "bid": 0.390,
      "underlying_ask": 27.900
    },
    {
      "id": 3889467149,
      "underlying_bid": 27.800,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.405,
      "bid": 0.390,
      "underlying_ask": 27.850
    },
    {
      "id": 3889467153,
      "underlying_bid": 27.750,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.405,
      "bid": 0.385,
      "underlying_ask": 27.800
    },
    {
      "id": 3889467157,
      "underlying_bid": 27.700,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.400,
      "bid": 0.385,
      "underlying_ask": 27.750
    },
    {
      "id": 3889467161,
      "underlying_bid": 27.650,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.400,
      "bid": 0.380,
      "underlying_ask": 27.700
    },
    {
      "id": 3889467165,
      "underlying_bid": 27.600,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.395,
      "bid": 0.380,
      "underlying_ask": 27.650
    },
    {
      "id": 3889467169,
      "underlying_bid": 27.550,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.395,
      "bid": 0.375,
      "underlying_ask": 27.600
    },
    {
      "id": 3889467173,
      "underlying_bid": 27.500,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.395,
      "bid": 0.375,
      "underlying_ask": 27.550
    },
    {
      "id": 3889467177,
      "underlying_bid": 27.450,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.390,
      "bid": 0.375,
      "underlying_ask": 27.500
    },
    {
      "id": 3889467181,
      "underlying_bid": 27.400,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.390,
      "bid": 0.370,
      "underlying_ask": 27.450
    },
    {
      "id": 3889467185,
      "underlying_bid": 27.350,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.385,
      "bid": 0.370,
      "underlying_ask": 27.400
    },
    {
      "id": 3889467189,
      "underlying_bid": 27.300,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.385,
      "bid": 0.365,
      "underlying_ask": 27.350
    },
    {
      "id": 3889467193,
      "underlying_bid": 27.250,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.380,
      "bid": 0.365,
      "underlying_ask": 27.300
    },
    {
      "id": 3889467197,
      "underlying_bid": 27.200,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.380,
      "bid": 0.360,
      "underlying_ask": 27.250
    },
    {
      "id": 3889467201,
      "underlying_bid": 27.150,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.380,
      "bid": 0.360,
      "underlying_ask": 27.200
    },
    {
      "id": 3889467205,
      "underlying_bid": 27.100,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.375,
      "bid": 0.355,
      "underlying_ask": 27.150
    },
    {
      "id": 3889467209,
      "underlying_bid": 27.050,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.375,
      "bid": 0.355,
      "underlying_ask": 27.100
    },
    {
      "id": 3889467213,
      "underlying_bid": 27.000,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.370,
      "bid": 0.350,
      "underlying_ask": 27.050
    },
    {
      "id": 3889467217,
      "underlying_bid": 26.950,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.370,
      "bid": 0.350,
      "underlying_ask": 27.000
    },
    {
      "id": 3889467221,
      "underlying_bid": 26.900,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.365,
      "bid": 0.350,
      "underlying_ask": 26.950
    },
    {
      "id": 3889467225,
      "underlying_bid": 26.850,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.365,
      "bid": 0.345,
      "underlying_ask": 26.900
    },
    {
      "id": 3889467229,
      "underlying_bid": 26.800,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.360,
      "bid": 0.345,
      "underlying_ask": 26.850
    },
    {
      "id": 3889467233,
      "underlying_bid": 26.750,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.360,
      "bid": 0.340,
      "underlying_ask": 26.800
    },
    {
      "id": 3889467237,
      "underlying_bid": 26.700,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.360,
      "bid": 0.340,
      "underlying_ask": 26.750
    },
    {
      "id": 3889467241,
      "underlying_bid": 26.650,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.355,
      "bid": 0.335,
      "underlying_ask": 26.700
    },
    {
      "id": 3889467245,
      "underlying_bid": 26.600,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.355,
      "bid": 0.335,
      "underlying_ask": 26.650
    },
    {
      "id": 3889467249,
      "underlying_bid": 26.550,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.350,
      "bid": 0.330,
      "underlying_ask": 26.600
    },
    {
      "id": 3889467253,
      "underlying_bid": 26.500,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.350,
      "bid": 0.330,
      "underlying_ask": 26.550
    },
    {
      "id": 3889467257,
      "underlying_bid": 26.450,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.345,
      "bid": 0.325,
      "underlying_ask": 26.500
    },
    {
      "id": 3889467261,
      "underlying_bid": 26.400,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.345,
      "bid": 0.325,
      "underlying_ask": 26.450
    },
    {
      "id": 3889467265,
      "underlying_bid": 26.350,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.345,
      "bid": 0.325,
      "underlying_ask": 26.400
    },
    {
      "id": 3889467269,
      "underlying_bid": 26.300,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.340,
      "bid": 0.320,
      "underlying_ask": 26.350
    },
    {
      "id": 3889467273,
      "underlying_bid": 26.250,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.340,
      "bid": 0.320,
      "underlying_ask": 26.300
    },
    {
      "id": 3889467277,
      "underlying_bid": 26.200,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.335,
      "bid": 0.315,
      "underlying_ask": 26.250
    },
    {
      "id": 3889467281,
      "underlying_bid": 26.150,
      "publish_time": "2021-02-09 15:14:24",
      "ask": 0.335,
      "bid": 0.315,
      "underlying_ask": 26.200
    }
  ],
  "ric_data": {
    "underlying_ric": "1810.HK",
    "b_trbc_business": null,
    "theta": "-0.01",
    "LOLIMIT": "0",
    "TRDPRC_1": "0.635",
    "percent_moneyness": "36",
    "last_trading_date": "26 Mar 21",
    "HIGH_1": "0.770",
    "type": "CALL",
    "effective_gearing": "3.6",
    "conv_ratio": "3.5",
    "wrnt_per_share": "0.286",
    "LOW_1": "0.052",
    "BID_PCTCHNG_2DP": "-1.39",
    "breakeven_price": "27.23",
    "ticker": "FWDW",
    "30D_LOW": "-",
    "underlying_pctchng": "+0.18",
    "delta_per_wrnt": "N/A",
    "warrant_price": 0.355000000,
    "OPEN_PRC": "0.110",
    "TURNOVER_SCALED": "0",
    "NO_BIDMKR2": "-",
    "dsply_nmll": "XIAOMI MBeCW210405",
    "issuer_name": "Macquarie Bank Ltd",
    "sensitivity": "1.82",
    "delta": "96.1",
    "TRADE_DATE": "13 Jan 21",
    "NO_BIDMKR2_SCALED": "-",
    "underlying_price_adj": 4.667085000,
    "BID_PCTCHNG": "-1.4",
    "BIDSIZE": "50,000",
    "underlying_price_cal": 27.2,
    "exercise_price_adj": 3.438000000,
    "update_time": "09 Feb 21 15:28",
    "dsply_name": "XIAOMI MBeCW210405",
    "YRHIGH": "-",
    "BEST_BSIZ1": "50000",
    "HST_CLOSE": "0.360",
    "ACVOL_1_SCALED": "0",
    "BID": "0.355",
    "r_hst_close": 0.1719000000,
    "maturity": "05 Apr 21",
    "div_yield": 0,
    "underlying_bid": 27.15,
    "fmt": 1,
    "30D_HIGH": "-",
    "SYMBOL": "XIML_t11.SI",
    "underlying_price": "27.20",
    "HST_VOL_SCALED": "-",
    "list_date": "13 Jul 20",
    "exchange_code": "SES",
    "underlying_curr": "HKD",
    "currency": "SGD",
    "ASK": "0.375",
    "conv_ratio_cal": 3.499999997,
    "UPLIMIT": "0",
    "NETCHNG_1": "0.0",
    "BID_NETCHNG": "-0.005",
    "HST_VOL": "-",
    "implied_volatility": "47.7",
    "VOL_PCTCHNG": "-",
    "underlying_bid_lm": 27.15,
    "TRDTIM_1": "  :",
    "moneyness": "1",
    "ASKSIZE": "10,000",
    "premium": "0.1",
    "SERVICE": "ELEKTRON_DD",
    "intrinsic_value": "0.354",
    "underlying_ticker": "XIAOMI-W",
    "issuer_code": "MBL",
    "exercise_price_cal": 20.000000000,
    "TURNOVER": "0",
    "underlying_name": "XIAOMI-W",
    "REC_STATUS": "0",
    "PCTCHNG": "0.0",
    "YRLOW": "-",
    "BEST_ASIZ1": "10000",
    "ACVOL_1": "0",
    "days_to_maturity": "55",
    "ric": "XIML_t11.SI",
    "exercise_price": "20.00"
  }
}

const livematrixData = {
  "symbols": [	
    {
      "dsply_nmll": "AEM MB eCW210917",
      "ric": "AEML_tm.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "VKEW",
      "hide_date": null
    },
    {
      "dsply_nmll": "HSI 30400MBeCW210330",
      "ric": "HSML_t212.SI",
      "hide_matrix": null,
      "future_dsply_name": "",
      "security_code": "HGKW",
      "hide_date": null
    },    
    {
      "dsply_nmll": "XIAOMI MBeCW210405",
      "ric": "XIML_t11.SI",
      "hide_matrix": null,
      "future_dsply_name": "-",
      "security_code": "FWDW",
      "hide_date": null
    }
  ], 
  "HSML_t212.SI": livematrixDataCase1_1,
  "AEML_tm.SI": livematrixDataCase1_2,
  "XIML_t11.SI": livematrixDataCase2_2
}

export default livematrixData;