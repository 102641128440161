
import React, { Component } from 'react';
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class limitedloss extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div>
                            <div id="mainnav">
                                <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                    <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                        <div id="mainscrollcontent">
                                            <div className="contentbox" style={{ lineHeight: '20px' }}>
                                                <p>
                                                    Gearing is one of the biggest benefits of warrants but gearing is a double-edged sword, as it also exposes investors to greater potential losses in percentage terms if their view is wrong. However, the difference between warrants and many other forms
                                                    of leverage is that your risk is limited to the initial payment, meaning you can increase your exposure while limiting your capital at risk.
                                                    <br />
                                                    <br />
                                                    Look at our hypothetical example here. You can achieve an increased effective exposure of $12,000 by buying $2,400 worth of warrants, which limits your total capital at risk to $2,400. If you were to achieve the same exposure by buying the share directly or through a margin loan, you would be exposed to the full $12,000.
                                                </p>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/limited-loss_enlarge.jpg" alt />
                                            </div>
                                            <div className="videoplayer-container" style={{
                                                height: '75vh',
                                                maxWidth: '440px',
                                                margin: ' 0 auto'
                                            }}>
                                                <iframe
                                                    title="Market View"
                                                    frameBorder="0"
                                                    allowFullScreen=""
                                                    height="100%"
                                                    width="100%"
                                                    src="https://www.youtube.com/embed/lblLY3RY4JM?autoplay=0&index=0&rel=0&wmode=opaque"
                                                // src={link}
                                                ></iframe>
                                            </div>
                                            <p>
                                                <a href="/WarrantsGuidebook/WhyTradeWarrantsPlaylist" style={{ color: '#000', fontSize: '16px', fontWeight: 'bold' }}>Warrants Short Video Playlist</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}