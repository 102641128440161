import React, { Component } from "react";
import classNames from "classnames";
import "./telegram.css";

export default class telegram extends Component {
  constructor() {
    super();
    this.state = {
      forTab: true,
    };
  }

  clickTab(flag) {
    this.setState({
      forTab: flag,
    });
  }

  render() {
    return (
      <div id="telegram" className="pageobj">
        <div className="welcometop">
          <div className="video-wrapper">
            <div className="welcomeimgtop hidden-xs hidden-sm">
              <img src="/img/telegram/section1/header-desktop-1600x450.png" />
            </div>
            <div className="welcomeimgtop2 visible-sm">
              <img src="/img/telegram/section1/header-desktop-1600x450.png" />
            </div>
            <div className="welcomeimg visible-xs">
              <img src="/img/telegram/section1/header-mobile-1170x570.png" />
            </div>
          </div>
        </div>
        <div className="jumbotron">
          <div className="container-fluid">
            <p className="lead"></p>
            <h1 className="broker_img">
              <a href="https://t.me/MacquarieWarrantsSG" target="_blank" style={{color:'#FFFFFF'}}>
                <b>Join us on Telegram</b>
                <span>@MacquarieWarrantsSG{" "}
                  <img
                    className="imgtab"
                    src="/img/telegram/section1/t_logo.png"
                  />
                </span>
              </a>
            </h1>
            <p></p>
          </div>
        </div>
        <nav className="tabnav container-fluid">
          <div className="row tabnav-row">
            <div className="col-xs-9 col-sm-6">
              <div className="navbox">
                <img
                  style={{ width: "100%" }}
                  src="/img/telegram/section1/phone.webp"
                />
                <a
                  className="joinLink hidden-xs"
                  href="https://web.telegram.org/#/im?p=@MacquarieWarrantsSG"
                  target="_blank"
                >
                  &nbsp;
                </a>
                <a
                  className="joinLink visible-xs"
                  href="https://t.me/MacquarieWarrantsSG"
                  target="_blank"
                >
                  &nbsp;
                </a>
              </div>
            </div>
            <div className="col-xs-6 hidden-xs">
              <div className="navbox howjoin">
                {/* <div id="howjoinStep"> */}
                <div style={{ marginTop: '370px' }}>
                  <img src="/img/telegram/tg_qr_trns.png" alt="" />
                </div>
                {/* <div
                    className="section"
                    id="tab1"
                    style={{ padding: "15px" }}
                  >
                    <p>
                      Get timely updates on structured warrants as well as the
                      foreign and local markets to help your daily trading and
                      decision making!
                    </p>
                    <p className="howJoinSubTitle">
                      If you already have the Telegram application
                    </p>
                    <a
                      link="https://t.me/MacquarieWarrantsSG"
                      className="btn broker_img"
                      style={{
                        width: "100%",
                        backgroundColor: "#0066A5",
                        color: "#ffffff",
                        padding: "0 12px",
                      }}
                    >
                      Join now
                    </a>
                    <p className="howJoinTitle">How do I join?</p>
                    <table id="viewtab" className="ctab">
                      <tbody>
                        <tr>
                          <td
                            className={classNames(
                              { active: this.state.forTab },
                              "ctab-item"
                            )}
                            id="forMobile"
                            onClick={() => this.clickTab(true)}
                          >
                            <span>for mobile</span>
                          </td>
                          <td className="ctab-item-space">&nbsp;</td>
                          <td
                            className={classNames(
                              { active: !this.state.forTab },
                              "ctab-item"
                            )}
                            id="forDesktop"
                            onClick={() => this.clickTab(false)}
                          >
                            <span>for desktop</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {this.state.forTab == true ? (
                      <ActiveMoblie />
                    ) : (
                      <ActiveDesktop />
                    )}
                    <div className="section alldone">
                      <div className="alldone-text">
                        <p>
                          All done! Stay tuned for Telegram exclusive content
                          coming up.
                        </p>
                      </div>
                      <div className="cta-btn">
                        <a href="/education/getstarted" target="_blank">
                          <img src="/img/telegram/section1/CTAbutton.png" />
                        </a>
                      </div>
                    </div>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
            <div className="col-xs-3 visible-xs howToJoin">
              <div className="navbox">
                <a href="#section1">
                  <img
                    className="imgtab  howjoin-btn"
                    style={{ width: "100%" }}
                    src="/img/telegram/section1/howToJoin.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="container-fluid visible-xs">
          <div id="section1"></div>
          <div id="howjoinStep">
            <div className="section" id="tab1">
              <div style={{ textAlign: 'center' }}>
                <img src="/img/telegram/tg_qr_trns.png" alt="" />
              </div>
              {/* <p>
                Get timely updates on structured warrants as well as the foreign
                and local markets to help your daily trading and decision
                making!
              </p>
              <p className="howJoinSubTitle">
                If you already have the Telegram application
              </p>
              <a
                link="https://t.me/MacquarieWarrantsSG"
                className="btn broker_img"
                style={{
                  width: "100%",
                  backgroundColor: "#0066A5",
                  color: "#ffffff",
                  padding: "0 12px",
                }}
              >
                Join now
              </a>
              <p className="howJoinTitle">How do I join?</p>
              <table id="viewtab" className="ctab">
                <tbody>
                  <tr>
                    <td
                      className="ctab-item  active"
                      id="forMobile"
                      data-value="M"
                    >
                      <span>for mobile</span>
                    </td>
                    <td className="ctab-item-space">&nbsp;</td>
                    <td className="ctab-item" id="forDesktop" data-value="D">
                      <span>for desktop</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="activeM">
                <div className="row step-row">
                  <div className="col-xs-1">
                    <img src="/img/telegram/section1/no1.png" />
                  </div>
                  <div className="col-xs-11">
                    <p className="howJoinSubTitle">
                      Download the Telegram application
                      <br />
                      <em className="small">
                        Skip this step if you already have the app
                      </em>
                    </p>
                    <div>
                      <span
                        className="androidDownload broker_img "
                        link="https://play.google.com/store/apps/details?id=org.telegram.messenger"
                      >
                        <img
                          className="forMDimg"
                          src="/img/telegram/section1/forMobile/mobile-forAndroid.png"
                        />
                      </span>
                      <span
                        className="appleDownload broker_img"
                        link="https://itunes.apple.com/app/telegram-messenger/id686449807"
                      >
                        <img
                          className="forMDimg"
                          src="/img/telegram/section1/forMobile/mobile-forApple.png"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row step-row">
                  <div className="col-xs-1">
                    <img src="/img/telegram/section1/no2.png" />
                  </div>
                  <div className="col-xs-11">
                    <p className="howJoinSubTitle">
                      Sign in with your mobile number
                    </p>
                    <img
                      className="for-img forMDimg"
                      src="/img/telegram/section1/forMobile/mobile-2signIn.png"
                    />
                  </div>
                </div>
                <div className="row step-row">
                  <div className="col-xs-1">
                    <img src="/img/telegram/section1/no3.png" />
                  </div>
                  <div className="col-xs-11">
                    <p className="howJoinSubTitle">
                      Search “Warrants Singapore” on the app, or{" "}
                      <span
                        className="clickhereM broker_img"
                        style={{ color: "#0675C2" }}
                        link="https://t.me/MacquarieWarrantsSG"
                      >
                        click here
                      </span>
                    </p>
                    <img
                      id="mobile-3"
                      className="for-img forMDimg"
                      src="/img/telegram/section1/forMobile/mobile-3search.png"
                    />
                  </div>
                </div>
                <div className="row step-row">
                  <div className="col-xs-1">
                    <img src="/img/telegram/section1/no4.png" />
                  </div>
                  <div className="col-xs-11">
                    <p className="howJoinSubTitle">
                      Tap on the join button at the bottom of your screen
                    </p>
                  </div>
                </div>
              </div>
              <div className="activeD hidden">
                <div className="row step-row">
                  <div className="col-xs-1">
                    <img src="/img/telegram/section1/no1.png" />
                  </div>
                  <div className="col-xs-11">
                    <p className="howJoinSubTitle">Click the link below</p>
                    <div>
                      <span
                        className="telegramWeb broker_img"
                        link="https://web.telegram.org/#/im?p=@MacquarieWarrantsSG"
                      >
                        <img
                          className="forMDimg"
                          src="/img/telegram/section1/forDesktop/web-TelegramWeb.png"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="row step-row">
                    <div className="col-xs-1">
                      <img src="/img/telegram/section1/no2.png" />
                    </div>
                    <div className="col-xs-11">
                      <p className="howJoinSubTitle">
                        Sign in with your mobile number
                      </p>
                      <img
                        className="for-img forMDimg"
                        src="/img/telegram/section1/forDesktop/web-2signin.png"
                      />
                    </div>
                  </div>
                  <div className="row step-row">
                    <div className="col-xs-1">
                      <img src="/img/telegram/section1/no3.png" />
                    </div>
                    <div className="col-xs-11">
                      <p className="howJoinSubTitle">
                        Search “Warrants Singapore” on the left side of the
                        page, or{" "}
                        <span
                          className="clickhereD broker_img"
                          style={{ color: "#0675C2" }}
                          link="https://web.telegram.org/#/im?p=@MacquarieWarrantsSG"
                        >
                          click here
                        </span>
                      </p>
                      <img
                        className="for-img forMDimg"
                        src="/img/telegram/section1/forDesktop/web-3search.png"
                      />
                    </div>
                  </div>
                  <div className="row step-row">
                    <div className="col-xs-1">
                      <img src="/img/telegram/section1/no4.png" />
                    </div>
                    <div className="col-xs-11">
                      <p className="howJoinSubTitle">
                        Tap on the join button at the bottom of your screen
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section alldone">
                <div className="alldone-text">
                  <p>
                    All done! Stay tuned for Telegram exclusive content coming
                    up.
                  </p>
                </div>
                <div className="cta-btn">
                  <a href="/education/getstarted" target="_blank">
                    <img src="/img/telegram/section1/CTAbutton.png" />
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div id="section2"></div>
          <div className="section" id="tab2">
            <p className="video_title">
              Watch our new animated video series for a fun and simple way to
              learn about warrants
            </p>
            <div className="video_img">
              <div id="video_ep1" className="video_ep_img">
                <a
                  href="/education/marketcommentary/Educational%20Videos/2sWqTvnYqrA/0"
                  target="_blank"
                >
                  <img src="/img/telegram/section2/EP1.png" />
                </a>
              </div>
              <div id="video_ep2" className="video_ep_img">
                <a
                  href="/education/marketcommentary/Educational%20Videos/phFQNIRmehY/2"
                  target="_blank"
                >
                  <img src="/img/telegram/section2/EP2.png" />
                </a>
              </div>
              <div id="video_ep3" className="video_ep_img">
                <a
                  href="/education/marketcommentary/Educational%20Videos/KaWH9px5VQU/3"
                  target="_blank"
                >
                  <img src="/img/telegram/section2/EP3.png" />
                </a>
              </div>
              <div id="video_ep4" className="video_ep_img">
                <a
                  href="/education/marketcommentary/Educational%20Videos/6qNsnZa6Tjg/4"
                  target="_blank"
                >
                  <img src="/img/telegram/section2/EP4.png" />
                </a>
              </div>
              <div id="video_ep5" className="video_ep_img">
                <a href="/education/whymacquarie" target="_blank">
                  <img src="/img/telegram/section2/EP5.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div id="section3"></div>
          <div className="section" id="tab3">
            <p className="contact_title">
              Contact us if you have any questions{" "}
            </p>
            <table className="contact_img bottom-pc">
              <tbody>
                <tr>
                  <td>
                    <a href="tel:+65 6601 0289">
                      <img
                        className="contact_phone"
                        src="/img/telegram/section3/phone.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a href="tel:+65 6601 0289">
                      <span className="contact_phone">&nbsp;+65 6601 0289</span>
                    </a>
                  </td>

                  <td>
                    <a href="mailto:info@warrants.com.sg">
                      <img
                        className="contact_email"
                        src="/img/telegram/section3/email.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a href="mailto:info@warrants.com.sg">
                      <span className="contact_email">
                        &nbsp;info@warrants.com.sg
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <a
                      className="broker_img"
                      href="https://www.facebook.com/MacquarieWarrantsSG/"
                      target="_blank"
                    >
                      <img
                        className="contact_facebook "
                        src="/img/telegram/section3/facebook.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a
                      className="broker_img"
                      href="https://www.facebook.com/MacquarieWarrantsSG/"
                      target="_blank"
                    >
                      <span className="contact_facebook">
                        &nbsp;@MacquarieWarrantsSG
                      </span>
                    </a>
                  </td>
                  <td>
                    <a href="/education/whymacquarie" target="_blank">
                      <img
                        className="contact_whyMacq"
                        src="/img/telegram/section3/whyMacq.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a href="/education/whymacquarie" target="_blank">
                      <span className="contact_whyMacq ">
                        &nbsp;Why Macquarie?
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <!--移动端布局--> */}
            <table className="contact_img bottom-pe">
              <tbody>
                <tr style={{ margin: "20px 0 15px 0" }}>
                  <td>
                    <a href="tel:+65 6601 0289">
                      <img
                        className="contact_phone"
                        src="/img/telegram/section3/phone.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a href="tel:+65 6601 0289">
                      <span className="contact_phone">&nbsp;+65 6601 0289</span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="mailto:info@warrants.com.sg">
                      <img
                        className="contact_email"
                        src="/img/telegram/section3/email.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a href="mailto:info@warrants.com.sg">
                      <span className="contact_email">
                        &nbsp;info@warrants.com.sg
                      </span>
                    </a>
                  </td>
                </tr>
                <tr style={{ margin: "15px 0 15px 0" }}>
                  <td style={{ textAlign: "center" }}>
                    <a
                      className="broker_img"
                      href="https://www.facebook.com/MacquarieWarrantsSG/"
                      target="_blank"
                    >
                      <img
                        className="contact_facebook"
                        src="/img/telegram/section3/facebook.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a
                      className="broker_img"
                      href="https://www.facebook.com/MacquarieWarrantsSG/"
                      target="_blank"
                    >
                      <span className="contact_facebook">
                        &nbsp;@MacquarieWarrantsSG
                      </span>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/education/whymacquarie" target="_blank">
                      <img
                        className="contact_whyMacq"
                        src="/img/telegram/section3/whyMacq.png"
                      />
                    </a>
                  </td>
                  <td>
                    <a href="/education/whymacquarie" target="_blank">
                      <span className="contact_whyMacq">
                        &nbsp;Why Macquarie?
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

class ActiveMoblie extends Component {
  render() {
    return (
      <div className="activeM">
        <div className="row step-row">
          <div className="col-xs-1">
            <img src="/img/telegram/section1/no1.png" />
          </div>
          <div className="col-xs-11">
            <p className="howJoinSubTitle">
              Download the Telegram application
              <br />
              <em className="small">
                Skip this step if you already have the app
              </em>
            </p>
            <div>
              <span
                className="androidDownload broker_img"
                link="https://play.google.com/store/apps/details?id=org.telegram.messenger"
              >
                <img
                  className="forMDimg"
                  src="/img/telegram/section1/forMobile/mobile-forAndroid.png"
                />
              </span>
              <span
                className="appleDownload broker_img"
                link="https://itunes.apple.com/app/telegram-messenger/id686449807"
              >
                <img
                  className="forMDimg"
                  src="/img/telegram/section1/forMobile/mobile-forApple.png"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="row step-row">
          <div className="col-xs-1">
            <img src="/img/telegram/section1/no2.png" />
          </div>
          <div className="col-xs-11">
            <p className="howJoinSubTitle">Sign in with your mobile number</p>
            <img
              className="for-img forMDimg"
              src="/img/telegram/section1/forMobile/mobile-2signIn.png"
            />
          </div>
        </div>
        <div className="row step-row">
          <div className="col-xs-1">
            <img src="/img/telegram/section1/no3.png" />
          </div>
          <div className="col-xs-11">
            <p className="howJoinSubTitle">
              Search “Warrants Singapore” on the app, or{" "}
              <span
                className="clickhereM broker_img"
                style={{ color: "#0675C2" }}
                link="https://t.me/MacquarieWarrantsSG"
              >
                click here
              </span>
            </p>
            <img
              id="mobile-3"
              className="for-img forMDimg"
              src="/img/telegram/section1/forMobile/mobile-3search.png"
            />
          </div>
        </div>
        <div className="row step-row">
          <div className="col-xs-1">
            <img src="/img/telegram/section1/no4.png" />
          </div>
          <div className="col-xs-11">
            <p className="howJoinSubTitle">
              Tap on the join button at the bottom of your screen
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class ActiveDesktop extends Component {
  render() {
    return (
      <div className="activeD">
        <div className="row step-row">
          <div className="col-xs-1">
            <img src="/img/telegram/section1/no1.png" />
          </div>
          <div className="col-xs-11">
            <p className="howJoinSubTitle">Click the link below</p>
            <div>
              <span
                className="telegramWeb broker_img"
                link="https://web.telegram.org/#/im?p=@MacquarieWarrantsSG"
              >
                <img
                  className="forMDimg"
                  src="/img/telegram/section1/forDesktop/web-TelegramWeb.png"
                />
              </span>
            </div>
          </div>
          <div className="row step-row">
            <div className="col-xs-1">
              <img src="/img/telegram/section1/no2.png" />
            </div>
            <div className="col-xs-11">
              <p className="howJoinSubTitle">Sign in with your mobile number</p>
              <img
                className="for-img forMDimg"
                src="/img/telegram/section1/forDesktop/web-2signin.png"
              />
            </div>
          </div>
          <div className="row step-row">
            <div className="col-xs-1">
              <img src="/img/telegram/section1/no3.png" />
            </div>
            <div className="col-xs-11">
              <p className="howJoinSubTitle">
                Search “Warrants Singapore” on the left side of the page, or{" "}
                <span
                  className="clickhereD broker_img"
                  style={{ color: "#0675C" }}
                  link="https://web.telegram.org/#/im?p=@MacquarieWarrantsSG"
                >
                  click here
                </span>
              </p>
              <img
                className="for-img forMDimg"
                src="/img/telegram/section1/forDesktop/web-3search.png"
              />
            </div>
          </div>
          <div className="row step-row">
            <div className="col-xs-1">
              <img src="/img/telegram/section1/no4.png" />
            </div>
            <div className="col-xs-11">
              <p className="howJoinSubTitle">
                Tap on the join button at the bottom of your screen
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
