
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class callvsput extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="" >
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ margin: '0 auto', marginBottom: 60, maxWidth: '800px' }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p />
                                            <div className="imgholder hidden-xs">
                                                <img src="/img/education/call-vs-put_enlarge.jpg" style={{ maxWidth: '800px' }} />
                                            </div>
                                            <div className="imgholder visible-xs">
                                                <img style={{ maxWidth: 340 }} src="/img/education/call-vs-put_enlarge_m.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}