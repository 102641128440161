
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class porfitfrombullorbearmarket extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div>
                            <div id="mainnav">
                                <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                    <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                        <div id="mainscrollcontent">
                                            <div className="contentbox">
                                                <p>Warrants provide investors the opportunity to profit from both stock price increases and falls. Call warrants can boost investors profit potential from stock price increases rises while put warrants enable investors to
                                                    benefit from sell-downs. Put warrants are particularly useful as they are one of the few products in Singapore that are available to retail investors to go ‘short’ (i.e. profit from downward moves) in stocks.
                                                    <br />
                                                    <br /> Put warrants can also be used as a form of insurance to protect an existing share portfolio against a falling market (a process called “hedging”). An investor with a holding in a particular stock who is nervous
                                                    about the future direction of the market, could purchase a put warrant. If the share price falls, the gains made on the warrant can be used to offset the losses on their shares.
                                                    <br />
                                                    <br /> This allows the investor to retain share ownership without having full exposure to the downside risks. Index warrants are most typically used for hedging. Investors should, however, understand the complexity of
                                                    utilising warrants for hedging or portfolio protection purposes. For example, the value of the warrant may not exactly correlate with value of the underlying shares.
                                                </p>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/profitfrombull.png" style={{ maxWidth: '400px' }} alt />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}