const photogallerydata = [
    {
        "en_description": "",
        "id": 125,
        "last_update_time": "01 Oct 2020",
        "th_title": "",
        "status": 1,
        "th_description": "",
        "en_title": "Webinar: Market View with the experts",
        "date": "Oct 1, 2020",
        "demo": true
    },
    {
        "en_description": "",
        "id": 117,
        "last_update_time": "18 Feb 2020",
        "th_title": "",
        "status": 1,
        "th_description": "",
        "en_title": "SGX Top Traded Leveraged Products Issuer - 2019 ",
        "date": "Feb 18, 2020",
        "demo": true
    }
]

const album117 = [
    {
        "album_id": 117,
        "id": 1781,
        "last_update_time": "Feb 18, 2020 9:59:41 AM"
    }
]

const albumID = {
    "117": album117
}

export default photogallerydata;
export {albumID}