
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class impliedvolatility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <div className="videotxt hidden-xxs hidden-xs">
                                                <p>In this episode we are going to explore the concept of volatility. It’s essential to understand implied volatility because it’s one of the key factors that impact a warrant’s price.</p>
                                            </div>
                                            <p>
                                                Implied by the current market price of the warrant, and represented as a percentage.
                                                <br /><br /> Implied volatility can be used to compare the relative price of similar warrants. The higher the level, the more expensive the warrant (all other pricing factors held constant).
                                                <br /><br /> Implied volatility is in turn influenced by factors including,
                                            </p>
                                            <div className="listholder" style={{ maxWidth: 480, textAlign: 'left' }}>
                                                <ul>
                                                    <li >Demand and supply forces on the warrant:</li>
                                                    <li>The level of volatility in market prices generally; and</li>
                                                    <li>Exchange traded options (ETO) and over the counter (OTC) markets</li>
                                                </ul>
                                            </div>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 300 }} src="/img/education/impliedvolatility1.png" />
                                            </div>
                                            <p>Suppose you observed the price movement of warrant A in the trading hours to be as such:
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/iv_table1.png" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/iv_table1.png')}>View table</div>
                                            </div>
                                            <p>
                                                In the table, you can see that from 9.05am to 10.30am, the warrant has been moving ‘tick for tick’ with the underlying share price. Meaning, for every time the underlying stock moves up one tick, so does the warrant price. Note that the warrant is at
                                                9.5 cents when the underlying share is trading at $5.50.<br /><br />
                                            </p>
                                            <div className="imgholder">
                                                <img src="/img/education/iv_table2.png" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/iv_table2.png')}>View table</div>
                                            </div>
                                            <p>
                                                Later that morning at 11.05am however, you’ll notice that the price of the warrant has gone up to 9.6 cents even though the underlying share is unchanged at $5.50. Now, this increase in warrant price can be attributed to a rise in implied volatility,
                                                from the 70 per cent earlier in the morning, to 71 per cent at 11.05am.<br /><br /> What may have caused the implied volatility to increase?
                                            </p>
                                            <div className="listholder" style={{ maxWidth: 774, textAlign: 'left' }}>
                                                <ul>
                                                    <li>An increase in demand or buy flows for the warrant; and/or</li>
                                                    <li>A market-related event such as upcoming results to be released by the company that has led to an increase in uncertainty and risk aversion, and hence, a forecast of higher levels of volatility in the market, and/or</li>
                                                    <li>An increase in the implied volatility of a similar option in the ETO or OTC market.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/iv_table3.png" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/iv_table3.png')}>View table</div>
                                            </div>
                                            <p>
                                                At the higher implied volatility of 71%, you notice that the warrant continues to move tick for tick with the underlying share, with the warrant ticking down to 9.6 cents as the stock ticks down from $5.51 to $5.50.<br /><br />                                However, at 4.40pm, the warrant suddenly falls to 9.5 cents when the underlying share is again, unchanged at $5.50. This is the same price basis that the warrant and stock was trading at when you first began monitoring
                                                their movements in the morning. The reason for this is because the implied volatility has also gone back to the 70% level it was trading at in the morning.<br /><br /> The question then is, what has caused the implied volatility
                                                to fall from 71% back to 70%? There are three plausible reasons:
                                            </p>
                                            <div className="listholder" style={{ maxWidth: 612, textAlign: 'left' }}>
                                                <ul>
                                                    <li>A rise in supply or sell flows for the warrant; and/or</li>
                                                    <li>A market related event that has seen uncertainty reduces, and a forecast or lower level of volatility in the market; and/or</li>
                                                    <li>A drop in the implied volatility level of a similar option in the ETO or OTC markets
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}