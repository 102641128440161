const turnoverdist = {
    "turnoverdist": {
        "content": [
            {
                "underlying_ric": "DBSM.SI",
                "03 Mar 21": "31.9",
                "09 Mar 21": "29.4",
                "underlying_ticker": "D05",
                "04 Mar 21": "87.0",
                "underlying_name": "DBS GROUP HOLDIN",
                "05 Mar 21": "64.9",
                "08 Mar 21": "79.7"
            },
            {
                "underlying_ric": "OCBC.SI",
                "03 Mar 21": "15.6",
                "09 Mar 21": "20.0",
                "underlying_ticker": "O39",
                "04 Mar 21": "22.7",
                "underlying_name": "OVERSEA-CHINESE",
                "05 Mar 21": "25.8",
                "08 Mar 21": "10.4"
            },
            {
                "underlying_ric": "3690.HK",
                "03 Mar 21": "20.5",
                "09 Mar 21": "18.4",
                "underlying_ticker": "3690",
                "04 Mar 21": "79.3",
                "underlying_name": "MEITUAN-W",
                "05 Mar 21": "73.5",
                "08 Mar 21": "71.3"
            },
            {
                "underlying_ric": "HHTIc1",
                "03 Mar 21": "0.1",
                "09 Mar 21": "15.9",
                "underlying_ticker": "HSTECH",
                "04 Mar 21": "22.2",
                "underlying_name": "HHTI HKD MAR1",
                "05 Mar 21": "5.9",
                "08 Mar 21": "11.2"
            },
            {
                "underlying_ric": "UOBH.SI",
                "03 Mar 21": "9.2",
                "09 Mar 21": "15.4",
                "underlying_ticker": "U11",
                "04 Mar 21": "58.8",
                "underlying_name": "UNITED OVERSEAS",
                "05 Mar 21": "33.1",
                "08 Mar 21": "76.1"
            },
            {
                "underlying_ric": "9988.HK",
                "03 Mar 21": "1.6",
                "09 Mar 21": "5.2",
                "underlying_ticker": "9988",
                "04 Mar 21": "3.6",
                "underlying_name": "BABA-SW",
                "05 Mar 21": "5.5",
                "08 Mar 21": "3.6"
            },
            {
                "underlying_ric": "0175.HK",
                "03 Mar 21": "0.4",
                "09 Mar 21": "3.6",
                "underlying_ticker": "175",
                "04 Mar 21": "3.8",
                "underlying_name": "GEELY AUTO",
                "05 Mar 21": "6.1",
                "08 Mar 21": "11.7"
            },
            {
                "underlying_ric": "1810.HK",
                "03 Mar 21": "1.3",
                "09 Mar 21": "3.0",
                "underlying_ticker": "1810",
                "04 Mar 21": "3.0",
                "underlying_name": "XIAOMI-W",
                "05 Mar 21": "2.9",
                "08 Mar 21": "1.8"
            },
            {
                "underlying_ric": "9618.HK",
                "03 Mar 21": "1.9",
                "09 Mar 21": "1.9",
                "underlying_ticker": "9618",
                "04 Mar 21": "4.2",
                "underlying_name": "JD-SW",
                "05 Mar 21": "3.4",
                "08 Mar 21": "5.6"
            },
            {
                "underlying_ric": "1211.HK",
                "03 Mar 21": "2.1",
                "09 Mar 21": "1.5",
                "underlying_ticker": "1211",
                "04 Mar 21": "3.0",
                "underlying_name": "BYD COMPANY",
                "05 Mar 21": "6.1",
                "08 Mar 21": "5.4"
            },
            {
                "underlying_ric": "0700.HK",
                "03 Mar 21": "0.5",
                "09 Mar 21": "1.4",
                "underlying_ticker": null,
                "04 Mar 21": "3.0",
                "underlying_name": "TENCENT",
                "05 Mar 21": "2.5",
                "08 Mar 21": "0.6"
            },
            {
                "underlying_ric": "CTDM.SI",
                "03 Mar 21": "0.1",
                "09 Mar 21": "0.1",
                "underlying_ticker": "C09",
                "04 Mar 21": "0.0",
                "underlying_name": "CITY DEVELOPMENT",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.2"
            },
            {
                "underlying_ric": "SGXL.SI",
                "03 Mar 21": "4.8",
                "09 Mar 21": "0.0",
                "underlying_ticker": "S68",
                "04 Mar 21": "1.4",
                "underlying_name": "SINGAPORE EXCHAN",
                "05 Mar 21": "0.3",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "SIAL.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "C6L",
                "04 Mar 21": "0.0",
                "underlying_name": "SINGAPORE AIRLIN",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "STEL.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "Z74",
                "04 Mar 21": "0.0",
                "underlying_name": "SINGTEL",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.6"
            },
            {
                "underlying_ric": "TOPG.SI",
                "03 Mar 21": "1.3",
                "09 Mar 21": "0.0",
                "underlying_ticker": "BVA",
                "04 Mar 21": "0.0",
                "underlying_name": "TOP GLOVE CORPOR",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "UTOS.SI",
                "03 Mar 21": "0.1",
                "09 Mar 21": "0.0",
                "underlying_ticker": "U14",
                "04 Mar 21": "0.2",
                "underlying_name": "UOL GROUP LIMITE",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "WLIL.SI",
                "03 Mar 21": "7.4",
                "09 Mar 21": "0.0",
                "underlying_ticker": "F34",
                "04 Mar 21": "4.1",
                "underlying_name": "WILMAR INTERNATI",
                "05 Mar 21": "0.0",
                "08 Mar 21": "1.8"
            },
            {
                "underlying_ric": "MACT.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "N2IU",
                "04 Mar 21": "0.0",
                "underlying_name": "MAPLETREE COMMER",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "KPLM.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "BN4",
                "04 Mar 21": "0.0",
                "underlying_name": "KEPPEL CORPORATI",
                "05 Mar 21": "0.0",
                "08 Mar 21": "3.9"
            },
            {
                "underlying_ric": "IFAS.SI",
                "03 Mar 21": "0.1",
                "09 Mar 21": "0.0",
                "underlying_ticker": "AIY",
                "04 Mar 21": "0.6",
                "underlying_name": "IFAST CORPORATIO",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "GENS.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "G13",
                "04 Mar 21": "0.0",
                "underlying_name": "GENTING SINGAPOR",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "CMDG.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "C52",
                "04 Mar 21": "0.0",
                "underlying_name": "COMFORTDELGRO LD",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "CATL.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "C31",
                "04 Mar 21": "0.0",
                "underlying_name": "CAPITALAND LIMIT",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "AEMN.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "A17U",
                "04 Mar 21": "0.0",
                "underlying_name": "ASCEN REIT",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "AEM.SI",
                "03 Mar 21": "1.3",
                "09 Mar 21": "0.0",
                "underlying_ticker": "AWX",
                "04 Mar 21": "0.6",
                "underlying_name": "AEM HOLDINGS LTD",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.9"
            },
            {
                "underlying_ric": "2382.HK",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "2382",
                "04 Mar 21": "0.1",
                "underlying_name": "SUNNY OPTICAL",
                "05 Mar 21": "0.0",
                "08 Mar 21": "0.0"
            },
            {
                "underlying_ric": "YAZG.SI",
                "03 Mar 21": "0.0",
                "09 Mar 21": "0.0",
                "underlying_ticker": "BS6",
                "04 Mar 21": "0.0",
                "underlying_name": "YANGZIJIANG SHIP",
                "05 Mar 21": "0.0",
                "08 Mar 21": "1.5"
            }
        ],
        "stats_date_list": [
            "09 Mar 21",
            "08 Mar 21",
            "05 Mar 21",
            "04 Mar 21",
            "03 Mar 21"
        ]
    }
}

export default turnoverdist