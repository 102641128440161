import React, { Component } from "react";
import classNames from "classnames";
import MQSGUtil from "../mqsgUtil";
import ReactTooltip from "react-tooltip";
import highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DatePicker from "react-datepicker";
import ReactBootstrapSlider from "react-bootstrap-slider";
import nodata from "highcharts/modules/no-data-to-display";
nodata(highcharts);
class LiveMatrixTodayTable extends Component {
    getTableContent(page) {
        if (this.props.logic !== null) {
            return this.props.logic.getTableContent(this.props.page);
        }
        return null;
    }

    getPrintRow() {
        if (this.isHome()) {
            return null;
        }
        var name = document.querySelector(".ricbar-lbl");
        name = name ? name.innerHTML : "";
        // var title = `Live matrix | Singapore Warrants | Macquarie | ${name}`;
        var title = `Live matrix | Singapore Warrants | Macquarie`;
        return (
            <tr>
                <th
                    colSpan="4"
                    className="th-left"
                    style={{
                        borderBottom: "0px",
                        padding: "0px 0px",
                        textAlign: "left",
                    }}
                >
                    {/* <ReactToPrint
              content={() => this.tableRef}
              documentTitle={title}
              trigger={() => (
                <img
                  className="printimg"
                  src="/img/bullet/print.png"
                  alt="print live matrix"
                  // onClick={this.print}
                />
              )}
            /> */}
                    <img
                        className="printimg"
                        src="/img/bullet/print.png"
                        alt="print live matrix"
                        onClick={this.print}
                        style={{
                            width: '20px',
                            marginTop: '-8px',
                            cursor: 'pointer'
                        }}
                    />
                </th>
            </tr>
        );
    }
    print() {
        // var oldHtml = document.body.innerHTML;
        // var newHtml = document.getElementById("lmtablecontainer").innerHTML;
        // document.body.innerHTML = newHtml;
        // window.print();
        // document.body.innerHTML = oldHtml;
        // window.location.reload();
        $("#lmtablecontainer").printThis({
            pageTitle: $(document).find("title").text() + " | " + $("#ticker").html(),
            loadCSS: ["/src/pages/tools/livematrix/livematrix.css", "/css/global.css"],
            // importStyle: ["<style>html{background:'#fff'}}</style>"]
        });


        // //获取当前页的html代码
        // var bodyhtml = document.getElementById("lmtablecontainer").innerHTML;
        // //设置打印开始区域、结束区域
        // var startFlag = "/* startprint */";
        // var endFlag = "/* endprint */";
        // // 要打印的部分
        // var printhtml = bodyhtml.substring(
        //   bodyhtml.indexOf(startFlag),
        //   bodyhtml.indexOf(endFlag)
        // );
        // // 生成并打印ifrme
        // var f = document.getElementById("printf");
        // f.contentDocument.write(bodyhtml);
        // f.contentDocument.write(
        //   '<link rel="stylesheet" type="text/css" href="/css/global.css">'
        // );
        // f.contentDocument.write(
        //   '<link rel="stylesheet" type="text/css" href="/src/tools/livenatrix/livenatrix.css">'
        // );
        // f.contentDocument.close();
        // f.contentWindow.print();
    }
    isHome() {
        if (this.props.page !== undefined && this.props.page !== null) {
            if (this.props.page === "home") {
                return true;
            }
        }
        return false;
    }

    render() {
        const isLoading = this.props.isLoading;
        var className = '';
        if (isLoading) {
            className += " selector-loadingindicator";
        }
        const printRow = this.getPrintRow();
        var showAll = true;
        var show = false;
        if (
            this.getTableContent(this.props.page) != null
        ) {
            if (this.getTableContent(this.props.page).length == 0) {
                if (this.isHome() === true) {
                    showAll = false;
                } else {
                    showAll = true;
                    if (isLoading) {
                        show = false;
                    } else {
                        show = true;
                    }

                }
            }

        } else {
            if (this.isHome() === true) {
                showAll = false;
            } else {
                showAll = true;
                if (isLoading) {
                    show = false;
                } else {
                    show = true;
                }

            }
        }
        var isSti = false;
        if (this.props.logic != null) {
            if (this.props.logic.isSTI) {
                isSti = true;
            } else {
                isSti = false;
            }
        }
        let isWarrantsShow = false;
        let isUnderyingShow = false;
        let future_dsply_name = '';
        if (
            typeof this.props.selected !== "undefined" &&
            this.props.selected !== null
        ) {
            if (
                this.props.selected.label.indexOf('S&P') != -1 ||
                this.props.selected.label.indexOf('DJI') != -1 ||
                this.props.selected.label.indexOf('HSI') != -1 ||
                this.props.selected.label.indexOf('HSTECH') != -1 ||
                this.props.selected.label.indexOf('NKY') != -1 ||
                this.props.selected.label.indexOf('SIMSCI') != -1 ||
                this.props.selected.label.indexOf('Sea') != -1 ||
                this.props.selected.label.indexOf('NASDAQ') != -1
            ) {
                isWarrantsShow = true;
            } else {
                if (this.props.selected.future_dsply_name && this.props.selected.future_dsply_name != '' && this.props.selected.future_dsply_name != '-') {
                    isUnderyingShow = true;
                    future_dsply_name = this.props.selected.future_dsply_name;
                }
            }
        }
        return (
            <div
                id="lmtablecontainer"
                ref={(el) => (this.tableRef = el)}
                className={className}
                style={{ paddingTop: "10px", overflowX: 'auto' }}
            >
                {/* startprint */}
                <div style={{ display: this.props.tabNum == 3 ? 'block' : 'none' }}>
                    <p style={{ marginTop: '10px', marginBottom: '0px', display: isWarrantsShow ? 'block' : 'none' }}>
                        Underlying price below refers to the relevant Index Futures contract. Refer to the "Live index futures prices" on the home page to verify which Index Futures it is tracking.<br /> For US indices, it is typically tracking the quarterly month futures - reach us at +65 6601 0189 to confirm the month should you be unsure.
                    </p>
                    <p id="p-contract" style={{ marginTop: '10px', marginBottom: '0px', display: isUnderyingShow ? 'block' : 'none' }}>Underlying price below refers to the relevant Index Futures contract

                        <span> over</span>
                        <span className="text-bold"> {future_dsply_name}</span>
                    </p>
                </div>
                <table
                    id="today_table"
                    className="table lbtable"
                    style={{
                        position: "relative",
                        width: '100%'
                    }}
                >
                    <thead
                        style={{
                            position: "relative",
                            display: showAll ? "revert" : "none",
                        }}
                    >
                        {printRow}
                        <tr>
                            <th width="30%">
                                <span className="th-left th-callbid">{this.props.logic && this.props.logic.ricdata.type == 'PUT' ? 'Underlying offer' : 'Underlying bid'}</span>
                            </th>
                            <th width="20%">
                                <span className="th-left">Warrant bid</span>
                            </th>
                            <th width="20%">
                                <span className="th-right">Warrant offer</span>
                            </th>
                            <th width="20%">
                                <span className="th-right th-calloffer">{this.props.logic && this.props.logic.ricdata.type != 'PUT' ? 'Underlying offer' : 'Underlying bid'}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>{this.getTableContent(this.props.page)}</tbody>
                    <tbody
                        style={{
                            display: show ? "revert" : "none",
                        }}
                    >
                        <tr>
                            <td
                                colSpan="6"
                                className="text-content"
                                style={{ fontSize: "16px" }}
                            >
                                There is no matrix available for this warrant, as the warrant
                                has no value at current levels in the underlying
                            </td>
                        </tr>
                    </tbody>
                </table>
                {/* endprint */}
            </div>
        );
    }
}
class IndtableShow extends Component {
    getData(fieldName, dp = null) {
        if (
            this.props.warrantdata !== null &&
            fieldName in this.props.warrantdata
        ) {
            const value = this.props.warrantdata[fieldName];
            if (dp !== null) {
                return Number(value).toFixed(dp);
            }
            return value;
        }
        return "";
    }

    getTheta() {
        // "last_trading_date": "23 Feb 21"
        const lasttradingdate = this.getData("last_trading_date");
        const currDate = new Date();
        const currDateMS = currDate.getTime();
        if (lasttradingdate.length > 0) {
            const lasttradingdateMS = Date.parse(lasttradingdate);
            const diff = currDateMS - lasttradingdateMS;
            // check if expired
            if (diff <= 24 * 60 * 60 * 1000) {
                return this.getData("theta", 4);
            }
        }
        return "-";
    }

    getMoneynessClass(nc) {
        let cnm = "";
        const value = Number(nc);
        if (!isNaN(value)) {
            if (value === 1) {
                cnm = "val mny up";
            } else if (value === 2) {
                cnm = "val mny down";
            }
        }
        return cnm;
    }

    getMoneynessName(nc) {
        let nm = "";
        const value = Number(nc);
        if (!isNaN(value)) {
            if (value === 1) {
                return "ITM";
            } else if (value === 2) {
                return "OTM";
            } else {
                return "ATM";
            }
        }
        return nm;
    }

    render() {
        const hstclose = this.getData("HST_CLOSE");
        const trdprc1 = this.getData("TRDPRC_1");
        const openprc = this.getData("OPEN_PRC");
        const acvol1scaled = this.getData("ACVOL_1_SCALED");
        const high1 = this.getData("HIGH_1");
        const turnoverscaled = this.getData("TURNOVER_SCALED");
        const low1 = this.getData("LOW_1");
        const underlyingprice = this.getData("underlying_price");
        const ticker = this.getData("ticker");
        const effectivegearing = this.getData("effective_gearing");
        // const underlyingticker = this.getData("underlying_ticker");
        const underlyingticker =
            MQSGUtil.getUnderlyingTickerDisplayName(this.getData("underlying_ric"), this.getData("underlying_ticker"));
        const delta = this.getData("delta");
        const type = this.getData("type");
        const deltaperwrnt = this.getData("delta_per_wrnt");
        const issuername = this.getData("issuer_name");
        const sensitivity = this.getData("sensitivity");
        // const exerciseprice = this.getData("exercise_price");
        const breakevenprice = this.getData("breakeven_price");
        const tradedate = this.getData("TRADE_DATE");
        const premium = this.getData("premium");
        const maturity = this.getData("maturity");
        const impliedvolatility = this.getData("implied_volatility");
        const lasttradingdate = this.getData("last_trading_date");
        const theta = this.getTheta();
        const daystomaturity = this.getData("days_to_maturity") + " days";
        const intrinsicvalue = this.getData("intrinsic_value");
        var convratio = this.getData("conv_ratio");
        var exerciseprice = this.getData("exercise_price");
        if (this.props.warrantdata && this.props.warrantdata.dsply_name.indexOf('Tencent') != -1) {
            convratio = this.getData("conv_ratio_cal").toFixed(3);
            exerciseprice = this.getData("exercise_price_cal").toFixed(3);
        } else if (
            this.props.warrantdata &&
            (this.props.warrantdata.dsply_name.indexOf('SembInd') != -1 ||
                this.props.warrantdata.dsply_name.indexOf('CityDev') != -1 ||
                this.props.warrantdata.dsply_name.indexOf('KepCorp') != -1 ||
                this.props.warrantdata.dsply_name.indexOf('Singtel') != -1
            )
        ) {
            // convratio = this.getData("conv_ratio_cal").toFixed(5);
            convratio = Math.floor(this.getData("conv_ratio_cal") * 100) / 100;
        }
        const moneyness = this.getData("moneyness");
        const moneynessClass = this.getMoneynessClass(moneyness);
        const moneynessdisplaystring =
            this.getData("percent_moneyness") +
            "% " +
            this.getMoneynessName(moneyness);

        return (
            <div>
                <div id="srtable" style={{ position: "relative" }}>
                    <table className="table priorTable">
                        <tbody>
                            <tr>
                                <th
                                    className="srtable-leftlbl bgcolor-01"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    Prior
                                </th>
                                <td
                                    className="srtable-leftval bgcolor-01 val HST_CLOSE"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    {hstclose}
                                </td>
                                <th
                                    className="srtable-rightlbl bgcolor-02"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    Last trade
                                </th>
                                <td
                                    className="srtable-rightval bgcolor-02 val TRDPRC_1"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    {trdprc1}
                                </td>
                            </tr>
                            <tr>
                                <th className="srtable-leftlbl" style={{ border: 0, textAlign: 'left' }}>
                                    Open
                                </th>
                                <td
                                    className="srtable-leftval val OPEN_PRC"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    {openprc}
                                </td>
                                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                                    Traded volume ('000)
                                </th>
                                <td className="srtable-rightval val ACVOL_1_SCALED" style={{ textAlign: 'left' }}>
                                    {acvol1scaled}
                                </td>
                            </tr>
                            <tr>
                                <th
                                    className="srtable-leftlbl bgcolor-01"
                                    style={{ border: 0, textAlign: 'left', textAlign: 'left' }}
                                >
                                    High
                                </th>
                                <td
                                    className="srtable-leftval bgcolor-01 val HIGH_1"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    {high1}
                                </td>
                                <th
                                    className="srtable-rightlbl bgcolor-02"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    Traded value ('000 SGD)
                                </th>
                                <td
                                    className="srtable-rightval bgcolor-02 val TURNOVER_SCALED"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    {turnoverscaled}
                                </td>
                            </tr>
                            <tr>
                                <th className="srtable-leftlbl" style={{ textAlign: 'left' }}>Low</th>
                                <td className="srtable-leftval val LOW_1" style={{ border: 0, textAlign: 'left' }}>
                                    {low1}
                                </td>
                                <th className="srtable-rightlbl" style={{ border: 0, textAlign: 'left' }}>
                                    Underlying price
                                </th>
                                <td
                                    className="srtable-rightval val underlying_price"
                                    style={{ border: 0, textAlign: 'left' }}
                                >
                                    {underlyingprice}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h3>Terms</h3>
                <div id="indtable" style={{ position: "relative" }}>
                    <table className="table visible-md visible-lg table-closed table-striped indicator_table TermsTable">
                        <tbody>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="dwsymbol"
                                        data-hasqtip="0"
                                        data-tip
                                        data-for="Warrant-tooltip"
                                    >
                                        Warrant code
                                    </span>
                                    <ReactTooltip
                                        id="Warrant-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Warrant code: </b>
                                        Each warrant has an unique 4-digit identification code,
                                        <a
                                            target="_blank"
                                            href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantsnamed"
                                        >
                                            &nbsp;&nbsp;Click here&nbsp;&nbsp;
                                        </a>
                                        for a more detailed explanation.
                                    </ReactTooltip>
                                </th>
                                <td className="val ticker">{ticker}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="effectivegearing"
                                        data-hasqtip="1"
                                        data-tip
                                        data-for="Effective-tooltip"
                                    >
                                        Effective gearing (x)
                                    </span>
                                    <ReactTooltip
                                        id="Effective-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Effective gearing: </b>
                                        Indicates your leveraged exposure. An effective gearing of
                                        5X tells you a 1% move in the underlying stock/index results
                                        in an approximate 5% move in your Warrant price.
                                        <a
                                            target="_blank"
                                            href="https://www.warrants.com.sg/InvestorAcademy/effectivegearing"
                                        >
                                            &nbsp;&nbsp;Click here&nbsp;&nbsp;
                                        </a>
                                        to learn more about effective gearing.
                                    </ReactTooltip>
                                </th>
                                <td className="val effective_gearing">{effectivegearing}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="underlying"
                                        data-hasqtip="2"
                                        data-tip
                                        data-for="Underlying-tooltip"
                                    >
                                        Underlying
                                    </span>
                                    <ReactTooltip
                                        id="Underlying-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Underlying: </b>
                                        The stock or index over which the Warrant is listed.
                                    </ReactTooltip>
                                </th>
                                <td className="val underlying_ticker">
                                    <a href={"/tools/underlying/" + underlyingticker}>{underlyingticker}</a>
                                </td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="delta"
                                        data-hasqtip="3"
                                        aria-describedby="qtip-3"
                                        data-tip
                                        data-for="Delta-tooltip"
                                    >
                                        Delta (%)
                                    </span>
                                    <ReactTooltip
                                        id="Delta-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Delta: </b>
                                        Estimates how a warrant moves in relation to the underlying
                                        stock/index. Puts have negative deltas because their prices
                                        increase as the underlying stock/index falls. Note, if your
                                        warrant has a WPS that this will need to be taken into
                                        account.
                                    </ReactTooltip>
                                </th>
                                <td className="val delta">{delta}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="type"
                                        data-hasqtip="4"
                                        data-tip
                                        data-for="Type-tooltip"
                                    >
                                        Type
                                    </span>
                                    <ReactTooltip
                                        id="Type-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Type: </b>
                                        Calls increase in value as share price rises, Puts increase
                                        in value as share price falls.
                                    </ReactTooltip>
                                </th>
                                <td className="val type">{type}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="deltaperdw"
                                        data-hasqtip="5"
                                        data-tip
                                        data-for="Deltaper-tooltip"
                                    >
                                        Delta per warrant (%)
                                    </span>
                                    <ReactTooltip
                                        id="Deltaper-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Delta per warrant: </b>
                                        Delta/Warrant Per Share.
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/delta"
                                            target="_blank"
                                        >
                                            &nbsp;Click here&nbsp;
                                        </a>
                                        to learn more about Delta per warrant.
                                    </ReactTooltip>
                                </th>
                                <td className="val delta_per_wrnt">{deltaperwrnt}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="issuer"
                                        data-hasqtip="6"
                                        data-tip
                                        data-for="Issuer-tooltip"
                                    >
                                        Issuer
                                    </span>
                                    <ReactTooltip
                                        id="Issuer-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Issuer: </b>
                                        The Bank or Securities company that issues the Warrant.
                                    </ReactTooltip>
                                </th>
                                <td className="val issuer_name">{issuername}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="sensitivity"
                                        data-hasqtip="7"
                                        data-tip
                                        data-for="Sensitivity-tooltip"
                                    >
                                        Sensitivity
                                    </span>
                                    <ReactTooltip
                                        id="Sensitivity-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Sensitivity: </b>
                                        Shows you how much the underlying stock or index must move
                                        for your warrant to move one "tick" or "Spread". For
                                        warrants that cost less than $0.200, one tick is $0.001.
                                    </ReactTooltip>
                                </th>
                                <td className="val sensitivity">{sensitivity}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="exerciseprice"
                                        data-hasqtip="8"
                                        data-tip
                                        data-for="Exercise-tooltip"
                                    >
                                        Exercise price
                                    </span>
                                    <ReactTooltip
                                        id="Exercise-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Exercise price: </b>
                                        The price at which you can buy (calls) or sell (puts) the
                                        underlying
                                        <br />
                                        share or index at expiry.
                                    </ReactTooltip>
                                </th>
                                <td className="val exercise_price">{exerciseprice}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="breakevenpriceatexpiry"
                                        data-hasqtip="9"
                                        aria-describedby="qtip-9"
                                        data-tip
                                        data-for="Break-tooltip"
                                    >
                                        Break even price at expiry
                                    </span>
                                    <ReactTooltip
                                        id="Break-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Break even price at expiry: </b>
                                        If you are still holding the warrant at expiry, this is the
                                        price that the shares/index must exceed (fall below) for
                                        call (puts) for you to make a profit on your purchase price.
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/breakevenprice"
                                            target="_blank"
                                        >
                                            &nbsp;Click here&nbsp;
                                        </a>
                                        here for a more detailed description.
                                    </ReactTooltip>
                                </th>
                                <td className="val breakeven_price">{breakevenprice}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="mostrecenttradedate"
                                        data-hasqtip="10"
                                        data-tip
                                        data-for="Most-tooltip"
                                    >
                                        Most recent trade date
                                    </span>
                                    <ReactTooltip
                                        id="Most-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Most recent trade date: </b>
                                        The last date that the warrant recorded a trade. Note:
                                        Warrant do not trade every day, this has implications when
                                        calculating price changes.
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/howarewarrantspriced"
                                            target="_blank"
                                        >
                                            &nbsp; Click here &nbsp;
                                        </a>
                                        for more info.
                                    </ReactTooltip>
                                </th>
                                <td className="val TRADE_DATE">{tradedate}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="premium"
                                        data-hasqtip="11"
                                        data-tip
                                        data-for="Premium-tooltip"
                                    >
                                        Premium (%)
                                    </span>
                                    <ReactTooltip
                                        id="Premium-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Premium: </b>
                                        Also refered to as the "Time Value of the Warrant. Note a
                                        higher premium could be due to the warrant being longer
                                        dated or due to the higher gearing.
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/premium"
                                            target="_blank"
                                        >
                                            &nbsp;Click here &nbsp;
                                        </a>
                                        to learn more about premium.
                                    </ReactTooltip>
                                </th>
                                <td className="val premium">{premium}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="expiry"
                                        data-hasqtip="12"
                                        data-tip
                                        data-for="Expiry-tooltip"
                                    >
                                        Expiry
                                    </span>
                                    <ReactTooltip
                                        id="Expiry-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Expiry: </b>
                                        The date at which the warrant will expire.
                                    </ReactTooltip>
                                </th>
                                <td className="val maturity">{maturity}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="impliedvolatility"
                                        data-hasqtip="13"
                                        data-tip
                                        data-for="Volatility-tooltip"
                                    >
                                        Volatility (%)
                                    </span>
                                    <ReactTooltip
                                        id="Volatility-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Volatility: </b>
                                        The higher the implied volatility, the higher the price of
                                        the Warrant. Relates to the volatility and risk of the
                                        underlying share/Index.
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/impliedvolatility"
                                            target="_blank"
                                        >
                                            &nbsp;Click here&nbsp;
                                        </a>
                                        to learn more about implied volatility.
                                    </ReactTooltip>
                                </th>
                                <td className="val implied_volatility">{impliedvolatility}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="lasttradingdate"
                                        data-hasqtip="14"
                                        data-tip
                                        data-for="Last-tooltip"
                                    >
                                        Last trade date
                                    </span>
                                    <ReactTooltip
                                        id="Last-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Last trading date: </b>
                                        The last day to buy or sell the warrant. This date is always
                                        the 5th trading day prior to the expiry date.
                                    </ReactTooltip>
                                </th>
                                <td className="val last_trading_date">{lasttradingdate}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="theta"
                                        data-hasqtip="15"
                                        data-tip
                                        data-for="Theta-tooltip"
                                    >
                                        Theta (%)
                                    </span>
                                    <ReactTooltip
                                        id="Theta-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Theta: </b>
                                        The daily time decay of the warrant expressed in percentage
                                        terms.
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/timedecay"
                                            target="_blank"
                                        >
                                            &nbsp;Click here&nbsp;
                                        </a>
                                        to learn more about Theta.
                                    </ReactTooltip>
                                </th>
                                <td className="val theta">{theta}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="timetomaturity"
                                        data-hasqtip="16"
                                        data-tip
                                        data-for="Daysto-tooltip"
                                    >
                                        Days to maturity
                                    </span>
                                    <ReactTooltip
                                        id="Daysto-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Days to maturity: </b>
                                        The number of days left until the expiry date of the
                                        warrant.
                                    </ReactTooltip>
                                </th>
                                <td className="val days_to_maturity">{daystomaturity}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="intrinsicvalueperdw"
                                        data-hasqtip="17"
                                        data-tip
                                        data-for="Intrinsic-tooltip"
                                    >
                                        Intrinsic value per warrant
                                    </span>
                                    <ReactTooltip
                                        id="Intrinsic-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Intrinsic value per warrant: </b>
                                        This is the difference between the exercise price and the
                                        current share price. For call warrant, if the exercise price
                                        is below the current share price it has intrinsic value,
                                        vice versa for puts. Please
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/intrinsicvalueandtimevalue"
                                            target="_blank"
                                        >
                                            &nbsp;Click here
                                        </a>
                                    </ReactTooltip>
                                </th>
                                <td className="val intrinsic_value">{intrinsicvalue}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="warrantpershare"
                                        data-hasqtip="18"
                                        aria-describedby="qtip-18"
                                        data-tip
                                        data-for="Warrantpershare-tooltip"
                                    >
                                        Warrant per share
                                    </span>
                                    <ReactTooltip
                                        id="Warrantpershare-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Warrant per share: </b>
                                        The number of warrants that entitles you to one unit of the
                                        underlying share or index at expiry.
                                    </ReactTooltip>
                                </th>
                                <td className="val conv_ratio">{convratio}</td>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="moneyness"
                                        data-hasqtip="19"
                                        data-tip
                                        data-for="Moneyness-tooltip"
                                    >
                                        Moneyness
                                    </span>
                                    <ReactTooltip
                                        id="Moneyness-tooltip"
                                        className="search-tooltip"
                                        delayHide={100}
                                        place="right"
                                        type="warning"
                                        effect="solid"
                                        backgroundColor="#F1D031"
                                        textColor="#000"
                                    >
                                        <b>Moneyness: </b>
                                        ITM (in-the-money), OTM (out-of-the-money), ATM
                                        (at-the-money).
                                        <a
                                            href="https://www.warrants.com.sg/InvestorAcademy/moneyness"
                                            target="_blank"
                                        >
                                            &nbsp; Click here&nbsp;
                                        </a>
                                        to learn more about moneyness.
                                    </ReactTooltip>
                                </th>
                                <td className={moneynessClass}>{moneynessdisplaystring}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table hidden-md hidden-lg table-closed table-striped">
                        <tbody>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="dwsymbol"
                                        data-hasqtip="20"
                                    >
                                        Warrant code
                                    </span>
                                </th>
                                <td className="val ticker">{ticker}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="underlying"
                                        data-hasqtip="21"
                                    >
                                        Underlying
                                    </span>
                                </th>
                                <td className="val underlying_ticker">
                                    <a href="/tools/underlying/HSI">{underlyingticker}</a>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span className="ttip" data-target="type" data-hasqtip="22">
                                        Type
                                    </span>
                                </th>
                                <td className="val type">{type}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span className="ttip" data-target="issuer" data-hasqtip="23">
                                        Issuer
                                    </span>
                                </th>
                                <td className="val issuer_name">{issuername}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span className="ttip" data-target="" data-hasqtip="24">
                                        Exercise price
                                    </span>
                                </th>
                                <td className="val exercise_price">{exerciseprice}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="mostrecenttradedate"
                                        data-hasqtip="25"
                                    >
                                        Most recent trade date
                                    </span>
                                </th>
                                <td className="val TRADE_DATE">{tradedate}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span className="ttip" data-target="expiry" data-hasqtip="26">
                                        Expiry
                                    </span>
                                </th>
                                <td className="val maturity">{maturity}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="lasttradingdate"
                                        data-hasqtip="27"
                                    >
                                        Last trading date
                                    </span>
                                </th>
                                <td className="val last_trading_date">{lasttradingdate}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="timetomaturity"
                                        data-hasqtip="28"
                                    >
                                        Days to maturity
                                    </span>
                                </th>
                                <td className="val days_to_maturity">{daystomaturity}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="warrantpershare"
                                        data-hasqtip="29"
                                    >
                                        Warrant per share
                                    </span>
                                </th>
                                <td className="val conv_ratio">{convratio}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="effectivegearing"
                                        data-hasqtip="30"
                                    >
                                        Effective gearing (x)
                                    </span>
                                </th>
                                <td className="val effective_gearing">{effectivegearing}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span className="ttip" data-target="delta" data-hasqtip="31">
                                        Delta
                                    </span>
                                </th>
                                <td className="val delta">{delta}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="deltaperdw"
                                        data-hasqtip="32"
                                    >
                                        Delta per warrant
                                    </span>
                                </th>
                                <td className="val delta_per_wrnt">{deltaperwrnt}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="sensitivity"
                                        data-hasqtip="33"
                                    >
                                        Sensitivity
                                    </span>
                                </th>
                                <td className="val sensitivity">{sensitivity}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="breakevenpriceatexpiry"
                                        data-hasqtip="34"
                                    >
                                        Break even price at expiry
                                    </span>
                                </th>
                                <td className="val breakeven_price">{breakevenprice}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="premium"
                                        data-hasqtip="35"
                                    >
                                        Premium(%)
                                    </span>
                                </th>
                                <td className="val premium">{premium}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="impliedvolatility"
                                        data-hasqtip="36"
                                    >
                                        Volatility (%)
                                    </span>
                                </th>
                                <td className="val implied_volatility">{impliedvolatility}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span className="ttip" data-target="theta" data-hasqtip="37">
                                        Theta (%)
                                    </span>
                                </th>
                                <td className="val theta">{theta}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="intrinsicvalueperdw"
                                        data-hasqtip="38"
                                    >
                                        Intrinsic value per warrant
                                    </span>
                                </th>
                                <td className="val intrinsic_value">{intrinsicvalue}</td>
                            </tr>
                            <tr>
                                <th>
                                    <span
                                        className="ttip"
                                        data-target="moneyness"
                                        data-hasqtip="39"
                                    >
                                        Moneyness
                                    </span>
                                </th>
                                <td className={moneynessClass}>{moneynessdisplaystring}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
class TermsChart extends Component {
    constructor(props) {
        super(props);
        // this.chart = React.createRef();
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            minDate: new Date(),
            MaxDate: new Date(),
            chartData: null,
            warrantPrice: '-',
            Underlying: '-',
            day: '-',
            last: '-',
            last1: '-',
            from: '-',
            from1: '-',
            fixedStartDate: '-',
            fixedEndDate: '-',
            switch: false,
            lastRic: true
        };
    }
    onPeriodChange(period) {
        this.props.onPeriodChange(period);
    }
    componentWillReceiveProps(props) {
        if (props.chartdata && props.warrantdata) {
            // if (props.chartdata == this.props.chartdata) {
            //     return false;
            // }
            // if (props.chartdata.data.length == 0 && props.chartdata.data1.length == 0) {
            //     return false;
            // }
            var min = '';
            var max = ''
            this.chart.current.chartRef.current.chart.xAxis[0].update({ min: null, max: null })
            var arrMin = props.chartdata.data[0].time.replace(' 00:00', '');
            var arrMax = props.chartdata.data[props.chartdata.data.length - 1].time.replace(' 00:00', '');
            var arrMin2 = props.chartdata.data1.length > 0 ? props.chartdata.data1[0].time.replace(' 00:00', '') : '';
            var arrMax2 = props.chartdata.data1.length > 0 ? props.chartdata.data1[props.chartdata.data1.length - 1].time.replace(' 00:00', '') : '';
            var minDate = new Date(props.warrantdata.list_date);
            var maxDate = new Date();
            if (props.chartdata.data1.length < 1) {
                min = arrMin
            } else {
                min = arrMin2
            }

            if (new Date(arrMax) < new Date(arrMax2)) {
                max = arrMax2
            } else {
                max = arrMax
            }
            // }
            var date = null;
            // var currentDate = new Date(max);
            var lastRic = JSON.parse(sessionStorage.getItem('lastRic'))
            if (this.state.switch) {
                var parameterSet = {
                    chartData: props.chartdata,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    switch: false
                }
            } else {
                if (lastRic && this.state.lastRic && lastRic.ric == this.props.selected.value) {
                    var parameterSet = {
                        chartData: props.chartdata,
                        startDate: new Date(lastRic.startDate),
                        endDate: new Date(lastRic.endDate),
                        lastRic: false
                    }
                } else {
                    var parameterSet = {
                        chartData: props.chartdata,
                        startDate: new Date(min),
                        endDate: new Date(max),
                        lastRic: false
                    }
                }

            }
            // if (this.state.fixedStartDate == '-' && this.state.fixedEndDate == '-') {
            //     date = {
            //         minDate: minDate,
            //         MaxDate: maxDate,
            //         fixedStartDate: minDate,
            //         fixedEndDate: maxDate,
            //     }
            //     if (lastRic && lastRic.ric != this.props.selected.value || lastRic == null) {
            //         window.setTimeout(() => {
            //             this.onPeriodChange('W')
            //         }, 300)
            //     }
            // } else {
            //     date = {}
            // }
            date = {
                minDate: minDate,
                MaxDate: maxDate,
            }
            this.setState(Object.assign(parameterSet, date), () => {
                this.changeDate(props.chartdata)
            })
        }
    }
    changeDate(chartdata) {
        if (this.props.period != "D") {
            var dataArr = this.chart.current.chartRef.current.chart.series[0].xData;
            var dataArr2 = this.chart.current.chartRef.current.chart.series[1].xData;
            var underlyingMax = undefined;
            var underlyingMin = undefined;
            var warrantMax = undefined;
            var warrantMin = undefined;
            if (dataArr.indexOf(this.state.endDate.getTime()) != -1) {
                underlyingMax = this.chart.current.chartRef.current.chart.series[0].yData[dataArr.indexOf(this.state.endDate.getTime())]
            }
            if (dataArr.indexOf(this.state.startDate.getTime()) != -1) {
                underlyingMin = this.chart.current.chartRef.current.chart.series[0].yData[dataArr.indexOf(this.state.startDate.getTime())]
            }
            if (dataArr2.indexOf(this.state.endDate.getTime()) != -1) {
                warrantMax = this.chart.current.chartRef.current.chart.series[1].yData[dataArr2.indexOf(this.state.endDate.getTime())]
            }
            if (dataArr2.indexOf(this.state.startDate.getTime()) != -1) {
                warrantMin = this.chart.current.chartRef.current.chart.series[1].yData[dataArr2.indexOf(this.state.startDate.getTime())]
            }
            var str = ''
            var str1 = ''
            if (((underlyingMax - underlyingMin) / underlyingMin).toFixed(2) * 100 == Infinity || isNaN(((underlyingMax - underlyingMin) / underlyingMin).toFixed(2) * 100)) {
                str = '-';
            } else {
                str = this.floatMultiply(((underlyingMax - underlyingMin) / underlyingMin).toFixed(3), 100) + '%';
            }
            if (((warrantMax - warrantMin) / warrantMin).toFixed(2) * 100 == Infinity || isNaN(((warrantMax - warrantMin) / warrantMin).toFixed(2) * 100)) {
                str1 = '-';
            } else {
                str1 = this.floatMultiply(((warrantMax - warrantMin) / warrantMin).toFixed(3), 100) + '%';
            }
            var day = (this.state.endDate.getTime() - this.state.startDate.getTime()) / (1000 * 60 * 60 * 24)
            // if (day == 0 || day > 0 && day < 1) {
            //   day = 1
            // }
            if (day < 1) {
                day = 1
            }
            this.setState({
                warrantPrice: str1,
                Underlying: str,
                last: warrantMin ? warrantMin : '-',
                last1: underlyingMin ? underlyingMin : '-',
                from: warrantMax ? warrantMax : '-',
                from1: underlyingMax ? underlyingMax : '-',
                day: Math.ceil(day)
            })
            this.chart.current.chartRef.current.chart.xAxis[0].update({ max: this.state.endDate.getTime(), min: this.state.startDate.getTime() })
        } else {
            var underlyingMax = undefined;
            var underlyingMin = undefined;
            var warrantMax = undefined;
            var warrantMin = undefined;
            underlyingMax = chartdata.data[0].close;
            underlyingMin = chartdata.data[chartdata.data.length - 1].close;
            warrantMax = chartdata.data1[0].close;
            warrantMin = chartdata.data1[chartdata.data1.length - 1].close;
            var str = ''
            var str1 = ''
            if (((underlyingMin - underlyingMax) / underlyingMax).toFixed(2) * 100 == Infinity || isNaN(((underlyingMin - underlyingMax) / underlyingMax).toFixed(2) * 100)) {
                str = '-';
            } else {
                str = this.floatMultiply(((underlyingMin - underlyingMax) / underlyingMax).toFixed(3), 100) + '%';
            }
            if (((warrantMin - warrantMax) / warrantMax).toFixed(2) * 100 == Infinity || isNaN(((warrantMin - warrantMax) / warrantMax).toFixed(2) * 100)) {
                str1 = '-';
            } else {
                str1 = this.floatMultiply(((warrantMin - warrantMax) / warrantMax).toFixed(3), 100) + '%';
            }
            var day = (this.state.endDate.getTime() - this.state.startDate.getTime()) / (1000 * 60 * 60 * 24)
            this.setState({
                warrantPrice: str1,
                Underlying: str,
                // last: warrantMin ? warrantMin : '-',
                // last1: underlyingMin ? underlyingMin : '-',
                // from: warrantMax ? warrantMax : '-',
                // from1: underlyingMax ? underlyingMax : '-',
                from: warrantMin ? warrantMin : '-',
                from1: underlyingMin ? underlyingMin : '-',
                last: warrantMax ? warrantMax : '-',
                last1: underlyingMax ? underlyingMax : '-',
                day: 1
            })
            // this.chart.current.chartRef.current.chart.xAxis[0].update({ max: this.state.endDate.getTime(), min: this.state.startDate.getTime() })
        }
    }
    componentDidMount() {
        this.chart = React.createRef();
        // this.changeDate(this.props.chartdata)
    }
    setStartDate(date) {
        if (date != null) {
            if (this.state.startDate.getTime() == date.getTime()) {
                return false;
            }
            var endDate = '';
            var boolean = false;
            if (this.props.period == "D") {
                endDate = this.state.MaxDate
                var time = new Date(date + '')
                var y = time.getFullYear();
                var m = time.getMonth() + 1;
                var d = time.getDate();
                var startDate = new Date(y + '-' + m + '-' + d + ' ' + '08:00:00')
            } else {
                endDate = this.state.endDate
                var startDate = date
            }
            if (this.props.period != "Y") {
                this.onPeriodChange('Y')
                boolean = true
            }
            this.setState({
                startDate: startDate,
                switch: boolean,
                endDate: endDate
            }, () => {
                sessionStorage.setItem('lastRic', JSON.stringify({ ric: this.props.selected.value, startDate: this.state.startDate, endDate: this.state.endDate }))
                this.changeDate(this.props.chartdata)

            })
        }
    }
    floatMultiply(arg1, arg2) {
        if (arg1 == null || arg2 == null) {
            return null;
        }
        var n1, n2;
        var r1, r2;
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        n1 = Number(arg1.toString().replace(".", ""));
        n2 = Number(arg2.toString().replace(".", ""));
        return n1 * n2 / Math.pow(10, r1 + r2);
    }
    setEndDate(date) {
        if (date != null) {
            var boolean = false;
            if (this.props.period != "Y") {
                this.onPeriodChange('Y')
                boolean = true
            }
            this.setState({
                endDate: date,
                switch: boolean
            }, () => {
                sessionStorage.setItem('lastRic', JSON.stringify({ ric: this.props.selected.value, startDate: this.state.startDate, endDate: this.state.endDate }))
                this.changeDate(this.props.chartdata)
            })
        }
    }
    render() {
        return (
            <div className="termschart" style={{ marginTop: '30px' }}>
                <table id="priceTable" style={{ fontSize: '14px' }}>
                    <tbody>
                        <tr>
                            <td colSpan="4" style={{ backgroundColor: '#DFDBCF', lineHeight: '35px' }}>Warrant vs. Underlying Performance</td>
                        </tr>
                        <tr style={{ background: '#F5F6F7' }}>
                            <td rowSpan="2" className="isShowPC">

                                From
                                &nbsp;
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => this.setStartDate(date)}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.endDate}
                                    selectsStart
                                />
                            </td>
                            <td className="isShowMobile">From</td>
                            <td>Warrant Price</td>
                            <td>Underlying</td>
                            <td>Number of days</td>
                        </tr>
                        <tr style={{ background: '#F5F6F7' }}>
                            <td className="isShowMobile">
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => this.setStartDate(date)}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.endDate}
                                    selectsStart
                                />
                            </td>
                            <td>{this.state.last}</td>
                            <td>{this.state.last1}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                To
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <DatePicker
                                    selectsEnd
                                    selected={this.state.endDate}
                                    onChange={(date) => this.setEndDate(date)}
                                    minDate={this.state.startDate}
                                    maxDate={this.state.MaxDate}
                                />
                            </td>
                            <td>{this.state.from}</td>
                            <td>{this.state.from1}</td>
                            <td></td>
                        </tr>
                        <tr style={{ background: '#F5F6F7' }}>
                            <td style={{ width: '36%' }}>
                            </td>
                            <td style={{ color: this.state.warrantPrice.indexOf('-') != -1 ? 'red' : '#6CC263' }}>{this.state.warrantPrice}</td>
                            <td style={{ color: this.state.Underlying.indexOf('-') != -1 ? 'red' : '#6CC263' }}>{this.state.Underlying}</td>
                            <td style={{ color: 'red' }}>{this.state.day}</td>
                        </tr>
                    </tbody>
                </table>
                <table id="periodtab" className="ctab">
                    <tbody>
                        <tr>
                            <td
                                className={classNames(
                                    { active: this.props.period === "D" },
                                    "ctab-item"
                                )}
                                onClick={() => this.onPeriodChange("D")}
                            >
                                1D
                            </td>
                            <td className="ctab-item-space">&nbsp;</td>
                            <td
                                className={classNames(
                                    { active: this.props.period === "W" },
                                    "ctab-item"
                                )}
                                onClick={() => this.onPeriodChange("W")}
                            >
                                1W
                            </td>
                            <td className="ctab-item-space">&nbsp;</td>
                            <td
                                className={classNames(
                                    { active: this.props.period === "M" },
                                    "ctab-item"
                                )}
                                onClick={() => this.onPeriodChange("M")}
                            >
                                1M
                            </td>
                            <td className="ctab-item-space">&nbsp;</td>
                            <td
                                className={classNames(
                                    { active: this.props.period === "Y" },
                                    "ctab-item"
                                )}
                                onClick={() => this.onPeriodChange("Y")}
                            >
                                MAX
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="chart-legend">
                    <div className="row hidden-xs">
                        <div className="chart-legend-left col-sm-6">
                            <div className="chart-legend-item">
                                Warrant bid price (SGD)
                                <span className="chart-legend-bar warrant"></span>
                            </div>
                        </div>
                        <div className="chart-legend-right col-sm-6">
                            <div className="chart-legend-item">
                                Underlying price
                                <span className="chart-legend-bar underlying"></span>
                            </div>
                        </div>
                    </div>
                    <div className="row visible-xs">
                        <table className="chart-legend-tbl">
                            <tbody>
                                <tr>
                                    <td>
                                        Warrant bid price (<span className="SGD"></span>)
                                    </td>
                                    <td>
                                        <span className="chart-legend-bar warrant"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Underlying price (
                                        <span className="underlying_curr">HKD</span>)
                                    </td>
                                    <td>
                                        <span className="chart-legend-bar underlying"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="chartbox" className="chartbox">
                    <Chart
                        period={this.props.period}
                        data={this.props.chartdata}
                        ref={this.chart}
                    />
                </div>
            </div>
        );
    }
}
class WrCalculatorBox extends Component {
    constructor(props) {
        super(props);
        this.state = this.init(props);
        this.timer = null;
    }
    isRightPanel() {
        if (
            typeof this.props.isRightPanel !== "undefined" &&
            this.props.isRightPanel !== null
        ) {
            return this.props.isRightPanel;
        }
        return false;
    }
    initbdval(marketdata) {
        const bdval = {
            currentValue: 0,
            stopValue: 0,
            typing: "",
            max: 1.0,
            min: 0.0,
            step: 0.01,
            initial: 0.0,
            dp: 2,
        };
        const underlyingprice = MQSGUtil.getNumber(
            marketdata,
            "underlying_price",
            null
        );
        const tick = marketdata.underlying_ticker;
        if (
            tick == 'HSI' ||
            tick == 'HSTECH' ||
            tick == 'STI' ||
            tick == 'N225'
        ) {
            bdval.dp = 0;
        }
        if (underlyingprice !== null) {
            bdval.initial = underlyingprice;
            bdval.currentValue = underlyingprice;
            bdval.stopValue = underlyingprice;
            bdval.typing = this.toThousands(underlyingprice);
            var indexUnderlyingTokens = [
                ".STI",
                "HSIc",
                "SSIcv",
                "ESc"
            ];
            var maxValue = Math.ceil(underlyingprice * 3);
            for (var i = 0; i < indexUnderlyingTokens.length; i++) {
                if (marketdata.underlying_ric.indexOf(indexUnderlyingTokens[i]) != -1) {
                    maxValue = Math.ceil(underlyingprice * 1.34);
                    break;
                }
            }
            // const maxValue = Math.ceil(underlyingprice * 3.0);
            // const maxValue = Math.ceil(underlyingprice * 1.34);
            bdval.max = maxValue;
        }
        return { bdval, underlyingprice };
    }

    initdateval(marketdata) {
        const dateval = {
            currentValue: 0,
            stopValue: 0,
            max: 31,
            min: 0,
            step: 1,
        };
        const lasttradingdate = MQSGUtil.getData(
            marketdata,
            "last_trading_date",
            null
        );
        if (lasttradingdate !== null) {
            const lastTradeDate = Date.parse(lasttradingdate);
            const currentDate = new Date();
            const diffTime = Math.abs(lastTradeDate - currentDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            dateval.max = diffDays;
        }
        return { dateval };
    }

    initivval(marketdata) {
        const ivval = {
            currentValue: 0,
            stopValue: 0,
            typing: "",
            max: 150.0,
            min: 10.0,
            step: 0.1,
            initial: 0.0,
            dp: 1,
        };
        const impliedvolatility = MQSGUtil.getNumber(
            marketdata,
            "implied_volatility",
            null
        );
        if (impliedvolatility !== null) {
            ivval.initial = impliedvolatility;
            ivval.currentValue = impliedvolatility;
            ivval.stopValue = impliedvolatility;
            ivval.typing = impliedvolatility.toFixed(ivval.dp);
        }
        return { ivval, impliedvolatility };
    }

    initWarrantBidPrice(marketdata, impliedvolatility, underlyingprice) {
        const warrantBidPrice = {
            currentValue: null,
            initial: null,
        };
        warrantBidPrice.initial = MQSGUtil.getData(
            marketdata,
            // "warrant_price",
            'BID',
            null
        );
        const selectedDate = this.generateDateString(0);
        this.logic.initMatrix(underlyingprice, impliedvolatility, selectedDate);
        // const calculatedWarrantBidPrice = this.logic.getWarrantBidPrice();
        // if (!isNaN(Number(calculatedWarrantBidPrice))) {
        //   warrantBidPrice.currentValue = calculatedWarrantBidPrice;
        // }
        warrantBidPrice.currentValue = warrantBidPrice.initial
        return { warrantBidPrice, selectedDate };
    }
    componentDidMount() {
        this.onReset()
    }
    init(props) {
        let initData = this.initNoData();
        if (typeof props.marketdata !== "undefined" && props.marketdata !== null) {
            this.logic = new WrCalculatorLogic(props.marketdata);
            initData = this.initData(props.marketdata);
        }
        return initData;
    }

    initNoData() {
        const bdval = {
            currentValue: 0,
            stopValue: 0,
            typing: "",
            max: 1.0,
            min: 0.0,
            step: 0.01,
            initial: 0.0,
            dp: 2,
        };
        const ecval = {
            currentValue: 0,
            stopValue: 0,
            typing: "",
            max: 1.0,
            min: 0.0,
            step: 0.01,
            initial: 0.0,
            dp: 2,
        };
        const edateval = {
            currentValue: 0,
            stopValue: 0,
            max: 31,
            min: 0,
            step: 1,
        };
        const eivval = {
            currentValue: 0,
            stopValue: 0,
            typing: "",
            max: 150.0,
            min: 10.0,
            step: 0.1,
            initial: 0.0,
            dp: 1,
        };
        const dateval = {
            currentValue: 0,
            stopValue: 0,
            max: 31,
            min: 0,
            step: 1,
        };
        const ivval = {
            currentValue: 0,
            stopValue: 0,
            typing: "",
            max: 150.0,
            min: 10.0,
            step: 0.1,
            initial: 0.0,
            dp: 1,
        };
        const warrantBidPrice = {
            currentValue: null,
            initial: null,
        };
        return {
            bdval,
            ecval,
            edateval,
            eivval,
            dateval,
            ivval,
            warrantBidPrice,
            delta: 0.0,
            effgearing: 0.0,
            dwpchg: 0.0,
        };
    }

    initData(marketdata) {
        const initData = {
            bdval: null,
            ecval: null,
            edateval: null,
            eivval: null,
            dateval: null,
            ivval: null,
            warrantBidPrice: null,
            delta: 0.0,
            effgearing: 0.0,
            dwpchg: 0.0,
        };
        const initbdval = this.initbdval(marketdata);
        const initecval = this.initbdval(marketdata);
        const initdateval = this.initdateval(marketdata);
        const initedateval = this.initdateval(marketdata);
        const initivval = this.initivval(marketdata);
        const initeivval = this.initivval(marketdata);
        const initWarrantBidPrice = this.initWarrantBidPrice(
            marketdata,
            initivval.impliedvolatility,
            initbdval.underlyingprice
        );
        initData.bdval = initbdval.bdval;
        initData.ecval = initecval.bdval;
        initData.dateval = initdateval.dateval;
        initData.edateval = initedateval.dateval;
        initData.ivval = initivval.ivval;
        initData.eivval = initeivval.ivval;
        initData.warrantBidPrice = initWarrantBidPrice.warrantBidPrice;
        const deltaAndEffgearing = this.logic.getDeltaAndEffGearingAndDwpChange(
            initbdval.underlyingprice,
            initWarrantBidPrice.selectedDate
        );
        initData.delta = deltaAndEffgearing.delta;
        initData.effgearing = deltaAndEffgearing.effgearing;
        initData.dwpchg = deltaAndEffgearing.dwpchg;
        return initData;
    }

    recalculateMatrix(
        underlyingBidPrice = null,
        impliedVolatility = null,
        noOfDay = null,
        n
    ) {
        let impliedVolatilityUse = impliedVolatility;
        if (impliedVolatilityUse === null) {
            impliedVolatilityUse = this.state.ivval.currentValue;
        }
        let underlyingBidPriceUse = underlyingBidPrice;
        if (underlyingBidPriceUse === null) {
            underlyingBidPriceUse = this.state.bdval.currentValue;
        }
        let selectedDateUse = this.getDate();
        if (noOfDay !== null) {
            selectedDateUse = this.generateDateString(noOfDay);
        }
        this.logic.initMatrix(
            underlyingBidPriceUse,
            impliedVolatilityUse,
            selectedDateUse
        );
        const calculatedWarrantBidPrice = this.logic.getWarrantBidPrice();
        if (!isNaN(Number(calculatedWarrantBidPrice))) {
            const warrantBidPrice = this.state.warrantBidPrice;
            if (n == 2) {
                warrantBidPrice.currentValue = calculatedWarrantBidPrice;
            } else if (n == 1 || n == 3) {
                warrantBidPrice.initial = calculatedWarrantBidPrice;
            } else {
                warrantBidPrice.currentValue = calculatedWarrantBidPrice;
            }

            this.setState({ warrantBidPrice });
        }
        const deltaAndEffgearing = this.logic.getDeltaAndEffGearingAndDwpChange(
            underlyingBidPriceUse,
            selectedDateUse
        );
        this.setState({
            delta: deltaAndEffgearing.delta,
            effgearing: deltaAndEffgearing.effgearing,
            dwpchg: deltaAndEffgearing.dwpchg,
        });
    }

    onReset() {
        const bdval = this.state.bdval;
        bdval.currentValue = bdval.initial;
        bdval.stopValue = bdval.initial;
        bdval.typing = bdval.initial.toFixed(bdval.dp);

        const ecval = this.state.ecval;
        ecval.currentValue = ecval.initial;
        ecval.stopValue = ecval.initial;
        ecval.typing = ecval.initial.toFixed(ecval.dp);

        const dateval = this.state.dateval;
        dateval.currentValue = 0;
        dateval.stopValue = 0;

        const edateval = this.state.edateval;
        edateval.currentValue = 0;
        edateval.stopValue = 0;

        const ivval = this.state.ivval;
        ivval.currentValue = ivval.initial;
        ivval.stopValue = ivval.initial;
        ivval.typing = ivval.initial.toFixed(ivval.dp);

        const eivval = this.state.eivval;
        eivval.currentValue = eivval.initial;
        eivval.stopValue = eivval.initial;
        eivval.typing = eivval.initial.toFixed(ivval.dp);

        this.setState({ bdval, dateval, ivval, ecval, eivval, edateval });
        this.recalculateMatrix(bdval.initial, ivval.initial, 0);
        this.recalculateMatrix(ecval.initial, eivval.initial, edateval.initial, 3);
    }
    toThousands(str) {
        var newStr = "";
        var count = 0;
        var str = str + '';
        if (str.indexOf(".") == -1) {
            for (var i = str.length - 1; i >= 0; i--) {
                if (count % 3 == 0 && count != 0) {
                    newStr = str.charAt(i) + "," + newStr;
                } else {
                    newStr = str.charAt(i) + newStr;
                }
                count++;
            }
            str = newStr + ".00";

        }
        else {
            for (var i = str.indexOf(".") - 1; i >= 0; i--) {
                if (count % 3 == 0 && count != 0) {
                    newStr = str.charAt(i) + "," + newStr;
                } else {
                    newStr = str.charAt(i) + newStr;
                }
                count++;
            }
            str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
        }
        return str
    }
    toRigitThousands(str) {
        var newStr = "";
        var count = 0;
        var str = str + '';
        if (str.indexOf(".") == -1) {
            for (var i = str.length - 1; i >= 0; i--) {
                if (count % 3 == 0 && count != 0) {
                    newStr = str.charAt(i) + "," + newStr;
                } else {
                    newStr = str.charAt(i) + newStr;
                }
                count++;
            }
            str = newStr;
        }
        else {
            for (var i = str.indexOf(".") - 1; i >= 0; i--) {
                if (count % 3 == 0 && count != 0) {
                    newStr = str.charAt(i) + "," + newStr;
                } else {
                    newStr = str.charAt(i) + newStr;
                }
                count++;
            }
            str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
        }
        return str
    }
    onUnderlyingBidPriceChange(event) {
        const newValue = this.state.bdval;
        newValue.currentValue = event.target.value;
        newValue.typing = this.toThousands(event.target.value);
        this.setState({ bdval: newValue });
    }
    onECUnderlyingBidPriceChange(event) {
        const newValue = this.state.ecval;
        newValue.currentValue = event.target.value;
        newValue.typing = this.toThousands(event.target.value);
        this.setState({ ecval: newValue });
    }

    onUnderlyingBidPriceInputChange(event) {
        const newValue = this.state.bdval;
        newValue.typing = event.target.value;
        this.setState({ bdval: newValue }, () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.onUnderlyingBidPriceKeyDown(event, true)
            }, 1000);
        });
    }
    onECUnderlyingBidPriceInputChange(event) {
        const newValue = this.state.ecval;
        newValue.typing = event.target.value;
        this.setState({ ecval: newValue }, () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.onECUnderlyingBidPriceKeyDown(event, true)
            }, 1000);
        });
    }
    moneyDelete(num) {
        if (num && num != undefined && num != null) {
            let _num = num;
            _num = _num.toString();
            _num = _num.replace(/,/gi, '');
            return _num
        } else {
            return num
        }
    }
    onUnderlyingBidPriceKeyDown(event, val) {
        if (event.key === "Enter" || val == true) {
            let value = null;
            if (this.state.bdval.typing.indexOf(',') != -1) {
                value = Number(this.moneyDelete(this.state.bdval.typing));
            } else {
                value = Number(this.state.bdval.typing);
            }
            if (this.state.bdval.typing.length > 0 && !isNaN(value)) {
                if (value < this.state.bdval.min || value > this.state.bdval.max) {
                    value = null;
                }
            } else {
                value = null;
            }
            if (value === null) {
                value = this.state.bdval.initial;
            }
            const event = { target: { value } };
            this.onUnderlyingBidPriceChangeStop(event);
        }
    }
    onECUnderlyingBidPriceKeyDown(event, val) {
        if (event.key === "Enter" || val == true) {
            let value = null;
            if (this.state.ecval.typing.indexOf(',') != -1) {
                value = Number(this.moneyDelete(this.state.ecval.typing));
            } else {
                value = Number(this.state.ecval.typing);
            }
            if (this.state.ecval.typing.length > 0 && !isNaN(value)) {
                if (value < this.state.ecval.min || value > this.state.ecval.max) {
                    value = null;
                }
            } else {
                value = null;
            }
            if (value === null) {
                value = this.state.ecval.initial;
            }
            const event = { target: { value } };
            this.onECUnderlyingBidPriceChangeStop(event);
        }
    }
    onUnderlyingBidPriceChangeStop(event) {
        const newValue = this.state.bdval;
        newValue.currentValue = event.target.value;
        newValue.stopValue = event.target.value;
        newValue.typing = this.toThousands(event.target.value.toFixed(this.state.bdval.dp));
        this.setState({ bdval: newValue });
        this.recalculateMatrix(event.target.value, null, null, 2);
    }

    onECUnderlyingBidPriceChangeStop(event) {
        const newValue = this.state.ecval;
        newValue.currentValue = event.target.value;
        newValue.stopValue = event.target.value;
        newValue.typing = this.toThousands(event.target.value.toFixed(this.state.ecval.dp));
        this.setState({ ecval: newValue });
        this.recalculateMatrix(event.target.value, null, null, 1);
    }

    getUnderlyingBidPrice() {
        return this.state.bdval.currentValue.toFixed(this.state.bdval.dp);
    }

    getUnderlyingBidPriceLastStopValue() {
        return this.state.bdval.typing;
    }

    calculateUnderlyingBidPriceChange() {
        const diff = this.state.bdval.stopValue - this.state.ecval.stopValue;
        // const change = (diff / this.state.ecval.stopValue) * 100.0;
        let change = Number(diff) == 0 || Number(this.state.ecval.stopValue) == 0 ? 0 : (diff / this.state.ecval.stopValue) * 100.0;
        return { diff, change };
    }

    getUnderlyingBidPriceChangePercentage() {
        const result = this.calculateUnderlyingBidPriceChange();
        // const change = result.change.toFixed(1);
        const change = result.change == 0 ? '0.0' : result.change.toFixed(1);
        return <>{change}</>;
    }

    getUnderlyingBidPrice3() {
        const result = this.calculateUnderlyingBidPriceChange();
        let upDown = "";
        let upDownSign = "";
        if (result.diff > 0) {
            upDown = "up";
            upDownSign = "+";
        } else if (result.diff < 0) {
            upDown = "down";
        }
        let getChange = result.change == 0 ? '0.0' : result.change.toFixed(1);
        let change = " (" + upDownSign + getChange + "%)";
        // const change = " (" + upDownSign + result.change.toFixed(1) + "%)";
        const current = this.getUnderlyingBidPriceLastStopValue();
        return (
            <>
                {current}
                <span className={upDown}>{change}</span>
            </>
        );
    }

    onDateChange(event) {
        const newValue = { ...this.state.dateval };
        newValue.currentValue = event.target.value;
        this.setState({ dateval: newValue });
    }
    oneDateChange(event) {
        const newValue = { ...this.state.edateval };
        newValue.currentValue = event.target.value;
        this.setState({ edateval: newValue });
    }

    onDateChangeStop(event) {
        const newValue = this.state.dateval;
        newValue.currentValue = event.target.value;
        newValue.stopValue = event.target.value;
        this.setState({ dateval: newValue });
        this.recalculateMatrix(null, null, event.target.value);
    }

    oneDateChangeStop(event) {
        const newValue = this.state.edateval;
        newValue.currentValue = event.target.value;
        newValue.stopValue = event.target.value;
        this.setState({ edateval: newValue });
        this.recalculateMatrix(this.state.ecval.currentValue, this.state.eivval.currentValue, event.target.value, 3);
    }

    onDatePickerChange(date) {
        const newValue = { ...this.state.dateval };
        const oneDayMS = 24 * 60 * 60 * 1000;
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        let noDay = 0;
        const diffMS = date.getTime() - currentDate.getTime();
        if (diffMS >= 0) {
            noDay = diffMS / oneDayMS;
            if (noDay >= newValue.max) {
                noDay = newValue.max;
            }
        }
        newValue.currentValue = noDay;
        newValue.stopValue = noDay;
        this.setState({ dateval: newValue });
        this.recalculateMatrix(null, null, noDay);
    }
    oneDatePickerChange(date) {
        const newValue = { ...this.state.edateval };
        const oneDayMS = 24 * 60 * 60 * 1000;
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        let noDay = 0;
        const diffMS = date.getTime() - currentDate.getTime();
        if (diffMS >= 0) {
            noDay = diffMS / oneDayMS;
            if (noDay >= newValue.max) {
                noDay = newValue.max;
            }
        }
        newValue.currentValue = noDay;
        newValue.stopValue = noDay;
        this.setState({ edateval: newValue });
        // this.recalculateMatrix(this.state.ecval.currentValue, this.state.eivval.currentValue, this.generateDateString(noDay), 3);
        this.recalculateMatrix(this.state.ecval.currentValue, this.state.eivval.currentValue, noDay, 3);

    }

    generateDateString(noOfDay) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + noOfDay);

        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day = currentDate.getDate();
        const month = months[currentDate.getMonth()];
        const year = currentDate.getFullYear().toString().slice(2);
        const selectedDate = day + " " + month + " " + year;
        return selectedDate;
    }

    getDateMS() {
        const currentDate = new Date();
        const noOfDay = this.state.dateval.currentValue;
        currentDate.setDate(currentDate.getDate() + noOfDay);
        return currentDate;
    }
    geteDateMS() {
        const currentDate = new Date();
        const noOfDay = this.state.edateval.currentValue;
        currentDate.setDate(currentDate.getDate() + noOfDay);
        return currentDate;
    }

    getDate() {
        return this.generateDateString(this.state.dateval.currentValue);
    }
    geteDate() {
        return this.generateDateString(this.state.edateval.currentValue);
    }

    getDateLastStopValue() {
        return this.generateDateString(this.state.dateval.stopValue);
    }

    onVolatilityChange(event) {
        const newValue = this.state.ivval;
        newValue.currentValue = event.target.value;
        newValue.typing = event.target.value;
        this.setState({ ivval: newValue });
    }
    oneVolatilityChange(event) {
        const newValue = this.state.eivval;
        newValue.currentValue = event.target.value;
        newValue.typing = event.target.value;
        this.setState({ eivval: newValue });
    }

    onVolatilityInputChange(event) {
        const newValue = this.state.ivval;
        newValue.typing = event.target.value;
        this.setState({ ivval: newValue }, () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.onVolatilityKeyDown(event, true)
            }, 1000);
        });
    }
    oneVolatilityInputChange(event) {
        const newValue = this.state.eivval;
        newValue.typing = event.target.value;
        this.setState({ eivval: newValue }, () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.onVolatilityKeyDown(event, true)
            }, 1000);
        });
    }

    onVolatilityKeyDown(event, val) {
        if (event.key === "Enter" || val == true) {
            let value = Number(this.state.ivval.typing);
            if (this.state.ivval.typing.length > 0 && !isNaN(value)) {
                if (value < this.state.ivval.min || value > this.state.ivval.max) {
                    value = null;
                }
            } else {
                value = null;
            }
            if (value === null) {
                value = this.state.ivval.initial;
            }
            const event = { target: { value } };
            this.onVolatilityChangeStop(event);
        }
    }

    onVolatilityChangeStop(event) {
        const newValue = this.state.ivval;
        newValue.currentValue = event.target.value;
        newValue.stopValue = event.target.value;
        newValue.typing = event.target.value.toFixed(this.state.ivval.dp);
        this.setState({ ivval: newValue });
        this.recalculateMatrix(null, event.target.value);
    }
    oneVolatilityChangeStop(event) {
        const newValue = this.state.eivval;
        newValue.currentValue = event.target.value;
        newValue.stopValue = event.target.value;
        newValue.typing = event.target.value.toFixed(this.state.eivval.dp);
        this.setState({ eivval: newValue });
        this.recalculateMatrix(this.state.ecval.currentValue, event.target.value, this.geteDate(), 3);
    }

    getVolatility() {
        return this.state.ivval.currentValue.toFixed(this.state.ivval.dp);
    }

    getVolatilityLastStopValue() {
        return this.state.ivval.stopValue.toFixed(this.state.ivval.dp);
    }

    calculateWarrantBidPriceChange(a) {
        if (a == 1) {
            var initialWarrantPrice = MQSGUtil.getData(
                this.props.marketdata,
                // "warrant_price",
                'BID',
                null
            );
            var calculatedWarrantBidPrice = this.state.warrantBidPrice.initial;
        } else {
            var initialWarrantPrice = this.state.warrantBidPrice.initial;
            var calculatedWarrantBidPrice = this.state.warrantBidPrice.currentValue;
        }

        if (
            initialWarrantPrice !== null &&
            !isNaN(Number(calculatedWarrantBidPrice))
        ) {
            const diff = calculatedWarrantBidPrice - initialWarrantPrice;
            // const change = (diff / initialWarrantPrice) * 100.0;
            let change = Number(diff) == 0 || Number(initialWarrantPrice) == 0 ? 0 : (diff / initialWarrantPrice) * 100.0;
            return { diff, change };
        }
        return null;
    }

    getWarrantBidPrice3(a) {
        const result = this.calculateWarrantBidPriceChange(a);
        if (result != null) {
            let upDown = "";
            let upDownSign = "";
            if (result.diff > 0) {
                upDown = "up";
                upDownSign = "+";
            } else if (result.diff < 0) {
                upDown = "down";
            }
            let getChange = result.change == 0 ? '0.0' : result.change.toFixed(1);
            let change = " (" + upDownSign + getChange + "%)";
            // const change = " (" + upDownSign + result.change.toFixed(1) + "%)";
            if (a == 1) {
                var current = this.state.warrantBidPrice.initial;
            } else {
                var current = this.state.warrantBidPrice.currentValue;
            }
            return (
                <>
                    {current}
                    {a != 1 && <span className={upDown}>{change}</span>}
                </>
            );
        }
        return (
            <>
                --<span>--</span>
            </>
        );
    }

    getDelta() {
        return this.state.delta;
    }

    getEffGearing() {
        return this.state.effgearing;
    }

    getDwpChange() {
        return this.state.dwpchg;
    }

    underlyingBidPriceControl() {
        return (
            <>
                <tr className="wcalbox-row-lbl">
                    <td className="wcalbox-col-lbl">Underlying price target</td>
                    <td className="wcol-col-empty"></td>
                </tr>
                <tr className="wcalbox-row-ctr">
                    <td className="wcalbox-col-input">
                        <input
                            className="wcalbox-input underlying_bid_price"
                            type="text"
                            maxLength="10"
                            name="bdval"
                            value={this.state.bdval.typing}
                            // onKeyDown={(event) => this.onUnderlyingBidPriceKeyDown(event)}
                            // onBlur={(event) => this.onUnderlyingBidPriceKeyDown(event, true)}
                            onChange={(event) => this.onUnderlyingBidPriceInputChange(event)}
                        />
                    </td>
                    <td className="wcalbox-col-slider  hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.bdval.currentValue}
                            change={(event) => this.onUnderlyingBidPriceChange(event)}
                            slideStop={(event) => this.onUnderlyingBidPriceChangeStop(event)}
                            step={this.state.bdval.step}
                            max={this.state.bdval.max}
                            min={this.state.bdval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }

    underlyingBidPriceControlRightPanel() {
        return (
            <>
                <tr>
                    <td>Underlying price target</td>
                    <td className="wcol-col-empty"></td>
                </tr>
                <tr>
                    <td>
                        <input
                            type="text"
                            maxLength="10"
                            name="bdval"
                            style={{ textAlign: "center" }}
                            value={this.state.bdval.typing}
                            // onKeyDown={(event) => this.onUnderlyingBidPriceKeyDown(event)}
                            // onBlur={(event) => this.onUnderlyingBidPriceKeyDown(event, true)}
                            onChange={(event) => this.onUnderlyingBidPriceInputChange(event)}
                        />
                    </td>
                    <td className=" hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.bdval.currentValue}
                            change={(event) => this.onUnderlyingBidPriceChange(event)}
                            slideStop={(event) => this.onUnderlyingBidPriceChangeStop(event)}
                            step={this.state.bdval.step}
                            max={this.state.bdval.max}
                            min={this.state.bdval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }
    entryCurrentUnderlyingBidPriceControlRightPanel() {
        return (
            <>
                <tr>
                    <td>Underlying price entry</td>
                    <td className="wcol-col-empty"></td>
                </tr>
                <tr>
                    <td>
                        <input
                            type="text"
                            maxLength="10"
                            name="bdval"
                            style={{ textAlign: "center" }}
                            value={this.state.ecval.typing}
                            // onKeyDown={(event) => this.onUnderlyingBidPriceKeyDown(event)}
                            // onBlur={(event) => this.onUnderlyingBidPriceKeyDown(event, true)}
                            onChange={(event) => this.onECUnderlyingBidPriceInputChange(event)}
                        />
                    </td>
                    <td className="hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.ecval.currentValue}
                            change={(event) => this.onECUnderlyingBidPriceChange(event)}
                            slideStop={(event) => this.onECUnderlyingBidPriceChangeStop(event)}
                            step={this.state.ecval.step}
                            max={this.state.ecval.max}
                            min={this.state.ecval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }
    entryCurrentUnderlyingBidPriceControl() {
        return (
            <>
                <tr className="wcalbox-row-lbl">
                    <td className="wcalbox-col-lbl">Underlying price entry</td>
                    <td className="wcol-col-empty "></td>
                </tr>
                <tr className="wcalbox-row-ctr">
                    <td className="wcalbox-col-input">
                        <input
                            className="wcalbox-input underlying_bid_price"
                            type="text"
                            maxLength="10"
                            name="bdval"
                            value={this.state.ecval.typing}
                            // onKeyDown={(event) => this.onUnderlyingBidPriceKeyDown(event)}
                            // onBlur={(event) => this.onUnderlyingBidPriceKeyDown(event, true)}
                            onChange={(event) => this.onECUnderlyingBidPriceInputChange(event)}
                        />
                    </td>
                    <td className="wcalbox-col-slider hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.ecval.currentValue}
                            change={(event) => this.onECUnderlyingBidPriceChange(event)}
                            slideStop={(event) => this.onECUnderlyingBidPriceChangeStop(event)}
                            step={this.state.ecval.step}
                            max={this.state.ecval.max}
                            min={this.state.ecval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }
    getbgDate(date) {
        var m = new Date().getMonth() + 1;
        var M = new Date(date).getMonth() + 1;
        var cls = m != M ? "random" : undefined;
        return cls
    }

    dateControl() {
        return (
            <>
                <tr className="wcalbox-row-lbl">
                    <td className="wcalbox-col-lbl">Date</td>
                    <td></td>
                </tr>
                <tr className="wcalbox-row-ctr   abcd">
                    <td className="wcalbox-col-input" style={{ position: "relative" }}>
                        {/* <input className="wcalbox-input today_date" type="text" name="dateval" 
                              value={this.getDate()} readOnly/> */}
                        <DatePicker
                            // selected={this.state.startDate}
                            selected={this.getDateMS()}
                            dateFormat="dd MMM yy"
                            onChange={(date) => this.onDatePickerChange(date)}
                            minDate={new Date()}
                            dayClassName={(date) =>
                                this.getbgDate(date)
                                // getDate(date) < Math.random() * 31 ? "random" : undefined
                            }
                        />
                    </td>
                    <td className="wcalbox-col-slider  hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.dateval.currentValue}
                            change={this.onDateChange.bind(this)}
                            slideStop={this.onDateChangeStop.bind(this)}
                            step={this.state.dateval.step}
                            max={this.state.dateval.max}
                            min={this.state.dateval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }

    edateControl() {
        return (
            <>
                <tr className="wcalbox-row-lbl">
                    <td className="wcalbox-col-lbl">Date</td>
                    <td></td>
                </tr>
                <tr className="wcalbox-row-ctr   abcd">
                    <td className="wcalbox-col-input" style={{ position: "relative" }}>
                        {/* <input className="wcalbox-input today_date" type="text" name="dateval" 
                              value={this.getDate()} readOnly/> */}
                        <DatePicker
                            // selected={this.state.startDate}
                            selected={this.geteDateMS()}
                            dateFormat="dd MMM yy"
                            onChange={(date) => this.oneDatePickerChange(date)}
                            minDate={new Date()}
                            dayClassName={(date) =>
                                this.getbgDate(date)
                                // getDate(date) < Math.random() * 31 ? "random" : undefined
                            }
                        />
                    </td>
                    <td className="wcalbox-col-slider  hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.edateval.currentValue}
                            change={this.oneDateChange.bind(this)}
                            slideStop={this.oneDateChangeStop.bind(this)}
                            step={this.state.edateval.step}
                            max={this.state.edateval.max}
                            min={this.state.edateval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }
    dateControlRightPanel() {
        return (
            <>
                <tr>
                    <td>Date</td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <DatePicker
                            selected={this.getDateMS()}
                            dateFormat="dd MMM yy"
                            onChange={(date) => this.onDatePickerChange(date)}
                        />
                    </td>
                    <td className="wcalbox-col-slider hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.dateval.currentValue}
                            change={this.onDateChange.bind(this)}
                            slideStop={this.onDateChangeStop.bind(this)}
                            step={this.state.dateval.step}
                            max={this.state.dateval.max}
                            min={this.state.dateval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }

    dateeControlRightPanel() {
        return (
            <>
                <tr>
                    <td>Date</td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <DatePicker
                            selected={this.geteDateMS()}
                            dateFormat="dd MMM yy"
                            onChange={(date) => this.oneDatePickerChange(date)}
                        />
                    </td>
                    <td className="wcalbox-col-slider hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.edateval.currentValue}
                            change={this.oneDateChange.bind(this)}
                            slideStop={this.oneDateChangeStop.bind(this)}
                            step={this.state.edateval.step}
                            max={this.state.edateval.max}
                            min={this.state.edateval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }

    volatilityControl() {
        return (
            <>
                <tr className="wcalbox-row-lbl iv-slider-top">
                    <td className="wcalbox-col-lbl">Volatility (%)</td>
                    <td></td>
                </tr>
                <tr className="wcalbox-row-ctr iv-slider-bottom">
                    <td className="wcalbox-col-input">
                        <input
                            className="wcalbox-input implied_volatility"
                            type="text"
                            name="ivval"
                            maxLength="6"
                            value={this.state.ivval.typing}
                            // onKeyDown={this.onVolatilityKeyDown.bind(this)}
                            // onBlur={(e) => { this.onVolatilityKeyDown(e, true) }}
                            onChange={this.onVolatilityInputChange.bind(this)}
                        />
                    </td>
                    <td className="wcalbox-col-slider  hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.ivval.currentValue}
                            change={this.onVolatilityChange.bind(this)}
                            slideStop={this.onVolatilityChangeStop.bind(this)}
                            step={this.state.ivval.step}
                            max={this.state.ivval.max}
                            min={this.state.ivval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }

    evolatilityControl() {
        return (
            <>
                <tr className="wcalbox-row-lbl iv-slider-top">
                    <td className="wcalbox-col-lbl">Volatility (%)</td>
                    <td></td>
                </tr>
                <tr className="wcalbox-row-ctr iv-slider-bottom">
                    <td className="wcalbox-col-input">
                        <input
                            className="wcalbox-input implied_volatility"
                            type="text"
                            name="ivval"
                            maxLength="6"
                            value={this.state.eivval.typing}
                            // onKeyDown={this.onVolatilityKeyDown.bind(this)}
                            // onBlur={(e) => { this.onVolatilityKeyDown(e, true) }}
                            onChange={this.oneVolatilityInputChange.bind(this)}
                        />
                    </td>
                    <td className="wcalbox-col-slider  hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.eivval.currentValue}
                            change={this.oneVolatilityChange.bind(this)}
                            slideStop={this.oneVolatilityChangeStop.bind(this)}
                            step={this.state.eivval.step}
                            max={this.state.eivval.max}
                            min={this.state.eivval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }
    volatilityControlRightPanel() {
        return (
            <>
                <tr>
                    <td>Volatility (%)</td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <input
                            type="text"
                            maxLength="10"
                            name="bdval"
                            style={{ textAlign: "center" }}
                            value={this.state.ivval.typing}
                            // onKeyDown={this.onVolatilityKeyDown.bind(this)}
                            // onBlur={(e) => { this.onVolatilityKeyDown(e, true) }}
                            onChange={this.onVolatilityInputChange.bind(this)}
                        />
                    </td>
                    <td className=" hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.ivval.currentValue}
                            change={this.onVolatilityChange.bind(this)}
                            slideStop={this.onVolatilityChangeStop.bind(this)}
                            step={this.state.ivval.step}
                            max={this.state.ivval.max}
                            min={this.state.ivval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }

    volatilityeControlRightPanel() {
        return (
            <>
                <tr>
                    <td>Volatility (%)</td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <input
                            type="text"
                            maxLength="10"
                            name="bdval"
                            style={{ textAlign: "center" }}
                            value={this.state.eivval.typing}
                            // onKeyDown={this.onVolatilityKeyDown.bind(this)}
                            // onBlur={(e) => { this.onVolatilityKeyDown(e, true) }}
                            onChange={this.oneVolatilityInputChange.bind(this)}
                        />
                    </td>
                    <td className=" hidden-xs hidden-sm">
                        <ReactBootstrapSlider
                            value={this.state.eivval.currentValue}
                            change={this.oneVolatilityChange.bind(this)}
                            slideStop={this.oneVolatilityChangeStop.bind(this)}
                            step={this.state.eivval.step}
                            max={this.state.eivval.max}
                            min={this.state.eivval.min}
                            reversed={false}
                        />
                    </td>
                </tr>
            </>
        );
    }

    xssmInput() {
        return (
            <div className="row">
                <div className="wcalbox-row col-xs-12 col-sm-4">
                    <div className="wcalbox-col">Underlying bid price</div>
                    <div className="wcalbox-col">
                        <input
                            className="wcalbox-input underlying_bid_price"
                            type="text"
                            name="bdval"
                            maxLength="10"
                        />
                    </div>
                </div>
                <div className="wcalbox-row col-xs-12 col-sm-4">
                    <div className="wcalbox-col">Date</div>
                    <div className="wcalbox-col">
                        <div id="datepicker-xs">
                            <input
                                className="wcalbox-input today_date"
                                type="text"
                                name="dateval"
                            />
                        </div>
                    </div>
                </div>
                <div className="wcalbox-row col-xs-12 col-sm-4 iv-slider-full">
                    <div className="wcalbox-col">Volatility (%)</div>
                    <div className="wcalbox-col">
                        <input
                            className="wcalbox-input implied_volatility"
                            type="text"
                            name="ivval"
                            maxLength="6"
                        />
                    </div>
                </div>
            </div>
        );
    }

    simulatedResultsUpperTable(i) {
        var value = i === 1 ? "Estimated Warrant bid price" : "Warrant bid price";
        var style = {};
        var style1 = {};
        if (i === 1) {
            style = {
                borderBottom: "1px solid #FECC0A",
                backgroundColor: "#fff",
                textAlign: "left",
            };
            style1 = {
                backgroundColor: "#F5F6F7",
            };
        } else {
            style = {
                backgroundColor: "#F5F6F7",
            };
            style1 = {
                backgroundColor: "#fff",
            };
        }
        return (
            <div className="row" style={{ overflowX: 'auto' }}>
                <div className="col-md-6 col-xs-6 col-sm-6">
                    Warrant Entry Price
                    <table id="ivtable" className="table table-striped-inverse">
                        <thead>
                            <tr className="row_0">
                                <th style={style}>{value}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="wprice_3 active" style={style1}>
                                    {this.getWarrantBidPrice3(1)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6 col-xs-6 col-sm-6" style={{ borderLeft: '1px solid #000' }}>
                    Warrant Exit Price
                    <table id="ivtable" className="table table-striped-inverse">
                        <thead>
                            <tr className="row_0">
                                <th style={style}>Underlying bid price</th>
                                <th style={style}>{value}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="uprice_3 active" style={style1}>
                                    {this.getUnderlyingBidPrice3()}
                                </td>
                                <td className="wprice_3 active" style={style1}>
                                    {this.getWarrantBidPrice3()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            // <table id="ivtable" className="table table-striped-inverse">
            //   <thead>
            //     <tr className="row_0">
            //       <th style={style}>Underlying bid price</th>
            //       <th style={style}>{value}</th>
            //     </tr>
            //   </thead>
            //   <tbody>
            //     <tr>
            //       <td className="uprice_3 active" style={style1}>
            //         {this.getUnderlyingBidPrice3()}
            //       </td>
            //       <td className="wprice_3 active" style={style1}>
            //         {this.getWarrantBidPrice3()}
            //       </td>
            //     </tr>
            //   </tbody>
            // </table>
        );
    }

    simulatedResultsLowerTable() {
        const marketdata = this.props.marketdata;
        const exerciseprice = MQSGUtil.getData(marketdata, "exercise_price");
        const currency = MQSGUtil.getData(marketdata, "currency");
        const underlyingcurr = MQSGUtil.getData(marketdata, "underlying_curr");
        const lasttradingdate = MQSGUtil.getData(marketdata, "last_trading_date");

        return (
            <div id="srtable" style={{ overflowX: 'auto' }}>
                <table className="table">
                    <tbody>
                        <tr>
                            <th className="srtable-leftlbl bgcolor-01">
                                Warrant bid price (<span className="currency">{currency}</span>)
                            </th>
                            <td className="srtable-leftval bgcolor-01 BID">
                                {this.state.warrantBidPrice.currentValue}
                            </td>
                            <th className="srtable-rightlbl bgcolor-02">
                                Exercise price (
                                <span className="underlying_curr">{underlyingcurr}</span>)
                            </th>
                            <td className="srtable-rightval bgcolor-02 exercise_price">
                                {exerciseprice}
                            </td>
                        </tr>
                        <tr>
                            <th className="srtable-leftlbl">Underlying change (%)</th>
                            <td className="srtable-leftval upchg">
                                {this.getUnderlyingBidPriceChangePercentage()}
                            </td>
                            <th className="srtable-rightlbl">Delta (%)</th>
                            <td className="sttable-rightlbl delta">{this.getDelta()}</td>
                        </tr>
                        <tr>
                            <th className="srtable-leftlbl bgcolor-01">
                                Underlying price (
                                <span className="underlying_curr">{underlyingcurr}</span>)
                            </th>
                            <td className="srtable-leftval bgcolor-01 underlying_price">
                                {this.getUnderlyingBidPriceLastStopValue()}
                            </td>
                            <th className="srtable-rightlbl bgcolor-02">
                                Effective gearing (X)
                            </th>
                            <td className="srtable-rightval bgcolor-02 effective_gearing">
                                {this.getEffGearing()}
                            </td>
                        </tr>
                        <tr style={{ display: "none" }}>
                            <th className="srtable-leftlbl">Change (%)</th>
                            <td className="srtable-leftval dwpchg">{this.getDwpChange()}</td>
                            <th className="srtable-rightlbl">Expiry</th>
                            <td className="srtable-rightval last_trading_date">
                                {lasttradingdate}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderAtRightPanel() {
        const marketdata = this.props.marketdata;
        const ticker = MQSGUtil.getData(marketdata, "ticker");
        return (
            <div
                className="section qlink-section"
                id="minidwcalc"
                style={{ position: "relative", padding: '0px' }}
            >
                <div className="page-header">
                    {/* <h2 className="small">{"Warrant Calculator (" + ticker + ")"}</h2> */}
                    <h2 className="small">{"Warrant Calculator"}</h2>
                </div>
                <p>Move the sliders to estimate the bid price of the warrant.</p>
                {/* <div>
            <table className="hidden-xs hidden-sm">
              <tbody>
                {this.entryCurrentUnderlyingBidPriceControlRightPanel()}
                {this.underlyingBidPriceControlRightPanel()}
                {this.dateControlRightPanel()}
                {this.volatilityControlRightPanel()}
              </tbody>
            </table>
          </div> */}
                <div className="row" id="wcalbox_right_tb">
                    <div className="col-md-6  col-xs-6 col-sm-6" style={{}}>
                        <div style={{ fontSize: '20px', fontWeight: '500' }}>Target entry variables</div>
                        <table className="wcalbox_table">
                            <tbody>
                                {this.entryCurrentUnderlyingBidPriceControlRightPanel()}
                                {this.dateeControlRightPanel()}
                                {this.volatilityeControlRightPanel()}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6  col-xs-6 col-sm-6" style={{ borderLeft: '1px solid #000' }}>
                        <div style={{ fontSize: '20px', fontWeight: '500' }}>Target exit variables</div>
                        <table className="wcalbox_table">
                            <tbody>
                                {this.underlyingBidPriceControlRightPanel()}
                                {this.dateControlRightPanel()}
                                {this.volatilityControlRightPanel()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <h3
                    className="bold_title"
                    style={{ fontFamily: "inherit", fontWeight: 500 }}
                >
                    Results
                </h3>
                {this.simulatedResultsUpperTable(1)}
                <div className="section-btn-wapper" style={{ textAlign: "left" }}>
                    <a
                        href="/tools/warrantcalculator"
                        className="btn btn-primary"
                        style={{ minWidth: "190px", whiteSpace: 'pre-wrap' }}
                        type="button"
                    >
                        Visit the Warrant calculator for more info!
                    </a>
                </div>
            </div>
        );
    }

    render() {
        // if (this.isRightPanel()) {
        //   return this.renderAtRightPanel();
        // }
        return (
            <div>
                <div id="wcalbox">
                    {/* <div className="container-fluid"> */}
                    <div className="row">
                        <div className="col-md-6  col-xs-6 col-sm-6">
                            <div style={{ fontSize: '20px', fontWeight: '500' }}>Target entry variables</div>
                            <table className="wcalbox_table">
                                <tbody>
                                    {this.entryCurrentUnderlyingBidPriceControl()}
                                    {this.edateControl()}
                                    {this.evolatilityControl()}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6  col-xs-6 col-sm-6" style={{ borderLeft: '1px solid #000' }}>
                            <div style={{ fontSize: '20px', fontWeight: '500' }}>Target exit variables</div>
                            <table className="wcalbox_table">
                                <tbody>
                                    {this.underlyingBidPriceControl()}
                                    {this.dateControl()}
                                    {this.volatilityControl()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <table className="">
              <tbody>
                {this.entryCurrentUnderlyingBidPriceControl()}
                {this.underlyingBidPriceControl()}
                {this.dateControl()}
                {this.volatilityControl()}
              </tbody>
            </table> */}

                    {/* <div className="visible-xs visible-sm">{this.xssmInput()}</div> */}
                    <p style={{ margin: "10px 0" }}>
                        Note that the warrant’s&nbsp;
                        <a href="/InvestorAcademy/impliedvolatility" target="_blank" style={{ color: '#0675C2' }}>
                            volatility{" "}
                        </a>
                        is affected by market related events and/or demand for the warrant.
                    </p>
                    <div className="resetbtn">
                        {/* javascript:location.reload() */}
                        {/* <a href="#" type="button" className="btn btn-primary">Reset</a> */}
                        <input
                            className="btn btn-primary"
                            value="Reset"
                            type="button"
                            style={{ minWidth: "100px" }}
                            onClick={this.onReset.bind(this)}
                        />
                    </div>
                </div>
                <h3 className="bold_title">Simulated results</h3>
                {this.simulatedResultsUpperTable()}
                {this.simulatedResultsLowerTable()}
                <p className="small">The price above is only indicative.</p>
                <hr className="hr-hmgbm" />
            </div>
        );
    }
}

class WrCalculatorLogic {
    constructor(marketdata) {
        this.marketdata = marketdata;
        this.uric = MQSGUtil.getData(this.marketdata, "underlying_ric");
        this.uname = MQSGUtil.getData(this.marketdata, "underlying_name");
        this.ivMatrix = null;
        this.ivMatrixDisp = null;
        this.unpricenum = 6; //n + 1 to ease calculation
        this.ivnum = 6; //n + 1 to ease calculatio
        this.fxRate = 1;
        this.upmax = null;
        this.upmin = null;
        this.unmax = null;
        this.unmin = null;
        this.unstep = 1;
        this.udp = null;
        this.wdp = null;
        this.riskFreeRate = 0.015;
        this.unPriceProps = { spread: 0.01, dp: 2 };
        this.setUnpricePropertiesByUname();
        this.setupDecimalPlace();
        this.setupMaxMinStep();
        this.setupFxRate();
    }

    setUnpricePropertiesByUname() {
        switch (this.uname) {
            case "N225":
                this.unPriceProps.spread = 5;
                this.unPriceProps.dp = 0;
                break;
            case "HSI":
            case "STI":
                this.unPriceProps.spread = 1;
                this.unPriceProps.dp = 0;
                break;
            case "SiMSCI":
                this.unPriceProps.spread = 0.05;
                this.unPriceProps.dp = 2;
                break;
            case "S&P500":
                this.unPriceProps.spread = 0.25;
                this.unPriceProps.dp = 2;
                break;
            default:
                break;
        }
    }

    setupDecimalPlace() {
        const underlyingPrice = MQSGUtil.getNumber(
            this.marketdata,
            "underlying_price",
            null
        );
        const bid = MQSGUtil.getNumber(this.marketdata, "BID", null);
        this.udp =
            underlyingPrice !== null ? MQSGUtil.getDecimalPlace(underlyingPrice) : 0;
        this.wdp = bid !== null ? MQSGUtil.getDecimalPlace(bid) : 0;
    }

    setupMaxMinStep() {
        let upmax = null;
        let upmin = null;
        const underlyingRic = MQSGUtil.getData(this.marketdata, "underlying_ric");
        const underlyingPrice = MQSGUtil.getNumber(
            this.marketdata,
            "underlying_price",
            null
        );
        if (underlyingPrice !== null) {
            if (this.isIndexUnderlying(underlyingRic)) {
                upmax = Number((underlyingPrice * 1.35).toFixed());
                upmin = Math.max(0, Number((underlyingPrice * 0.65).toFixed()));
            } else {
                upmax = 3 * underlyingPrice;
                upmin = 0;
            }
        }
        this.unmax = upmax;
        this.unmin = upmin;
        if (this.uname === "HSI" || this.uname === "STI" || this.uname === "N225") {
            if (upmax !== null) {
                this.upmax = upmax.toFixed(0);
            }
            if (upmin !== null) {
                this.upmin = upmin.toFixed(0);
            }
        } else {
            // this.upmax = upmax.toFixed(2);
            // this.upmin = upmin.toFixed(2);
            if (upmax !== null) {
                this.upmax = upmax.toFixed(2);
            }
            if (upmin !== null) {
                this.upmin = upmin.toFixed(2);
            }
        }
        if (this.uname === "HSI" || this.uname === "STI" || this.uname === "N225") {
            this.unstep = 1;
            if (this.uname === "N225") {
                this.unstep = 5;
            }
        } else {
            this.unstep = 0.01;
            if (this.uname === "SiMSCI") this.unstep = 0.05;
            else if (this.uname === "S&P500") this.unstep = 0.25;
        }
        if (this.upmax !== null) {
            this.upmax = Number(this.upmax);
        }
        if (this.upmin !== null) {
            this.upmin = Number(this.upmin);
        }
    }

    setupFxRate() {
        const rHstClose = MQSGUtil.getData(this.marketdata, "r_hst_close", null);
        if (rHstClose !== null) {
            this.fxRate = rHstClose;
        }
    }

    isIndexUnderlying(underlyingRic) {
        const indexUnderlyingTokens = [".STI", "HSIc", "SSIcv", "ESc"];
        let isIndexUnderlying = false;
        if (underlyingRic) {
            indexUnderlyingTokens.forEach((token) => {
                if (underlyingRic.startsWith(token)) {
                    isIndexUnderlying = true;
                }
            });
        }
        return isIndexUnderlying;
    }

    getDecimalPlace(value) {
        const decimalIndex = value.indexOf(".");
        if (decimalIndex > -1) {
            return value.length - (decimalIndex + 1);
        }
        return 0;
    }

    // caldateDiffInDays(a, b){
    //     var _MS_PER_DAY = 1000 * 60 * 60 * 24;
    //     var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    //     var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    //     return Math.floor(((utc2 - utc1)) / _MS_PER_DAY);
    // }

    caldateDiffInDays(numberOfSecondA, numberOfSecondB) {
        if (!isNaN(numberOfSecondA) && !isNaN(numberOfSecondB)) {
            const MS_PER_DAY = 1000 * 60 * 60 * 24;
            return Math.floor((numberOfSecondB - numberOfSecondA) / MS_PER_DAY);
        }
        return 0; // should not come to here
    }

    sign(x) {
        return x ? (x < 0 ? -1 : 1) : 0;
    }

    calCND(x) {
        var a1 = 0.254829592;
        var a2 = 0.284496736;
        var a3 = 1.421413741;
        var a4 = 1.453152027;
        var a5 = 1.061405429;
        var p = 0.3275911;
        var t = 1 / (1 + (p * Math.abs(x)) / Math.sqrt(2));
        var value =
            (1 / 2) *
            (1 +
                this.sign(x) *
                (1 -
                    (a1 * t -
                        a2 * Math.pow(t, 2) +
                        a3 * Math.pow(t, 3) -
                        a4 * Math.pow(t, 4) +
                        a5 * Math.pow(t, 5)) *
                    Math.exp((-x * x) / 2)));
        return value;
    }

    calDWPriceWithDividen(
        unprice,
        exercisePrice,
        riskFreeRate,
        sigma,
        TDays,
        warrantsPerShare,
        type,
        divYield
    ) {
        if (
            unprice !== null &&
            exercisePrice !== null &&
            warrantsPerShare !== null &&
            type !== null &&
            divYield !== null
        ) {
            const S = Number(unprice).toPrecision(10); //underlying price
            const X = Number(exercisePrice).toPrecision(10); //exercise price
            let c = 0;
            const T = TDays / 365;
            const F = S * Math.exp((riskFreeRate - divYield / 100) * T);

            let d1 =
                (Math.log(F / X) + ((sigma * sigma) / 2) * T) / (sigma * Math.sqrt(T));
            d1 = Number(d1).toPrecision(10);
            let d2 = d1 - sigma * Math.sqrt(T);
            d2 = Number(d2).toPrecision(10);

            if (type === "CALL") {
                c =
                    Math.exp(-riskFreeRate * T) *
                    (F * this.calCND(d1) - X * this.calCND(d2));
            } else if (type === "PUT") {
                c =
                    Math.exp(-riskFreeRate * T) *
                    (X * this.calCND(-d2) - F * this.calCND(-d1));
            }
            c = Number(c).toPrecision(10);
            return c / warrantsPerShare;
        }
        return 0; // should not come to here
    }

    calDelta(unprice, exercisePrice, riskFreeRate, sigma, TDays, type, divYield) {
        // var divYield = parseFloat(this.marketdata.div_yield/100);
        const S = unprice;
        const X = exercisePrice;
        const r = riskFreeRate;
        const T = TDays / 365;
        const F = S * Math.exp((r - divYield) * T);
        const d1 =
            (Math.log(F / X) + ((sigma * sigma) / 2) * T) / (sigma * Math.sqrt(T));
        let result = 0;
        if (type === "CALL") {
            result = this.calCND(d1) * Math.exp(-divYield * T);
        } else if (type === "PUT") {
            result = -1 * this.calCND(-d1) * Math.exp(-divYield * T);
        }
        return result;
    }

    calEffGearing(unprice, dwprice, wps, delta) {
        // const S = unprice;
        // const wp = dwprice;
        // return Math.abs(delta) * (S / (wp / wps));
        let S = Number(unprice);
        let wp = Number(dwprice);
        let getWP = wp == 0 || wps == 0 ? 0 : wp / wps;
        let getSwp = S == 0 || getWP == 0 ? 0 : S / getWP;
        return Math.abs(delta) * getSwp;
    }

    initMatrix(underlyingBidPrice, impliedVolatility, selectedDate) {
        this.initIVMatrix(underlyingBidPrice, impliedVolatility);
        this.calIVMatrix(underlyingBidPrice, selectedDate);
    }

    initIVMatrix(underlyingBidPrice, impliedVolatility) {
        const initIV =
            impliedVolatility === "N/A" ||
                impliedVolatility === "" ||
                impliedVolatility === "-" ||
                impliedVolatility === null
                ? 0
                : Number(impliedVolatility);
        const initUP = underlyingBidPrice * this.fxRate;

        if (this.ivMatrix === null) {
            this.ivMatrix = [];
            for (var i = 0; i < this.unpricenum; i++) {
                this.ivMatrix[i] = [];
                for (var j = 0; j < this.ivnum; j++) {
                    this.ivMatrix[i][j] = "";
                }
            }
        }

        for (let i = 1; i < this.ivnum; i++) {
            let iv = (i - this.ivnum / 2) * 1 + initIV;
            if (iv < 0) iv = 0;
            // var output = new NumberFormat();
            // output.setNumber(iv);
            // output.setPlaces(1, false);
            // this.ivMatrix[0][i] = output.toFormatted();
            this.ivMatrix[0][i] = iv.toFixed(1);
        }
        for (let i = 1; i < this.unpricenum; i++) {
            var p = initUP + (-this.unpricenum / 2 + i) * this.unPriceProps.spread;
            // var output = new NumberFormat();
            // output.setNumber(p);
            // output.setPlaces(that.unPriceProps.dp, false);
            // that.ivMatrix[i][0] = output.toFormatted();
            this.ivMatrix[i][0] = p.toFixed(this.unPriceProps.dp);
        }
        this.initIVMatrixDisp(underlyingBidPrice, impliedVolatility);
    }

    initIVMatrixDisp(underlyingBidPrice, impliedVolatility) {
        const initIV =
            impliedVolatility === "N/A" ||
                impliedVolatility === "" ||
                impliedVolatility === "-" ||
                impliedVolatility === null
                ? 0
                : Number(impliedVolatility);
        const initUP = underlyingBidPrice;

        if (this.ivMatrixDisp === null) {
            this.ivMatrixDisp = [];
            for (let i = 0; i < this.unpricenum; i++) {
                this.ivMatrixDisp[i] = [];
                for (let j = 0; j < this.ivnum; j++) {
                    this.ivMatrixDisp[i][j] = "";
                }
            }
        }

        for (let i = 1; i < this.ivnum; i++) {
            let iv = (i - this.ivnum / 2) * 1 + initIV;
            if (iv < 0) iv = 0;
            // var output = new NumberFormat();
            // output.setNumber(iv);
            // output.setPlaces(1, false);
            // that.ivMatrixDisp[0][i] = output.toFormatted();
            this.ivMatrixDisp[0][i] = iv.toFixed(1);
        }
        for (var i = 1; i < this.unpricenum; i++) {
            var p = initUP + (-this.unpricenum / 2 + i) * this.unPriceProps.spread;
            // var output = new NumberFormat();
            // output.setNumber(p);
            // output.setPlaces(that.unPriceProps.dp, false);
            // that.ivMatrixDisp[i][0] = output.toFormatted();
            this.ivMatrixDisp[i][0] = p.toFixed(this.unPriceProps.dp);
        }
    }

    calIVMatrix(underlyingBidPrice, selectedDate) {
        let unprice = underlyingBidPrice * this.fxRate;
        let exercisePrice = MQSGUtil.getNumber(
            this.marketdata,
            "exercise_price_cal",
            null
        );
        if (exercisePrice !== null) {
            exercisePrice = exercisePrice * this.fxRate;
        }
        const divYield = MQSGUtil.getNumber(this.marketdata, "div_yield", null);
        const type = MQSGUtil.getData(this.marketdata, "type", null);
        const maturity = Date.parse(MQSGUtil.getData(this.marketdata, "maturity"));
        const tddate = Date.parse(selectedDate); // selectedDate is date string from user input
        const TDays = this.caldateDiffInDays(tddate, maturity);
        const warrantsPerShare = MQSGUtil.getNumber(
            this.marketdata,
            "conv_ratio",
            null
        );
        for (let i = 1; i < this.unpricenum; i++) {
            for (let j = 1; j < this.ivnum; j++) {
                unprice = Number(this.ivMatrix[i][0].replace(",", ""));
                if (TDays < 0) {
                    this.ivMatrix[i][j] = "-";
                } else {
                    const sigma = Number(this.ivMatrix[0][j]) / 100;
                    const result = this.calDWPriceWithDividen(
                        unprice,
                        exercisePrice,
                        this.riskFreeRate,
                        sigma,
                        TDays,
                        warrantsPerShare,
                        type,
                        divYield
                    );
                    this.ivMatrix[i][j] = result.toFixed(3);
                }
            }
        }
    }

    getWarrantBidPrice(matrixIndex = 3) {
        return this.ivMatrix[matrixIndex][this.ivnum / 2];
    }

    getDeltaAndEffGearingAndDwpChange(underlyingBidPrice, selectedDate) {
        const uprice = underlyingBidPrice;
        const dwprice = this.ivMatrix[this.unpricenum / 2][this.ivnum / 2];
        const exercisePrice = MQSGUtil.getData(
            this.marketdata,
            "exercise_price_cal"
        );
        const type = MQSGUtil.getData(this.marketdata, "type");
        let divYield = MQSGUtil.getNumber(this.marketdata, "div_yield");
        if (divYield !== null) {
            divYield = divYield / 100;
        }
        const maturity = Date.parse(MQSGUtil.getData(this.marketdata, "maturity"));
        const tddate = Date.parse(selectedDate); // selectedDate is date string from user input
        const TDays = this.caldateDiffInDays(tddate, maturity);
        const conv_ratio = MQSGUtil.getNumber(
            this.marketdata,
            "conv_ratio_cal",
            null
        );
        const bidFromMD = MQSGUtil.getNumber(this.marketdata, "BID");
        const realDWprice = bidFromMD !== null ? bidFromMD * this.fxRate : 0;
        const dwpchg =
            (100 *
                ((!isNaN(dwprice) ? Number(dwprice).toFixed(2) : 0) - realDWprice)) /
            realDWprice;
        const result = { delta: null, effgearing: null, dwpchg: dwpchg };

        if (TDays >= 0 && conv_ratio !== null && conv_ratio !== 0) {
            const sigma = this.ivMatrix[0][this.ivnum / 2] / 100;
            const delta = this.calDelta(
                uprice * this.fxRate,
                exercisePrice * this.fxRate,
                this.riskFreeRate,
                sigma,
                TDays,
                type,
                divYield
            );
            const wps = 1 / conv_ratio;
            const effgearing = this.calEffGearing(
                uprice * this.fxRate,
                dwprice,
                wps,
                delta
            );
            // output.setNumber(delta * 100);
            // output.setPlaces(1, false);
            // that.$srtable.find(".delta").setValue(output.toFormatted());
            // output.setNumber(effgearing);
            // that.$srtable.find(".effective_gearing").setValue(output.toFormatted());
            result.delta = (delta * 100).toFixed(1);
            result.effgearing = effgearing.toFixed(1);
        }
        return result;
    }
}
class Chart extends Component {
    getData(data) {
        var chartData = {
            data1: [],
            data2: [],
            data1Axis: {
                min: null,
                max: null,
            },
            data2Axis: {
                min: null,
                max: null,
            },
        };
        if (data) {
            var arr1 = [];
            var arr2 = [];
            for (var i = 0; i < data.data.length; i++) {
                // chartData.data1.push({
                //   x: new Date(data.data[i]["time"]).getTime(),
                //   y: Number(data.data[i]["close"]),
                // });
                chartData.data1.push([
                    this.props.period === "D"
                        ? new Date(data.data[i]["time"].replace(' 00:00', '')).getTime() + 28800000
                        : new Date(data.data[i]["time"].replace(' 00:00', '')).getTime(),
                    Number(data.data[i]["close"]),
                ]);
                arr1.push(Number(data.data[i]["close"]));
            }
            for (var j = 0; j < data.data1.length; j++) {
                // chartData.data2.push({
                //   x: new Date(data.data1[j]["time"]).getTime(),
                //   y: Number(data.data1[j]["close"]),
                // });
                chartData.data2.push([
                    this.props.period === "D"
                        ? new Date(data.data1[j]["time"].replace(' 00:00', '')).getTime() + 28800000
                        : new Date(data.data1[j]["time"].replace(' 00:00', '')).getTime(),
                    Number(data.data1[j]["close"]),
                ]);
                arr2.push(Number(data.data1[j]["close"]));
            }
            chartData.data1Axis.min = Math.min.apply(Math, arr1);
            chartData.data1Axis.max = Math.max.apply(Math, arr1);
            chartData.data2Axis.min = Math.min.apply(Math, arr2);
            chartData.data2Axis.max = Math.max.apply(Math, arr2);
            return chartData;
        } else {
            return chartData;
        }
    }
    floatMultiply(arg1, arg2) {
        if (arg1 == null || arg2 == null) {
            return null;
        }
        var n1, n2;
        var r1, r2; // 小数位数
        try {
            r1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            r2 = 0;
        }
        n1 = Number(arg1.toString().replace(".", ""));
        n2 = Number(arg2.toString().replace(".", ""));
        return n1 * n2 / Math.pow(10, r1 + r2);
    }
    componentDidMount() {
        this.chartRef = React.createRef();
    }
    render() {
        var chartdata = this.getData(this.props.data);
        var that = this;
        const chartOption = {
            lang: {
                noData: "no-data-to-display",
            },
            chart: {
                type: "area",
                // zoomType: "x",
                // events: {
                //   selection: function (event) {
                //     // var std=event.xAxis[0].min+','+event.xAxis[0].max
                //     // var pgpd3=event.yAxis[0].min+','+event.yAxis[0].max
                //     // this.$emit("sendData", std,pgpd3);
                //     if (event.xAxis == undefined) {
                //       this.title.update({ text: '' });
                //       return;
                //     }
                //     var min = null;
                //     var max = null;
                //     var min1 = null;
                //     var max2 = null;
                //     var mum1 = event.xAxis[0].min + "";
                //     var mum2 = event.xAxis[0].max + "";
                //     if (mum1.indexOf('.') != -1) {
                //       mum1 = mum1.slice(0, mum1.indexOf('.'))
                //     }
                //     if (mum2.indexOf('.') != -1) {
                //       mum2 = mum2.slice(0, mum2.indexOf('.'))
                //     }
                //     mum1 = Number(mum1)
                //     mum2 = Number(mum2)
                //     // for (var i = 0; i < chartdata.data1.length; i++) {
                //     //   if (new Date(chartdata.data1[i][0])
                //     //   ) {
                //     //     min = chartdata.data1[i][1]
                //     //   }
                //     if (that.props.period === "D") {
                //       for (var i = 0; i < chartdata.data1.length; i++) {
                //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
                //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
                //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum1).getDate() &&
                //           new Date(chartdata.data1[i][0]).getHours() == new Date(mum1).getHours() &&
                //           new Date(chartdata.data1[i][0]).getMinutes() == new Date(mum1).getMinutes()
                //         ) {
                //           min = chartdata.data1[i][1]
                //         }
                //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
                //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
                //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum2).getDate() &&
                //           new Date(chartdata.data1[i][0]).getHours() == new Date(mum2).getHours() &&
                //           new Date(chartdata.data1[i][0]).getMinutes() == new Date(mum2).getMinutes()

                //         ) {
                //           max = chartdata.data1[i][1]
                //         }
                //       }
                //       for (var i = 0; i < chartdata.data2.length; i++) {
                //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
                //           new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
                //           new Date(chartdata.data2[i][0]).getDate() == new Date(mum1).getDate() &&
                //           new Date(chartdata.data2[i][0]).getHours() == new Date(mum1).getHours() &&
                //           new Date(chartdata.data2[i][0]).getMinutes() == new Date(mum1).getMinutes()

                //         ) {
                //           min1 = chartdata.data2[i][1]
                //         }
                //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
                //           new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
                //           new Date(chartdata.data2[i][0]).getDate() == new Date(mum2).getDate() &&
                //           new Date(chartdata.data2[i][0]).getHours() == new Date(mum2).getHours() &&
                //           new Date(chartdata.data2[i][0]).getMinutes() == new Date(mum2).getMinutes()

                //         ) {
                //           max2 = chartdata.data2[i][1]
                //         }
                //       }
                //     } else {
                //       for (var i = 0; i < chartdata.data1.length; i++) {
                //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum1).getFullYear() &&
                //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 &&
                //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum1).getDate()
                //         ) {
                //           min = chartdata.data1[i][1]
                //         }
                //         if (new Date(chartdata.data1[i][0]).getFullYear() == new Date(mum2).getFullYear() &&
                //           new Date(chartdata.data1[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 &&
                //           new Date(chartdata.data1[i][0]).getDate() == new Date(mum2).getDate()

                //         ) {
                //           max = chartdata.data1[i][1]
                //         }
                //       }
                //       for (var i = 0; i < chartdata.data2.length; i++) {
                //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum1).getFullYear() && new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum1).getMonth() + 1 && new Date(chartdata.data2[i][0]).getDate() == new Date(mum1).getDate()) {
                //           min1 = chartdata.data2[i][1]
                //         }
                //         if (new Date(chartdata.data2[i][0]).getFullYear() == new Date(mum2).getFullYear() && new Date(chartdata.data2[i][0]).getMonth() + 1 == new Date(mum2).getMonth() + 1 && new Date(chartdata.data2[i][0]).getDate() == new Date(mum2).getDate()) {
                //           max2 = chartdata.data2[i][1]
                //         }
                //       }
                //     }
                //     var str = '';
                //     var str1 = '';
                //     if (((max - min) / min).toFixed(2) * 100 == Infinity || isNaN(((max - min) / min).toFixed(2) * 100)) {
                //       str = '';
                //     } else {
                //       str = 'Underlying Percentage Change: ' + that.floatMultiply(((max - min) / min).toFixed(2), 100) + '%';
                //     }
                //     if (((max2 - min1) / min1).toFixed(2) * 100 == Infinity || isNaN(((max2 - min1) / min1).toFixed(3) * 100)) {
                //       str1 = '';
                //     } else {
                //       str1 = 'Warrant bid percentage change: ' + that.floatMultiply(((max2 - min1) / min1).toFixed(3), 100) + '%';
                //     }
                //     this.title.update({ text: str + '<br/>' + str1 });
                //   },
                // }
            },
            title: {
                text: "",
            },
            subtitle: {
                text: "",
            },
            credits: {
                text: "",
            },
            xAxis: {
                // allowDecimals: false,
                gridLineColor: "#FAFAFA",
                crosshair: true,
                crosshair: {
                    width: 1,
                    color: 'red'
                },
                gridLineWidth: 1,
                type: "datetime",
                title: {
                    text: "",
                },
                labels: {
                    // formatter: function () {
                    //   return Highcharts.dateFormat('%y/%m', this.value)
                    // }
                    formatter: function () {
                        var date = '';
                        if (that.props.period == 'D') {
                            date = Highcharts.dateFormat('%H:%M', this.value)
                        } else {
                            date = Highcharts.dateFormat('%d/%m', this.value)
                        }
                        return date
                    }
                },
                // tickInterval: 7 * 24 * 3600 * 1000,// one day,
                dateTimeLabelFormats: {
                    // day: '%d/%m/%y',
                    day: '%y/%m',
                }
            },
            yAxis: [
                {
                    // crosshair: true,
                    opposite: true,
                    labels: {
                        formatter: function () {
                            return this.value;
                        },
                    },
                    title: {
                        text: "",
                    },
                    lineWidth: 1,
                    tickWidth: 1,
                    gridLineColor: "#FAFAFA",
                    min: chartdata.data2Axis.min,
                    max: chartdata.data2Axis.max,
                },
                {
                    // crosshair: true,
                    labels: {
                        formatter: function () {
                            return this.value;
                        },
                    },
                    title: {
                        text: "",
                    },
                    lineWidth: 1,
                    tickWidth: 1,
                    gridLineColor: "#FAFAFA",
                    min: chartdata.data1Axis.min,
                    max: chartdata.data1Axis.max,
                },
            ],
            exporting: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                split: true,
            },
            plotOptions: {
                area: {
                    marker: {
                        enabled: false,
                        symbol: "circle",
                        radius: 2,
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                    },
                },
                // series: {
                //   pointInterval: 24 * 3600 * 1000 * 60// one day
                // }
            },
            series: [
                {
                    name: "Underlying price",
                    yAxis: 1,
                    data: chartdata.data1,
                    tooltip: {
                        pointFormat: "Underlying price : <b>{point.y}</b>",
                        split: true,
                    },
                },
                {
                    name: "Warrant bid price (SGD)",
                    data: chartdata.data2,
                    color: "#DFC49F",
                    tooltip: {
                        pointFormat: "Warrant price (SGD) : <b>{point.y}</b>",
                        split: true,
                    },
                },
            ],
        };
        const style = {
            width: "100%",
            height: "370px",
        };
        return (
            <HighchartsReact
                highcharts={highcharts}
                options={chartOption}
                style={style}
                ref={this.chartRef}
            />
        );
        // return <div style={style} ref="charts"></div>;
    }
}
class LivematrixLogic {
    constructor(livematrix, ricdata) {
        this.livematrix = livematrix;
        this.ricdata = ricdata;

        this.isMacq = false;
        if (ricdata && "issuer_name" in ricdata) {
            if (ricdata.issuer_name?.indexOf("Macquarie") > -1) {
                this.isMacq = true;
            }
        }
        this.isSTI = false;
        this.isXIAOMI = false;
        this.isSP = false;
        this.isHKStock = false;
        if ("underlying_ric" in ricdata) {
            if (ricdata.underlying_ric === ".STI") {
                this.isSTI = true;
            } else if (ricdata.underlying_ric.indexOf("ESc") > -1) {
                this.isSP = true;
            } else if (ricdata.underlying_ric.indexOf(".HK") > -1) {
                this.isHKStock = true;
            }
            if (ricdata.underlying_ric === "1810.HK") {
                this.isXIAOMI = true;
            }
        }
        this.isPut = false;
        if ("type" in ricdata) {
            if (ricdata.type === "PUT") {
                this.isPut = true;
            }
        }
        this.isCompressed = false;
        if (this.livematrix !== null) {
            if (this.livematrix.length > 0) {
                if ("is_compressed" in this.livematrix[0]) {
                    if (this.livematrix[0].is_compressed) {
                        this.isCompressed = true;
                    }
                }
            }
        }
    }

    findMidPoint(iscompress, isfiveday) {
        const lmdata = this.livematrix;
        const type = this.ricdata.type;
        const lmprice = this.ricdata.underlying_bid_lm; // Number from source
        const mp = {
            price: -1,
            diff: -1,
            index: -1,
            price2: -1,
            diff2: -1,
            index2: -1,
        };
        if (lmdata !== null) {
            for (let i = 0; i < lmdata.length; i++) {
                if (iscompress && !(i % 2 === 0)) {
                    //for compressed data, process only even data
                    continue;
                }
                let ubid;
                if (isfiveday && !iscompress) {
                    ubid = Object.keys(lmdata[i])[0]; // ?
                } else {
                    ubid =
                        lmdata[i][type === "PUT" ? "underlying_ask" : "underlying_bid"];
                }
                let diff = Math.abs(lmprice - ubid);
                if (mp.index === -1 || mp.diff > diff) {
                    mp.diff2 = mp.diff;
                    mp.price2 = mp.price;
                    mp.index2 = mp.index;
                    mp.diff = diff;
                    mp.price = ubid;
                    mp.index = i;
                }
                if (lmprice < ubid && mp.index === i - 1) {
                    mp.diff2 = diff;
                    mp.price2 = ubid;
                    mp.index2 = i;
                }
            }
        }
        return mp;
    }

    findBIDMidPoint(iscompress, isfiveday) {
        const lmdata = this.livematrix;
        const lmprice = this.ricdata.BID;
        const type = this.ricdata.type;
        const mp = {
            price: -1,
            diff: -1,
            index: -1,
            price2: -1,
            diff2: -1,
            index2: -1,
        };
        for (let i = 0; i < lmdata.length; i++) {
            if (iscompress && !(i % 2 === 0)) {
                //for compressed data, process only even data
                continue;
            }
            let ubid;
            if (isfiveday && !iscompress) {
                ubid = Object.keys(lmdata[i])[0];
            } else {
                ubid = lmdata[i][type === "PUT" ? "bid" : "bid"];
            }
            const diff = Math.abs(lmprice - ubid);
            if (mp.index === -1 || mp.diff > diff) {
                mp.diff2 = mp.diff;
                mp.price2 = mp.price;
                mp.index2 = mp.index;
                mp.diff = diff;
                mp.price = ubid;
                mp.index = i;
            }
            if (lmprice < ubid && mp.index === i - 1) {
                mp.diff2 = diff;
                mp.price2 = ubid;
                mp.index2 = i;
            }
        }
        return mp;
    }

    getTableContent(page) {
        if (page) {
            return this.getHomeData();
        } else {
            if (this.livematrix.length == 0) {
                return;
            }
            if (this.isHKStock === false && this.isSP === false) {
                return this.case1();
            }
            return this.case2();
        }
    }
    getHomeData() {
        const ubidask = this.isPut ? "underlying_ask" : "underlying_bid";
        //update quote data and description
        var tablecontent = [];
        if (this.livematrix && this.livematrix.length > 4 && !this.isSTI) {
            var midpoint;
            if (!this.isXIAOMI) {
                if (this.isCompressed) {
                    midpoint = this.findMidPoint(
                        this.livematrix,
                        this.ricdata.underlying_bid_lm,
                        true,
                        false,
                        this.ricdata.type
                    );
                    const rowShow = this.case1_1_show(ubidask, midpoint);
                    var hrow, trow;
                    var scount = 1;
                    var lcount = 1;
                    var showcount = -1;
                    var startIndex, endIndex;
                    if (midpoint.index > this.livematrix.length - 3) {
                        startIndex = midpoint.index - 4;
                        endIndex = midpoint.index + 1;
                    } else if (midpoint.index < 2) {
                        startIndex = 0;
                        endIndex = 5;
                    } else {
                        startIndex = midpoint.index - 2;
                        endIndex = midpoint.index + 3;
                    }
                    for (var i = startIndex; i < endIndex + 1; i++) {
                        hrow = this.livematrix[i];
                        trow = this.livematrix[i + 1];

                        if (!isNaN(hrow[ubidask])) {
                            hrow[ubidask] = Number(hrow[ubidask]);
                        }
                        if (hrow.head_tail_id === trow.head_tail_id) {
                            var classrow = "";
                            let isShow = false;
                            if (hrow[ubidask] === midpoint.price) {
                                classrow += "midpoint";
                                isShow = true;
                            } else if (hrow[ubidask] < midpoint.price) {
                                classrow += "srow srow" + scount;
                                if (rowShow.sShow.length > scount) {
                                    isShow = rowShow.sShow[scount];
                                }
                                scount++;
                            } else if (hrow[ubidask] > midpoint.price) {
                                classrow += "lrow lrow" + lcount;
                                if (rowShow.lShow.length > lcount) {
                                    isShow = rowShow.lShow[lcount];
                                }
                                lcount++;
                            }
                            // let showStyle = { display: "none" }; 有修改
                            let showStyle = {};
                            if (isShow) {
                                showcount += 1;
                                showStyle = {};
                                if (showcount % 2 === 0) {
                                    classrow += " bgcolor-08";
                                }
                            }
                            const hRow = (
                                <tr key={i} className={classrow} style={showStyle}>
                                    <td>
                                        {Number(
                                            this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                                        ).toFixed(3)}
                                    </td>
                                    <td>{Number(hrow.bid).toFixed(3)}</td>
                                    <td>{Number(hrow.ask).toFixed(3)}</td>
                                    <td>
                                        {Number(
                                            this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                                        ).toFixed(3)}
                                    </td>
                                </tr>
                            );
                            tablecontent.push(hRow);
                            const tRow = (
                                <tr key={i + 1} className={classrow} style={showStyle}>
                                    <td>
                                        {Number(
                                            this.isPut ? trow.underlying_ask : trow.underlying_bid
                                        ).toFixed(3)}
                                    </td>
                                    <td>{Number(trow.bid).toFixed(3)}</td>
                                    <td>{Number(trow.ask).toFixed(3)}</td>
                                    <td>
                                        {Number(
                                            this.isPut ? trow.underlying_bid : trow.underlying_ask
                                        ).toFixed(3)}
                                    </td>
                                </tr>
                            );
                            tablecontent.push(tRow);
                            i++;
                        }
                    }
                } else {
                    midpoint = this.findMidPoint(
                        this.livematrix,
                        this.ricdata.underlying_bid_lm,
                        false,
                        false,
                        this.ricdata.type
                    );
                    var livematrixindex = 0;
                    var livematrixlimit = 9;
                    livematrixindex = midpoint.index;
                    if (livematrixindex === 0) {
                        livematrixindex = Math.ceil(this.livematrix.length / 2);
                    }
                    let startIndex, endIndex;
                    if (midpoint.index === this.livematrix.length - 1) {
                        startIndex = midpoint.index - 4;
                        endIndex = midpoint.index;
                    } else if (midpoint.index < 2) {
                        startIndex = 0;
                        endIndex = 4;
                    } else {
                        startIndex = midpoint.index - 2;
                        endIndex = midpoint.index + 2;
                    }
                    for (let i = startIndex; i < endIndex + 1; i++) {
                        var livematrixdata = this.livematrix[i];
                        var isMidPoint =
                            livematrixdata.underlying_bid.toFixed(3) ===
                                midpoint.price.toFixed(3)
                                ? true
                                : false;
                        let rowClassName = isMidPoint ? "midpoint" : "";
                        rowClassName += i % 2 === 0 ? " bgcolor-08" : "";
                        const row = (
                            <tr key={i} className={rowClassName}>
                                <td>
                                    {(this.isPut
                                        ? livematrixdata.underlying_ask
                                        : livematrixdata.underlying_bid
                                    ).toFixed(3)}
                                </td>
                                <td>{livematrixdata.bid.toFixed(3)}</td>
                                <td>{livematrixdata.ask.toFixed(3)}</td>
                                <td>
                                    {(this.isPut
                                        ? livematrixdata.underlying_bid
                                        : livematrixdata.underlying_ask
                                    ).toFixed(3)}
                                </td>
                            </tr>
                        );
                        tablecontent.push(row);
                    }
                }
            } else {
                for (let i = 0; i < this.livematrix.length; i++) {
                    let livematrixdata = this.livematrix[i];
                    const rowClassName = i % 2 === 0 ? " bgcolor-08" : "";
                    const row = (
                        <tr key={i} className={rowClassName}>
                            <td>
                                {(this.isPut
                                    ? livematrixdata.underlying_ask
                                    : livematrixdata.underlying_bid
                                ).toFixed(3)}
                            </td>
                            <td>{livematrixdata.bid.toFixed(3)}</td>
                            <td>{livematrixdata.ask.toFixed(3)}</td>
                            <td>
                                {(this.isPut
                                    ? livematrixdata.underlying_bid
                                    : livematrixdata.underlying_ask
                                ).toFixed(3)}
                            </td>
                        </tr>
                    );
                    tablecontent.push(row);
                }
            }
        }
        return tablecontent;
    }
    //Case 1: isHKStock === false && isSP === false
    case1() {
        if (this.isCompressed) {
            return this.case1_1();
        }
        return this.case1_2();
    }

    //Case 1.1: isHKStock === false && isSP === false && is_compressed === true
    case1_1_show(ubidask, midpoint) {
        let hrow, trow;
        let scount = 1,
            lcount = 1;
        for (let i = 0; i < this.livematrix.length; i++) {
            hrow = this.livematrix[i];
            trow = this.livematrix[i + 1];
            let hrowUbidask = null;
            if (ubidask in hrow) {
                hrowUbidask = Number(hrow[ubidask]);
            }
            let midpointPrice = Number(midpoint.price);
            if (
                hrowUbidask !== null &&
                !isNaN(hrowUbidask) &&
                !isNaN(midpointPrice)
            ) {
                if (hrowUbidask < midpointPrice) {
                    scount++;
                } else if (hrowUbidask > midpointPrice) {
                    lcount++;
                }
            }
            i++;
        }
        const sShow = new Array(scount).fill(false);
        const lShow = new Array(lcount).fill(false);

        // if ("BID" in this.ricdata && Number(this.ricdata.BID) === 0.04) {
        //   for (let i = lcount; i >= lcount - 8; i--) {
        //     lShow[i] = true;
        //   }
        // } else {
        for (let i = lcount; i >= lcount - 8; i--) {
            lShow[i] = true;
        }
        for (let i = 1; i <= 8; i++) {
            sShow[i] = true;
        }
        // }
        return { sShow, lShow };
    }

    case1_1() {
        const ubidask = this.isPut ? "underlying_ask" : "underlying_bid";
        const midpoint = this.findMidPoint(true, false);
        const lm = this.ricdata.underlying_bid_lm;
        let hrow, trow;
        let scount = 1,
            lcount = 1,
            showcount = -1;
        const rowShow = this.case1_1_show(ubidask, midpoint);
        const tablecontent = [];
        var midpointPrice = Number(midpoint.price2);
        for (let i = 0; i < this.livematrix.length - 1; i++) {
            hrow = this.livematrix[i];
            trow = this.livematrix[i + 1];
            let hrowUbidask = null;
            let trowUbidask = null;
            if (ubidask in hrow) {
                hrowUbidask = Number(hrow[ubidask]);
            }
            if (ubidask in trow) {
                trowUbidask = Number(trow[ubidask]);
            }
            if (hrowUbidask === midpointPrice) {
                if (hrowUbidask > lm) {
                    if (trowUbidask > lm) {
                        midpointPrice = Number(midpoint.price)
                    }
                } else {
                    if (trowUbidask < lm) {
                        midpointPrice = Number(midpoint.price)
                    }
                }
            }
            if (
                hrowUbidask !== null &&
                !isNaN(hrowUbidask) &&
                !isNaN(midpointPrice)
            ) {
                if (hrow.head_tail_id === trow.head_tail_id) {
                    let rowClassName = "";
                    let isShow = false;
                    if (hrowUbidask === midpointPrice) {
                        rowClassName += "midpoint";
                        isShow = true;
                    } else if (hrowUbidask < midpointPrice) {
                        rowClassName += "srow srow" + scount;
                        if (rowShow.sShow.length > scount) {
                            isShow = rowShow.sShow[scount];
                        }
                        scount++;
                    } else if (hrowUbidask > midpointPrice) {
                        rowClassName += "lrow lrow" + lcount;
                        if (rowShow.lShow.length > lcount) {
                            isShow = rowShow.lShow[lcount];
                        }
                        lcount++;
                    }
                    let showStyle = { display: "none" };
                    if (isShow) {
                        showcount += 1;
                        showStyle = {};
                        if (showcount % 2 === 0) {
                            rowClassName += " bgcolor-08";
                        }
                    }
                    const hRow = (
                        <tr key={i} className={rowClassName} style={showStyle}>
                            <td>
                                {Number(
                                    this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                                ).toFixed(3)}
                            </td>
                            <td>{Number(hrow.bid).toFixed(3)}</td>
                            <td>{Number(hrow.ask).toFixed(3)}</td>
                            <td>
                                {Number(
                                    this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                                ).toFixed(3)}
                            </td>
                        </tr>
                    );
                    tablecontent.push(hRow);
                    const tRow = (
                        <tr key={i + 1} className={rowClassName} style={showStyle}>
                            <td>
                                {Number(
                                    this.isPut ? trow.underlying_ask : trow.underlying_bid
                                ).toFixed(3)}
                            </td>
                            <td>{Number(trow.bid).toFixed(3)}</td>
                            <td>{Number(trow.ask).toFixed(3)}</td>
                            <td>
                                {Number(
                                    this.isPut ? trow.underlying_bid : trow.underlying_ask
                                ).toFixed(3)}
                            </td>
                        </tr>
                    );
                    tablecontent.push(tRow);
                    i++;
                }
            }
        }
        // tableElement.html(tablecontent);
        // if (ric_data.BID == 0.04) {
        //     tableElement.find(".srow").hide();
        //     tableElement.find(".lrow").hide();
        //     for (var i = lcount; i >= lcount - 8; i--) {
        //         tableElement.find(".lrow" + i).show();
        //     }
        // } else {
        //     tableElement.find(".srow").hide();
        //     tableElement.find(".lrow").hide();
        //     for (var i = lcount; i >= lcount - 8; i--) {
        //         tableElement.find(".lrow" + i).show();
        //     }
        //     for (var i = 1; i <= 8; i++) {
        //         tableElement.find(".srow" + i).show();
        //     }
        // }
        // var isOdd = true;
        // var cnt = 1;
        // tableElement.find('tr:visible').filter(function () {
        //     if (cnt > 2) {
        //         cnt = 1;
        //         isOdd = !isOdd;
        //     }
        //     cnt++;
        //     return isOdd;
        // }).addClass('bgcolor-08');
        return tablecontent;
    }

    //Case 1.2: isHKStock === false && isSP === false && is_compressed === false
    case1_2() {
        const midpoint = this.findMidPoint(false, false);
        let livematrixindex = 0;
        let livematrixlimit = 9;
        if (this.isSP) {
            livematrixlimit = 14;
        }
        livematrixindex = midpoint.index;
        if (livematrixindex === 0) {
            livematrixindex = Math.ceil(this.livematrix.length / 2);
        }
        const tablecontent = [];
        for (let i = 0; i < this.livematrix.length; i++) {
            if (
                i > livematrixindex - livematrixlimit &&
                i < livematrixindex + livematrixlimit
            ) {
                let livematrixdata = this.livematrix[i];
                let isMidPoint =
                    livematrixdata.underlying_bid.toFixed(3) === midpoint.price.toFixed(3)
                        ? true
                        : false;
                if (!isMidPoint && midpoint.diff !== 0) {
                    isMidPoint =
                        livematrixdata.underlying_bid.toFixed(3) ===
                            midpoint.price2.toFixed(3)
                            ? true
                            : false;
                }
                // tablecontent += isMidPoint ? "<tr class='midpoint'><td>" : "<tr><td>";
                // tablecontent += (isPut? livematrixdata.underlying_ask : livematrixdata.underlying_bid ).toFixed(3);
                // tablecontent += "</td><td>"
                // tablecontent += livematrixdata.bid.toFixed(3);
                // tablecontent += "</td><td>"
                // tablecontent += livematrixdata.ask.toFixed(3);
                // tablecontent += "</td><td>"
                // tablecontent += (isPut? livematrixdata.underlying_bid : livematrixdata.underlying_ask).toFixed(3);
                // tablecontent += "</td></tr>";
                let rowClassName = isMidPoint ? "midpoint" : "";
                rowClassName += i % 2 === 0 ? " bgcolor-08" : "";
                const row = (
                    <tr key={i} className={rowClassName}>
                        <td>
                            {(this.isPut
                                ? livematrixdata.underlying_ask
                                : livematrixdata.underlying_bid
                            ).toFixed(3)}
                        </td>
                        <td>{livematrixdata.bid.toFixed(3)}</td>
                        <td>{livematrixdata.ask.toFixed(3)}</td>
                        <td>
                            {(this.isPut
                                ? livematrixdata.underlying_bid
                                : livematrixdata.underlying_ask
                            ).toFixed(3)}
                        </td>
                    </tr>
                );
                tablecontent.push(row);
            }
        }
        // tableElement.html(tablecontent);
        // tableElement.find('tr:visible').filter(':even').addClass('bgcolor-08');
        return tablecontent;
    }

    //Case 2: !(isHKStock === false && isSP === false)
    case2() {
        if (this.isCompressed) {
            return this.case2_1();
        }
        return this.case2_2();
    }

    // Case 2.1: !(isHKStock === false && isSP === false) && is_compressed === true
    case2_1_show(ubidask, midpoint) {
        let hrow, trow;
        let scount = 1,
            lcount = 1;
        for (let i = 0; i < this.livematrix.length; i++) {
            hrow = this.livematrix[i];
            trow = this.livematrix[i + 1];
            let hrowUbidask = null;
            if (ubidask in hrow) {
                hrowUbidask = Number(hrow[ubidask]);
            }
            let midpointPrice = Number(midpoint.price);
            if (
                hrowUbidask !== null &&
                !isNaN(hrowUbidask) &&
                !isNaN(midpointPrice)
            ) {
                if (hrowUbidask < midpointPrice) {
                    scount++;
                } else if (hrowUbidask > midpointPrice) {
                    lcount++;
                }
            }
            i++;
        }
        const sShow = new Array(scount).fill(false);
        const lShow = new Array(lcount).fill(false);
        // if ("BID" in this.ricdata && Number(this.ricdata.BID) === 0.04) {
        //   for (let i = lcount; i >= lcount - 8; i--) {
        //     lShow[i] = true;
        //   }
        // } else {
        if (this.livematrix[0].bid < this.livematrix[this.livematrix.length - 1].bid) {
            for (let i = 0; i <= 8; i++) {
                lShow[i] = true;
            }
            // i=0
            for (let i = scount; i >= scount - 8; i--) {
                sShow[i] = true;
            }
        } else {
            for (let i = lcount; i >= lcount - 8; i--) {
                lShow[i] = true;
            }
            for (let i = 1; i <= 8; i++) {
                sShow[i] = true;
            }
        }
        // }
        return { sShow, lShow };
    }

    case2_1() {
        const ubidask = "bid";
        const midpoint = this.findBIDMidPoint(true, false);
        let hrow = null;
        let trow = null;
        let scount = 1,
            lcount = 1,
            showcount = -1;
        const rowShow = this.case2_1_show(ubidask, midpoint);
        const tablecontent = [];
        for (let i = 0; i < this.livematrix.length - 1; i++) {
            hrow = this.livematrix[i];
            trow = this.livematrix[i + 1];
            let hrowUbidask = null;
            if (ubidask in hrow) {
                hrowUbidask = Number(hrow[ubidask]);
            }
            const midpointPrice = Number(midpoint.price);
            if (
                hrowUbidask !== null &&
                !isNaN(hrowUbidask) &&
                !isNaN(midpointPrice)
            ) {
                if (hrow.head_tail_id === trow.head_tail_id) {
                    let rowClassName = "";
                    let isShow = false;
                    if (hrowUbidask === midpointPrice) {
                        rowClassName += "midpoint";
                        isShow = true;
                    } else if (hrowUbidask < midpointPrice) {
                        rowClassName += "srow srow" + scount;
                        if (rowShow.sShow.length > scount) {
                            isShow = rowShow.sShow[scount];
                        }
                        scount++;
                    } else if (hrowUbidask > midpointPrice) {
                        rowClassName += "lrow lrow" + lcount;
                        if (rowShow.lShow.length > lcount) {
                            isShow = rowShow.lShow[lcount];
                        }
                        lcount++;
                    }

                    // tablecontent += "<tr class='" + rowClassName + "'><td>";
                    // tablecontent += Number(isPut? hrow.underlying_ask : hrow.underlying_bid).toFixed(3);
                    // tablecontent += "</td><td>";
                    // tablecontent += Number(hrow.bid).toFixed(3);
                    // tablecontent += "</td><td>";
                    // tablecontent += Number(hrow.ask).toFixed(3);
                    // tablecontent += "</td><td>";
                    // tablecontent += Number(isPut? hrow.underlying_bid : hrow.underlying_ask).toFixed(3);
                    // tablecontent += "</td></tr>";
                    let showStyle = { display: "none" };
                    if (isShow) {
                        showcount += 1;
                        showStyle = {};
                        if (showcount % 2 === 0) {
                            rowClassName += " bgcolor-08";
                        }
                    }
                    const hRow = (
                        <tr key={i} className={rowClassName} style={showStyle}>
                            <td>
                                {Number(
                                    this.isPut ? hrow.underlying_ask : hrow.underlying_bid
                                ).toFixed(3)}
                            </td>
                            <td>{Number(hrow.bid).toFixed(3)}</td>
                            <td>{Number(hrow.ask).toFixed(3)}</td>
                            <td>
                                {Number(
                                    this.isPut ? hrow.underlying_bid : hrow.underlying_ask
                                ).toFixed(3)}
                            </td>
                        </tr>
                    );
                    tablecontent.push(hRow);

                    // tablecontent += "<tr class='" + rowClassName + "'><td>";
                    // tablecontent += Number(isPut? trow.underlying_ask : trow.underlying_bid).toFixed(3);
                    // tablecontent += "</td><td>";
                    // tablecontent += Number(trow.bid).toFixed(3);
                    // tablecontent += "</td><td>";
                    // tablecontent += Number(trow.ask).toFixed(3);
                    // tablecontent += "</td><td>";
                    // tablecontent += Number(isPut? trow.underlying_bid : trow.underlying_ask).toFixed(3);
                    // tablecontent += "</td></tr>";
                    const tRow = (
                        <tr key={i + 1} className={rowClassName} style={showStyle}>
                            <td>
                                {Number(
                                    this.isPut ? trow.underlying_ask : trow.underlying_bid
                                ).toFixed(3)}
                            </td>
                            <td>{Number(trow.bid).toFixed(3)}</td>
                            <td>{Number(trow.ask).toFixed(3)}</td>
                            <td>
                                {Number(
                                    this.isPut ? trow.underlying_bid : trow.underlying_ask
                                ).toFixed(3)}
                            </td>
                        </tr>
                    );
                    tablecontent.push(tRow);
                    i++;
                }
            }
        }
        // tableElement.html(tablecontent);
        // if (ric_data.BID == 0.04) {
        //     tableElement.find(".srow").hide();
        //     tableElement.find(".lrow").hide();
        //     for (var i = lcount; i >= lcount - 8; i--) {
        //         tableElement.find(".lrow" + i).show();
        //     }
        // } else {
        //     tableElement.find(".srow").hide();
        //     tableElement.find(".lrow").hide();
        //     for (var i = lcount; i >= lcount - 8; i--) {
        //         tableElement.find(".lrow" + i).show();
        //     }
        //     for (var i = 1; i <= 8; i++) {
        //         tableElement.find(".srow" + i).show();
        //     }
        // }
        // var isOdd = true;
        // var cnt = 1;
        // tableElement.find('tr:visible').filter(function () {
        //     if (cnt > 2) {
        //         cnt = 1;
        //         isOdd = !isOdd;
        //     }
        //     cnt++;
        //     return isOdd;
        // }).addClass('bgcolor-08');
        return tablecontent;
    }

    // Case 2.2: !(isHKStock === false && isSP === false) && is_compressed === false
    case2_2() {
        const midpoint = this.findBIDMidPoint(false, false);
        let tick = 0.001;
        const midprice = Number(midpoint.price);
        if (midprice >= 0.2) tick = 0.005;
        var minvalue = Number((midprice - 5 * tick).toFixed(4));
        var macvalue = Number((midprice + 5 * tick).toFixed(4));
        // var ticklimit = 10;
        // var livematrixindex = 0;
        // var livematrixlimit = 14;
        // livematrixindex = midpoint.index;
        // if (livematrixindex === 0) {
        //     livematrixindex = Math.ceil(this.livematrix.length / 2);
        // }
        const tablecontent = [];
        for (var i = 0; i < this.livematrix.length; i++) {
            const livematrixdata = this.livematrix[i];
            const lmdatabid = Number(livematrixdata.bid);
            if (lmdatabid >= minvalue && lmdatabid <= macvalue) {
                let isMidPoint =
                    livematrixdata.bid.toFixed(3) === midpoint.price.toFixed(3)
                        ? true
                        : false;
                if (!isMidPoint && midpoint.diff !== 0) {
                    isMidPoint =
                        livematrixdata.bid.toFixed(3) === midpoint.price2.toFixed(3)
                            ? true
                            : false;
                }
                // tablecontent += "<tr><td>";
                // tablecontent += (isPut? livematrixdata.underlying_ask : livematrixdata.underlying_bid ).toFixed(3);
                // tablecontent += "</td><td>"
                // tablecontent += livematrixdata.bid.toFixed(3);
                // tablecontent += "</td><td>"
                // tablecontent += livematrixdata.ask.toFixed(3);
                // tablecontent += "</td><td>"
                // tablecontent += (isPut? livematrixdata.underlying_bid : livematrixdata.underlying_ask).toFixed(3);
                // tablecontent += "</td></tr>";
                const rowClassName = i % 2 === 0 ? " bgcolor-08" : "";
                const row = (
                    <tr key={i} className={rowClassName}>
                        <td>
                            {(this.isPut
                                ? livematrixdata.underlying_ask
                                : livematrixdata.underlying_bid
                            ).toFixed(3)}
                        </td>
                        <td>{livematrixdata.bid.toFixed(3)}</td>
                        <td>{livematrixdata.ask.toFixed(3)}</td>
                        <td>
                            {(this.isPut
                                ? livematrixdata.underlying_bid
                                : livematrixdata.underlying_ask
                            ).toFixed(3)}
                        </td>
                    </tr>
                );
                tablecontent.push(row);
            }
        }
        // tableElement.html(tablecontent);
        // tableElement.find('tr:visible').filter(':even').addClass('bgcolor-08');
        return tablecontent;
    }
}
export { LiveMatrixTodayTable, IndtableShow, TermsChart, WrCalculatorBox, LivematrixLogic }