const HSI = {
    "stime": "2021-02-26 11:34",
    "last": "29190",
    "month": "March",
    "chng": "-770",
    "code": "HSI",
    "pchng": "-2.57%"
}

const HTI = {
	"stime": "2021-02-25 16:31",
	"last": "9515",
	"month": "March",
	"chng": "183",
	"code": "HTI",
	"pchng": "1.96%"
}

// const SSIcv1 = {
//     "update_time": "11:41:23 AM",
//     "keys": [
//         "SSIcv1"
//     ],
//     "SSIcv1": [
//         {
//             "PRCTCK_1": "uptick",
//             "pct": "-2.80",
//             "dsply_name": "Nikkei",
//             "expiryMonth": "March",
//             "ask": "29,355",
//             "net": "-845",
//             "bid": "29,350",
//             "key": "SSIcv1"
//         }
//     ]
// }

const Others = {
	"update_time": "03:30:39 PM",
	"keys": [
		"SSIcv1"
	],
	"SSIcv1": [
		{
			"PRCTCK_1": "uptick",
			"pct": "+0.10",
			"dsply_name": "Nikkei",
			"expiryMonth": "March",
			"ask": "29,290",
			"net": "+30",
			"bid": "29,285",
			"key": "SSIcv1"
		}
	]
}

const liveindexfutureData = {
	"HSI": HSI,
	"HTI": HTI,
	"Others": Others
}

export default liveindexfutureData;