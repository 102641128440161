import React, { Component } from 'react'
import MQSGUtil from "../../../components/mqsgUtil";

import './subscribe.css'

export default class subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            warningShow: false,
            email: '',
            httpEmailText: false
        };
    }
    gtag_report_conversion() {
        const { email } = this.state
        // let reg = /^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/;
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!reg.test(email)) {
            this.setState({
                warningShow: true
            });
        } else {
            const url = MQSGUtil.getAPIBasePath() + "/registration?p=register";
            fetch(
                url,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: 'email=' + email
                }
            ).then((res) => res.json()).then(
                (result) => {
                    this.setState({
                        httpEmailText: true
                    }, () => {
                        window.setTimeout(() => {
                            this.setState({
                                httpEmailText: false,
                                warningShow: false
                            })
                        }, 3000)

                    })
                },
                (error) => {
                }
            );
        }
    }
    setEmail(e) {
        this.setState({
            email: e.target.value
        })
    }
    hiden() {
        this.setState({
            httpEmailText: false
        })
    }
    show(value) {
        this.setState({
            show: value
        })
    }
    linkTo(v) {
        var link = ''
        if (v == 1) {
            link = "/marketnews/dailyhighlights"
        } else {
            link = "/marketnews/overnightmarkets"
        }
        window.open(link, '_self')
    }
    render() {
        return (
            <div id="subscribe" className="pageobj" onClick={() => this.hiden}>
                <div className="breadcrumbbar">
                    <div className="container-fluid">
                        <ol className="breadcrumb">
                            <li><a href="/home/news">Market news</a></li>
                            <li className="active">Subscribe</li>
                        </ol>
                    </div>
                </div>
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section">
                                <div className="bg-layer hidden-sm hidden-xs" style={{ height: '1050px' }}>
                                    <img src="/img/marketnews/bg-subscribe.jpg" alt="" />
                                </div>
                                <div className="bg-layer visible-sm-block" style={{ height: '1050px' }}>
                                    <img src="/img/marketnews/bg-subscribe.jpg" alt="" />
                                </div>
                                <div className="bg-layer visible-xs-block">
                                    <img src="/img/marketnews/u2004_a.png" alt="" />
                                </div>
                                <div className="content-layer">
                                    <div className="page-header" style={{ marginTop: '0' }}>
                                        <div className="color-primary subh1">KEEP UP TO DATE</div>
                                        <div className="title_2">with the latest news</div>
                                        <div className="title_3">Sign up and join 17,000 subscribers in receiving free Morning Market Buzz</div>
                                        <div className="title_4">You will get the inside scoop on what's happening in the warrant market everyday</div>
                                        <div id="view-sample-btn" onClick={() => { this.show(true) }}>View sample</div>
                                    </div>
                                    <div className="tips-layer">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6">
                                                <div id="dailyTip" className="tip" style={{ cursor: 'pointer' }} onClick={() => { { this.linkTo(1) } }}>
                                                    <div className="tip-icon"><img src="/img/marketnews/subscribe_1.png" alt="" /></div>
                                                    <div className="tip-item">
                                                        <div className="tip-title">Morning Market Buzz</div>
                                                        Including Macquarie Research updates, technical analysis from industry experts and updates on the latest events moving the markets.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6">
                                                <div id="overnightTip" className="tip" style={{ cursor: 'pointer' }} onClick={() => { { this.linkTo(2) } }}>
                                                    <div className="tip-icon"><img src="/img/marketnews/subscribe_3.png" alt="" /></div>
                                                    <div className="tip-item">
                                                        <div className="tip-title">Overnight market wrap</div>
                                                        Highlights the major news events from the US and European markets. What is going to affect the market today?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="greatnewsletter visible-xs">
                                        <div className="title_2">This newsletter is GREAT!</div>
                                    </div>
                                    <div className="shadow-layer">
                                        <div className="greatnewsletter hidden-xs">
                                            <div className="title_2">This newsletter is GREAT!</div>
                                        </div>
                                        <div className="signup-layer">
                                            <div className="page-header">
                                                <h3>Sign up now</h3>
                                            </div>
                                            <input type="text" id="email" placeholder="Email" onChange={(e) => { this.setEmail(e) }} />
                                            <div className="warning" style={{ visibility: this.state.warningShow ? 'visible' : 'hidden' }}>invalid email</div>
                                            <p className="small">
                                                By clicking ”Submit”, you confirm that you would like to subscribe to Macquarie’s daily warrant newsletter. By subscribing, you will also automatically receive invitations or marketing material from Macquarie for warrant seminars and other warrant related initiatives. Macquarie website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Capital Securities (Singapore) Pte Ltd and other companies of Macquarie Group (“Macquarie”).
                                                <br /><br />
                                                Please read the <a target="_blank" href="/about/privacypolicy">privacy policy</a> and <a target="_blank" href="/about/importantinformation">important information</a> via the links at the bottom of this page to understand how your personal information will be treated by Macquarie.
                                            </p>
                                            <input type="button" id="submitBtn" value="Submit" onClick={() => this.gtag_report_conversion()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='newpopup_image' style={{ display: this.state.show ? '' : 'none' }}>
                    <div className='newpopup_image_box'>
                        <img src="/img/bullet/icon-close-white.png" className='btn-closes' alt="" onClick={() => { this.show(false) }} />
                        <img src="/img/bullet/sample.jpg" className='image' alt="" style={{ maxWidth: '1095px' }} />
                    </div>
                </div>
                <div id="popup-customized" style={{ display: this.state.httpEmailText ? 'table' : 'none' }}>
                    <div>
                        <h5>You have successfully registered for our daily newsletter!</h5>
                    </div>
                </div>
            </div>
        )
    }
}
