
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class lowercapitaloutlay extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div>
                            <div id="mainnav">
                                <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                    <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                        <div id="mainscrollcontent">
                                            <div className="contentbox">
                                                <p>Warrants enable investors to gain exposure to blue chip shares for a fraction of the share price. An investor interested in purchasing shares who does not have immediate access to funds could purchase call warrants to capture
                                                    the benefits of an anticipated price rise. Because of the significantly lower outlay for warrants, brokerage and transaction costs are also lower.</p>
                                            </div>
                                            <div className="imgholder">
                                                <img src="/img/education/Script_0104-lower-capital-outlay_enlarge.jpg" alt />
                                                <div className="visible-xs">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}