import React, { Component } from "react";
import { Link } from "react-router-dom";
import BreadcrumbBar from "../../../components/breadcrumbBar";
import MQSGUtil from "../../../components/mqsgUtil";
import "./gallery.css";

export default class Gallery extends Component {
  constructor() {
    super();
    this.state = {
      photogallerydata: null,
    };
  }

  componentDidMount() {
    this.onFetchData();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("Gallery");
    if (staticData !== null) {
      this.setState({ photogallerydata: staticData });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("Gallery")) {
      setTimeout(() => {
        this.onFetchStaticData();
      }, 100); // pretend fetch
      return;
    }
    let photogallerydata = null;
    const url = MQSGUtil.getAPIBasePath() + "/PhotoGalleryJSON";
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          photogallerydata = result;
          this.setState({ photogallerydata });
        },
        (error) => {
          this.setState({ photogallerydata });
        }
      );
  }

  getRows() {
    const rows = [];
    const photogallerydata = this.state.photogallerydata;
    if (photogallerydata !== null && Array.isArray(photogallerydata)) {
      photogallerydata.forEach((data, index) => {
        const id = MQSGUtil.getData(data, "id");
        // let imgsrc = "/photo?album_id=" + id;
        let imgsrc = MQSGUtil.getAPIBasePath() + "/photo?album_id=" + id;
        // --- below code can be take out in PROD later
        const demo = MQSGUtil.getData(data, "demo", null);
        if (demo !== null && demo) {
          imgsrc = "/img/photo/album_id_" + id + ".jpeg";
        }
        // --------------------------------------------
        const link = "/marketnews/individualgallery/" + id;
        const entitle = MQSGUtil.getData(data, "en_title");

        const div1 = (
          <div className="bg-layer">
            <img src={imgsrc} alt=""></img>
          </div>
        );
        const div2 = (
          <div className="content-layer">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Link target="_blank" to={link}>
                      <h2 style={{ color: "#fff" }}>{entitle}</h2>
                      <div className="view_btn hidden-xs">View more</div>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
        const row = (
          <div className="albumtmpl" key={index + "_" + id}>
            {div1}
            {div2}
          </div>
        );
        rows.push(row);
      });
    }
    return rows;
  }

  render() {
    const rows = this.getRows();
    return (
      <div id="photogallery" className="pageobj">
        <BreadcrumbBar
          link="/home/news"
          group="Market news"
          title="Photo gallery"
        />
        <div className="stripe-bg"></div>
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h2 style={{ margin: '0px', color: '#000' }}>Photo gallery</h2>
            </div>
            <p>
              Visit our gallery to see all of the happenings at our recent
              events
            </p>
            <div id="albums" style={{ position: "relative" }}>
              {rows}
            </div>
            <hr style={{ marginTop: "20px" }} className="hr-hmgbm" />
          </div>
        </div>
      </div>
    );
  }
}

// 1. /marketnews/individualgallery : we need to have individualgallery.jsx
