import React, { Component } from "react";
import MQSGUtil from "../mqsgUtil";
import ReactTooltip from "react-tooltip";
import "./liveindexfuture.css";

export default class LiveIndexFuture extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  getChildFun() {
    this.refs.child1.onFetchData()
    this.refs.child2.onFetchData()
  }
  renderPage() {
    return (
      <div className="holder-indexfuture">
        <div id="indexfuture" className="section" style={{ padding: '20px' }}>
          <div className="page-header">
            <h2 className="small">Live index futures prices</h2>
            {/* Live index
            futures prices */}
          </div>
          <table
            className="table table-striped table-noHeaderborder liveMatrixTable"
            style={{ marginBottom: "0px" }}
          >
            <thead>
              <tr>
                <th style={{ width: "20%", fontSize: '16px' }}>Underlying</th>
                <th></th>
                <th style={{ fontSize: '16px' }}>As at</th>
                {/* <th style={{ fontSize: '16px' }}>Contract</th> */}
                <th style={{ fontSize: '16px' }}>Last</th>
                <th style={{ fontSize: '16px' }}>Change</th>
                <th style={{ fontSize: '16px' }}>Change%</th>
              </tr>
            </thead>
            <tbody className="liveMatrixTable">
              <STI interval={10000} />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  renderHome() {
    return (
      <div className="holder-indexfuture">
        <div id="indexfuture" className="section" style={{ overflowX: 'auto' }}>
          <div className="page-header">
            <h2 className="small">Live index futures prices</h2>
            {/* Live index
            futures prices */}
          </div>
          <table
            className="table table-striped table-noHeaderborder"
            style={{ marginBottom: "0px" }}
          >
            <thead>
              <tr>
                <th className="minW82" style={{ width: "20%", fontSize: '16px' }}>Underlying</th>
                <th></th>
                <th className="minW100" style={{ fontSize: '16px' }}>As at</th>
                <th className="minW100" style={{ fontSize: '16px' }}>Contract</th>
                <th className="minW62" style={{ fontSize: '16px' }}>Last</th>
                <th className="minW82" style={{ fontSize: '16px' }}>Change</th>
                <th className="minW82" style={{ fontSize: '16px' }}>Change%</th>
              </tr>
            </thead>
            <tbody>
              <HSI interval={10000} ref="child1" getChildFun={this.getChildFun.bind(this)} />
              <HSTECH interval={10000} ref="child2" getChildFun={this.getChildFun.bind(this)} />
              {/* <STI interval={10000} /> */}
              <Others interval={10000} />
              <ZSEAsic1 interval={10000} />

            </tbody>
          </table>

          <Popovers></Popovers>
          {/* <div
            // className="priceUpdateInfo"
            className="indicator-item small"
            data-tip
            data-for="indicator-hotdw-tooltip"
            data-tooltip-id="indicator-hotdw-tooltip"
            style={{
              marginBottom: "20px",
              fontStyle: "italic",
              fontSize: "16px",
            }}
          >
            *Click refresh button for live quote
            <br />
            <span
              className="ttip"
              style={{ color: "#0675C2" }}
              data-target="hotdw"
            >
              HSI and HSTECH data provided by DB Power Online Limited
              <br />
              [Disclaimer]
            </span>
            <ReactTooltip
              id="indicator-hotdw-tooltip"
              className="lives-tooltip hidden-xs"
              delayHide={100}
              place="right"
              offset={{ top: -10, left: -30 }}
              // overridePosition={(
              //   // { left: number, top: number },
              //   currentEvent,
              //   currentTarget,
              //   node,
              //   place,
              //   desiredPlace,
              //   effect,
              //   offset
              // ) => ({ left: 0, top: 0 })}
              type="warning"
              effect="solid"
              backgroundColor="#F1D031"
              textColor="#000"
              width="70%"
              left="10px"
              top="10px"
            >
              <div style={{ fontStyle: "normal" }}>
                DB Power Online Limited, “HKEX Information Services Limited, its
                holding companies and/or any subsidiaries of such holding
                companies”, China Investment Information Services Limited, third
                party information providers endeavor to ensure the accuracy and
                reliability of the information provided but do not guarantee its
                accuracy or reliability and accept no liability (whether in tort
                or contract or otherwise) for any loss or damage arising from
                any inaccuracies or omissions.
                <br />
                The BMP Service is aimed for investor reference. The BMP only
                provides basic market information for reference purposes and the
                investors should consider if they would need more detailed
                market information for reference or to facilitate their
                investment decision.
                <br />
                <img
                  src="/img/home/dbpower.png"
                  alt=""
                  style={{ width: "160px" }}
                />
              </div>
            </ReactTooltip>
            <ReactTooltip
              id="indicator-hotdw-tooltip"
              className="lives-tooltip visible-xs"
              // delayHide={100}
              place="top"
              // overridePosition={(
              //   // { left: number, top: number },
              //   currentEvent,
              //   currentTarget,
              //   node,
              //   place,
              //   desiredPlace,
              //   effect,
              //   offset
              // ) => ({ left: 0, top: 0 })}
              type="warning"
              effect="solid"
              backgroundColor="#F1D031"
              textColor="#000"
              width="70%"
              left="10px"
              top="10px"
            >
              <div style={{ fontStyle: "normal" }}>
                DB Power Online Limited, “HKEX Information Services Limited, its
                holding companies and/or any subsidiaries of such holding
                companies”, China Investment Information Services Limited, third
                party information providers endeavor to ensure the accuracy and
                reliability of the information provided but do not guarantee its
                accuracy or reliability and accept no liability (whether in tort
                or contract or otherwise) for any loss or damage arising from
                any inaccuracies or omissions.
                <br />
                The BMP Service is aimed for investor reference. The BMP only
                provides basic market information for reference purposes and the
                investors should consider if they would need more detailed
                market information for reference or to facilitate their
                investment decision.
                <br />
                <img
                  src="/img/home/dbpower.png"
                  alt=""
                  style={{ width: "160px" }}
                />
              </div>
            </ReactTooltip>
          </div> */}
        </div>
      </div>
    );
  }
  render() {
    if (this.props.liveMatrix) {
      if (this.props.isSti && this.props.isSti.label.indexOf('STI') != -1) {
        return this.renderPage();
      } else {
        return null;
      }
    } else {
      return this.renderHome();
    }
  }
}

class Popovers extends Component {

  render() {

    return (
      <div
        // className="priceUpdateInfo"
        className="indicator-item small"
        data-tip
        data-for="indicator-hotdw-tooltip"
        data-tooltip-id="indicator-hotdw-tooltip"
        style={{
          marginBottom: "20px",
          fontStyle: "italic",
          fontSize: "16px",
        }}
      >
        *Click refresh button for live quote
        <br />
        <span
          className="ttip"
          style={{ color: "#0675C2" }}
          data-target="hotdw"
        >
          HSI and HSTECH data provided by DB Power Online Limited
          <br />
          [Disclaimer]
        </span>
        <ReactTooltip
          id="indicator-hotdw-tooltip"
          className="lives-tooltip hidden-xs"
          delayHide={100}
          place="right"
          offset={{ top: -10, left: -30 }}
          // overridePosition={(
          //   // { left: number, top: number },
          //   currentEvent,
          //   currentTarget,
          //   node,
          //   place,
          //   desiredPlace,
          //   effect,
          //   offset
          // ) => ({ left: 0, top: 0 })}
          type="warning"
          effect="solid"
          backgroundColor="#F1D031"
          textColor="#000"
          width="70%"
          left="10px"
          top="10px"
        >
          <div style={{ fontStyle: "normal" }}>
            DB Power Online Limited, “HKEX Information Services Limited, its
            holding companies and/or any subsidiaries of such holding
            companies”, China Investment Information Services Limited, third
            party information providers endeavor to ensure the accuracy and
            reliability of the information provided but do not guarantee its
            accuracy or reliability and accept no liability (whether in tort
            or contract or otherwise) for any loss or damage arising from
            any inaccuracies or omissions.
            <br />
            The BMP Service is aimed for investor reference. The BMP only
            provides basic market information for reference purposes and the
            investors should consider if they would need more detailed
            market information for reference or to facilitate their
            investment decision.
            <br />
            <img
              src="/img/home/dbpower.png"
              alt=""
              style={{ width: "160px" }}
            />
          </div>
        </ReactTooltip>
        <ReactTooltip
          id="indicator-hotdw-tooltip"
          className="lives-tooltip visible-xs"
          // delayHide={100}
          place="top"
          // overridePosition={(
          //   // { left: number, top: number },
          //   currentEvent,
          //   currentTarget,
          //   node,
          //   place,
          //   desiredPlace,
          //   effect,
          //   offset
          // ) => ({ left: 0, top: 0 })}
          type="warning"
          effect="solid"
          backgroundColor="#F1D031"
          textColor="#000"
          width="70%"
          left="10px"
          top="10px"
        >
          <div style={{ fontStyle: "normal" }}>
            DB Power Online Limited, “HKEX Information Services Limited, its
            holding companies and/or any subsidiaries of such holding
            companies”, China Investment Information Services Limited, third
            party information providers endeavor to ensure the accuracy and
            reliability of the information provided but do not guarantee its
            accuracy or reliability and accept no liability (whether in tort
            or contract or otherwise) for any loss or damage arising from
            any inaccuracies or omissions.
            <br />
            The BMP Service is aimed for investor reference. The BMP only
            provides basic market information for reference purposes and the
            investors should consider if they would need more detailed
            market information for reference or to facilitate their
            investment decision.
            <br />
            <img
              src="/img/home/dbpower.png"
              alt=""
              style={{ width: "160px" }}
            />
          </div>
        </ReactTooltip>
      </div>
    );
  }
}
class HSI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      livedata: null,
    };
    this.interval = null;
    this.abortController = new AbortController();
  }

  componentDidMount() {
    this.onFetchData();
    // this.interval = setInterval(() => this.onFetchData(), this.props.interval);
  }
  getData() {
    this.props.getChildFun()
  }
  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
    this.abortController.abort();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("LiveIndexFuture");
    if ("HSI" in staticData) {
      this.setState({ livedata: staticData["HSI"] });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("LiveIndexFuture")) {
      this.onFetchStaticData();
      return;
    }
    let livedata = null;
    const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=HSI";
    fetch(url, { signal: this.abortController.signal })
      .then((res) => res.json())
      .then(
        (result) => {
          livedata = result;
          this.setState({ livedata });
        },
        (error) => {
          this.setState({ livedata });
        }
      );
  }

  getDatetimeString(timestamp) {
    if (!MQSGUtil.isValidDateString(timestamp)) {
      return "";
    }
    const today = new Date();
    const updatetime = new Date(timestamp);
    let hour = updatetime.getHours();
    let seconds = updatetime.getSeconds();
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let ampm = " AM";
    if (hour > 11) {
      hour = hour - 12;
      ampm = " PM";
    }
    const hourString = hour < 10 ? "0" + hour : hour;
    const min = updatetime.getMinutes();
    const minString = min < 10 ? "0" + min : min;

    const second = today.getSeconds() + ''; // response resolution up to min
    let s1 = '';
    let s2 = '';
    if (second.length == 2) {
      s1 = second.charAt(0) + '';
      if (second.charAt(1) < 5) {
        s2 = '0';
      } else {
        s2 = '5';
      }
    } else {
      s1 = '0';
      if (second.charAt(0) < 5) {
        s2 = '0';
      } else {
        s2 = '5';
      }
    }
    let secondString = s1 + s2;
    // let secondString = second < 10 ? "0" + second : second;
    // [Pending] why need this logic ?
    // if (hour === 16 && min === 31) {
    //   secondString = "00";
    // } 
    return hourString + ":" + minString + ":" + seconds + ampm;
  }

  render() {
    const rowClassName = "row_HSI";
    const source = this.state.livedata;
    const ticker = "HSI";
    const month = MQSGUtil.getData(source, "month");
    const last = MQSGUtil.getFormatPrice(source, "last");
    const chng = MQSGUtil.getData(source, "chng");
    const chngString = MQSGUtil.signedString(chng, "+", "");
    const chngupdown = MQSGUtil.getUpDownClass(chng);
    const pchng = MQSGUtil.getNumber(source, "pchng");
    const pchngString = MQSGUtil.signedString(pchng, "+", "");
    const pchngupdown = MQSGUtil.getUpDownClass(pchng);
    const tickertime = this.getDatetimeString(
      MQSGUtil.getData(source, "stime")
    );

    const column1 = (
      <td className="minW82" style={{ fontSize: '16px' }}>
        <a href={"/tools/underlying/" + ticker}>{ticker}</a>
      </td>
    );
    const column2 = (
      <td rowSpan="2" style={{ fontSize: '16px' }}>
        <img
          className="refresh_index"
          style={{ cursor: "pointer", width: "22px" }}
          src="/img/bullet/refresh.png"
          alt=""
          onClick={() => { this.getData() }}
        />
      </td>
    );
    const column3 = (
      <td rowSpan="2" className="tickertime_HSI minW100" style={{ fontSize: '16px' }}>
        {tickertime}
      </td>
    );
    const column4 = <td className="col_month minW100" style={{ fontSize: '16px' }}>{month}</td>;
    const column5 = <td className={"col_last minW82"} style={{ fontSize: '16px' }}>{last}</td>;
    const column6 = <td className={"col_chng minW82" + chngupdown} style={{ fontSize: '16px' }}>{chngString}</td>;
    const column7 = (
      <td className={"col_pchng minW82" + pchngupdown} style={{ fontSize: '16px' }}>{pchngString}</td>
    );

    return (
      <tr className={rowClassName}>
        {column1}
        {column2}
        {column3}
        {column4}
        {column5}
        {column6}
        {column7}
      </tr>
    );
  }
}

class HSTECH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      livedata: null,
    };
    this.interval = null;
    this.abortController = new AbortController();
  }

  componentDidMount() {
    this.onFetchData();
    // this.interval = setInterval(() => this.onFetchData(), this.props.interval);
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
    this.abortController.abort();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("LiveIndexFuture");
    if ("HTI" in staticData) {
      this.setState({ livedata: staticData["HTI"] });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("LiveIndexFuture")) {
      this.onFetchStaticData();
      return;
    }
    let livedata = null;
    const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=HTI";
    fetch(url, { signal: this.abortController.signal })
      .then((res) => res.json())
      .then(
        (result) => {
          livedata = result;
          this.setState({ livedata });
        },
        (error) => {
          this.setState({ livedata });
        }
      );
  }

  render() {
    const rowClassName = "row_HSTECH";
    const source = this.state.livedata;
    const ticker = "HSTECH";
    const month = MQSGUtil.getData(source, "month");
    const last = MQSGUtil.getFormatPrice(source, "last");
    const chng = MQSGUtil.getData(source, "chng");
    const chngString = MQSGUtil.signedString(chng, "+", "");
    const chngupdown = MQSGUtil.getUpDownClass(chng);
    const pchng = MQSGUtil.getNumber(source, "pchng");
    const pchngString = MQSGUtil.signedString(pchng, "+", "");
    const pchngupdown = MQSGUtil.getUpDownClass(pchng);

    const column1 = (
      <td style={{ fontSize: '16px' }}>
        <a href={"/tools/underlying/" + ticker}>{ticker}</a>
      </td>
    );
    const column4 = <td className="col_month_1" style={{ fontSize: '16px' }}>{month}</td>;
    const column5 = <td className={"col_last_1"} style={{ fontSize: '16px' }}>{last}</td>;
    const column6 = <td className={"col_chng_1" + chngupdown} style={{ fontSize: '16px' }}>{chngString}</td>;
    const column7 = (
      <td className={"col_pchng_1" + pchngupdown} style={{ fontSize: '16px' }}>{pchngString}</td>
    );

    return (
      <tr className={rowClassName} style={{ background: "#F5F6F7" }}>
        {column1}
        {column4}
        {column5}
        {column6}
        {column7}
      </tr>
    );
  }
}

class Others extends Component {
  constructor(props) {
    super(props);
    this.state = {
      livedata: null,
    };
    this.interval = null;
    this.abortController = new AbortController();
    this.prevNetChng = {};
  }

  componentDidMount() {
    this.onFetchData();
    this.interval = setInterval(() => this.onFetchData(), this.props.interval);
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
    this.abortController.abort();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("LiveIndexFuture");
    if ("Others" in staticData) {
      this.setState({ livedata: staticData["Others"] });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("LiveIndexFuture")) {
      this.onFetchStaticData();
      return;
    }
    let livedata = null;
    if (this.props.code !== null) {
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?_t";
      fetch(url, { signal: this.abortController.signal })
        .then((res) => res.json())
        .then(
          (result) => {
            livedata = result;
            this.setState({ livedata });
          },
          (error) => {
            this.setState({ livedata });
          }
        );
    }
  }

  getTickupdown(currval, key) {
    let prevval = null;
    if (currval === null) {
      return "";
    }
    if (key in this.prevNetChng) {
      prevval = this.prevNetChng[key];
    } else {
      this.prevNetChng[key] = currval;
      return "";
    }
    let updown = "";
    if (currval > prevval) {
      updown = "uptick";
    } else if (prevval > currval) {
      updown = "downtick";
    }
    this.prevNetChng[key] = currval;
    return updown;
  }

  getData(key) {
    if (this.state.livedata !== null && key !== null) {
      if (key in this.state.livedata) {
        const data = this.state.livedata[key];
        if (Array.isArray(data)) {
          if (data.length > 0) {
            return data[0];
          }
        } else {
          return data;
        }
      }
    }
    return null;
  }

  getKeys() {
    if (this.state.livedata !== null) {
      if ("keys" in this.state.livedata) {
        const data = this.state.livedata["keys"];
        if (Array.isArray(data)) {
          return data;
        }
      }
    }
    return [];
  }

  getUpdatetime() {
    if (this.state.livedata !== null) {
      if ("update_time" in this.state.livedata) {
        return this.state.livedata["update_time"];
      }
    }
    return "";
  }

  getSymbol(displayname) {
    const symbols = { SSGc1: "SIMSCI", SSGc2: "SIMSCI" };
    if (displayname in symbols) {
      return symbols[displayname];
    }
    return displayname;
  }

  getRow(source, key, updatetime) {
    const displayname = MQSGUtil.getData(source, "dsply_name");
    const symbol = this.getSymbol(displayname);
    const tickertime = updatetime;
    const expiryMonth = MQSGUtil.getData(source, "expiryMonth");
    const bid = MQSGUtil.getData(source, "bid");
    const net = MQSGUtil.getData(source, "net");
    const tickupdown = this.getTickupdown(
      MQSGUtil.getNumber(source, "net", null),
      key
    );
    const rowClassName = "row_" + key + " " + tickupdown;
    const netupdown = MQSGUtil.getUpDownClass(net);
    const netString = MQSGUtil.signedString(net, "", "");
    const pct = MQSGUtil.getData(source, "pct");
    const pctString = MQSGUtil.signedString(pct, "", "");
    const pctupdown = MQSGUtil.getUpDownClass(pct);

    const column1 = (
      <td style={{ fontSize: '16px' }}>
        <a href={"/tools/underlying/" + `${symbol == 'Nikkei' ? 'N225' : symbol}`}>{symbol}</a>
      </td>
    );
    const column2 = <td style={{ fontSize: '16px' }}></td>;
    const column3 = <td className="tickertime" style={{ fontSize: '16px' }}>{tickertime}</td>;
    const column4 = <td className="col_expr" style={{ fontSize: '16px' }}>{expiryMonth}</td>;
    const column5 = <td className="col_bid" style={{ fontSize: '16px' }}>{bid}</td>;
    const column6 = <td className={"col_net " + netupdown} style={{ fontSize: '16px' }}>{netString}</td>;
    const column7 = <td className={"col_pct " + pctupdown} style={{ fontSize: '16px' }}>{pctString}</td>;

    return (
      <tr key={key} className={rowClassName}>
        {column1}
        {column2}
        {column3}
        {column4}
        {column5}
        {column6}
        {column7}
      </tr>
    );
  }

  render() {
    const rics = this.getKeys();
    const updatetime = this.getUpdatetime();
    const rows = [];
    rics.forEach((ric) => {
      const row = this.getRow(this.getData(ric), ric, updatetime);
      rows.push(row);
    });
    return <>{rows}</>;
  }
}

class STI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      livedata: null,
    };
    this.interval = null;
    this.abortController = new AbortController();
    this.prevNetChng = {};
  }

  componentDidMount() {
    this.onFetchData();
    this.interval = setInterval(() => this.onFetchData(), this.props.interval);
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
    this.abortController.abort();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("LiveIndexFuture");
    if ("Others" in staticData) {
      this.setState({ livedata: staticData["Others"] });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("LiveIndexFuture")) {
      this.onFetchStaticData();
      return;
    }
    let livedata = null;
    if (this.props.code !== null) {
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=STI";
      fetch(url, { signal: this.abortController.signal })
        .then((res) => res.json())
        .then(
          (result) => {
            livedata = result;
            this.setState({ livedata });
          },
          (error) => {
            this.setState({ livedata });
          }
        );
    }
  }

  getTickupdown(currval, key) {
    let prevval = null;
    if (currval === null) {
      return "";
    }
    if (key in this.prevNetChng) {
      prevval = this.prevNetChng[key];
    } else {
      this.prevNetChng[key] = currval;
      return "";
    }
    let updown = "";
    if (currval > prevval) {
      updown = "uptick";
    } else if (prevval > currval) {
      updown = "downtick";
    }
    this.prevNetChng[key] = currval;
    return updown;
  }

  getData(key) {
    if (this.state.livedata !== null && key !== null) {
      if (key in this.state.livedata) {
        const data = this.state.livedata[key];
        if (Array.isArray(data)) {
          if (data.length > 0) {
            return data[0];
          }
        } else {
          return data;
        }
      }
    }
    return null;
  }

  getKeys() {
    if (this.state.livedata !== null) {
      if ("keys" in this.state.livedata) {
        const data = this.state.livedata["keys"];
        if (Array.isArray(data)) {
          return data;
        }
      }
    }
    return [];
  }

  getUpdatetime() {
    if (this.state.livedata !== null) {
      if ("update_time" in this.state.livedata) {
        return this.state.livedata["update_time"];
      }
    }
    return "";
  }

  getSymbol(displayname) {
    const symbols = { Nikkei: "N225" };
    if (displayname in symbols) {
      return symbols[displayname];
    }
    return displayname;
  }

  getRow(source, key, updatetime) {
    const displayname = MQSGUtil.getData(source, "dsply_name");
    const symbol = this.getSymbol(displayname);
    const tickertime = updatetime;
    const expiryMonth = MQSGUtil.getData(source, "expiryMonth");
    const bid = MQSGUtil.getData(source, "bid");
    const net = MQSGUtil.getData(source, "net");
    const tickupdown = this.getTickupdown(
      MQSGUtil.getNumber(source, "net", null),
      key
    );
    const rowClassName = "row_" + key + " " + tickupdown;
    const netupdown = MQSGUtil.getUpDownClass(net);
    const netString = MQSGUtil.signedString(net, "", "");
    const pct = MQSGUtil.getData(source, "pct");
    const pctString = MQSGUtil.signedString(pct, "", "");
    const pctupdown = MQSGUtil.getUpDownClass(pct);

    const column1 = (
      <td style={{ fontSize: '16px' }}>
        <a href={"/tools/underlying/" + symbol}>{displayname}</a>
      </td>
    );
    const column2 = <td style={{ fontSize: '16px' }}></td>;
    const column3 = <td className="tickertime" style={{ fontSize: '16px' }}>{tickertime}</td>;
    const column4 = <td className="col_expr" style={{ fontSize: '16px' }}>{expiryMonth}</td>;
    const column5 = <td className="col_bid" style={{ fontSize: '16px' }}>{bid}</td>;
    const column6 = <td className={"col_net " + netupdown} style={{ fontSize: '16px' }}>{netString}</td>;
    const column7 = <td className={"col_pct " + pctupdown} style={{ fontSize: '16px' }}>{pctString}</td>;

    return (
      <tr key={key} className={rowClassName}>
        {column1}
        {column2}
        {column3}
        {column4}
        {column5}
        {column6}
        {column7}
      </tr>
    );
  }

  render() {
    const rics = this.getKeys();
    const updatetime = this.getUpdatetime();
    const rows = [];
    rics.forEach((ric) => {
      const row = this.getRow(this.getData(ric), ric, updatetime);
      rows.push(row);
    });
    return <>{rows}</>;
  }
}

class ZSEAsic1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      livedata: null,
    };
    this.interval = null;
    this.abortController = new AbortController();
    this.prevNetChng = {};
  }

  componentDidMount() {
    this.onFetchData();
    this.interval = setInterval(() => this.onFetchData(), this.props.interval);
  }

  componentWillUnmount() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
    this.abortController.abort();
  }

  onFetchStaticData() {
    const staticData = MQSGUtil.getStaticData("LiveIndexFuture");
    if ("Others" in staticData) {
      this.setState({ livedata: staticData["Others"] });
    }
  }

  onFetchData() {
    if (MQSGUtil.isUsingStaticData("LiveIndexFuture")) {
      this.onFetchStaticData();
      return;
    }
    let livedata = null;
    if (this.props.code !== null) {
      const url = MQSGUtil.getAPIBasePath() + "/LiveIndexJSON?ric=SEA";
      fetch(url, { signal: this.abortController.signal })
        .then((res) => res.json())
        .then(
          (result) => {
            livedata = result;
            this.setState({ livedata });
          },
          (error) => {
            this.setState({ livedata });
          }
        );
    }
  }

  getTickupdown(currval, key) {
    let prevval = null;
    if (currval === null) {
      return "";
    }
    if (key in this.prevNetChng) {
      prevval = this.prevNetChng[key];
    } else {
      this.prevNetChng[key] = currval;
      return "";
    }
    let updown = "";
    if (currval > prevval) {
      updown = "uptick";
    } else if (prevval > currval) {
      updown = "downtick";
    }
    this.prevNetChng[key] = currval;
    return updown;
  }

  getData(key) {
    if (this.state.livedata !== null && key !== null) {
      if (key in this.state.livedata) {
        const data = this.state.livedata[key];
        if (Array.isArray(data)) {
          if (data.length > 0) {
            return data[0];
          }
        } else {
          return data;
        }
      }
    }
    return null;
  }

  getKeys() {
    if (this.state.livedata !== null) {
      if ("keys" in this.state.livedata) {
        const data = this.state.livedata["keys"];
        if (Array.isArray(data)) {
          return data;
        }
      }
    }
    return [];
  }

  getUpdatetime() {
    if (this.state.livedata !== null) {
      if ("update_time" in this.state.livedata) {
        return this.state.livedata["update_time"];
      }
    }
    return "";
  }

  getSymbol(displayname) {
    const symbols = { ZSEAsic1: "SEA", ZSEAsic2: 'SEA' };
    if (displayname in symbols) {
      return symbols[displayname];
    }

    return displayname;
  }

  getRow(source, key, updatetime) {
    const displayname = MQSGUtil.getData(source, "dsply_name");
    const symbol = this.getSymbol(displayname);
    const tickertime = updatetime;
    const expiryMonth = MQSGUtil.getData(source, "expiryMonth");
    const bid = MQSGUtil.getData(source, "bid");
    const net = MQSGUtil.getData(source, "net");
    const tickupdown = this.getTickupdown(
      MQSGUtil.getNumber(source, "net", null),
      key
    );
    const rowClassName = "row_" + key + " " + tickupdown;
    const netupdown = MQSGUtil.getUpDownClass(net);
    const netString = MQSGUtil.signedString(net, "", "");
    const pct = MQSGUtil.getData(source, "pct");
    const pctString = MQSGUtil.signedString(pct, "", "");
    const pctupdown = MQSGUtil.getUpDownClass(pct);

    const column1 = (
      <td style={{ fontSize: '16px' }}>
        <a href={"/tools/underlying/" + symbol}>{symbol}</a>
      </td>
    );
    const column2 = <td style={{ fontSize: '16px' }}></td>;
    const column3 = <td className="tickertime" style={{ fontSize: '16px' }}>{tickertime}</td>;
    const column4 = <td className="col_expr" style={{ fontSize: '16px' }}>{expiryMonth}</td>;
    const column5 = <td className="col_bid" style={{ fontSize: '16px' }}>{bid}</td>;
    const column6 = <td className={"col_net " + netupdown} style={{ fontSize: '16px' }}>{netString}</td>;
    const column7 = <td className={"col_pct " + pctupdown} style={{ fontSize: '16px' }}>{pctString}</td>;

    return (
      <tr key={key} className={rowClassName}>
        {column1}
        {column2}
        {column3}
        {column4}
        {column5}
        {column6}
        {column7}
      </tr>
    );
  }

  render() {
    const rics = this.getKeys();
    const updatetime = this.getUpdatetime();
    const rows = [];
    rics.forEach((ric) => {
      const row = this.getRow(this.getData(ric), ric, updatetime);
      rows.push(row);
    });
    return <>{rows}</>;
  }
}

