
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class warrantsatexpirylasttradingday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div>
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>
                                                Warrants stop trading in the market four trading days before their expiry date. If you haven’t sold the warrant, the issuer will automatically exercise the warrant if it has any remaining value at expiry. You can look up a warrant’s last trading day via
                                                the warrant terms page, <a href="/tools/warrantterms" style={{ color: 'red' }}>click here</a>.
                                            </p>
                                            <div className="imgholder">
                                                <h2 className="title">Warrants Approaching Expiry</h2>
                                                <img src="/img/education/Warrants-Which-Are-Approaching-Expiry.jpg" alt />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/Warrants-Which-Are-Approaching-Expiry.jpg')}>View table</div>
                                            </div>
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>The settlement value for single stock warrants at expiry is based on an average closing price for the underlying share over the 5 days prior to expiry. This is done to reduce the ability for any large expiring
                                                                warrant positions to affect the closing price of the underlying share at expiry. </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div >
        )
    }
}