
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class qualityofamarketmaker extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>The quality and reputation of the market maker is also an important consideration when choosing which warrant to trade. You need to be confident that the market maker will be there to provide consistent pricing throughout the
                                                life of the warrant so that you can enter/exit the trade in a fair and liquid market.</p>
                                            <p>Macquarie has a leading market share in the Singapore warrant market and much of this success is due to the quality of our market making, something we are very proud of.</p>
                                            <div className="imgholder">
                                                <img src="/img/education/creditrisk1.png" style={{ maxWidth: '180px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}