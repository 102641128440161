//https://www.warrants.com.sg/NewsTableServlet?sp=overnightmarkets&start=0&qid=1614586343670

const overnighthigh = {
    "newsList": [
        {
            "id": "6793",
            "en_short": "<p>S&amp;P 500:&nbsp;3,811.15 (-0.5%)</p><p>Eurostoxx:&nbsp;3,636.44 (-1.3%)</p><p>US Crude Futures: USD61.50 (-3.2%)</p>",
            "en_title": "Tech stocks rebound as global bond rout eases",
            "date": "01 Mar 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,811.15 (-0.5%)</p><p>Eurostoxx:&nbsp;3,636.44 (-1.3%)</p><p>US Crude Futures: USD61.50 (-3.2%)</p>",
            "thai_title": "Tech stocks rebound as global bond rout eases"
        },
        {
            "id": "6789",
            "en_short": "<p>S&amp;P 500:&nbsp;3,829.34 (-2.5%)</p><p>Eurostoxx:&nbsp;3,685.28 (-0.6%)</p><p>US Crude Futures: USD63.53 (+0.5%)</p>",
            "en_title": "Wall Street lower amid tech selloff ",
            "date": "26 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,829.34 (-2.5%)</p><p>Eurostoxx:&nbsp;3,685.28 (-0.6%)</p><p>US Crude Futures: USD63.53 (+0.5%)</p>",
            "thai_title": "Wall Street lower amid tech selloff "
        },
        {
            "id": "6785",
            "en_short": "<p>S&amp;P 500:&nbsp;3,925.43 (+1.1%)</p><p>Eurostoxx:&nbsp;3,705.99 (+0.5%)</p><p>US Crude Futures: USD63.22 (+2.5%)</p>",
            "en_title": "Wall Street higher as Fed’s Powell calms inflation fears",
            "date": "25 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,925.43 (+1.1%)</p><p>Eurostoxx:&nbsp;3,705.99 (+0.5%)</p><p>US Crude Futures: USD63.22 (+2.5%)</p>",
            "thai_title": "Wall Street higher as Fed’s Powell calms inflation fears"
        },
        {
            "id": "6781",
            "en_short": "<p>S&amp;P 500:&nbsp;3,881.37 (+0.1%)</p><p>Eurostoxx:&nbsp;3,689.10 (-0.3%)</p><p>US Crude Futures: USD61.67 (-0.1%)</p>",
            "en_title": "US shares rebound on Powell policy remarks",
            "date": "24 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,881.37 (+0.1%)</p><p>Eurostoxx:&nbsp;3,689.10 (-0.3%)</p><p>US Crude Futures: USD61.67 (-0.1%)</p>",
            "thai_title": "US shares rebound on Powell policy remarks"
        },
        {
            "id": "6777",
            "en_short": "<p>S&amp;P 500:&nbsp;3,876.50 (-0.8%)</p><p>Eurostoxx:&nbsp;3,704.96 (-0.2%)</p><p>US Crude Futures: USD61.70 (+4.1%)</p>",
            "en_title": "S&amp;P 500 falls, Nasdaq at three-week low",
            "date": "23 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,876.50 (-0.8%)</p><p>Eurostoxx:&nbsp;3,704.96 (-0.2%)</p><p>US Crude Futures: USD61.70 (+4.1%)</p>",
            "thai_title": "S&amp;P 500 falls, Nasdaq at three-week low"
        },
        {
            "id": "6773",
            "en_short": "<p>S&amp;P 500:&nbsp;3,906.71 (-0.2%)</p><p>Eurostoxx:&nbsp;3,713.46 (+0.9%)</p><p>US Crude Futures: USD59.26 (-2.1%)</p>",
            "en_title": "Wall Street ends flat as investors rotate into cyclicals",
            "date": "22 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,906.71 (-0.2%)</p><p>Eurostoxx:&nbsp;3,713.46 (+0.9%)</p><p>US Crude Futures: USD59.26 (-2.1%)</p>",
            "thai_title": "Wall Street ends flat as investors rotate into cyclicals"
        },
        {
            "id": "6769",
            "en_short": "<p>S&amp;P 500:&nbsp;3,913.97 (-0.4%)</p><p>Eurostoxx:&nbsp;3,680.62 (-0.5%)</p><p>US Crude Futures: USD60.53 (-1.0%)</p>",
            "en_title": "Tech slide, rising jobless claims lead US benchmarks lower",
            "date": "19 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,913.97 (-0.4%)</p><p>Eurostoxx:&nbsp;3,680.62 (-0.5%)</p><p>US Crude Futures: USD60.53 (-1.0%)</p>",
            "thai_title": "Tech slide, rising jobless claims lead US benchmarks lower"
        },
        {
            "id": "6765",
            "en_short": "<p>S&amp;P 500:&nbsp;3,931.33 (-0.0%)</p><p>Eurostoxx:&nbsp;3,699.85 (-0.7%)</p><p>US Crude Futures: USD61.14 (+1.8%)</p>",
            "en_title": "Wall Street finish mixed, tech shares fall",
            "date": "18 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,931.33 (-0.0%)</p><p>Eurostoxx:&nbsp;3,699.85 (-0.7%)</p><p>US Crude Futures: USD61.14 (+1.8%)</p>",
            "thai_title": "Wall Street finish mixed, tech shares fall"
        },
        {
            "id": "6761",
            "en_short": "<p>S&amp;P 500:&nbsp;3,932.59 (-0.1%)</p><p>Eurostoxx:&nbsp;3,726.40 (-0.2%)</p><p>US Crude Futures: USD60.05 (+1.0%)</p>",
            "en_title": "Dow notches record high amid recovery optimism",
            "date": "17 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,932.59 (-0.1%)</p><p>Eurostoxx:&nbsp;3,726.40 (-0.2%)</p><p>US Crude Futures: USD60.05 (+1.0%)</p>",
            "thai_title": "Dow notches record high amid recovery optimism"
        },
        {
            "id": "6757",
            "en_short": "<p>S&amp;P 500:&nbsp;Closed</p><p>Eurostoxx:&nbsp;3,734.20 (+1.0%)</p><p>US Crude Futures: USD60.16 (-0.4%)</p>",
            "en_title": "European stocks rise on commodity gains",
            "date": "16 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;Closed</p><p>Eurostoxx:&nbsp;3,734.20 (+1.0%)</p><p>US Crude Futures: USD60.16 (-0.4%)</p>",
            "thai_title": "European stocks rise on commodity gains"
        },
        {
            "id": "6753",
            "en_short": "<p>S&amp;P 500:&nbsp;3,934.83 (+0.5%)</p><p>Eurostoxx:&nbsp;3,695.61 (+0.7%)</p><p>US Crude Futures: USD59.47 (+2.1%)</p>",
            "en_title": "Nasdaq, S&amp;P 500 log second weekly gain",
            "date": "15 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,934.83 (+0.5%)</p><p>Eurostoxx:&nbsp;3,695.61 (+0.7%)</p><p>US Crude Futures: USD59.47 (+2.1%)</p>",
            "thai_title": "Nasdaq, S&amp;P 500 log second weekly gain"
        },
        {
            "id": "6749",
            "en_short": "<p>S&amp;P 500:&nbsp;3,909.88 (-0.0%)</p><p>Eurostoxx:&nbsp;3,648.37 (-0.4%)</p><p>US Crude Futures: USD58.68 (+0.6%)</p>",
            "en_title": "US stocks end mixed with rally halted",
            "date": "11 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,909.88 (-0.0%)</p><p>Eurostoxx:&nbsp;3,648.37 (-0.4%)</p><p>US Crude Futures: USD58.68 (+0.6%)</p>",
            "thai_title": "US stocks end mixed with rally halted"
        },
        {
            "id": "6745",
            "en_short": "<p>S&amp;P 500:&nbsp;3,911.23 (-0.1%)</p><p>Eurostoxx:&nbsp;3,661.13 (-0.1%)</p><p>US Crude Futures: USD58.36 (+0.7%)</p>",
            "en_title": "S&amp;P 500 halts rally while Nasdaq extends record run to fresh highs",
            "date": "10 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,911.23 (-0.1%)</p><p>Eurostoxx:&nbsp;3,661.13 (-0.1%)</p><p>US Crude Futures: USD58.36 (+0.7%)</p>",
            "thai_title": "S&amp;P 500 halts rally while Nasdaq extends record run to fresh highs"
        },
        {
            "id": "6741",
            "en_short": "<p>S&amp;P 500:&nbsp;3,915.59 (+0.7%)</p><p>Eurostoxx:&nbsp;3,665.51 (+0.3%)</p><p>US Crude Futures: USD57.97 (+2.0%)</p>",
            "en_title": "Wall Street pushes on to record highs with stimulus hopes, vaccine rollout",
            "date": "09 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,915.59 (+0.7%)</p><p>Eurostoxx:&nbsp;3,665.51 (+0.3%)</p><p>US Crude Futures: USD57.97 (+2.0%)</p>",
            "thai_title": "Wall Street pushes on to record highs with stimulus hopes, vaccine rollout"
        },
        {
            "id": "6737",
            "en_short": "<p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"background:white\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"color:#333333\">S&amp;P 500:&nbsp;3,886.83 (+0.4%)</span></span></span></span></p><p style=\"margin-bottom:10px; text-align:start\"><span style=\"font-size:inherit\"><span style=\"background:white\"><span style=\"box-sizing:border-box\"><span style=\"font-variant-ligatures:normal\"><span style=\"text-decoration-thickness:initial\"><span style=\"text-decoration-style:initial\"><span style=\"text-decoration-color:initial\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,655.77 (+0.4%)</span></span></span></span></span></span></span></span></span></p><p style=\"margin-bottom:10px; text-align:start\"><span style=\"font-size:inherit\"><span style=\"background:white\"><span style=\"box-sizing:border-box\"><span style=\"font-variant-ligatures:normal\"><span style=\"text-decoration-thickness:initial\"><span style=\"text-decoration-style:initial\"><span style=\"text-decoration-color:initial\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"color:#333333\">US Crude Futures: USD56.85 (+0.7%)</span></span></span></span></span></span></span></span></span></p>",
            "en_title": "US equities extend gains on stimulus hopes",
            "date": "08 Feb 21",
            "thai_short": "<p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"background:white\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"color:#333333\">S&amp;P 500:&nbsp;3,886.83 (+0.4%)</span></span></span></span></p><p style=\"margin-bottom:10px; text-align:start\"><span style=\"font-size:inherit\"><span style=\"background:white\"><span style=\"box-sizing:border-box\"><span style=\"font-variant-ligatures:normal\"><span style=\"text-decoration-thickness:initial\"><span style=\"text-decoration-style:initial\"><span style=\"text-decoration-color:initial\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,655.77 (+0.4%)</span></span></span></span></span></span></span></span></span></p><p style=\"margin-bottom:10px; text-align:start\"><span style=\"font-size:inherit\"><span style=\"background:white\"><span style=\"box-sizing:border-box\"><span style=\"font-variant-ligatures:normal\"><span style=\"text-decoration-thickness:initial\"><span style=\"text-decoration-style:initial\"><span style=\"text-decoration-color:initial\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"color:#333333\">US Crude Futures: USD56.85 (+0.7%)</span></span></span></span></span></span></span></span></span></p>",
            "thai_title": "US equities extend gains on stimulus hopes"
        },
        {
            "id": "6733",
            "en_short": "<p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">S&amp;P 500:&nbsp;3,871.74 (+1.1%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,642.12 (+0.9%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">US Crude Futures: USD56.46 (+1.4%)</span></span></span></span></p>",
            "en_title": "S&amp;P 500, Nasdaq notches record closing highs",
            "date": "05 Feb 21",
            "thai_short": "<p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">S&amp;P 500:&nbsp;3,871.74 (+1.1%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,642.12 (+0.9%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">US Crude Futures: USD56.46 (+1.4%)</span></span></span></span></p>",
            "thai_title": "S&amp;P 500, Nasdaq notches record closing highs"
        },
        {
            "id": "6729",
            "en_short": "<p>S&amp;P 500:&nbsp;3,830.17 (+0.1%)</p><p>Eurostoxx:&nbsp;3,607.46 (+0.5%)</p><p>US Crude Futures: USD55.69 (+1.7%)</p>",
            "en_title": "Dow and S&amp;P 500 end third straight session in positive territory",
            "date": "04 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,830.17 (+0.1%)</p><p>Eurostoxx:&nbsp;3,607.46 (+0.5%)</p><p>US Crude Futures: USD55.69 (+1.7%)</p>",
            "thai_title": "Dow and S&amp;P 500 end third straight session in positive territory"
        },
        {
            "id": "6725",
            "en_short": "<p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">S&amp;P 500:&nbsp;3,826.31 (+1.4%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,591.47 (+1.7%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">US Crude Futures: USD54.76 (+2.3%)</span></span></span></span></p>",
            "en_title": "US shares post back-to-back gains ",
            "date": "03 Feb 21",
            "thai_short": "<p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">S&amp;P 500:&nbsp;3,826.31 (+1.4%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,591.47 (+1.7%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">US Crude Futures: USD54.76 (+2.3%)</span></span></span></span></p>",
            "thai_title": "US shares post back-to-back gains "
        },
        {
            "id": "6721",
            "en_short": "<p>S&amp;P 500:&nbsp;3,773.86 (+1.6%)</p><p>Eurostoxx:&nbsp;3,530.85 (+1.4%)</p><p>US Crude Futures: USD53.55 (+2.6%)</p>",
            "en_title": "S&amp;P 500 rises most since 24 Nov boosted by tech gains",
            "date": "02 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,773.86 (+1.6%)</p><p>Eurostoxx:&nbsp;3,530.85 (+1.4%)</p><p>US Crude Futures: USD53.55 (+2.6%)</p>",
            "thai_title": "S&amp;P 500 rises most since 24 Nov boosted by tech gains"
        },
        {
            "id": "6717",
            "en_short": "<p>S&amp;P 500:&nbsp;3,714.24 (-1.9%)</p><p>Eurostoxx:&nbsp;3,481.44 (-2.1%)</p><p>US Crude Futures: USD 52.20 (-0.3%)</p>",
            "en_title": "S&amp;P 500 wraps up worst week since October",
            "date": "01 Feb 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,714.24 (-1.9%)</p><p>Eurostoxx:&nbsp;3,481.44 (-2.1%)</p><p>US Crude Futures: USD 52.20 (-0.3%)</p>",
            "thai_title": "S&amp;P 500 wraps up worst week since October"
        },
        {
            "id": "6713",
            "en_short": "<p>S&amp;P 500:&nbsp;3,787.38 (+1.0%)</p><p>Eurostoxx:&nbsp;3,557.04 (+0.6%)</p><p>US Crude Futures: USD52.34 (-1.0%)</p>",
            "en_title": "US equities stage comeback from worst loss since October",
            "date": "29 Jan 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,787.38 (+1.0%)</p><p>Eurostoxx:&nbsp;3,557.04 (+0.6%)</p><p>US Crude Futures: USD52.34 (-1.0%)</p>",
            "thai_title": "US equities stage comeback from worst loss since October"
        },
        {
            "id": "6709",
            "en_short": "<p>S&amp;P 500:&nbsp;3,750.77 (-2.57%)</p><p>Eurostoxx:&nbsp;3,536.38 (-1.57%)</p><p>US Crude Futures: USD52.85 (+0.5%)</p>",
            "en_title": "US indices see worst day in 2021",
            "date": "28 Jan 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,750.77 (-2.57%)</p><p>Eurostoxx:&nbsp;3,536.38 (-1.57%)</p><p>US Crude Futures: USD52.85 (+0.5%)</p>",
            "thai_title": "US indices see worst day in 2021"
        },
        {
            "id": "6705",
            "en_short": "<p>S&amp;P 500:&nbsp;3,849.62 (-0.2%)</p><p>Eurostoxx:&nbsp;3,592.83 (+1.1%)</p><p>US Crude Futures: USD52.61 (-0.3%)</p>",
            "en_title": "US stocks end lower, S&amp;P 500 slip from record levels",
            "date": "27 Jan 21",
            "thai_short": "<p>S&amp;P 500:&nbsp;3,849.62 (-0.2%)</p><p>Eurostoxx:&nbsp;3,592.83 (+1.1%)</p><p>US Crude Futures: USD52.61 (-0.3%)</p>",
            "thai_title": "US stocks end lower, S&amp;P 500 slip from record levels"
        },
        {
            "id": "6701",
            "en_short": "<p>S<span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">&amp;P 500:&nbsp;3,855.36 (+0.4%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,553.14 (-1.4%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">US Crude Futures: USD52.77 (+1.0%)</span></span></span></span></p>",
            "en_title": "Nasdaq, S&amp;P 500 close at record highs",
            "date": "26 Jan 21",
            "thai_short": "<p>S<span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">&amp;P 500:&nbsp;3,855.36 (+0.4%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">Eurostoxx:&nbsp;3,553.14 (-1.4%)</span></span></span></span></p><p style=\"margin-bottom:10px\"><span style=\"font-size:11pt\"><span style=\"font-family:Calibri,sans-serif\"><span style=\"font-size:12.0pt\"><span style=\"color:#333333\">US Crude Futures: USD52.77 (+1.0%)</span></span></span></span></p>",
            "thai_title": "Nasdaq, S&amp;P 500 close at record highs"
        }
    ],
    "noOfPage": 164
}

export default overnighthigh;