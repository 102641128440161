
import React, { Component } from 'react';
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class riskmatrix extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css"/>*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>This chart summarizes how the expiry date of a warrant and its gearing level affects the risk level of the warrant.</p>
                                            <div className="imgholder">
                                                <img src="/img/education/Risks_matrix.jpg" />
                                            </div>
                                            <p>Generally, the shorter dated the warrants, the higher the risk of the warrant as there is less time remaining to reach your target price.</p>
                                            <p>Warrants with higher gearing also carry higher risk as they require a larger movement in the underlying price for the warrant to have value remaining at expiry, indicated by OTM (out-of-the-money warrants) in the matrix.</p>
                                            <p>Warrants with longer time to expiry and those that are deeper in-the-money (ITM) in the matrix are generally regarded as lower risk. Warrants that have further expiry dates allow more time for stocks to reach the target price
                                                and hence less time decay, while ITM warrants have lower gearing levels.</p>
                                            <p>It should be remembered that the higher the risk typically translates to a higher potential return and vice versa.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}