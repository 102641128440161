const dweduData = {
    "seminar": [
        {
            "id": 313,
            "th_time": "",
            "th_title": "",
            "en_time": "06:30 pm - 07:30 pm",
            "en_title": "Mastering the 3 key tools in warrant trading",
            "date": "04-Mar"
        }
    ]
}

export default dweduData;