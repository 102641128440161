
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";

export default class exerciseratio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <div className="videotxt hidden-xxs hidden-xs">
                                                <p>In this episode, we look at the term warrants per share. The term warrants per share is needed when calculating a number of different measures used when trading warrants including delta, sensitivity and the breakeven price
                                                    at expiry. So it’s a very handy tool to have in your pocket. It’s also pretty simple to understand.</p>
                                            </div>
                                            <p>
                                                The “Warrants Per Share” number (also known as WPS) shows the number of warrants needed to exchange for one underlying share/index futures at expiry.</p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/WPS-and-Conversion-Ratio.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/WPS-and-Conversion-Ratio.jpg')}>View table</div>
                                            </div>
                                            <p> The purpose of the WPS number is really just to break down the warrant into smaller parts, so that a warrant with a price of say $1 can be broken down into smaller parts each worth $0.20 by using a WPS of 5.<br /><br /> When calculating
                                                many indicators or ratios, such as delta, premium, etc you often need to take into account the WPS number. However, throughout this website, we have already done this for you and provided all the indicators you will need
                                                to make your decisions.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/WPS-Example-Table.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/WPS-Example-Table.jpg')}>View table</div>
                                            </div>
                                            <p>
                                                Let's take a look at an example to understand the effects of different WPS. In the above table, all three warrants in the below table have the same exercise price, expiry date etc. with WPS the only difference amongst them.<br /><br />                                Notice that warrant A has a WPS of 3 and therefore requires 3 warrants to exchange for one underlying share at expiry. It has a price of $0.20. Warrant C on the other hand has a WPS of 12 and requires 12 warrants to exercise
                                                at expiry, four times more than Warrant A. Therefore it costs exactly 4 times less at $0.05.
                                                <br /><br />
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/WPS-Example-Table-02.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/WPS-Example-Table-02.jpg')}>View table</div>
                                            </div>
                                            <p>The delta per warrant for Warrants A, B and C will also be different as they have different warrants per share figures. Remember that all three warrants are exactly the same, with the same exercise price and expiry. The delta
                                                for all three is therefore the same. However, the delta per warrant for Warrant A is higher as a result of being divided by a smaller warrants per share number of 3 compared to Warrant B for which the delta will be divided
                                                by 6. Warrant C will have the lowest delta per warrant of 5 per cent.
                                                <br /><br /> In the earlier tutorial on delta, we learnt that it estimates the change in the warrant price for a small change in the underlying share. So in this case, we can use the delta per warrants figure of the three warrants
                                                to calculate how they move given a 10 cents move in DBS.<br /><br /> Multiply the 20 per cent delta per warrant of Warrant A by the 10 cents move in DBS shares and you’ll find that Warrant A will move by 2 cents. Do the same
                                                for B and C and you’ll find they will experience a smaller move compared to A for the same price move in DBS shares. This is because their delta per warrant is lower. In percentage terms, however, the change will be the
                                                same across all warrants.<br /><br /> The important point to understand is that while warrants with the lower WPS will have a higher delta per warrant, they are also more expensive and therefore move the same in percentage
                                                terms. Meaning, if you purchased any of the above warrants, the return you will receive in percentage terms will be exactly the same. In this example, each of the warrants will move approximately 10% for a $0.10 move in
                                                DBS shares.<br /><br /> So while WPS is important for calculating ratios such as delta, sensitivity, breakeven price at expiry etc., it should not be a major factor in the decision making process for most warrant investors
                                                who are choosing a warrant.<br /><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}