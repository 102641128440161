
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import ShowImgpopup from "./showImgpopup/index.jsx";
import Footer from "./footer/index.jsx";

export default class breakevenprice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgUrl: ''
        };
    }
    open(v) {
        Alert.open(true)
    }
    openImg(url) {
        this.setState({ imgUrl: url });
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        const { imgUrl } = this.state
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <div className="videotxt hidden-xxs hidden-xs">
                                                <p>In previous sessions, we’ve been learning about some of the most important concepts when it comes to investing in warrants, terms such as delta, warrants per share and warrant sensitivity. In this tutorial we expand on
                                                    these ideas in this session by exploring two more terms that are useful for warrant traders to understand, breakeven price and premium.</p>
                                            </div>
                                            <p>
                                                Price level at expiry above (below) which the investor will make a profit in the case of a call (put). Calculation of the breakeven price is useful only if you were planning to hold the warrant till expiry, otherwise, breakeven is simply the price you
                                                bought the warrant at.<br /><br /> There are actually two ways to think about the breakeven price. If you’re not planning to hold the warrant until it expires, the breakeven price will be the
                                                price you paid for the warrant initially. If you are holding on to the warrant until it expires, the breakeven price is the price of the underlying shares at expiry such that the warrant is at the same price you had purchased
                                                it for initially.<br /><br /> To understand how the breakeven price at expiry is calculated, let’s look at an example.
                                            </p>
                                            {/* <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/AIA_put_warrant_example.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/AIA_put_warrant_example.jpg')}>View table</div>
                                            </div>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/example_breakevenprice.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/example_breakevenprice.jpg')}>View table</div>
                                            </div>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/formula_breakevenprice.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/formula_breakevenprice.jpg')}>View table</div>
                                            </div> */}


                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/slide1.jpg" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/slide1.jpg')}>View table</div>
                                            </div>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/Slide2.JPG" />
                                                <div className="visible-xs color_0675C2" onClick={() => this.openImg('/img/education/Slide2.JPG')}>View table</div>
                                            </div>

                                            <p>Let’s assume you are planning to hold this call warrant until its expiry date. The exercise price of the warrant is $11.00, the warrants per share is 7. When you first purchased the warrant, it was 10 cents while the underlying
                                                share price was trading at $11.20.
                                            </p>
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful Tips on how to use breakeven</h2>
                                                    <ul>
                                                        <li>
                                                            <p>Another simple way to calculate breakeven is to look at time value or premium. Premium represents the amount that the underlying share has to increase by in order for the warrants to be at break-even at expiry.
                                                                This will be explained in further detail in the next section.</p>
                                                        </li>
                                                        <li>
                                                            <p>Remember that the breakeven price is the price that the share needs to reach AT EXPIRY for the warrant to equal the same price that you paid to purchase the warrant. The breakeven price for the periods before
                                                                expiry will be lower. </p>
                                                        </li>
                                                        {/*                        <li><p>Warrant sensitivity is important for short term day traders and investors interested in the small/short term movements in a warrant. For all other investors, delta is likely to be more useful.</p></li>*/}
                                                        <li>
                                                            <p>You can view the breakeven for all warrants via the <a href="/tools/warrantterms" style={{ color: 'red' }}>Warrant Terms page</a>.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowImgpopup
                    {...this.props}
                    imgUrl={imgUrl}
                    cbClose={() => {
                        this.setState({ imgUrl: '' })
                    }}
                />
            </div>
        )
    }
}