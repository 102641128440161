const seminarlist = {
    "seminarList": [
        {
            "expired": false,
            "th_time": "",
            "th_title": "",
            "th_poster": "N",
            "en_time": "06:30 pm - 07:30 pm",
            "link": "",
            "en_poster": "Y",
            "date": "04 March 2021",
            "contact_info": "",
            "id": 313,
            "en_content": "<p>This webinar is&nbsp;intended to help investors get started with trading warrants! It will be presented by Mr Darren Zeng, of Macquarie Warrants Singapore.&nbsp;</p>\r\n\r\n<p>Topics to be covered:&nbsp;</p>\r\n\r\n<ul>\r\n\t<li>Recognise the key features of warrants</li>\r\n\t<li>Identify opportunities to trade with warrants</li>\r\n\t<li>Matching an investor&#39;s profile to warrant type</li>\r\n\t<li>Access to free warrant tools</li>\r\n\t<li>Selecting warrants to trade LIVE!</li>\r\n</ul>\r\n\r\n<p><br />\r\nEvent time: 06.30PM - 07.30PM<br />\r\n<br />\r\nNote: Webinar link will only be sent to successful registrations nearing the webinar date.</p>\r\n",
            "th_venue": "",
            "en_title": "Mastering the 3 key tools in warrant trading",
            "th_content": " ",
            "en_venue": "",
            "demo": true
        }
    ]
}

export default seminarlist;