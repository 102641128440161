
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class indexputwarrantexample extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <p >
                                            In this case, the HSI May futures have expired at a level lower than 20,000. To work out the expiry value of this put warrant, you minus the lower 18,800 settlement value of HSI May futures from the higher exercise price of of 20,000 and divide this by
                                            the warrants per share figure of 1000. Assuming a Hong Kong dollar/Singapore dollar exchange rate of 0.160, the value of this put warrant at expiry is 19.2 Singapore cents.
                                        </p>
                                        <p >
                                            If the HSI May futures final settlement level is at the exercise level of 20,000 or higher, there would be no cash settlement.
                                        </p>
                                        <div className="contentbox">
                                            <h2>EXAMPLE OF A HSI PUT WARRANT EXPIRING IN MAY</h2>
                                            <div className="exampleholder">
                                                <div className="examplebox">
                                                    <div className="col">
                                                        <div className="lbl">Exercise Level:&nbsp;&nbsp;</div>
                                                        <div className="val">20,000</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="lbl">Warrants per share:&nbsp;&nbsp;</div>
                                                        <div className="val">1,000 warrants per share</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="lbl">Settlement Level of HSI May future:&nbsp;&nbsp;</div>
                                                        <div className="val" >18,800</div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="lbl">FX Rate:&nbsp;&nbsp;</div>
                                                        <div className="val" >0.16 (SGD per HKD)</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="formulaholder">
                                                <div className="formulabox" >
                                                    <div className="leqt">
                                                        <table cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 39 }}>
                                                                        <b>Cash Settlement Amount</b>:
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="reqt">
                                                        <table className="fraction" cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>(20,000 - 18,800)</td>
                                                                    <td rowSpan={2}>&nbsp;=&nbsp;HKD1.20</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="upper_line">1,000</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="reqt" style={{ display: 'block' }}>
                                                        <table cellSpacing={0} cellPadding={0}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 39 }}>HKD1.20 x 0.16(FX Rate) = SGD0.192</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>Index warrants expiry dates are typically matched to the expiry date of the relevant futures contract. This is done because the issuer will use the futures contract to hedge the warrants, then both the warrants
                                                                and futures can be settled based on the same closing value in the futures.
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p>For index warrants, there is no 5 day averaging as with warrants over single stocks</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}