// 20210303171300
// https://www.warrants.com.sg/DocumentJSON?m=0&qid=1614762759843

const termsheetData = {
    "termsheet": [
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "15-11-2021",
        "dsply_nmll": "OCBC Bk MB ePW211115",
        "list_date": "09-12-2020",
        "underlying_ticker": "O39",
        "list_date_f": "09 Dec 20",
        "underlying_name": "OCBC",
        "conv_ratio": "12",
        "ACVOL_1": "0",
        "ticker": "JWRW",
        "ric": "OCML_t26.SI",
        "maturity_f": "15 Nov 21",
        "exercise_price": "10.000"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "15-11-2021",
        "dsply_nmll": "OCBC Bk MB eCW211115",
        "list_date": "09-12-2020",
        "underlying_ticker": "O39",
        "list_date_f": "09 Dec 20",
        "underlying_name": "OCBC",
        "conv_ratio": "12",
        "ACVOL_1": "0",
        "ticker": "VYWW",
        "ric": "OCML_t23.SI",
        "maturity_f": "15 Nov 21",
        "exercise_price": "9.800"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "12-11-2021",
        "dsply_nmll": "OCBC Bk MB eCW211112",
        "list_date": "09-12-2020",
        "underlying_ticker": "O39",
        "list_date_f": "09 Dec 20",
        "underlying_name": "OCBC",
        "conv_ratio": "12",
        "ACVOL_1": "132,000",
        "ticker": "WZ1W",
        "ric": "OCML_t25.SI",
        "maturity_f": "12 Nov 21",
        "exercise_price": "10.800"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "12-11-2021",
        "dsply_nmll": "OCBC Bk MB ePW211112",
        "list_date": "09-12-2020",
        "underlying_ticker": "O39",
        "list_date_f": "09 Dec 20",
        "underlying_name": "OCBC",
        "conv_ratio": "12",
        "ACVOL_1": "0",
        "ticker": "95JW",
        "ric": "OCML_t24.SI",
        "maturity_f": "12 Nov 21",
        "exercise_price": "9.000"
      },
      {
        "underlying_ric": "YAZG.SI",
        "maturity": "01-11-2021",
        "dsply_nmll": "YangzijiMBeCW211101",
        "list_date": "09-02-2021",
        "underlying_ticker": "BS6",
        "list_date_f": "09 Feb 21",
        "underlying_name": "YANGZIJIANG",
        "conv_ratio": "5",
        "ACVOL_1": "0",
        "ticker": "FOHW",
        "ric": "YZML_tx.SI",
        "maturity_f": "01 Nov 21",
        "exercise_price": "1.150"
      },
      {
        "underlying_ric": "STEL.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "SingtelMBeCW211018",
        "list_date": "03-12-2020",
        "underlying_ticker": "Z74",
        "list_date_f": "03 Dec 20",
        "underlying_name": "SINGTEL",
        "conv_ratio": "5",
        "ACVOL_1": "0",
        "ticker": "59XW",
        "ric": "SEML_t7.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "2.800"
      },
      {
        "underlying_ric": "CATL.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "CapitalaMBeCW211018",
        "list_date": "03-12-2020",
        "underlying_ticker": "C31",
        "list_date_f": "03 Dec 20",
        "underlying_name": "CAPLAND",
        "conv_ratio": "5",
        "ACVOL_1": "0",
        "ticker": "MHJW",
        "ric": "CAML_t4.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "3.800"
      },
      {
        "underlying_ric": "WLIL.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "Wilmar MB eCW211018",
        "list_date": "07-12-2020",
        "underlying_ticker": "F34",
        "list_date_f": "07 Dec 20",
        "underlying_name": "WILMAR INTERNATI",
        "conv_ratio": "8",
        "ACVOL_1": "334,000",
        "ticker": "NLGW",
        "ric": "WLML_t9.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "4.800"
      },
      {
        "underlying_ric": "KPLM.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "KepCorpMBeCW211018",
        "list_date": "07-12-2020",
        "underlying_ticker": "BN4",
        "list_date_f": "07 Dec 20",
        "underlying_name": "KEPCORP",
        "conv_ratio": "8",
        "ACVOL_1": "0",
        "ticker": "WFEW",
        "ric": "KPML_t10.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "5.700"
      },
      {
        "underlying_ric": "SGXL.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "SGX MB eCW211018",
        "list_date": "07-12-2020",
        "underlying_ticker": "S68",
        "list_date_f": "07 Dec 20",
        "underlying_name": "SGX",
        "conv_ratio": "10",
        "ACVOL_1": "43,000",
        "ticker": "ESYW",
        "ric": "SNML_t4.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "9.800"
      },
      {
        "underlying_ric": "SGXL.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "SGX MB ePW211018",
        "list_date": "07-12-2020",
        "underlying_ticker": "S68",
        "list_date_f": "07 Dec 20",
        "underlying_name": "SGX",
        "conv_ratio": "10",
        "ACVOL_1": "250,000",
        "ticker": "UJGW",
        "ric": "SNML_t3.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "8.500"
      },
      {
        "underlying_ric": "CMDG.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "ComfortDel MBeCW211018",
        "list_date": "03-12-2020",
        "underlying_ticker": "C52",
        "list_date_f": "03 Dec 20",
        "underlying_name": "COMFORTDELGRO LD",
        "conv_ratio": "5",
        "ACVOL_1": "0",
        "ticker": "MSHW",
        "ric": "CMML_t2.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "2.000"
      },
      {
        "underlying_ric": "MACT.SI",
        "maturity": "18-10-2021",
        "dsply_nmll": "MapleComTr MB eCW211018",
        "list_date": "03-12-2020",
        "underlying_ticker": "N2IU",
        "list_date_f": "03 Dec 20",
        "underlying_name": "MAPLETREE COMMER",
        "conv_ratio": "5",
        "ACVOL_1": "0",
        "ticker": "TRGW",
        "ric": "MAML_t.SI",
        "maturity_f": "18 Oct 21",
        "exercise_price": "2.200"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "08-10-2021",
        "dsply_nmll": "UOB MB eCW211008",
        "list_date": "27-11-2020",
        "underlying_ticker": "U11",
        "list_date_f": "27 Nov 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "RABW",
        "ric": "UOML_t29.SI",
        "maturity_f": "08 Oct 21",
        "exercise_price": "28.000"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "08-10-2021",
        "dsply_nmll": "UOB MB ePW211008",
        "list_date": "27-11-2020",
        "underlying_ticker": "U11",
        "list_date_f": "27 Nov 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "MPPW",
        "ric": "UOML_t30.SI",
        "maturity_f": "08 Oct 21",
        "exercise_price": "23.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "08-10-2021",
        "dsply_nmll": "DBS MB eCW211008",
        "list_date": "26-11-2020",
        "underlying_ticker": "D05",
        "list_date_f": "26 Nov 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "125,000",
        "ticker": "OFHW",
        "ric": "DBML_t34.SI",
        "maturity_f": "08 Oct 21",
        "exercise_price": "29.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "08-10-2021",
        "dsply_nmll": "DBS MB ePW211008",
        "list_date": "26-11-2020",
        "underlying_ticker": "D05",
        "list_date_f": "26 Nov 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "RWXW",
        "ric": "DBML_t35.SI",
        "maturity_f": "08 Oct 21",
        "exercise_price": "24.000"
      },
      {
        "underlying_ric": "0175.HK",
        "maturity": "05-10-2021",
        "dsply_nmll": "Geely MB eCW211005",
        "list_date": "15-01-2021",
        "underlying_ticker": "GEELY AUTO",
        "list_date_f": "15 Jan 21",
        "underlying_name": "GEELY AUTO",
        "conv_ratio": "7",
        "ACVOL_1": "60,000",
        "ticker": "XQDW",
        "ric": "GEML_te.SI",
        "maturity_f": "05 Oct 21",
        "exercise_price": "45.000"
      },
      {
        "underlying_ric": "0175.HK",
        "maturity": "05-10-2021",
        "dsply_nmll": "Geely MB ePW211005",
        "list_date": "15-01-2021",
        "underlying_ticker": "GEELY AUTO",
        "list_date_f": "15 Jan 21",
        "underlying_name": "GEELY AUTO",
        "conv_ratio": "7",
        "ACVOL_1": "0",
        "ticker": "VZGW",
        "ric": "GEML_tf.SI",
        "maturity_f": "05 Oct 21",
        "exercise_price": "30.000"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "04-10-2021",
        "dsply_nmll": "UOB MB eCW211004",
        "list_date": "27-11-2020",
        "underlying_ticker": "U11",
        "list_date_f": "27 Nov 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "KVKW",
        "ric": "UOML_t28.SI",
        "maturity_f": "04 Oct 21",
        "exercise_price": "26.000"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "01-10-2021",
        "dsply_nmll": "UOB MB ePW211001",
        "list_date": "27-11-2020",
        "underlying_ticker": "U11",
        "list_date_f": "27 Nov 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "VIFW",
        "ric": "UOML_t32.SI",
        "maturity_f": "01 Oct 21",
        "exercise_price": "25.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "01-10-2021",
        "dsply_nmll": "DBS MB eCW211001",
        "list_date": "26-11-2020",
        "underlying_ticker": "D05",
        "list_date_f": "26 Nov 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "390,000",
        "ticker": "9WJW",
        "ric": "DBML_t33.SI",
        "maturity_f": "01 Oct 21",
        "exercise_price": "27.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "01-10-2021",
        "dsply_nmll": "DBS MB ePW211001",
        "list_date": "26-11-2020",
        "underlying_ticker": "D05",
        "list_date_f": "26 Nov 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "KEDW",
        "ric": "DBML_t36.SI",
        "maturity_f": "01 Oct 21",
        "exercise_price": "26.000"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "01-10-2021",
        "dsply_nmll": "UOB MB eCW211001",
        "list_date": "27-11-2020",
        "underlying_ticker": "U11",
        "list_date_f": "27 Nov 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "208,000",
        "ticker": "XIIW",
        "ric": "UOML_t31.SI",
        "maturity_f": "01 Oct 21",
        "exercise_price": "24.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-09-2021",
        "dsply_nmll": "STI 2800MBeCW210930",
        "list_date": "08-12-2020",
        "underlying_ticker": "STI",
        "list_date_f": "08 Dec 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "UNCW",
        "ric": "STML_t40.SI",
        "maturity_f": "30 Sep 21",
        "exercise_price": "2,800.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-09-2021",
        "dsply_nmll": "STI 3050MBeCW210930",
        "list_date": "08-12-2020",
        "underlying_ticker": "STI",
        "list_date_f": "08 Dec 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "V6OW",
        "ric": "STML_t44.SI",
        "maturity_f": "30 Sep 21",
        "exercise_price": "3,050.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-09-2021",
        "dsply_nmll": "STI 3300MBeCW210930",
        "list_date": "08-12-2020",
        "underlying_ticker": "STI",
        "list_date_f": "08 Dec 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "LZTW",
        "ric": "STML_t43.SI",
        "maturity_f": "30 Sep 21",
        "exercise_price": "3,300.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-09-2021",
        "dsply_nmll": "STI 2500MBePW210930",
        "list_date": "08-12-2020",
        "underlying_ticker": "STI",
        "list_date_f": "08 Dec 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "JUUW",
        "ric": "STML_t42.SI",
        "maturity_f": "30 Sep 21",
        "exercise_price": "2,500.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-09-2021",
        "dsply_nmll": "STI 2750MBePW210930",
        "list_date": "08-12-2020",
        "underlying_ticker": "STI",
        "list_date_f": "08 Dec 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "JUPW",
        "ric": "STML_t41.SI",
        "maturity_f": "30 Sep 21",
        "exercise_price": "2,750.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-09-2021",
        "dsply_nmll": "STI 3000MBePW210930",
        "list_date": "08-12-2020",
        "underlying_ticker": "STI",
        "list_date_f": "08 Dec 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "PCEW",
        "ric": "STML_t45.SI",
        "maturity_f": "30 Sep 21",
        "exercise_price": "3,000.000"
      },
      {
        "underlying_ric": "TOPG.SI",
        "maturity": "17-09-2021",
        "dsply_nmll": "Top Glove MB eCW210917",
        "list_date": "17-12-2020",
        "underlying_ticker": "BVA",
        "list_date_f": "17 Dec 20",
        "underlying_name": "TOP GLOVE CORPOR",
        "conv_ratio": "10",
        "ACVOL_1": "300,000",
        "ticker": "1P7W",
        "ric": "TOPG_ta.SI",
        "maturity_f": "17 Sep 21",
        "exercise_price": "3.150"
      },
      {
        "underlying_ric": "AEM.SI",
        "maturity": "17-09-2021",
        "dsply_nmll": "AEM MB eCW210917",
        "list_date": "17-12-2020",
        "underlying_ticker": "AWX",
        "list_date_f": "17 Dec 20",
        "underlying_name": "AEM HOLDINGS LTD",
        "conv_ratio": "12",
        "ACVOL_1": "100,000",
        "ticker": "VKEW",
        "ric": "AEML_tm.SI",
        "maturity_f": "17 Sep 21",
        "exercise_price": "4.200"
      },
      {
        "underlying_ric": "3690.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Meituan MB ePW210705",
        "list_date": "13-11-2020",
        "underlying_ticker": "MEITUAN-W",
        "list_date_f": "13 Nov 20",
        "underlying_name": "MEITUAN-W",
        "conv_ratio": "45",
        "ACVOL_1": "20,000",
        "ticker": "MXTW",
        "ric": "MEML_tc.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "260.000"
      },
      {
        "underlying_ric": "0700.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "TencentMBePW210705",
        "list_date": "13-11-2020",
        "underlying_ticker": "TENCENT",
        "list_date_f": "13 Nov 20",
        "underlying_name": "TENCENT",
        "conv_ratio": "60",
        "ACVOL_1": "70,000",
        "ticker": "WVDW",
        "ric": "TCML_t19.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "500.000"
      },
      {
        "underlying_ric": "9988.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Alibaba MB eCW210705",
        "list_date": "17-12-2020",
        "underlying_ticker": "BABA-SW",
        "list_date_f": "17 Dec 20",
        "underlying_name": "BABA-SW",
        "conv_ratio": "45",
        "ACVOL_1": "170,000",
        "ticker": "R42W",
        "ric": "ALML_th.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "300.000"
      },
      {
        "underlying_ric": "0175.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Geely MB eCW210705",
        "list_date": "16-11-2020",
        "underlying_ticker": "GEELY AUTO",
        "list_date_f": "16 Nov 20",
        "underlying_name": "GEELY AUTO",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "RUHW",
        "ric": "GEML_td.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "28.000"
      },
      {
        "underlying_ric": "9988.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Alibaba MB ePW210705",
        "list_date": "17-12-2020",
        "underlying_ticker": "BABA-SW",
        "list_date_f": "17 Dec 20",
        "underlying_name": "BABA-SW",
        "conv_ratio": "45",
        "ACVOL_1": "40,000",
        "ticker": "QBDW",
        "ric": "ALML_tg.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "240.000"
      },
      {
        "underlying_ric": "9618.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "JD MB eCW210705",
        "list_date": "16-11-2020",
        "underlying_ticker": "JD-SW",
        "list_date_f": "16 Nov 20",
        "underlying_name": "JD-SW",
        "conv_ratio": "45",
        "ACVOL_1": "80,300",
        "ticker": "OPSW",
        "ric": "JDML_tb.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "400.000"
      },
      {
        "underlying_ric": "3690.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Meituan MB eCW210705",
        "list_date": "16-11-2020",
        "underlying_ticker": "MEITUAN-W",
        "list_date_f": "16 Nov 20",
        "underlying_name": "MEITUAN-W",
        "conv_ratio": "45",
        "ACVOL_1": "880,800",
        "ticker": "YC8W",
        "ric": "MEML_td.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "400.000"
      },
      {
        "underlying_ric": "9618.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "JD MB ePW210705",
        "list_date": "23-11-2020",
        "underlying_ticker": "JD-SW",
        "list_date_f": "23 Nov 20",
        "underlying_name": "JD-SW",
        "conv_ratio": "45",
        "ACVOL_1": "0",
        "ticker": "DVDW",
        "ric": "JDML_tc.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "280.000"
      },
      {
        "underlying_ric": "1810.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "XIAOMI MBeCW210705",
        "list_date": "18-12-2020",
        "underlying_ticker": "XIAOMI-W",
        "list_date_f": "18 Dec 20",
        "underlying_name": "XIAOMI-W",
        "conv_ratio": "10",
        "ACVOL_1": "160,000",
        "ticker": "WAIW",
        "ric": "XIML_t16.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "30.000"
      },
      {
        "underlying_ric": "2382.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Sunny MB eCW210705",
        "list_date": "18-12-2020",
        "underlying_ticker": "SUNNY OPTICAL",
        "list_date_f": "18 Dec 20",
        "underlying_name": "SUNNY OPTICAL",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "WAVW",
        "ric": "SUML_tc.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "160.000"
      },
      {
        "underlying_ric": "1211.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "BYD MB eCW210705",
        "list_date": "18-12-2020",
        "underlying_ticker": "BYD COMPANY",
        "list_date_f": "18 Dec 20",
        "underlying_name": "BYD COMPANY",
        "conv_ratio": "35",
        "ACVOL_1": "0",
        "ticker": "TMHW",
        "ric": "BYML_te.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "200.000"
      },
      {
        "underlying_ric": "0700.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "TencentMBeCW210705",
        "list_date": "18-12-2020",
        "underlying_ticker": "TENCENT",
        "list_date_f": "18 Dec 20",
        "underlying_name": "TENCENT",
        "conv_ratio": "60",
        "ACVOL_1": "300",
        "ticker": "PHDW",
        "ric": "TCML_t20.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "700.000"
      },
      {
        "underlying_ric": "2382.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Sunny MB ePW210705",
        "list_date": "18-12-2020",
        "underlying_ticker": "SUNNY OPTICAL",
        "list_date_f": "18 Dec 20",
        "underlying_name": "SUNNY OPTICAL",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "8P7W",
        "ric": "SUML_td.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "120.000"
      },
      {
        "underlying_ric": "1211.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "BYD MB ePW210705",
        "list_date": "18-12-2020",
        "underlying_ticker": "BYD COMPANY",
        "list_date_f": "18 Dec 20",
        "underlying_name": "BYD COMPANY",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "YBMW",
        "ric": "BYML_tf.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "150.000"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "05-07-2021",
        "dsply_nmll": "OCBC Bk MB ePW210705",
        "list_date": "01-12-2020",
        "underlying_ticker": "O39",
        "list_date_f": "01 Dec 20",
        "underlying_name": "OCBC",
        "conv_ratio": "10",
        "ACVOL_1": "20,000",
        "ticker": "9I5W",
        "ric": "OCML_t21.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "9.800"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "05-07-2021",
        "dsply_nmll": "UOB MB ePW210705",
        "list_date": "01-12-2020",
        "underlying_ticker": "U11",
        "list_date_f": "01 Dec 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "20,000",
        "ticker": "YWRW",
        "ric": "UOML_t33.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "22.000"
      },
      {
        "underlying_ric": "1211.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "BYD MB eCW210705A",
        "list_date": "15-01-2021",
        "underlying_ticker": "BYD COMPANY",
        "list_date_f": "15 Jan 21",
        "underlying_name": "BYD COMPANY",
        "conv_ratio": "45",
        "ACVOL_1": "240,600",
        "ticker": "MGVW",
        "ric": "BYML_tg.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "320.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "05-07-2021",
        "dsply_nmll": "DBS MB ePW210705",
        "list_date": "01-12-2020",
        "underlying_ticker": "D05",
        "list_date_f": "01 Dec 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "869,900",
        "ticker": "QYLW",
        "ric": "DBML_t37.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "24.500"
      },
      {
        "underlying_ric": "1211.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "BYD MB ePW210705A",
        "list_date": "15-01-2021",
        "underlying_ticker": "BYD COMPANY",
        "list_date_f": "15 Jan 21",
        "underlying_name": "BYD COMPANY",
        "conv_ratio": "45",
        "ACVOL_1": "0",
        "ticker": "VRJW",
        "ric": "BYML_th.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "250.000"
      },
      {
        "underlying_ric": "0175.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "Geely MB ePW210705",
        "list_date": "13-11-2020",
        "underlying_ticker": "GEELY AUTO",
        "list_date_f": "13 Nov 20",
        "underlying_name": "GEELY AUTO",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "OCCW",
        "ric": "GEML_tc.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "16.000"
      },
      {
        "underlying_ric": "1810.HK",
        "maturity": "05-07-2021",
        "dsply_nmll": "XIAOMI MBePW210705",
        "list_date": "13-11-2020",
        "underlying_ticker": "XIAOMI-W",
        "list_date_f": "13 Nov 20",
        "underlying_name": "XIAOMI-W",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "UKKW",
        "ric": "XIML_t15.SI",
        "maturity_f": "05 Jul 21",
        "exercise_price": "20.000"
      },
      {
        "underlying_ric": "UTOS.SI",
        "maturity": "01-07-2021",
        "dsply_nmll": "UOL MB eCW210701",
        "list_date": "30-11-2020",
        "underlying_ticker": "U14",
        "list_date_f": "30 Nov 20",
        "underlying_name": "UOL GROUP LIMITE",
        "conv_ratio": "10",
        "ACVOL_1": "20,000",
        "ticker": "ORGW",
        "ric": "ULML_t.SI",
        "maturity_f": "01 Jul 21",
        "exercise_price": "8.500"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "01-07-2021",
        "dsply_nmll": "OCBC Bk MB ePW210701",
        "list_date": "13-11-2020",
        "underlying_ticker": "O39",
        "list_date_f": "13 Nov 20",
        "underlying_name": "OCBC",
        "conv_ratio": "12",
        "ACVOL_1": "0",
        "ticker": "EUEW",
        "ric": "OCML_t19.SI",
        "maturity_f": "01 Jul 21",
        "exercise_price": "8.800"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "01-07-2021",
        "dsply_nmll": "UOB MB ePW210701",
        "list_date": "13-11-2020",
        "underlying_ticker": "U11",
        "list_date_f": "13 Nov 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "X0RW",
        "ric": "UOML_t27.SI",
        "maturity_f": "01 Jul 21",
        "exercise_price": "20.000"
      },
      {
        "underlying_ric": "GENS.SI",
        "maturity": "01-07-2021",
        "dsply_nmll": "GentingSMBeCW210701",
        "list_date": "28-12-2020",
        "underlying_ticker": "G13",
        "list_date_f": "28 Dec 20",
        "underlying_name": "GENTINGSIN",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "XJLW",
        "ric": "GTML_te.SI",
        "maturity_f": "01 Jul 21",
        "exercise_price": "0.950"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "01-07-2021",
        "dsply_nmll": "DBS MB ePW210701",
        "list_date": "13-11-2020",
        "underlying_ticker": "D05",
        "list_date_f": "13 Nov 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "YY9W",
        "ric": "DBML_t32.SI",
        "maturity_f": "01 Jul 21",
        "exercise_price": "22.000"
      },
      {
        "underlying_ric": "SIAL.SI",
        "maturity": "01-07-2021",
        "dsply_nmll": "SIA MB eCW210701",
        "list_date": "28-12-2020",
        "underlying_ticker": "C6L",
        "list_date_f": "28 Dec 20",
        "underlying_name": "SINGAPORE AIRLIN",
        "conv_ratio": "8",
        "ACVOL_1": "0",
        "ticker": "GIJW",
        "ric": "SLML_ta.SI",
        "maturity_f": "01 Jul 21",
        "exercise_price": "5.500"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-06-2021",
        "dsply_nmll": "STI 2900MBeCW210630",
        "list_date": "25-11-2020",
        "underlying_ticker": "STI",
        "list_date_f": "25 Nov 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "PXKW",
        "ric": "STML_t37.SI",
        "maturity_f": "30 Jun 21",
        "exercise_price": "2,900.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-06-2021",
        "dsply_nmll": "STI 3100MBeCW210630",
        "list_date": "25-11-2020",
        "underlying_ticker": "STI",
        "list_date_f": "25 Nov 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "EZEW",
        "ric": "STML_t38.SI",
        "maturity_f": "30 Jun 21",
        "exercise_price": "3,100.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-06-2021",
        "dsply_nmll": "STI 2650MBeCW210630",
        "list_date": "06-11-2020",
        "underlying_ticker": "STI",
        "list_date_f": "06 Nov 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "90,000",
        "ticker": "XINW",
        "ric": "STML_t34.SI",
        "maturity_f": "30 Jun 21",
        "exercise_price": "2,650.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-06-2021",
        "dsply_nmll": "STI 2600MBePW210630",
        "list_date": "25-11-2020",
        "underlying_ticker": "STI",
        "list_date_f": "25 Nov 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "VVZW",
        "ric": "STML_t39.SI",
        "maturity_f": "30 Jun 21",
        "exercise_price": "2,600.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-06-2021",
        "dsply_nmll": "STI 2400MBePW210630",
        "list_date": "06-11-2020",
        "underlying_ticker": "STI",
        "list_date_f": "06 Nov 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "OSTW",
        "ric": "STML_t35.SI",
        "maturity_f": "30 Jun 21",
        "exercise_price": "2,400.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "30-06-2021",
        "dsply_nmll": "STI 2800MBePW210630",
        "list_date": "25-11-2020",
        "underlying_ticker": "STI",
        "list_date_f": "25 Nov 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "YZHW",
        "ric": "STML_t36.SI",
        "maturity_f": "30 Jun 21",
        "exercise_price": "2,800.000"
      },
      {
        "underlying_ric": "HHTIc1",
        "maturity": "29-06-2021",
        "dsply_nmll": "HSTECH 14000MBeCW210629",
        "list_date": "22-02-2021",
        "underlying_ticker": "HSTECH",
        "list_date_f": "22 Feb 21",
        "underlying_name": "HANG SENG TECH INDEX",
        "conv_ratio": "800",
        "ACVOL_1": "14,000",
        "ticker": "ICYW",
        "ric": "HSML_t220.SI",
        "maturity_f": "29 Jun 21",
        "exercise_price": "14,000.000"
      },
      {
        "underlying_ric": "HHTIc1",
        "maturity": "29-06-2021",
        "dsply_nmll": "HSTECH 8000MBePW210629",
        "list_date": "22-02-2021",
        "underlying_ticker": "HSTECH",
        "list_date_f": "22 Feb 21",
        "underlying_name": "HANG SENG TECH INDEX",
        "conv_ratio": "800",
        "ACVOL_1": "0",
        "ticker": "V6EW",
        "ric": "HSML_t221.SI",
        "maturity_f": "29 Jun 21",
        "exercise_price": "8,000.000"
      },
      {
        "underlying_ric": "ESc1",
        "maturity": "18-06-2021",
        "dsply_nmll": "S&P 3800MBeCW210618",
        "list_date": "10-12-2020",
        "underlying_ticker": "S&P500",
        "list_date_f": "10 Dec 20",
        "underlying_name": "S&P500",
        "conv_ratio": "1,000",
        "ACVOL_1": "0",
        "ticker": "RUUW",
        "ric": "SXML_t26.SI",
        "maturity_f": "18 Jun 21",
        "exercise_price": "3,800.000"
      },
      {
        "underlying_ric": "ESc1",
        "maturity": "18-06-2021",
        "dsply_nmll": "S&P 4200MBeCW210618",
        "list_date": "10-12-2020",
        "underlying_ticker": "S&P500",
        "list_date_f": "10 Dec 20",
        "underlying_name": "S&P500",
        "conv_ratio": "1,000",
        "ACVOL_1": "0",
        "ticker": "GTJW",
        "ric": "SXML_t25.SI",
        "maturity_f": "18 Jun 21",
        "exercise_price": "4,200.000"
      },
      {
        "underlying_ric": "ESc1",
        "maturity": "18-06-2021",
        "dsply_nmll": "S&P 3000MBePW210618",
        "list_date": "10-12-2020",
        "underlying_ticker": "S&P500",
        "list_date_f": "10 Dec 20",
        "underlying_name": "S&P500",
        "conv_ratio": "1,000",
        "ACVOL_1": "0",
        "ticker": "XYPW",
        "ric": "SXML_t24.SI",
        "maturity_f": "18 Jun 21",
        "exercise_price": "3,000.000"
      },
      {
        "underlying_ric": "ESc1",
        "maturity": "18-06-2021",
        "dsply_nmll": "S&P 3400MBePW210618",
        "list_date": "10-12-2020",
        "underlying_ticker": "S&P500",
        "list_date_f": "10 Dec 20",
        "underlying_name": "S&P500",
        "conv_ratio": "1,000",
        "ACVOL_1": "60,000",
        "ticker": "OKBW",
        "ric": "SXML_t23.SI",
        "maturity_f": "18 Jun 21",
        "exercise_price": "3,400.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "11-06-2021",
        "dsply_nmll": "NKY 31000MBeCW210611",
        "list_date": "29-12-2020",
        "underlying_ticker": "N225",
        "list_date_f": "29 Dec 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "LXLW",
        "ric": "NIML_t26.SI",
        "maturity_f": "11 Jun 21",
        "exercise_price": "31,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "11-06-2021",
        "dsply_nmll": "NKY 20000MBePW210611",
        "list_date": "29-12-2020",
        "underlying_ticker": "N225",
        "list_date_f": "29 Dec 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "HASW",
        "ric": "NIML_t27.SI",
        "maturity_f": "11 Jun 21",
        "exercise_price": "20,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "11-06-2021",
        "dsply_nmll": "NKY 23000MBePW210611",
        "list_date": "29-12-2020",
        "underlying_ticker": "N225",
        "list_date_f": "29 Dec 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "XDQW",
        "ric": "NIML_t28.SI",
        "maturity_f": "11 Jun 21",
        "exercise_price": "23,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "11-06-2021",
        "dsply_nmll": "NKY 26000MBePW210611",
        "list_date": "29-12-2020",
        "underlying_ticker": "N225",
        "list_date_f": "29 Dec 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "EOGW",
        "ric": "NIML_t23.SI",
        "maturity_f": "11 Jun 21",
        "exercise_price": "26,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "11-06-2021",
        "dsply_nmll": "NKY 29000MBePW210611",
        "list_date": "29-12-2020",
        "underlying_ticker": "N225",
        "list_date_f": "29 Dec 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "9AFW",
        "ric": "NIML_t25.SI",
        "maturity_f": "11 Jun 21",
        "exercise_price": "29,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "11-06-2021",
        "dsply_nmll": "NKY 25000MBeCW210611",
        "list_date": "29-12-2020",
        "underlying_ticker": "N225",
        "list_date_f": "29 Dec 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "ZALW",
        "ric": "NIML_t22.SI",
        "maturity_f": "11 Jun 21",
        "exercise_price": "25,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "11-06-2021",
        "dsply_nmll": "NKY 28000MBeCW210611",
        "list_date": "29-12-2020",
        "underlying_ticker": "N225",
        "list_date_f": "29 Dec 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "TCFW",
        "ric": "NIML_t24.SI",
        "maturity_f": "11 Jun 21",
        "exercise_price": "28,000.000"
      },
      {
        "underlying_ric": "IFAS.SI",
        "maturity": "01-06-2021",
        "dsply_nmll": "IFAST MB eCW210601",
        "list_date": "30-11-2020",
        "underlying_ticker": "AIY",
        "list_date_f": "30 Nov 20",
        "underlying_name": "IFAST CORPORATIO",
        "conv_ratio": "5",
        "ACVOL_1": "1,100",
        "ticker": "KTRW",
        "ric": "IFML_tu.SI",
        "maturity_f": "01 Jun 21",
        "exercise_price": "4.200"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "28-05-2021",
        "dsply_nmll": "HSI 26400MBePW210528",
        "list_date": "11-02-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "11 Feb 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "14,094,700",
        "ticker": "EGAW",
        "ric": "HSML_t214.SI",
        "maturity_f": "28 May 21",
        "exercise_price": "26,400.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "28-05-2021",
        "dsply_nmll": "HSI 28000MBePW210528",
        "list_date": "08-01-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "08 Jan 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "1HZW",
        "ric": "HSML_t207.SI",
        "maturity_f": "28 May 21",
        "exercise_price": "28,000.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "28-05-2021",
        "dsply_nmll": "HSI 24800MBePW210528",
        "list_date": "08-01-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "08 Jan 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "7,600,000",
        "ticker": "TPCW",
        "ric": "HSML_t210.SI",
        "maturity_f": "28 May 21",
        "exercise_price": "24,800.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "28-05-2021",
        "dsply_nmll": "HSI 31200MBeCW210528",
        "list_date": "08-01-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "08 Jan 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "6,537,700",
        "ticker": "Z7PW",
        "ric": "HSML_t211.SI",
        "maturity_f": "28 May 21",
        "exercise_price": "31,200.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "28-05-2021",
        "dsply_nmll": "HSI 26600MBeCW210528",
        "list_date": "08-01-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "08 Jan 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "OKCW",
        "ric": "HSML_t209.SI",
        "maturity_f": "28 May 21",
        "exercise_price": "26,600.000"
      },
      {
        "underlying_ric": "AEMN.SI",
        "maturity": "24-05-2021",
        "dsply_nmll": "AscendasReit MBeCW210524",
        "list_date": "01-12-2020",
        "underlying_ticker": "A17U",
        "list_date_f": "01 Dec 20",
        "underlying_name": "ASCEN REIT",
        "conv_ratio": "6",
        "ACVOL_1": "0",
        "ticker": "RQOW",
        "ric": "AEML_tl.SI",
        "maturity_f": "24 May 21",
        "exercise_price": "3.600"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "14-05-2021",
        "dsply_nmll": "OCBC Bk MB eCW210514",
        "list_date": "01-12-2020",
        "underlying_ticker": "O39",
        "list_date_f": "01 Dec 20",
        "underlying_name": "OCBC",
        "conv_ratio": "10",
        "ACVOL_1": "0",
        "ticker": "GWWW",
        "ric": "OCML_t22.SI",
        "maturity_f": "14 May 21",
        "exercise_price": "11.500"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "14-05-2021",
        "dsply_nmll": "UOB MB eCW210514",
        "list_date": "01-12-2020",
        "underlying_ticker": "U11",
        "list_date_f": "01 Dec 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "ELVW",
        "ric": "UOML_t34.SI",
        "maturity_f": "14 May 21",
        "exercise_price": "27.000"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "10-05-2021",
        "dsply_nmll": "OCBC Bk MB eCW210510",
        "list_date": "16-11-2020",
        "underlying_ticker": "O39",
        "list_date_f": "16 Nov 20",
        "underlying_name": "OCBC",
        "conv_ratio": "10",
        "ACVOL_1": "810,000",
        "ticker": "LQFW",
        "ric": "OCML_t20.SI",
        "maturity_f": "10 May 21",
        "exercise_price": "10.500"
      },
      {
        "underlying_ric": "CTDM.SI",
        "maturity": "07-05-2021",
        "dsply_nmll": "CityDev MB eCW210507",
        "list_date": "30-11-2020",
        "underlying_ticker": "C09",
        "list_date_f": "30 Nov 20",
        "underlying_name": "CITY DEVELOPMENT",
        "conv_ratio": "10",
        "ACVOL_1": "20,000",
        "ticker": "LQXW",
        "ric": "CTML_tt.SI",
        "maturity_f": "07 May 21",
        "exercise_price": "9.000"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "07-05-2021",
        "dsply_nmll": "OCBC Bk MB eCW210507",
        "list_date": "09-12-2020",
        "underlying_ticker": "O39",
        "list_date_f": "09 Dec 20",
        "underlying_name": "OCBC",
        "conv_ratio": "12",
        "ACVOL_1": "0",
        "ticker": "ZRDW",
        "ric": "OCML_t27.SI",
        "maturity_f": "07 May 21",
        "exercise_price": "9.500"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "06-05-2021",
        "dsply_nmll": "UOB MB eCW210506",
        "list_date": "06-11-2020",
        "underlying_ticker": "U11",
        "list_date_f": "06 Nov 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "PWBW",
        "ric": "UOML_t26.SI",
        "maturity_f": "06 May 21",
        "exercise_price": "20.000"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "03-05-2021",
        "dsply_nmll": "UOB MB eCW210503",
        "list_date": "10-12-2020",
        "underlying_ticker": "U11",
        "list_date_f": "10 Dec 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "20,000",
        "ticker": "V8DW",
        "ric": "UOML_t35.SI",
        "maturity_f": "03 May 21",
        "exercise_price": "23.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "03-05-2021",
        "dsply_nmll": "DBS MB eCW210503",
        "list_date": "01-12-2020",
        "underlying_ticker": "D05",
        "list_date_f": "01 Dec 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "677,300",
        "ticker": "HRZW",
        "ric": "DBML_t38.SI",
        "maturity_f": "03 May 21",
        "exercise_price": "28.500"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 26200MBePW210429",
        "list_date": "16-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "16 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "8,240,000",
        "ticker": "TNSW",
        "ric": "HSML_t205.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "26,200.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 27000MBePW210429",
        "list_date": "16-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "16 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "3,538,000",
        "ticker": "8XWW",
        "ric": "HSML_t199.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "27,000.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 30800MBeCW210429",
        "list_date": "11-02-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "11 Feb 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "6,927,000",
        "ticker": "X5OW",
        "ric": "HSML_t216.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "30,800.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 31600MBeCW210429",
        "list_date": "18-02-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "18 Feb 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "1,254,000",
        "ticker": "KKAW",
        "ric": "HSML_t217.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "31,600.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 32400MBeCW210429",
        "list_date": "18-02-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "18 Feb 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "3,917,800",
        "ticker": "LPQW",
        "ric": "HSML_t218.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "32,400.000"
      },
      {
        "underlying_ric": "HHTIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSTECH 12300MBeCW210429",
        "list_date": "22-02-2021",
        "underlying_ticker": "HSTECH",
        "list_date_f": "22 Feb 21",
        "underlying_name": "HANG SENG TECH INDEX",
        "conv_ratio": "800",
        "ACVOL_1": "0",
        "ticker": "IQTW",
        "ric": "HSML_t219.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "12,300.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 28600MBePW210429",
        "list_date": "08-01-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "08 Jan 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "4,166,100",
        "ticker": "CVCW",
        "ric": "HSML_t206.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "28,600.000"
      },
      {
        "underlying_ric": "HHTIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSTECH 9300MBePW210429",
        "list_date": "22-02-2021",
        "underlying_ticker": "HSTECH",
        "list_date_f": "22 Feb 21",
        "underlying_name": "HANG SENG TECH INDEX",
        "conv_ratio": "800",
        "ACVOL_1": "0",
        "ticker": "YF7W",
        "ric": "HSML_t222.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "9,300.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 27600MBeCW210429",
        "list_date": "16-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "16 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "GJDW",
        "ric": "HSML_t200.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "27,600.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 28400MBeCW210429",
        "list_date": "16-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "16 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "SGAW",
        "ric": "HSML_t201.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "28,400.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 29200MBeCW210429",
        "list_date": "16-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "16 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "1,080,000",
        "ticker": "DZBW",
        "ric": "HSML_t202.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "29,200.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 30000MBeCW210429",
        "list_date": "16-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "16 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "12,470,000",
        "ticker": "XLDW",
        "ric": "HSML_t203.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "30,000.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "29-04-2021",
        "dsply_nmll": "HSI 25400MBePW210429",
        "list_date": "16-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "16 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "410,200",
        "ticker": "FMPW",
        "ric": "HSML_t204.SI",
        "maturity_f": "29 Apr 21",
        "exercise_price": "25,400.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "07-04-2021",
        "dsply_nmll": "DBS MB eCW210407",
        "list_date": "10-06-2020",
        "underlying_ticker": "D05",
        "list_date_f": "10 Jun 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "457,900",
        "ticker": "EYOW",
        "ric": "DBML_t31.SI",
        "maturity_f": "07 Apr 21",
        "exercise_price": "26.000"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "07-04-2021",
        "dsply_nmll": "UOB MB eCW210407",
        "list_date": "10-06-2020",
        "underlying_ticker": "U11",
        "list_date_f": "10 Jun 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "300,000",
        "ticker": "FSXW",
        "ric": "UOML_t25.SI",
        "maturity_f": "07 Apr 21",
        "exercise_price": "24.770"
      },
      {
        "underlying_ric": "3690.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "Meituan MB ePW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "MEITUAN-W",
        "list_date_f": "05 Oct 20",
        "underlying_name": "MEITUAN-W",
        "conv_ratio": "35",
        "ACVOL_1": "0",
        "ticker": "YLAW",
        "ric": "MEML_ta.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "200.000"
      },
      {
        "underlying_ric": "DBSM.SI",
        "maturity": "05-04-2021",
        "dsply_nmll": "DBS MB eCW210405",
        "list_date": "20-05-2020",
        "underlying_ticker": "D05",
        "list_date_f": "20 May 20",
        "underlying_name": "DBS",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "WRAW",
        "ric": "DBML_t30.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "21.000"
      },
      {
        "underlying_ric": "9988.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "AlibabaMBeCW210405",
        "list_date": "22-10-2020",
        "underlying_ticker": "BABA-SW",
        "list_date_f": "22 Oct 20",
        "underlying_name": "BABA-SW",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "1M7W",
        "ric": "ALML_tf.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "350.000"
      },
      {
        "underlying_ric": "UOBH.SI",
        "maturity": "05-04-2021",
        "dsply_nmll": "UOB MB eCW210405",
        "list_date": "20-05-2020",
        "underlying_ticker": "U11",
        "list_date_f": "20 May 20",
        "underlying_name": "UOB",
        "conv_ratio": "18",
        "ACVOL_1": "0",
        "ticker": "SMOW",
        "ric": "UOML_t24.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "20.807"
      },
      {
        "underlying_ric": "9988.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "AlibabaMBePW210405",
        "list_date": "22-10-2020",
        "underlying_ticker": "BABA-SW",
        "list_date_f": "22 Oct 20",
        "underlying_name": "BABA-SW",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "X7RW",
        "ric": "ALML_te.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "250.000"
      },
      {
        "underlying_ric": "1810.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "XIAOMI MBeCW210405",
        "list_date": "13-07-2020",
        "underlying_ticker": "XIAOMI-W",
        "list_date_f": "13 Jul 20",
        "underlying_name": "XIAOMI-W",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "FWDW",
        "ric": "XIML_t11.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "20.000"
      },
      {
        "underlying_ric": "0175.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "Geely MB eCW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "GEELY AUTO",
        "list_date_f": "05 Oct 20",
        "underlying_name": "GEELY AUTO",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "RQUW",
        "ric": "GEML_tb.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "18.000"
      },
      {
        "underlying_ric": "1810.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "XIAOMI MBePW210405",
        "list_date": "13-07-2020",
        "underlying_ticker": "XIAOMI-W",
        "list_date_f": "13 Jul 20",
        "underlying_name": "XIAOMI-W",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "UDWW",
        "ric": "XIML_t12.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "13.000"
      },
      {
        "underlying_ric": "1211.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "BYD MB eCW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "BYD COMPANY",
        "list_date_f": "05 Oct 20",
        "underlying_name": "BYD COMPANY",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "VHUW",
        "ric": "BYML_tb.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "140.000"
      },
      {
        "underlying_ric": "2382.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "Sunny MB eCW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "SUNNY OPTICAL",
        "list_date_f": "05 Oct 20",
        "underlying_name": "SUNNY OPTICAL",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "9T9W",
        "ric": "SUML_tb.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "140.000"
      },
      {
        "underlying_ric": "3690.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "Meituan MB eCW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "MEITUAN-W",
        "list_date_f": "05 Oct 20",
        "underlying_name": "MEITUAN-W",
        "conv_ratio": "35",
        "ACVOL_1": "0",
        "ticker": "JAOW",
        "ric": "MEML_tb.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "300.000"
      },
      {
        "underlying_ric": "0175.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "Geely MB ePW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "GEELY AUTO",
        "list_date_f": "05 Oct 20",
        "underlying_name": "GEELY AUTO",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "XZ4W",
        "ric": "GEML_ta.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "12.000"
      },
      {
        "underlying_ric": "1211.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "BYD MB ePW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "BYD COMPANY",
        "list_date_f": "05 Oct 20",
        "underlying_name": "BYD COMPANY",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "RUQW",
        "ric": "BYML_ta.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "98.000"
      },
      {
        "underlying_ric": "2382.HK",
        "maturity": "05-04-2021",
        "dsply_nmll": "Sunny MB ePW210405",
        "list_date": "05-10-2020",
        "underlying_ticker": "SUNNY OPTICAL",
        "list_date_f": "05 Oct 20",
        "underlying_name": "SUNNY OPTICAL",
        "conv_ratio": "25",
        "ACVOL_1": "0",
        "ticker": "PDGW",
        "ric": "SUML_ta.SI",
        "maturity_f": "05 Apr 21",
        "exercise_price": "100.000"
      },
      {
        "underlying_ric": "WLIL.SI",
        "maturity": "31-03-2021",
        "dsply_nmll": "Wilmar MB eCW210331",
        "list_date": "19-12-2019",
        "underlying_ticker": "F34",
        "list_date_f": "19 Dec 19",
        "underlying_name": "WILMAR INTERNATI",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "KSCW",
        "ric": "WLML_t7.SI",
        "maturity_f": "31 Mar 21",
        "exercise_price": "4.500"
      },
      {
        "underlying_ric": "STEL.SI",
        "maturity": "31-03-2021",
        "dsply_nmll": "SingtelMBeCW210331",
        "list_date": "19-12-2019",
        "underlying_ticker": "Z74",
        "list_date_f": "19 Dec 19",
        "underlying_name": "SINGTEL",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "YBNW",
        "ric": "SEML_t16.SI",
        "maturity_f": "31 Mar 21",
        "exercise_price": "3.800"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "31-03-2021",
        "dsply_nmll": "STI 2550MBeCW210331",
        "list_date": "29-09-2020",
        "underlying_ticker": "STI",
        "list_date_f": "29 Sep 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "8C5W",
        "ric": "STML_t33.SI",
        "maturity_f": "31 Mar 21",
        "exercise_price": "2,550.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "31-03-2021",
        "dsply_nmll": "STI 2300MBePW210331",
        "list_date": "29-07-2020",
        "underlying_ticker": "STI",
        "list_date_f": "29 Jul 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "WVEW",
        "ric": "STML_t31.SI",
        "maturity_f": "31 Mar 21",
        "exercise_price": "2,300.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "31-03-2021",
        "dsply_nmll": "STI 2500MBePW210331",
        "list_date": "29-07-2020",
        "underlying_ticker": "STI",
        "list_date_f": "29 Jul 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "DFAW",
        "ric": "STML_t32.SI",
        "maturity_f": "31 Mar 21",
        "exercise_price": "2,500.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "31-03-2021",
        "dsply_nmll": "STI 2800MBeCW210331",
        "list_date": "29-07-2020",
        "underlying_ticker": "STI",
        "list_date_f": "29 Jul 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "URDW",
        "ric": "STML_t29.SI",
        "maturity_f": "31 Mar 21",
        "exercise_price": "2,800.000"
      },
      {
        "underlying_ric": ".STI",
        "maturity": "31-03-2021",
        "dsply_nmll": "STI 3000MBeCW210331",
        "list_date": "29-07-2020",
        "underlying_ticker": "STI",
        "list_date_f": "29 Jul 20",
        "underlying_name": "STI",
        "conv_ratio": "2,500",
        "ACVOL_1": "0",
        "ticker": "VSBW",
        "ric": "STML_t30.SI",
        "maturity_f": "31 Mar 21",
        "exercise_price": "3,000.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 29600MBeCW210330",
        "list_date": "11-02-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "11 Feb 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "12,628,800",
        "ticker": "5N8W",
        "ric": "HSML_t215.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "29,600.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 28200MBePW210330",
        "list_date": "11-02-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "11 Feb 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "17,026,000",
        "ticker": "9PCW",
        "ric": "HSML_t213.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "28,200.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 30400MBeCW210330",
        "list_date": "08-01-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "08 Jan 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "20,855,700",
        "ticker": "HGKW",
        "ric": "HSML_t212.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "30,400.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 27200MBeCW210330",
        "list_date": "15-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "15 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "W6JW",
        "ric": "HSML_t195.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "27,200.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 27400MBePW210330",
        "list_date": "08-01-2021",
        "underlying_ticker": "HSI",
        "list_date_f": "08 Jan 21",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "9,314,700",
        "ticker": "EEKW",
        "ric": "HSML_t208.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "27,400.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 28000MBeCW210330",
        "list_date": "15-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "15 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "OMCW",
        "ric": "HSML_t194.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "28,000.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 28800MBeCW210330",
        "list_date": "15-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "15 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "560,000",
        "ticker": "QHEW",
        "ric": "HSML_t198.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "28,800.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 25800MBePW210330",
        "list_date": "15-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "15 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "KWBW",
        "ric": "HSML_t197.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "25,800.000"
      },
      {
        "underlying_ric": "HSIc1",
        "maturity": "30-03-2021",
        "dsply_nmll": "HSI 26600MBePW210330",
        "list_date": "15-12-2020",
        "underlying_ticker": "HSI",
        "list_date_f": "15 Dec 20",
        "underlying_name": "HSI",
        "conv_ratio": "1,200",
        "ACVOL_1": "0",
        "ticker": "NMKW",
        "ric": "HSML_t196.SI",
        "maturity_f": "30 Mar 21",
        "exercise_price": "26,600.000"
      },
      {
        "underlying_ric": "ESc1",
        "maturity": "19-03-2021",
        "dsply_nmll": "S&P 3950MBeCW210319",
        "list_date": "22-10-2020",
        "underlying_ticker": "S&P500",
        "list_date_f": "22 Oct 20",
        "underlying_name": "S&P500",
        "conv_ratio": "1,000",
        "ACVOL_1": "1,000",
        "ticker": "VQEW",
        "ric": "SXML_t21.SI",
        "maturity_f": "19 Mar 21",
        "exercise_price": "3,950.000"
      },
      {
        "underlying_ric": "ESc1",
        "maturity": "19-03-2021",
        "dsply_nmll": "S&P 2950MBePW210319",
        "list_date": "29-10-2020",
        "underlying_ticker": "S&P500",
        "list_date_f": "29 Oct 20",
        "underlying_name": "S&P500",
        "conv_ratio": "1,000",
        "ACVOL_1": "0",
        "ticker": "DOHW",
        "ric": "SXML_t22.SI",
        "maturity_f": "19 Mar 21",
        "exercise_price": "2,950.000"
      },
      {
        "underlying_ric": "ESc1",
        "maturity": "19-03-2021",
        "dsply_nmll": "S&P 2700MBePW210319",
        "list_date": "17-09-2020",
        "underlying_ticker": "S&P500",
        "list_date_f": "17 Sep 20",
        "underlying_name": "S&P500",
        "conv_ratio": "1,000",
        "ACVOL_1": "0",
        "ticker": "IQZW",
        "ric": "SXML_t20.SI",
        "maturity_f": "19 Mar 21",
        "exercise_price": "2,700.000"
      },
      {
        "underlying_ric": "SGXL.SI",
        "maturity": "15-03-2021",
        "dsply_nmll": "SGX MB eCW210315",
        "list_date": "11-09-2020",
        "underlying_ticker": "S68",
        "list_date_f": "11 Sep 20",
        "underlying_name": "SGX",
        "conv_ratio": "10",
        "ACVOL_1": "200,000",
        "ticker": "9QJW",
        "ric": "SNML_t2.SI",
        "maturity_f": "15 Mar 21",
        "exercise_price": "9.500"
      },
      {
        "underlying_ric": "SGXL.SI",
        "maturity": "15-03-2021",
        "dsply_nmll": "SGX MB ePW210315",
        "list_date": "11-09-2020",
        "underlying_ticker": "S68",
        "list_date_f": "11 Sep 20",
        "underlying_name": "SGX",
        "conv_ratio": "10",
        "ACVOL_1": "0",
        "ticker": "RXPW",
        "ric": "SNML_t1.SI",
        "maturity_f": "15 Mar 21",
        "exercise_price": "8.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "12-03-2021",
        "dsply_nmll": "NKY 30000MBeCW210312",
        "list_date": "10-09-2020",
        "underlying_ticker": "N225",
        "list_date_f": "10 Sep 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "YTXW",
        "ric": "NIML_t18.SI",
        "maturity_f": "12 Mar 21",
        "exercise_price": "30,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "12-03-2021",
        "dsply_nmll": "NKY 19000MBePW210312",
        "list_date": "10-09-2020",
        "underlying_ticker": "N225",
        "list_date_f": "10 Sep 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "WG8W",
        "ric": "NIML_t20.SI",
        "maturity_f": "12 Mar 21",
        "exercise_price": "19,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "12-03-2021",
        "dsply_nmll": "NKY 22000MBePW210312",
        "list_date": "10-09-2020",
        "underlying_ticker": "N225",
        "list_date_f": "10 Sep 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "UAFW",
        "ric": "NIML_t19.SI",
        "maturity_f": "12 Mar 21",
        "exercise_price": "22,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "12-03-2021",
        "dsply_nmll": "NKY 25000MBePW210312",
        "list_date": "10-09-2020",
        "underlying_ticker": "N225",
        "list_date_f": "10 Sep 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "VTWW",
        "ric": "NIML_t21.SI",
        "maturity_f": "12 Mar 21",
        "exercise_price": "25,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "12-03-2021",
        "dsply_nmll": "NKY 24000MBeCW210312",
        "list_date": "10-09-2020",
        "underlying_ticker": "N225",
        "list_date_f": "10 Sep 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "PJLW",
        "ric": "NIML_t16.SI",
        "maturity_f": "12 Mar 21",
        "exercise_price": "24,000.000"
      },
      {
        "underlying_ric": "SSIcv1",
        "maturity": "12-03-2021",
        "dsply_nmll": "NKY 27000MBeCW210312",
        "list_date": "10-09-2020",
        "underlying_ticker": "N225",
        "list_date_f": "10 Sep 20",
        "underlying_name": "N225",
        "conv_ratio": "80",
        "ACVOL_1": "0",
        "ticker": "FIQW",
        "ric": "NIML_t17.SI",
        "maturity_f": "12 Mar 21",
        "exercise_price": "27,000.000"
      },
      {
        "underlying_ric": "STEL.SI",
        "maturity": "04-03-2021",
        "dsply_nmll": "SingtelMBeCW210304",
        "list_date": "08-10-2020",
        "underlying_ticker": "Z74",
        "list_date_f": "08 Oct 20",
        "underlying_name": "SINGTEL",
        "conv_ratio": "1",
        "ACVOL_1": "0",
        "ticker": "GJAW",
        "ric": "SEML_t18.SI",
        "maturity_f": "04 Mar 21",
        "exercise_price": "2.400"
      },
      {
        "underlying_ric": "CATL.SI",
        "maturity": "04-03-2021",
        "dsply_nmll": "CapitalaMBeCW210304",
        "list_date": "08-10-2020",
        "underlying_ticker": "C31",
        "list_date_f": "08 Oct 20",
        "underlying_name": "CAPLAND",
        "conv_ratio": "2",
        "ACVOL_1": "0",
        "ticker": "HUFW",
        "ric": "CAML_t3.SI",
        "maturity_f": "04 Mar 21",
        "exercise_price": "3.000"
      },
      {
        "underlying_ric": "KPLM.SI",
        "maturity": "04-03-2021",
        "dsply_nmll": "KepCorpMBeCW210304",
        "list_date": "08-10-2020",
        "underlying_ticker": "BN4",
        "list_date_f": "08 Oct 20",
        "underlying_name": "KEPCORP",
        "conv_ratio": "3",
        "ACVOL_1": "0",
        "ticker": "NMBW",
        "ric": "KPML_t9.SI",
        "maturity_f": "04 Mar 21",
        "exercise_price": "5.400"
      },
      {
        "underlying_ric": "OCBC.SI",
        "maturity": "03-03-2021",
        "dsply_nmll": "OCBC Bk MB eCW210303",
        "list_date": "17-09-2020",
        "underlying_ticker": "O39",
        "list_date_f": "17 Sep 20",
        "underlying_name": "OCBC",
        "conv_ratio": "12",
        "ACVOL_1": "0",
        "ticker": "JSYW",
        "ric": "OCML_t18.SI",
        "maturity_f": "03 Mar 21",
        "exercise_price": "8.600"
      }
    ]
}

const noticeData = {
	"notice": [
		{
			"headline": "Expiry of Warrants on 2 March 2021 (Final Notice)",
			"id": 3377,
			"tickers": [
				"983W(AEM MB eCW210302)",
				"WQ6W(AscendasReit MBeCW210302)",
				"NYBW(BYD MB ePW210302)",
				"XATW(BYD MB eCW210302)",
				"9EFW(MapleComTr MB eCW210302)",
				"Q31W(TencentMBeCW210302)",
				"WL7W(TencentMBePW210302)",
				"WHGW(Top Glove MB eCW210302)"
			],
			"related_warrant": "983W(AEM MB eCW210302);WQ6W(AscendasReit MBeCW210302);NYBW(BYD MB ePW210302);XATW(BYD MB eCW210302);9EFW(MapleComTr MB eCW210302);Q31W(TencentMBeCW210302);WL7W(TencentMBePW210302);WHGW(Top Glove MB eCW210302)",
			"underlying": "AEM HOLDINGS LTD;ASCEN REIT;BYD COMPANY;MAPLETREE COMMER;TENCENT;TOP GLOVE CORPOR",
			"underlyings": [
				{
					"underlying_ric": "AEM.SI",
					"underlying_ticker": "AWX",
					"underlying_name": "AEM HOLDINGS LTD"
				},
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": "1211.HK",
					"underlying_ticker": "1211",
					"underlying_name": "BYD COMPANY"
				},
				{
					"underlying_ric": "1211.HK",
					"underlying_ticker": "BYD COMPANY",
					"underlying_name": "BYD COMPANY"
				},
				{
					"underlying_ric": "MACT.SI",
					"underlying_ticker": "N2IU",
					"underlying_name": "MAPLETREE COMMER"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "TOPG.SI",
					"underlying_ticker": "BVA",
					"underlying_name": "TOP GLOVE CORPOR"
				}
			],
			"date": "02 Mar 21"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2021 (First Notice)",
			"id": 3361,
			"tickers": [
				"OMCW(HSI 28000MBeCW210330)",
				"W6JW(HSI 27200MBeCW210330)",
				"NMKW(HSI 26600MBePW210330)",
				"KWBW(HSI 25800MBePW210330)",
				"QHEW(HSI 28800MBeCW210330)",
				"EEKW(HSI 27400MBePW210330)",
				"HGKW(HSI 30400MBeCW210330)",
				"5N8W(HSI 29600MBeCW210330)",
				"9PCW(HSI 28200MBePW210330)"
			],
			"related_warrant": "OMCW(HSI 28000MBeCW210330);W6JW(HSI 27200MBeCW210330);NMKW(HSI 26600MBePW210330);KWBW(HSI 25800MBePW210330);QHEW(HSI 28800MBeCW210330);EEKW(HSI 27400MBePW210330);HGKW(HSI 30400MBeCW210330);5N8W(HSI 29600MBeCW210330);9PCW(HSI 28200MBePW210330)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "01 Mar 21"
		},
		{
			"headline": "Expiry of Warrants on 31 March 2021 (First Notice)",
			"id": 3365,
			"tickers": [
				"YBNW(SingtelMBeCW210331)",
				"KSCW(Wilmar MB eCW210331)"
			],
			"related_warrant": "YBNW(SingtelMBeCW210331);KSCW(Wilmar MB eCW210331)",
			"underlying": "SINGTEL;WILMAR INTERNATI",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "WLIL.SI",
					"underlying_ticker": "F34",
					"underlying_name": "WILMAR INTERNATI"
				}
			],
			"date": "01 Mar 21"
		},
		{
			"headline": "Expiry of Warrants on 31 March 2021 (First Notice)",
			"id": 3369,
			"tickers": [
				"URDW(STI 2800MBeCW210331)",
				"VSBW(STI 3000MBeCW210331)",
				"WVEW(STI 2300MBePW210331)",
				"DFAW(STI 2500MBePW210331)",
				"8C5W(STI 2550MBeCW210331)"
			],
			"related_warrant": "URDW(STI 2800MBeCW210331);VSBW(STI 3000MBeCW210331);WVEW(STI 2300MBePW210331);DFAW(STI 2500MBePW210331);8C5W(STI 2550MBeCW210331)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "01 Mar 21"
		},
		{
			"headline": "Expiry of Warrants on 1 March 2021 (Final Notice)",
			"id": 3373,
			"tickers": [
				"XLJW(ComfortDel MBeCW210301)",
				"XV1W(OCBC Bk MB eCW210301)"
			],
			"related_warrant": "XLJW(ComfortDel MBeCW210301);XV1W(OCBC Bk MB eCW210301)",
			"underlying": "COMFORTDELGRO LD;OCBC",
			"underlyings": [
				{
					"underlying_ric": "CMDG.SI",
					"underlying_ticker": "C52",
					"underlying_name": "COMFORTDELGRO LD"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Mar 21"
		},
		{
			"headline": "Expiry of Warrants on 25 February 2021 (Final Notice)",
			"id": 3357,
			"tickers": [
				"VOIW(HSI 25000MBePW210225)",
				"8Y4W(HSI 28200MBeCW210225)",
				"JXBW(HSI 26000MBePW210225)",
				"FCHW(HSI 27400MBeCW210225)",
				"UCEW(HSI 26600MBeCW210225)"
			],
			"related_warrant": "VOIW(HSI 25000MBePW210225);8Y4W(HSI 28200MBeCW210225);JXBW(HSI 26000MBePW210225);FCHW(HSI 27400MBeCW210225);UCEW(HSI 26600MBeCW210225)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "25 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 22 February 2021 (Final Notice)",
			"id": 3353,
			"tickers": [
				"WU1W(CapitalaMBeCW210222)",
				"SJOW(KepCorp MBeCW210222)",
				"YX7W(SingtelMBeCW210222)",
				"NDHW(Wilmar MB eCW210222)"
			],
			"related_warrant": "WU1W(CapitalaMBeCW210222);SJOW(KepCorp MBeCW210222);YX7W(SingtelMBeCW210222);NDHW(Wilmar MB eCW210222)",
			"underlying": "CAPLAND;KEPCORP;SINGTEL;WILMAR INTERNATI",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "WLIL.SI",
					"underlying_ticker": "F34",
					"underlying_name": "WILMAR INTERNATI"
				}
			],
			"date": "22 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 19 March 2021 (First Notice)",
			"id": 3349,
			"tickers": [
				"IQZW(S&P 2700MBePW210319)",
				"VQEW(S&P 3950MBeCW210319)",
				"DOHW(S&P 2950MBePW210319)"
			],
			"related_warrant": "IQZW(S&P 2700MBePW210319);VQEW(S&P 3950MBeCW210319);DOHW(S&P 2950MBePW210319)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "19 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 17 February 2021 (Final Notice)",
			"id": 3345,
			"tickers": [
				"XPUW(DBS MB ePW210217)",
				"IEIW(OCBC Bk MB ePW210217)",
				"9KVW(UOB MB ePW210217)"
			],
			"related_warrant": "XPUW(DBS MB ePW210217);IEIW(OCBC Bk MB ePW210217);9KVW(UOB MB ePW210217)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "17 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 15 March 2021 (First Notice)",
			"id": 3341,
			"tickers": [
				"RXPW(SGX MB ePW210315)",
				"9QJW(SGX MB eCW210315)"
			],
			"related_warrant": "RXPW(SGX MB ePW210315);9QJW(SGX MB eCW210315)",
			"underlying": "SGX",
			"underlyings": [
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				}
			],
			"date": "15 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 12 March 2021 (First Notice)",
			"id": 3337,
			"tickers": [
				"PJLW(NKY 24000MBeCW210312)",
				"FIQW(NKY 27000MBeCW210312)",
				"YTXW(NKY 30000MBeCW210312)",
				"UAFW(NKY 22000MBePW210312)",
				"WG8W(NKY 19000MBePW210312)",
				"VTWW(NKY 25000MBePW210312)"
			],
			"related_warrant": "PJLW(NKY 24000MBeCW210312);FIQW(NKY 27000MBeCW210312);YTXW(NKY 30000MBeCW210312);UAFW(NKY 22000MBePW210312);WG8W(NKY 19000MBePW210312);VTWW(NKY 25000MBePW210312)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "11 Feb 21"
		},
		{
			"headline": "Clarification Announcement re expiry of Warrants relating to ordinary shares of NETEASE on 2 Feb 21",
			"id": 3333,
			"tickers": [
				"UPGW(NetEase180 MBeCW210202)",
				"VDMW(NetEase150 MBeCW210202)"
			],
			"related_warrant": "UPGW(NetEase180 MBeCW210202);VDMW(NetEase150 MBeCW210202)",
			"underlying": "NTES-S",
			"underlyings": [
				{
					"underlying_ric": "9999.HK",
					"underlying_ticker": "9999",
					"underlying_name": "NTES-S"
				}
			],
			"date": "08 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 4 March 2021 (First Notice)",
			"id": 3325,
			"tickers": [
				"HUFW(CapitalaMBeCW210304)",
				"NMBW(KepCorpMBeCW210304)",
				"GJAW(SingtelMBeCW210304)"
			],
			"related_warrant": "HUFW(CapitalaMBeCW210304);NMBW(KepCorpMBeCW210304);GJAW(SingtelMBeCW210304)",
			"underlying": "CAPLAND;KEPCORP;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "04 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 3 March 2021 (First Notice)",
			"id": 3321,
			"tickers": [
				"JSYW(OCBC Bk MB eCW210303)"
			],
			"related_warrant": "JSYW(OCBC Bk MB eCW210303)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "03 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 2 March 2021 (First Notice)",
			"id": 3313,
			"tickers": [
				"983W(AEM MB eCW210302)",
				"WQ6W(AscendasReit MBeCW210302)",
				"NYBW(BYD MB ePW210302)",
				"XATW(BYD MB eCW210302)",
				"9EFW(MapleComTr MB eCW210302)",
				"Q31W(TencentMBeCW210302)",
				"WL7W(TencentMBePW210302)",
				"WHGW(Top Glove MB eCW210302)"
			],
			"related_warrant": "983W(AEM MB eCW210302);WQ6W(AscendasReit MBeCW210302);NYBW(BYD MB ePW210302);XATW(BYD MB eCW210302);9EFW(MapleComTr MB eCW210302);Q31W(TencentMBeCW210302);WL7W(TencentMBePW210302);WHGW(Top Glove MB eCW210302)",
			"underlying": "AEM HOLDINGS LTD;ASCEN REIT;BYD COMPANY;MAPLETREE COMMER;TENCENT;TOP GLOVE CORPOR",
			"underlyings": [
				{
					"underlying_ric": "AEM.SI",
					"underlying_ticker": "AWX",
					"underlying_name": "AEM HOLDINGS LTD"
				},
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": "1211.HK",
					"underlying_ticker": "1211",
					"underlying_name": "BYD COMPANY"
				},
				{
					"underlying_ric": "1211.HK",
					"underlying_ticker": "BYD COMPANY",
					"underlying_name": "BYD COMPANY"
				},
				{
					"underlying_ric": "MACT.SI",
					"underlying_ticker": "N2IU",
					"underlying_name": "MAPLETREE COMMER"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "TOPG.SI",
					"underlying_ticker": "BVA",
					"underlying_name": "TOP GLOVE CORPOR"
				}
			],
			"date": "02 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 2 February 2021 (Final Notice)",
			"id": 3317,
			"tickers": [
				"94RW(XIAOMI MBePW210202)",
				"XH3W(XIAOMI MBeCW210202)",
				"UZJW(TencentMBeCW210202)",
				"PQTW(TencentMBePW210202)",
				"VWYW(Alibaba MB eCW210202)",
				"PHYW(Alibaba MB ePW210202)",
				"RCFW(DBS MB eCW210202)",
				"LWYW(JD MB ePW210202)",
				"VDMW(NetEase150 MBeCW210202)",
				"UPGW(NetEase180 MBeCW210202)",
				"GUAW(NetEase130 MBePW210202)"
			],
			"related_warrant": "94RW(XIAOMI MBePW210202);XH3W(XIAOMI MBeCW210202);UZJW(TencentMBeCW210202);PQTW(TencentMBePW210202);VWYW(Alibaba MB eCW210202);PHYW(Alibaba MB ePW210202);RCFW(DBS MB eCW210202);LWYW(JD MB ePW210202);VDMW(NetEase150 MBeCW210202);UPGW(NetEase180 MBeCW210202);GUAW(NetEase130 MBePW210202)",
			"underlying": "XIAOMI-W;TENCENT;BABA-SW;DBS;JD-SW;NTES-S",
			"underlyings": [
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "9988",
					"underlying_name": "BABA-SW"
				},
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "BABA-SW",
					"underlying_name": "BABA-SW"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "9618",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "JD-SW",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9999.HK",
					"underlying_ticker": "9999",
					"underlying_name": "NTES-S"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "02 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 1 March 2021 (First Notice)",
			"id": 3305,
			"tickers": [
				"XLJW(ComfortDel MBeCW210301)",
				"XV1W(OCBC Bk MB eCW210301)"
			],
			"related_warrant": "XLJW(ComfortDel MBeCW210301);XV1W(OCBC Bk MB eCW210301)",
			"underlying": "COMFORTDELGRO LD;OCBC",
			"underlyings": [
				{
					"underlying_ric": "CMDG.SI",
					"underlying_ticker": "C52",
					"underlying_name": "COMFORTDELGRO LD"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 1 February 2021 (Final Notice)",
			"id": 3309,
			"tickers": [
				"REWW(DBS MB eCW210201)",
				"LLTW(UOB MB eCW210201)"
			],
			"related_warrant": "REWW(DBS MB eCW210201);LLTW(UOB MB eCW210201)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "01 Feb 21"
		},
		{
			"headline": "Expiry of Warrants on 28 January 2021 (Final Notice)",
			"id": 3301,
			"tickers": [
				"UFVW(HSI 26200MBeCW210128)",
				"QLHW(HSI 27000MBeCW210128)",
				"KZBW(HSI 23000MBePW210128)",
				"EASW(HSI 23800MBePW210128)",
				"SDYW(HSI 27800MBeCW210128)",
				"IRNW(HSI 24600MBePW210128)",
				"MRUW(HSI 25400MBePW210128)"
			],
			"related_warrant": "UFVW(HSI 26200MBeCW210128);QLHW(HSI 27000MBeCW210128);KZBW(HSI 23000MBePW210128);EASW(HSI 23800MBePW210128);SDYW(HSI 27800MBeCW210128);IRNW(HSI 24600MBePW210128);MRUW(HSI 25400MBePW210128)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Jan 21"
		},
		{
			"headline": "Expiry of Warrants on 25 February 2021 (First Notice)",
			"id": 3297,
			"tickers": [
				"UCEW(HSI 26600MBeCW210225)",
				"FCHW(HSI 27400MBeCW210225)",
				"JXBW(HSI 26000MBePW210225)",
				"8Y4W(HSI 28200MBeCW210225)",
				"VOIW(HSI 25000MBePW210225)"
			],
			"related_warrant": "UCEW(HSI 26600MBeCW210225);FCHW(HSI 27400MBeCW210225);JXBW(HSI 26000MBePW210225);8Y4W(HSI 28200MBeCW210225);VOIW(HSI 25000MBePW210225)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "25 Jan 21"
		},
		{
			"headline": "Expiry of Warrants on 22 February 2021 (First Notice)",
			"id": 3289,
			"tickers": [
				"WU1W(CapitalaMBeCW210222)",
				"SJOW(KepCorp MBeCW210222)",
				"YX7W(SingtelMBeCW210222)",
				"NDHW(Wilmar MB eCW210222)"
			],
			"related_warrant": "WU1W(CapitalaMBeCW210222);SJOW(KepCorp MBeCW210222);YX7W(SingtelMBeCW210222);NDHW(Wilmar MB eCW210222)",
			"underlying": "CAPLAND;KEPCORP;SINGTEL;WILMAR INTERNATI",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "WLIL.SI",
					"underlying_ticker": "F34",
					"underlying_name": "WILMAR INTERNATI"
				}
			],
			"date": "22 Jan 21"
		},
		{
			"headline": "Expiry of Warrants on 17 February 2021 (First Notice)",
			"id": 3285,
			"tickers": [
				"XPUW(DBS MB ePW210217)",
				"IEIW(OCBC Bk MB ePW210217)",
				"9KVW(UOB MB ePW210217)"
			],
			"related_warrant": "XPUW(DBS MB ePW210217);IEIW(OCBC Bk MB ePW210217);9KVW(UOB MB ePW210217)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "15 Jan 21"
		},
		{
			"headline": "Expiry of Warrants on 5 January 2021 (Final Notice)",
			"id": 3277,
			"tickers": [
				"QCAW(Alibaba MB eCW210105)",
				"5N4W(Alibaba MB ePW210105)"
			],
			"related_warrant": "QCAW(Alibaba MB eCW210105);5N4W(Alibaba MB ePW210105)",
			"underlying": "BABA-SW",
			"underlyings": [
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "9988",
					"underlying_name": "BABA-SW"
				},
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "BABA-SW",
					"underlying_name": "BABA-SW"
				}
			],
			"date": "05 Jan 21"
		},
		{
			"headline": "Expiry of Warrants on 1 February 2021 (First Notice)",
			"id": 3261,
			"tickers": [
				"REWW(DBS MB eCW210201)",
				"LLTW(UOB MB eCW210201)"
			],
			"related_warrant": "REWW(DBS MB eCW210201);LLTW(UOB MB eCW210201)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "31 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 2 February 2021 (First Notice)",
			"id": 3265,
			"tickers": [
				"VWYW(Alibaba MB eCW210202)",
				"PHYW(Alibaba MB ePW210202)",
				"RCFW(DBS MB eCW210202)",
				"LWYW(JD MB ePW210202)",
				"VDMW(NetEase150 MBeCW210202)",
				"UPGW(NetEase180 MBeCW210202)",
				"GUAW(NetEase130 MBePW210202)",
				"UZJW(TencentMBeCW210202)",
				"PQTW(TencentMBePW210202)",
				"94RW(XIAOMI MBePW210202)",
				"XH3W(XIAOMI MBeCW210202)"
			],
			"related_warrant": "VWYW(Alibaba MB eCW210202);PHYW(Alibaba MB ePW210202);RCFW(DBS MB eCW210202);LWYW(JD MB ePW210202);VDMW(NetEase150 MBeCW210202);UPGW(NetEase180 MBeCW210202);GUAW(NetEase130 MBePW210202);UZJW(TencentMBeCW210202);PQTW(TencentMBePW210202);94RW(XIAOMI MBePW210202);XH3W(XIAOMI MBeCW210202)",
			"underlying": "BABA-SW;DBS;JD-SW;NTES-S;TENCENT;XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "9988",
					"underlying_name": "BABA-SW"
				},
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "BABA-SW",
					"underlying_name": "BABA-SW"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "9618",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "JD-SW",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9999.HK",
					"underlying_ticker": "9999",
					"underlying_name": "NTES-S"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "31 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2020 (Final Notice)",
			"id": 3269,
			"tickers": [
				"CXAW(STI 2650MBeCW201231)",
				"VK2W(STI 2400MBePW201231)"
			],
			"related_warrant": "CXAW(STI 2650MBeCW201231);VK2W(STI 2400MBePW201231)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "31 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2020 (Final Notice)",
			"id": 3273,
			"tickers": [
				"ZTOW(DBS MB ePW201231)",
				"56OW(DBS MB eCW201231)",
				"HAGW(OCBC Bk MB ePW201231)",
				"9MDW(UOB MB ePW201231)"
			],
			"related_warrant": "ZTOW(DBS MB ePW201231);56OW(DBS MB eCW201231);HAGW(OCBC Bk MB ePW201231);9MDW(UOB MB ePW201231)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "31 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2020 (Final Notice)",
			"id": 3253,
			"tickers": [
				"QOMW(DBS MB ePW201230)",
				"LCKW(OCBC Bk MB eCW201230)",
				"RHMW(UOB MB eCW201230)",
				"5YTW(UOB MB ePW201230)"
			],
			"related_warrant": "QOMW(DBS MB ePW201230);LCKW(OCBC Bk MB eCW201230);RHMW(UOB MB eCW201230);5YTW(UOB MB ePW201230)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "30 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2020 (Final Notice)",
			"id": 3257,
			"tickers": [
				"XSHW(HSI 22400MBePW201230)",
				"XDIW(HSI 25000MBePW201230)",
				"1IZW(HSI 28000MBeCW201230)",
				"TUVW(HSI 25800MBePW201230)",
				"R80W(HSI 27200MBeCW201230)",
				"59NW(HSI 24000MBePW201230)",
				"YP7W(HSI 23200MBePW201230)",
				"MHXW(HSI25600MBeCW201230)",
				"PLJW(HSI26400MBeCW201230)",
				"SSTW(HSI 24800MBeCW201230)"
			],
			"related_warrant": "XSHW(HSI 22400MBePW201230);XDIW(HSI 25000MBePW201230);1IZW(HSI 28000MBeCW201230);TUVW(HSI 25800MBePW201230);R80W(HSI 27200MBeCW201230);59NW(HSI 24000MBePW201230);YP7W(HSI 23200MBePW201230);MHXW(HSI25600MBeCW201230);PLJW(HSI26400MBeCW201230);SSTW(HSI 24800MBeCW201230)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "30 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 29 December 2020 (Final Notice)",
			"id": 3249,
			"tickers": [
				"B83W(DBS MB ePW201229)",
				"RIAW(DBS MB eCW201229)",
				"MADW(UOB MB eCW201229)",
				"GNAW(OCBC Bk MB eCW201229)"
			],
			"related_warrant": "B83W(DBS MB ePW201229);RIAW(DBS MB eCW201229);MADW(UOB MB eCW201229);GNAW(OCBC Bk MB eCW201229)",
			"underlying": "DBS;UOB;OCBC",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "29 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 28 January 2021 (First Notice)",
			"id": 3241,
			"tickers": [
				"IRNW(HSI 24600MBePW210128)",
				"MRUW(HSI 25400MBePW210128)",
				"SDYW(HSI 27800MBeCW210128)",
				"EASW(HSI 23800MBePW210128)",
				"KZBW(HSI 23000MBePW210128)",
				"QLHW(HSI 27000MBeCW210128)",
				"UFVW(HSI 26200MBeCW210128)"
			],
			"related_warrant": "IRNW(HSI 24600MBePW210128);MRUW(HSI 25400MBePW210128);SDYW(HSI 27800MBeCW210128);EASW(HSI 23800MBePW210128);KZBW(HSI 23000MBePW210128);QLHW(HSI 27000MBeCW210128);UFVW(HSI 26200MBeCW210128)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "28 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 28 December 2020 (Final Notice)",
			"id": 3245,
			"tickers": [
				"INYW(DBS MB eCW201228)",
				"YHUW(OCBC Bk MB eCW201228)",
				"NJBW(OCBC Bk MB ePW201228)",
				"INXW(UOB MB ePW201228)",
				"GWDW(UOB MB eCW201228)"
			],
			"related_warrant": "INYW(DBS MB eCW201228);YHUW(OCBC Bk MB eCW201228);NJBW(OCBC Bk MB ePW201228);INXW(UOB MB ePW201228);GWDW(UOB MB eCW201228)",
			"underlying": "DBS;OCBC;uob",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "uob"
				}
			],
			"date": "28 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 18 December 2020 (Final Notice)",
			"id": 3237,
			"tickers": [
				"SLQW(S&P 3100MBePW201218)",
				"RUFW(S&P 3800MBeCW201218)",
				"ODYW(S&P 3500MBeCW201218)"
			],
			"related_warrant": "SLQW(S&P 3100MBePW201218);RUFW(S&P 3800MBeCW201218);ODYW(S&P 3500MBeCW201218)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "21 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 5 January 2021 (First Notice)",
			"id": 3233,
			"tickers": [
				"QCAW(Alibaba MB eCW210105)",
				"5N4W(Alibaba MB ePW210105)"
			],
			"related_warrant": "QCAW(Alibaba MB eCW210105);5N4W(Alibaba MB ePW210105)",
			"underlying": "BABA-SW",
			"underlyings": [
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "9988",
					"underlying_name": "BABA-SW"
				},
				{
					"underlying_ric": "9988.HK",
					"underlying_ticker": "BABA-SW",
					"underlying_name": "BABA-SW"
				}
			],
			"date": "04 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2020 (Final Notice)",
			"id": 3229,
			"tickers": [
				"DYPW(JD MB eCW201202)"
			],
			"related_warrant": "DYPW(JD MB eCW201202)",
			"underlying": "JD-SW",
			"underlyings": [
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "9618",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "JD-SW",
					"underlying_name": "JD-SW"
				}
			],
			"date": "02 Dec 20"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2020 (First Notice)",
			"id": 3213,
			"tickers": [
				"XSHW(HSI 22400MBePW201230)",
				"XDIW(HSI 25000MBePW201230)",
				"1IZW(HSI 28000MBeCW201230)",
				"TUVW(HSI 25800MBePW201230)",
				"R80W(HSI 27200MBeCW201230)",
				"59NW(HSI 24000MBePW201230)",
				"YP7W(HSI 23200MBePW201230)",
				"MHXW(HSI25600MBeCW201230)",
				"PLJW(HSI26400MBeCW201230)",
				"SSTW(HSI 24800MBeCW201230)"
			],
			"related_warrant": "XSHW(HSI 22400MBePW201230);XDIW(HSI 25000MBePW201230);1IZW(HSI 28000MBeCW201230);TUVW(HSI 25800MBePW201230);R80W(HSI 27200MBeCW201230);59NW(HSI 24000MBePW201230);YP7W(HSI 23200MBePW201230);MHXW(HSI25600MBeCW201230);PLJW(HSI26400MBeCW201230);SSTW(HSI 24800MBeCW201230)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2020 (First Notice)",
			"id": 3217,
			"tickers": [
				"QOMW(DBS MB ePW201230)",
				"LCKW(OCBC Bk MB eCW201230)",
				"RHMW(UOB MB eCW201230)",
				"5YTW(UOB MB ePW201230)"
			],
			"related_warrant": "QOMW(DBS MB ePW201230);LCKW(OCBC Bk MB eCW201230);RHMW(UOB MB eCW201230);5YTW(UOB MB ePW201230)",
			"underlying": "DBS;OCBC;uob",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "uob"
				}
			],
			"date": "30 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2020 (First Notice)",
			"id": 3221,
			"tickers": [
				"ZTOW(DBS MB ePW201231)",
				"56OW(DBS MB eCW201231)",
				"HAGW(OCBC Bk MB ePW201231)",
				"9MDW(UOB MB ePW201231)"
			],
			"related_warrant": "ZTOW(DBS MB ePW201231);56OW(DBS MB eCW201231);HAGW(OCBC Bk MB ePW201231);9MDW(UOB MB ePW201231)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "30 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2020 (First Notice)",
			"id": 3225,
			"tickers": [
				"CXAW(STI 2650MBeCW201231)",
				"VK2W(STI 2400MBePW201231)"
			],
			"related_warrant": "CXAW(STI 2650MBeCW201231);VK2W(STI 2400MBePW201231)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 28 December 2020 (First Notice)",
			"id": 3197,
			"tickers": [
				"INYW(DBS MB eCW201228)",
				"YHUW(OCBC Bk MB eCW201228)",
				"NJBW(OCBC Bk MB ePW201228)",
				"GWDW(UOB MB eCW201228)",
				"INXW(UOB MB ePW201228)"
			],
			"related_warrant": "INYW(DBS MB eCW201228);YHUW(OCBC Bk MB eCW201228);NJBW(OCBC Bk MB ePW201228);GWDW(UOB MB eCW201228);INXW(UOB MB ePW201228)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "27 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 29 December 2020 (First Notice)",
			"id": 3201,
			"tickers": [
				"B83W(DBS MB ePW201229)",
				"RIAW(DBS MB eCW201229)",
				"GNAW(OCBC Bk MB eCW201229)",
				"MADW(UOB MB eCW201229)"
			],
			"related_warrant": "B83W(DBS MB ePW201229);RIAW(DBS MB eCW201229);GNAW(OCBC Bk MB eCW201229);MADW(UOB MB eCW201229)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "27 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2020 (Final Notice)",
			"id": 3209,
			"tickers": [
				"HLRW(HSI 23600MBePW201127)",
				"TMXW(HSI 21200MBePW201127)",
				"UHXW(HSI24400MBeCW201127)",
				"FBMW(HSI22000MBePW201127)",
				"NGOW(HSI22800MBePW201127)",
				"MHVW(HSI25200MBeCW201127)",
				"ZVBW(HSI 26000MBeCW201127)"
			],
			"related_warrant": "HLRW(HSI 23600MBePW201127);TMXW(HSI 21200MBePW201127);UHXW(HSI24400MBeCW201127);FBMW(HSI22000MBePW201127);NGOW(HSI22800MBePW201127);MHVW(HSI25200MBeCW201127);ZVBW(HSI 26000MBeCW201127)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 18 December 2020 (First Notice)",
			"id": 3193,
			"tickers": [
				"ODYW(S&P 3500MBeCW201218)",
				"RUFW(S&P 3800MBeCW201218)",
				"SLQW(S&P 3100MBePW201218)"
			],
			"related_warrant": "ODYW(S&P 3500MBeCW201218);RUFW(S&P 3800MBeCW201218);SLQW(S&P 3100MBePW201218)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "18 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 3 November 2020 (Final Notice)",
			"id": 3189,
			"tickers": [
				"1W9W(JD MB eCW201103)"
			],
			"related_warrant": "1W9W(JD MB eCW201103)",
			"underlying": "JD-SW",
			"underlyings": [
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "9618",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "JD-SW",
					"underlying_name": "JD-SW"
				}
			],
			"date": "03 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2020 (First Notice)",
			"id": 3177,
			"tickers": [
				"DYPW(JD MB eCW201202)"
			],
			"related_warrant": "DYPW(JD MB eCW201202)",
			"underlying": "JD-SW",
			"underlyings": [
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "9618",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "JD-SW",
					"underlying_name": "JD-SW"
				}
			],
			"date": "02 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 2 November 2020 (Final Notice)",
			"id": 3185,
			"tickers": [
				"HUBW(SingtelMBeCW201102)",
				"YPFW(CapitalaMBeCW201102)",
				"DIRW(Wilmar MB eCW201102)",
				"JPBW(KepCorp MBeCW201102)"
			],
			"related_warrant": "HUBW(SingtelMBeCW201102);YPFW(CapitalaMBeCW201102);DIRW(Wilmar MB eCW201102);JPBW(KepCorp MBeCW201102)",
			"underlying": "SINGTEL;CAPLAND;WILMAR INTERNATI;KEPCORP",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "WLIL.SI",
					"underlying_ticker": "F34",
					"underlying_name": "WILMAR INTERNATI"
				}
			],
			"date": "02 Nov 20"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2020 (Final Notice)",
			"id": 3173,
			"tickers": [
				"MKSW(STI 2900MBeCW201030)",
				"GDIW(STI 2600MBePW201030)",
				"9FDW(STI 2800MBePW201030)",
				"MSWW(STI 3100MBeCW201030)"
			],
			"related_warrant": "MKSW(STI 2900MBeCW201030);GDIW(STI 2600MBePW201030);9FDW(STI 2800MBePW201030);MSWW(STI 3100MBeCW201030)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Oct 20"
		},
		{
			"headline": "Expiry of Warrants on 29 October 2020 (Final Notice)",
			"id": 3161,
			"tickers": [
				"GCTW(HSI 26400MBeCW201029)",
				"XXNW(HSI23800MBeCW201029)",
				"QOFW(HSI24000MBePW201029)",
				"EXDW(HSI24800MBeCW201029)",
				"XSIW(HSI22400MBePW201029)",
				"YH9W(HSI25600MBeCW201029)",
				"8I2W(HSI 23200MBePW201029)"
			],
			"related_warrant": "GCTW(HSI 26400MBeCW201029);XXNW(HSI23800MBeCW201029);QOFW(HSI24000MBePW201029);EXDW(HSI24800MBeCW201029);XSIW(HSI22400MBePW201029);YH9W(HSI25600MBeCW201029);8I2W(HSI 23200MBePW201029)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "29 Oct 20"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2020 (First Notice)",
			"id": 3157,
			"tickers": [
				"TMXW(HSI 21200MBePW201127)",
				"UHXW(HSI24400MBeCW201127)",
				"FBMW(HSI22000MBePW201127)",
				"NGOW(HSI22800MBePW201127)",
				"MHVW(HSI25200MBeCW201127)",
				"ZVBW(HSI 26000MBeCW201127)",
				"HLRW(HSI 23600MBePW201127)"
			],
			"related_warrant": "TMXW(HSI 21200MBePW201127);UHXW(HSI24400MBeCW201127);FBMW(HSI22000MBePW201127);NGOW(HSI22800MBePW201127);MHVW(HSI25200MBeCW201127);ZVBW(HSI 26000MBeCW201127);HLRW(HSI 23600MBePW201127)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "27 Oct 20"
		},
		{
			"headline": "Expiry of Warrants on 3 November 2020 (First Notice)",
			"id": 3153,
			"tickers": [
				"1W9W(JD MB eCW201103)"
			],
			"related_warrant": "1W9W(JD MB eCW201103)",
			"underlying": "JD-SW",
			"underlyings": [
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "9618",
					"underlying_name": "JD-SW"
				},
				{
					"underlying_ric": "9618.HK",
					"underlying_ticker": "JD-SW",
					"underlying_name": "JD-SW"
				}
			],
			"date": "05 Oct 20"
		},
		{
			"headline": "Expiry of Warrants on 2 November 2020 (First Notice)",
			"id": 3149,
			"tickers": [
				"YPFW(CapitalaMBeCW201102)",
				"JPBW(KepCorp MBeCW201102)",
				"HUBW(SingtelMBeCW201102)",
				"DIRW(Wilmar MB eCW201102)"
			],
			"related_warrant": "YPFW(CapitalaMBeCW201102);JPBW(KepCorp MBeCW201102);HUBW(SingtelMBeCW201102);DIRW(Wilmar MB eCW201102)",
			"underlying": "CAPLAND;KEPCORP;SINGTEL;WILMAR INTERNATI",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "WLIL.SI",
					"underlying_ticker": "F34",
					"underlying_name": "WILMAR INTERNATI"
				}
			],
			"date": "02 Oct 20"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2020 (First Notice)",
			"id": 3145,
			"tickers": [
				"MKSW(STI 2900MBeCW201030)",
				"GDIW(STI 2600MBePW201030)",
				"9FDW(STI 2800MBePW201030)",
				"MSWW(STI 3100MBeCW201030)"
			],
			"related_warrant": "MKSW(STI 2900MBeCW201030);GDIW(STI 2600MBePW201030);9FDW(STI 2800MBePW201030);MSWW(STI 3100MBeCW201030)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Sep 20"
		},
		{
			"headline": "Expiry of Warrants on 29 October 2020 (First Notice)",
			"id": 3137,
			"tickers": [
				"XSIW(HSI22400MBePW201029)",
				"YH9W(HSI25600MBeCW201029)",
				"8I2W(HSI 23200MBePW201029)",
				"EXDW(HSI24800MBeCW201029)",
				"GCTW(HSI 26400MBeCW201029)",
				"QOFW(HSI24000MBePW201029)",
				"XXNW(HSI23800MBeCW201029)"
			],
			"related_warrant": "XSIW(HSI22400MBePW201029);YH9W(HSI25600MBeCW201029);8I2W(HSI 23200MBePW201029);EXDW(HSI24800MBeCW201029);GCTW(HSI 26400MBeCW201029);QOFW(HSI24000MBePW201029);XXNW(HSI23800MBeCW201029)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Sep 20"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2020 (Final Notice)",
			"id": 3141,
			"tickers": [
				"V7LW(HSI22800MBePW200929)",
				"FSJW(HSI24400MBeCW200929)",
				"XQUW(HSI23600MBePW200929)",
				"99PW(HSI25200MBeCW200929)",
				"V1NW(HSI 22000MBePW200929)",
				"8I1W(HSI 26000MBeCW200929)",
				"WI8W(HSI26800MBeCW200929)",
				"90EW(HSI27600MBeCW200929)",
				"TEQW(HSI24400MBePW200929)"
			],
			"related_warrant": "V7LW(HSI22800MBePW200929);FSJW(HSI24400MBeCW200929);XQUW(HSI23600MBePW200929);99PW(HSI25200MBeCW200929);V1NW(HSI 22000MBePW200929);8I1W(HSI 26000MBeCW200929);WI8W(HSI26800MBeCW200929);90EW(HSI27600MBeCW200929);TEQW(HSI24400MBePW200929)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Sep 20"
		},
		{
			"headline": "Expiry of Warrants on 18 September 2020 (Final Notice)",
			"id": 3133,
			"tickers": [
				"1S4W(S&P 3000MBePW200918)",
				"GXIW(S&P 2000MBePW200918)",
				"KRYW(S&P 3200MBeCW200918)",
				"MOOW(S&P 2500MBePW200918)",
				"963W(S&P 2850MBeCW200918)"
			],
			"related_warrant": "1S4W(S&P 3000MBePW200918);GXIW(S&P 2000MBePW200918);KRYW(S&P 3200MBeCW200918);MOOW(S&P 2500MBePW200918);963W(S&P 2850MBeCW200918)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "21 Sep 20"
		},
		{
			"headline": "Expiry of Warrants on 11 September 2020 (Final Notice)",
			"id": 3125,
			"tickers": [
				"NZAW(NKY 19500MBePW200911)",
				"KVBW(NKY 23500MBeCW200911)",
				"YFCW(NKY 21000MBeCW200911)",
				"LUMW(NKY 26000MBeCW200911)",
				"CUVW(NKY 22500MBePW200911)"
			],
			"related_warrant": "NZAW(NKY 19500MBePW200911);KVBW(NKY 23500MBeCW200911);YFCW(NKY 21000MBeCW200911);LUMW(NKY 26000MBeCW200911);CUVW(NKY 22500MBePW200911)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "11 Sep 20"
		},
		{
			"headline": "Expiry of Warrants on 2 September 2020 (Final Notice)",
			"id": 3117,
			"tickers": [
				"PUPW(BudweiserMBeCW200902)",
				"X7YW(XIAOMI MBePW200902)",
				"TGAW(XIAOMI MBeCW200902)"
			],
			"related_warrant": "PUPW(BudweiserMBeCW200902);X7YW(XIAOMI MBePW200902);TGAW(XIAOMI MBeCW200902)",
			"underlying": "BUD APAC;XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "1876.HK",
					"underlying_ticker": "1876",
					"underlying_name": "BUD APAC"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "02 Sep 20"
		},
		{
			"headline": "Expiry of Warrants on 31 August 2020 (Final Notice)",
			"id": 3113,
			"tickers": [
				"94IW(STI 3100MBePW200831)",
				"JVYW(STI 3400MBeCW200831)"
			],
			"related_warrant": "94IW(STI 3100MBePW200831);JVYW(STI 3400MBeCW200831)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "31 Aug 20"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2020 (First Notice)",
			"id": 3105,
			"tickers": [
				"FSJW(HSI24400MBeCW200929)",
				"V1NW(HSI 22000MBePW200929)",
				"99PW(HSI25200MBeCW200929)",
				"XQUW(HSI23600MBePW200929)",
				"8I1W(HSI 26000MBeCW200929)",
				"TEQW(HSI24400MBePW200929)",
				"V7LW(HSI22800MBePW200929)",
				"WI8W(HSI26800MBeCW200929)",
				"90EW(HSI27600MBeCW200929)"
			],
			"related_warrant": "FSJW(HSI24400MBeCW200929);V1NW(HSI 22000MBePW200929);99PW(HSI25200MBeCW200929);XQUW(HSI23600MBePW200929);8I1W(HSI 26000MBeCW200929);TEQW(HSI24400MBePW200929);V7LW(HSI22800MBePW200929);WI8W(HSI26800MBeCW200929);90EW(HSI27600MBeCW200929)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "28 Aug 20"
		},
		{
			"headline": "Expiry of Warrants on 28 August 2020 (Final Notice)",
			"id": 3109,
			"tickers": [
				"VTNW(HSI23800MBeCW200828)",
				"HKPW(HSI25800MBePW200828)",
				"HBOW(HSI27800MBeCW200828)",
				"93BW(HSI25000MBePW200828)",
				"RAIW(HSI24200MBePW200828)",
				"MGDW(HSI27000MBeCW200828)",
				"9MHW(HSI 24600MBeCW200828)",
				"Z6HW(HSI23400MBePW200828)",
				"5XPW(HSI22600MBePW200828)",
				"YFXW(HSI26200MBeCW200828)",
				"YKCW(HSI21800MBePW200828)",
				"NYPW(HSI21000MBePW200828)",
				"PNDW(HSI25400MBeCW200828)"
			],
			"related_warrant": "VTNW(HSI23800MBeCW200828);HKPW(HSI25800MBePW200828);HBOW(HSI27800MBeCW200828);93BW(HSI25000MBePW200828);RAIW(HSI24200MBePW200828);MGDW(HSI27000MBeCW200828);9MHW(HSI 24600MBeCW200828);Z6HW(HSI23400MBePW200828);5XPW(HSI22600MBePW200828);YFXW(HSI26200MBeCW200828);YKCW(HSI21800MBePW200828);NYPW(HSI21000MBePW200828);PNDW(HSI25400MBeCW200828)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "28 Aug 20"
		},
		{
			"headline": "Expiry of Warrants on 18 September 2020 (First Notice)",
			"id": 3101,
			"tickers": [
				"MOOW(S&P 2500MBePW200918)",
				"KRYW(S&P 3200MBeCW200918)",
				"GXIW(S&P 2000MBePW200918)",
				"1S4W(S&P 3000MBePW200918)",
				"963W(S&P 2850MBeCW200918)"
			],
			"related_warrant": "MOOW(S&P 2500MBePW200918);KRYW(S&P 3200MBeCW200918);GXIW(S&P 2000MBePW200918);1S4W(S&P 3000MBePW200918);963W(S&P 2850MBeCW200918)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "18 Aug 20"
		},
		{
			"headline": "Expiry of Warrants on 11 September 2020 (First Notice)",
			"id": 3097,
			"tickers": [
				"NZAW(NKY 19500MBePW200911)",
				"KVBW(NKY 23500MBeCW200911)",
				"YFCW(NKY 21000MBeCW200911)",
				"LUMW(NKY 26000MBeCW200911)",
				"CUVW(NKY 22500MBePW200911)"
			],
			"related_warrant": "NZAW(NKY 19500MBePW200911);KVBW(NKY 23500MBeCW200911);YFCW(NKY 21000MBeCW200911);LUMW(NKY 26000MBeCW200911);CUVW(NKY 22500MBePW200911)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "11 Aug 20"
		},
		{
			"headline": "Expiry of Warrants on 6 August 2020 (Final Notice)",
			"id": 3093,
			"tickers": [
				"WOYW(OCBC Bk MB eCW200806)"
			],
			"related_warrant": "WOYW(OCBC Bk MB eCW200806)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "06 Aug 20"
		},
		{
			"headline": "Expiry of Warrants on 28 August 2020 (First Notice)",
			"id": 3089,
			"tickers": [
				"9MHW(HSI 24600MBeCW200828)"
			],
			"related_warrant": "9MHW(HSI 24600MBeCW200828)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "03 Aug 20"
		},
		{
			"headline": "Expiry of Warrants on 31 August 2020 (First Notice)",
			"id": 3077,
			"tickers": [
				"94IW(STI 3100MBePW200831)",
				"JVYW(STI 3400MBeCW200831)"
			],
			"related_warrant": "94IW(STI 3100MBePW200831);JVYW(STI 3400MBeCW200831)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Jul 20"
		},
		{
			"headline": "Expiry of Warrants on 2 September 2020 (First Notice)",
			"id": 3081,
			"tickers": [
				"PUPW(BudweiserMBeCW200902)",
				"X7YW(XIAOMI MBePW200902)",
				"TGAW(XIAOMI MBeCW200902)"
			],
			"related_warrant": "PUPW(BudweiserMBeCW200902);X7YW(XIAOMI MBePW200902);TGAW(XIAOMI MBeCW200902)",
			"underlying": "XIAOMI-W;BUD APAC",
			"underlyings": [
				{
					"underlying_ric": "1876.HK",
					"underlying_ticker": "1876",
					"underlying_name": "BUD APAC"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "30 Jul 20"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2020 (Final Notice)",
			"id": 3085,
			"tickers": [
				"96JW(HSI21400MBePW200730)",
				"5K5W(HSI24200MBeCW200730)",
				"KFKW(HSI 25800MBeCW200730)",
				"FIUW(HSI 25000MBeCW200730)",
				"QTHW(HSI22200MBePW200730)",
				"XS7W(HSI23400MBeCW200730)",
				"FYEW(HSI23000MBePW200730)"
			],
			"related_warrant": "96JW(HSI21400MBePW200730);5K5W(HSI24200MBeCW200730);KFKW(HSI 25800MBeCW200730);FIUW(HSI 25000MBeCW200730);QTHW(HSI22200MBePW200730);XS7W(HSI23400MBeCW200730);FYEW(HSI23000MBePW200730)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "30 Jul 20"
		},
		{
			"headline": "Expiry of Warrants on 28 August 2020 (First Notice)",
			"id": 3073,
			"tickers": [
				"MGDW(HSI27000MBeCW200828)",
				"RAIW(HSI24200MBePW200828)",
				"93BW(HSI25000MBePW200828)",
				"HBOW(HSI27800MBeCW200828)",
				"Z6HW(HSI23400MBePW200828)",
				"5XPW(HSI22600MBePW200828)",
				"YFXW(HSI26200MBeCW200828)",
				"VTNW(HSI23800MBeCW200828)",
				"NYPW(HSI21000MBePW200828)",
				"PNDW(HSI25400MBeCW200828)",
				"YKCW(HSI21800MBePW200828)",
				"HKPW(HSI25800MBePW200828)"
			],
			"related_warrant": "MGDW(HSI27000MBeCW200828);RAIW(HSI24200MBePW200828);93BW(HSI25000MBePW200828);HBOW(HSI27800MBeCW200828);Z6HW(HSI23400MBePW200828);5XPW(HSI22600MBePW200828);YFXW(HSI26200MBeCW200828);VTNW(HSI23800MBeCW200828);NYPW(HSI21000MBePW200828);PNDW(HSI25400MBeCW200828);YKCW(HSI21800MBePW200828);HKPW(HSI25800MBePW200828)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Jul 20"
		},
		{
			"headline": "Expiry of Warrants on 6 August 2020 (First Notice)",
			"id": 3069,
			"tickers": [
				"WOYW(OCBC Bk MB eCW200806)"
			],
			"related_warrant": "WOYW(OCBC Bk MB eCW200806)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "06 Jul 20"
		},
		{
			"headline": "Expiry of Warrants on 3 July 2020 (Final Notice)",
			"id": 3065,
			"tickers": [
				"CXFW(HAIDILAO MBeCW200703)",
				"5Y1W(TencentMBeCW200703)",
				"GDNW(TencentMBePW200703)",
				"47RW(XIAOMI MBePW200703)",
				"QSCW(XIAOMI MBeCW200703)"
			],
			"related_warrant": "CXFW(HAIDILAO MBeCW200703);5Y1W(TencentMBeCW200703);GDNW(TencentMBePW200703);47RW(XIAOMI MBePW200703);QSCW(XIAOMI MBeCW200703)",
			"underlying": "HAIDILAO;TENCENT;XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "6862.HK",
					"underlying_ticker": "6862",
					"underlying_name": "HAIDILAO"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "03 Jul 20"
		},
		{
			"headline": "Expiry of Warrants on 1 July 2020 (Final Notice)",
			"id": 3061,
			"tickers": [
				"W8BW(OCBC Bk MB ePW200701)"
			],
			"related_warrant": "W8BW(OCBC Bk MB ePW200701)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Jul 20"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2020 (First Notice)",
			"id": 3049,
			"tickers": [
				"96JW(HSI21400MBePW200730)",
				"XS7W(HSI23400MBeCW200730)",
				"QTHW(HSI22200MBePW200730)",
				"FIUW(HSI 25000MBeCW200730)",
				"KFKW(HSI 25800MBeCW200730)",
				"5K5W(HSI24200MBeCW200730)",
				"FYEW(HSI23000MBePW200730)"
			],
			"related_warrant": "96JW(HSI21400MBePW200730);XS7W(HSI23400MBeCW200730);QTHW(HSI22200MBePW200730);FIUW(HSI 25000MBeCW200730);KFKW(HSI 25800MBeCW200730);5K5W(HSI24200MBeCW200730);FYEW(HSI23000MBePW200730)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "30 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2020 (Final Notice)",
			"id": 3053,
			"tickers": [
				"SPGW(STI 3300MBeCW200630)",
				"A91W(STI 3250MBePW200630)",
				"5S0W(STI 3550MBeCW200630)",
				"EEAW(STI 3000MBePW200630)"
			],
			"related_warrant": "SPGW(STI 3300MBeCW200630);A91W(STI 3250MBePW200630);5S0W(STI 3550MBeCW200630);EEAW(STI 3000MBePW200630)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2020 (Final Notice)",
			"id": 3057,
			"tickers": [
				"TTDW(Venture MB eCW200630)"
			],
			"related_warrant": "TTDW(Venture MB eCW200630)",
			"underlying": "VENTURE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				}
			],
			"date": "30 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2020 (Final Notice)",
			"id": 3045,
			"tickers": [
				"DIWW(HSI27000MBeCW200629)",
				"PAOW(HSI23400MBePW200629)",
				"GSGW(HSI22600MBePW200629)",
				"RVHW(HSI26200MBeCW200629)",
				"Y8MW(HSI25400MBeCW200629)",
				"WSPW(HSI23800MBeCW200629)",
				"946W(HSI21800MBePW200629)",
				"RFPW(HSI24600MBeCW200629)"
			],
			"related_warrant": "DIWW(HSI27000MBeCW200629);PAOW(HSI23400MBePW200629);GSGW(HSI22600MBePW200629);RVHW(HSI26200MBeCW200629);Y8MW(HSI25400MBeCW200629);WSPW(HSI23800MBeCW200629);946W(HSI21800MBePW200629);RFPW(HSI24600MBeCW200629)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Jun 20"
		},
		{
			"headline": "Adjustment Notice",
			"id": 3041,
			"tickers": [
				"GWDW(UOB MB eCW201228)",
				"INXW(UOB MB ePW201228)",
				"MADW(UOB MB eCW201229)",
				"RHMW(UOB MB eCW201230)",
				"5YTW(UOB MB ePW201230)",
				"9MDW(UOB MB ePW201231)",
				"LLTW(UOB MB eCW210201)",
				"9KVW(UOB MB ePW210217)",
				"SMOW(UOB MB eCW210405)",
				"FSXW(UOB MB eCW210407)"
			],
			"related_warrant": "GWDW(UOB MB eCW201228);INXW(UOB MB ePW201228);MADW(UOB MB eCW201229);RHMW(UOB MB eCW201230);5YTW(UOB MB ePW201230);9MDW(UOB MB ePW201231);LLTW(UOB MB eCW210201);9KVW(UOB MB ePW210217);SMOW(UOB MB eCW210405);FSXW(UOB MB eCW210407)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "12 Jun 20"
		},
		{
			"headline": "Adjustment Notice",
			"id": 3037,
			"tickers": [
				"GWDW(UOB MB eCW201228)",
				"INXW(UOB MB ePW201228)",
				"MADW(UOB MB eCW201229)",
				"RHMW(UOB MB eCW201230)",
				"5YTW(UOB MB ePW201230)",
				"9MDW(UOB MB ePW201231)",
				"LLTW(UOB MB eCW210201)",
				"9KVW(UOB MB ePW210217)",
				"SMOW(UOB MB eCW210405)",
				"FSXW(UOB MB eCW210407)"
			],
			"related_warrant": "GWDW(UOB MB eCW201228);INXW(UOB MB ePW201228);MADW(UOB MB eCW201229);RHMW(UOB MB eCW201230);5YTW(UOB MB ePW201230);9MDW(UOB MB ePW201231);LLTW(UOB MB eCW210201);9KVW(UOB MB ePW210217);SMOW(UOB MB eCW210405);FSXW(UOB MB eCW210407)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "10 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 3 July 2020 (First Notice)",
			"id": 3033,
			"tickers": [
				"CXFW(HAIDILAO MBeCW200703)",
				"5Y1W(TencentMBeCW200703)",
				"GDNW(TencentMBePW200703)",
				"47RW(XIAOMI MBePW200703)",
				"QSCW(XIAOMI MBeCW200703)"
			],
			"related_warrant": "CXFW(HAIDILAO MBeCW200703);5Y1W(TencentMBeCW200703);GDNW(TencentMBePW200703);47RW(XIAOMI MBePW200703);QSCW(XIAOMI MBeCW200703)",
			"underlying": "HAIDILAO;TENCENT;XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "6862.HK",
					"underlying_ticker": "6862",
					"underlying_name": "HAIDILAO"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "03 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 2 June 2020 (Final Notice)",
			"id": 3029,
			"tickers": [
				"RKWW(DBS MB eCW200602)",
				"ECDW(DBS MB ePW200602)",
				"KVWW(TencentMBeCW200602)",
				"PQPW(TencentMBePW200602)",
				"R89W(BudweiserMBeCW200602)",
				"GOKW(BudweiserMBePW200602)",
				"UXCW(HAIDILAO MBeCW200602)"
			],
			"related_warrant": "RKWW(DBS MB eCW200602);ECDW(DBS MB ePW200602);KVWW(TencentMBeCW200602);PQPW(TencentMBePW200602);R89W(BudweiserMBeCW200602);GOKW(BudweiserMBePW200602);UXCW(HAIDILAO MBeCW200602)",
			"underlying": "DBS;TENCENT;BUD APAC;HAIDILAO",
			"underlyings": [
				{
					"underlying_ric": "1876.HK",
					"underlying_ticker": "1876",
					"underlying_name": "BUD APAC"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "6862.HK",
					"underlying_ticker": "6862",
					"underlying_name": "HAIDILAO"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "02 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 1 July 2020 (First Notice)",
			"id": 3021,
			"tickers": [
				"W8BW(OCBC Bk MB ePW200701)"
			],
			"related_warrant": "W8BW(OCBC Bk MB ePW200701)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 1 June 2020 (Final Notice)",
			"id": 3025,
			"tickers": [
				"VK7W(UOB MB eCW 200601)",
				"EOYW(UOB MB ePW200601)",
				"WJOW(SembMar MBeCW200601)",
				"UPQW(KepCorp MBeCW200601)",
				"9Y4W(DBS MB eCW200601)"
			],
			"related_warrant": "VK7W(UOB MB eCW 200601);EOYW(UOB MB ePW200601);WJOW(SembMar MBeCW200601);UPQW(KepCorp MBeCW200601);9Y4W(DBS MB eCW200601)",
			"underlying": "UOB;SEMBMAR;KEPCORP;DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "01 Jun 20"
		},
		{
			"headline": "Expiry of Warrants on 29 Jun 2020 (First Notice)",
			"id": 3009,
			"tickers": [
				"DIWW(HSI27000MBeCW200629)",
				"GSGW(HSI22600MBePW200629)",
				"RVHW(HSI26200MBeCW200629)",
				"Y8MW(HSI25400MBeCW200629)",
				"WSPW(HSI23800MBeCW200629)",
				"946W(HSI21800MBePW200629)",
				"RFPW(HSI24600MBeCW200629)",
				"PAOW(HSI23400MBePW200629)"
			],
			"related_warrant": "DIWW(HSI27000MBeCW200629);GSGW(HSI22600MBePW200629);RVHW(HSI26200MBeCW200629);Y8MW(HSI25400MBeCW200629);WSPW(HSI23800MBeCW200629);946W(HSI21800MBePW200629);RFPW(HSI24600MBeCW200629);PAOW(HSI23400MBePW200629)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 May 20"
		},
		{
			"headline": "Expiry of Warrants on 30 Jun 2020 (First Notice)",
			"id": 3013,
			"tickers": [
				"SPGW(STI 3300MBeCW200630)",
				"A91W(STI 3250MBePW200630)",
				"5S0W(STI 3550MBeCW200630)",
				"EEAW(STI 3000MBePW200630)"
			],
			"related_warrant": "SPGW(STI 3300MBeCW200630);A91W(STI 3250MBePW200630);5S0W(STI 3550MBeCW200630);EEAW(STI 3000MBePW200630)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 May 20"
		},
		{
			"headline": "Expiry of Warrants on 30 Jun 2020 (First Notice)",
			"id": 3017,
			"tickers": [
				"TTDW(Venture MB eCW200630)"
			],
			"related_warrant": "TTDW(Venture MB eCW200630)",
			"underlying": "VENTURE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				}
			],
			"date": "29 May 20"
		},
		{
			"headline": "Expiry of Warrants on 28 May 2020 (Final Notice)",
			"id": 3005,
			"tickers": [
				"WY2W(HSI23200MBePW200528)",
				"5YOW(HSI26600MBeCW200528)",
				"9U3W(HSI22200MBePW200528)",
				"NRKW(HSI24200MBeCW200528)",
				"ITDW(HSI20600MBePW200528)",
				"1U2W(HSI25000MBeCW200528)",
				"YYKW(HSI25800MBeCW200528)",
				"QAAW(HSI27400MBeCW200528)",
				"QKOW(HSI21400MBePW200528)"
			],
			"related_warrant": "WY2W(HSI23200MBePW200528);5YOW(HSI26600MBeCW200528);9U3W(HSI22200MBePW200528);NRKW(HSI24200MBeCW200528);ITDW(HSI20600MBePW200528);1U2W(HSI25000MBeCW200528);YYKW(HSI25800MBeCW200528);QAAW(HSI27400MBeCW200528);QKOW(HSI21400MBePW200528)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 May 20"
		},
		{
			"headline": "Expiry of Warrants on 1 Jun 2020 (First Notice)",
			"id": 2993,
			"tickers": [
				"VK7W(UOB MB eCW 200601)",
				"EOYW(UOB MB ePW200601)",
				"9Y4W(DBS MB eCW200601)",
				"UPQW(KepCorp MBeCW200601)",
				"WJOW(SembMar MBeCW200601)"
			],
			"related_warrant": "VK7W(UOB MB eCW 200601);EOYW(UOB MB ePW200601);9Y4W(DBS MB eCW200601);UPQW(KepCorp MBeCW200601);WJOW(SembMar MBeCW200601)",
			"underlying": "DBS;KEPCORP;SEMBMAR;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "30 Apr 20"
		},
		{
			"headline": "Expiry of Warrants on 2 Jun 2020 (First Notice)",
			"id": 2997,
			"tickers": [
				"R89W(BudweiserMBeCW200602)",
				"GOKW(BudweiserMBePW200602)",
				"ECDW(DBS MB ePW200602)",
				"RKWW(DBS MB eCW200602)",
				"UXCW(HAIDILAO MBeCW200602)",
				"KVWW(TencentMBeCW200602)",
				"PQPW(TencentMBePW200602)"
			],
			"related_warrant": "R89W(BudweiserMBeCW200602);GOKW(BudweiserMBePW200602);ECDW(DBS MB ePW200602);RKWW(DBS MB eCW200602);UXCW(HAIDILAO MBeCW200602);KVWW(TencentMBeCW200602);PQPW(TencentMBePW200602)",
			"underlying": "BUD APAC;DBS;HAIDILAO;TENCENT",
			"underlyings": [
				{
					"underlying_ric": "1876.HK",
					"underlying_ticker": "1876",
					"underlying_name": "BUD APAC"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "6862.HK",
					"underlying_ticker": "6862",
					"underlying_name": "HAIDILAO"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "30 Apr 20"
		},
		{
			"headline": "Expiry of Warrants on 30 April 2020 (Final Notice)",
			"id": 3001,
			"tickers": [
				"WVZW(STI 3100MBePW200430)",
				"RSXW(STI 3400MBeCW200430)"
			],
			"related_warrant": "WVZW(STI 3100MBePW200430);RSXW(STI 3400MBeCW200430)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Apr 20"
		},
		{
			"headline": "Expiry of Warrants on 28 May 2020 (First Notice)",
			"id": 2981,
			"tickers": [
				"NRKW(HSI24200MBeCW200528)",
				"1U2W(HSI25000MBeCW200528)",
				"QKOW(HSI21400MBePW200528)",
				"WY2W(HSI23200MBePW200528)",
				"5YOW(HSI26600MBeCW200528)",
				"QAAW(HSI27400MBeCW200528)",
				"YYKW(HSI25800MBeCW200528)",
				"ITDW(HSI20600MBePW200528)",
				"9U3W(HSI22200MBePW200528)"
			],
			"related_warrant": "NRKW(HSI24200MBeCW200528);1U2W(HSI25000MBeCW200528);QKOW(HSI21400MBePW200528);WY2W(HSI23200MBePW200528);5YOW(HSI26600MBeCW200528);QAAW(HSI27400MBeCW200528);YYKW(HSI25800MBeCW200528);ITDW(HSI20600MBePW200528);9U3W(HSI22200MBePW200528)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Apr 20"
		},
		{
			"headline": "Expiry of Warrants on 28 April 2020 (Final Notice)",
			"id": 2989,
			"tickers": [
				"5L5W(HSI26000MBePW200428)",
				"SBGW(HSI25400MBeCW200428)",
				"WKIW(HSI24400MBePW200428)",
				"W6CW(HSI 26800MBePW200428)",
				"LLHW(HSI 28600MBeCW200428)",
				"Q84W(HSI26200MBeCW200428)",
				"YSYW(HSI27000MBeCW200428)",
				"X2SW(HSI22800MBePW200428)",
				"UITW(HSI25200MBePW200428)",
				"W8XW(HSI27800MBeCW200428)"
			],
			"related_warrant": "5L5W(HSI26000MBePW200428);SBGW(HSI25400MBeCW200428);WKIW(HSI24400MBePW200428);W6CW(HSI 26800MBePW200428);LLHW(HSI 28600MBeCW200428);Q84W(HSI26200MBeCW200428);YSYW(HSI27000MBeCW200428);X2SW(HSI22800MBePW200428);UITW(HSI25200MBePW200428);W8XW(HSI27800MBeCW200428)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Apr 20"
		},
		{
			"headline": "Cessation of Mailing Final Expiry Notices to Warrantholders via Post",
			"id": 2953,
			"tickers": [
				""
			],
			"related_warrant": "",
			"underlying": "",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": ""
				}
			],
			"date": "06 Apr 20"
		},
		{
			"headline": "Expiry of Warrants on 6 April 2020 (Final Notice)",
			"id": 2965,
			"tickers": [
				"SHGW(SingtelMBeCW200406)"
			],
			"related_warrant": "SHGW(SingtelMBeCW200406)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "06 Apr 20"
		},
		{
			"headline": "Expiry of Warrants on 30 April 2020 (First Notice)",
			"id": 2945,
			"tickers": [
				"WVZW(STI 3100MBePW200430)",
				"RSXW(STI 3400MBeCW200430)"
			],
			"related_warrant": "WVZW(STI 3100MBePW200430);RSXW(STI 3400MBeCW200430)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2020 (Third Notice)",
			"id": 2949,
			"tickers": [
				"IPZW(HSI24800MBePW200330)",
				"MKEW(HSI28200MBeCW200330)",
				"DKYW(HSI25600MBePW200330)",
				"FAXW(HSI29000MBeCW200330)",
				"WBFW(HSI28000MBePW200330)",
				"FIPW(HSI29800MBeCW200330)",
				"OMDW(HSI26600MBeCW200330)",
				"QTWW(HSI26400MBePW200330)",
				"FSRW(HSI27400MBeCW200330)",
				"LSVW(HSI27200MBePW200330)"
			],
			"related_warrant": "IPZW(HSI24800MBePW200330);MKEW(HSI28200MBeCW200330);DKYW(HSI25600MBePW200330);FAXW(HSI29000MBeCW200330);WBFW(HSI28000MBePW200330);FIPW(HSI29800MBeCW200330);OMDW(HSI26600MBeCW200330);QTWW(HSI26400MBePW200330);FSRW(HSI27400MBeCW200330);LSVW(HSI27200MBePW200330)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 28 April 2020 (First Notice)",
			"id": 2941,
			"tickers": [
				"SBGW(HSI25400MBeCW200428)",
				"LLHW(HSI 28600MBeCW200428)",
				"W6CW(HSI 26800MBePW200428)",
				"5L5W(HSI26000MBePW200428)",
				"UITW(HSI25200MBePW200428)",
				"Q84W(HSI26200MBeCW200428)",
				"YSYW(HSI27000MBeCW200428)",
				"X2SW(HSI22800MBePW200428)",
				"W8XW(HSI27800MBeCW200428)",
				"WKIW(HSI24400MBePW200428)"
			],
			"related_warrant": "SBGW(HSI25400MBeCW200428);LLHW(HSI 28600MBeCW200428);W6CW(HSI 26800MBePW200428);5L5W(HSI26000MBePW200428);UITW(HSI25200MBePW200428);Q84W(HSI26200MBeCW200428);YSYW(HSI27000MBeCW200428);X2SW(HSI22800MBePW200428);W8XW(HSI27800MBeCW200428);WKIW(HSI24400MBePW200428)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 20 March 2020 (Third Notice)",
			"id": 2937,
			"tickers": [
				"YM7W(S&P 2900MBePW200320)",
				"QOBW(S&P 3300MBeCW200320)",
				"ZAVW(S&P 3100MBePW200320)"
			],
			"related_warrant": "YM7W(S&P 2900MBePW200320);QOBW(S&P 3300MBeCW200320);ZAVW(S&P 3100MBePW200320)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "23 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 13 March 2020 (Third Notice)",
			"id": 2929,
			"tickers": [
				"Y0JW(NKY 25000MBeCW200313)",
				"VM8W(NKY 21500MBePW200313)",
				"TKYW(NKY 19000MBePW200313)",
				"GYKW(NKY 22500MBeCW200313)",
				"PWMW(NKY 20000MBeCW200313)"
			],
			"related_warrant": "Y0JW(NKY 25000MBeCW200313);VM8W(NKY 21500MBePW200313);TKYW(NKY 19000MBePW200313);GYKW(NKY 22500MBeCW200313);PWMW(NKY 20000MBeCW200313)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "13 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 9 March 2020 (Third Notice)",
			"id": 2921,
			"tickers": [
				"KMDW(Venture MB eCW200309)"
			],
			"related_warrant": "KMDW(Venture MB eCW200309)",
			"underlying": "VENTURE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				}
			],
			"date": "09 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 6 April 2020 (First Notice)",
			"id": 2917,
			"tickers": [
				"SHGW(SingtelMBeCW200406)"
			],
			"related_warrant": "SHGW(SingtelMBeCW200406)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "06 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 2 March 2020 (Third Notice)",
			"id": 2901,
			"tickers": [
				"XKLW(DBS MB eCW200302)",
				"WQCW(OCBC Bk MB eCW200302)",
				"UQXW(OCBC Bk MB ePW200302)",
				"LSOW(Venture MB eCW200302)",
				"NYHW(YangzijiMBeCW200302)"
			],
			"related_warrant": "XKLW(DBS MB eCW200302);WQCW(OCBC Bk MB eCW200302);UQXW(OCBC Bk MB ePW200302);LSOW(Venture MB eCW200302);NYHW(YangzijiMBeCW200302)",
			"underlying": "DBS;OCBC;VENTURE;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "02 Mar 20"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2020 (First Notice)",
			"id": 2893,
			"tickers": [
				"FIPW(HSI29800MBeCW200330)",
				"OMDW(HSI26600MBeCW200330)",
				"DKYW(HSI25600MBePW200330)",
				"FSRW(HSI27400MBeCW200330)",
				"QTWW(HSI26400MBePW200330)",
				"WBFW(HSI28000MBePW200330)",
				"LSVW(HSI27200MBePW200330)",
				"MKEW(HSI28200MBeCW200330)",
				"IPZW(HSI24800MBePW200330)",
				"FAXW(HSI29000MBeCW200330)"
			],
			"related_warrant": "FIPW(HSI29800MBeCW200330);OMDW(HSI26600MBeCW200330);DKYW(HSI25600MBePW200330);FSRW(HSI27400MBeCW200330);QTWW(HSI26400MBePW200330);WBFW(HSI28000MBePW200330);LSVW(HSI27200MBePW200330);MKEW(HSI28200MBeCW200330);IPZW(HSI24800MBePW200330);FAXW(HSI29000MBeCW200330)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 28 February 2020 (Third Notice)",
			"id": 2897,
			"tickers": [
				"W7PW(STI 3100MBeCW200228)",
				"RGOW(STI 3300MBeCW200228)",
				"PDRW(STI 3000MBePW200228)"
			],
			"related_warrant": "W7PW(STI 3100MBeCW200228);RGOW(STI 3300MBeCW200228);PDRW(STI 3000MBePW200228)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2020 (Third Expiry)",
			"id": 2889,
			"tickers": [
				"LYOW(HSI27000MBePW200227)",
				"TIKW(HSI27200MBeCW200227)",
				"SSDW(HSI26200MBePW200227)",
				"IYVW(HSI28000MBeCW200227)",
				"9LPW(HSI26400MBeCW200227)",
				"JBOW(HSI28800MBeCW200227)",
				"TOKW(HSI25400MBePW200227)",
				"Y0MW(HSI28600MBePW200227)",
				"Y7LW(HSI29600MBeCW200227)"
			],
			"related_warrant": "LYOW(HSI27000MBePW200227);TIKW(HSI27200MBeCW200227);SSDW(HSI26200MBePW200227);IYVW(HSI28000MBeCW200227);9LPW(HSI26400MBeCW200227);JBOW(HSI28800MBeCW200227);TOKW(HSI25400MBePW200227);Y0MW(HSI28600MBePW200227);Y7LW(HSI29600MBeCW200227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 20 March 2020 (First Notice)",
			"id": 2885,
			"tickers": [
				"YM7W(S&P 2900MBePW200320)",
				"ZAVW(S&P 3100MBePW200320)",
				"QOBW(S&P 3300MBeCW200320)"
			],
			"related_warrant": "YM7W(S&P 2900MBePW200320);ZAVW(S&P 3100MBePW200320);QOBW(S&P 3300MBeCW200320)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "20 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 13 March 2020 (First Notice)",
			"id": 2881,
			"tickers": [
				"Y0JW(NKY 25000MBeCW200313)",
				"VM8W(NKY 21500MBePW200313)",
				"TKYW(NKY 19000MBePW200313)",
				"GYKW(NKY 22500MBeCW200313)",
				"PWMW(NKY 20000MBeCW200313)"
			],
			"related_warrant": "Y0JW(NKY 25000MBeCW200313);VM8W(NKY 21500MBePW200313);TKYW(NKY 19000MBePW200313);GYKW(NKY 22500MBeCW200313);PWMW(NKY 20000MBeCW200313)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "13 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 9 March 2020 (First Notice)",
			"id": 2873,
			"tickers": [
				"KMDW(Venture MB eCW200309)"
			],
			"related_warrant": "KMDW(Venture MB eCW200309)",
			"underlying": "VENTURE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				}
			],
			"date": "07 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 7 February 2020 (Third Notice)",
			"id": 2877,
			"tickers": [
				"IBCW(DBS MB ePW200207)"
			],
			"related_warrant": "IBCW(DBS MB ePW200207)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "07 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2020 (First Notice - Additional) ",
			"id": 2869,
			"tickers": [
				"9LPW(HSI26400MBeCW200227)"
			],
			"related_warrant": "9LPW(HSI26400MBeCW200227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "05 Feb 20"
		},
		{
			"headline": "Expiry of Warrants on 2 March 2020 (First Notice)",
			"id": 2865,
			"tickers": [
				"XKLW(DBS MB eCW200302)",
				"WQCW(OCBC Bk MB eCW200302)",
				"UQXW(OCBC Bk MB ePW200302)",
				"LSOW(Venture MB eCW200302)",
				"NYHW(YangzijiMBeCW200302)"
			],
			"related_warrant": "XKLW(DBS MB eCW200302);WQCW(OCBC Bk MB eCW200302);UQXW(OCBC Bk MB ePW200302);LSOW(Venture MB eCW200302);NYHW(YangzijiMBeCW200302)",
			"underlying": "DBS;OCBC;VENTURE;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "31 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 30 January 2020 (Third Notice)",
			"id": 2861,
			"tickers": [
				"SCOW(HSI27600MBeCW200130)",
				"WB1W(HSI26800MBeCW200130)",
				"1V2W(HSI28400MBeCW200130)",
				"TAHW(HSI26600MBePW200130)",
				"YQ1W(HSI25800MBePW200130)",
				"DFOW(HSI27400MBePW200130)",
				"VRHW(HSI25000MBePW200130)"
			],
			"related_warrant": "SCOW(HSI27600MBeCW200130);WB1W(HSI26800MBeCW200130);1V2W(HSI28400MBeCW200130);TAHW(HSI26600MBePW200130);YQ1W(HSI25800MBePW200130);DFOW(HSI27400MBePW200130);VRHW(HSI25000MBePW200130)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 28 February 2020 (First Notice)",
			"id": 2857,
			"tickers": [
				"W7PW(STI 3100MBeCW200228)",
				"RGOW(STI 3300MBeCW200228)",
				"PDRW(STI 3000MBePW200228)"
			],
			"related_warrant": "W7PW(STI 3100MBeCW200228);RGOW(STI 3300MBeCW200228);PDRW(STI 3000MBePW200228)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Jan 20"
		},
		{
			"headline": " Expiry of Warrants on 27 February 2020 (First Notice)",
			"id": 2853,
			"tickers": [
				"JBOW(HSI28800MBeCW200227)",
				"Y7LW(HSI29600MBeCW200227)",
				"Y0MW(HSI28600MBePW200227)",
				"TOKW(HSI25400MBePW200227)",
				"IYVW(HSI28000MBeCW200227)",
				"SSDW(HSI26200MBePW200227)",
				"TIKW(HSI27200MBeCW200227)",
				"LYOW(HSI27000MBePW200227)"
			],
			"related_warrant": "JBOW(HSI28800MBeCW200227);Y7LW(HSI29600MBeCW200227);Y0MW(HSI28600MBePW200227);TOKW(HSI25400MBePW200227);IYVW(HSI28000MBeCW200227);SSDW(HSI26200MBePW200227);TIKW(HSI27200MBeCW200227);LYOW(HSI27000MBePW200227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "24 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 20 January 2020 (Third Notice)",
			"id": 2849,
			"tickers": [
				"UBWW(UOB MB eCW200120)"
			],
			"related_warrant": "UBWW(UOB MB eCW200120)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "20 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 9 January 2020 (Third Notice)",
			"id": 2845,
			"tickers": [
				"XLVW(DBS MB eCW200109)",
				"NYXW(DBS MB ePW200109)"
			],
			"related_warrant": "XLVW(DBS MB eCW200109);NYXW(DBS MB ePW200109)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "09 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 7 February 2020 (First Notice)",
			"id": 2837,
			"tickers": [
				"IBCW(DBS MB ePW200207)"
			],
			"related_warrant": "IBCW(DBS MB ePW200207)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "07 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 7 January 2020 (Third Notice)",
			"id": 2841,
			"tickers": [
				"HBXW(UOB MB eCW200107)",
				"TSSW(UOB MB ePW200107)"
			],
			"related_warrant": "HBXW(UOB MB eCW200107);TSSW(UOB MB ePW200107)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "07 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 6 January 2020 (Third Notice)",
			"id": 2833,
			"tickers": [
				"SCFW(CapitalaMBeCW200106)",
				"DDQW(DBS MB eCW200106)",
				"VX2W(UOB MB eCW200106)"
			],
			"related_warrant": "SCFW(CapitalaMBeCW200106);DDQW(DBS MB eCW200106);VX2W(UOB MB eCW200106)",
			"underlying": "CAPLAND;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 3 January 2020 (Third Notice)",
			"id": 2829,
			"tickers": [
				"ROKW(XIAOMI MBeCW200103)",
				"UEAW(XIAOMI MBePW200103)"
			],
			"related_warrant": "ROKW(XIAOMI MBeCW200103);UEAW(XIAOMI MBePW200103)",
			"underlying": "XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "03 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 2 January 2020 (Third Notice)",
			"id": 2825,
			"tickers": [
				"9K9W(DBS MB eCW200102)",
				"LYVW(OCBC Bk MB eCW200102)",
				"QSXW(UOB MB eCW200102)"
			],
			"related_warrant": "9K9W(DBS MB eCW200102);LYVW(OCBC Bk MB eCW200102);QSXW(UOB MB eCW200102)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Jan 20"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2019 (Third Notice)",
			"id": 2817,
			"tickers": [
				"HNTW(SIMSCI 340MBePW191231)",
				"OQOW(STI 3400MBeCW191231)",
				"1YUW(STI 3300MBePW191231)",
				"SBSW(STI 3600MBeCW191231)",
				"UNAW(STI 3100MBePW191231)"
			],
			"related_warrant": "HNTW(SIMSCI 340MBePW191231);OQOW(STI 3400MBeCW191231);1YUW(STI 3300MBePW191231);SBSW(STI 3600MBeCW191231);UNAW(STI 3100MBePW191231)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "31 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 30 Jan 2020 (First Notice)",
			"id": 2821,
			"tickers": [
				"DFOW(HSI27400MBePW200130)"
			],
			"related_warrant": "DFOW(HSI27400MBePW200130)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "31 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 30 January 2020 (First Notice)",
			"id": 2801,
			"tickers": [
				"WB1W(HSI26800MBeCW200130)",
				"1V2W(HSI28400MBeCW200130)",
				"SCOW(HSI27600MBeCW200130)",
				"TAHW(HSI26600MBePW200130)",
				"YQ1W(HSI25800MBePW200130)",
				"VRHW(HSI25000MBePW200130)"
			],
			"related_warrant": "WB1W(HSI26800MBeCW200130);1V2W(HSI28400MBeCW200130);SCOW(HSI27600MBeCW200130);TAHW(HSI26600MBePW200130);YQ1W(HSI25800MBePW200130);VRHW(HSI25000MBePW200130)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2019 (Third Notice)",
			"id": 2805,
			"tickers": [
				"MWOW(DBS MB eCW191230)"
			],
			"related_warrant": "MWOW(DBS MB eCW191230)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "30 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2019 (Third Notice)",
			"id": 2809,
			"tickers": [
				"9IRW(HSI26000MBePW191230)",
				"YFEW(HSI26800MBePW191230)",
				"6HZW(HSI31200MBeCW191230)",
				"FGNW(HSI27200MBeCW191230)",
				"XIEW(HSI26400MBeCW191230)",
				"LGMW(HSI27600MBePW191230)",
				"MMQW(HSI23600MBePW191230)",
				"EUQW(HSI25400MBePW191230)",
				"8ZCW(HSI28000MBeCW191230)",
				"MECW(HSI24600MBePW191230)"
			],
			"related_warrant": "9IRW(HSI26000MBePW191230);YFEW(HSI26800MBePW191230);6HZW(HSI31200MBeCW191230);FGNW(HSI27200MBeCW191230);XIEW(HSI26400MBeCW191230);LGMW(HSI27600MBePW191230);MMQW(HSI23600MBePW191230);EUQW(HSI25400MBePW191230);8ZCW(HSI28000MBeCW191230);MECW(HSI24600MBePW191230)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 20 December 2019 (Third Notice)",
			"id": 2797,
			"tickers": [
				"IPKW(S&P 2600MBePW191220)",
				"X2WW(S&P 3000MBeCW191220)",
				"JPCW(S&P 3100MBeCW191220)",
				"DDMW(S&P 2800MBePW191220)",
				"TLNW(S&P 2700MBePW191220)"
			],
			"related_warrant": "IPKW(S&P 2600MBePW191220);X2WW(S&P 3000MBeCW191220);JPCW(S&P 3100MBeCW191220);DDMW(S&P 2800MBePW191220);TLNW(S&P 2700MBePW191220)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "23 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 20 January 2020 (First Notice)",
			"id": 2793,
			"tickers": [
				"UBWW(UOB MB eCW200120)"
			],
			"related_warrant": "UBWW(UOB MB eCW200120)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "20 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 9 December 2019 (Third Notice)",
			"id": 2785,
			"tickers": [
				"MMVW(DBS MB eCW191209)"
			],
			"related_warrant": "MMVW(DBS MB eCW191209)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "09 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 9 January 2020 (First Notice)",
			"id": 2789,
			"tickers": [
				"XLVW(DBS MB eCW200109)",
				"NYXW(DBS MB ePW200109)"
			],
			"related_warrant": "XLVW(DBS MB eCW200109);NYXW(DBS MB ePW200109)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "09 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 6 January 2020 (First Notice)",
			"id": 2777,
			"tickers": [
				"SCFW(CapitalaMBeCW200106)",
				"DDQW(DBS MB eCW200106)",
				"VX2W(UOB MB eCW200106)"
			],
			"related_warrant": "SCFW(CapitalaMBeCW200106);DDQW(DBS MB eCW200106);VX2W(UOB MB eCW200106)",
			"underlying": "CAPLAND;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 7 January 2020 (First Notice)",
			"id": 2781,
			"tickers": [
				"HBXW(UOB MB eCW200107)",
				"TSSW(UOB MB ePW200107)"
			],
			"related_warrant": "HBXW(UOB MB eCW200107);TSSW(UOB MB ePW200107)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 3 January 2020 (First Notice)",
			"id": 2773,
			"tickers": [
				"ROKW(XIAOMI MBeCW200103)",
				"UEAW(XIAOMI MBePW200103)"
			],
			"related_warrant": "ROKW(XIAOMI MBeCW200103);UEAW(XIAOMI MBePW200103)",
			"underlying": "XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "03 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2019 (Third Notice)",
			"id": 2765,
			"tickers": [
				"IBSW(DBS MB ePW191202)",
				"KXWW(OCBC Bk MB eCW191202)",
				"TXSW(OCBC Bk MB ePW191202)",
				"RTRW(UOB MB ePW191202)"
			],
			"related_warrant": "IBSW(DBS MB ePW191202);KXWW(OCBC Bk MB eCW191202);TXSW(OCBC Bk MB ePW191202);RTRW(UOB MB ePW191202)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 2 January 2020 (First Notice)",
			"id": 2769,
			"tickers": [
				"9K9W(DBS MB eCW200102)",
				"LYVW(OCBC Bk MB eCW200102)",
				"QSXW(UOB MB eCW200102)"
			],
			"related_warrant": "9K9W(DBS MB eCW200102);LYVW(OCBC Bk MB eCW200102);QSXW(UOB MB eCW200102)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Dec 19"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2019 (First Notice)",
			"id": 2753,
			"tickers": [
				"MWOW(DBS MB eCW191230)"
			],
			"related_warrant": "MWOW(DBS MB eCW191230)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "29 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2019 (First Notice)",
			"id": 2757,
			"tickers": [
				"MMQW(HSI23600MBePW191230)",
				"LGMW(HSI27600MBePW191230)",
				"XIEW(HSI26400MBeCW191230)",
				"FGNW(HSI27200MBeCW191230)",
				"6HZW(HSI31200MBeCW191230)",
				"YFEW(HSI26800MBePW191230)",
				"9IRW(HSI26000MBePW191230)",
				"EUQW(HSI25400MBePW191230)",
				"8ZCW(HSI28000MBeCW191230)",
				"MECW(HSI24600MBePW191230)"
			],
			"related_warrant": "MMQW(HSI23600MBePW191230);LGMW(HSI27600MBePW191230);XIEW(HSI26400MBeCW191230);FGNW(HSI27200MBeCW191230);6HZW(HSI31200MBeCW191230);YFEW(HSI26800MBePW191230);9IRW(HSI26000MBePW191230);EUQW(HSI25400MBePW191230);8ZCW(HSI28000MBeCW191230);MECW(HSI24600MBePW191230)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2019 (First Notice)",
			"id": 2761,
			"tickers": [
				"OQOW(STI 3400MBeCW191231)",
				"UNAW(STI 3100MBePW191231)",
				"1YUW(STI 3300MBePW191231)",
				"SBSW(STI 3600MBeCW191231)",
				"HNTW(SIMSCI 340MBePW191231)"
			],
			"related_warrant": "OQOW(STI 3400MBeCW191231);UNAW(STI 3100MBePW191231);1YUW(STI 3300MBePW191231);SBSW(STI 3600MBeCW191231);HNTW(SIMSCI 340MBePW191231)",
			"underlying": "STI;SiMSCI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 28 November 2019 (Third Notice)",
			"id": 2749,
			"tickers": [
				"NIPW(HSI26200MBeCW191128)",
				"X5VW(HSI27800MBeCW191128)",
				"YT9W(HSI25600MBePW191128)",
				"UQFW(HSI26400MBePW191128)",
				"STEW(HSI24800MBePW191128)",
				"9H7W(HSI27000MBeCW191128)"
			],
			"related_warrant": "NIPW(HSI26200MBeCW191128);X5VW(HSI27800MBeCW191128);YT9W(HSI25600MBePW191128);UQFW(HSI26400MBePW191128);STEW(HSI24800MBePW191128);9H7W(HSI27000MBeCW191128)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 20 December 2019 (First Notice)",
			"id": 2745,
			"tickers": [
				"TLNW(S&P 2700MBePW191220)",
				"JPCW(S&P 3100MBeCW191220)",
				"IPKW(S&P 2600MBePW191220)",
				"DDMW(S&P 2800MBePW191220)",
				"X2WW(S&P 3000MBeCW191220)"
			],
			"related_warrant": "TLNW(S&P 2700MBePW191220);JPCW(S&P 3100MBeCW191220);IPKW(S&P 2600MBePW191220);DDMW(S&P 2800MBePW191220);X2WW(S&P 3000MBeCW191220)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "20 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 9 December 2019 (First Notice)",
			"id": 2741,
			"tickers": [
				"MMVW(DBS MB eCW191209)"
			],
			"related_warrant": "MMVW(DBS MB eCW191209)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "08 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2019 (First Notice)",
			"id": 2733,
			"tickers": [
				"KXWW(OCBC Bk MB eCW191202)",
				"TXSW(OCBC Bk MB ePW191202)",
				"IBSW(DBS MB ePW191202)",
				"RTRW(UOB MB ePW191202)"
			],
			"related_warrant": "KXWW(OCBC Bk MB eCW191202);TXSW(OCBC Bk MB ePW191202);IBSW(DBS MB ePW191202);RTRW(UOB MB ePW191202)",
			"underlying": "OCBC;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "01 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 1 November 2019 (Third Notice)",
			"id": 2737,
			"tickers": [
				"XYGW(KepCorp MBeCW191101)",
				"9SDW(SingTelMBeCW191101)"
			],
			"related_warrant": "XYGW(KepCorp MBeCW191101);9SDW(SingTelMBeCW191101)",
			"underlying": "KEPCORP;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "01 Nov 19"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2019 (Third Notice)",
			"id": 2729,
			"tickers": [
				"PCAW(HSI25800MBeCW191030)",
				"ZFHW(HSI26600MBeCW191030)",
				"YTJW(HSI26800MBePW191030)",
				"IUYW(HSI23600MBePW191030)",
				"JKVW(HSI24400MBePW191030)",
				"KMPW(HSI26000MBePW191030)",
				"XIVW(HSI25200MBePW191030)",
				"56AW(HSI27400MBeCW191030)"
			],
			"related_warrant": "PCAW(HSI25800MBeCW191030);ZFHW(HSI26600MBeCW191030);YTJW(HSI26800MBePW191030);IUYW(HSI23600MBePW191030);JKVW(HSI24400MBePW191030);KMPW(HSI26000MBePW191030);XIVW(HSI25200MBePW191030);56AW(HSI27400MBeCW191030)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Oct 19"
		},
		{
			"headline": "Expiry of Warrants on 28 November 2019 (First Notice)",
			"id": 2725,
			"tickers": [
				"YT9W(HSI25600MBePW191128)",
				"X5VW(HSI27800MBeCW191128)",
				"UQFW(HSI26400MBePW191128)",
				"NIPW(HSI26200MBeCW191128)",
				"STEW(HSI24800MBePW191128)",
				"9H7W(HSI27000MBeCW191128)"
			],
			"related_warrant": "YT9W(HSI25600MBePW191128);X5VW(HSI27800MBeCW191128);UQFW(HSI26400MBePW191128);NIPW(HSI26200MBeCW191128);STEW(HSI24800MBePW191128);9H7W(HSI27000MBeCW191128)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "25 Oct 19"
		},
		{
			"headline": "Expiry of Warrants on 7 October 2019 (Third Notice)",
			"id": 2721,
			"tickers": [
				"VVPW(DBS MB eCW191007)",
				"NRRW(UOB MB eCW191007)"
			],
			"related_warrant": "VVPW(DBS MB eCW191007);NRRW(UOB MB eCW191007)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "07 Oct 19"
		},
		{
			"headline": "Expiry of Warrants on 1 November 2019 (First Notice)",
			"id": 2713,
			"tickers": [
				"XYGW(KepCorp MBeCW191101)",
				"9SDW(SingTelMBeCW191101)"
			],
			"related_warrant": "XYGW(KepCorp MBeCW191101);9SDW(SingTelMBeCW191101)",
			"underlying": "KEPCORP;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "01 Oct 19"
		},
		{
			"headline": "Expiry of Warrants on 1 October 2019 (Third Notice)",
			"id": 2717,
			"tickers": [
				"TYUW(SembMar MBeCW191001)"
			],
			"related_warrant": "TYUW(SembMar MBeCW191001)",
			"underlying": "SEMBMAR",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				}
			],
			"date": "01 Oct 19"
		},
		{
			"headline": "Expiry of Warrants on 30 September 2019 (Third Notice)",
			"id": 2705,
			"tickers": [
				"XMSW(STI 3000 MBeCW190930)",
				"DQFW(STI 2700MBePW190930)",
				"XNRW(SIMSCI320MBePW190930)",
				"ENBW(SIMSCI400MBeCW190930)"
			],
			"related_warrant": "XMSW(STI 3000 MBeCW190930);DQFW(STI 2700MBePW190930);XNRW(SIMSCI320MBePW190930);ENBW(SIMSCI400MBeCW190930)",
			"underlying": "STI;SiMSCI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Sep 19"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2019 (First Notice)",
			"id": 2709,
			"tickers": [
				"IUYW(HSI23600MBePW191030)",
				"JKVW(HSI24400MBePW191030)",
				"KMPW(HSI26000MBePW191030)",
				"56AW(HSI27400MBeCW191030)",
				"YTJW(HSI26800MBePW191030)",
				"ZFHW(HSI26600MBeCW191030)",
				"PCAW(HSI25800MBeCW191030)",
				"XIVW(HSI25200MBePW191030)"
			],
			"related_warrant": "IUYW(HSI23600MBePW191030);JKVW(HSI24400MBePW191030);KMPW(HSI26000MBePW191030);56AW(HSI27400MBeCW191030);YTJW(HSI26800MBePW191030);ZFHW(HSI26600MBeCW191030);PCAW(HSI25800MBeCW191030);XIVW(HSI25200MBePW191030)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Sep 19"
		},
		{
			"headline": "Expiry of Warrants on 27 September 2019 (Third Notice)",
			"id": 2701,
			"tickers": [
				"MJWW(HSI28000MBePW190927)",
				"QOHW(HSI24800MBePW190927)",
				"HHMW(HSI29000MBeCW190927)",
				"YJMW(HSI25400MBeCW190927)",
				"W5MW(HSI25600MBePW190927)",
				"ZUVW(HSI24000MBePW190927)",
				"FEMW(HSI27000MBeCW190927)",
				"OGAW(HSI26200MBeCW190927)",
				"YVSW(HSI29800MBeCW190927)",
				"VBGW(HSI30600MBeCW190927)"
			],
			"related_warrant": "MJWW(HSI28000MBePW190927);QOHW(HSI24800MBePW190927);HHMW(HSI29000MBeCW190927);YJMW(HSI25400MBeCW190927);W5MW(HSI25600MBePW190927);ZUVW(HSI24000MBePW190927);FEMW(HSI27000MBeCW190927);OGAW(HSI26200MBeCW190927);YVSW(HSI29800MBeCW190927);VBGW(HSI30600MBeCW190927)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Sep 19"
		},
		{
			"headline": "Expiry of Warrants on 26 September 2019 (Third Notice)",
			"id": 2697,
			"tickers": [
				"58CW(TencentMBeCW190926)",
				"GXVW(TencentMBePW190926)"
			],
			"related_warrant": "58CW(TencentMBeCW190926);GXVW(TencentMBePW190926)",
			"underlying": "TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "26 Sep 19"
		},
		{
			"headline": "Expiry of Warrants on 20 September 2019 (Third Notice)",
			"id": 2693,
			"tickers": [
				"GSTW(S&P 2850MBeCW190920)",
				"GACW(S&P 2400MBePW190920)"
			],
			"related_warrant": "GSTW(S&P 2850MBeCW190920);GACW(S&P 2400MBePW190920)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "23 Sep 19"
		},
		{
			"headline": "Expiry of Warrants on 13 September 2019 (Third Notice)",
			"id": 2689,
			"tickers": [
				"DTUW(NKY 23000MBeCW190913)",
				"SYNW(NKY 21000MBePW190913)",
				"KFVW(NKY 18000MBePW190913)",
				"JCGW(NKY 20000MBeCW190913)"
			],
			"related_warrant": "DTUW(NKY 23000MBeCW190913);SYNW(NKY 21000MBePW190913);KFVW(NKY 18000MBePW190913);JCGW(NKY 20000MBeCW190913)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "13 Sep 19"
		},
		{
			"headline": "Expiry of Warrants on 7 October 2019 (First Notice)",
			"id": 2685,
			"tickers": [
				"VVPW(DBS MB eCW191007)",
				"NRRW(UOB MB eCW191007)"
			],
			"related_warrant": "VVPW(DBS MB eCW191007);NRRW(UOB MB eCW191007)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Sep 19"
		},
		{
			"headline": "Expiry of Warrants on 30 September 2019 (First Notice)",
			"id": 2677,
			"tickers": [
				"XNRW(SIMSCI320MBePW190930)",
				"ENBW(SIMSCI400MBeCW190930)",
				"XMSW(STI 3000 MBeCW190930)",
				"DQFW(STI 2700MBePW190930)"
			],
			"related_warrant": "XNRW(SIMSCI320MBePW190930);ENBW(SIMSCI400MBeCW190930);XMSW(STI 3000 MBeCW190930);DQFW(STI 2700MBePW190930)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 1 October 2019 (First Notice)",
			"id": 2681,
			"tickers": [
				"TYUW(SembMar MBeCW191001)"
			],
			"related_warrant": "TYUW(SembMar MBeCW191001)",
			"underlying": "SEMBMAR",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				}
			],
			"date": "30 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 29 August 2019 (Third Notice)",
			"id": 2673,
			"tickers": [
				"KTAW(HSI28400MBeCW190829)",
				"FFEW(HSI28600MBePW190829)",
				"WB6W(HSI25400MBePW190829)",
				"9EHW(HSI27600MBeCW190829)",
				"V6NW(HSI30000MBeCW190829)",
				"TQWW(HSI27000MBePW190829)",
				"9WBW(HSI29200MBeCW190829)",
				"VP7W(HSI27800MBePW190829)",
				"MNKW(HSI26200MBePW190829)"
			],
			"related_warrant": "KTAW(HSI28400MBeCW190829);FFEW(HSI28600MBePW190829);WB6W(HSI25400MBePW190829);9EHW(HSI27600MBeCW190829);V6NW(HSI30000MBeCW190829);TQWW(HSI27000MBePW190829);9WBW(HSI29200MBeCW190829);VP7W(HSI27800MBePW190829);MNKW(HSI26200MBePW190829)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 27 September 2019 (First Notice)",
			"id": 2669,
			"tickers": [
				"VBGW(HSI30600MBeCW190927)",
				"OGAW(HSI26200MBeCW190927)",
				"W5MW(HSI25600MBePW190927)",
				"FEMW(HSI27000MBeCW190927)",
				"YVSW(HSI29800MBeCW190927)",
				"MJWW(HSI28000MBePW190927)",
				"YJMW(HSI25400MBeCW190927)",
				"HHMW(HSI29000MBeCW190927)",
				"ZUVW(HSI24000MBePW190927)",
				"QOHW(HSI24800MBePW190927)"
			],
			"related_warrant": "VBGW(HSI30600MBeCW190927);OGAW(HSI26200MBeCW190927);W5MW(HSI25600MBePW190927);FEMW(HSI27000MBeCW190927);YVSW(HSI29800MBeCW190927);MJWW(HSI28000MBePW190927);YJMW(HSI25400MBeCW190927);HHMW(HSI29000MBeCW190927);ZUVW(HSI24000MBePW190927);QOHW(HSI24800MBePW190927)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 26 September 2019 (First Notice)",
			"id": 2665,
			"tickers": [
				"58CW(TencentMBeCW190926)",
				"GXVW(TencentMBePW190926)"
			],
			"related_warrant": "58CW(TencentMBeCW190926);GXVW(TencentMBePW190926)",
			"underlying": "TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "26 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 20 September 2019 (First Notice)",
			"id": 2661,
			"tickers": [
				"GACW(S&P 2400MBePW190920)",
				"GSTW(S&P 2850MBeCW190920)"
			],
			"related_warrant": "GACW(S&P 2400MBePW190920);GSTW(S&P 2850MBeCW190920)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "20 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 13 September 2019 (First Notice)",
			"id": 2657,
			"tickers": [
				"DTUW(NKY 23000MBeCW190913)",
				"SYNW(NKY 21000MBePW190913)",
				"KFVW(NKY 18000MBePW190913)",
				"JCGW(NKY 20000MBeCW190913)"
			],
			"related_warrant": "DTUW(NKY 23000MBeCW190913);SYNW(NKY 21000MBePW190913);KFVW(NKY 18000MBePW190913);JCGW(NKY 20000MBeCW190913)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "13 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 1 August 2019 (Third Notice)",
			"id": 2653,
			"tickers": [
				"KREW(Wilmar MB eCW190801)"
			],
			"related_warrant": "KREW(Wilmar MB eCW190801)",
			"underlying": "WILMAR INTERNATI",
			"underlyings": [
				{
					"underlying_ric": "WLIL.SI",
					"underlying_ticker": "F34",
					"underlying_name": "WILMAR INTERNATI"
				}
			],
			"date": "01 Aug 19"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2019 (Third Notice)",
			"id": 2641,
			"tickers": [
				"VFFW(HSI28000MBeCW190730)",
				"VMDW(HSI27200MBeCW190730)",
				"UMRW(HSI30400MBeCW190730)",
				"9KRW(HSI27600MBePW190730)",
				"MPSW(HSI25800MBePW190730)",
				"X9DW(HSI26800MBePW190730)",
				"XUGW(HSI26400MBeCW190730)",
				"MTSW(HSI28400MBePW190730)",
				"WVQW(HSI28800MBeCW190730)",
				"52VW(HSI29600MBeCW190730)"
			],
			"related_warrant": "VFFW(HSI28000MBeCW190730);VMDW(HSI27200MBeCW190730);UMRW(HSI30400MBeCW190730);9KRW(HSI27600MBePW190730);MPSW(HSI25800MBePW190730);X9DW(HSI26800MBePW190730);XUGW(HSI26400MBeCW190730);MTSW(HSI28400MBePW190730);WVQW(HSI28800MBeCW190730);52VW(HSI29600MBeCW190730)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2019 (Third Notice)",
			"id": 2645,
			"tickers": [
				"GWKW(DBS MB ePW190730)",
				"PFDW(UOB MB ePW190730)"
			],
			"related_warrant": "GWKW(DBS MB ePW190730);PFDW(UOB MB ePW190730)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "30 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 29 August 2019 (First Notice)",
			"id": 2649,
			"tickers": [
				"FFEW(HSI28600MBePW190829)"
			],
			"related_warrant": "FFEW(HSI28600MBePW190829)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 29 August 2019 (First Notice)",
			"id": 2633,
			"tickers": [
				"9WBW(HSI29200MBeCW190829)",
				"KTAW(HSI28400MBeCW190829)",
				"MNKW(HSI26200MBePW190829)",
				"9EHW(HSI27600MBeCW190829)",
				"V6NW(HSI30000MBeCW190829)",
				"VP7W(HSI27800MBePW190829)",
				"TQWW(HSI27000MBePW190829)",
				"WB6W(HSI25400MBePW190829)"
			],
			"related_warrant": "9WBW(HSI29200MBeCW190829);KTAW(HSI28400MBeCW190829);MNKW(HSI26200MBePW190829);9EHW(HSI27600MBeCW190829);V6NW(HSI30000MBeCW190829);VP7W(HSI27800MBePW190829);TQWW(HSI27000MBePW190829);WB6W(HSI25400MBePW190829)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 29 July 2019 (Third Notice)",
			"id": 2637,
			"tickers": [
				"YN8W(DBS MB ePW190729)",
				"LJIW(UOB MB ePW190729)"
			],
			"related_warrant": "YN8W(DBS MB ePW190729);LJIW(UOB MB ePW190729)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "29 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 8 July 2019 (Third Notice)",
			"id": 2625,
			"tickers": [
				"52AW(OCBC Bk MB ePW190708)"
			],
			"related_warrant": "52AW(OCBC Bk MB ePW190708)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "08 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 2 July 2019 (Third Notice)",
			"id": 2621,
			"tickers": [
				"XBOW(TencentMBePW190702)",
				"YFMW(TencentMBeCW190702)"
			],
			"related_warrant": "XBOW(TencentMBePW190702);YFMW(TencentMBeCW190702)",
			"underlying": "TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "02 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 1 July 2019 (Third Notice)",
			"id": 2613,
			"tickers": [
				"QDGW(KepCorp MBeCW190701)",
				"OUOW(OCBC Bk MB eCW190701)",
				"MVWW(YangzijiMBeCW190701)"
			],
			"related_warrant": "QDGW(KepCorp MBeCW190701);OUOW(OCBC Bk MB eCW190701);MVWW(YangzijiMBeCW190701)",
			"underlying": "KEPCORP;OCBC;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "01 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 1 August 2019 (First Notice)",
			"id": 2617,
			"tickers": [
				"KREW(Wilmar MB eCW190801)"
			],
			"related_warrant": "KREW(Wilmar MB eCW190801)",
			"underlying": "WILMAR INTERNATI",
			"underlyings": [
				{
					"underlying_ric": "WLIL.SI",
					"underlying_ticker": "F34",
					"underlying_name": "WILMAR INTERNATI"
				}
			],
			"date": "01 Jul 19"
		},
		{
			"headline": "Expiry of Warrants on 29 July 2019 (First Notice)",
			"id": 2593,
			"tickers": [
				"YN8W(DBS MB ePW190729)",
				"LJIW(UOB MB ePW190729)"
			],
			"related_warrant": "YN8W(DBS MB ePW190729);LJIW(UOB MB ePW190729)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "28 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2019 (First Notice)",
			"id": 2601,
			"tickers": [
				"GWKW(DBS MB ePW190730)",
				"PFDW(UOB MB ePW190730)"
			],
			"related_warrant": "GWKW(DBS MB ePW190730);PFDW(UOB MB ePW190730)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "28 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 28 June 2019 (Third Notice)",
			"id": 2605,
			"tickers": [
				"5XKW(SIMSCI380MBeCW190628)",
				"FQXW(SIMSCI350MBeCW190628)",
				"Y2WW(SIMSCI300MBePW190628)",
				"CUSW(SIMSCI330MBePW190628)",
				"LRGW(STI 2900MBePW190628)",
				"DVEW(STI 3200MBeCW190628)"
			],
			"related_warrant": "5XKW(SIMSCI380MBeCW190628);FQXW(SIMSCI350MBeCW190628);Y2WW(SIMSCI300MBePW190628);CUSW(SIMSCI330MBePW190628);LRGW(STI 2900MBePW190628);DVEW(STI 3200MBeCW190628)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2019 (First Notice)",
			"id": 2609,
			"tickers": [
				"52VW(HSI29600MBeCW190730)",
				"MPSW(HSI25800MBePW190730)",
				"9KRW(HSI27600MBePW190730)",
				"UMRW(HSI30400MBeCW190730)",
				"VFFW(HSI28000MBeCW190730)",
				"WVQW(HSI28800MBeCW190730)",
				"MTSW(HSI28400MBePW190730)",
				"XUGW(HSI26400MBeCW190730)",
				"X9DW(HSI26800MBePW190730)",
				"VMDW(HSI27200MBeCW190730)"
			],
			"related_warrant": "52VW(HSI29600MBeCW190730);MPSW(HSI25800MBePW190730);9KRW(HSI27600MBePW190730);UMRW(HSI30400MBeCW190730);VFFW(HSI28000MBeCW190730);WVQW(HSI28800MBeCW190730);MTSW(HSI28400MBePW190730);XUGW(HSI26400MBeCW190730);X9DW(HSI26800MBePW190730);VMDW(HSI27200MBeCW190730)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 27 June 2019 (Third Notice)",
			"id": 2589,
			"tickers": [
				"VS8W(HSI27000MBePW190627)",
				"ZX7W(HSI28200MBeCW190627)",
				"DWRW(HSI22000MBePW190627)",
				"MBPW(HSI28000MBePW190627)",
				"VSUW(HSI32000MBeCW190627)",
				"YOSW(HSI29000MBeCW190627)",
				"LAFW(HSI30000MBeCW190627)",
				"JPPW(HSI31000MBeCW190627)",
				"LELW(HSI26000MBePW190627)",
				"CXYW(HSI28800MBePW190627)"
			],
			"related_warrant": "VS8W(HSI27000MBePW190627);ZX7W(HSI28200MBeCW190627);DWRW(HSI22000MBePW190627);MBPW(HSI28000MBePW190627);VSUW(HSI32000MBeCW190627);YOSW(HSI29000MBeCW190627);LAFW(HSI30000MBeCW190627);JPPW(HSI31000MBeCW190627);LELW(HSI26000MBePW190627);CXYW(HSI28800MBePW190627)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 21 June 2019 (Third Notice)",
			"id": 2585,
			"tickers": [
				"XN8W(S&P 2200MBePW190621)",
				"SQZW(S&P 2650MBeCW190621)"
			],
			"related_warrant": "XN8W(S&P 2200MBePW190621);SQZW(S&P 2650MBeCW190621)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "24 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 10 June 2019 (Third Expiry)",
			"id": 2581,
			"tickers": [
				"GKOW(DBS MB ePW190610)",
				"KHBW(UOB MB ePW190610)"
			],
			"related_warrant": "GKOW(DBS MB ePW190610);KHBW(UOB MB ePW190610)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "10 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 8 July 2019 (First Notice)",
			"id": 2577,
			"tickers": [
				"52AW(OCBC Bk MB ePW190708)"
			],
			"related_warrant": "52AW(OCBC Bk MB ePW190708)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "07 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 04 June 2019 (Third Notice)",
			"id": 2573,
			"tickers": [
				"Y6UW(XIAOMI MBeCW190604)",
				"KPLW(XIAOMIMBePW190604)"
			],
			"related_warrant": "Y6UW(XIAOMI MBeCW190604);KPLW(XIAOMIMBePW190604)",
			"underlying": "XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "04 Jun 19"
		},
		{
			"headline": "Expiry of Warrants on 1 July 2019 (First Notice)",
			"id": 2561,
			"tickers": [
				"QDGW(KepCorp MBeCW190701)",
				"OUOW(OCBC Bk MB eCW190701)",
				"MVWW(YangzijiMBeCW190701)"
			],
			"related_warrant": "QDGW(KepCorp MBeCW190701);OUOW(OCBC Bk MB eCW190701);MVWW(YangzijiMBeCW190701)",
			"underlying": "KEPCORP;OCBC;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "31 May 19"
		},
		{
			"headline": "Expiry of Warrants on 2 July 2019 (First Notice)",
			"id": 2565,
			"tickers": [
				"XBOW(TencentMBePW190702)",
				"YFMW(TencentMBeCW190702)"
			],
			"related_warrant": "XBOW(TencentMBePW190702);YFMW(TencentMBeCW190702)",
			"underlying": "TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "31 May 19"
		},
		{
			"headline": "Expiry of Warrants on 30 May 2019 (Third Notice)",
			"id": 2557,
			"tickers": [
				"47LW(HSI29800MBeCW190530)",
				"EPAW(HSI29000MBeCW190530)",
				"5ZWW(HSI27800MBePW190530)",
				"8X1W(HSI28600MBePW190530)",
				"EXOW(HSI29400MBePW190530)",
				"KHQW(HSI30600MBeCW190530)"
			],
			"related_warrant": "47LW(HSI29800MBeCW190530);EPAW(HSI29000MBeCW190530);5ZWW(HSI27800MBePW190530);8X1W(HSI28600MBePW190530);EXOW(HSI29400MBePW190530);KHQW(HSI30600MBeCW190530)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 May 19"
		},
		{
			"headline": "Expiry of Warrants on 28 June 2019 (First Notice)",
			"id": 2553,
			"tickers": [
				"5XKW(SIMSCI380MBeCW190628)",
				"FQXW(SIMSCI350MBeCW190628)",
				"Y2WW(SIMSCI300MBePW190628)",
				"CUSW(SIMSCI330MBePW190628)",
				"LRGW(STI 2900MBePW190628)",
				"DVEW(STI 3200MBeCW190628)"
			],
			"related_warrant": "5XKW(SIMSCI380MBeCW190628);FQXW(SIMSCI350MBeCW190628);Y2WW(SIMSCI300MBePW190628);CUSW(SIMSCI330MBePW190628);LRGW(STI 2900MBePW190628);DVEW(STI 3200MBeCW190628)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 May 19"
		},
		{
			"headline": "Expiry of Warrants on 27 June 2019 (First Notice)",
			"id": 2549,
			"tickers": [
				"MBPW(HSI28000MBePW190627)",
				"VSUW(HSI32000MBeCW190627)",
				"YOSW(HSI29000MBeCW190627)",
				"LAFW(HSI30000MBeCW190627)",
				"DWRW(HSI22000MBePW190627)",
				"ZX7W(HSI28200MBeCW190627)",
				"LELW(HSI26000MBePW190627)",
				"CXYW(HSI28800MBePW190627)",
				"VS8W(HSI27000MBePW190627)",
				"JPPW(HSI31000MBeCW190627)"
			],
			"related_warrant": "MBPW(HSI28000MBePW190627);VSUW(HSI32000MBeCW190627);YOSW(HSI29000MBeCW190627);LAFW(HSI30000MBeCW190627);DWRW(HSI22000MBePW190627);ZX7W(HSI28200MBeCW190627);LELW(HSI26000MBePW190627);CXYW(HSI28800MBePW190627);VS8W(HSI27000MBePW190627);JPPW(HSI31000MBeCW190627)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 May 19"
		},
		{
			"headline": "Expiry of Warrants on 4 June 2019 (First Notice)",
			"id": 2541,
			"tickers": [
				"Y6UW(XIAOMI MBeCW190604)",
				"KPLW(XIAOMIMBePW190604)"
			],
			"related_warrant": "Y6UW(XIAOMI MBeCW190604);KPLW(XIAOMIMBePW190604)",
			"underlying": "XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "03 May 19"
		},
		{
			"headline": "Adjustment Notice",
			"id": 2545,
			"tickers": [
				"KHBW(UOB MB ePW190610)",
				"LJIW(UOB MB ePW190729)",
				"PFDW(UOB MB ePW190730)",
				"NRRW(UOB MB eCW191007)",
				"RTRW(UOB MB ePW191202)",
				"QSXW(UOB MB eCW200102)",
				"VX2W(UOB MB eCW200106)"
			],
			"related_warrant": "KHBW(UOB MB ePW190610);LJIW(UOB MB ePW190729);PFDW(UOB MB ePW190730);NRRW(UOB MB eCW191007);RTRW(UOB MB ePW191202);QSXW(UOB MB eCW200102);VX2W(UOB MB eCW200106)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "03 May 19"
		},
		{
			"headline": "Expiry of Warrants on 30 May 2019 (First Notice)",
			"id": 2537,
			"tickers": [
				"47LW(HSI29800MBeCW190530)",
				"EPAW(HSI29000MBeCW190530)",
				"5ZWW(HSI27800MBePW190530)",
				"8X1W(HSI28600MBePW190530)",
				"EXOW(HSI29400MBePW190530)",
				"KHQW(HSI30600MBeCW190530)"
			],
			"related_warrant": "47LW(HSI29800MBeCW190530);EPAW(HSI29000MBeCW190530);5ZWW(HSI27800MBePW190530);8X1W(HSI28600MBePW190530);EXOW(HSI29400MBePW190530);KHQW(HSI30600MBeCW190530)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 29 April 2019 (Third Notice)",
			"id": 2533,
			"tickers": [
				"FIIW(HSI28600MBeCW190429)",
				"CYKW(HSI29400MBeCW190429)",
				"X5ZW(HSI28800MBePW190429)",
				"UBRW(HSI30200MBeCW190429)",
				"LBIW(HSI28000MBePW190429)",
				"QKJW(HSI26400MBePW190429)",
				"PQJW(HSI27200MBePW190429)"
			],
			"related_warrant": "FIIW(HSI28600MBeCW190429);CYKW(HSI29400MBeCW190429);X5ZW(HSI28800MBePW190429);UBRW(HSI30200MBeCW190429);LBIW(HSI28000MBePW190429);QKJW(HSI26400MBePW190429);PQJW(HSI27200MBePW190429)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 16 April 2019 (Third Notice)",
			"id": 2529,
			"tickers": [
				"QMOW(DBS MB eCW190416)",
				"QGJW(UOB MB eCW190416)"
			],
			"related_warrant": "QMOW(DBS MB eCW190416);QGJW(UOB MB eCW190416)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "16 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 15 April 2019 (Third Notice)",
			"id": 2525,
			"tickers": [
				"XNHW(UOB MB eCW190415)",
				"GFRW(Venture MB eCW190415)"
			],
			"related_warrant": "XNHW(UOB MB eCW190415);GFRW(Venture MB eCW190415)",
			"underlying": "UOB;VENTURE",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				}
			],
			"date": "15 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 8 April 2019 (Third Notice)",
			"id": 2521,
			"tickers": [
				"ZVDW(DBS MB eCW190408)",
				"WIQW(UOB MB eCW190408)"
			],
			"related_warrant": "ZVDW(DBS MB eCW190408);WIQW(UOB MB eCW190408)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "08 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 4 April 2019 (Third Notice)",
			"id": 2517,
			"tickers": [
				"OKLW(CapitalaMBeCW190404)",
				"FIFW(SembMar MBeCW190404)",
				"VCUW(SingTelMBeCW190404)"
			],
			"related_warrant": "OKLW(CapitalaMBeCW190404);FIFW(SembMar MBeCW190404);VCUW(SingTelMBeCW190404)",
			"underlying": "CAPLAND;SEMBMAR;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "04 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 2 April 2019 (Third Notice)",
			"id": 2513,
			"tickers": [
				"OAYW(YangzijiMBeCW190402)"
			],
			"related_warrant": "OAYW(YangzijiMBeCW190402)",
			"underlying": "YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "02 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 15 March 2019 (Third Notice)",
			"id": 2505,
			"tickers": [
				"RNBW(S&P 2900MBeCW190315)",
				"XSQW(S&P 2650MBePW190315)"
			],
			"related_warrant": "RNBW(S&P 2900MBeCW190315);XSQW(S&P 2650MBePW190315)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "01 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 1 April 2019 (Third Notice)",
			"id": 2509,
			"tickers": [
				"ETGW(OCBC Bk MB eCW190401)"
			],
			"related_warrant": "ETGW(OCBC Bk MB eCW190401)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Apr 19"
		},
		{
			"headline": "Expiry of Warrants on 29 April 2019 (First Notice)",
			"id": 2497,
			"tickers": [
				"FIIW(HSI28600MBeCW190429)",
				"PQJW(HSI27200MBePW190429)",
				"X5ZW(HSI28800MBePW190429)",
				"UBRW(HSI30200MBeCW190429)",
				"LBIW(HSI28000MBePW190429)",
				"QKJW(HSI26400MBePW190429)",
				"CYKW(HSI29400MBeCW190429)"
			],
			"related_warrant": "FIIW(HSI28600MBeCW190429);PQJW(HSI27200MBePW190429);X5ZW(HSI28800MBePW190429);UBRW(HSI30200MBeCW190429);LBIW(HSI28000MBePW190429);QKJW(HSI26400MBePW190429);CYKW(HSI29400MBeCW190429)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 29 March 2019 (Third Notice)",
			"id": 2501,
			"tickers": [
				"QKBW(SIMSCI390MBeCW190329)",
				"FWHW(SIMSCI365MBeCW190329)",
				"JXPW(SIMSCI315MBePW190329)",
				"WGRW(SIMSCI340MBePW190329)",
				"MGIW(STI 3400MBeCW190329)",
				"V0MW(STI 3050MBePW190329)"
			],
			"related_warrant": "QKBW(SIMSCI390MBeCW190329);FWHW(SIMSCI365MBeCW190329);JXPW(SIMSCI315MBePW190329);WGRW(SIMSCI340MBePW190329);MGIW(STI 3400MBeCW190329);V0MW(STI 3050MBePW190329)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 28 March 2019 (Third Notice)",
			"id": 2493,
			"tickers": [
				"ZH2W(HSI29000MBeCW190328)",
				"XLFW(HSI24400MBePW190328)",
				"LYEW(HSI26800MBePW190328)",
				"VN8W(HSI27400MBeCW190328)",
				"XG8W(HSI27600MBePW190328)",
				"9N6W(HSI25200MBePW190328)",
				"EOXW(HSI28200MBeCW190328)",
				"YNIW(HSI26000MBePW190328)",
				"JQNW(HSI28400MBePW190328)"
			],
			"related_warrant": "ZH2W(HSI29000MBeCW190328);XLFW(HSI24400MBePW190328);LYEW(HSI26800MBePW190328);VN8W(HSI27400MBeCW190328);XG8W(HSI27600MBePW190328);9N6W(HSI25200MBePW190328);EOXW(HSI28200MBeCW190328);YNIW(HSI26000MBePW190328);JQNW(HSI28400MBePW190328)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 15 April 2019 (First Notice)",
			"id": 2485,
			"tickers": [
				"XNHW(UOB MB eCW190415)",
				"GFRW(Venture MB eCW190415)"
			],
			"related_warrant": "XNHW(UOB MB eCW190415);GFRW(Venture MB eCW190415)",
			"underlying": "UOB;VENTURE",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				}
			],
			"date": "15 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 16 April 2019 (First Notice)",
			"id": 2489,
			"tickers": [
				"QMOW(DBS MB eCW190416)",
				"QGJW(UOB MB eCW190416)"
			],
			"related_warrant": "QMOW(DBS MB eCW190416);QGJW(UOB MB eCW190416)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "15 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 08 April 2019 (First Notice)",
			"id": 2477,
			"tickers": [
				"ZVDW(DBS MB eCW190408)",
				"WIQW(UOB MB eCW190408)"
			],
			"related_warrant": "ZVDW(DBS MB eCW190408);WIQW(UOB MB eCW190408)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "08 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 08 Mar 2019 (Third Notice)",
			"id": 2481,
			"tickers": [
				"VGQW(NKY 21000MBePW190308)",
				"DAPW(NKY 19000MBePW190308)",
				"JITW(NKY 24000MBeCW190308)",
				"XC8W(NKY 22000MBeCW190308)",
				"VRMW(NKY 26000MBeCW190308)",
				"JNFW(NKY 23000MBePW190308)"
			],
			"related_warrant": "VGQW(NKY 21000MBePW190308);DAPW(NKY 19000MBePW190308);JITW(NKY 24000MBeCW190308);XC8W(NKY 22000MBeCW190308);VRMW(NKY 26000MBeCW190308);JNFW(NKY 23000MBePW190308)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "08 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 04 April 2019 (First Notice)",
			"id": 2469,
			"tickers": [
				"OKLW(CapitalaMBeCW190404)",
				"FIFW(SembMar MBeCW190404)",
				"VCUW(SingTelMBeCW190404)"
			],
			"related_warrant": "OKLW(CapitalaMBeCW190404);FIFW(SembMar MBeCW190404);VCUW(SingTelMBeCW190404)",
			"underlying": "CAPLAND;SEMBMAR;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "04 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 04 March 2019 (Third Notice)",
			"id": 2473,
			"tickers": [
				"HIFW(DBS MB eCW190304)",
				"9PSW(DBS MB ePW190304)",
				"ZA0W(UOB MB ePW190304)"
			],
			"related_warrant": "HIFW(DBS MB eCW190304);9PSW(DBS MB ePW190304);ZA0W(UOB MB ePW190304)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "04 Mar 19"
		},
		{
			"headline": " Expiry of Warrants on 1 April 2019 (First Notice) ",
			"id": 2461,
			"tickers": [
				"ETGW(OCBC Bk MB eCW190401)"
			],
			"related_warrant": "ETGW(OCBC Bk MB eCW190401)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Mar 19"
		},
		{
			"headline": " Expiry of Warrants on 2 April 2019 (First Notice) ",
			"id": 2465,
			"tickers": [
				"OAYW(YangzijiMBeCW190402)"
			],
			"related_warrant": "OAYW(YangzijiMBeCW190402)",
			"underlying": "YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "01 Mar 19"
		},
		{
			"headline": "Expiry of Warrants on 28 March 2019 (First Notice)",
			"id": 2453,
			"tickers": [
				"ZH2W(HSI29000MBeCW190328)",
				"XLFW(HSI24400MBePW190328)",
				"LYEW(HSI26800MBePW190328)",
				"VN8W(HSI27400MBeCW190328)",
				"XG8W(HSI27600MBePW190328)",
				"9N6W(HSI25200MBePW190328)",
				"EOXW(HSI28200MBeCW190328)",
				"YNIW(HSI26000MBePW190328)",
				"JQNW(HSI28400MBePW190328)"
			],
			"related_warrant": "ZH2W(HSI29000MBeCW190328);XLFW(HSI24400MBePW190328);LYEW(HSI26800MBePW190328);VN8W(HSI27400MBeCW190328);XG8W(HSI27600MBePW190328);9N6W(HSI25200MBePW190328);EOXW(HSI28200MBeCW190328);YNIW(HSI26000MBePW190328);JQNW(HSI28400MBePW190328)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 29 March 2019 (First Notice)",
			"id": 2457,
			"tickers": [
				"QKBW(SIMSCI390MBeCW190329)",
				"FWHW(SIMSCI365MBeCW190329)",
				"JXPW(SIMSCI315MBePW190329)",
				"WGRW(SIMSCI340MBePW190329)",
				"MGIW(STI 3400MBeCW190329)",
				"V0MW(STI 3050MBePW190329)"
			],
			"related_warrant": "QKBW(SIMSCI390MBeCW190329);FWHW(SIMSCI365MBeCW190329);JXPW(SIMSCI315MBePW190329);WGRW(SIMSCI340MBePW190329);MGIW(STI 3400MBeCW190329);V0MW(STI 3050MBePW190329)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2019 (Third Notice)",
			"id": 2449,
			"tickers": [
				"HESW(HSI24600MBePW190227)",
				"LOPW(HSI23800MBePW190227)",
				"HRRW(HSI25400MBePW190227)",
				"W7NW(HSI27000MBeCW190227)",
				"YY2W(HSI26200MBePW190227)",
				"KBTW(HSI26000MBeCW190227)",
				"RHVW(HSI25200MBeCW190227)"
			],
			"related_warrant": "HESW(HSI24600MBePW190227);LOPW(HSI23800MBePW190227);HRRW(HSI25400MBePW190227);W7NW(HSI27000MBeCW190227);YY2W(HSI26200MBePW190227);KBTW(HSI26000MBeCW190227);RHVW(HSI25200MBeCW190227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 22 February 2019 (Third Notice)",
			"id": 2445,
			"tickers": [
				"9ZTW(SembMar MBeCW190222)",
				"XF3W(OCBC Bk MB ePW190222)",
				"YIFW(SGX MB eCW190222)",
				"LSPW(Venture MB eCW190222)",
				"VF2W(CapitalaMBeCW190222)",
				"9YXW(YangzijiMBeCW190222)"
			],
			"related_warrant": "9ZTW(SembMar MBeCW190222);XF3W(OCBC Bk MB ePW190222);YIFW(SGX MB eCW190222);LSPW(Venture MB eCW190222);VF2W(CapitalaMBeCW190222);9YXW(YangzijiMBeCW190222)",
			"underlying": "SEMBMAR;OCBC;SGX;VENTURE;CAPLAND;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "22 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 15 March 2019 (First Notice)",
			"id": 2441,
			"tickers": [
				"XSQW(S&P 2650MBePW190315)",
				"RNBW(S&P 2900MBeCW190315)"
			],
			"related_warrant": "XSQW(S&P 2650MBePW190315);RNBW(S&P 2900MBeCW190315)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "15 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 8 March 2019 (First Notice)",
			"id": 2437,
			"tickers": [
				"VGQW(NKY 21000MBePW190308)",
				"JITW(NKY 24000MBeCW190308)",
				"VRMW(NKY 26000MBeCW190308)",
				"JNFW(NKY 23000MBePW190308)",
				"XC8W(NKY 22000MBeCW190308)",
				"DAPW(NKY 19000MBePW190308)"
			],
			"related_warrant": "VGQW(NKY 21000MBePW190308);JITW(NKY 24000MBeCW190308);VRMW(NKY 26000MBeCW190308);JNFW(NKY 23000MBePW190308);XC8W(NKY 22000MBeCW190308);DAPW(NKY 19000MBePW190308)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "08 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 4 March 2019 ( First Notice)",
			"id": 2425,
			"tickers": [
				"HIFW(DBS MB eCW190304)",
				"9PSW(DBS MB ePW190304)",
				"ZA0W(UOB MB ePW190304)"
			],
			"related_warrant": "HIFW(DBS MB eCW190304);9PSW(DBS MB ePW190304);ZA0W(UOB MB ePW190304)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "04 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 4 February 2019 (Third Notice)",
			"id": 2433,
			"tickers": [
				"MPAW(XIAOMI20MBeCW190204)",
				"DVQW(XIAOMI24MBeCW190204)",
				"PGXW(XIAOMIMBePW190204)",
				"FKUW(XIAOMI30MBeCW190204)"
			],
			"related_warrant": "MPAW(XIAOMI20MBeCW190204);DVQW(XIAOMI24MBeCW190204);PGXW(XIAOMIMBePW190204);FKUW(XIAOMI30MBeCW190204)",
			"underlying": "XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "04 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 1 February 2019 (Third Notice)",
			"id": 2421,
			"tickers": [
				"55QW(SingTelMBeCW190201)"
			],
			"related_warrant": "55QW(SingTelMBeCW190201)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "01 Feb 19"
		},
		{
			"headline": "Expiry of Warrants on 31 January 2019 (Third Notice)",
			"id": 2417,
			"tickers": [
				"WN9W(DBS MB eCW190131)"
			],
			"related_warrant": "WN9W(DBS MB eCW190131)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "31 Jan 19"
		},
		{
			"headline": "Expiry of Warrants on 30 January 2019 (Third Notice)",
			"id": 2413,
			"tickers": [
				"X3EW(HSI27600MBeCW190130)",
				"FDNW(HSI27800MBeCW190130)",
				"VYNW(HSI23200MBePW190130)",
				"5S7W(HSI26800MBeCW190130)",
				"WWTW(HSI24800MBePW190130)",
				"9NLW(HSI25200MBePW190130)",
				"VPJW(HSI24200MBePW190130)",
				"RDBW(HSI28800MBeCW190130)",
				"UOAW(HSI25800MBeCW190130)",
				"TLKW(HSI26200MBePW190130)"
			],
			"related_warrant": "X3EW(HSI27600MBeCW190130);FDNW(HSI27800MBeCW190130);VYNW(HSI23200MBePW190130);5S7W(HSI26800MBeCW190130);WWTW(HSI24800MBePW190130);9NLW(HSI25200MBePW190130);VPJW(HSI24200MBePW190130);RDBW(HSI28800MBeCW190130);UOAW(HSI25800MBeCW190130);TLKW(HSI26200MBePW190130)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Jan 19"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2019 (First Notice)",
			"id": 2409,
			"tickers": [
				"KBTW(HSI26000MBeCW190227)",
				"W7NW(HSI27000MBeCW190227)",
				"HESW(HSI24600MBePW190227)",
				"LOPW(HSI23800MBePW190227)",
				"HRRW(HSI25400MBePW190227)",
				"YY2W(HSI26200MBePW190227)",
				"RHVW(HSI25200MBeCW190227)"
			],
			"related_warrant": "KBTW(HSI26000MBeCW190227);W7NW(HSI27000MBeCW190227);HESW(HSI24600MBePW190227);LOPW(HSI23800MBePW190227);HRRW(HSI25400MBePW190227);YY2W(HSI26200MBePW190227);RHVW(HSI25200MBeCW190227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "25 Jan 19"
		},
		{
			"headline": "Expiry of Warrants on 22 February 2019 (First Notice)",
			"id": 2405,
			"tickers": [
				"VF2W(CapitalaMBeCW190222)",
				"9ZTW(SembMar MBeCW190222)",
				"YIFW(SGX MB eCW190222)",
				"LSPW(Venture MB eCW190222)",
				"9YXW(YangzijiMBeCW190222)",
				"XF3W(OCBC Bk MB ePW190222)"
			],
			"related_warrant": "VF2W(CapitalaMBeCW190222);9ZTW(SembMar MBeCW190222);YIFW(SGX MB eCW190222);LSPW(Venture MB eCW190222);9YXW(YangzijiMBeCW190222);XF3W(OCBC Bk MB ePW190222)",
			"underlying": "CAPLAND;SEMBMAR;SGX;VENTURE;YANGZIJIANG;OCBC",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VENTURE"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "22 Jan 19"
		},
		{
			"headline": "Expiry of Warrants on 18 January 2019 (Third Notice)",
			"id": 2401,
			"tickers": [
				"TRWW(DBS MB ePW190118)",
				"DXCW(DBS MB eCW190118)",
				"JLVW(UOB MB eCW190118)"
			],
			"related_warrant": "TRWW(DBS MB ePW190118);DXCW(DBS MB eCW190118);JLVW(UOB MB eCW190118)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "18 Jan 19"
		},
		{
			"headline": "Expiry of Warrants on 4 February 2019 (First Notice)",
			"id": 2397,
			"tickers": [
				"MPAW(XIAOMI20MBeCW190204)",
				"DVQW(XIAOMI24MBeCW190204)",
				"PGXW(XIAOMIMBePW190204)",
				"FKUW(XIAOMI30MBeCW190204)"
			],
			"related_warrant": "MPAW(XIAOMI20MBeCW190204);DVQW(XIAOMI24MBeCW190204);PGXW(XIAOMIMBePW190204);FKUW(XIAOMI30MBeCW190204)",
			"underlying": "XIAOMI-W",
			"underlyings": [
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "1810",
					"underlying_name": "XIAOMI-W"
				},
				{
					"underlying_ric": "1810.HK",
					"underlying_ticker": "XIAOMI-W",
					"underlying_name": "XIAOMI-W"
				}
			],
			"date": "04 Jan 19"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2018 (Third Notice)",
			"id": 2377,
			"tickers": [
				"SYDW(STI 3300MBeCW181231)",
				"EPJW(STI 3500MBeCW181231)",
				"YJQW(STI 3100MBePW181231)",
				"9ISW(STI 3250MBePW181231)",
				"NUZW(SIMSCI330MBePW181231)",
				"9J6W(SIMSCI405MBeCW181231)",
				"HVSW(SIMSCI355MBePW181231)",
				"ESWW(SIMSCI380MBeCW181231)"
			],
			"related_warrant": "SYDW(STI 3300MBeCW181231);EPJW(STI 3500MBeCW181231);YJQW(STI 3100MBePW181231);9ISW(STI 3250MBePW181231);NUZW(SIMSCI330MBePW181231);9J6W(SIMSCI405MBeCW181231);HVSW(SIMSCI355MBePW181231);ESWW(SIMSCI380MBeCW181231)",
			"underlying": "STI;SiMSCI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "31 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 31 January 2019 (First Notice)",
			"id": 2389,
			"tickers": [
				"WN9W(DBS MB eCW190131)"
			],
			"related_warrant": "WN9W(DBS MB eCW190131)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "31 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 1 February 2019 (First Notice)",
			"id": 2385,
			"tickers": [
				"55QW(SingTelMBeCW190201)"
			],
			"related_warrant": "55QW(SingTelMBeCW190201)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "31 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 30 January 2019 (First Notice)",
			"id": 2369,
			"tickers": [
				"9NLW(HSI25200MBePW190130)",
				"TLKW(HSI26200MBePW190130)",
				"X3EW(HSI27600MBeCW190130)",
				"5S7W(HSI26800MBeCW190130)",
				"VPJW(HSI24200MBePW190130)",
				"FDNW(HSI27800MBeCW190130)",
				"UOAW(HSI25800MBeCW190130)",
				"WWTW(HSI24800MBePW190130)",
				"VYNW(HSI23200MBePW190130)",
				"RDBW(HSI28800MBeCW190130)"
			],
			"related_warrant": "9NLW(HSI25200MBePW190130);TLKW(HSI26200MBePW190130);X3EW(HSI27600MBeCW190130);5S7W(HSI26800MBeCW190130);VPJW(HSI24200MBePW190130);FDNW(HSI27800MBeCW190130);UOAW(HSI25800MBeCW190130);WWTW(HSI24800MBePW190130);VYNW(HSI23200MBePW190130);RDBW(HSI28800MBeCW190130)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 28 December 2018 (Third Notice)",
			"id": 2373,
			"tickers": [
				"9SEW(HSI28400MBeCW181228)",
				"JUGW(HSI27200MBeCW181228)",
				"9CDW(HSI23600MBePW181228)",
				"UVMW(HSI27000MBeCW181228)",
				"TOYW(HSI26400MBeCW181228)",
				"UTBW(HSI24400MBePW181228)",
				"LQKW(HSI26000MBePW181228)",
				"WHAW(HSI26800MBePW181228)",
				"JOGW(HSI25600MBeCW181228)",
				"PRBW(HSI25400MBePW181228)",
				"LJBW(HSI29200MBeCW181228)"
			],
			"related_warrant": "9SEW(HSI28400MBeCW181228);JUGW(HSI27200MBeCW181228);9CDW(HSI23600MBePW181228);UVMW(HSI27000MBeCW181228);TOYW(HSI26400MBeCW181228);UTBW(HSI24400MBePW181228);LQKW(HSI26000MBePW181228);WHAW(HSI26800MBePW181228);JOGW(HSI25600MBeCW181228);PRBW(HSI25400MBePW181228);LJBW(HSI29200MBeCW181228)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 21 December 2018 (Third Notice)",
			"id": 2393,
			"tickers": [
				"YR5W(S&P 2600MBePW181221)",
				"CTZW(S&P 2400MBePW181221)",
				"CTYW(S&P 3000MBeCW181221)",
				"CUBW(S&P 2700MBePW181221)"
			],
			"related_warrant": "YR5W(S&P 2600MBePW181221);CTZW(S&P 2400MBePW181221);CTYW(S&P 3000MBeCW181221);CUBW(S&P 2700MBePW181221)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "24 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 21 December 2018 (Third Notice)",
			"id": 2365,
			"tickers": [
				"LLKW(CapitalaMBeCW181221)",
				"TLLW(KepCorp MBeCW181221)"
			],
			"related_warrant": "LLKW(CapitalaMBeCW181221);TLLW(KepCorp MBeCW181221)",
			"underlying": "CAPLAND;KEPCORP",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				}
			],
			"date": "21 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 18 January 2019 (First Notice)",
			"id": 2361,
			"tickers": [
				"TRWW(DBS MB ePW190118)",
				"DXCW(DBS MB eCW190118)",
				"JLVW(UOB MB eCW190118)"
			],
			"related_warrant": "TRWW(DBS MB ePW190118);DXCW(DBS MB eCW190118);JLVW(UOB MB eCW190118)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "18 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 17 December 2018 (Third Notice)",
			"id": 2357,
			"tickers": [
				"KUAW(OCBC Bk MB eCW181217)",
				"CRIW(SembMar MBeCW181217)",
				"YTRW(YangzijiMBeCW181217)"
			],
			"related_warrant": "KUAW(OCBC Bk MB eCW181217);CRIW(SembMar MBeCW181217);YTRW(YangzijiMBeCW181217)",
			"underlying": "OCBC;SEMBMAR;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "17 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 14 December 2018 (Third Notice)",
			"id": 2353,
			"tickers": [
				"CTDW(NKY 20500MBePW181214)",
				"CTEW(NKY 23000MBePW181214)",
				"CTAW(NKY 22500MBeCW181214)",
				"CSZW(NKY 20000MBeCW181214)",
				"CTCW(NKY 18000MBePW181214)",
				"CTBW(NKY 25000MBeCW181214)"
			],
			"related_warrant": "CTDW(NKY 20500MBePW181214);CTEW(NKY 23000MBePW181214);CTAW(NKY 22500MBeCW181214);CSZW(NKY 20000MBeCW181214);CTCW(NKY 18000MBePW181214);CTBW(NKY 25000MBeCW181214)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "14 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 10 December 2018 (Third Notice)",
			"id": 2349,
			"tickers": [
				"FVIW(DBS MB eCW181210)",
				"V6UW(DBS MB ePW181210)",
				"SZTW(UOB MB ePW181210)",
				"FUAW(UOB MB eCW181210)"
			],
			"related_warrant": "FVIW(DBS MB eCW181210);V6UW(DBS MB ePW181210);SZTW(UOB MB ePW181210);FUAW(UOB MB eCW181210)",
			"underlying": "DBS;uob",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "uob"
				}
			],
			"date": "10 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 3 December 2018 (Third Notice)",
			"id": 2345,
			"tickers": [
				"CSUW(OCBC Bk MB ePW181203)",
				"CSTW(OCBC Bk MB eCW181203)"
			],
			"related_warrant": "CSUW(OCBC Bk MB ePW181203);CSTW(OCBC Bk MB eCW181203)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "03 Dec 18"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2018 (First Notice)",
			"id": 2337,
			"tickers": [
				"NUZW(SIMSCI330MBePW181231)",
				"9J6W(SIMSCI405MBeCW181231)",
				"HVSW(SIMSCI355MBePW181231)",
				"ESWW(SIMSCI380MBeCW181231)"
			],
			"related_warrant": "NUZW(SIMSCI330MBePW181231);9J6W(SIMSCI405MBeCW181231);HVSW(SIMSCI355MBePW181231);ESWW(SIMSCI380MBeCW181231)",
			"underlying": "SiMSCI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				}
			],
			"date": "30 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2018 (First Notice)",
			"id": 2341,
			"tickers": [
				"EPJW(STI 3500MBeCW181231)",
				"YJQW(STI 3100MBePW181231)",
				"SYDW(STI 3300MBeCW181231)",
				"9ISW(STI 3250MBePW181231)"
			],
			"related_warrant": "EPJW(STI 3500MBeCW181231);YJQW(STI 3100MBePW181231);SYDW(STI 3300MBeCW181231);9ISW(STI 3250MBePW181231)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 29 November 2018 (Third Expiry)",
			"id": 2333,
			"tickers": [
				"WBCW(HSI25800MBeCW181129)",
				"ZU6W(HSI28200 MBeCW181129)",
				"VYYW(HSI27000MBePW181129)",
				"ZZ9W(HSI29000MBeCW181129)",
				"OLQW(HSI24600MBePW181129)",
				"V9KW(HSI26600MBeCW181129)",
				"JQRW(HSI25400MBePW181129)",
				"9Z8W(HSI26200MBePW181129)",
				"JXIW(HSI27400MBeCW181129)"
			],
			"related_warrant": "WBCW(HSI25800MBeCW181129);ZU6W(HSI28200 MBeCW181129);VYYW(HSI27000MBePW181129);ZZ9W(HSI29000MBeCW181129);OLQW(HSI24600MBePW181129);V9KW(HSI26600MBeCW181129);JQRW(HSI25400MBePW181129);9Z8W(HSI26200MBePW181129);JXIW(HSI27400MBeCW181129)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 28 December 2018 (First Notice)",
			"id": 2329,
			"tickers": [
				"9CDW(HSI23600MBePW181228)",
				"JUGW(HSI27200MBeCW181228)",
				"LQKW(HSI26000MBePW181228)",
				"JOGW(HSI25600MBeCW181228)",
				"PRBW(HSI25400MBePW181228)",
				"TOYW(HSI26400MBeCW181228)",
				"UTBW(HSI24400MBePW181228)",
				"WHAW(HSI26800MBePW181228)",
				"LJBW(HSI29200MBeCW181228)",
				"9SEW(HSI28400MBeCW181228)",
				"UVMW(HSI27000MBeCW181228)"
			],
			"related_warrant": "9CDW(HSI23600MBePW181228);JUGW(HSI27200MBeCW181228);LQKW(HSI26000MBePW181228);JOGW(HSI25600MBeCW181228);PRBW(HSI25400MBePW181228);TOYW(HSI26400MBeCW181228);UTBW(HSI24400MBePW181228);WHAW(HSI26800MBePW181228);LJBW(HSI29200MBeCW181228);9SEW(HSI28400MBeCW181228);UVMW(HSI27000MBeCW181228)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2018 (Third Notice)",
			"id": 2325,
			"tickers": [
				"DTVW(DBS MB eCW181127)"
			],
			"related_warrant": "DTVW(DBS MB eCW181127)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "27 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 26 November 2018 (Third Notice)",
			"id": 2321,
			"tickers": [
				"CTVW(DBS MB eCW181126)",
				"CTWW(DBS MB ePW181126)",
				"CTTW(UOB MB eCW181126)",
				"CTUW(UOB MB ePW181126)"
			],
			"related_warrant": "CTVW(DBS MB eCW181126);CTWW(DBS MB ePW181126);CTTW(UOB MB eCW181126);CTUW(UOB MB ePW181126)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "26 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 22 November 2018 (Third Notice)",
			"id": 2317,
			"tickers": [
				"CSKW(SingPost MBeCW181122)"
			],
			"related_warrant": "CSKW(SingPost MBeCW181122)",
			"underlying": "SINGPOST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGPOST"
				}
			],
			"date": "22 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 21 December 2018 (First Notice)",
			"id": 2309,
			"tickers": [
				"LLKW(CapitalaMBeCW181221)",
				"TLLW(KepCorp MBeCW181221)"
			],
			"related_warrant": "LLKW(CapitalaMBeCW181221);TLLW(KepCorp MBeCW181221)",
			"underlying": "CAPLAND;KEPCORP",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				}
			],
			"date": "21 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 21 December 2018 (First Notice)",
			"id": 2313,
			"tickers": [
				"YR5W(S&P 2600MBePW181221)",
				"CTZW(S&P 2400MBePW181221)",
				"CTYW(S&P 3000MBeCW181221)",
				"CUBW(S&P 2700MBePW181221)"
			],
			"related_warrant": "YR5W(S&P 2600MBePW181221);CTZW(S&P 2400MBePW181221);CTYW(S&P 3000MBeCW181221);CUBW(S&P 2700MBePW181221)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "21 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 17 December 2018 (First Notice)",
			"id": 2305,
			"tickers": [
				"KUAW(OCBC Bk MB eCW181217)",
				"CRIW(SembMar MBeCW181217)",
				"YTRW(YangzijiMBeCW181217)"
			],
			"related_warrant": "KUAW(OCBC Bk MB eCW181217);CRIW(SembMar MBeCW181217);YTRW(YangzijiMBeCW181217)",
			"underlying": "OCBC;SEMBMAR;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "16 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 14 December 2018 (First Notice)",
			"id": 2301,
			"tickers": [
				"CTDW(NKY 20500MBePW181214)",
				"CTEW(NKY 23000MBePW181214)",
				"CTAW(NKY 22500MBeCW181214)",
				"CSZW(NKY 20000MBeCW181214)",
				"CTCW(NKY 18000MBePW181214)",
				"CTBW(NKY 25000MBeCW181214)"
			],
			"related_warrant": "CTDW(NKY 20500MBePW181214);CTEW(NKY 23000MBePW181214);CTAW(NKY 22500MBeCW181214);CSZW(NKY 20000MBeCW181214);CTCW(NKY 18000MBePW181214);CTBW(NKY 25000MBeCW181214)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "14 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 10 December 2018 (First Notice)",
			"id": 2297,
			"tickers": [
				"FVIW(DBS MB eCW181210)",
				"V6UW(DBS MB ePW181210)",
				"FUAW(UOB MB eCW181210)",
				"SZTW(UOB MB ePW181210)"
			],
			"related_warrant": "FVIW(DBS MB eCW181210);V6UW(DBS MB ePW181210);FUAW(UOB MB eCW181210);SZTW(UOB MB ePW181210)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "09 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 5 November 2018 (Third Notice)",
			"id": 2293,
			"tickers": [
				"CSJW(SingTelMBeCW181105)"
			],
			"related_warrant": "CSJW(SingTelMBeCW181105)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "05 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 3 December 2018 (First Notice)",
			"id": 2289,
			"tickers": [
				"CSUW(OCBC Bk MB ePW181203)",
				"CSTW(OCBC Bk MB eCW181203)"
			],
			"related_warrant": "CSUW(OCBC Bk MB ePW181203);CSTW(OCBC Bk MB eCW181203)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "02 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 1 November 2018 (Third Notice)",
			"id": 2281,
			"tickers": [
				"CSCW(OCBC Bk MB eCW181101)",
				"CSDW(OCBC Bk MB ePW181101)",
				"CQNW(SembMar MBeCW181101)",
				"CRVW(YangzijiMBeCW181101)"
			],
			"related_warrant": "CSCW(OCBC Bk MB eCW181101);CSDW(OCBC Bk MB ePW181101);CQNW(SembMar MBeCW181101);CRVW(YangzijiMBeCW181101)",
			"underlying": "OCBC;SEMBMAR;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "01 Nov 18"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2018 (Third Notice)",
			"id": 2273,
			"tickers": [
				"XVJW(HSI25800MBePW181030)",
				"TRXW(HSI29400MBeCW181030)",
				"994W(HSI26600MBePW181030)",
				"ZKGW(HSI28600MBeCW181030)",
				"QBKW(HSI27800MBeCW181030)"
			],
			"related_warrant": "XVJW(HSI25800MBePW181030);TRXW(HSI29400MBeCW181030);994W(HSI26600MBePW181030);ZKGW(HSI28600MBeCW181030);QBKW(HSI27800MBeCW181030)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 29 November 2018 (First Notice)",
			"id": 2269,
			"tickers": [
				"WBCW(HSI25800MBeCW181129)",
				"VYYW(HSI27000MBePW181129)",
				"9Z8W(HSI26200MBePW181129)",
				"ZU6W(HSI28200 MBeCW181129)",
				"OLQW(HSI24600MBePW181129)",
				"ZZ9W(HSI29000MBeCW181129)",
				"JQRW(HSI25400MBePW181129)",
				"JXIW(HSI27400MBeCW181129)",
				"V9KW(HSI26600MBeCW181129)"
			],
			"related_warrant": "WBCW(HSI25800MBeCW181129);VYYW(HSI27000MBePW181129);9Z8W(HSI26200MBePW181129);ZU6W(HSI28200 MBeCW181129);OLQW(HSI24600MBePW181129);ZZ9W(HSI29000MBeCW181129);JQRW(HSI25400MBePW181129);JXIW(HSI27400MBeCW181129);V9KW(HSI26600MBeCW181129)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 26 November 2018 (First Notice)",
			"id": 2261,
			"tickers": [
				"CTVW(DBS MB eCW181126)",
				"CTWW(DBS MB ePW181126)",
				"CTUW(UOB MB ePW181126)",
				"CTTW(UOB MB eCW181126)"
			],
			"related_warrant": "CTVW(DBS MB eCW181126);CTWW(DBS MB ePW181126);CTUW(UOB MB ePW181126);CTTW(UOB MB eCW181126)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "26 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2018 (First Notice)",
			"id": 2265,
			"tickers": [
				"DTVW(DBS MB eCW181127)"
			],
			"related_warrant": "DTVW(DBS MB eCW181127)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "26 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 22 November 2018 (First Notice)",
			"id": 2253,
			"tickers": [
				"CSKW(SingPost MBeCW181122)"
			],
			"related_warrant": "CSKW(SingPost MBeCW181122)",
			"underlying": "SINGPOST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGPOST"
				}
			],
			"date": "22 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 22 October 2018 (Third Notice)",
			"id": 2257,
			"tickers": [
				"CRUW(KepCorp MBeCW181022)"
			],
			"related_warrant": "CRUW(KepCorp MBeCW181022)",
			"underlying": "KEPCORP",
			"underlyings": [
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				}
			],
			"date": "22 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 15 October 2018 (Third Notice)",
			"id": 2249,
			"tickers": [
				"COUW(SingTelMBeCW181015)"
			],
			"related_warrant": "COUW(SingTelMBeCW181015)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "15 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 9 October 2018 (Third Notice)",
			"id": 2245,
			"tickers": [
				"COWW(CapitalaMBeCW181009)",
				"COXW(YangzijiMBeCW181009)"
			],
			"related_warrant": "COWW(CapitalaMBeCW181009);COXW(YangzijiMBeCW181009)",
			"underlying": "CAPLAND;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "09 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 8 October 2018 (Third Notice)",
			"id": 2241,
			"tickers": [
				"COVW(SingTelMBeCW181008)"
			],
			"related_warrant": "COVW(SingTelMBeCW181008)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "08 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 5 November 2018 (First Notice)",
			"id": 2237,
			"tickers": [
				"CSJW(SingTelMBeCW181105)"
			],
			"related_warrant": "CSJW(SingTelMBeCW181105)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "05 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 1 November 2018 (First Notice)",
			"id": 2229,
			"tickers": [
				"CSCW(OCBC Bk MB eCW181101)",
				"CSDW(OCBC Bk MB ePW181101)",
				"CQNW(SembMar MBeCW181101)",
				"CRVW(YangzijiMBeCW181101)"
			],
			"related_warrant": "CSCW(OCBC Bk MB eCW181101);CSDW(OCBC Bk MB ePW181101);CQNW(SembMar MBeCW181101);CRVW(YangzijiMBeCW181101)",
			"underlying": "OCBC;SEMBMAR;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBMAR"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "01 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 1 October 2018 (Third Notice)",
			"id": 2233,
			"tickers": [
				"CSVW(DBS MB ePW181001)",
				"CQIW(KepCorp MBeCW181001)",
				"CSWW(UOB MB ePW181001)"
			],
			"related_warrant": "CSVW(DBS MB ePW181001);CQIW(KepCorp MBeCW181001);CSWW(UOB MB ePW181001)",
			"underlying": "DBS;KEPCORP;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "01 Oct 18"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2018 (First Notice)",
			"id": 2221,
			"tickers": [
				"XVJW(HSI25800MBePW181030)",
				"994W(HSI26600MBePW181030)",
				"ZKGW(HSI28600MBeCW181030)",
				"QBKW(HSI27800MBeCW181030)",
				"TRXW(HSI29400MBeCW181030)"
			],
			"related_warrant": "XVJW(HSI25800MBePW181030);994W(HSI26600MBePW181030);ZKGW(HSI28600MBeCW181030);QBKW(HSI27800MBeCW181030);TRXW(HSI29400MBeCW181030)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 28 September 2018 (Third Notice)",
			"id": 2225,
			"tickers": [
				"CRSW(SIMSCI400MBeCW180928)",
				"CRTW(SIMSCI350MBePW180928)",
				"CTFW(SIMSCI450MBeCW180928)",
				"CSOW(STI 3350MBePW180928)",
				"CSNW(STI 3600MBeCW180928)"
			],
			"related_warrant": "CRSW(SIMSCI400MBeCW180928);CRTW(SIMSCI350MBePW180928);CTFW(SIMSCI450MBeCW180928);CSOW(STI 3350MBePW180928);CSNW(STI 3600MBeCW180928)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 24 September 2018 (Third Notice)",
			"id": 2213,
			"tickers": [
				"CRHW(GentingSMBeCW180924)"
			],
			"related_warrant": "CRHW(GentingSMBeCW180924)",
			"underlying": "GENTINGSIN",
			"underlyings": [
				{
					"underlying_ric": "GENS.SI",
					"underlying_ticker": "G13",
					"underlying_name": "GENTINGSIN"
				}
			],
			"date": "24 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 21 September 2018 (Third Notice)",
			"id": 2217,
			"tickers": [
				"CSGW(S&P 2900MBeCW180921)",
				"CTXW(S&P 2800MBeCW180921)",
				"CUAW(S&P 2550MBePW180921)",
				"CSHW(S&P 2450MBePW180921)"
			],
			"related_warrant": "CSGW(S&P 2900MBeCW180921);CTXW(S&P 2800MBeCW180921);CUAW(S&P 2550MBePW180921);CSHW(S&P 2450MBePW180921)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "24 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 22 October 2018 (First Notice)",
			"id": 2205,
			"tickers": [
				"CRUW(KepCorp MBeCW181022)"
			],
			"related_warrant": "CRUW(KepCorp MBeCW181022)",
			"underlying": "KEPCORP",
			"underlyings": [
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				}
			],
			"date": "21 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 15 October 2018 (First Notice)",
			"id": 2201,
			"tickers": [
				"COVW(SingTelMBeCW181008)"
			],
			"related_warrant": "COVW(SingTelMBeCW181008)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "14 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 10 September 2018 (Third Notice)",
			"id": 2197,
			"tickers": [
				"CPMW(KepCorp MBeCW180910)"
			],
			"related_warrant": "CPMW(KepCorp MBeCW180910)",
			"underlying": "KEPCORP",
			"underlyings": [
				{
					"underlying_ric": "KPLM.SI",
					"underlying_ticker": "BN4",
					"underlying_name": "KEPCORP"
				}
			],
			"date": "10 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 8 October 2018 (First Notice)",
			"id": 2189,
			"tickers": [
				"COVW(SingTelMBeCW181008)"
			],
			"related_warrant": "COVW(SingTelMBeCW181008)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "07 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 9 October 2018 ( First Notice)",
			"id": 2193,
			"tickers": [
				"COWW(CapitalaMBeCW181009)",
				"COXW(YangzijiMBeCW181009)"
			],
			"related_warrant": "COWW(CapitalaMBeCW181009);COXW(YangzijiMBeCW181009)",
			"underlying": "CAPLAND;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "07 Sep 18"
		},
		{
			"headline": "Expiry of Warrants on 1 October 2018 (First Notice)",
			"id": 2185,
			"tickers": [
				"CQIW(KepCorp MBeCW181001)",
				"CSVW(DBS MB ePW181001)",
				"CSWW(UOB MB ePW181001)"
			],
			"related_warrant": "CQIW(KepCorp MBeCW181001);CSVW(DBS MB ePW181001);CSWW(UOB MB ePW181001)",
			"underlying": "KEPPEL CORPORATI;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORPORATI"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "31 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 30 August 2018 (Third Notice)",
			"id": 2181,
			"tickers": [
				"CTIW(HSI29000MBePW180830)",
				"LWPW(HSI31200MBeCW180830)",
				"YBVW(HSI26600MBePW180830)",
				"EOWW(HSI33000MBeCW180830)",
				"LITW(HSI28200MBePW180830)",
				"WYZW(HSI29600MBeCW180830)",
				"IMXW(HSI30000MBePW180830)",
				"CTLW(HSI32000MBeCW180830)",
				"FLGW(HSI30400MBeCW180830)",
				"W0RW(HSI27400MBePW180830)",
				"PQUW(HSI28800MBeCW180830)"
			],
			"related_warrant": "CTIW(HSI29000MBePW180830);LWPW(HSI31200MBeCW180830);YBVW(HSI26600MBePW180830);EOWW(HSI33000MBeCW180830);LITW(HSI28200MBePW180830);WYZW(HSI29600MBeCW180830);IMXW(HSI30000MBePW180830);CTLW(HSI32000MBeCW180830);FLGW(HSI30400MBeCW180830);W0RW(HSI27400MBePW180830);PQUW(HSI28800MBeCW180830)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 28 September 2018 (First Notice)",
			"id": 2177,
			"tickers": [
				"CRSW(SIMSCI400MBeCW180928)",
				"CRTW(SIMSCI350MBePW180928)",
				"CTFW(SIMSCI450MBeCW180928)",
				"CSOW(STI 3350MBePW180928)",
				"CSNW(STI 3600MBeCW180928)"
			],
			"related_warrant": "CRSW(SIMSCI400MBeCW180928);CRTW(SIMSCI350MBePW180928);CTFW(SIMSCI450MBeCW180928);CSOW(STI 3350MBePW180928);CSNW(STI 3600MBeCW180928)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 27 September 2018 (First Notice)",
			"id": 2173,
			"tickers": [
				"SPHW(HSI29200MBeCW180927)",
				"5R2W(HSI27800MBePW180927)",
				"QPBW(HSI30000MBeCW180927)",
				"IJQW(HSI28400MBeCW180927)",
				"LDYW(HSI26200MBePW180927)",
				"UFNW(HSI27600MBeCW180927)",
				"QFIW(HSI27000MBePW180927)",
				"9MAW(HSI30800MBeCW180927)"
			],
			"related_warrant": "SPHW(HSI29200MBeCW180927);5R2W(HSI27800MBePW180927);QPBW(HSI30000MBeCW180927);IJQW(HSI28400MBeCW180927);LDYW(HSI26200MBePW180927);UFNW(HSI27600MBeCW180927);QFIW(HSI27000MBePW180927);9MAW(HSI30800MBeCW180927)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 24 September 2018 (First Notice)",
			"id": 2169,
			"tickers": [
				"CRHW(GentingSMBeCW180924)"
			],
			"related_warrant": "CRHW(GentingSMBeCW180924)",
			"underlying": "GENTINGSIN",
			"underlyings": [
				{
					"underlying_ric": "GENS.SI",
					"underlying_ticker": "G13",
					"underlying_name": "GENTINGSIN"
				}
			],
			"date": "24 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 21 September 2018 (First Notice)",
			"id": 2165,
			"tickers": [
				"CSGW(S&P 2900MBeCW180921)",
				"CSHW(S&P 2450MBePW180921)",
				"CTXW(S&P 2800MBeCW180921)",
				"CUAW(S&P 2550MBePW180921)"
			],
			"related_warrant": "CSGW(S&P 2900MBeCW180921);CSHW(S&P 2450MBePW180921);CTXW(S&P 2800MBeCW180921);CUAW(S&P 2550MBePW180921)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "21 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 16 August 2018 (Third Notice)",
			"id": 2161,
			"tickers": [
				"CPEW(OCBC Bk MB ePW180816)"
			],
			"related_warrant": "CPEW(OCBC Bk MB ePW180816)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "16 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 10 August 2018 (Third Notice) ",
			"id": 2153,
			"tickers": [
				"CQHW(DBS MB eCW180810)"
			],
			"related_warrant": "CQHW(DBS MB eCW180810)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "10 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 10 September 2018 (First Notice)",
			"id": 2157,
			"tickers": [
				"CPMW(KepCorp MBeCW180910)"
			],
			"related_warrant": "CPMW(KepCorp MBeCW180910)",
			"underlying": "KEPPEL CORPORATI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORPORATI"
				}
			],
			"date": "10 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 08 August 2018 (Third Notice) ",
			"id": 2149,
			"tickers": [
				"CQXW(DBS MB eCW180808)"
			],
			"related_warrant": "CQXW(DBS MB eCW180808)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "08 Aug 18"
		},
		{
			"headline": " Expiry of Warrants on 07 August 2018 (Third Notice) ",
			"id": 2145,
			"tickers": [
				"CPFW(DBS MB eCW180807)"
			],
			"related_warrant": "CPFW(DBS MB eCW180807)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "07 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 06 August 2018 (Third Notice)",
			"id": 2141,
			"tickers": [
				"CPGW(DBS MB eCW180806)",
				"CPZW(UOB MB eCW180806)"
			],
			"related_warrant": "CPGW(DBS MB eCW180806);CPZW(UOB MB eCW180806)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 02 August 2018 (Third Notice)",
			"id": 2137,
			"tickers": [
				"COYW(UOB MB eCW180802)"
			],
			"related_warrant": "COYW(UOB MB eCW180802)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 01 August 2018 (Third Notice)",
			"id": 2133,
			"tickers": [
				"COZW(UOB MB eCW180801)"
			],
			"related_warrant": "COZW(UOB MB eCW180801)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "01 Aug 18"
		},
		{
			"headline": "Expiry of Warrants on 31 July 2018 (Third Notice)",
			"id": 2129,
			"tickers": [
				"CPCW(OCBC Bk MB eCW180731)"
			],
			"related_warrant": "CPCW(OCBC Bk MB eCW180731)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "31 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 30 August 2018 (First Notice)",
			"id": 2121,
			"tickers": [
				"WYZW(HSI29600MBeCW180830)",
				"IMXW(HSI30000MBePW180830)",
				"FLGW(HSI30400MBeCW180830)",
				"W0RW(HSI27400MBePW180830)",
				"CTLW(HSI32000MBeCW180830)",
				"PQUW(HSI28800MBeCW180830)",
				"EOWW(HSI33000MBeCW180830)",
				"CTIW(HSI29000MBePW180830)",
				"LWPW(HSI31200MBeCW180830)",
				"LITW(HSI28200MBePW180830)",
				"YBVW(HSI26600MBePW180830)"
			],
			"related_warrant": "WYZW(HSI29600MBeCW180830);IMXW(HSI30000MBePW180830);FLGW(HSI30400MBeCW180830);W0RW(HSI27400MBePW180830);CTLW(HSI32000MBeCW180830);PQUW(HSI28800MBeCW180830);EOWW(HSI33000MBeCW180830);CTIW(HSI29000MBePW180830);LWPW(HSI31200MBeCW180830);LITW(HSI28200MBePW180830);YBVW(HSI26600MBePW180830)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2018 (Third Notice)",
			"id": 2125,
			"tickers": [
				"JNLW(HSI29600MBePW180730)",
				"9F6W(HSI31000MBeCW180730)",
				"CTKW(HSI33400MBeCW180730)",
				"RWDW(HSI29400MBeCW180730)",
				"FXTW(HSI30400MBePW180730)",
				"50HW(HSI32600MBeCW180730)",
				"CTHW(HSI28000MBePW180730)",
				"IKLW(HSI31800MBeCW180730)",
				"ILPW(HSI30200MBeCW180730)"
			],
			"related_warrant": "JNLW(HSI29600MBePW180730);9F6W(HSI31000MBeCW180730);CTKW(HSI33400MBeCW180730);RWDW(HSI29400MBeCW180730);FXTW(HSI30400MBePW180730);50HW(HSI32600MBeCW180730);CTHW(HSI28000MBePW180730);IKLW(HSI31800MBeCW180730);ILPW(HSI30200MBeCW180730)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 26 July 2018 (Third Notice)",
			"id": 2117,
			"tickers": [
				"CPDW(OCBC Bk MB eCW180726)"
			],
			"related_warrant": "CPDW(OCBC Bk MB eCW180726)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "26 Jul 18"
		},
		{
			"headline": "Adjustment Notice",
			"id": 2113,
			"tickers": [
				"CPMW(KepCorp MBeCW180910)",
				"CQIW(KepCorp MBeCW181001)",
				"CRUW(KepCorp MBeCW181022)",
				"TLLW(KepCorp MBeCW181221)"
			],
			"related_warrant": "CPMW(KepCorp MBeCW180910);CQIW(KepCorp MBeCW181001);CRUW(KepCorp MBeCW181022);TLLW(KepCorp MBeCW181221)",
			"underlying": "KEPPEL CORPORATI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORPORATI"
				}
			],
			"date": "24 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 19 July 2018 (Third Notice)",
			"id": 2109,
			"tickers": [
				"CRBW(DBS MB ePW180719)"
			],
			"related_warrant": "CRBW(DBS MB ePW180719)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "19 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 18 July 2018 (Third Notice)",
			"id": 2105,
			"tickers": [
				"CPHW(DBS MB ePW180718)"
			],
			"related_warrant": "CPHW(DBS MB ePW180718)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "18 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 17 July 2018 (Third Notice)",
			"id": 2101,
			"tickers": [
				"CQYW(DBS MB ePW180717)"
			],
			"related_warrant": "CQYW(DBS MB ePW180717)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "17 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 16 August 2018 (First Notice)",
			"id": 2093,
			"tickers": [
				"CPEW(OCBC Bk MB ePW180816)"
			],
			"related_warrant": "CPEW(OCBC Bk MB ePW180816)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "16 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 16 July 2018 (Third Notice)",
			"id": 2097,
			"tickers": [
				"CPIW(DBS MB ePW180716)"
			],
			"related_warrant": "CPIW(DBS MB ePW180716)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "16 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 10 July 2018 (Third Notice)",
			"id": 2085,
			"tickers": [
				"CPAW(UOB MB ePW180710)"
			],
			"related_warrant": "CPAW(UOB MB ePW180710)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "10 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 10 August 2018 (First Notice)",
			"id": 2089,
			"tickers": [
				"CQHW(DBS MB eCW180810)"
			],
			"related_warrant": "CQHW(DBS MB eCW180810)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "10 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 9 July 2018 (Third Notice)",
			"id": 2081,
			"tickers": [
				"CPBW(UOB MB ePW180709)"
			],
			"related_warrant": "CPBW(UOB MB ePW180709)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "09 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 6 August 2018 (First Notice)",
			"id": 2065,
			"tickers": [
				"CPGW(DBS MB eCW180806)",
				"CPZW(UOB MB eCW180806)"
			],
			"related_warrant": "CPGW(DBS MB eCW180806);CPZW(UOB MB eCW180806)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 7 August 2018 (First Notice)",
			"id": 2073,
			"tickers": [
				"CPFW(DBS MB eCW180807)"
			],
			"related_warrant": "CPFW(DBS MB eCW180807)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "06 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 8 August 2018 (First Notice)",
			"id": 2077,
			"tickers": [
				"CQXW(DBS MB eCW180808)"
			],
			"related_warrant": "CQXW(DBS MB eCW180808)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "06 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 2 August 2018 (First Notice)",
			"id": 2057,
			"tickers": [
				"COYW(UOB MB eCW180802)"
			],
			"related_warrant": "COYW(UOB MB eCW180802)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 2 July 2018 (Third Notice)",
			"id": 2061,
			"tickers": [
				"CNGW(GentingSMBeCW180702)",
				"CNLW(OCBC Bk MB ePW180702)",
				"CNMW(OCBC Bk MB eCW180702)",
				"CMXW(Yangziji MB eCW180702)"
			],
			"related_warrant": "CNGW(GentingSMBeCW180702);CNLW(OCBC Bk MB ePW180702);CNMW(OCBC Bk MB eCW180702);CMXW(Yangziji MB eCW180702)",
			"underlying": "GENTINGSIN;OCBC;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "GENS.SI",
					"underlying_ticker": "G13",
					"underlying_name": "GENTINGSIN"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "02 Jul 18"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2018 (Third Notice)",
			"id": 2041,
			"tickers": [
				"CQJW(SIMSCI450MBeCW180629)",
				"CQKW(SIMSCI380MBePW180629)",
				"COSW(STI 3250MBePW180629)",
				"COQW(STI 3450MBePW180629)",
				"CORW(STI 3700MBeCW180629)",
				"COTW(STI 3500MBeCW180629)",
				"CRCW(STI 3450MBePW180629A)"
			],
			"related_warrant": "CQJW(SIMSCI450MBeCW180629);CQKW(SIMSCI380MBePW180629);COSW(STI 3250MBePW180629);COQW(STI 3450MBePW180629);CORW(STI 3700MBeCW180629);COTW(STI 3500MBeCW180629);CRCW(STI 3450MBePW180629A)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2018 (First Notice)",
			"id": 2045,
			"tickers": [
				"FXTW(HSI30400MBePW180730)",
				"CTKW(HSI33400MBeCW180730)",
				"9F6W(HSI31000MBeCW180730)",
				"CTHW(HSI28000MBePW180730)",
				"RWDW(HSI29400MBeCW180730)",
				"IKLW(HSI31800MBeCW180730)",
				"ILPW(HSI30200MBeCW180730)",
				"JNLW(HSI29600MBePW180730)",
				"50HW(HSI32600MBeCW180730)"
			],
			"related_warrant": "FXTW(HSI30400MBePW180730);CTKW(HSI33400MBeCW180730);9F6W(HSI31000MBeCW180730);CTHW(HSI28000MBePW180730);RWDW(HSI29400MBeCW180730);IKLW(HSI31800MBeCW180730);ILPW(HSI30200MBeCW180730);JNLW(HSI29600MBePW180730);50HW(HSI32600MBeCW180730)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 31 July 2018 (First Notice)",
			"id": 2049,
			"tickers": [
				"CPCW(OCBC Bk MB eCW180731)"
			],
			"related_warrant": "CPCW(OCBC Bk MB eCW180731)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "29 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 01 Aug 2018 (First Notice)",
			"id": 2053,
			"tickers": [
				"COZW(UOB MB eCW180801)"
			],
			"related_warrant": "COZW(UOB MB eCW180801)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "29 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 28 June 2018 (Third Notice)",
			"id": 2037,
			"tickers": [
				"CTGW(HSI29400MBePW180628)",
				"CTRW(HSI31000MBePW180628)",
				"CTJW(HSI31400MBeCW180628)",
				"CSLW(HSI32200MBeCW180628)",
				"CTSW(HSI30200MBePW180628)",
				"CSMW(HSI28600MBePW180628)"
			],
			"related_warrant": "CTGW(HSI29400MBePW180628);CTRW(HSI31000MBePW180628);CTJW(HSI31400MBeCW180628);CSLW(HSI32200MBeCW180628);CTSW(HSI30200MBePW180628);CSMW(HSI28600MBePW180628)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 26 July 2018 (First Notice)",
			"id": 2033,
			"tickers": [
				"CPDW(OCBC Bk MB eCW180726)"
			],
			"related_warrant": "CPDW(OCBC Bk MB eCW180726)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "26 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 19 July 2018 (First Notice)",
			"id": 2029,
			"tickers": [
				"CRBW(DBS MB ePW180719)"
			],
			"related_warrant": "CRBW(DBS MB ePW180719)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "19 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 18 June 2018 (Third Notice)",
			"id": 2021,
			"tickers": [
				"CMEW(S&P 2800MBeCW180618)",
				"CMFW(S&P 2400MBePW180618)"
			],
			"related_warrant": "CMEW(S&P 2800MBeCW180618);CMFW(S&P 2400MBePW180618)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "18 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 18 Jul 2018 (First Notice)",
			"id": 2025,
			"tickers": [
				"CPHW(DBS MB ePW180718)"
			],
			"related_warrant": "CPHW(DBS MB ePW180718)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "18 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 17 July 2018 (First Notice)",
			"id": 2013,
			"tickers": [
				"CQYW(DBS MB ePW180717)"
			],
			"related_warrant": "CQYW(DBS MB ePW180717)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "14 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 16 July 2018 (First Notice)",
			"id": 2017,
			"tickers": [
				"CPIW(DBS MB ePW180716)"
			],
			"related_warrant": "CPIW(DBS MB ePW180716)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "14 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 9 July 2018 (First Notice)",
			"id": 2001,
			"tickers": [
				"CPBW(UOB MB ePW180709)"
			],
			"related_warrant": "CPBW(UOB MB ePW180709)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "08 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 10 July 2018 (First Notice)",
			"id": 2005,
			"tickers": [
				"CPAW(UOB MB ePW180710)"
			],
			"related_warrant": "CPAW(UOB MB ePW180710)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "08 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 8 June 2018 (Third Notice)",
			"id": 2009,
			"tickers": [
				"CMJW(NKY 22000MBeCW180608)",
				"CMNW(NKY 23000MBePW180608)",
				"CMKW(NKY 24000MBeCW180608)",
				"CMIW(NKY 20000MBeCW180608)",
				"CMLW(NKY 19000MBePW180608)",
				"CMMW(NKY 21000MBePW180608)"
			],
			"related_warrant": "CMJW(NKY 22000MBeCW180608);CMNW(NKY 23000MBePW180608);CMKW(NKY 24000MBeCW180608);CMIW(NKY 20000MBeCW180608);CMLW(NKY 19000MBePW180608);CMMW(NKY 21000MBePW180608)",
			"underlying": "n225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "n225"
				}
			],
			"date": "08 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 4 June 2018 (Third Notice)",
			"id": 1997,
			"tickers": [
				"CMWW(SingPost MBeCW180604)"
			],
			"related_warrant": "CMWW(SingPost MBeCW180604)",
			"underlying": "SINGPOST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGPOST"
				}
			],
			"date": "04 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 2 July 2018 (First Notie)",
			"id": 1993,
			"tickers": [
				"CNGW(GentingSMBeCW180702)",
				"CNMW(OCBC Bk MB eCW180702)",
				"CNLW(OCBC Bk MB ePW180702)",
				"CMXW(Yangziji MB eCW180702)"
			],
			"related_warrant": "CNGW(GentingSMBeCW180702);CNMW(OCBC Bk MB eCW180702);CNLW(OCBC Bk MB ePW180702);CMXW(Yangziji MB eCW180702)",
			"underlying": "GENTINGSIN;OCBC;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "GENS.SI",
					"underlying_ticker": "G13",
					"underlying_name": "GENTINGSIN"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "01 Jun 18"
		},
		{
			"headline": "Expiry of Warrants on 30 May 2018 (Third Notice)",
			"id": 1989,
			"tickers": [
				"CSYW(HSI30800MBePW180530)",
				"CRZW(HSI29200MBePW180530)",
				"CSXW(HSI32000MBeCW180530)",
				"CRXW(HSI30400MBeCW180530)",
				"CRYW(HSI31200MBeCW180530)",
				"CSAW(HSI30000MBePW180530)"
			],
			"related_warrant": "CSYW(HSI30800MBePW180530);CRZW(HSI29200MBePW180530);CSXW(HSI32000MBeCW180530);CRXW(HSI30400MBeCW180530);CRYW(HSI31200MBeCW180530);CSAW(HSI30000MBePW180530)",
			"underlying": "hsi",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "30 May 18"
		},
		{
			"headline": "Expiry of Warrants on 28 June 2018 (First Notice)",
			"id": 1977,
			"tickers": [
				"CSMW(HSI28600MBePW180628)",
				"CTJW(HSI31400MBeCW180628)",
				"CTGW(HSI29400MBePW180628)",
				"CTSW(HSI30200MBePW180628)",
				"CSLW(HSI32200MBeCW180628)",
				"CTRW(HSI31000MBePW180628)"
			],
			"related_warrant": "CSMW(HSI28600MBePW180628);CTJW(HSI31400MBeCW180628);CTGW(HSI29400MBePW180628);CTSW(HSI30200MBePW180628);CSLW(HSI32200MBeCW180628);CTRW(HSI31000MBePW180628)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 May 18"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2018",
			"id": 1981,
			"tickers": [
				"CQJW(SIMSCI450MBeCW180629)",
				"CQKW(SIMSCI380MBePW180629)",
				"COSW(STI 3250MBePW180629)",
				"COQW(STI 3450MBePW180629)",
				"CORW(STI 3700MBeCW180629)",
				"COTW(STI 3500MBeCW180629)",
				"CRCW(STI 3450MBePW180629A)"
			],
			"related_warrant": "CQJW(SIMSCI450MBeCW180629);CQKW(SIMSCI380MBePW180629);COSW(STI 3250MBePW180629);COQW(STI 3450MBePW180629);CORW(STI 3700MBeCW180629);COTW(STI 3500MBeCW180629);CRCW(STI 3450MBePW180629A)",
			"underlying": "SiMSCI;STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SiMSCI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 May 18"
		},
		{
			"headline": "Expiry of Warrants on 18 June 2018 (First Notice)",
			"id": 1973,
			"tickers": [
				"CMEW(S&P 2800MBeCW180618)",
				"CMFW(S&P 2400MBePW180618)"
			],
			"related_warrant": "CMEW(S&P 2800MBeCW180618);CMFW(S&P 2400MBePW180618)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "18 May 18"
		},
		{
			"headline": "Expiry of Warrants on 8 June 2018 (First Notice)",
			"id": 1969,
			"tickers": [
				"CMJW(NKY 22000MBeCW180608)",
				"CMNW(NKY 23000MBePW180608)",
				"CMKW(NKY 24000MBeCW180608)",
				"CMIW(NKY 20000MBeCW180608)",
				"CMLW(NKY 19000MBePW180608)",
				"CMMW(NKY 21000MBePW180608)"
			],
			"related_warrant": "CMJW(NKY 22000MBeCW180608);CMNW(NKY 23000MBePW180608);CMKW(NKY 24000MBeCW180608);CMIW(NKY 20000MBeCW180608);CMLW(NKY 19000MBePW180608);CMMW(NKY 21000MBePW180608)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "08 May 18"
		},
		{
			"headline": "Expiry of Warrants on 4 June 2018 (First Notice)",
			"id": 1965,
			"tickers": [
				"CMWW(SingPost MBeCW180604)"
			],
			"related_warrant": "CMWW(SingPost MBeCW180604)",
			"underlying": "SINGPOST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGPOST"
				}
			],
			"date": "04 May 18"
		},
		{
			"headline": "Expiry of Warrants on 30 May 2018 (First Notice)",
			"id": 1957,
			"tickers": [
				"CSYW(HSI30800MBePW180530)",
				"CRZW(HSI29200MBePW180530)",
				"CSAW(HSI30000MBePW180530)",
				"CSXW(HSI32000MBeCW180530)",
				"CRXW(HSI30400MBeCW180530)",
				"CRYW(HSI31200MBeCW180530)"
			],
			"related_warrant": "CSYW(HSI30800MBePW180530);CRZW(HSI29200MBePW180530);CSAW(HSI30000MBePW180530);CSXW(HSI32000MBeCW180530);CRXW(HSI30400MBeCW180530);CRYW(HSI31200MBeCW180530)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "02 May 18"
		},
		{
			"headline": "Adjustment Notice",
			"id": 1961,
			"tickers": [
				"CPIW(DBS MB ePW180716)",
				"CQYW(DBS MB ePW180717)",
				"CPHW(DBS MB ePW180718)",
				"CRBW(DBS MB ePW180719)",
				"CPGW(DBS MB eCW180806)",
				"CPFW(DBS MB eCW180807)",
				"CQXW(DBS MB eCW180808)",
				"CQHW(DBS MB eCW180810)",
				"CSVW(DBS MB ePW181001)"
			],
			"related_warrant": "CPIW(DBS MB ePW180716);CQYW(DBS MB ePW180717);CPHW(DBS MB ePW180718);CRBW(DBS MB ePW180719);CPGW(DBS MB eCW180806);CPFW(DBS MB eCW180807);CQXW(DBS MB eCW180808);CQHW(DBS MB eCW180810);CSVW(DBS MB ePW181001)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "02 May 18"
		},
		{
			"headline": "Expiry of Warrants on 27 April 2018 (Third Notice)",
			"id": 1953,
			"tickers": [
				"CQUW(HSI31600MBeCW180427)",
				"CQZW(HSI32600MBeCW180427)",
				"CSBW(HSI30800MBeCW180427)",
				"CRAW(HSI29800MBePW180427)",
				"CRKW(HSI30800MBePW180427)",
				"CQWW(HSI28800MBePW180427)"
			],
			"related_warrant": "CQUW(HSI31600MBeCW180427);CQZW(HSI32600MBeCW180427);CSBW(HSI30800MBeCW180427);CRAW(HSI29800MBePW180427);CRKW(HSI30800MBePW180427);CQWW(HSI28800MBePW180427)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Apr 18"
		},
		{
			"headline": "Adjustment Notice",
			"id": 1949,
			"tickers": [
				"CPBW(UOB MB ePW180709)",
				"CPAW(UOB MB ePW180710)",
				"COZW(UOB MB eCW180801)",
				"COYW(UOB MB eCW180802)",
				"CPZW(UOB MB eCW180806)",
				"CSWW(UOB MB ePW181001)"
			],
			"related_warrant": "CPBW(UOB MB ePW180709);CPAW(UOB MB ePW180710);COZW(UOB MB eCW180801);COYW(UOB MB eCW180802);CPZW(UOB MB eCW180806);CSWW(UOB MB ePW181001)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "25 Apr 18"
		},
		{
			"headline": "Expiry of Warrants on 27 April 2018 (First Notice)",
			"id": 1941,
			"tickers": [
				"CQUW(HSI31600MBeCW180427)",
				"CQZW(HSI32600MBeCW180427)",
				"CSBW(HSI30800MBeCW180427)",
				"CRAW(HSI29800MBePW180427)",
				"CRKW(HSI30800MBePW180427)",
				"CQWW(HSI28800MBePW180427)"
			],
			"related_warrant": "CQUW(HSI31600MBeCW180427);CQZW(HSI32600MBeCW180427);CSBW(HSI30800MBeCW180427);CRAW(HSI29800MBePW180427);CRKW(HSI30800MBePW180427);CQWW(HSI28800MBePW180427)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "20 Apr 18"
		},
		{
			"headline": "Clarification Announcement",
			"id": 1945,
			"tickers": [
				"CRKW(HSI30800MBePW180427)"
			],
			"related_warrant": "CRKW(HSI30800MBePW180427)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "20 Apr 18"
		},
		{
			"headline": " Expiry of Warrants on 16 April 2018 (Third Notice)",
			"id": 1937,
			"tickers": [
				"CNJW(DBS MB ePW180416)",
				"CNKW(DBS MB eCW180416)"
			],
			"related_warrant": "CNJW(DBS MB ePW180416);CNKW(DBS MB eCW180416)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "16 Apr 18"
		},
		{
			"headline": "Expiry of Warrants on 12 April 2018 (Third Notice)",
			"id": 1933,
			"tickers": [
				"CMUW(DBS MB ePW180412)",
				"CMTW(DBS MB eCW180412)",
				"CNBW(UOB MB eCW180412)",
				"CNCW(UOB MB ePW180412)"
			],
			"related_warrant": "CMUW(DBS MB ePW180412);CMTW(DBS MB eCW180412);CNBW(UOB MB eCW180412);CNCW(UOB MB ePW180412)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "12 Apr 18"
		},
		{
			"headline": "Expiry of Warrants on 10 April 2018 (Third Notice)",
			"id": 1929,
			"tickers": [
				"CMVW(CapitalaMBeCW180410)",
				"CMDW(DBS MB eCW180410)",
				"CMCW(UOB MB eCW180410)"
			],
			"related_warrant": "CMVW(CapitalaMBeCW180410);CMDW(DBS MB eCW180410);CMCW(UOB MB eCW180410)",
			"underlying": "CAPLAND;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "10 Apr 18"
		},
		{
			"headline": "Expiry of Warrants on 2 April 2018 (Third Notice)",
			"id": 1925,
			"tickers": [
				"CLJW(SingTelMBeCW180402)",
				"CLZW(DBS MB ePW180402)",
				"CMAW(UOB MB ePW180402)"
			],
			"related_warrant": "CLJW(SingTelMBeCW180402);CLZW(DBS MB ePW180402);CMAW(UOB MB ePW180402)",
			"underlying": "SINGTEL;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Apr 18"
		},
		{
			"headline": "Expiry of Warrants on 29 March 2018 (Third Notice)",
			"id": 1921,
			"tickers": [
				"CMSW(STI 3350MBePW180329)",
				"CMRW(STI 3600MBeCW180329)"
			],
			"related_warrant": "CMSW(STI 3350MBePW180329);CMRW(STI 3600MBeCW180329)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 28 March 2018 (Third Notice)",
			"id": 1917,
			"tickers": [
				"CQMW(HSI33800MBeCW180328)",
				"CPOW(HSI30200MBePW180328)",
				"CQAW(HSI31800MBePW180328)",
				"CPNW(HSI32000MBeCW180328)",
				"CQVW(HSI30400MBeCW180328)",
				"COKW(HSI29200MBePW180328)",
				"COJW(HSI27600MBePW180328)",
				"CQBW(HSI33000MBeCW180328)",
				"COLW(HSI31200MBeCW180328)"
			],
			"related_warrant": "CQMW(HSI33800MBeCW180328);CPOW(HSI30200MBePW180328);CQAW(HSI31800MBePW180328);CPNW(HSI32000MBeCW180328);CQVW(HSI30400MBeCW180328);COKW(HSI29200MBePW180328);COJW(HSI27600MBePW180328);CQBW(HSI33000MBeCW180328);COLW(HSI31200MBeCW180328)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 27 April 2018 (First Notice)",
			"id": 1913,
			"tickers": [
				"CQZW(HSI32600MBeCW180427)",
				"CQUW(HSI31600MBeCW180427)",
				"CRAW(HSI29800MBePW180427)",
				"CQWW(HSI28800MBePW180427)",
				"CRKW(HSI30800MBePW180427)"
			],
			"related_warrant": "CQZW(HSI32600MBeCW180427);CQUW(HSI31600MBeCW180427);CRAW(HSI29800MBePW180427);CQWW(HSI28800MBePW180427);CRKW(HSI30800MBePW180427)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 16 April 2018 (First Notice)",
			"id": 1909,
			"tickers": [
				"CNJW(DBS MB ePW180416)",
				"CNKW(DBS MB eCW180416)"
			],
			"related_warrant": "CNJW(DBS MB ePW180416);CNKW(DBS MB eCW180416)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "16 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 12 April 2018 (First Notice)",
			"id": 1905,
			"tickers": [
				"CMTW(DBS MB eCW180412)",
				"CMUW(DBS MB ePW180412)",
				"CNBW(UOB MB eCW180412)",
				"CNCW(UOB MB ePW180412)"
			],
			"related_warrant": "CMTW(DBS MB eCW180412);CMUW(DBS MB ePW180412);CNBW(UOB MB eCW180412);CNCW(UOB MB ePW180412)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "12 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 10 April 2018 (First Notice)",
			"id": 1901,
			"tickers": [
				"CMDW(DBS MB eCW180410)",
				"CMVW(CapitalaMBeCW180410)",
				"CMCW(UOB MB eCW180410)"
			],
			"related_warrant": "CMDW(DBS MB eCW180410);CMVW(CapitalaMBeCW180410);CMCW(UOB MB eCW180410)",
			"underlying": "DBS;CAPLAND;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "09 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 02 April 2018 (First Notice)",
			"id": 1897,
			"tickers": [
				"CLJW(SingTelMBeCW180402)",
				"CLZW(DBS MB ePW180402)",
				"CMAW(UOB MB ePW180402)"
			],
			"related_warrant": "CLJW(SingTelMBeCW180402);CLZW(DBS MB ePW180402);CMAW(UOB MB ePW180402)",
			"underlying": "SINGTEL;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 1 March 2018 (Third Notice)",
			"id": 1893,
			"tickers": [
				"CJYW(OCBC Bk MB eCW180301)",
				"CKBW(OCBC Bk MB ePW180301)"
			],
			"related_warrant": "CJYW(OCBC Bk MB eCW180301);CKBW(OCBC Bk MB ePW180301)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Mar 18"
		},
		{
			"headline": "Expiry of Warrants on 28 March 2018 (First Notice)",
			"id": 1885,
			"tickers": [
				"CQMW(HSI33800MBeCW180328)",
				"CQVW(HSI30400MBeCW180328)",
				"CQAW(HSI31800MBePW180328)",
				"CPNW(HSI32000MBeCW180328)",
				"COKW(HSI29200MBePW180328)",
				"COJW(HSI27600MBePW180328)",
				"COLW(HSI31200MBeCW180328)",
				"CQBW(HSI33000MBeCW180328)",
				"CPOW(HSI30200MBePW180328)"
			],
			"related_warrant": "CQMW(HSI33800MBeCW180328);CQVW(HSI30400MBeCW180328);CQAW(HSI31800MBePW180328);CPNW(HSI32000MBeCW180328);COKW(HSI29200MBePW180328);COJW(HSI27600MBePW180328);COLW(HSI31200MBeCW180328);CQBW(HSI33000MBeCW180328);CPOW(HSI30200MBePW180328)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Feb 18"
		},
		{
			"headline": "Expiry of Warrants on 29 March 2018 (First Notice)",
			"id": 1889,
			"tickers": [
				"CMSW(STI 3350MBePW180329)",
				"CMRW(STI 3600MBeCW180329)"
			],
			"related_warrant": "CMSW(STI 3350MBePW180329);CMRW(STI 3600MBeCW180329)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Feb 18"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2018 (Third Notice)",
			"id": 1877,
			"tickers": [
				"CPKW(HSI31800MBeCW180227)",
				"CPXW(HSI32600MBeCW180227)",
				"CNRW(HSI30800MBeCW180227)",
				"CNQW(HSI29600MBePW180227)",
				"COOW(HSI30000MBeCW180227)",
				"CQGW(HSI33400MBeCW180227)",
				"CQFW(HSI32400MBePW180227)",
				"CPYW(HSI31600MBePW180227)",
				"CONW(HSI28800MBePW180227)",
				"COPW(HSI29200MBeCW180227)",
				"COMW(HSI28000MBePW180227)",
				"CPLW(HSI30600MBePW180227)"
			],
			"related_warrant": "CPKW(HSI31800MBeCW180227);CPXW(HSI32600MBeCW180227);CNRW(HSI30800MBeCW180227);CNQW(HSI29600MBePW180227);COOW(HSI30000MBeCW180227);CQGW(HSI33400MBeCW180227);CQFW(HSI32400MBePW180227);CPYW(HSI31600MBePW180227);CONW(HSI28800MBePW180227);COPW(HSI29200MBeCW180227);COMW(HSI28000MBePW180227);CPLW(HSI30600MBePW180227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Feb 18"
		},
		{
			"headline": "Expiry of Warrants on 26 February 2018 (Third Notice)",
			"id": 1873,
			"tickers": [
				"CKSW(DBS MB eCW180226)",
				"CKTW(OCBC Bk MB eCW180226)",
				"CLHW(UOB MB eCW180226)",
				"CLIW(UOB MB ePW180226)"
			],
			"related_warrant": "CKSW(DBS MB eCW180226);CKTW(OCBC Bk MB eCW180226);CLHW(UOB MB eCW180226);CLIW(UOB MB ePW180226)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "26 Feb 18"
		},
		{
			"headline": "Expiry of Warrants on 01 March 2018 (First Notice)",
			"id": 1869,
			"tickers": [
				"CJYW(OCBC Bk MB eCW180301)",
				"CKBW(OCBC Bk MB ePW180301)"
			],
			"related_warrant": "CJYW(OCBC Bk MB eCW180301);CKBW(OCBC Bk MB ePW180301)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Feb 18"
		},
		{
			"headline": "Expiry of Warrants on 30 January 2018 (Third Notice)",
			"id": 1865,
			"tickers": [
				"CNIW(HSI29200MBePW180130)",
				"CNPW(HSI28400MBePW180130A)",
				"CMZW(HSI29600MBeCW180130)",
				"CNAW(HSI28400MBePW180130)",
				"CMYW(HSI28800MBeCW180130)",
				"CNFW(HSI30400MBeCW180130)",
				"CNOW(HSI30000MBePW180130)"
			],
			"related_warrant": "CNIW(HSI29200MBePW180130);CNPW(HSI28400MBePW180130A);CMZW(HSI29600MBeCW180130);CNAW(HSI28400MBePW180130);CMYW(HSI28800MBeCW180130);CNFW(HSI30400MBeCW180130);CNOW(HSI30000MBePW180130)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Jan 18"
		},
		{
			"headline": "Expiry of Warrants on 29 January 2018 (Third Notice)",
			"id": 1861,
			"tickers": [
				"CJMW(SingTelMBeCW180129)"
			],
			"related_warrant": "CJMW(SingTelMBeCW180129)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "29 Jan 18"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2018 (First Notice)",
			"id": 1853,
			"tickers": [
				"COPW(HSI29200MBeCW180227)",
				"CPYW(HSI31600MBePW180227)",
				"CNRW(HSI30800MBeCW180227)",
				"CNQW(HSI29600MBePW180227)",
				"CPLW(HSI30600MBePW180227)",
				"CPKW(HSI31800MBeCW180227)",
				"CPXW(HSI32600MBeCW180227)",
				"CONW(HSI28800MBePW180227)",
				"COOW(HSI30000MBeCW180227)",
				"COMW(HSI28000MBePW180227)",
				"CQFW(HSI32400MBePW180227)",
				"CQGW(HSI33400MBeCW180227)"
			],
			"related_warrant": "COPW(HSI29200MBeCW180227);CPYW(HSI31600MBePW180227);CNRW(HSI30800MBeCW180227);CNQW(HSI29600MBePW180227);CPLW(HSI30600MBePW180227);CPKW(HSI31800MBeCW180227);CPXW(HSI32600MBeCW180227);CONW(HSI28800MBePW180227);COOW(HSI30000MBeCW180227);COMW(HSI28000MBePW180227);CQFW(HSI32400MBePW180227);CQGW(HSI33400MBeCW180227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "26 Jan 18"
		},
		{
			"headline": "Expiry of Warrants on 26 February 2018 (First Notice)",
			"id": 1857,
			"tickers": [
				"CKSW(DBS MB eCW180226)",
				"CKTW(OCBC Bk MB eCW180226)",
				"CLHW(UOB MB eCW180226)",
				"CLIW(UOB MB ePW180226)"
			],
			"related_warrant": "CKSW(DBS MB eCW180226);CKTW(OCBC Bk MB eCW180226);CLHW(UOB MB eCW180226);CLIW(UOB MB ePW180226)",
			"underlying": "DBS;OCBC;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "26 Jan 18"
		},
		{
			"headline": "Expiry of Warrants on 15 January 2018 (Third Notice)",
			"id": 1849,
			"tickers": [
				"CJXW(DBS MB eCW180115)",
				"CKRW(DBS MB ePW180115)",
				"CKAW(UOB MB ePW180115)",
				"CJZW(UOB MB eCW180115)"
			],
			"related_warrant": "CJXW(DBS MB eCW180115);CKRW(DBS MB ePW180115);CKAW(UOB MB ePW180115);CJZW(UOB MB eCW180115)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "15 Jan 18"
		},
		{
			"headline": "Expiry of Warrants on 29 December 2017 (Third Notice)",
			"id": 1837,
			"tickers": [
				"CMBW(STI 3500MBeCW171229)",
				"CKMW(STI 3300MBeCW171229)",
				"CKLW(STI 3250MBePW171229)",
				"CLQW(STI 3050MBePW171229)"
			],
			"related_warrant": "CMBW(STI 3500MBeCW171229);CKMW(STI 3300MBeCW171229);CKLW(STI 3250MBePW171229);CLQW(STI 3050MBePW171229)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 29 January 2018 (First Notice)",
			"id": 1841,
			"tickers": [
				"CJMW(SingTelMBeCW180129)"
			],
			"related_warrant": "CJMW(SingTelMBeCW180129)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "29 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 30 January 2018 (First Notice)",
			"id": 1845,
			"tickers": [
				"CNPW(HSI28400MBePW180130A)",
				"CNIW(HSI29200MBePW180130)",
				"CMZW(HSI29600MBeCW180130)",
				"CNAW(HSI28400MBePW180130)",
				"CNOW(HSI30000MBePW180130)",
				"CMYW(HSI28800MBeCW180130)",
				"CNFW(HSI30400MBeCW180130)"
			],
			"related_warrant": "CNPW(HSI28400MBePW180130A);CNIW(HSI29200MBePW180130);CMZW(HSI29600MBeCW180130);CNAW(HSI28400MBePW180130);CNOW(HSI30000MBePW180130);CMYW(HSI28800MBeCW180130);CNFW(HSI30400MBeCW180130)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 28 December 2017 (Third Notice)",
			"id": 1833,
			"tickers": [
				"CKOW(HSI28200MBeCW171228)",
				"CNHW(HSI30000MBeCW171228)",
				"CLDW(HSI29200MBeCW171228)",
				"CLYW(HSI28000MBePW171228)",
				"CNDW(HSI28800MBePW171228)",
				"CKZW(HSI26688VTePW171228)"
			],
			"related_warrant": "CKOW(HSI28200MBeCW171228);CNHW(HSI30000MBeCW171228);CLDW(HSI29200MBeCW171228);CLYW(HSI28000MBePW171228);CNDW(HSI28800MBePW171228);CKZW(HSI26688VTePW171228)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 15 December 2017 (Thrid Notice)",
			"id": 1829,
			"tickers": [
				"CJHW(S&P 2450MBePW171215)",
				"CJGW(S&P 2400MBeCW171215)",
				"CJFW(S&P 2600MBeCW171215)",
				"CJIW(S&P 2250MBePW171215)"
			],
			"related_warrant": "CJHW(S&P 2450MBePW171215);CJGW(S&P 2400MBeCW171215);CJFW(S&P 2600MBeCW171215);CJIW(S&P 2250MBePW171215)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "18 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 15 January 2018 (First Notice)",
			"id": 1821,
			"tickers": [
				"CKRW(DBS MB ePW180115)",
				"CJXW(DBS MB eCW180115)",
				"CJZW(UOB MB eCW180115)",
				"CKAW(UOB MB ePW180115)"
			],
			"related_warrant": "CKRW(DBS MB ePW180115);CJXW(DBS MB eCW180115);CJZW(UOB MB eCW180115);CKAW(UOB MB ePW180115)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "15 Dec 17"
		},
		{
			"headline": "Adjustment Notice",
			"id": 1825,
			"tickers": [
				"CJMW(SingTelMBeCW180129)",
				"CLJW(SingTelMBeCW180402)"
			],
			"related_warrant": "CJMW(SingTelMBeCW180129);CLJW(SingTelMBeCW180402)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "15 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 8 December 2017 (Third Notice)",
			"id": 1817,
			"tickers": [
				"CJBW(NKY 18000MBePW171208)",
				"CJEW(NKY 19000MBeCW171208)",
				"CJAW(NKY 16000MBePW171208)",
				"CJDW(NKY 21000MBeCW171208)",
				"CJCW(NKY 23000MBeCW171208)",
				"CIZW(NKY 20000MBePW171208)"
			],
			"related_warrant": "CJBW(NKY 18000MBePW171208);CJEW(NKY 19000MBeCW171208);CJAW(NKY 16000MBePW171208);CJDW(NKY 21000MBeCW171208);CJCW(NKY 23000MBeCW171208);CIZW(NKY 20000MBePW171208)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "08 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 4 December 2017 (Third Notice)",
			"id": 1813,
			"tickers": [
				"CJTW(DBS MB ePW171204)"
			],
			"related_warrant": "CJTW(DBS MB ePW171204)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "04 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 1 December 2017 (Third Notice)",
			"id": 1809,
			"tickers": [
				"CIYW(OCBC Bk MB ePW171201)",
				"CHRW(OCBC Bk MB eCW171201)"
			],
			"related_warrant": "CIYW(OCBC Bk MB ePW171201);CHRW(OCBC Bk MB eCW171201)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Dec 17"
		},
		{
			"headline": "Expiry of Warrants on 29 December 2017 (First Notice)",
			"id": 1801,
			"tickers": [
				"CMBW(STI 3500MBeCW171229)",
				"CKMW(STI 3300MBeCW171229)",
				"CKLW(STI 3250MBePW171229)",
				"CLQW(STI 3050MBePW171229)"
			],
			"related_warrant": "CMBW(STI 3500MBeCW171229);CKMW(STI 3300MBeCW171229);CKLW(STI 3250MBePW171229);CLQW(STI 3050MBePW171229)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 29 November 2017 (Third Notice)",
			"id": 1805,
			"tickers": [
				"CLTW(HSI29000MBeCW171129)",
				"CLMW(HSI27000MBePW171129)",
				"CLKW(HSI28200MBeCW171129)",
				"CLLW(HSI27400MBeCW171129)",
				"CLXW(HSI28600MBePW171129)",
				"CLSW(HSI27800MBePW171129)"
			],
			"related_warrant": "CLTW(HSI29000MBeCW171129);CLMW(HSI27000MBePW171129);CLKW(HSI28200MBeCW171129);CLLW(HSI27400MBeCW171129);CLXW(HSI28600MBePW171129);CLSW(HSI27800MBePW171129)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 28 December 2017 (First Notice)",
			"id": 1797,
			"tickers": [
				"CNDW(HSI28800MBePW171228)",
				"CNHW(HSI30000MBeCW171228)",
				"CKOW(HSI28200MBeCW171228)",
				"CLDW(HSI29200MBeCW171228)",
				"CKNW(HSI27000MBePW171228)",
				"CLYW(HSI28000MBePW171228)"
			],
			"related_warrant": "CNDW(HSI28800MBePW171228);CNHW(HSI30000MBeCW171228);CKOW(HSI28200MBeCW171228);CLDW(HSI29200MBeCW171228);CKNW(HSI27000MBePW171228);CLYW(HSI28000MBePW171228)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 15 December 2017 (First Notice)",
			"id": 1789,
			"tickers": [
				"CJFW(S&P 2600MBeCW171215)",
				"CJGW(S&P 2400MBeCW171215)",
				"CJHW(S&P 2450MBePW171215)",
				"CJIW(S&P 2250MBePW171215)"
			],
			"related_warrant": "CJFW(S&P 2600MBeCW171215);CJGW(S&P 2400MBeCW171215);CJHW(S&P 2450MBePW171215);CJIW(S&P 2250MBePW171215)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "15 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 13 November 2017 (Third Notice)",
			"id": 1785,
			"tickers": [
				"CGWW(OCBC Bk MB ePW171113)",
				"CGMW(OCBC Bk MB eCW171113)"
			],
			"related_warrant": "CGWW(OCBC Bk MB ePW171113);CGMW(OCBC Bk MB eCW171113)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "13 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 8 December 2017 (First Notice)",
			"id": 1781,
			"tickers": [
				"CJBW(NKY 18000MBePW171208)",
				"CJEW(NKY 19000MBeCW171208)",
				"CJAW(NKY 16000MBePW171208)",
				"CJDW(NKY 21000MBeCW171208)",
				"CJCW(NKY 23000MBeCW171208)",
				"CIZW(NKY 20000MBePW171208)"
			],
			"related_warrant": "CJBW(NKY 18000MBePW171208);CJEW(NKY 19000MBeCW171208);CJAW(NKY 16000MBePW171208);CJDW(NKY 21000MBeCW171208);CJCW(NKY 23000MBeCW171208);CIZW(NKY 20000MBePW171208)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "08 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 06 November 2017 (Third Notice)",
			"id": 1777,
			"tickers": [
				"CICW(UOB MB ePW171106)"
			],
			"related_warrant": "CICW(UOB MB ePW171106)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 4 December 2017 (First Notice)",
			"id": 1773,
			"tickers": [
				"CJTW(DBS MB ePW171204)"
			],
			"related_warrant": "CJTW(DBS MB ePW171204)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "03 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 01 November 2017 (Third Notice)",
			"id": 1765,
			"tickers": [
				"CFKW(CapitalaMBeCW171101)",
				"CBMW(DBS MB eCW171101)",
				"CBRW(UOB MB eCW171101)"
			],
			"related_warrant": "CFKW(CapitalaMBeCW171101);CBMW(DBS MB eCW171101);CBRW(UOB MB eCW171101)",
			"underlying": "CAPLAND;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "01 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 1 December 2017 (First Notice)",
			"id": 1769,
			"tickers": [
				"CIYW(OCBC Bk MB ePW171201)",
				"CHRW(OCBC Bk MB eCW171201)"
			],
			"related_warrant": "CIYW(OCBC Bk MB ePW171201);CHRW(OCBC Bk MB eCW171201)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Nov 17"
		},
		{
			"headline": "Expiry of Warrants on 31 October 2017 (Third Notice)",
			"id": 1761,
			"tickers": [
				"CIJW(STI 3200MBeCW171031)",
				"CIKW(STI 3150MBePW171031)",
				"CJWW(STI 3400MBeCW171031)",
				"CKCW(STI 3350MBePW171031)"
			],
			"related_warrant": "CIJW(STI 3200MBeCW171031);CIKW(STI 3150MBePW171031);CJWW(STI 3400MBeCW171031);CKCW(STI 3350MBePW171031)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "31 Oct 17"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2017 (Third Notice)",
			"id": 1757,
			"tickers": [
				"CBLW(DBS MB eCW171030)",
				"CBOW(DBS MB ePW171030)",
				"CKKW(HSI26600MBePW171030)",
				"CKQW(HSI28600MBeCW171030)",
				"CLEW(HSI28200MBePW171030)",
				"CKPW(HSI27400MBePW171030)",
				"CKJW(HSI27800MBeCW171030)",
				"CBSW(UOB MB eCW171030)"
			],
			"related_warrant": "CBLW(DBS MB eCW171030);CBOW(DBS MB ePW171030);CKKW(HSI26600MBePW171030);CKQW(HSI28600MBeCW171030);CLEW(HSI28200MBePW171030);CKPW(HSI27400MBePW171030);CKJW(HSI27800MBeCW171030);CBSW(UOB MB eCW171030)",
			"underlying": "DBS;HSI;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "30 Oct 17"
		},
		{
			"headline": "Expiry of Warrants on 29 November 2017 (First Notice)",
			"id": 1753,
			"tickers": [
				"CLTW(HSI29000MBeCW171129)",
				"CLLW(HSI27400MBeCW171129)",
				"CLKW(HSI28200MBeCW171129)",
				"CLXW(HSI28600MBePW171129)",
				"CLMW(HSI27000MBePW171129)",
				"CLSW(HSI27800MBePW171129)"
			],
			"related_warrant": "CLTW(HSI29000MBeCW171129);CLLW(HSI27400MBeCW171129);CLKW(HSI28200MBeCW171129);CLXW(HSI28600MBePW171129);CLMW(HSI27000MBePW171129);CLSW(HSI27800MBePW171129)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Oct 17"
		},
		{
			"headline": "Expiry of Warrants on 16 October 2017 (Third Notice)",
			"id": 1749,
			"tickers": [
				"CGQW(DBS MB eCW171016)",
				"CGXW(DBS MB ePW171016)",
				"CGUW(UOB MB ePW171016)",
				"CGVW(UOB MB eCW171016)"
			],
			"related_warrant": "CGQW(DBS MB eCW171016);CGXW(DBS MB ePW171016);CGUW(UOB MB ePW171016);CGVW(UOB MB eCW171016)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "16 Oct 17"
		},
		{
			"headline": "Expiry of Warrants on 13 November 2017 (First Notice)",
			"id": 1745,
			"tickers": [
				"CGWW(OCBC Bk MB ePW171113)",
				"CGMW(OCBC Bk MB eCW171113)"
			],
			"related_warrant": "CGWW(OCBC Bk MB ePW171113);CGMW(OCBC Bk MB eCW171113)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "13 Oct 17"
		},
		{
			"headline": "Expiry of Warrants on 6 November 2017 (First Notice)",
			"id": 1741,
			"tickers": [
				"CICW(UOB MB ePW171106)"
			],
			"related_warrant": "CICW(UOB MB ePW171106)",
			"underlying": "UOB",
			"underlyings": [
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "06 Oct 17"
		},
		{
			"headline": "Expiry of Warrants on 2 October 2017 (Third Notice)",
			"id": 1737,
			"tickers": [
				"CEIW(CapitalaMBePW171002)",
				"CEHW(CapitalaMBeCW171002)",
				"CBNW(DBS MB ePW171002)",
				"CBKW(DBS MB eCW171002)",
				"CFEW(KepCorp MBeCW171002)",
				"CFDW(KepCorp MBePW171002)",
				"CBIW(OCBC Bk MB ePW171002)",
				"CBHW(OCBC Bk MB eCW171002)",
				"CBAW(SingTelMBeCW171002)",
				"CBBW(SingTelMBePW171002)",
				"CBQW(UOB MB ePW171002)"
			],
			"related_warrant": "CEIW(CapitalaMBePW171002);CEHW(CapitalaMBeCW171002);CBNW(DBS MB ePW171002);CBKW(DBS MB eCW171002);CFEW(KepCorp MBeCW171002);CFDW(KepCorp MBePW171002);CBIW(OCBC Bk MB ePW171002);CBHW(OCBC Bk MB eCW171002);CBAW(SingTelMBeCW171002);CBBW(SingTelMBePW171002);CBQW(UOB MB ePW171002)",
			"underlying": "CAPLAND;DBS;KEPPEL;OCBC;SINGTEL;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "02 Oct 17"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2017 (First Notice)",
			"id": 1725,
			"tickers": [
				"CBOW(DBS MB ePW171030)",
				"CBLW(DBS MB eCW171030)",
				"CKPW(HSI27400MBePW171030)",
				"CLEW(HSI28200MBePW171030)",
				"CKKW(HSI26600MBePW171030)",
				"CKQW(HSI28600MBeCW171030)",
				"CKJW(HSI27800MBeCW171030)",
				"CBSW(UOB MB eCW171030)"
			],
			"related_warrant": "CBOW(DBS MB ePW171030);CBLW(DBS MB eCW171030);CKPW(HSI27400MBePW171030);CLEW(HSI28200MBePW171030);CKKW(HSI26600MBePW171030);CKQW(HSI28600MBeCW171030);CKJW(HSI27800MBeCW171030);CBSW(UOB MB eCW171030)",
			"underlying": "DBS;HSI;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "29 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 31 October 2017 (First Notice)",
			"id": 1729,
			"tickers": [
				"CIJW(STI 3200MBeCW171031)",
				"CIKW(STI 3150MBePW171031)",
				"CJWW(STI 3400MBeCW171031)",
				"CKCW(STI 3350MBePW171031)"
			],
			"related_warrant": "CIJW(STI 3200MBeCW171031);CIKW(STI 3150MBePW171031);CJWW(STI 3400MBeCW171031);CKCW(STI 3350MBePW171031)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "29 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 1 November 2017 (First Notice)",
			"id": 1733,
			"tickers": [
				"CFKW(CapitalaMBeCW171101)",
				"CBMW(DBS MB eCW171101)",
				"CBRW(UOB MB eCW171101)"
			],
			"related_warrant": "CFKW(CapitalaMBeCW171101);CBMW(DBS MB eCW171101);CBRW(UOB MB eCW171101)",
			"underlying": "CAPLAND;DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "29 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 25 September 2017 (Third Notice)",
			"id": 1721,
			"tickers": [
				"CEFW(Wilmar MBeCW170925)"
			],
			"related_warrant": "CEFW(Wilmar MBeCW170925)",
			"underlying": "WILMAR",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR"
				}
			],
			"date": "25 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 15 September 2017 (Third Notice)",
			"id": 1717,
			"tickers": [
				"CDDW(S&P 2500MBeCW170915)",
				"CDGW(S&P 2000MBePW170915)",
				"CFBW(S&P 2200MBePW170915)",
				"CHOW(S&P 2300MBePW170915)"
			],
			"related_warrant": "CDDW(S&P 2500MBeCW170915);CDGW(S&P 2000MBePW170915);CFBW(S&P 2200MBePW170915);CHOW(S&P 2300MBePW170915)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "18 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 16 October 2017 (First Notice)",
			"id": 1713,
			"tickers": [
				"CGQW(DBS MB eCW171016)",
				"CGXW(DBS MB ePW171016)",
				"CGUW(UOB MB ePW171016)",
				"CGVW(UOB MB eCW171016)"
			],
			"related_warrant": "CGQW(DBS MB eCW171016);CGXW(DBS MB ePW171016);CGUW(UOB MB ePW171016);CGVW(UOB MB eCW171016)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "15 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 8 September 2017 (Third Notice)",
			"id": 1709,
			"tickers": [
				"CFYW(NKY 18000MBeCW170908)",
				"CGAW(NKY 22000MBeCW170908)",
				"CGDW(NKY 19000MBePW170908)",
				"CFXW(NKY 16000MBeCW170908)",
				"CGBW(NKY 17000MBePW170908)",
				"CFZW(NKY 20000MBeCW170908)",
				"CGCW(NKY 21000MBePW170908)"
			],
			"related_warrant": "CFYW(NKY 18000MBeCW170908);CGAW(NKY 22000MBeCW170908);CGDW(NKY 19000MBePW170908);CFXW(NKY 16000MBeCW170908);CGBW(NKY 17000MBePW170908);CFZW(NKY 20000MBeCW170908);CGCW(NKY 21000MBePW170908)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "08 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 4 September 2017 (Third Notice)",
			"id": 1705,
			"tickers": [
				"CELW(SGX MB eCW170904)"
			],
			"related_warrant": "CELW(SGX MB eCW170904)",
			"underlying": "SGX",
			"underlyings": [
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				}
			],
			"date": "04 Sep 17"
		},
		{
			"headline": "Expiry of Warrants on 02 October 2017 (First Notice)",
			"id": 1697,
			"tickers": [
				"CEIW(CapitalaMBePW171002)",
				"CEHW(CapitalaMBeCW171002)",
				"CBKW(DBS MB eCW171002)",
				"CBNW(DBS MB ePW171002)",
				"CFEW(KepCorp MBeCW171002)",
				"CFDW(KepCorp MBePW171002)",
				"CBIW(OCBC Bk MB ePW171002)",
				"CBHW(OCBC Bk MB eCW171002)",
				"CBAW(SingTelMBeCW171002)",
				"CBBW(SingTelMBePW171002)",
				"CBQW(UOB MB ePW171002)"
			],
			"related_warrant": "CEIW(CapitalaMBePW171002);CEHW(CapitalaMBeCW171002);CBKW(DBS MB eCW171002);CBNW(DBS MB ePW171002);CFEW(KepCorp MBeCW171002);CFDW(KepCorp MBePW171002);CBIW(OCBC Bk MB ePW171002);CBHW(OCBC Bk MB eCW171002);CBAW(SingTelMBeCW171002);CBBW(SingTelMBePW171002);CBQW(UOB MB ePW171002)",
			"underlying": "CAPLAND;DBS;KEPPEL;OCBC;SINGTEL;UOB",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "31 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 31 August 2017 (Third Notice)",
			"id": 1701,
			"tickers": [
				"CHAW(STI 3250MBePW170831)",
				"CGZW(STI 3500MBeCW170831)",
				"CFVW(STI 3300MBeCW170831)",
				"CFWW(STI 3050MBePW170831)"
			],
			"related_warrant": "CHAW(STI 3250MBePW170831);CGZW(STI 3500MBeCW170831);CFVW(STI 3300MBeCW170831);CFWW(STI 3050MBePW170831)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "31 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 30 August 2017 (Third Notice)",
			"id": 1693,
			"tickers": [
				"CHMW(HSI23800MBePW170830)",
				"CJQW(HSI25800MBePW170830)",
				"CHLW(HSI26400MBeCW170830)",
				"CJOW(HSI27200MBeCW170830)",
				"CIIW(HSI25400MBeCW170830)",
				"CIHW(HSI24800MBePW170830)",
				"CJVW(HSI26600MBePW170830)"
			],
			"related_warrant": "CHMW(HSI23800MBePW170830);CJQW(HSI25800MBePW170830);CHLW(HSI26400MBeCW170830);CJOW(HSI27200MBeCW170830);CIIW(HSI25400MBeCW170830);CIHW(HSI24800MBePW170830);CJVW(HSI26600MBePW170830)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 28 September 2017 (First Notice)",
			"id": 1689,
			"tickers": [
				"CJKW(HSI24600MBePW170928)",
				"CJUW(HSI26200MBePW170928)",
				"CJPW(HSI27400MBeCW170928)",
				"CKHW(HSI28200MBeCW170928)",
				"CKIW(HSI27000MBePW170928)",
				"CJJW(HSI26600MBeCW170928)"
			],
			"related_warrant": "CJKW(HSI24600MBePW170928);CJUW(HSI26200MBePW170928);CJPW(HSI27400MBeCW170928);CKHW(HSI28200MBeCW170928);CKIW(HSI27000MBePW170928);CJJW(HSI26600MBeCW170928)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 25 September 2017 (First Notice)",
			"id": 1685,
			"tickers": [
				"CEFW(Wilmar MBeCW170925)"
			],
			"related_warrant": "CEFW(Wilmar MBeCW170925)",
			"underlying": "WILMAR",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR"
				}
			],
			"date": "25 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 15 September 2017 (First Notice)",
			"id": 1681,
			"tickers": [
				"CDDW(S&P 2500MBeCW170915)",
				"CDGW(S&P 2000MBePW170915)",
				"CFBW(S&P 2200MBePW170915)",
				"CHOW(S&P 2300MBePW170915)"
			],
			"related_warrant": "CDDW(S&P 2500MBeCW170915);CDGW(S&P 2000MBePW170915);CFBW(S&P 2200MBePW170915);CHOW(S&P 2300MBePW170915)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "15 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 08 Sep 2017 (First Notice)",
			"id": 1677,
			"tickers": [
				"CFYW(NKY 18000MBeCW170908)",
				"CGAW(NKY 22000MBeCW170908)",
				"CGDW(NKY 19000MBePW170908)",
				"CFXW(NKY 16000MBeCW170908)",
				"CGBW(NKY 17000MBePW170908)",
				"CFZW(NKY 20000MBeCW170908)",
				"CGCW(NKY 21000MBePW170908)"
			],
			"related_warrant": "CFYW(NKY 18000MBeCW170908);CGAW(NKY 22000MBeCW170908);CGDW(NKY 19000MBePW170908);CFXW(NKY 16000MBeCW170908);CGBW(NKY 17000MBePW170908);CFZW(NKY 20000MBeCW170908);CGCW(NKY 21000MBePW170908)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "08 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 04 Sep 2017 (First Notice)",
			"id": 1673,
			"tickers": [
				"CELW(SGX MB eCW170904)"
			],
			"related_warrant": "CELW(SGX MB eCW170904)",
			"underlying": "SGX",
			"underlyings": [
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				}
			],
			"date": "04 Aug 17"
		},
		{
			"headline": "Expiry of Warrants on 31 Aug 2017 (First Notice)",
			"id": 1669,
			"tickers": [
				"CHAW(STI 3250MBePW170831)",
				"CGZW(STI 3500MBeCW170831)",
				"CFVW(STI 3300MBeCW170831)",
				"CFWW(STI 3050MBePW170831)"
			],
			"related_warrant": "CHAW(STI 3250MBePW170831);CGZW(STI 3500MBeCW170831);CFVW(STI 3300MBeCW170831);CFWW(STI 3050MBePW170831)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "31 Jul 17"
		},
		{
			"headline": "Expiry of Warrants on 30 Aug 2017 (First Notice)",
			"id": 1661,
			"tickers": [
				"CHMW(HSI23800MBePW170830)",
				"CHLW(HSI26400MBeCW170830)",
				"CIIW(HSI25400MBeCW170830)",
				"CIHW(HSI24800MBePW170830)",
				"CJVW(HSI26600MBePW170830)",
				"CJOW(HSI27200MBeCW170830)",
				"CJQW(HSI25800MBePW170830)"
			],
			"related_warrant": "CHMW(HSI23800MBePW170830);CHLW(HSI26400MBeCW170830);CIIW(HSI25400MBeCW170830);CIHW(HSI24800MBePW170830);CJVW(HSI26600MBePW170830);CJOW(HSI27200MBeCW170830);CJQW(HSI25800MBePW170830)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Jul 17"
		},
		{
			"headline": "Expiry of Warrants on 28 July 2017 (Third Notice)",
			"id": 1665,
			"tickers": [
				"CGSW(HSI24400MBePW170728)",
				"CGRW(HSI25000MBeCW170728)",
				"CGYW(HSI25800MBeCW170728)",
				"CHQW(HSI26600MBeCW170728)",
				"CHPW(HSI25200MBePW170728)"
			],
			"related_warrant": "CGSW(HSI24400MBePW170728);CGRW(HSI25000MBeCW170728);CGYW(HSI25800MBeCW170728);CHQW(HSI26600MBeCW170728);CHPW(HSI25200MBePW170728)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Jul 17"
		},
		{
			"headline": "Expiry of Warrants on 10 July 2017 (Third Notice)",
			"id": 1657,
			"tickers": [
				"CBJW(DBS MB eCW170710)",
				"CCDW(Wilmar MBePW170710)",
				"CCCW(Wilmar MBeCW170710)"
			],
			"related_warrant": "CBJW(DBS MB eCW170710);CCDW(Wilmar MBePW170710);CCCW(Wilmar MBeCW170710)",
			"underlying": "DBS;WILMAR",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR"
				}
			],
			"date": "10 Jul 17"
		},
		{
			"headline": "Expiry of Warrants on 3 July 2017 (Third Notice)",
			"id": 1653,
			"tickers": [
				"CDOW(CapitalaMBePW170703)",
				"CDNW(CapitalaMBeCW170703)",
				"CBEW(DBS MB ePW170703)",
				"CBDW(DBS MB eCW170703)",
				"CCBW(KepCorp MBePW170703)",
				"CCAW(KepCorp MBeCW170703)",
				"CBFW(UOB MB eCW170703)",
				"CBGW(UOB MB ePW170703)",
				"BYUW(Wilmar MBePW170703)",
				"BYTW(Wilmar MBeCW170703)"
			],
			"related_warrant": "CDOW(CapitalaMBePW170703);CDNW(CapitalaMBeCW170703);CBEW(DBS MB ePW170703);CBDW(DBS MB eCW170703);CCBW(KepCorp MBePW170703);CCAW(KepCorp MBeCW170703);CBFW(UOB MB eCW170703);CBGW(UOB MB ePW170703);BYUW(Wilmar MBePW170703);BYTW(Wilmar MBeCW170703)",
			"underlying": "CAPLAND;DBS;KEPPEL;UOB;WILMAR",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR"
				}
			],
			"date": "03 Jul 17"
		},
		{
			"headline": "Expiry of Warrants on 30 June 2017 (Third Notice)",
			"id": 1649,
			"tickers": [
				"CDKW(STI 3050MBeCW170630)",
				"CDMW(STI 2900MBePW170630)",
				"CDJW(STI 2850MBeCW170630)",
				"CDLW(STI 2800MBePW170630)"
			],
			"related_warrant": "CDKW(STI 3050MBeCW170630);CDMW(STI 2900MBePW170630);CDJW(STI 2850MBeCW170630);CDLW(STI 2800MBePW170630)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2017 (Third Notice)",
			"id": 1641,
			"tickers": [
				"CHKW(HSI26200MBeCW170629)",
				"CGJW(HSI24400MBeCW170629)",
				"CGLW(HSI23000MBePW170629)",
				"CGKW(HSI23800MBePW170629)",
				"CGIW(HSI25200MBeCW170629)",
				"CHCW(HSI24600MBePW170629)"
			],
			"related_warrant": "CHKW(HSI26200MBeCW170629);CGJW(HSI24400MBeCW170629);CGLW(HSI23000MBePW170629);CGKW(HSI23800MBePW170629);CGIW(HSI25200MBeCW170629);CHCW(HSI24600MBePW170629)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 28 July 2017 (First Notice)",
			"id": 1637,
			"tickers": [
				"CHQW(HSI26600MBeCW170728)",
				"CHPW(HSI25200MBePW170728)",
				"CGYW(HSI25800MBeCW170728)",
				"CGRW(HSI25000MBeCW170728)",
				"CGSW(HSI24400MBePW170728)"
			],
			"related_warrant": "CHQW(HSI26600MBeCW170728);CHPW(HSI25200MBePW170728);CGYW(HSI25800MBeCW170728);CGRW(HSI25000MBeCW170728);CGSW(HSI24400MBePW170728)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 16 June 2017 (Third Notice)",
			"id": 1633,
			"tickers": [
				"CDEW(S&P 2350MBeCW170616)",
				"CDFW(S&P 2250MBeCW170616)",
				"CDIW(S&P 2200MBePW170616)",
				"CDHW(S&P 2100MBePW170616)",
				"CFCW(S&P 2550MBeCW170616)"
			],
			"related_warrant": "CDEW(S&P 2350MBeCW170616);CDFW(S&P 2250MBeCW170616);CDIW(S&P 2200MBePW170616);CDHW(S&P 2100MBePW170616);CFCW(S&P 2550MBeCW170616)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "19 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 12 June 2017 (Third Notice)",
			"id": 1629,
			"tickers": [
				"CAJW(DBS MB ePW170612)",
				"CAIW(DBS MB eCW170612)"
			],
			"related_warrant": "CAJW(DBS MB ePW170612);CAIW(DBS MB eCW170612)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "12 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 10 July 2017 (First Notice)",
			"id": 1621,
			"tickers": [
				"CBJW(DBS MB eCW170710)",
				"CCDW(Wilmar MBePW170710)",
				"CCCW(Wilmar MBeCW170710)"
			],
			"related_warrant": "CBJW(DBS MB eCW170710);CCDW(Wilmar MBePW170710);CCCW(Wilmar MBeCW170710)",
			"underlying": "DBS;WILMAR",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR"
				}
			],
			"date": "09 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 9 June 2017 (Third Notice)",
			"id": 1625,
			"tickers": [
				"CCPW(NKY 20000MBePW170609)",
				"CCMW(NKY 18000MBePW170609)",
				"CCIW(NKY 15000MBeCW170609)",
				"CCJW(NKY 17000MBeCW170609)",
				"CCNW(NKY 16000MBePW170609)",
				"CCLW(NKY 19000MBeCW170609)",
				"CCKW(NKY 21000MBeCW170609)",
				"CCOW(NKY 14000MBePW170609)"
			],
			"related_warrant": "CCPW(NKY 20000MBePW170609);CCMW(NKY 18000MBePW170609);CCIW(NKY 15000MBeCW170609);CCJW(NKY 17000MBeCW170609);CCNW(NKY 16000MBePW170609);CCLW(NKY 19000MBeCW170609);CCKW(NKY 21000MBeCW170609);CCOW(NKY 14000MBePW170609)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "09 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 5 June 2017 (Third Notice)",
			"id": 1617,
			"tickers": [
				"CABW(DBS MB ePW170605)",
				"CAAW(DBS MB eCW170605)",
				"BZYW(UOB MB eCW170605)",
				"BZZW(UOB MB ePW170605)"
			],
			"related_warrant": "CABW(DBS MB ePW170605);CAAW(DBS MB eCW170605);BZYW(UOB MB eCW170605);BZZW(UOB MB ePW170605)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "05 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 03 July 2017 (First Notice)",
			"id": 1609,
			"tickers": [
				"CDOW(CapitalaMBePW170703)",
				"CDNW(CapitalaMBeCW170703)",
				"CBDW(DBS MB eCW170703)",
				"CBEW(DBS MB ePW170703)",
				"CCBW(KepCorp MBePW170703)",
				"CCAW(KepCorp MBeCW170703)",
				"CBFW(UOB MB eCW170703)",
				"CBGW(UOB MB ePW170703)",
				"BYUW(Wilmar MBePW170703)",
				"BYTW(Wilmar MBeCW170703)"
			],
			"related_warrant": "CDOW(CapitalaMBePW170703);CDNW(CapitalaMBeCW170703);CBDW(DBS MB eCW170703);CBEW(DBS MB ePW170703);CCBW(KepCorp MBePW170703);CCAW(KepCorp MBeCW170703);CBFW(UOB MB eCW170703);CBGW(UOB MB ePW170703);BYUW(Wilmar MBePW170703);BYTW(Wilmar MBeCW170703)",
			"underlying": "CAPLAND;DBS;KEPPEL;UOB;WILMAR",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				},
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR"
				}
			],
			"date": "02 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 2 June 2017 (Third Notice)",
			"id": 1613,
			"tickers": [
				"BXNW(SingTelMBeCW170602)"
			],
			"related_warrant": "BXNW(SingTelMBeCW170602)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "02 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 1 June 2017 (Third Notice)",
			"id": 1605,
			"tickers": [
				"BWZW(SingTelMBePW170601)"
			],
			"related_warrant": "BWZW(SingTelMBePW170601)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "01 Jun 17"
		},
		{
			"headline": "Expiry of Warrants on 30 June 2017 (First Notice)",
			"id": 1597,
			"tickers": [
				"CDKW(STI 3050MBeCW170630)",
				"CDMW(STI 2900MBePW170630)",
				"CDJW(STI 2850MBeCW170630)",
				"CDLW(STI 2800MBePW170630)"
			],
			"related_warrant": "CDKW(STI 3050MBeCW170630);CDMW(STI 2900MBePW170630);CDJW(STI 2850MBeCW170630);CDLW(STI 2800MBePW170630)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "30 May 17"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2017 (First Notice)",
			"id": 1585,
			"tickers": [
				"CHCW(HSI24600MBePW170629)",
				"CHKW(HSI26200MBeCW170629)",
				"CGJW(HSI24400MBeCW170629)",
				"CGLW(HSI23000MBePW170629)",
				"CGKW(HSI23800MBePW170629)",
				"CGIW(HSI25200MBeCW170629)"
			],
			"related_warrant": "CHCW(HSI24600MBePW170629);CHKW(HSI26200MBeCW170629);CGJW(HSI24400MBeCW170629);CGLW(HSI23000MBePW170629);CGKW(HSI23800MBePW170629);CGIW(HSI25200MBeCW170629)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 May 17"
		},
		{
			"headline": "Expiry of Warrants on 29 May 2017 (Third Notice)",
			"id": 1593,
			"tickers": [
				"CFSW(HSI23200MBePW170529)",
				"CFTW(HSI24000MBePW170529)",
				"CFRW(HSI25400MBeCW170529)",
				"CFHW(HSI22400MBePW170529)",
				"CFGW(HSI23800MBeCW170529)",
				"CFFW(HSI24600MBeCW170529)"
			],
			"related_warrant": "CFSW(HSI23200MBePW170529);CFTW(HSI24000MBePW170529);CFRW(HSI25400MBeCW170529);CFHW(HSI22400MBePW170529);CFGW(HSI23800MBeCW170529);CFFW(HSI24600MBeCW170529)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "29 May 17"
		},
		{
			"headline": "Expiry of Warrants on 16 June 2017 (First Notice)",
			"id": 1581,
			"tickers": [
				"CDEW(S&P 2350MBeCW170616)",
				"CDFW(S&P 2250MBeCW170616)",
				"CDHW(S&P 2100MBePW170616)",
				"CDIW(S&P 2200MBePW170616)",
				"CFCW(S&P 2550MBeCW170616)"
			],
			"related_warrant": "CDEW(S&P 2350MBeCW170616);CDFW(S&P 2250MBeCW170616);CDHW(S&P 2100MBePW170616);CDIW(S&P 2200MBePW170616);CFCW(S&P 2550MBeCW170616)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "16 May 17"
		},
		{
			"headline": "Expiry of Warrants on 12 June 2017 (First Notice)",
			"id": 1569,
			"tickers": [
				"CAJW(DBS MB ePW170612)",
				"CAIW(DBS MB eCW170612)"
			],
			"related_warrant": "CAJW(DBS MB ePW170612);CAIW(DBS MB eCW170612)",
			"underlying": "DBS",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				}
			],
			"date": "12 May 17"
		},
		{
			"headline": "Expiry of Warrants on 9 June 2017 (First Notice)",
			"id": 1565,
			"tickers": [
				"CCPW(NKY 20000MBePW170609)",
				"CCMW(NKY 18000MBePW170609)",
				"CCIW(NKY 15000MBeCW170609)",
				"CCJW(NKY 17000MBeCW170609)",
				"CCNW(NKY 16000MBePW170609)",
				"CCLW(NKY 19000MBeCW170609)",
				"CCKW(NKY 21000MBeCW170609)",
				"CCOW(NKY 14000MBePW170609)"
			],
			"related_warrant": "CCPW(NKY 20000MBePW170609);CCMW(NKY 18000MBePW170609);CCIW(NKY 15000MBeCW170609);CCJW(NKY 17000MBeCW170609);CCNW(NKY 16000MBePW170609);CCLW(NKY 19000MBeCW170609);CCKW(NKY 21000MBeCW170609);CCOW(NKY 14000MBePW170609)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "09 May 17"
		},
		{
			"headline": "Expiry of Warrants on 5 June 2017 (First Notice)",
			"id": 1561,
			"tickers": [
				"CABW(DBS MB ePW170605)",
				"CAAW(DBS MB eCW170605)",
				"BZYW(UOB MB eCW170605)",
				"BZZW(UOB MB ePW170605)"
			],
			"related_warrant": "CABW(DBS MB ePW170605);CAAW(DBS MB eCW170605);BZYW(UOB MB eCW170605);BZZW(UOB MB ePW170605)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "05 May 17"
		},
		{
			"headline": "Expiry of Warrants on 2 June 2017 (First Notice)",
			"id": 1557,
			"tickers": [
				"BXNW(SingTelMBeCW170602)"
			],
			"related_warrant": "BXNW(SingTelMBeCW170602)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "02 May 17"
		},
		{
			"headline": "Expiry of Warrants on 29 May 2017 (First Notice)",
			"id": 1545,
			"tickers": [
				"CFTW(HSI24000MBePW170529)",
				"CFRW(HSI25400MBeCW170529)",
				"CFSW(HSI23200MBePW170529)",
				"CFHW(HSI22400MBePW170529)",
				"CFGW(HSI23800MBeCW170529)",
				"CFFW(HSI24600MBeCW170529)"
			],
			"related_warrant": "CFTW(HSI24000MBePW170529);CFRW(HSI25400MBeCW170529);CFSW(HSI23200MBePW170529);CFHW(HSI22400MBePW170529);CFGW(HSI23800MBeCW170529);CFFW(HSI24600MBeCW170529)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Apr 17"
		},
		{
			"headline": "Expiry of Warrants on 1 June 2017 (First Notice)",
			"id": 1549,
			"tickers": [
				"BWZW(SingTelMBePW170601)"
			],
			"related_warrant": "BWZW(SingTelMBePW170601)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "28 Apr 17"
		},
		{
			"headline": "Expiry of Warrants on 28 April 2017 (Third Notice)",
			"id": 1553,
			"tickers": [
				"CBUW(STI 2900MBeCW170428)",
				"CBVW(STI 3000MBeCW170428)",
				"CBXW(STI 2950MBePW170428)",
				"CBWW(STI 2850MBePW170428)"
			],
			"related_warrant": "CBUW(STI 2900MBeCW170428);CBVW(STI 3000MBeCW170428);CBXW(STI 2950MBePW170428);CBWW(STI 2850MBePW170428)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Apr 17"
		},
		{
			"headline": "Expiry of Warrants on 27 April 2017 (Third Notice)",
			"id": 1541,
			"tickers": [
				"CCUW(HSI24200MBeCW170427)",
				"CCVW(HSI21200MBePW170427)",
				"CENW(HSI22800MBePW170427)",
				"CESW(HSI23600MBePW170427)",
				"CFUW(HSI24400MBePW170427)",
				"CFQW(HSI25000MBeCW170427)",
				"CFLW(HSI23400MBeCW170427)"
			],
			"related_warrant": "CCUW(HSI24200MBeCW170427);CCVW(HSI21200MBePW170427);CENW(HSI22800MBePW170427);CESW(HSI23600MBePW170427);CFUW(HSI24400MBePW170427);CFQW(HSI25000MBeCW170427);CFLW(HSI23400MBeCW170427)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Apr 17"
		},
		{
			"headline": "Expiry of Warrants on 3 April 2017 (Third Notice)",
			"id": 1537,
			"tickers": [
				"BYRW(CapitalaMBeCW170403)",
				"BYQW(CapitalaMBePW170403)"
			],
			"related_warrant": "BYRW(CapitalaMBeCW170403);BYQW(CapitalaMBePW170403)",
			"underlying": "CAPLAND",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				}
			],
			"date": "03 Apr 17"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2017 (Third Notice)",
			"id": 1533,
			"tickers": [
				"CCHW(HSI22400MBePW170330)",
				"CCEW(HSI23000MBeCW170330)",
				"CCSW(HSI22200MBeCW170330)",
				"CEMW(HSI24600MBeCW170330)",
				"CCGW(HSI21600MBePW170330)",
				"CEOW(HSI23200MBePW170330)",
				"CCFW(HSI23800MBeCW170330)",
				"CCTW(HSI20600MBePW170330)"
			],
			"related_warrant": "CCHW(HSI22400MBePW170330);CCEW(HSI23000MBeCW170330);CCSW(HSI22200MBeCW170330);CEMW(HSI24600MBeCW170330);CCGW(HSI21600MBePW170330);CEOW(HSI23200MBePW170330);CCFW(HSI23800MBeCW170330);CCTW(HSI20600MBePW170330)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "30 Mar 17"
		},
		{
			"headline": "Expiry of Warrants on 28 April 2017 (First Notice)",
			"id": 1529,
			"tickers": [
				"CBUW(STI 2900MBeCW170428)",
				"CBVW(STI 3000MBeCW170428)",
				"CBXW(STI 2950MBePW170428)",
				"CBWW(STI 2850MBePW170428)"
			],
			"related_warrant": "CBUW(STI 2900MBeCW170428);CBVW(STI 3000MBeCW170428);CBXW(STI 2950MBePW170428);CBWW(STI 2850MBePW170428)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Mar 17"
		},
		{
			"headline": "Expiry of Warrants on 27 April 2017 (First Notice)",
			"id": 1525,
			"tickers": [
				"CFQW(HSI25000MBeCW170427)",
				"CFUW(HSI24400MBePW170427)",
				"CCVW(HSI21200MBePW170427)",
				"CESW(HSI23600MBePW170427)",
				"CCUW(HSI24200MBeCW170427)",
				"CFLW(HSI23400MBeCW170427)",
				"CENW(HSI22800MBePW170427)"
			],
			"related_warrant": "CFQW(HSI25000MBeCW170427);CFUW(HSI24400MBePW170427);CCVW(HSI21200MBePW170427);CESW(HSI23600MBePW170427);CCUW(HSI24200MBeCW170427);CFLW(HSI23400MBeCW170427);CENW(HSI22800MBePW170427)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Mar 17"
		},
		{
			"headline": "Expiry of Warrants on 17 March 2017 (Third Notice)",
			"id": 1521,
			"tickers": [
				"CAXW(S&P 2280MBeCW170317)",
				"CAWW(S&P 2180MBeCW170317)",
				"CAZW(S&P 2130MBePW170317)",
				"CAYW(S&P 2030MBePW170317)"
			],
			"related_warrant": "CAXW(S&P 2280MBeCW170317);CAWW(S&P 2180MBeCW170317);CAZW(S&P 2130MBePW170317);CAYW(S&P 2030MBePW170317)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "20 Mar 17"
		},
		{
			"headline": "Expiry of Warrants on 10 March 2017 (Third Notice)",
			"id": 1517,
			"tickers": [
				"BZCW(NKY 14500MBeCW170310)",
				"BZDW(NKY 15500MBePW170310)",
				"BZEW(NKY 16500MBeCW170310)",
				"BZFW(NKY 17500MBePW170310)",
				"BZGW(NKY 18000MBeCW170310)",
				"BZBW(NKY 14000MBePW170310)",
				"BZHW(NKY 19500MBeCW170310)"
			],
			"related_warrant": "BZCW(NKY 14500MBeCW170310);BZDW(NKY 15500MBePW170310);BZEW(NKY 16500MBeCW170310);BZFW(NKY 17500MBePW170310);BZGW(NKY 18000MBeCW170310);BZBW(NKY 14000MBePW170310);BZHW(NKY 19500MBeCW170310)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "10 Mar 17"
		},
		{
			"headline": "Expiry of Warrants on 6 March 2017 (Third Notice)",
			"id": 1513,
			"tickers": [
				"BYWW(KepCorp MBePW170306)",
				"BYVW(KepCorp MBeCW170306)",
				"BYPW(OCBC Bk MB eCW170306)",
				"BYOW(OCBC Bk MB ePW170306)"
			],
			"related_warrant": "BYWW(KepCorp MBePW170306);BYVW(KepCorp MBeCW170306);BYPW(OCBC Bk MB eCW170306);BYOW(OCBC Bk MB ePW170306)",
			"underlying": "KEPPEL;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "06 Mar 17"
		},
		{
			"headline": "Expiry of Warrants on 03 April 2017 (First Notice)",
			"id": 1509,
			"tickers": [
				"BYRW(CapitalaMBeCW170403)",
				"BYQW(CapitalaMBePW170403)"
			],
			"related_warrant": "BYRW(CapitalaMBeCW170403);BYQW(CapitalaMBePW170403)",
			"underlying": "CAPLAND",
			"underlyings": [
				{
					"underlying_ric": "CATL.SI",
					"underlying_ticker": "C31",
					"underlying_name": "CAPLAND"
				}
			],
			"date": "03 Mar 17"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2017 (First Notice)",
			"id": 1501,
			"tickers": [
				"CEMW(HSI24600MBeCW170330)",
				"CCSW(HSI22200MBeCW170330)",
				"CCHW(HSI22400MBePW170330)",
				"CCFW(HSI23800MBeCW170330)",
				"CEOW(HSI23200MBePW170330)",
				"CCGW(HSI21600MBePW170330)",
				"CCTW(HSI20600MBePW170330)",
				"CCEW(HSI23000MBeCW170330)"
			],
			"related_warrant": "CEMW(HSI24600MBeCW170330);CCSW(HSI22200MBeCW170330);CCHW(HSI22400MBePW170330);CCFW(HSI23800MBeCW170330);CEOW(HSI23200MBePW170330);CCGW(HSI21600MBePW170330);CCTW(HSI20600MBePW170330);CCEW(HSI23000MBeCW170330)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "28 Feb 17"
		},
		{
			"headline": "Expiry of Warrants on 28 February 2017 (Third Notice)",
			"id": 1505,
			"tickers": [
				"BYNW(STI 2750MBePW170228)",
				"BYMW(STI 2950MBeCW170228)"
			],
			"related_warrant": "BYNW(STI 2750MBePW170228);BYMW(STI 2950MBeCW170228)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "28 Feb 17"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2017 (Third Notice)",
			"id": 1497,
			"tickers": [
				"CCYW(HSI22000MBePW170227)",
				"CCWW(HSI21800MBeCW170227)",
				"CCZW(HSI20400MBePW170227)",
				"CCXW(HSI23400MBeCW170227)",
				"CBZW(HSI21200MBePW170227)",
				"CBYW(HSI22600MBeCW170227)"
			],
			"related_warrant": "CCYW(HSI22000MBePW170227);CCWW(HSI21800MBeCW170227);CCZW(HSI20400MBePW170227);CCXW(HSI23400MBeCW170227);CBZW(HSI21200MBePW170227);CBYW(HSI22600MBeCW170227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Feb 17"
		},
		{
			"headline": "Expiry of Warrants on 17 March 2017 (First Notice)",
			"id": 1493,
			"tickers": [
				"CAWW(undefined)",
				"CAXW(undefined)",
				"CAYW(undefined)",
				"CAZW(undefined)"
			],
			"related_warrant": "CAWW(undefined);CAXW(undefined);CAYW(undefined);CAZW(undefined)",
			"underlying": "S&P500",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P500"
				}
			],
			"date": "17 Feb 17"
		},
		{
			"headline": "Expiry of Warrants on 10 March 2017 (First Notice)",
			"id": 1489,
			"tickers": [
				"BZCW(NKY 14500MBeCW170310)",
				"BZDW(NKY 15500MBePW170310)",
				"BZEW(NKY 16500MBeCW170310)",
				"BZFW(NKY 17500MBePW170310)",
				"BZGW(NKY 18000MBeCW170310)",
				"BZBW(NKY 14000MBePW170310)",
				"BZHW(NKY 19500MBeCW170310)"
			],
			"related_warrant": "BZCW(NKY 14500MBeCW170310);BZDW(NKY 15500MBePW170310);BZEW(NKY 16500MBeCW170310);BZFW(NKY 17500MBePW170310);BZGW(NKY 18000MBeCW170310);BZBW(NKY 14000MBePW170310);BZHW(NKY 19500MBeCW170310)",
			"underlying": "N225",
			"underlyings": [
				{
					"underlying_ric": "SSIcv1",
					"underlying_ticker": "N225",
					"underlying_name": "N225"
				}
			],
			"date": "10 Feb 17"
		},
		{
			"headline": "Expiry of Warrants on 6 March 2017 (First Notice)",
			"id": 1485,
			"tickers": [
				"BYWW(KepCorp MBePW170306)",
				"BYVW(KepCorp MBeCW170306)",
				"BYPW(OCBC Bk MB eCW170306)",
				"BYOW(OCBC Bk MB ePW170306)"
			],
			"related_warrant": "BYWW(KepCorp MBePW170306);BYVW(KepCorp MBeCW170306);BYPW(OCBC Bk MB eCW170306);BYOW(OCBC Bk MB ePW170306)",
			"underlying": "KEPPEL;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "06 Feb 17"
		},
		{
			"headline": "Expiry of Warrants on 1 February 2017 (Third Notice)",
			"id": 1477,
			"tickers": [
				"BWSW(DBS MB ePW170201)",
				"BWRW(DBS MB eCW170201)",
				"BWUW(UOB MB ePW170201)",
				"BWTW(UOB MB eCW170201)"
			],
			"related_warrant": "BWSW(DBS MB ePW170201);BWRW(DBS MB eCW170201);BWUW(UOB MB ePW170201);BWTW(UOB MB eCW170201)",
			"underlying": "DBS;UOB",
			"underlyings": [
				{
					"underlying_ric": "DBSM.SI",
					"underlying_ticker": "D05",
					"underlying_name": "DBS"
				},
				{
					"underlying_ric": "UOBH.SI",
					"underlying_ticker": "U11",
					"underlying_name": "UOB"
				}
			],
			"date": "01 Feb 17"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2017 (First Notice)",
			"id": 1469,
			"tickers": [
				"CCYW(HSI22000MBePW170227)",
				"CBYW(HSI22600MBeCW170227)",
				"CBZW(HSI21200MBePW170227)",
				"CCXW(HSI23400MBeCW170227)",
				"CCZW(HSI20400MBePW170227)",
				"CCWW(HSI21800MBeCW170227)"
			],
			"related_warrant": "CCYW(HSI22000MBePW170227);CBYW(HSI22600MBeCW170227);CBZW(HSI21200MBePW170227);CCXW(HSI23400MBeCW170227);CCZW(HSI20400MBePW170227);CCWW(HSI21800MBeCW170227)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "27 Jan 17"
		},
		{
			"headline": "Expiry of Warrants on 28 February 2017 (First Notice)",
			"id": 1473,
			"tickers": [
				"BYNW(STI 2750MBePW170228)",
				"BYMW(STI 2950MBeCW170228)"
			],
			"related_warrant": "BYNW(STI 2750MBePW170228);BYMW(STI 2950MBeCW170228)",
			"underlying": "STI",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STI"
				}
			],
			"date": "27 Jan 17"
		},
		{
			"headline": "Expiry of Warrants on 26 January 2017 (Third Notice)",
			"id": 1465,
			"tickers": [
				"CAPW(HSI22400MBePW170126)",
				"CAOW(HSI23000MBeCW170126)",
				"CAQW(HSI21400MBePW170126)",
				"CANW(HSI23800MBeCW170126)",
				"CCQW(HSI22200MBeCW170126)",
				"CCRW(HSI20600MBePW170126)"
			],
			"related_warrant": "CAPW(HSI22400MBePW170126);CAOW(HSI23000MBeCW170126);CAQW(HSI21400MBePW170126);CANW(HSI23800MBeCW170126);CCQW(HSI22200MBeCW170126);CCRW(HSI20600MBePW170126)",
			"underlying": "HSI",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HSI"
				}
			],
			"date": "26 Jan 17"
		},
		{
			"headline": "Expiry of Warrants on 23 January 2017 (Third Notice)",
			"id": 1457,
			"tickers": [
				"BQHW(GLP MB eCW170123)"
			],
			"related_warrant": "BQHW(GLP MB eCW170123)",
			"underlying": "GLOBAL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL"
				}
			],
			"date": "23 Jan 17"
		},
		{
			"headline": "Expiry of Warrants on 18 January 2017 (Third Notice)",
			"id": 1453,
			"tickers": [
				"BVXW(OCBC Bk MBeCW170118)"
			],
			"related_warrant": "BVXW(OCBC Bk MBeCW170118)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "18 Jan 17"
		},
		{
			"headline": "Expiry of Warrants on 1 February 2017 (First Notice)",
			"id": 1445,
			"tickers": [
				"BWSW(DBS MB ePW170201)",
				"BWRW(DBS MB eCW170201)",
				"BWUW(UOB MB ePW170201)",
				"BWTW(UOB MB eCW170201)"
			],
			"related_warrant": "BWSW(DBS MB ePW170201);BWRW(DBS MB eCW170201);BWUW(UOB MB ePW170201);BWTW(UOB MB eCW170201)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "30 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2016 (Third Notice)",
			"id": 1449,
			"tickers": [
				"BWOW(STI 2800MBePW161230)",
				"BWPW(STI 3000MBeCW161230)"
			],
			"related_warrant": "BWOW(STI 2800MBePW161230);BWPW(STI 3000MBeCW161230)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 29 December 2016 (Third Notice)",
			"id": 1441,
			"tickers": [
				"BXJW(ChinaA5010500MBeCW161229)",
				"BXKW(ChinaA509500MBePW161229)",
				"CACW(HSI22400MBeCW161229)",
				"BYXW(HSI22800MBePW161229)",
				"BZAW(HSI25000MBeCW161229)",
				"BYYW(HSI23600MBePW161229)",
				"BYZW(HSI24200MBeCW161229)",
				"CADW(HSI21000MBePW161229)",
				"BZWW(HSI22000MBePW161229)",
				"BZVW(HSI23400MBeCW161229)"
			],
			"related_warrant": "BXJW(ChinaA5010500MBeCW161229);BXKW(ChinaA509500MBePW161229);CACW(HSI22400MBeCW161229);BYXW(HSI22800MBePW161229);BZAW(HSI25000MBeCW161229);BYYW(HSI23600MBePW161229);BYZW(HSI24200MBeCW161229);CADW(HSI21000MBePW161229);BZWW(HSI22000MBePW161229);BZVW(HSI23400MBeCW161229)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 27 December 2016 (Third Notice)",
			"id": 1433,
			"tickers": [
				"BUNW(OCBC Bk MB ePW161227)"
			],
			"related_warrant": "BUNW(OCBC Bk MB ePW161227)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "27 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 26 January 2017 (First Notice)",
			"id": 1425,
			"tickers": [
				"CAQW(HSI21400MBePW170126)",
				"CAOW(HSI23000MBeCW170126)",
				"CAPW(HSI22400MBePW170126)",
				"CANW(HSI23800MBeCW170126)",
				"CCRW(HSI20600MBePW170126)",
				"CCQW(HSI22200MBeCW170126)"
			],
			"related_warrant": "CAQW(HSI21400MBePW170126);CAOW(HSI23000MBeCW170126);CAPW(HSI22400MBePW170126);CANW(HSI23800MBeCW170126);CCRW(HSI20600MBePW170126);CCQW(HSI22200MBeCW170126)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "23 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 23 January 2017 (First Notice)",
			"id": 1429,
			"tickers": [
				"BQHW(GLP MB eCW170123)"
			],
			"related_warrant": "BQHW(GLP MB eCW170123)",
			"underlying": "GLOBAL LOG PROP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				}
			],
			"date": "23 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 16 December 2016 (Third Notice)",
			"id": 1421,
			"tickers": [
				""
			],
			"related_warrant": "",
			"underlying": "",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": ""
				}
			],
			"date": "19 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 18 January 2017 (First Notice)",
			"id": 1417,
			"tickers": [
				"BVXW(OCBC Bk MBeCW170118)"
			],
			"related_warrant": "BVXW(OCBC Bk MBeCW170118)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "16 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 13 December 2016 (Third Notice)",
			"id": 1413,
			"tickers": [
				"BNRW(KepCorp MBeCW161213)",
				"BNTW(KepCorp MBePW161213)"
			],
			"related_warrant": "BNRW(KepCorp MBeCW161213);BNTW(KepCorp MBePW161213)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "13 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 12 December 2016 (Third Notice)",
			"id": 1409,
			"tickers": [
				"BNXW(CapitalaMBeCW161212)",
				"BNYW(CapitalaMBePW161212)",
				"BNWW(GLP MB ePW161212)",
				"BNVW(GLP MB eCW161212)",
				"BNSW(KepCorp MBeCW161212)",
				"BNUW(KepCorp MBePW161212)",
				"BNQW(SembMar MBeCW161212)"
			],
			"related_warrant": "BNXW(CapitalaMBeCW161212);BNYW(CapitalaMBePW161212);BNWW(GLP MB ePW161212);BNVW(GLP MB eCW161212);BNSW(KepCorp MBeCW161212);BNUW(KepCorp MBePW161212);BNQW(SembMar MBeCW161212)",
			"underlying": "CAPITALAND;GLOBAL LOG PROP;KEPPEL CORP;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "12 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 9 December 2016 (Third Notice)",
			"id": 1405,
			"tickers": [
				"BWFW(NKY 16000MBeCW161209)",
				"BWHW(NKY 17000MBePW161209)",
				"BWEW(NKY 17500MBeCW161209)",
				"BWGW(NKY 14000MBeCW161209)",
				"BWJW(NKY 13500MBePW161209)",
				"BWDW(NKY 19500MBeCW161209)",
				"BWIW(NKY 15500MBePW161209)"
			],
			"related_warrant": "BWFW(NKY 16000MBeCW161209);BWHW(NKY 17000MBePW161209);BWEW(NKY 17500MBeCW161209);BWGW(NKY 14000MBeCW161209);BWJW(NKY 13500MBePW161209);BWDW(NKY 19500MBeCW161209);BWIW(NKY 15500MBePW161209)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "09 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 6 December 2016 (Third Notice)",
			"id": 1401,
			"tickers": [
				"BNGW(SingPost MBeCW161206)",
				"BNFW(Yangziji MB eCW161206)"
			],
			"related_warrant": "BNGW(SingPost MBeCW161206);BNFW(Yangziji MB eCW161206)",
			"underlying": "SINGAPORE POST;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "06 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 5 December 2016 (Third Notice)",
			"id": 1397,
			"tickers": [
				"BPUW(KepCorp MB eCW161205)",
				"BNHW(SingPost MBeCW161205)"
			],
			"related_warrant": "BPUW(KepCorp MB eCW161205);BNHW(SingPost MBeCW161205)",
			"underlying": "KEPPEL CORP;SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "05 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 1 Dec 2016 (Third Notice)",
			"id": 1389,
			"tickers": [
				"BLCW(CapitalaMBeCW161201)",
				"BVTW(DBS MB ePW161201)",
				"BNJW(OCBC Bk MB ePW161201)",
				"BVUW(UOB MB ePW161201)",
				"BLBW(SingTelMBeCW161201)",
				"BLEW(Wilmar MBeCW161201)",
				"BLDW(Wilmar MBePW161201)",
				"BLVW(Yangziji MB eCW161201)"
			],
			"related_warrant": "BLCW(CapitalaMBeCW161201);BVTW(DBS MB ePW161201);BNJW(OCBC Bk MB ePW161201);BVUW(UOB MB ePW161201);BLBW(SingTelMBeCW161201);BLEW(Wilmar MBeCW161201);BLDW(Wilmar MBePW161201);BLVW(Yangziji MB eCW161201)",
			"underlying": "CAPITALAND;DBS GRP HLDGS;OCBC;UOB LTD;SINGTEL;WILMAR INTL;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "01 Dec 16"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2016 (First Notice)",
			"id": 1381,
			"tickers": [
				"BWOW(STI 2800MBePW161230)",
				"BWPW(STI 3000MBeCW161230)"
			],
			"related_warrant": "BWOW(STI 2800MBePW161230);BWPW(STI 3000MBeCW161230)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 30 November 2016 (Third Notice)",
			"id": 1385,
			"tickers": [
				"BUMW(UOB MB eCW161130)"
			],
			"related_warrant": "BUMW(UOB MB eCW161130)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "30 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 29 December 2016 (First Notice)",
			"id": 1373,
			"tickers": [
				"BXJW(ChinaA5010500MBeCW161229)",
				"BXKW(ChinaA509500MBePW161229)",
				"BZAW(HSI25000MBeCW161229)",
				"BYYW(HSI23600MBePW161229)",
				"BYZW(HSI24200MBeCW161229)",
				"BYXW(HSI22800MBePW161229)",
				"BZVW(HSI23400MBeCW161229)",
				"CADW(HSI21000MBePW161229)",
				"CACW(HSI22400MBeCW161229)",
				"BZWW(HSI22000MBePW161229)"
			],
			"related_warrant": "BXJW(ChinaA5010500MBeCW161229);BXKW(ChinaA509500MBePW161229);BZAW(HSI25000MBeCW161229);BYYW(HSI23600MBePW161229);BYZW(HSI24200MBeCW161229);BYXW(HSI22800MBePW161229);BZVW(HSI23400MBeCW161229);CADW(HSI21000MBePW161229);CACW(HSI22400MBeCW161229);BZWW(HSI22000MBePW161229)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 29 November 2016 (Third Notice)",
			"id": 1377,
			"tickers": [
				"BYFW(HSI23800MBeCW161129)",
				"BYHW(HSI23200MBePW161129)",
				"BYGW(HSI24600MBeCW161129)",
				"BZIW(HSI23000MBeCW161129)",
				"BZJW(HSI22400MBePW161129)"
			],
			"related_warrant": "BYFW(HSI23800MBeCW161129);BYHW(HSI23200MBePW161129);BYGW(HSI24600MBeCW161129);BZIW(HSI23000MBeCW161129);BZJW(HSI22400MBePW161129)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 27 December 2016 (First Notice)",
			"id": 1369,
			"tickers": [
				"BUNW(OCBC Bk MB ePW161227)"
			],
			"related_warrant": "BUNW(OCBC Bk MB ePW161227)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "25 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 16 December 2016 (First Notice)",
			"id": 1365,
			"tickers": [
				"BWVW(undefined)",
				"BWWW(undefined)",
				"BWXW(undefined)",
				"BWYW(undefined)"
			],
			"related_warrant": "BWVW(undefined);BWWW(undefined);BWXW(undefined);BWYW(undefined)",
			"underlying": "S&P 500 INDEX",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P 500 INDEX"
				}
			],
			"date": "16 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 15 November 2016 (Third Notice)",
			"id": 1357,
			"tickers": [
				"BNIW(SingTelMBePW161115)",
				"BNCW(SingTelMBeCW161115)"
			],
			"related_warrant": "BNIW(SingTelMBePW161115);BNCW(SingTelMBeCW161115)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "15 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 14 November 2016 (Third Notice)",
			"id": 1349,
			"tickers": [
				"BNDW(SingTelMBePW161114)",
				"BNBW(SingTelMBeCW161114)"
			],
			"related_warrant": "BNDW(SingTelMBePW161114);BNBW(SingTelMBeCW161114)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "14 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 12 December 2016 (First Notice)",
			"id": 1345,
			"tickers": [
				"BNXW(CapitalaMBeCW161212)",
				"BNYW(CapitalaMBePW161212)",
				"BNWW(GLP MB ePW161212)",
				"BNVW(GLP MB eCW161212)",
				"BNSW(KepCorp MBeCW161212)",
				"BNUW(KepCorp MBePW161212)",
				"BNQW(SembMar MBeCW161212)"
			],
			"related_warrant": "BNXW(CapitalaMBeCW161212);BNYW(CapitalaMBePW161212);BNWW(GLP MB ePW161212);BNVW(GLP MB eCW161212);BNSW(KepCorp MBeCW161212);BNUW(KepCorp MBePW161212);BNQW(SembMar MBeCW161212)",
			"underlying": "CAPITALAND;GLOBAL LOG PROP;KEPPEL CORP;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "11 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 13 December 2016 (First Notice)",
			"id": 1341,
			"tickers": [
				"BNRW(KepCorp MBeCW161213)",
				"BNTW(KepCorp MBePW161213)"
			],
			"related_warrant": "BNRW(KepCorp MBeCW161213);BNTW(KepCorp MBePW161213)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "11 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 9 December 2016 (First Notice)",
			"id": 1333,
			"tickers": [
				"BWFW(NKY 16000MBeCW161209)",
				"BWHW(NKY 17000MBePW161209)",
				"BWEW(NKY 17500MBeCW161209)",
				"BWGW(NKY 14000MBeCW161209)",
				"BWJW(NKY 13500MBePW161209)",
				"BWDW(NKY 19500MBeCW161209)",
				"BWIW(NKY 15500MBePW161209)"
			],
			"related_warrant": "BWFW(NKY 16000MBeCW161209);BWHW(NKY 17000MBePW161209);BWEW(NKY 17500MBeCW161209);BWGW(NKY 14000MBeCW161209);BWJW(NKY 13500MBePW161209);BWDW(NKY 19500MBeCW161209);BWIW(NKY 15500MBePW161209)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "09 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 5 December 2016 (First Notice)",
			"id": 1325,
			"tickers": [
				"BPUW(KepCorp MB eCW161205)",
				"BNHW(SingPost MBeCW161205)"
			],
			"related_warrant": "BPUW(KepCorp MB eCW161205);BNHW(SingPost MBeCW161205)",
			"underlying": "KEPPEL CORP;SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "04 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 6 December 2016 (First Notice)",
			"id": 1329,
			"tickers": [
				"BNGW(SingPost MBeCW161206)",
				"BNFW(Yangziji MB eCW161206)"
			],
			"related_warrant": "BNGW(SingPost MBeCW161206);BNFW(Yangziji MB eCW161206)",
			"underlying": "SINGAPORE POST;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "04 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 01 December 2016 (First Notice)",
			"id": 1317,
			"tickers": [
				"BLBW(SingTelMBeCW161201)",
				"BLCW(CapitalaMBeCW161201)",
				"BLEW(Wilmar MBeCW161201)",
				"BLDW(Wilmar MBePW161201)",
				"BNJW(OCBC Bk MB ePW161201)",
				"BVTW(DBS MB ePW161201)",
				"BVUW(UOB MB ePW161201)",
				"BLVW(Yangziji MB eCW161201)"
			],
			"related_warrant": "BLBW(SingTelMBeCW161201);BLCW(CapitalaMBeCW161201);BLEW(Wilmar MBeCW161201);BLDW(Wilmar MBePW161201);BNJW(OCBC Bk MB ePW161201);BVTW(DBS MB ePW161201);BVUW(UOB MB ePW161201);BLVW(Yangziji MB eCW161201)",
			"underlying": "SINGTEL;CAPITALAND;WILMAR INTL;ocbc;DBS GRP HLDGS;UOB LTD;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "ocbc"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "01 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 01 November 2016 (Third Notice)",
			"id": 1321,
			"tickers": [
				"BPIW(KepCorp MBePW161101)",
				"BPHW(KepCorp MBeCW161101)"
			],
			"related_warrant": "BPIW(KepCorp MBePW161101);BPHW(KepCorp MBeCW161101)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "01 Nov 16"
		},
		{
			"headline": "Expiry of Warrants on 31 October 2016 (Third Notice)",
			"id": 1313,
			"tickers": [
				"BUDW(DBS MB eCW161031)",
				"BURW(STI 2650MBePW161031)",
				"BUQW(STI 2800MBeCW161031)",
				"BUCW(UOB MB eCW161031)"
			],
			"related_warrant": "BUDW(DBS MB eCW161031);BURW(STI 2650MBePW161031);BUQW(STI 2800MBeCW161031);BUCW(UOB MB eCW161031)",
			"underlying": "DBS GRP HLDGS;STRAITS TIMES;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "31 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 29 November 2016 (First Notice)",
			"id": 1301,
			"tickers": [
				"BZIW(HSI23000MBeCW161129)",
				"BYGW(HSI24600MBeCW161129)",
				"BYHW(HSI23200MBePW161129)",
				"BYFW(HSI23800MBeCW161129)",
				"BZJW(HSI22400MBePW161129)"
			],
			"related_warrant": "BZIW(HSI23000MBeCW161129);BYGW(HSI24600MBeCW161129);BYHW(HSI23200MBePW161129);BYFW(HSI23800MBeCW161129);BZJW(HSI22400MBePW161129)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 30 November 2016 (First Notice)",
			"id": 1305,
			"tickers": [
				"BUMW(UOB MB eCW161130)"
			],
			"related_warrant": "BUMW(UOB MB eCW161130)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "28 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 28 October 2016 (Third Notice)",
			"id": 1309,
			"tickers": [
				"BVRW(ChinaA5010000MBeCW161028)",
				"BVSW(ChinaA509500MBePW161028)",
				"BTSW(HSI21600MBeCW161028)",
				"BXUW(HSI24200MBeCW161028)",
				"BXTW(HSI23600MBePW161028)",
				"BXIW(HSI22800MBePW161028)",
				"BXFW(HSI23400MBeCW161028)",
				"BXHW(HSI22000MBePW161028)",
				"BTTW(HSI22600MBeCW161028)"
			],
			"related_warrant": "BVRW(ChinaA5010000MBeCW161028);BVSW(ChinaA509500MBePW161028);BTSW(HSI21600MBeCW161028);BXUW(HSI24200MBeCW161028);BXTW(HSI23600MBePW161028);BXIW(HSI22800MBePW161028);BXFW(HSI23400MBeCW161028);BXHW(HSI22000MBePW161028);BTTW(HSI22600MBeCW161028)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 14 November 2016 (First Notice)",
			"id": 1293,
			"tickers": [
				"BNDW(SingTelMBePW161114)",
				"BNBW(SingTelMBeCW161114)"
			],
			"related_warrant": "BNDW(SingTelMBePW161114);BNBW(SingTelMBeCW161114)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "14 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 15 November 2016 (First Notice)",
			"id": 1297,
			"tickers": [
				"BNIW(SingTelMBePW161115)",
				"BNCW(SingTelMBeCW161115)"
			],
			"related_warrant": "BNIW(SingTelMBePW161115);BNCW(SingTelMBeCW161115)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "14 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 04 October 2016 (Third Notice)",
			"id": 1289,
			"tickers": [
				"BQEW(DBS MB eCW161004)",
				"BNPW(OCBC Bk MBeCW161004)"
			],
			"related_warrant": "BQEW(DBS MB eCW161004);BNPW(OCBC Bk MBeCW161004)",
			"underlying": "DBS GRP HLDGS;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "04 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 03 October 2016 (Third Notice)",
			"id": 1285,
			"tickers": [
				"BNNW(DBS MB eCW161003)",
				"BNOW(DBS MB ePW161003)",
				"BMKW(OCBC Bk MB eCW161003)",
				"BNLW(UOB MB ePW161003)",
				"BDZW(Wilmar MBeCW161003)"
			],
			"related_warrant": "BNNW(DBS MB eCW161003);BNOW(DBS MB ePW161003);BMKW(OCBC Bk MB eCW161003);BNLW(UOB MB ePW161003);BDZW(Wilmar MBeCW161003)",
			"underlying": "DBS GRP HLDGS;OCBC;UOB LTD;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "03 Oct 16"
		},
		{
			"headline": "Expiry of Warrants on 31 October 2016 (First Notice)",
			"id": 1277,
			"tickers": [
				"BUDW(DBS MB eCW161031)",
				"BURW(STI 2650MBePW161031)",
				"BUQW(STI 2800MBeCW161031)",
				"BUCW(UOB MB eCW161031)"
			],
			"related_warrant": "BUDW(DBS MB eCW161031);BURW(STI 2650MBePW161031);BUQW(STI 2800MBeCW161031);BUCW(UOB MB eCW161031)",
			"underlying": "DBS GRP HLDGS;STRAITS TIMES;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "30 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 01 November 2016 (First Notice)",
			"id": 1281,
			"tickers": [
				"BPIW(KepCorp MBePW161101)",
				"BPHW(KepCorp MBeCW161101)"
			],
			"related_warrant": "BPIW(KepCorp MBePW161101);BPHW(KepCorp MBeCW161101)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "30 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2016 (Third Notice)",
			"id": 1273,
			"tickers": [
				"BWKW(HSI23000MBeCW160929)",
				"BXAW(HSI23800MBeCW160929)",
				"BTLW(HSI19000MBePW160929)",
				"BVVW(HSI22200MBeCW160929)",
				"BVGW(HSI19200MBePW160929)",
				"BWLW(HSI21600MBePW160929)",
				"BTKW(HSI22000MBeCW160929)",
				"BVFW(HSI21400MBeCW160929)",
				"BXBW(HSI22400MBePW160929)"
			],
			"related_warrant": "BWKW(HSI23000MBeCW160929);BXAW(HSI23800MBeCW160929);BTLW(HSI19000MBePW160929);BVVW(HSI22200MBeCW160929);BVGW(HSI19200MBePW160929);BWLW(HSI21600MBePW160929);BTKW(HSI22000MBeCW160929);BVFW(HSI21400MBeCW160929);BXBW(HSI22400MBePW160929)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 28 October 2016 (First Notice)",
			"id": 1269,
			"tickers": [
				"BVRW(ChinaA5010000MBeCW161028)",
				"BVSW(ChinaA509500MBePW161028)",
				"BXUW(HSI24200MBeCW161028)",
				"BTSW(HSI21600MBeCW161028)",
				"BXHW(HSI22000MBePW161028)",
				"BXFW(HSI23400MBeCW161028)",
				"BXIW(HSI22800MBePW161028)",
				"BXTW(HSI23600MBePW161028)",
				"BTTW(HSI22600MBeCW161028)"
			],
			"related_warrant": "BVRW(ChinaA5010000MBeCW161028);BVSW(ChinaA509500MBePW161028);BXUW(HSI24200MBeCW161028);BTSW(HSI21600MBeCW161028);BXHW(HSI22000MBePW161028);BXFW(HSI23400MBeCW161028);BXIW(HSI22800MBePW161028);BXTW(HSI23600MBePW161028);BTTW(HSI22600MBeCW161028)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 9 September 2016 (Third Notice)",
			"id": 1265,
			"tickers": [
				"BSBW(NKY 15000MBeCW160909)",
				"BSDW(NKY 18500MBeCW160909)",
				"BSEW(NKY 13000MBePW160909)",
				"BSGW(NKY 14500MBePW160909)",
				"BSFW(NKY 16000MBePW160909)",
				"BSAW(NKY 13500MBeCW160909)",
				"BSCW(NKY 16500MBeCW160909)"
			],
			"related_warrant": "BSBW(NKY 15000MBeCW160909);BSDW(NKY 18500MBeCW160909);BSEW(NKY 13000MBePW160909);BSGW(NKY 14500MBePW160909);BSFW(NKY 16000MBePW160909);BSAW(NKY 13500MBeCW160909);BSCW(NKY 16500MBeCW160909)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "09 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 3 October 2016 (First Expiry)",
			"id": 1253,
			"tickers": [
				"BNNW(DBS MB eCW161003)",
				"BNOW(DBS MB ePW161003)",
				"BMKW(OCBC Bk MB eCW161003)",
				"BDZW(Wilmar MBeCW161003)",
				"BNLW(UOB MB ePW161003)"
			],
			"related_warrant": "BNNW(DBS MB eCW161003);BNOW(DBS MB ePW161003);BMKW(OCBC Bk MB eCW161003);BDZW(Wilmar MBeCW161003);BNLW(UOB MB ePW161003)",
			"underlying": "DBS GRP HLDGS;OCBC;WILMAR INTL;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "02 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 4 October 2016 (First Notice)",
			"id": 1257,
			"tickers": [
				"BQEW(DBS MB eCW161004)",
				"BNPW(OCBC Bk MBeCW161004)"
			],
			"related_warrant": "BQEW(DBS MB eCW161004);BNPW(OCBC Bk MBeCW161004)",
			"underlying": "DBS GRP HLDGS;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "02 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 2 September 2016 (Third Notice)",
			"id": 1261,
			"tickers": [
				"BGDW(CapitalaMBeCW160902)",
				"BGEW(CapitalaMBePW160902)",
				"BMIW(KepCorp MB ePW160902)",
				"BMJW(KepCorp MB eCW160902)",
				"BMLW(SembMar MBeCW160902)",
				"BGBW(SingPost MBeCW160902)"
			],
			"related_warrant": "BGDW(CapitalaMBeCW160902);BGEW(CapitalaMBePW160902);BMIW(KepCorp MB ePW160902);BMJW(KepCorp MB eCW160902);BMLW(SembMar MBeCW160902);BGBW(SingPost MBeCW160902)",
			"underlying": "CAPITALAND;KEPPEL CORP;SEMBCORP MARINE;SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "02 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 01 September 2016 (Third Notice)",
			"id": 1249,
			"tickers": [
				"BDSW(CapitalaMBeCW160901)",
				"BDWW(Ezion MBeCW160901)",
				"BKFW(KepCorp MB eCW160901)",
				"BKEW(KepCorp MB ePW160901)"
			],
			"related_warrant": "BDSW(CapitalaMBeCW160901);BDWW(Ezion MBeCW160901);BKFW(KepCorp MB eCW160901);BKEW(KepCorp MB ePW160901)",
			"underlying": "CAPITALAND;EZION HOLDINGS;KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "01 Sep 16"
		},
		{
			"headline": "Expiry of Warrants on 31 August 2016 (Third Notice)",
			"id": 1245,
			"tickers": [
				"BPWW(STI 2650MBeCW160831)",
				"BPVW(STI 2500MBePW160831)",
				"BSVW(STI 2800MBePW160831)",
				"BSUW(STI 2950MBeCW160831)"
			],
			"related_warrant": "BPWW(STI 2650MBeCW160831);BPVW(STI 2500MBePW160831);BSVW(STI 2800MBePW160831);BSUW(STI 2950MBeCW160831)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "31 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 30 August 2016 (Third Notice)",
			"id": 1241,
			"tickers": [
				"BRZW(CN9000MBePW160830)",
				"BRYW(CN9500MBeCW160830)",
				"BVMW(HSI21000MBeCW160830)",
				"BVDW(HSI18800MBePW160830)",
				"BVCW(HSI20200MBeCW160830)",
				"BVOW(HSI20400MBePW160830)",
				"BVBW(HSI19400MBeCW160830)",
				"BVWW(HSI21200MBePW160830)",
				"BVEW(HSI19600MBePW160830)",
				"BVNW(HSI21800MBeCW160830)"
			],
			"related_warrant": "BRZW(CN9000MBePW160830);BRYW(CN9500MBeCW160830);BVMW(HSI21000MBeCW160830);BVDW(HSI18800MBePW160830);BVCW(HSI20200MBeCW160830);BVOW(HSI20400MBePW160830);BVBW(HSI19400MBeCW160830);BVWW(HSI21200MBePW160830);BVEW(HSI19600MBePW160830);BVNW(HSI21800MBeCW160830)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2016 (First Notice)",
			"id": 1237,
			"tickers": [
				"BVGW(HSI19200MBePW160929)",
				"BTLW(HSI19000MBePW160929)",
				"BTKW(HSI22000MBeCW160929)",
				"BWKW(HSI23000MBeCW160929)",
				"BWLW(HSI21600MBePW160929)",
				"BVFW(HSI21400MBeCW160929)",
				"BXBW(HSI22400MBePW160929)",
				"BVVW(HSI22200MBeCW160929)",
				"BXAW(HSI23800MBeCW160929)"
			],
			"related_warrant": "BVGW(HSI19200MBePW160929);BTLW(HSI19000MBePW160929);BTKW(HSI22000MBeCW160929);BWKW(HSI23000MBeCW160929);BWLW(HSI21600MBePW160929);BVFW(HSI21400MBeCW160929);BXBW(HSI22400MBePW160929);BVVW(HSI22200MBeCW160929);BXAW(HSI23800MBeCW160929)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 16 September 2016 (First Notice)",
			"id": 1233,
			"tickers": [
				"BUTW(S&P 2050MBeCW160916)",
				"BUUW(S&P 2120MBeCW160916)",
				"BUVW(S&P 1950MBePW160916)",
				"BUWW(S&P 2020MBePW160916)"
			],
			"related_warrant": "BUTW(S&P 2050MBeCW160916);BUUW(S&P 2120MBeCW160916);BUVW(S&P 1950MBePW160916);BUWW(S&P 2020MBePW160916)",
			"underlying": "S&P 500 INDEX",
			"underlyings": [
				{
					"underlying_ric": "ESc1",
					"underlying_ticker": "S&P500",
					"underlying_name": "S&P 500 INDEX"
				}
			],
			"date": "16 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 9 September 2016 (First Notice)",
			"id": 1221,
			"tickers": [
				"BSBW(NKY 15000MBeCW160909)",
				"BSDW(NKY 18500MBeCW160909)",
				"BSEW(NKY 13000MBePW160909)",
				"BSGW(NKY 14500MBePW160909)",
				"BSFW(NKY 16000MBePW160909)",
				"BSAW(NKY 13500MBeCW160909)",
				"BSCW(NKY 16500MBeCW160909)"
			],
			"related_warrant": "BSBW(NKY 15000MBeCW160909);BSDW(NKY 18500MBeCW160909);BSEW(NKY 13000MBePW160909);BSGW(NKY 14500MBePW160909);BSFW(NKY 16000MBePW160909);BSAW(NKY 13500MBeCW160909);BSCW(NKY 16500MBeCW160909)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "08 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 03 August 2016 (Third Notice)",
			"id": 1217,
			"tickers": [
				"AVUW(NOL MB eCW160803)"
			],
			"related_warrant": "AVUW(NOL MB eCW160803)",
			"underlying": "NEPTUNE OL LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				}
			],
			"date": "03 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 02 September 2016 (First Notice)",
			"id": 1205,
			"tickers": [
				"BGDW(CapitalaMBeCW160902)",
				"BGEW(CapitalaMBePW160902)",
				"BMIW(KepCorp MB ePW160902)",
				"BMJW(KepCorp MB eCW160902)",
				"BMLW(SembMar MBeCW160902)",
				"BGBW(SingPost MBeCW160902)"
			],
			"related_warrant": "BGDW(CapitalaMBeCW160902);BGEW(CapitalaMBePW160902);BMIW(KepCorp MB ePW160902);BMJW(KepCorp MB eCW160902);BMLW(SembMar MBeCW160902);BGBW(SingPost MBeCW160902)",
			"underlying": "CAPITALAND;KEPPEL CORP;SEMBCORP MARINE;SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "02 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 01 September 2016 (First Notice)",
			"id": 1201,
			"tickers": [
				"BDSW(CapitalaMBeCW160901)",
				"BDWW(Ezion MBeCW160901)",
				"BKFW(KepCorp MB eCW160901)",
				"BKEW(KepCorp MB ePW160901)"
			],
			"related_warrant": "BDSW(CapitalaMBeCW160901);BDWW(Ezion MBeCW160901);BKFW(KepCorp MB eCW160901);BKEW(KepCorp MB ePW160901)",
			"underlying": "CAPITALAND;EZION HOLDINGS;KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "01 Aug 16"
		},
		{
			"headline": "Expiry of Warrants on 30 August 2016 (First Notice)",
			"id": 1193,
			"tickers": [
				"BRZW(ChinaA509000MBePW160830)",
				"BRYW(ChinaA509500MBeCW160830)",
				"BVDW(HSI18800MBePW160830)",
				"BVBW(HSI19400MBeCW160830)",
				"BVCW(HSI20200MBeCW160830)",
				"BVOW(HSI20400MBePW160830)",
				"BVEW(HSI19600MBePW160830)",
				"BVNW(HSI21800MBeCW160830)",
				"BVWW(HSI21200MBePW160830)",
				"BVMW(HSI21000MBeCW160830)"
			],
			"related_warrant": "BRZW(ChinaA509000MBePW160830);BRYW(ChinaA509500MBeCW160830);BVDW(HSI18800MBePW160830);BVBW(HSI19400MBeCW160830);BVCW(HSI20200MBeCW160830);BVOW(HSI20400MBePW160830);BVEW(HSI19600MBePW160830);BVNW(HSI21800MBeCW160830);BVWW(HSI21200MBePW160830);BVMW(HSI21000MBeCW160830)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 31 August 2016 (First Notice)",
			"id": 1197,
			"tickers": [
				"BPWW(STI 2650MBeCW160831)",
				"BPVW(STI 2500MBePW160831)",
				"BSVW(STI 2800MBePW160831)",
				"BSUW(STI 2950MBeCW160831)"
			],
			"related_warrant": "BPWW(STI 2650MBeCW160831);BPVW(STI 2500MBePW160831);BSVW(STI 2800MBePW160831);BSUW(STI 2950MBeCW160831)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 28 July 2016 (Third Notice)",
			"id": 1189,
			"tickers": [
				"BUAW(HSI20600MBeCW160728)",
				"BUBW(HSI20000MBePW160728)",
				"BUKW(HSI20800MBePW160728)",
				"BULW(HSI21400MBeCW160728)",
				"BTZW(HSI19800MBeCW160728)",
				"BUSW(HSI19200MBePW160728)"
			],
			"related_warrant": "BUAW(HSI20600MBeCW160728);BUBW(HSI20000MBePW160728);BUKW(HSI20800MBePW160728);BULW(HSI21400MBeCW160728);BTZW(HSI19800MBeCW160728);BUSW(HSI19200MBePW160728)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 11 July 2016 (Third Notice)",
			"id": 1185,
			"tickers": [
				"BPJW(DBS MB eCW160711)",
				"BPTW(UOB MB eCW160711)"
			],
			"related_warrant": "BPJW(DBS MB eCW160711);BPTW(UOB MB eCW160711)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "11 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 05 July 2016 (Third Notice)",
			"id": 1177,
			"tickers": [
				"BNMW(DBS MB eCW160705)",
				"BNKW(UOB MB eCW160705)"
			],
			"related_warrant": "BNMW(DBS MB eCW160705);BNKW(UOB MB eCW160705)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "05 Jul 16"
		},
		{
			"headline": "Adjustment Notice",
			"id": 1181,
			"tickers": [
				"BDWW(Ezion MBeCW160901)"
			],
			"related_warrant": "BDWW(Ezion MBeCW160901)",
			"underlying": "EZION HOLDINGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				}
			],
			"date": "05 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 04 July 2016 (Third Notice)",
			"id": 1173,
			"tickers": [
				"BMDW(DBS MB eCW160704)",
				"BMMW(UOB MB eCW160704)"
			],
			"related_warrant": "BMDW(DBS MB eCW160704);BMMW(UOB MB eCW160704)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 03 August 2016 (First Notice)",
			"id": 1165,
			"tickers": [
				"AVUW(NOL MB eCW160803)"
			],
			"related_warrant": "AVUW(NOL MB eCW160803)",
			"underlying": "NEPTUNE OL LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				}
			],
			"date": "01 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 01 July 2016 (Third Notice)",
			"id": 1169,
			"tickers": [
				"BKDW(DBS MB eCW160701)",
				"BKHW(OCBC Bk MB eCW160701)",
				"BKGW(OCBC Bk MB ePW160701)",
				"AZQW(NobleGrpMBeCW160701)",
				"AZVW(Ezion MBeCW160701)"
			],
			"related_warrant": "BKDW(DBS MB eCW160701);BKHW(OCBC Bk MB eCW160701);BKGW(OCBC Bk MB ePW160701);AZQW(NobleGrpMBeCW160701);AZVW(Ezion MBeCW160701)",
			"underlying": "DBS GRP HLDGS;OCBC;NOBLE GROUP;EZION HOLDINGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Jul 16"
		},
		{
			"headline": "Expiry of Warrants on 30 June 2016 (Third Notice)",
			"id": 1161,
			"tickers": [
				"BOGW(STI 2850MBePW160630)",
				"BOFW(STI 2750MBeCW160630)",
				"BODW(STI 2900MBeCW160630)",
				"BOHW(STI 2700MBePW160630)"
			],
			"related_warrant": "BOGW(STI 2850MBePW160630);BOFW(STI 2750MBeCW160630);BODW(STI 2900MBeCW160630);BOHW(STI 2700MBePW160630)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2016 (Third Notice)",
			"id": 1157,
			"tickers": [
				"BPLW(CN8000MBePW160629)",
				"BPKW(CN10000MBeCW160629)",
				"BSXW(HSI21000MBeCW160629)",
				"BTQW(HSI19400MBeCW160629)",
				"BNZW(HSI23200MBeCW160629)",
				"BSWW(HSI21800MBeCW160629)",
				"BSYW(HSI20600MBePW160629)",
				"BSZW(HSI21400MBePW160629)",
				"BTRW(HSI18800MBePW160629)",
				"BTIW(HSI19600MBePW160629A)",
				"BOCW(HSI20000MBePW160629)",
				"BTHW(HSI20200MBeCW160629A)"
			],
			"related_warrant": "BPLW(CN8000MBePW160629);BPKW(CN10000MBeCW160629);BSXW(HSI21000MBeCW160629);BTQW(HSI19400MBeCW160629);BNZW(HSI23200MBeCW160629);BSWW(HSI21800MBeCW160629);BSYW(HSI20600MBePW160629);BSZW(HSI21400MBePW160629);BTRW(HSI18800MBePW160629);BTIW(HSI19600MBePW160629A);BOCW(HSI20000MBePW160629);BTHW(HSI20200MBeCW160629A)",
			"underlying": "China A50;hsi;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "hsi"
				}
			],
			"date": "29 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 28 July 2016 (First Notice)",
			"id": 1153,
			"tickers": [
				"BUKW(HSI20800MBePW160728)",
				"BULW(HSI21400MBeCW160728)",
				"BUAW(HSI20600MBeCW160728)",
				"BUBW(HSI20000MBePW160728)",
				"BUSW(HSI19200MBePW160728)",
				"BTZW(HSI19800MBeCW160728)"
			],
			"related_warrant": "BUKW(HSI20800MBePW160728);BULW(HSI21400MBeCW160728);BUAW(HSI20600MBeCW160728);BUBW(HSI20000MBePW160728);BUSW(HSI19200MBePW160728);BTZW(HSI19800MBeCW160728)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 27 June 2016 (Third Notice)",
			"id": 1145,
			"tickers": [
				"BDQW(GLP MB ePW160627)",
				"BDPW(GLP MB eCW160627)",
				"BDTW(SembMar MBeCW160627)"
			],
			"related_warrant": "BDQW(GLP MB ePW160627);BDPW(GLP MB eCW160627);BDTW(SembMar MBeCW160627)",
			"underlying": "GLOBAL LOG PROP;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "27 Jun 16"
		},
		{
			"headline": "Adjustment Notice",
			"id": 1149,
			"tickers": [
				"AZQW(NobleGrpMBeCW160701)"
			],
			"related_warrant": "AZQW(NobleGrpMBeCW160701)",
			"underlying": "NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				}
			],
			"date": "27 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 11 July 2016 (First Notice)",
			"id": 1137,
			"tickers": [
				"BPJW(DBS MB eCW160711)",
				"BPTW(UOB MB eCW160711)"
			],
			"related_warrant": "BPJW(DBS MB eCW160711);BPTW(UOB MB eCW160711)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "10 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 10 June 2016 (Third Notice)",
			"id": 1141,
			"tickers": [
				"BONW(NKY 18000MBeCW160610)",
				"BOTW(NKY 21000MBePW160610)",
				"BOMW(NKY 16000MBeCW160610)",
				"BOSW(NKY 19000MBePW160610)",
				"BOPW(NKY 21500MBeCW160610)",
				"BORW(NKY 17500MBePW160610)",
				"BOOW(NKY 19500MBeCW160610)",
				"BOQW(NKY 15500MBePW160610)"
			],
			"related_warrant": "BONW(NKY 18000MBeCW160610);BOTW(NKY 21000MBePW160610);BOMW(NKY 16000MBeCW160610);BOSW(NKY 19000MBePW160610);BOPW(NKY 21500MBeCW160610);BORW(NKY 17500MBePW160610);BOOW(NKY 19500MBeCW160610);BOQW(NKY 15500MBePW160610)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "10 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 06 June 2016 (Third Notice)",
			"id": 1133,
			"tickers": [
				"AMJW(YangzijiMBeCW160606)"
			],
			"related_warrant": "AMJW(YangzijiMBeCW160606)",
			"underlying": "YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "06 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 3 June 2016 (Third Notice)",
			"id": 1121,
			"tickers": [
				"ATSW(SingTelMBePW160603)",
				"ATRW(SingTelMBeCW160603)"
			],
			"related_warrant": "ATSW(SingTelMBePW160603);ATRW(SingTelMBeCW160603)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "03 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 4 July 2016 (First Notice)",
			"id": 1125,
			"tickers": [
				"BMDW(DBS MB eCW160704)",
				"BMMW(UOB MB eCW160704)"
			],
			"related_warrant": "BMDW(DBS MB eCW160704);BMMW(UOB MB eCW160704)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 5 July 2016 (First Notice)",
			"id": 1129,
			"tickers": [
				"BNMW(DBS MB eCW160705)",
				"BNKW(UOB MB eCW160705)"
			],
			"related_warrant": "BNMW(DBS MB eCW160705);BNKW(UOB MB eCW160705)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 2 June 2016 (Third Notice)",
			"id": 1117,
			"tickers": [
				"AWBW(NobleGrpMBeCW160602)"
			],
			"related_warrant": "AWBW(NobleGrpMBeCW160602)",
			"underlying": "NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				}
			],
			"date": "02 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 1 July 2016 (First Notice)",
			"id": 1109,
			"tickers": [
				"BKDW(DBS MB eCW160701)",
				"BKHW(OCBC Bk MB eCW160701)",
				"BKGW(OCBC Bk MB ePW160701)",
				"AZQW(NobleGrpMBeCW160701)",
				"AZVW(Ezion MBeCW160701)"
			],
			"related_warrant": "BKDW(DBS MB eCW160701);BKHW(OCBC Bk MB eCW160701);BKGW(OCBC Bk MB ePW160701);AZQW(NobleGrpMBeCW160701);AZVW(Ezion MBeCW160701)",
			"underlying": "DBS GRP HLDGS;OCBC;NOBLE GROUP;EZION HOLDINGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "01 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 01 Jun 2016 (Third Notice)",
			"id": 1113,
			"tickers": [
				"ALRW(CapitalaMBeCW160601)",
				"BKCW(DBS MB ePW160601)",
				"ANBW(DBS MB eCW160601)",
				"ALMW(GentingSMBeCW160601)",
				"ALUW(GLP MBeCW160601)",
				"BJRW(KepCorp MB ePW160601)",
				"BJSW(KepCorp MBeCW160601A)",
				"AMQW(KepCorp MBeCW160601)",
				"ALJW(NOL MB eCW160601)",
				"AMAW(NobleGrpMBeCW160601)",
				"ANHW(OCBC Bk MBeCW160601)",
				"AMMW(SembMar MBeCW160601)",
				"ANNW(SGX MB eCW160601)",
				"BJOW(SGX MB ePW160601)",
				"ANLW(SingPost MBeCW160601)",
				"AMVW(UOB MB eCW160601)",
				"BKJW(UOB MB eCW160601A)",
				"BKIW(UOB MB ePW160601)",
				"AMHW(Wilmar MBeCW160601)"
			],
			"related_warrant": "ALRW(CapitalaMBeCW160601);BKCW(DBS MB ePW160601);ANBW(DBS MB eCW160601);ALMW(GentingSMBeCW160601);ALUW(GLP MBeCW160601);BJRW(KepCorp MB ePW160601);BJSW(KepCorp MBeCW160601A);AMQW(KepCorp MBeCW160601);ALJW(NOL MB eCW160601);AMAW(NobleGrpMBeCW160601);ANHW(OCBC Bk MBeCW160601);AMMW(SembMar MBeCW160601);ANNW(SGX MB eCW160601);BJOW(SGX MB ePW160601);ANLW(SingPost MBeCW160601);AMVW(UOB MB eCW160601);BKJW(UOB MB eCW160601A);BKIW(UOB MB ePW160601);AMHW(Wilmar MBeCW160601)",
			"underlying": "CAPITALAND;DBS GRP HLDGS;GENTING SPORE;GLOBAL LOG PROP;KEPPEL CORP;NEPTUNE OL LTD;NOBLE GROUP;OCBC;SEMBCORP MARINE;sgx;SINGAPORE POST;UOB LTD;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "sgx"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Jun 16"
		},
		{
			"headline": "Expiry of Warrants on 30 June 2016 (First Notice)",
			"id": 1093,
			"tickers": [
				"BOHW(STI 2700MBePW160630)",
				"BOGW(STI 2850MBePW160630)",
				"BODW(STI 2900MBeCW160630)",
				"BOFW(STI 2750MBeCW160630)"
			],
			"related_warrant": "BOHW(STI 2700MBePW160630);BOGW(STI 2850MBePW160630);BODW(STI 2900MBeCW160630);BOFW(STI 2750MBeCW160630)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 May 16"
		},
		{
			"headline": "Expiry of Warrants on 30 May 2016 (Third Notice)",
			"id": 1097,
			"tickers": [
				"BRNW(HSI19200MBePW160530)",
				"BSJW(HSI21000MBePW160530)",
				"BROW(HSI20600MBeCW160530)",
				"BSIW(HSI22200MBeCW160530)",
				"BRXW(HSI19800MBeCW160530)",
				"BRMW(HSI20000MBePW160530)",
				"BRPW(HSI21400MBeCW160530)"
			],
			"related_warrant": "BRNW(HSI19200MBePW160530);BSJW(HSI21000MBePW160530);BROW(HSI20600MBeCW160530);BSIW(HSI22200MBeCW160530);BRXW(HSI19800MBeCW160530);BRMW(HSI20000MBePW160530);BRPW(HSI21400MBeCW160530)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 May 16"
		},
		{
			"headline": "Expiry of Warrants on 27 June 2016 (First Notice)",
			"id": 1085,
			"tickers": [
				"BDQW(GLP MB ePW160627)",
				"BDPW(GLP MB eCW160627)",
				"BDTW(SembMar MBeCW160627)"
			],
			"related_warrant": "BDQW(GLP MB ePW160627);BDPW(GLP MB eCW160627);BDTW(SembMar MBeCW160627)",
			"underlying": "GLOBAL LOG PROP;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "27 May 16"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2016 (First Notice)",
			"id": 1089,
			"tickers": [
				"BPLW(CN8000MBePW160629)",
				"BPKW(CN10000MBeCW160629)",
				"BSXW(HSI21000MBeCW160629)",
				"BOCW(HSI20000MBePW160629)",
				"BTIW(HSI19600MBePW160629A)",
				"BTRW(HSI18800MBePW160629)",
				"BSZW(HSI21400MBePW160629)",
				"BSWW(HSI21800MBeCW160629)",
				"BTHW(HSI20200MBeCW160629A)",
				"BTQW(HSI19400MBeCW160629)",
				"BNZW(HSI23200MBeCW160629)",
				"BSYW(HSI20600MBePW160629)"
			],
			"related_warrant": "BPLW(CN8000MBePW160629);BPKW(CN10000MBeCW160629);BSXW(HSI21000MBeCW160629);BOCW(HSI20000MBePW160629);BTIW(HSI19600MBePW160629A);BTRW(HSI18800MBePW160629);BSZW(HSI21400MBePW160629);BSWW(HSI21800MBeCW160629);BTHW(HSI20200MBeCW160629A);BTQW(HSI19400MBeCW160629);BNZW(HSI23200MBeCW160629);BSYW(HSI20600MBePW160629)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "27 May 16"
		},
		{
			"headline": "Expiry of Warrants on 10 June 2016 (First Notice)",
			"id": 1077,
			"tickers": [
				"BONW(NKY 18000MBeCW160610)",
				"BOTW(NKY 21000MBePW160610)",
				"BOMW(NKY 16000MBeCW160610)",
				"BOSW(NKY 19000MBePW160610)",
				"BOPW(NKY 21500MBeCW160610)",
				"BORW(NKY 17500MBePW160610)",
				"BOOW(NKY 19500MBeCW160610)",
				"BOQW(NKY 15500MBePW160610)"
			],
			"related_warrant": "BONW(NKY 18000MBeCW160610);BOTW(NKY 21000MBePW160610);BOMW(NKY 16000MBeCW160610);BOSW(NKY 19000MBePW160610);BOPW(NKY 21500MBeCW160610);BORW(NKY 17500MBePW160610);BOOW(NKY 19500MBeCW160610);BOQW(NKY 15500MBePW160610)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "10 May 16"
		},
		{
			"headline": "Expiry of Warrants on 10 May 2016 (Third Notice)",
			"id": 1081,
			"tickers": [
				"BIMW(OCBC Bk MBePW160510)"
			],
			"related_warrant": "BIMW(OCBC Bk MBePW160510)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "10 May 16"
		},
		{
			"headline": "Expiry of Warrants on 6 June 2016 (First Notice)",
			"id": 1073,
			"tickers": [
				"AMJW(YangzijiMBeCW160606)"
			],
			"related_warrant": "AMJW(YangzijiMBeCW160606)",
			"underlying": "YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "06 May 16"
		},
		{
			"headline": "Expiry of Warrants on 04 May 2016 (Third Notice)",
			"id": 1069,
			"tickers": [
				"BIIW(SingTelMBeCW160504)",
				"BILW(SingTelMBePW160504)"
			],
			"related_warrant": "BIIW(SingTelMBeCW160504);BILW(SingTelMBePW160504)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "04 May 16"
		},
		{
			"headline": "Expiry of Warrants on 3 June 2016 (First Notice)",
			"id": 1065,
			"tickers": [
				"ATSW(SingTelMBePW160603)",
				"ATRW(SingTelMBeCW160603)"
			],
			"related_warrant": "ATSW(SingTelMBePW160603);ATRW(SingTelMBeCW160603)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "03 May 16"
		},
		{
			"headline": "Expiry of Warrants on 2 June 2016 (First Notice)",
			"id": 1049,
			"tickers": [
				"AWBW(NobleGrpMBeCW160602)"
			],
			"related_warrant": "AWBW(NobleGrpMBeCW160602)",
			"underlying": "NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				}
			],
			"date": "29 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 1 June 2016 (First Notice)",
			"id": 1053,
			"tickers": [
				"ALRW(CapitalaMBeCW160601)",
				"BKCW(DBS MB ePW160601)",
				"ANBW(DBS MB eCW160601)",
				"ALMW(GentingSMBeCW160601)",
				"ALUW(GLP MBeCW160601)",
				"BJRW(KepCorp MB ePW160601)",
				"BJSW(KepCorp MBeCW160601A)",
				"AMQW(KepCorp MBeCW160601)",
				"ALJW(NOL MB eCW160601)",
				"AMAW(NobleGrpMBeCW160601)",
				"ANHW(OCBC Bk MBeCW160601)",
				"AMMW(SembMar MBeCW160601)",
				"ANNW(SGX MB eCW160601)",
				"BJOW(SGX MB ePW160601)",
				"ANLW(SingPost MBeCW160601)",
				"AMVW(UOB MB eCW160601)",
				"BKJW(UOB MB eCW160601A)",
				"BKIW(UOB MB ePW160601)",
				"AMHW(Wilmar MBeCW160601)"
			],
			"related_warrant": "ALRW(CapitalaMBeCW160601);BKCW(DBS MB ePW160601);ANBW(DBS MB eCW160601);ALMW(GentingSMBeCW160601);ALUW(GLP MBeCW160601);BJRW(KepCorp MB ePW160601);BJSW(KepCorp MBeCW160601A);AMQW(KepCorp MBeCW160601);ALJW(NOL MB eCW160601);AMAW(NobleGrpMBeCW160601);ANHW(OCBC Bk MBeCW160601);AMMW(SembMar MBeCW160601);ANNW(SGX MB eCW160601);BJOW(SGX MB ePW160601);ANLW(SingPost MBeCW160601);AMVW(UOB MB eCW160601);BKJW(UOB MB eCW160601A);BKIW(UOB MB ePW160601);AMHW(Wilmar MBeCW160601)",
			"underlying": "CAPITALAND;DBS GRP HLDGS;GENTING SPORE;GLOBAL LOG PROP;KEPPEL CORP;NEPTUNE OL LTD;NOBLE GROUP;OCBC;SEMBCORP MARINE;SGX;SINGAPORE POST;UOB LTD;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "29 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 30 May 2016 (First Notice)",
			"id": 1057,
			"tickers": [
				"BSJW(HSI21000MBePW160530)",
				"BSIW(HSI22200MBeCW160530)",
				"BRPW(HSI21400MBeCW160530)",
				"BRNW(HSI19200MBePW160530)",
				"BRMW(HSI20000MBePW160530)",
				"BRXW(HSI19800MBeCW160530)",
				"BROW(HSI20600MBeCW160530)"
			],
			"related_warrant": "BSJW(HSI21000MBePW160530);BSIW(HSI22200MBeCW160530);BRPW(HSI21400MBeCW160530);BRNW(HSI19200MBePW160530);BRMW(HSI20000MBePW160530);BRXW(HSI19800MBeCW160530);BROW(HSI20600MBeCW160530)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 29 April 2016 (Third Notice)",
			"id": 1061,
			"tickers": [
				"BMFW(STI 2800MBePW160429)",
				"BOEW(STI 2850MBeCW160429)",
				"BMEW(STI 3000MBeCW160429)",
				"BOIW(STI 2650MBePW160429)"
			],
			"related_warrant": "BMFW(STI 2800MBePW160429);BOEW(STI 2850MBeCW160429);BMEW(STI 3000MBeCW160429);BOIW(STI 2650MBePW160429)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 28 April 2016 (Third Notice)",
			"id": 1045,
			"tickers": [
				"BQKW(HSI18000MBePW160428)",
				"BRFW(HSI19600MBePW160428)",
				"BQJW(HSI20200MBeCW160428)",
				"BOBW(HSI20600MBePW160428)",
				"BRGW(HSI21000MBeCW160428)",
				"BQRW(HSI19200MBeCW160428)",
				"BOAW(HSI22800MBeCW160428)",
				"BMVW(CN11500MBeCW160428)",
				"BMWW(CN9500MBePW160428)"
			],
			"related_warrant": "BQKW(HSI18000MBePW160428);BRFW(HSI19600MBePW160428);BQJW(HSI20200MBeCW160428);BOBW(HSI20600MBePW160428);BRGW(HSI21000MBeCW160428);BQRW(HSI19200MBeCW160428);BOAW(HSI22800MBeCW160428);BMVW(CN11500MBeCW160428);BMWW(CN9500MBePW160428)",
			"underlying": "HANG SENG INDEX;China A50",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Apr 16"
		},
		{
			"headline": "Adjustment Notice",
			"id": 1041,
			"tickers": [
				"AZVW(Ezion MBeCW160701)",
				"BDWW(Ezion MBeCW160901)"
			],
			"related_warrant": "AZVW(Ezion MBeCW160701);BDWW(Ezion MBeCW160901)",
			"underlying": "EZION HOLDINGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				}
			],
			"date": "14 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 10 May 2016 (First Notice)",
			"id": 1037,
			"tickers": [
				"BIMW(OCBC Bk MBePW160510)"
			],
			"related_warrant": "BIMW(OCBC Bk MBePW160510)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "08 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 06 April 2016 (Third Notice)",
			"id": 1033,
			"tickers": [
				"BIJW(OCBC Bk MBeCW160406)"
			],
			"related_warrant": "BIJW(OCBC Bk MBeCW160406)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "06 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 04 April 2016 (Third Notice)",
			"id": 1025,
			"tickers": [
				"BJQW(DBS MB eCW160404)",
				"BJPW(DBS MB EPW160404)",
				"BJMW(UOB MB ePW160404)",
				"BJNW(UOB MB eCW160404)"
			],
			"related_warrant": "BJQW(DBS MB eCW160404);BJPW(DBS MB EPW160404);BJMW(UOB MB ePW160404);BJNW(UOB MB eCW160404)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 04 May 2016 (First Notice)",
			"id": 1029,
			"tickers": [
				"BIIW(SingTelMBeCW160504)",
				"BILW(SingTelMBePW160504)"
			],
			"related_warrant": "BIIW(SingTelMBeCW160504);BILW(SingTelMBePW160504)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "04 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 01 April 2016 (Third Notice)",
			"id": 1021,
			"tickers": [
				"BHAW(GLP MB ePW160401)",
				"BGZW(GLP MB eCW160401)"
			],
			"related_warrant": "BHAW(GLP MB ePW160401);BGZW(GLP MB eCW160401)",
			"underlying": "GLOBAL LOG PROP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				}
			],
			"date": "01 Apr 16"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2016 (Third Notice)",
			"id": 1017,
			"tickers": [
				"BKMW(CNOOCMBeCW160330)",
				"BKLW(CNOOC MB ePW160330)",
				"BKQW(PingAnInsMBeCW160330)",
				"BKPW(PingAnInsMBePW160330)",
				"BKRW(TencentMBePW160330)",
				"BKSW(TencentMBeCW160330)",
				"BKOW(HKExcMBeCW160330)",
				"BKNW(HKExc MB ePW160330)",
				"BPYW(HSI19800MBeCW160330)",
				"BQLW(HSI19200MBePW160330)",
				"BPXW(HSI17600MBePW160330)",
				"BQGW(HSI18800MBeCW160330)",
				"BLQW(HSI21000MBePW160330)",
				"BLOW(HSI23000MBeCW160330)",
				"BPAW(HSI20600MBeCW160330)",
				"BPEW(HSI18400MBePW160330)"
			],
			"related_warrant": "BKMW(CNOOCMBeCW160330);BKLW(CNOOC MB ePW160330);BKQW(PingAnInsMBeCW160330);BKPW(PingAnInsMBePW160330);BKRW(TencentMBePW160330);BKSW(TencentMBeCW160330);BKOW(HKExcMBeCW160330);BKNW(HKExc MB ePW160330);BPYW(HSI19800MBeCW160330);BQLW(HSI19200MBePW160330);BPXW(HSI17600MBePW160330);BQGW(HSI18800MBeCW160330);BLQW(HSI21000MBePW160330);BLOW(HSI23000MBeCW160330);BPAW(HSI20600MBeCW160330);BPEW(HSI18400MBePW160330)",
			"underlying": "CNOOC;PING AN;TENCENT;HKEX;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "0883.HK",
					"underlying_ticker": "883",
					"underlying_name": "CNOOC"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				},
				{
					"underlying_ric": "0388.HK",
					"underlying_ticker": "388",
					"underlying_name": "HKEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				}
			],
			"date": "30 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 29 April 2016 (First Notice)",
			"id": 1013,
			"tickers": [
				"BMFW(STI 2800MBePW160429)",
				"BOEW(STI 2850MBeCW160429)",
				"BMEW(STI 3000MBeCW160429)",
				"BOIW(STI 2650MBePW160429)"
			],
			"related_warrant": "BMFW(STI 2800MBePW160429);BOEW(STI 2850MBeCW160429);BMEW(STI 3000MBeCW160429);BOIW(STI 2650MBePW160429)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 28 April 2016 (First Notice)",
			"id": 1009,
			"tickers": [
				"BMVW(ChinaA5011500MBeCW160428)",
				"BMWW(ChinaA509500MBePW160428)",
				"BOAW(HSI22800MBeCW160428)",
				"BQKW(HSI18000MBePW160428)",
				"BRGW(HSI21000MBeCW160428)",
				"BQJW(HSI20200MBeCW160428)",
				"BRFW(HSI19600MBePW160428)",
				"BOBW(HSI20600MBePW160428)",
				"BQRW(HSI19200MBeCW160428)"
			],
			"related_warrant": "BMVW(ChinaA5011500MBeCW160428);BMWW(ChinaA509500MBePW160428);BOAW(HSI22800MBeCW160428);BQKW(HSI18000MBePW160428);BRGW(HSI21000MBeCW160428);BQJW(HSI20200MBeCW160428);BRFW(HSI19600MBePW160428);BOBW(HSI20600MBePW160428);BQRW(HSI19200MBeCW160428)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 11 March 2016 (Third Notice)",
			"id": 1005,
			"tickers": [
				"BHYW(NKY 17500MBeCW160311)",
				"BICW(NKY 17000MBePW160311)",
				"BHZW(NKY 19000MBeCW160311)",
				"BIDW(NKY 18500MBePW160311)",
				"BIAW(NKY 20500MBeCW160311)",
				"BIBW(NKY 15500MBePW160311)",
				"BIEW(NKY 20000MBePW160311)",
				"BHXW(NKY 16000MBeCW160311)"
			],
			"related_warrant": "BHYW(NKY 17500MBeCW160311);BICW(NKY 17000MBePW160311);BHZW(NKY 19000MBeCW160311);BIDW(NKY 18500MBePW160311);BIAW(NKY 20500MBeCW160311);BIBW(NKY 15500MBePW160311);BIEW(NKY 20000MBePW160311);BHXW(NKY 16000MBeCW160311)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "11 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 04 April 2016 (First Notice)",
			"id": 997,
			"tickers": [
				"BJQW(DBS MB eCW160404)",
				"BJPW(DBS MB EPW160404)",
				"BJMW(UOB MB ePW160404)",
				"BJNW(UOB MB eCW160404)"
			],
			"related_warrant": "BJQW(DBS MB eCW160404);BJPW(DBS MB EPW160404);BJMW(UOB MB ePW160404);BJNW(UOB MB eCW160404)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 06 April 2016 (First Notice)",
			"id": 1001,
			"tickers": [
				"BIJW(OCBC Bk MBeCW160406)"
			],
			"related_warrant": "BIJW(OCBC Bk MBeCW160406)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "04 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 02 March 2016 (Third Notice)",
			"id": 993,
			"tickers": [
				"ALSW(CapitalaMBePW160302)",
				"ASQW(GentingSMBeCW160302)"
			],
			"related_warrant": "ALSW(CapitalaMBePW160302);ASQW(GentingSMBeCW160302)",
			"underlying": "CAPITALAND;GENTING SPORE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				}
			],
			"date": "02 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 01 April 2016 (First Notice)",
			"id": 981,
			"tickers": [
				"BHAW(GLP MB ePW160401)",
				"BGZW(GLP MB eCW160401)"
			],
			"related_warrant": "BHAW(GLP MB ePW160401);BGZW(GLP MB eCW160401)",
			"underlying": "GLOBAL LOG PROP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				}
			],
			"date": "01 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 01 March 2016 (Third Notice)",
			"id": 989,
			"tickers": [
				"ALNW(GentingSMBeCW160301)",
				"ALTW(CapitalaMBePW160301)",
				"BGYW(Wilmar MBePW160301)",
				"BGXW(Wilmar MBeCW160301)"
			],
			"related_warrant": "ALNW(GentingSMBeCW160301);ALTW(CapitalaMBePW160301);BGYW(Wilmar MBePW160301);BGXW(Wilmar MBeCW160301)",
			"underlying": "GENTING SPORE;CAPITALAND;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Mar 16"
		},
		{
			"headline": "Expiry of Warrants on 29 February 2016 (Third Notice)",
			"id": 969,
			"tickers": [
				"BJUW(STI 2900MBePW160229)",
				"BJTW(STI 3100MBeCW160229)"
			],
			"related_warrant": "BJUW(STI 2900MBePW160229);BJTW(STI 3100MBeCW160229)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2016 (First Notice)",
			"id": 973,
			"tickers": [
				"BKMW(CNOOCMBeCW160330)",
				"BKLW(CNOOC MB ePW160330)",
				"BKOW(HKExcMBeCW160330)",
				"BKNW(HKExc MB ePW160330)",
				"BKQW(PingAnInsMBeCW160330)",
				"BKPW(PingAnInsMBePW160330)",
				"BKRW(TencentMBePW160330)",
				"BKSW(TencentMBeCW160330)"
			],
			"related_warrant": "BKMW(CNOOCMBeCW160330);BKLW(CNOOC MB ePW160330);BKOW(HKExcMBeCW160330);BKNW(HKExc MB ePW160330);BKQW(PingAnInsMBeCW160330);BKPW(PingAnInsMBePW160330);BKRW(TencentMBePW160330);BKSW(TencentMBeCW160330)",
			"underlying": "CNOOC;HKEX;PING AN;TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0883.HK",
					"underlying_ticker": "883",
					"underlying_name": "CNOOC"
				},
				{
					"underlying_ric": "0388.HK",
					"underlying_ticker": "388",
					"underlying_name": "HKEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "29 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2016 (First Notice)",
			"id": 977,
			"tickers": [
				"BQLW(HSI19200MBePW160330)",
				"BPXW(HSI17600MBePW160330)",
				"BPYW(HSI19800MBeCW160330)",
				"BQGW(HSI18800MBeCW160330)",
				"BLQW(HSI21000MBePW160330)",
				"BLOW(HSI23000MBeCW160330)",
				"BPAW(HSI20600MBeCW160330)",
				"BPEW(HSI18400MBePW160330)"
			],
			"related_warrant": "BQLW(HSI19200MBePW160330);BPXW(HSI17600MBePW160330);BPYW(HSI19800MBeCW160330);BQGW(HSI18800MBeCW160330);BLQW(HSI21000MBePW160330);BLOW(HSI23000MBeCW160330);BPAW(HSI20600MBeCW160330);BPEW(HSI18400MBePW160330)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 26 February 2016 (Third Notice)",
			"id": 965,
			"tickers": [
				"BGLW(CN12000MBeCW160226)",
				"BGMW(CN8500MBePW160226)",
				"BMZW(HSI21600MBePW160226)",
				"BPBW(HSI21000MBeCW160226)",
				"BPGW(HSI20000MBeCW160226)",
				"BMYW(HSI22800MBeCW160226)",
				"BMXW(HSI22000MBeCW160226)",
				"BPDW(HSI19600MBePW160226)"
			],
			"related_warrant": "BGLW(CN12000MBeCW160226);BGMW(CN8500MBePW160226);BMZW(HSI21600MBePW160226);BPBW(HSI21000MBeCW160226);BPGW(HSI20000MBeCW160226);BMYW(HSI22800MBeCW160226);BMXW(HSI22000MBeCW160226);BPDW(HSI19600MBePW160226)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "26 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 22 February 2016 (Third Notice)",
			"id": 961,
			"tickers": [
				"BFNW(KepCorp MBeCW160222)",
				"BFOW(KepCorp MBePW160222)"
			],
			"related_warrant": "BFNW(KepCorp MBeCW160222);BFOW(KepCorp MBePW160222)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "22 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 15 February 2016 (Third Notice)",
			"id": 957,
			"tickers": [
				"BGWW(DBS MB ePW160215)",
				"BIFW(DBS MB eCW160215)",
				"BHCW(UOB MB ePW160215)"
			],
			"related_warrant": "BGWW(DBS MB ePW160215);BIFW(DBS MB eCW160215);BHCW(UOB MB ePW160215)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "15 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 11 March 2016 (First Notice)",
			"id": 953,
			"tickers": [
				"BHYW(NKY 17500MBeCW160311)",
				"BICW(NKY 17000MBePW160311)",
				"BHZW(NKY 19000MBeCW160311)",
				"BIDW(NKY 18500MBePW160311)",
				"BIAW(NKY 20500MBeCW160311)",
				"BIBW(NKY 15500MBePW160311)",
				"BIEW(NKY 20000MBePW160311)",
				"BHXW(NKY 16000MBeCW160311)"
			],
			"related_warrant": "BHYW(NKY 17500MBeCW160311);BICW(NKY 17000MBePW160311);BHZW(NKY 19000MBeCW160311);BIDW(NKY 18500MBePW160311);BIAW(NKY 20500MBeCW160311);BIBW(NKY 15500MBePW160311);BIEW(NKY 20000MBePW160311);BHXW(NKY 16000MBeCW160311)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "11 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 02 March 2016 (First Notice)",
			"id": 949,
			"tickers": [
				"ALSW(CapitalaMBePW160302)",
				"ASQW(GentingSMBeCW160302)"
			],
			"related_warrant": "ALSW(CapitalaMBePW160302);ASQW(GentingSMBeCW160302)",
			"underlying": "CAPITALAND;GENTING SPORE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				}
			],
			"date": "02 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 01 February 2016 (Third Notice)",
			"id": 941,
			"tickers": [
				"BFMW(DBS MB ePW160201)",
				"BFLW(DBS MB eCW160201)",
				"BFSW(UOB MB ePW160201)",
				"BFRW(UOB MB eCW160201)",
				"BDIW(KepCorp MBePW160201)",
				"BDHW(KepCorp MBeCW160201)",
				"ALKW(NOL MB eCW160201)",
				"ALOW(GentingSMBeCW160201)"
			],
			"related_warrant": "BFMW(DBS MB ePW160201);BFLW(DBS MB eCW160201);BFSW(UOB MB ePW160201);BFRW(UOB MB eCW160201);BDIW(KepCorp MBePW160201);BDHW(KepCorp MBeCW160201);ALKW(NOL MB eCW160201);ALOW(GentingSMBeCW160201)",
			"underlying": "DBS GRP HLDGS;UOB LTD;KEPPEL CORP;NEPTUNE OL LTD;GENTING SPORE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "01 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 01 March 2016 (First Notice)",
			"id": 945,
			"tickers": [
				"BGYW(Wilmar MBePW160301)",
				"BGXW(Wilmar MBeCW160301)",
				"ALNW(GentingSMBeCW160301)",
				"ALTW(CapitalaMBePW160301)"
			],
			"related_warrant": "BGYW(Wilmar MBePW160301);BGXW(Wilmar MBeCW160301);ALNW(GentingSMBeCW160301);ALTW(CapitalaMBePW160301)",
			"underlying": "WILMAR INTL;GENTING SPORE;CAPITALAND",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Feb 16"
		},
		{
			"headline": "Expiry of Warrants on 29 February 2016 (First Notice)",
			"id": 937,
			"tickers": [
				"BJUW(STI 2900MBePW160229)",
				"BJTW(STI 3100MBeCW160229)"
			],
			"related_warrant": "BJUW(STI 2900MBePW160229);BJTW(STI 3100MBeCW160229)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 28 January 2016 (Third Notice)",
			"id": 933,
			"tickers": [
				"BHTW(DBS MB eCW160128)",
				"BIZW(CN9500MBePW160128)",
				"BIYW(CN11500MBeCW160128)",
				"BMBW(HSI22400MBeCW160128)",
				"BMCW(HSI22000MBePW160128)",
				"BLPW(HSI21200MBePW160128)",
				"BLNW(HSI23200MBeCW160128)",
				"BMPW(HSI21600MBeCW160128)",
				"BMUW(HSI20400MBePW160128)"
			],
			"related_warrant": "BHTW(DBS MB eCW160128);BIZW(CN9500MBePW160128);BIYW(CN11500MBeCW160128);BMBW(HSI22400MBeCW160128);BMCW(HSI22000MBePW160128);BLPW(HSI21200MBePW160128);BLNW(HSI23200MBeCW160128);BMPW(HSI21600MBeCW160128);BMUW(HSI20400MBePW160128)",
			"underlying": "DBS GRP HLDGS;China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 26 February 2016 (First Notice)",
			"id": 929,
			"tickers": [
				"BGLW(ChinaA5012000MBeCW160226)",
				"BGMW(ChinaA508500MBePW160226)",
				"BPBW(HSI21000MBeCW160226)",
				"BMYW(HSI22800MBeCW160226)",
				"BMXW(HSI22000MBeCW160226)",
				"BMZW(HSI21600MBePW160226)",
				"BPDW(HSI19600MBePW160226)",
				"BPGW(HSI20000MBeCW160226)"
			],
			"related_warrant": "BGLW(ChinaA5012000MBeCW160226);BGMW(ChinaA508500MBePW160226);BPBW(HSI21000MBeCW160226);BMYW(HSI22800MBeCW160226);BMXW(HSI22000MBeCW160226);BMZW(HSI21600MBePW160226);BPDW(HSI19600MBePW160226);BPGW(HSI20000MBeCW160226)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "26 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 22 February 2016 (First Notice)",
			"id": 925,
			"tickers": [
				"BFOW(KepCorp MBePW160222)",
				"BFNW(KepCorp MBeCW160222)"
			],
			"related_warrant": "BFOW(KepCorp MBePW160222);BFNW(KepCorp MBeCW160222)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "22 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 15 February 2016 (First Notice)",
			"id": 921,
			"tickers": [
				"BGWW(DBS MB ePW160215)",
				"BIFW(DBS MB eCW160215)",
				"BHCW(UOB MB ePW160215)"
			],
			"related_warrant": "BGWW(DBS MB ePW160215);BIFW(DBS MB eCW160215);BHCW(UOB MB ePW160215)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "15 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 11 January 2016 (Third Notice)",
			"id": 917,
			"tickers": [
				"AMCW(SingTelMBeCW160111)",
				"BFQW(OCBC Bk MBePW160111)",
				"BFPW(OCBC Bk MBeCW160111)",
				"BEEW(UOB MB ePW160111)",
				"BEDW(UOB MB eCW160111)",
				"BEFW(DBS MB eCW160111)",
				"BEGW(DBS MB ePW160111)"
			],
			"related_warrant": "AMCW(SingTelMBeCW160111);BFQW(OCBC Bk MBePW160111);BFPW(OCBC Bk MBeCW160111);BEEW(UOB MB ePW160111);BEDW(UOB MB eCW160111);BEFW(DBS MB eCW160111);BEGW(DBS MB ePW160111)",
			"underlying": "SINGTEL;OCBC;UOB LTD;DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "11 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 6 January 2016 (Third Notice)",
			"id": 913,
			"tickers": [
				"AMDW(SingTelMBePW160106)",
				"AMFW(Wilmar MBePW160106)",
				"ALLW(GentingSMBePW160106)"
			],
			"related_warrant": "AMDW(SingTelMBePW160106);AMFW(Wilmar MBePW160106);ALLW(GentingSMBePW160106)",
			"underlying": "SINGTEL;WILMAR INTL;GENTING SPORE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "06 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 5 January 2016 (Third Notice)",
			"id": 909,
			"tickers": [
				"ALPW(GentingSMBeCW160105)",
				"ANQW(SGX MB ePW160105)",
				"ANOW(SGX MB eCW160105)",
				"AMIW(Yangziji MBeCW160105)",
				"BBKW(KepCorp MBePW160105)",
				"AMEW(SingTelMBePW160105)",
				"AMGW(Wilmar MBePW160105)"
			],
			"related_warrant": "ALPW(GentingSMBeCW160105);ANQW(SGX MB ePW160105);ANOW(SGX MB eCW160105);AMIW(Yangziji MBeCW160105);BBKW(KepCorp MBePW160105);AMEW(SingTelMBePW160105);AMGW(Wilmar MBePW160105)",
			"underlying": "GENTING SPORE;SGX;YANGZIJIANG;KEPPEL CORP;SINGTEL;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "05 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 4 January 2016 (Third Notice)",
			"id": 905,
			"tickers": [
				"U3WW(CapitalaMBeCW160104)",
				"AUVW(ChinaConsMBeCW160104)",
				"AUWW(ChinaConsMBePW160104)",
				"U3XW(DBS MB eCW160104)",
				"AIJW(Ezra MBeCW160104)",
				"BBJW(KepCorp MBeCW160104A)",
				"U3YW(KepCorp MBeCW160104)",
				"U3ZW(OCBC Bk MBeCW160104)",
				"AJMW(SembMar MBeCW160104)",
				"ANKW(SGX MB ePW160104)",
				"ANPW(SGX MB eCW160104)",
				"ANMW(SingPost MBeCW160104)",
				"U4FW(SingTelMBeCW160104)",
				"BDKW(UOB MB ePW160104)",
				"U4GW(UOB MB eCW160104)",
				"AMKW(Wilmar MBeCW160104)",
				"AIQW(YangzijiMBeCW160104)",
				"AKBW(GentingSMBePW160104)",
				"AKAW(NobleGrpMBePW160104)"
			],
			"related_warrant": "U3WW(CapitalaMBeCW160104);AUVW(ChinaConsMBeCW160104);AUWW(ChinaConsMBePW160104);U3XW(DBS MB eCW160104);AIJW(Ezra MBeCW160104);BBJW(KepCorp MBeCW160104A);U3YW(KepCorp MBeCW160104);U3ZW(OCBC Bk MBeCW160104);AJMW(SembMar MBeCW160104);ANKW(SGX MB ePW160104);ANPW(SGX MB eCW160104);ANMW(SingPost MBeCW160104);U4FW(SingTelMBeCW160104);BDKW(UOB MB ePW160104);U4GW(UOB MB eCW160104);AMKW(Wilmar MBeCW160104);AIQW(YangzijiMBeCW160104);AKBW(GentingSMBePW160104);AKAW(NobleGrpMBePW160104)",
			"underlying": "CAPITALAND;CCB;DBS GRP HLDGS;EZRA HLDGS;KEPPEL CORP;OCBC;SEMBCORP MARINE;SGX;SINGAPORE POST;SINGTEL;UOB LTD;WILMAR INTL;YANGZIJIANG;GENTING SPORE;NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": "0939.HK",
					"underlying_ticker": "939",
					"underlying_name": "CCB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "04 Jan 16"
		},
		{
			"headline": "Expiry of Warrants on 1 February 2016 (First Notice)",
			"id": 897,
			"tickers": [
				"BFLW(DBS MB eCW160201)",
				"BFMW(DBS MB ePW160201)",
				"ALOW(GentingSMBeCW160201)",
				"BDHW(KepCorp MBeCW160201)",
				"BDIW(KepCorp MBePW160201)",
				"ALKW(NOL MB eCW160201)",
				"BFSW(UOB MB ePW160201)",
				"BFRW(UOB MB eCW160201)"
			],
			"related_warrant": "BFLW(DBS MB eCW160201);BFMW(DBS MB ePW160201);ALOW(GentingSMBeCW160201);BDHW(KepCorp MBeCW160201);BDIW(KepCorp MBePW160201);ALKW(NOL MB eCW160201);BFSW(UOB MB ePW160201);BFRW(UOB MB eCW160201)",
			"underlying": "DBS GRP HLDGS;GENTING SPORE;KEPPEL CORP;NEPTUNE OL LTD;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "31 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2015 (Third Notice)",
			"id": 901,
			"tickers": [
				"BFVW(STI 2800MBePW151231)",
				"BFWW(STI 3000MBeCW151231)",
				"BEAW(STI 3150MBeCW151231)",
				"BDMW(STI 3100MBePW151231)",
				"BDLW(STI 3300MBeCW151231)",
				"BEBW(STI 2950MBePW151231)"
			],
			"related_warrant": "BFVW(STI 2800MBePW151231);BFWW(STI 3000MBeCW151231);BEAW(STI 3150MBeCW151231);BDMW(STI 3100MBePW151231);BDLW(STI 3300MBeCW151231);BEBW(STI 2950MBePW151231)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "31 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2015 (Third Notice)",
			"id": 893,
			"tickers": [
				"BCNW(CNOOCMBeCW151230)",
				"BCMW(CNOOCMBePW151230)",
				"BGIW(HSI24000MBeCW151230)",
				"BKTW(HSI22800MBeCW151230)",
				"BJBW(HSI22400MBePW151230)",
				"BJCW(HSI23800MBeCW151230)",
				"BKUW(HSI21400MBePW151230)",
				"BJAW(HSI24600MBeCW151230)",
				"BJYW(HSI23200MBePW151230)",
				"BGJW(HSI20000MBePW151230)",
				"BJXW(HSI25400MBeCW151230)",
				"BCOW(PingAnInsMBePW151230)",
				"BCPW(PingAnInsMBeCW151230)",
				"BCQW(TencentMBePW151230)",
				"BCRW(TencentMBeCW151230)"
			],
			"related_warrant": "BCNW(CNOOCMBeCW151230);BCMW(CNOOCMBePW151230);BGIW(HSI24000MBeCW151230);BKTW(HSI22800MBeCW151230);BJBW(HSI22400MBePW151230);BJCW(HSI23800MBeCW151230);BKUW(HSI21400MBePW151230);BJAW(HSI24600MBeCW151230);BJYW(HSI23200MBePW151230);BGJW(HSI20000MBePW151230);BJXW(HSI25400MBeCW151230);BCOW(PingAnInsMBePW151230);BCPW(PingAnInsMBeCW151230);BCQW(TencentMBePW151230);BCRW(TencentMBeCW151230)",
			"underlying": "CNOOC;HANG SENG INDEX;PING AN;TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0883.HK",
					"underlying_ticker": "883",
					"underlying_name": "CNOOC"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				}
			],
			"date": "30 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 28 January 2016 (First Notice)",
			"id": 889,
			"tickers": [
				"BHTW(DBS MB eCW160128)",
				"BIZW(ChinaA509500MBePW160128)",
				"BIYW(ChinaA5011500MBeCW160128)",
				"BLNW(HSI23200MBeCW160128)",
				"BLPW(HSI21200MBePW160128)",
				"BMUW(HSI20400MBePW160128)",
				"BMPW(HSI21600MBeCW160128)",
				"BMBW(HSI22400MBeCW160128)",
				"BMCW(HSI22000MBePW160128)"
			],
			"related_warrant": "BHTW(DBS MB eCW160128);BIZW(ChinaA509500MBePW160128);BIYW(ChinaA5011500MBeCW160128);BLNW(HSI23200MBeCW160128);BLPW(HSI21200MBePW160128);BMUW(HSI20400MBePW160128);BMPW(HSI21600MBeCW160128);BMBW(HSI22400MBeCW160128);BMCW(HSI22000MBePW160128)",
			"underlying": "DBS GRP HLDGS;China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 11 January 2016 (First Notice)",
			"id": 881,
			"tickers": [
				"BEFW(DBS MB eCW160111)",
				"BEGW(DBS MB ePW160111)",
				"BFQW(OCBC Bk MBePW160111)",
				"BFPW(OCBC Bk MBeCW160111)",
				"AMCW(SingTelMBeCW160111)",
				"BEEW(UOB MB ePW160111)",
				"BEDW(UOB MB eCW160111)"
			],
			"related_warrant": "BEFW(DBS MB eCW160111);BEGW(DBS MB ePW160111);BFQW(OCBC Bk MBePW160111);BFPW(OCBC Bk MBeCW160111);AMCW(SingTelMBeCW160111);BEEW(UOB MB ePW160111);BEDW(UOB MB eCW160111)",
			"underlying": "DBS GRP HLDGS;OCBC;SINGTEL;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "11 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 11 December 2015 (Third Notice)",
			"id": 885,
			"tickers": [
				"BCGW(NKY 21500MBeCW151211)",
				"BCKW(NKY 21000MBePW151211)",
				"BCLW(NKY 22500MBePW151211)",
				"BCJW(NKY 19500MBePW151211)",
				"BCEW(NKY 18500MBeCW151211)",
				"BCIW(NKY 18000MBePW151211)",
				"BCHW(NKY 23000MBeCW151211)",
				"BCFW(NKY 20000MBeCW151211)"
			],
			"related_warrant": "BCGW(NKY 21500MBeCW151211);BCKW(NKY 21000MBePW151211);BCLW(NKY 22500MBePW151211);BCJW(NKY 19500MBePW151211);BCEW(NKY 18500MBeCW151211);BCIW(NKY 18000MBePW151211);BCHW(NKY 23000MBeCW151211);BCFW(NKY 20000MBeCW151211)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "11 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 4 January 2016 (First Notice)",
			"id": 865,
			"tickers": [
				"U3WW(CapitalaMBeCW160104)",
				"AUVW(ChinaConsMBeCW160104)",
				"AUWW(ChinaConsMBePW160104)",
				"U3XW(DBS MB eCW160104)",
				"AIJW(Ezra MBeCW160104)",
				"U3YW(KepCorp MBeCW160104)",
				"BBJW(KepCorp MBeCW160104A)",
				"U3ZW(OCBC Bk MBeCW160104)",
				"AJMW(SembMar MBeCW160104)",
				"ANKW(SGX MB ePW160104)",
				"ANPW(SGX MB eCW160104)",
				"ANMW(SingPost MBeCW160104)",
				"U4FW(SingTelMBeCW160104)",
				"BDKW(UOB MB ePW160104)",
				"U4GW(UOB MB eCW160104)",
				"AIQW(YangzijiMBeCW160104)",
				"AKBW(GentingSMBePW160104)",
				"AKAW(NobleGrpMBePW160104)",
				"AMKW(Wilmar MBeCW160104)"
			],
			"related_warrant": "U3WW(CapitalaMBeCW160104);AUVW(ChinaConsMBeCW160104);AUWW(ChinaConsMBePW160104);U3XW(DBS MB eCW160104);AIJW(Ezra MBeCW160104);U3YW(KepCorp MBeCW160104);BBJW(KepCorp MBeCW160104A);U3ZW(OCBC Bk MBeCW160104);AJMW(SembMar MBeCW160104);ANKW(SGX MB ePW160104);ANPW(SGX MB eCW160104);ANMW(SingPost MBeCW160104);U4FW(SingTelMBeCW160104);BDKW(UOB MB ePW160104);U4GW(UOB MB eCW160104);AIQW(YangzijiMBeCW160104);AKBW(GentingSMBePW160104);AKAW(NobleGrpMBePW160104);AMKW(Wilmar MBeCW160104)",
			"underlying": "CAPITALAND;CCB;DBS GRP HLDGS;EZRA HLDGS;KEPPEL CORP;OCBC;SEMBCORP MARINE;SGX;SINGAPORE POST;SINGTEL;UOB LTD;YANGZIJIANG;GENTING SPORE;NOBLE GROUP;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": "0939.HK",
					"underlying_ticker": "939",
					"underlying_name": "CCB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "04 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 5 January 2016 (First Notice)",
			"id": 869,
			"tickers": [
				"ALPW(GentingSMBeCW160105)",
				"ANQW(SGX MB ePW160105)",
				"ANOW(SGX MB eCW160105)",
				"AMIW(Yangziji MBeCW160105)",
				"BBKW(KepCorp MBePW160105)",
				"AMEW(SingTelMBePW160105)",
				"AMGW(Wilmar MBePW160105)"
			],
			"related_warrant": "ALPW(GentingSMBeCW160105);ANQW(SGX MB ePW160105);ANOW(SGX MB eCW160105);AMIW(Yangziji MBeCW160105);BBKW(KepCorp MBePW160105);AMEW(SingTelMBePW160105);AMGW(Wilmar MBePW160105)",
			"underlying": "GENTING SPORE;SGX;YANGZIJIANG;KEPPEL CORP;SINGTEL;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "04 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 6 January 2016 (First Notice)",
			"id": 873,
			"tickers": [
				"ALLW(GentingSMBePW160106)",
				"AMDW(SingTelMBePW160106)",
				"AMFW(Wilmar MBePW160106)"
			],
			"related_warrant": "ALLW(GentingSMBePW160106);AMDW(SingTelMBePW160106);AMFW(Wilmar MBePW160106)",
			"underlying": "GENTING SPORE;SINGTEL;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "04 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 4 December 2015 (Third Notice)",
			"id": 877,
			"tickers": [
				"BDJW(UOB MB eCW151204)"
			],
			"related_warrant": "BDJW(UOB MB eCW151204)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2015 (Third Notice)",
			"id": 861,
			"tickers": [
				"BBNW(DBS MB ePW151202)",
				"BBLW(UOB MB ePW151202)"
			],
			"related_warrant": "BBNW(DBS MB ePW151202);BBLW(UOB MB ePW151202)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 1 December 2015 (Third Notice)",
			"id": 857,
			"tickers": [
				"BBOW(DBS MB eCW151201)",
				"AGJW(GentingSMBeCW151201)",
				"AGIW(NOL MB eCW151201)",
				"AZNW(OCBC Bk MBeCW151201)",
				"AZPW(OCBC Bk MBePW151201)",
				"BBMW(UOB MB eCW151201)",
				"AMBW(NobleGrpMBePW151201)"
			],
			"related_warrant": "BBOW(DBS MB eCW151201);AGJW(GentingSMBeCW151201);AGIW(NOL MB eCW151201);AZNW(OCBC Bk MBeCW151201);AZPW(OCBC Bk MBePW151201);BBMW(UOB MB eCW151201);AMBW(NobleGrpMBePW151201)",
			"underlying": "DBS GRP HLDGS;GENTING SPORE;NEPTUNE OL LTD;OCBC;UOB LTD;NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "01 Dec 15"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2015 (First Notice)",
			"id": 849,
			"tickers": [
				"BCNW(CNOOCMBeCW151230)",
				"BCMW(CNOOCMBePW151230)",
				"BJBW(HSI22400MBePW151230)",
				"BJCW(HSI23800MBeCW151230)",
				"BGIW(HSI24000MBeCW151230)",
				"BJAW(HSI24600MBeCW151230)",
				"BJYW(HSI23200MBePW151230)",
				"BJXW(HSI25400MBeCW151230)",
				"BGJW(HSI20000MBePW151230)",
				"BKUW(HSI21400MBePW151230)",
				"BKTW(HSI22800MBeCW151230)",
				"BCOW(PingAnInsMBePW151230)",
				"BCPW(PingAnInsMBeCW151230)",
				"BCQW(TencentMBePW151230)",
				"BCRW(TencentMBeCW151230)"
			],
			"related_warrant": "BCNW(CNOOCMBeCW151230);BCMW(CNOOCMBePW151230);BJBW(HSI22400MBePW151230);BJCW(HSI23800MBeCW151230);BGIW(HSI24000MBeCW151230);BJAW(HSI24600MBeCW151230);BJYW(HSI23200MBePW151230);BJXW(HSI25400MBeCW151230);BGJW(HSI20000MBePW151230);BKUW(HSI21400MBePW151230);BKTW(HSI22800MBeCW151230);BCOW(PingAnInsMBePW151230);BCPW(PingAnInsMBeCW151230);BCQW(TencentMBePW151230);BCRW(TencentMBeCW151230)",
			"underlying": "CNOOC;HANG SENG INDEX;PING AN;TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0883.HK",
					"underlying_ticker": "883",
					"underlying_name": "CNOOC"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				}
			],
			"date": "30 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2015 (First Notice)",
			"id": 853,
			"tickers": [
				"BEAW(STI 3150MBeCW151231)",
				"BDMW(STI 3100MBePW151231)",
				"BDLW(STI 3300MBeCW151231)",
				"BEBW(STI 2950MBePW151231)",
				"BFWW(STI 3000MBeCW151231)",
				"BFVW(STI 2800MBePW151231)"
			],
			"related_warrant": "BEAW(STI 3150MBeCW151231);BDMW(STI 3100MBePW151231);BDLW(STI 3300MBeCW151231);BEBW(STI 2950MBePW151231);BFWW(STI 3000MBeCW151231);BFVW(STI 2800MBePW151231)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2015 (Third Notice)",
			"id": 845,
			"tickers": [
				"BGAW(CN10500MBeCW151127)",
				"BDEW(CN9500MBePW151127)",
				"BBUW(CN10000MBePW151127)",
				"BFZW(CN8000MBePW151127)",
				"BAXW(CN15000MBeCW151127)",
				"BDDW(CN12500MBeCW151127)",
				"BIHW(HSI22400MBeCW151127)",
				"BIKW(HSI23400MBeCW151127)",
				"BGTW(HSI21400MBeCW151127)",
				"BGVW(HSI18000MBePW151127)",
				"BIGW(HSI21000MBePW151127)",
				"BINW(HSI22000MBePW151127)",
				"BGUW(HSI19000MBePW151127)"
			],
			"related_warrant": "BGAW(CN10500MBeCW151127);BDEW(CN9500MBePW151127);BBUW(CN10000MBePW151127);BFZW(CN8000MBePW151127);BAXW(CN15000MBeCW151127);BDDW(CN12500MBeCW151127);BIHW(HSI22400MBeCW151127);BIKW(HSI23400MBeCW151127);BGTW(HSI21400MBeCW151127);BGVW(HSI18000MBePW151127);BIGW(HSI21000MBePW151127);BINW(HSI22000MBePW151127);BGUW(HSI19000MBePW151127)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "27 Nov 15"
		},
		{
			"headline": "Adjustment Notice",
			"id": 841,
			"tickers": [
				"BBMW(UOB MB eCW151201)",
				"BBLW(UOB MB ePW151202)",
				"BDJW(UOB MB eCW151204)",
				"U4GW(UOB MB eCW160104)",
				"BDKW(UOB MB ePW160104)",
				"BEDW(UOB MB eCW160111)",
				"BEEW(UOB MB ePW160111)",
				"BFSW(UOB MB ePW160201)",
				"BFRW(UOB MB eCW160201)",
				"BHCW(UOB MB ePW160215)",
				"BJNW(UOB MB eCW160404)",
				"BJMW(UOB MB ePW160404)",
				"BKJW(UOB MBeCW160601)",
				"BKIW(UOB MB ePW160601)",
				"AMVW(UOB MB eCW160601)"
			],
			"related_warrant": "BBMW(UOB MB eCW151201);BBLW(UOB MB ePW151202);BDJW(UOB MB eCW151204);U4GW(UOB MB eCW160104);BDKW(UOB MB ePW160104);BEDW(UOB MB eCW160111);BEEW(UOB MB ePW160111);BFSW(UOB MB ePW160201);BFRW(UOB MB eCW160201);BHCW(UOB MB ePW160215);BJNW(UOB MB eCW160404);BJMW(UOB MB ePW160404);BKJW(UOB MBeCW160601);BKIW(UOB MB ePW160601);AMVW(UOB MB eCW160601)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "13 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 11 December 2015 (First Notice)",
			"id": 837,
			"tickers": [
				"BCGW(NKY 21500MBeCW151211)",
				"BCKW(NKY 21000MBePW151211)",
				"BCLW(NKY 22500MBePW151211)",
				"BCJW(NKY 19500MBePW151211)",
				"BCEW(NKY 18500MBeCW151211)",
				"BCIW(NKY 18000MBePW151211)",
				"BCHW(NKY 23000MBeCW151211)",
				"BCFW(NKY 20000MBeCW151211)"
			],
			"related_warrant": "BCGW(NKY 21500MBeCW151211);BCKW(NKY 21000MBePW151211);BCLW(NKY 22500MBePW151211);BCJW(NKY 19500MBePW151211);BCEW(NKY 18500MBeCW151211);BCIW(NKY 18000MBePW151211);BCHW(NKY 23000MBeCW151211);BCFW(NKY 20000MBeCW151211)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "11 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 4 December 2015 (First Notice)",
			"id": 833,
			"tickers": [
				"BDJW(UOB MB eCW151204)"
			],
			"related_warrant": "BDJW(UOB MB eCW151204)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 3 November 2015 (Third Notice)",
			"id": 829,
			"tickers": [
				"AUTW(CLI MB eCW151103)",
				"AUUW(CLI MB ePW151103)",
				"AVIW(OCBC Bk MBeCW151103)",
				"AVHW(OCBC Bk MBePW151103)",
				"AMLW(SembMar MBeCW151103)"
			],
			"related_warrant": "AUTW(CLI MB eCW151103);AUUW(CLI MB ePW151103);AVIW(OCBC Bk MBeCW151103);AVHW(OCBC Bk MBePW151103);AMLW(SembMar MBeCW151103)",
			"underlying": "CHINA LIFE;OCBC;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": "2628.HK",
					"underlying_ticker": "2628",
					"underlying_name": "CHINA LIFE"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "03 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2015 (First Notice)",
			"id": 821,
			"tickers": [
				"BBNW(DBS MB ePW151202)",
				"BBLW(UOB MB ePW151202)"
			],
			"related_warrant": "BBNW(DBS MB ePW151202);BBLW(UOB MB ePW151202)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 2 November 2015 (Third Notice)",
			"id": 825,
			"tickers": [
				"AITW(Ezion MBeCW151102)",
				"AZKW(KepCorp MBeCW151102)",
				"AZOW(OCBC Bk MBeCW151102)",
				"AIPW(SembMar MBeCW151102)",
				"AGUW(SingTelMBePW151102)"
			],
			"related_warrant": "AITW(Ezion MBeCW151102);AZKW(KepCorp MBeCW151102);AZOW(OCBC Bk MBeCW151102);AIPW(SembMar MBeCW151102);AGUW(SingTelMBePW151102)",
			"underlying": "EZION HOLDINGS;KEPPEL CORP;OCBC;SEMBCORP MARINE;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "02 Nov 15"
		},
		{
			"headline": "Expiry of Warrants on 1 December 2015 (First Notice)",
			"id": 813,
			"tickers": [
				"BBOW(DBS MB eCW151201)",
				"AGJW(GentingSMBeCW151201)",
				"AGIW(NOL MB eCW151201)",
				"AZNW(OCBC Bk MBeCW151201)",
				"AZPW(OCBC Bk MBePW151201)",
				"BBMW(UOB MB eCW151201)",
				"AMBW(NobleGrpMBePW151201)"
			],
			"related_warrant": "BBOW(DBS MB eCW151201);AGJW(GentingSMBeCW151201);AGIW(NOL MB eCW151201);AZNW(OCBC Bk MBeCW151201);AZPW(OCBC Bk MBePW151201);BBMW(UOB MB eCW151201);AMBW(NobleGrpMBePW151201)",
			"underlying": "DBS GRP HLDGS;GENTING SPORE;NEPTUNE OL LTD;OCBC;UOB LTD;NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "30 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2015 (Third Notice)",
			"id": 817,
			"tickers": [
				"AZLW(STI 3400MBeCW151030)",
				"AZMW(STI 3200MBePW151030)"
			],
			"related_warrant": "AZLW(STI 3400MBeCW151030);AZMW(STI 3200MBePW151030)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 29 October 2015 (Third Notice)",
			"id": 809,
			"tickers": [
				"BFBW(HSI22800MBeCW151029)",
				"BENW(HSI24600MBeCW151029)",
				"BHBW(HSI18600MBePW151029)",
				"BEOW(HSI23800MBeCW151029)",
				"BGSW(HSI20800MBeCW151029)",
				"BGNW(HSI21600MBePW151029)",
				"BEJW(HSI23400MBePW151029)",
				"BFYW(HSI21800MBeCW151029)",
				"BELW(HSI22600MBePW151029)",
				"BFXW(HSI19600MBePW151029)",
				"BFDW(HSI20600MBePW151029)"
			],
			"related_warrant": "BFBW(HSI22800MBeCW151029);BENW(HSI24600MBeCW151029);BHBW(HSI18600MBePW151029);BEOW(HSI23800MBeCW151029);BGSW(HSI20800MBeCW151029);BGNW(HSI21600MBePW151029);BEJW(HSI23400MBePW151029);BFYW(HSI21800MBeCW151029);BELW(HSI22600MBePW151029);BFXW(HSI19600MBePW151029);BFDW(HSI20600MBePW151029)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2015 (First Notice)",
			"id": 805,
			"tickers": [
				"BGAW(ChinaA5010500MBeCW151127)",
				"BDEW(ChinaA509500MBePW151127)",
				"BBUW(ChinaA5010000MBePW151127)",
				"BFZW(ChinaA508000MBePW151127)",
				"BAXW(ChinaA5015000MBeCW151127)",
				"BDDW(ChinaA5012500MBeCW151127)",
				"BGTW(HSI21400MBeCW151127)",
				"BIKW(HSI23400MBeCW151127)",
				"BGVW(HSI18000MBePW151127)",
				"BINW(HSI22000MBePW151127)",
				"BIHW(HSI22400MBeCW151127)",
				"BGUW(HSI19000MBePW151127)",
				"BIGW(HSI21000MBePW151127)"
			],
			"related_warrant": "BGAW(ChinaA5010500MBeCW151127);BDEW(ChinaA509500MBePW151127);BBUW(ChinaA5010000MBePW151127);BFZW(ChinaA508000MBePW151127);BAXW(ChinaA5015000MBeCW151127);BDDW(ChinaA5012500MBeCW151127);BGTW(HSI21400MBeCW151127);BIKW(HSI23400MBeCW151127);BGVW(HSI18000MBePW151127);BINW(HSI22000MBePW151127);BIHW(HSI22400MBeCW151127);BGUW(HSI19000MBePW151127);BIGW(HSI21000MBePW151127)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "27 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 05 October 2015 (Third Notice)",
			"id": 797,
			"tickers": [
				"ATAW(ChinaMobiMBeCW151005)",
				"ATBW(ChinaMobiMBePW151005)",
				"ATCW(CNOOCMBeCW151005)",
				"ATDW(CNOOCMBePW151005)",
				"AUHW(HKExcMBeCW151005)",
				"AUIW(HKExcMBePW151005)",
				"AULW(TencentMBeCW151005)",
				"AUMW(TencentMBePW151005)",
				"AUJW(PingAnInsMBeCW151005)",
				"AUKW(PingAnInsMBePW151005)",
				"ALXW(GLP MBeCW151005)",
				"ALVW(GLP MBePW151005)"
			],
			"related_warrant": "ATAW(ChinaMobiMBeCW151005);ATBW(ChinaMobiMBePW151005);ATCW(CNOOCMBeCW151005);ATDW(CNOOCMBePW151005);AUHW(HKExcMBeCW151005);AUIW(HKExcMBePW151005);AULW(TencentMBeCW151005);AUMW(TencentMBePW151005);AUJW(PingAnInsMBeCW151005);AUKW(PingAnInsMBePW151005);ALXW(GLP MBeCW151005);ALVW(GLP MBePW151005)",
			"underlying": "CHINA MOBILE;CNOOC;HKEX;TENCENT;PING AN;GLOBAL LOG PROP",
			"underlyings": [
				{
					"underlying_ric": "0941.HK",
					"underlying_ticker": "941",
					"underlying_name": "CHINA MOBILE"
				},
				{
					"underlying_ric": "0883.HK",
					"underlying_ticker": "883",
					"underlying_name": "CNOOC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": "0388.HK",
					"underlying_ticker": "388",
					"underlying_name": "HKEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				}
			],
			"date": "05 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 02 November 2015 (First Notice)",
			"id": 785,
			"tickers": [
				"AITW(Ezion MBeCW151102)",
				"AZKW(KepCorp MBeCW151102)",
				"AZOW(OCBC Bk MBeCW151102)",
				"AIPW(SembMar MBeCW151102)",
				"AGUW(SingTelMBePW151102)"
			],
			"related_warrant": "AITW(Ezion MBeCW151102);AZKW(KepCorp MBeCW151102);AZOW(OCBC Bk MBeCW151102);AIPW(SembMar MBeCW151102);AGUW(SingTelMBePW151102)",
			"underlying": "EZION HOLDINGS;KEPPEL CORP;OCBC;SEMBCORP MARINE;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "02 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 03 November 2015 (First Notice)",
			"id": 789,
			"tickers": [
				"AUTW(CLI MB eCW151103)",
				"AUUW(CLI MB ePW151103)",
				"AVIW(OCBC Bk MBeCW151103)",
				"AVHW(OCBC Bk MBePW151103)",
				"AMLW(SembMar MBeCW151103)"
			],
			"related_warrant": "AUTW(CLI MB eCW151103);AUUW(CLI MB ePW151103);AVIW(OCBC Bk MBeCW151103);AVHW(OCBC Bk MBePW151103);AMLW(SembMar MBeCW151103)",
			"underlying": "China A50;CHINA LIFE;OCBC;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "2628.HK",
					"underlying_ticker": "2628",
					"underlying_name": "CHINA LIFE"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "02 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 02 October 2015 (Third Notice)",
			"id": 793,
			"tickers": [
				"AVSW(DBS MB ePW151002)",
				"AVTW(DBS MB eCW151002)",
				"AVRW(UOB MB ePW151002)",
				"AVPW(UOB MB eCW151002)",
				"ALWW(GLP MBePW151002)"
			],
			"related_warrant": "AVSW(DBS MB ePW151002);AVTW(DBS MB eCW151002);AVRW(UOB MB ePW151002);AVPW(UOB MB eCW151002);ALWW(GLP MBePW151002)",
			"underlying": "DBS GRP HLDGS;UOB LTD;GLOBAL LOG PROP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 01 October 2015 (Third Notice)",
			"id": 781,
			"tickers": [
				"UT2W(GLP MB eCW151001)",
				"AVQW(UOB MB eCW151001)",
				"UK5W(Wilmar MBeCW151001)",
				"AVEW(DBS MB eCW151001)",
				"UM2W(VardHldgsMBeCW151001)"
			],
			"related_warrant": "UT2W(GLP MB eCW151001);AVQW(UOB MB eCW151001);UK5W(Wilmar MBeCW151001);AVEW(DBS MB eCW151001);UM2W(VardHldgsMBeCW151001)",
			"underlying": "GLOBAL LOG PROP;UOB LTD;WILMAR INTL;DBS GRP HLDGS;VARD HOLDINGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Oct 15"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2015 (First Notice)",
			"id": 777,
			"tickers": [
				"AZLW(STI 3400MBeCW151030)",
				"AZMW(STI 3200MBePW151030)"
			],
			"related_warrant": "AZLW(STI 3400MBeCW151030);AZMW(STI 3200MBePW151030)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 29 October 2015 (First Notice)",
			"id": 769,
			"tickers": [
				"BGNW(HSI21600MBePW151029)",
				"BHBW(HSI18600MBePW151029)",
				"BENW(HSI24600MBeCW151029)",
				"BEJW(HSI23400MBePW151029)",
				"BELW(HSI22600MBePW151029)",
				"BFDW(HSI20600MBePW151029)",
				"BFBW(HSI22800MBeCW151029)",
				"BFYW(HSI21800MBeCW151029)",
				"BEOW(HSI23800MBeCW151029)",
				"BFXW(HSI19600MBePW151029)",
				"BGSW(HSI20800MBeCW151029)"
			],
			"related_warrant": "BGNW(HSI21600MBePW151029);BHBW(HSI18600MBePW151029);BENW(HSI24600MBeCW151029);BEJW(HSI23400MBePW151029);BELW(HSI22600MBePW151029);BFDW(HSI20600MBePW151029);BFBW(HSI22800MBeCW151029);BFYW(HSI21800MBeCW151029);BEOW(HSI23800MBeCW151029);BFXW(HSI19600MBePW151029);BGSW(HSI20800MBeCW151029)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2015 (Third Notice)",
			"id": 773,
			"tickers": [
				"BEKW(HSI22800MBePW150929)",
				"BEMW(HSI24200MBeCW150929)",
				"BFCW(HSI20800MBePW150929)",
				"BCUW(HSI25200MBeCW150929)",
				"BCSW(HSI24800MBePW150929)",
				"BCTW(HSI26000MBeCW150929)",
				"BDCW(HSI23800MBePW150929)",
				"BFAW(HSI22200MBeCW150929)",
				"AYRW(CN15500MBeCW150929)",
				"AYTW(CN13000MBePW150929)",
				"BAYW(CN10000MBePW150929)",
				"AYYW(CN17000MBeCW150929)",
				"AYSW(CN11500MBePW150929)",
				"AYQW(CN14000MBeCW150929)"
			],
			"related_warrant": "BEKW(HSI22800MBePW150929);BEMW(HSI24200MBeCW150929);BFCW(HSI20800MBePW150929);BCUW(HSI25200MBeCW150929);BCSW(HSI24800MBePW150929);BCTW(HSI26000MBeCW150929);BDCW(HSI23800MBePW150929);BFAW(HSI22200MBeCW150929);AYRW(CN15500MBeCW150929);AYTW(CN13000MBePW150929);BAYW(CN10000MBePW150929);AYYW(CN17000MBeCW150929);AYSW(CN11500MBePW150929);AYQW(CN14000MBeCW150929)",
			"underlying": "HANG SENG INDEX;China A50",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 15 September 2015 (Third Notice)",
			"id": 765,
			"tickers": [
				"ANDW(DBS MB eCW150915)"
			],
			"related_warrant": "ANDW(DBS MB eCW150915)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "15 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 14 September 2015 (Third Notice)",
			"id": 761,
			"tickers": [
				"ANEW(DBS MB eCW150914)"
			],
			"related_warrant": "ANEW(DBS MB eCW150914)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "14 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 11 September 2015 (Third Notice)",
			"id": 757,
			"tickers": [
				"AXPW(NKY 19500MBeCW150911)",
				"AXQW(NKY 18000MBeCW150911)",
				"AXUW(NKY 17500MBePW150911)",
				"AXTW(NKY 19000MBePW150911)",
				"AXNW(NKY 22500MBeCW150911)",
				"AXSW(NKY 20500MBePW150911)",
				"AXRW(NKY 22000MBePW150911)",
				"AXOW(NKY 21000MBeCW150911)"
			],
			"related_warrant": "AXPW(NKY 19500MBeCW150911);AXQW(NKY 18000MBeCW150911);AXUW(NKY 17500MBePW150911);AXTW(NKY 19000MBePW150911);AXNW(NKY 22500MBeCW150911);AXSW(NKY 20500MBePW150911);AXRW(NKY 22000MBePW150911);AXOW(NKY 21000MBeCW150911)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "11 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 7 September 2015 (Third Notice)",
			"id": 753,
			"tickers": [
				"AFQW(SingPost MBeCW150907)"
			],
			"related_warrant": "AFQW(SingPost MBeCW150907)",
			"underlying": "SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "07 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 5 October 2015 (First Notice)",
			"id": 749,
			"tickers": [
				"ATAW(ChinaMobiMBeCW151005)",
				"ATBW(ChinaMobiMBePW151005)",
				"ATCW(CNOOCMBeCW151005)",
				"ATDW(CNOOCMBePW151005)",
				"ALVW(GLP MBePW151005)",
				"ALXW(GLP MBeCW151005)",
				"AUJW(PingAnInsMBeCW151005)",
				"AUKW(PingAnInsMBePW151005)",
				"AULW(TencentMBeCW151005)",
				"AUMW(TencentMBePW151005)",
				"AUHW(HKExcMBeCW151005)",
				"AUIW(HKExcMBePW151005)"
			],
			"related_warrant": "ATAW(ChinaMobiMBeCW151005);ATBW(ChinaMobiMBePW151005);ATCW(CNOOCMBeCW151005);ATDW(CNOOCMBePW151005);ALVW(GLP MBePW151005);ALXW(GLP MBeCW151005);AUJW(PingAnInsMBeCW151005);AUKW(PingAnInsMBePW151005);AULW(TencentMBeCW151005);AUMW(TencentMBePW151005);AUHW(HKExcMBeCW151005);AUIW(HKExcMBePW151005)",
			"underlying": "CHINA MOBILE;CNOOC;GLOBAL LOG PROP;PING AN;TENCENT;HKEX",
			"underlyings": [
				{
					"underlying_ric": "0941.HK",
					"underlying_ticker": "941",
					"underlying_name": "CHINA MOBILE"
				},
				{
					"underlying_ric": "0883.HK",
					"underlying_ticker": "883",
					"underlying_name": "CNOOC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": "0388.HK",
					"underlying_ticker": "388",
					"underlying_name": "HKEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				}
			],
			"date": "04 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 3 September 2015 (Third Notice)",
			"id": 745,
			"tickers": [
				"AMPW(KepCorp MBePW150903)"
			],
			"related_warrant": "AMPW(KepCorp MBePW150903)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "03 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 2 October 2015 (First Notice)",
			"id": 737,
			"tickers": [
				"AVSW(DBS MB ePW151002)",
				"AVTW(DBS MB eCW151002)",
				"AVRW(UOB MB ePW151002)",
				"AVPW(UOB MB eCW151002)",
				"ALWW(GLP MBePW151002)"
			],
			"related_warrant": "AVSW(DBS MB ePW151002);AVTW(DBS MB eCW151002);AVRW(UOB MB ePW151002);AVPW(UOB MB eCW151002);ALWW(GLP MBePW151002)",
			"underlying": "DBS GRP HLDGS;UOB LTD;GLOBAL LOG PROP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 2 September 2015 (Third Notice)",
			"id": 741,
			"tickers": [
				"ASYW(CLI MB eCW150902)",
				"ASZW(CLI MB ePW150902)",
				"AMOW(KepCorp MBePW150902)"
			],
			"related_warrant": "ASYW(CLI MB eCW150902);ASZW(CLI MB ePW150902);AMOW(KepCorp MBePW150902)",
			"underlying": "CHINA LIFE;KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": "2628.HK",
					"underlying_ticker": "2628",
					"underlying_name": "CHINA LIFE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "02 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 1 September 2015 (Third Notice)",
			"id": 733,
			"tickers": [
				"UI9W(Japfa MBeCW150901)",
				"UM1W(Wilmar MBeCW150901)",
				"AMSW(KepCorp MBeCW150901)"
			],
			"related_warrant": "UI9W(Japfa MBeCW150901);UM1W(Wilmar MBeCW150901);AMSW(KepCorp MBeCW150901)",
			"underlying": "JAPFA LTD;WILMAR INTL;KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "JAPFA LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 1 October 2015 (First Notice)",
			"id": 729,
			"tickers": [
				"UT2W(GLP MB eCW151001)",
				"AVQW(UOB MB eCW151001)",
				"UK5W(Wilmar MBeCW151001)",
				"AVEW(DBS MB eCW151001)",
				"UM2W(VardHldgsMBeCW151001)"
			],
			"related_warrant": "UT2W(GLP MB eCW151001);AVQW(UOB MB eCW151001);UK5W(Wilmar MBeCW151001);AVEW(DBS MB eCW151001);UM2W(VardHldgsMBeCW151001)",
			"underlying": "GLOBAL LOG PROP;UOB LTD;WILMAR INTL;DBS GRP HLDGS;VARD HOLDINGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Sep 15"
		},
		{
			"headline": "Expiry of Warrants on 31 August 2015 (Third Notice)",
			"id": 721,
			"tickers": [
				"AVGW(STI 3600MBeCW150831)",
				"AVFW(STI 3450MBePW150831)"
			],
			"related_warrant": "AVGW(STI 3600MBeCW150831);AVFW(STI 3450MBePW150831)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "31 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2015 (First Notice)",
			"id": 713,
			"tickers": [
				"AYTW(CN13000MBePW150929)",
				"BAYW(CN10000MBePW150929)",
				"AYYW(CN17000MBeCW150929)",
				"AYSW(CN11500MBePW150929)",
				"AYQW(CN14000MBeCW150929)",
				"AYRW(CN15500MBeCW150929)",
				"BEKW(HSI22800MBePW150929)",
				"BFCW(HSI20800MBePW150929)",
				"BDCW(HSI23800MBePW150929)",
				"BCTW(HSI26000MBeCW150929)",
				"BFAW(HSI22200MBeCW150929)",
				"BCSW(HSI24800MBePW150929)",
				"BCUW(HSI25200MBeCW150929)",
				"BEMW(HSI24200MBeCW150929)"
			],
			"related_warrant": "AYTW(CN13000MBePW150929);BAYW(CN10000MBePW150929);AYYW(CN17000MBeCW150929);AYSW(CN11500MBePW150929);AYQW(CN14000MBeCW150929);AYRW(CN15500MBeCW150929);BEKW(HSI22800MBePW150929);BFCW(HSI20800MBePW150929);BDCW(HSI23800MBePW150929);BCTW(HSI26000MBeCW150929);BFAW(HSI22200MBeCW150929);BCSW(HSI24800MBePW150929);BCUW(HSI25200MBeCW150929);BEMW(HSI24200MBeCW150929)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 28 August 2015 (Third Notice)",
			"id": 717,
			"tickers": [
				"BAUW(HSI26400MBeCW150828)",
				"BBHW(HSI25400MBeCW150828)",
				"BBIW(HSI24400MBeCW150828)",
				"BAWW(HSI25000MBePW150828A)",
				"BAKW(HSI28000MBeCW150828)",
				"ATVW(HSI23200MBePW150828)",
				"ATUW(HSI26200MBeCW150828)",
				"BBGW(HSI23000MBePW150828)",
				"BBVW(HSI24000MBePW150828)",
				"AVKW(HSI29000MBeCW150828)",
				"AVJW(HSI25000MBePW150828)",
				"BALW(HSI27200MBeCW150828)",
				"BAMW(HSI25800MBePW150828)",
				"BANW(HSI26800MBePW150828)"
			],
			"related_warrant": "BAUW(HSI26400MBeCW150828);BBHW(HSI25400MBeCW150828);BBIW(HSI24400MBeCW150828);BAWW(HSI25000MBePW150828A);BAKW(HSI28000MBeCW150828);ATVW(HSI23200MBePW150828);ATUW(HSI26200MBeCW150828);BBGW(HSI23000MBePW150828);BBVW(HSI24000MBePW150828);AVKW(HSI29000MBeCW150828);AVJW(HSI25000MBePW150828);BALW(HSI27200MBeCW150828);BAMW(HSI25800MBePW150828);BANW(HSI26800MBePW150828)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 14 September 2015 (First Notice)",
			"id": 705,
			"tickers": [
				"ANEW(DBS MB eCW150914)"
			],
			"related_warrant": "ANEW(DBS MB eCW150914)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "14 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 15 September 2015 (First Notice)",
			"id": 709,
			"tickers": [
				"ANDW(DBS MB eCW150915)"
			],
			"related_warrant": "ANDW(DBS MB eCW150915)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "14 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 11 September 2015 (First Notice)",
			"id": 701,
			"tickers": [
				"AXPW(NKY 19500MBeCW150911)",
				"AXQW(NKY 18000MBeCW150911)",
				"AXUW(NKY 17500MBePW150911)",
				"AXTW(NKY 19000MBePW150911)",
				"AXNW(NKY 22500MBeCW150911)",
				"AXSW(NKY 20500MBePW150911)",
				"AXRW(NKY 22000MBePW150911)",
				"AXOW(NKY 21000MBeCW150911)"
			],
			"related_warrant": "AXPW(NKY 19500MBeCW150911);AXQW(NKY 18000MBeCW150911);AXUW(NKY 17500MBePW150911);AXTW(NKY 19000MBePW150911);AXNW(NKY 22500MBeCW150911);AXSW(NKY 20500MBePW150911);AXRW(NKY 22000MBePW150911);AXOW(NKY 21000MBeCW150911)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "11 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 7 September 2015 (First Notice)",
			"id": 697,
			"tickers": [
				"AFQW(SingPost MBeCW150907)"
			],
			"related_warrant": "AFQW(SingPost MBeCW150907)",
			"underlying": "SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "06 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 4 August 2015 (Third Notice)",
			"id": 693,
			"tickers": [
				"ASVW(TencentMBePW150804)",
				"ASWW(TencentMBeCW150804A)",
				"ASXW(TencentMBeCW150804)"
			],
			"related_warrant": "ASVW(TencentMBePW150804);ASWW(TencentMBeCW150804A);ASXW(TencentMBeCW150804)",
			"underlying": "TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "04 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 3 September 2015 (First Notice)",
			"id": 685,
			"tickers": [
				"AMPW(KepCorp MBePW150903)"
			],
			"related_warrant": "AMPW(KepCorp MBePW150903)",
			"underlying": "KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "03 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 3 August 2015 (Third Notice)",
			"id": 689,
			"tickers": [
				"ALQW(CapitalaMBeCW150803)",
				"ALZW(NobleGrpMBeCW150803)",
				"ANIW(OCBC Bk MBeCW150803)",
				"AIIW(SGX MB ePW150803)",
				"AJGW(SGX MB eCW150803)"
			],
			"related_warrant": "ALQW(CapitalaMBeCW150803);ALZW(NobleGrpMBeCW150803);ANIW(OCBC Bk MBeCW150803);AIIW(SGX MB ePW150803);AJGW(SGX MB eCW150803)",
			"underlying": "CAPITALAND;NOBLE GROUP;OCBC;SGX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				}
			],
			"date": "03 Aug 15"
		},
		{
			"headline": "Expiry of Warrants on 31 August 2015 (First Notice)",
			"id": 673,
			"tickers": [
				"AVGW(STI 3600MBeCW150831)",
				"AVFW(STI 3450MBePW150831)"
			],
			"related_warrant": "AVGW(STI 3600MBeCW150831);AVFW(STI 3450MBePW150831)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "31 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 1 September 2015 (First Notice)",
			"id": 677,
			"tickers": [
				"UI9W(Japfa MBeCW150901)",
				"AMSW(KepCorp MBeCW150901)",
				"UM1W(Wilmar MBeCW150901)"
			],
			"related_warrant": "UI9W(Japfa MBeCW150901);AMSW(KepCorp MBeCW150901);UM1W(Wilmar MBeCW150901)",
			"underlying": "JAPFA LTD;KEPPEL CORP;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "JAPFA LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "31 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 2 September 2015 (First Notice)",
			"id": 681,
			"tickers": [
				"ASYW(CLI MB eCW150902)",
				"ASZW(CLI MB ePW150902)",
				"AMOW(KepCorp MBePW150902)"
			],
			"related_warrant": "ASYW(CLI MB eCW150902);ASZW(CLI MB ePW150902);AMOW(KepCorp MBePW150902)",
			"underlying": "CHINA LIFE;KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": "2628.HK",
					"underlying_ticker": "2628",
					"underlying_name": "CHINA LIFE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "31 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2015 (Third Notice)",
			"id": 669,
			"tickers": [
				"AVOW(CN16500MBeCW150730)",
				"AVLW(CN12500MBePW150730)",
				"ATKW(CN11000MBePW150730)",
				"AODW(CN15000MBeCW150730)",
				"AOCW(CN10000MBePW150730)",
				"ATJW(CN12000MBeCW150730)",
				"AYZW(HSI29200MBeCW150730)",
				"BAVW(HSI25600MBePW150730)",
				"AYHW(HSI26800MBeCW150730)",
				"AYKW(HSI27200MBePW150730)",
				"AYIW(HSI27600MBeCW150730)",
				"AZJW(HSI28400MBeCW150730)",
				"AYJW(HSI26400MBePW150730)"
			],
			"related_warrant": "AVOW(CN16500MBeCW150730);AVLW(CN12500MBePW150730);ATKW(CN11000MBePW150730);AODW(CN15000MBeCW150730);AOCW(CN10000MBePW150730);ATJW(CN12000MBeCW150730);AYZW(HSI29200MBeCW150730);BAVW(HSI25600MBePW150730);AYHW(HSI26800MBeCW150730);AYKW(HSI27200MBePW150730);AYIW(HSI27600MBeCW150730);AZJW(HSI28400MBeCW150730);AYJW(HSI26400MBePW150730)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 28 August 2015 (First expiry)",
			"id": 665,
			"tickers": [
				"BBHW(HSI25400MBeCW150828)",
				"BAWW(HSI25000MBePW150828A)",
				"AVKW(HSI29000MBeCW150828)",
				"AVJW(HSI25000MBePW150828)",
				"BAMW(HSI25800MBePW150828)",
				"ATUW(HSI26200MBeCW150828)",
				"ATVW(HSI23200MBePW150828)",
				"BBVW(HSI24000MBePW150828)",
				"BALW(HSI27200MBeCW150828)",
				"BANW(HSI26800MBePW150828)",
				"BBGW(HSI23000MBePW150828)",
				"BBIW(HSI24400MBeCW150828)",
				"BAKW(HSI28000MBeCW150828)",
				"BAUW(HSI26400MBeCW150828)"
			],
			"related_warrant": "BBHW(HSI25400MBeCW150828);BAWW(HSI25000MBePW150828A);AVKW(HSI29000MBeCW150828);AVJW(HSI25000MBePW150828);BAMW(HSI25800MBePW150828);ATUW(HSI26200MBeCW150828);ATVW(HSI23200MBePW150828);BBVW(HSI24000MBePW150828);BALW(HSI27200MBeCW150828);BANW(HSI26800MBePW150828);BBGW(HSI23000MBePW150828);BBIW(HSI24400MBeCW150828);BAKW(HSI28000MBeCW150828);BAUW(HSI26400MBeCW150828)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Jul 15"
		},
		{
			"headline": "Adjustment Notice",
			"id": 661,
			"tickers": [
				"AUJW(PingAnInsMBeCW151005)",
				"AUKW(PingAnInsMBePW151005)",
				"BCOW(PingAnInsMBePW151230)",
				"BCPW(PingAnInsMBeCW151230)"
			],
			"related_warrant": "AUJW(PingAnInsMBeCW151005);AUKW(PingAnInsMBePW151005);BCOW(PingAnInsMBePW151230);BCPW(PingAnInsMBeCW151230)",
			"underlying": "PING AN;PING AN",
			"underlyings": [
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				}
			],
			"date": "24 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 16 July 2015 (Third Notice)",
			"id": 657,
			"tickers": [
				"ANAW(DBS MB ePW150716)"
			],
			"related_warrant": "ANAW(DBS MB ePW150716)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "16 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 15 July 2015 (Third Notice)",
			"id": 653,
			"tickers": [
				"ANCW(DBS MB eCW150715)",
				"AMYW(DBS MB ePW150715)",
				"ANGW(OCBC Bk MBePW150715)"
			],
			"related_warrant": "ANCW(DBS MB eCW150715);AMYW(DBS MB ePW150715);ANGW(OCBC Bk MBePW150715)",
			"underlying": "DBS GRP HLDGS;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "15 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 14 July 2015 (Third Notice)",
			"id": 649,
			"tickers": [
				"AMZW(DBS MB ePW150714)",
				"ANFW(OCBC Bk MBePW150714)"
			],
			"related_warrant": "AMZW(DBS MB ePW150714);ANFW(OCBC Bk MBePW150714)",
			"underlying": "DBS GRP HLDGS;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "14 Jul 15"
		},
		{
			"headline": "Adjustment Notice",
			"id": 645,
			"tickers": [
				"AFQW(SingPost MBeCW150907)",
				"ANMW(SingPost MBeCW160104)",
				"ANLW(SingPost MBeCW160601)"
			],
			"related_warrant": "AFQW(SingPost MBeCW150907);ANMW(SingPost MBeCW160104);ANLW(SingPost MBeCW160601)",
			"underlying": "SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "10 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2015 (First expiry)",
			"id": 641,
			"tickers": [
				"BAVW(HSI25600MBePW150730)"
			],
			"related_warrant": "BAVW(HSI25600MBePW150730)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "06 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 03 August 2015 (First expiry)",
			"id": 629,
			"tickers": [
				"ALQW(CapitalaMBeCW150803)",
				"ALZW(NobleGrpMBeCW150803)",
				"ANIW(OCBC Bk MBeCW150803)",
				"AIIW(SGX MB ePW150803)",
				"AJGW(SGX MB eCW150803)"
			],
			"related_warrant": "ALQW(CapitalaMBeCW150803);ALZW(NobleGrpMBeCW150803);ANIW(OCBC Bk MBeCW150803);AIIW(SGX MB ePW150803);AJGW(SGX MB eCW150803)",
			"underlying": "CAPITALAND;NOBLE GROUP;OCBC;SGX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				}
			],
			"date": "03 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 04 August 2015 (First expiry)",
			"id": 633,
			"tickers": [
				"ASVW(TencentMBePW150804)",
				"ASWW(TencentMBeCW150804A)",
				"ASXW(TencentMBeCW150804)"
			],
			"related_warrant": "ASVW(TencentMBePW150804);ASWW(TencentMBeCW150804A);ASXW(TencentMBeCW150804)",
			"underlying": "TENCENT",
			"underlyings": [
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "700",
					"underlying_name": "TENCENT"
				},
				{
					"underlying_ric": "0700.HK",
					"underlying_ticker": "TENCENT",
					"underlying_name": "TENCENT"
				}
			],
			"date": "03 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 03 July 2015 (Third Notice)",
			"id": 637,
			"tickers": [
				"ASRW(HKExcMBeCW150703)",
				"ASSW(HKExcMBePW150703)",
				"ASUW(PingAnInsMBePW150703)",
				"ASTW(PingAnInsMBeCW150703)",
				"AMTW(UOB MB ePW150703)"
			],
			"related_warrant": "ASRW(HKExcMBeCW150703);ASSW(HKExcMBePW150703);ASUW(PingAnInsMBePW150703);ASTW(PingAnInsMBeCW150703);AMTW(UOB MB ePW150703)",
			"underlying": "HKEX;PING AN;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": "0388.HK",
					"underlying_ticker": "388",
					"underlying_name": "HKEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 02 July 2015 (Third Notice)",
			"id": 625,
			"tickers": [
				"AJLW(KepCorp MBePW150702)",
				"AMRW(KepCorp MBeCW150702)",
				"AMWW(UOB MB eCW150702)",
				"AMUW(UOB MB ePW150702)"
			],
			"related_warrant": "AJLW(KepCorp MBePW150702);AMRW(KepCorp MBeCW150702);AMWW(UOB MB eCW150702);AMUW(UOB MB ePW150702)",
			"underlying": "KEPPEL CORP;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 01 July 2015 (Third Notice)",
			"id": 621,
			"tickers": [
				"T8PW(CapitalaMBeCW150701)",
				"T8OW(DBS MB eCW150701)",
				"AMNW(KepCorp MBePW150701)",
				"AJKW(KepCorp MBeCW150701)",
				"ALYW(NobleGrpMBeCW150701)",
				"ANJW(OCBC Bk MBeCW150701)",
				"AGPW(SembMar MBeCW150701)",
				"AMXW(UOB MB eCW150701)",
				"T8LW(Wilmar MBeCW150701)"
			],
			"related_warrant": "T8PW(CapitalaMBeCW150701);T8OW(DBS MB eCW150701);AMNW(KepCorp MBePW150701);AJKW(KepCorp MBeCW150701);ALYW(NobleGrpMBeCW150701);ANJW(OCBC Bk MBeCW150701);AGPW(SembMar MBeCW150701);AMXW(UOB MB eCW150701);T8LW(Wilmar MBeCW150701)",
			"underlying": "CAPITALAND;DBS GRP HLDGS;KEPPEL CORP;NOBLE GROUP;OCBC;SEMBCORP MARINE;UOB LTD;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Jul 15"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2015 (First Notice)",
			"id": 613,
			"tickers": [
				"AVOW(ChinaA5016500MBeCW150730)",
				"AVLW(ChinaA5012500MBePW150730)",
				"ATKW(ChinaA5011000MBePW150730)",
				"AODW(ChinaA5015000MBeCW150730)",
				"AOCW(ChinaA5010000MBePW150730)",
				"ATJW(ChinaA5012000MBeCW150730)",
				"AYIW(HSI27600MBeCW150730)",
				"AZJW(HSI28400MBeCW150730)",
				"AYKW(HSI27200MBePW150730)",
				"AYHW(HSI26800MBeCW150730)",
				"AYJW(HSI26400MBePW150730)",
				"AYZW(HSI29200MBeCW150730)"
			],
			"related_warrant": "AVOW(ChinaA5016500MBeCW150730);AVLW(ChinaA5012500MBePW150730);ATKW(ChinaA5011000MBePW150730);AODW(ChinaA5015000MBeCW150730);AOCW(ChinaA5010000MBePW150730);ATJW(ChinaA5012000MBeCW150730);AYIW(HSI27600MBeCW150730);AZJW(HSI28400MBeCW150730);AYKW(HSI27200MBePW150730);AYHW(HSI26800MBeCW150730);AYJW(HSI26400MBePW150730);AYZW(HSI29200MBeCW150730)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 30 June 2015 (Third Notice)",
			"id": 617,
			"tickers": [
				"ASPW(STI 3250MBePW150630)",
				"ASOW(STI 3450MBeCW150630)"
			],
			"related_warrant": "ASPW(STI 3250MBePW150630);ASOW(STI 3450MBeCW150630)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2015 (Third Notice)",
			"id": 609,
			"tickers": [
				"ATXW(HSI27400MBeCW150629)",
				"ATZW(HSI28200MBeCW150629)",
				"AUBW(HSI26200MBePW150629)",
				"AUDW(HSI27000MBePW150629)",
				"AUYW(HSI29000MBeCW150629)",
				"AFKW(HSI26000MBeCW150629)",
				"AFIW(HSI22000MBePW150629)"
			],
			"related_warrant": "ATXW(HSI27400MBeCW150629);ATZW(HSI28200MBeCW150629);AUBW(HSI26200MBePW150629);AUDW(HSI27000MBePW150629);AUYW(HSI29000MBeCW150629);AFKW(HSI26000MBeCW150629);AFIW(HSI22000MBePW150629)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Jun 15"
		},
		{
			"headline": "Adjustment Notice",
			"id": 605,
			"tickers": [
				"AIJW(Ezra MBeCW160104)"
			],
			"related_warrant": "AIJW(Ezra MBeCW160104)",
			"underlying": "EZRA HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				}
			],
			"date": "25 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 16 July 2015 (First expiry)",
			"id": 601,
			"tickers": [
				"ANAW(DBS MB ePW150716)"
			],
			"related_warrant": "ANAW(DBS MB ePW150716)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "16 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 15 July 2015 (First expiry)",
			"id": 597,
			"tickers": [
				"ANCW(DBS MB eCW150715)",
				"AMYW(DBS MB ePW150715)",
				"ANGW(OCBC Bk MBePW150715)"
			],
			"related_warrant": "ANCW(DBS MB eCW150715);AMYW(DBS MB ePW150715);ANGW(OCBC Bk MBePW150715)",
			"underlying": "DBS GRP HLDGS;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "15 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 14 July 2015 (First expiry)",
			"id": 589,
			"tickers": [
				"AMZW(DBS MB ePW150714)",
				"ANFW(OCBC Bk MBePW150714)"
			],
			"related_warrant": "AMZW(DBS MB ePW150714);ANFW(OCBC Bk MBePW150714)",
			"underlying": "DBS GRP HLDGS;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "12 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 12 June 2015 (Third Notice)",
			"id": 593,
			"tickers": [
				"ANYW(NKY 15500MBeCW150612)",
				"ANXW(NKY 17000MBeCW150612)",
				"ANWW(NKY 18500MBeCW150612)",
				"ANVW(NKY 20000MBeCW150612)",
				"ANUW(NKY 15000MBePW150612)",
				"ANSW(NKY 18000MBePW150612)",
				"ANRW(NKY 19500MBePW150612)",
				"ANTW(NKY 16500MBePW150612)"
			],
			"related_warrant": "ANYW(NKY 15500MBeCW150612);ANXW(NKY 17000MBeCW150612);ANWW(NKY 18500MBeCW150612);ANVW(NKY 20000MBeCW150612);ANUW(NKY 15000MBePW150612);ANSW(NKY 18000MBePW150612);ANRW(NKY 19500MBePW150612);ANTW(NKY 16500MBePW150612)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "12 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 3 July 2015 (First expiry)",
			"id": 581,
			"tickers": [
				"ASRW(HKExcMBeCW150703)",
				"ASSW(HKExcMBePW150703)",
				"ASUW(PingAnInsMBePW150703)",
				"ASTW(PingAnInsMBeCW150703)",
				"AMTW(UOB MB ePW150703)"
			],
			"related_warrant": "ASRW(HKExcMBeCW150703);ASSW(HKExcMBePW150703);ASUW(PingAnInsMBePW150703);ASTW(PingAnInsMBeCW150703);AMTW(UOB MB ePW150703)",
			"underlying": "HKEX;PING AN;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": "0388.HK",
					"underlying_ticker": "388",
					"underlying_name": "HKEX"
				},
				{
					"underlying_ric": "2318.HK",
					"underlying_ticker": "2318",
					"underlying_name": "PING AN"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 3 June 2015 (Third expiry)",
			"id": 585,
			"tickers": [
				"AIOW(KepCorp MBePW150603)",
				"ADGW(SingPost MBeCW150603)",
				"AJEW(UOB MB ePW150603)"
			],
			"related_warrant": "AIOW(KepCorp MBePW150603);ADGW(SingPost MBeCW150603);AJEW(UOB MB ePW150603)",
			"underlying": "KEPPEL CORP;SINGAPORE POST;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 2 July 2015 (First notice)",
			"id": 573,
			"tickers": [
				"AMRW(KepCorp MBeCW150702)",
				"AJLW(KepCorp MBePW150702)",
				"AMUW(UOB MB ePW150702)",
				"AMWW(UOB MB eCW150702)"
			],
			"related_warrant": "AMRW(KepCorp MBeCW150702);AJLW(KepCorp MBePW150702);AMUW(UOB MB ePW150702);AMWW(UOB MB eCW150702)",
			"underlying": "KEPPEL CORP;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 2 June 2015 (Third notice)",
			"id": 577,
			"tickers": [
				"AGCW(DBS MB eCW150602)",
				"AINW(KepCorp MBeCW150602)",
				"AJFW(UOB MB eCW150602)",
				"UC5W(GentingSMBePW150602)"
			],
			"related_warrant": "AGCW(DBS MB eCW150602);AINW(KepCorp MBeCW150602);AJFW(UOB MB eCW150602);UC5W(GentingSMBePW150602)",
			"underlying": "DBS GRP HLDGS;KEPPEL CORP;UOB LTD;GENTING SPORE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Jun 15"
		},
		{
			"headline": "Expiry of Warrants on 29 June 2015 (First notice)",
			"id": 557,
			"tickers": [
				"ATZW(HSI28200MBeCW150629)",
				"AFKW(HSI26000MBeCW150629)",
				"AUBW(HSI26200MBePW150629)",
				"AUYW(HSI29000MBeCW150629)",
				"AUDW(HSI27000MBePW150629)",
				"ATXW(HSI27400MBeCW150629)",
				"AFIW(HSI22000MBePW150629)"
			],
			"related_warrant": "ATZW(HSI28200MBeCW150629);AFKW(HSI26000MBeCW150629);AUBW(HSI26200MBePW150629);AUYW(HSI29000MBeCW150629);AUDW(HSI27000MBePW150629);ATXW(HSI27400MBeCW150629);AFIW(HSI22000MBePW150629)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 May 15"
		},
		{
			"headline": "Expiry of Warrants on 30 June 2015 (First notice)",
			"id": 561,
			"tickers": [
				"ASPW(STI 3250MBePW150630)",
				"ASOW(STI 3450MBeCW150630)"
			],
			"related_warrant": "ASPW(STI 3250MBePW150630);ASOW(STI 3450MBeCW150630)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 May 15"
		},
		{
			"headline": "Expiry of Warrants on 1 July 2015 (First notice)",
			"id": 565,
			"tickers": [
				"T8PW(CapitalaMBeCW150701)",
				"T8OW(DBS MB eCW150701)",
				"AMNW(KepCorp MBePW150701)",
				"AJKW(KepCorp MBeCW150701)",
				"ALYW(NobleGrpMBeCW150701)",
				"ANJW(OCBC Bk MBeCW150701)",
				"AGPW(SembMar MBeCW150701)",
				"AMXW(UOB MB eCW150701)",
				"T8LW(Wilmar MBeCW150701)"
			],
			"related_warrant": "T8PW(CapitalaMBeCW150701);T8OW(DBS MB eCW150701);AMNW(KepCorp MBePW150701);AJKW(KepCorp MBeCW150701);ALYW(NobleGrpMBeCW150701);ANJW(OCBC Bk MBeCW150701);AGPW(SembMar MBeCW150701);AMXW(UOB MB eCW150701);T8LW(Wilmar MBeCW150701)",
			"underlying": "CAPITALAND;DBS GRP HLDGS;keppel corp;NOBLE GROUP;OCBC;SEMBCORP MARINE;UOB LTD;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "keppel corp"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "29 May 15"
		},
		{
			"headline": "Expiry of Warrants on 1 June 2015 (Third notice)",
			"id": 569,
			"tickers": [
				"TF9W(CapitalaMBeCW150601)",
				"T8NW(KepCorp MBeCW150601)",
				"T8MW(SingTelMBeCW150601)"
			],
			"related_warrant": "TF9W(CapitalaMBeCW150601);T8NW(KepCorp MBeCW150601);T8MW(SingTelMBeCW150601)",
			"underlying": "CAPITALAND;keppel corp;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "keppel corp"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "29 May 15"
		},
		{
			"headline": "Expiry of Warrants on 28 May 2015 (Third Notice)",
			"id": 553,
			"tickers": [
				"ALHW(ChinaA5012500MBeCW150528)",
				"AJDW(ChinaA509200MBePW150528)",
				"AJCW(ChinaA5011000MBeCW150528)",
				"AOBW(ChinaA5010600MBePW150528)",
				"ATWW(HSI24600MBePW150528)",
				"ATEW(HSI24000MBePW150528)",
				"ATFW(HSI24200MBeCW150528)",
				"AUAW(HSI27000MBeCW150528)",
				"ATYW(HSI27800MBeCW150528)",
				"AUCW(HSI26600MBePW150528)",
				"ATTW(HSI25400MBeCW150528)",
				"ATGW(HSI24800MBeCW150528)",
				"AUFW(HSI28600MBeCW150528)",
				"AVDW(HSI29400MBeCW150528)",
				"AUGW(HSI27400MBePW150528)"
			],
			"related_warrant": "ALHW(ChinaA5012500MBeCW150528);AJDW(ChinaA509200MBePW150528);AJCW(ChinaA5011000MBeCW150528);AOBW(ChinaA5010600MBePW150528);ATWW(HSI24600MBePW150528);ATEW(HSI24000MBePW150528);ATFW(HSI24200MBeCW150528);AUAW(HSI27000MBeCW150528);ATYW(HSI27800MBeCW150528);AUCW(HSI26600MBePW150528);ATTW(HSI25400MBeCW150528);ATGW(HSI24800MBeCW150528);AUFW(HSI28600MBeCW150528);AVDW(HSI29400MBeCW150528);AUGW(HSI27400MBePW150528)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 May 15"
		},
		{
			"headline": "Expiry of Warrants on 25 May 2015 (Third Notice)",
			"id": 549,
			"tickers": [
				"AGEW(UOB MB ePW150525)"
			],
			"related_warrant": "AGEW(UOB MB ePW150525)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "25 May 15"
		},
		{
			"headline": "Expiry of Warrants on 19 May 2015 (Third Notice)",
			"id": 541,
			"tickers": [
				"UQ9W(SembMar MBePW150519)"
			],
			"related_warrant": "UQ9W(SembMar MBePW150519)",
			"underlying": "SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "19 May 15"
		},
		{
			"headline": "Expiry of Warrants on 12 June 2015 (First Notice)",
			"id": 537,
			"tickers": [
				"ANRW(NKY 19500MBePW150612)",
				"ANTW(NKY 16500MBePW150612)",
				"ANSW(NKY 18000MBePW150612)",
				"ANUW(NKY 15000MBePW150612)",
				"ANVW(NKY 20000MBeCW150612)",
				"ANWW(NKY 18500MBeCW150612)",
				"ANXW(NKY 17000MBeCW150612)",
				"ANYW(NKY 15500MBeCW150612)"
			],
			"related_warrant": "ANRW(NKY 19500MBePW150612);ANTW(NKY 16500MBePW150612);ANSW(NKY 18000MBePW150612);ANUW(NKY 15000MBePW150612);ANVW(NKY 20000MBeCW150612);ANWW(NKY 18500MBeCW150612);ANXW(NKY 17000MBeCW150612);ANYW(NKY 15500MBeCW150612)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "12 May 15"
		},
		{
			"headline": "Expiry of Warrants on 4 May 2015 (Third Notice)",
			"id": 533,
			"tickers": [
				"UD7W(BiosensoMBeCW150504)",
				"UU3W(Ezion MBeCW150504)",
				"ADIW(Ezra MBeCW150504)",
				"U8IW(IndoAgriMBeCW150504)",
				"U8EW(NOL MB eCW150504)",
				"UC7W(Yanlord MBeCW150504)",
				"AGMW(OCBC Bk MBePW150504)"
			],
			"related_warrant": "UD7W(BiosensoMBeCW150504);UU3W(Ezion MBeCW150504);ADIW(Ezra MBeCW150504);U8IW(IndoAgriMBeCW150504);U8EW(NOL MB eCW150504);UC7W(Yanlord MBeCW150504);AGMW(OCBC Bk MBePW150504)",
			"underlying": "BIOSENSORS;EZION HOLDINGS;EZRA HLDGS;INDOFOOD AGRI;NEPTUNE OL LTD;YANLORD LAND;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "INDOFOOD AGRI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "04 May 15"
		},
		{
			"headline": "Expiry of Warrants on 1 June 2015 (First Notice)",
			"id": 517,
			"tickers": [
				"TF9W(CapitalaMBeCW150601)"
			],
			"related_warrant": "TF9W(CapitalaMBeCW150601)",
			"underlying": "CAPITALAND",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				}
			],
			"date": "30 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 2 June 2015 (First Notice)",
			"id": 521,
			"tickers": [
				"AGCW(DBS MB eCW150602)",
				"AINW(KepCorp MBeCW150602)",
				"AJFW(UOB MB eCW150602)",
				"UC5W(GentingSMBePW150602)"
			],
			"related_warrant": "AGCW(DBS MB eCW150602);AINW(KepCorp MBeCW150602);AJFW(UOB MB eCW150602);UC5W(GentingSMBePW150602)",
			"underlying": "DBS GRP HLDGS;KEPPEL CORP;UOB LTD;GENTING SPORE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "30 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 3 June 2015 (First Notice)",
			"id": 525,
			"tickers": [
				"ADGW(SingPost MBeCW150603)",
				"AIOW(KepCorp MBePW150603)",
				"AJEW(UOB MB ePW150603)"
			],
			"related_warrant": "ADGW(SingPost MBeCW150603);AIOW(KepCorp MBePW150603);AJEW(UOB MB ePW150603)",
			"underlying": "SINGAPORE POST;KEPPEL CORP;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "30 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 30 April 2015 (Third Notice)",
			"id": 529,
			"tickers": [
				"AIRW(STI 3200MBePW150430)",
				"AISW(STI 3400MBeCW150430)",
				"AQGW(STI 3500MBeCW150430)",
				"AQFW(STI 3300MBePW150430)"
			],
			"related_warrant": "AIRW(STI 3200MBePW150430);AISW(STI 3400MBeCW150430);AQGW(STI 3500MBeCW150430);AQFW(STI 3300MBePW150430)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 29 April 2015",
			"id": 513,
			"tickers": [
				"APXW(HSI24200MBePW150429)",
				"APVW(HSI23600MBePW150429)",
				"APSW(HSI24400MBeCW150429)",
				"APQW(HSI25000MBeCW150429)"
			],
			"related_warrant": "APXW(HSI24200MBePW150429);APVW(HSI23600MBePW150429);APSW(HSI24400MBeCW150429);APQW(HSI25000MBeCW150429)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 28 May 2015 (First Notice)",
			"id": 505,
			"tickers": [
				"AUCW(HSI26600MBePW150528)",
				"ATYW(HSI27800MBeCW150528)",
				"ATGW(HSI24800MBeCW150528)",
				"ATEW(HSI24000MBePW150528)",
				"ATFW(HSI24200MBeCW150528)",
				"AUGW(HSI27400MBePW150528)",
				"ATTW(HSI25400MBeCW150528)",
				"AUAW(HSI27000MBeCW150528)",
				"AVDW(HSI29400MBeCW150528)",
				"AUFW(HSI28600MBeCW150528)",
				"ATWW(HSI24600MBePW150528)",
				"AJDW(ChinaA509200MBePW150528)",
				"AJCW(ChinaA5011000MBeCW150528)",
				"AOBW(ChinaA5010600MBePW150528)",
				"ALHW(ChinaA5012500MBeCW150528)"
			],
			"related_warrant": "AUCW(HSI26600MBePW150528);ATYW(HSI27800MBeCW150528);ATGW(HSI24800MBeCW150528);ATEW(HSI24000MBePW150528);ATFW(HSI24200MBeCW150528);AUGW(HSI27400MBePW150528);ATTW(HSI25400MBeCW150528);AUAW(HSI27000MBeCW150528);AVDW(HSI29400MBeCW150528);AUFW(HSI28600MBeCW150528);ATWW(HSI24600MBePW150528);AJDW(ChinaA509200MBePW150528);AJCW(ChinaA5011000MBeCW150528);AOBW(ChinaA5010600MBePW150528);ALHW(ChinaA5012500MBeCW150528)",
			"underlying": "HANG SENG INDEX;China A50",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Apr 15"
		},
		{
			"headline": "Adjustment Notice",
			"id": 509,
			"tickers": [
				"AGEW(UOB MB ePW150525)",
				"AJFW(UOB MB eCW150602)",
				"AJEW(UOB MB ePW150603)",
				"AMXW(UOB MB eCW150701)",
				"AMWW(UOB MB eCW150702)",
				"AMUW(UOB MB ePW150702)",
				"AMTW(UOB MB ePW150703)",
				"AVQW(UOB MB eCW151001)",
				"AVPW(UOB MB eCW151002)",
				"AVRW(UOB MB ePW151002)",
				"U4GW(UOB MB eCW160104)",
				"AMVW(UOB MB eCW160601)"
			],
			"related_warrant": "AGEW(UOB MB ePW150525);AJFW(UOB MB eCW150602);AJEW(UOB MB ePW150603);AMXW(UOB MB eCW150701);AMWW(UOB MB eCW150702);AMUW(UOB MB ePW150702);AMTW(UOB MB ePW150703);AVQW(UOB MB eCW151001);AVPW(UOB MB eCW151002);AVRW(UOB MB ePW151002);U4GW(UOB MB eCW160104);AMVW(UOB MB eCW160601)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "28 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 25 May 2015 (First Notice)",
			"id": 501,
			"tickers": [
				"AGEW(UOB MB ePW150525)"
			],
			"related_warrant": "AGEW(UOB MB ePW150525)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "24 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 20 April 2015 (Third Notice)",
			"id": 497,
			"tickers": [
				"AGNW(DBS MB eCW150420)"
			],
			"related_warrant": "AGNW(DBS MB eCW150420)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "20 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 19 May 2015 (First Notice)",
			"id": 493,
			"tickers": [
				"UQ9W(SembMar MBePW150519)"
			],
			"related_warrant": "UQ9W(SembMar MBePW150519)",
			"underlying": "SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				}
			],
			"date": "17 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 15 April 2015 (Third Notice)",
			"id": 489,
			"tickers": [
				"AGFW(UOB MB eCW150415)"
			],
			"related_warrant": "AGFW(UOB MB eCW150415)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "15 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 13 April 2015 (Third Notice)",
			"id": 485,
			"tickers": [
				"AGDW(OCBC Bk MBeCW150413)"
			],
			"related_warrant": "AGDW(OCBC Bk MBeCW150413)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "13 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 4 May 2015 (First Notice)",
			"id": 477,
			"tickers": [
				"UD7W(BiosensoMBeCW150504)",
				"UU3W(Ezion MBeCW150504)",
				"ADIW(Ezra MBeCW150504)",
				"U8IW(IndoAgriMBeCW150504)",
				"U8EW(NOL MB eCW150504)",
				"UC7W(Yanlord MBeCW150504)",
				"AGMW(OCBC Bk MBePW150504)"
			],
			"related_warrant": "UD7W(BiosensoMBeCW150504);UU3W(Ezion MBeCW150504);ADIW(Ezra MBeCW150504);U8IW(IndoAgriMBeCW150504);U8EW(NOL MB eCW150504);UC7W(Yanlord MBeCW150504);AGMW(OCBC Bk MBePW150504)",
			"underlying": "BIOSENSORS;EZION HOLDINGS;EZRA HLDGS;INDOFOOD AGRI;NEPTUNE OL LTD;YANLORD LAND;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "INDOFOOD AGRI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "02 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 2 April 2015 (Third Notice)",
			"id": 481,
			"tickers": [
				"AAOW(ComfortDeMBeCW150402)",
				"AAAW(SingPost MBeCW150402)",
				"AACW(NobleGrpMBeCW150402)",
				"AGTW(DBS MB ePW150402)"
			],
			"related_warrant": "AAOW(ComfortDeMBeCW150402);AAAW(SingPost MBeCW150402);AACW(NobleGrpMBeCW150402);AGTW(DBS MB ePW150402)",
			"underlying": "COMFORTDELGRO;SINGAPORE POST;NOBLE GROUP;DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COMFORTDELGRO"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "02 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 1 April 2015 (Third Notice)",
			"id": 473,
			"tickers": [
				"U8CW(NOL MB eCW150401)",
				"U8TW(NobleGrpMBeCW150401)",
				"U8PW(OCBC Bk MBePW150401)",
				"AFTW(DBS MB ePW150401)",
				"UU1W(KepCorp MBeCW150401)",
				"UU5W(Wilmar MBePW150401)"
			],
			"related_warrant": "U8CW(NOL MB eCW150401);U8TW(NobleGrpMBeCW150401);U8PW(OCBC Bk MBePW150401);AFTW(DBS MB ePW150401);UU1W(KepCorp MBeCW150401);UU5W(Wilmar MBePW150401)",
			"underlying": "NEPTUNE OL LTD;NOBLE GROUP;OCBC;DBS GRP HLDGS;KEPPEL CORP;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Apr 15"
		},
		{
			"headline": "Expiry of Warrants on 30 April 2015 (First Notice)",
			"id": 465,
			"tickers": [
				"AIRW(STI 3200MBePW150430)",
				"AISW(STI 3400MBeCW150430)",
				"AQGW(STI 3500MBeCW150430)",
				"AQFW(STI 3300MBePW150430)"
			],
			"related_warrant": "AIRW(STI 3200MBePW150430);AISW(STI 3400MBeCW150430);AQGW(STI 3500MBeCW150430);AQFW(STI 3300MBePW150430)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2015 (Third Notice)",
			"id": 469,
			"tickers": [
				"UU2W(KepCorp MBePW150330)",
				"APUW(HSI23200MBePW150330)",
				"AFJW(HSI22200MBePW150330)",
				"AQIW(HSI25600MBeCW150330)",
				"AFLW(HSI24600MBeCW150330)",
				"APTW(HSI24000MBeCW150330)",
				"APWW(HSI23800MBePW150330)",
				"APYW(HSI24400MBePW150330)",
				"APRW(HSI25000MBeCW150330)",
				"AILW(ChinaA508000MBePW150330)",
				"AIMW(ChinaA508600MBeCW150330)",
				"AIWW(ChinaA509600MBeCW150330)",
				"AIXW(ChinaA509000MBePW150330)",
				"AGQW(ChinaA507800MBeCW150330)",
				"AGRW(ChinaA507200MBePW150330)"
			],
			"related_warrant": "UU2W(KepCorp MBePW150330);APUW(HSI23200MBePW150330);AFJW(HSI22200MBePW150330);AQIW(HSI25600MBeCW150330);AFLW(HSI24600MBeCW150330);APTW(HSI24000MBeCW150330);APWW(HSI23800MBePW150330);APYW(HSI24400MBePW150330);APRW(HSI25000MBeCW150330);AILW(ChinaA508000MBePW150330);AIMW(ChinaA508600MBeCW150330);AIWW(ChinaA509600MBeCW150330);AIXW(ChinaA509000MBePW150330);AGQW(ChinaA507800MBeCW150330);AGRW(ChinaA507200MBePW150330)",
			"underlying": "KEPPEL CORP;HANG SENG INDEX;China A50",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "30 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 29 April 2015 (First Notice)",
			"id": 461,
			"tickers": [
				"APQW(HSI25000MBeCW150429)",
				"APXW(HSI24200MBePW150429)",
				"APVW(HSI23600MBePW150429)",
				"APSW(HSI24400MBeCW150429)"
			],
			"related_warrant": "APQW(HSI25000MBeCW150429);APXW(HSI24200MBePW150429);APVW(HSI23600MBePW150429);APSW(HSI24400MBeCW150429)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "27 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 20 April 2015 (First Notice)",
			"id": 457,
			"tickers": [
				"AGNW(DBS MB eCW150420)"
			],
			"related_warrant": "AGNW(DBS MB eCW150420)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "20 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 13 April 2015 (First Notice)",
			"id": 445,
			"tickers": [
				"AGDW(OCBC Bk MBeCW150413)"
			],
			"related_warrant": "AGDW(OCBC Bk MBeCW150413)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "13 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 15 April 2015 (First Notice)",
			"id": 449,
			"tickers": [
				"AGFW(UOB MB eCW150415)"
			],
			"related_warrant": "AGFW(UOB MB eCW150415)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "13 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 13 March 2015 (Third Notice)",
			"id": 453,
			"tickers": [
				"UR3W(NKY 19000MBeCW150313)",
				"UR2W(NKY 17500MBeCW150313)",
				"UR6W(NKY 17000MBePW150313)",
				"UR7W(NKY 18500MBePW150313)",
				"UR0W(NKY 14500MBeCW150313)",
				"UR4W(NKY 14000MBePW150313)",
				"UR1W(NKY 16000MBeCW150313)",
				"UR5W(NKY 15500MBePW150313)"
			],
			"related_warrant": "UR3W(NKY 19000MBeCW150313);UR2W(NKY 17500MBeCW150313);UR6W(NKY 17000MBePW150313);UR7W(NKY 18500MBePW150313);UR0W(NKY 14500MBeCW150313);UR4W(NKY 14000MBePW150313);UR1W(NKY 16000MBeCW150313);UR5W(NKY 15500MBePW150313)",
			"underlying": "NIKKEI INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI INDEX"
				}
			],
			"date": "13 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 3 March 2015 (Third Notice)",
			"id": 441,
			"tickers": [
				"UL9W(KepCorp MBeCW150303)",
				"UL1W(GentingSMBePW150303)",
				"ACNW(Wilmar MBePW150303)"
			],
			"related_warrant": "UL9W(KepCorp MBeCW150303);UL1W(GentingSMBePW150303);ACNW(Wilmar MBePW150303)",
			"underlying": "KEPPEL CORP;GENTING SPORE;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "03 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 2 March 2015 (Third Notice)",
			"id": 433,
			"tickers": [
				"ABWW(BiosensoMBeCW150302)",
				"UT4W(DBS MB eCW150302)",
				"ACBW(Ezra MBeCW150302)",
				"UL0W(GentingSMBeCW150302)",
				"ACFW(NOL MB eCW150302)",
				"U8QW(OCBC Bk MBePW150302)",
				"U8RW(OCBC Bk MBeCW150302)",
				"U8LW(SembMar MBePW150302)",
				"U8MW(SembMar MBeCW150302)",
				"UC6W(SGX MB eCW150302)",
				"UT3W(UOB MB eCW150302)",
				"U9BW(Wilmar MBePW150302)",
				"ACOW(Wilmar MBeCW150302)",
				"ACMW(Yanlord MBeCW150302)",
				"ABVW(CapitalaMBePW150302)",
				"UK6W(KepCorp MBePW150302)",
				"ACGW(NobleGrpMBePW150302)"
			],
			"related_warrant": "ABWW(BiosensoMBeCW150302);UT4W(DBS MB eCW150302);ACBW(Ezra MBeCW150302);UL0W(GentingSMBeCW150302);ACFW(NOL MB eCW150302);U8QW(OCBC Bk MBePW150302);U8RW(OCBC Bk MBeCW150302);U8LW(SembMar MBePW150302);U8MW(SembMar MBeCW150302);UC6W(SGX MB eCW150302);UT3W(UOB MB eCW150302);U9BW(Wilmar MBePW150302);ACOW(Wilmar MBeCW150302);ACMW(Yanlord MBeCW150302);ABVW(CapitalaMBePW150302);UK6W(KepCorp MBePW150302);ACGW(NobleGrpMBePW150302)",
			"underlying": "BIOSENSORS;DBS GRP HLDGS;EZRA HLDGS;GENTING SPORE;NEPTUNE OL LTD;OCBC;SEMBCORP MARINE;SGX;UOB LTD;WILMAR INTL;YANLORD LAND;CAPITALAND;KEPPEL CORP;NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "02 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 2 April 2015 (First Notice)",
			"id": 437,
			"tickers": [
				"AAOW(ComfortDeMBeCW150402)",
				"AACW(NobleGrpMBeCW150402)",
				"AAAW(SingPost MBeCW150402)",
				"AGTW(DBS MB ePW150402)"
			],
			"related_warrant": "AAOW(ComfortDeMBeCW150402);AACW(NobleGrpMBeCW150402);AAAW(SingPost MBeCW150402);AGTW(DBS MB ePW150402)",
			"underlying": "COMFORTDELGRO;NOBLE GROUP;SINGAPORE POST;DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COMFORTDELGRO"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "02 Mar 15"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2015 (Third Notice)",
			"id": 421,
			"tickers": [
				"AGBW(STI 3150MBePW150227)",
				"UU0W(STI 3050MBePW150227)",
				"UT9W(STI 3200MBeCW150227)"
			],
			"related_warrant": "AGBW(STI 3150MBePW150227);UU0W(STI 3050MBePW150227);UT9W(STI 3200MBeCW150227)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "27 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 30 March 2015 (First Notice)",
			"id": 425,
			"tickers": [
				"AILW(ChinaA508000MBePW150330)",
				"AIMW(ChinaA508600MBeCW150330)",
				"AIWW(ChinaA509600MBeCW150330)",
				"AIXW(ChinaA509000MBePW150330)",
				"AGQW(ChinaA507800MBeCW150330)",
				"AGRW(ChinaA507200MBePW150330)",
				"APYW(HSI24400MBePW150330)",
				"AFLW(HSI24600MBeCW150330)",
				"APTW(HSI24000MBeCW150330)",
				"APRW(HSI25000MBeCW150330)",
				"APUW(HSI23200MBePW150330)",
				"APWW(HSI23800MBePW150330)",
				"AQIW(HSI25600MBeCW150330)",
				"AFJW(HSI22200MBePW150330)",
				"UU2W(KepCorp MBePW150330)"
			],
			"related_warrant": "AILW(ChinaA508000MBePW150330);AIMW(ChinaA508600MBeCW150330);AIWW(ChinaA509600MBeCW150330);AIXW(ChinaA509000MBePW150330);AGQW(ChinaA507800MBeCW150330);AGRW(ChinaA507200MBePW150330);APYW(HSI24400MBePW150330);AFLW(HSI24600MBeCW150330);APTW(HSI24000MBeCW150330);APRW(HSI25000MBeCW150330);APUW(HSI23200MBePW150330);APWW(HSI23800MBePW150330);AQIW(HSI25600MBeCW150330);AFJW(HSI22200MBePW150330);UU2W(KepCorp MBePW150330)",
			"underlying": "China A50;HANG SENG INDEX;KEPPEL CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				}
			],
			"date": "27 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 1 April 2015 (First Notice)",
			"id": 429,
			"tickers": [
				"UU1W(KepCorp MBeCW150401)",
				"U8CW(NOL MB eCW150401)",
				"U8TW(NobleGrpMBeCW150401)",
				"AFTW(DBS MB ePW150401)",
				"U8PW(OCBC Bk MBePW150401)",
				"UU5W(Wilmar MBePW150401)"
			],
			"related_warrant": "UU1W(KepCorp MBeCW150401);U8CW(NOL MB eCW150401);U8TW(NobleGrpMBeCW150401);AFTW(DBS MB ePW150401);U8PW(OCBC Bk MBePW150401);UU5W(Wilmar MBePW150401)",
			"underlying": "KEPPEL CORP;NEPTUNE OL LTD;NOBLE GROUP;DBS GRP HLDGS;OCBC;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "27 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 26 February 2015 (Third Notice)",
			"id": 417,
			"tickers": [
				"ANZW(HSI23400MBePW150226)",
				"AQHW(HSI24000MBePW150226)",
				"AQJW(HSI25400MBeCW150226)",
				"AJTW(HSI23000MBeCW150226)",
				"AJVW(HSI22800MBePW150226)",
				"AJUW(HSI22400MBeCW150226)",
				"AJWW(HSI22200MBePW150226)",
				"ALIW(HSI23600MBeCW150226)",
				"AOAW(HSI24200MBeCW150226)"
			],
			"related_warrant": "ANZW(HSI23400MBePW150226);AQHW(HSI24000MBePW150226);AQJW(HSI25400MBeCW150226);AJTW(HSI23000MBeCW150226);AJVW(HSI22800MBePW150226);AJUW(HSI22400MBeCW150226);AJWW(HSI22200MBePW150226);ALIW(HSI23600MBeCW150226);AOAW(HSI24200MBeCW150226)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "26 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 26 February 2015 (First Notice)",
			"id": 413,
			"tickers": [
				"AQHW(HSI24000MBePW150226)",
				"AQJW(HSI25400MBeCW150226)"
			],
			"related_warrant": "AQHW(HSI24000MBePW150226);AQJW(HSI25400MBeCW150226)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "17 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 13 March 2015 (First Notice)",
			"id": 409,
			"tickers": [
				"UR3W(NKY 19000MBeCW150313)",
				"UR2W(NKY 17500MBeCW150313)",
				"UR6W(NKY 17000MBePW150313)",
				"UR7W(NKY 18500MBePW150313)",
				"UR0W(NKY 14500MBeCW150313)",
				"UR4W(NKY 14000MBePW150313)",
				"UR1W(NKY 16000MBeCW150313)",
				"UR5W(NKY 15500MBePW150313)"
			],
			"related_warrant": "UR3W(NKY 19000MBeCW150313);UR2W(NKY 17500MBeCW150313);UR6W(NKY 17000MBePW150313);UR7W(NKY 18500MBePW150313);UR0W(NKY 14500MBeCW150313);UR4W(NKY 14000MBePW150313);UR1W(NKY 16000MBeCW150313);UR5W(NKY 15500MBePW150313)",
			"underlying": "NIKKEI INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI INDEX"
				}
			],
			"date": "13 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 3 March 2015 (First Notice)",
			"id": 401,
			"tickers": [
				"UL9W(KepCorp MBeCW150303)",
				"UL1W(GentingSMBePW150303)",
				"ACNW(Wilmar MBePW150303)"
			],
			"related_warrant": "UL9W(KepCorp MBeCW150303);UL1W(GentingSMBePW150303);ACNW(Wilmar MBePW150303)",
			"underlying": "KEPPEL CORP;GENTING SPORE;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "03 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 3 February 2015 (Third Notice)",
			"id": 405,
			"tickers": [
				"U7VW(CapitalaMBePW150203)",
				"UM0W(UOB MB ePW150203)"
			],
			"related_warrant": "U7VW(CapitalaMBePW150203);UM0W(UOB MB ePW150203)",
			"underlying": "CAPITALAND;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 2 March 2015 (First Notice)",
			"id": 393,
			"tickers": [
				"ABWW(BiosensoMBeCW150302)",
				"UT4W(DBS MB eCW150302)",
				"ACBW(Ezra MBeCW150302)",
				"UL0W(GentingSMBeCW150302)",
				"ACFW(NOL MB eCW150302)",
				"U8QW(OCBC Bk MBePW150302)",
				"U8RW(OCBC Bk MBeCW150302)",
				"U8LW(SembMar MBePW150302)",
				"U8MW(SembMar MBeCW150302)",
				"UC6W(SGX MB eCW150302)",
				"UT3W(UOB MB eCW150302)",
				"U9BW(Wilmar MBePW150302)",
				"ACOW(Wilmar MBeCW150302)",
				"ACMW(Yanlord MBeCW150302)",
				"ABVW(CapitalaMBePW150302)",
				"UL9W(KepCorp MBeCW150303)",
				"ACGW(NobleGrpMBePW150302)"
			],
			"related_warrant": "ABWW(BiosensoMBeCW150302);UT4W(DBS MB eCW150302);ACBW(Ezra MBeCW150302);UL0W(GentingSMBeCW150302);ACFW(NOL MB eCW150302);U8QW(OCBC Bk MBePW150302);U8RW(OCBC Bk MBeCW150302);U8LW(SembMar MBePW150302);U8MW(SembMar MBeCW150302);UC6W(SGX MB eCW150302);UT3W(UOB MB eCW150302);U9BW(Wilmar MBePW150302);ACOW(Wilmar MBeCW150302);ACMW(Yanlord MBeCW150302);ABVW(CapitalaMBePW150302);UL9W(KepCorp MBeCW150303);ACGW(NobleGrpMBePW150302)",
			"underlying": "BIOSENSORS;DBS GRP HLDGS;EZRA HLDGS;GENTING SPORE;NEPTUNE OL LTD;OCBC;SEMBCORP MARINE;SGX;UOB LTD;WILMAR INTL;YANLORD LAND;CAPITALAND;KEPPEL CORP;NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "02 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 2 February 2015 (Third Notice)",
			"id": 397,
			"tickers": [
				"ABXW(BiosensoMBeCW150202)",
				"U7WW(CapitalaMBeCW150202)",
				"UB9W(CoscoCorMBeCW150202)",
				"U8BW(Ezion MBeCW150202)",
				"U7XW(Ezra MBeCW150202)",
				"U8FW(GentingSMBeCW150202)",
				"ACEW(NOL MB eCW150202)",
				"U8UW(NobleGrpMBeCW150202)",
				"U4UW(NobleGrpMBePW150202)",
				"ACLW(OCBC Bk MBeCW150202)",
				"U7PW(POSHMBeCW150202)",
				"ACKW(SembMar MBeCW150202)",
				"ACJW(SembMar MBePW150202)",
				"U4QW(SingTelMBePW150202)",
				"U4SW(SingTelMBeCW150202)",
				"UK7W(DBS MB ePW150202)",
				"ACIW(SGX MB ePW150202)"
			],
			"related_warrant": "ABXW(BiosensoMBeCW150202);U7WW(CapitalaMBeCW150202);UB9W(CoscoCorMBeCW150202);U8BW(Ezion MBeCW150202);U7XW(Ezra MBeCW150202);U8FW(GentingSMBeCW150202);ACEW(NOL MB eCW150202);U8UW(NobleGrpMBeCW150202);U4UW(NobleGrpMBePW150202);ACLW(OCBC Bk MBeCW150202);U7PW(POSHMBeCW150202);ACKW(SembMar MBeCW150202);ACJW(SembMar MBePW150202);U4QW(SingTelMBePW150202);U4SW(SingTelMBeCW150202);UK7W(DBS MB ePW150202);ACIW(SGX MB ePW150202)",
			"underlying": "BIOSENSORS;CAPITALAND;COSCO CORP;EZION HOLDINGS;EZRA HLDGS;GENTING SPORE;NEPTUNE OL LTD;NOBLE GROUP;OCBC;PACC OFFSHORE;SEMBCORP MARINE;SINGTEL;DBS GRP HLDGS;SGX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "PACC OFFSHORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "02 Feb 15"
		},
		{
			"headline": "Expiry of Warrants on 29 January 2015 (Third Notice)",
			"id": 389,
			"tickers": [
				"AJNW(HSI22600MBePW150129)",
				"AHAW(HSI24000MBeCW150129)",
				"AHBW(HSI23800MBePW150129)",
				"AGZW(HSI23200MBePW150129)",
				"AHCW(HSI23400MBeCW150129)",
				"AIKW(HSI24600MBeCW150129)",
				"UK4W(ChinaA507600MBePW150129)",
				"UK3W(ChinaA508200MBeCW150129)"
			],
			"related_warrant": "AJNW(HSI22600MBePW150129);AHAW(HSI24000MBeCW150129);AHBW(HSI23800MBePW150129);AGZW(HSI23200MBePW150129);AHCW(HSI23400MBeCW150129);AIKW(HSI24600MBeCW150129);UK4W(ChinaA507600MBePW150129);UK3W(ChinaA508200MBeCW150129)",
			"underlying": "HANG SENG INDEX;China A50",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 27 February 2015 (First Notice)",
			"id": 385,
			"tickers": [
				"AGBW(STI 3150MBePW150227)",
				"UU0W(STI 3050MBePW150227)",
				"UT9W(STI 3200MBeCW150227)"
			],
			"related_warrant": "AGBW(STI 3150MBePW150227);UU0W(STI 3050MBePW150227);UT9W(STI 3200MBeCW150227)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "27 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 26 February 2015 (First Notice)",
			"id": 381,
			"tickers": [
				"AJWW(HSI22200MBePW150226)",
				"AOAW(HSI24200MBeCW150226)",
				"AJUW(HSI22400MBeCW150226)",
				"AJVW(HSI22800MBePW150226)",
				"AJTW(HSI23000MBeCW150226)",
				"ALIW(HSI23600MBeCW150226)",
				"ANZW(HSI23400MBePW150226)"
			],
			"related_warrant": "AJWW(HSI22200MBePW150226);AOAW(HSI24200MBeCW150226);AJUW(HSI22400MBeCW150226);AJVW(HSI22800MBePW150226);AJTW(HSI23000MBeCW150226);ALIW(HSI23600MBeCW150226);ANZW(HSI23400MBePW150226)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "26 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 19 January 2015 (Third Notice)",
			"id": 377,
			"tickers": [
				"SW1W(SingTelMBeCW150119)"
			],
			"related_warrant": "SW1W(SingTelMBeCW150119)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "19 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 7 January 2015 (Third Notice)",
			"id": 373,
			"tickers": [
				"U8SW(OCBC Bk MBeCW150107)"
			],
			"related_warrant": "U8SW(OCBC Bk MBeCW150107)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "07 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 6 January 2015 (Third Notice)",
			"id": 369,
			"tickers": [
				"AABW(SingPost MBeCW150106)",
				"UC4W(DBS MB eCW150106)"
			],
			"related_warrant": "AABW(SingPost MBeCW150106);UC4W(DBS MB eCW150106)",
			"underlying": "SINGAPORE POST;DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "06 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 5 January 2015 (Third Notice)",
			"id": 365,
			"tickers": [
				"U4KW(AscendasRMBePW150105)",
				"U4MW(GLP MB ePW150105)",
				"U4VW(NobleGrpMBeCW150105)",
				"U4TW(NobleGrpMBePW150105)",
				"U4XW(OCBC Bk MBeCW150105)",
				"U8NW(SGX MB ePW150105)",
				"U8OW(SGX MB eCW150105)",
				"U4PW(SingTelMBePW150105)",
				"U4RW(SingTelMBeCW150105)",
				"U5EW(YangzijiMBeCW150105)"
			],
			"related_warrant": "U4KW(AscendasRMBePW150105);U4MW(GLP MB ePW150105);U4VW(NobleGrpMBeCW150105);U4TW(NobleGrpMBePW150105);U4XW(OCBC Bk MBeCW150105);U8NW(SGX MB ePW150105);U8OW(SGX MB eCW150105);U4PW(SingTelMBePW150105);U4RW(SingTelMBeCW150105);U5EW(YangzijiMBeCW150105)",
			"underlying": "ASCEN REIT;GLOBAL LOG PROP;NOBLE GROUP;OCBC;SGX;SINGTEL;YANGZIJIANG",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "05 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 2 January 2015 (Third Notice)",
			"id": 353,
			"tickers": [
				"ST8W(BiosensoMBeCW150102)",
				"U7UW(CapitalaMBePW150102)",
				"SR0W(CapitalaMBeCW150102)",
				"SW4W(ComfortDeMBeCW150102)",
				"SR8W(CoscoCorMBeCW150102)",
				"SQ5W(DBS MB eCW150102)",
				"SR9W(Ezion MBeCW150102)",
				"SS0W(Ezra MBeCW150102)",
				"ST7W(GentingSMBeCW150102)",
				"U8GW(GentingSMBePW150102)",
				"SR2W(GLP MB eCW150102)",
				"U8HW(GLP MB ePW150102)",
				"SV0W(IndoAgriMBeCW150102)",
				"SS1W(KepCorp MBeCW150102)",
				"SU0W(NOL MB eCW150102)",
				"SU9W(NobleGrpMBeCW150102)",
				"SU8W(Olam MBeCW150102)",
				"SQ6W(OCBC Bk MBeCW150102)",
				"U7OW(POSHMBeCW150102)",
				"SS2W(SembMar MBeCW150102)",
				"SQ7W(SGX MB eCW150102)",
				"ACHW(SGX MB ePW150102)",
				"SW3W(SPH MB eCW150102)",
				"ADOW(SMRT MBeCW150102)",
				"SW0W(StarHubMBeCW150102)",
				"SQ8W(UOB MB eCW150102)",
				"SS3W(VardHldgsMBeCW150102)",
				"SU7W(Wilmar MBeCW150102)",
				"SU1W(YangzijiMBeCW150102)",
				"SR3W(Yanlord MBeCW150102)",
				"SW2W(ST Engg MBeCW150102)"
			],
			"related_warrant": "ST8W(BiosensoMBeCW150102);U7UW(CapitalaMBePW150102);SR0W(CapitalaMBeCW150102);SW4W(ComfortDeMBeCW150102);SR8W(CoscoCorMBeCW150102);SQ5W(DBS MB eCW150102);SR9W(Ezion MBeCW150102);SS0W(Ezra MBeCW150102);ST7W(GentingSMBeCW150102);U8GW(GentingSMBePW150102);SR2W(GLP MB eCW150102);U8HW(GLP MB ePW150102);SV0W(IndoAgriMBeCW150102);SS1W(KepCorp MBeCW150102);SU0W(NOL MB eCW150102);SU9W(NobleGrpMBeCW150102);SU8W(Olam MBeCW150102);SQ6W(OCBC Bk MBeCW150102);U7OW(POSHMBeCW150102);SS2W(SembMar MBeCW150102);SQ7W(SGX MB eCW150102);ACHW(SGX MB ePW150102);SW3W(SPH MB eCW150102);ADOW(SMRT MBeCW150102);SW0W(StarHubMBeCW150102);SQ8W(UOB MB eCW150102);SS3W(VardHldgsMBeCW150102);SU7W(Wilmar MBeCW150102);SU1W(YangzijiMBeCW150102);SR3W(Yanlord MBeCW150102);SW2W(ST Engg MBeCW150102)",
			"underlying": "BIOSENSORS;CAPITALAND;COMFORTDELGRO;COSCO CORP;DBS GRP HLDGS;EZION HOLDINGS;EZRA HLDGS;GENTING SPORE;GLOBAL LOG PROP;INDOFOOD AGRI;KEPPEL CORP;NEPTUNE OL LTD;NOBLE GROUP;OLAM INTER;OCBC;PACC OFFSHORE;SEMBCORP MARINE;SGX;SPORE PRESS HLDG;SMRT CORP;STARHUB;UOB LTD;VARD HOLDINGS;WILMAR INTL;YANGZIJIANG;YANLORD LAND;ST ENGRG",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COMFORTDELGRO"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "INDOFOOD AGRI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "PACC OFFSHORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SMRT CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SPORE PRESS HLDG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "ST ENGRG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STARHUB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "02 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 2 February 2015 (First Notice)",
			"id": 357,
			"tickers": [
				"ABXW(BiosensoMBeCW150202)",
				"U7WW(CapitalaMBeCW150202)",
				"UB9W(CoscoCorMBeCW150202)",
				"UK7W(DBS MB ePW150202)",
				"U8BW(Ezion MBeCW150202)",
				"U7XW(Ezra MBeCW150202)",
				"U8FW(GentingSMBeCW150202)",
				"ACEW(NOL MB eCW150202)",
				"U8UW(NobleGrpMBeCW150202)",
				"U4UW(NobleGrpMBePW150202)",
				"ACLW(OCBC Bk MBeCW150202)",
				"U7PW(POSHMBeCW150202)",
				"ACJW(SembMar MBePW150202)",
				"ACKW(SembMar MBeCW150202)",
				"ACIW(SGX MB ePW150202)",
				"U4QW(SingTelMBePW150202)",
				"U4SW(SingTelMBeCW150202)"
			],
			"related_warrant": "ABXW(BiosensoMBeCW150202);U7WW(CapitalaMBeCW150202);UB9W(CoscoCorMBeCW150202);UK7W(DBS MB ePW150202);U8BW(Ezion MBeCW150202);U7XW(Ezra MBeCW150202);U8FW(GentingSMBeCW150202);ACEW(NOL MB eCW150202);U8UW(NobleGrpMBeCW150202);U4UW(NobleGrpMBePW150202);ACLW(OCBC Bk MBeCW150202);U7PW(POSHMBeCW150202);ACJW(SembMar MBePW150202);ACKW(SembMar MBeCW150202);ACIW(SGX MB ePW150202);U4QW(SingTelMBePW150202);U4SW(SingTelMBeCW150202)",
			"underlying": "BIOSENSORS;CAPITALAND;COSCO CORP;DBS GRP HLDGS;EZION HOLDINGS;EZRA HLDGS;GENTING SPORE;NEPTUNE OL LTD;NOBLE GROUP;OCBC;PACC OFFSHORE;SEMBCORP MARINE;SGX;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "PACC OFFSHORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "02 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 3 February 2015 (First Notice)",
			"id": 361,
			"tickers": [
				"U7VW(CapitalaMBePW150203)",
				"UM0W(UOB MB ePW150203)"
			],
			"related_warrant": "U7VW(CapitalaMBePW150203);UM0W(UOB MB ePW150203)",
			"underlying": "CAPITALAND;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Jan 15"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2014 (Third Notice)",
			"id": 349,
			"tickers": [
				"TP3W(HSI25000MBeCW141230)",
				"TP2W(HSI21000MBePW141230)",
				"AFRW(HSI23600MBePW141230)",
				"UY4W(HSI23800MBeCW141230)",
				"UT8W(HSI23000MBePW141230)",
				"UT7W(HSI22400MBePW141230)",
				"UT5W(HSI22600MBeCW141230)",
				"AFSW(HSI24400MBeCW141230)",
				"UT6W(HSI23200MBeCW141230)"
			],
			"related_warrant": "TP3W(HSI25000MBeCW141230);TP2W(HSI21000MBePW141230);AFRW(HSI23600MBePW141230);UY4W(HSI23800MBeCW141230);UT8W(HSI23000MBePW141230);UT7W(HSI22400MBePW141230);UT5W(HSI22600MBeCW141230);AFSW(HSI24400MBeCW141230);UT6W(HSI23200MBeCW141230)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 29 January 2015 (First Notice)",
			"id": 345,
			"tickers": [
				"UK4W(ChinaA507600MBePW150129)",
				"UK3W(ChinaA508200MBeCW150129)",
				"AJNW(HSI22600MBePW150129)",
				"AHAW(HSI24000MBeCW150129)",
				"AGZW(HSI23200MBePW150129)",
				"AHCW(HSI23400MBeCW150129)",
				"AHBW(HSI23800MBePW150129)",
				"AIKW(HSI24600MBeCW150129)"
			],
			"related_warrant": "UK4W(ChinaA507600MBePW150129);UK3W(ChinaA508200MBeCW150129);AJNW(HSI22600MBePW150129);AHAW(HSI24000MBeCW150129);AGZW(HSI23200MBePW150129);AHCW(HSI23400MBeCW150129);AHBW(HSI23800MBePW150129);AIKW(HSI24600MBeCW150129)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 19 January 2015 (First Notice)",
			"id": 341,
			"tickers": [
				"SW1W(SingTelMBeCW150119)"
			],
			"related_warrant": "SW1W(SingTelMBeCW150119)",
			"underlying": "SINGTEL",
			"underlyings": [
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				}
			],
			"date": "19 Dec 14"
		},
		{
			"headline": "Adjustment Notice",
			"id": 337,
			"tickers": [
				"SS0W(Ezra MBeCW150102)",
				"U7XW(Ezra MBeCW150202)",
				"ACBW(Ezra MBeCW150302)",
				"ADIW(Ezra MBeCW150504)",
				"AIJW(Ezra MBeCW160104)"
			],
			"related_warrant": "SS0W(Ezra MBeCW150102);U7XW(Ezra MBeCW150202);ACBW(Ezra MBeCW150302);ADIW(Ezra MBeCW150504);AIJW(Ezra MBeCW160104)",
			"underlying": "EZRA HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				}
			],
			"date": "15 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 12 December 2014 (Third Notice)",
			"id": 333,
			"tickers": [
				"ADBW(NKY 17000MBeCW141212)",
				"ADEW(NKY 12500MBeCW141212)",
				"ADAW(NKY 13500MBePW141212)",
				"ADDW(NKY 14000MBeCW141212)",
				"ACZW(NKY 15000MBePW141212)",
				"ADCW(NKY 15500MBeCW141212)",
				"ACYW(NKY 16500MBePW141212)"
			],
			"related_warrant": "ADBW(NKY 17000MBeCW141212);ADEW(NKY 12500MBeCW141212);ADAW(NKY 13500MBePW141212);ADDW(NKY 14000MBeCW141212);ACZW(NKY 15000MBePW141212);ADCW(NKY 15500MBeCW141212);ACYW(NKY 16500MBePW141212)",
			"underlying": "NIKKEI INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI INDEX"
				}
			],
			"date": "12 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 5 January 2015 (First Notice)",
			"id": 317,
			"tickers": [
				"U4TW(NobleGrpMBePW150105)",
				"U4VW(NobleGrpMBeCW150105)",
				"U4XW(OCBC Bk MBeCW150105)",
				"U8NW(SGX MB ePW150105)",
				"U8OW(SGX MB eCW150105)",
				"U4RW(SingTelMBeCW150105)",
				"U4PW(SingTelMBePW150105)",
				"U5EW(YangzijiMBeCW150105)",
				"U4KW(AscendasRMBePW150105)",
				"U4MW(GLP MB ePW150105)"
			],
			"related_warrant": "U4TW(NobleGrpMBePW150105);U4VW(NobleGrpMBeCW150105);U4XW(OCBC Bk MBeCW150105);U8NW(SGX MB ePW150105);U8OW(SGX MB eCW150105);U4RW(SingTelMBeCW150105);U4PW(SingTelMBePW150105);U5EW(YangzijiMBeCW150105);U4KW(AscendasRMBePW150105);U4MW(GLP MB ePW150105)",
			"underlying": "NOBLE GROUP;OCBC;SGX;SINGTEL;YANGZIJIANG;ASCEN REIT;GLOBAL LOG PROP",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				}
			],
			"date": "05 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 6 January 2015 (First Notice)",
			"id": 321,
			"tickers": [
				"UC4W(DBS MB eCW150106)",
				"AABW(SingPost MBeCW150106)"
			],
			"related_warrant": "UC4W(DBS MB eCW150106);AABW(SingPost MBeCW150106)",
			"underlying": "DBS GRP HLDGS;SINGAPORE POST",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SINGAPORE POST"
				}
			],
			"date": "05 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 7 January 2015 (First Notice)",
			"id": 325,
			"tickers": [
				"U8SW(OCBC Bk MBeCW150107)"
			],
			"related_warrant": "U8SW(OCBC Bk MBeCW150107)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "05 Dec 14"
		},
		{
			"headline": "Adjustment Notice",
			"id": 329,
			"tickers": [
				"SW3W(SPH MB eCW150102)"
			],
			"related_warrant": "SW3W(SPH MB eCW150102)",
			"underlying": "SPORE PRESS HLDG",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SPORE PRESS HLDG"
				}
			],
			"date": "05 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 3 December 2014 (Third Notice)",
			"id": 301,
			"tickers": [
				"ADFW(UOB MB eCW141203)"
			],
			"related_warrant": "ADFW(UOB MB eCW141203)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2014 (Third Notice)",
			"id": 293,
			"tickers": [
				"AFFW(DBS MB ePW141202)",
				"AFGW(UOB MB ePW141202)"
			],
			"related_warrant": "AFFW(DBS MB ePW141202);AFGW(UOB MB ePW141202)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "02 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 2 January 2014 (First Notice)",
			"id": 297,
			"tickers": [
				"SQ5W(DBS MB eCW150102)",
				"SS0W(Ezra MBeCW150102)",
				"SR9W(Ezion MBeCW150102)",
				"SR2W(GLP MB eCW150102)",
				"U8HW(GLP MB ePW150102)",
				"ST7W(GentingSMBeCW150102)",
				"U8GW(GentingSMBePW150102)",
				"SW0W(StarHubMBeCW150102)",
				"SW2W(ST Engg MBeCW150102)",
				"SW3W(SPH MB eCW150102)",
				"SQ7W(SGX MB eCW150102)",
				"ACHW(SGX MB ePW150102)",
				"SS2W(SembMar MBeCW150102)",
				"SR3W(Yanlord MBeCW150102)",
				"SU1W(YangzijiMBeCW150102)",
				"SR8W(CoscoCorMBeCW150102)",
				"SW4W(ComfortDeMBeCW150102)",
				"SR0W(CapitalaMBeCW150102)",
				"U7UW(CapitalaMBePW150102)",
				"ST8W(BiosensoMBeCW150102)",
				"SS1W(KepCorp MBeCW150102)",
				"SV0W(IndoAgriMBeCW150102)",
				"SU8W(Olam MBeCW150102)",
				"SQ6W(OCBC Bk MBeCW150102)",
				"SU0W(NOL MB eCW150102)",
				"SU9W(NobleGrpMBeCW150102)",
				"SU7W(Wilmar MBeCW150102)",
				"SS3W(VardHldgsMBeCW150102)",
				"SQ8W(UOB MB eCW150102)",
				"U7OW(POSHMBeCW150102)",
				"ADOW(SMRT MBeCW150102)"
			],
			"related_warrant": "SQ5W(DBS MB eCW150102);SS0W(Ezra MBeCW150102);SR9W(Ezion MBeCW150102);SR2W(GLP MB eCW150102);U8HW(GLP MB ePW150102);ST7W(GentingSMBeCW150102);U8GW(GentingSMBePW150102);SW0W(StarHubMBeCW150102);SW2W(ST Engg MBeCW150102);SW3W(SPH MB eCW150102);SQ7W(SGX MB eCW150102);ACHW(SGX MB ePW150102);SS2W(SembMar MBeCW150102);SR3W(Yanlord MBeCW150102);SU1W(YangzijiMBeCW150102);SR8W(CoscoCorMBeCW150102);SW4W(ComfortDeMBeCW150102);SR0W(CapitalaMBeCW150102);U7UW(CapitalaMBePW150102);ST8W(BiosensoMBeCW150102);SS1W(KepCorp MBeCW150102);SV0W(IndoAgriMBeCW150102);SU8W(Olam MBeCW150102);SQ6W(OCBC Bk MBeCW150102);SU0W(NOL MB eCW150102);SU9W(NobleGrpMBeCW150102);SU7W(Wilmar MBeCW150102);SS3W(VardHldgsMBeCW150102);SQ8W(UOB MB eCW150102);U7OW(POSHMBeCW150102);ADOW(SMRT MBeCW150102)",
			"underlying": "DBS GRP HLDGS;EZRA HLDGS;EZION HOLDINGS;GLOBAL LOG PROP;GENTING SPORE;STARHUB;ST ENGRG;SPORE PRESS HLDG;SGX;SEMBCORP MARINE;YANLORD LAND;YANGZIJIANG;COSCO CORP;COMFORTDELGRO;CAPITALAND;BIOSENSORS;KEPPEL CORP;INDOFOOD AGRI;OLAM INTER;OCBC;NEPTUNE OL LTD;NOBLE GROUP;WILMAR INTL;VARD HOLDINGS;UOB LTD;PACC OFFSHORE;SMRT CORP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COMFORTDELGRO"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "INDOFOOD AGRI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "PACC OFFSHORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SMRT CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SPORE PRESS HLDG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "ST ENGRG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STARHUB"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "02 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 1 December 2014 (Third Notice)",
			"id": 289,
			"tickers": [
				"ABZW(DBS MB eCW141201)",
				"U8DW(KepCorp MBePW141201)",
				"U8JW(KepCorp MBeCW141201)",
				"TP8W(SGX MB eCW141201)",
				"ACQW(UOB MB eCW141201)"
			],
			"related_warrant": "ABZW(DBS MB eCW141201);U8DW(KepCorp MBePW141201);U8JW(KepCorp MBeCW141201);TP8W(SGX MB eCW141201);ACQW(UOB MB eCW141201)",
			"underlying": "DBS GRP HLDGS;KEPPEL CORP;SGX;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "01 Dec 14"
		},
		{
			"headline": "Expiry of Warrants on 31 December 2014 (First Notice)",
			"id": 281,
			"tickers": [
				"T3XW(STI 3450MBeCW141231)",
				"T3WW(STI 3150MBePW141231)",
				"UM3W(STI 3300MBeCW141231)"
			],
			"related_warrant": "T3XW(STI 3450MBeCW141231);T3WW(STI 3150MBePW141231);UM3W(STI 3300MBeCW141231)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "28 Nov 14"
		},
		{
			"headline": "Expiry of Warrants on 30 December 2014 (First Notice)",
			"id": 285,
			"tickers": [
				"UT7W(HSI22400MBePW141230)",
				"UT8W(HSI23000MBePW141230)",
				"AFRW(HSI23600MBePW141230)",
				"UY4W(HSI23800MBeCW141230)",
				"UT6W(HSI23200MBeCW141230)",
				"UT5W(HSI22600MBeCW141230)",
				"AFSW(HSI24400MBeCW141230)",
				"TP2W(HSI21000MBePW141230)",
				"TP3W(HSI25000MBeCW141230)"
			],
			"related_warrant": "UT7W(HSI22400MBePW141230);UT8W(HSI23000MBePW141230);AFRW(HSI23600MBePW141230);UY4W(HSI23800MBeCW141230);UT6W(HSI23200MBeCW141230);UT5W(HSI22600MBeCW141230);AFSW(HSI24400MBeCW141230);TP2W(HSI21000MBePW141230);TP3W(HSI25000MBeCW141230)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Nov 14"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2014 (Third Notice)",
			"id": 277,
			"tickers": [
				"UK0W(HSI25000MBePW141127)",
				"UJ6W(HSI24800MBeCW141127)",
				"UJ7W(HSI25200MBeCW141127)",
				"UK1W(HSI25400MBePW141127)",
				"UQ0W(HSI23400MBePW141127)",
				"UP9W(HSI23600MBeCW141127)",
				"UQ5W(HSI23000MBeCW141127)",
				"UJ9W(HSI24600MBePW141127)",
				"UL8W(HSI24000MBePW141127)",
				"UP6W(HSI24200MBeCW141127)",
				"UJ8W(HSI25600MBeCW141127)",
				"UQ6W(HSI22800MBePW141127)",
				"U9DW(ChinaA507000MBePW141127)",
				"U9CW(ChinaA507600MBeCW141127)"
			],
			"related_warrant": "UK0W(HSI25000MBePW141127);UJ6W(HSI24800MBeCW141127);UJ7W(HSI25200MBeCW141127);UK1W(HSI25400MBePW141127);UQ0W(HSI23400MBePW141127);UP9W(HSI23600MBeCW141127);UQ5W(HSI23000MBeCW141127);UJ9W(HSI24600MBePW141127);UL8W(HSI24000MBePW141127);UP6W(HSI24200MBeCW141127);UJ8W(HSI25600MBeCW141127);UQ6W(HSI22800MBePW141127);U9DW(ChinaA507000MBePW141127);U9CW(ChinaA507600MBeCW141127)",
			"underlying": "HANG SENG INDEX;China A50",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "27 Nov 14"
		},
		{
			"headline": "Adjustment Notice",
			"id": 269,
			"tickers": [
				"SU9W(NobleGrpMBeCW150102)",
				"U4TW(NobleGrpMBePW150105)",
				"U4VW(NobleGrpMBeCW150105)",
				"U8UW(NobleGrpMBeCW150202)",
				"U4UW(NobleGrpMBePW150202)",
				"ACGW(NobleGrpMBePW150302)",
				"U8TW(NobleGrpMBeCW150401)",
				"AACW(NobleGrpMBeCW150402)"
			],
			"related_warrant": "SU9W(NobleGrpMBeCW150102);U4TW(NobleGrpMBePW150105);U4VW(NobleGrpMBeCW150105);U8UW(NobleGrpMBeCW150202);U4UW(NobleGrpMBePW150202);ACGW(NobleGrpMBePW150302);U8TW(NobleGrpMBeCW150401);AACW(NobleGrpMBeCW150402)",
			"underlying": "NOBLE GROUP",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				}
			],
			"date": "17 Nov 14"
		},
		{
			"headline": "Expiry of Warrants on 12 December 2014 (First Notice)",
			"id": 265,
			"tickers": [
				"ADBW(NKY 17000MBeCW141212)",
				"ADEW(NKY 12500MBeCW141212)",
				"ADAW(NKY 13500MBePW141212)",
				"ADDW(NKY 14000MBeCW141212)",
				"ACZW(NKY 15000MBePW141212)",
				"ADCW(NKY 15500MBeCW141212)",
				"ACYW(NKY 16500MBePW141212)"
			],
			"related_warrant": "ADBW(NKY 17000MBeCW141212);ADEW(NKY 12500MBeCW141212);ADAW(NKY 13500MBePW141212);ADDW(NKY 14000MBeCW141212);ACZW(NKY 15000MBePW141212);ADCW(NKY 15500MBeCW141212);ACYW(NKY 16500MBePW141212)",
			"underlying": "NIKKEI INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI INDEX"
				}
			],
			"date": "12 Nov 14"
		},
		{
			"headline": "Expiry of Warrants on 04 November 2014 ( Third Notice)",
			"id": 261,
			"tickers": [
				"ACDW(KepCorp MBePW141104)",
				"ACPW(UOB MB ePW141104)"
			],
			"related_warrant": "ACDW(KepCorp MBePW141104);ACPW(UOB MB ePW141104)",
			"underlying": "KEPPEL CORP;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Nov 14"
		},
		{
			"headline": "Expiry of Warrants on 3 December 2014 (First Notice)",
			"id": 249,
			"tickers": [
				"ADFW(UOB MB eCW141203)"
			],
			"related_warrant": "ADFW(UOB MB eCW141203)",
			"underlying": "UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Nov 14"
		},
		{
			"headline": "Expiry of Warrants on 03 November 2014 (Third Notice)",
			"id": 253,
			"tickers": [
				"U0EW(AscendasRMBeCW141103)",
				"ABYW(DBS MB ePW141103)",
				"ACAW(DBS MB eCW141103)",
				"TI4W(Ezion MBeCW141103)",
				"U8AW(Ezion MBeCW141103A)",
				"TQ2W(IndoAgriMBeCW141103)",
				"ACCW(KepCorp MBeCW141103)",
				"U8KW(KepCorp MBePW141103)",
				"TK8W(Olam MBeCW141103)",
				"AAHW(UOB MB ePW141103)",
				"ACRW(UOB MB eCW141103)",
				"TL0W(VardHldgsMBeCW141103)",
				"TY2W(CapitalaMBePW141103)",
				"U4BW(SembMar MBePW141103)"
			],
			"related_warrant": "U0EW(AscendasRMBeCW141103);ABYW(DBS MB ePW141103);ACAW(DBS MB eCW141103);TI4W(Ezion MBeCW141103);U8AW(Ezion MBeCW141103A);TQ2W(IndoAgriMBeCW141103);ACCW(KepCorp MBeCW141103);U8KW(KepCorp MBePW141103);TK8W(Olam MBeCW141103);AAHW(UOB MB ePW141103);ACRW(UOB MB eCW141103);TL0W(VardHldgsMBeCW141103);TY2W(CapitalaMBePW141103);U4BW(SembMar MBePW141103)",
			"underlying": "ASCEN REIT;DBS GRP HLDGS;EZION HOLDINGS;INDOFOOD AGRI;KEPPEL CORP;OLAM INTER;UOB LTD;VARD HOLDINGS;CAPITALAND;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "INDOFOOD AGRI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				}
			],
			"date": "03 Nov 14"
		},
		{
			"headline": "Adjustment Notice",
			"id": 257,
			"tickers": [
				"SU8W(Olam MBeCW150102)"
			],
			"related_warrant": "SU8W(Olam MBeCW150102)",
			"underlying": "OLAM INTER",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				}
			],
			"date": "03 Nov 14"
		},
		{
			"headline": "Expiry of Warrants on 1 December 2014 (First Notice)",
			"id": 237,
			"tickers": [
				"ABZW(DBS MB eCW141201)",
				"U8JW(KepCorp MBeCW141201)",
				"U8DW(KepCorp MBePW141201)",
				"TP8W(SGX MB eCW141201)",
				"ACQW(UOB MB eCW141201)"
			],
			"related_warrant": "ABZW(DBS MB eCW141201);U8JW(KepCorp MBeCW141201);U8DW(KepCorp MBePW141201);TP8W(SGX MB eCW141201);ACQW(UOB MB eCW141201)",
			"underlying": "DBS GRP HLDGS;KEPPEL CORP;SGX;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "31 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 2 December 2014 (First Notice)",
			"id": 241,
			"tickers": [
				"AFFW(DBS MB ePW141202)",
				"AFGW(UOB MB ePW141202)"
			],
			"related_warrant": "AFFW(DBS MB ePW141202);AFGW(UOB MB ePW141202)",
			"underlying": "DBS GRP HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "31 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 31 October 2014 (Third Notice)",
			"id": 245,
			"tickers": [
				"U5CW(STI 3350MBePW141031)",
				"U4LW(STI 3300MBeCW141031)"
			],
			"related_warrant": "U5CW(STI 3350MBePW141031);U4LW(STI 3300MBeCW141031)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "31 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2014 (Third Notice)",
			"id": 233,
			"tickers": [
				"UL7W(HSI23800MBePW141030)",
				"UI3W(HSI25200MBeCW141030)",
				"UI1W(HSI25000MBePW141030)",
				"UI2W(HSI24600MBeCW141030)",
				"UI5W(HSI24400MBePW141030)",
				"UI4W(HSI25800MBeCW141030)",
				"UP7W(HSI24000MBeCW141030)"
			],
			"related_warrant": "UL7W(HSI23800MBePW141030);UI3W(HSI25200MBeCW141030);UI1W(HSI25000MBePW141030);UI2W(HSI24600MBeCW141030);UI5W(HSI24400MBePW141030);UI4W(HSI25800MBeCW141030);UP7W(HSI24000MBeCW141030)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 27 November 2014 (First Notice)",
			"id": 229,
			"tickers": [
				"U9DW(ChinaA507000MBePW141127)",
				"U9CW(ChinaA507600MBeCW141127)",
				"UJ9W(HSI24600MBePW141127)",
				"UK1W(HSI25400MBePW141127)",
				"UQ6W(HSI22800MBePW141127)",
				"UQ5W(HSI23000MBeCW141127)",
				"UL8W(HSI24000MBePW141127)",
				"UP6W(HSI24200MBeCW141127)",
				"UP9W(HSI23600MBeCW141127)",
				"UQ0W(HSI23400MBePW141127)",
				"UJ8W(HSI25600MBeCW141127)",
				"UJ6W(HSI24800MBeCW141127)",
				"UK0W(HSI25000MBePW141127)",
				"UJ7W(HSI25200MBeCW141127)"
			],
			"related_warrant": "U9DW(ChinaA507000MBePW141127);U9CW(ChinaA507600MBeCW141127);UJ9W(HSI24600MBePW141127);UK1W(HSI25400MBePW141127);UQ6W(HSI22800MBePW141127);UQ5W(HSI23000MBeCW141127);UL8W(HSI24000MBePW141127);UP6W(HSI24200MBeCW141127);UP9W(HSI23600MBeCW141127);UQ0W(HSI23400MBePW141127);UJ8W(HSI25600MBeCW141127);UJ6W(HSI24800MBeCW141127);UK0W(HSI25000MBePW141127);UJ7W(HSI25200MBeCW141127)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "27 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 3 November 2014 (First Notice)",
			"id": 221,
			"tickers": [
				"U0EW(AscendasRMBeCW141103)",
				"ABYW(DBS MB ePW141103)",
				"ACAW(DBS MB eCW141103)",
				"TI4W(Ezion MBeCW141103)",
				"U8AW(Ezion MBeCW141103A)",
				"TQ2W(IndoAgriMBeCW141103)",
				"ACCW(KepCorp MBeCW141103)",
				"U8KW(KepCorp MBePW141103)",
				"TK8W(Olam MBeCW141103)",
				"AAHW(UOB MB ePW141103)",
				"ACRW(UOB MB eCW141103)",
				"TL0W(VardHldgsMBeCW141103)",
				"TY2W(CapitalaMBePW141103)",
				"U4BW(SembMar MBePW141103)"
			],
			"related_warrant": "U0EW(AscendasRMBeCW141103);ABYW(DBS MB ePW141103);ACAW(DBS MB eCW141103);TI4W(Ezion MBeCW141103);U8AW(Ezion MBeCW141103A);TQ2W(IndoAgriMBeCW141103);ACCW(KepCorp MBeCW141103);U8KW(KepCorp MBePW141103);TK8W(Olam MBeCW141103);AAHW(UOB MB ePW141103);ACRW(UOB MB eCW141103);TL0W(VardHldgsMBeCW141103);TY2W(CapitalaMBePW141103);U4BW(SembMar MBePW141103)",
			"underlying": "ASCEN REIT;DBS GRP HLDGS;EZION HOLDINGS;INDOFOOD AGRI;KEPPEL CORP;OLAM INTER;UOB LTD;VARD HOLDINGS;CAPITALAND;SEMBCORP MARINE",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "INDOFOOD AGRI"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				}
			],
			"date": "03 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 4 November 2014 (First Notice)",
			"id": 225,
			"tickers": [
				"ACDW(KepCorp MBePW141104)",
				"ACPW(UOB MB ePW141104)"
			],
			"related_warrant": "ACDW(KepCorp MBePW141104);ACPW(UOB MB ePW141104)",
			"underlying": "KEPPEL CORP;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "03 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 2 October 2014 (Third Notice)",
			"id": 217,
			"tickers": [
				"U4HW(Wilmar MBeCW141002)",
				"U0DW(AscendasRMBePW141002)"
			],
			"related_warrant": "U4HW(Wilmar MBeCW141002);U0DW(AscendasRMBePW141002)",
			"underlying": "WILMAR INTL;ASCEN REIT",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "02 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 1 October 2014 (Third Notice)",
			"id": 213,
			"tickers": [
				"U0FW(AscendasRMBeCW141001)",
				"TQ0W(BiosensoMBeCW141001)",
				"TG0W(CapitalaMBeCW141001)",
				"U4DW(DBS MB eCW141001)",
				"U7TW(DBS MB ePW141001)",
				"TI5W(Ezion MBeCW141001)",
				"TK5W(GLP MB eCW141001)",
				"TK6W(GLP MB ePW141001)",
				"TH8W(NobleGrpMBePW141001)",
				"TH9W(NobleGrpMBeCW141001)",
				"TJ4W(SembMar MBeCW141001)",
				"TJ2W(SembMar MBePW141001)",
				"TP9W(SGX MB eCW141001)",
				"ACSW(UOB MB eCW141001)",
				"TI1W(Wilmar MBeCW141001)",
				"TI0W(Wilmar MBePW141001)",
				"U0GW(YangzijiMBeCW141001)",
				"TQ1W(Yanlord MBeCW141001)",
				"U4AW(GentingSMBePW141001)",
				"TI2W(OCBC Bk MBePW141001)"
			],
			"related_warrant": "U0FW(AscendasRMBeCW141001);TQ0W(BiosensoMBeCW141001);TG0W(CapitalaMBeCW141001);U4DW(DBS MB eCW141001);U7TW(DBS MB ePW141001);TI5W(Ezion MBeCW141001);TK5W(GLP MB eCW141001);TK6W(GLP MB ePW141001);TH8W(NobleGrpMBePW141001);TH9W(NobleGrpMBeCW141001);TJ4W(SembMar MBeCW141001);TJ2W(SembMar MBePW141001);TP9W(SGX MB eCW141001);ACSW(UOB MB eCW141001);TI1W(Wilmar MBeCW141001);TI0W(Wilmar MBePW141001);U0GW(YangzijiMBeCW141001);TQ1W(Yanlord MBeCW141001);U4AW(GentingSMBePW141001);TI2W(OCBC Bk MBePW141001)",
			"underlying": "ASCEN REIT;BIOSENSORS;CAPITALAND;DBS GRP HLDGS;EZION HOLDINGS;GLOBAL LOG PROP;NOBLE GROUP;SEMBCORP MARINE;SGX;UOB LTD;WILMAR INTL;YANGZIJIANG;YANLORD LAND;GENTING SPORE;OCBC",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "01 Oct 14"
		},
		{
			"headline": "Expiry of Warrants on 30 October 2014 (First Notice)",
			"id": 209,
			"tickers": [
				"UI4W(HSI25800MBeCW141030)",
				"UI5W(HSI24400MBePW141030)",
				"UI2W(HSI24600MBeCW141030)",
				"UI3W(HSI25200MBeCW141030)",
				"UI1W(HSI25000MBePW141030)",
				"UP7W(HSI24000MBeCW141030)",
				"UL7W(HSI23800MBePW141030)"
			],
			"related_warrant": "UI4W(HSI25800MBeCW141030);UI5W(HSI24400MBePW141030);UI2W(HSI24600MBeCW141030);UI3W(HSI25200MBeCW141030);UI1W(HSI25000MBePW141030);UP7W(HSI24000MBeCW141030);UL7W(HSI23800MBePW141030)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Sep 14"
		},
		{
			"headline": "Expiry of Warrants on 31 October 2014 (First Notice)",
			"id": 205,
			"tickers": [
				"U5CW(STI 3350MBePW141031)",
				"U4LW(STI 3300MBeCW141031)"
			],
			"related_warrant": "U5CW(STI 3350MBePW141031);U4LW(STI 3300MBeCW141031)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Sep 14"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2014 (Third Notice)",
			"id": 197,
			"tickers": [
				"AFHW(HSI25400MBeCW140929)",
				"AFDW(HSI24800MBeCW140929)",
				"AFEW(HSI24600MBePW140929)",
				"AEYW(HSI23400MBePW140929)",
				"AFAW(HSI23600MBeCW140929)",
				"AEZW(HSI24000MBePW140929)",
				"AFBW(HSI24200MBeCW140929)"
			],
			"related_warrant": "AFHW(HSI25400MBeCW140929);AFDW(HSI24800MBeCW140929);AFEW(HSI24600MBePW140929);AEYW(HSI23400MBePW140929);AFAW(HSI23600MBeCW140929);AEZW(HSI24000MBePW140929);AFBW(HSI24200MBeCW140929)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Sep 14"
		},
		{
			"headline": "Expiry of Warrants on 12 September 2014 (Third Notice)",
			"id": 193,
			"tickers": [
				"U5NW(NKY 17000MBePW140912)",
				"U5JW(NKY 17500MBeCW140912)",
				"U5MW(NKY 15500MBePW140912)",
				"U5IW(NKY 16000MBeCW140912)",
				"U5LW(NKY 14000MBePW140912)",
				"U5HW(NKY 14500MBeCW140912)",
				"U5KW(NKY 12500MBePW140912)",
				"U5GW(NKY 13000MBeCW140912)"
			],
			"related_warrant": "U5NW(NKY 17000MBePW140912);U5JW(NKY 17500MBeCW140912);U5MW(NKY 15500MBePW140912);U5IW(NKY 16000MBeCW140912);U5LW(NKY 14000MBePW140912);U5HW(NKY 14500MBeCW140912);U5KW(NKY 12500MBePW140912);U5GW(NKY 13000MBeCW140912)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "12 Sep 14"
		},
		{
			"headline": "ADJUSTMENT NOTICE",
			"id": 185,
			"tickers": [
				"TI5W(Ezion MBeCW141001)",
				"TI4W(Ezion MBeCW141103)",
				"U8AW(Ezion MBeCW141103A)",
				"SR9W(Ezion MBeCW150102)",
				"U8BW(Ezion MBeCW150202)"
			],
			"related_warrant": "TI5W(Ezion MBeCW141001);TI4W(Ezion MBeCW141103);U8AW(Ezion MBeCW141103A);SR9W(Ezion MBeCW150102);U8BW(Ezion MBeCW150202)",
			"underlying": "EZION HOLDINGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				}
			],
			"date": "05 Sep 14"
		},
		{
			"headline": "Expiry of Warrants on 05 September 2014 (Third Notice)",
			"id": 189,
			"tickers": [
				"U4CW(DBS MB eCW140905)"
			],
			"related_warrant": "U4CW(DBS MB eCW140905)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "05 Sep 14"
		},
		{
			"headline": "Expiry of Warrants on 29 September 2014 (First Notice)",
			"id": 157,
			"tickers": [
				"AFHW(HSI25400MBeCW140929)",
				"AFBW(HSI24200MBeCW140929)",
				"AEZW(HSI24000MBePW140929)",
				"AFAW(HSI23600MBeCW140929)",
				"AEYW(HSI23400MBePW140929)",
				"AFEW(HSI24600MBePW140929)",
				"AFDW(HSI24800MBeCW140929)"
			],
			"related_warrant": "AFHW(HSI25400MBeCW140929);AFBW(HSI24200MBeCW140929);AEZW(HSI24000MBePW140929);AFAW(HSI23600MBeCW140929);AEYW(HSI23400MBePW140929);AFEW(HSI24600MBePW140929);AFDW(HSI24800MBeCW140929)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "29 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 29 August 2014 (Third Notice)",
			"id": 161,
			"tickers": [
				"U1SW(STI 3050MBePW140829)",
				"U1TW(STI 3150MBeCW140829)"
			],
			"related_warrant": "U1SW(STI 3050MBePW140829);U1TW(STI 3150MBeCW140829)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 28 August 2014",
			"id": 145,
			"tickers": [
				"ADHW(HSI24400MBeCW140828)",
				"AAKW(HSI23200MBeCW140828)",
				"AAMW(HSI23000MBePW140828)",
				"AANW(HSI23600MBePW140828)",
				"AALW(HSI23800MBeCW140828)",
				"U1UW(CN6000MBePW140828)",
				"U1VW(CN6600MBeCW140828)"
			],
			"related_warrant": "ADHW(HSI24400MBeCW140828);AAKW(HSI23200MBeCW140828);AAMW(HSI23000MBePW140828);AANW(HSI23600MBePW140828);AALW(HSI23800MBeCW140828);U1UW(CN6000MBePW140828);U1VW(CN6600MBeCW140828)",
			"underlying": "HANG SENG INDEX;China A50",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "28 Aug 14"
		},
		{
			"headline": "ADJUSTMENT NOTICE",
			"id": 141,
			"tickers": [
				"OCBCMBECW150102(SQ6W)",
				"OCBCMBEPW141001 (TI2W)",
				"OCBCMBECW160104 (U3ZW)",
				"OCBCMBECW150105 (U4XW)",
				"OCBCMBECW150107 (U8SW)",
				"OCBCMBECW150302 (U8RW)",
				"OCBCMBEPW150302 (U8QW)",
				"OCBCMBEPW150401 (U8PW)",
				"OCBCMBECW150202 (ACLW)"
			],
			"related_warrant": "OCBCMBECW150102(SQ6W);OCBCMBEPW141001 (TI2W);OCBCMBECW160104 (U3ZW);OCBCMBECW150105 (U4XW);OCBCMBECW150107 (U8SW);OCBCMBECW150302 (U8RW);OCBCMBEPW150302 (U8QW);OCBCMBEPW150401 (U8PW);OCBCMBECW150202 (ACLW)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "22 Aug 14"
		},
		{
			"headline": "Notice to Warrantholders - Expiry of Warrants on 12 September 2014",
			"id": 105,
			"tickers": [
				"NK13000MBC140912(U5GW)",
				"NK14500MBC140912(U5HW)",
				"NK16000MBC140912(U5IW)",
				"NK17500MBC140912(U5JW)",
				"NK12500MBP140912(U5KW)",
				"NK14000MBP140912(U5LW)",
				"NK15500MBP140912(U5MW)",
				"NK17000MBP140912(U5NW)"
			],
			"related_warrant": "NK13000MBC140912(U5GW);NK14500MBC140912(U5HW);NK16000MBC140912(U5IW);NK17500MBC140912(U5JW);NK12500MBP140912(U5KW);NK14000MBP140912(U5LW);NK15500MBP140912(U5MW);NK17000MBP140912(U5NW)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "13 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 5 September 2014 (First Notice)",
			"id": 89,
			"tickers": [
				"DBS MBECW140905(U4CW)"
			],
			"related_warrant": "DBS MBECW140905(U4CW)",
			"underlying": "DBS GRP HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				}
			],
			"date": "05 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 5 August 2014 (Third Notice)",
			"id": 93,
			"tickers": [
				"EZRAMBECW140805(TY8W)"
			],
			"related_warrant": "EZRAMBECW140805(TY8W)",
			"underlying": "EZRA HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				}
			],
			"date": "05 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 4 August 2014 (Third Notice)",
			"id": 85,
			"tickers": [
				"EZRAMBECW140804(TY9W)",
				"UOB MB ECW140804(U7YW)",
				"UOB MB EPW140804(U7ZW)"
			],
			"related_warrant": "EZRAMBECW140804(TY9W);UOB MB ECW140804(U7YW);UOB MB EPW140804(U7ZW)",
			"underlying": "EZRA HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 1 August 2014 (Third Notice)",
			"id": 77,
			"tickers": [
				"CAPLAMBEPW140801(T9WW)",
				"VARDHMBECW140801(TA1W)",
				"BIOSEMBEC140801 (TE8W)",
				"COSCOMBECW140801(TE4W)",
				"WILMMBECW140801 (TE6W)",
				"WILMMBEPW140801 (TE5W)",
				"EZRAMBECW140801(TG3W)",
				"UOB MB ECW140801(TH5W)",
				"OCBCMBECW140801 (TI3W)",
				"NOL MBECW140801 (TJ6W)",
				"OLAM MBECW140801(TK7W)",
				"CAPLAMBECW140801(TX8W)",
				"SGX MBEPW140801 (TX5W)",
				"SEMMAMBECW140801(TZ2W)",
				"UOB MB EPW140801(U4WW)"
			],
			"related_warrant": "CAPLAMBEPW140801(T9WW);VARDHMBECW140801(TA1W);BIOSEMBEC140801 (TE8W);COSCOMBECW140801(TE4W);WILMMBECW140801 (TE6W);WILMMBEPW140801 (TE5W);EZRAMBECW140801(TG3W);UOB MB ECW140801(TH5W);OCBCMBECW140801 (TI3W);NOL MBECW140801 (TJ6W);OLAM MBECW140801(TK7W);CAPLAMBECW140801(TX8W);SGX MBEPW140801 (TX5W);SEMMAMBECW140801(TZ2W);UOB MB EPW140801(U4WW)",
			"underlying": "SGX;CAPITALAND;BIOSENSORS;SEMBCORP MARINE;COSCO CORP;OLAM INTER;OCBC;NEPTUNE OL LTD;WILMAR INTL;VARD HOLDINGS;UOB LTD;EZRA HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 1 September 2014 (First Notice)",
			"id": 81,
			"tickers": [
				"BIOSEMBEC140901 (TE7W)",
				"COSCOMBECW140901(TE3W)",
				"CAPLAMBECW140901(TG1W)",
				"SEMMAMBECW140901(TJ5W)",
				"SEMMAMBEPW140901(TJ3W)",
				"STELMBEPW140901 (TP6W)",
				"CAPLAMBEPW140901(TX7W)",
				"OLAM MBECW140901(U2HW)",
				"KEPCMBECW140901(U4EW)",
				"KEPCMBEPW140901 (U4NW)",
				"SGX MBEPW140901 (U5DW)",
				"DBS MBECW140901 (U5FW)",
				"UOB MB ECW140901(U5VW)",
				"DBS MBEPW140901 (U7SW)"
			],
			"related_warrant": "BIOSEMBEC140901 (TE7W);COSCOMBECW140901(TE3W);CAPLAMBECW140901(TG1W);SEMMAMBECW140901(TJ5W);SEMMAMBEPW140901(TJ3W);STELMBEPW140901 (TP6W);CAPLAMBEPW140901(TX7W);OLAM MBECW140901(U2HW);KEPCMBECW140901(U4EW);KEPCMBEPW140901 (U4NW);SGX MBEPW140901 (U5DW);DBS MBECW140901 (U5FW);UOB MB ECW140901(U5VW);DBS MBEPW140901 (U7SW)",
			"underlying": "SINGTEL;SEMBCORP MARINE;COSCO CORP;CAPITALAND;BIOSENSORS;OLAM INTER;KEPPEL CORP;DBS GRP HLDGS;SGX;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "01 Aug 14"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2014 (Third Notice)",
			"id": 73,
			"tickers": [
				"HS22000MBC140730(U8YW)",
				"HS22400MBP140730(U8WW)",
				"HS22800MBC140730(U8ZW)",
				"HS23200MBP140730(U8XW)",
				"HS23600MBC140730(U9AW)"
			],
			"related_warrant": "HS22000MBC140730(U8YW);HS22400MBP140730(U8WW);HS22800MBC140730(U8ZW);HS23200MBP140730(U8XW);HS23600MBC140730(U9AW)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 29 August 2014 (First Notice)",
			"id": 69,
			"tickers": [
				"STI3050MBP140829(U1SW)",
				"STI3150MBC140829(U1TW)"
			],
			"related_warrant": "STI3050MBP140829(U1SW);STI3150MBC140829(U1TW)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "29 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 28 August 2014",
			"id": 65,
			"tickers": [
				"CN6000MBP140828 (U1UW)",
				"CN6600MBC140828 (U1VW)",
				"HS23200MBC140828(AAKW)",
				"HS23800MBC140828(AALW)",
				"HS23000MBP140828(AAMW)",
				"HS23600MBP140828(AANW)",
				"HS24400MBC140828(ADHW)"
			],
			"related_warrant": "CN6000MBP140828 (U1UW);CN6600MBC140828 (U1VW);HS23200MBC140828(AAKW);HS23800MBC140828(AALW);HS23000MBP140828(AAMW);HS23600MBP140828(AANW);HS24400MBC140828(ADHW)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "25 Jul 14"
		},
		{
			"headline": "Early Termination of Warrants on 15 July 2014",
			"id": 61,
			"tickers": [
				"CAPMALMBCW140901(TQ4W)",
				"CAPMALMBPW141001(TQ3W)",
				"CAPMALMBCW150102(SR1W)",
				"CAPMALMBPW150105(U4OW)"
			],
			"related_warrant": "CAPMALMBCW140901(TQ4W);CAPMALMBPW141001(TQ3W);CAPMALMBCW150102(SR1W);CAPMALMBPW150105(U4OW)",
			"underlying": "CAPITAMALLS ASIA",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITAMALLS ASIA"
				}
			],
			"date": "14 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 04 August 2014 (First Notice)",
			"id": 54,
			"tickers": [
				"EZRA MB ECW140804(TY9W)",
				"UOB MB ECW140804(U7YW)",
				"UOB MB EPW140804(U7ZW)"
			],
			"related_warrant": "EZRA MB ECW140804(TY9W);UOB MB ECW140804(U7YW);UOB MB EPW140804(U7ZW)",
			"underlying": "EZRA HLDGS;UOB LTD",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "04 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 05 August 2014 (First Notice)",
			"id": 58,
			"tickers": [
				"EZRA MB ECW140805(TY8W)"
			],
			"related_warrant": "EZRA MB ECW140805(TY8W)",
			"underlying": "EZRA HLDGS",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				}
			],
			"date": "04 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 03 July 2014 (Third Notice)",
			"id": 46,
			"tickers": [
				"OCBC BK MB ECW140703(TX3W)"
			],
			"related_warrant": "OCBC BK MB ECW140703(TX3W)",
			"underlying": "OCBC",
			"underlyings": [
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "03 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 02 July 2014 (Third Notice)",
			"id": 42,
			"tickers": [
				"DBS MBEPW140702(TG6W)",
				"EZRAMBECW140702(TZ0W)",
				"KEPCMBEPW140702(TY1W)",
				"NOL MBECW140702(TY7W)",
				"OCBCMBEPW140702(TX4W)"
			],
			"related_warrant": "DBS MBEPW140702(TG6W);EZRAMBECW140702(TZ0W);KEPCMBEPW140702(TY1W);NOL MBECW140702(TY7W);OCBCMBEPW140702(TX4W)",
			"underlying": "EZRA HLDGS;NEPTUNE OL LTD;DBS GRP HLDGS;KEPPEL CORP;OCBC",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				}
			],
			"date": "02 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 01 August 2014 (First Notice)",
			"id": 34,
			"tickers": [
				"BIOSEMBEC140801(TE8W)",
				"CAPLAMBECW140801(TX8W)",
				"CAPLAMBEPW140801(T9WW)",
				"COSCOMBECW140801(TE4W)",
				"EZRAMBECW140801(TG3W)",
				"NOL MBECW140801(TJ6W)",
				"OCBCMBECW140801(TI3W)",
				"OLAM MBECW140801(TK7W)",
				"SEMMAMBECW140801(TZ2W)",
				"SGX MBEPW140801(TX5W)",
				"UOB MB ECW140801(TH5W)",
				"UOB MB EPW140801(U4WW)",
				"VARDHMBECW140801(TA1W)",
				"WILMMBECW140801(TE6W)",
				"WILMMBEPW140801(TE5W)"
			],
			"related_warrant": "BIOSEMBEC140801(TE8W);CAPLAMBECW140801(TX8W);CAPLAMBEPW140801(T9WW);COSCOMBECW140801(TE4W);EZRAMBECW140801(TG3W);NOL MBECW140801(TJ6W);OCBCMBECW140801(TI3W);OLAM MBECW140801(TK7W);SEMMAMBECW140801(TZ2W);SGX MBEPW140801(TX5W);UOB MB ECW140801(TH5W);UOB MB EPW140801(U4WW);VARDHMBECW140801(TA1W);WILMMBECW140801(TE6W);WILMMBEPW140801(TE5W)",
			"underlying": "BIOSENSORS;CAPITALAND;COSCO CORP;EZRA HLDGS;NEPTUNE OL LTD;OLAM INTER;OCBC;SEMBCORP MARINE;UOB LTD;VARD HOLDINGS;WILMAR INTL;SGX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "VARD HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "01 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 01 July 2014 (Third Notice)",
			"id": 38,
			"tickers": [
				"CAPLAMBECW140701(TG2W)",
				"DBS MBEPW140701 (TG5W)",
				"EZIONMBECW140701(T9MW)",
				"EZRAMBECW140701(TF8W)",
				"GEN MBECW140701 (T9VW)",
				"GEN MBEPW140701 (T5IW)",
				"KEPCMBECW140701 (TG7W)",
				"KEPCMBEPW140701 (TG9W)",
				"NOBLMBECW140701 (T7SW)",
				"NOL MBECW140701 (TJ7W)",
				"OCBCMBECW140701 (T9ZW)",
				"OCBCMBEPW140701 (TA0W)",
				"SEMMAMBECW140701(TZ1W)",
				"SGX MBECW140701 (TX6W)",
				"SGX MBEPW140701 (TP7W)",
				"SPH MBECW140701 (T8DW)",
				"STELMBECW140701 (T7PW)",
				"UOB MB ECW140701(TH6W)",
				"UOB MB EPW140701(TH3W)",
				"WILMMBEPW140701 (T5OW)",
				"YANGZMBEC140701 (T4ZW)",
				"YAN MBECW140701 (T5DW)"
			],
			"related_warrant": "CAPLAMBECW140701(TG2W);DBS MBEPW140701 (TG5W);EZIONMBECW140701(T9MW);EZRAMBECW140701(TF8W);GEN MBECW140701 (T9VW);GEN MBEPW140701 (T5IW);KEPCMBECW140701 (TG7W);KEPCMBEPW140701 (TG9W);NOBLMBECW140701 (T7SW);NOL MBECW140701 (TJ7W);OCBCMBECW140701 (T9ZW);OCBCMBEPW140701 (TA0W);SEMMAMBECW140701(TZ1W);SGX MBECW140701 (TX6W);SGX MBEPW140701 (TP7W);SPH MBECW140701 (T8DW);STELMBECW140701 (T7PW);UOB MB ECW140701(TH6W);UOB MB EPW140701(TH3W);WILMMBEPW140701 (T5OW);YANGZMBEC140701 (T4ZW);YAN MBECW140701 (T5DW)",
			"underlying": "CAPITALAND;EZION HOLDINGS;EZRA HLDGS;GENTING SPORE;KEPPEL CORP;NEPTUNE OL LTD;NOBLE GROUP;OCBC;SEMBCORP MARINE;SGX;SPORE PRESS HLDG;SINGTEL;UOB LTD;YANGZIJIANG;YANLORD LAND;DBS GRP HLDGS;WILMAR INTL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZRA HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NEPTUNE OL LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SPORE PRESS HLDG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "01 Jul 14"
		},
		{
			"headline": "Expiry of Warrants on 30 July 2014 (First Notice)",
			"id": 26,
			"tickers": [
				"HS22000MBC140730(U8YW)",
				"HS22400MBP140730(U8WW)",
				"HS22800MBC140730(U8ZW)",
				"HS23200MBP140730(U8XW)",
				"HS23600MBC140730(U9AW)"
			],
			"related_warrant": "HS22000MBC140730(U8YW);HS22400MBP140730(U8WW);HS22800MBC140730(U8ZW);HS23200MBP140730(U8XW);HS23600MBC140730(U9AW)",
			"underlying": "HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "30 Jun 14"
		},
		{
			"headline": "Expiry of Warrants on 30 June 2014 (3rd Notice)",
			"id": 30,
			"tickers": [
				"STI2850MBP140630(TY0W)",
				"STI2950MBC140630(TX9W)"
			],
			"related_warrant": "STI2850MBP140630(TY0W);STI2950MBC140630(TX9W)",
			"underlying": "STRAITS TIMES",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "STRAITS TIMES"
				}
			],
			"date": "30 Jun 14"
		},
		{
			"headline": "Expiry of Warrants on 27 June 2014 (Third Notice)",
			"id": 14,
			"tickers": [
				"CN8000MBP140627 (TF2W)",
				"CN8600MBC140627 (TF1W)",
				"CN7000MBP140627 (TR5W)",
				"CN7600MBC140627(TR6W)",
				"HS22600MBP140627(U5QW)",
				"HS23000MBC140627(U5OW)",
				"HS23400MBP140627(U5RW)",
				"HS23800MBC140627(U5PW)",
				"HS21600MBP140627(U6GW)",
				"HS21800MBC140627(U6EW)",
				"HS22200MBP140627(U6DW)",
				"HS22400MBC140627(U6FW)"
			],
			"related_warrant": "CN8000MBP140627 (TF2W);CN8600MBC140627 (TF1W);CN7000MBP140627 (TR5W);CN7600MBC140627(TR6W);HS22600MBP140627(U5QW);HS23000MBC140627(U5OW);HS23400MBP140627(U5RW);HS23800MBC140627(U5PW);HS21600MBP140627(U6GW);HS21800MBC140627(U6EW);HS22200MBP140627(U6DW);HS22400MBC140627(U6FW)",
			"underlying": "China A50;HANG SENG INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "China A50"
				},
				{
					"underlying_ric": "HSIc1",
					"underlying_ticker": "HSI",
					"underlying_name": "HANG SENG INDEX"
				}
			],
			"date": "27 Jun 14"
		},
		{
			"headline": "Expiry of Warrants on 13 June 2014",
			"id": 13,
			"tickers": [
				"NKY14000MBeCW140613(TW4W)",
				"NKY15500MBeCW140613(TW3W)",
				"NKY17000MBeCW140613(TW2W)",
				"NKY18500MBeCW140613(TW1W)",
				"NKY13000MBePW140613(TW0W)",
				"NKY14500MBePW140613(TV9W)",
				"NKY16000MBePW140613(TV8W)",
				"NKY17500MBePW140613(TV7W)"
			],
			"related_warrant": "NKY14000MBeCW140613(TW4W);NKY15500MBeCW140613(TW3W);NKY17000MBeCW140613(TW2W);NKY18500MBeCW140613(TW1W);NKY13000MBePW140613(TW0W);NKY14500MBePW140613(TV9W);NKY16000MBePW140613(TV8W);NKY17500MBePW140613(TV7W)",
			"underlying": "NIKKEI 225 INDEX",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NIKKEI 225 INDEX"
				}
			],
			"date": "13 Jun 14"
		},
		{
			"headline": "Expiry of Warrants on 02 October 2014 (First Notice)",
			"id": 177,
			"tickers": [
				"U4HW(Wilmar MBeCW141002)",
				"U0DW(AscendasRMBePW141002)"
			],
			"related_warrant": "U4HW(Wilmar MBeCW141002);U0DW(AscendasRMBePW141002)",
			"underlying": "WILMAR INTL;ASCEN REIT",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				}
			],
			"date": "02 Dec 13"
		},
		{
			"headline": "Expiry of Warrants on 01 September 2014 (Third Notice)",
			"id": 169,
			"tickers": [
				"TE7W(BiosensoMBeCW140901)",
				"TX7W(CapitalaMBePW140901)",
				"TG1W(CapitalaMBeCW140901)",
				"TE3W(CoscoCorMBeCW140901)",
				"U5FW(DBS MB eCW140901)",
				"U7SW(DBS MB ePW140901)",
				"U4NW(KepCorp MBePW140901)",
				"U4EW(KepCorp MBeCW140901)",
				"U2HW(Olam MBeCW140901)",
				"TJ5W(SembMar MBeCW140901)",
				"TJ3W(SembMar MBePW140901)",
				"U5VW(UOB MB eCW140901)",
				"U5DW(SGX MB ePW140901)",
				"TP6W(SingTelMBePW140901)"
			],
			"related_warrant": "TE7W(BiosensoMBeCW140901);TX7W(CapitalaMBePW140901);TG1W(CapitalaMBeCW140901);TE3W(CoscoCorMBeCW140901);U5FW(DBS MB eCW140901);U7SW(DBS MB ePW140901);U4NW(KepCorp MBePW140901);U4EW(KepCorp MBeCW140901);U2HW(Olam MBeCW140901);TJ5W(SembMar MBeCW140901);TJ3W(SembMar MBePW140901);U5VW(UOB MB eCW140901);U5DW(SGX MB ePW140901);TP6W(SingTelMBePW140901)",
			"underlying": "BIOSENSORS;CAPITALAND;COSCO CORP;DBS GRP HLDGS;KEPPEL CORP;OLAM INTER;SEMBCORP MARINE;UOB LTD;SGX;SINGTEL",
			"underlyings": [
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "COSCO CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "KEPPEL CORP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "OLAM INTER"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": "STEL.SI",
					"underlying_ticker": "Z74",
					"underlying_name": "SINGTEL"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				}
			],
			"date": "01 Dec 13"
		},
		{
			"headline": "Expiry of Warrants on 01 October 2014 (First Notice)",
			"id": 165,
			"tickers": [
				"U0FW(AscendasRMBeCW141001)",
				"TQ0W(BiosensoMBeCW141001)",
				"TG0W(CapitalaMBeCW141001)",
				"U4DW(DBS MB eCW141001)",
				"U7TW(DBS MB ePW141001)",
				"TI5W(Ezion MBeCW141001)",
				"TK5W(GLP MB eCW141001)",
				"TK6W(GLP MB ePW141001)",
				"TH9W(NobleGrpMBeCW141001)",
				"TH8W(NobleGrpMBePW141001)",
				"TJ4W(SembMar MBeCW141001)",
				"TJ2W(SembMar MBePW141001)",
				"TP9W(SGX MB eCW141001)",
				"ACSW(UOB MB eCW141001)",
				"TI1W(Wilmar MBeCW141001)",
				"TI0W(Wilmar MBePW141001)",
				"U0GW(YangzijiMBeCW141001)",
				"TQ1W(Yanlord MBeCW141001)",
				"U4AW(GentingSMBePW141001)",
				"TI2W(OCBC Bk MBePW141001)"
			],
			"related_warrant": "U0FW(AscendasRMBeCW141001);TQ0W(BiosensoMBeCW141001);TG0W(CapitalaMBeCW141001);U4DW(DBS MB eCW141001);U7TW(DBS MB ePW141001);TI5W(Ezion MBeCW141001);TK5W(GLP MB eCW141001);TK6W(GLP MB ePW141001);TH9W(NobleGrpMBeCW141001);TH8W(NobleGrpMBePW141001);TJ4W(SembMar MBeCW141001);TJ2W(SembMar MBePW141001);TP9W(SGX MB eCW141001);ACSW(UOB MB eCW141001);TI1W(Wilmar MBeCW141001);TI0W(Wilmar MBePW141001);U0GW(YangzijiMBeCW141001);TQ1W(Yanlord MBeCW141001);U4AW(GentingSMBePW141001);TI2W(OCBC Bk MBePW141001)",
			"underlying": "ASCEN REIT;BIOSENSORS;CAPITALAND;DBS GRP HLDGS;EZION HOLDINGS;GLOBAL LOG PROP;NOBLE GROUP;SEMBCORP MARINE;SGX;UOB LTD;WILMAR INTL;YANGZIJIANG;YANLORD LAND;GENTING SPORE;OCBC",
			"underlyings": [
				{
					"underlying_ric": "AEMN.SI",
					"underlying_ticker": "A17U",
					"underlying_name": "ASCEN REIT"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "BIOSENSORS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "CAPITALAND"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "DBS GRP HLDGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "EZION HOLDINGS"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GENTING SPORE"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "GLOBAL LOG PROP"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "NOBLE GROUP"
				},
				{
					"underlying_ric": "OCBC.SI",
					"underlying_ticker": "O39",
					"underlying_name": "OCBC"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "SEMBCORP MARINE"
				},
				{
					"underlying_ric": "SGXL.SI",
					"underlying_ticker": "S68",
					"underlying_name": "SGX"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "UOB LTD"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "WILMAR INTL"
				},
				{
					"underlying_ric": "YAZG.SI",
					"underlying_ticker": "BS6",
					"underlying_name": "YANGZIJIANG"
				},
				{
					"underlying_ric": null,
					"underlying_ticker": null,
					"underlying_name": "YANLORD LAND"
				}
			],
			"date": "01 Dec 13"
		}
	]
}

const documentData = {
  "termsheetData": termsheetData,
  "noticeData": noticeData
}

export default documentData;