import React, { Component } from "react";

export default class sharebtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }
  changeShow(i) {
    this.setState({ isShow: i });
  }
  render() {
    return (
      <div>
        <div className="sharebtn hidden-sm hidden-xs">
          <ul className="list-unstyled">
            <li>
              <a
                className="sharebtn-hl btn"
                onClick={() => this.changeShow(true)}
              ></a>
            </li>
            <li>
              <a
                target="_blank"
                href="/marketnews/subscribe"
                className="sharebtn-sb btn"
              ></a>
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-fb btn disclaimer"
                type="button"
                href="https://www.facebook.com/MacquarieWarrantsSG/?utm_source=MQ&amp;utm_medium=Icon&amp;utm_campaign=FB"
              ></a>
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-tl btn"
                type="button"
                // href="/telegram?utm_source=Landing%20page&amp;utm_medium=Icon&amp;utm_campaign=Landing%20page"
                href="https://t.me/MacquarieWarrantsSG"
              ></a>
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-yt btn disclaimer"
                type="button"
                href="https://bit.ly/34Bgg1t"
              ></a>
            </li>
          </ul>
        </div>
        <div
          className="popup-customized"
          style={{ display: this.state.isShow ? "table" : "none" }}
          onClick={() => this.changeShow(false)}
        >
          <div>
            <div className="hlbox">
              <div className="hlbox-close"></div>
              <div className="hl-txt">Hotline</div>
              <a href="tel:+65 6601 0289" className="btn btn-primary">
                <span
                  className="hl-icon"
                  onClick={() => this.changeShow(false)}
                ></span>
                <span className="hl-num">+65 6601 0289</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
