import React, { Component } from "react";
import Select from "react-select";

import PageHeader from "./pageHeader/pageHeader";
import BreadcrumbBar from "./breadcrumbBar";
import MQSGUtil from "./mqsgUtil";
import AdvertBox from "./advert/advert";
import { Link } from "react-router-dom";

export default class privacypolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        document.title = "Privacy policy"
    }
    render() {
        return (
            <div id="privacypolicy" className="pageobj">
                <BreadcrumbBar
                    link="/home"
                    group="Macquarie"
                    title="Privacy policy"
                />
                <div className="stripe-bg"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section">
                                <div className="page-header">
                                    <h1>Privacy policy</h1>
                                </div>
                                <div className="title">Macquarie Group Privacy Policy</div>
                                <p>Central to the success of Macquarie Group (being Macquarie Group Limited and its related bodies corporate globally) is our commitment to our clients. We honour without reservation our obligation to respect and protect the privacy of the personal information of individuals with whom we deal.</p>
                                <div className="title">The Information We Collect</div>
                                <p>Whenever it is reasonable and practicable to do so, we only collect information about you directly from you.</p>
                                <p>We only collect Personal Information about You which is reasonably necessary to:</p>
                                <ul>
                                    <li>provide you with quality products or services</li>
                                    <li>consider applications and approaches you make to us</li>
                                    <li>maintain your contact details</li>
                                    <li>fulfil our legal obligations under applicable laws and rules, such as those relating to Anti-Money Laundering and Counter-Terrorism Financing</li>
                                </ul>
                                <p>We generally hold Personal Information relating to:</p>
                                <ul>
                                    <li>the products and services we provide or have provided to you</li>
                                    <li>your financial interests and sometimes your financial position</li>
                                    <li>your contact details</li>
                                </ul>
                                <p>We do not generally collect Sensitive Information about You unless required by applicable Laws or Rules. Sensitive Information includes information relating to:</p>
                                <ul>
                                    <li>race</li>
                                    <li>political or religious beliefs</li>
                                    <li>sexual preferences</li>
                                    <li>criminal convictions</li>
                                    <li>membership of professional or trade associations or unions</li>
                                    <li>health information</li>
                                </ul>
                                <p>We collect health information where we offer products with an insurance component to our customers. We do not use or share that information for any purpose other than the underwriting or administration of a customer's policy, claim or account, or as otherwise disclosed to the customer when the information was collected.</p>
                                <p>
                                    We also collect personal information from people who submit applications or register interest for employment with Macquarie Group. Please read the
                                    &nbsp;<a href="http://www.macquarie.com/th/about/careers#?scroll=privacy">Career Privacy Policy</a>
                                    &nbsp;for more information.
                                </p>
                                <div className="title">How that Information is Used</div>
                                <p>We only use and disclose personal information about you for the purpose for which it was disclosed to us or related purposes which would reasonably be expected without your permission.</p>
                                <p>For example, we may from time to time use your personal information to inform you of investment opportunities or to provide information about products and services which we expect may be of interest to you. However, we do respect your right to ask us not to do this.</p>
                                <p>We do not sell personal information to other organisations to allow them to do this.</p>
                                <p>We may share personal information inside the Macquarie Group, and may disclose personal information outside the Macquarie Group:</p>
                                <ul>
                                    <li>as required by applicable laws or rules, such as those relating to Anti-Money Laundering and Counter-Terrorism Financing</li>
                                    <li>to service providers in, or outside of, Australia who provide services in connection with our products and services</li>
                                    <li>to your nominated financial adviser with your permission</li>
                                </ul>
                                <p>Laws and rules regarding the use of personal information may vary by jurisdiction. Macquarie Group complies with applicable laws and rules in the relevant jurisdiction.</p>
                                <div className="title">Keeping Information Accurate and Up to Date</div>
                                <p>We take all reasonable steps to ensure that all information we hold is as accurate as is possible. You are able to contact us at any time and ask for its correction if you feel the information we have about you is inaccurate or incomplete.</p>
                                <p>Ensuring easy access to the site for all users is important to us - so please contact us if you have difficulties using this site or if you have any further questions or comments.</p>
                                <div className="title">Keeping Information Secure</div>
                                <p>We use security procedures and the latest technology to protect the information we hold. Access to and use of personal information within Macquarie Group is appropriately limited to prevent misuse or unlawful disclosure of the information.</p>
                                <p>If other organisations provide support services, we require them to appropriately safeguard the privacy of the information provided to them.</p>
                                <p>Where the personal information we collect is no longer required, we delete the information or permanently de-identify it.</p>
                                <div className="title">How You can Access your Information</div>
                                <p>You can contact us to request access to your information. In normal circumstances we will give you full access to your information. However, there may be some legal or administrative reasons to deny access. If access is denied, we will provide you with the reason why.</p>
                                <div className="title">Cookies</div>
                                <p>A 'cookie' is a packet of information placed on a user's computer by a website for record keeping purposes. Cookies are generally used on Macquarie Group sites to:</p>
                                <ul>
                                    <li>access online services - if you visit a Macquarie Group website and log into our secure areas we will use cookies to enable us to authorise your access</li>
                                    <li>manage advertising - we use advertising companies to deliver our online advertising. When you see one of our ads on a third party website, cookies are sometimes used to collect information about what pages you visit and the type of software you are using</li>
                                    <li>traffic monitoring - we use tracking companies to gather information about how people are using the Macquarie site, this information includes time of visit, pages visited, and some system information about the type of computer you are using. We use this information to enhance the content and services offered on the site.</li>
                                </ul>
                                <p>Cookies may also be used for other purposes on Macquarie Group sites but in each case none of the information collected can be used to personally identify you.</p>
                                <p>You can configure your browser to accept all cookies, reject all cookies, or notify you when a cookie is sent. (Each browser is different, so check the "Help" menu of your browser to learn how to change your cookie preferences).</p>
                                <p>If you disable the use of cookies on your web browser or remove or reject specific cookies from Macquarie Group websites or linked sites then you may not be able to gain access to all of the content and facilities on those websites.</p>
                                <div className="title">Dealing with Macquarie Group Online</div>
                                <p>If you visit a Macquarie Group website to browse, read or download information, our system will log these movements. These web site logs are not personally identifiable and Macquarie Group makes no attempt to link them with the individuals who browse the site.</p>
                                <p>Your account information is password protected and we recommend you do not divulge your password to anyone. To further protect your personal information, your log-in time will automatically expire after a certain period of inactivity.</p>
                                <div className="title">Contact Us</div>
                                <p>If you have any questions regarding our privacy policy or handling of information, please
                                    &nbsp;<a href="/about/contactus">contact us</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}