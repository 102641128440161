
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class intrinsicvalueandtimevalue extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>A warrant’s value consists of:</p>
                                            <div className="flex_row">
                                                <div className="subtitle"><span>1</span></div>
                                                <h3 style={{ color: '#FFDA44' }}>Intrinsic Value</h3>
                                            </div>
                                            <p style={{ lineHeight: '20px' }}>
                                                When the exercise price of a call warrant is below the underlying’s current share price, it is said to have ‘intrinsic value’. Have a look at the example below. In this case the exercise price of $3 is at a $0.20 “discount” from the current share price,
                                                this is its intrinsic value. As such, this warrant will have a minimum price of 20-cents – its intrinsic value. All call warrants with an exercise price lower than the spot price should always be worth at least the intrinsic
                                                value.
                                            </p>
                                            <div className="imgholder">
                                                <img style={{ maxWidth: 600 }} src="/img/education/Intrinic-Value-and-Time-Value_enlarge.jpg" />
                                            </div>
                                            <div className="flex_row">
                                                <div className="subtitle"><span>2</span></div>
                                                <h3 style={{ color: '#FFDA44' }}>Time value</h3>
                                            </div>
                                            <p>A warrant will usually be worth more than its intrinsic value. As you can see in this example, the warrant is priced at $0.30, which is $0.10 more than its intrinsic value. This remaining value is called ‘time value’.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div >
        )
    }
}