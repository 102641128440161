
import React, { Component } from 'react'
import "./investormy.css"
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";

export default class formulatocalculatevalueofputwarrantatexpiry extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="">
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto', }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        {/* <title>Call Vs Put</title> */}
                                        <div className="contentbox">
                                            <p>Single stock put warrants that expire with value are those where the average closing price of the underlying share closes below the exercise price. The settlement value is then calculated by subtracting the five day average
                                                closing price from the exercise price, divided by warrants per share.</p>
                                            <h2 className="title">FORMULA TO CALCULATE VALUE OF PUT WARRANT AT EXPIRY</h2>
                                            <div className="formulaholder">
                                                <div className="formulabox">
                                                    <div className="leqt">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ height: 39, }}>
                                                                        Value at Expiry =
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="reqt">
                                                        <table className="fraction">
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{}}>(Exercise price - 5 Day Average Closing Price)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="upper_line" style={{}}>Warrants per share</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}