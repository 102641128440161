
import React, { Component } from 'react'
import Alert from "./alert/alert.jsx";
import Footer from "./footer/index.jsx";
import "./investormy.css";

export default class howarewarrantsnamed extends Component {
    open(v) {
        Alert.open(true)
    }
    componentWillMount() {
        document.title = "Macquarie Education"
    }
    render() {
        return (
            <div >
                <div>
                    <div className="" >
                        <div id="maincontainer" style={{ display: 'table-cell', width: '100%' }}>
                            <div id="mainnav">
                            </div>
                            {/*<div id="maincontent" style="margin-left:40px;margin-right:40px;padding-top:40px;">*/}
                            <div id="maincontent" style={{ marginLeft: 10, marginRight: 10, marginBottom: 60 }}>
                                <div id="mainscroll" style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                    <div id="mainscrollcontent">
                                        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                                        {/*<link rel="stylesheet" href="css/common.css" />*/}
                                        <title>Call Vs Put</title>
                                        <div className="contentbox">
                                            <p>Like shares, warrants are listed on the SGX and can be bought and sold through any stockbroker. Each warrant has an assigned name and warrant code. The name contains information about the warrant, whereas the code is used by
                                                most trading platforms to identify each warrant.<br /><br /> The SGX assigns “Warrant Codes” to each warrant (also known as Trading IDs, Sing IDs or GL codes). These codes are 4 digit alphanumeric code ending in the letter
                                                ‘W’, for example “BDSW” and “BGEW”. The warrant codes are used by the various trading systems (including many of the broker online platforms) to individually identify a warrant.
                                                <br /><br /> Throughout this website, we will normally include the warrant codes next to the warrant name, these will often be in brackets following the warrant name, for example “BDSW” in the following description “CapitalaMBeCW160901
                                                (BDSW)”.
                                            </p>
                                            <div className="imgholder hidden-xs">
                                                <img src="/img/education/warrant_name.png" />
                                            </div>
                                            <br />
                                            <br />
                                            <div className="tipholder">
                                                <div className="tipbox">
                                                    <h2>Helpful tips</h2>
                                                    <ul>
                                                        <li>
                                                            <p>CALL warrants increase in value as a share price increases. PUT warrants increase in value as a share price falls.</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}